import { TIMEZONE } from '@invisible/common/helpers'
import { IDataFilter, IReportMeta, TFilterValue } from '@invisible/common/types'
import { fromGlobalId } from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { REPORT_VISUALIZATION_ORDER } from '@invisible/ui/constants'
import { GlobalOutlineIcon } from '@invisible/ui/icons'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { DescriptionIcon } from '@invisible/ui/reports/icons'
import { isEmpty, sortBy } from 'lodash/fp'

import { withStory, withStoryDynamicFilters } from './HOCs'
import { useStories, useStory, useStoryDynamicFilters } from './hooks'
import { Report } from './Report'
import { SeparatorReport } from './SeparatorReport'
import { StoryDynamicFilters } from './StoryDynamicFilters'

const renderReport = (reportMetaData: IReportMeta, dataFilters: IDataFilter<TFilterValue>[]) => {
  switch (reportMetaData.sourceType) {
    case 'text':
      if (!isEmpty(reportMetaData?.subtitle) || !isEmpty(reportMetaData.reportTitle))
        return (
          <SeparatorReport
            title={reportMetaData.subtitle}
            subtitle={reportMetaData?.subtitle}
            key={reportMetaData.id}
          />
        )
      return null
    default:
      return (
        <Report reportMetaData={reportMetaData} dataFilters={dataFilters} key={reportMetaData.id} />
      )
  }
}

/* eslint-disable @typescript-eslint/ban-types */
const StoryComponent: React.FC = ({ children }) => {
  const story = useStory()
  const { dataFiltersPerReport } = useStoryDynamicFilters()
  const { staticFilters } = useStories()

  const { data: reportMetas, isLoading: isLoadingReports } = useQuery(
    [
      'report.getReportMetaByStoryId',
      {
        id: fromGlobalId(story.id ?? ''),
      },
    ],
    {
      refetchOnWindowFocus: false,
    }
  )

  if (isLoadingReports)
    return (
      <div className='flex h-full items-center justify-center'>
        <LogoSpinner width={30} />
      </div>
    )

  return (
    <div className='absolute left-0 w-full'>
      <div
        className='sticky -top-1 z-20 bg-white p-4'
        style={{
          boxShadow: '0px 6px 5px 0px rgba(96, 76, 165, 0.12)',
        }}>
        <div className='flex items-center gap-2'>
          <div id='story-title' className='text-xl font-bold'>
            {story.title}
          </div>
          {!!story.description && <DescriptionIcon description={story.description} />}
        </div>
        <div className='text-paragraphs my-2 text-base'>{story?.subtitle}</div>
        <div className='my-2 flex items-end gap-2 whitespace-nowrap'>
          {children}
          <StoryDynamicFilters />
          <div className='mb-2 flex items-center gap-1'>
            <GlobalOutlineIcon width={20} height={20} />
            <span className='pt-0.5'>Reporting Time Zone: {TIMEZONE.UTC}</span>
          </div>
        </div>
      </div>

      <div className='p-4'>
        {!isEmpty(reportMetas) &&
          dataFiltersPerReport != null &&
          sortBy((reportMetaData: IReportMeta) => reportMetaData?.order)(reportMetas).map(
            (reportMetaData: IReportMeta, index: number) => {
              const filterPerReport = dataFiltersPerReport.find(
                (filterPerReport) => fromGlobalId(filterPerReport.reportId) === reportMetaData.id
              )
              const reportMetaWithSortedVisualizations = {
                ...reportMetaData,
                visualizations: reportMetaData.visualizations.sort(
                  (visualizationA, visualizationB) =>
                    REPORT_VISUALIZATION_ORDER[visualizationA.type] -
                    REPORT_VISUALIZATION_ORDER[visualizationB.type]
                ),
              }
              return (
                <div
                  id={`report-${index}`}
                  data-testid={`report-container-${index}`}
                  className='mb-8'>
                  {renderReport(reportMetaWithSortedVisualizations, [
                    ...(filterPerReport?.filters ?? []),
                    ...staticFilters,
                  ])}
                </div>
              )
            }
          )}
        {isEmpty(reportMetas) && (
          <div className='flex items-center justify-center text-2xl'>
            Could not find reports for this story.
          </div>
        )}
      </div>
    </div>
  )
}

const Story = withStory(withStoryDynamicFilters(StoryComponent))

export { Story }
