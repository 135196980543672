import { Stack, Typography } from '@mui/material'
import React from 'react'
import sanitize from 'sanitize-html'

export const QaMeasurements = ({
  qaStepMeasurement,
}: {
  qaStepMeasurement?: { belowTheBar: string; atTheBar: string; aboveTheBar: string }
}) => (
  <Stack
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '16px',
    }}>
    <Stack sx={{ display: 'flex', direction: 'column', gap: '8px' }}>
      <Typography fontWeight='bold' fontSize='12px'>
        Below the bar (fail)
      </Typography>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(qaStepMeasurement?.belowTheBar ?? ''),
        }}
      />
    </Stack>
    <Stack sx={{ display: 'flex', direction: 'column', gap: '8px' }}>
      <Typography fontWeight='bold' fontSize='12px'>
        At the bar (pass)
      </Typography>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(qaStepMeasurement?.atTheBar ?? ''),
        }}
      />
    </Stack>
    <Stack sx={{ display: 'flex', direction: 'column', gap: '8px' }}>
      <Typography fontWeight='bold' fontSize='12px'>
        Above the bar (pass)
      </Typography>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(qaStepMeasurement?.aboveTheBar ?? ''),
        }}
      />
    </Stack>
  </Stack>
)
