import { ComposableMap, Geographies, Geography, ProjectionConfig } from 'react-simple-maps'

import { skeletonData } from './skeleton-data'

export const ChoroplethSkeleton = ({ height = 400 }: { height?: number }) => {
  const LOADING_PROJECTION_CONFIG = {
    scale: (1000 / Math.PI / 2 + 400 / Math.PI / 2) / 2,
    center: [0, 0],
  }
  return (
    <ComposableMap
      projection='geoMercator'
      projectionConfig={LOADING_PROJECTION_CONFIG as ProjectionConfig}
      height={height}>
      <Geographies
        geography={skeletonData}
        style={{
          fill: 'url("#di38s69-animated-diff")',
        }}
        id='di38s69-diff'>
        {({ geographies }) =>
          geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} />)
        }
      </Geographies>
      <linearGradient id='di38s69-animated-diff'>
        <stop offset='0%' stop-color='#f3f3f3' stop-opacity='1'>
          <animate
            attributeName='offset'
            values='-2; -2; 1'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'></animate>
        </stop>
        <stop offset='50%' stop-color='#ecebeb' stop-opacity='1'>
          <animate
            attributeName='offset'
            values='-1; -1; 2'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'></animate>
        </stop>
        <stop offset='100%' stop-color='#f3f3f3' stop-opacity='1'>
          <animate
            attributeName='offset'
            values='0; 0; 3'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'></animate>
        </stop>
      </linearGradient>
    </ComposableMap>
  )
}
