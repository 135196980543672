import { SVGProps } from 'react'
function CircleArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_107_7969)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM1.53846 10C1.53846 5.32682 5.32682 1.53846 10 1.53846C14.6732 1.53846 18.4615 5.32682 18.4615 10C18.4615 14.6732 14.6732 18.4615 10 18.4615C5.32682 18.4615 1.53846 14.6732 1.53846 10Z'
          fill='currentColor'
        />
        <path
          d='M10.9501 5.80806C10.706 5.56398 10.3103 5.56398 10.0662 5.80806C9.8221 6.05214 9.8221 6.44786 10.0662 6.69194L12.3799 9.00566H5.625C5.27982 9.00566 5 9.28548 5 9.63066C5 9.97584 5.27982 10.2557 5.625 10.2557H12.38L10.0662 12.5695C9.8221 12.8135 9.8221 13.2093 10.0662 13.4533C10.3103 13.6974 10.706 13.6974 10.9501 13.4533L14.325 10.0784C14.4415 9.96487 14.5139 9.80622 14.5139 9.63066C14.5139 9.45388 14.4405 9.29424 14.3226 9.18055L10.9501 5.80806Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_107_7969'>
          <rect width='20' height='20' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircleArrowLeft
