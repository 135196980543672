import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  toGlobalId,
  useBaseRunCreateMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from 'react'

type Json = string | number | boolean | null | JsonArray | { [key: string]: Json }
type JsonArray = Array<Json>

interface InitialValue {
  baseVariableId: string
  value: Json
}

interface OnMutateVariables {
  parentBaseRunId?: string
  initialValues: InitialValue[]
}

interface BaseRunCreateInput extends OnMutateVariables {
  baseId: string
  deadline?: Date
  benchmark?: number
  stepRunId: string
  createStepRun?: boolean
  stepIdToCreateStepRunFor?: string
}

type OnMutateHandler = (variables: OnMutateVariables) => void

const useBaseRunCreate = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: () => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: createBaseRun, isLoading } = useBaseRunCreateMutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const { sourceStepRunId } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useBaseRunCreate via GraphQL resulted in an error: ${errorMessage}`,
        {
          stepRunId: fromGlobalId(sourceStepRunId),
        }
      )
    },
    onSuccess: (_, variables) => {
      const { sourceStepRunId } = variables
      handleOnSuccess?.()
      logger.info('Mutating from useBaseRunCreate via GraphQL successful', {
        stepRunId: fromGlobalId(sourceStepRunId),
      })
    },
    onMutate: handleOnMutate
      ? (variables) => {
          const initialValues = Array.isArray(variables.baseRunVariablesData)
            ? variables.baseRunVariablesData.map((iv) => ({
                baseVariableId: fromGlobalId(iv.baseVariableId),
                value: (iv.value ?? null) as Json,
              }))
            : [
                {
                  baseVariableId: fromGlobalId(variables.baseRunVariablesData.baseVariableId),
                  value: (variables.baseRunVariablesData.value ?? null) as Json,
                },
              ]

          handleOnMutate({
            parentBaseRunId: fromGlobalId(variables.parentBaseRunId),
            initialValues,
          })
        }
      : undefined,
    onSettled,
  })

  const mutateAsync = async (data: BaseRunCreateInput) => {
    logger.info('Mutating from useBaseRunCreate', {
      stepRunId: data.stepRunId,
      baseRunVariables: data.initialValues,
    })
    logger.info('Mutating from useBaseRunCreate via GraphQL', {
      stepRunId: data.stepRunId,
    })
    const graphQLData = {
      baseId: toGlobalId('BaseType', data.baseId),
      parentBaseRunId: toGlobalId('BaseRunType', data.parentBaseRunId),
      sourceStepRunId: toGlobalId('StepRunType', data.stepRunId),
      ...(data.stepIdToCreateStepRunFor
        ? {
            stepRunCreateData: { stepId: toGlobalId('StepType', data.stepIdToCreateStepRunFor) },
          }
        : {}),
      baseRunVariablesData: data.initialValues.map((iv) => ({
        baseVariableId: toGlobalId('BaseVariableType', iv.baseVariableId),
        value: iv.value,
      })),
    }
    const response = await createBaseRun(graphQLData)
    const baserun = response.baseRunCreateWizardAction
    return {
      ...response,
      // these two keys are included to be backwards compatible with the usage in wacs
      id: fromGlobalId(baserun.id),
      createdAt: new Date(baserun.createdAt),
    }
  }

  return { mutateAsync, isLoading }
}

export { useBaseRunCreate }
