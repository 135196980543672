import { z } from '@invisible/zod'

const schema = z.object({
  rate: z.number(),
  multiplier: z.number(),
  isTimeTracking: z.boolean().optional(),
  timeTrackedAdjusted: z.boolean().optional(),
  useAgentDefaultRate: z.boolean().optional(),
  deducedHandlingTime: z.number().optional(),
  limitExceeded: z.string().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
