import { datadogLogs } from '@datadog/browser-logs'
import { compact } from 'lodash'

import {
  ENV,
  GIT_REF,
  GIT_SHA,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
  NEXT_PUBLIC_DATADOG_SERVICE_NAME,
} from '../config/env'

const initLogger = () => {
  if (ENV === 'production') {
    datadogLogs.init({
      clientToken: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: NEXT_PUBLIC_DATADOG_SERVICE_NAME,
      version: compact([GIT_REF, GIT_SHA]).join('@'),
      env: ENV,
      sampleRate: NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
    })
    return datadogLogs.logger
  }
  return console
}

export { initLogger, datadogLogs as logger }
