import { useStepRunSnooze } from '@invisible/common/components/process-base'
import { useTosSowState } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  IStepRunType,
  toGlobalId,
  useGetOrCreateStatementOfWorkMutation,
  useStepRunStartV2Mutation,
} from '@invisible/concorde/gql-client'
import { useContext } from '@invisible/trpc/client'
import { resetIdleCheckStateStorage } from '@invisible/ui/hooks/use-user-activity'
import { StepRunControlsCell } from '@invisible/ui/react-table'
import { useToasts } from '@invisible/ui/toasts'
import { useSession } from 'next-auth/react'
import React, { FC } from 'react'
import { useQueryClient } from 'react-query'

import {
  handleSetUserLifecycleStage,
  handleStepRunUpdatesOnLooopResourceStatus,
} from './common/helpers'
import { useCompleteStepRun } from './hooks/useCompleteStepRun'
import { TBaseRunQueryData } from './hooks/useGetBaseRuns'
import { useSnoozeActivitiesFindAllInactiveForStepRun } from './hooks/useSnoozeActivitiesFindAllInactiveForStepRun'
import { useStepRunUnsnooze } from './hooks/useStepRunUnsnooze'

type TBaseRun = NonNullable<TBaseRunQueryData>['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

interface StepRunControlProps {
  stepRun: TStepRun
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StepRunControls: FC<StepRunControlProps> = ({ stepRun }) => {
  const { data: session } = useSession()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { addToast } = useToasts()
  const { dispatch: dispatchSowState } = useTosSowState()

  const { data: stepRunSnoozeActivities, refetch: refetchSnoozeInactiveActivities } =
    useSnoozeActivitiesFindAllInactiveForStepRun({ stepRunId: stepRun?.id })

  const { mutateAsync: startStepRun, isLoading: isStarting } = useStepRunStartV2Mutation({
    onSuccess: (response) => {
      if (response.stepRunStartV2.__typename !== 'GraphQLErrorType') {
        const stepRunStartData = response.stepRunStartV2 as IStepRunType
        const transformedData = {
          id: fromGlobalId(stepRunStartData.id),
          stepId: fromGlobalId(stepRunStartData.id),
          assigneeId: fromGlobalId(stepRunStartData.assignee?.id),
          baseRunId: fromGlobalId(stepRunStartData.baseRun.id),
        }
        handleSetUserLifecycleStage({
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
        })
        handleStepRunUpdatesOnLooopResourceStatus({
          id: transformedData.id,
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
          baseRunId: transformedData.baseRunId,
        })
      }
    },
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      resetIdleCheckStateStorage()
    },
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Start failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })

  const { mutateAsync: snoozeStepRun, isLoading: isSnoozing } = useStepRunSnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
    onError: (error) => {
      addToast(`Snooze failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
  })

  const { mutateAsync: unSnoozeStepRun, isLoading: isUnsnoozing } = useStepRunUnsnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      refetchSnoozeInactiveActivities()
    },
    onError: (error) => {
      addToast(`Unsnooze failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
  })

  const { mutateAsync: submitForCompletion, isLoading: isSubmitting } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
    onError: (error) => {
      addToast(`Submission failed: ${(error as Error)?.message}`, {
        appearance: 'error',
      })
    },
  })
  const { mutateAsync: getOrCreateSow } = useGetOrCreateStatementOfWorkMutation({
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Get or create SoW failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })

  const handleCellClick = async () => {
    try {
      if (stepRun.status === 'snoozed') await unSnoozeStepRun({ stepRunId: stepRun.id })
      if (stepRun.status === 'pending') {
        const stepRunStartData = await startStepRun({ id: toGlobalId('StepRunType', stepRun.id) })
        if (stepRunStartData.stepRunStartV2.__typename === 'GraphQLErrorType') {
          const { message, code } = stepRunStartData.stepRunStartV2

          if (message === 'User has not acknowledged the latest statement of work') {
            try {
              const request = await getOrCreateSow({
                stepRunId: toGlobalId('StepRunType', stepRun.id),
              })

              if (request?.getOrCreateStatementOfWork?.__typename !== 'GraphQLErrorType') {
                dispatchSowState({
                  type: 'setSowToAcknowledge',
                  showSowModal: true,
                  sowToAcknowledge: request.getOrCreateStatementOfWork,
                  stepRunId: stepRun.id,
                })
              }
            } catch (err: unknown) {
              addToast(`Fetch Statement of Work failed: ${(err as Error | undefined)?.message}`, {
                appearance: 'error',
              })
            }
          } else {
            addToast(`${code}: ${message}`, {
              appearance: 'error',
            })
          }
        }
      }
    } catch (error) {
      return
    }
  }

  const handleDoneClick = async () => {
    try {
      await submitForCompletion({ id: stepRun.id })
    } catch (error) {
      return
    }
  }

  const handleSnoozeClick = async () => {
    try {
      await snoozeStepRun({ stepRunId: stepRun.id })
    } catch (error) {
      return
    }
  }

  return (
    <StepRunControlsCell
      status={stepRun?.status ?? 'disabled'}
      startedAt={stepRun?.startedAt}
      disabled={
        !(session?.user && stepRun?.assignee?.email === session?.user?.email) ||
        isStarting ||
        isSubmitting ||
        isSnoozing ||
        isUnsnoozing
      }
      stepRunSnoozeActivities={stepRunSnoozeActivities}
      onClick={handleCellClick}
      doneClick={handleDoneClick}
      snoozeClick={handleSnoozeClick}
    />
  )
}

export { StepRunControls }
