import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  parseResponse,
  toGlobalId,
  useStepRunSnoozeV2Mutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from 'react'

interface StepRunSnoozeInput {
  stepRunId: string
}

type OnMutateHandler = (variables: StepRunSnoozeInput) => void

const useStepRunSnooze = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: (stepRun: { id: string }) => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: stepRunSnooze, isLoading } = useStepRunSnoozeV2Mutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const { id } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useStepRunSnoozeMutation via GraphQL resulted in an error: ${errorMessage}`,
        {
          stepRunId: fromGlobalId(id),
        }
      )
    },
    onSuccess: (_, variables) => {
      const { id } = variables
      handleOnSuccess?.({ id })
      logger.info('Mutating from useStepRunSnoozeMutation via GraphQL successful', {
        stepRunId: fromGlobalId(id),
      })
    },
    onMutate: () =>
      handleOnMutate
        ? (variables: StepRunSnoozeInput) => {
            handleOnMutate({
              stepRunId: fromGlobalId(variables.stepRunId),
            })
          }
        : undefined,
    onSettled,
  })

  const mutateAsync = async (data: StepRunSnoozeInput) => {
    logger.info('Mutating from useStepRunSnoozeMutation', { data })

    const graphQLData = {
      id: toGlobalId('StepRunType', data.stepRunId),
    }

    const response = await stepRunSnooze(graphQLData)

    const gqlResponse = parseResponse(response.stepRunSnoozeV2, (code: number, message: string) => {
      const _error = new Error(`${code}: ${message}`)
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: message,
          variant: 'error',
        })
      }
    })

    return {
      stepRunId: gqlResponse?.id,
    }
  }

  return {
    mutateAsync,
    isLoading,
  }
}

export { useStepRunSnooze }
