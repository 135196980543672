import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { OldDropdown } from '@invisible/ui/dropdown'
import { Input } from '@invisible/ui/input'
import { Text } from '@invisible/ui/text'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { ChangeEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { Flex } from 'rebass'

import {
  findBaseVariableId,
  getBaseRunVariableFromQueryData,
  safeJSONParse,
} from '../common/helpers'
import { useBaseRunVariablesWizardUpdate } from '../hooks/useBaseRunVariablesWizardUpdate'
import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'

const PROVIDER_NO_BNPL = 'No BNPL'
const MANDATORY_PROVIDERS = ['Access denied', 'Other']

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

type IProps = WizardSchemas.WACConfig.TSchema & {
  baseRun: TBaseRun
  stepRun: TStepRun
  closeWizard: () => void
  form: TBaseRunVariable
}

const MIN_SCORE = 0
const MAX_SCORE = 100

// eslint-disable-next-line react/display-name
const KlarnaQAFormWAC = forwardRef(({ form, baseRun, stepRun }: IProps) => {
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { addToast } = useToasts()
  const { dispatch } = useWizardState()
  const ref = useRef<HTMLDivElement>(null)

  // fetch each variable separately
  const pickListBaseVariableId = useMemo(() => findBaseVariableId(form, 'pick_list'), [form])
  const typeBaseVariableId = useMemo(() => findBaseVariableId(form, 'type'), [form])
  const pdpBaseVariableId = useMemo(() => findBaseVariableId(form, 'pdp_qa'), [form])
  const checkoutBaseVariableId = useMemo(() => findBaseVariableId(form, 'checkout_qa'), [form])
  const pdpFreeTextBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'pdp_free_text_qa'),
    [form]
  )
  const checkoutFreeTextBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'checkout_free_text_qa'),
    [form]
  )
  const additionalNotesBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'additional_notes_qa'),
    [form]
  )
  const pdp1BaseVariableId = useMemo(() => findBaseVariableId(form, 'pdp_1'), [form])
  const checkout1BaseVariableId = useMemo(() => findBaseVariableId(form, 'checkout_1'), [form])
  const notes1BaseVariableId = useMemo(() => findBaseVariableId(form, 'notes_1'), [form])
  const notes2BaseVariableId = useMemo(() => findBaseVariableId(form, 'notes_2'), [form])
  const score1BaseVariableId = useMemo(() => findBaseVariableId(form, 'score_1'), [form])
  const score2BaseVariableId = useMemo(() => findBaseVariableId(form, 'score_2'), [form])
  const pdpMatchesBaseVariableId = useMemo(() => findBaseVariableId(form, 'pdp_matches'), [form])
  const checkoutMatchesBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'checkout_matches'),
    [form]
  )
  const pdpFreeText1BaseVariableId = useMemo(
    () => findBaseVariableId(form, 'pdp_free_text_1'),
    [form]
  )
  const pdpFreeText2BaseVariableId = useMemo(
    () => findBaseVariableId(form, 'pdp_free_text_2'),
    [form]
  )
  const checkoutFreeText1BaseVariableId = useMemo(
    () => findBaseVariableId(form, 'checkout_free_text_1'),
    [form]
  )
  const checkoutFreeText2BaseVariableId = useMemo(
    () => findBaseVariableId(form, 'checkout_free_text_2'),
    [form]
  )

  const { data } = useQuery([
    'baseRunVariable.findByBaseRunIdAndBaseVariableId',
    [
      {
        baseRunId: baseRun.id,
        baseVariableIds: [
          pickListBaseVariableId,
          typeBaseVariableId,
          pdp1BaseVariableId,
          checkout1BaseVariableId,
          additionalNotesBaseVariableId,
          pdpMatchesBaseVariableId,
          checkoutMatchesBaseVariableId,
          notes1BaseVariableId,
          notes2BaseVariableId,
          score1BaseVariableId,
          score2BaseVariableId,
          pdpFreeText1BaseVariableId,
          pdpFreeText2BaseVariableId,
          pdpFreeTextBaseVariableId,
          checkoutFreeText1BaseVariableId,
          checkoutFreeText2BaseVariableId,
          checkoutFreeTextBaseVariableId,
        ],
      },
    ],
  ])

  const pickList = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pickListBaseVariableId) ?? [],
    [data, pickListBaseVariableId]
  )
  const type = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, typeBaseVariableId) ?? [],
    [data, typeBaseVariableId]
  )
  const initialPdp = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdp1BaseVariableId) ?? [],
    [data, pdp1BaseVariableId]
  )
  const initialCheckout = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkout1BaseVariableId) ?? [],
    [data, checkout1BaseVariableId]
  )
  const initialAdditionalNotes = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, additionalNotesBaseVariableId) ?? '',
    [data, additionalNotesBaseVariableId]
  )

  const initialNotes1 = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, notes1BaseVariableId) ?? '',
    [data, notes1BaseVariableId]
  )
  const initialNotes2 = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, notes2BaseVariableId) ?? '',
    [data, notes2BaseVariableId]
  )
  const initialScore1 = useMemo(
    () => getBaseRunVariableFromQueryData<number>(data, score1BaseVariableId) ?? 0,
    [data, score1BaseVariableId]
  )
  const initialScore2 = useMemo(
    () => getBaseRunVariableFromQueryData<number>(data, score2BaseVariableId) ?? 0,
    [data, score2BaseVariableId]
  )
  const initialPdpMatches = useMemo(
    () => getBaseRunVariableFromQueryData<boolean[]>(data, pdpMatchesBaseVariableId) ?? [],
    [data, pdpMatchesBaseVariableId]
  )
  const initialCheckoutMatches = useMemo(
    () => getBaseRunVariableFromQueryData<boolean[]>(data, checkoutMatchesBaseVariableId) ?? [],
    [data, checkoutMatchesBaseVariableId]
  )
  const initialPdpFreeText1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpFreeText1BaseVariableId) ?? [],
    [data, pdpFreeText1BaseVariableId]
  )
  const initialPdpFreeText2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpFreeText2BaseVariableId) ?? [],
    [data, pdpFreeText2BaseVariableId]
  )
  const initialPdpFreeText = useMemo(
    () =>
      getBaseRunVariableFromQueryData<string[]>(data, pdpFreeTextBaseVariableId) ??
      Array(2).fill(''),
    [data, pdpFreeTextBaseVariableId]
  )
  const initialCheckoutFreeText1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeText1BaseVariableId) ?? [],
    [data, checkoutFreeText1BaseVariableId]
  )
  const initialCheckoutFreeText2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeText2BaseVariableId) ?? [],
    [data, checkoutFreeText2BaseVariableId]
  )
  const initialCheckoutFreeText = useMemo(
    () =>
      getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeTextBaseVariableId) ??
      Array(2).fill(''),
    [data, checkoutFreeTextBaseVariableId]
  )

  const [pdp, setPdp] = useState<(string | null)[]>(Array(3).fill(''))
  const [checkout, setCheckout] = useState<(string | null)[]>(Array(8).fill(''))
  const [pdpFreeText, setPdpFreeText] = useState<(string | null)[]>(Array(2).fill(''))
  const [checkoutFreeText, setCheckoutFreeText] = useState<(string | null)[]>(Array(2).fill(''))
  const [additionalNotes, setAdditionalNotes] = useState<string>('')
  const [notes1, setNotes1] = useState<string>('')
  const [notes2, setNotes2] = useState<string>('')
  const [pdpFreeText1, setPdpFreeText1] = useState<(string | null)[]>(Array(2).fill(''))
  const [pdpFreeText2, setPdpFreeText2] = useState<(string | null)[]>(Array(2).fill(''))
  const [checkoutFreeText1, setCheckoutFreeText1] = useState<(string | null)[]>(Array(2).fill(''))
  const [checkoutFreeText2, setCheckoutFreeText2] = useState<(string | null)[]>(Array(2).fill(''))
  const [score1, setScore1] = useState<number>(0)
  const [score2, setScore2] = useState<number>(0)
  const [pdpMatches, setPdpMatches] = useState<boolean[]>(Array(3).fill(true))
  const [checkoutMatches, setCheckoutMatches] = useState<boolean[]>(Array(7).fill(true))

  const disabledDueToScoreLimits = useMemo(
    () => score1 < MIN_SCORE || score2 < MIN_SCORE || score1 > MAX_SCORE || score2 > MAX_SCORE,
    [score1, score2]
  )

  const disabledDueToRepeatedProvidersPdp = useMemo<boolean>(
    () => new Set(pdp.filter((a) => a)).size !== pdp.filter((a) => a).length,
    [pdp]
  )

  const disabledDueToMandatoryNotesPdp = useMemo<boolean>(
    () => !!pdp.find((p) => MANDATORY_PROVIDERS.includes(p || '')) && additionalNotes.trim() === '',
    [pdp, additionalNotes]
  )

  const disabledDueToMandatoryNotesCheckout = useMemo<boolean>(
    () =>
      type === 'complex' &&
      !!checkout.find((p) => MANDATORY_PROVIDERS.includes(p || '')) &&
      additionalNotes.trim() === '',
    [type, checkout, additionalNotes]
  )

  const disabledDueToNoBNPL = useMemo<boolean>(() => pdp[0] === PROVIDER_NO_BNPL, [pdp])

  const disabledDueToRepeatedProvidersCheckout = useMemo<boolean>(
    () =>
      type === 'complex' &&
      new Set(checkout.filter((a) => a)).size !== checkout.filter((a) => a).length,
    [type, checkout]
  )

  const disabled = useMemo(
    () =>
      disabledDueToScoreLimits ||
      disabledDueToRepeatedProvidersPdp ||
      disabledDueToRepeatedProvidersCheckout ||
      disabledDueToMandatoryNotesPdp ||
      disabledDueToMandatoryNotesCheckout,
    [
      disabledDueToScoreLimits,
      disabledDueToRepeatedProvidersPdp,
      disabledDueToRepeatedProvidersCheckout,
      disabledDueToMandatoryNotesPdp,
      disabledDueToMandatoryNotesCheckout,
    ]
  )

  const getOptions = useCallback(
    (type: 'pdp' | 'checkout', idx) => {
      const arr = type === 'pdp' ? pdp : checkout
      const newPickList = pickList.filter(
        (p) => type === 'checkout' || idx === 0 || p !== PROVIDER_NO_BNPL
      )
      return newPickList
        .slice(idx === 0 ? 0 : newPickList.indexOf(arr[idx - 1] as string) + 1)
        .map((p) => ({ key: p, value: p }))
    },
    [pickList, pdp, checkout]
  )

  useEffect(() => {
    const newPdp = pdp.slice()
    const tempInitialPdp: string[] =
      typeof initialPdp === 'string' ? safeJSONParse(initialPdp) : initialPdp

    tempInitialPdp.forEach((p, idx) => {
      newPdp[idx] = pdpMatches[idx] ? newPdp[idx] : p
    })
    setPdp(newPdp)
  }, [initialPdp, pdpMatches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newCheckout = checkout.slice()
    const tempInitialCheckout: string[] =
      typeof initialCheckout === 'string' ? safeJSONParse(initialCheckout) : initialCheckout

    tempInitialCheckout.forEach((p, idx) => {
      newCheckout[idx] = checkoutMatches[idx] ? newCheckout[idx] : p
    })
    setCheckout(newCheckout)
  }, [initialCheckout, checkoutMatches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setNotes1(initialNotes1)
  }, [initialNotes1])

  useEffect(() => {
    setNotes2(initialNotes2)
  }, [initialNotes2])

  useEffect(() => {
    setScore1(initialScore1)
  }, [initialScore1])

  useEffect(() => {
    setScore2(initialScore2)
  }, [initialScore2])

  useEffect(() => {
    setPdpFreeText1(initialPdpFreeText1)
  }, [initialPdpFreeText1])

  useEffect(() => {
    setPdpFreeText2(initialPdpFreeText2)
  }, [initialPdpFreeText2])

  useEffect(() => {
    setPdpFreeText(initialPdpFreeText)
  }, [initialPdpFreeText])

  useEffect(() => {
    setCheckoutFreeText1(initialCheckoutFreeText1)
  }, [initialCheckoutFreeText1])

  useEffect(() => {
    setCheckoutFreeText2(initialCheckoutFreeText2)
  }, [initialCheckoutFreeText2])

  useEffect(() => {
    setCheckoutFreeText(initialCheckoutFreeText)
  }, [initialCheckoutFreeText])

  useEffect(() => {
    setAdditionalNotes(initialAdditionalNotes)
  }, [initialAdditionalNotes])

  useEffect(() => {
    setPdpMatches(initialPdpMatches)
  }, [initialPdpMatches])

  useEffect(() => {
    setCheckoutMatches(initialCheckoutMatches)
  }, [initialCheckoutMatches])

  const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
  })

  const { mutateAsync: updateManyBaseRunVariables } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const handleSubmit = async () => {
    try {
      const baseVariablesToUpdate = [
        {
          baseVariableId: pdpBaseVariableId,
          baseRunId: baseRun.id,
          value: pdp,
        },
        {
          baseVariableId: checkoutBaseVariableId,
          baseRunId: baseRun.id,
          value: checkout,
        },
        {
          baseVariableId: pdpFreeTextBaseVariableId,
          baseRunId: baseRun.id,
          value: pdpFreeText,
        },
        {
          baseVariableId: checkoutFreeTextBaseVariableId,
          baseRunId: baseRun.id,
          value: checkoutFreeText,
        },
        {
          baseVariableId: additionalNotesBaseVariableId,
          baseRunId: baseRun.id,
          value: additionalNotes,
        },
        {
          baseVariableId: score1BaseVariableId,
          baseRunId: baseRun.id,
          value: score1,
        },
        {
          baseVariableId: score2BaseVariableId,
          baseRunId: baseRun.id,
          value: score2,
        },
        {
          baseVariableId: notes1BaseVariableId,
          baseRunId: baseRun.id,
          value: notes1,
        },
        {
          baseVariableId: notes2BaseVariableId,
          baseRunId: baseRun.id,
          value: notes2,
        },
      ]
      await updateManyBaseRunVariables({ stepRunId: stepRun.id, data: baseVariablesToUpdate })

      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    } catch (error) {
      sendErrorToSentry(error)
      addToast(`Something went wrong: ${(error as Error).message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <div
      className='h-5/5 border-gray box-border overflow-auto rounded-lg border bg-white p-2.5 shadow'
      ref={ref}>
      <Text mb='10px' fontWeight='bold'>
        {type === 'simple' ? 'Alternate Payment Providers' : 'PDP Providers'}
      </Text>
      {pdp.map((name, id: any) => (
        <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
          <Text textAlign='end' width='40%' mr={2}>
            {id + 1}
          </Text>
          {pdpMatches[id] ? (
            <div className={'w-7/12'}>
              <OldDropdown
                width='100%'
                selectedKey={(name as string) ?? null}
                search
                clearText={'(clear)'}
                onClear={() => setPdp(pdp.map((p, i) => (i === id ? '' : p)))}
                options={getOptions('pdp', id)}
                disabled={id > 0 && disabledDueToNoBNPL}
                onSelect={(value) => {
                  setPdp(() => pdp.map((p, i) => (i === id ? value : p)))
                  setPdpMatches(() => pdpMatches.map((p, i) => (i === id ? true : p)))
                }}
                name={''}
              />
            </div>
          ) : (
            <div className={'w-7/12 rounded-2xl border-8 border-solid border-red-500'}>
              <OldDropdown
                width='100%'
                selectedKey={null}
                search
                clearText={'(clear)'}
                onClear={() => setPdp(pdp.map((p, i) => (i === id ? '' : p)))}
                options={getOptions('pdp', id)}
                disabled={id > 0 && disabledDueToNoBNPL}
                onSelect={(value) => {
                  setPdp(() => pdp.map((p, i) => (i === id ? value : p)))
                  setPdpMatches(() => pdpMatches.map((p, i) => (i === id ? true : p)))
                }}
                name={''}
              />
            </div>
          )}
        </Flex>
      ))}
      <Text mb='10px' fontWeight='bold'>
        {'PDP Free Text'}
      </Text>
      {pdpFreeText?.map((name: string | null, id: any) => (
        <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
          <Text textAlign='end' width='40%' mr={2}>
            {id + 1}
          </Text>
          <div className={`w-7/12`}>
            <Input
              width='100%'
              value={pdpFreeText[id] ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPdpFreeText(pdpFreeText.map((p, i) => (i === id ? e.target.value : p)))
              }}
            />
          </div>
        </Flex>
      ))}
      {type === 'complex' && (
        <>
          <Text mb='10px' fontWeight='bold'>
            {'Checkout Providers'}
          </Text>
          {checkout.map((name, id: any) => (
            <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
              <Text textAlign='end' width='40%' mr={2}>
                {id + 1}
              </Text>
              {checkoutMatches[id] ? (
                <div className={'w-7/12'}>
                  <OldDropdown
                    width='100%'
                    selectedKey={(name as string) ?? null}
                    search
                    clearText={'(clear)'}
                    onClear={() => setCheckout(checkout.map((p, i) => (i === id ? '' : p)))}
                    options={getOptions('checkout', id)}
                    disabled={disabledDueToNoBNPL}
                    onSelect={(value) => {
                      setCheckout(() => checkout.map((p, i) => (i === id ? value : p)))
                      setCheckoutMatches(() => checkoutMatches.map((p, i) => (i === id ? true : p)))
                    }}
                    name={''}
                  />
                </div>
              ) : (
                <div className={'w-7/12 rounded-2xl border-8 border-solid border-red-500'}>
                  <OldDropdown
                    width='100%'
                    selectedKey={null}
                    search
                    clearText={'(clear)'}
                    onClear={() => setCheckout(checkout.map((p, i) => (i === id ? '' : p)))}
                    options={getOptions('checkout', id)}
                    disabled={disabledDueToNoBNPL}
                    onSelect={(value) => {
                      setCheckout(() => checkout.map((p, i) => (i === id ? value : p)))
                      setCheckoutMatches(() => checkoutMatches.map((p, i) => (i === id ? true : p)))
                    }}
                    name={''}
                  />
                </div>
              )}
            </Flex>
          ))}
        </>
      )}
      {type === 'complex' && (
        <>
          <Text mb='10px' fontWeight='bold'>
            {'Checkout Free Text'}
          </Text>
          {checkoutFreeText?.map((name: string | null, id: any) => (
            <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
              <Text textAlign='end' width='40%' mr={2}>
                {id + 1}
              </Text>
              <div className={`w-7/12`}>
                <Input
                  width='100%'
                  value={checkoutFreeText[id] ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCheckoutFreeText(
                      checkoutFreeText.map((p, i) => (i === id ? e.target.value : p))
                    )
                  }}
                />
              </div>
            </Flex>
          ))}
        </>
      )}
      <Text mb='10px' fontWeight='bold'>
        {'QA Scores'}
      </Text>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <Text textAlign='end' width='40%' mr={2}>
          {'Agent 1 score'}
        </Text>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            type='number'
            max={100}
            min={0}
            value={score1.toString()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setScore1(Number(e.target.value))
            }}
          />
        </div>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <Text textAlign='end' width='40%' mr={2}>
          {'QC Notes Agent 1'}
        </Text>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            value={notes1 as string}
            max={100}
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNotes1(e.target.value)
            }}
          />
        </div>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <Text textAlign='end' width='40%' mr={2}>
          {'Agent 2 score'}
        </Text>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            type='number'
            max={100}
            min={0}
            value={score2.toString()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setScore2(Number(e.target.value))
            }}
          />
        </div>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <Text textAlign='end' width='40%' mr={2}>
          {'QC Notes Agent 2'}
        </Text>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            value={notes2 as string}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNotes2(e.target.value)
            }}
          />
        </div>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <Text textAlign='end' width='40%' mr={2}>
          {'External Notes'}
        </Text>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            value={additionalNotes}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAdditionalNotes(e.target.value)
            }}
          />
        </div>
      </Flex>
      <div className='mt-3'>
        <Button
          variant='primary'
          size='md'
          onClick={handleSubmit}
          disabled={disabled}
          loading={isMarkStepRunDoneLoading}
          wFull>
          {'Submit'}
        </Button>
      </div>
    </div>
  )
})

export { KlarnaQAFormWAC }
