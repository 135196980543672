import { BranchStepMeta } from '@invisible/ultron/zod'
import { values } from 'lodash/fp'

export const MAXIMUM_EXPORTABLE_BASERUNS = 10000 as const
export const DEFAULT_ITEMS_PER_PAGE = 40 as const

export const OPTIMISTIC_STEP_ID = '61de7099-49cf-4d30-bb77-a7b1d505842c'

// Process Builder
export const DND_DRAG_TYPES = {
  CANVAS_STEP: 'CanvasStep',
  STEP_TEMPLATE: 'StepTemplate',
  TRIGGER_STEP: 'TriggerStep',
} as const

export const NODE_TYPES = {
  START: 'start',
  END: 'end',
  REGULAR: 'regular',
  TRIGGER: 'trigger',
  TRIGGER_PLACEHOLDER: 'triggerPlaceholder',
  STAGE: 'stage',
  ATTENDED_MAP: 'attendedMap',
  AUDIT: 'audit',
  IGNORE: 'ignore',
} as const
export const _NODE_TYPES = values(NODE_TYPES)

export const EDGE_TYPES = {
  DEFAULT: 'default',
  INITIAL_TRIGGER: 'initialTrigger',
} as const
export const _EDGE_TYPES = values(EDGE_TYPES)

export const conditionSymbols: Record<BranchStepMeta.TBranch['condition']['name'], string> = {
  eq: '=',
  neq: '≠',
  isNull: '= NULL',
  isNotNull: '≠ NULL',
}
