import { SVGProps } from 'react'
function Dog(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={527}
      height={577}
      viewBox='0 0 527 577'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M294.238 87.419c-8.24 13.733-24.033 17.929-30.899 18.31v415.207s20.682-59.996 71.753-87.567c37.886-20.452 73.903-2.48 52.283 32.899 22.798-13.901 39.361-36.584 47.914-57.821 8.553-21.238 3.688-60.239 3.688-60.239 15.461-33.978 47.524 47.059 47.524 47.059-6.34-82.095-60.061-134.931-60.061-155.914 0-20.982 12.537-22.294 38.861 24.238 6.95-49.638-138.557-167.772-118.525-239.315-33.268 33.983-44.298 49.41-52.538 63.143z'
        fill='#fff'
      />
      <path
        d='M336.215 49.55c-17.982 11.323-33.499 48.404-33.499 48.404 22.655 0 43.483 8.356 51.066 12.534 0 0-21.563-36.295-17.567-60.937z'
        fill='#F34747'
      />
      <path
        d='M323.16 216.795c-12.702 0-20.713-4.313-23.13-6.469 0 4.231-4.901 7.841-4.901 11.37 0 3.528 1.706 8.75 8.567 8.75 14.271 0 18.942-9.273 19.464-13.651zM291.244 332.65c14.884 0 29.974-23.772 35.658-35.658-6.072 16.15-17.521 46.22-26.859 53.86-8.527 6.976-5.709 32.573-36.705 32.573v-59.301c13.178 0 9.302 8.526 27.906 8.526z'
        fill='#000'
      />
      <mask id='dog_svg__a' maskUnits='userSpaceOnUse' x={263} y={296} width={64} height={88}>
        <path
          d='M291.244 332.65c14.884 0 29.974-23.772 35.658-35.658-6.072 16.15-17.521 46.22-26.859 53.86-8.527 6.976-5.709 32.573-36.705 32.573v-59.301c13.178 0 9.302 8.526 27.906 8.526z'
          fill='#000'
        />
      </mask>
      <g mask='url(#dog_svg__a)'>
        <path
          d='M263.338 376.239c21.314 0 25.585-29.764 25.056-44.647 0-7.505-16.704-8.519-25.056-8.088v52.735z'
          fill='#F34747'
        />
      </g>
      <path
        d='M287.867 286.9c0 14.271-16.353 22.299-24.529 24.529v-47.275c6.244 0 24.529 4.906 24.529 22.746z'
        fill='#000'
      />
      <path
        d='M232.44 87.419c8.239 13.733 24.032 17.929 30.899 18.31v415.207s-20.682-59.996-71.754-87.567c-37.885-20.452-73.903-2.48-52.282 32.899-22.798-13.901-39.362-36.584-47.915-57.821-8.553-21.238-3.688-60.239-3.688-60.239-15.46-33.978-47.523 47.059-47.523 47.059 6.34-82.095 60.061-134.931 60.061-155.914 0-20.982-12.538-22.294-38.861 24.238-6.95-49.638 138.557-167.772 118.525-239.315 33.267 33.983 44.298 49.41 52.538 63.143z'
        fill='#fff'
      />
      <path
        d='M190.461 49.55c17.982 11.323 33.499 48.404 33.499 48.404-22.655 0-43.483 8.356-51.066 12.534 0 0 21.563-36.295 17.567-60.937z'
        fill='#F34747'
      />
      <path
        d='M203.524 216.795c12.703 0 20.713-4.313 23.131-6.469 0 4.231 4.901 7.841 4.901 11.37-.001 3.528-1.706 8.75-8.567 8.75-14.271 0-18.942-9.273-19.465-13.651zM235.431 332.65c-14.883 0-29.973-23.772-35.658-35.658 6.073 16.15 17.522 46.22 26.859 53.86 8.527 6.976 5.71 32.573 36.706 32.573v-59.301c-13.178 0-9.302 8.526-27.907 8.526z'
        fill='#000'
      />
      <mask id='dog_svg__b' maskUnits='userSpaceOnUse' x={199} y={296} width={65} height={88}>
        <path
          d='M235.431 332.65c-14.883 0-29.973-23.772-35.658-35.658 6.073 16.15 17.522 46.22 26.859 53.86 8.527 6.976 5.71 32.573 36.706 32.573v-59.301c-13.178 0-9.302 8.526-27.907 8.526z'
          fill='#000'
        />
      </mask>
      <g mask='url(#dog_svg__b)'>
        <path
          d='M263.338 376.239c-21.314 0-25.585-29.764-25.056-44.647 0-7.505 16.704-8.519 25.056-8.088v52.735z'
          fill='#F34747'
        />
      </g>
      <path
        d='M238.808 286.9c0 14.271 16.353 22.299 24.53 24.529v-47.275c-6.244 0-24.53 4.906-24.53 22.746z'
        fill='#000'
      />
      <path
        d='M256.528 288.131h-4.567v4.68h-2.832v-11.749h7.859v2.187h-5.027v2.703h4.567v2.179zM261.184 292.811h-2.825v-11.749h2.825v11.749zM272.964 292.811h-2.816l-4.164-7.311v7.311h-2.832v-11.749h2.832l4.156 7.311v-7.311h2.824v11.749zM277.329 289.091h-2.203l-.347-8.029h2.897l-.347 8.029zm-2.63 2.501c0-.398.142-.723.427-.976.291-.258.651-.387 1.081-.387.431 0 .788.129 1.074.387.29.253.435.578.435.976s-.145.726-.435.985c-.286.252-.643.379-1.074.379-.43 0-.79-.127-1.081-.379a1.272 1.272 0 01-.427-.985z'
        fill='#fff'
      />
      <defs>
        <filter
          id='dog_svg__filter0_d'
          x={0.177}
          y={0.275}
          width={526.324}
          height={576.66}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={20} />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default Dog
