import { SVGProps } from 'react'
function InstructionsFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='29'
      height='24'
      viewBox='0 0 29 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.356 20C11.1506 20.0033 11.9117 20.3204 12.4736 20.8823C13.0355 21.4442 13.3527 22.2054 13.356 23C13.356 23.2652 13.4613 23.5196 13.6489 23.7071C13.8364 23.8946 14.0907 24 14.356 24C14.6212 24 14.8755 23.8946 15.0631 23.7071C15.2506 23.5196 15.356 23.2652 15.356 23C15.3592 22.2054 15.6764 21.4442 16.2383 20.8823C16.8002 20.3204 17.5613 20.0033 18.356 20H26.356C26.8864 20 27.3951 19.7893 27.7702 19.4142C28.1452 19.0391 28.356 18.5304 28.356 18V2C28.356 1.46957 28.1452 0.960859 27.7702 0.585786C27.3951 0.210714 26.8864 0 26.356 0H20.356C19.0299 0 17.7581 0.526784 16.8204 1.46447C15.8827 2.40215 15.356 3.67392 15.356 5V15C15.356 15.2652 15.2506 15.5196 15.0631 15.7071C14.8755 15.8946 14.6212 16 14.356 16C14.0907 16 13.8364 15.8946 13.6489 15.7071C13.4613 15.5196 13.356 15.2652 13.356 15V5C13.356 3.67392 12.8292 2.40215 11.8915 1.46447C10.9538 0.526784 9.68204 0 8.35596 0H2.35596C1.82552 0 1.31682 0.210714 0.941743 0.585786C0.566671 0.960859 0.355957 1.46957 0.355957 2V18C0.355957 18.5304 0.566671 19.0391 0.941743 19.4142C1.31682 19.7893 1.82552 20 2.35596 20H10.356Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InstructionsFilled
