import { SVGProps } from 'react'
function InputSheet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M46.2858 0H27.5195C26.5728 0 25.8053 0.767569 25.8053 1.7143V9.38315H1.71422C0.767531 9.38305 0 10.1506 0 11.0974V46.2857C0 47.2324 0.767531 48 1.71422 48H36.9021C37.8488 48 38.6163 47.2324 38.6163 46.2857V22.1957H46.2858C47.2325 22.1957 48 21.4282 48 20.4814V1.71421C48 0.767475 47.2325 0 46.2858 0ZM3.42844 44.5715V12.8116H25.8053V19.7712L16.9396 28.6373L15.5351 27.2328C14.6028 26.3002 13.0082 26.7292 12.6671 28.0012L10.7517 35.1501C10.4152 36.4062 11.5623 37.5949 12.8512 37.2496L19.9997 35.3341C21.2737 34.9929 21.6994 33.3971 20.7682 32.4661L19.3638 31.0616L28.2292 22.1957H35.1877V44.5716L3.42844 44.5715ZM35.1884 18.7672H29.2337V12.8121H35.1884V18.7672ZM35.1884 9.38352H29.2337V3.42851H35.1884V9.38352ZM44.5715 18.7672H38.6167V12.8121H44.5715V18.7672ZM44.5716 9.38352H38.6168V3.42851H44.5716V9.38352Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InputSheet
