import { SVGProps } from 'react'

function GoogleMonoColor(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M21.8836 10.1365H12.2059V14.1142H17.7345C17.4961 15.3999 16.7729 16.4874 15.6827 17.216C14.7613 17.8321 13.5854 18.1963 12.2032 18.1963C9.52734 18.1963 7.26395 16.3883 6.45502 13.9588C6.25145 13.3428 6.13359 12.6838 6.13359 12.0062C6.13359 11.3285 6.25145 10.6696 6.45502 10.0535C7.26663 7.6267 9.53002 5.81866 12.2059 5.81866C13.714 5.81866 15.0666 6.33831 16.1327 7.35616L19.0791 4.40706C17.2979 2.74634 14.9756 1.72849 12.2059 1.72849C8.19074 1.72849 4.71663 4.03206 3.02645 7.39099C2.33002 8.77849 1.93359 10.3481 1.93359 12.0088C1.93359 13.6696 2.33002 15.2365 3.02645 16.624C4.71663 19.983 8.19074 22.2865 12.2059 22.2865C14.9809 22.2865 17.3059 21.3651 19.0041 19.7955C20.9461 18.0062 22.0684 15.3705 22.0684 12.2392C22.0684 11.5106 22.0041 10.8115 21.8836 10.1365Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GoogleMonoColor
