import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { IDataFilter, TFilterValue } from '@invisible/common/types'
import { useQuery } from '@invisible/trpc/client'
import { FILTER_OPERATOR, FILTER_TYPE } from '@invisible/ui/constants'
import { Dropdown, IOption } from '@invisible/ui/dropdown'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

type TBases = TProcessWithBases['bases']

interface IProps {
  bases: NonNullable<TBases>
  defaultReportBaseId?: string | null
  onChange: (baseSaticFilter: IDataFilter<TFilterValue>) => void
}

export const BaseFilter = ({ bases, defaultReportBaseId, onChange }: IProps) => {
  const router = useRouter()
  const [baseOptions, setBaseOptions] = useState<IOption[]>([])
  const [selectedKey, setSelectedKey] = useState<string | undefined>()

  const { data: activePricingConfigs } = useQuery(
    [
      'pricingConfig.findByProcessId',
      { processId: router.query['id'] as string, onlyActive: true },
    ],
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  // Initialize and update selectedKey and baseOptions state
  useEffect(() => {
    const baseFromActivePricingConfig = activePricingConfigs?.[0]?.base

    let baseIdToBaseOptionMap = baseFromActivePricingConfig
      ? {
          [baseFromActivePricingConfig.id]: {
            key: baseFromActivePricingConfig.id,
            value: baseFromActivePricingConfig.name,
          },
        }
      : {}

    baseIdToBaseOptionMap = bases.reduce((map, base) => {
      if (!map[base.id]) {
        const baseOption = { key: base.id, value: base.name }
        map[base.id] = baseOption
      }
      return map
    }, baseIdToBaseOptionMap)

    const newBaseOptions = Object.values(baseIdToBaseOptionMap)

    const isEveryBaseOptionInMap = baseOptions.every((option) => baseIdToBaseOptionMap[option.key])
    const isSameNumberOfOptions = baseOptions.length === newBaseOptions.length

    if (!isSameNumberOfOptions || !isEveryBaseOptionInMap) {
      setBaseOptions(newBaseOptions)
    }

    // Check if selected key needs to be initialized or updated. if it does,
    // then consequently onChange should be called
    const updatedKey =
      baseIdToBaseOptionMap[selectedKey ?? '']?.key ??
      defaultReportBaseId ??
      baseFromActivePricingConfig?.id ??
      newBaseOptions[0]?.key

    if (updatedKey && updatedKey !== selectedKey) {
      setSelectedKey(updatedKey)
      const updatedBaseFilter = {
        name: FILTER_TYPE.BASE,
        operator: FILTER_OPERATOR.in,
        key: updatedKey,
        value: [updatedKey],
        valueType: 'String',
      }
      onChange(updatedBaseFilter)
    }
  }, [bases, defaultReportBaseId, activePricingConfigs?.[0]?.base])

  const handleBaseChange = (option: { key: string; value: string | number | string[] }) => {
    const updatedBaseFilter = {
      name: FILTER_TYPE.BASE,
      operator: FILTER_OPERATOR.in,
      key: option.key,
      value: [option.key],
      valueType: 'String',
    }
    setSelectedKey(option.key)
    onChange(updatedBaseFilter)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant='body1' sx={{ my: 1, fontWeight: 'bold' }}>
          Base
        </Typography>
      </ThemeProvider>
      <Dropdown
        name={FILTER_TYPE.BASE}
        placeholder='Select a base'
        options={baseOptions}
        selectedKey={selectedKey}
        onChange={handleBaseChange}
      />
    </div>
  )
}
