import { SVGProps } from 'react'
function ExportOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='21'
      viewBox='0 0 27 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.390537 0.795229C0.76373 0.38811 1.3963 0.360607 1.80342 0.733799L13.1277 11.1144L24.452 0.733799C24.8591 0.360607 25.4917 0.38811 25.8648 0.795229C26.238 1.20235 26.2105 1.83491 25.8034 2.20811L13.8034 13.2081C13.4211 13.5586 12.8343 13.5586 12.452 13.2081L0.451967 2.20811C0.0448478 1.83491 0.0173449 1.20235 0.390537 0.795229Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.127692 1.47095C0.127692 0.918668 0.575407 0.470953 1.12769 0.470953H25.1277C25.68 0.470953 26.1277 0.918668 26.1277 1.47095V18.471C26.1277 19.0014 25.917 19.5101 25.5419 19.8852C25.1668 20.2602 24.6581 20.471 24.1277 20.471H2.12769C1.59726 20.471 1.08855 20.2602 0.713478 19.8852C0.338405 19.5101 0.127692 19.0014 0.127692 18.471V1.47095ZM2.12769 2.47095V18.471H24.1277V2.47095H2.12769Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6772 9.79505C12.0505 10.2021 12.0231 10.8347 11.6161 11.2079L2.11609 19.9204C1.70906 20.2937 1.07649 20.2664 0.703198 19.8594C0.329909 19.4523 0.357261 18.8197 0.764291 18.4465L10.2643 9.73396C10.6713 9.36067 11.3039 9.38802 11.6772 9.79505Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5782 9.79505C14.9515 9.38802 15.5841 9.36067 15.9911 9.73396L25.4911 18.4465C25.8981 18.8197 25.9255 19.4523 25.5522 19.8594C25.1789 20.2664 24.5463 20.2937 24.1393 19.9204L14.6393 11.2079C14.2323 10.8347 14.2049 10.2021 14.5782 9.79505Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ExportOutline
