import { SVGProps } from 'react'
function InstructionsOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='29'
      height='24'
      viewBox='0 0 29 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.35596 0C1.82552 0 1.31682 0.210714 0.941743 0.585787C0.566671 0.960859 0.355957 1.46957 0.355957 2V18C0.355957 18.5304 0.56667 19.0391 0.941743 19.4142C1.31682 19.7893 1.82553 20 2.35596 20H10.356C11.1516 20 11.9147 20.3161 12.4773 20.8787C13.0399 21.4413 13.356 22.2044 13.356 23C13.356 23.5523 13.8037 24 14.356 24C14.9082 24 15.356 23.5523 15.356 23C15.356 22.2044 15.672 21.4413 16.2346 20.8787C16.7972 20.3161 17.5603 20 18.356 20H26.356C26.8864 20 27.3951 19.7893 27.7702 19.4142C28.1452 19.0391 28.356 18.5304 28.356 18V2C28.356 1.46957 28.1452 0.960861 27.7702 0.585787C27.3951 0.210714 26.8864 0 26.356 0H18.356C17.0299 0 15.7581 0.526784 14.8204 1.46447C14.6521 1.63275 14.4971 1.81178 14.356 1.99997C14.2148 1.81178 14.0598 1.63275 13.8915 1.46447C12.9538 0.526784 11.682 0 10.356 0H2.35596ZM15.356 5V19C16.2164 18.3547 17.2679 18 18.356 18H26.356V2L18.356 2C17.5603 2 16.7972 2.31607 16.2346 2.87868C15.672 3.44129 15.356 4.20435 15.356 5ZM13.356 5C13.356 4.20435 13.0399 3.44129 12.4773 2.87868C11.9147 2.31607 11.1516 2 10.356 2L2.35596 2V18H10.356C11.444 18 12.4956 18.3547 13.356 19V5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InstructionsOutline
