import { useAbilityContext } from '@invisible/authorization/client'
import { Backdrop, WizardContainer } from '@invisible/common/components/wizard/wizard-container'
import { useStore } from '@invisible/common/stores/process-store'
import {
  IStepRunStatusEnum,
  toGlobalId,
  useStepRunsByBaseRunIdQuery,
} from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { theme } from '@invisible/ui/mui-theme-v2'
import { createColumnHelper, Table } from '@invisible/ui/table'
import { Prisma, VariableType } from '@invisible/ultron/prisma'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { useRouter } from 'next/router'
import { ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { Portal } from 'react-portal'
import { ReactFlowProvider } from 'reactflow'
import shallow from 'zustand/shallow'

import { BaseRunVariableValue } from '../common/BaseRunVariableValue'
import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import { CancelButton } from '../customCells/CancelButton'
import { TBaseRunQueryData, useGetBaseRuns } from '../hooks/useGetBaseRuns'
import { BaseRunGraph } from './BaseRunGraph'
import { BaseRunUpdates } from './BaseRunUpdates'
import { DeleteButton } from './DeleteButton'
import { InterfaceReadOnlyLink } from './InterfaceReadOnlyLink'

interface IBaseRunDetailsProps {
  baseRunId: string
  children: ReactNode
}

type IBaseRunVariableCellData = {
  accessor: string
  type: VariableType
  name: string
  baseVariable: { type: VariableType; name: string; options: string[]; id: string }
  value: Prisma.JsonValue
  id: string
}

type TabName = 'Details' | 'Flow' | 'Interfaces' | 'Updates'

const TabStyle = {
  '&:not(.Mui-selected)': {
    fontWeight: 500,
  },
}

const baseRunDetailstheme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: '#000000',
    },
  },
})

export const BaseRunDetails = ({ baseRunId, children }: IBaseRunDetailsProps) => {
  const columnHelper = createColumnHelper<IBaseRunVariableCellData>()
  const columns = [
    columnHelper.display({
      meta: { width: '20%', position: 'relative' },
      id: 'label',
      cell: (props) => {
        const baseVariableName = startCase(props.row.original?.name ?? '')
        return (
          <Tooltip title={baseVariableName} arrow placement='bottom-end'>
            <Typography
              variant='body1'
              sx={{
                width: '180px',
                textAlign: 'right',
                paddingLeft: '20px',
                fontWeight: 'bold',
              }}>
              {baseVariableName} :
            </Typography>
          </Tooltip>
        )
      },
    }),
    columnHelper.display({
      id: 'content',
      cell: (props) => (
        <div className=' py-5'>
          <BaseRunVariableValue value={props.row.original?.value} type={props.row.original?.type} />
        </div>
      ),
    }),
  ]

  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage, columnOrder } = useStore(
    useCallback(
      (state) => ({
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        currentPage: state.currentPage,
        columnOrder: state.columnOrder,
      }),
      []
    ),
    shallow
  )
  const { query } = useRouter()
  const [Can] = useAbilityContext()

  const sortByColumnOrder = (data: IBaseRunVariableCellData[]) =>
    data.sort((a, b) => {
      const getIndex = (name: string) =>
        ((columnOrder ?? []) as string[])?.indexOf(name) < 0
          ? 1000
          : ((columnOrder ?? []) as string[])?.indexOf(name)

      return getIndex(a.accessor) - getIndex(b.accessor)
    })

  const { data: processData } = useQuery(['process.findByIdWithBases', { id: query.id as string }])

  const { data: baseRunsData } = useGetBaseRuns(
    {
      baseId,
      baseViewId,
      take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      filters: filterOption ?? [],
      sort: sortOption ?? {},
      search: searchTerm ?? '',
      page: currentPage ?? 1,
    },
    {}
  )

  const [selectedTab, setSelectedTab] = useState<TabName>('Details')
  const [isBaseRunDetailsPanelOpen, setIsBaseRunDetailsPanelOpen] = useState(false)

  const { data: baseRunGetHistory, isLoading: isGetHistoryLoading } = useQuery(
    ['baseRun.getHistory', { id: baseRunId }],
    {
      enabled: isBaseRunDetailsPanelOpen,
      refetchOnMount: 'always',
    }
  )
  const totalUpdates =
    (baseRunGetHistory?.baseRun?.length || 0) +
    (baseRunGetHistory?.baseRunVariables?.length || 0) +
    (baseRunGetHistory?.stepRuns?.length || 0)

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: TabName) => {
    setSelectedTab(newValue)
    refetchStepRunsData()
  }

  const { data: stepRunsData, refetch: refetchStepRunsData } = useStepRunsByBaseRunIdQuery(
    {
      baseRunId: toGlobalId('BaseRunType', baseRunId),
      statuses: [IStepRunStatusEnum.Running, IStepRunStatusEnum.Done],
    },
    {
      enabled: isBaseRunDetailsPanelOpen,
      refetchOnMount: 'always',
    }
  )
  const manualStepRuns =
    stepRunsData?.stepRunsV2.edges.filter(
      ({ node }) => node.stepTemplate.type === 'manual' && node.step.name !== 'Manual Trigger'
    ) ?? []

  const { data: baseRunDetailsData } = useQuery(
    [
      'baseRun.getBaseRunDetails',
      {
        id: baseRunId,
      },
    ],
    {
      enabled: isBaseRunDetailsPanelOpen,
    }
  )

  const baseRunVariablesWithChildrenCount = useMemo(() => {
    if (!baseRunDetailsData || !baseRunsData || !processData) return undefined
    const currentBaseRun = (baseRunsData as TBaseRunQueryData).items.find(
      (br) => br.id === baseRunId
    )
    if (!currentBaseRun) return undefined

    const formattedChildCount = Object.keys(currentBaseRun.childCounts).map((id: string) => ({
      id,
      accessor: id,
      name: processData.bases.find((base) => base.id === id)?.name ?? '',
      type: 'childBaseRuns' as const,
      value: currentBaseRun.childCounts[id],
    }))

    const formattedBaseRunVariables =
      baseRunDetailsData.baseRunVariables?.map((brv) => ({
        ...brv,
        accessor: brv.baseVariable.id,
        type: brv.baseVariable.type,
        name: brv.baseVariable.name,
      })) ?? []

    const sortedBaseRunVariables = sortByColumnOrder(formattedBaseRunVariables)

    return [
      {
        value: baseRunDetailsData.id || '',
        accessor: baseRunDetailsData.id,
        type: 'ID',
        name: 'id',
      },
      {
        value: baseRunDetailsData.deadline
          ? format(baseRunDetailsData.deadline, 'yyyy-MM-dd HH:mm aa')
          : '',
        accessor: baseRunDetailsData.deadline,
        type: 'string',
        name: 'deadline',
      },
      {
        value: baseRunDetailsData.createdAt
          ? format(baseRunDetailsData.createdAt, 'yyyy-MM-dd HH:mm aa')
          : '',
        accessor: baseRunDetailsData.createdAt,
        type: 'string',
        name: 'createdAt',
      },
      ...sortedBaseRunVariables,
      ...formattedChildCount,
    ] as IBaseRunVariableCellData[]
  }, [baseRunDetailsData, baseRunsData, processData, baseRunId])

  return (
    <ThemeProvider theme={baseRunDetailstheme}>
      <div>
        <div className='flex items-center justify-items-center gap-2'>
          {children}
          <Tooltip title='Base Run Information' arrow placement='bottom-end'>
            <IconButton onClick={() => setIsBaseRunDetailsPanelOpen(true)}>
              <InfoOutlinedIcon
                data-testid='BaseRunDetailsTrigger'
                fontSize='medium'
                className='pointer-events-auto opacity-20 hover:opacity-80'
              />
            </IconButton>
          </Tooltip>
        </div>

        {isBaseRunDetailsPanelOpen ? (
          <Portal>
            <Backdrop>
              <WizardContainer>
                <div className='bg-theme-grey box-border flex w-full shrink-0 items-center justify-between px-8 py-5'>
                  <Typography
                    variant='h6'
                    sx={{
                      textAlign: 'left',
                    }}>
                    BASE RUN INFORMATION
                  </Typography>
                  <div className='flex justify-end'>
                    <CloseIcon
                      onClick={() => setIsBaseRunDetailsPanelOpen(false)}
                      sx={{ cursor: 'pointer', color: grey[600] }}
                    />
                  </div>
                </div>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label='base run details tabs'>
                      <Tab
                        label='Details'
                        value='Details'
                        sx={{ ...TabStyle, marginLeft: '50px' }}
                      />
                      <Tab label='Flow' value='Flow' sx={TabStyle} />
                      <Tab
                        label={`Interfaces (${manualStepRuns.length})`}
                        value='Interfaces'
                        sx={TabStyle}
                      />
                      <Tab label={`Updates (${totalUpdates})`} value='Updates' sx={TabStyle} />
                    </TabList>
                  </Box>
                  <TabPanel value='Details'>
                    <div>
                      {!baseRunVariablesWithChildrenCount || !baseRunDetailsData ? (
                        <div className='flex w-full items-center justify-center pt-20'>
                          <LogoSpinner width={40} />
                        </div>
                      ) : (
                        <>
                          <Table.PaginationProvider>
                            <Table
                              defaultPageSize={10}
                              data={baseRunVariablesWithChildrenCount}
                              columns={columns}
                            />
                            <div className='my-4 flex items-center justify-end'>
                              <Table.Pagination />
                            </div>
                          </Table.PaginationProvider>

                          <Stack direction='row' spacing={2}>
                            <Can I='cancel' a='BaseRun'>
                              <CancelButton
                                baseRunId={baseRunDetailsData.id}
                                status={baseRunDetailsData.status}
                                onCancelDone={() => setIsBaseRunDetailsPanelOpen(false)}
                              />
                            </Can>
                            <Can I='delete' a='BaseRun'>
                              <DeleteButton
                                baseRunId={baseRunDetailsData.id}
                                onDeleteDone={() => setIsBaseRunDetailsPanelOpen(false)}
                              />
                            </Can>
                          </Stack>
                        </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value='Flow'>
                    <ReactFlowProvider>
                      <BaseRunGraph baseRunId={baseRunId} />
                    </ReactFlowProvider>
                  </TabPanel>
                  <TabPanel value='Interfaces'>
                    <ol className='w-2/12'>
                      {manualStepRuns.map(({ node }) => (
                        <InterfaceReadOnlyLink id={node.id} step={node.step} />
                      ))}
                    </ol>
                  </TabPanel>
                  <TabPanel value='Updates'>
                    <BaseRunUpdates data={baseRunGetHistory} isLoading={isGetHistoryLoading} />
                  </TabPanel>
                </TabContext>
              </WizardContainer>
            </Backdrop>
          </Portal>
        ) : null}
      </div>
    </ThemeProvider>
  )
}
