import { SVGProps } from 'react'

function Billing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7895 1.40351C17.7895 0.628372 17.1611 0 16.386 0C15.6108 0 14.9825 0.628372 14.9825 1.40351V3.36842H13.5789C11.7178 3.36842 9.93284 4.10777 8.61679 5.42381C7.30075 6.73986 6.5614 8.5248 6.5614 10.386C6.5614 12.2471 7.30075 14.0321 8.61679 15.3481C9.93284 16.6642 11.7178 17.4035 13.5789 17.4035H14.9825V25.8246H13.0175C11.9008 25.8246 10.8299 25.381 10.0403 24.5913C9.25063 23.8017 8.80702 22.7307 8.80702 21.614C8.80702 20.8389 8.17865 20.2105 7.40351 20.2105C6.62837 20.2105 6 20.8389 6 21.614C6 23.4752 6.73935 25.2601 8.05539 26.5762C9.37144 27.8922 11.1564 28.6316 13.0175 28.6316H14.9825V30.5965C14.9825 31.3716 15.6108 32 16.386 32C17.1611 32 17.7895 31.3716 17.7895 30.5965V28.6316H19.7544C21.6156 28.6316 23.4005 27.8922 24.7165 26.5762C26.0326 25.2601 26.7719 23.4752 26.7719 21.614C26.7719 19.7529 26.0326 17.9679 24.7165 16.6519C23.4005 15.3358 21.6156 14.5965 19.7544 14.5965H17.7895V6.17544H18.6316C19.1845 6.17544 19.732 6.28435 20.2429 6.49595C20.7537 6.70754 21.2179 7.01769 21.6089 7.40867C21.9999 7.79966 22.31 8.26382 22.5216 8.77467C22.7332 9.28551 22.8421 9.83303 22.8421 10.386C22.8421 11.1611 23.4705 11.7895 24.2456 11.7895C25.0208 11.7895 25.6491 11.1611 25.6491 10.386C25.6491 9.46441 25.4676 8.55188 25.1149 7.70047C24.7623 6.84906 24.2454 6.07545 23.5937 5.42381C22.9421 4.77217 22.1685 4.25526 21.3171 3.9026C20.4657 3.54994 19.5531 3.36842 18.6316 3.36842H17.7895V1.40351ZM14.9825 6.17544H13.5789C12.4622 6.17544 11.3913 6.61905 10.6017 7.40867C9.81203 8.1983 9.36842 9.26926 9.36842 10.386C9.36842 11.5027 9.81203 12.5736 10.6017 13.3633C11.3913 14.1529 12.4622 14.5965 13.5789 14.5965H14.9825V6.17544ZM17.7895 17.4035V25.8246H19.7544C20.8711 25.8246 21.9421 25.381 22.7317 24.5913C23.5213 23.8017 23.9649 22.7307 23.9649 21.614C23.9649 20.4973 23.5213 19.4264 22.7317 18.6367C21.9421 17.8471 20.8711 17.4035 19.7544 17.4035H17.7895Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Billing
