import type { TProcessByIdStep, TProcessStepGoTo } from '@invisible/common/components/process-base'
import { AttendedMapStepMeta } from '@invisible/ultron/zod'
import ELK, { ElkNode, ElkPrimitiveEdge } from 'elkjs'
import { compact } from 'lodash/fp'
import { CoordinateExtent, Edge, Node } from 'reactflow'

import { _EDGE_TYPES, _NODE_TYPES, NODE_TYPES } from '../constants'

type TNodeType = typeof _NODE_TYPES[number]
type TEdgeType = typeof _EDGE_TYPES[number]

const NODE_WIDTH = 500
const NODE_HEIGHT = 64

const elk = new ELK()

export interface ExtendedElkNode extends ElkNode {
  data: { step: TProcessByIdStep }
  selectable?: boolean
  draggable?: boolean
  connectable?: boolean
  type: TNodeType
  extent?: 'parent' | CoordinateExtent
  parentNode?: string
  selected?: boolean
}

export interface ExtendedElkEdge extends ElkPrimitiveEdge {
  data: { stepGoTo: TProcessStepGoTo }
  type: TEdgeType
}

const createGraphLayoutElk = async (
  flowNodes: Node<{ step: TProcessByIdStep; type: TNodeType }>[],
  flowEdges: Edge<{
    stepGoTo: TProcessStepGoTo
    type: TEdgeType
    isTriggerEdge: boolean
    label: string
  }>[],
  graphHorizontalWidth: number,
  showStages: boolean,
  layout: 'horizontal' | 'vertical'
) => {
  const layoutOptions = {
    'spacing.nodeNodeBetweenLayers': '200',
    'elk.direction': layout === 'horizontal' ? 'RIGHT' : 'DOWN',
    'org.eclipse.elk.algorithm': 'org.eclipse.elk.layered',
    'org.eclipse.elk.spacing.nodeNode': '200',
    'org.eclipse.elk.spacing.edgeNode': '200',
    'elk.edgeRouting': 'POLYLINE',
    'org.eclipse.elk.layered.nodePlacement.favorStraightEdges': 'false',
    'org.eclipse.elk.layered.nodePlacement.strategy': 'SIMPLE',
    'org.eclipse.elk.layered.layering.strategy':
      layout === 'horizontal' ? 'NETWORK_SIMPLEX' : 'LONGEST_PATH',
    'org.eclipse.elk.hierarchyHandling': 'INCLUDE_CHILDREN',
    'org.eclipse.elk.layered.mergeEdges': 'true',
    'org.eclipse.elk.layered.allowNonFlowPortsToSwitchSides': 'true',
    'org.eclipse.elk.hypernode': 'true',
  }

  const elkNodes: ExtendedElkNode[] = []
  const elkEdges: ExtendedElkEdge[] = []

  const processedNodes: string[] = []
  const shadowSteps = compact(
    flowNodes
      .filter((n) => n.type === NODE_TYPES.ATTENDED_MAP)
      .map((s) => (s?.data.step.meta as AttendedMapStepMeta.TSchema)?.shadowStepId)
  )

  flowNodes.forEach((node) => {
    if (!showStages) {
      elkNodes.push({
        id: node.id,
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        type: node.data.type,
        selectable: node.selectable,
        draggable: node.draggable,
        connectable: node.connectable,
        data: node.data,
        parentNode: node.parentNode,
        layoutOptions: {
          ...layoutOptions,
          ...(shadowSteps.includes(node.id) ? { 'org.eclipse.elk.noLayout': 'true' } : {}),
        },
        selected: node.selected,
      })
      return
    }

    if (processedNodes.includes(node.id)) return
    const nodeChildren = flowNodes.filter((n) => n.parentNode === node.id)
    if (node.data.type === NODE_TYPES.STAGE && nodeChildren.length === 0) return
    elkNodes.push({
      id: node.id,
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
      type: node.data.type,
      selectable: node.selectable,
      draggable: node.draggable,
      connectable: node.connectable,
      data: node.data,
      extent: node.extent,
      parentNode: node.parentNode,
      layoutOptions: {
        ...layoutOptions,
        ...(shadowSteps.includes(node.id) ? { 'org.eclipse.elk.noLayout': 'true' } : {}),
      },
      selected: node.selected,
      children: nodeChildren.map((child) => ({
        id: child.id,
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        type: child.data.type,
        selectable: child.selectable,
        draggable: child.draggable,
        connectable: child.connectable,
        data: child.data,
        parentNode: shadowSteps.includes(child.id) ? undefined : child.parentNode,
        selected: child.selected,
        extent: child.extent,
        layoutOptions: {
          ...(shadowSteps.includes(child.id)
            ? {
                'org.eclipse.elk.noLayout': 'true',
              }
            : {}),
        },
      })),
    })
    processedNodes.push(node.id)
    nodeChildren.forEach((child) => processedNodes.push(child.id))
  })

  flowEdges.forEach((edge) => {
    if (!edge.data) return
    elkEdges.push({
      id: edge.id,
      source: edge.source,
      target: edge.target,
      type: edge.data.type,
      data: edge.data,
    })
  })

  const newGraph = await elk.layout({
    id: 'root',
    layoutOptions,
    children: elkNodes,
    edges: elkEdges,
  })

  const data = {
    edges: newGraph.edges,
    nodes: newGraph.children?.reduce<Node[]>((acc, n) => {
      const updatedNode = {
        ...n,
        position: {
          x: n.x as number,
          y: n.y as number,
        },
        data: {
          ...(n as ExtendedElkNode).data,
          height: n.height as number,
          width: n.width as number,
        },
      }
      acc.push(updatedNode)

      n.children?.forEach((child) => {
        acc.push({
          ...child,
          data: { ...(child as ExtendedElkNode).data },
          position: {
            x: child.x as number,
            y: child.y as number,
          },
        })
      })

      return acc
    }, []),
  } as unknown as { edges: Edge[]; nodes: Node[] }

  return data
}

export { createGraphLayoutElk }
