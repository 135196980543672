import { SVGProps } from 'react'
function DumbellOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='20'
      viewBox='0 0 32 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 2C6 0.89543 6.89543 0 8 0H11C12.1046 0 13 0.89543 13 2V18C13 19.1046 12.1046 20 11 20H8C6.89543 20 6 19.1046 6 18V2ZM11 2H8V18H11V2Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 2C19 0.89543 19.8954 0 21 0H24C25.1046 0 26 0.89543 26 2V18C26 19.1046 25.1046 20 24 20H21C19.8954 20 19 19.1046 19 18V2ZM24 2H21V18H24V2Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 4C24 3.44772 24.4477 3 25 3H28C28.5304 3 29.0391 3.21071 29.4142 3.58579C29.7893 3.96086 30 4.46957 30 5V15C30 15.5304 29.7893 16.0391 29.4142 16.4142C29.0391 16.7893 28.5304 17 28 17H25C24.4477 17 24 16.5523 24 16C24 15.4477 24.4477 15 25 15H28V5H25C24.4477 5 24 4.55228 24 4Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H7C7.55228 3 8 3.44772 8 4C8 4.55228 7.55228 5 7 5H4V15H7C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V5C2 4.46957 2.21071 3.96086 2.58579 3.58579Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 10C11 9.44771 11.4477 9 12 9H20C20.5523 9 21 9.44771 21 10C21 10.5523 20.5523 11 20 11H12C11.4477 11 11 10.5523 11 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28 10C28 9.44771 28.4477 9 29 9H31C31.5523 9 32 9.44771 32 10C32 10.5523 31.5523 11 31 11H29C28.4477 11 28 10.5523 28 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 10C0 9.44771 0.447715 9 1 9H3C3.55228 9 4 9.44771 4 10C4 10.5523 3.55228 11 3 11H1C0.447715 11 0 10.5523 0 10Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DumbellOutlined
