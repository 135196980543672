export const CHILL_THEME = [
  '#EFEDF5',
  '#DFDBED',
  '#CFC9E4',
  '#A094C9',
  '#8070B7',
  '#604CA5',
  '#4F3E87',
  '#352A5A',
  '#241C3D',
  '#130F1F',
  '#000000',
]

export const BRIGHT_THEME = [
  '#22315C',
  '#EB5C95',
  '#604CA5',
  '#F5AC71',
  '#01F3FD',
  '#EC2474',
  '#00D7D3',
  '#8F45FF',
  '#FF933C',
  '#ff002e',
]
