import { captureException } from '@sentry/nextjs'
import { NextApiResponse } from 'next'

import { MetaError } from './errors'

const handleError = (err: MetaError, res: NextApiResponse) => {
  if (err instanceof MetaError) {
    return res.status(err.status).send(err.message)
  }
  return res.status(500).send('INTERNAL_SERVER_ERROR: Unhandled error')
}

const sendErrorToSentry = (error: any) => {
  const originalError = error.data?.originalError || 'Unknown Error'

  // We don't want to send UserErrors & BusinessLogicErrors to Sentry
  if (originalError === 'UserError' || originalError === 'BusinessLogicError') {
    return
  }

  captureException(error)
}

export { handleError, sendErrorToSentry }
