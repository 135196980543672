import { SVGProps } from 'react'
function Robot(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.002 10.003a.666.666 0 100 1.333.666.666 0 000-1.333zm-4.666-.666a.667.667 0 00-.667.666v1.334a.667.667 0 101.333 0v-1.334a.667.667 0 00-.666-.666zm13.333 0a.667.667 0 00-.667.666v1.334a.667.667 0 101.334 0v-1.334a.666.666 0 00-.667-.666zM11.336 4.67H8.669v-.853a1.333 1.333 0 00.667-1.147 1.333 1.333 0 10-2.667 0 1.333 1.333 0 00.667 1.147v.853H4.669a2 2 0 00-2 2v6a2 2 0 002 2h6.667a2 2 0 002-2v-6a2 2 0 00-2-2zM9.149 6.003l-.333 1.334H7.189l-.333-1.334h2.293zm2.853 6.667a.667.667 0 01-.666.667H4.669a.667.667 0 01-.667-.667v-6a.667.667 0 01.667-.667h.813l.52 2.16a.667.667 0 00.667.507h2.667a.667.667 0 00.666-.507l.52-2.16h.814a.667.667 0 01.666.667v6zm-2-2.667a.666.666 0 100 1.333.666.666 0 000-1.333z'
        fill='currentColor'
        fillOpacity={0.85}
      />
    </svg>
  )
}

export default Robot
