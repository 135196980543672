import { copyToClipboard } from '@invisible/ui/helpers'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined'
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import ReplayIcon from '@mui/icons-material/Replay'
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import { RefObject, useState } from 'react'

import { FAST_FORWARD_BY, FAST_REWIND_BY, PLAYBACK_RATES } from './constants'
import { formatTime } from './helpers'
import { MenuList } from './MenuList'

type IProps = {
  videoRef: RefObject<HTMLVideoElement>
  duration: number
  toggleFullScreen: () => void
  seekVideo: (time: number) => void
}
export const VideoControls = ({ videoRef, duration, toggleFullScreen, seekVideo }: IProps) => {
  const [playbackRate, setPlaybackRate] = useState(videoRef.current?.playbackRate || 1)
  const [forwardBy, setForwardBy] = useState(1)
  const [backwardBy, setBackwardBy] = useState(-1)

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }

  const handlePlaybackRateChange = (rate: number) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = rate
      setPlaybackRate(rate)
    }
  }

  if (!videoRef.current) return null

  const currentTime = videoRef.current?.currentTime
  return (
    <div className='box-border flex w-full items-center justify-between bg-[#F9F9F9] px-1 py-1'>
      <span className='flex items-center gap-2'>
        <span className='text-gray-500 hover:cursor-pointer' onClick={() => togglePlay()}>
          {videoRef.current.paused || videoRef.current.ended ? <PlayArrowIcon /> : <PauseIcon />}
        </span>
        <span
          className='text-gray-500 hover:cursor-pointer'
          onClick={() => seekVideo(currentTime > 0 ? currentTime + backwardBy : 0)}>
          <FastRewindOutlinedIcon />
        </span>
        <span
          className='text-gray-500 hover:cursor-pointer'
          onClick={() => seekVideo(currentTime < duration ? currentTime + forwardBy : duration)}>
          <FastForwardOutlinedIcon />
        </span>
        <MenuList
          id='fast-rewind-by'
          menuItems={FAST_REWIND_BY.map((f) => ({
            label: f.key,
            value: f.value,
            handleClick: (time: number) => setBackwardBy(time),
          }))}>
          <span className='flex items-center text-gray-500 hover:cursor-pointer'>
            <ReplayIcon /> <span className='text-xs'>{backwardBy}s</span>{' '}
            <ArrowDropDownOutlinedIcon />
          </span>
        </MenuList>
        <MenuList
          id='fast-forward-by'
          menuItems={FAST_FORWARD_BY.map((f) => ({
            label: f.key,
            value: f.value,
            handleClick: (time: number) => setForwardBy(time),
          }))}>
          <span className='flex items-center text-gray-500 hover:cursor-pointer'>
            <ReplayIcon sx={{ transform: 'scale(-1,1)' }} />{' '}
            <span className='text-xs'>{forwardBy}s</span> <ArrowDropDownOutlinedIcon />
          </span>
        </MenuList>
      </span>

      <span
        className='col-span-2 m-4 font-semibold	text-gray-500 hover:cursor-pointer'
        onClick={() => copyToClipboard(`${formatTime(currentTime)}/${formatTime(duration)}`)}>
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>

      <div className='flex items-center gap-1'>
        {videoRef.current.loop ? (
          <span
            className='text-gray-500 hover:cursor-pointer'
            title='Stop Segnment Repeat'
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.loop = false
              }
            }}>
            <RepeatOneIcon />
          </span>
        ) : null}

        <MenuList
          id='fast-forward-by'
          menuItems={PLAYBACK_RATES.map((r) => ({
            label: r.key,
            value: r.value,
            handleClick: (rate) => handlePlaybackRateChange(rate),
          }))}>
          <span className='flex items-center text-gray-500 hover:cursor-pointer'>
            <SlowMotionVideoIcon /> <span className='text-xs'>{playbackRate}x</span>{' '}
            <ArrowDropDownOutlinedIcon />
          </span>
        </MenuList>
        <span className='text-gray-500 hover:cursor-pointer' onClick={() => toggleFullScreen()}>
          <FullscreenIcon />
        </span>
      </div>
    </div>
  )
}
