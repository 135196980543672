import { SVGProps } from 'react'
function BookFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.1377 1C7.37932 1 7.5752 1.19588 7.5752 1.4375V6.66797L8.83785 5.68591C8.99583 5.56303 9.21706 5.56303 9.37504 5.68591L10.6377 6.66797V1.4375C10.6377 1.19588 10.8336 1 11.0752 1C11.3168 1 11.5127 1.19588 11.5127 1.4375V1.87501V7.5625C11.5127 7.7296 11.4175 7.8821 11.2674 7.95551C11.1173 8.02893 10.9385 8.01043 10.8066 7.90784L9.10645 6.5855L7.40629 7.90784C7.2744 8.01043 7.09558 8.02893 6.94548 7.95551C6.79537 7.8821 6.7002 7.7296 6.7002 7.5625V1.87501V1.4375C6.7002 1.19588 6.89607 1 7.1377 1Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.33861 1.00001H13.2627C13.5043 1.00001 13.7002 1.19589 13.7002 1.43751V12.4701C13.7002 12.7117 13.5043 12.9076 13.2627 12.9076H4.33771L4.33578 12.9076C4.18957 12.907 4.04415 12.9367 3.90768 12.9958C3.77115 13.0548 3.64564 13.1423 3.53899 13.2544C3.43231 13.3665 3.34671 13.5009 3.28817 13.6503C3.23095 13.7963 3.20094 13.9532 3.20021 14.1121V14.125C3.20021 14.3663 3.00487 14.562 2.76358 14.5625C2.5223 14.563 2.32618 14.368 2.32521 14.1267C2.32519 14.1213 2.32519 14.1158 2.32521 14.1104V3.09323C2.32424 2.82114 2.37447 2.55108 2.47349 2.2984C2.57263 2.04541 2.719 1.81418 2.90517 1.61857C3.09137 1.42292 3.31381 1.26671 3.56023 1.1601C3.80641 1.05359 4.07102 0.998977 4.33861 1.00001ZM3.20021 12.3955C3.31274 12.3157 3.4333 12.2476 3.56023 12.1927C3.80642 12.0862 4.07103 12.0316 4.33863 12.0326H12.8252V1.87501H11.5127H6.7002H4.33578C4.18957 1.87437 4.04415 1.90412 3.90768 1.96316C3.77115 2.02223 3.64564 2.10975 3.53899 2.2218C3.43231 2.3339 3.34671 2.46826 3.28817 2.61766C3.22961 2.76708 3.19956 2.9279 3.20021 3.09066L3.20021 3.0924L3.20021 12.3955Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.7627 13.6875C3.00432 13.6875 3.20021 13.8834 3.20021 14.125H12.3877C12.6293 14.125 12.8252 14.3209 12.8252 14.5625C12.8252 14.8041 12.6293 15 12.3877 15H2.7627C2.52107 15 2.3252 14.8041 2.3252 14.5625V14.125C2.3252 13.8834 2.52107 13.6875 2.7627 13.6875Z'
        fill='currentColor'
      />
      <path
        d='M11.5127 1.87501V7.5625C11.5127 7.7296 11.4175 7.8821 11.2674 7.95551C11.1173 8.02893 10.9385 8.01043 10.8066 7.90784L9.10645 6.5855L7.40629 7.90784C7.2744 8.01043 7.09558 8.02893 6.94548 7.95551C6.79537 7.8821 6.7002 7.7296 6.7002 7.5625V1.87501H4.33578C4.18957 1.87437 4.04415 1.90412 3.90768 1.96316C3.77115 2.02223 3.64564 2.10975 3.53899 2.2218C3.43231 2.3339 3.34671 2.46826 3.28817 2.61766C3.22961 2.76708 3.19956 2.9279 3.20021 3.09066L3.20021 3.0924L3.20021 12.3955C3.31274 12.3157 3.4333 12.2476 3.56023 12.1927C3.80642 12.0862 4.07103 12.0316 4.33863 12.0326H12.8252V1.87501H11.5127Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BookFilled
