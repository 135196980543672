import { SVGProps } from 'react'

function CopyOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.68481 3.125C6.68481 2.50368 7.18849 2 7.80981 2H28.0598C28.6811 2 29.1848 2.50368 29.1848 3.125V23.375C29.1848 23.9963 28.6811 24.5 28.0598 24.5C27.4385 24.5 26.9348 23.9963 26.9348 23.375V4.25H7.80981C7.18849 4.25 6.68481 3.74632 6.68481 3.125Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.18481 7.625C2.18481 7.00368 2.68849 6.5 3.30981 6.5H23.5598C24.1811 6.5 24.6848 7.00368 24.6848 7.625V27.875C24.6848 28.4963 24.1811 29 23.5598 29H3.30981C2.68849 29 2.18481 28.4963 2.18481 27.875V7.625ZM4.43481 8.75V26.75H22.4348V8.75H4.43481Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CopyOutline
