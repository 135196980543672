// From the Layout type provided in react-grid-layout

import { z } from '@invisible/zod'

const RESIZE_HANDLE = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'] as const
const resizeHandleSchema = z.enum(RESIZE_HANDLE)
type TResizeHandle = z.infer<typeof resizeHandleSchema>

/**
 * A pair of baseVariableId and key. The key matches up with the trigger's payload.
 */
const schema = z
  .object({
    /**
     * A string corresponding to the component key.
     * Uses the index of components instead if not provided.
     */
    i: z.string(),
    /**
     * X position in grid units.
     */
    x: z.number(),

    /**
     * Y position in grid units.
     */
    y: z.number(),

    /**
     * Width in grid units.
     */
    w: z.number(),

    /**
     * Height in grid units.
     */
    h: z.number(),

    /**
     * Minimum width in grid units.
     */
    minW: z.number().optional(),

    /**
     * Maximum width in grid units.
     */
    maxW: z.number().optional(),

    /**
     * Minimum height in grid units.
     */
    minH: z.number().optional(),

    /**
     * Maximum height in grid units.
     */
    maxH: z.number().optional(),

    /**
     * set by DragEvents (onDragStart, onDrag, onDragStop) and ResizeEvents (onResizeStart, onResize, onResizeStop)
     */
    moved: z.boolean().optional(),

    /**
     * If true, equal to `isDraggable: false` and `isResizable: false`.
     */
    static: z.boolean().optional(),

    /**
     * If false, will not be draggable. Overrides `static`.
     */
    isDraggable: z.boolean().optional(),

    /**
     * If false, will not be resizable. Overrides `static`.
     */
    isResizable: z.boolean().optional(),

    /**
     * By default, a handle is only shown on the bottom-right (southeast) corner.
     * Note that resizing from the top or left is generally not intuitive.
     */
    resizeHandles: z.array(resizeHandleSchema).optional(),

    /**
     * If true and draggable, item will be moved only within grid.
     */
    isBounded: z.boolean().optional(),
  })
  .strict()

/**
 * A Layout as defined by react-grid-layout
 */
type TSchema = z.infer<typeof schema>

export { resizeHandleSchema, schema }
export type { TResizeHandle, TSchema }
