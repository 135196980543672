import { UuidSchema, z } from '@invisible/zod'

import { schema as formFieldTypeSchema } from './FormFieldType'

const schema = z.object({
  baseId: z.string().optional(),
  fields: z.array(
    z.object({
      id: UuidSchema.optional(),
      label: z.string().optional(),
      type: formFieldTypeSchema.or(z.string()),
      baseVariableId: UuidSchema.optional(),
      baseId: UuidSchema.optional(),
      editable: z.boolean().optional(),
      required: z.boolean().optional(),
      expandable: z.boolean().optional(),
      options: z.array(z.any()).optional(),
      isConditionalInput: z.boolean().optional(),
    })
  ),
  sortOrder: z.string().optional(),
  sortField: z.string().optional(),
  filters: z
    .array(
      z.object({
        id: z.string(),
        baseVariableId: z.string(),
        value: z.array(z.string().optional()),
      })
    )
    .optional(),
  allowAddBaseRun: z.boolean().optional(),
  allowDeleteBaseRun: z.boolean().optional(),
  rowsLimit: z.number().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
