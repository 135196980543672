import { useAbilityContext } from '@invisible/authorization/client'
import { useStore } from '@invisible/common/stores/process-store'
import { logger } from '@invisible/logger/client'
import { Button } from '@invisible/ui/button'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import {
  CheckCircleOutlineIcon,
  CircledExclamationIcon,
  CloseCircleIcon,
  DashCircleFilledIcon,
  ProgressIcon,
  RocketFilledIcon,
  SnoozeOutlineIcon,
  SquaredUpArrowIcon,
} from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Text } from '@invisible/ui/text'
import { baseTheme } from '@invisible/ui/themes'
import type { PrismaAll, StepRun, StepRunStatus } from '@invisible/ultron/prisma'
import { FC, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { useStepRunExecute } from '../hooks/useStepRunExecute'

interface IProps {
  stepRun: StepRun
  status: StepRunStatus | 'notStarted'
}

const STEP_STATUS = {
  notStarted: { text: 'Not Started', icon: null },
  pending: { text: 'Pending', icon: <RocketFilledIcon /> },
  disabled: { text: 'Start', icon: <RocketFilledIcon /> },
  done: {
    text: 'Done',
    icon: <CheckCircleOutlineIcon width={18} height={18} color={baseTheme.colors.success} />,
  },
  escalated: {
    text: 'Escalated',
    icon: <SquaredUpArrowIcon width={17} height={17} color='red' />,
  },
  snoozed: {
    text: 'Snoozed',
    icon: <SnoozeOutlineIcon width={18} height={18} color='#FAAD14' />,
  },
  cancelled: {
    text: 'Cancelled',
    icon: <CloseCircleIcon width={18} height={18} color='gray' />,
  },
  queued: {
    text: 'Queued',
    icon: <DashCircleFilledIcon width={16} height={16} color='red' />,
  },
  failed: {
    text: 'Failed',
    icon: <DashCircleFilledIcon width={16} height={16} color='red' />,
  },
  running: {
    text: 'In Progress',
    icon: <ProgressIcon width={16} height={16} color={baseTheme.colors.primary} />,
  },
  expired: {
    text: 'Expired',
    icon: <CircledExclamationIcon width={16} height={16} color='orange' />,
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const AutomatedStepStatusCell: FC<IProps> = ({ stepRun, status }) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const [isRetryStepRunModalOpen, setRetryStepRunModalOpen] = useState(false)
  const [_, ability] = useAbilityContext()
  const reactQueryClient = useQueryClient()

  const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage } = useStore(
    useCallback(
      (state) => ({
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        currentPage: state.currentPage,
      }),
      []
    ),
    shallow
  )

  const canAssignStepRun = ability?.can('update', 'StepRun', null, 'status') // Permission specific for this action

  const updateBaseRunQueryCache = () => {
    reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
      [
        'get-base-runs',
        {
          baseId,
          baseViewId,
          take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
          filters: filterOption ?? [],
          sort: sortOption ?? {},
          search: searchTerm ?? '',
          page: currentPage ?? 1,
        },
      ],
      (prevData) => {
        if (!prevData) return prevData

        const updatedBaseRunData = prevData.items.map((baseRun) => {
          if (baseRun.id === stepRun.baseRunId) {
            return {
              ...baseRun,
              stepRuns: baseRun.stepRuns.map((sr) =>
                sr.id !== stepRun.id ? sr : { ...sr, status: 'pending' as PrismaAll.StepRunStatus }
              ),
            }
          }
          return baseRun
        })
        return {
          ...prevData,
          baseRuns: updatedBaseRunData,
        }
      }
    )
  }

  const { mutateAsync: executeStepRun, isLoading: isStepRunBeingRetried } = useStepRunExecute({
    onSuccess: () => {
      updateBaseRunQueryCache()
      reactQueryClient.invalidateQueries('get-base-runs')
      setRetryStepRunModalOpen(false)
    },
  })

  const handleRetryButtonClick = async () => {
    if (isStepRunBeingRetried) return
    const msg = 'Are you sure you want to retry this automation?'
    if (!window.confirm(msg)) return
    logger.info('RETRYING STEP RUN', {
      baseRunId: stepRun.baseRunId,
      stepRunId: stepRun.id,
    })

    await executeStepRun({ id: stepRun.id })
  }

  const canRetry: boolean =
    canAssignStepRun &&
    !isStepRunBeingRetried &&
    (status === 'failed' ||
      (status === 'running' &&
        stepRun?.startedAt &&
        stepRun?.startedAt < new Date(Date.now() - 1000 * 60 * 60)))
      ? true
      : false

  return (
    <div>
      <div
        className={`flex ${canRetry ? 'cursor-pointer' : ''} items-center`}
        onClick={() => {
          if (canRetry) {
            setRetryStepRunModalOpen(true)
          }
        }}>
        <div className='mr-2 flex w-5 items-center justify-center'>{STEP_STATUS[status].icon}</div>
        <Text>{STEP_STATUS[status].text}</Text>
      </div>
      {isRetryStepRunModalOpen ? (
        <Modal
          icon='InfoFilledIcon'
          title='Failed/Running Step Run'
          onClose={() => setRetryStepRunModalOpen(false)}
          primaryButton={
            <Button
              variant='primary'
              size='md'
              onClick={async () => handleRetryButtonClick()}
              disabled={
                isStepRunBeingRetried ||
                new Date(stepRun.updatedAt) > new Date(Date.now() - 1000 * 60)
              }
              loading={isStepRunBeingRetried}>
              Retry Step Run
            </Button>
          }
          secondaryButton={
            <Button
              variant='secondary'
              size='md'
              onClick={() => window.open('https://help.inv.tech', '_blank')}>
              Get Help
            </Button>
          }>
          <div>
            <Text ml={2}> {stepRun.errorMessage}</Text>
          </div>
          {new Date(stepRun.updatedAt) > new Date(Date.now() - 1000 * 60) ? (
            <div>
              <Text color={'red'} ml={3}>
                Please wait at least 1 minute since last failure before retrying.
              </Text>
            </div>
          ) : null}
        </Modal>
      ) : null}
    </div>
  )
}
