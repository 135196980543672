import LaunchIcon from '@mui/icons-material/Launch'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { PAPER_STYLE } from './ReviewExportTaskDialog'

const DataProfileFailed = () => (
  <Paper style={PAPER_STYLE}>
    <Typography variant='h6'>Data profile generation failed.</Typography>
    <Typography variant='caption'>
      Please raise a support ticket at the help desk mentioning the process link and export name.
    </Typography>
    <Tooltip title='Invisible Help Desk' enterDelay={500}>
      <IconButton
        href={'https://invisible.atlassian.net/servicedesk/customer/portals'}
        target='_blank'>
        <LaunchIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  </Paper>
)

export { DataProfileFailed }
