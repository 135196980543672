import { SVGProps } from 'react'
function RotateLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='15'
      viewBox='0 0 27 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.46613 0.136475C2.01841 0.136475 2.46613 0.58419 2.46613 1.13647V8.13648H9.46613C10.0184 8.13648 10.4661 8.58419 10.4661 9.13648C10.4661 9.68876 10.0184 10.1365 9.46613 10.1365H1.46613C0.913841 10.1365 0.466125 9.68876 0.466125 9.13648V1.13647C0.466125 0.58419 0.913841 0.136475 1.46613 0.136475Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4161 0.869689C13.9385 0.367683 16.5531 0.625134 18.9292 1.60947C21.3052 2.5938 23.3359 4.26079 24.7643 6.39951C26.1927 8.53824 26.9546 11.0526 26.9536 13.6244C26.9534 14.1767 26.5055 14.6243 25.9533 14.6241C25.401 14.6239 24.9534 14.176 24.9536 13.6237C24.9544 11.4475 24.3097 9.31999 23.1011 7.5103C21.8924 5.70061 20.1742 4.29009 18.1637 3.45719C16.1532 2.62429 13.9408 2.40644 11.8065 2.83122C9.67219 3.25599 7.71181 4.3043 6.17342 5.84349L2.17323 9.84368C1.78271 10.2342 1.14954 10.2342 0.759019 9.84368C0.368494 9.45316 0.368494 8.81999 0.759019 8.42947L4.75883 4.42965V4.42965C6.57691 2.6107 8.89383 1.37168 11.4161 0.869689Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RotateLeft
