import { useEffect } from 'react';

// The model viewer is a custom (non-standard) HTML element, so we need to declare it here by extending the JSX namespace
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': unknown;
    }
  }
}

interface ModelViewerProps {
  modelUrl: string;
}

const ThreeDimensionModelViewer = ({ modelUrl }: ModelViewerProps) => {
  useEffect(() => {
    // The model-viewer library is only available in the browser
    import('@google/model-viewer');
  }, []);

  return (
    <div style={{ height: '600px', width: '100vh'}}>
      <model-viewer
        style={{ height: '100%', width: '100%' }}
        src={modelUrl}
        alt="A 3D model"
        auto-rotate={true}
        camera-controls={true}
        auto-scale
        background-color="#455A64"
        exposure="0.5"
        touch-action="pan-y"
        tone-mapping="commerce"
        >
      </model-viewer>
    </div>
  );
}

export default ThreeDimensionModelViewer;
