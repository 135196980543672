import { SVGProps } from 'react'
function CleanData(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M30.3591 48.0916C29.463 48.0916 28.5668 47.7517 27.8827 47.0676L20.8128 39.9955C20.1507 39.3356 19.7866 38.4556 19.7866 37.5213C19.7866 36.5874 20.1507 35.7074 20.8128 35.0475L34.9552 20.9051C36.2791 19.583 38.5834 19.583 39.9054 20.9051L46.9754 27.975C48.3414 29.3392 48.3414 31.5614 46.9754 32.9252L32.833 47.0676C32.151 47.7517 31.2549 48.0916 30.3591 48.0916ZM30.005 44.9476C30.2569 45.1977 30.4551 45.2017 30.7129 44.9476L44.8553 30.8052C44.9813 30.6773 45.0014 30.5294 45.0014 30.4514C45.0014 30.3734 44.9813 30.2251 44.8553 30.0973L37.7832 23.027C37.5272 22.771 37.3313 22.7728 37.0753 23.027L22.9329 37.1694C22.8069 37.2976 22.7867 37.4455 22.7867 37.5235C22.7867 37.6015 22.8047 37.7495 22.9329 37.8755L30.005 44.9476Z'
          fill='currentColor'
        />
        <path
          d='M36.8431 42.4356C36.4593 42.4356 36.0751 42.2895 35.7832 41.9954L25.8828 32.0954C25.2968 31.5094 25.2968 30.5591 25.8828 29.9731C26.4687 29.3871 27.4187 29.3871 28.005 29.9731L37.9051 39.8736C38.4911 40.4595 38.4911 41.4095 37.9051 41.9954C37.611 42.2895 37.2272 42.4356 36.8431 42.4356Z'
          fill='currentColor'
        />
        <path
          d='M13.6806 42.0936H4.50015C2.01789 42.0936 7.62939e-06 40.0753 7.62939e-06 37.5935V4.59243C7.62939e-06 2.11017 2.01789 0.0922852 4.50015 0.0922852H33.5009C35.9832 0.0922852 38.0011 2.11017 38.0011 4.59243V13.8527C38.0011 14.6808 37.3291 15.3528 36.501 15.3528C35.673 15.3528 35.001 14.6808 35.001 13.8527V4.59243C35.001 3.7644 34.329 3.09238 33.5009 3.09238H4.50015C3.67212 3.09238 3.0001 3.7644 3.0001 4.59243V37.5935C3.0001 38.4215 3.67212 39.0935 4.50015 39.0935H13.6806C14.5086 39.0935 15.1807 39.7655 15.1807 40.5936C15.1807 41.4216 14.5086 42.0936 13.6806 42.0936Z'
          fill='currentColor'
        />
        <path
          d='M36.501 13.0928H1.50005C0.672026 13.0928 7.62939e-06 12.4208 7.62939e-06 11.5928C7.62939e-06 10.7647 0.672026 10.0927 1.50005 10.0927H36.501C37.3291 10.0927 38.0011 10.7647 38.0011 11.5928C38.0011 12.4208 37.3291 13.0928 36.501 13.0928Z'
          fill='currentColor'
        />
        <path
          d='M29.1411 23.0929H1.50005C0.672026 23.0929 7.62939e-06 22.4209 7.62939e-06 21.5928C7.62939e-06 20.7648 0.672026 20.0928 1.50005 20.0928H29.1411C29.9691 20.0928 30.6411 20.7648 30.6411 21.5928C30.6411 22.4209 29.9691 23.0929 29.1411 23.0929Z'
          fill='currentColor'
        />
        <path
          d='M19.0007 33.0933H1.50005C0.672026 33.0933 7.62939e-06 32.4213 7.62939e-06 31.5933C7.62939e-06 30.7652 0.672026 30.0932 1.50005 30.0932H19.0007C19.8288 30.0932 20.5008 30.7652 20.5008 31.5933C20.5008 32.4213 19.8288 33.0933 19.0007 33.0933Z'
          fill='currentColor'
        />
        <path
          d='M19.0007 33.0933C18.1727 33.0933 17.5007 32.4213 17.5007 31.5933V11.5928C17.5007 10.7647 18.1727 10.0927 19.0007 10.0927C19.8288 10.0927 20.5008 10.7647 20.5008 11.5928V31.5933C20.5008 32.4213 19.8288 33.0933 19.0007 33.0933Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CleanData
