import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

import { useBaseRunFindById } from '../hooks/useBaseRunFindById'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { InformationWAC } from './InformationWAC'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  closeWizard: () => void
}

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

const InformationWACContainer = ({ information, showName, name, baseRun }: IProps) => {
  const { data: processInfo, isLoading: loadingProcess } = useBaseRunFindById({
    baseRunId: baseRun.id,
  })

  return (
    <Container>
      {showName ? <Text fontWeight='bold'>{name}</Text> : null}
      {loadingProcess || !processInfo ? (
        <div>Loading...</div>
      ) : (
        <InformationWAC {...{ information, baseRun, processId: processInfo.process.id }} />
      )}
    </Container>
  )
}

export { InformationWACContainer }
