import { SVGProps } from 'react'
function CelebrateOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.505 6.98283C10.8424 6.90872 11.193 6.92108 11.5243 7.01875C11.8548 7.1162 12.1553 7.29539 12.3982 7.53976L12.4 7.54154L24.4585 19.6L24.4605 19.6021C24.7047 19.8449 24.8838 20.1454 24.9813 20.4757C25.0789 20.807 25.0913 21.1576 25.0172 21.495C24.9431 21.8323 24.7849 22.1455 24.5573 22.4053C24.3298 22.6651 24.0402 22.8633 23.7156 22.9812L4.75404 29.8694C4.38936 30.0067 3.99286 30.0365 3.61161 29.9552C3.22706 29.8732 2.87448 29.6816 2.59645 29.4036C2.31842 29.1255 2.12682 28.7729 2.0448 28.3884C1.96347 28.0071 1.99332 27.6106 2.13068 27.2459L9.01873 8.28451C9.13671 7.95988 9.33486 7.67022 9.5947 7.44268C9.85455 7.21513 10.1677 7.05695 10.505 6.98283ZM3.0913 27.6023L4.04837 27.9682C4.04925 27.9659 4.04944 27.9634 4.04893 27.9609C4.04841 27.9585 4.04721 27.9563 4.04546 27.9545C4.04371 27.9528 4.0415 27.9516 4.03907 27.9511C4.03673 27.9506 4.03428 27.9507 4.03203 27.9516L4.04221 27.9478L3.0913 27.6023ZM4.05804 27.942L23.0157 21.0553L23.0126 21.0521L10.9479 8.98743L10.9448 8.98431L4.05804 27.942Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.9013 3.0246C26.4672 3.0246 26.9259 3.48334 26.9259 4.04921H25.9013C26.9259 4.04921 26.9259 4.05025 26.9259 4.05025V4.05136L26.9259 4.0538L26.9259 4.05957L26.9257 4.07455C26.9255 4.08605 26.9252 4.10055 26.9246 4.11786C26.9234 4.15246 26.9213 4.19847 26.9173 4.25429C26.9094 4.36558 26.8939 4.51787 26.8639 4.69794C26.8047 5.05321 26.6844 5.54257 26.4335 6.04433C26.1806 6.55011 25.7852 7.08705 25.1729 7.49526C24.5542 7.90775 23.7752 8.14763 22.8275 8.14763C22.2383 8.14763 21.8646 8.29197 21.6188 8.45583C21.3666 8.62396 21.1857 8.85547 21.0543 9.11815C20.921 9.38484 20.8492 9.66393 20.8124 9.885C20.7944 9.9931 20.7857 10.0809 20.7817 10.1378C20.7797 10.166 20.7788 10.186 20.7785 10.1965L20.7783 10.204C20.7744 10.7666 20.3172 11.2214 19.7537 11.2214C19.1878 11.2214 18.7291 10.7627 18.7291 10.1968H19.7537C18.7291 10.1968 18.7291 10.1958 18.7291 10.1958L18.7291 10.1922L18.7291 10.1865L18.7293 10.1715C18.7295 10.16 18.7298 10.1455 18.7304 10.1282C18.7315 10.0936 18.7337 10.0476 18.7377 9.99176C18.7456 9.88047 18.761 9.72817 18.791 9.54811C18.8503 9.19284 18.9706 8.70348 19.2215 8.20171C19.4744 7.69594 19.8698 7.15899 20.4821 6.75078C21.1008 6.3383 21.8797 6.09842 22.8275 6.09842C23.4167 6.09842 23.7904 5.95407 24.0362 5.79022C24.2884 5.62209 24.4693 5.39058 24.6006 5.1279C24.734 4.86121 24.8058 4.58211 24.8426 4.36105C24.8606 4.25294 24.8692 4.1651 24.8733 4.10829C24.8753 4.08006 24.8762 4.06002 24.8765 4.0496L24.8767 4.04206C24.8806 3.47947 25.3378 3.0246 25.9013 3.0246Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.88963 19.9233C5.28976 19.5232 5.9385 19.5232 6.33864 19.9233L12.0764 25.6611C12.4766 26.0612 12.4766 26.71 12.0764 27.1101C11.6763 27.5102 11.0275 27.5102 10.6274 27.1101L4.88963 21.3723C4.4895 20.9722 4.48949 20.3234 4.88963 19.9233Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6799 2C17.2457 2 17.7045 2.45873 17.7045 3.0246V6.09842C17.7045 6.66429 17.2457 7.12302 16.6799 7.12302C16.114 7.12302 15.6553 6.66429 15.6553 6.09842V3.0246C15.6553 2.45873 16.114 2 16.6799 2Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.1768 14.5954C25.5769 14.1952 26.2257 14.1952 26.6258 14.5954L28.675 16.6446C29.0751 17.0447 29.0751 17.6934 28.675 18.0936C28.2749 18.4937 27.6261 18.4937 27.226 18.0936L25.1768 16.0444C24.7767 15.6442 24.7767 14.9955 25.1768 14.5954Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9471 9.87283C30.1261 10.4097 29.836 10.9899 29.2991 11.1689L26.2253 12.1935C25.6885 12.3724 25.1082 12.0823 24.9293 11.5454C24.7503 11.0086 25.0405 10.4284 25.5773 10.2494L28.6511 9.22481C29.1879 9.04587 29.7682 9.33599 29.9471 9.87283Z'
        fill='#604CA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.34868 13.1609C7.74881 12.7608 8.39756 12.7608 8.79769 13.1609L18.8388 23.202C19.2389 23.6021 19.2389 24.2509 18.8388 24.651C18.4387 25.0512 17.7899 25.0512 17.3898 24.651L7.34868 14.6099C6.94855 14.2098 6.94855 13.561 7.34868 13.1609Z'
        fill='#604CA5'
      />
    </svg>
  )
}

export default CelebrateOutlined
