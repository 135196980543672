export const ITEMS_BASE_ID = '9bca7d6f-99eb-444f-9673-045ec61b6680'

export const UUIDs: Record<string, any> = {
  CasesBase: {
    'Go Live ID': 'fe3c8eca-a1ad-4d11-9c86-2449f1f60651',
    'Cust ID': '829fd286-fd8d-47df-bfcd-b4f59434d76e',
    'Go Live Name': '967cd6d8-a2f9-4bd2-904b-8ce317ca84fe',
    'Case URL': 'caef81d2-4046-4b1b-9383-1c5837248bb8',
    'Final Review Type': '97642160-3364-424a-925d-6338886489e1',
    'Account Name': '9a4cdfa2-de92-44e7-907f-fb641662359e',
    'Package Status': 'af0bfc15-c6f5-4261-8adb-c336edce7695',
    'Virtual Restaurant': 'a3a38fac-8c44-4a82-8fe0-3900b114b4a3',
    'Contains Alcohal': '68b2e4f7-fb5b-4d3b-8381-5321539be9d7',
    'External Notes': 'd87e7394-7b38-4d88-9ebf-0e903f9a5b19',
    'Menu Item Count': 'cfb92388-0776-46e2-8338-252b104b9065',
    Source: '38863f10-1677-44aa-9ccc-cb447c1a1b78',
    'Final Review Rep Name': '7d47e614-3ea8-4337-889f-6e1397b5bb06',
    Queue: '8e8a17f6-61ef-4819-ab0e-21e48dc43728',
    'Menu Copy': '6521ae82-2680-4938-b543-6f1f56c14e7f',
  },
}

export const ITEM_KEYS: Record<string, any> = {
  goLiveID: 'Go Live ID',
  custID: 'Cust ID',
  goLiveName: 'Go Live Name',
  caseUrl: 'Case URL',
  finalReviewType: 'Final Review Type',
  accountName: 'Account Name',
  packageStatus: 'Package Status',
  virtualRestaurant: 'Virtual Restaurant',
  containsAlcohal: 'Contains Alcohal',
  externalNotes: 'External Notes',
  menuItemCount: 'Menu Item Count',
  source: 'Source',
  finalReviewRepName: 'Final Review Rep Name',
  queue: 'Queue',
  menuCopy: 'Menu Copy',
}
