import { SVGProps } from 'react'
function BoltFoodOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='125'
      height='30'
      viewBox='0 0 125 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.0894 3.45328e-05V22.5553H35.7838V1.12755L41.0894 3.45328e-05ZM25.9829 17.2481C27.4495 17.2481 28.6361 16.0612 28.6361 14.5946C28.6361 13.1276 27.4495 11.941 25.9829 11.941C24.5167 11.941 23.3301 13.1276 23.3301 14.5946C23.3301 16.0612 24.5167 17.2481 25.9829 17.2481ZM25.9829 6.40528C30.5004 6.40528 34.1699 10.0686 34.1699 14.5946C34.1699 19.1202 30.5 22.7838 25.9829 22.7838C21.4586 22.7838 17.7959 19.1202 17.7959 14.5946C17.7959 10.0686 21.4658 6.40528 25.9829 6.40528ZM25.9829 24.6929C27.4481 24.6929 28.6361 25.8808 28.6361 27.3464C28.6361 28.812 27.4481 30 25.9829 30C24.5181 30 23.3301 28.812 23.3301 27.3464C23.3301 25.8808 24.5181 24.6929 25.9829 24.6929ZM14.1779 10.6291C15.4601 11.8968 16.2559 13.6511 16.2483 15.5895C16.2483 19.4372 13.1315 22.5552 9.28485 22.5552H0V0H8.25328C12.0999 0 15.2167 3.11803 15.2167 6.96541C15.2167 8.30704 14.8411 9.56751 14.1779 10.6291ZM9.2921 17.2481C10.2059 17.2481 10.9503 16.5036 10.9503 15.5895C10.9503 14.6757 10.2059 13.9312 9.2921 13.9312H5.31322V17.2481H9.2921ZM5.31322 5.30711V8.62406H8.26087C9.17437 8.62406 9.91836 7.87951 9.91836 6.96541C9.91836 6.05166 9.17437 5.30711 8.26087 5.30711H5.31322ZM51.1112 12.0146H48.4656V16.1794C48.4656 17.4398 48.8713 18.3685 49.9322 18.3685C50.6175 18.3685 51.1184 18.2137 51.1184 18.2137V22.1205C51.1184 22.1205 50.0206 22.7839 48.5319 22.7839H48.4656C48.3997 22.7839 48.3406 22.7763 48.274 22.7763H48.2226C48.1932 22.7763 48.1559 22.7691 48.1266 22.7691C45.1641 22.614 43.1527 20.7492 43.1527 17.5134V3.78114L48.4584 2.65363V6.70752H51.1112V12.0146Z'
        fill='#34D186'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.6337 17.2486C83.1028 17.2486 84.2917 16.0616 84.2917 14.595C84.2917 13.1279 83.1028 11.9413 81.6337 11.9413C80.1643 11.9413 78.9754 13.1279 78.9754 14.595C78.9754 16.0616 80.1643 17.2486 81.6337 17.2486ZM81.6337 6.40551C86.1596 6.40551 89.8366 10.0689 89.8366 14.595C89.8366 19.1207 86.1596 22.7844 81.6337 22.7844C77.1005 22.7844 73.4308 19.1207 73.4308 14.595C73.4308 10.0689 77.1078 6.40551 81.6337 6.40551ZM98.993 17.2486C100.462 17.2486 101.651 16.0616 101.651 14.595C101.651 13.1279 100.462 11.9413 98.993 11.9413C97.5236 11.9413 96.3347 13.1279 96.3347 14.595C96.3347 16.0616 97.5236 17.2486 98.993 17.2486ZM98.993 6.40551C103.519 6.40551 107.196 10.0689 107.196 14.595C107.196 19.1207 103.519 22.7844 98.993 22.7844C94.4599 22.7844 90.7901 19.1207 90.7901 14.595C90.7901 10.0689 94.4671 6.40551 98.993 6.40551ZM64.7239 13.9353V22.5557H59.408V0H73.5982V5.31447H64.7239V8.62043H71.9891V13.9353H64.7239ZM119.165 21.1934C118.263 22.0307 117.106 22.5557 115.675 22.5557C111.52 22.5557 108.151 18.9406 108.151 14.4805C108.151 10.0208 111.52 6.40541 115.675 6.40541C117.106 6.40541 118.263 6.92083 119.165 7.74888V0H124.481V22.5557H119.165V21.1934ZM116.281 17.1587C117.763 17.1587 118.964 15.9596 118.964 14.4805C118.964 13.0014 117.763 11.8028 116.281 11.8028C114.8 11.8028 113.599 13.0014 113.599 14.4805C113.599 15.9596 114.8 17.1587 116.281 17.1587Z'
        fill='#2F313F'
      />
    </svg>
  )
}

export default BoltFoodOriginal
