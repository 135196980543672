import { SVGProps } from 'react'

function StepFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        d='M8 9H4V13H8V9ZM4 7C2.89543 7 2 7.89543 2 9V13C2 14.1046 2.89543 15 4 15H8C9.10457 15 10 14.1046 10 13V9C10 7.89543 9.10457 7 8 7H4Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        d='M8 20H4V24H8V20ZM4 18C2.89543 18 2 18.8954 2 20V24C2 25.1046 2.89543 26 4 26H8C9.10457 26 10 25.1046 10 24V20C10 18.8954 9.10457 18 8 18H4Z'
        fill='currentColor'
      />
      <path d='M14 13V9H28V13H14Z' fill='currentColor' />
      <path
        d='M13.7273 7C12.6256 7 12 7.99182 12 8.85714V13.1429C12 14.0082 12.6256 15 13.7273 15H28.2727C29.3744 15 30 14.0082 30 13.1429V8.85714C30 7.99182 29.3744 7 28.2727 7H13.7273ZM14 9V13H28V9H14Z'
        fill='currentColor'
      />
      <path
        d='M13.7273 18C12.6256 18 12 18.9918 12 19.8571V24.1429C12 25.0082 12.6256 26 13.7273 26H28.2727C29.3744 26 30 25.0082 30 24.1429V19.8571C30 18.9918 29.3744 18 28.2727 18H13.7273ZM14 20V24H28V20H14Z'
        fill='currentColor'
      />
      <path d='M14 24V20H28V24H14Z' fill='currentColor' />
    </svg>
  )
}

export default StepFilled
