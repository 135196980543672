import { Tab, Tabs } from '@invisible/ui/tabs'
import { FC, useState } from 'react'

import { CostPage } from './ProcessCost'
import { ReconciliationPage } from './ProcessReconciliation'
import { UsagePage } from './ProcessUsage'

// eslint-disable-next-line @typescript-eslint/ban-types
const ProcessFinances: FC<{ companyId: string; processId: string }> = ({
  companyId,
  processId,
}) => {
  const [activeTab, setActiveTab] = useState('Usage')
  const handleTabChange = (value: string | number) => {
    setActiveTab(value as string)
  }

  return (
    <div className='h-full w-full'>
      <div className='border-weak flex w-full border-0 border-b border-solid'>
        <div className='translate-y-[1px]'>
          <Tabs orientation='horizontal' size='medium' value={activeTab} onChange={handleTabChange}>
            <Tab name='Usage' value='Usage' />
            <Tab name='Cost' value='Cost' />
            <Tab name='Reconcile' value='Reconcile' />
          </Tabs>
        </div>
      </div>
      {activeTab === 'Usage' ? <UsagePage companyId={companyId} processId={processId} /> : null}
      {activeTab === 'Cost' ? <CostPage processId={processId} /> : null}
      {activeTab === 'Reconcile' ? (
        <ReconciliationPage companyId={companyId} processId={processId} />
      ) : null}
    </div>
  )
}

export { ProcessFinances }
