import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import axios from 'axios'
import { useMutation as rqUseMutation, UseMutationOptions } from 'react-query'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type TVariables = {
  processId: string
  stepRunId: string
  baseRunId: string
  baseRunVariables: {
    baseVariableId: string
    baseRunId: string
    value: string | number | boolean | null | string[]
  }[]
  formConfig: WizardSchemas.Form.TSchema
  assignNextTaskV1: boolean
  assignNextTaskV2: boolean
  startedAt?: Date
  durationInSeconds?: number
  isAttendedMap?: boolean
}

const useSubmitWizardForm = (options?: UseMutationOptions<unknown, unknown, TVariables>) => {
  const { isLoading, mutateAsync } = rqUseMutation(
    ['complete-step-run'],
    async ({
      stepRunId,
      baseRunId,
      baseRunVariables,
      assignNextTaskV2,
      isAttendedMap,
      formConfig,
      durationInSeconds,
    }: TVariables) => {
      if (isAttendedMap) {
        await axios.post(
          `${NEXT_PUBLIC_CONCORDE_URL}/api/attended-map-execute/`,
          {
            step_run_id: stepRunId,
            started_at: null,
            duration_in_seconds: durationInSeconds,
            base_run_variables: baseRunVariables.reduce<Record<string, unknown>>(
              (acc, { baseVariableId, value }) => {
                acc[baseVariableId] = value
                return acc
              },
              {}
            ),
          },
          { withCredentials: true }
        )
        return
      }
      // TODO: This should throw SOW error
      await axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/wizard/submit/`,
        {
          step_run_id: stepRunId,
          base_run_id: baseRunId,
          base_run_variables: baseRunVariables.reduce<Record<string, unknown>>(
            (acc, { baseVariableId, value }) => {
              acc[baseVariableId] = value
              return acc
            },
            {}
          ),
          started_at: null,
          skip_auto_assign: !assignNextTaskV2,
          config: {
            type: formConfig.type,
            base_id: formConfig.baseId,
            assign_parent_base_run_id: formConfig.assignParentBaseRunId,
            complete_step_run_on_submit: formConfig.completeStepRunOnSubmit,
          },
        },
        {
          withCredentials: true,
        }
      )
    },
    options
  )

  return { isLoading, mutateAsync }
}

export { useSubmitWizardForm }
