// not using env-var here due to how nextjs behaves with frontend. cant destructure process.env
export const NEXT_PUBLIC_DATADOG_APP_ID = (process.env as any).NEXT_PUBLIC_DATADOG_APP_ID
export const NEXT_PUBLIC_DATADOG_CLIENT_TOKEN = (process.env as any)
  .NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
export const NEXT_PUBLIC_DATADOG_SERVICE_NAME = (process.env as any)
  .NEXT_PUBLIC_DATADOG_SERVICE_NAME
export const NEXT_PUBLIC_DATADOG_SAMPLE_RATE = parseInt(
  (process.env as any).NEXT_PUBLIC_DATADOG_SAMPLE_RATE
)

export const ENV = (process.env as any).NEXT_PUBLIC_ENV || 'development'
export const GIT_SHA = (process.env as any).NEXT_PUBLIC_GIT_SHA
export const GIT_REF = (process.env as any).NEXT_PUBLIC_GIT_REF
