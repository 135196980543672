import { useEffect, useRef } from 'react'
import React from 'react'

import { IHighlightedTextProps } from './types'

const HighlightedText = ({
  id,
  start,
  end,
  text,
  dispatcher,
  isHighlighted,
}: IHighlightedTextProps) => {
  const ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    if (isHighlighted) {
      dispatcher({ anchorEl: ref.current })
    }
  }, [isHighlighted, dispatcher])

  const openContextMenu = () => {
    dispatcher({
      anchorEl: ref.current,
      annotation: { start, end, text, id },
    })
  }

  return (
    <mark ref={ref} onClick={openContextMenu} data-start={start} data-end={end}>
      {text}
    </mark>
  )
}

const Split = (props: IHighlightedTextProps) => {
  if (props.mark) return <HighlightedText {...props} />

  return (
    <span data-start={props.start} data-end={props.end}>
      {props.text}
    </span>
  )
}

export default Split
