import { IExportTaskScheduleConfigType } from '@invisible/concorde/gql-client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { upperFirst } from 'lodash/fp'

interface IProps {
  name: string
  description?: string
  baseName: string
  recipients: {
    to: string[]
    cc?: string[]
    bcc?: string[]
  }
  schedule?: Pick<IExportTaskScheduleConfigType, 'description'>
}

const chipStyle = {
  color: 'grey.600',
  borderRadius: '16px',
  textTransform: 'lowercase',
}

const ExportTaskSummary = ({ name, description, baseName, recipients, schedule }: IProps) => (
  <Stack
    direction={{ xs: 'column', sm: 'row' }}
    spacing={4}
    justifyContent='space-between'
    alignItems='flex-start'>
    <Stack gap='16px' width='500px'>
      <Typography fontWeight='bold' variant='body1' gutterBottom>
        Details
      </Typography>
      <Box>
        <InputLabel id='name' shrink={true}>
          Export name *
        </InputLabel>
        <Typography variant='body1'>{name}</Typography>
      </Box>
      {description ? (
        <TextField
          label='Description'
          variant='standard'
          size='small'
          rows={4}
          multiline
          value={description}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      ) : null}
      <Box>
        <InputLabel id='base' shrink={true}>
          Selected base *
        </InputLabel>
        <Typography variant='body1'>{baseName}</Typography>
      </Box>

      <br />

      <Typography variant='body1' component='span' sx={{ fontWeight: 'bold' }}>
        Recipients
      </Typography>

      <Stack>
        <InputLabel id='to' shrink={true}>
          Email export to*
        </InputLabel>
        <Grid container rowSpacing={'10px'} columnSpacing={'24px'}>
          {recipients.to?.length > 0 &&
            recipients.to.map((email, index) => (
              <Grid item key={index}>
                <Chip
                  variant='filled'
                  label={email}
                  sx={{
                    ...chipStyle,
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Stack>
      {recipients.cc && recipients.cc?.length > 0 && (
        <Stack>
          <InputLabel id='cc' shrink={true}>
            cc
          </InputLabel>
          <Grid container rowSpacing={'10px'} columnSpacing={'24px'}>
            {recipients.cc.map((email, index) => (
              <Grid item key={index}>
                <Chip
                  variant='filled'
                  label={email}
                  sx={{
                    ...chipStyle,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}

      {recipients.bcc && recipients.bcc?.length > 0 && (
        <Stack>
          <InputLabel id='bcc' shrink={true}>
            bcc
          </InputLabel>
          <Grid container rowSpacing={'10px'} columnSpacing={'24px'}>
            {recipients.bcc.map((email, index) => (
              <Grid item key={index}>
                <Chip
                  variant='filled'
                  label={email}
                  sx={{
                    ...chipStyle,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </Stack>

    <Stack gap='16px'>
      <Box
        sx={{
          height: '400px',
          width: '300px',
          p: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }}>
        <Stack gap='8px'>
          <Stack direction='row' alignItems='center' gap='8px'>
            <CalendarTodayIcon fontSize='small' />
            <Typography fontWeight='bold'>Schedule summary</Typography>
          </Stack>
          <Typography fontWeight='bold'>Current </Typography>
          <Typography>
            {schedule && schedule.description
              ? upperFirst(schedule.description)
              : 'Your export will not repeat'}
          </Typography>

          <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            You can pause/resume recurring exports or edit frequency at any time.
          </Typography>
        </Stack>
      </Box>
    </Stack>
  </Stack>
)

export { ExportTaskSummary }
