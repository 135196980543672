import { SVGProps } from 'react'

function VideoPlain(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_2_299)'>
        <path
          d='M22.7143 6.38955L19.2857 8.36366V4.29223C19.2857 3.34669 18.517 2.57794 17.5714 2.57794H1.71429C0.76875 2.57794 0 3.34669 0 4.29223V19.7208C0 20.6663 0.76875 21.4351 1.71429 21.4351H17.5714C18.517 21.4351 19.2857 20.6663 19.2857 19.7208V15.6494L22.7143 17.6235C23.2848 17.9529 24 17.5404 24 16.8842V7.13151C24 6.47259 23.2848 6.06009 22.7143 6.38955ZM17.3571 19.5065H1.92857V4.50651H17.3571V19.5065ZM22.0714 15.0333L19.2857 13.4315V10.5842L22.0714 8.97973V15.0333ZM3.85714 7.93509H6.85714C6.975 7.93509 7.07143 7.83866 7.07143 7.7208V6.43509C7.07143 6.31723 6.975 6.2208 6.85714 6.2208H3.85714C3.73929 6.2208 3.64286 6.31723 3.64286 6.43509V7.7208C3.64286 7.83866 3.73929 7.93509 3.85714 7.93509Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_299'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VideoPlain
