import { classNames } from '@invisible/common/helpers'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { TextArea } from '@invisible/ui/form'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Rating } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { FC, useState } from 'react'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { BASES } from './constants'

interface IProps {
  stepRunId: string
  response: {
    id: string
    text: string
    index: number
    category: string
    score: number
    model: number
    rationale: string
  }
  activePromptId: string
  canSubmit: boolean
  wizardIsReadOnly: boolean
}

const RESPONSE_CATEGORIES = [
  'No issues',
  'Not accurate',
  'Dangerous',
  'Sexually explicit',
  'Hallucinations',
]

type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

// eslint-disable-next-line @typescript-eslint/ban-types
const PromptResponse: FC<IProps> = ({
  stepRunId,
  response: { category, id, score, text, index, model, rationale },
  activePromptId,
  canSubmit,
  wizardIsReadOnly,
}) => {
  const theme = createTheme({})
  const reactQueryContext = useContext()
  const [isSelected, setIsSelected] = useState(false)
  const [responseText, setResponseText] = useState(text)
  const [responseRationale, setResponseRationale] = useState(rationale)

  const { mutateAsync: updateVariable, isLoading } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: BASES.RESPONSES.ID,
            parentBaseRunId: activePromptId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return prevData.map((b) =>
            b.id !== data[0].baseRunId
              ? b
              : {
                  ...b,
                  baseRunVariables: b.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
          )
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const handleResponseSelection = async () => {
    await updateVariable({
      stepRunId,
      data: [
        {
          baseRunId: activePromptId,
          baseVariableId: BASES.PROMPTS.BASE_VARIABLES.ACCEPTED_RESPONSE,
          value: responseText,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: BASES.PROMPTS.BASE_VARIABLES.ACCEPTED_MODEL,
          value: model,
        },
        {
          baseRunId: id,
          baseVariableId: BASES.RESPONSES.BASE_VARIABLES.ACCEPTED,
          value: true,
        },
      ],
    })
  }

  return (
    <div className='bg-weak-3 border-main rounded-md border border-solid p-3'>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>Response {index}</div>
        {!isSelected ? (
          <Button
            size='sm'
            variant='secondary'
            disabled={!canSubmit || wizardIsReadOnly}
            onClick={() => setIsSelected(true)}>
            Select
          </Button>
        ) : (
          <Button
            size='sm'
            disabled={wizardIsReadOnly}
            variant='primary'
            onClick={handleResponseSelection}
            loading={isLoading}>
            Save
          </Button>
        )}
      </div>
      <div
        className={classNames(
          'my-3 inline-block whitespace-pre-line outline-none',
          isSelected ? 'rounded border border-solid border-gray-300 bg-white p-2' : 'bg-inherit'
        )}
        contentEditable={isSelected && !wizardIsReadOnly}
        onInput={(e) => setResponseText(e?.currentTarget?.textContent ?? '')}>
        {text.trim()}
      </div>
      <div className='flex items-center justify-between'>
        <div>
          <div className='font-medium'>Grade Response</div>
          <ThemeProvider theme={theme}>
            <Rating
              max={4}
              defaultValue={score}
              className='mt-2'
              sx={{ color: '#604ca5' }}
              disabled={wizardIsReadOnly}
              onChange={(_, newValue) =>
                updateVariable({
                  stepRunId,
                  data: [
                    {
                      baseRunId: id,
                      baseVariableId: BASES.RESPONSES.BASE_VARIABLES.SCORE,
                      value: newValue,
                    },
                  ],
                })
              }
            />
          </ThemeProvider>
          <TextArea
            value={responseRationale}
            placeholder='Rationale'
            readOnly={wizardIsReadOnly}
            onChange={(e) => {
              setResponseRationale(e.target.value)
            }}
            onBlur={() =>
              updateVariable({
                stepRunId,
                data: [
                  {
                    baseRunId: id,
                    baseVariableId: BASES.RESPONSES.BASE_VARIABLES.RATIONALE,
                    value: responseRationale,
                  },
                ],
              })
            }
            className='resize-none !rounded'
          />
        </div>

        <Dropdown
          options={RESPONSE_CATEGORIES.map((c) => ({ key: c, value: c }))}
          width='300px'
          placeholder='Select the Correct Categorization'
          defaultKey={category}
          disabled={wizardIsReadOnly}
          onChange={async ({ key }) => {
            await updateVariable({
              stepRunId,
              data: [
                {
                  baseRunId: id,
                  baseVariableId: BASES.RESPONSES.BASE_VARIABLES.CATEGORY,
                  value: key,
                },
              ],
            })
          }}
        />
      </div>
    </div>
  )
}

export { PromptResponse }
