import { SVGProps } from 'react'
function CoffeeMug(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#coffee-mug_svg__clip0)'>
        <path
          d='M4.299 4.394c.31 0 .562-.252.562-.563V.566a.562.562 0 10-1.125 0V3.83c0 .311.252.563.563.563zm9.578 2.25h-1.875c0-.622-.503-1.125-1.125-1.125h-7.75c-.622 0-1.125.503-1.125 1.125v3.875c0 .053.003.104.01.154a5 5 0 009.931 1.095h1.934c.622 0 1.125-.503 1.125-1.124V7.769c0-.622-.503-1.125-1.125-1.125zm-3 4h-.017a3.874 3.874 0 11-7.716 0h-.017v-4h7.75v4zm3-.125h-1.75v-2.75h1.75v2.75zM9.58 4.394c.311 0 .563-.252.563-.563V.566a.562.562 0 10-1.125 0V3.83c0 .311.251.563.562.563zm-2.656 0c.31 0 .562-.252.562-.563V.566a.562.562 0 10-1.125 0V3.83c0 .311.252.563.563.563z'
          fill='currentColor'
          fillOpacity={0.85}
        />
      </g>
      <defs>
        <clipPath id='coffee-mug_svg__clip0'>
          <path fill='currentColor' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CoffeeMug
