import { useTransformationRulesQuery } from '@invisible/concorde/gql-client'
import Editor from '@monaco-editor/react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as monacoEditor from 'monaco-editor'
import { Dispatch, useMemo, useState } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { useFetchBaseRunSample } from './hooks/useFetchBaseRunSample'
import { evalJsonata } from './jsonataHelpers'
import styles from './Preview.module.css'
import { TReducerAction, TReducerState } from './reducer'
import { TFormSchema } from './schema'

interface IProps {
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
  errors: FieldErrors<TFormSchema>
  control: Control<TFormSchema, object>
}

type TSelectedOption = TFormSchema['selectedTransformationRule'] | null

export const options = {
  minimap: { enabled: false },
  lineNumbers: 'off',
  contextmenu: false,
  automaticLayout: true,
  scrollBeyondLastLine: true,
  readOnly: true,
  scrollbar: {
    verticalScrollbarSize: 0,
  },
} as monacoEditor.editor.IStandaloneEditorConstructionOptions

const Preview = ({ state, errors, control }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<TSelectedOption>(null)
  const [result, setResult] = useState('')

  const { baseRunSample, baseRunCount } = useFetchBaseRunSample({
    filterConfigId: state.filterConfigId ?? '',
    enabled: !!state.filterConfigId,
    params: [state.filters, state.stepRunFilters],
  })

  const handleSelectChange = async (item: TSelectedOption, reason: AutocompleteChangeReason) => {
    let jsonataResult = ''
    if (reason === 'clear' || !item) {
      setSelectedOption(null)
      return
    }

    try {
      jsonataResult = await evalJsonata(baseRunSample, item.expression)
    } catch {
      jsonataResult = 'Error evaluating jsonata expression'
    }

    setSelectedOption(item)
    setResult(jsonataResult)
  }

  const { data: transformationRuleData } = useTransformationRulesQuery(
    {
      filters: {
        baseId: state.baseId,
      },
    },
    {
      enabled: !!state.baseId,
      refetchOnWindowFocus: 'always',
    }
  )

  const selectableRules = useMemo(() => {
    const transformationRules = transformationRuleData?.transformationRules?.edges ?? []
    return transformationRules.map(({ node }) => ({
      label: node.name as string,
      value: node.id as string,
      expression: node.expression as string,
    }))
  }, [transformationRuleData])

  return (
    <Stack direction='column' gap={1} className='flex items-start justify-between'>
      <Stack direction='row' gap={1} alignItems='center'>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          Preview your export
        </Typography>
        <Typography variant='body1' sx={{ ml: 1, opacity: '70%' }}>
          {baseRunCount} rows selected
        </Typography>
        <Tooltip
          title={
            <Typography fontSize='11px'>
              This number represents the rows selected
              <br />
              based on your filter configuration.
            </Typography>
          }
          placement='bottom-start'>
          <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'grey.600' }} />
        </Tooltip>
      </Stack>

      <Stack direction='row' gap={2}>
        <Controller
          control={control}
          name='selectedTransformationRule'
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ width: 300, mt: 2 }}>
              <Autocomplete
                onChange={(_, item, reason) => {
                  handleSelectChange(item, reason)
                  onChange(item)
                }}
                value={value}
                options={selectableRules}
                isOptionEqualToValue={(left, right) => left?.value === right?.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select a schema *'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      input: {
                        '&::placeholder': {
                          fontStyle: 'italic',
                        },
                      },
                    }}
                    placeholder='Select a schema...'
                    error={!!errors.selectedTransformationRule}
                    helperText={
                      errors.selectedTransformationRule && errors.selectedTransformationRule.message
                    }
                  />
                )}
                size='small'
              />
            </FormControl>
          )}
        />

        <Button
          sx={{
            mt: 2,
            fontWeight: 'normal',
            height: '40px',
          }}
          size='small'
          variant='contained'
          endIcon={<LaunchIcon />}
          href={`/export/transformation-rule/build?filterConfigId=${
            state.filterConfigId ?? ''
          }&transformationRuleId=${selectedOption?.value ?? ''}`}
          target='_blank'>
          {selectedOption ? 'Edit this schema' : 'Add a schema'}
        </Button>
        <Button
          sx={{
            mt: 2,
            fontWeight: 'normal',
            height: '40px',
          }}
          size='small'
          variant='text'
          endIcon={<LaunchIcon />}
          href='https://docs.jsonata.org/simple'
          target='_blank'>
          View documentation
        </Button>
      </Stack>

      <br />

      <Stack direction='row' gap={2} width={'100%'} minHeight={'315px'} height={'400px'}>
        <Stack direction='column' gap={1} width={'50%'}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Data Selected
          </Typography>
          <Editor
            language='json'
            theme='light'
            value={JSON.stringify(baseRunSample, null, 2)}
            options={{ ...options, extraEditorClassName: styles['jsonata-input-pane'] }}
          />
        </Stack>

        <Stack direction='column' gap={1} width={'50%'}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Data transformed by "{selectedOption ? selectedOption.label : '-'}"
          </Typography>
          {!selectedOption ? (
            <Box
              width='100%'
              minHeight={'315px'}
              height={'400px'}
              sx={{ backgroundColor: grey[100] }}>
              <Typography variant='body1' color='black' sx={{ opacity: '60%', mt: 3, ml: 2 }}>
                <em>Select a schema to apply data transformations to your export...</em>
              </Typography>
            </Box>
          ) : (
            <Editor
              language='json'
              theme='light'
              value={result}
              options={{ ...options, extraEditorClassName: styles['jsonata-result-pane'] }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export { Preview }
