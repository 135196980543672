import { SVGProps } from 'react'

function HomeFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.037 12.6953L17.9682 1.63526C17.5232 1.22474 16.942 0.99707 16.3389 0.99707C15.7358 0.99707 15.1546 1.22474 14.7096 1.63526L2.64075 12.6953C2.39365 12.9233 2.1963 13.2006 2.06124 13.5097C1.92619 13.8188 1.85639 14.1528 1.85628 14.4905V28.502C1.84647 29.1157 2.06131 29.7115 2.45972 30.1755C2.68584 30.4348 2.9644 30.6422 3.27665 30.7839C3.58891 30.9256 3.92763 30.9983 4.27005 30.997H11.5113C11.8314 30.997 12.1384 30.8688 12.3647 30.6405C12.5911 30.4123 12.7182 30.1027 12.7182 29.7799V22.4775C12.7182 22.1547 12.8454 21.8452 13.0717 21.6169C13.298 21.3887 13.605 21.2604 13.9251 21.2604H18.7526C19.0727 21.2604 19.3797 21.3887 19.606 21.6169C19.8324 21.8452 19.9595 22.1547 19.9595 22.4775V29.7799C19.9595 30.1027 20.0867 30.4123 20.313 30.6405C20.5393 30.8688 20.8463 30.997 21.1664 30.997H28.4077C28.808 31.0005 29.2027 30.9011 29.5542 30.7079C29.9368 30.4978 30.2563 30.1878 30.4793 29.8104C30.7022 29.4331 30.8204 29.0021 30.8215 28.5628V14.4905C30.8214 14.1528 30.7516 13.8188 30.6165 13.5097C30.4815 13.2006 30.2841 12.9233 30.037 12.6953Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default HomeFilled
