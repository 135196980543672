import classNames from 'classnames'
import { useState } from 'react'

type NumberRatingProps = {
  value: number | null
  onChange: (value: number) => void
  disabled?: boolean
  selectedValueColor?: string
}
const NumberRating = ({ value, onChange, disabled, selectedValueColor }: NumberRatingProps) => {
  const [selected, setSelected] = useState<number | null>(value)
  const options = [
    { key: '5', value: 5 },
    { key: '4', value: 4 },
    { key: '3', value: 3 },
    { key: '2', value: 2 },
    { key: '1', value: 1 },
  ]
  return (
    <div
      className={classNames(
        'flex justify-between gap-x-4 py-1',
        disabled ? 'cursor-not-allowed' : ''
      )}>
      {options.map((option, index) => (
        <div className='relative flex items-center justify-center p-1' key={index}>
          {selected === option.value ? (
            <div
              style={selected === option.value ? { color: selectedValueColor } : undefined}
              className={classNames(
                'center absolute top-[-12px] h-full align-top font-bold',
                selected === option.value ? `font-bold` : ''
              )}>
              .
            </div>
          ) : null}
          <span
            style={selected === option.value ? { color: selectedValueColor } : undefined}
            className={classNames(
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              selected === option.value ? `font-bold` : ''
            )}
            onClick={
              disabled
                ? () => undefined
                : () => {
                    setSelected(option.value)
                    onChange(option.value)
                  }
            }>
            {option.key}
          </span>
        </div>
      ))}
    </div>
  )
}

export { NumberRating }
