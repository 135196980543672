import type {} from '@mui/x-data-grid/themeAugmentation'

import MuiAlert from './MuiAlert'
import MuiAvatar from './MuiAvatar'
import MuiBadge, { IsOnlineBadge } from './MuiBadge'
import { chipVariantIcons, MuiChip } from './MuiChip'
import MuiDataGrid from './MuiDataGrid'
import MuiTab from './MuiTab'
import MuiTabPanel from './MuiTabPanel'
import Pagination from './Pagination'

interface PaletteOptions {
  primary: {
    main: string
    [key: number]: string
  }
}

const components = (palette: PaletteOptions) => ({
  MuiDataGrid: MuiDataGrid(palette),
  MuiAvatar,
  MuiChip,
  MuiAlert,
  MuiTab,
  MuiBadge,
  MuiTabPanel,
})

export { chipVariantIcons, components, IsOnlineBadge, Pagination }
