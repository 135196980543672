import { Moment, moment } from '@invisible/legacy/moment'

export interface IDateRange {
  start: Moment
  end: Moment
  isFirstCycle: boolean
}

export const calculatePreviousCycle = ({
  cycleStart,
  offsetCycles,
}: {
  cycleStart: Moment
  offsetCycles: number
}): IDateRange => {
  const previousCycleEnd = cycleStart.clone().subtract({ day: 1 })

  const date = moment.utc(previousCycleEnd)
  const startOfMonth = date.clone().startOf('month')
  const cycleBreak = startOfMonth.clone().add(15, 'days')
  if (offsetCycles < 0) {
    const previousCycleStart =
      previousCycleEnd < cycleBreak ? startOfMonth.clone() : startOfMonth.clone().add({ day: 15 })

    return calculatePreviousCycle({
      cycleStart: previousCycleStart,
      offsetCycles: offsetCycles + 1,
    })
  }

  return previousCycleEnd < cycleBreak
    ? {
        start: startOfMonth.clone(),
        end: cycleBreak.clone().subtract({ second: 1 }),
        isFirstCycle: true,
      }
    : {
        start: cycleBreak.clone(),
        end: date.clone().endOf('month'),
        isFirstCycle: false,
      }
}

interface ICalculatePaymentCycle {
  nowReference?: Moment | null
  offsetCycles?: number
}

export const calculateNextCycle = ({
  cycleStart,
  offsetCycles,
}: {
  cycleStart: Moment
  offsetCycles: number
}): IDateRange => {
  const nextCycleStart = cycleStart.clone().add({ day: 1 })

  const date = moment.utc(nextCycleStart)
  const startOfMonth = date.clone().startOf('month')
  const cycleBreak = startOfMonth.clone().add(15, 'days')

  if (offsetCycles > 2) {
    const nextCycleEnd =
      nextCycleStart < cycleBreak
        ? startOfMonth.clone().add({ day: 14 })
        : startOfMonth.clone().endOf('month')

    return calculateNextCycle({
      cycleStart: nextCycleEnd.clone().add({ day: 1 }),
      offsetCycles: offsetCycles - 1,
    })
  }

  return nextCycleStart < cycleBreak
    ? {
        start: startOfMonth.clone(),
        end: cycleBreak.clone().subtract({ second: 1 }),
        isFirstCycle: true,
      }
    : {
        start: cycleBreak.clone(),
        end: date.clone().endOf('month'),
        isFirstCycle: false,
      }
}

const calculateFirstMonthlyCycle = (startOfMonth: Moment) => ({
  start: startOfMonth,
  end: startOfMonth.clone().add({ day: 15 }).subtract({ second: 1 }),
  isFirstCycle: true,
})

const calculateLastMonthlyCycle = (startOfMonth: Moment) => ({
  start: startOfMonth.clone().add({ day: 15 }),
  end: startOfMonth.clone().endOf('month'),
  isFirstCycle: false,
})

export const calculatePaymentCycle = ({
  nowReference,
  offsetCycles,
}: ICalculatePaymentCycle = {}) => {
  const now = nowReference ?? moment.utc()
  const startOfMonth = now.clone().startOf('month')
  const cycleBreak = startOfMonth.clone().add({ day: 15 })
  const isFirstCycle = now < cycleBreak

  if (offsetCycles) {
    if (offsetCycles < 0) {
      const currentCycleStart = isFirstCycle
        ? startOfMonth.clone()
        : cycleBreak.clone().subtract({ second: 1 })

      return calculatePreviousCycle({
        cycleStart: currentCycleStart,
        offsetCycles,
      })
    }

    if (offsetCycles === 1) {
      return isFirstCycle
        ? calculateFirstMonthlyCycle(startOfMonth)
        : calculateLastMonthlyCycle(startOfMonth)
    }

    if (offsetCycles > 1) {
      const currentCycleEnd = isFirstCycle
        ? cycleBreak.clone().subtract({ second: 1 })
        : startOfMonth.clone().endOf('month')

      return calculateNextCycle({
        cycleStart: currentCycleEnd.clone().add({ day: 1 }),
        offsetCycles: offsetCycles,
      })
    }
  }

  return isFirstCycle
    ? calculateLastMonthlyCycle(startOfMonth.clone().subtract({ month: 1 }).startOf('month'))
    : calculateFirstMonthlyCycle(startOfMonth.clone())
}

export const calculateNextCycleStart = (): Moment => {
  const currentDate = moment.utc()
  const startOfMonth = currentDate.clone().startOf('month')
  const cycleBreak = startOfMonth.clone().add(15, 'days')

  let nextCycleStart: Moment

  if (currentDate.isBefore(cycleBreak)) {
    nextCycleStart = cycleBreak
  } else {
    nextCycleStart = startOfMonth.clone().add(1, 'month').startOf('month')
  }

  return nextCycleStart
}
