import { IExportConfigurationDataQuery } from '@invisible/concorde/gql-client'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { groupBy, startCase } from 'lodash/fp'
import { Dispatch, useMemo, useState } from 'react'

import { convertDateRangeToHumanReadableText } from './helpers'
import { TReducerAction, TReducerState } from './reducer'
import {
  SingleStepRunFilterDialog,
  STEP_RUN_PROPERTY_OPTIONS_VALUES,
} from './SingleStepRunFilterDialog'

interface IProps {
  steps: NonNullable<IExportConfigurationDataQuery['process']['steps']>
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
}

export const TREE_VIEW_STYLES = {
  '&.MuiSimpleTreeView-root .Mui-expanded': {
    backgroundColor: 'transparent',
  },
  '&.MuiSimpleTreeView-root .Mui-selected.Mui-focused': {
    backgroundColor: 'transparent',
  },
  '&.MuiSimpleTreeView-root .MuiTreeItem-content:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiSimpleTreeView-root .Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&.MuiSimpleTreeView-root .Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
}

const StepRunFilters = ({ steps, dispatch, state }: IProps) => {
  const [stepIdBeingConfigured, setStepIdBeingConfigured] = useState<string | null>(null)
  const groupedSteps = useMemo(() => {
    const stepsInSelectedBase = steps.filter((step) => step.base.id === state.baseId)
    return groupBy((step) => step.lifecycleStage?.name ?? 'No stage', stepsInSelectedBase)
  }, [steps, state.baseId])

  const checkAllInStage = (stage: string, checked: boolean) => {
    if (checked)
      return dispatch({
        type: 'ADD_STEP_RUN_FILTERS',
        payload: groupedSteps[stage].map((s) => ({ step_id: s.id })),
      })

    return dispatch({
      type: 'REMOVE_STEP_RUN_FILTERS',
      payload: groupedSteps[stage].map((s) => s.id),
    })
  }

  const checkStep = (stepId: string, checked: boolean) => {
    if (checked)
      return dispatch({
        type: 'ADD_STEP_RUN_FILTERS',
        payload: [{ step_id: stepId }],
      })

    // Remove status and time range filters
    dispatch({
      type: 'REMOVE_STEP_RUN_FILTERS',
      payload: [stepId],
    })
    dispatch({
      type: 'REMOVE_FILTERS',
      payload: [stepId],
    })
  }

  // Computes the summary of the filters applied to a step to be shown on hover
  const computeStepRunFilterSummary = (stepId: string) => {
    const statusFilter = state.filters.find((f) => f.key === stepId) as {
      key: string
      value: string[]
    }
    const timeRangeFilter = state.stepRunFilters.find((f) => f.step_id === stepId)

    return (
      <Box>
        <Typography>Step run filters applied</Typography>
        <br />
        {statusFilter ? (
          <>
            <Box>
              <Typography>Filter the data by status ({statusFilter.value.length})</Typography>
              {statusFilter.value.map((status) => (
                <Typography key={status}>- {startCase(status)}</Typography>
              ))}
            </Box>
            <br />
          </>
        ) : null}

        {timeRangeFilter ? (
          <Box>
            <Typography>Filter the data by time range</Typography>
            {STEP_RUN_PROPERTY_OPTIONS_VALUES.map((option) => {
              const dateRange = timeRangeFilter[option]

              return dateRange ? (
                <Typography>
                  - {startCase(option)}: {convertDateRangeToHumanReadableText(dateRange)}
                </Typography>
              ) : null
            })}
          </Box>
        ) : null}
      </Box>
    )
  }

  return (
    <>
      <SimpleTreeView sx={TREE_VIEW_STYLES}>
        {Object.keys(groupedSteps).map((stage) => {
          // Every step in the stage has a filter
          const isChecked = groupedSteps[stage].every(
            (s) =>
              state.stepRunFilters.some((f) => f.step_id === s.id) ||
              state.filters.some((f) => f.key === s.id)
          )
          // At least one step in the stage has a filter, but not all
          const isIndeterminate =
            !isChecked &&
            groupedSteps[stage].some(
              (s) =>
                state.stepRunFilters.some((f) => f.step_id === s.id) ||
                state.filters.some((f) => f.key === s.id)
            )
          return (
            <TreeItem itemId={stage} label={stage} key={stage}>
              <Box ml='8px'>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={isChecked}
                      onChange={(e) => checkAllInStage(stage, e.target.checked)}
                      // At least one checked but not all
                      indeterminate={isIndeterminate}
                    />
                  }
                  label={`${groupedSteps[stage].length} step(s)`}
                />
                <Stack ml='32px' gap='4px'>
                  {groupedSteps[stage]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((step) => {
                      const statusFilter = state.filters.find((f) => f.key === step.id)
                      const timeRangeFilter = state.stepRunFilters.find(
                        (f) => f.step_id === step.id
                      )
                      const filterCount =
                        (statusFilter ? 1 : 0) +
                        Object.keys(timeRangeFilter ?? {}).filter((k) => k !== 'step_id').length

                      return (
                        <Stack
                          key={step.id}
                          direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          // Show filter container on hover
                          sx={{
                            '&:hover .filter-container': {
                              visibility: 'visible',
                            },
                          }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                checked={Boolean(statusFilter) || Boolean(timeRangeFilter)}
                                onChange={(e) => checkStep(step.id, e.target.checked)}
                              />
                            }
                            label={step.name}
                          />
                          <Stack direction='row' alignItems='center' gap='16px'>
                            {/* Filter count and summary */}
                            {filterCount ? (
                              <Tooltip title={computeStepRunFilterSummary(step.id)}>
                                <Stack
                                  alignItems='center'
                                  justifyContent='center'
                                  width='16px'
                                  height='16px'
                                  padding='4px'
                                  borderRadius='50%'
                                  bgcolor='primary.400'
                                  color='white'>
                                  {filterCount}
                                </Stack>
                              </Tooltip>
                            ) : null}

                            {timeRangeFilter || statusFilter ? (
                              <Button
                                size='small'
                                startIcon={<EditIcon />}
                                sx={{
                                  fontWeight: 'normal',
                                }}
                                onClick={() => setStepIdBeingConfigured(step.id)}>
                                EDIT STEP RUN FILTERS
                              </Button>
                            ) : (
                              <Button
                                className='filter-container'
                                size='small'
                                startIcon={<AddIcon />}
                                sx={{
                                  fontWeight: 'normal',
                                  visibility: 'hidden',
                                }}
                                onClick={() => setStepIdBeingConfigured(step.id)}>
                                CONFIGURE STEP RUN FILTERS
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      )
                    })}
                </Stack>
              </Box>
            </TreeItem>
          )
        })}
      </SimpleTreeView>

      <SingleStepRunFilterDialog
        key={stepIdBeingConfigured}
        stepId={stepIdBeingConfigured as string}
        open={Boolean(stepIdBeingConfigured)}
        handleClose={() => setStepIdBeingConfigured(null)}
        dispatch={dispatch}
        statusFilter={state.filters.find((f) => f.key === stepIdBeingConfigured)}
        timeRangeFilter={state.stepRunFilters.find((f) => f.step_id === stepIdBeingConfigured)}
      />
    </>
  )
}

export { StepRunFilters }
