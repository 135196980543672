import { useQuery } from '@invisible/trpc/client'
import { createColumnHelper, Table } from '@invisible/ui/table'
import Link from 'next/link'
import { useMemo } from 'react'

const CompaniesTable = () => {
  const { data: companies, isLoading } = useQuery(['company.findMany'])

  const columnHelper = createColumnHelper<any>()
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Company Name',
        cell: (info) => (
          <Link href={`/company/${info.row.original?.['id']}?view=overview`}>
            <a className='text-black no-underline'>
              <div className='pl-3'>{info.getValue()}</div>
            </a>
          </Link>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => (
          <div className='pl-2'>{new Date(info.row.original.createdAt).toDateString()}</div>
        ),
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        cell: (info) => (
          <div className='pl-2'>{new Date(info.row.original.updatedAt).toDateString()}</div>
        ),
      }),
    ],
    []
  )

  return (
    <div className='px-4'>
      <div className='pt-8 pb-4 text-xl font-semibold'>Companies</div>
      <div>
        <Table.PaginationProvider>
          <Table.Container>
            <Table data={companies ?? []} columns={columns} isDataLoading={isLoading} />
            <Table.Pagination />
          </Table.Container>
        </Table.PaginationProvider>
      </div>
    </div>
  )
}

export { CompaniesTable }
