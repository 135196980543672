import { withErrorBoundary } from '@invisible/common/components/error-boundary'
import { classNames } from '@invisible/common/helpers'
import { ITableColumn } from '@invisible/common/types'
import { TABLE_SKELETON_DATA } from '@invisible/ui/constants'
import { SortIcon } from '@invisible/ui/react-table'
import { Skeleton } from '@invisible/ui/skeleton'
import { Text } from '@invisible/ui/text'
import { gray } from '@invisible/ui/themes'
import { compact } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { Row, useSortBy, useTable } from 'react-table'

import { BRIGHT_THEME } from '../../../../constants/colorThemes'
interface TModel extends Record<string, unknown> {
  baseId?: number
}
// eslint-disable-next-line @typescript-eslint/ban-types
export const IconContainer: React.FC<{ visible: boolean }> = ({ visible, children }) => (
  <div
    className={classNames(
      'ml-8 group-hover:visible',
      'cursor-pointer',
      visible ? 'visible' : 'invisible'
    )}>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const TableContainer: React.FC<{ fullView?: boolean }> = ({ fullView, children }) => (
  <div
    className={classNames(
      'scrollbar-hide relative overflow-auto',
      fullView ? 'h-[1000px] max-h-[1000px] min-h-[1000px]' : 'h-52 max-h-56 min-h-0'
    )}>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const StyledTable: React.FC = ({ children }) => (
  <table className='relative w-full border-spacing-0 bg-[#FAFAFB]'>{children}</table>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const StyledTableHeadRow: React.FC = ({ children }) => (
  <tr className='bg-[#FAFAFB] text-xs font-medium shadow-[inset_0px_-1px_0px_#f0f0f0]'>
    {children}
  </tr>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const StyledTD: React.FC<{ additionalClasses?: string }> = ({ additionalClasses, children }) => (
  <td
    className={classNames(
      'items-center whitespace-nowrap p-4',
      additionalClasses ? `${additionalClasses}` : ''
    )}>
    {children}
  </td>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const StyledTR: React.FC<{ isLoading?: boolean }> = ({ isLoading, children }) => (
  <tr
    className={classNames(
      'shadow-[inset_0px_-1px_0px_#f0f0f0]',
      isLoading ? 'bg-[#FAFAFB]' : 'bg-[#FFFFFF]'
    )}>
    {children}
  </tr>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const TableWrapper: React.FC = ({ children }) => (
  <div className='rounded-xl border border-solid border-[#EAE9EB]  bg-[#FAFAFB] p-[0px_26px_26px_26px]'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const TableTH: React.FC = ({ children }) => (
  <th className='sticky top-0 whitespace-nowrap bg-[#FAFAFB] p-[1rem_0.5rem]'>{children}</th>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderCell: React.FC = ({ children }) => (
  <div className='group flex items-center bg-[#FAFAFB]'>{children}</div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const ColorCell: React.FC<{ color?: string }> = ({ color, children }) => (
  <div style={{ backgroundColor: color ? color : '' }} className='ml-1 mr-2 h-2 w-2 rounded-[50%]'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const NoDataText: React.FC<{ height?: string }> = ({ height, children }) => (
  <div
    id='no-data'
    style={{ height: height }}
    className='z-10 flex items-center justify-center text-base font-normal text-[#A19FA5]'>
    {children}
  </div>
)

const getSkeletonData = (fullView: boolean) => (fullView ? Array(20).fill({}) : Array(3).fill({}))

const getSkeletonColumns = (limit?: number) =>
  limit
    ? [...Array(limit)].map((_, index) => ({
        ...TABLE_SKELETON_DATA[index],
        Cell: <Skeleton.Rectangle width={160} height={30} />,
      }))
    : TABLE_SKELETON_DATA.map((column) => ({
        ...column,
        Cell: <Skeleton.Rectangle width={160} height={30} />,
      }))

const getColumns = (columns: ITableColumn[], limit?: number) =>
  limit ? compact([...Array(limit)].map((_, index) => columns[index])) : columns ?? []

const Table: React.FunctionComponent<{
  columns?: ITableColumn[] | null
  data?: Record<string, unknown>[] | null
  isLoading: boolean
  fullView?: boolean
  limit?: number
  errorMessage?: string
}> = withErrorBoundary(
  ({ columns = [], data = [], isLoading, limit, fullView = false, errorMessage }) => {
    const tableData = useMemo(
      () => (isLoading ? getSkeletonData(fullView) : data),
      [isLoading, data, fullView]
    )
    const tableColumns = useMemo(
      () => (isLoading ? getSkeletonColumns(limit) : getColumns(columns ?? [], limit)),
      [isLoading, columns, limit]
    )
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<TModel>(
      { columns: tableColumns, data: tableData ?? [] },
      useSortBy
    )
    const renderRow = useCallback(
      (rows: Array<Row>) =>
        rows?.map((row, index) => {
          prepareRow(row)
          return (
            // @ts-expect-error Already existing key
            <StyledTR isLoading={isLoading} key={index} {...row.getRowProps()}>
              {row.cells.map((cell: any, i) =>
                !isLoading && i < 1 ? (
                  <StyledTD
                    additionalClasses='flex'
                    {...cell.getCellProps()}
                    key={cell.getCellProps().key}>
                    {' '}
                    <ColorCell color={BRIGHT_THEME[index % BRIGHT_THEME.length]} />
                    {cell.render('Cell')}
                  </StyledTD>
                ) : (
                  <StyledTD {...cell.getCellProps()} key={cell.getCellProps().key}>
                    {cell.column.url ? (
                      <a href={`${cell.column.url}/${cell.value}`} target='blank'>
                        {cell.render('Cell')}
                      </a>
                    ) : (
                      cell.render('Cell')
                    )}
                  </StyledTD>
                )
              )}
            </StyledTR>
          )
        }),
      [prepareRow, isLoading]
    )

    return (
      <TableWrapper>
        <TableContainer fullView={fullView}>
          <StyledTable {...getTableProps()} data-test='table'>
            <thead>
              {headerGroups?.map((headerGroup, index) => (
                <StyledTableHeadRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers?.map((column: any, index) => (
                    <TableTH {...column.getHeaderProps()} key={index}>
                      <HeaderCell>
                        <Text fontWeight={500} color={`${gray(13)}`}>
                          {column.render('Header')}
                        </Text>

                        <IconContainer visible={column.isSorted}>
                          <SortIcon
                            {...column.getSortByToggleProps()}
                            direction={
                              column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                            }
                          />
                        </IconContainer>
                      </HeaderCell>
                    </TableTH>
                  ))}
                </StyledTableHeadRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>{renderRow(rows).length > 0 && renderRow(rows)}</tbody>
          </StyledTable>
          {renderRow(rows).length === 0 && (
            <NoDataText
              data-test='no-table-data'
              height={columns && columns.length > 0 ? (limit === 1 ? 'auto' : '119px') : '100%'}>
              {errorMessage ?? 'There is no data to show at the moment'}
            </NoDataText>
          )}
        </TableContainer>
      </TableWrapper>
    )
  }
)
export { Table }
