import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form'

import { ChipEmailInput } from './ChipEmailInput'
import { TFormSchema } from './schema'

interface IProps {
  control: Control<TFormSchema, object>
  setValue: UseFormSetValue<TFormSchema>
  errors: FieldErrors<TFormSchema>
}

const Recipients = ({ control, setValue, errors }: IProps) => (
  <Stack gap={3} width='550px'>
    <Typography variant='body1' component='span' sx={{ fontWeight: 'bold' }}>
      Send a link to download the export
    </Typography>

    <ChipEmailInput
      name='to'
      label='Email export to*'
      numOfRows={6}
      control={control}
      setValue={setValue}
      errors={errors}
    />

    <Box>
      <ChipEmailInput name='cc' label='CC' control={control} setValue={setValue} errors={errors} />
      <Typography
        sx={{
          fontSize: '0.675rem',
          color: 'grey.600',
          marginTop: '4px',
          marginLeft: '8px',
        }}>
        You will be included as a recipient when your export is sent.
      </Typography>
    </Box>

    <ChipEmailInput name='bcc' label='BCC' control={control} setValue={setValue} errors={errors} />
  </Stack>
)

export { Recipients }
