import type { TProcessByIdBase } from '@invisible/common/components/process-base'
import {
  fromGlobalId,
  IBaseType,
  toGlobalId,
  useBasesbyProcessIdQuery,
} from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { useGate } from 'statsig-react'

const useGetBasesByProcessId = (processId: string) => {
  const { value: enableBasesGraphql } = useGate('enable-bases-graphql')

  const { data: graphqlData } = useBasesbyProcessIdQuery(
    {
      id: toGlobalId('Process', processId),
    },
    {
      enabled: enableBasesGraphql,
    }
  )

  const { data: trpcData } = useQuery(
    [
      'base.findByProcessId',
      {
        processId,
      },
    ],
    {
      enabled: !enableBasesGraphql,
    }
  )

  return (
    enableBasesGraphql
      ? {
          data:
            graphqlData?.bases.edges?.map((edge) => ({
              ...edge.node,
              id: fromGlobalId(edge.node.id),
            })) ?? [],
        }
      : { data: trpcData }
  ) as { data: (IBaseType | TProcessByIdBase)[] }
}

export { useGetBasesByProcessId }
