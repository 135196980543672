import { SVGProps } from 'react'

function AutoLayout(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C8.34518 0 8.625 0.275517 8.625 0.615385V2.46154C8.625 2.80141 8.34518 3.07692 8 3.07692C7.65482 3.07692 7.375 2.80141 7.375 2.46154V0.615385C7.375 0.275517 7.65482 0 8 0Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 12.9231C8.34518 12.9231 8.625 13.1986 8.625 13.5385V15.3846C8.625 15.7245 8.34518 16 8 16C7.65482 16 7.375 15.7245 7.375 15.3846V13.5385C7.375 13.1986 7.65482 12.9231 8 12.9231Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 6.15385C8.34518 6.15385 8.625 6.42936 8.625 6.76923V9.23077C8.625 9.57064 8.34518 9.84615 8 9.84615C7.65482 9.84615 7.375 9.57064 7.375 9.23077V6.76923C7.375 6.42936 7.65482 6.15385 8 6.15385Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.375 3.07692C2.375 2.39719 2.93464 1.84615 3.625 1.84615H12.375C13.0654 1.84615 13.625 2.39719 13.625 3.07692V6.15385C13.625 6.83358 13.0654 7.38462 12.375 7.38462H3.625C2.93464 7.38462 2.375 6.83358 2.375 6.15385V3.07692ZM12.375 3.07692H3.625V6.15385H12.375V3.07692Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 9.84615C0.5 9.16642 1.05964 8.61539 1.75 8.61539H14.25C14.9404 8.61539 15.5 9.16642 15.5 9.84615V12.9231C15.5 13.6028 14.9404 14.1538 14.25 14.1538H1.75C1.05964 14.1538 0.5 13.6028 0.5 12.9231V9.84615ZM14.25 9.84615H1.75V12.9231H14.25V9.84615Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AutoLayout
