import { z } from 'zod'

import { ReportingPlatform, ReportingScope } from './PrismaModelsAsZodSchema'

const strictReportSchema = z
  .object({
    name: z.string(),
    platform: z.nativeEnum(ReportingPlatform),
    scope: z.nativeEnum(ReportingScope),
    order: z.number(),
  })
  .strict()

const schema = z
  .object({
    platform: z.nativeEnum(ReportingPlatform),
    scope: z.nativeEnum(ReportingScope),
    name: z.string(),
    reports: z.array(strictReportSchema),
  })
  .strict()

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
