import { SVGProps } from 'react'

function BellFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M29.4681 23.5008C28.6163 21.9897 27.5913 19.1156 27.5913 14.0341V12.9823C27.5913 6.41929 22.4663 1.04151 16.1576 0.99707H16.071C14.5562 0.999015 13.0566 1.30711 11.6579 1.90378C10.2591 2.50044 8.9886 3.37399 7.91883 4.47453C6.84907 5.57508 6.00101 6.88107 5.42308 8.31796C4.84516 9.75484 4.54868 11.2945 4.55058 12.8489V14.0341C4.55058 19.1156 3.52558 21.9897 2.67382 23.5008C2.46783 23.8609 2.35819 24.2704 2.35599 24.688C2.3538 25.1055 2.45912 25.5163 2.66132 25.8786C2.86352 26.241 3.1554 26.5421 3.50744 26.7515C3.85947 26.9609 4.25917 27.0712 4.66607 27.0711H10.2963C10.2963 28.6428 10.9047 30.1501 11.9877 31.2614C13.0706 32.3727 14.5394 32.9971 16.071 32.9971C17.6025 32.9971 19.0713 32.3727 20.1542 31.2614C21.2372 30.1501 21.8456 28.6428 21.8456 27.0711H27.4758C27.8827 27.0738 28.2829 26.9655 28.6355 26.7572C28.9881 26.549 29.2804 26.2483 29.4825 25.886C29.6829 25.5216 29.787 25.1098 29.7845 24.6914C29.782 24.273 29.6729 23.8626 29.4681 23.5008ZM16.071 30.6267C15.1532 30.6228 14.2741 30.2469 13.6252 29.581C12.9762 28.915 12.61 28.0129 12.6062 27.0711H19.5357C19.5319 28.0129 19.1657 28.915 18.5167 29.581C17.8678 30.2469 16.9887 30.6228 16.071 30.6267Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BellFilled
