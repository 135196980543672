import { SVGProps } from 'react'

function ProductRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.2844 15.8564L18.8356 12.913C18.7558 11.9435 18.5374 10.9905 18.1869 10.0831C17.2463 7.63435 15.5841 6.02886 14.6678 5.29098C14.4392 5.1042 14.1535 5.00149 13.8584 5.00002C13.5632 4.99854 13.2765 5.09839 13.0461 5.28288C12.1217 6.02075 10.4351 7.62624 9.48643 10.075C9.12256 11.0012 8.90112 11.9772 8.82964 12.9698L6.42951 15.8564C6.3022 16.0085 6.21121 16.1875 6.16344 16.38C6.11567 16.5725 6.11238 16.7733 6.15382 16.9673L7.15928 21.4756C7.20658 21.6907 7.30818 21.89 7.45438 22.0547C7.60058 22.2193 7.78651 22.3438 7.99446 22.4162C8.13551 22.464 8.28338 22.4887 8.43232 22.4892C8.72421 22.4876 9.00703 22.3876 9.23507 22.2054L11.4892 20.4053H16.2246L18.4788 22.2054C18.7068 22.3876 18.9897 22.4876 19.2815 22.4892C19.4305 22.4887 19.5784 22.464 19.7194 22.4162C19.9274 22.3438 20.1133 22.2193 20.2595 22.0547C20.4057 21.89 20.5073 21.6907 20.5546 21.4756L21.56 16.9673C21.6015 16.7733 21.5982 16.5725 21.5504 16.38C21.5027 16.1875 21.4117 16.0085 21.2844 15.8564ZM8.42421 21.1918L7.42686 16.6835L8.87829 14.9401C9.06749 16.4591 9.58373 18.0106 10.427 19.5944L8.42421 21.1918ZM13.8569 12.9454C13.6645 12.9454 13.4764 12.8884 13.3163 12.7815C13.1563 12.6745 13.0316 12.5226 12.958 12.3448C12.8843 12.167 12.8651 11.9713 12.9026 11.7826C12.9401 11.5938 13.0328 11.4205 13.1689 11.2844C13.305 11.1483 13.4784 11.0556 13.6671 11.0181C13.8559 10.9805 14.0515 10.9998 14.2293 11.0735C14.4071 11.1471 14.5591 11.2718 14.666 11.4318C14.7729 11.5918 14.83 11.78 14.83 11.9724C14.83 12.2305 14.7274 12.478 14.545 12.6605C14.3625 12.8429 14.115 12.9454 13.8569 12.9454ZM19.2897 21.1918L17.2787 19.5863C18.122 17.989 18.6248 16.424 18.8031 14.8996L20.287 16.6835L19.2897 21.1918Z'
        fill='currentColor'
      />
      <path
        d='M12.5596 21.7026C12.3875 21.7026 12.2225 21.771 12.1009 21.8926C11.9792 22.0143 11.9109 22.1793 11.9109 22.3513C11.9109 22.5234 11.9792 22.6884 12.1009 22.81C12.2225 22.9317 12.3875 23 12.5596 23H15.1543C15.3263 23 15.4913 22.9317 15.613 22.81C15.7346 22.6884 15.803 22.5234 15.803 22.3513C15.803 22.1793 15.7346 22.0143 15.613 21.8926C15.4913 21.771 15.3263 21.7026 15.1543 21.7026H12.5596Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40608 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50334 20.9221 7.21716 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ProductRibbon
