import { SVGProps } from 'react'
function TextMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.33.73a.599.599 0 00-.585.475L.878 14.665a.5.5 0 00.979.208l.852-4.004h3.243l.852 4.004a.5.5 0 10.978-.208L4.916 1.205A.599.599 0 004.33.731zm0 2.525L5.74 9.87H2.922L4.33 3.255zm6.306 1.783a.5.5 0 100 1h.033a3.418 3.418 0 013.387 2.957h-2.758a3.137 3.137 0 000 6.274h.326c.963 0 1.835-.393 2.463-1.028v.528a.5.5 0 101 0V9.456a4.418 4.418 0 00-4.418-4.418h-.033zm.662 4.957h2.789v1.811a2.463 2.463 0 01-2.463 2.463h-.326a2.137 2.137 0 010-4.274z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TextMode
