import { IFormattedPeriod } from '@invisible/common/types'
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'
import { format as formatTZ } from 'date-fns-tz'
import { toLower } from 'lodash/fp'

import { TIMEZONE } from './reports'

export const PERIODS = {
  TODAY: 'Today',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_QUARTER: 'This Quarter',
  THIS_YEAR: 'This Year',
  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last Week',
  LAST_MONTH: 'Last Month',
  LAST_QUARTER: 'Last Quarter',
  LAST_YEAR: 'Last Year',
} as const

export const PERIODS_WITH_CUSTOM = {
  ...PERIODS,
  CUSTOM: 'Custom',
} as const

export type TPeriodOption = typeof PERIODS_WITH_CUSTOM[keyof typeof PERIODS_WITH_CUSTOM]

export type IKeyPeriods = typeof PERIODS[keyof typeof PERIODS]

export type IKeyPeriodsWithCustom = typeof PERIODS_WITH_CUSTOM[keyof typeof PERIODS_WITH_CUSTOM]

export interface IValuesPeriods<KeyPeriods> {
  periodOption: KeyPeriods
  startDate?: string
  endDate?: string
}

export const periodOptions = [
  { key: PERIODS.THIS_WEEK, value: 1 },
  { key: PERIODS.THIS_MONTH, value: 2 },
  { key: PERIODS.THIS_QUARTER, value: 3 },
  { key: PERIODS.THIS_YEAR, value: 4 },
  { key: PERIODS.LAST_WEEK, value: 5 },
  { key: PERIODS.LAST_MONTH, value: 6 },
  { key: PERIODS.LAST_QUARTER, value: 7 },
  { key: PERIODS.LAST_YEAR, value: 8 },
]

export const periodOptionsWithCustom = [
  ...periodOptions,
  { key: PERIODS_WITH_CUSTOM.CUSTOM, value: 9 },
]

export const getPeriodByName = (
  periodName: IKeyPeriodsWithCustom,
  from?: Date,
  to?: Date
): IFormattedPeriod => {
  const newDate = new Date()
  let period

  switch (toLower(periodName)) {
    case toLower(PERIODS.YESTERDAY):
      period = {
        periodOption: periodName,
        to: endOfDay(subDays(newDate, 1)),
        from: startOfDay(subDays(newDate, 1)),
      }
      break
    case toLower(PERIODS.LAST_WEEK):
      period = {
        periodOption: periodName,
        to: endOfISOWeek(subWeeks(startOfISOWeek(newDate), 1)),
        from: subWeeks(startOfISOWeek(newDate), 1),
      }
      break
    case toLower(PERIODS.LAST_MONTH):
      period = {
        periodOption: periodName,
        to: endOfMonth(subMonths(startOfMonth(newDate), 1)),
        from: subMonths(startOfMonth(newDate), 1),
      }
      break
    case toLower(PERIODS.LAST_QUARTER):
      period = {
        periodOption: periodName,
        to: endOfQuarter(subQuarters(startOfQuarter(newDate), 1)),
        from: subQuarters(startOfQuarter(newDate), 1),
      }
      break
    case toLower(PERIODS.LAST_YEAR):
      period = {
        periodOption: periodName,
        to: endOfYear(subYears(startOfYear(newDate), 1)),
        from: subYears(startOfYear(newDate), 1),
      }
      break
    case toLower(PERIODS.TODAY):
      period = {
        periodOption: periodName,
        to: endOfDay(newDate),
        from: startOfDay(newDate),
      }
      break
    case toLower(PERIODS.THIS_WEEK):
      period = {
        periodOption: periodName,
        to: endOfISOWeek(newDate),
        from: startOfISOWeek(newDate),
      }
      break
    case toLower(PERIODS.THIS_MONTH):
      period = {
        periodOption: periodName,
        to: endOfMonth(newDate),
        from: startOfMonth(newDate),
      }
      break
    case toLower(PERIODS.THIS_QUARTER):
      period = {
        periodOption: periodName,
        to: endOfQuarter(newDate),
        from: startOfQuarter(newDate),
      }
      break
    case toLower(PERIODS.THIS_YEAR):
      period = {
        periodOption: periodName,
        to: endOfYear(startOfYear(newDate)),
        from: startOfYear(newDate),
      }
      break
    default:
      period = {
        periodOption: periodName,
        to: endOfDay(to ?? new Date(0)),
        from: startOfDay(from ?? new Date()),
      }
  }

  return {
    ...period,
    from: formatTZ(period?.from, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: TIMEZONE.UTC,
    }),
    to: formatTZ(period?.to, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: TIMEZONE.UTC,
    }),
  }
}
