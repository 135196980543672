import { sortBy } from 'lodash'

import { TAnnotation } from './types'

/**
 * Splits a given text into segments based on the provided offsets.
 * Each segment is either marked or unmarked based on whether it corresponds to an offset range.
 */
export const splitWithOffsets = (text: string, offsets: TAnnotation[]) => {
  let lastEnd = 0
  const splits = []

  for (const offset of sortBy(offsets, (o) => o.start)) {
    const { start, end } = offset
    if (lastEnd < start) {
      splits.push({
        start: lastEnd,
        end: start,
        text: text.slice(lastEnd, start),
      })
    }
    splits.push({
      ...offset,
      mark: true,
      text: text.slice(start, end),
    })
    lastEnd = end
  }
  if (lastEnd < text.length) {
    splits.push({
      start: lastEnd,
      end: text.length,
      text: text.slice(lastEnd, text.length),
    })
  }

  return splits
}

// Checks if a given text selection is empty, meaning the selection has no range.
export const selectionIsEmpty = (selection: Selection) => {
  const position = selection.anchorNode?.compareDocumentPosition(selection.focusNode as Node)

  return position === 0 && selection.focusOffset === selection.anchorOffset
}

// Determines if a text selection was made backwards, meaning from the end to the start of the selection.
export const selectionIsBackwards = (selection: Selection) => {
  if (selectionIsEmpty(selection)) return false

  const position = selection.anchorNode?.compareDocumentPosition(selection.focusNode as Node)

  let backward = false
  if (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  )
    backward = true

  return backward
}

// Function to remove only the beginning and ending tag symbols around URLs
export const removeEnclosingTagsAroundUrls = (text: string): string =>
  text.replace(/<(https?:\/\/)?www\.[^<>]+>/g, (match) => match.replace(/<|>/g, ''))
