import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { SnackbarContext } from '@invisible/common/providers'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import InfoIcon from '@mui/icons-material/Info'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import useBaseRunVariableFindManyByBaseRunId from 'libs/common/components/process-base/src/lib/hooks/useBaseRunVariableFindManyByBaseRunId'
import { flatten, sampleSize, shuffle } from 'lodash/fp'
import pMap from 'p-map'
import pTimes from 'p-times'
import { useContext as useReactContext, useEffect, useMemo, useState } from 'react'
import Markdown from 'react-markdown'
import { useGate } from 'statsig-react'

import { NEXT_PUBLIC_MTC_RLHF_WITHOUT_CLOUDFARE_URL } from '../../../../config/env'
import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunCreateMany } from '../../hooks/useBaseRunCreateManyWizardAction'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { useFirstManualStepForBaseRun } from '../../hooks/useFirstManualStepForBaseRun'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { RLHFContext, RLHFContextDataType } from './context'
import { CustomMultiSelect } from './sub-components/CustomMultiSelect'
import { Turn } from './Turn'

type TManyBaseRunData = NonNullable<inferQueryOutput<'baseRun.findManyByParents'>>
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  rlhfPlus: WizardSchemas.RLHFPlus.TSchema
  isReadOnly: boolean
}

type TModelConfig = NonNullable<WizardSchemas.RLHFPlus.TSchema['models']>[number]

const RLHFWACPlus = ({ baseRun, rlhfPlus: config, stepRun, isReadOnly }: IProps) => {
  const { value: enableMtcRlhfNonCloudfareRoute } = useGate('enable-mtc-rlhf-non-cloudfare-route')

  const reactQueryContext = useContext()
  const [promptText, setPromptText] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tab, setTab] = useState('1')
  const [showInfoSection, setShowInfoSection] = useState(false)
  const [isLastResponseEmpty, setIsLastResponseEmpty] = useState(false)
  const [isFetchingResponses, setisFetchingResponses] = useState(false)
  const [visibleResponseIndices, setVisibleResponseIndices] = useState<number[]>(() =>
    [1, 2, 3, 4, 5].slice(
      0,
      config.models?.reduce((acc, curr) => acc + (curr.numOfCalls ?? 1), 0) ?? 0
    )
  )
  const [collapseAll, setCollapseAll] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [responseMetadataValidationFailures, setResponseMetadataValidationFailures] = useState(
    [] as string[]
  )
  const [promptMetadataValidationFailures, setPromptMetadataValidationFailures] = useState(
    [] as string[]
  )
  const { showSnackbar } = useReactContext(SnackbarContext)

  const { dispatch } = useWizardState()

  const conversationData = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: [baseRun.id],
  })

  const { data: prompts } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config?.promptsBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])
  const { data: firstManualStepRun } = useFirstManualStepForBaseRun({
    baseRunId: baseRun.id,
  })
  const firstManualStepRunCreatedAt = useMemo(
    () => firstManualStepRun?.createdAt ?? '',
    [firstManualStepRun]
  )

  const normalizedConversationData = useMemo(
    () => ({
      maxTurn: conversationData?.find(
        (v) => v.baseVariableId === config?.conversationMaxTurnsBaseVariableId
      )?.value as number,
      minTurn: conversationData?.find(
        (v) => v.baseVariableId === config?.conversationMinTurnsBaseVariableId
      )?.value as number,
      preamble: conversationData?.find(
        (v) => v.baseVariableId === config?.conversationPreambleBaseVariableId
      )?.value as string,
      instruction: conversationData?.find(
        (v) => v.baseVariableId === config?.conversationInstructionBaseVariableId
      )?.value as string,
      modelTemperature: conversationData?.find(
        (v) => v.baseVariableId === config?.conversationModelTempBaseVariableId
      )?.value as number,
    }),
    [conversationData, config]
  )

  // Parses prompts base runs into a typed object with its base run variables
  const normalizedPrompts = useMemo(
    () =>
      (prompts ?? [])
        .map((prompt) => ({
          id: prompt.id,
          text: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptTextBaseVariableId
          )?.value as string,
          index: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptIndexBaseVariableId
          )?.value as number,
          acceptedResponse: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptResponseBaseVariableId
          )?.value as string,
          acceptedModel: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptAcceptedModelBaseVariableId
          )?.value as string,
          responseId: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.responseIdBaseVariableId
          )?.value as string,
          createdAt: prompt.createdAt,
          baseRunVariables: prompt.baseRunVariables,
        }))
        .sort((a, b) => a.index - b.index),
    [prompts, config]
  )
  const tokenCount = useMemo(
    () =>
      (normalizedPrompts ?? [])
        .map((prompt) => (prompt?.acceptedResponse?.length ?? 0) + (prompt?.text?.length ?? 0))
        .reduce((sum, length) => sum + length, 0),
    [normalizedPrompts]
  )

  const unsubmittedPrompt = normalizedPrompts?.find((prompt) => !prompt.acceptedResponse)

  // Uses the Prompt IDs as the parent IDs to fetch all Responses.
  const { data: allResponses } = useQuery([
    'baseRun.findManyByParents',
    {
      parentIds: normalizedPrompts?.map((prompt) => prompt.id) ?? [],
      includeBaseVariableIds: (config?.responseMetadata?.fields ?? [])
        .map((metadata) => metadata.baseVariableId as string)
        .filter(Boolean),
    },
  ])

  // Checks if required metadata are filled in the response
  const validateResponseMetadata = (response: TManyBaseRunData[number]) => {
    if (!response.baseRunVariables) return true

    for (const variable of response.baseRunVariables) {
      if (
        (config.responseMetadata?.fields ?? []).some(
          (metadata) =>
            metadata.required &&
            metadata.baseVariableId === variable.baseVariableId &&
            ((metadata.type === 'multiselect' &&
              typeof variable.value === 'string' &&
              variable.value.split(',').filter((v) => v).length === 0) ||
              variable.value === null)
        )
      ) {
        return false
      }
    }

    return true
  }

  const validatePromptMetadata = (prompt: TFindChildBaseRunsData[number]) => {
    if (!prompt.baseRunVariables) return true

    for (const variable of prompt.baseRunVariables) {
      if (
        (config.promptMetadata?.fields ?? []).some(
          (metadata) =>
            metadata.required &&
            metadata.baseVariableId === variable.baseVariable.id &&
            variable.value === null
        )
      ) {
        return false
      }
    }

    return true
  }

  // Checks if all responses have valid metadata. Creates an array of all PromptIDs that are failing validation.
  useEffect(() => {
    setResponseMetadataValidationFailures([])
    setResponseMetadataValidationFailures((prev) => [
      ...prev,
      ...(allResponses ?? [])
        .filter((response) => !validateResponseMetadata(response))
        .map((r) => r.parentId as string),
    ])
  }, [allResponses])

  // Checks if all prompts have valid metadata. Creates an array of all PromptIDs that are failing validation.
  useEffect(() => {
    setPromptMetadataValidationFailures([])
    setPromptMetadataValidationFailures((prev) => [
      ...prev,
      ...(prompts ?? [])
        .filter((prompt) => !validatePromptMetadata(prompt))
        .map((p) => p.id as string),
    ])
  }, [prompts])

  // If there are 0 validation failures, set the RLHFPLUS-ResponseMetadata in setReadyForSubmit to true, so the Wizard Submit button can be activated.
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS-ResponseMetadata',
      value: responseMetadataValidationFailures.length === 0,
    })
  }, [responseMetadataValidationFailures])

  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS-PromptMetadata',
      value: promptMetadataValidationFailures.length === 0,
    })
  }, [promptMetadataValidationFailures])

  // If there are no unsubmitted prompts, set the RLHFPLUS in setReadyForSubmit to true, so the Wizard Submit button can be activated.
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS',
      value:
        (config.allowEndingWithoutResponse || !unsubmittedPrompt) &&
        (prompts?.length ?? 0) >=
          (normalizedConversationData.minTurn && normalizedConversationData.maxTurn
            ? normalizedConversationData.minTurn
            : undefined ?? config.minMaxTurns?.[0] ?? 1),
    })
  }, [unsubmittedPrompt, prompts])

  const { mutateAsync: createBaseRun, isLoading: isCreatingBaseRun } = useBaseRunCreate()

  const { mutateAsync: deleteBaseRuns, isLoading: isDeletingBaseRuns } =
    useBaseRunDeleteWithStepRunReference({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
      onError: (error) => {
        showSnackbar({
          message: 'Something went wrong while deleting prompts or responses.',
          variant: 'error',
        })
        logger.error('Failed to delete prompts or responses.', error)
      },
    })

  const { mutateAsync: createBaseRuns, isLoading: isCreatingManyBaseRuns } = useBaseRunCreateMany({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateBaseRunVariables, isLoading: isUpdatingBaseVariables } =
    useBaseRunVariablesWizardUpdate({
      onMutate: async (data) => {
        reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config?.promptsBaseId as string,
              parentBaseRunId: baseRun.id,
            },
          ],
          (prevData) => {
            if (!prevData) return

            return prevData.map((b) => {
              const brv = data.find((d) => d.baseRunId === b.id)
              return brv === undefined
                ? b
                : {
                    ...b,
                    baseRunVariables: b.baseRunVariables.map((v) =>
                      v.baseVariable.id !== brv.baseVariableId ? v : { ...v, value: brv.value }
                    ),
                  }
            })
          }
        )
      },
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        reactQueryContext.invalidateQueries('baseRun.findManyByParents')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
        reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId')
      },
      onError: (error) => {
        showSnackbar({
          message: `Failed to save changes.`,
          variant: 'error',
        })
        logger.error('Failed to update base variable changes.', error)
      },
    })

  useEffect(() => {
    const isWacSubmitting = isCreatingBaseRun || isCreatingManyBaseRuns
    dispatch({
      type: 'setIsWacSubmitting',
      key: 'RLHFPLUS',
      value: isWacSubmitting,
    })
  }, [dispatch, isCreatingBaseRun, isCreatingManyBaseRuns])

  const queryModel = async (input: {
    query: string
    model: string
    chatHistory: { id: string; text: string; acceptedResponse: string }[]
    perModelCallCount?: number
    meta?: Record<string, any>
  }) => {
    const count = input?.perModelCallCount ?? 1
    const body = JSON.stringify({
      baseRunId: baseRun.id,
      chatHistory: input.chatHistory,
      query: input.query,
      model: input.model,
      meta: input.meta,
      preamble: config?.conversationPreambleBaseVariableId
        ? normalizedConversationData.preamble
        : null,
    })

    const rlhfEndpoint = enableMtcRlhfNonCloudfareRoute
      ? `${NEXT_PUBLIC_MTC_RLHF_WITHOUT_CLOUDFARE_URL}/query-model`
      : '/api/wacs/rlhf/query-model'

    const results = await pTimes(
      count < 1 ? 1 : count,
      async () =>
        await fetch(rlhfEndpoint, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        })
          .then(async (res) => {
            try {
              return res.json()
            } catch (error) {
              const message = await res.text()
              logger.error('Failed to query model.', {
                reqBody: body,
                responseContent: message,
                error,
              })
              return {
                message: message,
              }
            }
          })
          .catch((error) => {
            logger.error('Failed to query model.', {
              reqBody: body,
              error,
            })
            return {
              message: error.message,
            }
          })
    )
    return results.reduce((acc, curr) => {
      if (!Array.isArray(curr)) {
        showSnackbar({
          message: `Unable to fetch response for model: ${input.model}. \n${curr?.message ?? ''}`,
          variant: 'error',
        })
        logger.error(`Error fetching model: ${input.model} response.`, {
          message: curr.message ?? '',
        })
        return acc
      }
      return [...acc, ...curr]
    }, [])
  }

  const randomizeModels = (models: TModelConfig[]) => {
    if (config.allowRandomSampling && config.numOfModelsToSample) {
      return sampleSize(config.numOfModelsToSample, models)
    }
    return models
  }

  const randomizeModelResponses = (
    responses: {
      model: string
      index: number
      message: string
      metaFields: { value: any; baseVariableId: string }[]
    }[]
  ) => {
    if (config.allowRandomizeModelResponses) {
      return shuffle(responses)
    }
    return responses
  }

  const resubmitPrompt = async (failedPrompt: { id: string; text: string }) => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS',
      value: config.allowEndingWithoutResponse ?? false,
    })
    setisFetchingResponses(true)
    setIsLastResponseEmpty(false)
    const modelResponses = await pMap(randomizeModels(config.models ?? []), async (model) => {
      const metaParams = model.responseMetaParams ?? []
      const responses = await queryModel({
        query: failedPrompt.text,
        model: model.name,
        chatHistory:
          flatten(
            normalizedPrompts?.filter((prompt: { id: string }) => failedPrompt.id !== prompt.id) // Exclude failed prompt
          ) ?? [],
        perModelCallCount: model?.numOfCalls,
        meta: {
          ...model.params.reduce((acc, param) => ({ ...acc, [param.name]: param.value }), {}),
          ...(config.conversationModelTempBaseVariableId &&
          normalizedConversationData.modelTemperature
            ? {
                temperature: Number.parseFloat(`${normalizedConversationData.modelTemperature}`),
              }
            : {}),
        },
      })
      return responses.map(
        (response: { text: string; model?: string; meta?: any }, index: number) => ({
          message: response.text,
          model: model.name,
          index: index + 1,
          metaFields: metaParams
            .map((p) => ({
              value: response.meta?.[p.key],
              baseVariableId: p.baseVariableId,
            }))
            .filter((p) => p.baseVariableId),
        })
      )
    })
    setisFetchingResponses(false)

    if (modelResponses && flatten(modelResponses).every((res) => res.message)) {
      // Create responses
      await createBaseRuns({
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: failedPrompt.id,
        initialValuesArray: randomizeModelResponses(flatten(modelResponses)).map(
          (response, index) => [
            {
              baseVariableId: config?.responseTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseOriginalTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseIndexBaseVariableId as string,
              value: index + 1,
            },
            {
              baseVariableId: config?.responseModelBaseVariableId as string,
              value: response.model,
            },
            ...response.metaFields,
          ]
        ),
        sourceStepRunId: stepRun.id,
      })
    } else if (modelResponses) {
      setIsLastResponseEmpty(true)
    }
  }

  const handlePromptSubmission = async (text: string) => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS',
      value: config.allowEndingWithoutResponse ?? false,
    })
    setisFetchingResponses(true)
    setIsLastResponseEmpty(false)
    const modelResponses = await pMap(randomizeModels(config.models ?? []), async (model) => {
      const metaParams = model.responseMetaParams ?? []
      const responses = await queryModel({
        query: text,
        model: model.name,
        chatHistory: flatten(normalizedPrompts) ?? [],
        perModelCallCount: model?.numOfCalls,
        meta: {
          ...model.params.reduce((acc, param) => ({ ...acc, [param.name]: param.value }), {}),
          ...(config.conversationModelTempBaseVariableId &&
          normalizedConversationData.modelTemperature
            ? {
                temperature: Number.parseFloat(`${normalizedConversationData.modelTemperature}`),
              }
            : {}),
        },
      })
      return responses.map(
        (response: { text: string; model?: string; meta?: any }, index: number) => ({
          message: response.text,
          model: model.name,
          index: index + 1,
          metaFields: metaParams
            .map((p) => ({
              value: response.meta?.[p.key],
              baseVariableId: p.baseVariableId,
            }))
            .filter((p) => p.baseVariableId),
        })
      )
    })
    setisFetchingResponses(false)

    const prompt = await createBaseRun({
      baseId: config?.promptsBaseId as string,
      parentBaseRunId: baseRun.id,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: config?.promptTextBaseVariableId as string,
          value: promptText,
        },
        {
          baseVariableId: config?.promptIndexBaseVariableId as string,
          value: (prompts?.length ?? 0) + 1,
        },
      ],
    })

    if (modelResponses && flatten(modelResponses).every((res) => res.message)) {
      // Create responses
      await createBaseRuns({
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: prompt.id,
        initialValuesArray: randomizeModelResponses(flatten(modelResponses)).map(
          (response, index) => [
            {
              baseVariableId: config?.responseTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseOriginalTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseModelBaseVariableId as string,
              value: response.model,
            },
            {
              baseVariableId: config?.responseIndexBaseVariableId as string,
              value: index + 1,
            },
            ...response.metaFields,
          ]
        ),
        sourceStepRunId: stepRun.id,
      })
    }

    setPromptText('')
  }

  const handleDeletePrompt = async (prompt: { id: string; responseId: string }) => {
    if (
      !window.confirm(
        'You are about to delete this prompt and its responses. \nThis will also delete subsequent prompts and responses if any. \nAre you sure?'
      )
    )
      return

    const currentPromptCreatedAt = prompts?.find((p) => p.id === prompt.id)?.createdAt ?? ''
    const subsequentPrompts = prompts?.filter(
      (p) => p.createdAt > currentPromptCreatedAt && p.id !== prompt.id
    )
    const promptsResponses = await Promise.all(
      [...(subsequentPrompts || []), prompt]?.map((p) =>
        reactQueryContext.fetchQuery([
          'baseRun.findChildBaseRuns',
          {
            baseId: config?.responsesBaseId as string,
            parentBaseRunId: p.id as string,
          },
        ])
      )
    )
    const baseRunsIdsToDelete = [
      prompt.id,
      ...(subsequentPrompts || []).map((p) => p.id),
      ...flatten(promptsResponses).map((r) => r.id),
    ]
    await deleteBaseRuns({
      baseRunIds: baseRunsIdsToDelete,
      stepRunId: stepRun.id,
    })
    reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
      [
        'baseRun.findChildBaseRuns',
        {
          baseId: config?.promptsBaseId as string,
          parentBaseRunId: baseRun.id,
        },
      ],
      (prevData) => {
        if (!prevData) return
        return prevData.filter((baseRun) => !baseRunsIdsToDelete.includes(baseRun.id))
      }
    )
  }

  const handleRefetchPromptResponses = async (prompt: { id: string; text: string }) => {
    if (
      !window.confirm(
        "You are about to delete this prompt's responses and fetch new ones. \nAre you sure?"
      )
    )
      return
    setisFetchingResponses(true)

    await updateBaseRunVariables({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: prompt.id,
          baseVariableId: config?.promptResponseBaseVariableId as string,
          value: null,
        },
        {
          baseRunId: prompt.id,
          baseVariableId: config?.promptAcceptedModelBaseVariableId as string,
          value: null,
        },
        {
          baseRunId: prompt.id,
          baseVariableId: config?.responseIdBaseVariableId as string,
          value: null,
        },
      ],
    })

    const promptsResponses = await Promise.all(
      [{ id: prompt.id }]?.map((p) =>
        reactQueryContext.fetchQuery([
          'baseRun.findChildBaseRuns',
          {
            baseId: config?.responsesBaseId as string,
            parentBaseRunId: p.id as string,
          },
        ])
      )
    )
    const baseRunsIdsToDelete = [...flatten(promptsResponses).map((r) => r.id)]
    if (baseRunsIdsToDelete.length > 0) {
      await deleteBaseRuns({
        baseRunIds: baseRunsIdsToDelete,
        stepRunId: stepRun.id,
      })
    }
    await resubmitPrompt(prompt)
  }

  const rhlfContextValues = {
    stepRunId: stepRun.id,
    baseRunId: baseRun.id,
    config,
    isReadOnly,
    firstManualStepRunCreatedAt,
    numOfPrompts: normalizedPrompts?.length ?? 0,
    visibleResponseIndices,
    responseMetadataValidationFailures,
    promptMetadataValidationFailures,
    setIsUpdated,
    updateBaseRunVariables: (
      data: { baseRunId: string; baseVariableId: string; value: unknown }[]
    ) => updateBaseRunVariables({ stepRunId: stepRun.id, data: [...data] as any }),
    resubmitPrompt,
    deletePrompt: handleDeletePrompt,
    submitPrompt: handlePromptSubmission,
    refetchPromptResponses: handleRefetchPromptResponses,
    loaders: {
      isFetchingResponses,
      isCreatingManyBaseRuns,
      isCreatingBaseRun,
      isDeletingBaseRuns,
      isUpdatingBaseVariables,
    },
  }

  const isMinMaxTurnsVariablesSet =
    normalizedConversationData.minTurn && normalizedConversationData.maxTurn
  const numOfResponsesPerPrompt =
    config.models?.reduce((acc, curr) => acc + (curr.numOfCalls ?? 1), 0) ?? 0

  return (
    <div
      className={classNames(
        'box-border grid h-full w-full bg-white p-2',
        showInfoSection ? 'grid grid-cols-[30%_70%]' : null,
        isUpdatingBaseVariables ? 'cursor-progress' : null
      )}>
      <ThemeProvider theme={theme}>
        <RLHFContext.Provider value={rhlfContextValues as RLHFContextDataType}>
          {showInfoSection ? (
            <section
              className='flex h-full flex-col overflow-auto'
              style={{ borderRight: '1px solid grey' }}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(_, tab) => setTab(tab)} aria-label='lab API tabs example'>
                    <Tab label='Instructions' value='1' />
                    <Tab label='Preamble' value='2' />
                  </TabList>
                </Box>
                <TabPanel value='1'>
                  <Markdown
                    className='my-3 overflow-auto'
                    components={{
                      p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                    }}>
                    {
                      (normalizedConversationData.instruction ??
                        'No instructions provided.') as string
                    }
                  </Markdown>
                </TabPanel>
                <TabPanel value='2'>
                  <Markdown
                    className='my-3 overflow-auto'
                    components={{
                      p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                    }}>
                    {(normalizedConversationData.preamble ?? 'No preamble provided.') as string}
                  </Markdown>
                </TabPanel>
              </TabContext>
            </section>
          ) : null}
          <section className='ml-2 flex h-full flex-col overflow-auto'>
            <div className='text-paragraphs flex items-center py-3 text-sm font-bold'>
              <IconButton
                onClick={() => {
                  setShowInfoSection(!showInfoSection)
                }}
                className=' text-gray-400'>
                <ViewSidebarIcon />
              </IconButton>
              <div className='flex'>
                <span>Your conversations (</span>
                {config?.minMaxTurns || isMinMaxTurnsVariablesSet ? (
                  <span>
                    Turns count:{' '}
                    {isMinMaxTurnsVariablesSet
                      ? normalizedConversationData.minTurn
                      : undefined ?? config.minMaxTurns?.[0] ?? 1}{' '}
                    Min,{' '}
                    {isMinMaxTurnsVariablesSet
                      ? normalizedConversationData.maxTurn
                      : undefined ?? config.minMaxTurns?.[1] ?? 1}{' '}
                    Max
                  </span>
                ) : null}
                <span>)</span>
                <span className='ml-2'>Token count: {tokenCount}</span>
              </div>

              <div className='ml-2'>
                <CustomMultiSelect
                  label='Visible Responses'
                  options={[
                    { key: 'Response A', value: 1 },
                    { key: 'Response B', value: 2 },
                    { key: 'Response C', value: 3 },
                    { key: 'Response D', value: 4 },
                    { key: 'Response E', value: 5 },
                  ].slice(0, numOfResponsesPerPrompt)}
                  selectedKeys={visibleResponseIndices}
                  onSelect={(selectedKeys) => setVisibleResponseIndices(selectedKeys as number[])}
                />
              </div>
              <div className='ml-2 text-gray-400'>
                {isUpdated ? 'Not Saved' : isUpdatingBaseVariables ? 'Saving...' : 'Saved!'}
              </div>
              {collapseAll ? (
                <IconButton
                  onClick={() => {
                    setCollapseAll(!collapseAll)
                  }}
                  className='ml-2 text-gray-400'>
                  <UnfoldMoreIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setCollapseAll(!collapseAll)
                  }}
                  className='ml-2 text-gray-400'>
                  <UnfoldLessIcon />
                </IconButton>
              )}
            </div>
            <div className='box-border h-[calc(100%-50px)] w-full overflow-auto'>
              <div className=''>
                {normalizedPrompts?.map((prompt, index) => (
                  <Turn key={index} prompt={{ ...prompt, index }} collapsed={collapseAll} />
                ))}

                {isFetchingResponses || isCreatingBaseRun || isCreatingManyBaseRuns ? (
                  <div className='mb-2'>
                    <div className='max-w-1/2 relative  ml-auto mt-2 mr-1 w-fit min-w-[20%] max-w-[50%] overflow-auto rounded bg-[#F5F5F7] py-2 px-4 text-sm'>
                      Generating responses...
                    </div>
                  </div>
                ) : null}
              </div>
              {isLastResponseEmpty ? (
                <div className='text-paragraphs py-2'>
                  Blank response from Model. You can refresh the page & retry or End Conversation.
                </div>
              ) : null}

              {config.disableAddNewPrompt ||
              (prompts?.length ?? 0) >=
                (isMinMaxTurnsVariablesSet
                  ? normalizedConversationData.maxTurn
                  : undefined ?? config.minMaxTurns?.[1] ?? 1) ? null : (
                <div className='flex w-full max-w-[70%] items-center gap-3'>
                  <TextField
                    placeholder='Enter your prompt here...'
                    value={promptText}
                    onChange={(e) => {
                      setPromptText(e.target.value)
                    }}
                    multiline
                    fullWidth
                    maxRows={15}
                    minRows={1}
                    disabled={
                      Boolean(unsubmittedPrompt) ||
                      isCreatingBaseRun ||
                      isFetchingResponses ||
                      isCreatingManyBaseRuns ||
                      isReadOnly
                    }
                  />
                  <Button
                    variant='contained'
                    onClick={() => handlePromptSubmission(promptText)}
                    disabled={
                      isCreatingBaseRun ||
                      isFetchingResponses ||
                      isCreatingManyBaseRuns ||
                      !promptText ||
                      Boolean(unsubmittedPrompt) ||
                      isReadOnly
                    }>
                    Send
                  </Button>
                </div>
              )}
              {config.hidePreambleText ? null : (
                <div className='border-main flex items-center'>
                  Read Preamble{' '}
                  <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                    <div>
                      <Tooltip
                        open={tooltipOpen}
                        onClose={() => setTooltipOpen(false)}
                        title={
                          <p className='whitespace-pre-wrap'>
                            {normalizedConversationData.preamble}
                          </p>
                        }
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow>
                        <IconButton aria-label='preamble-info' onClick={() => setTooltipOpen(true)}>
                          <InfoIcon width={20} height={20} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                </div>
              )}
            </div>
          </section>
        </RLHFContext.Provider>
      </ThemeProvider>
    </div>
  )
}

export { RLHFWACPlus }
