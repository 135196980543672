import { SVGProps } from 'react'
function History(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='25'
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9536 5.5293C14.5059 5.5293 14.9536 5.97701 14.9536 6.5293V12.5293C14.9536 13.0816 14.5059 13.5293 13.9536 13.5293C13.4013 13.5293 12.9536 13.0816 12.9536 12.5293V6.5293C12.9536 5.97701 13.4013 5.5293 13.9536 5.5293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0874 12.0296C13.3634 11.5512 13.975 11.3871 14.4533 11.6631L19.6533 14.6631C20.1317 14.9391 20.2958 15.5506 20.0198 16.029C19.7438 16.5074 19.1323 16.6715 18.6539 16.3955L13.4539 13.3955C12.9755 13.1195 12.8114 12.508 13.0874 12.0296Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.92859 2.9917C2.48087 2.9917 2.92859 3.43941 2.92859 3.9917V7.9917H6.92859C7.48087 7.9917 7.92859 8.43941 7.92859 8.9917C7.92859 9.54398 7.48087 9.9917 6.92859 9.9917H1.92859C1.3763 9.9917 0.928589 9.54398 0.928589 8.9917V3.9917C0.928589 3.43941 1.3763 2.9917 1.92859 2.9917Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6153 0.760596C13.9433 0.296779 16.3566 0.533848 18.5499 1.44181C20.7432 2.34978 22.6179 3.88784 23.9369 5.86144C25.2559 7.83504 25.9599 10.1555 25.9599 12.5293C25.9599 14.9031 25.2559 17.2236 23.9369 19.1972C22.6179 21.1708 20.7432 22.7088 18.5499 23.6168C16.3566 24.5248 13.9433 24.7618 11.6153 24.298C9.28721 23.8342 7.14903 22.6903 5.47119 21.0111C5.08083 20.6204 5.08109 19.9873 5.47177 19.5969C5.86245 19.2065 6.49562 19.2068 6.88598 19.5975C8.28418 20.9968 10.066 21.95 12.006 22.3366C13.9461 22.7231 15.9571 22.5255 17.7849 21.7689C19.6126 21.0122 21.1749 19.7305 22.2741 18.0859C23.3732 16.4412 23.9599 14.5075 23.9599 12.5293C23.9599 10.5511 23.3732 8.61742 22.2741 6.97275C21.1749 5.32808 19.6126 4.04636 17.7849 3.28973C15.9571 2.53309 13.9461 2.33553 12.006 2.72205C10.066 3.10856 8.28418 4.06178 6.88598 5.46112L6.88465 5.46245L2.63465 9.69995C2.24355 10.0899 1.61039 10.089 1.22044 9.69787C0.830493 9.30677 0.831425 8.6736 1.22252 8.28365L5.47119 4.04748C5.47142 4.04726 5.47097 4.04771 5.47119 4.04748C7.14893 2.36864 9.28752 1.22435 11.6153 0.760596Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default History
