import type { OpenAI } from '@invisible/thirdparty/openai'

export const BASES = {
  PROMPTS: {
    ID: '12de70d9-e15e-45ed-a28f-acbaf657a7b8',
    BASE_VARIABLES: {
      PROMPT_TEXT: 'bc0018fb-d5c6-488c-a664-8f6f09cd60a5',
      PROMPT_INDEX: '505ddab9-0828-4de2-ac01-2d39a88c9e07',
      ACCEPTED_RESPONSE: 'b9c1d950-97be-45b2-ab7a-5305f67720d6',
      ACCEPTED_MODEL: '578350d2-05ec-4bd8-8a44-bbbd070ef9dc',
    },
  },
  RESPONSES: {
    ID: '0803d54a-296c-4eb9-8390-a900896a5763',
    BASE_VARIABLES: {
      INDEX: '5f008c39-afab-4679-84bb-15607c3460a0',
      TEXT: 'e376d1ed-22e9-4404-858c-0da6594160e5',
      ACCEPTED: 'd84159eb-3296-40af-b7c8-e2bf951bf0dd',
      CATEGORY: '70f480e1-3383-436c-8ebf-1edaf6b81769',
      SCORE: '5325a60e-94ed-442f-b34d-d3e28774486a',
      MODEL: 'bf068a46-9953-42a3-abf8-394c8263541c',
      RATIONALE: 'b03f4f42-f995-44af-8729-c48c54cd95a8',
    },
  },
} as const

export const MODELS = {
  'j2-jumbo-instruct': {
    id: 'j2-jumbo-instruct',
    name: 'A12I J2 Jumbo Instruct',
    executor: async ({ response_count, prompt }: { response_count: number; prompt: string }) => {
      try {
        const response = await fetch('/api/ai21/instruct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'j2-jumbo-instruct',
            prompt,
            response_count,
          }),
        }).then((res) => res.json())
        return response.completions.map((r: { data: { text: string } }) => r.data.text) as string[]
      } catch (e) {
        return []
      }
    },
  },

  'j2-grande-instruct': {
    id: 'j2-grande-instruct',
    name: 'A12I J2 Grande Instruct',
    executor: async ({ response_count, prompt }: { response_count: number; prompt: string }) => {
      try {
        const response = await fetch('/api/ai21/instruct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'j2-grande-instruct',
            prompt,
            response_count,
          }),
        }).then((res) => res.json())
        return response.completions.map((r: { data: { text: string } }) => r.data.text) as string[]
      } catch (e) {
        return []
      }
    },
  },
  'gpt3.5-turbo': {
    id: 'gpt3.5-turbo',
    name: 'OpenAI GPT-3.5',
    executor: async ({ response_count, prompt }: { response_count: number; prompt: string }) => {
      try {
        const response = (await fetch('/api/openai/createChatCompletion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              { role: 'user', content: prompt },
            ],
            n: response_count,
          }),
        }).then((res) => res.json())) as OpenAI.Chat.Completions.ChatCompletion
        return response.choices.map((c) => c.message?.content) as string[]
      } catch (e) {
        return []
      }
    },
  },
  'sophos-1': {
    id: 'sophos-1',
    name: 'Text Cortext Sophos 1',
    executor: async ({ response_count, prompt }: { response_count: number; prompt: string }) => {
      try {
        const response = await fetch('/api/textCortext/auto-complete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt,
            response_count,
          }),
        }).then((res) => res.json())
        return response.data.outputs.map((o: { index: number; text: string }) => o.text) as string[]
      } catch (e) {
        return []
      }
    },
  },
} as const
