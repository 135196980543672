import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro'
import { keys } from 'lodash'

import { DATE_PRESETS, lastXDaysRegex, timeRangeOptions } from './DateFilter'

type TValue = {
  start_date?: string
  end_date?: string
} | null

const DateMathOrDateRangeSwitcher = ({
  value,
  handleChange,
}: {
  value: TValue
  handleChange: (value: TValue) => void
}) => {
  const isDateMathExpression =
    value && value.start_date && value.end_date
      ? value.start_date.includes('now') || value.end_date.includes('now')
      : false
  // Capture the last X days from the date math expression excluding 7 and 30 days
  const lastXDaysValue =
    isDateMathExpression && value && lastXDaysRegex.test(value.start_date ?? '')
      ? value.start_date?.match(lastXDaysRegex)?.groups?.days
      : null

  const getAutocompleteValue = () => {
    if (!value || !isDateMathExpression) return 'Custom Value (Default)'

    return (
      keys(DATE_PRESETS).find((k) => {
        const [start, end] = DATE_PRESETS[k as keyof typeof DATE_PRESETS]
        return start === value.start_date && end === value.end_date
      }) ?? 'Last X days'
    )
  }

  const getDatePickerValue = (): [Date | null, Date | null] => {
    if (!value || isDateMathExpression || !value.start_date || !value.end_date) return [null, null]

    return [new Date(value.start_date), new Date(value.end_date)]
  }

  const handleTimeRangeSelection = (_: unknown, newValue: string) => {
    if (keys(DATE_PRESETS).includes(newValue)) {
      const dateMathExpression = DATE_PRESETS[newValue as keyof typeof DATE_PRESETS]
      handleChange({
        start_date: dateMathExpression[0],
        end_date: dateMathExpression[1],
      })
      // Custom date range
    } else {
      handleChange(null)
    }
  }

  return (
    <Stack gap='32px'>
      <Stack direction='row' justifyContent='space-between' alignItems='center' gap='16px'>
        <Typography>Select a time range</Typography>
        <Autocomplete
          options={timeRangeOptions}
          renderInput={(params) => <TextField {...params} />}
          size='small'
          sx={{
            flexGrow: 1,
          }}
          disableClearable
          value={getAutocompleteValue() ?? undefined}
          onChange={handleTimeRangeSelection}
        />
        {getAutocompleteValue() === 'Last X days' ? (
          <TextField
            size='small'
            type='number'
            sx={{ width: '100px' }}
            value={lastXDaysValue}
            onChange={(e) => {
              // Ensure the value is a positive number
              const value = Math.abs(Number(e.target.value))
              handleChange({
                start_date: `now-${value}d`,
                end_date: 'now',
              })
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        ) : null}
      </Stack>

      {!isDateMathExpression ? (
        <>
          <Divider />
          <DateTimeRangePicker
            localeText={{ start: 'MM/DD/YYYY hh:mm', end: 'MM/DD/YYYY hh:mm' }}
            slotProps={{
              textField: {
                size: 'small',
                InputProps: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            onChange={(date) => {
              if (date[0] && date[1])
                handleChange({
                  start_date: (date[0] as Date).toISOString(),
                  end_date: (date[1] as Date).toISOString(),
                })
            }}
            value={getDatePickerValue()}
          />
        </>
      ) : null}
    </Stack>
  )
}

export { DateMathOrDateRangeSwitcher }
