import { SVGProps } from 'react'
function RobotHead(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.25 13.75a.916.916 0 100 1.832.916.916 0 000-1.832zm-6.417-.917a.917.917 0 00-.916.917v1.833a.917.917 0 001.833 0V13.75a.917.917 0 00-.917-.917zm18.334 0a.917.917 0 00-.917.917v1.833a.917.917 0 001.833 0V13.75a.917.917 0 00-.916-.917zm-4.584-6.417h-3.667V5.243a1.833 1.833 0 00.917-1.577 1.833 1.833 0 00-3.667 0 1.833 1.833 0 00.917 1.577v1.173H6.416a2.75 2.75 0 00-2.75 2.75v8.25a2.75 2.75 0 002.75 2.75h9.167a2.75 2.75 0 002.75-2.75v-8.25a2.75 2.75 0 00-2.75-2.75zM12.576 8.25l-.458 1.833H9.882L9.423 8.25h3.153zm3.924 9.166a.917.917 0 01-.917.917H6.417a.917.917 0 01-.917-.917v-8.25a.917.917 0 01.917-.916h1.118l.715 2.97a.917.917 0 00.916.696h3.667a.917.917 0 00.917-.696l.715-2.97h1.118a.917.917 0 01.917.916v8.25zm-2.75-3.666a.916.916 0 100 1.831.916.916 0 000-1.831z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RobotHead
