import { formatUnit } from '@invisible/common/helpers'
import { ITooltipLabelConfig, ITooltipValueConfig } from '@invisible/common/types'
import React from 'react'

const capitalizeWords = (string: string) => string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())

type TooltipProps = {
  color?: string
  headers: string[]
  dataRows: (string | number)[][]
}

const TableTooltip = ({ color, headers, dataRows }: TooltipProps) => (
  <table className='rounded-md border border-solid bg-white' style={{ borderColor: color }}>
    <thead>
      <tr className='font-bold'>
        {headers.map((header, index) => (
          <>
            <th>{header}</th>
            {index + 1 !== headers.length && <th></th>}
          </>
        ))}
      </tr>
    </thead>
    <tbody>
      {dataRows.map((dataRow) => (
        <tr>
          {dataRow.map((dataColumn, index) => (
            <>
              <td>{dataColumn}</td>
              {index + 1 !== dataRow.length && <td></td>}
            </>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

const withConfigAndFormatting =
  (TooltipComponent: React.FunctionComponent<TooltipProps>) =>
  ({
    label,
    value,
    color,
    tooltipConfig,
  }: {
    label: string | number
    value: string | number
    color?: string
    tooltipConfig?: {
      labelConfig: ITooltipLabelConfig
      valueConfig: ITooltipValueConfig
    }
  }) => {
    const labelHeader =
      tooltipConfig && tooltipConfig.labelConfig.labelType === 'Series'
        ? capitalizeWords(tooltipConfig?.labelConfig.labelValue.replace(/_/g, ' '))
        : tooltipConfig?.labelConfig.label

    const valueHeader = tooltipConfig?.valueConfig.label

    const formattedValue = formatUnit({
      postfixUnit: tooltipConfig?.valueConfig.postfixUnit,
      prefixUnit: tooltipConfig?.valueConfig.prefixUnit,
      value,
      valueType: tooltipConfig?.valueConfig.valueType ?? '',
    })
    return (
      <TooltipComponent
        headers={[labelHeader ?? '', valueHeader ?? '']}
        dataRows={[[label, formattedValue]]}
        color={color}
      />
    )
  }

const CustomChartToolTip = withConfigAndFormatting(TableTooltip)

export { CustomChartToolTip, TableTooltip }
