import { SVGProps } from 'react'
function Slack(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3559 13.4686C11.2042 13.4061 11.0417 13.3744 10.8777 13.375L10.8751 13.3751L9.62507 13.375V14.6277C9.62438 14.7917 9.65617 14.9542 9.71861 15.1058C9.78106 15.2575 9.87291 15.3953 9.98888 15.5112C10.1048 15.6272 10.2426 15.7191 10.3943 15.7815C10.5459 15.8439 10.7084 15.8757 10.8724 15.8751L10.8777 15.875C11.0417 15.8757 11.2042 15.8439 11.3559 15.7815C11.5075 15.7191 11.6453 15.6272 11.7613 15.5112C11.8772 15.3953 11.9691 15.2575 12.0315 15.1058C12.094 14.9542 12.1258 14.7917 12.1251 14.6277L12.1251 14.6224C12.1257 14.4584 12.094 14.2959 12.0315 14.1443C11.9691 13.9926 11.8772 13.8548 11.7613 13.7389C11.6453 13.6229 11.5075 13.531 11.3559 13.4686ZM10.8738 12.125C11.2024 12.1239 11.528 12.1876 11.8318 12.3127C12.136 12.438 12.4125 12.6223 12.6451 12.855C12.8778 13.0876 13.0621 13.3641 13.1874 13.6683C13.3123 13.9718 13.3761 14.2969 13.3751 14.625C13.3761 14.9532 13.3123 15.2783 13.1874 15.5818C13.0621 15.886 12.8778 16.1625 12.6451 16.3951C12.4125 16.6278 12.136 16.8121 11.8318 16.9374C11.5283 17.0623 11.2032 17.1261 10.8751 17.1251C10.5469 17.1261 10.2218 17.0623 9.91835 16.9374C9.6141 16.8121 9.33766 16.6278 9.105 16.3951C8.87233 16.1625 8.68805 15.886 8.56277 15.5818C8.43766 15.2779 8.37388 14.9524 8.37507 14.6238C8.37507 14.6234 8.37507 14.6229 8.37508 14.6224L9.00007 14.625H8.37507V14.6238V12.75C8.37507 12.4049 8.65489 12.125 9.00007 12.125H10.8738ZM10.8738 12.125C10.8734 12.125 10.8729 12.1251 10.8724 12.1251L10.8751 12.75V12.125H10.8738Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4686 6.64423C13.4062 6.79588 13.3744 6.95839 13.3751 7.12239L13.3751 7.12501L13.3751 8.37501H14.6277C14.7917 8.3757 14.9542 8.34391 15.1059 8.28147C15.2575 8.21902 15.3953 8.12717 15.5113 8.0112C15.6272 7.89523 15.7191 7.75745 15.7815 7.6058C15.844 7.45415 15.8758 7.29164 15.8751 7.12763L15.8751 7.12239C15.8757 6.95839 15.844 6.79588 15.7815 6.64423C15.7191 6.49258 15.6272 6.35479 15.5113 6.23882C15.3953 6.12286 15.2575 6.031 15.1059 5.96856C14.9542 5.90612 14.7917 5.87432 14.6277 5.87501L14.6225 5.87503C14.4584 5.87434 14.2959 5.90612 14.1443 5.96856C13.9926 6.031 13.8549 6.12286 13.7389 6.23882C13.6229 6.35479 13.5311 6.49258 13.4686 6.64423ZM12.1251 7.12623C12.1239 6.79767 12.1877 6.47212 12.3128 6.16829C12.438 5.86404 12.6223 5.58761 12.855 5.35494C13.0877 5.12228 13.3641 4.93799 13.6684 4.81271C13.9718 4.68776 14.2969 4.62398 14.6251 4.62501C14.9532 4.62398 15.2783 4.68776 15.5818 4.81271C15.886 4.93799 16.1625 5.12228 16.3951 5.35494C16.6278 5.58761 16.8121 5.86404 16.9374 6.16829C17.0623 6.47174 17.1261 6.79687 17.1251 7.12501C17.1261 7.45316 17.0623 7.77828 16.9374 8.08173C16.8121 8.38598 16.6278 8.66242 16.3951 8.89508C16.1625 9.12775 15.886 9.31204 15.5818 9.43732C15.278 9.56242 14.9524 9.62621 14.6239 9.62501C14.6234 9.62501 14.6229 9.62501 14.6225 9.62501L14.6251 9.00001V9.62501H14.6239H12.7501C12.4049 9.62501 12.1251 9.34519 12.1251 9.00001V7.12623ZM12.1251 7.12623C12.1251 7.1267 12.1251 7.12717 12.1251 7.12763L12.7501 7.12501H12.1251V7.12623Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.64425 4.53147C6.7959 4.59391 6.95842 4.62571 7.12242 4.62502L7.12504 4.62501L8.37504 4.62502V3.3724C8.37573 3.20839 8.34394 3.04588 8.28149 2.89423C8.21905 2.74258 8.12719 2.6048 8.01123 2.48883C7.89526 2.37286 7.75747 2.28101 7.60582 2.21856C7.45417 2.15612 7.29166 2.12432 7.12766 2.12501L7.12242 2.12503C6.95842 2.12435 6.7959 2.15612 6.64425 2.21856C6.4926 2.28101 6.35482 2.37286 6.23885 2.48883C6.12288 2.6048 6.03103 2.74258 5.96858 2.89423C5.90614 3.04588 5.87434 3.20839 5.87503 3.3724L5.87505 3.37764C5.87437 3.54164 5.90614 3.70415 5.96858 3.8558C6.03103 4.00745 6.12288 4.14524 6.23885 4.2612C6.35482 4.37717 6.4926 4.46903 6.64425 4.53147ZM7.12626 5.87502C6.7977 5.87621 6.47214 5.81242 6.16832 5.68732C5.86407 5.56204 5.58763 5.37775 5.35497 5.14509C5.1223 4.91242 4.93801 4.63599 4.81273 4.33174C4.68779 4.02829 4.624 3.70316 4.62503 3.37502C4.624 3.04687 4.68779 2.72175 4.81273 2.4183C4.93801 2.11404 5.1223 1.83761 5.35497 1.60495C5.58763 1.37228 5.86407 1.18799 6.16832 1.06271C6.47177 0.937766 6.79689 0.873981 7.12504 0.875012C7.45318 0.873981 7.77831 0.937766 8.08176 1.06271C8.38601 1.18799 8.66245 1.37228 8.89511 1.60495C9.12777 1.83761 9.31206 2.11404 9.43734 2.4183C9.56244 2.72212 9.62623 3.04768 9.62504 3.37624C9.62504 3.3767 9.62503 3.37717 9.62503 3.37764L9.00004 3.37502H9.62504V3.37624V5.25002C9.62504 5.59519 9.34522 5.87502 9.00004 5.87502H7.12626ZM7.12626 5.87502C7.12673 5.87501 7.12719 5.87501 7.12766 5.87501L7.12504 5.25002V5.87502H7.12626Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.53147 11.3558C4.59391 11.2042 4.62571 11.0416 4.62502 10.8776L4.62501 10.875L4.62502 9.62502H3.3724C3.20839 9.62433 3.04588 9.65612 2.89423 9.71856C2.74258 9.78101 2.6048 9.87286 2.48883 9.98883C2.37286 10.1048 2.28101 10.2426 2.21856 10.3942C2.15612 10.5459 2.12432 10.7084 2.12501 10.8724L2.12503 10.8776C2.12435 11.0416 2.15612 11.2042 2.21856 11.3558C2.28101 11.5075 2.37286 11.6452 2.48883 11.7612C2.6048 11.8772 2.74258 11.969 2.89423 12.0315C3.04588 12.0939 3.20839 12.1257 3.3724 12.125L3.37764 12.125C3.54164 12.1257 3.70415 12.0939 3.8558 12.0315C4.00745 11.969 4.14524 11.8772 4.2612 11.7612C4.37717 11.6452 4.46903 11.5075 4.53147 11.3558ZM5.87502 10.8738C5.87621 11.2024 5.81242 11.5279 5.68732 11.8317C5.56204 12.136 5.37775 12.4124 5.14509 12.6451C4.91242 12.8778 4.63599 13.062 4.33173 13.1873C4.02829 13.3123 3.70316 13.3761 3.37502 13.375C3.04687 13.3761 2.72175 13.3123 2.4183 13.1873C2.11404 13.062 1.83761 12.8778 1.60495 12.6451C1.37228 12.4124 1.18799 12.136 1.06271 11.8317C0.937766 11.5283 0.873981 11.2032 0.875012 10.875C0.873981 10.5469 0.937766 10.2217 1.06271 9.9183C1.18799 9.61404 1.37228 9.33761 1.60495 9.10494C1.83761 8.87228 2.11404 8.68799 2.4183 8.56271C2.72212 8.43761 3.04768 8.37382 3.37624 8.37502C3.3767 8.37502 3.37717 8.37502 3.37764 8.37502L3.37502 9.00002V8.37502H3.37624H5.25002C5.59519 8.37502 5.87502 8.65484 5.87502 9.00002V10.8738ZM5.87502 10.8738C5.87501 10.8733 5.87501 10.8729 5.87501 10.8724L5.25002 10.875H5.87502V10.8738Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.37505 7.12503C8.37505 6.79351 8.24335 6.47557 8.00893 6.24115C7.77451 6.00673 7.45656 5.87502 7.12504 5.87502L3.37505 5.87503C3.04353 5.87503 2.72559 6.00673 2.49117 6.24115C2.25675 6.47557 2.12505 6.79351 2.12505 7.12503C2.12505 7.45655 2.25675 7.7745 2.49117 8.00892C2.72559 8.24334 3.0435 8.37502 3.37502 8.37502L8.37505 8.37503V7.12503ZM8.89282 5.35727C9.36166 5.82611 9.62505 6.46199 9.62505 7.12503V9.00003C9.62505 9.34521 9.34523 9.62503 9.00005 9.62503H3.37505C2.71201 9.62503 2.07612 9.36164 1.60728 8.8928C1.13844 8.42396 0.875049 7.78807 0.875049 7.12503C0.875049 6.46199 1.13844 5.82611 1.60728 5.35727C2.07612 4.88842 2.71201 4.62503 3.37505 4.62503L7.12504 4.62501C7.78808 4.62501 8.42398 4.88842 8.89282 5.35727Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.875 8.37503C11.2066 8.37503 11.5245 8.24334 11.7589 8.00892C11.9934 7.7745 12.1251 7.45653 12.1251 7.12501L12.125 3.37503C12.125 3.04351 11.9934 2.72557 11.7589 2.49115C11.5245 2.25673 11.2066 2.12503 10.875 2.12503C10.5435 2.12503 10.2256 2.25673 9.99117 2.49115C9.75675 2.72557 9.62504 3.0435 9.62504 3.37502L9.62505 8.37503H10.875ZM12.6428 8.8928C12.174 9.36164 11.5381 9.62503 10.875 9.62503H9.00005C8.65487 9.62503 8.37505 9.34521 8.37505 9.00003V3.37503C8.37505 2.71199 8.63844 2.07611 9.10728 1.60727C9.57612 1.13842 10.212 0.875033 10.875 0.875033C11.5381 0.875033 12.174 1.13842 12.6428 1.60727C13.1117 2.07611 13.375 2.71199 13.375 3.37503L13.3751 7.12501C13.3751 7.78805 13.1117 8.42396 12.6428 8.8928Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.62505 10.875C9.62505 11.2066 9.75675 11.5245 9.99117 11.7589C10.2256 11.9933 10.5435 12.125 10.8751 12.125L14.625 12.125C14.9566 12.125 15.2745 11.9933 15.5089 11.7589C15.7434 11.5245 15.875 11.2066 15.875 10.875C15.875 10.5435 15.7434 10.2256 15.5089 9.99115C15.2745 9.75673 14.9566 9.62501 14.6251 9.62501L9.62505 9.62503V10.875ZM9.10728 12.6428C8.63844 12.174 8.37505 11.5381 8.37505 10.875L8.37505 9.00003C8.37505 8.65485 8.65487 8.37503 9.00005 8.37503H14.625C15.2881 8.37503 15.924 8.63842 16.3928 9.10727C16.8617 9.57611 17.125 10.212 17.125 10.875C17.125 11.5381 16.8617 12.174 16.3928 12.6428C15.924 13.1116 15.2881 13.375 14.625 13.375L10.8751 13.3751C10.212 13.3751 9.57612 13.1116 9.10728 12.6428Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.12505 9.62503C6.79353 9.62503 6.47559 9.75673 6.24117 9.99115C6.00675 10.2256 5.87502 10.5435 5.87502 10.875L5.87505 14.625C5.87505 14.9566 6.00675 15.2745 6.24117 15.5089C6.47559 15.7433 6.79353 15.875 7.12505 15.875C7.45657 15.875 7.77451 15.7433 8.00893 15.5089C8.24335 15.2745 8.37507 14.9566 8.37507 14.625L8.37505 9.62503H7.12505ZM5.35728 9.10727C5.82612 8.63842 6.46201 8.37503 7.12505 8.37503L9.00005 8.37503C9.34523 8.37503 9.62505 8.65485 9.62505 9.00003V14.625C9.62505 15.2881 9.36166 15.924 8.89282 16.3928C8.42398 16.8616 7.78809 17.125 7.12505 17.125C6.46201 17.125 5.82612 16.8616 5.35728 16.3928C4.88844 15.924 4.62505 15.2881 4.62505 14.625L4.62501 10.875C4.62501 10.212 4.88844 9.57611 5.35728 9.10727Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Slack
