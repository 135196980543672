import { z } from '@invisible/zod'

const schema = z
  .object({
    type: z.enum(['custom', 'named']),
    name: z.string().optional(),
    src: z.string().optional(),
    settings: z
      .object({
        width: z.number(),
        height: z.number(),
      })
      .strict()
      .optional(),
  })
  .superRefine((schema, ctx) => {
    if (schema.type === 'custom' && !schema.settings) {
      ctx.addIssue({
        message: 'settings for the custom icon must be provided',
        code: z.ZodIssueCode.custom,
        fatal: true,
      })
      return
    }

    if (schema.type === 'custom' && !schema.src) {
      ctx.addIssue({
        message: 'src for the custom icon must be provided',
        code: z.ZodIssueCode.custom,
        fatal: true,
      })
      return
    }

    if (schema.type === 'named' && !schema.name) {
      ctx.addIssue({
        message: 'a name must be provided for named icon',
        code: z.ZodIssueCode.custom,
      })
    }
  })

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
