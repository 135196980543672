import { SVGProps } from 'react'

function BbotOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15 0C6.73469 0 0 6.73469 0 15C0 16.4082 0.183673 17.7551 0.55102 19.102C0.734694 19.6531 0.857143 20.1429 1.04082 20.5714C1.16327 20.8776 1.28571 21.1837 1.40816 21.4286C1.71429 22.0408 2.02041 22.6531 2.38776 23.2041C5.20408 27.4286 9.85714 29.9388 14.9388 29.9388C23.2653 30 30 23.2653 30 15C30 6.73469 23.2653 0 15 0ZM15 27.9184C10.898 27.9184 7.04082 25.9592 4.59184 22.6531H23.8776C24 22.6531 24.1224 22.5918 24.2449 22.5306C24.3061 22.4694 24.3673 22.3469 24.3673 22.1633C24.3673 19.102 23.3265 16.102 21.6122 13.8367C19.7755 11.5102 17.3878 10.2245 14.8163 10.2245C11.2653 10.2245 8.02041 12.7347 6.30612 16.7143C6.2449 16.898 5.93878 17.6939 5.87755 17.8776H8.38776C8.44898 17.6939 8.7551 16.898 8.87755 16.7143C9.12245 16.2245 9.42857 15.7959 9.73469 15.4286C11.1429 13.6531 12.9184 12.6735 14.8163 12.6735C16.7143 12.6735 18.4898 13.6531 19.8367 15.4286C20.8163 16.7143 21.4898 18.2449 21.8571 19.9592C21.8571 20.0204 21.8571 20.0816 21.7959 20.1429C21.7347 20.2041 21.6735 20.2041 21.6122 20.2041H3.18367C3.12245 20.0816 3.06122 19.9592 3 19.7755C2.81633 19.4082 2.69388 18.9796 2.57143 18.551C2.26531 17.3878 2.08163 16.2245 2.08163 15C2.08163 7.89796 7.89796 2.08163 15 2.08163C22.102 2.08163 27.9184 7.89796 27.9184 15C27.9184 22.102 22.102 27.9184 15 27.9184Z'
        fill='#1167E8'
      />
      <path
        d='M17.2041 8.14284H13.2857C13.0408 8.14284 12.7959 7.89795 12.7959 7.65305V7.22448C12.7959 6.97958 13.0408 6.73468 13.2857 6.73468H17.2041C17.449 6.73468 17.6939 6.97958 17.6939 7.22448V7.65305C17.6939 7.89795 17.5102 8.14284 17.2041 8.14284Z'
        fill='#1167E8'
      />
    </svg>
  )
}

export default BbotOriginal
