import { classNames } from '@invisible/common/helpers'
import { TQuestionAndAnswerTree } from '@invisible/common/types'
import { sendErrorToSentry } from '@invisible/errors'
import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { useToasts } from '@invisible/ui/toasts'
import { TUuid } from '@invisible/zod'
import { FC, useState } from 'react'

interface IProp {
  question: TQuestionAndAnswerTree
  touched: string
  handleQuestionTouch: (id: TUuid) => void
  onCommentSave: (comment: string) => Promise<void>
  isDPIAView: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const QAJustification: FC<IProp> = ({
  question,
  touched,
  handleQuestionTouch,
  onCommentSave,
  isDPIAView,
}) => {
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const { addToast } = useToasts()

  const handleDiscard = () => {
    setComment('')
    handleQuestionTouch('')
  }

  const resetAfterSave = () => {
    handleDiscard()
    setIsSaving(false)
  }

  const handleSaveClicked = async () => {
    setIsSaving(true)
    try {
      await onCommentSave(comment)
      resetAfterSave()
    } catch (e) {
      sendErrorToSentry(e)
      resetAfterSave()
      addToast(`Failed to save: ${JSON.stringify(e)}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <>
      {touched === question.id && question.type !== 'collection' ? (
        <div className={classNames(!isDPIAView ? `ml-${question.level * 2} mt-2` : 'my-4')}>
          <TextArea
            placeholder='Justify your changes here'
            value={comment}
            rows={3}
            onChange={(e) => setComment(e.currentTarget.value)}
          />
        </div>
      ) : null}

      {touched === question.id ? (
        <div className='mb-3 mt-3 flex justify-center'>
          <div>
            <Button size='sm' disabled={isSaving} variant='secondary' onClick={handleDiscard}>
              {!comment.length ? 'Cancel' : 'Discard Changes'}
            </Button>
          </div>

          <div className='ml-3'>
            <Button
              size='sm'
              variant='primary'
              disabled={!comment.length}
              onClick={handleSaveClicked}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default QAJustification
