import { SVGProps } from 'react'
function PauseCircleFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.5 0C13.3355 0 10.2421 0.938383 7.61088 2.69649C4.9797 4.45459 2.92894 6.95344 1.71793 9.87706C0.506933 12.8007 0.190079 16.0177 0.807443 19.1214C1.42481 22.2251 2.94866 25.0761 5.1863 27.3137C7.42394 29.5513 10.2749 31.0752 13.3786 31.6926C16.4823 32.3099 19.6993 31.9931 22.6229 30.7821C25.5466 29.5711 28.0454 27.5203 29.8035 24.8891C31.5616 22.2579 32.5 19.1645 32.5 16C32.4919 11.759 30.8035 7.69409 27.8047 4.69527C24.8059 1.69645 20.741 0.00813226 16.5 0ZM14.0385 20.9231C14.0385 21.2495 13.9088 21.5625 13.678 21.7934C13.4472 22.0242 13.1341 22.1538 12.8077 22.1538C12.4813 22.1538 12.1682 22.0242 11.9374 21.7934C11.7066 21.5625 11.5769 21.2495 11.5769 20.9231V11.0769C11.5769 10.7505 11.7066 10.4374 11.9374 10.2066C12.1682 9.97582 12.4813 9.84615 12.8077 9.84615C13.1341 9.84615 13.4472 9.97582 13.678 10.2066C13.9088 10.4374 14.0385 10.7505 14.0385 11.0769V20.9231ZM21.4231 20.9231C21.4231 21.2495 21.2934 21.5625 21.0626 21.7934C20.8318 22.0242 20.5187 22.1538 20.1923 22.1538C19.8659 22.1538 19.5528 22.0242 19.322 21.7934C19.0912 21.5625 18.9615 21.2495 18.9615 20.9231V11.0769C18.9615 10.7505 19.0912 10.4374 19.322 10.2066C19.5528 9.97582 19.8659 9.84615 20.1923 9.84615C20.5187 9.84615 20.8318 9.97582 21.0626 10.2066C21.2934 10.4374 21.4231 10.7505 21.4231 11.0769V20.9231Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PauseCircleFilled
