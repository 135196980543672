import { SVGProps } from 'react'
function GoogleSheet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={38}
      height={47}
      viewBox='0 0 38 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.683.493L36.97 11.781c.315.315.493.745.493 1.191v32.35c0 .928-.75 1.678-1.678 1.678H2.214c-.928 0-1.678-.75-1.678-1.679V1.68C.536.75 1.286 0 2.214 0h22.283c.446 0 .871.178 1.186.493zm-1.962 13.25h9.872l-9.872-9.872v9.872zM4.313 43.223h29.375V17.31h-11.33a2.203 2.203 0 01-2.204-2.203V3.777H4.313v39.446zm16.38-18.028h5.75v4.444h-5.75v-4.444zm-3-3h11.75v16.972H8.554V22.194h9.139zm3 13.972h5.75v-3.528h-5.75v3.528zm-3-3.528v3.528h-6.139v-3.528h6.139zm0-3h-6.139v-4.444h6.139v4.444z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GoogleSheet
