import { z } from '@invisible/zod'

import { ProcessStatusSchema } from '../constants/process'

const schema = z
  .object({
    id: z.string().uuid(),
    name: z.string().nonempty(),
    description: z.string().nullish(),
    version: z.number().optional(),
    originProcessId: z.string().uuid().optional(),
    rootBaseId: z.string().uuid().nullish(),
    status: ProcessStatusSchema.optional(),
    createdByUserId: z.string().uuid().optional(),
    delegatedByUserId: z.string().uuid().optional(),
    leaderUserId: z.string().uuid().optional(),
    companyId: z.string().uuid().optional(),
    template: z.boolean().optional(),
    operationsManagerId: z.string().uuid().optional(),
  })
  .strict()
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema.omit({ id: true }).strip()

const updateArgsSchema = schema.omit({ originProcessId: true }).partial().strip()

type TCreateArgs = z.infer<typeof createArgsSchema>
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

export { create, createArgsSchema, schema, updateArgsSchema }
export type { TCreateArgs, TSchema }
