import { useLocalStore } from '@invisible/hooks/use-local-store'
import { Dispatch } from 'react'

export interface CompanySummary {
  id: string
  name: string
}

const useSelectedCompany = (initialState?: CompanySummary) =>
  useLocalStore('selectedCompany', initialState) as [CompanySummary, Dispatch<CompanySummary>]

export { useSelectedCompany }
