import { z } from '@invisible/zod'

import { datePresetEnum } from '../../enums'

const schema = z.object({
  from: z.string().optional(),
  to: z.string().optional(),
  preset: datePresetEnum.optional(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
