const getMaxValue = (...args: (number | undefined)[]) => {
  if (args.every((val) => val === undefined)) return
  return Math.max(...args.map((val) => val ?? Number.NEGATIVE_INFINITY))
}

const getMinValue = (...args: (number | undefined)[]) => {
  if (args.every((val) => val === undefined)) return
  return Math.min(...args.map((val) => val ?? Number.POSITIVE_INFINITY))
}

export { getMaxValue, getMinValue }
