import { SVGProps } from 'react'
function ClockFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='26'
      viewBox='0 0 27 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.356 0C10.7848 0 8.27139 0.762437 6.13355 2.1909C3.99571 3.61935 2.32947 5.64968 1.34553 8.02512C0.36159 10.4006 0.104146 13.0144 0.605754 15.5362C1.10736 18.0579 2.34549 20.3743 4.16357 22.1924C5.98166 24.0105 8.29804 25.2486 10.8198 25.7502C13.3415 26.2518 15.9554 25.9944 18.3308 25.0104C20.7063 24.0265 22.7366 22.3603 24.1651 20.2224C25.5935 18.0846 26.356 15.5712 26.356 13C26.3494 9.55422 24.9776 6.25145 22.5411 3.81491C20.1045 1.37837 16.8017 0.00660747 13.356 0ZM20.356 14H13.356C13.0907 14 12.8364 13.8946 12.6489 13.7071C12.4613 13.5196 12.356 13.2652 12.356 13V6C12.356 5.73478 12.4613 5.48043 12.6489 5.29289C12.8364 5.10536 13.0907 5 13.356 5C13.6212 5 13.8755 5.10536 14.0631 5.29289C14.2506 5.48043 14.356 5.73478 14.356 6V12H20.356C20.6212 12 20.8755 12.1054 21.0631 12.2929C21.2506 12.4804 21.356 12.7348 21.356 13C21.356 13.2652 21.2506 13.5196 21.0631 13.7071C20.8755 13.8946 20.6212 14 20.356 14Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ClockFilled
