import { SVGProps } from 'react'
function TaskTraining(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M2.38235 0.941176H9.91177C10.1614 0.941176 10.4008 1.04034 10.5773 1.21684C10.7538 1.39335 10.8529 1.63274 10.8529 1.88235V6.60894C11.1758 6.63812 11.4911 6.69741 11.7941 6.78306V1.88235C11.7941 1.38312 11.5958 0.904338 11.2428 0.551328C10.8898 0.198319 10.411 0 9.91177 0H2.38235C1.88312 0 1.40434 0.198319 1.05133 0.551328C0.698319 0.904338 0.5 1.38312 0.5 1.88235V13.1765C0.5 13.6757 0.698319 14.1545 1.05133 14.5075C1.40434 14.8605 1.88312 15.0588 2.38235 15.0588H6.38894C6.14923 14.7685 5.94189 14.4529 5.77059 14.1176H2.38235C2.13274 14.1176 1.89335 14.0185 1.71684 13.842C1.54034 13.6655 1.44118 13.4261 1.44118 13.1765H5.40071C5.31362 12.8688 5.25533 12.5537 5.22659 12.2353H1.44118V1.88235C1.44118 1.63274 1.54034 1.39335 1.71684 1.21684C1.89335 1.04034 2.13274 0.941176 2.38235 0.941176Z'
        fill='currentColor'
      />
      <path
        d='M3.32353 1.88236C3.07391 1.88236 2.83452 1.98152 2.65802 2.15802C2.48151 2.33453 2.38235 2.57392 2.38235 2.82354V3.76471C2.38235 4.01433 2.48151 4.25372 2.65802 4.43022C2.83452 4.60673 3.07391 4.70589 3.32353 4.70589H8.97059C9.2202 4.70589 9.45959 4.60673 9.6361 4.43022C9.8126 4.25372 9.91177 4.01433 9.91177 3.76471V2.82354C9.91177 2.57392 9.8126 2.33453 9.6361 2.15802C9.45959 1.98152 9.2202 1.88236 8.97059 1.88236H3.32353ZM8.97059 2.82354V3.76471H3.32353V2.82354H8.97059Z'
        fill='currentColor'
      />
      <path
        d='M14.6176 11.7647C14.6176 12.888 14.1714 13.9652 13.3772 14.7595C12.5829 15.5538 11.5056 16 10.3824 16C9.25908 16 8.18182 15.5538 7.38755 14.7595C6.59328 13.9652 6.14706 12.888 6.14706 11.7647C6.14706 10.6414 6.59328 9.56415 7.38755 8.76988C8.18182 7.97561 9.25908 7.52939 10.3824 7.52939C11.5056 7.52939 12.5829 7.97561 13.3772 8.76988C14.1714 9.56415 14.6176 10.6414 14.6176 11.7647ZM9.93059 9.91433C9.86956 9.87242 9.79864 9.84716 9.72486 9.84105C9.65107 9.83495 9.57697 9.8482 9.50988 9.87951C9.45512 9.90361 9.40557 9.93813 9.364 9.98116C9.27632 10.071 9.22733 10.1916 9.22753 10.3172V13.2113C9.22695 13.2737 9.23871 13.3355 9.26213 13.3934C9.28555 13.4512 9.32017 13.5038 9.364 13.5482C9.40696 13.5909 9.45694 13.626 9.51176 13.6517C9.60726 13.6955 9.7156 13.7022 9.81576 13.6706C9.85915 13.6563 9.90033 13.636 9.93812 13.6103L12.0991 12.1355C12.1648 12.0922 12.2178 12.0321 12.2525 11.9614C12.2882 11.8902 12.3066 11.8115 12.3061 11.7319C12.3056 11.6522 12.2863 11.5738 12.2496 11.503C12.2128 11.4334 12.1587 11.3744 12.0925 11.3317L9.93059 9.91433Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TaskTraining
