import { FC, ReactNode } from 'react'

interface MarkerProps {
  id: string
  className?: string
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Marker: FC<MarkerProps> = ({ id, className, children }) => (
  <marker
    className={className || 'react-flow__arrowhead'}
    id={id}
    markerWidth='15'
    markerHeight='15'
    viewBox='-10 -10 20 20'
    orient='auto'
    markerUnits='userSpaceOnUse'
    refX='0'
    refY='0'>
    {children}
  </marker>
)

interface MarkerDefinitionsProps {
  id: string
  color: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MarkerDefinition: FC<MarkerDefinitionsProps> = ({ color, id }) => (
  <svg>
    <defs>
      <Marker id={id}>
        <polyline
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
          fill={color}
          points='-12,-6 0,0 -12,6 -12,-6'
        />
      </Marker>
    </defs>
  </svg>
)

export { MarkerDefinition }
