import { SVGProps } from 'react'
function TagFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='29'
      height='30'
      viewBox='0 0 29 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M28.2195 14.455L15.157 1.39253C14.9258 1.15926 14.6403 0.98693 14.3262 0.890951C14.0121 0.794971 13.6791 0.778335 13.357 0.842529L3.26948 2.86753C3.0782 2.90573 2.90252 2.99972 2.76459 3.13764C2.62667 3.27557 2.53268 3.45125 2.49448 3.64253L0.469482 13.73C0.405288 14.0521 0.421925 14.3851 0.517904 14.6993C0.613883 15.0134 0.786212 15.2988 1.01948 15.53L14.082 28.5925C14.2668 28.7792 14.4868 28.9274 14.7292 29.0286C14.9717 29.1297 15.2318 29.1818 15.4945 29.1818C15.7572 29.1818 16.0173 29.1297 16.2597 29.0286C16.5022 28.9274 16.7222 28.7792 16.907 28.5925L28.2195 17.28C28.4062 17.0952 28.5544 16.8752 28.6555 16.6328C28.7567 16.3903 28.8088 16.1302 28.8088 15.8675C28.8088 15.6048 28.7567 15.3447 28.6555 15.1023C28.5544 14.8598 28.4062 14.6398 28.2195 14.455ZM8.71948 10.5925C8.42281 10.5925 8.1328 10.5046 7.88613 10.3397C7.63945 10.1749 7.44719 9.94064 7.33366 9.66655C7.22013 9.39247 7.19043 9.09086 7.2483 8.79989C7.30618 8.50892 7.44904 8.24165 7.65882 8.03187C7.8686 7.82209 8.13587 7.67923 8.42685 7.62135C8.71782 7.56347 9.01942 7.59318 9.29351 7.70671C9.5676 7.82024 9.80187 8.0125 9.96669 8.25917C10.1315 8.50585 10.2195 8.79586 10.2195 9.09253C10.2195 9.49035 10.0614 9.87189 9.78014 10.1532C9.49884 10.4345 9.11731 10.5925 8.71948 10.5925Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TagFilled
