import { SVGProps } from 'react'
function DocLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.401 9.669L21.137.405A1.375 1.375 0 0020.164 0H1.878C1.116 0 .5.616.5 1.378v35.816c0 .762.616 1.377 1.378 1.377h27.55c.762 0 1.378-.615 1.378-1.377V10.646c0-.366-.146-.719-.405-.977zm-2.772 1.61h-8.102V3.177l8.102 8.102zm.078 24.193H3.599V3.099H16.6v9.299a1.808 1.808 0 001.808 1.808h9.299v21.266z'
        fill='currentColor'
      />
      <path
        d='M33.535 39.97l-.005-.005a1.824 1.824 0 00-2.569 0h0l-.005.005-4.7 4.702c-1.631 1.63-4.353 1.782-6.132 0h0c-1.784-1.784-1.63-4.503 0-6.133l4.7-4.701a1.825 1.825 0 000-2.579l-1.61-1.61h0l-.005-.005a1.825 1.825 0 00-2.568 0h0l-.005.005-4.701 4.701 1.06 1.06-1.06-1.06c-4.008 4.009-4.01 10.498 0 14.503 4.007 4.002 10.495 4.01 14.5 0l.002-.002 4.7-4.7-1.06-1.06 1.06 1.06a1.825 1.825 0 000-2.579l-1.06 1.06 1.06-1.06-1.602-1.602h0zm10.462-19.177h0c-4.008-4.009-10.496-4.01-14.501 0 0 0 0 0 0 0l-4.705 4.7h0l-.006.006a1.825 1.825 0 000 2.568h0l.006.005 1.606 1.606 1.06-1.06-1.06 1.06a1.825 1.825 0 002.578 0l4.701-4.701c1.63-1.63 4.353-1.782 6.131-.001h.001c1.783 1.784 1.63 4.503 0 6.133l-4.701 4.702h0l-.005.005a1.825 1.825 0 000 2.568h0l.005.005 1.61 1.61a1.825 1.825 0 002.579 0l4.7-4.7.001-.002a10.26 10.26 0 000-14.504zm-9 7.32h0l-.007-.007a1.825 1.825 0 00-2.568 0h0l-.005.005-9.163 9.16h0l-.006.006a1.825 1.825 0 000 2.568h0l.005.005 1.602 1.602a1.825 1.825 0 002.579 0l9.16-9.16-1.061-1.06 1.06 1.06c.711-.71.711-1.866.002-2.577 0 0 0 0 0 0l-.002-.001-1.596-1.601z'
        fill='currentColor'
        stroke='#fff'
        strokeWidth={3}
      />
    </svg>
  )
}

export default DocLink
