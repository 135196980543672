import type { UltronRouter } from '@invisible/ultron/trpc/server'
import { createReactQueryHooks } from '@trpc/react'

export const trpc = createReactQueryHooks<UltronRouter>()

export const {
  useMutation,
  useSubscription,
  useQuery,
  useDehydratedState,
  useInfiniteQuery,
  useContext,
} = trpc
