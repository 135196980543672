import { SVGProps } from 'react'
function DoubleCaratRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.366135 0.292893C0.75666 -0.0976311 1.38982 -0.0976311 1.78035 0.292893L11.7803 10.2929C12.1709 10.6834 12.1709 11.3166 11.7803 11.7071L1.78035 21.7071C1.38982 22.0976 0.75666 22.0976 0.366135 21.7071C-0.0243889 21.3166 -0.0243889 20.6834 0.366135 20.2929L9.65903 11L0.366135 1.70711C-0.0243889 1.31658 -0.0243889 0.683417 0.366135 0.292893Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3661 0.292893C11.7567 -0.0976311 12.3898 -0.0976311 12.7803 0.292893L22.7803 10.2929C23.1709 10.6834 23.1709 11.3166 22.7803 11.7071L12.7803 21.7071C12.3898 22.0976 11.7567 22.0976 11.3661 21.7071C10.9756 21.3166 10.9756 20.6834 11.3661 20.2929L20.659 11L11.3661 1.70711C10.9756 1.31658 10.9756 0.683417 11.3661 0.292893Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DoubleCaratRight
