import { styled } from '@mui/material/styles'
import { GlobalStyles } from '@mui/system'
import { flow } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { useMemo } from 'react'
import ReactQuill from 'react-quill'
import sanitize from 'sanitize-html'

import { GOOGLE_CLOUD_BUCKET_NAME } from './Editor/env'
import { breakDown, fixUp } from './Editor/fix-lists'
import { hash } from './Editor/hash'
import { sanitizeDescriptionOptions } from './Editor/sanitizeOptions'

const GOOGLE_CLOUD_API = 'https://storage.googleapis.com'
const primaryColor = '#000'
const EditorStyle = (props: { readOnly: boolean }) => (
  <GlobalStyles
    styles={{
      '.ql-error': {
        color: 'attention',
        position: 'absolute',
        fontSize: '14px',
        top: '0.2rem',
      },
      '.ql-toolbar': {
        display: props.readOnly ? 'none' : 'inherit',
      },
      '.ql-container': {
        borderTop: props.readOnly ? '1px solid red !important' : '0',
      },
      '.ql-snow .ql-tooltip': {
        zIndex: 1001,
        paddingTop: '1rem',
      },
      '.ql-editor': {
        backgroundColor: 'white',
      },
      '.ql-formula svg:hover path, .ql-script svg:hover path, .ql-script svg:hover rect': {
        stroke: primaryColor,
      },
    }}
  />
)

type QuillProps = typeof ReactQuill.prototype.props
type QuillIConsType = 'link' | 'code-block' | 'clean' | 'video' | 'image'
type QuillIConsTypeArray = Array<QuillIConsType>

const ShowError = () => <p>An error occurred</p>
const ShowLoading = () => <p>Loading ...</p>

const QuillNoSSRWrapper = dynamic<QuillProps>(
  () =>
    import('./Editor/QuillEditor')
      .then((mod) => mod.QuillEditor)
      .catch(
        // Errors on load show here
        () => ShowError
      ),
  {
    ssr: false,
    loading: ShowLoading,
  }
)

const EditorContainer = styled('div')<{
  width?: string
  margin?: string
}>(({ width, margin }) => ({
  margin: margin ?? '1em auto',
  fontSize: '14px',
  borderColor: 'var(--divider, rgba(0, 0, 0, 0.12))',
  background: '#FFF',
  '& .ql-tooltip': {
    left: 0,
  },
  '& .ql-editor.ql-blank::before': {
    fontStyle: 'normal',
  },
  '& .ql-editor': {
    fontSize: '14px',
    marginBottom: '25px',
    minHeight: '10px !important',
  },
  '& .ql-toolbar': {
    borderColor: 'var(--divider, rgba(0, 0, 0, 0.12))',
    borderBottom: '1px solid var(--divider, rgba(0, 0, 0, 0.12))',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  '& .ql-container': {
    borderColor: 'var(--divider, rgba(0, 0, 0, 0.12))',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  '& .ql-picker-label': {
    color: 'var(--text-disabled, rgba(0,0,0,0.38))',
  },
  '& .ql-stroke': {
    stroke: 'var(--text-disabled, rgba(0,0,0,0.38)) !important',
  },
  '& .ql-fill': {
    fill: 'var(--text-disabled, rgba(0,0,0,0.38)) !important',
  },
}))

const fileReader = (file: File) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(file)
  })
}

const Extensions: { [key: string]: string } = {
  'application/pdf': '.pdf',
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif',
}

const imageUploaderCallback = async (file: File) => {
  try {
    const fileResult = await fileReader(file)
    const fileHash = hash(fileResult as string | ArrayBuffer | null)
    const ext: string | undefined = Extensions[file.type]
    const publicUrl = `${GOOGLE_CLOUD_API}/${GOOGLE_CLOUD_BUCKET_NAME}/${fileHash}${ext}`
    const response = await fetch(
      `/api/google/cloud/signedUrl?file=${fileHash}${ext}&type=${file.type}`,
      {
        method: 'POST',
        mode: 'cors',
      }
    )
    const { url } = await response.json()
    const googleAnswer = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      mode: 'cors',
      body: file,
    })

    return publicUrl
  } catch (err: any) {
    throw new Error(`Upload Failed: ${err.message}`)
  }
}

export interface IChatQuillEditor {
  value: string
  showSplitStep?: boolean
  splitStep?: (step1Instructions: string, step2Instructions: string) => any
  convertStep?: () => any
  onChange: (value: string) => any
  width?: string
  margin?: string
  readOnly?: boolean
  removeMedia?: QuillIConsTypeArray | undefined
  onKeyDown?: (value: React.KeyboardEvent) => void
  placeholder?: string
  valueProp?: boolean
}

const ChatQuillEditor = ({ readOnly = false, ...props }: IChatQuillEditor) => {
  function splitText(this: { quill: any }) {
    if (props.splitStep && props.showSplitStep) {
      const cursorPosition = this.quill.getSelection().index
      const deltaFirstHalf = this.quill.getContents(0, cursorPosition)
      const deltaSecondHalf = this.quill.getContents(cursorPosition)
      const fistHalfConverter = new QuillDeltaToHtmlConverter(deltaFirstHalf.ops, {})
      const firstHalfHTMLString = fistHalfConverter.convert()
      const secondHalfConverter = new QuillDeltaToHtmlConverter(deltaSecondHalf.ops, {})
      const secondHalfHTMLString = secondHalfConverter.convert()
      props.splitStep(firstHalfHTMLString, secondHalfHTMLString)
    }
  }
  const convertStep = () => {
    if (props.convertStep) {
      props.convertStep()
    }
  }

  type IIconsList = (
    | string[]
    | {
        header: (number | boolean)[]
      }[]
    | (
        | {
            color: string[]
          }
        | {
            background: string[]
          }
      )[]
    | {
        list: string
      }[]
    | {
        script: string
      }[]
  )[]

  const removeIcons = (list: IIconsList, icons: string[]) => {
    icons.forEach((element: string) => {
      const index = list.findIndex((item: any) => item.includes(element))
      if (index !== -1) {
        list.splice(index, 1)
      }
    })
  }

  const toolbarIcons = useMemo(() => {
    const iconsList: IIconsList = [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'strike', 'blockquote'],
      [
        {
          color: [
            'rgb(0,0,0)',
            'rgb(68,68,68)',
            'rgb(102,102,102)',
            'rgb(153,153,153)',
            'rgb(204,204,204)',
            'rgb(238,238,238)',
            'rgb(243,243,243)',
            'rgb(255,255,255)',
            'rgb(255,0,0)',
            'rgb(255,153,0)',
            'rgb(255,255,0)',
            'rgb(0,255,0)',
            'rgb(0,255,255)',
            'rgb(0,0,255)',
            'rgb(153,0,255)',
            'rgb(255,0,255)',
          ],
        },
        {
          background: [
            'rgb(244,204,204)',
            'rgb(252,229,205)',
            'rgb(255,242,204)',
            'rgb(217,234,211)',
            'rgb(208,244,227)',
            'rgb(207,226,243)',
            'rgb(217,210,233)',
            'rgb(234,209,220)',
            'rgb(234,153,153)',
            'rgb(249,203,156)',
            'rgb(255,229,153)',
            'rgb(182,215,168)',
            'rgb(162,196,201)',
            'rgb(162,196,201)',
            'rgb(180,167,214)',
            'rgb(213,166,189)',
          ],
        },
      ],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['code-block'],
      ['clean'],
      ['video'],
      ['image'],
    ]
    if (props.splitStep && props.showSplitStep) {
      iconsList.push(['formula'])
    }
    if (props.convertStep) {
      iconsList.push([{ script: 'sub' }])
    }
    if (props?.removeMedia && props?.removeMedia.length > 0) {
      removeIcons(iconsList, props.removeMedia)
    }
    return iconsList
  }, [])

  const options = useMemo(
    () => ({
      modules: {
        toolbar: {
          container: toolbarIcons,
          handlers: {
            formula: splitText,
            script: convertStep,
          },
        },
        clipboard: {
          matchVisual: false,
        },
        imageUploader: {
          upload: imageUploaderCallback,
        },
      },
    }),
    []
  )
  const extraProps: { value?: string } = {}
  if (props.valueProp && props.value === '') {
    extraProps.value = breakDown(props.value)
  }

  return (
    <>
      <EditorStyle readOnly={readOnly} />
      <EditorContainer className='editor-container' width={props.width} margin={props.margin}>
        {typeof window !== 'undefined' ? (
          <QuillNoSSRWrapper
            readOnly={readOnly}
            modules={options.modules}
            defaultValue={breakDown(props.value)}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            onChange={flow(
              fixUp,
              (value) => sanitize(value, sanitizeDescriptionOptions),
              props.onChange
            )}
            {...extraProps}
          />
        ) : null}
      </EditorContainer>
    </>
  )
}

export { ChatQuillEditor }
