import { SVGProps } from 'react'
function SalesforceOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='143'
      height='100'
      viewBox='0 0 143 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M59.4681 10.8939C64.082 6.08779 70.4902 3.10798 77.5713 3.10798C86.9913 3.10798 95.2259 8.3627 99.6155 16.1807C103.428 14.4825 107.626 13.5213 112.079 13.5213C129.093 13.5213 142.871 27.4271 142.871 44.6011C142.871 61.7751 129.093 75.6809 112.079 75.6809C109.997 75.6809 107.978 75.4566 106.024 75.0721C102.179 81.9609 94.8094 86.6069 86.3826 86.6069C82.8581 86.6069 79.4937 85.8058 76.546 84.332C72.637 93.5277 63.5053 100 52.8997 100C41.8456 100 32.3935 93.0151 28.8049 83.1785C27.2349 83.4989 25.5687 83.6911 23.9026 83.6911C10.6697 83.6911 0 72.9253 0 59.5963C0 50.6889 4.80615 42.9029 11.9193 38.7376C10.4454 35.3733 9.64434 31.6245 9.64434 27.7155C9.64434 12.3999 22.0763 0 37.3919 0C46.3954 0 54.3736 4.26145 59.4681 10.8939Z'
        fill='#139CD8'
      />
      <path
        d='M20.6985 51.8744C20.6023 52.0987 20.7305 52.1628 20.7625 52.1948C21.0189 52.3871 21.3072 52.5152 21.5636 52.6754C23.0054 53.4444 24.3832 53.6687 25.825 53.6687C28.7408 53.6687 30.5671 52.0987 30.5671 49.5995V49.5354C30.5671 47.2285 28.5165 46.3634 26.594 45.7546L26.3377 45.6905C24.8958 45.2099 23.6462 44.8254 23.6462 43.8642V43.8001C23.6462 42.967 24.3832 42.3903 25.5046 42.3903C26.7542 42.3903 28.2601 42.8068 29.2214 43.3515C29.2214 43.3515 29.5097 43.5438 29.6059 43.2554C29.6699 43.0952 30.1506 41.7815 30.2146 41.6533C30.2787 41.4931 30.1826 41.397 30.0865 41.3329C28.9971 40.6601 27.4591 40.2115 25.8891 40.2115H25.6007C22.9093 40.2115 21.0509 41.8456 21.0509 44.1525V44.2166C21.0509 46.6517 23.1015 47.4528 25.056 47.9975L25.3764 48.0936C26.7863 48.5422 28.0038 48.8946 28.0038 49.8879V49.952C28.0038 50.8491 27.2028 51.522 25.9532 51.522C25.4726 51.522 23.8705 51.522 22.1723 50.4326C21.9801 50.3044 21.8519 50.2403 21.6917 50.1122C21.5956 50.0481 21.4034 49.984 21.3072 50.2403L20.6985 51.8744Z'
        fill='white'
      />
      <path
        d='M63.6015 51.8744C63.5054 52.0987 63.6336 52.1628 63.6656 52.1948C63.9219 52.3871 64.2103 52.5152 64.4666 52.6754C65.9085 53.4444 67.2863 53.6687 68.7281 53.6687C71.6438 53.6687 73.4702 52.0987 73.4702 49.5995V49.5354C73.4702 47.2285 71.4195 46.3634 69.4971 45.7546L69.2408 45.6905C67.7989 45.2099 66.5493 44.8254 66.5493 43.8642V43.8001C66.5493 42.967 67.2863 42.3903 68.4077 42.3903C69.6573 42.3903 71.1632 42.8068 72.1244 43.3515C72.1244 43.3515 72.4128 43.5438 72.5089 43.2554C72.573 43.0952 73.0536 41.7815 73.1177 41.6533C73.1818 41.4931 73.0857 41.397 72.9896 41.3329C71.9002 40.6601 70.3622 40.2115 68.7922 40.2115H68.5038C65.8124 40.2115 63.954 41.8456 63.954 44.1525V44.2166C63.954 46.6517 66.0367 47.4528 67.9591 47.9975L68.2795 48.0936C69.6893 48.5422 70.9069 48.8946 70.9069 49.8879V49.952C70.9069 50.8491 70.1059 51.522 68.8563 51.522C68.3756 51.522 66.7736 51.522 65.0754 50.4326C64.8832 50.3044 64.755 50.2403 64.5948 50.1122C64.5307 50.0801 64.3064 49.984 64.2103 50.2403L63.6015 51.8744Z'
        fill='white'
      />
      <path
        d='M95.3222 44.3121C95.0979 43.5111 94.7134 42.7742 94.2007 42.1974C93.6881 41.5886 93.0793 41.108 92.3103 40.7556C91.5734 40.4031 90.6762 40.2429 89.6829 40.2429C88.6897 40.2429 87.7925 40.4352 87.0556 40.7556C86.3186 41.108 85.6778 41.5886 85.1652 42.1974C84.6525 42.8062 84.3001 43.5111 84.0437 44.3121C83.8194 45.1132 83.6913 46.0103 83.6913 46.9395C83.6913 47.8687 83.8194 48.7658 84.0437 49.5669C84.268 50.3679 84.6525 51.1048 85.1652 51.6816C85.6778 52.2903 86.2866 52.771 87.0556 53.0914C87.7925 53.4438 88.6897 53.604 89.6829 53.604C90.6762 53.604 91.5413 53.4438 92.3103 53.0914C93.0473 52.7389 93.6881 52.2583 94.2007 51.6816C94.7134 51.0728 95.0658 50.3679 95.3222 49.5669C95.5465 48.7658 95.6746 47.8687 95.6746 46.9395C95.7067 46.0103 95.5785 45.1132 95.3222 44.3121ZM92.887 46.9395C92.887 48.3493 92.6307 49.4707 92.086 50.2718C91.5734 51.0407 90.8044 51.4252 89.715 51.4252C88.6256 51.4252 87.8566 51.0407 87.344 50.2718C86.8313 49.4707 86.575 48.3493 86.575 46.9395C86.575 45.5297 86.8313 44.4083 87.344 43.6393C87.8566 42.8703 88.6256 42.4858 89.715 42.4858C90.8044 42.4858 91.5734 42.8703 92.086 43.6393C92.6307 44.4083 92.887 45.5297 92.887 46.9395Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M115.412 51.0413C115.348 50.8171 115.091 50.8811 115.091 50.8811C114.739 51.0093 114.354 51.1375 113.938 51.2016C113.521 51.2656 113.073 51.2977 112.592 51.2977C111.407 51.2977 110.477 50.9452 109.804 50.2403C109.132 49.5354 108.747 48.414 108.747 46.9081C108.747 45.5303 109.1 44.4729 109.676 43.6719C110.285 42.8709 111.182 42.4864 112.4 42.4864C113.393 42.4864 114.194 42.6146 114.995 42.8709C114.995 42.8709 115.187 42.967 115.283 42.7107C115.508 42.1019 115.668 41.6854 115.892 41.0446C115.956 40.8523 115.796 40.7882 115.732 40.7562C115.412 40.628 114.675 40.4358 114.098 40.3397C113.585 40.2756 112.944 40.2115 112.272 40.2115C111.246 40.2115 110.317 40.4037 109.548 40.7241C108.779 41.0766 108.106 41.5572 107.562 42.166C107.049 42.7748 106.632 43.4797 106.376 44.2807C106.12 45.0817 105.992 45.9789 105.992 46.9081C105.992 48.9266 106.536 50.5607 107.626 51.7783C108.715 52.9959 110.349 53.6046 112.464 53.6046C113.713 53.6046 114.995 53.3483 115.924 52.9958C115.924 52.9958 116.117 52.8997 116.02 52.7075L115.412 51.0413Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M127.94 43.864C127.748 43.063 127.203 42.294 126.882 41.9416C126.338 41.3648 125.825 40.9804 125.312 40.7561C124.64 40.4677 123.839 40.2755 122.941 40.2755C121.916 40.2755 120.955 40.4357 120.218 40.8202C119.449 41.1726 118.808 41.6532 118.295 42.262C117.783 42.8708 117.43 43.5757 117.174 44.4087C116.918 45.2098 116.822 46.1069 116.822 47.0361C116.822 47.9973 116.95 48.8945 117.206 49.6955C117.462 50.4965 117.847 51.2014 118.392 51.8102C118.936 52.387 119.641 52.8676 120.474 53.188C121.307 53.5084 122.333 53.6686 123.486 53.6686C125.889 53.6686 127.139 53.1239 127.651 52.8355C127.748 52.7714 127.844 52.7074 127.715 52.451L127.171 50.9451C127.075 50.7208 126.85 50.8169 126.85 50.8169C126.242 51.0412 125.409 51.4257 123.454 51.4257C122.172 51.4257 121.211 51.0412 120.634 50.4645C120.026 49.8557 119.705 48.9586 119.673 47.709H127.94C127.94 47.709 128.164 47.709 128.196 47.4847C128.196 47.3245 128.484 45.7224 127.94 43.864ZM119.705 45.5943C119.833 44.7932 120.026 44.1524 120.378 43.6398C120.891 42.8708 121.66 42.4222 122.749 42.4222C123.839 42.4222 124.543 42.8387 125.056 43.6398C125.409 44.1524 125.537 44.8253 125.601 45.5943H119.705Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.6148 43.864C61.4226 43.063 60.8779 42.294 60.5575 41.9416C60.0128 41.3648 59.5001 40.9804 58.9874 40.7561C58.3146 40.4677 57.5136 40.2755 56.6164 40.2755C55.5911 40.2755 54.6299 40.4357 53.8929 40.8202C53.1239 41.1726 52.4831 41.6532 51.9705 42.262C51.4578 42.8708 51.1054 43.5757 50.849 44.4087C50.5927 45.2098 50.4966 46.1069 50.4966 47.0361C50.4966 47.9973 50.6247 48.8945 50.8811 49.6955C51.1374 50.4965 51.5219 51.2014 52.0666 51.8102C52.6113 52.387 53.3162 52.8676 54.1493 53.188C54.9823 53.5084 56.0076 53.6686 57.1611 53.6686C59.5642 53.6686 60.8138 53.1239 61.3264 52.8355C61.4226 52.7714 61.5187 52.7074 61.3905 52.451L60.8458 50.9451C60.7497 50.7208 60.5254 50.8169 60.5254 50.8169C59.9166 51.0412 59.0836 51.4257 57.1291 51.4257C55.8474 51.4257 54.8862 51.0412 54.3095 50.4645C53.7007 49.8557 53.3803 48.9586 53.3482 47.709H61.6148C61.6148 47.709 61.8391 47.709 61.8711 47.4847C61.8711 47.3245 62.1595 45.7224 61.6148 43.864ZM53.3803 45.5943C53.5084 44.7932 53.7007 44.1524 54.0531 43.6398C54.5658 42.8708 55.3348 42.4222 56.4242 42.4222C57.5136 42.4222 58.2185 42.8387 58.7311 43.6398C59.0836 44.1524 59.2117 44.8253 59.2758 45.5943H53.3803Z'
        fill='white'
      />
      <path
        d='M38.7693 45.1778C38.4489 45.1458 38.0003 45.1458 37.4877 45.1458C36.7828 45.1458 36.0779 45.2419 35.4371 45.4021C34.7962 45.5623 34.2195 45.8507 33.7068 46.2032C33.1942 46.5556 32.8097 47.0362 32.5213 47.5809C32.233 48.1256 32.0728 48.7664 32.0728 49.5034C32.0728 50.2403 32.2009 50.8811 32.4572 51.3938C32.7136 51.9065 33.066 52.355 33.5466 52.6754C33.9952 52.9958 34.572 53.2522 35.2448 53.4124C35.8856 53.5726 36.6226 53.6367 37.4236 53.6367C38.2887 53.6367 39.1218 53.5726 39.9548 53.4124C40.7559 53.2842 41.7491 53.0599 42.0375 52.9958C42.3259 52.9318 42.6142 52.8356 42.6142 52.8356C42.8065 52.7716 42.8065 52.5793 42.8065 52.5793V45.0176C42.8065 43.3515 42.3579 42.1339 41.4928 41.365C40.6277 40.596 39.3461 40.2115 37.6799 40.2115C37.0712 40.2115 36.0458 40.3076 35.4691 40.4037C35.4691 40.4037 33.6748 40.7562 32.9058 41.3329C32.9058 41.3329 32.7456 41.429 32.8417 41.6533L33.4185 43.2233C33.4826 43.4156 33.7068 43.3515 33.7068 43.3515C33.7068 43.3515 33.7709 43.3195 33.835 43.2874C35.4371 42.4223 37.4556 42.4544 37.4556 42.4544C38.3528 42.4544 39.0257 42.6466 39.5063 42.9991C39.9548 43.3515 40.1791 43.8642 40.1791 44.9856V45.338C39.4422 45.2419 38.7693 45.1778 38.7693 45.1778ZM35.4691 51.0093C35.1487 50.753 35.0846 50.6889 34.9885 50.5287C34.8283 50.2724 34.7322 49.9199 34.7322 49.4393C34.7322 48.7024 34.9885 48.1897 35.4691 47.8372C35.4691 47.8372 36.174 47.2285 37.8722 47.2285C39.0577 47.2285 40.115 47.4207 40.115 47.4207V51.2016C40.115 51.2016 39.0577 51.4258 37.8722 51.4899C36.2381 51.586 35.4691 51.0093 35.4691 51.0093Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M105.575 40.9162C105.639 40.7239 105.511 40.6278 105.447 40.6278C105.319 40.5637 104.614 40.4356 104.069 40.4035C103.012 40.3395 102.435 40.4997 101.922 40.756C101.41 40.9803 100.833 41.3648 100.513 41.7813V40.756C100.513 40.6278 100.416 40.4997 100.288 40.4997H98.1736C98.0455 40.4997 97.9493 40.5958 97.9493 40.756V53.1238C97.9493 53.252 98.0455 53.3801 98.2057 53.3801H100.384C100.513 53.3801 100.641 53.284 100.641 53.1238V46.9399C100.641 46.1068 100.737 45.2738 100.929 44.7611C101.121 44.2485 101.346 43.8319 101.666 43.5436C101.954 43.2552 102.307 43.0309 102.691 42.9348C103.076 42.8066 103.492 42.7746 103.813 42.7746C104.261 42.7746 104.71 42.9027 104.71 42.9027C104.87 42.9348 104.966 42.8066 105.03 42.6784C105.094 42.294 105.511 41.1405 105.575 40.9162Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.1652 35.1806C84.9088 35.0845 84.6525 35.0524 84.3321 34.9883C84.0117 34.9243 83.6272 34.8922 83.2107 34.8922C81.7047 34.8922 80.5192 35.3087 79.7182 36.1418C78.8851 36.9749 78.3404 38.2245 78.0521 39.8906L77.9559 40.4673H76.0655C76.0655 40.4673 75.8412 40.4673 75.7772 40.7237L75.4567 42.4539C75.4247 42.6141 75.5208 42.7102 75.7131 42.7102H77.5394L75.681 53.0595C75.5208 53.8925 75.3606 54.5654 75.2004 55.1101C75.0082 55.6227 74.848 56.0072 74.6237 56.2956C74.3994 56.5519 74.2071 56.7762 73.8547 56.8723C73.5663 56.9685 73.2459 57.0005 72.8935 57.0005C72.7012 57.0005 72.4128 56.9685 72.2206 56.9364C72.0284 56.9044 71.9322 56.8403 71.772 56.8083C71.772 56.8083 71.5477 56.7121 71.4837 56.9364C71.4196 57.1287 70.939 58.4424 70.8749 58.6346C70.8108 58.7948 70.9069 58.923 71.003 58.955C71.2594 59.0511 71.4196 59.0832 71.772 59.1793C72.2206 59.2754 72.6371 59.3075 72.9896 59.3075C73.7586 59.3075 74.4314 59.2113 75.0082 58.9871C75.5849 58.7628 76.0976 58.4103 76.5461 57.9297C77.0267 57.385 77.3472 56.8403 77.6355 56.0713C77.9239 55.3023 78.1802 54.3731 78.3725 53.2838L80.2308 42.7423H82.9543C82.9543 42.7423 83.1786 42.7423 83.2427 42.4859L83.5631 40.7557C83.5952 40.5955 83.499 40.4994 83.3068 40.4994H80.6474C80.6474 40.4353 80.7756 39.5061 81.096 38.641C81.2241 38.2565 81.4804 37.9681 81.6727 37.7439C81.8649 37.5516 82.1213 37.3914 82.3776 37.2953C82.6339 37.1992 82.9223 37.1671 83.2747 37.1671C83.5311 37.1671 83.7554 37.1992 83.9476 37.2312C84.2039 37.2953 84.3001 37.3273 84.3641 37.3273C84.6205 37.4235 84.6846 37.3273 84.7166 37.1992L85.3574 35.4689C85.3574 35.3087 85.1972 35.2126 85.1652 35.1806Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.1577 53.0921C48.1577 53.2203 48.0616 53.3485 47.9334 53.3485H45.7226C45.5944 53.3485 45.4983 53.2203 45.4983 53.0921V35.4055C45.4983 35.2773 45.5944 35.1492 45.7226 35.1492H47.9334C48.0616 35.1492 48.1577 35.2453 48.1577 35.4055V53.0921Z'
        fill='white'
      />
    </svg>
  )
}

export default SalesforceOriginal
