/* eslint-disable @typescript-eslint/ban-types */

import { StoriesProvider, TStoriesProvider } from '../providers'

const withStories =
  (WrappedComponent: React.FC): React.FC<TStoriesProvider> =>
  ({ children, ...rest }) =>
    (
      <StoriesProvider {...rest}>
        <WrappedComponent>{children}</WrappedComponent>
      </StoriesProvider>
    )

export { withStories }
