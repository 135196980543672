import { mapWithKey } from '@invisible/ui/helpers'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { createColumnHelper, Table } from '@invisible/ui/table'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import Box from '@mui/material/Box'
import { format } from 'date-fns'
import { compact, find, flatten, flow, orderBy, reverse, startCase, take } from 'lodash/fp'
import { useMemo } from 'react'
import { JsonValue } from 'type-fest'

interface IProps {
  data: TBaseRunHistory | undefined
  isLoading: boolean
}

type TBaseRunHistory = inferQueryOutput<'baseRun.getHistory'>
type TTableData = {
  index: number
  modifiedOn: Date
  modifiedBy: string
  change: string
}

const columnHelper = createColumnHelper<TTableData>()

const columns = [
  columnHelper.accessor('index', {
    header: () => <div>#</div>,
    meta: {
      width: '5%',
    },
  }),
  columnHelper.accessor('modifiedOn', {
    header: () => <div>Modified On</div>,
    cell: (info) => <div className='px-3'>{format(info.getValue(), 'Pp')}</div>,
    meta: {
      width: '20%',
    },
  }),
  columnHelper.accessor('modifiedBy', {
    header: () => <div>Modified By</div>,
    meta: {
      width: '20%',
    },
  }),
  columnHelper.accessor('change', {
    header: () => <div>Description Of Change</div>,
    meta: {
      width: '55%',
    },
  }),
]

const parseValue = (value: string | JsonValue) => {
  if (value === 'DbNull') return ''
  return value
}

const constructBaseRunEventText = ({
  event,
  data,
  eventIndex,
  field,
}: {
  event: TBaseRunHistory['baseRun'][number]
  data: TBaseRunHistory
  eventIndex: number
  field?: string
}) => {
  if (!field) return 'Base run was created'

  const previousEvent = data.baseRun[eventIndex - 1]

  // We don't want to show unchanged values
  if (
    previousEvent &&
    parseValue(JSON.parse(previousEvent.snapshot as string)[field]) ===
      parseValue(JSON.parse(event.snapshot as string)?.[field])
  )
    return null

  return `${startCase(field)} was changed ${
    previousEvent
      ? `from "${parseValue(JSON.parse(previousEvent.snapshot as string)[field])}" `
      : ''
  }to "${parseValue(JSON.parse(event.snapshot as string)?.[field])}"`
}

const constructBaseRunVariableEventText = ({
  event,
  data,
  eventIndex,
}: {
  event: TBaseRunHistory['baseRunVariables'][number]
  data: TBaseRunHistory
  eventIndex: number
}) => {
  // reverse array up to current event index and find first occurrence with matching baseRunVariableId
  const previousEvent = flow(
    take(eventIndex),
    reverse,
    find(
      (s: TBaseRunHistory['baseRunVariables'][number]) =>
        s.baseRunVariableId === event.baseRunVariableId && s.id !== event.id
    )
  )(data?.baseRunVariables ?? [])

  // We don't want to show unchanged values
  if (previousEvent && parseValue(previousEvent?.value) === parseValue(event?.value)) return null

  return `${startCase(event.baseRunVariable.baseVariable.name)} was changed ${
    previousEvent ? `from "${parseValue(previousEvent?.value)}" ` : ''
  }to "${parseValue(event.value)}"`
}

const constructStepRunEventData = ({
  event,
  data,
  eventIndex,
  field,
}: {
  event: TBaseRunHistory['stepRuns'][number]
  data: TBaseRunHistory
  eventIndex: number
  field?: string
}) => {
  if (!field) return `Step run for "${event.stepRun.step.name}" was created`

  const previousEvent = flow(
    take(eventIndex),
    reverse,
    find(
      (s: TBaseRunHistory['stepRuns'][number]) =>
        s.stepRunId === event.stepRunId && s.id !== event.id
    )
  )(data?.stepRuns ?? [])

  // We don't want to show unchanged values
  if (
    previousEvent &&
    parseValue(JSON.parse(previousEvent.snapshot as string)[field]) ===
      parseValue(JSON.parse(event.snapshot as string)?.[field])
  )
    return null

  return `${startCase(field)} on step run for "${event.stepRun.step.name}" was changed ${
    previousEvent ? `from "${parseValue(JSON.parse(previousEvent.snapshot)[field])}" ` : ''
  }to "${parseValue(JSON.parse(event.snapshot)[field])}"`
}

const PaginationContainer = () => (
  <MUIThemeProvider>
    <Box sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Table.Pagination />
    </Box>
  </MUIThemeProvider>
)

const BaseRunUpdates = ({ data, isLoading }: IProps) => {
  const baseRunData = useMemo(
    () =>
      flow(
        flatten,
        compact,
        orderBy('modifiedOn', 'desc'),
        mapWithKey((event: TBaseRunHistory['baseRun'][number], index: number) => ({
          ...event,
          index: index + 1,
        }))
      )(
        data?.baseRun.map((event, index) => {
          if (event.fieldsChanged.length)
            return event.fieldsChanged.map((field) => {
              const changeDescription = constructBaseRunEventText({
                event,
                data,
                eventIndex: index,
                field,
              })
              return changeDescription
                ? {
                    modifiedOn: event.createdAt,
                    modifiedBy: event.user?.name ?? '',
                    change: changeDescription,
                  }
                : null
            })

          const changeDescription = constructBaseRunEventText({ event, data, eventIndex: index })
          return changeDescription
            ? {
                modifiedOn: event.createdAt,
                modifiedBy: event.user?.name ?? '',
                change: changeDescription,
              }
            : null
        }) ?? []
      ),
    [data]
  ) as TTableData[]

  const baseRunVariableData = useMemo(
    () =>
      flow(
        compact,
        orderBy('modifiedOn', 'desc'),
        mapWithKey((event: TBaseRunHistory['baseRunVariables'][number], index: number) => ({
          ...event,
          index: index + 1,
        }))
      )(
        data?.baseRunVariables.map((event, index) => {
          const changeDescription = constructBaseRunVariableEventText({
            event,
            data,
            eventIndex: index,
          })

          return changeDescription
            ? {
                modifiedOn: event.createdAt,
                modifiedBy: event.user?.name ?? '',
                change: constructBaseRunVariableEventText({ event, data, eventIndex: index }),
              }
            : null
        }) ?? []
      ),
    [data]
  ) as TTableData[]

  const stepRunData = useMemo(
    () =>
      flow(
        flatten,
        compact,
        orderBy('modifiedOn', 'desc'),
        mapWithKey((event: TBaseRunHistory['stepRuns'][number], index: number) => ({
          ...event,
          index: index + 1,
        }))
      )(
        data?.stepRuns?.map((event, index) => {
          if (event.fieldsChanged.length)
            return event.fieldsChanged.map((field) => {
              const changeDescription = constructStepRunEventData({
                event,
                data,
                eventIndex: index,
                field,
              })
              return changeDescription
                ? {
                    modifiedOn: event.createdAt,
                    modifiedBy: event.user?.name ?? '',
                    change: changeDescription,
                  }
                : null
            })

          const changeDescription = constructStepRunEventData({ event, data, eventIndex: index })
          return changeDescription
            ? {
                modifiedOn: event.createdAt,
                modifiedBy: event.user?.name ?? '',
                change: changeDescription,
              }
            : null
        }) ?? []
      ),
    [data]
  ) as TTableData[]

  return (
    <div className='p-3'>
      <Table.TabProvider defaultTabValue='BaseRun'>
        <Table.Header>
          <Table.Header.TabGroup>
            <Table.Header.Tab value='BaseRun' label={`Base Runs (${baseRunData.length})`} />
            <Table.Header.Tab
              value='BaseRunVariables'
              label={`Base Run Variables (${baseRunVariableData.length})`}
            />
            <Table.Header.Tab value='StepRuns' label={`Step Runs (${stepRunData.length})`} />
          </Table.Header.TabGroup>
        </Table.Header>
        <Table.Container tab='BaseRun'>
          <Table.PaginationProvider>
            <Table<TTableData>
              data={baseRunData}
              columns={columns}
              variant='square'
              isDataLoading={isLoading}
            />
            <PaginationContainer />
          </Table.PaginationProvider>
        </Table.Container>
        <Table.Container tab='BaseRunVariables'>
          <Table.PaginationProvider>
            <Table<TTableData>
              data={baseRunVariableData}
              columns={columns}
              variant='square'
              isDataLoading={isLoading}
            />
            <PaginationContainer />
          </Table.PaginationProvider>
        </Table.Container>
        <Table.Container tab='StepRuns'>
          <Table.PaginationProvider>
            <Table<TTableData>
              data={stepRunData}
              columns={columns}
              variant='square'
              isDataLoading={isLoading}
            />
            <PaginationContainer />
          </Table.PaginationProvider>
        </Table.Container>
      </Table.TabProvider>
    </div>
  )
}

export { BaseRunUpdates }
