import { SVGProps } from 'react'
function CircledExclamation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#circled-exclamation_svg__clip0)' fill='currentColor'>
        <path d='M11 0C4.925 0 0 4.926 0 11c0 6.075 4.925 11 11 11s11-4.925 11-11c0-6.074-4.925-11-11-11zm0 20.134a9.135 9.135 0 01-9.134-9.133A9.136 9.136 0 0111 1.867 9.135 9.135 0 0120.134 11 9.135 9.135 0 0111 20.133z' />
        <path d='M9.821 15.322a1.179 1.179 0 102.357 0 1.179 1.179 0 00-2.357 0zm.59-2.75h1.178a.197.197 0 00.196-.196v-6.68a.197.197 0 00-.196-.196H10.41a.197.197 0 00-.196.197v6.679c0 .107.088.196.196.196z' />
      </g>
      <defs>
        <clipPath id='circled-exclamation_svg__clip0'>
          <path fill='currentColor' d='M0 0h22v22H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircledExclamation
