import { UuidSchema, z } from '@invisible/zod'

const filterSchema = z.object({
  id: z.string(),
  isStepAssignee: z.boolean().optional(),
  isStepStatus: z.boolean().optional(),
  isBaseRunStatus: z.boolean().optional(),
  value: z
    .array(
      z.union([
        z.enum(['pending', 'queued', 'running', 'snoozed', 'done', 'passed', 'failed']), // stepRun status
        z.enum(['pending', 'running', 'disabled', 'snoozed', 'done', 'failed']), // baseRun status
        z.string(),
        z.number(),
        z.union([z.boolean(), z.null()]),
      ])
    )
    .or(z.object({ startDate: z.string(), endDate: z.string() })),
})

const dateRangeSchema = z.object({
  start_date: z.string().optional(),
  end_date: z.string().optional(),
})

const schema = z.object({
  columnOrder: z.array(z.string()).optional(),
  filterableColumns: z.array(UuidSchema.or(z.string())).optional(),
  hiddenColumns: z.array(UuidSchema.or(z.string())).optional(),
  sortBy: z.record(z.enum(['asc', 'desc'])).optional(),
  filters: z.array(filterSchema).optional(),
  stepRunFilters: z
    .array(
      z.object({
        step_id: z.string(),
        stopped_at: dateRangeSchema.optional(),
        started_at: dateRangeSchema.optional(),
        created_at: dateRangeSchema.optional(),
        updated_at: dateRangeSchema.optional(),
        delivered_at: dateRangeSchema.optional(),
        expires_at: dateRangeSchema.optional(),
        deadline: dateRangeSchema.optional(),
        unpause_at: dateRangeSchema.optional(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { filterSchema, schema }
export type { TSchema }
