import { z } from '@invisible/zod'

const metadataSchema = z.array(
  z.object({
    name: z.string(),
    type: z.string(),
    baseVariableId: z.string(),
  })
)

const schema = z.object({
  model: z.string().optional(),
  numOfModelCalls: z.number().optional(),
  conversationsBaseId: z.string().optional(),
  promptsBaseId: z.string().optional(),
  responsesBaseId: z.string().optional(),
  promptTextBaseVariableId: z.string().optional(),
  promptIndexBaseVariableId: z.string().optional(),
  promptResponseBaseVariableId: z.string().optional(),
  promptAcceptedModelBaseVariableId: z.string().optional(),
  responseIdBaseVariableId: z.string().optional(),

  responseTextBaseVariableId: z.string().optional(),
  responseModelBaseVariableId: z.string().optional(),
  promptMetadata: metadataSchema.optional(),
  responseMetadata: metadataSchema.optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
