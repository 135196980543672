import { formatAsUTC, getUtcDate } from '@invisible/common/date'
import { classNames as _classNames } from '@invisible/common/helpers'
import { CalendarOutlineIcon } from '@invisible/ui/icons'
import { addYears, format } from 'date-fns'
import defaultLocale from 'date-fns/locale/en-US'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import type { CalendarProps } from 'react-date-range'
import { Calendar } from 'react-date-range'

export interface ICalendar extends CalendarProps {
  onChange: (date: Date) => void
  onSetOpen?: () => void
  date?: Date
  showMonthArrow?: boolean
  showMonthAndYearPickers?: boolean
  disabledDates?: Date[]
  classNames?: Record<string, string>
  locale?: Record<string, string>
  ranges?: {
    startDate: Date
    endDate: Date
    key: string
    color?: string
    autoFocus?: boolean
    disabled?: boolean
    showDateDisplay?: boolean
  }[]
  focusedRange?: number[] & any
  dateDisplayFormat?: string
  monthDisplayFormat?: string
  weekdayDisplayFormat?: string
  dayDisplayFormat?: string
  showDateDisplay?: boolean
  showPreview?: boolean
  displayMode?: 'date' | 'dateRange'
  months?: number
  previewFormat?: string
  forceUtcDate?: boolean
  color?: string
  scroll?: {
    enabled?: boolean
    monthHeight?: number
    longMonthHeight?: number
    monthWidth?: number
    calendarWidth?: number
    calendarHeight?: number
  }
  direction?: 'vertical' | 'horizontal'
  maxDate?: Date
  minDate?: Date
  rangeColors?: string[]
  startDatePlaceholder?: string
  endDatePlaceholder?: string
  editableDateInputs?: boolean
  dragSelectionEnabled?: boolean
  fixedHeight?: boolean
  tableVariant?: boolean
  placeholder?: string
}

interface IProps extends Partial<ICalendar> {
  wFull?: boolean
  disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleDatePicker: FC<IProps> = ({
  date,
  color,
  onChange,
  showMonthArrow = true,
  showMonthAndYearPickers = true,
  disabledDay,
  disabledDates = [],
  classNames = {},
  locale = defaultLocale,
  ranges = [],
  focusedRange = [0, 0],
  dateDisplayFormat = 'MMM d, yyyy',
  monthDisplayFormat = 'MMM yyyy',
  weekdayDisplayFormat = 'E',
  dayDisplayFormat = 'd',
  showDateDisplay = true,
  showPreview = true,
  displayMode = 'date',
  months = 1,
  previewFormat,
  forceUtcDate = false,
  scroll = {
    enabled: false,
  },
  direction = 'vertical',
  maxDate = addYears(new Date(), 20),
  minDate = addYears(new Date(), -100),
  rangeColors = ['#3d91ff', '#3ecf8e', '#fed14c'],
  startDatePlaceholder = 'Early',
  endDatePlaceholder = 'Continuous',
  editableDateInputs = false,
  dragSelectionEnabled = true,
  fixedHeight = false,
  tableVariant = false,
  wFull = false,
  placeholder = 'Select Date',
  disabled = false,
  onSetOpen,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleChange = (newDate: Date) => {
    setOpen(false)
    setSelectedDate(newDate)
    if (onChange) {
      return onChange(newDate)
    }
  }

  const handleClickOut = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        setOpen(false)
      }
    },
    [containerRef.current]
  )

  const getInputDate = () => {
    if (!selectedDate) {
      return new Date()
    } else if (forceUtcDate) {
      return getUtcDate(selectedDate)
    } else {
      return new Date(selectedDate)
    }
  }

  useEffect(() => {
    if (disabled && containerRef?.current) containerRef.current.style.pointerEvents = 'none'
  }, [])

  useEffect(() => {
    if (selectedDate !== date) {
      setSelectedDate(date)
    }
  }, [date])

  useEffect(() => {
    document.addEventListener('click', handleClickOut)

    return () => {
      document.removeEventListener('click', handleClickOut)
    }
  }, [handleClickOut])

  return (
    <div ref={containerRef} data-cy='singleDatePicker'>
      <div
        className={_classNames(
          'border-main hover:border-primary focus:outline-primary relative cursor-pointer',
          'border border-solid duration-300 ease-in-out focus:outline focus:outline-1',
          'flex items-center justify-between p-2',
          tableVariant ? 'rounded-md' : 'rounded-sm',
          wFull ? 'w-full' : 'w-[130px]'
        )}
        tabIndex={0}
        onClick={() => {
          setOpen((prev) => !prev)
          onSetOpen && onSetOpen()
        }}>
        <div className='text-strong text-sm'>
          {!selectedDate || !(selectedDate instanceof Date) ? (
            <span>{placeholder}</span>
          ) : (
            <span className='selected'>
              {previewFormat
                ? format(selectedDate, previewFormat)
                : forceUtcDate
                ? formatAsUTC(selectedDate, 'M/d/yyyy')
                : selectedDate?.toLocaleDateString()}
            </span>
          )}
        </div>
        <CalendarOutlineIcon className='text-muted h-5 w-5' />
      </div>

      {open && (
        <Calendar
          date={getInputDate()}
          color={color}
          onChange={handleChange as any}
          showMonthArrow={showMonthArrow}
          showMonthAndYearPickers={showMonthAndYearPickers}
          disabledDay={disabledDay}
          disabledDates={disabledDates as unknown as Date[]}
          classNames={classNames}
          locale={locale}
          ranges={ranges}
          focusedRange={focusedRange}
          dateDisplayFormat={dateDisplayFormat}
          monthDisplayFormat={monthDisplayFormat}
          weekdayDisplayFormat={weekdayDisplayFormat}
          dayDisplayFormat={dayDisplayFormat}
          showDateDisplay={showDateDisplay}
          showPreview={showPreview}
          displayMode={displayMode}
          months={months}
          scroll={scroll}
          direction={direction}
          maxDate={maxDate}
          minDate={minDate}
          rangeColors={rangeColors}
          startDatePlaceholder={startDatePlaceholder}
          endDatePlaceholder={endDatePlaceholder}
          editableDateInputs={editableDateInputs}
          dragSelectionEnabled={dragSelectionEnabled}
          fixedHeight={fixedHeight}
          {...rest}
        />
      )}
    </div>
  )
}

export { SingleDatePicker }
