import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { isEqual } from 'lodash'
import { capitalize } from 'lodash/fp'
import { Dispatch, SetStateAction, useState } from 'react'

import { convertDateRangeToHumanReadableText } from './helpers'
import { TReducerAction, TStepRunFilters } from './reducer'
import { TimeRangeDialog } from './TimeRangeDialog'

interface IProps {
  open: boolean
  handleClose: () => void
  statusFilter?: { key: string; value: unknown }
  timeRangeFilter?: TStepRunFilters[number]
  dispatch: Dispatch<TReducerAction>
  stepId: string
}

const STEP_RUN_STATUS_OPTIONS = [
  { label: 'Disabled', value: 'disabled' },
  { label: 'Done', value: 'done' },
  { label: 'Expired', value: 'expired' },
  { label: 'Failed', value: 'failed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Queued', value: 'queued' },
  { label: 'Running', value: 'running' },
  { label: 'Snoozed', value: 'snoozed' },
] as const

export const STEP_RUN_PROPERTY_OPTIONS = [
  { label: 'Started at', value: 'started_at' },
  { label: 'Stopped at', value: 'stopped_at' },
  { label: 'Created at', value: 'created_at' },
  { label: 'Updated at', value: 'updated_at' },
  { label: 'Delivered at', value: 'delivered_at' },
  { label: 'Expires at', value: 'expires_at' },
  { label: 'Deadline', value: 'deadline' },
] as const
export const STEP_RUN_PROPERTY_OPTIONS_VALUES = STEP_RUN_PROPERTY_OPTIONS.map(
  (option) => option.value
)

const SingleStepRunFilterDialog = ({
  open,
  handleClose,
  statusFilter,
  timeRangeFilter,
  dispatch,
  stepId,
}: IProps) => {
  const [selectedStepRunStatuses, setSelectedStepRunStatuses] = useState<string[]>(
    (statusFilter?.value ?? []) as string[]
  )
  const [selectedTimeRangeFilters, setSelectedTimeRangeFilters] = useState(timeRangeFilter)
  const [isTimeRangeDialogOpen, setIsTimeRangeDialogOpen] = useState(false)

  const isNoTimeRangeSelected =
    !selectedTimeRangeFilters || isEqual(Object.keys(selectedTimeRangeFilters ?? {}), ['step_id'])

  const handleDialogSubmit = () => {
    // Remove the status filter if no statuses are selected
    if (!selectedStepRunStatuses.length && statusFilter)
      dispatch({
        type: 'REMOVE_FILTERS',
        payload: [statusFilter.key],
      })
    // Add  or update the status filter if statuses are selected
    else if (selectedStepRunStatuses.length)
      dispatch({
        type: 'UPSERT_FILTER',
        payload: {
          key: stepId,
          value: selectedStepRunStatuses,
          is_step_status: true,
        },
      })

    // Remove the time range filters if no time range is selected
    if (isNoTimeRangeSelected)
      dispatch({
        type: 'REMOVE_STEP_RUN_FILTERS',
        payload: [stepId],
      })
    // Add or update the time range filters
    else if (selectedTimeRangeFilters)
      dispatch({
        type: 'UPSERT_STEP_RUN_FILTER',
        payload: {
          key: stepId,
          value: {
            ...selectedTimeRangeFilters,
            step_id: stepId,
          },
        },
      })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontWeight: '500' }}>Configure step run filters</DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            width: '500px',
            height: '500px',
          }}>
          <Typography color='grey.600'>
            You can define, below, step run filters by status and time ranges.
          </Typography>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography fontWeight='500'>Filter the data by status</Typography>
            <Select
              multiple
              value={selectedStepRunStatuses}
              onChange={(e) => {
                const value = e.target.value
                setSelectedStepRunStatuses(
                  typeof value === 'string' ? value.split(',') : (value as string[])
                )
              }}
              input={<OutlinedInput size='small' sx={{ width: '220px' }} />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <Typography color='grey.600'>Choose data by status</Typography>
                }
                return selected.map(capitalize).join(', ')
              }}
              displayEmpty>
              {STEP_RUN_STATUS_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ p: 0, px: '4px' }}>
                  <Checkbox
                    checked={selectedStepRunStatuses.indexOf(option.value) > -1}
                    size='small'
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Divider />

          <Box>
            <Stack direction='row' justifyContent='space-between'>
              <Typography fontWeight='500'>Time range</Typography>
              <Button
                startIcon={<AddIcon />}
                size='small'
                sx={{ fontWeight: 'normal' }}
                onClick={() => setIsTimeRangeDialogOpen(true)}>
                ADD TIME RANGE
              </Button>
            </Stack>

            {isNoTimeRangeSelected ? (
              <Stack
                direction='row'
                gap='4px'
                alignItems='center'
                mt='8px'
                px='16px'
                py='8px'
                borderRadius='4px'
                sx={{ backgroundColor: 'grey.50', color: 'grey.600' }}>
                <InfoIcon sx={{ fontSize: '20px' }} />
                <Typography>No time ranges have been added yet.</Typography>
                <Button
                  size='small'
                  sx={{ fontWeight: 'normal' }}
                  onClick={() => setIsTimeRangeDialogOpen(true)}>
                  Add time range
                </Button>
              </Stack>
            ) : (
              <TimeRangeList
                selectedTimeRangeFilters={selectedTimeRangeFilters}
                setSelectedTimeRangeFilters={setSelectedTimeRangeFilters}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={handleClose}>
            CANCEL
          </Button>
          <Button
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              handleDialogSubmit()
              handleClose()
            }}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>

      <TimeRangeDialog
        isOpen={isTimeRangeDialogOpen}
        onClose={() => setIsTimeRangeDialogOpen(false)}
        onSubmit={(key, value) =>
          setSelectedTimeRangeFilters((prev) => ({
            ...prev,
            step_id: stepId,
            [key]: value,
          }))
        }
      />
    </>
  )
}

const TimeRangeList = ({
  selectedTimeRangeFilters,
  setSelectedTimeRangeFilters,
}: {
  selectedTimeRangeFilters: TStepRunFilters[number]
  setSelectedTimeRangeFilters: Dispatch<SetStateAction<TStepRunFilters[number] | undefined>>
}) => (
  <Stack gap='8px' mt='8px'>
    {STEP_RUN_PROPERTY_OPTIONS_VALUES.map((key) => {
      const value = selectedTimeRangeFilters?.[key]
      if (!value) return null
      return (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          px='16px'
          py='4px'
          borderRadius='4px'
          sx={{ backgroundColor: 'grey.50' }}>
          <Stack direction='row' gap='4px'>
            <Typography fontWeight='500'>
              {STEP_RUN_PROPERTY_OPTIONS.find((option) => option.value === key)?.label}:
            </Typography>
            <Typography>{convertDateRangeToHumanReadableText(value)}</Typography>
          </Stack>

          <IconButton size='small'>
            <ClearIcon
              sx={{ fontSize: '18px' }}
              onClick={() =>
                setSelectedTimeRangeFilters((prev) => {
                  if (!prev) return prev
                  // Remove the selected key from the object
                  const { [key]: _, ...rest } = prev
                  return rest
                })
              }
            />
          </IconButton>
        </Stack>
      )
    })}
  </Stack>
)

export { SingleStepRunFilterDialog }
