import { useAbilityContext } from '@invisible/authorization/client'
import {
  BreadcrumbsContextProvider,
  GeneralLayout,
  PageContextProvider,
  useSidebarContext,
} from '@invisible/common/layouts'
import { CompanySummary, useSelectedCompany } from '@invisible/hooks/use-selected-company'
import { recordPageView } from '@invisible/segment'
import { useUserGuidingIdentifyUser } from '@invisible/thirdparty/user-guiding'
import {
  HomeFilledIcon,
  HomeIcon,
  LockOutlineIcon,
  MessageFilledIcon,
  MessageIcon,
  TaskFilledIcon,
  TaskIcon,
} from '@invisible/ui/icons'
import { ProfileMenu } from '@invisible/ui/profile-menu'
import { setUpRumUser } from '@invisible/utils/datadog/client'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useSession } from 'next-auth/react'
import { FC, useEffect } from 'react'
import { useGate } from 'statsig-react'

import { useCompanyMenu } from '../../common/hooks'
import { CompanyMenu } from '../../views/layout/CompanyMenu'

const USER_GUIDING_SCRIPT = (
  <Script
    id='userguiding'
    strategy='afterInteractive'
    dangerouslySetInnerHTML={{
      __html: `
(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','273953690ID');
`,
    }}></Script>
)

const portalProcessOptions = [
  {
    title: 'Work',
    path: '/work',
    renderIcon: (filled: boolean) =>
      filled ? (
        <TaskFilledIcon className='h-5 w-5' />
      ) : (
        <TaskIcon className='h-5 w-5' filled={filled} />
      ),
  },
  {
    title: 'Message',
    path: '/message/chats', // base path should be /message but we need to redirect to /message/chats for now
    renderIcon: (filled: boolean) =>
      filled ? <MessageFilledIcon className='h-5 w-5' /> : <MessageIcon className='h-5 w-5' />,
  },
]

const profileOptions = [
  {
    title: 'Credentials',
    path: '/settings/credentials',
    renderIcon: () => <LockOutlineIcon className='h-5 w-5' />,
  },
]

const sidebarButtons = portalProcessOptions.map((option) => (
  <GeneralLayout.Sidebar.SidebarLink key={option.path} option={option} />
))

const SidebarCompanyMenu = () => {
  const [isCollapsed] = useSidebarContext()
  return (
    <div>
      {isCollapsed ? null : (
        <div className='px-4'>
          <CompanyMenu />
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Layout: FC = ({ children }) => {
  useUserGuidingIdentifyUser()

  const { data: session } = useSession()
  const user = session?.user
  const router = useRouter()
  const [selectedCompany, setSelectedCompany] = useSelectedCompany()
  const { data: companyMenuData } = useCompanyMenu()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Can, ability] = useAbilityContext()
  useEffect(() => {
    if (companyMenuData?.myCompanies?.[0] && !selectedCompany)
      // no company previously in localStorage
      setSelectedCompany(companyMenuData.myCompanies[0] as CompanySummary)
  }, [selectedCompany, companyMenuData, setSelectedCompany])

  useEffect(() => {
    if (session?.user) {
      const memory = window?.isSecureContext ? (navigator as any)?.deviceMemory : null
      const user = {
        ...(session.user.email && { id: session.user.email }),
        memory,
      }
      setUpRumUser(user)
    }
  }, [session?.user])

  useEffect(() => {
    recordPageView(router.pathname)
  }, [router.pathname])

  const { value: enablePortalProcessMap } = useGate('enable-portal-process-map')

  return (
    <BreadcrumbsContextProvider>
      <PageContextProvider>
        <GeneralLayout>
          {USER_GUIDING_SCRIPT}
          <GeneralLayout.Sidebar appName='portal'>
            <SidebarCompanyMenu />
            <GeneralLayout.Sidebar.SidebarLink
              option={{
                title: 'Home',
                path: '/',
                renderIcon: (filled: boolean) =>
                  filled ? (
                    <HomeFilledIcon className='h-5 w-5' />
                  ) : (
                    <HomeIcon className='h-5 w-5' />
                  ),
              }}
              exact
            />
            {sidebarButtons.filter((option: JSX.Element) => {
              if (option.key === '/design') return enablePortalProcessMap
              return true
            })}
          </GeneralLayout.Sidebar>
          <GeneralLayout.ContentArea>
            <GeneralLayout.ContentArea.Topbar>
              {user && (
                <ProfileMenu
                  profilePicture={user?.image || ''}
                  profileText={user?.name || ''}
                  profileSubText={user?.email || ''}
                  profileOptions={profileOptions}
                />
              )}
            </GeneralLayout.ContentArea.Topbar>
            <GeneralLayout.ContentArea.Page>{children}</GeneralLayout.ContentArea.Page>
            <GeneralLayout.ContentArea.CookieConsentBar />
          </GeneralLayout.ContentArea>
        </GeneralLayout>
      </PageContextProvider>
    </BreadcrumbsContextProvider>
  )
}

export { Layout }
