import { createContext, Dispatch, FC, useContext, useReducer } from 'react'

/**
 * Used to map an EmbedControlWAC to it's corresponding EmbedWAC
 * Stored as an object, where the key is the EmbedWAC id and the value
 * is the currently selected link.
 */

type Action =
  | {
      type: 'changeEmbedControlLink' | 'changeCurrentEmbedLink'
      payload: {
        embedId: string
        link: string
      }
    }
  | {
      type: 'reset'
    }

const SelectedEmbedContextReducer = createContext<
  | {
      state: {
        embedControlLink: Record<string, string>
        currentEmbedLink: Record<string, string>
      }
      dispatch: Dispatch<Action>
    }
  | undefined
>(undefined)

export const SelectedEmbedLinkReducer = (
  state: {
    embedControlLink: Record<string, string>
    currentEmbedLink: Record<string, string>
  },
  action: Action
) => {
  switch (action.type) {
    case 'changeEmbedControlLink': {
      return {
        ...state,
        embedControlLink: { [action.payload.embedId]: action.payload.link },
      }
    }
    case 'changeCurrentEmbedLink': {
      return {
        ...state,
        currentEmbedLink: { [action.payload.embedId]: action.payload.link },
      }
    }
    case 'reset': {
      return {
        embedControlLink: {},
        currentEmbedLink: {},
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SelectedEmbedLinkProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(SelectedEmbedLinkReducer, {
    embedControlLink: {},
    currentEmbedLink: {},
  })
  const value = { state, dispatch }
  return (
    <SelectedEmbedContextReducer.Provider value={value}>
      {children}
    </SelectedEmbedContextReducer.Provider>
  )
}

export const useSelectedEmbedLink = () => {
  const context = useContext(SelectedEmbedContextReducer)
  if (context === undefined) {
    throw new Error('useSelectedEmbedLink must be used within a SelectedEmbedLinkProvider')
  }
  return context
}
