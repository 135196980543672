import { z } from '@invisible/zod'

import { FORMULA_RESULT_TYPES } from '../../constants/formula'
import { formulaSchema } from './common'

/**
 * Formula that returns a boolean.
 */
const predicateSchema = formulaSchema.omit({ resultType: true }).extend({
  resultType: z.literal(FORMULA_RESULT_TYPES.BOOLEAN),
})

/**
 * Formula that returns a boolean.
 */
type TPredicate = z.infer<typeof predicateSchema>

export { predicateSchema }
export type { TPredicate }
