import { SVGProps } from 'react'
function Launch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9802 3.7694c2.3168-2.3167 4.5805-2.2667 5.5001-2.134.1326.9196.1826 3.1833-2.1341 5.5l-1.1108 1.1108a.7135.7135 0 0 0-.0165.0165l-5.0372 5.0372-3.366-3.366 6.1645-6.1645Zm4.4865 4.4865-.8871.887v6.0809a1.585 1.585 0 0 1-.4716 1.1054l-3.1974 3.2073a1.584 1.584 0 0 1-1.5803.3951 1.5848 1.5848 0 0 1-1.0936-1.2065l-.7846-3.9139-4.1476-4.1476L1.391 9.879l-.0007-.0001A1.5846 1.5846 0 0 1 .579 7.205l.001-.0012 3.2062-3.1962a1.5846 1.5846 0 0 1 1.1054-.4715l.0113-.0001h6.0697l.8871-.887C14.626-.1174 17.4453-.1157 18.7101.0674a1.5645 1.5645 0 0 1 1.3381 1.3382c.1831 1.2647.1847 4.084-2.5815 6.8503ZM9.3881 5.1206h-4.474l-.0063.0065L1.7 8.3248l.0012.0003 3.7338.7485 3.953-3.953Zm1.6539 9.5599 3.953-3.953v4.4737l-3.2042 3.2142-.7488-3.7349Zm-6.2132-.6382a.7924.7924 0 0 0-.5051-1.502c-2.0144.6774-2.9793 2.3528-3.437 3.7268-.231.6942-.3452 1.344-.402 1.8183a8.7099 8.7099 0 0 0-.0505.5753 6.0082 6.0082 0 0 0-.0075.2137l-.0002.0145v.0073c0 .0004 0 .0007.7923.0007H.4265c0 .4375.3547.7923.7923.7923v-.7923c0 .7923.0003.7923.0007.7923h.0025l.0048-.0001.0145-.0001a6.0103 6.0103 0 0 0 .2138-.0075 8.6536 8.6536 0 0 0 .5752-.0504c.4743-.0569 1.1241-.171 1.8182-.4022 1.3741-.4576 3.0495-1.4225 3.7269-3.4369a.7923.7923 0 0 0-1.502-.5051c-.4516 1.343-1.579 2.0568-2.7255 2.4386-.4552.1516-.8935.2432-1.2559.2982.055-.3624.1466-.8007.2982-1.2559.3818-1.1465 1.0956-2.2739 2.4386-2.7255Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Launch
