import { fromGlobalId, IProcessByIdQuery } from '@invisible/concorde/gql-client'

import { TProcessByIdBase } from './types'
import {
  TGraphqlBases,
  TGraphqlBaseVariables,
  TGraphqlCompany,
  TGraphqlLifecycleStages,
  TGraphqlOperationsManager,
  TGraphqlProcessTags,
  TGraphqlRootBase,
  TGraphqlStepGoTos,
  TGraphqlSteps,
  TGraphqlStepTemplateVariables,
  TProcessLayout,
  UnifiedProcessById,
} from './types'

/**
 * Checks if a company is valid.
 *
 * @param company - The company to check.
 * @returns `true` if the company is not null or undefined, `false` otherwise.
 */
const isCompanyValid = (company: TGraphqlCompany | null): boolean =>
  company !== null && company !== undefined

/**
 * Extracts the id and name fields from a TGraphqlCompany object.
 *
 * @param company - The TGraphqlCompany object to extract fields from.
 * @returns An object containing the id and name fields.
 */
const extractCompanyFields = (company: TGraphqlCompany): { id: string; name: string } => ({
  id: fromGlobalId(company?.id),
  name: company?.name ?? '',
})

/**
 * Maps a lifecycle stage from the GraphQL response to a simplified object.
 *
 * @param stage - The lifecycle stage to map.
 * @returns The mapped lifecycle stage object.
 */
const mapLifecycleStage = (stage: TGraphqlLifecycleStages[0]) => ({
  id: fromGlobalId(stage.id),
  name: stage.name,
  position: stage.position,
})

/**
 * Maps a `goTo` object to a new object with `goToStepId` and `goFromStepId` properties.
 * @param goTo - The `goTo` object to be mapped.
 * @returns An object with `goToStepId` and `goFromStepId` properties.
 */
const mapStepGoTo = (goTo: TGraphqlStepGoTos[0], processId: string) => ({
  processId,
  goToStepId: fromGlobalId(goTo.goToStep.id),
  goFromStepId: fromGlobalId(goTo.goFromStep.id),
  goToStep: {
    id: fromGlobalId(goTo.goToStep.id),
    name: goTo.goToStep.name,
    meta: goTo.goToStep.meta ?? {},
    stepTemplate: {
      id: fromGlobalId(goTo.goToStep.stepTemplate.id),
      name: goTo.goToStep.stepTemplate.name,
      subtype: goTo.goToStep.stepTemplate.subtype ?? '',
    },
  },
  goFromStep: {
    id: fromGlobalId(goTo.goFromStep.id),
    name: goTo.goFromStep.name,
    meta: goTo.goFromStep.meta ?? {},
    stepTemplate: {
      id: fromGlobalId(goTo.goFromStep.stepTemplate.id),
      name: goTo.goFromStep.stepTemplate.name,
      subtype: goTo.goFromStep.stepTemplate.subtype ?? '',
    },
  },
})

/**
 * Maps a base variable to a new object with modified properties.
 *
 * @param variable - The base variable to be mapped.
 * @param baseId - The ID of the base.
 * @returns The mapped object with modified properties.
 */
const mapBaseVariable = (variable: TGraphqlBaseVariables[0], baseId: string) => ({
  id: fromGlobalId(variable.id),
  name: variable.name,
  key: variable.key,
  type: variable.type,
  hidden: variable.hidden ?? null,
  required: variable.required,
  baseId: fromGlobalId(baseId),
  options: variable.options ?? [],
  defaultValue: variable.defaultValue ?? null,
})

/**
 * Maps a base object from the GraphQL response to a unified format.
 *
 * @param base - The base object to be mapped.
 * @returns The mapped base object.
 */
const mapBase = (base: TGraphqlBases[0], processId: string) => ({
  processId,
  id: fromGlobalId(base.id),
  sla: base.sla,
  name: base.name,
  deadline: base.deadline,
  qualityKpi: base.qualityKpi,
  parentId: fromGlobalId(base.parent?.id),
  baseVariables: mapGraphqlToUnifiedBaseVars(base.baseVariables, base.id),
  deadlineStrategy: {
    id: base.deadlineStrategy?.id ? fromGlobalId(base.deadlineStrategy.id) : '',
    name: base.deadlineStrategy?.name ?? '',
    baseVariableId: base.deadlineStrategy?.baseVariable?.id
      ? fromGlobalId(base.deadlineStrategy?.baseVariable?.id)
      : '',
    baseVariable: {
      id: base.deadlineStrategy?.baseVariable?.id
        ? fromGlobalId(base.deadlineStrategy.baseVariable.id)
        : '',
      name: base.deadlineStrategy?.baseVariable?.name ?? '',
    },
  },
})

/**
 * Maps a step template reference to a new object with parsed IDs.
 *
 * @param ref - The step template reference to map.
 * @param stepId - The ID of the step.
 * @returns An object with parsed IDs for stepId, baseVariableId, and stepTemplateVariableId.
 */
const mapStepTemplateReference = (
  ref: TGraphqlStepTemplateVariables[0]['stepVariableReferences'][0],
  stepId: string
) => ({
  id: fromGlobalId(ref.id),
  stepId: fromGlobalId(stepId),
  baseVariableId: fromGlobalId(ref.baseVariable?.id),
  stepTemplateVariableId: fromGlobalId(ref.stepTemplateVariable.id),
})

/**
 * Maps a step template variable to a new object format.
 *
 * @param variable - The step template variable to map.
 * @param stepId - The ID of the step.
 * @returns The mapped step template variable object.
 */
const mapStepTemplateVariable = (variable: TGraphqlStepTemplateVariables[0], stepId: string) => ({
  id: fromGlobalId(variable.id),
  key: variable.key,
  name: variable.name,
  type: variable.type,
  required: variable.required,
  stepId: fromGlobalId(stepId),
  stepTemplateVariableId: fromGlobalId(variable.id),
  position: variable.position,
  direction: variable.direction,
  createdAt: variable.createdAt,
  updatedAt: variable.updatedAt,
  stepVariableReferences: variable.stepVariableReferences.map((ref) =>
    mapStepTemplateReference(ref, stepId)
  ),
})

/**
 * Maps a step object to a step template object.
 * @param step - The step object to map.
 * @param companyId - The ID of the company.
 * @returns The mapped step template object.
 */
const mapStepTemplate = (step: TGraphqlSteps[0], companyId: string) => ({
  id: fromGlobalId(step.stepTemplate.id),
  name: step.stepTemplate.name,
  icon: step.stepTemplate.icon,
  type: step.stepTemplate.type,
  companyId,
  costInMillicents: step.stepTemplate.costInMillicents ?? null,
  subtype: step.stepTemplate.subtype ?? '',
  service: {
    id: fromGlobalId(step.stepTemplate.service?.id),
    name: step.stepTemplate.service?.name ?? '',
  },
  credentialType: {
    id: fromGlobalId(step.stepTemplate.credentialTypeId),
  },
  stepTemplateVariables: mapGraphqlToUnifiedStepTemplateVariables(
    step.stepTemplate.stepTemplateVariables,
    step.id
  ),
})

/**
 * Maps a step object to a new object with transformed properties.
 * @param step - The step object to map.
 * @param companyId - The ID of the company.
 * @returns The mapped step object.
 */
const mapStep = (step: TGraphqlSteps[0], companyId: string) => ({
  id: fromGlobalId(step.id),
  name: step.name,
  updatedAt: step.updatedAt,
  baseId: fromGlobalId(step.base.id),
  meta: step.meta ?? {},
  pauseFor: step.pauseFor ?? null,
  expiresIn: step.expiresIn ?? null,
  processId: fromGlobalId(step.id),
  description: step.description ?? '',
  leadTracking: step.leadTracking,
  costInMillicents: step.costInMillicents ?? null,
  stepTemplateId: fromGlobalId(step.stepTemplate.id),
  lifecycleStageId: fromGlobalId(step.lifecycleStage?.id),
  clientCredentialId: fromGlobalId(step.clientCredentialId),
  stepVariableReferences: step.stepVariableReferences.map((ref) =>
    mapStepTemplateReference(ref, step.id)
  ),
  stepTemplate: mapStepTemplate(step, companyId),
})

const mapLayout = (layout: TProcessLayout) => ({
  id: fromGlobalId(layout.id),
  name: layout.name,
  orientation: layout.orientation,
})

const mapOperationsManager = (operationsManager: TGraphqlOperationsManager) => ({
  id: operationsManager?.id ? fromGlobalId(operationsManager.id) : '',
  name: operationsManager?.name ?? '',
})

const mapTag = (tag: TGraphqlProcessTags[0]) => ({
  tag: {
    id: fromGlobalId(tag.id),
    name: tag.name,
    tagType: {
      id: fromGlobalId(tag.tagType.id),
      name: tag.tagType.name,
    },
  },
})

const mapRootBase = (rootBase: TGraphqlRootBase) => ({
  id: fromGlobalId(rootBase?.id),
  name: rootBase?.name ?? null,
  sla: rootBase?.sla ?? null,
})

const mapGraphqlToUnifiedCompany = (company: TGraphqlCompany) => {
  if (!isCompanyValid(company)) {
    return null
  }

  return extractCompanyFields(company)
}
const mapGraphqlToUnifiedStepTemplateVariables = (
  stepTemplateVariables: TGraphqlStepTemplateVariables,
  stepId: string
) => stepTemplateVariables.map((variable) => mapStepTemplateVariable(variable, stepId))
const mapGraphqlToUnifiedBaseVars = (baseVariables: TGraphqlBaseVariables, baseId: string) =>
  baseVariables.map((variable) => mapBaseVariable(variable, baseId))
const mapGraphqlToUnifiedLifecycleStages = (lifecycleStages: TGraphqlLifecycleStages) =>
  lifecycleStages.map(mapLifecycleStage)
const mapGraphqlToUnifiedStepGoTos = (stepGoTos: TGraphqlStepGoTos, processId: string) =>
  stepGoTos.map((goTo) => mapStepGoTo(goTo, processId))
const mapGraphqlToUnifiedBases = (bases: TGraphqlBases, processId: string) =>
  bases.map((base) => mapBase(base, processId))
const mapGraphqlToUnifiedSteps = (steps: TGraphqlSteps, companyId: string) =>
  steps.map((step) => mapStep(step, companyId))
const mapGraphqlToUnifiedLayouts = (layouts: TProcessLayout[]) =>
  layouts.map((layout) => mapLayout(layout))
const mapGraphqlToUnifiedProcessToTags = (tags: TGraphqlProcessTags) =>
  tags.map((tag) => mapTag(tag))

/**
 * Maps the GraphQL response data to a UnifiedProcessById object.
 *
 * @param data - The GraphQL response data of type IProcessByIdQuery.
 * @returns The mapped UnifiedProcessById object.
 */
export const mapGraphqlToUnifiedProcess = (data: IProcessByIdQuery | undefined): UnifiedProcessById | null => {
  if (!data?.process) return null
  return {
    id: fromGlobalId(data.process.id),
    name: data.process.name,
    status: data.process.status,
    companyId: fromGlobalId(data.process.company?.id),
    rootBaseId: fromGlobalId(data.process.rootBase?.id),
    company: mapGraphqlToUnifiedCompany(data.process.company),
    lifecycleStages: mapGraphqlToUnifiedLifecycleStages(data.process.lifecycleStages),
    stepGoTos: mapGraphqlToUnifiedStepGoTos(data.process.stepGoTos, data.process.id),
    bases: mapGraphqlToUnifiedBases(data.process.bases, data.process.id) as TProcessByIdBase[],
    steps: mapGraphqlToUnifiedSteps(data.process.steps, data?.process?.company?.id),
    processLayouts: mapGraphqlToUnifiedLayouts(data.process.processLayouts),
    operationsManager: mapOperationsManager(data.process.operationsManager),
    processToTags: mapGraphqlToUnifiedProcessToTags(data.process.tags),
    template: data.process.template,
    description: data.process.description,
    shortDescription: data.process.shortDescription,
    looopResourceHubId: data.process.looopResourceHubId ?? null,
    leaderUserId: data.process.leaderUser?.id,
    enableBillEvents: data.process.enableBillEvents,
    defaultReportBaseId: data.process.defaultReportBase
      ? fromGlobalId(data.process.defaultReportBase.id)
      : null,
    rootBase: mapRootBase(data.process.rootBase),
  }
}
