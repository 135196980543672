import MuiPagination from '@mui/material/Pagination'
import { TablePaginationProps } from '@mui/material/TablePagination'
import {
  gridPageSizeSelector,
  GridPagination,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'

const BasePagination = ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const apiRef = useGridApiContext()
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const rowCount = useGridSelector(apiRef, gridRowCountSelector)

  const pageCount = Math.ceil(rowCount / pageSize)

  return (
    <MuiPagination
      color='primary'
      shape='rounded'
      variant='outlined'
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
    />
  )
}

const Pagination = (props: any) => <GridPagination ActionsComponent={BasePagination} {...props} />

export default Pagination
