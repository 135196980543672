import { SVGProps } from 'react'
function CircledPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11 1.375c-5.315 0-9.625 4.31-9.625 9.625s4.31 9.625 9.625 9.625 9.625-4.31 9.625-9.625S16.315 1.375 11 1.375zm0 17.617A7.994 7.994 0 013.008 11 7.994 7.994 0 0111 3.008 7.994 7.994 0 0118.992 11 7.994 7.994 0 0111 18.992z'
        fill='currentColor'
      />
      <path
        d='M15.455 10.722L9.093 6.103a.342.342 0 00-.543.277v9.239c0 .281.318.44.543.277l6.362-4.62a.341.341 0 000-.554zm-5.534 2.88V8.397l3.581 2.601-3.581 2.602z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CircledPlay
