import { serialize } from '@invisible/common/helpers'
import { isEqual } from 'lodash'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

export interface IPageState {
  title?: string
  subTitle?: string | ReactNode
  fullWidth?: boolean
  breadcrumbs?: (string | { name: string; path: string })[]
  subtitleLink?: string
}

const initialState: IPageState = { breadcrumbs: [] }

const PageContext = createContext<[IPageState, Dispatch<SetStateAction<IPageState>>]>([
  initialState,
  () => null,
])

// eslint-disable-next-line @typescript-eslint/ban-types
export const PageContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<IPageState>({})
  return <PageContext.Provider value={[state, setState]}>{children}</PageContext.Provider>
}

export const usePageContext = (newState?: IPageState) => {
  const [state, setState] = useContext(PageContext)

  useEffect(() => {
    if (
      newState &&
      !isEqual(
        { ...state, subtitle: state.subTitle ? serialize(state.subTitle) : undefined },
        { ...newState, subtitle: newState.subTitle ? serialize(newState?.subTitle) : undefined }
      )
    )
      setState((s) => ({ ...s, ...newState }))
  }, [])

  return [state, setState] as const
}
