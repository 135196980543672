import { SVGProps } from 'react'
function Bank(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 34 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M32.3474 13.9891C33.5889 13.9891 34.1072 12.394 33.0988 11.6587L17.7546 0.497098C17.5354 0.336552 17.2709 0.25 16.9992 0.25C16.7276 0.25 16.463 0.336552 16.2438 0.497098L0.899649 11.6587C-0.108833 12.39 0.409471 13.9891 1.65501 13.9891H4.14206V29.0159H1.2492C1.07242 29.0159 0.927774 29.1605 0.927774 29.3373V31.4266C0.927774 31.6034 1.07242 31.748 1.2492 31.748H32.7492C32.926 31.748 33.0706 31.6034 33.0706 31.4266V29.3373C33.0706 29.1605 32.926 29.0159 32.7492 29.0159H29.8563V13.9891H32.3474ZM16.9992 3.32969L27.8916 11.2529H6.10679L16.9992 3.32969ZM7.03492 13.9891H11.7358V29.0159H7.03492V13.9891ZM14.6287 13.9891H19.3296V29.0159H14.6287V13.9891ZM26.9635 29.0159H22.2224V13.9891H26.9635V29.0159Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Bank
