import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

import { TForm } from './ExportTaskScheduleConfiguration';
import { SCHEDULING_OPTIONS } from './SchedulerConstants';

interface ScheduleOptionSelectorProps {
    control: Control<TForm>;
    onUpdateScheduleOption: (value: string | null) => void;
}

const ScheduleOptionSelector = ({ control, onUpdateScheduleOption }: ScheduleOptionSelectorProps) => (
    <Stack direction='row' alignItems='center' gap='16px'>
        <Typography width='180px'>Export on this schedule* </Typography>
        <Controller
            name='taskSchedule'
            control={control}
            render={({ field }) => (
                <Autocomplete
                    options={SCHEDULING_OPTIONS}
                    value={SCHEDULING_OPTIONS.find((b) => b.value === field.value)}
                    renderInput={(params) => <TextField {...params} label='Select schedule...' />}
                    size='small'
                    sx={{
                        width: '250px',
                    }}
                    onChange={(_, newValue) => onUpdateScheduleOption(newValue?.value ?? null)}
                />
            )}
        />
    </Stack>
);

export { ScheduleOptionSelector }
