import { SVGProps } from 'react'
function LockPlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.24 19.9275H40.32C41.382 19.9275 42.24 20.737 42.24 21.7391V26.9558C41.68 26.8957 41.1105 26.8648 40.5333 26.8648C39.6418 26.8648 38.7686 26.9385 37.92 27.0798V24.0035H4.32V41.2136H25.6024C25.6295 42.6335 25.8792 44.0021 26.32 45.2897H1.92C0.858001 45.2897 0 44.4801 0 43.4781V21.7391C0 20.737 0.858001 19.9275 1.92 19.9275H6.00001V7.24635C6.00001 3.24387 9.43801 0 13.68 0H28.56C32.802 0 36.24 3.24387 36.24 7.24635V19.9275ZM13.68 4.07607C11.826 4.07607 10.32 5.49704 10.32 7.24635V19.9275H31.92V7.24635C31.92 5.49704 30.414 4.07607 28.56 4.07607H13.68ZM19.44 36.345V33.3445C18.9447 33.009 18.5751 32.5339 18.3842 31.9875C18.1933 31.4411 18.1911 30.8517 18.3779 30.3041C18.5647 29.7565 18.9308 29.2789 19.4236 28.9401C19.9163 28.6013 20.5103 28.4188 21.12 28.4188C21.7297 28.4188 22.3237 28.6013 22.8165 28.9401C23.3092 29.2789 23.6754 29.7565 23.8621 30.3041C24.0489 30.8517 24.0467 31.4411 23.8558 31.9875C23.665 32.5339 23.2953 33.009 22.8 33.3445V36.345C22.8 36.5941 22.584 36.7979 22.32 36.7979H19.92C19.656 36.7979 19.44 36.5941 19.44 36.345Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.5467 42.364L37.5467 48L42.0267 48V42.364H48V38.1369H42.0267V32.5009H37.5467L37.5467 38.1369H31.5734V42.364H37.5467Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LockPlus
