import { safeJSONParse } from '@invisible/common/helpers'
import { Dropdown } from '@invisible/ui/dropdown'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import {
  BooleanCell,
  CurrencyCell,
  DateCell,
  DateFilter,
  DateTimeCell,
  DurationCell,
  InputCell,
  RichTextCell,
  UrlCell,
} from '@invisible/ui/react-table'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { CellContext } from '@tanstack/react-table'
import { isArray, isObjectLike, isPlainObject, isUndefined } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { memo, useCallback, useState } from 'react'

const InputCellMemoized = memo(InputCell)
const BooleanCellMemoized = memo(BooleanCell)
const DropdownMemoized = memo(Dropdown)
const RichTextMemoized = memo(RichTextCell)
const DateCellMemoized = memo(DateCell)
const UrlCellMemoized = memo(UrlCell)
const DurationCellMemoized = memo(DurationCell)
const CurrencyCellMemoized = memo(CurrencyCell)
const DateTimeCellMemoized = memo(DateTimeCell)

const Editor = dynamic(() => import('react-json-view'), { ssr: false })

type ICustomCellProps = CellContext<Record<string, any>, any>

const JSONCell = memo(
  ({
    value,
    onChange,
    disabled,
    defaultValue,
  }: {
    value: unknown
    onChange: (value: unknown) => Promise<void>
    disabled: boolean
    defaultValue: unknown
  }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const parsedValue = isObjectLike(value) ? value : safeJSONParse(value as string)
    const stringifiedValue = parsedValue ? JSON.stringify(parsedValue) : ''

    return (
      <MUIThemeProvider>
        <Stack direction='row' gap='4px' alignItems='center'>
          <div title={stringifiedValue}>
            {/* Show first 40 characters */}
            {stringifiedValue.substring(0, 40)}
            {stringifiedValue.length > 40 ? '...' : ''}
          </div>
          <IconButton onClick={() => setIsExpanded(true)}>
            <OpenInFullIcon color='primary' sx={{ fontSize: '14px' }} />
          </IconButton>
        </Stack>

        <Dialog open={isExpanded} onClose={() => setIsExpanded(false)}>
          <DialogContent sx={{ height: '500px', width: '500px' }}>
            <Editor
              src={parsedValue ?? defaultValue}
              name='value'
              displayDataTypes={false}
              enableClipboard={false}
              quotesOnKeys={false}
              validationMessage='Invalid JSON'
              onEdit={disabled ? undefined : ({ updated_src }) => onChange(updated_src)}
              onAdd={disabled ? undefined : ({ updated_src }) => onChange(updated_src)}
              onDelete={disabled ? undefined : ({ updated_src }) => onChange(updated_src)}
            />
          </DialogContent>
        </Dialog>
      </MUIThemeProvider>
    )
  }
)

/**
 *
 * Get custom cell based on base variable type.
 * A base variable has a filed type which set in the builder.
 * Base variables are rendered in the baseviews based on their type.
 *
 * A base variable value (baseRunVariable) can be in 3 states:
 * 1. null: the value is not set
 *    In this case, we render an empty cell, so that the user can set a value.
 * 2. undefined: the given base variable doesn't exsist in the given base run
 *    This can happen if a base variable added to a base after some base runs are created.
 *    Normally we create a base run variable for old base runs when a base variable created.
 *    But still we have some legacy data.
 *    In this case we render nothing, since the base variable doesn't exist in the base run.
 * 3. value exists
 *    If a value exists, we try to render the value with proper input based on the type of the base variable.
 *
 *    There is an edge case here:
 *    Since base variable types are mutable at any given time, imagine a base variable created as a number type.
 *    After a while the type of the base variable changed to object.
 *    Since we will have some base runs with a number value, and some base runs with an object value.
 *    (Normally we should follow with a data migration, either automate, or warn the user about the consequences of the change.)
 *
 *    What will happen is, we will try to render object type as a number, which will result in:
 *
 *    - Error: Objects are not valid as a React child
 *
 *    To prevent this, if the value is set we check the actual value of the base run variable and the type of the base variable.
 *    If the actual value is not compatible with the type of the base variable, we render nothing.
 *
 * The check guarding against all 3 states is:
 * case 'number':
 *  if (isUndefined(actualValue) || (actualValue !== null && typeof actualValue !== 'number')) {
 *    return null
 *  }
 *  ....
 *
 * @param type type of base variable see: libs/ultron/zod/src/constants/variable.ts
 * @param width width of the cell
 */
export const getCustomCell = (type: string, width: number) => {
  switch (type) {
    case 'number':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          const handleChange = useCallback(
            ({ value, id }: { value: string; id: string | number }) => {
              info.table.options?.meta?.updateBaseRunVariable?.({
                baseRunVariableId: info.getValue()?.id,
                value: Number(value),
              })
            },
            [info.table.options?.meta?.updateBaseRunVariable, info.getValue()?.id]
          )
          const isANumberStr = typeof actualValue === 'string' && !isNaN(Number(actualValue))
          if (
            (isUndefined(actualValue) ||
              (actualValue !== null && typeof actualValue !== 'number')) &&
            !isANumberStr
          ) {
            return null
          }

          return (
            <InputCellMemoized
              inputType='number'
              value={info.getValue()?.actualValue}
              disabled={!info.getValue()?.isEditable}
              id={info.getValue()?.id}
              onChange={handleChange}
            />
          )
        },
      }
    case 'boolean':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue

          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'boolean')
          ) {
            return null
          }

          return (
            <BooleanCellMemoized
              isOn={info.getValue()?.actualValue ?? null}
              onToggle={(value) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value,
                })
              }
              disabled={!info.getValue()?.isEditable}
            />
          )
        },
      }
    case 'enum':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue

          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }

          return (
            <DropdownMemoized
              disabled={!info.getValue()?.isEditable}
              width={`${width - 10}px`}
              options={(info.getValue()?.options ?? []).map((e: string) => ({
                key: e,
                value: e,
              }))}
              selectedKey={info.getValue()?.actualValue}
              onChange={({ key }) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value: key,
                })
              }
              name='Select a value'
            />
          )
        },
      }
    case 'html':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }

          return (
            <RichTextMemoized
              value={info.getValue()?.actualValue}
              onChange={(value) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value,
                })
              }
            />
          )
        },
      }

    case 'datetime':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }
          return (
            <DateTimeCellMemoized
              disabled={!info.getValue()?.isEditable}
              onChange={(value) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value: value?.toISOString(),
                })
              }
              value={info.getValue()?.actualValue ? new Date(info.getValue()?.actualValue) : null}
            />
          )
        },
        Filter: (props: any) => <DateFilter {...props} />,
      }

    case 'date':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }
          return (
            <DateCellMemoized
              disabled={!info.getValue()?.isEditable}
              onChange={(value) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value: value?.toISOString(),
                })
              }
              width={`${width - 30}px`}
              value={info.getValue()?.actualValue ? new Date(info.getValue()?.actualValue) : null}
            />
          )
        },
        Filter: (props: any) => <DateFilter {...props} />,
      }
    case 'url':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }

          return (
            <UrlCellMemoized
              width={width}
              value={info.getValue()?.actualValue}
              isEditable={info.getValue()?.isEditable}
              id={info.getValue()?.id}
              onChange={({ value }) =>
                info.table.options?.meta?.updateBaseRunVariable?.({
                  baseRunVariableId: info.getValue()?.id,
                  value,
                })
              }
              onLinkClick={info.getValue()?.onUrlLinkClick}
            />
          )
        },
      }
    case 'timeleft':
      return {
        Cell: (info: ICustomCellProps) => {
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'number')
          ) {
            return null
          }

          return (
            <DurationCellMemoized
              duration={info.getValue()?.actualValue}
              type={
                info.getValue()?.actualValue - new Date().getTime() < 10000000
                  ? 'danger'
                  : info.getValue()?.actualValue - new Date().getTime() < 20000000
                  ? 'warning'
                  : 'safe'
              }
            />
          )
        },
      }

    case 'money':
      return {
        Cell: (info: ICustomCellProps) => {
          const handleBaseRunVariableUpdate = (value: string | undefined): void => {
            info.table.options?.meta?.updateBaseRunVariable?.({
              baseRunVariableId: info.getValue()?.id,
              value: value ? Number(value) : 0,
            })
          }
          const actualValue = info.getValue()?.actualValue
          if (
            isUndefined(actualValue) ||
            (actualValue !== null && typeof actualValue !== 'string')
          ) {
            return null
          }

          return (
            <CurrencyCellMemoized
              id={info.getValue()?.id}
              value={info.getValue()?.actualValue}
              disabled={!info.getValue()?.isEditable}
              onBlur={handleBaseRunVariableUpdate}
              label='currency-input'
            />
          )
        },
      }

    case 'object':
    case 'a_string':
    case 'a_boolean':
    case 'a_email':
    case 'a_enum':
    case 'a_html':
    case 'a_number':
    case 'a_url':
      return {
        Cell: (info: ICustomCellProps) => {
          const handleChange = useCallback(
            async (value: unknown) => {
              await info.table.options?.meta?.updateBaseRunVariable?.({
                baseRunVariableId: info.getValue()?.id,
                value,
              })
            },
            [info.table.options?.meta?.updateBaseRunVariable, info.getValue()?.id]
          )
          const value = info.getValue()?.actualValue
          if (isUndefined(info.getValue()?.actualValue)) return null

          return (
            <JSONCell
              value={value}
              onChange={handleChange}
              disabled={!info.getValue()?.isEditable}
              defaultValue={type === 'object' ? {} : []}
            />
          )
        },
      }

    default:
      return {
        Cell: (info: ICustomCellProps) => {
          const handleChange = useCallback(
            ({ value, id }: { value: string; id: string | number }) => {
              info.table.options?.meta?.updateBaseRunVariable?.({
                baseRunVariableId: info.getValue()?.id,
                value,
              })
            },
            [info.table.options?.meta?.updateBaseRunVariable, info.getValue()?.id]
          )
          if (isUndefined(info.getValue()?.actualValue)) return null

          const value = info.getValue()?.actualValue
          const formattedValue =
            isPlainObject(value) || isArray(value) ? JSON.stringify(value) : String(value)

          return (
            <InputCellMemoized
              variableType={type}
              value={formattedValue}
              disabled={!info.getValue()?.isEditable}
              id={info.getValue()?.id}
              onChange={handleChange}
              style={{ width }}
              expandable={info.getValue()?.expandable}
            />
          )
        },
      }
  }
}
