import { classNames } from '@invisible/common/helpers'
import Tooltip from '@mui/material/Tooltip'
import { Instance } from '@popperjs/core'
import {
  forwardRef,
  MouseEvent,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { ContextMenu } from '../common/ContextMenu'
import { AnnotationEdit } from './AnnotationEdit'
import { formatTime } from './helpers'

type Annotation = {
  id: string
  startFrame: number
  endFrame: number
  duration: number
  text: string
}
type AnnotationTimeLineProps = {
  duration: number
  isAnnotating: boolean
  preview?: boolean
  newAnnotation?: Annotation
  annotations: Annotation[]
  onTimelineClick?: (seekTime: number) => void
  playScene?: (start: number, end: number, loop?: boolean) => void
  deleteAnnotation?: (id: string) => Promise<void>
  updateAnnotationText?: (id: string, text: string) => Promise<void>
}

export const AnnotationTimeline = forwardRef(
  (
    {
      duration,
      isAnnotating,
      annotations,
      preview,
      newAnnotation,
      onTimelineClick,
      playScene,
      deleteAnnotation,
      updateAnnotationText,
    }: AnnotationTimeLineProps,
    ref: Ref<unknown>
  ) => {
    const [timelineWidth, setTimelineWidth] = useState(0)
    const [currentAnnotationId, setCurrentAnnotationId] = useState<string | null>(null)
    const [editMode, setEditMode] = useState(false)
    const [hoverTime, setHoverTime] = useState(0)

    // used for passing functions to parent component
    const internalRef = useRef({})
    const timelineRef = useRef<HTMLDivElement>(null)
    const positionRef = useRef<{ x: number; y: number }>({
      x: 0,
      y: 0,
    })
    const popperRef = useRef<Instance>(null)

    useImperativeHandle(ref, () => ({
      setEditedAnnotation,
    }))

    const handleMouseMove = (event: MouseEvent) => {
      if (preview) {
        popperRef.current?.destroy()
        return
      }
      if (event.target === event.currentTarget) {
        positionRef.current = { x: event.clientX, y: event.clientY }

        if (popperRef.current != null) {
          popperRef.current.update()
        }
        const clickPosition = event.nativeEvent.offsetX
        const clickPercentage = clickPosition / timelineWidth
        const timeSeg = clickPercentage * duration

        setHoverTime(timeSeg)
        return
      }
      if (event.target !== event.currentTarget) {
        popperRef.current?.destroy()
      }
    }

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        event.preventDefault()
        const clickPosition = event.nativeEvent.offsetX
        const clickPercentage = clickPosition / timelineWidth
        const seekTime = clickPercentage * duration
        onTimelineClick?.(seekTime)
        return
      }
    }

    const setEditedAnnotation = (id: string) => {
      if (!id) return
      setCurrentAnnotationId(id)
      setEditMode(true)
    }

    useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setTimelineWidth(entry.target.clientWidth)
        }
      })

      if (timelineRef.current) {
        observer.observe(timelineRef.current)
      }

      return () => {
        if (timelineRef.current) {
          observer.unobserve(timelineRef.current)
        }
      }
    }, [])

    return (
      <Tooltip
        title={formatTime(hoverTime)}
        placement='bottom'
        ref={internalRef}
        arrow
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () =>
              timelineRef.current
                ? new DOMRect(
                    positionRef.current.x,
                    timelineRef.current?.getBoundingClientRect().y,
                    0,
                    0
                  )
                : new DOMRect(0, 0, 0, 0),
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 15],
              },
            },
          ],
        }}>
        <div
          className={classNames(
            'relative flex h-5 w-full items-center overflow-hidden bg-[#e5e3e3] focus:outline-none',
            isAnnotating ? 'cursor-pointer' : '',
            preview ? 'cursor-default' : 'cursor-pointer'
          )}
          style={{ borderBottom: '1px solid #cdcccc' }}
          ref={timelineRef}
          onMouseMove={handleMouseMove}
          onClick={!preview && onTimelineClick ? handleClick : undefined}>
          {[...(newAnnotation ? [newAnnotation] : []), ...annotations].map((annotation) => {
            const startPercentage = (annotation.startFrame / duration) * 100
            const endPercentage = (annotation.endFrame / duration) * 100
            const width = endPercentage - startPercentage

            return (
              <ContextMenu
                className='flex items-center'
                menuItems={[
                  {
                    title: 'Play Scene',
                    handleClick: () => {
                      playScene?.(annotation.startFrame, annotation.endFrame)
                    },
                  },
                  {
                    title: 'Play Scene (Loop)',
                    handleClick: () => {
                      playScene?.(annotation.startFrame, annotation.endFrame, true)
                    },
                  },
                  ...(preview
                    ? []
                    : [
                        {
                          title: 'Edit Annotation',
                          handleClick: () => {
                            setEditMode(true)
                            setCurrentAnnotationId(annotation.id)
                          },
                        },
                        {
                          title: 'Delete Annotation',
                          handleClick: () => deleteAnnotation?.(annotation.id),
                        },
                      ]),
                ]}>
                <Tooltip
                  open={currentAnnotationId === annotation.id && !newAnnotation}
                  onOpen={() => setCurrentAnnotationId(annotation.id)}
                  onClose={() => {
                    if (!editMode) {
                      setCurrentAnnotationId(null)
                      setEditMode(false)
                    }
                  }}
                  title={
                    <AnnotationEdit
                      annotation={annotation}
                      preview={preview}
                      editMode={editMode}
                      setEditMode={(mode) => {
                        setEditMode(mode)
                        if (!mode) {
                          setCurrentAnnotationId(null)
                        }
                      }}
                      saveAnnotationText={updateAnnotationText}
                      deleteAnnotation={deleteAnnotation}
                    />
                  }
                  leaveDelay={200}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        color: 'common.black',
                        border: '1px solid #dadde9',
                        minWidth: '300px',
                        maxHeight: '400px',
                        overflow: 'auto',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                        },
                      },
                    },
                  }}>
                  <div
                    key={annotation.id}
                    className={classNames(
                      'absolute flex cursor-pointer items-center justify-center',
                      preview ? 'bg-[#b7d4ee]' : 'bg-gray-400'
                    )}
                    style={{
                      left: `${startPercentage}%`,
                      width: `${width}%`,
                      height: '40%',
                    }}>
                    <span
                      className={classNames(
                        'absolute right-0 h-[180%] w-[2px]',
                        preview ? 'bg-[#1E88E5]' : 'bg-gray-600'
                      )}></span>
                    {width ? (
                      <span
                        className={classNames(
                          'absolute left-0 h-[180%] w-[2px]',
                          preview ? 'bg-[#1E88E5]' : 'bg-gray-600'
                        )}></span>
                    ) : null}
                  </div>
                </Tooltip>
              </ContextMenu>
            )
          })}
        </div>
      </Tooltip>
    )
  }
)
