import { SVGProps } from 'react'
function Check(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='17'
      viewBox='0 0 24 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.4713 0.82219C23.8618 1.21271 23.8618 1.84588 23.4713 2.2364L9.47127 16.2364C9.08074 16.6269 8.44758 16.6269 8.05705 16.2364L1.05705 9.2364C0.666529 8.84588 0.666529 8.21271 1.05705 7.82219C1.44758 7.43167 2.08074 7.43167 2.47127 7.82219L8.76416 14.1151L22.0571 0.82219C22.4476 0.431666 23.0807 0.431666 23.4713 0.82219Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Check
