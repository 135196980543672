import { Button } from '@invisible/ui/button'
import { CircleArrowLeftIcon } from '@invisible/ui/icons'
import { Progress } from '@invisible/ui/progress'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isEqual } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { TNormalizedBaseRun } from './types'

interface IProps {
  allBaseRuns: TNormalizedBaseRun[]
  onSave: (dataToBeRanked: TNormalizedBaseRun[]) => void
  setCurrentView: Dispatch<SetStateAction<'view' | 'rank'>>
  isUpdatingManyVariables: boolean
  config: WizardSchemas.Ranking.TSchema
  readOnly: boolean
}

const MergeRank = ({
  allBaseRuns,
  onSave,
  setCurrentView,
  isUpdatingManyVariables,
  config,
  readOnly,
}: IProps) => {
  const [dataToBeRanked, setDataToBeRanked] = useState<TNormalizedBaseRun[][]>(
    allBaseRuns.map((baseRun) => [baseRun])
  )
  const [currentPartitions, setCurrentPartitions] = useState<TNormalizedBaseRun[][]>([])

  // We only need the Left Index as we will always be comparing against the first element of the Right Partition at any given time.
  const [leftIndex, setLeftIndex] = useState(0)

  const [selectedOption, setSelectedOption] = useState<0 | 1 | null>(null)

  const [currentProgress, setCurrentProgress] = useState(0)

  const [equalityBuckets, setEqualityBuckets] = useState<TNormalizedBaseRun[][]>([])

  const refMain = useRef<HTMLDivElement>(null)
  const refA = useRef<HTMLDivElement>(null)
  const refB = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (currentPartitions.length !== 2) return

    // If the current index is the last index, we have finished ranking the current partition, and so we can update the allPartitions state.
    if (currentPartitions[1].length === 0) {
      setDataToBeRanked((prev) => {
        const newAllParts: TNormalizedBaseRun[][] = [currentPartitions[0]]

        prev.forEach((partition, index) => {
          if (index > 1) {
            newAllParts.push(partition)
          }
        })
        return newAllParts.sort((a, b) => a.length - b.length)
      })
    }
  }, [currentPartitions])

  const handleSave = () => {
    // We need a map to keep the mappings between the buckets and their respective ranks, so the other BaseRuns in the bucket can be assigned the same rank.
    const equalityBucketMap = {} as { [key: string]: TNormalizedBaseRun[] }

    const newRank = dataToBeRanked[0].map((baseRun, index) => {
      // We only need to look into the equality buckets if there are any.
      if (equalityBuckets.length > 0) {
        // We look into the existing Bucket Mappings to see if the current BaseRun is in any of the buckets that's already assigned a rank.
        const rank = (Object.keys(equalityBucketMap) as (keyof typeof equalityBucketMap)[]).find(
          (key) => equalityBucketMap[key].includes(baseRun)
        )

        // If the current BaseRun is in a bucket that's already assigned a rank, we assign that rank to the current BaseRun.
        if (rank) {
          return { ...baseRun, rank: Number(rank) }
        }

        // If not, we look to see if the BaseRun is even a part of an equality bucket.
        const equalityBucket = equalityBuckets.find((bucket) => bucket.includes(baseRun))

        if (equalityBucket) {
          // If it is, then that means the bucket doesn't have an existing mapping, as this is the first BaseRun in the bucket to come up.
          // So we add the mapping for this bucket. It gets the rank of the index + 1. This will be the rank for all other members of this bucket too.
          equalityBucketMap[index + 1] = equalityBucket as TNormalizedBaseRun[]
        }
      }

      // If the current BaseRun was not from an already mapped bucket, OR if it is not in any equalityBucket at all, it gets a rank of index + 1.
      return { ...baseRun, rank: index + 1 }
    })

    // We call the onSave function passed in as a prop, and pass in the newRank array. This will update the ranks of the BaseRuns in the database.
    onSave(newRank)

    setCurrentPartitions([])
    setEqualityBuckets([])
  }

  // This function gets called when the "Equal" button is clicked for a pair of BaseRuns being compared.
  const handleEquality = () => {
    // We update the equalityBuckets state with the new equality buckets that are formed after adding the new equal ranked BaseRuns.
    setEqualityBuckets((prev) => {
      // The first time this gets called, we can simply add the first bucket.
      if (prev.length === 0) return [[currentPartitions[0][leftIndex], currentPartitions[1][0]]]

      let existingEqualityFound = false

      // We map over all existing buckets to see if the current pair of BaseRuns are already in any of them.
      const newBuckets = prev.map((equalityBucket) => {
        if (
          equalityBucket.includes(currentPartitions[0][leftIndex]) ||
          equalityBucket.includes(currentPartitions[1][0])
        ) {
          // If an existing bucket contains either of the BaseRuns, then it means both these BaseRuns belong to the same bucket.
          existingEqualityFound = true

          // We use a Set to make sure we have only unique values in the updated buckets array.
          return [
            ...new Set([
              ...equalityBucket,
              currentPartitions[0][leftIndex],
              currentPartitions[1][0],
            ]),
          ]
        }

        // If the current pair of BaseRuns are not in the current existing bucket, we simply return the existing bucket, as it doesn't need updating.
        return equalityBucket
      })

      // If the current pair of BaseRuns are not in ANY of the existing buckets, we create a new bucket with the current pair of BaseRuns.
      if (!existingEqualityFound) {
        return [...prev, [currentPartitions[0][leftIndex], currentPartitions[1][0]]]
      }

      /* 
        At this point in this function, at least one existing bucket was updated. 
        It's possible that we have the same BaseRun present in two separate equalityBuckets. 
        In that case, we merge the two buckets, as they should all be the same rank. 
      */
      let mergedBuckets: TNormalizedBaseRun[][] = []

      // We iterate over all the newBuckets to see if any of them can be merged. The goal is to create the final array of buckets in mergedBuckets.
      newBuckets.forEach((bucket) => {
        // mergeBuckets function returns the updated mergedBuckets array. So we simply update the mergedBuckets variable with the return value.
        mergedBuckets = mergeBuckets(bucket, mergedBuckets)
      })

      // We set the mergedBuckets as the new array of equalityBuckets. At this point, all buckets are unique and there is no overlap between them.
      return mergedBuckets
    })

    // When Equality is chosen, we treat it the same as the right-side option being selected, so that the BaseRun gets inserted into the left partition.
    handleUserDecision(1)
  }

  // Utility function that accepts a bucket and the existing mergedBuckets array, and returns the updated mergedBuckets array.
  const mergeBuckets = (bucket: TNormalizedBaseRun[], mergedBuckets: TNormalizedBaseRun[][]) => {
    // If mergedBuckets array is empty, we can simply push the bucket into it and return.
    if (mergedBuckets.length === 0) return [bucket]

    // We loop over all existing mergedBuckets to see if the current bucket can be merged with any of them.
    const newMergedBuckets = mergedBuckets.map((mergedBucket) => {
      // We look for overlap between the existing mergedBucket and the bucket in question.
      if (
        mergedBucket.some((baseRun) =>
          bucket.find((bucketBaseRun) => isEqual(baseRun, bucketBaseRun))
        )
      ) {
        // If there is an overlap, we merge the two buckets. We use a Set to make sure we have only unique values in the updated buckets array.
        return [...new Set([...mergedBucket, ...bucket])]
      }

      // If there is no overlap, this mergedBucket will remain unchanged and we move on to the next one as we map over the existing mergedBuckets.
      return mergedBucket
    })

    /* 
      If the newMergedBuckets array is equal to the existing mergedBuckets array, it means the current bucket was not merged to any of the existing buckets.
      In that case, we simply push the current bucket into the newMergedBuckets array.
    */
    if (isEqual(mergedBuckets, newMergedBuckets)) {
      return [...mergedBuckets, bucket]
    }

    // We return the updated newMergedBuckets array.
    return newMergedBuckets
  }

  useEffect(() => {
    if (dataToBeRanked.length === 1) {
      handleSave()
    } else {
      setCurrentPartitions([dataToBeRanked[0], dataToBeRanked[1]])
    }

    setSelectedOption(null)
    setLeftIndex(0)
  }, [dataToBeRanked])

  const handleUserDecision = (chosenItem: 0 | 1) => {
    if (readOnly) return

    // If left item is chosen:
    if (chosenItem === 0) {
      // If we are at the end of the left partition, we can simply merge the two partitions. Otherwise, we move the left index one step to the right.
      if (leftIndex === currentPartitions[0].length - 1) {
        setCurrentPartitions((prev) => [[...prev[0], ...prev[1]], []])
      } else {
        setLeftIndex((prev) => prev + 1)
      }
    }

    // If right item is chosen, we insert the first item of the right partition into the left partition at current Index, and remove it from the right partition.
    if (chosenItem === 1) {
      setCurrentPartitions((prev) => {
        const newLeft = [...prev[0]]
        newLeft.splice(leftIndex, 0, prev[1][0])
        const newRight = prev[1].filter((_, index) => index !== 0)

        if (leftIndex === newLeft.length - 1) {
          return [[...newLeft, ...newRight], []]
        }

        setLeftIndex((prev) => prev + 1)
        return [newLeft, newRight]
      })
    }
    setSelectedOption(null)
  }

  const reset = () => {
    setDataToBeRanked(allBaseRuns.map((baseRun) => [baseRun]))
    setCurrentPartitions([])
    setLeftIndex(0)
    setSelectedOption(null)
    setEqualityBuckets([])
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.code) {
      case 'KeyA': // A
        refA.current?.focus()
        setSelectedOption(0)
        break

      case 'KeyB': // B
        refB.current?.focus()
        setSelectedOption(1)
        break

      case 'Enter': // Enter
        if (selectedOption !== null) handleUserDecision(selectedOption)
        break
    }
  }

  // The below useEffect is used to calculate the real-time progress.
  useEffect(() => {
    if (dataToBeRanked.length === 1) {
      setCurrentProgress(1)
      return
    }

    const progressAtStartOfCurrentLevel =
      (allBaseRuns.length - dataToBeRanked.length) / allBaseRuns.length
    const progressAtStartOfNextLevel =
      (allBaseRuns.length - dataToBeRanked.length + 1) / allBaseRuns.length

    if (currentPartitions[0] && currentPartitions[0].length > 0) {
      setCurrentProgress(
        progressAtStartOfCurrentLevel +
          (progressAtStartOfNextLevel - progressAtStartOfCurrentLevel) *
            (leftIndex / currentPartitions[0].length)
      )
      return
    }

    setCurrentProgress(progressAtStartOfCurrentLevel)
  }, [dataToBeRanked, leftIndex])

  useEffect(() => {
    // When a selection is saved, the focus goes to the main div
    if (selectedOption === null) {
      refA.current?.blur()
      refB.current?.blur()
      refMain.current?.focus()
    }
  }, [currentProgress, leftIndex])

  return (
    <div
      className='relative flex h-full w-full flex-col justify-between p-6 focus:outline-none'
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={refMain}>
      <Progress percentage={true} progress={currentProgress} width={1400} color={'black'} />
      {dataToBeRanked.length > 1 &&
      currentPartitions.length > 1 &&
      currentPartitions[1].length > 0 ? (
        <div className='overflow-auto px-16'>
          <p className='text-lg'>
            Which of these responses is better in terms of{' '}
            <span className='font-bold'>{config.rankingCriteria}</span>?
          </p>
          <div className='grid grid-cols-12 gap-0'>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={0}
              ref={refA}
              onClick={() => handleUserDecision(0)}
              tabIndex={1}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>A</div>
              {currentPartitions[0][leftIndex].message}
            </div>
            <div className='col-span-1 my-12 text-2xl'>Or...</div>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={1}
              ref={refB}
              onClick={() => handleUserDecision(1)}
              tabIndex={2}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>B</div>
              {currentPartitions[1][0].message}
            </div>
            {selectedOption !== null ? (
              <div className='col-span-1 m-2 my-10 mr-0 flex items-center'>
                <CircleArrowLeftIcon
                  color='black'
                  height={24}
                  width={24}
                  onClick={() => handleUserDecision(selectedOption)}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <h2 className='flex items-center justify-center'>
          Finished! The new rankings will be auto-saved now...
        </h2>
      )}
      <div className='flex items-center justify-center gap-6 p-3'>
        {config.disallowEqualRanking ? null : (
          <Button
            disabled={isUpdatingManyVariables || readOnly}
            onClick={() => {
              handleEquality()
            }}>
            Equal
          </Button>
        )}

        <Button
          disabled={isUpdatingManyVariables || dataToBeRanked.length !== 1 || readOnly}
          onClick={() => {
            handleSave()
          }}>
          {isUpdatingManyVariables ? 'Saving...' : 'Save'}
        </Button>

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            reset()
          }}>
          Reset
        </Button>

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            setCurrentView('view')
          }}>
          View Current Order
        </Button>
      </div>
    </div>
  )
}

export { MergeRank }
