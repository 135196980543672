import { SVGProps } from 'react'
function SaveFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='26'
      viewBox='0 0 27 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M25.6931 6.5L19.8296 0.636459C19.6292 0.43455 19.3908 0.274347 19.1282 0.165105C18.8655 0.0558629 18.5838 -0.000252509 18.2994 8.54139e-07H2.49626C1.92162 8.54139e-07 1.37052 0.228274 0.964192 0.634603C0.557863 1.04093 0.32959 1.59203 0.32959 2.16667V23.8333C0.32959 24.408 0.557863 24.9591 0.964192 25.3654C1.37052 25.7717 1.92162 26 2.49626 26H24.1629C24.7376 26 25.2887 25.7717 25.695 25.3654C26.1013 24.9591 26.3296 24.408 26.3296 23.8333V8.03021C26.3298 7.74575 26.2737 7.46406 26.1645 7.20142C26.0552 6.93877 25.895 6.70037 25.6931 6.5ZM24.1629 8.03021V23.8333H20.9129V16.25C20.9129 15.6754 20.6847 15.1243 20.2783 14.7179C19.872 14.3116 19.3209 14.0833 18.7463 14.0833H7.91292C7.33829 14.0833 6.78719 14.3116 6.38086 14.7179C5.97453 15.1243 5.74626 15.6754 5.74626 16.25V23.8333H2.49626V2.16667H18.2994L24.1629 8.03021ZM17.6629 5.41667C17.6629 5.70399 17.5488 5.97954 17.3456 6.1827C17.1425 6.38586 16.8669 6.5 16.5796 6.5H8.99626C8.70894 6.5 8.43339 6.38586 8.23022 6.1827C8.02706 5.97954 7.91292 5.70399 7.91292 5.41667C7.91292 5.12935 8.02706 4.8538 8.23022 4.65064C8.43339 4.44747 8.70894 4.33333 8.99626 4.33333H16.5796C16.8669 4.33333 17.1425 4.44747 17.3456 4.65064C17.5488 4.8538 17.6629 5.12935 17.6629 5.41667Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SaveFilled
