import { SVGProps } from 'react'
function Condition(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 10C2 9.72386 2.22386 9.5 2.5 9.5H13.5C13.7761 9.5 14 9.72386 14 10C14 10.2761 13.7761 10.5 13.5 10.5H2.5C2.22386 10.5 2 10.2761 2 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 6C2 5.72386 2.22386 5.5 2.5 5.5H13.5C13.7761 5.5 14 5.72386 14 6C14 6.27614 13.7761 6.5 13.5 6.5H2.5C2.22386 6.5 2 6.27614 2 6Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Condition
