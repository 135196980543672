import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { datadogRum } from '@datadog/browser-rum'
import { TBaseRunVariable } from '@invisible/common/components/providers/active-wizard-provider'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import {
  Backdrop,
  RGLContainer,
  Topbar,
  WizardContainer,
  WizardTitle,
} from '@invisible/common/components/wizard/wizard-container'
import { useDisableRefetchOnFocus } from '@invisible/ui/hooks/use-disable-refetch-on-focus'
import { TaskTrainingIcon } from '@invisible/ui/icons'
import { DragHandleVerticalIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isEqual } from 'lodash/fp'
import { forwardRef, memo, useEffect, useMemo, useRef } from 'react'

import { CustomWizardAtomicComponents } from './customWizardAtomicComponents'
import { WizardAtomicComponents } from './wizardAtomicComponents'

const allWACs = { ...WizardAtomicComponents, ...CustomWizardAtomicComponents }

const WizardReadOnly = () => {
  const { state } = useWizardState()

  useDisableRefetchOnFocus({ isDisabled: !state.isWizardOpen })

  const ref = useRef<HTMLElement>(null)

  // Below replaces value: { baseVariableId: [uuid], dynamic: true } by value: [actual value of the baseVariable on the baseRun]
  const parsedWizardConfig = useMemo(
    () =>
      state.wizardData.map((wac) => {
        const value = wac.config.value
        if (WizardSchemas.WACConfig.hasDynamicValue(value)) {
          const baseRunVariable = (state.wizardInitialBRVs as TBaseRunVariable[]).find(
            (brv) => brv.baseVariableId === value.baseVariableId
          )

          return {
            ...wac,
            config: {
              ...wac.config,
              value: baseRunVariable?.value,
              baseRunVariableId: baseRunVariable?.id,
            },
          }
        }
        return wac
      }) as WizardSchemas.WizardConfig.TSchema,
    [state?.wizardInitialBRVs, state.wizardData]
  )

  if (!state.isWizardOpen) return null

  return (
    <Backdrop>
      <WizardContainer>
        <Topbar>
          <WizardTitle>{state.stepName?.toUpperCase()}</WizardTitle>
          <div className='flex items-center gap-2'>
            {state.trainingLink ? (
              <a
                href={state.trainingLink}
                title='Show Me How!'
                target='_blank'
                rel='noreferrer noopener'
                style={{ height: '18px' }}>
                <TaskTrainingIcon
                  color='white'
                  width={45}
                  height={18}
                  style={{ cursor: 'pointer' }}
                />
              </a>
            ) : null}
          </div>
        </Topbar>

        <RGLContainer
          margin={[10, 10]}
          rowHeight={30}
          containerPadding={[20, 10]}
          isDraggable={false}
          isResizable={false}
          draggableHandle='.react-grid-dragHandle-WAC'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}>
          {parsedWizardConfig.map((wac) => (
            <div key={wac.config.id} data-grid={{ ...wac.layout }}>
              <div className='react-grid-dragHandle-WAC absolute  z-50 cursor-grab'>
                <DragHandleVerticalIcon height={12} width={12} />
              </div>
              <MemoizedWAC wac={wac} isReadOnly={true} ref={ref} />
            </div>
          ))}
        </RGLContainer>
      </WizardContainer>
    </Backdrop>
  )
}

const MemoizedWAC = memo(
  forwardRef(
    (
      { wac, isReadOnly }: { wac: WizardSchemas.WizardConfig.TSchema[number]; isReadOnly: boolean },
      ref
    ) => {
      const WAC = allWACs[wac.config.type]
      const { state } = useWizardState()

      // Overriding default viewname behavior here to capture the Wizard modal in DD RUM
      useEffect(() => {
        if (wac.config.name !== undefined) {
          datadogRum.startView({ name: `Wizard: ${wac.config.name} - ${wac.config.type}` })
        }
      }, [wac.config])

      if (!WAC) return null
      return (
        <WAC
          {...wac.config}
          height={wac.layout.h}
          wizardInitialBRVs={state.wizardInitialBRVs as TBaseRunVariable[]}
          baseRun={state.baseRun}
          stepRun={state.stepRun}
          isReadOnly={isReadOnly}
          ref={ref}
        />
      )
    }
  ),
  (oldProps, newProps) => isEqual(oldProps.wac, newProps.wac)
)

export { WizardReadOnly }
