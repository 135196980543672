import { z } from '@invisible/zod'

import { schema as WizardConfigSchema } from './WizardConfig'

const schema = z.object({
  wizardConfig: WizardConfigSchema.optional(),
  trainingLink: z.string().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
