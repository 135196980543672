import { SVGProps } from 'react'

function DashCircleOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9.846 15.2693C9.16627 15.2693 8.61523 15.8203 8.61523 16.5001C8.61523 17.1798 9.16627 17.7308 9.846 17.7308H22.1537C22.8334 17.7308 23.3845 17.1798 23.3845 16.5001C23.3845 15.8203 22.8334 15.2693 22.1537 15.2693H9.846Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 0.5C7.16344 0.5 0 7.66344 0 16.5C0 25.3366 7.16344 32.5 16 32.5C24.8366 32.5 32 25.3366 32 16.5C32 7.66344 24.8366 0.5 16 0.5ZM2.46154 16.5C2.46154 9.02291 8.52291 2.96154 16 2.96154C23.4771 2.96154 29.5385 9.02291 29.5385 16.5C29.5385 23.9771 23.4771 30.0385 16 30.0385C8.52291 30.0385 2.46154 23.9771 2.46154 16.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DashCircleOutline
