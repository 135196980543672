import { SVGProps } from 'react'

function SquaredUpArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='currentColor' />
      <path
        d='M12.7682 8.44819L8.32312 3.32542C8.28294 3.27907 8.23326 3.24189 8.17746 3.21641C8.12166 3.19094 8.06103 3.17775 7.99968 3.17775C7.93834 3.17775 7.87771 3.19094 7.82191 3.21641C7.7661 3.24189 7.71643 3.27907 7.67625 3.32542L3.2325 8.44819C3.21907 8.46363 3.21036 8.48261 3.20741 8.50286C3.20446 8.52311 3.2074 8.54378 3.21587 8.56241C3.22433 8.58104 3.23798 8.59684 3.25517 8.60793C3.27237 8.61902 3.29239 8.62494 3.31285 8.62498H4.39767C4.45928 8.62498 4.51821 8.59819 4.55973 8.55132L7.49142 5.1723V12.7138C7.49142 12.7727 7.53964 12.821 7.59857 12.821H8.40214C8.46107 12.821 8.50928 12.7727 8.50928 12.7138V5.1723L11.441 8.55132C11.4812 8.59819 11.5401 8.62498 11.603 8.62498H12.6879C12.7789 8.62498 12.8285 8.51784 12.7682 8.44819Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SquaredUpArrow
