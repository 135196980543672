import * as env from 'env-var'

export const GOOGLE_CLOUD_BUCKET_NAME = process.env.NEXT_PUBLIC_GOOGLE_CLOUD_BUCKET_NAME
export const NEXT_PUBLIC_MANTICORE_URL = process.env.NEXT_PUBLIC_MANTICORE_URL as string
export const NEXT_PUBLIC_MTC_RLHF_WITHOUT_CLOUDFARE_URL = process.env
  .NEXT_PUBLIC_MTC_RLHF_WITHOUT_CLOUDFARE_URL as string
export const NEXT_PUBLIC_CONCORDE_URL = process.env.NEXT_PUBLIC_CONCORDE_URL as string

export const LOOOP_PUBLIC_API_KEY = env
  .get('LOOOP_PUBLIC_API_KEY')
  .required(true)
  .default('fake')
  .asString()
