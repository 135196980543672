import { SVGProps } from 'react'
function Format(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={14}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.791 5.749h-4.75a.125.125 0 00-.125.125v1.75c0 .068.057.125.125.125h.75a.125.125 0 00.125-.125v-.875h.938v5h-.719a.125.125 0 00-.125.125v.75c0 .068.056.125.125.125h2.563a.125.125 0 00.125-.125v-.75a.125.125 0 00-.125-.125h-.72v-5h.938v.875c0 .068.057.125.125.125h.75a.125.125 0 00.125-.125v-1.75a.125.125 0 00-.125-.125zM9.666 3.874v-2a.125.125 0 00-.125-.125h-8.5a.125.125 0 00-.125.125v2c0 .068.057.125.125.125h.875a.125.125 0 00.125-.125v-1h2.625v8.75H3.23a.125.125 0 00-.125.125v.875c0 .068.056.125.125.125h4.125a.125.125 0 00.125-.125v-.875a.125.125 0 00-.125-.125H5.916v-8.75h2.625v1c0 .068.057.125.125.125h.875a.125.125 0 00.125-.125z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Format
