import { SVGProps } from 'react'

function BadgeFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='15'
      height='20'
      viewBox='0 0 15 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.5 11.875C9.91625 11.875 11.875 9.91625 11.875 7.5C11.875 5.08375 9.91625 3.125 7.5 3.125C5.08375 3.125 3.125 5.08375 3.125 7.5C3.125 9.91625 5.08375 11.875 7.5 11.875Z'
        fill='currentColor'
      />
      <path
        d='M7.50008 0.625014C6.08371 0.622129 4.7011 1.05727 3.54168 1.87081C2.38226 2.68435 1.50276 3.83649 1.02367 5.16937C0.544587 6.50225 0.48936 7.95067 0.865557 9.31616C1.24175 10.6817 2.03097 11.8974 3.12508 12.7969V18.75C3.12444 18.8567 3.15145 18.9617 3.20348 19.0548C3.25551 19.1479 3.33078 19.2259 3.42195 19.2813C3.51239 19.3381 3.61588 19.3708 3.72257 19.3763C3.82925 19.3818 3.93555 19.3598 4.03132 19.3125L7.50008 17.5703L10.9688 19.3125C11.0575 19.3517 11.1531 19.373 11.2501 19.375C11.4158 19.375 11.5748 19.3092 11.692 19.192C11.8092 19.0747 11.8751 18.9158 11.8751 18.75V12.7969C12.9692 11.8974 13.7584 10.6817 14.1346 9.31616C14.5108 7.95067 14.4556 6.50225 13.9765 5.16937C13.4974 3.83649 12.6179 2.68435 11.4585 1.87081C10.2991 1.05727 8.91644 0.622129 7.50008 0.625014ZM7.50008 13.125C6.38756 13.125 5.30002 12.7951 4.37499 12.177C3.44997 11.5589 2.729 10.6804 2.30325 9.65261C1.87751 8.62477 1.76612 7.49377 1.98316 6.40263C2.2002 5.31149 2.73593 4.30921 3.5226 3.52254C4.30927 2.73587 5.31155 2.20014 6.40269 1.9831C7.49384 1.76606 8.62484 1.87745 9.65267 2.30319C10.6805 2.72893 11.559 3.44991 12.1771 4.37493C12.7952 5.29996 13.1251 6.38749 13.1251 7.50001C13.123 8.99122 12.5297 10.4208 11.4753 11.4752C10.4208 12.5297 8.99128 13.1229 7.50008 13.125Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BadgeFilled
