import { classNames } from '@invisible/common/helpers'
import type { TQuestionnaireAnswerChangeHandler, TQuestionTree } from '@invisible/common/types'
import { TextArea } from '@invisible/ui/form'
import React, { FC } from 'react'

interface IProps {
  onChange?: TQuestionnaireAnswerChangeHandler
  question: TQuestionTree
  text: string
  readOnly?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const OpenText: FC<IProps> = ({ question, onChange, text, readOnly }) => {
  const handleChange = (v: string) => {
    if (readOnly || !onChange) return
    onChange({ questionId: question.id, value: v, type: 'open' })
  }

  const hasBeenFilled = !!text.length

  if (readOnly)
    return (
      <div
        className={classNames(
          'flex justify-center border border-gray-300 bg-purple-50 p-4',
          hasBeenFilled ? 'text-gray-800' : 'text-gray-600'
        )}>
        <p className={classNames(!hasBeenFilled ? 'italic' : '')}>
          {hasBeenFilled ? text : 'Unanswered question'}
        </p>
      </div>
    )

  return (
    <div>
      <TextArea
        onChange={(e) => handleChange(e.target.value as string)}
        value={text}
        placeholder={'Enter your answer here'}
        rows={5}
        role='textbox'
      />
    </div>
  )
}

export { OpenText }
