import { SVGProps } from 'react'
function Incomplete(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={14}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.155 10.536a5 5 0 11-7.071-7.071 5 5 0 017.07 7.07zm1.414 1.414a7 7 0 11-9.9-9.9 7 7 0 019.9 9.9zM10.033 6H5.205v2h4.828V6z'
        fill='#FF002E'
      />
    </svg>
  )
}

export default Incomplete
