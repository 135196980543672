import axios from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type TVariables = { payload: Record<string, unknown>; triggerStepId: string }

const useExecuteManualTrigger = (options?: UseMutationOptions<unknown, unknown, TVariables>) => {
  const { isLoading, mutateAsync } = useMutation(
    ['execute-manual-trigger'],
    async ({ payload, triggerStepId }: TVariables) =>
      axios.post(`${NEXT_PUBLIC_CONCORDE_URL}/api/triggers/${triggerStepId}`, payload, {
        withCredentials: true,
      }),
    options
  )

  return { isLoading, mutateAsync }
}

export { useExecuteManualTrigger }
