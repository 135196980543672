import { classNames } from '@invisible/common/helpers'
import { useLocalStore } from '@invisible/hooks/use-local-store'
import { useResizeObserver } from '@invisible/hooks/use-resize-observer'
import { Breadcrumb } from '@invisible/ui/breadcrumb'
import { CookieConsentBar } from '@invisible/ui/cookie-consent'
import { CaretDownIcon, CaretRightIcon } from '@invisible/ui/icons'
import {
  SymbolInvisible,
  SymbolInvisibleAdmin,
  SymbolInvisibleOperate,
  SymbolInvisiblePortal,
} from '@invisible/ui/images'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react'

import { useBreadcrumbsContext } from '../../hooks'

type SidebarItemProps = {
  children: React.ReactNode
  option: {
    title: string
    path: string
    renderIcon: (filled: boolean) => JSX.Element
  }
  exact?: boolean
}

const SidebarItem = ({
  children,
  option: { title, renderIcon, path },
  exact,
}: SidebarItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false)

  const { pathname } = useRouter()
  const isActive = exact ? pathname === path : `${pathname}/`.startsWith(`${path}/`)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [width] = useResizeObserver(containerRef as MutableRefObject<HTMLDivElement>)

  const isCollapsed = width <= 100
  const ButtonTextColorStyles = isHovered
    ? 'text-theme-main font-medium'
    : isActive
    ? 'text-theme-main font-medium'
    : 'text-paragraphs'

  const handleClick = () => {
    if (!isCollapsed) setIsOpen((prev) => !prev)
  }

  const handleMouseOver = () => {
    setIsHovered(true)
    setIsFloatingMenuOpen(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setIsFloatingMenuOpen(false)
  }

  return (
    <div ref={containerRef} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <button
        className={classNames(
          'relative box-border flex h-11 w-full flex-none cursor-pointer items-center gap-3 border-none px-5 transition-all duration-200 ease-in-out',
          ButtonTextColorStyles,
          isActive
            ? 'border-theme-main bg-theme-weak-3 text-theme-main border-0  border-r-2 border-solid'
            : ' bg-void hover:bg-weak'
        )}
        onClick={handleClick}>
        <div className='flex items-center gap-2.5'>
          {renderIcon(isActive)}
          {isCollapsed && isHovered ? <CaretRightIcon width={12} height={12} /> : null}
        </div>
        {!isCollapsed ? (
          <>
            {title}
            {isHovered ? (
              <div
                className={`ml-auto flex h-6 w-6 items-center justify-center ${
                  isActive ? 'text-theme-main' : ''
                } ${isOpen ? '' : '-rotate-90'} transition-all duration-200 ease-in-out`}>
                <CaretDownIcon width={12} height={12} />
              </div>
            ) : null}
          </>
        ) : null}
      </button>
      {!isCollapsed ? (
        <div
          className={classNames(
            'opacity-100 transition-all ease-in-out',
            isOpen ? 'h-max' : 'invisible h-0 opacity-0'
          )}>
          {children}
        </div>
      ) : isFloatingMenuOpen ? (
        <div
          style={{
            top: containerRef.current?.offsetTop ?? 0,
          }}
          className='absolute left-16 z-50 w-60'>
          <div className='bg-void flex flex-col rounded shadow-xl'>{children}</div>
        </div>
      ) : null}
    </div>
  )
}

interface ISidebarLinkProps {
  option: {
    title: string
    path: string
    renderIcon?: (filled: boolean) => JSX.Element
  }
  exact?: boolean
}

const SidebarLink = ({
  option: { title, path, renderIcon },
  exact,
  ...props
}: ISidebarLinkProps) => {
  const { asPath, push } = useRouter()
  const isActive = exact ? asPath === path : asPath.startsWith(path)
  const handleClick = () => {
    push(path)
  }

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [width] = useResizeObserver(containerRef as MutableRefObject<HTMLDivElement>)

  return (
    <Link href={path}>
      <a onClick={handleClick} {...props} className='no-underline hover:no-underline'>
        <div
          ref={containerRef}
          className={`box-border flex h-11 w-full items-center gap-3 px-5 ${
            isActive
              ? 'border-theme-main bg-theme-weak-3 text-theme-main  border-0 border-r-2 border-solid'
              : 'text-paragraphs hover:bg-weak'
          } transition-all duration-200 ease-in-out`}>
          {renderIcon?.(isActive) ?? <div className='w-4'> </div>}
          {width > 100 && (
            <div
              data-testid={`sidebar-link-${title}`}
              className={`${isActive ? 'font-medium' : 'font-normal'}`}>
              {title}
            </div>
          )}
        </div>
      </a>
    </Link>
  )
}

const SidebarContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([
  false,
  () => null,
])

export const useSidebarContext = () => {
  const [isCollapsed, setIsCollapsed] = useContext(SidebarContext)

  return [isCollapsed, setIsCollapsed] as const
}

interface SidebarProps {
  appName: 'admin' | 'operate' | 'portal'
  children: ReactNode
}

const INVISIBLE_ICONS = {
  admin: <SymbolInvisibleAdmin className='fill-void' />,
  operate: <SymbolInvisibleOperate className='fill-void' />,
  portal: <SymbolInvisiblePortal className='fill-void' />,
}

const SidebarComponent = ({ appName, children }: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useLocalStore(
    `${window.location.origin}-sidebar-collapsed`,
    false
  )
  const [isHovered, setIsHovered] = useState(false)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [width] = useResizeObserver(containerRef as MutableRefObject<HTMLDivElement>)

  return (
    <SidebarContext.Provider value={[isCollapsed, setIsCollapsed]}>
      <div
        ref={containerRef}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`bg-void box-border flex h-screen flex-none flex-col gap-4 transition-all duration-200 ease-in-out ${
          isCollapsed ? 'w-16' : 'w-60'
        }`}>
        <div className='min-h-14 bg-theme-main relative flex h-14 w-full items-center justify-center'>
          <div className='flex items-center justify-center gap-2'>
            {width < 200 ? (
              <div className='min-h-14 flex h-14 items-center'>
                <SymbolInvisible className='fill-void' />
              </div>
            ) : (
              INVISIBLE_ICONS[appName]
            )}
          </div>
          {isHovered && (
            <div
              className={`text-paragraphs border-weak hover:bg-weak absolute -right-3 top-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid bg-white ${
                isCollapsed ? '-rotate-90' : 'rotate-90'
              } transition-all duration-200 ease-in-out`}
              onClick={() => setIsCollapsed((prev) => !prev)}>
              <CaretDownIcon width={11} height={11} />
            </div>
          )}
        </div>
        <div className='flex h-full flex-col gap-4 overflow-y-auto overflow-x-visible'>
          {children}
        </div>
      </div>
    </SidebarContext.Provider>
  )
}

const Topbar = ({ children }: { children: ReactNode }) => {
  const { push } = useRouter()
  const [breadCrumbsState] = useBreadcrumbsContext()
  return (
    <div className='flex h-14 min-h-[3.5rem] w-full flex-initial'>
      <div className='flex flex-grow items-center'>
        <Breadcrumb>
          {breadCrumbsState?.map((breadcrumb, i) => (
            <Breadcrumb.Item
              key={i}
              onClick={
                typeof breadcrumb !== 'string'
                  ? () => {
                      push(breadcrumb.path)
                    }
                  : undefined
              }>
              {typeof breadcrumb !== 'string' ? breadcrumb?.name : breadcrumb}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <div className='flex items-center gap-2'>{children}</div>
    </div>
  )
}

const ContentAreaComponent = ({ children }: { children: ReactNode }) => (
  <div className='bg-app box-border flex h-screen w-full flex-col overflow-auto px-4 pb-4'>
    {children}
  </div>
)

interface PageHeaderProps {
  title: string
  subtitle: string | React.ReactNode
}

const PageHeader = ({ title, subtitle }: PageHeaderProps) => (
  <div className='flex flex-col'>
    <div className='text-2xl font-bold'>{title}</div>
    <div className='text-paragraphs text-sm'>{subtitle}</div>
  </div>
)

const Header = ({ children }: { children: ReactNode }) => (
  <div className='border-weak bg-weak-3 box-border flex w-full items-center rounded-t-md border-0 border-b border-solid p-4'>
    {children}
  </div>
)

const LayoutComponent = ({ children }: { children?: ReactNode }) => (
  <div className='max-w-screen box-border flex h-full min-h-screen w-full'>{children}</div>
)

const PageComponent = ({ children }: { children: ReactNode }) => (
  <div className='bg-void relative box-border flex flex-auto flex-col overflow-auto rounded-lg'>
    {children}
  </div>
)

interface ISecondaryNavLinkProps {
  option: {
    title: string
    path: string
  }
  exact?: boolean
}

const SecondaryNavLink = ({ option: { title, path }, exact, ...props }: ISecondaryNavLinkProps) => {
  const { push, asPath } = useRouter()
  const isActive = exact ? asPath === path : asPath.startsWith(path)

  const handleClick = () => {
    push(path)
  }

  return (
    <Link href={path}>
      <a
        onClick={handleClick}
        {...props}
        className={`min-h-6 flex items-center justify-center border-0 border-b-[3px] border-solid py-2 no-underline hover:no-underline ${
          isActive
            ? 'border-theme-main text-theme-main  border-0 border-b-[3px] border-solid font-bold'
            : 'text-paragraphs border-void hover:border-main hover:border-0 hover:border-b-[3px] hover:border-solid'
        }`}>
        {title}
      </a>
    </Link>
  )
}

const SecondaryNavComponent = ({ children }: { children: ReactNode }) => (
  <div className='border-weak flex gap-x-4 border-0 border-b border-solid px-4 pt-1'>
    {children}
  </div>
)

const SecondaryNav = Object.assign(SecondaryNavComponent, {
  SecondaryNavLink,
})

const Page = Object.assign(PageComponent, {
  Header,
})

const ContentArea = Object.assign(ContentAreaComponent, {
  Topbar,
  Page,
  CookieConsentBar,
})

const Sidebar = Object.assign(SidebarComponent, {
  SidebarLink,
  SidebarItem,
})

const GeneralLayout = Object.assign(LayoutComponent, {
  Sidebar,
  ContentArea,
})

export { GeneralLayout, Header, PageHeader, SecondaryNav }
