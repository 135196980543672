import { SVGProps } from 'react'

function Finance(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.441 3.1607C15.7838 2.94643 16.2162 2.94643 16.559 3.1607L30.4257 11.8274C30.8321 12.0814 31.023 12.5787 30.893 13.045C30.763 13.5114 30.3439 13.8333 29.8667 13.8333H2.13333C1.65611 13.8333 1.23696 13.5114 1.10699 13.045C0.977018 12.5787 1.16786 12.0814 1.5743 11.8274L15.441 3.1607ZM5.90191 11.6667H26.0981L16 5.35536L5.90191 11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.4 11.6667C6.9891 11.6667 7.46667 12.1517 7.46667 12.75V23.5833C7.46667 24.1816 6.9891 24.6667 6.4 24.6667C5.8109 24.6667 5.33333 24.1816 5.33333 23.5833V12.75C5.33333 12.1517 5.8109 11.6667 6.4 11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8 11.6667C13.3891 11.6667 13.8667 12.1517 13.8667 12.75V23.5833C13.8667 24.1816 13.3891 24.6667 12.8 24.6667C12.2109 24.6667 11.7333 24.1816 11.7333 23.5833V12.75C11.7333 12.1517 12.2109 11.6667 12.8 11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2 11.6667C19.7891 11.6667 20.2667 12.1517 20.2667 12.75V23.5833C20.2667 24.1816 19.7891 24.6667 19.2 24.6667C18.6109 24.6667 18.1333 24.1816 18.1333 23.5833V12.75C18.1333 12.1517 18.6109 11.6667 19.2 11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.6 11.6667C26.1891 11.6667 26.6667 12.1517 26.6667 12.75V23.5833C26.6667 24.1816 26.1891 24.6667 25.6 24.6667C25.0109 24.6667 24.5333 24.1816 24.5333 23.5833V12.75C24.5333 12.1517 25.0109 11.6667 25.6 11.6667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.13333 23.5833C2.13333 22.985 2.6109 22.5 3.2 22.5H28.8C29.3891 22.5 29.8667 22.985 29.8667 23.5833C29.8667 24.1816 29.3891 24.6667 28.8 24.6667H3.2C2.6109 24.6667 2.13333 24.1816 2.13333 23.5833Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 27.9167C0 27.3184 0.477563 26.8333 1.06667 26.8333H30.9333C31.5224 26.8333 32 27.3184 32 27.9167C32 28.515 31.5224 29 30.9333 29H1.06667C0.477563 29 0 28.515 0 27.9167Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Finance
