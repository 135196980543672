import type { TProcess, TProcessStepTemplate } from '@invisible/common/components/process-base'
import {
  mapGraphqlToUnifiedProcess,
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { DND_DRAG_TYPES, OPTIMISTIC_STEP_ID } from '@invisible/common/stores/process-store'
import { IProcessByIdQuery, toGlobalId } from '@invisible/concorde/gql-client'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { LinkIcon } from '@invisible/ui/icons'
import { FC, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { useQueryClient } from 'react-query'
import { useNodes, useReactFlow } from 'reactflow'
import { useGate } from 'statsig-react'

import { computeBuilderStepColors } from './helper'

interface IProps {
  stepId?: string | null
  attendedMapStepId: string
  processId: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ShadowStep: FC<IProps> = ({ stepId, processId, attendedMapStepId }) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-process-by-id-query')
  const graphqlQueryClient = useQueryClient()
  const reactQueryContext = useContext()
  const { setNodes, setEdges } = useReactFlow()
  const nodes = useNodes()

  const { data: process } = useProcessById({ id: processId })

  const { data: stepTemplates } = useQuery(['stepTemplate.findTriggerStepTemplates'])

  const isSelected = useMemo(() => nodes.find((n) => n.id === stepId)?.selected, [nodes, stepId])
  const step = useMemo(() => process?.steps.find((s) => s.id === stepId), [process, stepId])
  const colors = computeBuilderStepColors(step?.stepTemplate as TProcessStepTemplate)

  const queryKey = isGraphqlEnabled
    ? ['ProcessById', { id: toGlobalId('ProcessType', processId) }]
    : ['process.findByIdWithStepsAndStepGoTos', { id: processId }]

  const queryClient = isGraphqlEnabled ? graphqlQueryClient : reactQueryContext.queryClient

  const { mutateAsync: createStep } = useMutation('attendedMapStep.updateShadowStep', {
    onSettled: () => {
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
    onMutate: async (variables) => {
      await graphqlQueryClient.cancelQueries('ProcessById')
      await reactQueryContext.queryClient.cancelQueries('process.findByIdWithStepsAndStepGoTos')
      const stepTemplate = stepTemplates?.find((s) => s.id === variables.stepTemplateId)
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error Wrong types
        (prevData: TProcess | IProcessByIdQuery) => {
          const mappedPrevData =
            isGraphqlEnabled && 'process' in prevData
              ? (mapGraphqlToUnifiedProcess(prevData) as TProcess)
              : (prevData as TProcess)
          return {
            ...mappedPrevData,
            steps: [
              ...mappedPrevData.steps.map((s) =>
                s.id === attendedMapStepId ? { ...s, meta: { shadowStepId: OPTIMISTIC_STEP_ID } } : s
              ),
              {
                id: OPTIMISTIC_STEP_ID,
                name: stepTemplate?.name ?? '',
                processId: processId,
                position: 1,
                meta: {},
                stepTemplateId: variables.stepTemplateId,
                stepTemplate: {
                  ...stepTemplate,
                },
              },
            ],
          }
        }
      )
    },
  })

  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_DRAG_TYPES.STEP_TEMPLATE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: async (item: any) => {
      await createStep({
        attendedMapStepId,
        stepTemplateId: item.id,
      })
    },
  }))

  const handleShadowStepClick = () => {
    setNodes((pNodes) =>
      pNodes.map((node) =>
        node.id === stepId
          ? { ...node, selected: true }
          : node.selected
          ? { ...node, selected: false }
          : node
      )
    )
    setEdges((pEdges) =>
      pEdges.map((edge) => (edge.selected ? { ...edge, selected: false } : edge))
    )
  }

  if (!step)
    return (
      <div
        ref={drop}
        className={classNames(
          'text-primary ml-2 box-border flex h-16 items-center gap-2 rounded border border-dashed border-gray-400 bg-white pl-5',
          isOver ? 'bg-gray-200' : 'bg-white',
          // react-flow specific class to disable selection/dragging
          'nodrag'
        )}>
        <div
          className={classNames(
            'flex h-8 w-8 shrink-0 items-center justify-center rounded-md',
            colors.bg,
            colors.border
          )}>
          <LinkIcon className='h-4 w-4 text-white' />
        </div>
        <div className='flex w-40 flex-col text-xs '>
          <span className='block font-bold tracking-wider'> Shadow Step</span>
          <span className='block tracking-wide'>Drag here</span>
        </div>
      </div>
    )

  return (
    <div
      className={classNames(
        'text-primary relative ml-2 box-border flex h-16 cursor-default items-center gap-2 rounded border-2 border-solid bg-white px-2',
        isSelected ? 'border-black' : colors.border,
        'nodrag'
      )}
      onClick={handleShadowStepClick}>
      <div
        className={classNames(
          'flex h-8 w-8 shrink-0 items-center justify-center rounded-md',
          colors.bg,
          colors.border
        )}>
        <colors.icon className='h-4 w-4 text-white' />
      </div>
      <div className='flex w-40 flex-col text-xs'>
        <span className='block truncate font-bold tracking-wider' title={step?.name ?? ''}>
          {step?.name ?? ''}
        </span>
        <span className='block truncate tracking-wide'>{step.stepTemplate.name}</span>
      </div>
    </div>
  )
}

export { ShadowStep }
