import { FC, SVGProps } from 'react'

type TDirection = 'asc' | 'desc'
interface ISortIconProps extends SVGProps<SVGSVGElement> {
  direction?: TDirection
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SortIcon: FC<ISortIconProps> = ({ direction, ...props }) => (
  <svg
    width='11'
    height='22'
    viewBox='0 0 11 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M9.46854 12.9385H1.53146C1.2934 12.9385 1.16047 13.1898 1.3079 13.3614L5.27644 17.9632C5.39003 18.0949 5.60876 18.0949 5.72356 17.9632L9.6921 13.3614C9.83953 13.1898 9.7066 12.9385 9.46854 12.9385Z'
      fillOpacity={direction === 'asc' ? '1' : '0.25'}
      className='fill-theme-main'
    />
    <path
      d='M9.6921 9.63903L5.72356 5.03727C5.60997 4.90555 5.39124 4.90555 5.27644 5.03727L1.3079 9.63903C1.16047 9.81063 1.2934 10.062 1.53146 10.062H9.46854C9.7066 10.062 9.83953 9.81063 9.6921 9.63903Z'
      className='fill-theme-main'
      fillOpacity={direction === 'desc' ? '1' : '0.25'}
    />
  </svg>
)

export default SortIcon
