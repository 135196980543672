import { SVGProps } from 'react'
function TelephoneFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='25'
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24.521 16.8L18.6585 14.3C18.356 14.1644 18.0239 14.1082 17.6937 14.1366C17.3634 14.1651 17.0459 14.2772 16.771 14.4625L13.6335 16.55C11.7213 15.6213 10.1731 14.0819 9.2335 12.175L11.3085 9.00001C11.4925 8.72486 11.605 8.40809 11.6355 8.07847C11.6661 7.74885 11.6138 7.41681 11.4835 7.11251L8.971 1.25001C8.79567 0.854153 8.49881 0.524432 8.12347 0.308664C7.74812 0.092896 7.3138 0.00229855 6.8835 0.0500099C5.19549 0.27114 3.64521 1.09773 2.5209 2.37608C1.39659 3.65444 0.774749 5.29758 0.770996 7.00001C0.770996 16.925 8.846 25 18.771 25C20.4734 24.9963 22.1166 24.3744 23.3949 23.2501C24.6733 22.1258 25.4999 20.5755 25.721 18.8875C25.7687 18.4572 25.6781 18.0229 25.4623 17.6475C25.2466 17.2722 24.9169 16.9753 24.521 16.8Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TelephoneFilled
