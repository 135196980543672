import { SVGProps } from 'react'
function TalkdeskOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='128'
      height='26'
      viewBox='0 0 128 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9.22294 25.9853C6.76807 25.9853 3.05073 25.0376 3.05073 19.5331V10.2401H0V8.55623L3.0506 8.24074V3.12047L5.39974 3.01617L5.89118 8.10094L11.9233 8.03033V10.2401H6.03228V19.1822C6.03228 22.6534 7.67995 23.4603 9.57406 23.4603C10.3802 23.4603 11.467 23.3197 12.3791 23.0747L12.8699 25.389C11.5375 25.8444 10.1694 25.9853 9.22294 25.9853Z'
        fill='#0F3D5C'
      />
      <path
        d='M25.4108 16.5866C20.8871 16.6222 17.6955 17.5345 17.6955 20.2687C17.6955 22.5135 19.1685 23.7409 21.448 23.7409C22.8155 23.7409 24.2534 23.3197 25.4108 22.127V16.5866V16.5866ZM26.1118 25.564C26.0063 25.2139 25.902 24.7576 25.7966 24.3366C24.1486 25.5994 22.4299 25.9854 20.5716 25.9854C17.1348 25.9854 14.6804 23.81 14.6804 20.3392C14.6804 16.0611 19.2386 14.6237 25.411 14.5182V13.9564C25.411 10.5199 23.868 9.88875 21.8338 9.88875C20.8174 9.88875 19.1345 10.2044 17.8712 10.6257L17.17 8.45123C18.713 7.85523 20.9573 7.43414 22.4649 7.43414C24.9553 7.43414 28.3561 8.0659 28.3561 13.5361V22.268C28.3561 23.2146 28.4613 24.4763 28.6023 25.46L26.1118 25.564Z'
        fill='#0F3D5C'
      />
      <path
        d='M38.0562 25.9853C36.163 25.9853 33.4973 25.2483 33.4973 20.4799V0.455288L36.4434 0V20.3739C36.4434 23.0387 37.5653 23.4603 38.7574 23.4603C39.1785 23.4603 39.5289 23.4247 39.8444 23.3553L40.4759 25.67C39.8097 25.8444 39.0384 25.9853 38.0562 25.9853Z'
        fill='#0F3D5C'
      />
      <path
        d='M53.859 25.4588L47.8626 17.3238H46.0039V25.4588H43.058V0.456199L46.0039 0V16.061L53.6483 8.03094H57.1204L49.9662 15.394L57.4005 25.4588H53.859Z'
        fill='#0F3D5C'
      />
      <path
        d='M71.8349 10.7307C70.8883 10.3096 69.6253 10.0295 68.399 10.0295C65.0679 10.0295 62.7166 11.9582 62.7166 16.692C62.7166 21.4612 65.2077 23.1795 67.8385 23.1795C69.3097 23.1795 70.7485 22.5485 71.8349 21.6009V10.7307ZM72.5007 25.4588L72.3958 24.3016C71.1337 25.2843 69.3803 25.9856 67.5568 25.9856C63.2089 25.9856 59.7014 23.0747 59.7014 16.6921C59.7014 10.3452 63.2426 7.39879 68.363 7.39879C69.5556 7.39879 70.8191 7.64471 71.8349 8.03081V0.456199L74.8166 0V25.459H72.5007V25.4588Z'
        fill='#0F3D5C'
      />
      <path
        d='M89.7011 15.691C89.7346 14.2005 89.0728 11.7505 86.3579 11.7505C83.8408 11.7505 82.7808 14.0343 82.6159 15.691H89.7011ZM82.6159 18.5384C82.7148 21.4517 84.9668 22.7113 87.583 22.7113C89.4698 22.7113 90.794 22.4122 92.054 21.9826L92.6489 24.7641C91.2589 25.3596 89.3381 25.7905 87.0204 25.7905C81.7874 25.7905 78.7089 22.5788 78.7089 17.6442C78.7089 13.1743 81.4236 8.96906 86.5901 8.96906C91.8205 8.96906 93.5435 13.2734 93.5435 16.8169C93.5435 17.5782 93.4765 18.1743 93.4102 18.5386H82.6159V18.5384Z'
        fill='#0F3D5C'
      />
      <path
        d='M101.672 25.9852C99.358 25.9852 97.9548 25.564 96.6581 25.0735L97.0093 22.6885C98.2705 23.2845 99.6386 23.6356 101.498 23.6356C103.707 23.6356 105.776 22.8988 105.776 20.6894C105.776 20.0591 105.495 19.5329 104.689 18.9017C103.392 17.9547 101.077 17.3232 99.4979 16.2367C98.1308 15.2901 97.5005 13.9563 97.5005 12.4139C97.5005 9.32827 99.9904 7.4339 103.04 7.4339C104.723 7.4339 105.846 7.67916 107.109 8.20611L106.828 10.3806C105.881 9.99371 104.898 9.78369 103.461 9.78369C101.533 9.78369 100.446 10.7657 100.446 12.2741C100.446 13.1154 101.006 13.9217 102.585 14.7284C104.444 15.6404 105.495 15.9199 106.898 16.8324C108.23 17.7444 108.861 18.9369 108.861 20.4451C108.861 24.8276 104.689 25.9852 101.672 25.9852Z'
        fill='#0F3D5C'
      />
      <path
        d='M123.506 25.4588L117.51 17.3238H115.651V25.4588H112.705V0.456199L115.651 0V16.061L123.296 8.03094H126.767L119.614 15.394L127.048 25.4588H123.506Z'
        fill='#0F3D5C'
      />
      <path
        d='M79.0175 9.30606C79.2575 7.38399 80.893 5.89588 82.8801 5.89588H89.6983C91.6844 5.89588 93.32 7.38399 93.5597 9.30606H94.8116C95.2737 9.30606 95.7211 9.36497 96.1509 9.46667V8.77795C96.1509 5.55139 93.5347 2.93475 90.3066 2.93475H82.2716C79.0437 2.93475 76.4275 5.55152 76.4275 8.77795V9.46667C76.8577 9.36497 77.3045 9.30606 77.767 9.30606H79.0175Z'
        fill='#0F3D5C'
      />
    </svg>
  )
}

export default TalkdeskOriginal
