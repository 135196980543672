import { SVGProps } from 'react'
function DeleteFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='33'
      viewBox='0 0 31 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M29.0796 5.68773H22.8296V4.45696C22.8296 3.47234 22.4546 2.48773 21.7046 1.87234C21.0796 1.13388 20.0796 0.764648 19.0796 0.764648H11.5796C10.5796 0.764648 9.57959 1.13388 8.95459 1.87234C8.20459 2.48773 7.82959 3.47234 7.82959 4.45696V5.68773H1.57959C1.20459 5.68773 0.95459 5.8108 0.70459 6.05696C0.45459 6.30311 0.32959 6.54926 0.32959 6.91849C0.32959 7.28773 0.45459 7.53388 0.70459 7.78003C0.95459 8.02619 1.20459 8.14926 1.57959 8.14926H2.82959V30.3031C2.82959 30.9185 3.07959 31.5339 3.57959 32.0262C4.07959 32.5185 4.70459 32.7646 5.32959 32.7646H25.3296C25.9546 32.7646 26.5796 32.5185 27.0796 32.0262C27.5796 31.5339 27.8296 30.9185 27.8296 30.3031V8.14926H29.0796C29.4546 8.14926 29.7046 8.02619 29.9546 7.78003C30.2046 7.53388 30.3296 7.28773 30.3296 6.91849C30.3296 6.54926 30.2046 6.30311 29.9546 6.05696C29.7046 5.8108 29.4546 5.68773 29.0796 5.68773ZM12.8296 24.1493C12.8296 24.5185 12.7046 24.7646 12.4546 25.0108C12.2046 25.257 11.9546 25.38 11.5796 25.38C11.2046 25.38 10.9546 25.257 10.7046 25.0108C10.4546 24.7646 10.3296 24.5185 10.3296 24.1493V14.3031C10.3296 13.9339 10.4546 13.6877 10.7046 13.4416C10.9546 13.1954 11.2046 13.0723 11.5796 13.0723C11.9546 13.0723 12.2046 13.1954 12.4546 13.4416C12.7046 13.6877 12.8296 13.9339 12.8296 14.3031V24.1493ZM20.3296 24.1493C20.3296 24.5185 20.2046 24.7646 19.9546 25.0108C19.7046 25.257 19.4546 25.38 19.0796 25.38C18.7046 25.38 18.4546 25.257 18.2046 25.0108C17.9546 24.7646 17.8296 24.5185 17.8296 24.1493V14.3031C17.8296 13.9339 17.9546 13.6877 18.2046 13.4416C18.4546 13.1954 18.7046 13.0723 19.0796 13.0723C19.4546 13.0723 19.7046 13.1954 19.9546 13.4416C20.2046 13.6877 20.3296 13.9339 20.3296 14.3031V24.1493ZM20.3296 5.68773H10.3296V4.45696C10.3296 4.08773 10.4546 3.84157 10.7046 3.59542C10.9546 3.34926 11.2046 3.22619 11.5796 3.22619H19.0796C19.4546 3.22619 19.7046 3.34926 19.9546 3.59542C20.2046 3.84157 20.3296 4.08773 20.3296 4.45696V5.68773Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DeleteFilled
