import { Button } from '@invisible/ui/button'
import { CloseIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Text } from '@invisible/ui/text'
import { styled } from '@invisible/ui/themes'
import { lib, SHA1 } from 'crypto-js'
import { get } from 'lodash/fp'
import { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'rebass'

import { GOOGLE_CLOUD_BUCKET_NAME } from '../config/env'

const GOOGLE_CLOUD_API = 'https://storage.googleapis.com'

const hash = (name: string | ArrayBuffer | null) => {
  const wordArr = lib.WordArray.create(name)

  return SHA1(wordArr).toString()
}

const ACCEPTED_FILE_TYPES = [
  'image/*',
  '.csv',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.rtf',
  '.txt',
  '.numbers',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
] as const
const CloseIconContainer = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`

const StyledBtn = styled.button`
  margin-top: 5px;
  cursor: pointer;
`
const fileReader = (file: File) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(file)
  })
}

const ModalToDeleteFile = ({
  setOpenModal,
  setFile,
  fileInputRef,
  handleFile,
  filesUploaded,
  publicUrl,
  setFilesAndUrls,
  filesAndUrls,
  handleUrlAndFiles,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  fileInputRef: React.RefObject<HTMLInputElement>
  handleFile: (publicUrl: string) => void
  filesUploaded: string[]
  publicUrl: string
  setFilesAndUrls: React.Dispatch<
    React.SetStateAction<
      {
        file: string
        url: string
      }[]
    >
  >
  filesAndUrls: {
    file: string
    url: string
  }[]
  handleUrlAndFiles?: (filesAndUrls: { file: string; url: string }[]) => void
}) => (
  <Modal
    absolute
    icon='CircledPauseIcon'
    onClose={(e) => {
      if (e) e.preventDefault()
      setOpenModal(false)
    }}
    primaryButton={
      <Button
        variant='primary'
        size='md'
        onClick={(e) => {
          e.preventDefault()
          setFile(null)
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          handleFile(filesUploaded.filter((file) => file !== publicUrl).join(', '))

          const filteredUrlAndFiles = filesAndUrls.filter((file) => file.url !== publicUrl)
          setFilesAndUrls(filteredUrlAndFiles)
          if (handleUrlAndFiles) handleUrlAndFiles(filteredUrlAndFiles)
          setOpenModal(false)
        }}>
        Confirm
      </Button>
    }
    secondaryButton={
      <Button variant='danger' size='md' onClick={() => setOpenModal(false)}>
        Cancel
      </Button>
    }
    title='Are you sure you want to delete the file?'></Modal>
)

const getAllowFileExtension = () => ACCEPTED_FILE_TYPES.join()

const FileInputUploader = ({
  handleFile,
  handleError,
  filesUploaded,
  setFilesUploaded,
  handleUrlAndFiles,
  setFilesAndUrls,
  filesAndUrls,
  acceptedFileTypes,
}: {
  handleFile: (publicUrl: string) => void
  handleError: () => void
  filesUploaded: string[]
  setFilesUploaded: React.Dispatch<React.SetStateAction<string[]>>
  handleUrlAndFiles?: (filesAndUrls: { file: string; url: string }[]) => void
  setFilesAndUrls: React.Dispatch<
    React.SetStateAction<
      {
        file: string
        url: string
      }[]
    >
  >
  filesAndUrls: {
    file: string
    url: string
  }[]
  acceptedFileTypes?: typeof ACCEPTED_FILE_TYPES[number][]
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [publicUrl, setPublicUrl] = useState('')

  useEffect(() => {
    if (!file) return
    setIsUploading(true)
    fileReader(file).then((fileResult) => {
      const fileHash = hash(fileResult as string | ArrayBuffer | null)
      const ext: string | undefined = file.name.split('.').pop()
      const url = `${GOOGLE_CLOUD_API}/${GOOGLE_CLOUD_BUCKET_NAME}/${fileHash}.${ext}`
      setPublicUrl(url)
      fetch(`/api/google/cloud/signedUrl?file=${fileHash}.${ext}&type=${file.type}`, {
        method: 'POST',
        mode: 'cors',
      })
        .then((res) => res.json())
        .then(get('url'))
        .then((urlGoogleStore) =>
          fetch(urlGoogleStore, {
            method: 'PUT',
            headers: {
              'Content-Type': file.type,
            },
            mode: 'cors',
            body: file,
          })
        )
        .then(() => {
          setFilesUploaded([...filesUploaded, url])
          const filesAndUlrsUploaded = [...filesAndUrls, { file: file?.name, url }]

          setFilesAndUrls(filesAndUlrsUploaded)
          if (handleUrlAndFiles) handleUrlAndFiles(filesAndUlrsUploaded)
          handleFile([...filesUploaded, url].join(', '))
          setIsUploading(false)
        })
        .catch(() => {
          setFile(null)
          setIsUploading(false)
          if (fileInputRef.current) fileInputRef.current.value = ''
          handleError()
        })
    })
  }, [file])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (target.files?.[0]) {
      const file = target.files[0]
      setFile(file)
    }
  }

  return (
    <Flex>
      <Box width={4 / 5}>
        <input
          type='file'
          accept={acceptedFileTypes?.join() || getAllowFileExtension()}
          id={`upload-input`}
          onChange={handleChange}
          ref={fileInputRef}
        />
        {isUploading && <Text>Uploading file, please wait...</Text>}
      </Box>
      <Box width={1 / 5}>
        {file && (
          <CloseIconContainer onClick={() => setOpenModal(true)}>
            <CloseIcon />
          </CloseIconContainer>
        )}
        {openModal && (
          <ModalToDeleteFile
            setOpenModal={setOpenModal}
            setFile={setFile}
            fileInputRef={fileInputRef}
            handleFile={handleFile}
            filesUploaded={filesUploaded}
            publicUrl={publicUrl}
            filesAndUrls={filesAndUrls}
            handleUrlAndFiles={handleUrlAndFiles}
            setFilesAndUrls={setFilesAndUrls}
          />
        )}
      </Box>
    </Flex>
  )
}

/**
 * @deprecated Use FileUploaderDropzone
 */

const FileUploader = ({
  handleFile,
  handleError,
  handleUrlAndFiles,
  acceptedFileTypes,
  maxFiles,
}: {
  handleFile: (publicUrl: string) => void
  handleError: () => void
  handleUrlAndFiles?: (filesAndUrls: { file: string; url: string }[]) => void
  acceptedFileTypes?: typeof ACCEPTED_FILE_TYPES[number][]
  maxFiles?: number
}) => {
  const [filesCount, setFilesCount] = useState<number>(1)
  const [filesUploaded, setFilesUploaded] = useState<string[]>([])
  const [filesAndUrls, setFilesAndUrls] = useState<{ file: string; url: string }[]>([])

  const canAddMoreFiles = typeof maxFiles === 'undefined' || maxFiles > filesCount

  return (
    <>
      {Array.from(Array(filesCount)).map((x, index) => (
        <FileInputUploader
          acceptedFileTypes={acceptedFileTypes}
          handleFile={handleFile}
          handleError={handleError}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          handleUrlAndFiles={handleUrlAndFiles}
          setFilesAndUrls={setFilesAndUrls}
          filesAndUrls={filesAndUrls}
          key={index}
        />
      ))}
      {canAddMoreFiles && (
        <StyledBtn
          onClick={(e) => {
            e.preventDefault()
            setFilesCount((count) => count + 1)
          }}>
          Add another File
        </StyledBtn>
      )}
    </>
  )
}

export { FileUploader }
