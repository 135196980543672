import { SVGProps } from 'react'
function LockOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='27'
      viewBox='0 0 25 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.551514 11C0.551514 9.89543 1.44694 9 2.55151 9H22.5515C23.6561 9 24.5515 9.89543 24.5515 11V25C24.5515 26.1046 23.6561 27 22.5515 27H2.55151C1.44694 27 0.551514 26.1046 0.551514 25V11ZM22.5515 11H2.55151V25H22.5515V11Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5515 2C11.6233 2 10.733 2.36875 10.0766 3.02513C9.42026 3.6815 9.05151 4.57174 9.05151 5.5V10C9.05151 10.5523 8.6038 11 8.05151 11C7.49923 11 7.05151 10.5523 7.05151 10V5.5C7.05151 4.04131 7.63098 2.64236 8.66243 1.61091C9.69388 0.579463 11.0928 0 12.5515 0C14.0102 0 15.4092 0.579463 16.4406 1.61091C17.472 2.64236 18.0515 4.04131 18.0515 5.5V10C18.0515 10.5523 17.6038 11 17.0515 11C16.4992 11 16.0515 10.5523 16.0515 10V5.5C16.0515 4.57174 15.6828 3.6815 15.0264 3.02513C14.37 2.36875 13.4798 2 12.5515 2Z'
        fill='currentColor'
      />
      <path
        d='M12.5515 19.5C13.3799 19.5 14.0515 18.8284 14.0515 18C14.0515 17.1716 13.3799 16.5 12.5515 16.5C11.7231 16.5 11.0515 17.1716 11.0515 18C11.0515 18.8284 11.7231 19.5 12.5515 19.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LockOutline
