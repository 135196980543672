import { z } from '@invisible/zod'

import { predicateSchema } from '../predicate'
import { booleanArgSchema } from './args'

/**
 * Formula that takes 1 or more boolean arguments and returns a boolean.
 */
const booleanPredicateSchema = predicateSchema
  .omit({ args: true })
  .extend({ args: booleanArgSchema.array().min(1) })
  .strict()

/**
 * Formula that takes 1 or more boolean arguments and returns a boolean.
 */
type TBooleanPredicate = z.infer<typeof booleanPredicateSchema>

/**
 * A formula that takes exactly two boolean arguments and returns a boolean.
 */
const booleanComparisonSchema = booleanPredicateSchema
  .omit({ args: true })
  .extend({ args: booleanArgSchema.array().length(2) })
  .strict()

/**
 * A formula that takes exactly two boolean arguments and returns a boolean.
 */
type TBooleanComparison = z.infer<typeof booleanComparisonSchema>

/**
 * Formula that takes exactly one boolean argument and returns a boolean.
 */
const booleanUnaryPredicateSchema = booleanPredicateSchema
  .omit({ args: true })
  .extend({ args: booleanArgSchema.array().length(1) })
  .strict()

/**
 * Formula that takes exactly one boolean argument and returns a boolean.
 */
type TBooleanUnaryPredicate = z.infer<typeof booleanUnaryPredicateSchema>

export { booleanComparisonSchema, booleanPredicateSchema, booleanUnaryPredicateSchema }
export type { TBooleanComparison, TBooleanPredicate, TBooleanUnaryPredicate }
