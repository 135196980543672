import { SVGProps } from 'react'
function CalendlyOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      {...props}>
      <path
        d='M19.4726 18.7742C18.5534 19.59 17.4054 20.6051 15.3239 20.6051H14.0792C12.573 20.6051 11.203 20.0576 10.2224 19.0652C9.26447 18.0955 8.73716 16.7684 8.73716 15.3278V13.6248C8.73716 12.1841 9.26447 10.857 10.2224 9.88734C11.203 8.89495 12.573 8.3483 14.0792 8.3483H15.3239C17.4079 8.3483 18.5534 9.36255 19.4726 10.1783C20.4271 11.0193 21.2505 11.7544 23.4463 11.7544C23.7812 11.7543 24.1156 11.7276 24.4463 11.6745L24.4387 11.6551C24.3072 11.3285 24.1529 11.0115 23.977 10.7065L22.5094 8.16412C21.8476 7.01803 20.8958 6.06632 19.7497 5.40466C18.6035 4.743 17.3033 4.39469 15.9799 4.39474H13.0439C11.7205 4.39469 10.4204 4.743 9.27418 5.40466C8.12801 6.06632 7.17619 7.01803 6.51439 8.16412L5.04684 10.7065C4.38527 11.8527 4.03699 13.1528 4.03699 14.4763C4.03699 15.7997 4.38527 17.0999 5.04684 18.2461L6.51439 20.7884C7.17619 21.9345 8.12801 22.8862 9.27418 23.5479C10.4204 24.2096 11.7205 24.5579 13.0439 24.5578H15.9799C17.3033 24.5579 18.6035 24.2096 19.7497 23.5479C20.8958 22.8862 21.8476 21.9345 22.5094 20.7884L23.977 18.2503C24.1529 17.9452 24.3072 17.6283 24.4387 17.3016L24.4463 17.2831C24.1158 17.2282 23.7814 17.2001 23.4463 17.199C21.2505 17.199 20.4271 17.9299 19.4726 18.7751'
        fill='#006BFF'
      />
      <path
        d='M15.3239 9.89148H14.0792C11.7866 9.89148 10.2804 11.5289 10.2804 13.6247V15.3277C10.2804 17.4235 11.7866 19.0609 14.0792 19.0609H15.3239C18.6644 19.0609 18.402 15.6549 23.4463 15.6549C23.9242 15.6543 24.4012 15.6983 24.871 15.7861C25.024 14.9195 25.024 14.0329 24.871 13.1663C24.4011 13.2537 23.9242 13.2977 23.4463 13.2975C18.4003 13.2975 18.6644 9.89148 15.3239 9.89148Z'
        fill='#006BFF'
      />
      <path
        d='M27.769 17.0308C26.91 16.4016 25.9194 15.9757 24.8718 15.7852C24.8718 15.7945 24.8718 15.8021 24.8718 15.8105C24.7816 16.3126 24.6407 16.8043 24.4513 17.278C25.3167 17.4121 26.1377 17.7502 26.8465 18.2645C26.8465 18.2713 26.8422 18.2797 26.8397 18.2872C25.9275 21.241 23.9861 23.7696 21.3681 25.4137C18.7501 27.0577 15.6292 27.7082 12.5724 27.2469C9.51562 26.7857 6.72567 25.2433 4.70937 22.8999C2.69308 20.5565 1.58422 17.5676 1.58422 14.4762C1.58422 11.3848 2.69308 8.39594 4.70937 6.05257C6.72567 3.7092 9.51562 2.16678 12.5724 1.70551C15.6292 1.24425 18.7501 1.89473 21.3681 3.5388C23.9861 5.18287 25.9275 7.71146 26.8397 10.6652C26.8397 10.6728 26.8439 10.6812 26.8465 10.6879C26.1378 11.2023 25.3167 11.5402 24.4513 11.6736C24.6405 12.1479 24.7814 12.6402 24.8718 13.1428C24.8713 13.1507 24.8713 13.1585 24.8718 13.1664C25.9193 12.9762 26.91 12.5506 27.769 11.9217C28.5949 11.3103 28.4351 10.6198 28.3098 10.2111C27.2887 6.8993 25.1134 4.06373 22.1792 2.21963C19.2449 0.375528 15.7465 -0.35472 12.3196 0.161582C8.89261 0.677884 5.76461 2.40647 3.50395 5.03324C1.24329 7.66 0 11.0106 0 14.4762C0 17.9418 1.24329 21.2925 3.50395 23.9192C5.76461 26.546 8.89261 28.2746 12.3196 28.7909C15.7465 29.3072 19.2449 28.5769 22.1792 26.7328C25.1134 24.8887 27.2887 22.0532 28.3098 18.7414C28.4351 18.3326 28.5949 17.6422 27.769 17.0308Z'
        fill='#006BFF'
      />
      <path
        d='M24.4462 11.6735C24.1157 11.7284 23.7813 11.7566 23.4462 11.7576C21.2504 11.7576 20.427 11.0268 19.4733 10.1816C18.5533 9.36583 17.4078 8.35074 15.3238 8.35074H14.0791C12.572 8.35074 11.2029 8.89823 10.2223 9.89062C9.26437 10.8603 8.73706 12.1874 8.73706 13.628V15.3311C8.73706 16.7717 9.26437 18.0988 10.2223 19.0685C11.2029 20.0609 12.572 20.6075 14.0791 20.6075H15.3238C17.4078 20.6075 18.5533 19.5933 19.4733 18.7775C20.427 17.9365 21.2504 17.2015 23.4462 17.2015C23.7811 17.2015 24.1155 17.2283 24.4462 17.2814C24.636 16.8078 24.7769 16.316 24.8667 15.8138C24.8671 15.8054 24.8671 15.797 24.8667 15.7886C24.3968 15.7015 23.9199 15.6578 23.442 15.6582C18.396 15.6582 18.6601 19.0651 15.3196 19.0651H14.0749C11.7823 19.0651 10.2761 17.4268 10.2761 15.3311V13.6247C10.2761 11.5289 11.7823 9.89146 14.0749 9.89146H15.3196C18.6601 9.89146 18.3977 13.2967 23.442 13.2967C23.9199 13.2975 24.3969 13.2539 24.8667 13.1663C24.8667 13.1588 24.8667 13.1512 24.8667 13.1428C24.7763 12.6401 24.6354 12.1479 24.4462 11.6735Z'
        fill='#0AE8F0'
      />
      <path
        d='M24.4462 11.6735C24.1157 11.7284 23.7813 11.7566 23.4462 11.7576C21.2504 11.7576 20.427 11.0268 19.4733 10.1816C18.5533 9.36583 17.4078 8.35074 15.3238 8.35074H14.0791C12.572 8.35074 11.2029 8.89823 10.2223 9.89062C9.26437 10.8603 8.73706 12.1874 8.73706 13.628V15.3311C8.73706 16.7717 9.26437 18.0988 10.2223 19.0685C11.2029 20.0609 12.572 20.6075 14.0791 20.6075H15.3238C17.4078 20.6075 18.5533 19.5933 19.4733 18.7775C20.427 17.9365 21.2504 17.2015 23.4462 17.2015C23.7811 17.2015 24.1155 17.2283 24.4462 17.2814C24.636 16.8078 24.7769 16.316 24.8667 15.8138C24.8671 15.8054 24.8671 15.797 24.8667 15.7886C24.3968 15.7015 23.9199 15.6578 23.442 15.6582C18.396 15.6582 18.6601 19.0651 15.3196 19.0651H14.0749C11.7823 19.0651 10.2761 17.4268 10.2761 15.3311V13.6247C10.2761 11.5289 11.7823 9.89146 14.0749 9.89146H15.3196C18.6601 9.89146 18.3977 13.2967 23.442 13.2967C23.9199 13.2975 24.3969 13.2539 24.8667 13.1663C24.8667 13.1588 24.8667 13.1512 24.8667 13.1428C24.7763 12.6401 24.6354 12.1479 24.4462 11.6735Z'
        fill='#0AE8F0'
      />
    </svg>
  )
}

export default CalendlyOriginal
