import { SVGProps } from 'react'
function DownloadFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.37959 14.294C8.19838 14.1049 8.07533 13.8676 8.0251 13.6106C7.97487 13.3535 7.99957 13.0874 8.09626 12.844C8.19568 12.5982 8.36613 12.3876 8.58581 12.2392C8.8055 12.0908 9.06446 12.0112 9.32959 12.0107H14.9963V1.67733C14.9963 1.32371 15.1367 0.984567 15.3868 0.734519C15.6368 0.48447 15.976 0.343994 16.3296 0.343994C16.6832 0.343994 17.0224 0.48447 17.2724 0.734519C17.5224 0.984567 17.6629 1.32371 17.6629 1.67733V12.0107H23.3296C23.5947 12.0112 23.8537 12.0908 24.0734 12.2392C24.2931 12.3876 24.4635 12.5982 24.5629 12.844C24.6596 13.0874 24.6843 13.3535 24.6341 13.6106C24.5838 13.8676 24.4608 14.1049 24.2796 14.294L17.2796 21.294C17.0247 21.5404 16.6841 21.6782 16.3296 21.6782C15.9751 21.6782 15.6344 21.5404 15.3796 21.294L8.37959 14.294ZM30.9963 19.0107C30.6426 19.0107 30.3035 19.1511 30.0534 19.4012C29.8034 19.6512 29.6629 19.9904 29.6629 20.344V29.6773H2.99626V20.344C2.99626 19.9904 2.85578 19.6512 2.60573 19.4012C2.35568 19.1511 2.01655 19.0107 1.66292 19.0107C1.3093 19.0107 0.970163 19.1511 0.720114 19.4012C0.470066 19.6512 0.32959 19.9904 0.32959 20.344V29.6773C0.32959 30.3846 0.610541 31.0628 1.11064 31.5629C1.61074 32.063 2.28901 32.344 2.99626 32.344H29.6629C30.3702 32.344 31.0484 32.063 31.5485 31.5629C32.0486 31.0628 32.3296 30.3846 32.3296 29.6773V20.344C32.3296 19.9904 32.1891 19.6512 31.9391 19.4012C31.689 19.1511 31.3499 19.0107 30.9963 19.0107Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DownloadFilled
