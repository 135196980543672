import { SVGProps } from 'react'

function DashCircleFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16 0.5C12.8355 0.5 9.74207 1.43838 7.11088 3.19649C4.4797 4.95459 2.42894 7.45344 1.21793 10.3771C0.00693255 13.3007 -0.309921 16.5177 0.307443 19.6214C0.924806 22.7251 2.44866 25.5761 4.6863 27.8137C6.92394 30.0513 9.77486 31.5752 12.8786 32.1926C15.9823 32.8099 19.1993 32.4931 22.1229 31.2821C25.0466 30.0711 27.5454 28.0203 29.3035 25.3891C31.0616 22.7579 32 19.6645 32 16.5C31.9919 12.259 30.3035 8.19409 27.3047 5.19527C24.3059 2.19645 20.241 0.508132 16 0.5ZM22.1538 17.7308H9.84616C9.51974 17.7308 9.20669 17.6011 8.97587 17.3703C8.74506 17.1395 8.61539 16.8264 8.61539 16.5C8.61539 16.1736 8.74506 15.8605 8.97587 15.6297C9.20669 15.3989 9.51974 15.2692 9.84616 15.2692H22.1538C22.4803 15.2692 22.7933 15.3989 23.0241 15.6297C23.2549 15.8605 23.3846 16.1736 23.3846 16.5C23.3846 16.8264 23.2549 17.1395 23.0241 17.3703C22.7933 17.6011 22.4803 17.7308 22.1538 17.7308Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DashCircleFilled
