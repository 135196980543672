const NEXT_PUBLIC_STATSIG_ENVIRONMENT_TIER = process.env
  .NEXT_PUBLIC_STATSIG_ENVIRONMENT_TIER as string
const NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY as string
const NEXT_PUBLIC_INVISIBLE_STATSIG_PROXY = process.env
  .NEXT_PUBLIC_INVISIBLE_STATSIG_PROXY as string

export {
  NEXT_PUBLIC_INVISIBLE_STATSIG_PROXY,
  NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY,
  NEXT_PUBLIC_STATSIG_ENVIRONMENT_TIER,
}
