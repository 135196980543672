import { SVGProps } from 'react'

function SnoozeOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.606 8C16.606 7.58579 16.9417 7.25 17.356 7.25H20.856C21.1502 7.25 21.4173 7.4221 21.5389 7.69009C21.6605 7.95807 21.6142 8.27241 21.4204 8.49388L19.0088 11.25H20.856C21.2702 11.25 21.606 11.5858 21.606 12C21.606 12.4142 21.2702 12.75 20.856 12.75H17.356C17.0617 12.75 16.7946 12.5779 16.673 12.3099C16.5514 12.0419 16.5977 11.7276 16.7915 11.5061L19.2031 8.75H17.356C16.9417 8.75 16.606 8.41421 16.606 8Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.481 1.5C22.481 1.01675 22.8727 0.625 23.356 0.625H28.356C28.7183 0.625 29.0431 0.848273 29.1729 1.18652C29.3027 1.52477 29.2106 1.90802 28.9413 2.15038L25.6362 5.125H28.356C28.8392 5.125 29.231 5.51675 29.231 6C29.231 6.48325 28.8392 6.875 28.356 6.875H23.356C22.9937 6.875 22.6688 6.65173 22.539 6.31348C22.4092 5.97523 22.5013 5.59198 22.7706 5.34962L26.0757 2.375H23.356C22.8727 2.375 22.481 1.98325 22.481 1.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.356 7C7.28082 7 2.35596 11.9249 2.35596 18C2.35596 24.0751 7.28082 29 13.356 29C19.4311 29 24.356 24.0751 24.356 18C24.356 17.0489 24.2355 16.1274 24.0095 15.2493C23.8718 14.7144 24.1938 14.1692 24.7286 14.0316C25.2635 13.8939 25.8087 14.2159 25.9463 14.7507C26.2139 15.7903 26.356 16.8793 26.356 18C26.356 25.1797 20.5357 31 13.356 31C6.17626 31 0.355957 25.1797 0.355957 18C0.355957 10.8203 6.17626 5 13.356 5C13.9082 5 14.356 5.44772 14.356 6C14.356 6.55228 13.9082 7 13.356 7Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.356 13C14.9082 13 15.356 13.4477 15.356 14V19.5C15.356 20.0523 14.9082 20.5 14.356 20.5H9.35596C8.80367 20.5 8.35596 20.0523 8.35596 19.5C8.35596 18.9477 8.80367 18.5 9.35596 18.5H13.356V14C13.356 13.4477 13.8037 13 14.356 13Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SnoozeOutline
