import type { TProcessByIdStep } from '@invisible/common/components/process-base'
import {
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { FlagOutlineIcon } from '@invisible/ui/icons'
import { baseTheme } from '@invisible/ui/themes'
import { FC, useMemo } from 'react'
import { Handle, Position } from 'reactflow'

interface IProps {
  selected: boolean
  data: {
    step: TProcessByIdStep
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const EndStep: FC<IProps> = ({ data, selected }) => {
  const { data: process } = useProcessById({ id: data.step.processId })

  const step = useMemo(
    () => process?.steps.find((s: { id: string }) => s.id === data.step.id),
    [process]
  )

  return (
    <>
      <div
        className={classNames(
          'text-primary border-void bg-void box-border flex h-16 w-52 cursor-default items-center rounded border-2 border-solid px-3',
          selected ? 'border-fuchsia-weak' : ''
        )}>
        <FlagOutlineIcon width={20} height={20} color={baseTheme.colors.primary} />
        <span className='ml-5 w-40 truncate font-bold tracking-wider'>{step?.name ?? ''}</span>
      </div>
      <Handle type='target' position={Position.Left} className='!bg-theme-weak !h-2 !w-2' />
    </>
  )
}

export { EndStep }
