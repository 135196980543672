export const createTooltip = ({
  highlightElement,
  tooltipText,
  left,
  top,
}: {
  highlightElement: HTMLElement
  tooltipText: string
  left: number
  top: number
}) => {
  // Create a new div element to serve as the tooltip
  const tooltip = document.createElement('div')
  tooltip.classList.add('tooltip')
  tooltip.innerText = tooltipText
  tooltip.style.position = 'absolute'
  tooltip.style.left = `${left}px`
  tooltip.style.top = `${top + 10}px` // Position above the highlight
  tooltip.style.backgroundColor = 'black'
  tooltip.style.color = 'white'
  tooltip.style.padding = '5px'
  tooltip.style.borderRadius = '4px'
  tooltip.style.fontSize = '12px'
  tooltip.style.zIndex = '1000'
  tooltip.style.visibility = 'hidden'

  document.body.appendChild(tooltip)

  // Add an event listener to the document to track mouse movement
  document.body.addEventListener('mousemove', (event) => {
    // Get the bounding rectangle of the highlightElement (the area it covers)

    const rect = highlightElement.getBoundingClientRect()
    const mouseX = event.clientX
    const mouseY = event.clientY
    // Check if the mouse is within the bounds of the highlightElement
    if (
      mouseX >= rect.left &&
      mouseX <= rect.right &&
      mouseY >= rect.top &&
      mouseY <= rect.bottom
    ) {
      tooltip.style.visibility = 'visible'
    } else {
      tooltip.style.visibility = 'hidden'
    }
  })

  // Use MutationObserver to detect when the highlightElement is removed
  const observer = new MutationObserver(() => {
    if (!document.body.contains(highlightElement)) {
      tooltip.remove()
      observer.disconnect()
    }
  })

  observer.observe(document.body, { childList: true, subtree: true })
}
