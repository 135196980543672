import { SVGProps } from 'react'

function Funnel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={22}
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.7745 2.04503C31.579 1.57567 31.258 1.17672 30.8508 0.89708C30.4436 0.617442 29.9679 0.469266 29.4822 0.470714H2.50756C2.0219 0.474837 1.54765 0.626533 1.14153 0.907656C0.73541 1.18878 0.414617 1.58743 0.217521 2.05592C0.020424 2.52441 -0.0446315 3.04292 0.0301352 3.54941C0.104902 4.0559 0.316326 4.52893 0.639117 4.91193L10.9705 16.8933V29.8192C10.9697 30.3002 11.0937 30.7722 11.3289 31.184C11.5642 31.5959 11.9018 31.932 12.3051 32.1558C12.6682 32.3584 13.072 32.4664 13.4827 32.4707C13.9802 32.4709 14.4665 32.3152 14.8801 32.0233L19.9044 28.4769C20.2485 28.2356 20.5305 27.9079 20.7251 27.5232C20.9196 27.1385 21.0207 26.7089 21.0192 26.2729V16.8933L31.3506 4.91193C31.6794 4.53031 31.8948 4.05524 31.9701 3.54562C32.0455 3.03599 31.9775 2.51423 31.7745 2.04503Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Funnel
