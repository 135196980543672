import { SVGProps } from 'react'
function AddUserOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M23.1721 15.0133C21.2962 15.0133 19.5307 15.6091 18.0961 16.6243C17.6547 16.4036 17.1913 16.2271 16.7278 16.0505C19.001 14.5498 20.5238 11.9678 20.5238 9.05466C20.5238 4.42018 16.7499 0.668457 12.1373 0.668457C7.52478 0.668457 3.75087 4.42018 3.75087 9.05466C3.75087 11.9678 5.27367 14.5498 7.54684 16.0505C6.73027 16.3374 5.95783 16.7126 5.20746 17.154C3.11085 18.4119 1.34528 20.2436 0.131446 22.4064C-0.155459 22.9581 0.0431678 23.6202 0.572839 23.9291C1.10251 24.216 1.7646 24.0174 2.07357 23.4878C3.08878 21.656 4.56744 20.1112 6.33301 19.0519C9.35655 17.2423 13.1084 16.9774 16.3306 18.2574C15.0947 19.7802 14.3443 21.7223 14.3443 23.8409C14.3443 28.7181 18.2948 32.6685 23.1721 32.6685C28.0495 32.6685 32 28.7181 32 23.8409C32 18.9636 28.0495 15.0133 23.1721 15.0133ZM12.1373 15.234C8.73861 15.234 5.95783 12.4533 5.95783 9.05466C5.95783 5.65604 8.73861 2.87535 12.1373 2.87535C15.5361 2.87535 18.3168 5.65604 18.3168 9.05466C18.3168 12.4533 15.5361 15.234 12.1373 15.234ZM23.1721 30.4616C19.5307 30.4616 16.5513 27.4823 16.5513 23.8409C16.5513 22.0974 17.2354 20.4864 18.361 19.3167C18.8906 18.7429 19.5086 18.2795 20.2148 17.9264C21.0976 17.485 22.1128 17.2202 23.1721 17.2202C26.8136 17.2202 29.793 20.1995 29.793 23.8409C29.793 27.4823 26.8136 30.4616 23.1721 30.4616Z'
        fill='currentColor'
      />
      <path
        d='M27.0769 22.8222H24.6153V20.3607C24.6153 19.6715 24.0738 19.1299 23.3846 19.1299C22.9907 19.1299 22.6461 19.3022 22.3999 19.5976C22.2522 19.7945 22.1538 20.0653 22.1538 20.3607V22.8222H19.6922C19.003 22.8222 18.4615 23.3638 18.4615 24.053C18.4615 24.7422 19.003 25.2838 19.6922 25.2838H22.1538V27.7453C22.1538 28.4345 22.6953 28.9761 23.3846 28.9761C24.0738 28.9761 24.6153 28.4345 24.6153 27.7453V25.2838H27.0769C27.7661 25.2838 28.3076 24.7422 28.3076 24.053C28.3076 23.3638 27.7661 22.8222 27.0769 22.8222Z'
        fill='curentColor'
      />
    </svg>
  )
}

export default AddUserOutline
