import { Button } from '@invisible/ui/button'
import { CircleArrowLeftIcon } from '@invisible/ui/icons'
import { Progress } from '@invisible/ui/progress'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'

import { TNormalizedBaseRun } from './types'

interface IProps {
  allBaseRuns: TNormalizedBaseRun[]
  onSave: (dataToBeRanked: TNormalizedBaseRun[]) => void
  setCurrentView: Dispatch<SetStateAction<'view' | 'rank'>>
  isUpdatingManyVariables: boolean
  config: WizardSchemas.Ranking.TSchema
  readOnly: boolean
}

const Rank = ({
  allBaseRuns,
  onSave,
  setCurrentView,
  isUpdatingManyVariables,
  config,
  readOnly,
}: IProps) => {
  const [dataToBeRanked, setDataToBeRanked] = useState(allBaseRuns)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [round, setRound] = useState(1)
  const [totalComparisonsCompleted, setTotalComparisonsCompleted] = useState(0)
  const [selectedOption, setSelectedOption] = useState<number | null>(null) // [0, 1]

  const refMain = useRef<HTMLDivElement>(null)
  const refA = useRef<HTMLDivElement>(null)
  const refB = useRef<HTMLDivElement>(null)

  const updateIndices = (data: TNormalizedBaseRun[], selection: number) => {
    switch (selection) {
      case 0:
        if (data[currentIndex].rank === data[currentIndex + 1].rank) {
          data[currentIndex].rank = currentIndex + 1
          data[currentIndex + 1].rank = currentIndex + 2
        }
        break

      case 1:
        data[currentIndex].rank = currentIndex + 2
        data[currentIndex + 1].rank = currentIndex + 1
        break
    }
  }

  const handleEquality = () => {
    // We create a copy of the current state of the data to be ranked
    const newData = [...dataToBeRanked]

    // Since we need to make the ranks equal, we check the lower of the two ranks and set the higher one to that value
    if (newData[currentIndex].rank < newData[currentIndex + 1].rank) {
      newData[currentIndex + 1].rank = newData[currentIndex].rank
    } else {
      newData[currentIndex].rank = newData[currentIndex + 1].rank
    }

    // We update the data to be ranked
    setDataToBeRanked(newData)

    /* 
      We call handleSelect so it can do the rest of the work of moving to the next pair/round. 
      Passing 2 as a parameter insures no default behavior of selection-related swapping is triggered.
    */
    handleSelect(2)
  }

  // Handler for when an option is selected
  const handleSelect = (selection: number | null) => {
    if (selection === null || readOnly) return

    // We create a copy of the current state of the data to be ranked
    const newData = [...dataToBeRanked]

    // We send the indices to be updated based on which option is selected
    updateIndices(newData, selection)

    // If the right-hand side (second) option is selected, we also swap the positions of the pair in the array
    if (selection === 1) {
      const temp = newData[currentIndex]
      newData[currentIndex] = newData[currentIndex + 1]
      newData[currentIndex + 1] = temp
    }

    /* 
      We update the data to be ranked. 
      There will be no change if selection === 2 (this request comes from handleEquality()).
      There will be no change if the left-hand side (first) option is selected AND the ranks are unequal.
    */
    setDataToBeRanked(newData)

    if (
      currentIndex < dataToBeRanked.length - round - 1 ||
      (round === dataToBeRanked.length && currentIndex < dataToBeRanked.length - 2) // Last round goes to the end
    ) {
      setCurrentIndex(currentIndex + 1) // move to next pair
    } else {
      setCurrentIndex(0) // new round
      setRound(round + 1)
    }

    setTotalComparisonsCompleted(totalComparisonsCompleted + 1)
    setSelectedOption(null)
  }

  useEffect(() => {
    // Once the last round is completed, save the data automatically
    if (round > dataToBeRanked.length) {
      onSave(dataToBeRanked)
    }
  }, [round])

  const reset = () => {
    setDataToBeRanked(allBaseRuns)
    setCurrentIndex(0)
    setRound(1)
    setTotalComparisonsCompleted(0)
    setSelectedOption(null)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.code) {
      case 'KeyA': // A
        refA.current?.focus()
        setSelectedOption(0)
        break

      case 'KeyB': // B
        refB.current?.focus()
        setSelectedOption(1)
        break

      case 'Enter': // Enter
        handleSelect(selectedOption)
        break
    }
  }

  useEffect(() => {
    // When a selection is saved, the focus goes to the main div
    if (selectedOption === null) {
      refA.current?.blur()
      refB.current?.blur()
      refMain.current?.focus()
    }
  }, [totalComparisonsCompleted])

  return (
    <div
      className='relative flex h-full w-full flex-col justify-between p-6 focus:outline-none'
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={refMain}>
      <Progress
        percentage={true}
        progress={
          totalComparisonsCompleted /
          ((dataToBeRanked.length * (dataToBeRanked.length - 1)) / 2 + dataToBeRanked.length - 1)
        }
        width={1400}
        color={'black'}
      />
      {round > dataToBeRanked.length ? (
        <h2 className='flex items-center justify-center'>
          Finished! The new rankings will be auto-saved now...
        </h2>
      ) : (
        <div className='overflow-auto px-16'>
          <p className='text-lg'>
            Which of these responses is better in terms of{' '}
            <span className='font-bold'>{config.rankingCriteria}</span>?
          </p>
          <div className='grid grid-cols-12 gap-0'>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={0}
              ref={refA}
              onClick={() => handleSelect(0)}
              tabIndex={1}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>A</div>
              {dataToBeRanked[currentIndex].message}
            </div>
            <div className='col-span-1 my-12 text-2xl'>Or...</div>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={1}
              ref={refB}
              onClick={() => handleSelect(1)}
              tabIndex={2}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>B</div>
              {dataToBeRanked[currentIndex + 1].message}
            </div>
            {selectedOption !== null ? (
              <div className='col-span-1 m-2 my-10 mr-0 flex items-center'>
                <CircleArrowLeftIcon
                  color='black'
                  height={24}
                  width={24}
                  onClick={() => handleSelect(selectedOption)}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className='flex items-center justify-center gap-6 p-3'>
        <Button
          disabled={round <= dataToBeRanked.length || isUpdatingManyVariables || readOnly}
          onClick={() => {
            onSave(dataToBeRanked)
          }}>
          {isUpdatingManyVariables ? 'Saving...' : 'Save'}
        </Button>

        {config.disallowEqualRanking ? null : (
          <Button
            disabled={isUpdatingManyVariables || readOnly}
            onClick={() => {
              handleEquality()
            }}>
            Equal
          </Button>
        )}

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            reset()
          }}>
          Reset
        </Button>

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            setCurrentView('view')
          }}>
          View Current Order
        </Button>
      </div>
    </div>
  )
}

export { Rank }
