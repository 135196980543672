import { SnackbarContext } from '@invisible/common/providers'
import { TFilterOption } from '@invisible/common/stores/process-store'
import type {
  BaseRunStatus,
  StepRunStatus,
  StepTemplateType,
  VariableType,
} from '@invisible/ultron/prisma'
import axios from 'axios'
import { isPlainObject, snakeCase } from 'lodash/fp'
import { useContext } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useGate } from 'statsig-react'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

interface IProps {
  baseId: string
  baseViewId: string
  filters: TFilterOption[]
  sort: Record<string, 'asc' | 'desc' | undefined>
  search: string
  page: number
  take: number
}

type TConcordeBaseRunQueryResponse = {
  item_count: number
  items: {
    base_runs: {
      id: string
      deadline: string
      created_at: string
      delivered_at: string | null
      status: BaseRunStatus
      parent_id: string | null
      base_id: string
      base_run_variables: {
        id: string
        base_run_id: string
        base_variable_id: string
        value: unknown
      }[]
      step_runs: {
        id: string
        step_id: string
        snooze_activity_id: string | null
        created_at: string
        started_at: string | null
        stopped_at: string | null
        base_run_id: string
        status: StepRunStatus
        assignee_id: string | null
        error_message: string | null
        updated_at: string
        snooze_activities?: {
          id: string
          snoozed_at: string
          unsnoozed_at: string | null
          active: boolean
        }[]
      }[]
      child_count: Record<string, number>
    }[]
    base_variables: Record<
      string,
      {
        type: VariableType
        name: string
        options: string[] | null
        key: string
        required: boolean
      }
    >
    step_templates: Record<
      string,
      {
        id: string
        type: StepTemplateType
        name: string
        step_template_variables: { id: string; key: string }[]
      }
    >
    steps: Record<
      string,
      {
        meta: unknown
        base_id: string
        name: string
        step_template_id: string
        step_variable_references: { base_variable_id: string; step_template_variable_id: string }[]
      }
    >
    assignees: Record<
      string,
      {
        email: string
        email_verified_at: string | null
        first_name: string | null
        last_name: string | null
        gender: string | null
        name: string | null
        preferred_name: string | null
        location: string | null
        phone: string | null
        pronouns: string | null
        birthday: string | null
        image: string | null
        timezone: string | null
        manager_id: string | null
        specific_role_id: string
        offboarded_at: string | null
        status: string
        slack: string | null
        created_at: string
        updated_at: string
        last_login: string | null
        password: string | null
        is_superuser: boolean
        is_staff: boolean
        is_active: boolean
      }
    >
  }
}

export type TBaseRunQueryData = {
  itemCount: number
  items: {
    id: string
    deadline: Date
    createdAt: Date
    deliveredAt: Date | null
    status: BaseRunStatus
    parentId: string | null
    baseId: string
    childCounts: Record<string, number>
    baseRunVariables: {
      id: string
      baseRunId: string
      baseVariableId: string
      value: unknown
      baseVariable: {
        id: string
        type: VariableType
        name: string
        options: string[] | null
        key: string
        required: boolean
      }
    }[]
    stepRuns: {
      id: string
      stepId: string
      snoozeActivityId: string | null
      createdAt: Date
      startedAt: Date | null
      stoppedAt: Date | null
      baseRunId: string
      status: StepRunStatus
      assigneeId: string | null
      errorMessage: string | null
      updatedAt: Date
      assignee: {
        id: string
        firstName: string | null
        lastName: string | null
        name: string | null
        preferredName: string | null
        image: string | null
        specificRoleId: string
        offboardedAt: Date | null
        status: string
        slack: string | null
        email: string
      } | null
      step: {
        id: string
        meta: unknown
        baseId: string
        name: string
        stepTemplateId: string
        stepTemplate: {
          id: string
          type: StepTemplateType
          name: string
          stepTemplateVariables: { id: string; key: string }[]
        }
        stepVariableReferences: {
          stepTemplateVariable: {
            key: string
          }
          baseVariableId: string
        }[]
      }
      snoozeActivites: {
        id: string
        snoozedAt: Date
        unsnoozedAt: Date | null
        active: boolean
      }[]
    }[]
  }[]
}

const useGetBaseRuns = (
  { baseId, baseViewId, filters, sort, search, page, take }: IProps,
  options: UseQueryOptions<TBaseRunQueryData>
) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const { value: enableBaseRunsFindByBaseIdV2 } = useGate('enable-base-runs-find-by-base-id-v2')

  const response = useQuery<TBaseRunQueryData>(
    ['get-base-runs', { baseId, baseViewId, filters, page, search, sort, take }],
    async () => {
      // Transforms createdAt and deliveredAt to snake_case
      const transformedSort = sort?.createdAt
        ? { created_at: sort.createdAt }
        : sort?.deliveredAt
        ? { delivered_at: sort.deliveredAt }
        : sort

      // Transforms relevant keys in filters to snake_case
      const transformedFilters = filters.map((filterItem) => {
        // We also want to transform {startDate: Date, endDate: Date} to {start_date: Date, end_date: Date} if present as the value
        const value =
          typeof filterItem.value === 'object' &&
          isPlainObject(filterItem.value) &&
          'startDate' in filterItem.value &&
          'endDate' in filterItem.value
            ? { start_date: filterItem.value.startDate, end_date: filterItem.value.endDate }
            : filterItem.value

        return {
          key: ['parentBaseRunId', 'createdAt', 'deliveredAt'].includes(filterItem.id)
            ? snakeCase(filterItem.id)
            : filterItem.id,
          value,
          ...(filterItem.isStepAssignee ? { is_step_assignee: filterItem.isStepAssignee } : {}),
          ...(filterItem.isStepStatus ? { is_step_status: filterItem.isStepStatus } : {}),
          ...(filterItem.isBaseRunStatus ? { is_base_run_status: filterItem.isBaseRunStatus } : {}),
        }
      })

      const queryUrl = new URL(
        `${NEXT_PUBLIC_CONCORDE_URL}/data/api/base-runs/${
          enableBaseRunsFindByBaseIdV2 ? 'v2/' : ''
        }find-by-base-id/`
      )
      queryUrl.searchParams.set('base_id', baseId)
      queryUrl.searchParams.set('base_view_id', baseViewId)
      queryUrl.searchParams.set('page', String(page))
      queryUrl.searchParams.set('page_size', String(take))
      queryUrl.searchParams.set('search', search)
      queryUrl.searchParams.set('sort', JSON.stringify(transformedSort))
      queryUrl.searchParams.set('extra_filters', JSON.stringify(transformedFilters))

      const response = await axios.get<TConcordeBaseRunQueryResponse>(queryUrl.toString(), {
        withCredentials: true,
      })
      const responseData = response.data

      return transformData(responseData)
    },
    {
      ...options,
      onError: (error) => {
        showSnackbar({
          message: `Something went wrong fetching base runs: ${(error as Error)?.message}`,
          variant: 'error',
        })
      },
    }
  )

  return response
}

const transformData = (responseData: TConcordeBaseRunQueryResponse) => {
  const formattedResponse: TBaseRunQueryData = {
    itemCount: responseData.item_count,
    items: responseData.items.base_runs.map((baseRun) => ({
      id: baseRun.id,
      deadline: new Date(baseRun.deadline),
      createdAt: new Date(baseRun.created_at),
      deliveredAt: baseRun.delivered_at ? new Date(baseRun.delivered_at) : null,
      status: baseRun.status,
      parentId: baseRun.parent_id,
      baseId: baseRun.base_id,
      baseRunVariables: baseRun.base_run_variables.map((baseRunVariable) => ({
        id: baseRunVariable.id,
        baseRunId: baseRunVariable.base_run_id,
        baseVariableId: baseRunVariable.base_variable_id,
        value: baseRunVariable.value,
        baseVariable: {
          id: baseRunVariable.base_variable_id,
          ...responseData.items.base_variables[baseRunVariable.base_variable_id],
          options:
            responseData.items.base_variables[baseRunVariable.base_variable_id].options ?? [],
        },
      })),
      stepRuns: baseRun.step_runs.map((stepRun) => {
        const step = responseData.items.steps[stepRun.step_id]
        const stepTemplate = responseData.items.step_templates[step.step_template_id]
        const assignee = stepRun.assignee_id
          ? responseData.items.assignees[stepRun.assignee_id]
          : null

        return {
          id: stepRun.id,
          stepId: stepRun.step_id,
          snoozeActivityId: stepRun.snooze_activity_id,
          createdAt: new Date(stepRun.created_at),
          startedAt: stepRun.started_at ? new Date(stepRun.started_at) : null,
          stoppedAt: stepRun.stopped_at ? new Date(stepRun.stopped_at) : null,
          baseRunId: stepRun.base_run_id,
          status: stepRun.status,
          assigneeId: stepRun.assignee_id,
          errorMessage: stepRun.error_message,
          updatedAt: new Date(stepRun.updated_at),
          assignee: assignee
            ? {
                id: stepRun.assignee_id as string,
                firstName: assignee.first_name,
                lastName: assignee.last_name,
                name: assignee.name,
                preferredName: assignee.preferred_name,
                image: assignee.image,
                specificRoleId: assignee.specific_role_id,
                offboardedAt: assignee.offboarded_at ? new Date(assignee.offboarded_at) : null,
                status: assignee.status,
                slack: assignee.slack,
                email: assignee.email,
              }
            : null,
          step: {
            id: stepRun.step_id,
            meta: step.meta,
            baseId: step.base_id,
            name: step.name,
            stepTemplateId: step.step_template_id,
            stepTemplate: {
              ...stepTemplate,
              stepTemplateVariables: stepTemplate.step_template_variables,
            },
            stepVariableReferences: step.step_variable_references?.map((stepVariable) => ({
              stepTemplateVariable: {
                key:
                  responseData.items.step_templates[
                    step.step_template_id
                  ].step_template_variables?.find(
                    (variable) => variable.id === stepVariable.step_template_variable_id
                  )?.key ?? '',
              },
              baseVariableId: stepVariable.base_variable_id,
            })),
          },
          snoozeActivites: (stepRun.snooze_activities ?? []).map((snoozeActivity) => ({
            id: snoozeActivity.id,
            snoozedAt: new Date(snoozeActivity.snoozed_at),
            unsnoozedAt: snoozeActivity.unsnoozed_at ? new Date(snoozeActivity.unsnoozed_at) : null,
            active: snoozeActivity.active,
          })),
        }
      }),
      childCounts: baseRun.child_count,
    })),
  }

  return formattedResponse
}

export { useGetBaseRuns }
