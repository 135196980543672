import { WizardReadOnly } from '@invisible/common/components/process-base'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  toGlobalId,
  useReadOnlyStepRunsByIdQuery,
} from '@invisible/concorde/gql-client'
import { ToastProvider } from '@invisible/ui/toasts'
import { cloneDeep } from 'lodash'
import { useContext } from 'react'

const transformValuesFromGlobalId = (obj: Record<string, unknown>, targetKey: string) => {
  for (const key in obj) {
    if (key === targetKey && typeof obj[key] === 'string') {
      obj[key] = fromGlobalId(obj[key] as string)
    }
    if (typeof obj[key] === 'object' && key !== 'wizardConfig') {
      transformValuesFromGlobalId(obj[key] as Record<string, unknown>, targetKey)
    }
  }
}

const ReadOnlyInterface = ({ stepRunId }: { stepRunId: string }) => {
  const { showSnackbar } = useContext(SnackbarContext)

  useReadOnlyStepRunsByIdQuery(
    {
      id: toGlobalId('StepRunType', stepRunId),
    },
    {
      onSuccess: (response) => {
        try {
          const data = cloneDeep(response)
          transformValuesFromGlobalId(data, 'id')

          if (data['stepRuns']['edges'].length === 0) {
            showSnackbar({
              message: `could not find the interface with the step run id: ${stepRunId}`,
              variant: 'error',
            })
            return
          }
          const stepRun = data['stepRuns']['edges'][0]['node']

          // only needed because currently the graphql response and trpc has descrepency
          // will be removed once it's available
          const modifiedStepRun = { ...stepRun } as typeof stepRun & {
            baseRunId: string
            stepId: string
            snoozeActivities: undefined
          }

          modifiedStepRun['baseRunId'] = stepRun['baseRun']['id']
          modifiedStepRun['stepId'] = stepRun['step']['id']
          modifiedStepRun['snoozeActivities'] = undefined
          modifiedStepRun['baseRun']['baseRunVariables'] = modifiedStepRun['baseRun'][
            'baseRunVariables'
          ].map((brv) => ({
            ...brv,
            baseVariableId: brv['baseVariable']['id'],
          }))

          dispatch({
            type: 'openWizardReadOnly',
            stepRun: modifiedStepRun,
            baseRun: modifiedStepRun['baseRun'],
            wizardInitialBRVs: modifiedStepRun['baseRun']['baseRunVariables'],
            stepName: modifiedStepRun['step']['name'],
            wizardData: modifiedStepRun['step']['meta']['wizardConfig'] ?? [],
          })
        } catch {
          showSnackbar({
            message: 'failed to open the interface',
            variant: 'error',
          })
        }
      },
    }
  )

  const { dispatch } = useWizardState()

  return (
    <ToastProvider>
      <WizardReadOnly />
    </ToastProvider>
  )
}

export { ReadOnlyInterface }
