import { zodResolver } from '@hookform/resolvers/zod'
import { SnackbarContext } from '@invisible/common/providers'
import {
  IDataDeliveryExportReviewStatusEnum,
  parseResponse,
  useExportReviewCreateMutation,
  useLastExportEventQuery,
} from '@invisible/concorde/gql-client'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { z } from 'zod'

import { IExportTaskInReview } from '../Activity'
import { DataProfileFailed } from './DataProfileFailed'
import { DataProfileOverview } from './DataProfileOverview'
import { DataProfileRunning } from './DataProfileRunning'

interface IProps {
  exportTask: IExportTaskInReview | null
  setExportTaskId: Dispatch<SetStateAction<IExportTaskInReview | null>>
}

export const PAPER_STYLE = {
  margin: '16px 40px',
  padding: '16px',
  height: '90%',
  maxHeight: '90%',
  overflow: 'auto',
  border: '1px solid #E0E0E0',
  boxShadow: 'none',
}

const formSchema = z
  .object({
    exportTaskId: z.string(),
    exportTaskName: z.string().optional(),
    status: z.nativeEnum(IDataDeliveryExportReviewStatusEnum),
    notes: z.string().optional(),
  })
  .refine(
    (schema) => {
      if (schema.status === IDataDeliveryExportReviewStatusEnum.Rejected) {
        return schema.notes && schema.notes.length > 0
      }
      return true
    },
    {
      message: 'Please provide a reason for rejection.',
      path: ['reviewExportTask'],
    }
  )

type TFormSchema = z.infer<typeof formSchema>

const ReviewExportTaskDialog = ({ exportTask, setExportTaskId }: IProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [reviewNotes, setReviewNotes] = useState('')

  const reactQueryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  })
  const getActionName = () =>
    getValues('status') === IDataDeliveryExportReviewStatusEnum.Approved ? 'approve' : 'reject'

  const currentStatus = getValues('status')

  const { showSnackbar } = useContext(SnackbarContext)

  const { data: lastExportEventData } = useLastExportEventQuery(
    {
      exportTaskId: exportTask?.exportTaskId,
    },
    {
      enabled: !!exportTask?.exportTaskId,
      onError: () => {
        showSnackbar({
          message: 'Unexpected error occurred while fetching export task profiler status.',
          variant: 'error',
        })
      },
    }
  )
  const lastExportEvent = lastExportEventData?.lastExportEvent?.eventType
  const { mutateAsync: createExportReview, isLoading: isExportReviewCreating } =
    useExportReviewCreateMutation({
      onError: () => {
        showSnackbar({
          message: 'Unexpected error occurred while creating export review.',
          variant: 'error',
        })
      },
    })

  const handleConfirmReviewDialogOpen = ({
    status,
  }: {
    status: IDataDeliveryExportReviewStatusEnum
  }) => {
    setValue('exportTaskId', exportTask?.exportTaskId)
    setValue('exportTaskName', exportTask?.exportTaskName)
    setValue('status', status)
    setIsConfirmModalOpen(true)
    handleReviewDialogClose()
  }

  const handleConfirmReviewDialogClose = () => {
    setIsConfirmModalOpen(false)
    reset({ notes: '' })
  }

  const handleReviewDialogClose = () => {
    setExportTaskId(null)
  }

  const handleSave = async (formData: TFormSchema) => {
    const data = {
      exportTaskId: formData.exportTaskId,
      status: formData.status,
      notes: formData.notes,
    }
    const response = await createExportReview({ data })
    parseResponse(response, (_, message) => {
      showSnackbar({
        message,
        variant: 'error',
      })
    })

    handleConfirmReviewDialogClose()
    reactQueryClient.invalidateQueries('ExportTasksWithReviews')
    showSnackbar({
      message: 'Review submitted! Thanks For submitting your review!',
      variant: 'success',
    })
  }

  useEffect(() => {
    if (currentStatus === IDataDeliveryExportReviewStatusEnum.Approved) {
      setReviewNotes('')
    }
  }, [currentStatus])

  return (
    <>
      <Dialog open={!!exportTask} onClose={handleReviewDialogClose} maxWidth='xl' fullWidth>
        <DialogTitle>Review export - {exportTask?.exportTaskName}</DialogTitle>

        <DialogContent sx={{ height: '90vh', px: 0 }}>
          <IconButton
            onClick={handleReviewDialogClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}>
            <CloseIcon />
          </IconButton>

          {lastExportEvent === 'profiler_job_running' ? (
            <DataProfileRunning />
          ) : lastExportEvent === 'profiler_job_failed' ? (
            <DataProfileFailed />
          ) : (
            <DataProfileOverview exportTask={exportTask} />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              mb: 1,
              mr: 1,
              fontWeight: 'normal',
            }}
            variant='contained'
            startIcon={<CloseIcon />}
            onClick={() => {
              handleConfirmReviewDialogOpen({
                status: IDataDeliveryExportReviewStatusEnum.Rejected,
              })
            }}>
            REJECT
          </Button>
          <Button
            sx={{
              mb: 1,
              mr: 1,
              fontWeight: 'normal',
            }}
            variant='contained'
            startIcon={<DoneIcon />}
            onClick={() => {
              handleConfirmReviewDialogOpen({
                status: IDataDeliveryExportReviewStatusEnum.Approved,
              })
            }}>
            APPROVE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmModalOpen} onClose={handleConfirmReviewDialogClose}>
        <DialogTitle>Submit review</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
          <Typography variant='body1'>
            Are you sure you want to <strong>{getActionName()}</strong> the export:{' '}
            <strong>{getValues('exportTaskName')}</strong>?
          </Typography>
          <br />
          <TextField
            multiline
            rows={5}
            label={`Notes ${
              getValues('status') === IDataDeliveryExportReviewStatusEnum.Approved
                ? '(Optional)'
                : ''
            }`}
            size='small'
            fullWidth
            required={getValues('status') === IDataDeliveryExportReviewStatusEnum.Rejected}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mt: '8px' }}
            placeholder={`Please explain your decision to ${getActionName()}...`}
            error={!!errors.reviewExportTask}
            helperText={errors.reviewExportTask && errors.reviewExportTask.message}
            {...register('notes')}
            onChange={(e) => {
              const value = e.target.value
              setReviewNotes(value)
            }}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={handleConfirmReviewDialogClose}>CANCEL</LoadingButton>

          <LoadingButton
            loading={isExportReviewCreating}
            onClick={handleSubmit(handleSave)}
            disabled={
              getValues('status') === IDataDeliveryExportReviewStatusEnum.Rejected &&
              !reviewNotes.trim()
            }>
            CONFIRM & SUBMIT
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { ReviewExportTaskDialog }
