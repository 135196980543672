import { SVGProps } from 'react'
function SkillOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='20'
      viewBox='0 0 33 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.80493 2C6.80493 0.89543 7.70036 0 8.80493 0H11.8049C12.9095 0 13.8049 0.89543 13.8049 2V18C13.8049 19.1046 12.9095 20 11.8049 20H8.80493C7.70036 20 6.80493 19.1046 6.80493 18V2ZM11.8049 2H8.80493V18H11.8049V2Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.8049 2C19.8049 0.89543 20.7004 0 21.8049 0H24.8049C25.9095 0 26.8049 0.89543 26.8049 2V18C26.8049 19.1046 25.9095 20 24.8049 20H21.8049C20.7004 20 19.8049 19.1046 19.8049 18V2ZM24.8049 2H21.8049V18H24.8049V2Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8049 4C24.8049 3.44772 25.2526 3 25.8049 3H28.8049C29.3354 3 29.8441 3.21071 30.2191 3.58579C30.5942 3.96086 30.8049 4.46957 30.8049 5V15C30.8049 15.5304 30.5942 16.0391 30.2191 16.4142C29.8441 16.7893 29.3354 17 28.8049 17H25.8049C25.2526 17 24.8049 16.5523 24.8049 16C24.8049 15.4477 25.2526 15 25.8049 15H28.8049V5H25.8049C25.2526 5 24.8049 4.55228 24.8049 4Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.39072 3.58579C3.76579 3.21071 4.2745 3 4.80493 3H7.80493C8.35722 3 8.80493 3.44772 8.80493 4C8.80493 4.55228 8.35722 5 7.80493 5H4.80493V15H7.80493C8.35722 15 8.80493 15.4477 8.80493 16C8.80493 16.5523 8.35722 17 7.80493 17H4.80493C4.2745 17 3.76579 16.7893 3.39072 16.4142C3.01564 16.0391 2.80493 15.5304 2.80493 15V5C2.80493 4.46957 3.01565 3.96086 3.39072 3.58579Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8049 10C11.8049 9.44771 12.2526 9 12.8049 9H20.8049C21.3572 9 21.8049 9.44771 21.8049 10C21.8049 10.5523 21.3572 11 20.8049 11H12.8049C12.2526 11 11.8049 10.5523 11.8049 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8049 10C28.8049 9.44771 29.2526 9 29.8049 9H31.8049C32.3572 9 32.8049 9.44771 32.8049 10C32.8049 10.5523 32.3572 11 31.8049 11H29.8049C29.2526 11 28.8049 10.5523 28.8049 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.804932 10C0.804932 9.44771 1.25265 9 1.80493 9H3.80493C4.35722 9 4.80493 9.44771 4.80493 10C4.80493 10.5523 4.35722 11 3.80493 11H1.80493C1.25265 11 0.804932 10.5523 0.804932 10Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SkillOutline
