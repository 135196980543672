import * as Popover from '@radix-ui/react-popover'
import type { ReactElement } from 'react'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import type { TagProps } from './ui-tag'
import { Tag } from './ui-tag'

const OVERFLOWID = 'overflow-counter'

type TagsProps = {
  children: ReactElement<TagProps> | ReactElement<TagProps>[]
}

const Tags = ({ children }: TagsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [overflowCount, setOverflowCount] = useState(0)

  const tags = containerRef.current?.children

  const overflowedTags = useMemo(
    () =>
      Array.from(tags ?? [])
        .filter((element) => element.id !== OVERFLOWID)
        .slice(-overflowCount)
        .map((tag) => <div className={tag.className}>{tag.innerHTML}</div>),

    [tags, overflowCount]
  )

  useLayoutEffect(() => {
    const { current } = containerRef

    const trigger = () => {
      const container = containerRef.current
      const elements = container?.children

      let count = 0

      if (elements) {
        Array.from(elements).forEach((element) => {
          if ((element as HTMLDivElement).id === OVERFLOWID) {
            ;(element as HTMLDivElement).style.order = '2'
          } else if (
            (element as HTMLDivElement).offsetLeft + (element as HTMLDivElement).offsetWidth >
            container.offsetWidth + 8
          ) {
            ;(element as HTMLDivElement).style.visibility = 'hidden'
            ;(element as HTMLDivElement).style.order = '3'
            count++
          } else {
            ;(element as HTMLDivElement).style.visibility = 'visible'
            ;(element as HTMLDivElement).style.order = '1'
          }
        })

        setOverflowCount(count)
      }
    }

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current)
      }
      trigger()
    }
  }, [containerRef, overflowCount])

  return (
    <div className='flex w-full'>
      <div ref={containerRef} className='text-paragraphs flex w-full flex-grow gap-2'>
        {children}
        <Popover.Root>
          <Popover.Trigger asChild>
            <div id={OVERFLOWID} className='hover:cursor-pointer'>
              {overflowCount > 0 ? <Tag>+{overflowCount}</Tag> : null}
            </div>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content sideOffset={5}>
              <div className='top-6 flex flex-col gap-2 rounded bg-black/80 py-2 px-3'>
                {overflowedTags}
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </div>
  )
}

export { Tags }
export type { TagsProps }
