import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { palette } from '../palette'

const MuiAlert = {
  defaultProps: {
    iconMapping: {
      success: <CheckCircleOutlineIcon color='success' />,
      error: <ErrorOutlineIcon color='error' />,
    },
  },
  styleOverrides: {
    standardSuccess: {
      border: `1px solid ${palette.success.main}`,
    },
    standardError: {
      backgroundColor: palette.error.light,
    },
  },
}
export default MuiAlert
