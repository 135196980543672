import { map } from 'lodash'
import { entries } from 'lodash/fp'

import { ERROR_KEYS } from './constants'
import type { QaFormData, TBaseRun, TChildBaseRun } from './types'

const formatObject = (data: TBaseRun | TChildBaseRun) => {
  const result: Record<string, any> = {}
  data.baseRunVariables?.forEach((brv) => {
    result[brv.baseVariable.name] = brv.value
    result[`${brv.baseVariable.name} baseRunVariableId`] = brv.id
    result['baseRunId'] = data.id
  })
  return result
}

const completeAuditStep = (
  caseVariable: Record<string, any>,
  data: QaFormData | Partial<QaFormData>
) =>
  map(entries(data), ([key, value]) => ({
    baseRunId: caseVariable['baseRunId'],
    baseRunVariableId: caseVariable[`${ERROR_KEYS[key]} baseRunVariableId`],
    baseVariableId: caseVariable[`${ERROR_KEYS[key]} baseRunVariableId`],
    value: ((k, v: any) => v)(key, value),
  }))

export { completeAuditStep, formatObject }
