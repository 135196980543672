import { SVGProps } from 'react'

function AddToTeamFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='30'
      viewBox='0 0 32 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3939 5.8545C13.3939 2.99033 15.7157 0.668457 18.5799 0.668457C21.4441 0.668457 23.766 2.99033 23.766 5.8545C23.766 8.71868 21.4441 11.0406 18.5799 11.0406C15.7157 11.0406 13.3939 8.71868 13.3939 5.8545ZM7.50015 2.66846C5.01487 2.66846 3.00015 4.68318 3.00015 7.16846C3.00015 9.65374 5.01487 11.6685 7.50015 11.6685C9.98543 11.6685 12.0001 9.65374 12.0001 7.16846C12.0001 4.68318 9.98543 2.66846 7.50015 2.66846ZM11.9271 15.1206C13.1886 13.5385 15.3108 12.3894 18.5799 12.3894C18.8634 12.3894 19.1387 12.398 19.4058 12.4148C20.5067 11.9347 21.7223 11.6685 23 11.6685C27.9706 11.6685 32 15.6979 32 20.6685C32 25.639 27.9706 29.6685 23 29.6685C18.0294 29.6685 14 25.639 14 20.6685H11.4637C10.9372 20.6685 10.5009 20.2603 10.4659 19.735C10.3829 18.4899 10.6998 16.6599 11.9271 15.1206ZM1.88595 15.0709C3.47364 13.5642 6.11645 12.5912 10.262 12.8094C10.6564 12.8301 11.0016 13.0811 11.1431 13.4498C11.2846 13.8185 11.1958 14.236 10.9165 14.5152C10.1381 15.2933 9.59425 16.0318 9.23919 16.8305C8.88604 17.6248 8.69721 18.5294 8.6967 19.6686C8.69645 20.2207 8.24881 20.6681 7.6967 20.6681H1.00929C0.482821 20.6681 0.04652 20.26 0.0115001 19.7347C-0.073928 18.4532 0.305598 16.5706 1.88595 15.0709ZM24 16.6685C24 16.1162 23.5523 15.6685 23 15.6685C22.4477 15.6685 22 16.1162 22 16.6685V19.6685H19C18.4477 19.6685 18 20.1162 18 20.6685C18 21.2207 18.4477 21.6685 19 21.6685H22V24.6685C22 25.2207 22.4477 25.6685 23 25.6685C23.5523 25.6685 24 25.2207 24 24.6685V21.6685H27C27.5523 21.6685 28 21.2207 28 20.6685C28 20.1162 27.5523 19.6685 27 19.6685H24V16.6685Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AddToTeamFilled
