export type LoaderColor = 'void' | 'header'

const ColorStyles = {
  void: 'bg-void',
  header: 'bg-header',
}

const FadeStickLoader = ({ color }: { color: LoaderColor }) => (
  <div className='absolute'>
    <div className='relative top-[5px] left-[5px] h-[15px] w-[15px]'>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute top-[5px] left-0 h-[3.75px] w-[1.75px] animate-[fade_1.2s_0.12s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute top-[3.4067px] left-[3.4067px] h-[3.75px] w-[1.75px] -rotate-45 animate-[fade_1.2s_0.24s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute top-0 left-[5px] h-[3.75px] w-[1.75px] rotate-90 animate-[fade_1.2s_0.36s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute -top-[3.4067px] left-[3.4067px] h-[3.75px] w-[1.75px] rotate-45 animate-[fade_1.2s_0.48s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute -top-[5px] left-0 h-[3.75px] w-[1.75px] animate-[fade_1.2s_0.60s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute -top-[3.4067px] -left-[3.4067px] h-[3.75px] w-[1.75px] -rotate-45 animate-[fade_1.2s_0.72s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute top-0 -left-[5px] h-[3.75px] w-[1.75px] rotate-90 animate-[fade_1.2s_0.84s_infinite] rounded-sm transition ease-in-out`}></div>
      <div
        className={`${ColorStyles[color]} margin-[1px] absolute top-[3.4067px] -left-[3.4067px] h-[3.75px] w-[1.75px] rotate-45 animate-[fade_1.2s_0.96s_infinite] rounded-sm transition ease-in-out`}></div>
    </div>
  </div>
)

const Loaders = {
  FadeStickLoader,
} as const

type LoaderName = keyof typeof Loaders
type Loader = typeof Loaders[LoaderName]

const LoaderNames = Object.keys(Loaders) as LoaderName[]

export type { Loader, LoaderName }

export { FadeStickLoader, LoaderNames, Loaders }
