import { SVGProps } from 'react'
function SaveOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='26'
      viewBox='0 0 27 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.99625 4.33333C8.39795 4.33333 7.91292 4.81836 7.91292 5.41667C7.91292 6.01497 8.39795 6.5 8.99625 6.5H16.5796C17.1779 6.5 17.6629 6.01497 17.6629 5.41667C17.6629 4.81836 17.1779 4.33333 16.5796 4.33333H8.99625Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2978 6.58409e-06C18.5802 -0.000691694 18.8599 0.0541587 19.1211 0.161442C19.383 0.269007 19.6212 0.427168 19.8218 0.626872L19.8237 0.628763L25.7027 6.50774C25.9024 6.70844 26.0606 6.94656 26.1681 7.20845C26.2755 7.46971 26.3303 7.74953 26.3296 8.03197L26.3296 8.03401L26.3296 9.20833V8.03021V8.03197V23.8333C26.3296 24.408 26.1013 24.9591 25.695 25.3654C25.2887 25.7717 24.7376 26 24.1629 26H2.49626C1.92162 26 1.37052 25.7717 0.964192 25.3654C0.557863 24.9591 0.32959 24.408 0.32959 23.8333V2.16667C0.32959 1.59204 0.557864 1.04094 0.964192 0.634609C1.37052 0.22828 1.92162 6.58409e-06 2.49626 6.58409e-06H18.2978ZM18.2975 2.16667L24.1629 8.03207V23.8333H20.9129V16.25C20.9129 15.6754 20.6847 15.1243 20.2783 14.7179C19.872 14.3116 19.3209 14.0833 18.7463 14.0833H7.91292C7.33829 14.0833 6.78719 14.3116 6.38086 14.7179C5.97453 15.1243 5.74626 15.6754 5.74626 16.25V23.8333H2.49626L2.49626 2.16667L18.2975 2.16667ZM18.7463 16.25V23.8333H7.91292V16.25H18.7463Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SaveOutline
