import { UuidSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

const schema = z
  .object({
    percentage: z.number().min(0).max(100),
    condition: z
      .object({
        baseVariableId: UuidSchema,
        values: z
          .array(
            z.object({
              value: z.string(),
              percentage: z.number().min(0).max(100),
            })
          )
          .refine((items) => new Set(items.map((item) => item.value)).size === items.length, {
            message: 'Value must be unique',
          }),
      })
      .optional(),
  })
  .strict()

type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>

const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.partial().strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>

const update = ({ branchStepMeta, data }: { branchStepMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(branchStepMeta, updateArgs)
}

export { create, createArgsSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
