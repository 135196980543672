import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  IGetNextAssignedStepRunQuery,
  IProcessStepRunStatusEnum,
  toGlobalId,
  useGetNextAssignedStepRunQuery,
} from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { useContext, useEffect, useRef, useState } from 'react'
import { useGate } from 'statsig-react'

import { useBaseRunFindById } from './useBaseRunFindById'
import { TBaseRunQueryData } from './useGetBaseRuns'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

const POLLING_PERIOD = 500
const DEFAULT_SECONDS_TO_WAIT = 10
const INITIAL_COUNTER = (1000 * DEFAULT_SECONDS_TO_WAIT) / POLLING_PERIOD

const mapGraphQLData = (
  stepRun: NonNullable<IGetNextAssignedStepRunQuery['getNextAssignedStepRun']>
) => ({
  id: fromGlobalId(stepRun.id),
  status: stepRun.status as IProcessStepRunStatusEnum,
  startedAt: new Date(stepRun.startedAt),
  assignee: stepRun.assignee
    ? {
        id: fromGlobalId(stepRun.assignee.id),
        name: stepRun.assignee.name,
        email: stepRun.assignee.email,
        image: stepRun.assignee.image,
      }
    : null,
  baseRunId: fromGlobalId(stepRun.baseRun.id),
  baseRun: {
    id: fromGlobalId(stepRun.baseRun.id),
    baseId: fromGlobalId(stepRun.baseRun.base.id),
    base: {
      id: fromGlobalId(stepRun.baseRun.base.id),
      baseVariables: stepRun.baseRun.base.baseVariables.map((bv) => ({
        id: fromGlobalId(bv.id),
      })),
    },
    baseRunVariables: stepRun.baseRun.baseRunVariables.map((brv) => ({
      id: fromGlobalId(brv.id),
    })),
  },
  stepId: fromGlobalId(stepRun.step.id),
  step: {
    id: fromGlobalId(stepRun.step.id),
    meta: stepRun.step.meta,
    name: stepRun.step.name,
  },
  lifecycleStageId: stepRun.lifecycleStage ? fromGlobalId(stepRun.lifecycleStage.id) : null,
  wizardInitialBaseRunVariables:
    stepRun?.baseRun?.baseRunVariables?.map((brv) => ({
      id: fromGlobalId(brv.id),
      value: brv.value,
      valueStr: brv.valueStr,
      baseId: fromGlobalId(brv.base.id),
      baseRunId: fromGlobalId(brv.baseRun.id),
      baseVariableId: fromGlobalId(brv.baseVariable.id),
      baseVariable: {
        id: fromGlobalId(brv.baseVariable.id),
        defaultValue: brv.baseVariable.defaultValue,
        description: brv.baseVariable.description,
        hidden: brv.baseVariable.hidden,
        key: brv.baseVariable.key,
        name: brv.baseVariable.name,
        options: brv.baseVariable.options,
        position: brv.baseVariable.position,
        required: brv.baseVariable.required,
        schema: brv.baseVariable.schema,
        stage: brv.baseVariable.stage,
        type: brv.baseVariable.type,
      },
    })) ?? [],
})

const usePollAutoAssignment = (initialStepRun: TStepRun, skipAutoAssign = false) => {
  const nextStepRunRef = useRef<Record<string, any> | undefined>()
  const counterRef = useRef(INITIAL_COUNTER)
  const [stepRun, setStepRun] = useState(initialStepRun)
  const { showSnackbar } = useContext(SnackbarContext)

  useEffect(() => {
    setStepRun(initialStepRun)
  }, [initialStepRun])

  const { data: baseRun } = useBaseRunFindById({
    baseRunId: stepRun.baseRunId,
  })

  const { refetch: refetchTRPC } = useQuery(
    [
      'user.getActiveAssignment',
      {
        assigneeId: stepRun.assignee?.id as string,
        processId: baseRun?.process?.id as string,
      },
    ],
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.id !== stepRun.id) {
          // we have a new assignment
          nextStepRunRef.current = data
        }
      },
    }
  )

  const { refetch: refetchGraphQL } = useGetNextAssignedStepRunQuery(
    {
      processId: toGlobalId('ProcessType', baseRun?.process.id),
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (
          data?.getNextAssignedStepRun?.id &&
          fromGlobalId(data.getNextAssignedStepRun.id) !== stepRun.id
        ) {
          // we have a new assignment
          const assignedStepRun = data.getNextAssignedStepRun

          nextStepRunRef.current = mapGraphQLData(assignedStepRun)
        }
      },
      onError: (error: { message: string }) =>
        showSnackbar({
          message: `Assigning next task failed: ${error.message}`,
          variant: 'error',
        }),
    }
  )

  const { value: isGraphQLEnabled } = useGate('enable-graphql-get-next-assigned-step-run')

  const pollAutoAssignment = async (): Promise<Record<string, any> | void> => {
    if (!baseRun || skipAutoAssign) {
      return Promise.resolve() as unknown as Promise<Record<string, any>>
    }

    const loop = () =>
      new Promise<Record<string, any> | void>((resolve) => {
        if (nextStepRunRef.current) {
          resolve(nextStepRunRef.current)
          nextStepRunRef.current = undefined
          counterRef.current = INITIAL_COUNTER
          return
        }

        if (counterRef.current <= 0) {
          resolve()
          nextStepRunRef.current = undefined
          counterRef.current = INITIAL_COUNTER
          return
        }
        counterRef.current -= 1

        if (isGraphQLEnabled) {
          refetchGraphQL()
        } else {
          refetchTRPC()
        }

        setTimeout(async () => {
          const result = await loop()
          resolve(result)
        }, POLLING_PERIOD)
      })

    return loop()
  }

  return pollAutoAssignment
}

export default usePollAutoAssignment
