import { classNames } from '@invisible/common/helpers'
import {
  TChoiceFreeText,
  TChoiceFreeTextHandler,
  TCommentTree,
  TCommentType,
  TMultiEntry,
  TQuestionAndAnswerTree,
  TQuestionnaireAnswerChangeHandler,
  TQuestionnaireAnswerEntries,
  TResolutions,
  TSingleEntry,
} from '@invisible/common/types'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext, useMutation } from '@invisible/trpc/client'
import { ArrowIndicatorIcon } from '@invisible/ui/icons'
import {
  DATA_PROTECTION_IMPACT_ASSESSMENT,
  PROCESS_SECURITY_MANAGEMENT,
} from '@invisible/ultron/shared'
import { TUuid } from '@invisible/zod'
import { isEmpty, omit } from 'lodash/fp'
import { FC, ReactElement, useRef, useState } from 'react'

import { MultiChoice, SingleChoice } from '../'
import { OpenText } from '../OpenText'
import type { TUser } from '../types'
import { updateAnswerInPlace } from '../utils'
import Comment from './Comment'
import QAControls from './QAControls'
import QAJustification from './QAJustification'
import Questions from './QuestionsQA'

interface IProp {
  question: TQuestionAndAnswerTree
  entries: TQuestionnaireAnswerEntries
  questionnaireRunId: TUuid
  comments: TCommentTree[]
  user: TUser
  touched: string
  isDPOView: boolean
  handleQuestionTouch: (id: TUuid) => void
  answerId: string | undefined
  resolutions: TResolutions
  isDPIAView: boolean
  handleQuestionInOpenModal: (id: TUuid) => void
  questionInOpenModal: TUuid
}

type TValueQA = TQuestionnaireAnswerEntries[string]

// eslint-disable-next-line @typescript-eslint/ban-types
const QuestionQA: FC<IProp> = ({
  question,
  entries,
  questionnaireRunId,
  comments,
  user,
  isDPOView,
  handleQuestionTouch,
  touched,
  answerId,
  resolutions,
  isDPIAView,
  handleQuestionInOpenModal,
  questionInOpenModal,
}) => {
  // Component state, refs, and variables
  const [commentsExpanded, setCommentsExpanded] = useState(false)
  const [resolverReplyTo, setResolverReplyTo] = useState('')
  const [valueQA, setValueQA] = useState<TValueQA | null>(null)

  const questionQARef = useRef<HTMLDivElement>(null)

  const { id, type, parentId, text, required } = question
  const questionnaireId = isDPIAView
    ? DATA_PROTECTION_IMPACT_ASSESSMENT
    : PROCESS_SECURITY_MANAGEMENT

  // Contexts and mutations for creating and updating comments/answers
  const reactQueryContext = useContext()

  const { mutateAsync: createComment } = useMutation('questionnaireComment.createComment', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('questionnaireQuestion.findQuestionsAndAnswers')
    },
  })

  const updateOptimisticallyOnMutation = async () => {
    await reactQueryContext.cancelQuery([
      'questionnaireQuestion.findQuestionsAndAnswers',
      {
        questionnaireRunId,
        questionnaireId,
      },
    ])
    const previousQuestionAndAnswers = reactQueryContext.getQueryData([
      'questionnaireQuestion.findQuestionsAndAnswers',
      {
        questionnaireRunId,
        questionnaireId,
      },
    ])
    if (!previousQuestionAndAnswers) return {}

    reactQueryContext.setQueryData(
      [
        'questionnaireQuestion.findQuestionsAndAnswers',
        {
          questionnaireRunId,
          questionnaireId,
        },
      ],
      updateAnswerInPlace(previousQuestionAndAnswers, question.id, valueQA as TValueQA)
    )

    return { previousQuestionAndAnswers }
  }

  const { mutateAsync: updateAnswer } = useMutation('questionnaireAnswer.update', {
    onMutate: async () => await updateOptimisticallyOnMutation(),

    onSettled: () => {
      reactQueryContext.invalidateQueries('questionnaireQuestion.findQuestionsAndAnswers')
    },
  })

  const { mutateAsync: createAnswerAndHistory } = useMutation(
    'questionnaireAnswer.createOneAndHistory',
    {
      onMutate: async () => await updateOptimisticallyOnMutation(),
      onSettled: () => {
        reactQueryContext.invalidateQueries('questionnaireQuestion.findQuestionsAndAnswers')
      },
    }
  )

  // Event handlers
  const toggleComments = () => setCommentsExpanded(!commentsExpanded)

  const handleChoiceFreeTextChange: TChoiceFreeTextHandler = ({ optionId, value }) => {
    const freeText = valueQA?.freeText || {}
    setValueQA({
      ...valueQA,
      freeText: {
        ...freeText,
        [optionId]: value,
      },
    })
  }

  const handleQAChange: TQuestionnaireAnswerChangeHandler = ({ value, type }) => {
    if (type === 'multi_choice') {
      if (!valueQA) {
        setValueQA({ choices: [value] })
        return
      }

      const choices = valueQA.choices ?? []
      const hasChoice = choices.includes(value)

      if (hasChoice) {
        const oldChoiceFreeText = valueQA.freeText || {}
        const newChoiceFreeText = omit(value, oldChoiceFreeText)
        const values = choices.filter((c) => c !== value)
        setValueQA({
          ...(!isEmpty(newChoiceFreeText) ? { freeText: newChoiceFreeText } : {}),
          choices: values,
        })
        return
      }

      setValueQA({ ...(valueQA ? valueQA : {}), choices: [...choices, value] })
      return
    }

    if (type === 'single_choice') {
      const oldChoiceFreeText = valueQA?.freeText || {}
      const newChoiceFreeText =
        valueQA?.choice !== value ? omit(value, oldChoiceFreeText) : oldChoiceFreeText
      setValueQA({
        ...(!isEmpty(newChoiceFreeText) ? { freeText: newChoiceFreeText } : {}),
        choice: value,
      })
      return
    }

    setValueQA({ text: value })
  }

  let questionComponent: ReactElement | null
  let questionComponentQA: ReactElement | null
  let value: TSingleEntry | TMultiEntry
  let freeText: TChoiceFreeText
  let freeTextQA: TChoiceFreeText

  const goToQA = () => {
    if (questionQARef.current) {
      questionQARef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const computeChoiceFreeText = (isQA?: boolean) => {
    if (!isQA) return entries[id]?.freeText ?? {}
    return valueQA?.freeText ?? {}
  }

  switch (type) {
    case 'single_choice':
      value = entries[id]?.choice ?? ''
      freeText = computeChoiceFreeText()
      freeTextQA = computeChoiceFreeText(true)
      questionComponent = (
        <SingleChoice question={question} choice={value} readOnly={true} freeText={freeText} />
      )
      questionComponentQA = (
        <SingleChoice
          question={question}
          onChange={handleQAChange}
          choice={valueQA?.choice ?? ''}
          freeText={freeTextQA}
          onChoiceFreeTextChange={handleChoiceFreeTextChange}
        />
      )
      break
    case 'multi_choice':
      value = entries[id]?.choices ?? []
      freeText = computeChoiceFreeText()
      freeTextQA = computeChoiceFreeText(true)
      questionComponent = (
        <MultiChoice question={question} choices={value} readOnly={true} freeText={freeText} />
      )
      questionComponentQA = (
        <MultiChoice
          question={question}
          onChange={handleQAChange}
          choices={valueQA?.choices ?? []}
          freeText={freeTextQA}
          onChoiceFreeTextChange={handleChoiceFreeTextChange}
        />
      )
      break
    case 'open':
      value = entries[id]?.text ?? ''
      questionComponent = <OpenText question={question} text={value} readOnly={true} />
      questionComponentQA = (
        <OpenText question={question} text={valueQA?.text ?? ''} onChange={handleQAChange} />
      )
      break
    case 'collection':
      questionComponent = (
        <Questions
          questions={question.children}
          questionnaireRunId={questionnaireRunId}
          isDPOView={isDPOView}
          entries={entries}
          user={user}
          resolutions={resolutions}
          isDPIAView={isDPIAView}
        />
      )
      questionComponentQA = null
      break
    default:
      questionComponent = null
      questionComponentQA = null
  }

  if (!user) return null

  const handleChangeResolver = async (replyToId: string) => {
    goToQA()
    handleQuestionTouch(question.id)
    setResolverReplyTo(replyToId)
  }

  const handleSaveComment = async (comment: string) => {
    const commentPayload = {
      comment,
      questionId: question.id,
      commenterId: user.id,
      questionnaireRunId,
      type: 'resolver' as TCommentType,
      ...(resolverReplyTo.length ? { replyToId: resolverReplyTo } : {}),
    }

    const savedComment = await createComment(commentPayload)

    try {
      if (!valueQA) return
      if (!answerId) {
        await createAnswerAndHistory({
          questionnaireRunId,
          questionId: question.id,
          ...(question.type === 'open' ? { text: valueQA?.text as TSingleEntry } : {}),
          ...(question.type === 'single_choice' ? { choice: valueQA?.choice as TSingleEntry } : {}),
          ...(question.type === 'multi_choice' ? { choices: valueQA?.choices as TMultiEntry } : {}),
          ...(valueQA?.freeText ? { freeText: valueQA.freeText } : {}),
          commentId: savedComment.id,
          userId: user.id,
        })
        setValueQA(null)
        return
      }

      await updateAnswer({
        id: answerId,
        ...(question.type === 'open' ? { text: valueQA as TSingleEntry } : {}),
        ...(question.type === 'single_choice' ? { choice: valueQA?.choice as TSingleEntry } : {}),
        ...(question.type === 'multi_choice' ? { choices: valueQA?.choices as TMultiEntry } : {}),
        ...(valueQA?.freeText ? { freeText: valueQA.freeText } : {}),
        userId: user.id,
        commentId: savedComment.id,
      })
      setValueQA(null)
    } catch (e) {
      sendErrorToSentry(e)
      setValueQA(null)
      throw new Error('welp')
    }
  }

  // Flags for conditional rendering
  const isEditResponseVisible = !comments.length && !question.children.length
  const hasUnresolved = !resolutions[question.id]
  const hasChildren = question.children.length > 0

  return (
    <div
      key={id}
      className={classNames(
        'p-4',
        !parentId ? 'border-1 rounded-lg border-gray-200 bg-white shadow-sm' : '',
        questionInOpenModal && questionInOpenModal !== id
          ? 'blur-xs pointer-events-none blur-sm filter'
          : ''
      )}>
      <div className={classNames('px-2', !parentId ? 'py-2 text-center' : '')} ref={questionQARef}>
        {text}
        {required && <span className='text-red-600'>*</span>}
      </div>
      <div className='p-2'>
        {questionComponent}
        {touched !== question.id ? (
          <QAControls
            commentsExpanded={commentsExpanded}
            toggleComments={toggleComments}
            handleQuestionTouch={handleQuestionTouch}
            questionId={question.id}
            isEditVisible={isEditResponseVisible}
            hasUnresolved={hasUnresolved}
            isDPIAView={isDPIAView}
            hasChildren={hasChildren}
          />
        ) : null}

        {touched === question.id ? (
          <>
            <div className='flex justify-center p-4'>
              <ArrowIndicatorIcon className='-rotate-180' />
            </div>
            {questionComponentQA}
          </>
        ) : null}

        <QAJustification
          question={question}
          touched={touched}
          handleQuestionTouch={handleQuestionTouch}
          onCommentSave={handleSaveComment}
          isDPIAView={isDPIAView}
        />
        {commentsExpanded ? (
          <div>
            {comments.map((comment) => (
              <Comment
                comment={comment}
                key={comment.id}
                question={question}
                isDPOView={isDPOView}
                userId={user.id}
                questionnaireRunId={questionnaireRunId}
                onResolverClick={handleChangeResolver}
                hasUnresolved={hasUnresolved}
                handleQuestionInOpenModal={handleQuestionInOpenModal}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default QuestionQA
