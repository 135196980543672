import { SVGProps } from 'react'
function Boolean(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.8725 1.9682H11.0057C12.0012 1.9682 12.8092 2.77661 12.8092 3.77119C12.8092 4.7669 12.0015 5.57425 11.0057 5.57425H4.8725C4.39851 6.06818 3.73178 6.3756 2.9931 6.3756C1.5546 6.3756 0.388672 5.20975 0.388672 3.77117C0.388672 2.33276 1.55452 1.16675 2.9931 1.16675C3.73172 1.16675 4.39851 1.47427 4.8725 1.9682ZM5.59744 3.77119C5.59744 4.27695 5.4526 4.76051 5.18812 5.17354H11.0057C11.7803 5.17354 12.4086 4.54555 12.4086 3.77119C12.4086 2.99787 11.7799 2.36885 11.0057 2.36885H5.18812C5.45259 2.78196 5.59744 3.26543 5.59744 3.77119ZM0.789461 3.77119C0.789461 4.9885 1.77593 5.97495 2.99322 5.97495C4.21044 5.97495 5.19677 4.98848 5.19677 3.77119C5.19677 2.55391 4.2103 1.56743 2.99322 1.56743C1.77591 1.56743 0.789461 2.55399 0.789461 3.77119ZM9.12706 8.37894C9.60105 7.88501 10.2678 7.57759 11.0065 7.57759C12.445 7.57759 13.6109 8.74344 13.6109 10.182C13.6109 11.6204 12.445 12.7864 11.0065 12.7864C10.2678 12.7864 9.60105 12.479 9.12706 11.9851H2.99363C1.99816 11.9851 1.19008 11.1767 1.19008 10.1821C1.19008 9.18639 1.99785 8.37904 2.99363 8.37904L9.12706 8.37894ZM8.80286 10.182C8.80286 11.3992 9.78933 12.3858 11.0064 12.3858C12.2237 12.3858 13.2102 11.3992 13.2102 10.182C13.2102 8.96469 12.2237 7.97823 11.0064 7.97823C9.78919 7.97823 8.80286 8.96471 8.80286 10.182ZM8.40215 10.182C8.40215 9.67624 8.54699 9.19268 8.81147 8.77965H2.99363C2.21903 8.77965 1.59073 9.40764 1.59073 10.182C1.59073 10.9553 2.21953 11.5843 2.99363 11.5843H8.81147C8.547 11.1712 8.40215 10.6878 8.40215 10.182Z'
        fill='currentColor'
        fillOpacity='1'
        stroke='currentColor'
        strokeOpacity='1'
        strokeWidth='1'
      />
    </svg>
  )
}

export default Boolean
