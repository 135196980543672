import { login } from '@invisible/authentication/internal'
import { LoadingModal } from '@invisible/ui/loading-modal'
import { useEffect } from 'react'

export const SignInPage = ({ redirectTo = '/' }) => {
  useEffect(() => {
    login(redirectTo)
  }, [redirectTo])
  return <LoadingModal text='Signing you in...' />
}

export default SignInPage
