import { SVGProps } from 'react'
function FlagFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.8384 2.35528C30.6215 2.25165 30.3806 2.20896 30.1413 2.23176C29.902 2.25456 29.6734 2.342 29.48 2.48474C24.9034 5.91524 21.0221 4.26472 16.5425 2.3391C12.0629 0.413492 6.79086 -1.85193 1.0175 2.48474C0.856821 2.60532 0.726407 2.76168 0.636585 2.94143C0.546763 3.12118 0.5 3.31939 0.5 3.52036V30.7055C0.5 31.0488 0.636306 31.3781 0.878931 31.6208C1.12156 31.8636 1.45063 32 1.79375 32C2.13687 32 2.46594 31.8636 2.70857 31.6208C2.95119 31.3781 3.0875 31.0488 3.0875 30.7055V23.6017C7.43773 20.6729 11.1896 22.2748 15.5075 24.1357C18.1435 25.2523 20.9736 26.4659 24.0139 26.4659C26.2456 26.4659 28.5905 25.8186 31.0325 23.9739C31.1923 23.8525 31.322 23.6959 31.4117 23.5164C31.5015 23.3368 31.5488 23.139 31.55 22.9383V3.52036C31.548 3.27987 31.4808 3.04443 31.3555 2.83921C31.2301 2.634 31.0514 2.46673 30.8384 2.35528ZM20.5531 23.3105V14.6533C17.4966 13.7795 14.5534 12.0804 11.4969 11.2066V19.88C8.86086 19.2003 6.06312 19.1033 3.0875 20.6081V12.0643C6.04695 10.349 8.81234 10.4461 11.4969 11.2066V3.14818C12.8614 3.59774 14.2003 4.12173 15.5075 4.7178C17.1247 5.39743 18.8066 6.1256 20.5531 6.57869V14.6533C23.2377 15.4138 26.003 15.5109 28.9625 13.7957V22.2748C26.003 24.2652 23.3185 24.1681 20.5531 23.3105Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FlagFilled
