import {
  BaseRunVariableFindManyByBaseRunIdDocument,
  fromGlobalId,
  toGlobalId,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { Prisma } from '@prisma/client'
import { GraphQLClient } from 'graphql-request'
import { useEffect, useState } from 'react'
import { useGate } from 'statsig-react'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type TBaseRunVariable = {
  id: string
  baseRunId: string
  baseId: string
  baseVariableId: string
  createdAt: Date
  updatedAt: Date
  value: Prisma.JsonValue
  valueStr: string | null
}

export type TBaseRunVariables = TBaseRunVariable[]

// TODO: replace with @invisible/graphql-client/fetcher
const fetchBaseRuns = async (baseRunIds: string[]) => {
  const graphqlClient = new GraphQLClient(`${NEXT_PUBLIC_CONCORDE_URL}/graphql`, {
    credentials: 'include',
  })
  const variables = {
    ids: baseRunIds.map((baseRunId) => toGlobalId('BaseRunType', baseRunId)),
  }

  return await graphqlClient.request(BaseRunVariableFindManyByBaseRunIdDocument, variables)
}

const useMappedGraphQLResults = (baseRunIds: string[]) => {
  const [mappedResults, setMappedResults] = useState<TBaseRunVariables>([])

  const fetchData = async () => {
    const chunkArray = []
    for (let i = 0; i < baseRunIds.length; i += 100) {
      chunkArray.push(baseRunIds.slice(i, i + 100))
    }
    try {
      const graphqlResult = await Promise.all(
        chunkArray.map((baseRunIds) => fetchBaseRuns(baseRunIds))
      )

      const mappedGraphqlResult = graphqlResult
        .map((b) => b?.baseRuns?.edges)
        .flat()
        .map((baseRun) => baseRun?.node.baseRunVariables)
        .flat()
        .map((brv) => ({
          id: fromGlobalId(brv?.id),
          baseRunId: fromGlobalId(brv?.baseRun.id),
          baseId: fromGlobalId(brv?.base.id),
          baseVariableId: fromGlobalId(brv?.baseVariable.id),
          createdAt: new Date(brv?.createdAt),
          updatedAt: new Date(brv?.updatedAt),
          value: brv?.value as Prisma.JsonValue,
          valueStr: brv?.valueStr as string | null,
        }))

      setMappedResults(mappedGraphqlResult)
    } catch (error) {
      logger.error('Error fetching data:', { error })
    }
  }

  useEffect(() => {
    fetchData()
  }, [baseRunIds.length])

  return mappedResults
}

const useBaseRunVariableFindManyByBaseRunId = ({ baseRunIds }: { baseRunIds: string[] }) => {
  const { value: isGraphQLEnabled } = useGate(
    'enable-graphql-base-run-variables-find-by-base-run-ids'
  )

  const [data, setData] = useState<TBaseRunVariables>([])
  const { data: trpcResult } = useTrpcQuery(
    ['baseRunVariable.findManyByBaseRunId', { baseRunIds }],
    { enabled: !isGraphQLEnabled }
  )
  const mappedGraphqlResult = useMappedGraphQLResults(baseRunIds)
  const newData = isGraphQLEnabled ? mappedGraphqlResult : trpcResult

  useEffect(() => {
    if (newData) {
      setData(newData)
    }
  }, [baseRunIds])

  return data
}

export default useBaseRunVariableFindManyByBaseRunId

export { useBaseRunVariableFindManyByBaseRunId }
