import { ComponentType, ReactNode } from 'react'

import { ErrorBoundary } from './ErrorBoundary'
import { WithErrorBoundaryOptions } from './types'

/**
 * `withErrorBoundary` HOC: Enhances a component with error-handling capabilities.
 *
 * @param {ComponentType<T>} WrappedComponent - Component to enhance.
 * @param {WithErrorBoundaryOptions?} options - Configuration for ErrorBoundary.
 *   - fallback? {ComponentType<FallbackProps> | ReactNode} - Displayed on error (default is the DefaultFallback component).
 *   - onError? {(error: Error, info: { componentStack: string })} - Callback on error (default does nothing).
 *
 * @example
 * ```tsx
 * const CouldBreak = () => {
 *   return <div>Invisible</div>
 * };
 *
 * const ErrorFallback = ({ error }) => <div>{error.message}</div>;
 * const WrappedComponent = withErrorBoundary(CouldBreak, { fallback: ErrorFallback });
 * // Usage
 * <WrappedComponent />
 * ```
 */

const withErrorBoundary =
  <T,>(WrappedComponent: ComponentType<T>, options?: WithErrorBoundaryOptions) =>
  ({ children, ...props }: T & { children?: ReactNode }) =>
    (
      <ErrorBoundary fallback={options?.fallback} onError={options?.onError}>
        <WrappedComponent {...(props as T)}>{children}</WrappedComponent>
      </ErrorBoundary>
    )

export { withErrorBoundary }
