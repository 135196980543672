import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { Modal } from '@invisible/ui/modal'
import { NullSwitch } from '@invisible/ui/null-switch'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { isEqual, isNil } from 'lodash/fp'
import { FC, useEffect, useMemo, useReducer } from 'react'

import { FieldRenderers } from './fieldRenderers'
import { stateReducer } from './reducer'
import tools, { TProperties, TTools, TType } from './tools'

export type TToolNames = TTools[number]['tool_name']
export type TActions = TTools[number]['actions']
export type TParameters = TActions[number]['parameters']

interface IProps {
  lastStagedAction:
    | { action: string; tool: string }
    | {
        action: string
        tool: string
        requestValues: Record<string, unknown> | null
        responseValues: Record<string, unknown> | null
        schemaVersion: string
        lastUpdated: string
        requiresConfirmation: boolean
      }[]
    | null
  onClose: () => void
  onSave: (arg: {
    botToToolRequest: Record<string, unknown>
    toolToBotResponse: Record<string, unknown> | string
    schemaVersion?: string
    lastUpdated?: string
    stagedAction?: { action: string; tool: string } | null
  }) => Promise<void>
  toolsList: string[]
  messages: {
    id: string
    type: string
    index: number
    text: string
  }[]
  handleMultiToolSubmit: (arg: {
    botToToolRequests?: {
      value: Record<string, unknown>
      lastUpdated?: string
      schemaVersion?: string
    }[]
    toolToBotResponses?: {
      value: Record<string, unknown>
      lastUpdated?: string
      schemaVersion?: string
    }[]
    stagedActions?:
      | {
          action: string
          tool: string
          requestValues: Record<string, unknown> | null
          responseValues: Record<string, unknown> | null
          schemaVersion: string
          lastUpdated: string
          requiresConfirmation: boolean
        }[]
      | null
  }) => Promise<void>
  allowMultiActionSelection?: boolean
}

const INITIATE_STAGING_RESPONSE = { status: 'staged' }
const CLEAR_STAGING_REQUEST = {
  tool: 'Staging',
  action: 'clear',
  arguments: {},
}
const CLEAR_STAGING_RESPONSE = { status: 'cleared' }
const EXECUTE_STAGING_REQUEST = {
  tool: 'Staging',
  action: 'execute',
  arguments: {},
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ToolSelector: FC<IProps> = ({
  onClose,
  onSave,
  lastStagedAction,
  toolsList,
  messages,
  handleMultiToolSubmit,
  allowMultiActionSelection,
}) => {
  const [
    {
      isMultiActionMode,
      currentActionType,
      dataList,
      selectedToolName,
      selectedAction,
      activeTab,
      selectedResponseCode,
      isActionConfirmed,
      requestValues,
      responseValues,
      currentResponseIndex,
    },
    dispatch,
  ] = useReducer(stateReducer, {
    isMultiActionMode: Array.isArray(lastStagedAction),
    currentActionType: Array.isArray(lastStagedAction)
      ? lastStagedAction[0]?.requiresConfirmation ?? null
      : null,
    dataList: Array.isArray(lastStagedAction) ? lastStagedAction : [],
    currentResponseIndex: 0,
    selectedToolName: !Array.isArray(lastStagedAction) ? lastStagedAction?.tool ?? null : null,
    selectedAction: !Array.isArray(lastStagedAction)
      ? tools
          .find((tool) => tool.tool_name === lastStagedAction?.tool)
          ?.actions.find((action) => action.action_name === lastStagedAction?.action) ?? null
      : null,
    activeTab: lastStagedAction ? 'Response' : 'Request',
    selectedResponseCode: null,
    isActionConfirmed: false,
    requestValues: null,
    responseValues: {},
  })

  const toolActions = useMemo(() => {
    if (!selectedToolName) return null
    const tool = tools.find((tool) => tool.tool_name === selectedToolName)
    const actions = tool?.actions as typeof tools[number]['actions']
    if (currentActionType !== null)
      return actions.filter((action) => action.requires_confirmation === currentActionType)
    return actions
  }, [currentActionType, selectedToolName])

  const toolToBotResponses = useMemo(
    () =>
      messages
        .sort((a, b) => a.index - b.index)
        // Recompute indexes to ensure they are accurate
        .map((message, i) => ({ ...message, index: i + 1 }))
        .filter(
          (message) =>
            message.type === 'tool_to_bot_response' &&
            // Exclude {status: "staged"} and {status: "cleared"}
            !isEqual(Object.keys(message.text), ['status'])
        ),
    [messages]
  )

  const selectedResponse = selectedResponseCode
    ? selectedAction?.response_codes?.[selectedResponseCode]
    : null

  // Switching to the response is disabled until all required request parameters are filled
  const isResponseEnabled = useMemo(() => {
    if (!selectedAction) return false

    const parameters: TParameters = selectedAction.parameters
    return parameters
      .filter((p) => p.required)
      .every(
        (p) => !isNil(requestValues?.[p.parameter_name]) && requestValues?.[p.parameter_name] !== ''
      )
  }, [selectedAction, requestValues])

  const selectedTool = useMemo(
    () => tools.find((tool) => tool.tool_name === selectedToolName),
    [selectedToolName]
  )

  // Submit data after all responses are filled for multi-action
  useEffect(() => {
    const submitData = async () => {
      await handleMultiToolSubmit({
        botToToolRequests: currentActionType
          ? [{ value: EXECUTE_STAGING_REQUEST }]
          : dataList.map((data) => ({
              value: {
                tool: data.tool,
                action: data.action,
                arguments: data.requestValues ?? {},
              },
              lastUpdated: data.lastUpdated,
              schemaVersion: data.schemaVersion,
            })),
        toolToBotResponses: dataList.map((data) => ({
          value: data.responseValues ?? {},
          lastUpdated: data.lastUpdated,
          schemaVersion: data.schemaVersion,
        })),
      })
    }
    if (currentResponseIndex !== 0 && currentResponseIndex === dataList.length) {
      submitData()
      dispatch({ type: 'SET_CURRENT_RESPONSE_INDEX', payload: -1 })
    }
  }, [currentResponseIndex, dataList, handleMultiToolSubmit, currentActionType])

  return (
    <Modal title='Bot to Tool Request' width={700} onClose={onClose}>
      {lastStagedAction && !isActionConfirmed ? (
        <div>
          <div>
            You currently have{' '}
            {!Array.isArray(lastStagedAction) ? (
              <span>
                {lastStagedAction.tool}.{lastStagedAction.action}
              </span>
            ) : (
              <ul>
                {lastStagedAction.map((action) => (
                  <li className='whitespace-normal break-words pb-2 font-mono'>
                    {JSON.stringify(action)}
                  </li>
                ))}
              </ul>
            )}{' '}
            staged. Would you like to clear it or execute?
          </div>
          <div className='mt-4 flex items-center gap-4'>
            <Button
              variant='danger'
              onClick={() =>
                onSave({
                  botToToolRequest: CLEAR_STAGING_REQUEST,
                  toolToBotResponse: CLEAR_STAGING_RESPONSE,
                  stagedAction: null,
                })
              }>
              Clear
            </Button>
            <Button
              variant='secondary'
              onClick={() => dispatch({ type: 'SET_IS_ACTION_CONFIRMED', payload: true })}>
              Execute
            </Button>
          </div>
        </div>
      ) : (
        <div className='box-border h-[70vh] overflow-auto'>
          {allowMultiActionSelection ? (
            <div className='flex items-center justify-end gap-2'>
              <div>Single Action</div>
              <NullSwitch
                isOn={isMultiActionMode}
                onToggle={(checked) =>
                  dispatch({ type: 'SET_MULTIACTION_MODE', payload: checked ?? false })
                }
              />
              <div>Multi Action</div>
            </div>
          ) : null}

          <Tabs
            value={activeTab}
            onChange={(tab) =>
              dispatch({ type: 'SET_ACTIVE_TAB', payload: tab as 'Request' | 'Response' })
            }>
            {/* Request tab is disabled if there's a staged action */}
            <Tab
              name='Request'
              value='Request'
              disabled={Boolean(lastStagedAction) || isMultiActionMode}>
              <div className='mt-4 mb-2'>Tool:</div>
              <Dropdown
                options={(toolsList.length > 0
                  ? toolsList
                  : tools.map((tool) => tool.tool_name)
                ).map((tool) => ({
                  key: tool,
                  value: tool,
                }))}
                maxHeight='200px'
                selectedKey={selectedToolName}
                width='100%'
                onChange={({ key }) =>
                  dispatch({ type: 'SELECT_TOOL', payload: key as TToolNames })
                }
              />
              {toolActions ? (
                <>
                  <div className='mt-4 mb-2'>Action:</div>
                  <Dropdown
                    options={toolActions.map((action) => ({
                      key: action.action_name,
                      value: action.action_name,
                    }))}
                    maxHeight='200px'
                    selectedKey={selectedAction?.action_name}
                    width='100%'
                    key={selectedToolName}
                    onChange={({ key }) => {
                      const action = toolActions.find((a) => a.action_name === key)
                      dispatch({ type: 'SELECT_ACTION', payload: action as TActions[number] })
                    }}
                  />

                  {requestValues &&
                    selectedAction?.parameters.map((parameter, index) => {
                      const Field = FieldRenderers?.[parameter.type] ?? FieldRenderers?.['string']
                      const enumValues = parameter?.enum
                      if (!Field) return null

                      return (
                        <div key={index + parameter.parameter_name}>
                          <div className='mt-4'>
                            {parameter.parameter_name} {parameter.required ? '*' : ''}
                          </div>
                          <div className='mb-2 text-xs text-gray-400'>{parameter.description}</div>
                          <Field
                            value={requestValues[parameter.parameter_name]}
                            handleChange={(value) =>
                              dispatch({
                                type: 'SET_REQUEST_VALUES',
                                payload: {
                                  [parameter.parameter_name]: value,
                                },
                              })
                            }
                            schema={parameter as unknown as TProperties['key']}
                            enumValues={enumValues}
                            arrayType={parameter.items?.type}
                            toolToBotResponses={toolToBotResponses}
                          />
                        </div>
                      )
                    })}
                </>
              ) : null}

              {!isMultiActionMode && selectedAction?.requires_confirmation ? (
                <div className='mt-4 flex justify-center'>
                  <Button
                    disabled={!isResponseEnabled}
                    onClick={() => {
                      onSave({
                        botToToolRequest: {
                          tool: selectedToolName,
                          action: selectedAction?.action_name,
                          arguments: Object.entries(requestValues ?? {}).reduce(
                            (acc, [key, value]) => {
                              if (value !== null) {
                                acc[key] = value
                              }
                              return acc
                            },
                            {} as Record<string, unknown>
                          ),
                        },
                        schemaVersion: selectedTool?.schema_version,
                        lastUpdated: selectedTool?.last_update,
                        toolToBotResponse: INITIATE_STAGING_RESPONSE,
                        stagedAction: {
                          tool: selectedToolName ?? '',
                          action: selectedAction.action_name,
                        },
                      })
                    }}>
                    Perform Action
                  </Button>
                </div>
              ) : null}

              {isMultiActionMode ? (
                <>
                  <div className='mt-4 flex justify-center'>
                    <Button
                      disabled={!isResponseEnabled}
                      onClick={() =>
                        dispatch({
                          type: 'ADD_DATA',
                          payload: {
                            action: selectedAction?.action_name ?? '',
                            tool: selectedToolName ?? '',
                            requestValues,
                            requiresConfirmation: selectedAction?.requires_confirmation ?? false,
                            schemaVersion: selectedTool?.schema_version ?? '',
                            lastUpdated: selectedTool?.last_update ?? '',
                          },
                        })
                      }>
                      Append
                    </Button>
                  </div>

                  {dataList.length ? (
                    <>
                      <div>Actions to execute:</div>
                      <ul>
                        {dataList.map((data) => (
                          <li className='whitespace-normal break-words pb-2 font-mono'>
                            {JSON.stringify(data)}
                          </li>
                        ))}
                      </ul>
                      <div className='flex justify-center'>
                        <Button
                          disabled={!dataList.length}
                          onClick={() => {
                            if (!currentActionType) {
                              dispatch({ type: 'SET_ACTIVE_TAB', payload: 'Response' })
                              return
                            }
                            handleMultiToolSubmit({
                              botToToolRequests: dataList.map((data) => ({
                                value: {
                                  tool: data.tool,
                                  action: data.action,
                                  arguments: data.requestValues ?? {},
                                },
                                lastUpdated: data.lastUpdated,
                                schemaVersion: data.schemaVersion,
                              })),
                              toolToBotResponses: [{ value: INITIATE_STAGING_RESPONSE }],
                              stagedActions: dataList.map((data) => ({
                                action: data.action,
                                tool: data.tool,
                                requestValues: data.requestValues ?? {},
                                responseValues: {},
                                schemaVersion: data.schemaVersion,
                                lastUpdated: data.lastUpdated,
                                requiresConfirmation: currentActionType,
                              })),
                            })
                          }}>
                          Execute Actions
                        </Button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </Tab>
            <Tab
              name='Response'
              value='Response'
              disabled={
                !isResponseEnabled ||
                (!lastStagedAction && selectedAction?.requires_confirmation) ||
                isMultiActionMode
              }>
              {isMultiActionMode ? (
                <>
                  <div className='flex justify-end pt-2 font-bold'>
                    {currentResponseIndex + 1} out of {dataList.length}
                  </div>

                  <div className='whitespace-normal break-words font-mono'>
                    {JSON.stringify(dataList[currentResponseIndex], null, 2)}
                  </div>
                </>
              ) : null}
              <div>
                <div className='mt-4 mb-2'>Responses</div>
                <Dropdown
                  options={Object.keys(selectedAction?.response_codes ?? []).map((code) => ({
                    key: code,
                    value: code,
                  }))}
                  maxHeight='200px'
                  selectedKey={selectedResponseCode}
                  width='100%'
                  onChange={({ key }) => dispatch({ type: 'SELECT_RESPONSE_CODE', payload: key })}
                />

                {selectedAction && selectedResponse
                  ? Object.entries(selectedResponse.schema.properties ?? {}).map(
                      ([propertyName, property]) => {
                        const propertyType =
                          property.format ??
                          (!Array.isArray(property.type)
                            ? property.type
                            : (property.type.find((t) => t !== 'null') as TType))
                        if (!propertyType) return null

                        const Field = FieldRenderers?.[propertyType] ?? FieldRenderers?.['string']
                        if (!Field) return null

                        return (
                          <div key={propertyName}>
                            <div className='mt-4 mb-2'>
                              {propertyName}{' '}
                              {selectedResponse.schema.required?.includes(propertyName) ? '*' : ''}
                            </div>
                            <Field
                              value={responseValues?.[propertyName]}
                              schema={property}
                              arrayType={property.items?.type}
                              handleChange={(value) =>
                                dispatch({
                                  type: 'SET_RESPONSE_VALUES',
                                  payload: {
                                    [propertyName]: value,
                                  },
                                })
                              }
                              toolToBotResponses={toolToBotResponses}
                            />
                          </div>
                        )
                      }
                    )
                  : null}
                <div className='mt-4 flex justify-center'>
                  <Button
                    disabled={selectedResponse?.schema.required?.some(
                      (key) => isNil(responseValues[key]) || responseValues[key] === ''
                    )}
                    onClick={async () => {
                      if (isMultiActionMode) {
                        dispatch({ type: 'ADVANCE_RESPONSE' })
                      } else if (selectedAction?.requires_confirmation) {
                        onSave({
                          botToToolRequest: EXECUTE_STAGING_REQUEST,
                          schemaVersion: selectedTool?.schema_version,
                          lastUpdated: selectedTool?.last_update,
                          toolToBotResponse: responseValues,
                        })
                      } else {
                        onSave({
                          botToToolRequest: {
                            tool: selectedToolName,
                            action: selectedAction?.action_name,
                            arguments: Object.entries(requestValues ?? {}).reduce(
                              (acc, [key, value]) => {
                                if (value !== null) {
                                  acc[key] = value
                                }
                                return acc
                              },
                              {} as Record<string, unknown>
                            ),
                          },
                          schemaVersion: selectedTool?.schema_version,
                          lastUpdated: selectedTool?.last_update,
                          toolToBotResponse: responseValues,
                        })
                      }
                    }}>
                    Save
                  </Button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </Modal>
  )
}

export { ToolSelector }
