import { useAbilityContext } from '@invisible/authorization/client'
import { useStore } from '@invisible/common/stores/process-store'
import {
  getErrorMessage,
  toGlobalId,
  useBaseRunsCancelMutation,
} from '@invisible/concorde/gql-client'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { SmallCheckbox } from '@invisible/ui/form'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { CloseIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { BulkActionButton, TableStyles } from '@invisible/ui/table'
import { useToasts } from '@invisible/ui/toasts'
import { isEqual } from 'lodash/fp'
import { unique } from 'radash'
import { FC, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { useBaseRunsDelete } from '../hooks/useBaseRunsDelete'
import { UnpauseModal } from './UnpauseModal'

const BulkActionPopUp = () => {
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const [Can] = useAbilityContext()
  const { addToast } = useToasts()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false)

  const { mutateAsync: cancelBaseRuns } = useBaseRunsCancelMutation({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onError: (error) => {
      addToast(`Cancel failed: ${getErrorMessage(error)}!`, {
        appearance: 'error',
      })
    },
  })

  const { mutateAsync: deleteBaseRuns, isLoading: isDeleteBaseRunsLoading } = useBaseRunsDelete({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onError: (error) => {
      addToast(`Cancel failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
  })

  const { selectedBaseRuns, setSelectedBaseRuns } = useStore(
    useCallback(
      (state) => ({
        selectedBaseRuns: state.selectedBaseRuns,
        setSelectedBaseRuns: state.setSelectedBaseRuns,
      }),
      []
    ),
    shallow
  )

  const handleCancelBaseRuns = () => {
    const ids = selectedBaseRuns.map((id) => toGlobalId('BaseRunType', id))
    cancelBaseRuns({ ids })
    setIsCancelModalOpen(false)
    setSelectedBaseRuns([])
  }

  const handleDeleteBaseRuns = async () => {
    await deleteBaseRuns({ baseRunIds: selectedBaseRuns })
    setIsDeleteModalOpen(false)
    setSelectedBaseRuns([])
  }

  useEffect(() => {
    setSelectedBaseRuns([])
    setIsCancelModalOpen(false)
  }, [baseViewId, setSelectedBaseRuns])

  return (
    <div className='relative font-normal normal-case tracking-normal'>
      {selectedBaseRuns.length ? (
        <TableStyles.BulkActionContainer>
          <TableStyles.SelectCounter
            count={selectedBaseRuns.length}
            singular='Item'
            plural='Items'
          />
          <BulkActionButton
            title='Cancel Baseruns'
            onClick={() => setIsCancelModalOpen(true)}
            icon='CancelBaseRunOutlineIcon'
          />

          <Can I='delete' a='BaseRun'>
            <BulkActionButton
              title='Delete Baseruns'
              onClick={() => setIsDeleteModalOpen(true)}
              icon='DeleteOutlineIcon'
            />
          </Can>

          <BulkActionButton
            title='Unpause Baseruns'
            onClick={() => setIsUnpauseModalOpen(true)}
            icon='PlayIcon'
          />

          <TableStyles.CloseBulkAction>
            <div
              className='hover:bg-weak-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded'
              onClick={() => {
                setSelectedBaseRuns([])
              }}
              title='Unselect'>
              <CloseIcon width={16} height={16} />
            </div>
          </TableStyles.CloseBulkAction>

          <Modal
            icon='CircledExclamationIcon'
            visible={isCancelModalOpen}
            title={`Cancel ${selectedBaseRuns.length} Baseruns?`}
            onClose={() => setIsCancelModalOpen(false)}
            primaryButton={
              <Button variant='danger' size='md' onClick={handleCancelBaseRuns}>
                Cancel
              </Button>
            }
            secondaryButton={
              <Button variant='secondary' size='md' onClick={() => setIsCancelModalOpen(false)}>
                Nevermind
              </Button>
            }>
            <div className='text-paragraphs'>
              Are you sure you want to cancel these base runs and all their children?
            </div>
          </Modal>
          <Modal
            icon='CircledExclamationIcon'
            visible={isDeleteModalOpen}
            title={`Delete ${selectedBaseRuns.length} Baseruns?`}
            onClose={() => setIsDeleteModalOpen(false)}
            primaryButton={
              <Button
                variant='danger'
                size='md'
                onClick={handleDeleteBaseRuns}
                loading={isDeleteBaseRunsLoading}>
                Delete
              </Button>
            }
            secondaryButton={
              <Button variant='secondary' size='md' onClick={() => setIsDeleteModalOpen(false)}>
                Nevermind
              </Button>
            }>
            <div className='text-paragraphs'>
              Are you sure you want to delete these base runs and all their children? This action
              can't be reversed
            </div>
          </Modal>
          <UnpauseModal
            visible={isUnpauseModalOpen}
            onCloseModal={() => setIsUnpauseModalOpen(false)}
          />
        </TableStyles.BulkActionContainer>
      ) : null}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderRowSelector: FC<{ allBaseRunIds: string[] }> = ({ allBaseRunIds }) => {
  const { selectedBaseRuns, setSelectedBaseRuns } = useStore(
    useCallback(
      (state) => ({
        selectedBaseRuns: state.selectedBaseRuns,
        setSelectedBaseRuns: state.setSelectedBaseRuns,
      }),
      []
    ),
    shallow
  )

  const handleOnClick = (value: boolean) => {
    if (value) {
      return setSelectedBaseRuns(allBaseRunIds)
    }
    return setSelectedBaseRuns([])
  }

  return (
    <div>
      <SmallCheckbox
        checked={
          isEqual(selectedBaseRuns?.sort(), allBaseRunIds.sort()) &&
          Boolean(selectedBaseRuns.length)
        }
        onClick={handleOnClick}
      />
      <BulkActionPopUp />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RowSelector: FC<{ baseRunId: string }> = ({ baseRunId }) => {
  const { selectedBaseRuns, setSelectedBaseRuns } = useStore(
    useCallback(
      (state) => ({
        selectedBaseRuns: state.selectedBaseRuns,
        setSelectedBaseRuns: state.setSelectedBaseRuns,
      }),
      []
    ),
    shallow
  )

  const handleOnClick = (value: boolean) => {
    if (value) {
      return setSelectedBaseRuns(unique([...selectedBaseRuns, baseRunId], (id) => id))
    }
    return setSelectedBaseRuns(selectedBaseRuns.filter((id) => id !== baseRunId))
  }
  return <SmallCheckbox checked={selectedBaseRuns?.includes(baseRunId)} onClick={handleOnClick} />
}

export { BulkActionPopUp, HeaderRowSelector, RowSelector }
