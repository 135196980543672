import { SVGProps } from 'react'

function GetTaskFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.6933 7.14667H29.2489C31.2163 7.14667 32.8044 8.74667 32.8044 10.7022V28.4444C32.8044 30.4119 31.2163 32 29.2489 32H11.5067C9.55111 32 7.95111 30.4119 7.95111 28.4444V24.8889H4.36C2.39259 24.8889 0.804443 23.3007 0.804443 21.3333V3.55556C0.804443 1.58815 2.39259 0 4.36 0H22.1378C24.1052 0 25.6933 1.58815 25.6933 3.55556V7.14667ZM29.2489 29.6296C29.9007 29.6296 30.4341 29.0963 30.4341 28.4444V10.7022C30.4341 10.0504 29.9007 9.51704 29.2489 9.51704H25.6933V21.3333C25.6933 23.3007 24.1052 24.8889 22.1378 24.8889H10.3215V28.4444C10.3215 29.0963 10.8548 29.6296 11.5067 29.6296H29.2489ZM14.8043 11.1429H17.6615C18.4615 11.1429 19.09 11.7714 19.09 12.5714C19.09 13.3714 18.4615 14 17.6615 14H14.8043V16.8572C14.8043 17.6572 14.1758 18.2857 13.3758 18.2857C12.5758 18.2857 11.9472 17.6572 11.9472 16.8572V14H9.09005C8.29005 14 7.66147 13.3714 7.66147 12.5714C7.66147 11.7714 8.29005 11.1429 9.09005 11.1429H11.9472V8.28573C11.9472 7.94288 12.0615 7.62859 12.2329 7.40002C12.5186 7.05716 12.9186 6.85716 13.3758 6.85716C14.1758 6.85716 14.8043 7.48573 14.8043 8.28573V11.1429Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GetTaskFilled
