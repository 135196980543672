import { SVGProps } from 'react'

function ArrowIndicator(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={8}
      fill='none'
      viewBox='0 0 12 8'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.669 6.723L6.618.866a.385.385 0 00-.57 0L.999 6.723c-.188.219-.018.539.285.539h10.101c.303 0 .473-.32.285-.539z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowIndicator
