import { SVGProps } from 'react'
function SortRemoveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='18'
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 9C0.355957 8.44772 0.803672 8 1.35596 8H10.356C10.9082 8 11.356 8.44772 11.356 9C11.356 9.55228 10.9082 10 10.356 10H1.35596C0.803672 10 0.355957 9.55228 0.355957 9Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 1C0.355957 0.447715 0.803672 1.49012e-08 1.35596 1.49012e-08H8.35596C8.90824 1.49012e-08 9.35596 0.447715 9.35596 1C9.35596 1.55228 8.90824 2 8.35596 2H1.35596C0.803672 2 0.355957 1.55228 0.355957 1Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 17C0.355957 16.4477 0.803672 16 1.35596 16H18.356C18.9082 16 19.356 16.4477 19.356 17C19.356 17.5523 18.9082 18 18.356 18H1.35596C0.803672 18 0.355957 17.5523 0.355957 17Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0631 0.292893C24.4536 0.683417 24.4536 1.31658 24.0631 1.70711L15.0631 10.7071C14.6725 11.0976 14.0394 11.0976 13.6489 10.7071C13.2583 10.3166 13.2583 9.68342 13.6489 9.29289L22.6488 0.292893C23.0394 -0.0976311 23.6725 -0.0976311 24.0631 0.292893Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6489 0.292893C14.0394 -0.0976311 14.6725 -0.0976311 15.0631 0.292893L24.0631 9.29289C24.4536 9.68342 24.4536 10.3166 24.0631 10.7071C23.6725 11.0976 23.0394 11.0976 22.6488 10.7071L13.6489 1.70711C13.2583 1.31658 13.2583 0.683417 13.6489 0.292893Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SortRemoveIcon
