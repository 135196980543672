import { MultiLineInputCell } from '@invisible/ui/react-table'
import { Table } from '@invisible/ui/table'
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { isArray, isPlainObject, isUndefined } from 'lodash/fp'
import { memo } from 'react'

import { DEFAULT_ITEMS_PER_PAGE } from '../../common/constants'
import { getCustomCell } from '../../customCells/GetCustomCell'
import { RowActionCell } from './RowActionCell'

const columnHelper = createColumnHelper<Record<string, any>>()
const MultiLineInputMemoized = memo(MultiLineInputCell)

export interface TModel extends Record<string, unknown> {
  [key: string]: any
}

type ICustomCellProps = CellContext<Record<string, any>, any>

export const baseRunIdHook = (
  columns: ColumnDef<TModel, unknown>[]
): ColumnDef<TModel, unknown>[] => [
  columnHelper.accessor('id', {
    header: () => (
      <div
        className='text-theme-main flex px-3 text-left text-xs font-bold uppercase tracking-wide'
        style={{ width: '40px' }}>
        ID
      </div>
    ),
    cell: (info: ICustomCellProps) => (
      <div style={{ width: '200px' }}>{info.getValue()?.actualValue}</div>
    ),
  }),
  ...columns,
]

export const rowNumbersHook =
  (opts?: { disableDelete?: boolean; stepRunId: string }) =>
  (columns: ColumnDef<TModel, unknown>[]): ColumnDef<TModel, unknown>[] =>
    [
      columnHelper.accessor('rowNumbers', {
        header: () => (
          <div
            className='text-theme-main flex px-3 text-left text-xs font-bold uppercase tracking-wide'
            style={{ width: '40px' }}>
            #
          </div>
        ),
        cell: (info) => {
          const { index, original } = info.row
          return (
            <Table.Cell justifyCenter>
              <RowActionCell
                disableDelete={opts?.disableDelete}
                baseRunId={Object.values(original)?.[0]?.baseRunId as string}
                stepRunId={opts?.stepRunId as string}>
                {((info.table.options.meta?.currentPage ?? 1) - 1) *
                  (info.table.options.meta?.pageSize ?? DEFAULT_ITEMS_PER_PAGE) +
                  (index + 1)}
              </RowActionCell>
            </Table.Cell>
          )
        },
      }),
      ...columns,
    ]

export const getChildBaseViewWACCustomCell = (type: string, width: number) => {
  if (type !== 'string') return getCustomCell(type, width)

  return {
    Cell: (info: ICustomCellProps) => {
      if (isUndefined(info.getValue()?.actualValue)) return null
      const value = info.getValue()?.actualValue
      const formattedValue =
        isPlainObject(value) || isArray(value) ? JSON.stringify(value) : String(value)

      return (
        <MultiLineInputMemoized
          disabled={!info.getValue()?.isEditable}
          value={formattedValue}
          width={width}
          expandable={info.getValue()?.expandable}
          onChange={async (value) =>
            info.table.options?.meta?.updateBaseRunVariable?.({
              baseRunVariableId: info.getValue()?.id,
              value,
            })
          }
        />
      )
    },
  }
}
