import { entries, map } from 'lodash'

import { ITEM_KEYS, UUIDs } from './constants'
import { OperateFormData, TBaseRun, TChildBaseRun } from './types'
const formatObject = (data: TBaseRun | TChildBaseRun) => {
  const result: Record<string, any> = {}
  data.baseRunVariables?.forEach((brv: any) => {
    result[brv.baseVariable.name] = brv.value
    result[`${brv.baseVariable.name} baseRunVariableId`] = brv.id
    result['baseRunId'] = data.id
  })
  return result
}

const completeOperateStep = (
  caseVariable: Record<string, any>,
  data: OperateFormData
): { baseRunId: string; baseRunVariableId: string; baseVariableId: string; value: any }[] =>
  map(entries(data), ([key, value]) => ({
    baseRunId: caseVariable['baseRunId'],
    baseRunVariableId: caseVariable[`${ITEM_KEYS[key]} baseRunVariableId`],
    baseVariableId: UUIDs.CasesBase[ITEM_KEYS[key]],
    value: value,
  }))

export { completeOperateStep, formatObject }
