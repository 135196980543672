import { theme } from '@invisible/ui/mui-theme-v2'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

type IProps = {
  tooltipText: string
}

export const ReadOnlyInterfaceNotAvailable = ({ tooltipText }: IProps) => (
  <ThemeProvider theme={theme}>
    <Stack direction='row' spacing={1} className='flex items-center justify-center'>
      <Tooltip title={tooltipText}>
        <ErrorOutlineOutlinedIcon fontSize='small' />
      </Tooltip>
      <Typography variant='body2'>{'This interface is Not Available in Read Only mode'}</Typography>
    </Stack>
  </ThemeProvider>
)
