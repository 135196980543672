import { useEffect } from 'react'

import type { TMatch } from '../types'
import { highlightInputFieldErrors } from '../utils/highlightInputFieldErrors'

// Hook to add event listeners for triggering the highlight update
export const useHighlightInputErrorsOnEvent = ({ matches }: { matches: TMatch[] }) => {
  useEffect(() => {
    function handleUpdate() {
      highlightInputFieldErrors({ matches })
    }

    // Add event listeners for various events
    window.addEventListener('scroll', handleUpdate, true)
    window.addEventListener('resize', handleUpdate)
    window.addEventListener('input', handleUpdate) // In case input changes dynamically

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('scroll', handleUpdate, true)
      window.removeEventListener('resize', handleUpdate)
      window.removeEventListener('input', handleUpdate)
    }
  }, [matches])
}
