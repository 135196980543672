/* eslint-disable @typescript-eslint/ban-types */
import '../../../global.css'
import 'react-date-range/dist/styles.css'
import '../../../libs/ui/styles/src/lib/date-picker.css'

import { datadogRum } from '@datadog/browser-rum'
import { NEXTAUTH_URL } from '@invisible/authentication/internal'
import { withAbilityContext } from '@invisible/authorization/client'
import { createApp, GraphqlQueryProvider } from '@invisible/common/providers'
import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { withTRPC } from '@invisible/trpc/client'
import { LoadingModal } from '@invisible/ui/loading-modal'
import { LicenseInfo } from '@mui/x-license'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import type { Session } from 'next-auth'
import { signIn, useSession } from 'next-auth/react'
import { FC, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Layout } from '../components/layouts/Layout'

// MuiX License, this is intended to be public and not a secret
// https://mui.com/x/introduction/licensing/#security
LicenseInfo.setLicenseKey(
  '52ba9f985f3e06f888c913a8f6134592Tz05MTQ3MSxFPTE3NDg1MjczODQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const withDNDContext =
  // eslint-disable-next-line @typescript-eslint/ban-types


    <T,>(Component: FC<T>): FC<T> =>
    (props) => {
      const router = useRouter()
      const [existingUser, setExistingUser] = useState(false)
      const [currentUser, isLoading] = useLoggedInUser()
      const { data: session, status } = useSession({
        required: true,
        onUnauthenticated() {
          signIn('auth0', { callbackUrl: `${NEXTAUTH_URL}${router.asPath}` }, { prompt: 'login' })
        },
      })

      useEffect(() => {
        if (isLoading) return

        if (session && currentUser?.id) {
          setExistingUser(true)
        }

        if (!currentUser?.id) {
          // Redirect again to sign in if the client is not found for the user
          signIn('auth0', { callbackUrl: `${NEXTAUTH_URL}${router.pathname}` }, { prompt: 'login' })
        }
      }, [session, currentUser])

      const Page = (
        <DndProvider backend={HTML5Backend}>
          {status === 'loading' || !existingUser ? <LoadingModal /> : <Component {...props} />}
        </DndProvider>
      )
      return Page
    }

// eslint-disable-next-line @typescript-eslint/ban-types
const WithLayout: FC = ({ children }) => <Layout>{children}</Layout>

const withGraphqlQueryProvider = <T,>(Component: FC<T>): FC<T> =>
  function addGraphQLQueryProvider(props) {
    return (
      <GraphqlQueryProvider>
        <Component {...props} />
      </GraphqlQueryProvider>
    )
  }

const AppBody = createApp({
  Layout: withGraphqlQueryProvider(withDNDContext(withAbilityContext(WithLayout))),
  pageTitle: 'Invisible',
})

const Page = (props: AppProps<{ session?: Session }>) => {
  const router = useRouter()

  useEffect(() => {
    datadogRum.startView({ name: router.asPath })
  })

  return <AppBody {...props} />
}

export default withTRPC(Page)
