import { SVGProps } from 'react'
function ThumbsDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.55627 1.73379C1.57299 1.71707 1.59567 1.70767 1.61932 1.70767H5.27935V9.7419H1.61932C1.59567 9.7419 1.57299 9.7325 1.55627 9.71578C1.53955 9.69906 1.53015 9.67637 1.53015 9.65272V1.79684C1.53015 1.77319 1.53955 1.75051 1.55627 1.73379ZM1.61932 0.457672C1.26415 0.457672 0.923529 0.598763 0.672385 0.849906C0.421242 1.10105 0.280151 1.44167 0.280151 1.79684V9.65272C0.280151 10.0079 0.421242 10.3485 0.672385 10.5997C0.92353 10.8508 1.26415 10.9919 1.61932 10.9919H5.5181L8.91621 17.7881C9.02208 17.9999 9.23849 18.1336 9.47523 18.1336C10.3986 18.1336 11.2842 17.7668 11.9371 17.1138C12.5901 16.4609 12.9569 15.5753 12.9569 14.6519V13.1344H18.0455C18.3367 13.1343 18.6246 13.0723 18.8901 12.9524C19.1555 12.8325 19.3924 12.6575 19.5851 12.439C19.7777 12.2205 19.9216 11.9636 20.0073 11.6852C20.093 11.4068 20.1185 11.1134 20.0821 10.8244L19.0109 2.25435C18.9484 1.75824 18.707 1.30199 18.332 0.971199C17.957 0.640409 17.4741 0.457813 16.974 0.457672H5.90437C5.90436 0.457672 5.90438 0.457672 5.90437 0.457672H1.61932ZM6.52935 1.70767V10.2193L9.846 16.8526C10.3003 16.7761 10.7232 16.56 11.0533 16.23C11.4718 15.8114 11.7069 15.2438 11.7069 14.6519V12.5094C11.7069 12.1642 11.9867 11.8844 12.3319 11.8844H18.0451C18.159 11.8844 18.2718 11.8601 18.3756 11.8132C18.4794 11.7663 18.5721 11.6978 18.6475 11.6123C18.7228 11.5269 18.7791 11.4263 18.8127 11.3174C18.8462 11.2085 18.8562 11.0937 18.8419 10.9806L17.7707 2.4106C17.7462 2.2165 17.6518 2.038 17.5051 1.90858C17.3584 1.77919 17.1695 1.70775 16.974 1.70767H6.52935Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ThumbsDown
