import { SVGProps } from 'react'
function LoadMenu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={15}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.603 3.758L9.24.395a.5.5 0 00-.353-.147H2.25a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V4.112a.503.503 0 00-.147-.354zm-1.006.584H8.656v-2.94l2.94 2.94zm.028 8.781h-8.75V1.373h4.719v3.375a.656.656 0 00.656.656h3.375v7.72zm-3.875-6.5a.125.125 0 00-.125-.125h-.75a.125.125 0 00-.125.125v1.688H5.062a.125.125 0 00-.125.125v.75c0 .068.057.125.125.125H6.75v1.687c0 .069.056.125.125.125h.75a.125.125 0 00.125-.125V9.311h1.687a.125.125 0 00.125-.125v-.75a.125.125 0 00-.125-.125H7.75V6.623z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LoadMenu
