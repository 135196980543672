import { useSession } from 'next-auth/react'
import { ReactNode, useMemo } from 'react'
import {
  StatsigOptions,
  StatsigProvider as NativeStatsigProvider,
  StatsigUser,
} from 'statsig-react'

import {
  NEXT_PUBLIC_INVISIBLE_STATSIG_PROXY,
  NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY,
  NEXT_PUBLIC_STATSIG_ENVIRONMENT_TIER,
} from './config/env'

interface IProps {
  children: ReactNode
}

const statsigOptions: StatsigOptions = {
  environment: { tier: NEXT_PUBLIC_STATSIG_ENVIRONMENT_TIER ?? 'staging' },
  api: NEXT_PUBLIC_INVISIBLE_STATSIG_PROXY,
}

export const StatsigProvider = ({ children }: IProps) => {
  const { data: session } = useSession()

  const user = useMemo(() => {
    const email = session?.user?.email ?? undefined // just to conform to StatsigUser type
    return {
      userID: email,
      email,
      privateAttributes: {
        email: 'hide_entity@invisible.email',
      },
    } as StatsigUser
  }, [session?.user?.email])

  return (
    <NativeStatsigProvider
      // NEVER the server-side key from Statsig, which is different, PLEASE
      sdkKey={NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY ?? 'client-placeholderKey'} // maintain client- prefix in unusually undefined situations so things don't break
      waitForInitialization={true}
      user={user}
      options={statsigOptions}>
      {children}
    </NativeStatsigProvider>
  )
}
