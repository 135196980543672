import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  parseResponse,
  toGlobalId,
  useStepRunUnsnoozeV2Mutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { resetIdleCheckStateStorage } from '@invisible/ui/hooks/use-user-activity'
import { useContext } from 'react'

type MutationInput = {
  stepRunId: string
}

const useStepRunUnsnooze = ({
  onSettled,
  onSuccess,
  onError: handleError,
}: {
  onSettled?: () => void
  onSuccess?: () => void
  onError?: (error: Error) => void
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const showErrorMessage = (error: Error) => {
    if (handleError) {
      handleError(error)
      return
    }
    logger.error(`Mutation stepRun.unsnooze resulted in an error: ${error.message}`, error)
    showSnackbar({
      message: error.message,
      variant: 'error',
    })
  }

  const { mutateAsync: stepRunUnsnoozeGraphQL, isLoading } = useStepRunUnsnoozeV2Mutation({
    onSettled: () => {
      onSettled?.()
      resetIdleCheckStateStorage()
    },
    onSuccess,
    onError: (error) => {
      const _error = new Error(getErrorMessage(error))
      showErrorMessage(_error)
    },
  })

  const mutateAsync = async ({ stepRunId }: MutationInput) => {
    logger.info('Mutating from useStepRunUnsnooze via GraphQL', {
      stepRunId,
    })
    const stepRunIdGlobal = toGlobalId('StepRunType', stepRunId)
    const response = await stepRunUnsnoozeGraphQL({ stepRunId: stepRunIdGlobal })
    const gqlResponse = parseResponse(
      response.stepRunUnsnoozeV2,
      (code: number, message: string) => {
        const _error = new Error(`${code}: ${message}`)
        if (handleError) {
          handleError(_error)
        } else {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        }
      }
    )
    return {
      stepRunId: fromGlobalId(gqlResponse?.id),
    }
  }

  return {
    mutateAsync,
    isLoading,
  }
}

export { useStepRunUnsnooze }
