import { SVGProps } from 'react'
function AirtableOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='37'
      height='30'
      viewBox='0 0 37 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.2427 0.372999L2.68102 5.82126C1.92686 6.12428 1.93468 7.16451 2.69356 7.45655L16.3117 12.6998C17.5083 13.1605 18.8408 13.1605 20.0373 12.6998L33.6557 7.45635C34.4144 7.16451 34.4226 6.12448 33.668 5.82146L20.1068 0.3728C18.8696 -0.124267 17.4797 -0.124267 16.2425 0.3728'
        fill='#FCB400'
      />
      <path
        d='M19.3829 15.5627V28.6612C19.3829 29.284 20.03 29.7108 20.6264 29.4813L35.8013 23.7625C35.9705 23.6974 36.1157 23.5844 36.218 23.4382C36.3203 23.2919 36.375 23.1193 36.3749 22.9425V9.84418C36.3749 9.22116 35.7279 8.79457 35.1314 9.02414L19.9566 14.7429C19.7874 14.8081 19.6422 14.9211 19.5399 15.0673C19.4376 15.2135 19.383 15.3862 19.3829 15.5629'
        fill='#18BFFF'
      />
      <path
        d='M15.8393 16.2387L11.3358 18.3498L10.8785 18.5644L1.37179 22.987C0.769371 23.2693 0 22.8429 0 22.193V9.89908C0 9.66393 0.124185 9.46091 0.290724 9.3082C0.359047 9.24215 0.436558 9.18571 0.521001 9.14052C0.747988 9.00817 1.07202 8.97284 1.34753 9.07864L15.7637 14.6245C16.4965 14.9068 16.554 15.9037 15.8393 16.2389'
        fill='#F82B60'
      />
      <path
        d='M15.8394 16.2386L11.3358 18.3498L0.290771 9.308C0.359107 9.24202 0.436618 9.18565 0.521048 9.14052C0.748036 9.00817 1.07207 8.97284 1.34758 9.07864L15.7637 14.6245C16.4965 14.9068 16.5541 15.9037 15.8394 16.2388'
        fill='black'
        fill-opacity='0.25'
      />
    </svg>
  )
}

export default AirtableOriginal
