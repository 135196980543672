import { sanitizeUrl } from '@braintree/sanitize-url'
import { classNames } from '@invisible/common/helpers'
import { SnackbarContext } from '@invisible/common/providers'
import { commonMIMETypes } from '@invisible/common/types'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ThemeProvider } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { useContext as useReactContext, useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { ReadOnlyInterfaceNotAvailable } from '../../interface/NotAvailable'
import { AnnotationTimeline } from './AnnotationTimeline'
import { formatTime, timeStringToSeconds } from './helpers'
import { VideoControls } from './VideoControls'
import { VideoTimeline } from './VideoTimeline'

type IProps = WizardSchemas.WACConfig.TSchema & {
  height: number
  baseRun: any
  stepRun: any
  video: WizardSchemas.Video.TSchema
  isReadOnly: boolean
}

export const VideoWAC = ({
  value,
  showName,
  name,
  video: config,
  baseRun,
  stepRun,
  isReadOnly,
}: IProps) => {
  const reactQueryContext = useContext()
  const [embed, setEmbed] = useState('')
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [segmentStart, setSegmentStart] = useState<number | null>(null)
  const [segmentEnd, setSegmentEnd] = useState<number | null>(null)
  const [selectedSegment, setSelectedSegment] = useState<{ start: number; stop: number } | null>(
    null
  )
  const [signedUrl, setSignedUrl] = useState('')
  const { showSnackbar } = useReactContext(SnackbarContext)

  const videoRef = useRef<HTMLVideoElement>(null)
  const annotationTimelineRef = useRef<unknown>()

  const { mutateAsync: createBaseRun, isLoading: createBaseRunLoading } = useBaseRunCreate({
    onSuccess: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      setSegmentStart(null)
      setSegmentEnd(null)
      showSnackbar({
        message: 'Annotation Created',
        variant: 'info',
      })
    },
    onError: (error) => {
      setSegmentStart(null)
      setSegmentEnd(null)
      logger.error('Error creating Video annotation', {
        error,
        baseRunId: baseRun.id,
        stepRunId: stepRun.id,
      })
      showSnackbar({ message: `Failed to create annotation`, variant: 'error' })
    },
  })

  const { mutateAsync: updateBaseRunVariable, isLoading: IsUpdatingBaseRunVariable } =
    useBaseRunVariablesWizardUpdate({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        showSnackbar({
          message: 'Annotation updated',
          variant: 'info',
        })
      },
      onError: (error) => {
        logger.error('Error updating Video annotation', {
          error,
          baseRunId: baseRun.id,
          stepRunId: stepRun.id,
        })
        showSnackbar({
          message: 'Failed to update annotation',
          variant: 'error',
        })
      },
    })

  const { mutateAsync: deleteBaseRuns } = useBaseRunDeleteWithStepRunReference({
    onSuccess: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      showSnackbar({
        message: 'Annotation Deleted',
        variant: 'info',
      })
    },
    onError: (error) => {
      logger.error('Error deleting Video annotation', {
        error,
        baseRunId: baseRun.id,
        stepRunId: stepRun.id,
      })
      showSnackbar({ message: `Failed to Deleted annotation`, variant: 'error' })
    },
  })

  const { data: annotations } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config?.annotationBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])

  const normalizedAnnotations = useMemo(
    () =>
      (annotations ?? [])
        .map((annot) => ({
          id: annot.id,
          text: annot.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.textBaseVariableId
          )?.value as string,
          startFrame: annot.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.timecodeStartBaseVariableId
          )?.value as any,
          endFrame: annot.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.timecodeEndBaseVariableId
          )?.value as string,
          duration: annot.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.durationBaseVariableId
          )?.value as string,
          preProvidedInfo: annot.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.preProvidedInfoBaseVariableId
          )?.value as string,
          createdAt: annot.createdAt,
        }))
        .sort((a, b) => a.startFrame - b.startFrame),
    [annotations, config]
  )

  const preloadedAnnotations = useMemo(
    () =>
      normalizedAnnotations
        .filter((annot) => annot.preProvidedInfo)
        .map((annot) => ({
          id: annot.id,
          startFrame: timeStringToSeconds(annot.startFrame),
          endFrame: timeStringToSeconds(annot.endFrame),
          duration: timeStringToSeconds(annot.duration),
          text: annot.preProvidedInfo,
        })),
    [normalizedAnnotations]
  )

  const userCreatedAnnotations = useMemo(
    () =>
      normalizedAnnotations
        .filter((annot) => !annot.preProvidedInfo)
        .map((annot) => ({
          id: annot.id,
          startFrame: timeStringToSeconds(annot.startFrame),
          endFrame: timeStringToSeconds(annot.endFrame),
          duration: timeStringToSeconds(annot.duration),
          text: annot.text,
        })),
    [normalizedAnnotations]
  )

  const saveTimecodeToBaseRun = async (startSeg: number, endSeg: number) => {
    if (createBaseRunLoading) {
      return
    }

    const newBaseRun = await createBaseRun({
      baseId: config.annotationBaseId as string,
      parentBaseRunId: baseRun.id,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: config.timecodeStartBaseVariableId as string,
          value: formatTime(startSeg),
        },
        {
          baseVariableId: config.timecodeEndBaseVariableId as string,
          value: formatTime(endSeg),
        },
        {
          baseVariableId: config.durationBaseVariableId as string,
          value: formatTime(endSeg - startSeg),
        },
      ],
    })

    if (annotationTimelineRef.current) {
      ;(annotationTimelineRef.current as any)?.setEditedAnnotation(newBaseRun.id)
    }
  }

  const validateAndSaveSegments = async (timeSeg?: number) => {
    if (createBaseRunLoading || !timeSeg) {
      return
    }
    // Check if the segment is already captured
    if (
      normalizedAnnotations.some(
        (annot) =>
          timeSeg >= timeStringToSeconds(annot.startFrame) &&
          timeSeg <= timeStringToSeconds(annot.endFrame)
      )
    ) {
      showSnackbar({
        message: 'Segment already captured',
        variant: 'warning',
      })
      return
    }

    if (!segmentStart) {
      setSegmentStart(timeSeg ?? 0)
      return
    }

    if (segmentStart && timeSeg > segmentStart) {
      const endSeg = timeSeg ?? 0
      setSegmentEnd(endSeg)
      await saveTimecodeToBaseRun(segmentStart, endSeg)
    }
  }

  const handleUpdateBaseRunVariable = async (
    baseRunId: string,
    baseVariableId: string,
    value: string
  ) => {
    if (IsUpdatingBaseRunVariable) {
      return
    }

    await updateBaseRunVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId,
          baseVariableId,
          value,
        },
      ],
    })
  }

  const handleDeleteBaseRun = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this annotation?')) return

    await deleteBaseRuns({ baseRunIds: [id], stepRunId: stepRun.id })
  }

  const onTimeUpdate = () => {
    if (!videoRef.current) return

    setCurrentTime(videoRef.current.currentTime ?? 0)

    // Pause the video if it reaches the end of the segment being played
    if (
      !videoRef.current.loop &&
      selectedSegment &&
      videoRef.current.currentTime >= selectedSegment.stop
    ) {
      videoRef.current.currentTime = selectedSegment.stop
      videoRef.current.pause()
      setSelectedSegment(null)
    }

    // Loop the video if it reaches the end of the segment being played
    if (
      videoRef.current.loop &&
      selectedSegment &&
      videoRef.current.currentTime >= selectedSegment.stop
    ) {
      videoRef.current.currentTime = selectedSegment.start
      videoRef.current.play()
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!config.enableNativeVideoControls) {
      if (event.code === 'KeyF') {
        toggleFullScreen()
        return
      }
      if (event.code === 'Space') {
        togglePlayPause()
        return
      }
    }
    if (event.code === 'Escape') {
      setSegmentStart(null)
      return
    }
    if (!event.shiftKey) return
    if (event.code === 'KeyA') {
      validateAndSaveSegments(videoRef?.current?.currentTime)
      return
    }
  }

  const seekVideo = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
      setCurrentTime(time)
    }
  }

  const playSegment = (start: number, end: number, loop = false) => {
    if (videoRef.current) {
      videoRef.current.currentTime = start
      videoRef.current.play()
      videoRef.current.loop = loop
      setSelectedSegment({ start, stop: end })
    }
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      videoRef.current?.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  const togglePlayPause = () => {
    if (videoRef.current) {
      videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
    }
  }

  const getFileDetailsFromGoogleCloudUrl = (url: string) => {
    const urlObject = new URL(url)
    const pathParts = urlObject.pathname.split('/')

    const bucketName = pathParts[1]
    const directoryName = pathParts.slice(2, -1).join('/')

    const fileNameWithExtension = pathParts[pathParts.length - 1]
    const extension = fileNameWithExtension.split('.')[1]
    const type = commonMIMETypes[extension]
    const file = directoryName ? `${directoryName}/${fileNameWithExtension}` : fileNameWithExtension

    return { file, type, bucketName }
  }

  const retrieveCloudVideo = async (url: string) => {
    try {
      const { file, type, bucketName } = getFileDetailsFromGoogleCloudUrl(url)
      const response = await axios.post(
        `/api/google/cloud/signedUrl?file=${file}&type=${type}&bucketName=${bucketName}&action=read&excludeContentType=true`
      )

      // Update the video source with the signed URL
      const publicUrl = response.data.url
      if (videoRef.current) {
        videoRef.current.src = publicUrl
        videoRef.current.load() // Reload the video with the new source
      }
      setSignedUrl(publicUrl)
    } catch (error) {
      logger.error(`Error retrieving signed URL: ${url}`, {
        error,
        baseRunId: baseRun.id,
        stepRunId: stepRun.id,
      })
      setSignedUrl(url)
    }
  }

  const renderVideo = (url: string) => {
    const isPrivate = url.includes('storage.cloud.google.com')
    if (!url) {
      return null
    }
    if (url.includes('loom.com')) {
      const loomVideoId = sanitizeUrl(url).split('/').pop()

      return (
        <div className='max-h-[600px] w-full border border-gray-400'>
          <iframe
            src={`https://www.loom.com/embed/${loomVideoId}`}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            allowFullScreen
          />
        </div>
      )
    }

    if (isPrivate && signedUrl === '') {
      const handleAsync = async () => {
        await retrieveCloudVideo(url)
      }
      handleAsync()
    }

    if (isReadOnly) return <ReadOnlyInterfaceNotAvailable tooltipText={'Video WAC'} />

    return (
      <div
        onKeyDown={handleKeyPress}
        tabIndex={1}
        className={classNames(
          'focus:outline-none',
          segmentStart && !segmentEnd && !createBaseRunLoading ? `cursor-crosshair` : '',
          createBaseRunLoading ? 'cursor-progress' : ''
        )}>
        <video
          className='mb-[-4px] max-h-[600px] w-full border border-gray-400 focus:outline-none'
          controls={config?.enableNativeVideoControls ?? true}
          ref={videoRef}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={(e) => {
            setDuration(e.currentTarget.duration)
          }}>
          <source src={isPrivate ? signedUrl : url}></source>
        </video>
        <VideoTimeline
          duration={duration}
          currentTime={currentTime}
          onTimelineClick={(seekTime) => seekVideo(seekTime)}
        />
        <VideoControls
          videoRef={videoRef}
          duration={duration}
          seekVideo={(seekTime) => seekVideo(seekTime)}
          toggleFullScreen={toggleFullScreen}
        />
        {config?.enableVideoAnnotation ? (
          <>
            <AnnotationTimeline
              preview
              key='preview-annotation-timeline'
              duration={duration}
              isAnnotating={segmentStart !== null && !segmentEnd}
              annotations={preloadedAnnotations}
              playScene={playSegment}
            />
            <AnnotationTimeline
              key='user-annotation-timeline'
              ref={annotationTimelineRef}
              duration={duration}
              isAnnotating={segmentStart !== null && !segmentEnd}
              newAnnotation={
                segmentStart
                  ? {
                      id: uuid(),
                      duration: 0,
                      text: '',
                      startFrame: segmentStart,
                      endFrame: segmentEnd as number,
                    }
                  : undefined
              }
              annotations={userCreatedAnnotations}
              onTimelineClick={validateAndSaveSegments}
              playScene={playSegment}
              deleteAnnotation={handleDeleteBaseRun}
              updateAnnotationText={async (baseRunId, value) =>
                handleUpdateBaseRunVariable(baseRunId, config.textBaseVariableId as string, value)
              }
            />
            <div className='flex'>
              <Tooltip
                title={
                  <>
                    <Typography variant='body2' gutterBottom>
                      When hovering inside of the timeline, click to start annotation and move your
                      cursor to the scene ending and click to save scene. You can also your keyboard
                      to mark a scene by pressing SHIFT+A while the video is playing and pressing
                      SHIFT+A again to end the scene.
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                      Timecode values can be edited in the table below for scene annotations created
                      by you or other users.
                    </Typography>
                  </>
                }>
                <span className='mt-4 flex cursor-pointer items-center p-1 text-gray-500 hover:bg-slate-100'>
                  <InfoOutlinedIcon className='mr-2' /> How to annotate a scene
                </span>
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
    )
  }

  return (
    <div className='relative box-border flex h-full flex-col overflow-auto rounded-lg border border-gray-400 bg-white p-2.5 shadow-md'>
      {showName ? <div className='mb-3 font-bold'>{name}</div> : null}

      <ThemeProvider theme={theme}>{renderVideo(value as string)}</ThemeProvider>
    </div>
  )
}
