import { SVGProps } from 'react'
function SmallRobot(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M3.21652 5.21722C3.21652 5.50138 3.3294 5.7739 3.53033 5.97483C3.73126 6.17576 4.00379 6.28864 4.28795 6.28864C4.57211 6.28864 4.84463 6.17576 5.04556 5.97483C5.24649 5.7739 5.35938 5.50138 5.35938 5.21722C5.35938 4.93305 5.24649 4.66053 5.04556 4.4596C4.84463 4.25867 4.57211 4.14579 4.28795 4.14579C4.00379 4.14579 3.73126 4.25867 3.53033 4.4596C3.3294 4.66053 3.21652 4.93305 3.21652 5.21722ZM13.0737 0.50293H0.930804C0.614732 0.50293 0.359375 0.758287 0.359375 1.07436V12.8601C0.359375 13.1761 0.614732 13.4315 0.930804 13.4315H13.0737C13.3897 13.4315 13.6451 13.1761 13.6451 12.8601V1.07436C13.6451 0.758287 13.3897 0.50293 13.0737 0.50293ZM12.5022 12.2886H1.50223V1.64579H12.5022V12.2886ZM8.64509 5.21722C8.64509 5.50138 8.75797 5.7739 8.9589 5.97483C9.15984 6.17576 9.43236 6.28864 9.71652 6.28864C10.0007 6.28864 10.2732 6.17576 10.4741 5.97483C10.6751 5.7739 10.7879 5.50138 10.7879 5.21722C10.7879 4.93305 10.6751 4.66053 10.4741 4.4596C10.2732 4.25867 10.0007 4.14579 9.71652 4.14579C9.43236 4.14579 9.15984 4.25867 8.9589 4.4596C8.75797 4.66053 8.64509 4.93305 8.64509 5.21722ZM13.1129 15.1458H0.891518C0.596875 15.1458 0.359375 15.4011 0.359375 15.7172V16.3601C0.359375 16.4386 0.418303 16.5029 0.491518 16.5029H13.5112C13.5844 16.5029 13.6433 16.4386 13.6433 16.3601V15.7172C13.6451 15.4011 13.4076 15.1458 13.1129 15.1458ZM9.71652 8.4315H4.28795C4.20938 8.4315 4.14509 8.49579 4.14509 8.57436V9.64579C4.14509 9.72436 4.20938 9.78864 4.28795 9.78864H9.71652C9.79509 9.78864 9.85938 9.72436 9.85938 9.64579V8.57436C9.85938 8.49579 9.79509 8.4315 9.71652 8.4315Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SmallRobot
