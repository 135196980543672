import { SVGProps } from 'react'

function GetTask(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5931_49921)'>
        <path
          d='M29.2489 7.14374H25.6933V3.55263C25.6933 1.58522 24.1052 -0.00292969 22.1378 -0.00292969H4.36C2.39259 -0.00292969 0.804443 1.58522 0.804443 3.55263V21.3304C0.804443 23.2978 2.39259 24.886 4.36 24.886H7.95111V28.4415C7.95111 30.4089 9.55111 31.9971 11.5067 31.9971H29.2489C31.2163 31.9971 32.8044 30.4089 32.8044 28.4415V10.6993C32.8044 8.74374 31.2163 7.14374 29.2489 7.14374ZM4.36 22.5156C3.70815 22.5156 3.17481 21.9823 3.17481 21.3304V3.55263C3.17481 2.90077 3.70815 2.36744 4.36 2.36744H22.1378C22.7896 2.36744 23.323 2.90077 23.323 3.55263V21.3304C23.323 21.9823 22.7896 22.5156 22.1378 22.5156H4.36ZM30.4341 28.4415C30.4341 29.0934 29.9007 29.6267 29.2489 29.6267H11.5067C10.8548 29.6267 10.3215 29.0934 10.3215 28.4415V24.886H22.1378C24.1052 24.886 25.6933 23.2978 25.6933 21.3304V9.51411H29.2489C29.9007 9.51411 30.4341 10.0474 30.4341 10.6993V28.4415Z'
          fill='currentColor'
        />
        <path
          d='M17.4294 11.1221H14.6794V8.37207C14.6794 7.60207 14.0744 6.99707 13.3044 6.99707C12.8644 6.99707 12.4794 7.18957 12.2044 7.51957C12.0394 7.73957 11.9294 8.04207 11.9294 8.37207V11.1221H9.17944C8.40944 11.1221 7.80444 11.7271 7.80444 12.4971C7.80444 13.2671 8.40944 13.8721 9.17944 13.8721H11.9294V16.6221C11.9294 17.3921 12.5344 17.9971 13.3044 17.9971C14.0744 17.9971 14.6794 17.3921 14.6794 16.6221V13.8721H17.4294C18.1994 13.8721 18.8044 13.2671 18.8044 12.4971C18.8044 11.7271 18.1994 11.1221 17.4294 11.1221Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5931_49921'>
          <rect width='32' height='32' fill='white' transform='translate(0.804443)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GetTask
