import type { TProcess, TProcessByIdStep } from '@invisible/common/components/process-base'
import {
  mapGraphqlToUnifiedProcess,
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { DND_DRAG_TYPES, OPTIMISTIC_STEP_ID } from '@invisible/common/stores/process-store'
import { useStore } from '@invisible/common/stores/process-store'
import { IProcessByIdQuery, toGlobalId } from '@invisible/concorde/gql-client'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { LinkIcon } from '@invisible/ui/icons'
import { CRON_TRIGGER_STEP_TEMPLATE_ID } from '@invisible/ultron/shared'
import { FC, useCallback, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { useQueryClient } from 'react-query'
import { Handle, Position, useUpdateNodeInternals } from 'reactflow'
import { useGate } from 'statsig-react'
import shallow from 'zustand/shallow'

interface IProps {
  data: {
    baseId: string
    processRootBaseId: string
    processId: string
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BlankCanvasTrigger: FC<IProps> = ({ data }) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-process-by-id-query')
  const graphqlQueryClient = useQueryClient()
  const updateNodeInternals = useUpdateNodeInternals()
  const reactQueryContext = useContext()
  const { layoutOrientation } = useStore(
    useCallback(
      (state) => ({
        layoutOrientation: state.layoutOrientation,
      }),
      []
    ),
    shallow
  )

  const { data: stepTemplates } = useQuery(['stepTemplate.findTriggerStepTemplates'])

  const queryKey = isGraphqlEnabled
    ? ['ProcessById', { id: toGlobalId('ProcessType', data.processId) }]
    : ['process.findByIdWithStepsAndStepGoTos', { id: data.processId }]

  const queryClient = isGraphqlEnabled ? graphqlQueryClient : reactQueryContext.queryClient

  const { mutateAsync: createStep } = useMutation('trigger.create', {
    onSettled: () => {
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
    onMutate: async (variables) => {
      await graphqlQueryClient.cancelQueries('ProcessById')
      await reactQueryContext.queryClient.cancelQueries('process.findByIdWithStepsAndStepGoTos')
      const stepTemplate = stepTemplates?.find((s) => s.id === variables.triggerStepTemplateId)
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error Wrong types
        (prevData: TProcess | IProcessByIdQuery) => {
          const mappedPrevData =
            isGraphqlEnabled && 'process' in prevData
              ? (mapGraphqlToUnifiedProcess(prevData) as TProcess)
              : (prevData as TProcess)
          return {
          ...mappedPrevData,
          steps: [
            ...mappedPrevData.steps,
            {
              id: OPTIMISTIC_STEP_ID,
              name: stepTemplate?.name ?? '',
              processId: data.processId,
              position: 1,
              meta: variables.meta,
              stepTemplateId: variables.triggerStepTemplateId,
              stepTemplate: {
                ...stepTemplate,
              },
            },
          ],
          stepGoTos: [
            ...mappedPrevData.stepGoTos,
            {
              goFromStepId: OPTIMISTIC_STEP_ID,
              goToStepId: variables.goToStepId,
              processId: data.processId,
            },
          ],
        }}
      )
    },
  })

  const { data: process } = useProcessById({ id: data.processId })

  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_DRAG_TYPES.TRIGGER_STEP,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: async (item: any) => {
      createStep({
        triggerStepTemplateId: item.id,
        baseId: process?.rootBaseId as string,
        goToStepId: process?.steps?.[0].id as string,
        meta: {
          payloadSchema: {},
          ...(item.id === CRON_TRIGGER_STEP_TEMPLATE_ID ? { cronExpression: '* * * * *' } : {}),
        },
      })
    },
  }))

  useEffect(() => {
    updateNodeInternals('placeholder-1')
  }, [layoutOrientation])

  return (
    <>
      <div
        ref={drop}
        className={classNames(
          'text-primary  box-border flex h-16 w-52 items-center rounded border border-dashed border-gray-400 bg-white pl-5',
          isOver ? 'bg-gray-200' : 'bg-white',
          // react-flow specific class to disable selection/dragging
          'nodrag'
        )}>
        <LinkIcon width={20} height={20} className='text-primary' />
        <div className='ml-5 flex flex-col text-xs '>
          <span className='font-bold tracking-wider'> TRIGGER START</span>
          <span className='tracking-wide'>Drag here</span>
        </div>
      </div>

      <Handle
        type='source'
        position={layoutOrientation === 'horizontal' ? Position.Right : Position.Bottom}
        className='!h-2 !w-2 !bg-purple-500'
      />
    </>
  )
}

export { BlankCanvasTrigger }
