import {
  IAttribute,
  IDataFilter,
  IFilter,
  IFilterColumnOption,
  IFormattedPeriod,
  IInterval,
  IPieChartDatum,
  IReportFilter,
  IReportMeta,
  isIInterval,
  TDateViewLevel,
  TFilterInputType,
  TFilterValue,
  TReportingScope,
} from '@invisible/common/types'
import { logger } from '@invisible/logger/server'
import {
  DATE_TIME_FORMATS,
  FILTER_OPERATOR,
  FILTER_TYPE,
  REPORT_FILTER_MAPPING,
  VIEW_TYPES,
} from '@invisible/ui/constants'
import { BarDatum, ComputedDatum as ComputedBarDatum } from '@nivo/bar'
import { ComputedDatum as ComputedPieDatum } from '@nivo/pie'
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth } from 'date-fns'
import { format as formatTZ, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  compact,
  filter,
  find,
  findIndex,
  flatten,
  flow,
  forEach,
  get,
  includes,
  isEmpty,
  isNumber,
  isString,
  join,
  map,
  reduce,
  some,
  startCase,
} from 'lodash/fp'

export interface IParamsUltronAPI {
  dataFilter?: IDataFilter<TFilterValue>[]
  reportFilter?: IReportFilter
}

export const TIMEZONE = {
  PT: 'America/Los_Angeles',
  UTC: 'UTC',
} as const

export const REPORT_TYPE = {
  Custom: 'Custom',
  Core: 'Core',
} as const

export const promiseAllWithCatch = (promises: Promise<unknown>[]) =>
  Promise.all(
    promises.map((p) =>
      p
        .then((res) => res)
        .catch((error) => {
          logger.error(`Error caught in promise: ${error}`)
        })
    )
  )

export const getReportMetaDataByReportName = (reportName: string, reportsMetaData: IReportMeta[]) =>
  find((reportMetaData: IReportMeta) => reportMetaData?.reportName === reportName, reportsMetaData)!

export const getPieChartColor = (
  node: Omit<ComputedPieDatum<IPieChartDatum>, 'fill' | 'color' | 'arc'>
) => node.data.color || ''

export const getBarChartColor = (
  node: ComputedBarDatum<BarDatum>,
  colors: Record<string, string>
) => colors[node.id]

export const parseNumericValuesInJSON = (jsonData: JSON) => {
  const data = JSON.stringify(jsonData)
  return JSON.parse(data, (key, value) => {
    if (typeof value === 'string' && /^\d+\.?\d*$/.test(value)) {
      return Number(value)
    }
    return value
  })
}

// to acquire data in UTC from API
export const generateTimeZoneFilter = (timeZone: string): IDataFilter<string> => ({
  name: FILTER_TYPE.Timezone,
  operator: FILTER_OPERATOR.equals,
  valueType: 'String',
  value: timeZone,
})

export const parseDataFilters = ({
  formattedPeriod,
  reportDynamicFilters,
  scope,
  baseId,
  processId,
  timeZone,
  withLabels,
}: {
  formattedPeriod?: IFormattedPeriod
  reportDynamicFilters?: IFilter<TFilterValue>[]
  scope?: {
    type: TReportingScope
    scopedId: string | number
  }
  baseId?: string
  processId?: string
  timeZone?: string
  withLabels?: boolean
}): IDataFilter<TFilterValue>[] => {
  const dataFilters = compact([
    !!baseId && {
      name: 'BASE',
      valueType: 'String',
      operator: FILTER_OPERATOR.equals,
      value: baseId,
    },
    !!processId && {
      name: 'PROCESS',
      valueType: 'String',
      operator: FILTER_OPERATOR.equals,
      value: processId,
    },
    !!timeZone && generateTimeZoneFilter(timeZone),
    !!formattedPeriod && generateIntervalFilter(formattedPeriod),
    !!scope && generateScopedFilter(scope.type, scope.scopedId),
  ])

  if (!reportDynamicFilters || isEmpty(reportDynamicFilters)) return dataFilters

  return replaceDataFilterByDynamicFilter({
    dataFilters,
    dynamicFilters: reportDynamicFilters,
    withLabels: !!withLabels,
  })
}

const parseDataFilter = ({
  filter: { filterName, valueType, operator, value, key, label },
  withLabel,
}: {
  filter: IFilter<TFilterValue>
  withLabel: boolean
}): IDataFilter<TFilterValue> => ({
  name: filterName,
  valueType,
  operator,
  value,
  key,
  ...(withLabel ? { label } : []),
})

const mapFiltersToDataFilters = ({
  filters,
  withLabels,
}: {
  filters: IFilter<TFilterValue>[]
  withLabels: boolean
}) =>
  map((filter: IFilter<TFilterValue>) => parseDataFilter({ filter, withLabel: withLabels }))(
    filters
  )

export const replaceDataFilterByDynamicFilter = ({
  dataFilters,
  dynamicFilters,
  withLabels,
}: {
  dataFilters: IDataFilter<TFilterValue>[]
  dynamicFilters: IFilter<TFilterValue>[]
  withLabels: boolean
}) =>
  flow(
    filter(
      (fltr: IDataFilter<TFilterValue>) =>
        !some((dynamicFltr: IFilter<TFilterValue>) => fltr.name === dynamicFltr.filterName)(
          dynamicFilters
        )
    ),
    (requestFilters: IDataFilter<TFilterValue>[]) => [
      ...requestFilters,
      ...mapFiltersToDataFilters({ filters: dynamicFilters, withLabels }),
    ]
  )(dataFilters)

export const generateDrillFilter = (drillId: string | number): IDataFilter<TFilterValue> => ({
  name: FILTER_TYPE.Drill,
  operator: FILTER_OPERATOR.equals,
  valueType: startCase(typeof drillId),
  value: drillId,
})

export const getPieChartNodeFilters = ({
  node,
  formattedPeriod,
  scope,
  scopedId,
}: {
  node: ComputedPieDatum<IPieChartDatum>
  formattedPeriod?: IFormattedPeriod
  scopedId: number | string
  scope: TReportingScope
}) => {
  let filters: IDataFilter<TFilterValue>[] = []

  filters = [
    generateTimeZoneFilter(TIMEZONE.UTC),
    generateScopedFilter(scope, scopedId),
    ...(typeof node.data.drillId !== 'undefined' ? [generateDrillFilter(node.data.drillId)] : []),
    ...(formattedPeriod ? [generateIntervalFilter(formattedPeriod)] : []),
  ]
  return filters
}

export const getBarChartNodeFilters = ({
  node,
  formattedPeriod,
  scope,
  scopedId,
}: {
  node: ComputedBarDatum<BarDatum>
  formattedPeriod?: IFormattedPeriod
  scope: TReportingScope
  scopedId: string | number
}) => {
  let filters: IDataFilter<TFilterValue>[] = []

  if (includes(node.data.valueType)(['DateTime', 'Date'])) {
    const date = new Date(
      formatInTimeZone({
        date: node.data.date,
        format: "yyyy-MM-dd'T'HH:mm:ss.SSS",
      })
    )

    const intervalFilterValue = {
      from: node.data.format === DATE_TIME_FORMATS.monthly ? startOfMonth(date) : startOfDay(date),
      to: node.data.format === DATE_TIME_FORMATS.monthly ? endOfMonth(date) : endOfDay(date),
    }

    filters = [
      {
        name: FILTER_TYPE.Interval,
        operator: FILTER_OPERATOR.between,
        valueType: 'DateTime',
        value: {
          from: formatInTimeZone({
            date: zonedTimeToUtc(intervalFilterValue.from, TIMEZONE.UTC),
            tz: TIMEZONE.UTC,
          }),
          to: formatInTimeZone({
            date: zonedTimeToUtc(intervalFilterValue.to, TIMEZONE.UTC),
            tz: TIMEZONE.UTC,
          }),
        },
      },
    ]
  } else if (formattedPeriod) {
    filters = [generateIntervalFilter(formattedPeriod)]
  }

  filters = [
    ...(filters || []),
    generateTimeZoneFilter(TIMEZONE.UTC),
    generateScopedFilter(scope, scopedId),
    generateDrillFilter(node.data['drillId_' + node.id]),
  ]
  return filters
}

export const attributeHasDefaultValue = ({
  defaultValue,
  operator,
  name,
  selectOptions,
  defaultSelectValue,
}: IAttribute) =>
  ((!!selectOptions && !!defaultSelectValue) || (!!defaultValue && !!operator)) &&
  includes(name)(Object.values(FILTER_TYPE))

export const generateIntervalFilter = (
  formattedPeriod: IFormattedPeriod,
  timeZone?: typeof TIMEZONE[keyof typeof TIMEZONE]
): IDataFilter<IInterval> => ({
  name: FILTER_TYPE.Interval,
  valueType: 'DateTime',
  operator: 'between',
  value: {
    from: formatInTimeZone({ date: formattedPeriod.from, tz: timeZone }),
    to: formatInTimeZone({ date: formattedPeriod.to, tz: timeZone }),
  },
})

export const generateScopedFilter = (
  scope: TReportingScope,
  scopedId: string | number
): IDataFilter<string | number> => ({
  name: scope === 'company' ? FILTER_TYPE.Company : FILTER_TYPE.Process,
  operator: FILTER_OPERATOR.equals,
  valueType: startCase(typeof scopedId),
  value: scopedId,
})

export const isFilterValueSet = (filterOperatorType: string, filterValue: TFilterValue) =>
  filterOperatorType === FILTER_OPERATOR.between && isIInterval(filterValue)
    ? !isEmpty(filterValue?.from) && !isEmpty(filterValue?.to)
    : filterOperatorType !== FILTER_OPERATOR.null && filterOperatorType !== FILTER_OPERATOR.not_null
    ? !isEmpty(filterValue)
    : true

export const formatUnit = ({
  valueType,
  postfixUnit,
  prefixUnit,
  value,
  isCSVData = false,
}: {
  valueType: string
  postfixUnit?: string
  prefixUnit?: string
  value: string | number | null | undefined
  isCSVData?: boolean
}) => {
  const unit = postfixUnit ?? postfixUnit
  let initializedVal
  if (value == null) {
    initializedVal = includes(valueType)(['Decimal', 'Number', 'Float', 'Int', 'BigInt']) ? 0 : ''
  } else {
    initializedVal = value
  }
  const val = includes(valueType)(['Decimal', 'Number', 'Float'])
    ? formatNumberValue(Number(initializedVal).toFixed(2), isCSVData)
    : initializedVal

  switch (unit) {
    case '$':
      return `$${Number(val).toLocaleString()}`
    case 'hours':
      return formatHoursToDays(Number(val), true)
    default:
      return `${prefixUnit ?? ''}${String(val)}${postfixUnit ?? ''}`
  }
}

export const formatInTimeZone = ({
  date,
  format,
  tz,
}: {
  date?: Date | string | number
  format?: string
  tz?: string
}): string => {
  // Some date values are from DB are null. Therefore, there is a possibility of date to be null on runtime
  if (!date) return ''
  const dateObj = isString(date) || isNumber(date) ? new Date(date) : date
  const timeZone = tz || TIMEZONE.UTC

  return flow(
    (date) =>
      utcToZonedTime(date, timeZone, {
        timeZone,
      }),
    (zonedDateTime) =>
      formatTZ(zonedDateTime, format ?? "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
        timeZone,
      })
  )(dateObj?.toISOString())
}

export const formatNumberValue = (value: string, isCSVData?: boolean) =>
  !isCSVData ? Number(value).toLocaleString() : value

const formatHoursToDays = (hours: number, isLong?: boolean): string =>
  hours < 24
    ? `${hours}${isLong ? 'hours' : 'hrs'}`
    : `${Math.floor(hours / 24)}${isLong ? 'days' : 'd'}${' '}${getHoursAndMins(hours)}`

const getHoursAndMins = (hours: number) => {
  if (Number((hours % 24).toFixed(1).split('.')[1]) > 0) {
    return `${(hours % 24).toFixed(2).split('.')[0]}${
      Number((hours % 24).toFixed()) > 1 ? 'hrs' : 'hr'
    } ${Number((hours % 24).toFixed(1).split('.')[1]) * 6}mins`
  } else {
    return `${(hours % 24).toFixed()}hrs`
  }
}

export const generateSumSeries = ({
  chartDataProp,
  keys,
  indexBy,
}: {
  chartDataProp: BarDatum[]
  keys: string[] | undefined
  indexBy: string | undefined
}) => {
  const series = map((d: BarDatum) => {
    let sum = 0
    forEach((k: string) => {
      if (d[k]) {
        sum += Number(d[k])
      }
    })(keys)
    return { index: indexBy && d[indexBy], value: Number(sum.toFixed(2)) }
  })(chartDataProp)

  const first: object[] = []
  const second: object[] = []
  let index = 0
  series?.forEach((v) => {
    if (v.value > 0) {
      index % 2 === 0 ? first.push(v) : second.push(v)
      index++
    }
  })
  return { first, second }
}
export const getFirstLevelReport = (name: string) => {
  switch (name) {
    case 'clients_to_process_tags':
    case 'process_tags_to_instances':
    case 'process_tag_instances_to_outputs':
      return 'clients_to_process_tags'
    case 'clients_to_process_delegators':
    case 'process_delegators_to_instances':
    case 'delegator_instances_to_outputs':
      return 'clients_to_process_delegators'
    case 'process_tags_to_budget':
    case 'instances_to_budget':
    case 'outputs_to_budget':
      return 'process_tags_to_budget'
    default:
      return name
  }
}

export const isBrowserCompatible = () => {
  let browser,
    isCompatible = false
  const userAgent = navigator.userAgent

  if (userAgent.includes('Firefox/')) {
    browser = `${userAgent.split('Firefox/')[1]}`
    browser = `${browser.split('.')[0]}`
    if (Number(browser) > 72) {
      isCompatible = true
      return true
    }
  } else if (userAgent.includes('Chrome/')) {
    browser = `${userAgent.split('Chrome/')[1]}`
    browser = `${browser.split('Safari/')[0]}`
    browser = `${browser.split('.')[0]}`
    if (Number(browser) > 80) {
      isCompatible = true
      return true
    }
  } else if (userAgent.includes('Safari/')) {
    browser = `${userAgent.split('Safari/')[1]}`
    browser = `${browser.split('.')[0]}`
    if (Number(browser) > 14) {
      isCompatible = true
      return true
    }
  } else if (userAgent.includes('Edg/')) {
    browser = `${userAgent.split('Edg/')[1]}`
    browser = `${browser.split('.')[0]}`
    if (Number(browser) > 80) {
      isCompatible = true
      return true
    }
  }
  return isCompatible
}

export const formatCSVFileData = (data: string) => {
  const rawHeaders = data.split(/[\r\n]/)[0].split(',')
  const headers = map((str: string) => str.toLowerCase())(rawHeaders)
  const tagNameColumn = includes('process tags category')(headers)
  const instanceIdColumn = includes('instance id')(headers)
  const validData = tagNameColumn && instanceIdColumn ? validCSVData({ headers, data }) : null
  const missingColumns = `${
    !tagNameColumn && !instanceIdColumn
      ? 'Process Tags Category and Instance Id are'
      : tagNameColumn
      ? 'Instance Id is'
      : 'Process Tags Category is'
  }`
  const errMessage =
    !tagNameColumn || !instanceIdColumn
      ? `Unable to process CSV.\nRequired column ${missingColumns} missing.`
      : ''
  return { validData, errMessage }
}

const validCSVData = ({ headers, data }: { headers: string[]; data: string }) => {
  const array = data.split(/[\n]/)
  const isCut = (val: string, headers: string[]) => val.split(/[\n,]/).length % headers.length !== 0
  const formattedData: { tagName: string; instanceId: number | null }[] = []

  map((arr: string, index: number) => {
    const datum: { tagName: string; instanceId: number | null } = {
      tagName: '',
      instanceId: 0,
    }
    if (index === 0) return
    headers.map((h, ind) => {
      switch (h) {
        case 'process tags category':
          return (datum['tagName'] = arr.split(',')[ind])
        case 'instance id':
          return (datum['instanceId'] = arr.split(',')[ind] ? Number(arr.split(',')[ind]) : null)
        default:
          return
      }
    })
    formattedData.push(datum)
  })(array)

  const headerRemovedData = formattedData.slice(1)

  if (isCut(data, headers)) headerRemovedData.slice(-1)

  const filteredData = filter((d: { tagName: string; instanceId: number }) =>
    isNumber(d.instanceId)
  )(headerRemovedData)
  return filteredData
}

export const mapTypeToInputType = (valueType: string): TFilterInputType => {
  switch (valueType) {
    case 'Int':
    case 'BigInt':
    case 'Float':
    case 'Decimal':
      return 'number'
    case 'Date':
    case 'DateTime':
      return 'date'
    case 'Boolean':
      return 'boolean'
    default:
      return 'text'
  }
}

export const formatFilterValueLabel = ({
  filterValue,
  filterValueType,
  filterOperatorType,
}: {
  filterValue: TFilterValue
  filterValueType: string
  filterOperatorType: string
}) => {
  if (!isFilterValueSet(filterOperatorType, filterValue)) return ''
  const inputType = mapTypeToInputType(filterValueType)
  const filterTypes = [...REPORT_FILTER_MAPPING[inputType], ...REPORT_FILTER_MAPPING.common]
  const filterTypeLabel = filterTypes.find(
    (filterType) => filterType.operator === filterOperatorType
  )?.label
  if (!filterTypeLabel) return ''
  switch (filterOperatorType) {
    case FILTER_OPERATOR.between:
      return (
        filterTypeLabel +
        ' ' +
        formatFilterValueByType((filterValue as IInterval).from, filterValueType) +
        ' and ' +
        formatFilterValueByType((filterValue as IInterval).to, filterValueType)
      )
    case FILTER_OPERATOR.null:
      return 'is NULL'
    case FILTER_OPERATOR.not_null:
      return 'is not NULL'

    case FILTER_OPERATOR.in:
      return filterTypeLabel + ' ' + join(', ')(filterValue as string[])
    default:
      return filterTypeLabel + ' ' + formatFilterValueByType(filterValue as string, filterValueType)
  }
}

export const formatFilterValueByType = <T extends string | number | boolean | null | undefined>(
  value: T,
  type: string
) => {
  if (!value) return
  const inputType = mapTypeToInputType(type)
  switch (inputType) {
    case 'date':
      if (!(isString(value) || isNumber(value))) return value
      return format(new Date(value), 'M/d/yyyy')
    case 'text':
      if (!(isString(value) || isNumber(value))) return value
      return `"${value}"`
    default:
      return value
  }
}

export const mapColumnOptionsToFilters = (filterColumnOptions: IFilterColumnOption[]) =>
  map(
    ({
      key,
      label,
      valueType,
      filterName,
      isVisible,
      isEditable,
      defaultValue,
      operator,
      isConfigurable,
    }: IFilterColumnOption): IFilter<TFilterValue> => ({
      key,
      value: defaultValue!,
      operator: operator!,
      defaultOpen: false,
      valueLabel: formatFilterValueLabel({
        filterOperatorType: operator!,
        filterValue: defaultValue!,
        filterValueType: valueType!,
      }),
      label,
      valueType,
      filterName,
      isEditable,
      isVisible,
      isConfigurable,
    })
  )(filterColumnOptions)

export const generateReportDynamicFilters = (
  reportFilters: IFilter<TFilterValue>[],
  unifiedFilters: IFilter<TFilterValue>[]
) =>
  reportFilters.map((reportFilter) => {
    const matchingLabelStoryFilter = unifiedFilters.find(
      (storyFilter) => storyFilter.label === reportFilter.label
    )
    return {
      ...reportFilter,
      value: matchingLabelStoryFilter?.value ?? reportFilter.value,
      operator: matchingLabelStoryFilter?.operator ?? reportFilter.operator,
      valueLabel: matchingLabelStoryFilter?.valueLabel ?? reportFilter.valueLabel,
    }
  })

export const hasBarsLimitExceeded = ({
  isGrouped,
  numOfLegends,
  numOfXAxisPoints,
}: {
  numOfXAxisPoints: number
  numOfLegends: number
  isGrouped: boolean
}) => {
  const barChartBarsLimit = 90
  const numberOfBars = (isGrouped ? numOfLegends : 1) * numOfXAxisPoints
  return numberOfBars > barChartBarsLimit
}
