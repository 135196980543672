import { safeJSONParse } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { SmallCheckbox } from '@invisible/ui/form'
import { Input } from '@invisible/ui/input'
import { format } from 'date-fns'
import dynamic from 'next/dynamic'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { TProperties, TType } from './tools'

const Editor = dynamic(() => import('react-json-view'), { ssr: false })

const FieldRenderers: Partial<
  Record<
    TType,
    // eslint-disable-next-line @typescript-eslint/ban-types
    FC<{
      value: unknown
      handleChange: (updatedValue: unknown) => void
      enumValues?: string[] | null
      arrayType?: TType
      schema?: TProperties['key']
      toolToBotResponses?: {
        index: number
        text: string
      }[]
    }>
  >
> = {
  str: ({ handleChange, value }) => (
    <Input
      className='w-full'
      defaultValue={value as string}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
    />
  ),
  string: ({ handleChange, enumValues, value }) => {
    if (enumValues)
      return (
        <Dropdown
          options={enumValues.map((val) => ({
            key: val,
            value: val,
          }))}
          selectedKey={value as string}
          maxHeight='200px'
          width='100%'
          onChange={({ key }) => handleChange(key)}
        />
      )
    return (
      <Input
        className='w-full'
        defaultValue={value as string}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
      />
    )
  },
  number: ({ value, handleChange }) => (
    <Input
      className='w-full'
      type='number'
      defaultValue={value as number}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(Number(e.target.value))}
    />
  ),
  int: ({ value, handleChange }) => (
    <Input
      className='w-full'
      type='number'
      defaultValue={value as number}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(Number(e.target.value))}
    />
  ),
  integer: ({ value, handleChange, toolToBotResponses, schema }) => {
    if (schema && 'xPreviousResponse' in schema) {
      return (
        <>
          <div>
            <pre className='mb-2 whitespace-normal break-words'>
              {JSON.stringify(toolToBotResponses?.find((r) => r.index === value)?.text)}
            </pre>
          </div>
          <Dropdown
            options={(toolToBotResponses ?? []).map((t) => ({
              key: String(t.index),
              value: JSON.stringify(t.text),
            }))}
            renderDropdownOption={(o) => <div title={o.value as string}>{o.value}</div>}
            maxHeight='200px'
            selectedKey={String(value)}
            width='100%'
            onChange={({ key }) => handleChange(Number(key))}
          />
        </>
      )
    }
    return (
      <Input
        className='w-full'
        type='number'
        defaultValue={value as number}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(Number(e.target.value))}
      />
    )
  },
  float: ({ value, handleChange }) => (
    <Input
      className='w-full'
      type='number'
      defaultValue={value as number}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(Number(e.target.value))}
    />
  ),
  decimal: ({ value, handleChange }) => (
    <Input
      className='w-full'
      type='number'
      defaultValue={value as number}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(Number(e.target.value))}
    />
  ),
  boolean: ({ value, handleChange }) => (
    <SmallCheckbox defaultChecked={Boolean(value)} onClick={(checked) => handleChange(checked)} />
  ),
  date: ({ value, handleChange }) => (
    <DateTimePicker
      value={value as Date}
      onChange={(date) => {
        if (date) handleChange(format(date, 'yyyy-MM-dd'))
      }}
      fullWidth
      hideTime
      inputReadonly
    />
  ),
  datetime: ({ value, handleChange }) => (
    <DateTimePicker
      value={value as Date}
      onChange={(date) => {
        if (date) handleChange(date.toISOString())
      }}
      fullWidth
      inputReadonly
    />
  ),
  'date-time': ({ value, handleChange }) => (
    <DateTimePicker
      value={value as Date}
      onChange={(date) => {
        if (date) handleChange(date.toISOString())
      }}
      fullWidth
      inputReadonly
    />
  ),
  json: ({ value, handleChange }) => (
    <div className='flex gap-2'>
      <Editor
        src={(value ?? {}) as object}
        name={null}
        displayDataTypes={true}
        enableClipboard={true}
        quotesOnKeys={false}
        validationMessage='Invalid JSON'
        onEdit={({ updated_src }) => handleChange(updated_src)}
        onAdd={({ updated_src }) => handleChange(updated_src)}
        onDelete={({ updated_src }) => handleChange(updated_src)}
        sortKeys
      />
      <Button
        variant='secondary'
        size='sm'
        onClick={async () => {
          const json = await navigator.clipboard.readText()
          handleChange(safeJSONParse(json))
        }}>
        Paste from clipboard
      </Button>
    </div>
  ),
  array: ({ value, handleChange, schema, arrayType }) => (
    <ArrayRenderer
      arrayType={arrayType}
      schema={schema}
      value={value}
      handleChange={handleChange}
    />
  ),
  object: ({ value, handleChange, schema }) => (
    <ObjectRenderer schema={schema} value={value} handleChange={handleChange} />
  ),
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ArrayRenderer: FC<{
  value: unknown
  handleChange: (updatedValue: unknown) => void
  arrayType?: TType
  schema?: TProperties['key']
}> = ({ value, handleChange, arrayType, schema }) => {
  const Field = arrayType ? FieldRenderers?.[arrayType] : FieldRenderers['string']
  const [arrayValues, setArrayValues] = useState(
    ((value ?? []) as unknown[]).map((v) => ({ value: v, id: uuid() }))
  )
  useEffect(() => {
    handleChange(arrayValues.map((v) => v.value))
  }, [arrayValues])

  if (!Field) return null
  return (
    <>
      {arrayValues.map((v, index) => (
        <div className='mb-2 flex items-center justify-between gap-2' key={v.id}>
          <Field
            value={v.value}
            handleChange={(updatedValue) =>
              setArrayValues((prev) =>
                prev.map((p, i) => (i === index ? { ...p, value: updatedValue } : p))
              )
            }
            schema={schema?.items}
            arrayType={schema?.items?.items?.type}
          />
          <Button
            icon='DeleteOutlineIcon'
            shape='square'
            variant='secondary'
            onClick={() => setArrayValues((prev) => prev.filter((p, i) => i !== index))}
          />
        </div>
      ))}
      <div className='mt-2 flex justify-end'>
        <Button
          icon='PlusIcon'
          shape='square'
          onClick={() => setArrayValues((prev) => [...prev, { value: null, id: uuid() }])}
        />
      </div>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ObjectRenderer: FC<{
  value: unknown
  handleChange: (updatedValue: unknown) => void
  schema?: TProperties['key']
}> = ({ value, handleChange, schema }) => {
  const [objectValues, setObjectValues] = useState<Record<string, unknown>>(
    (value ??
      Object.entries(schema?.properties ?? {}).reduce<Record<string, unknown>>(
        (acc, [key, curr]) => {
          acc[key] = curr.type === 'boolean' ? false : null
          return acc
        },
        {}
      )) as Record<string, unknown>
  )

  useEffect(() => {
    handleChange(
      Object.entries(objectValues).reduce((acc, [key, value]) => {
        if (value === null) return acc
        return { ...acc, [key]: value }
      }, {})
    )
  }, [objectValues])

  if (!schema) return null

  return (
    <>
      <div>{schema.description}</div>
      <div className='flex flex-wrap gap-3 rounded border border-solid border-gray-200 p-2'>
        {Object.entries(schema?.properties ?? {}).map(([key, prop]) => {
          const propertyType =
            prop.format ??
            (!Array.isArray(prop.type) ? prop.type : (prop.type.find((t) => t !== 'null') as TType))
          const Field = FieldRenderers?.[propertyType] ?? FieldRenderers['string']
          if (!Field) return null

          return (
            <div className='mb-2 flex items-center gap-1' key={key}>
              <div className='flex flex-col'>
                <div>
                  {key} {schema.required?.includes?.(key) ? '*' : ''}
                </div>
                <div className='mb-2 text-xs text-gray-400'>{prop.description}</div>
              </div>
              <div>
                <Field
                  value={objectValues[key]}
                  handleChange={(updatedValue) =>
                    setObjectValues((prev) => ({ ...prev, [key]: updatedValue }))
                  }
                  schema={prop}
                  arrayType={prop.items?.type}
                />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export { FieldRenderers }
