import {
  TProcessByIdStep,
  TProcessLifecycleStage,
  TProcessStepGoTo,
} from '@invisible/common/components/process-base'
import { SHADOW_STEP_TEMPLATE_ID } from '@invisible/ultron/shared'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { TProcessLayoutOrientationEnum } from '@invisible/ultron/zod'
import { compact, isEqual } from 'lodash/fp'
import { RuleGroupTypeIC } from 'react-querybuilder'
import { applyEdgeChanges, applyNodeChanges, Edge, EdgeChange, Node, NodeChange } from 'reactflow'

import { EDGE_TYPES, NODE_TYPES, OPTIMISTIC_STEP_ID } from '../constants'
import { StoreSlice } from '../index'
import { createGraphLayoutElk } from './autoLayout'

type TProcessLayout = NonNullable<inferQueryOutput<'process.getLayout'>>
export interface IProcessBuilderSlice {
  positionedNodes: Node[]
  positionedEdges: Edge[]
  processId: string
  shouldAutoLayout: boolean
  lastStepPosition: { x: number; y: number } | null
  graphHorizontalWidth: number
  showStages: boolean
  currentLayoutId: string | null
  layoutOrientation: 'horizontal' | 'vertical'
  activeBranchStepConfig: RuleGroupTypeIC | null

  convertProcessToNodesAndEdges: (arg: {
    steps: TProcessByIdStep[]
    stepGoTos: TProcessStepGoTo[]
    processId: string
    rootBaseId: string
    processStatus: string
    stages: TProcessLifecycleStage[]
    layout?: TProcessLayout | null
    forceAutoLayout?: boolean
  }) => void
  onNodeChanges: (changes: NodeChange[]) => void
  onEdgeChanges: (changes: EdgeChange[]) => void
  autoLayout: () => void
  disableAutoLayout: () => void
  addStepPosition: (position: { x: number; y: number }) => void
  setGraphHorizontalWidth: (width: number) => void
  setStageViewActive: (showStages: boolean) => void
  setCurrentLayoutId: (layoutId: string | null) => void
  setLayoutOrientation: (orientation: 'horizontal' | 'vertical') => void
  setActiveBranchStepConfig: (config: RuleGroupTypeIC) => void
}

export const createProcessBuilderSlice: StoreSlice<IProcessBuilderSlice> = (set, get) => ({
  positionedNodes: [],
  positionedEdges: [],
  lastStepPosition: null,
  processId: '',
  shouldAutoLayout: true,
  graphHorizontalWidth: 200,
  showStages: false,
  currentLayoutId: null,
  layoutOrientation: 'vertical',
  activeBranchStepConfig: null,

  // Functions
  convertProcessToNodesAndEdges: async ({
    steps,
    stepGoTos,
    processId,
    rootBaseId,
    processStatus,
    stages,
    layout,
    forceAutoLayout,
  }) => {
    const existingNodes = get().positionedNodes
    const showStages = get().showStages
    let nodes: Node[] = [
      ...(showStages
        ? // Add only stages that have steps assigned
          compact(
            stages.map((stage) =>
              !steps.find((step) => step.lifecycleStageId === stage.id)
                ? null
                : {
                    id: stage.id,
                    type: NODE_TYPES.STAGE,
                    data: { label: stage.name, type: NODE_TYPES.STAGE },
                    position: existingNodes.find((n) => n.id === stage.id)?.position ?? {
                      x: 0,
                      y: 0,
                    },
                  }
            )
          )
        : []),
      ...(steps.map((step) => ({
        id: step.id,
        selectable: step.id !== OPTIMISTIC_STEP_ID,
        connectable: step.id !== OPTIMISTIC_STEP_ID,
        draggable: step.id !== OPTIMISTIC_STEP_ID,
        type:
          step.stepTemplate.type === 'trigger'
            ? NODE_TYPES.TRIGGER
            : step.stepTemplate.subtype === 'end_process'
            ? NODE_TYPES.END
            : step.stepTemplate.subtype === 'attended_map'
            ? NODE_TYPES.ATTENDED_MAP
            : step.stepTemplateId === SHADOW_STEP_TEMPLATE_ID
            ? NODE_TYPES.IGNORE
            : NODE_TYPES.REGULAR,
        data: {
          step,
          type:
            step.stepTemplate.type === 'trigger'
              ? NODE_TYPES.TRIGGER
              : step.stepTemplate.subtype === 'end_process'
              ? NODE_TYPES.END
              : step.stepTemplate.subtype === 'attended_map'
              ? NODE_TYPES.ATTENDED_MAP
              : step.stepTemplateId === SHADOW_STEP_TEMPLATE_ID
              ? NODE_TYPES.IGNORE
              : NODE_TYPES.REGULAR,
        },
        position: existingNodes.find((n) => n.id === step.id)?.position ??
          existingNodes.find((n) => n.id === OPTIMISTIC_STEP_ID)?.position ??
          get().lastStepPosition ?? { x: 0, y: 0 },
        selected: existingNodes.find((n) => n.id === step.id)?.selected ?? false,
        parentNode: showStages ? step.lifecycleStageId ?? undefined : undefined,
      })) ?? []),
    ]

    let edges: Edge[] =
      stepGoTos.map((stepGoTo, index) => ({
        id: `${stepGoTo.goFromStepId}-${stepGoTo.goToStepId}-${index}`,
        source: stepGoTo.goFromStepId,
        target: stepGoTo.goToStepId,
        type: EDGE_TYPES.DEFAULT,
        data: {
          stepGoTo,
          type: EDGE_TYPES.DEFAULT,
          isNonEditableProcess: processStatus === 'active' || processStatus === 'testing',
          processRootBaseId: rootBaseId,
        },
      })) ?? []

    // If it's a brand new process there would be a single step with no stepGoTos
    if (edges.length === 0 && nodes.length === 1) {
      nodes.push({
        id: 'placeholder-1',
        type: NODE_TYPES.TRIGGER_PLACEHOLDER,
        data: {
          type: NODE_TYPES.TRIGGER_PLACEHOLDER,
          baseId: steps?.[0]?.baseId,
          processId,
          processRootBaseId: rootBaseId,
        },
        position: existingNodes.find((n) => n.id === 'placeholder-1')?.position ?? { x: 0, y: 0 },
      })

      edges.push({
        id: 'edge-1',
        source: nodes.find((n) => n.type === NODE_TYPES.TRIGGER_PLACEHOLDER)?.id as string,
        target: nodes.find((n) => n.type === NODE_TYPES.REGULAR)?.id as string,
        type: EDGE_TYPES.INITIAL_TRIGGER,
        data: {
          type: EDGE_TYPES.INITIAL_TRIGGER,
        },
      })
    }

    const isNodeAdditionOrDeletion = existingNodes.length !== nodes.length

    if (layout) {
      const positionedNodes = nodes.map((node) => {
        const nodecoordinates = layout.stepPositions.find((l) => l.stepId === node.id)
        if (nodecoordinates) {
          return {
            ...node,
            position: {
              x: nodecoordinates.xCoordinate,
              y: nodecoordinates.yCoordinate,
            },
          }
        }

        const lastStepPosition = get().lastStepPosition

        if (lastStepPosition) {
          return {
            ...node,
            position: {
              x: lastStepPosition.x,
              y: lastStepPosition.y,
            },
          }
        }
        return node
      })
      nodes = positionedNodes
    }

    // only auto layout when the flag is set and either the number of nodes change, it's a new process, or a node's stage changed
    else if (
      get().shouldAutoLayout &&
      (forceAutoLayout ||
        isNodeAdditionOrDeletion ||
        processId !== get().processId ||
        !isEqual(
          nodes.map((n) => n.parentNode).sort(),
          existingNodes.map((n) => n.parentNode).sort()
        ))
    ) {
      const { nodes: positionedNodes, edges: positionedEdges } = await createGraphLayoutElk(
        nodes,
        edges,
        get().graphHorizontalWidth,
        get().showStages,
        get().layoutOrientation
      )
      nodes = positionedNodes
      edges = positionedEdges
    }

    set((prev) => ({
      ...prev,
      positionedNodes: nodes,
      positionedEdges: edges,
      processId,
      shouldAutoLayout: true,
      lastStepPosition: null,
      ...(layout?.orientation
        ? { layoutOrientation: layout.orientation as TProcessLayoutOrientationEnum }
        : {}),
    }))
  },

  onNodeChanges: (changes: NodeChange[]) => {
    const nodes = get().positionedNodes
    set((prev) => ({ ...prev, positionedNodes: applyNodeChanges(changes, nodes) }))
  },

  onEdgeChanges: (changes: EdgeChange[]) => {
    const edges = get().positionedEdges
    set((prev) => ({ ...prev, positionedEdges: applyEdgeChanges(changes, edges) }))
  },

  disableAutoLayout: () => {
    set((prev) => ({ ...prev, shouldAutoLayout: false }))
  },

  autoLayout: async () => {
    const { nodes: positionedNodes, edges: positionedEdges } = await createGraphLayoutElk(
      get().positionedNodes,
      get().positionedEdges,
      get().graphHorizontalWidth,
      get().showStages,
      get().layoutOrientation
    )

    set((prev) => ({
      ...prev,
      positionedNodes,
      positionedEdges,
    }))
  },

  addStepPosition: (position) =>
    set((prev) => ({
      ...prev,
      lastStepPosition: position,
    })),

  setGraphHorizontalWidth: (width) =>
    set((prev) => ({
      ...prev,
      graphHorizontalWidth: width,
    })),

  setStageViewActive: (showStages) => {
    set((prev) => ({
      ...prev,
      showStages,
    }))
  },

  setCurrentLayoutId: (layoutId) => {
    set((prev) => ({
      ...prev,
      currentLayoutId: layoutId,
    }))
  },

  setLayoutOrientation: (orientation) => {
    set((prev) => ({
      ...prev,
      layoutOrientation: orientation,
    }))
  },

  setActiveBranchStepConfig: (config) => {
    set((prev) => ({
      ...prev,
      activeBranchStepConfig: config,
    }))
  },
})
