import { parseDataFilters, TIMEZONE } from '@invisible/common/helpers'
import { IDataFilter, TFilterValue } from '@invisible/common/types'
import { useReportData } from '@invisible/hooks/use-report'
import { useFilters } from '@invisible/hooks/use-report-dynamic-filters'
import { useSelectedCompany } from '@invisible/hooks/use-selected-company'
import type { ReportName } from '@invisible/ui/constants'
import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { usePortalReportMeta, useReportCustomDateRange } from '../hooks/'

export enum ReportActionType {
  SET_REPORT_NAME = 'setReportName',
  SET_PREVIOUS_REPORT_NAME = 'setPreviousReportName',
  SET_REPORT_QUERY_FILTERS = 'setReportQueryFilters',
  SET_MULTIPLE = 'setMultiple',
}

type ReportAction =
  | {
      type: ReportActionType.SET_REPORT_NAME
      payload: ReportName
    }
  | {
      type: ReportActionType.SET_PREVIOUS_REPORT_NAME
      payload: ReportName
    }
  | {
      type: ReportActionType.SET_REPORT_QUERY_FILTERS
      payload: IDataFilter<TFilterValue>[]
    }
  | {
      type: ReportActionType.SET_MULTIPLE
      payload: Partial<ReportState>
    }

type ReportState = {
  reportName: ReportName
  previousReportName?: ReportName
  reportQueryFilters: IDataFilter<TFilterValue>[]
}

const initialReportState: ReportState = {
  reportName: 'process_tags_to_instances',
  previousReportName: undefined,
  reportQueryFilters: [],
}

const ReportContext = createContext<{
  state: ReportState
  dispatch: React.Dispatch<ReportAction>
}>({
  state: {
    ...initialReportState,
  },
  dispatch: () => null,
})

const reportReducer = (state: ReportState, action: ReportAction) => {
  switch (action.type) {
    case ReportActionType.SET_REPORT_NAME: {
      return {
        ...state,
        reportName: action.payload,
      }
    }
    case ReportActionType.SET_PREVIOUS_REPORT_NAME: {
      return {
        ...state,
        previousReportName: action.payload,
      }
    }
    case ReportActionType.SET_REPORT_QUERY_FILTERS: {
      return {
        ...state,
        reportQueryFilters: action.payload,
      }
    }
    case ReportActionType.SET_MULTIPLE: {
      return {
        ...state,
        ...action.payload,
      }
    }
  }
}

const useReportContext = () => {
  const context = useContext(ReportContext)
  if (context === undefined) {
    throw new Error('useReportContext must be used within a ReportProvider')
  }
  return context
}

type ReportProviderProps = {
  reportName: ReportName
  children: React.ReactNode
}

export const useFormattedReport = (reportName: ReportName) => {
  const [selectedCompany] = useSelectedCompany()
  const { state } = useReportContext()

  const {
    data: { completeReportMeta },
    isLoading: reportMetaDataIsLoading,
  } = usePortalReportMeta(reportName)

  const { data: formattedReport, isLoading: formattedReportIsLoading } = useReportData({
    reportId: completeReportMeta?.id,
    filters: state.reportQueryFilters,
  })

  const isLoading = reportMetaDataIsLoading || formattedReportIsLoading

  return {
    data: formattedReport,
    isLoading,
  }
}

const ReportProvider = ({ reportName, children }: ReportProviderProps) => {
  const [selectedCompany] = useSelectedCompany()
  const { formattedPeriod } = useReportCustomDateRange()
  const { storedFilters } = useFilters(reportName)

  const [state, dispatch] = useReducer(reportReducer, {
    ...initialReportState,
    reportName,
  })

  useEffect(() => {
    dispatch({
      type: ReportActionType.SET_REPORT_QUERY_FILTERS,
      payload: parseDataFilters({
        timeZone: TIMEZONE.UTC,
        formattedPeriod,
        scope: {
          type: 'company',
          scopedId: selectedCompany?.id,
        },
        reportDynamicFilters: storedFilters,
      }),
    })
  }, [selectedCompany?.id, formattedPeriod])

  return (
    <ReportContext.Provider
      value={{
        state,
        dispatch,
      }}>
      {children}
    </ReportContext.Provider>
  )
}

export { ReportProvider, useReportContext }
