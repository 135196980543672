import { IUserPermissionsQuery, useUserPermissionsQuery } from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { useGate } from 'statsig-react'
import { JsonValue } from 'type-fest'

interface UseLoggedInUserPermissionsProps {
  onlyBaseView: boolean
  refetchBehaviour?: {
    refetchOnMount: boolean
    refetchInterval: number
    keepPreviousData: boolean
    refetchOnWindowFocus: boolean
  }
}

export type TUserPermission = {
  action: string
  subject: string
  fields?: string[] | null
  description: string | null
  conditions: JsonValue | null
}

type TUserPermissionResponseData = IUserPermissionsQuery['me']['permissions'] | inferQueryOutput<'permission.findByLoggedInUser'> | undefined

const mapResponseToUserPermissions = (
  data: TUserPermissionResponseData,
): TUserPermission[] | undefined => {
  if (!data) return undefined
  return (data ?? []).map((permission) => {
    const fields = Array.isArray(permission.fields)
      ? (permission.fields.length !== 0) ? permission.fields : null
      : null

    return {
      action: permission.action,
      subject: permission.subject,
      fields,
      conditions: permission.conditions ?? null,
      description: permission.description ?? null,
    }
  })
}

const useLoggedInUserPermissions = ({
  onlyBaseView,
  refetchBehaviour,
}: UseLoggedInUserPermissionsProps) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-find-by-logged-in-user-query')

  // tRPC query
  const { data: trpcResult, isLoading: isTrpcPermissionsLoading } = useTrpcQuery(
    ['permission.findByLoggedInUser', { onlyBaseView }],
    {
      enabled: !isGraphqlEnabled,
      ...refetchBehaviour,
    }
  )

  // GraphQL query
  const { data: graphqlResult, isLoading: isGraphqlPermissionsLoading } = useUserPermissionsQuery(
    { onlyBaseView },
    {
      enabled: isGraphqlEnabled,
      ...refetchBehaviour,
    }
  )

  const data = isGraphqlEnabled ? mapResponseToUserPermissions(
    graphqlResult?.me?.permissions
  ) : trpcResult

  return {
    data,
    isLoading: isGraphqlEnabled ? isGraphqlPermissionsLoading : isTrpcPermissionsLoading,
  }
}

export { useLoggedInUserPermissions }
