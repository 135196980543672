export const SeparatorReport = ({ title, subtitle }: { title: string; subtitle?: string }) => (
  <div className='border-theme-weak-2 relative rounded-xl border border-solid bg-white p-4 shadow-md'>
    <div className='flex flex-col items-center justify-center'>
      <h3 className='my-2 text-xl font-normal'>{title}</h3>
      {subtitle && (
        <p className={'text-grayScale-600 my-2 text-sm font-normal leading-6'}>{subtitle}</p>
      )}
    </div>
  </div>
)
