import ContentLoader from 'react-content-loader'

interface ITableProps {
  width: number | string
  height: number | string
  rx?: number
  ry?: number
  className?: string
  bgColor: string
}

const TableRow = ({ width, height, rx, ry, bgColor }: ITableProps) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor={bgColor}
    foregroundColor='#ecebeb'>
    <rect x='8' y='1' rx={rx} ry={ry} width={width} height={height} />
  </ContentLoader>
)

export const ProcessTableSkeleton = () => (
  <div className='flex w-full flex-col justify-start gap-4'>
    <TableRow width={'100%'} height={50} rx={4} ry={4} bgColor={'#d9d9d9'} className='rounded' />
    <TableRow width={'100%'} height={50} bgColor={'#f3f3f3'} />
    <TableRow width={'100%'} height={50} bgColor={'#f3f3f3'} />
    <TableRow width={'100%'} height={50} rx={2} bgColor={'#f3f3f3'} />
    <TableRow width={'100%'} height={50} rx={2} bgColor={'#f3f3f3'} />
    <TableRow width={'100%'} height={50} rx={2} bgColor={'#f3f3f3'} />
  </div>
)
