import { z } from '@invisible/zod'

/**
 * BaseVariableValuesUpdateStepMeta step metadata contains a destinationBaseId and action to perform on it and baseVariableMappings to map base variables from source base to destination base variables.
 * These mappings are used to copy values from source run base variables to destination base run variables.
 *
 */
const schema = z.object({
  destinationBaseId: z.string().uuid().optional(),
  sourceBaseId: z.string().uuid().optional(),
  strategy: z
    .enum([
      'COPY_FROM_SAME_BASE',
      'COPY_PARENT_TO_CHILD',
      'COPY_PARENT_TO_ALL_CHILD',
      'COPY_CHILD_TO_PARENT:FIRST',
      'COPY_CHILD_TO_PARENT:LAST',
    ])
    .optional(),
  baseVariableMappings: z.array(
    z.object({
      sourceBaseVariableId: z.string().uuid().optional(),
      destinationBaseVariableId: z.string().uuid().optional(),
    })
  ),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
