export const uiLineChartData = [
  {
    id: 'japan',
    color: 'hsl(57, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 96,
      },
      {
        x: 'helicopter',
        y: 76,
      },
      {
        x: 'boat',
        y: 30,
      },
      {
        x: 'train',
        y: 277,
      },
      {
        x: 'subway',
        y: 88,
      },
      {
        x: 'bus',
        y: 190,
      },
      {
        x: 'car',
        y: 155,
      },
      {
        x: 'moto',
        y: 161,
      },
      {
        x: 'bicycle',
        y: 61,
      },
      {
        x: 'horse',
        y: 176,
      },
      {
        x: 'skateboard',
        y: 49,
      },
      {
        x: 'others',
        y: 197,
      },
    ],
  },
  {
    id: 'france',
    color: 'hsl(171, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 273,
      },
      {
        x: 'helicopter',
        y: 161,
      },
      {
        x: 'boat',
        y: 84,
      },
      {
        x: 'train',
        y: 89,
      },
      {
        x: 'subway',
        y: 45,
      },
      {
        x: 'bus',
        y: 290,
      },
      {
        x: 'car',
        y: 30,
      },
      {
        x: 'moto',
        y: 249,
      },
      {
        x: 'bicycle',
        y: 158,
      },
      {
        x: 'horse',
        y: 300,
      },
      {
        x: 'skateboard',
        y: 244,
      },
      {
        x: 'others',
        y: 265,
      },
    ],
  },
  {
    id: 'us',
    color: 'hsl(120, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 99,
      },
      {
        x: 'helicopter',
        y: 182,
      },
      {
        x: 'boat',
        y: 121,
      },
      {
        x: 'train',
        y: 197,
      },
      {
        x: 'subway',
        y: 83,
      },
      {
        x: 'bus',
        y: 251,
      },
      {
        x: 'car',
        y: 208,
      },
      {
        x: 'moto',
        y: 280,
      },
      {
        x: 'bicycle',
        y: 192,
      },
      {
        x: 'horse',
        y: 156,
      },
      {
        x: 'skateboard',
        y: 2,
      },
      {
        x: 'others',
        y: 114,
      },
    ],
  },
  {
    id: 'germany',
    color: 'hsl(26, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 19,
      },
      {
        x: 'helicopter',
        y: 248,
      },
      {
        x: 'boat',
        y: 66,
      },
      {
        x: 'train',
        y: 189,
      },
      {
        x: 'subway',
        y: 274,
      },
      {
        x: 'bus',
        y: 212,
      },
      {
        x: 'car',
        y: 20,
      },
      {
        x: 'moto',
        y: 191,
      },
      {
        x: 'bicycle',
        y: 299,
      },
      {
        x: 'horse',
        y: 287,
      },
      {
        x: 'skateboard',
        y: 9,
      },
      {
        x: 'others',
        y: 167,
      },
    ],
  },
  {
    id: 'norway',
    color: 'hsl(229, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 248,
      },
      {
        x: 'helicopter',
        y: 191,
      },
      {
        x: 'boat',
        y: 20,
      },
      {
        x: 'train',
        y: 206,
      },
      {
        x: 'subway',
        y: 90,
      },
      {
        x: 'bus',
        y: 128,
      },
      {
        x: 'car',
        y: 280,
      },
      {
        x: 'moto',
        y: 121,
      },
      {
        x: 'bicycle',
        y: 216,
      },
      {
        x: 'horse',
        y: 274,
      },
      {
        x: 'skateboard',
        y: 10,
      },
      {
        x: 'others',
        y: 175,
      },
    ],
  },
]
