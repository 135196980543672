import {
  NotFoundImg,
  Random_Agent_1,
  Random_Agent_2,
  Random_Agent_3,
  Random_Agent_4,
  Random_Agent_5,
} from '@invisible/ui/images'
import { styled } from '@invisible/ui/themes'
import Image from 'next/image'
import Link from 'next/link'

const agentPhotos = [Random_Agent_1, Random_Agent_2, Random_Agent_3, Random_Agent_4, Random_Agent_5]

const agentImageUrl = agentPhotos[Math.floor(Math.random() * 5)]

const NotFoundImage = styled(NotFoundImg)`
  margin-top: 2rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const NotFound = () => (
  <div className='my-0 mx-[1em] text-center md:my-0 md:mx-[2em] lg:mx-[1em] lg:my-[2rem]'>
    <div className='relative z-0'>
      <NotFoundImage width={650} height={430} />
      <div className='absolute left-0 top-0 right-0 bottom-0 m-auto h-[61%] w-[40%] md:h-[265px] md:w-[265px]'>
        <Image
          className='rounded-full grayscale'
          src={agentImageUrl}
          alt='Picture of an agent'
          layout='fill'
        />
      </div>
    </div>
    <p className='relative -mt-2 px-[10px] font-sans text-[1.2rem] font-semibold text-black md:px-0 md:text-[1.5rem]'>
      What you are looking for is not here 👀
    </p>
    <p className='relative px-[10px] font-sans text-[0.9rem] font-light text-black md:px-0 md:text-[1.2rem]'>
      Lucky you, an agent is here to help.
    </p>
    <Link href='/'>
      <a className='rounded-[37px] border-0 bg-[#542cea] py-[0.2rem] px-[0.5rem] text-base text-[#efefef] no-underline'>
        Go Home
      </a>
    </Link>
  </div>
)
