import { SVGProps } from 'react'

function LanguageRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.48599 7.24431C10.8221 6.35152 12.393 5.875 14 5.875C16.1542 5.87727 18.2195 6.73403 19.7427 8.25727C21.266 9.78051 22.1227 11.8458 22.125 14C22.125 15.607 21.6485 17.1779 20.7557 18.514C19.8629 19.8502 18.594 20.8916 17.1093 21.5065C15.6247 22.1215 13.991 22.2824 12.4149 21.9689C10.8388 21.6554 9.39106 20.8815 8.25476 19.7452C7.11846 18.6089 6.34462 17.1612 6.03112 15.5851C5.71761 14.009 5.87852 12.3753 6.49348 10.8907C7.10844 9.40605 8.14984 8.1371 9.48599 7.24431ZM20.375 16.5734C20.7059 15.7558 20.8757 14.882 20.875 14C20.8741 12.698 20.5039 11.4231 19.8075 10.323C19.1111 9.22296 18.117 8.34297 16.9406 7.78516L16.9688 7.83594C17.1073 8.08276 17.1575 8.36942 17.1112 8.64865C17.0649 8.92788 16.9248 9.18298 16.7141 9.37187L14.6117 11.2734C14.54 11.3377 14.4614 11.3938 14.3773 11.4406L13.4203 11.9688C13.2354 12.0708 13.0277 12.1246 12.8164 12.125H11.5109L10.9 13.1984L11.525 13.3336C11.7104 13.3734 11.8843 13.4552 12.0332 13.5726C12.1822 13.69 12.3023 13.84 12.3844 14.0109L12.6813 14.625H13.3625C13.4873 14.411 13.6725 14.2386 13.8948 14.1293C14.1172 14.0201 14.3668 13.9789 14.6125 14.0109L16.3953 14.2516C16.5682 14.2748 16.7343 14.3342 16.8828 14.4258L20.375 16.5734ZM7.79219 11.0469C7.35194 11.969 7.12396 12.9781 7.125 14C7.12798 15.7959 7.83272 17.5196 9.08885 18.8031C10.345 20.0867 12.053 20.8285 13.8484 20.8703L14 20.0891L12.468 18.5109L12.457 18.5C12.2912 18.3204 12.1816 18.0962 12.1419 17.855C12.1022 17.6138 12.1341 17.3662 12.2336 17.143L12.7984 15.875H12.6828C12.4484 15.8744 12.2188 15.8082 12.02 15.6839C11.8212 15.5597 11.6611 15.3823 11.5578 15.1719L11.2602 14.5531L9.58594 14.193C9.38132 14.1491 9.19098 14.0546 9.03237 13.9181C8.87376 13.7817 8.75196 13.6075 8.67813 13.4117L7.79219 11.0469Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40608 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50334 20.9221 7.21716 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LanguageRibbon
