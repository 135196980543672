import { withErrorBoundary } from '@invisible/common/components/error-boundary'
import { generateTargetLayer } from '@invisible/common/components/reports'
import { formatUnit, generateSumSeries, getMaxValue, getMinValue } from '@invisible/common/helpers'
import { Line } from '@invisible/common/types'
import { BarDatum, BarTooltipProps, ComputedDatum, ResponsiveBar } from '@nivo/bar'
import { OrdinalColorScaleConfig } from '@nivo/colors'
import { ColorSchemeId } from '@nivo/colors'
import { ValueFormat } from '@nivo/core'
import { Theme } from '@nivo/core'
import { isEmpty, some } from 'lodash/fp'
import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { BarChartSkeleton } from './skeleton-components'

export interface UiBarChartProps {
  height?: string
  width?: number
  chartDataProp?: BarDatum[]
  chartSubDataProp?: BarDatum
  chartSubSubDataProp?: BarDatum
  isLoading?: boolean
  handleNodeClick?: (
    node: ComputedDatum<BarDatum> & {
      color: string
    }
  ) => void
  theme?: Theme
  colors: OrdinalColorScaleConfig<ComputedDatum<BarDatum>> | ColorSchemeId
  labelTextColor?: string
  layout?: 'horizontal' | 'vertical'
  groupMode?: 'stacked' | 'grouped'
  showLegends?: boolean
  keys?: string[]
  defs?: {
    id: string
    type: string
    background: string
    color: string
    size: number
    padding: number
    stagger: boolean
  }[]
  fill?: {
    match: {
      id: string
    }
    id: string
  }[]
  axisLeft?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  axisRight?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  axisTop?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  axisBottom?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  indexBy?: string
  labelSkipHeight?: number
  labelSkipWidth?: number
  minValue?: number | 'auto'
  maxValue?: number | 'auto'
  padding?: number
  factor?: number
  enableLabel?: boolean
  enableLegends?: boolean
  enableGridY?: boolean
  showTotal?: boolean
  skeletonPadding?: string
  margin?: object

  // eslint-disable-next-line @typescript-eslint/ban-types
  tooltip?: React.FC<BarTooltipProps<BarDatum>>
  valueFormat?: ValueFormat<number>
  targetLine?: Line
}

const StyledBarChart = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? '600px'};
`
const SkeletonDiv = styled.div<{ skeletonPadding?: string }>`
  display: flex;
  margin: ${({ skeletonPadding }) => skeletonPadding ?? '0px'};
  justify-content: center;
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const BarChart: FC<UiBarChartProps> = withErrorBoundary(
  ({
    height,
    chartDataProp,
    chartSubDataProp,
    chartSubSubDataProp,
    isLoading,
    colors,
    factor = 1,
    showLegends,
    theme,
    handleNodeClick,
    labelTextColor,
    layout,
    groupMode,
    keys,
    defs,
    fill,
    indexBy,
    labelSkipHeight,
    labelSkipWidth,
    minValue,
    maxValue,
    padding,
    enableLabel,
    enableLegends,
    enableGridY,
    axisLeft,
    axisRight,
    axisTop,
    axisBottom,
    margin,
    skeletonPadding,
    showTotal,
    tooltip,
    valueFormat,
    targetLine,
    ...props
  }) => {
    const [showAlternate, setShowAlternate] = useState<boolean>(false)
    const [sumDefaultSeries, setSumDefaultSeries] = useState<object[]>([])
    const [alternateSumSeries, setAlternateSumSeries] = useState<object[]>([])

    useEffect(() => {
      const { first, second } = generateSumSeries({
        chartDataProp: chartDataProp ?? [],
        keys,
        indexBy,
      })
      setSumDefaultSeries(first)
      setAlternateSumSeries(second)
      setShowAlternate(false)
    }, [chartDataProp, indexBy, keys])

    // The Bar Chart does not automatically update its max value and min value along Y-Axis according to the Target Lines
    // rendered by Target layer. This results in Target Lines being overflowed out of the SVG. This Object stores minumum
    // and the maximum value in all of the Target Lines which is then passed to nivo/bar component to adjust its max and
    // min value
    const TargetMinMaxValues = useMemo(() => {
      if (!targetLine) return
      const targetYValues = targetLine.data.map((point) => point.y)
      return { max: Math.max(...targetYValues), min: Math.min(...targetYValues) }
    }, [targetLine])

    const getYAxisMinValue = () => {
      if (minValue === 'auto') return minValue
      return getMinValue(minValue, TargetMinMaxValues?.min)
    }

    const getYAxisMaxValue = () => {
      if (maxValue === 'auto') return maxValue
      return getMaxValue(maxValue, TargetMinMaxValues?.max)
    }

    const Total = ({
      x,
      sum,
      yScale,
      index,
    }: {
      x: { x: string | number; width: number }
      sum: { index: number; value: number }
      yScale: any
      index: number
    }) => (
      <g transform={`translate(${x?.x}, ${yScale(sum?.value) - 18})`} key={sum?.index - index}>
        <text
          x={x?.width / 2}
          y={5}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fontSize: '10px',
            fontWeight: '500',
          }}>
          {sum?.value > 0
            ? `${formatUnit({
                valueType: 'Number',
                value: sum?.value || '',
              })}`
            : ''}
        </text>
      </g>
    )

    const generateSum = ({ series, bars, yScale }: any) =>
      series?.map((s: { index: number; value: number }, i: number) => {
        const x = bars.find(
          (f: { key: string | number; data: { id: string | number } }) =>
            f.key === f.data.id + '.' + s.index
        )
        return <Total key={i} yScale={yScale} sum={s} x={x} index={i} />
      })

    const series = showAlternate ? alternateSumSeries : sumDefaultSeries

    const Sum = ({ bars, yScale }: any) =>
      showTotal ? generateSum({ series, bars, yScale }) : null

    const Target = targetLine ? [generateTargetLayer([targetLine])] : []

    const handleMouseEnter = (d: ComputedDatum<BarDatum>, e: any) => {
      e.target.style.cursor = 'pointer'
      if (!some((s: { index: number | string }) => d.indexValue === s.index)(sumDefaultSeries)) {
        setShowAlternate(true)
      }
    }

    const handleMouseLeave = (d: ComputedDatum<BarDatum>, e: any) => {
      if (!some((s: { index: number | string }) => d.indexValue === s.index)(sumDefaultSeries)) {
        setShowAlternate(false)
      }
    }

    return (
      <div>
        {isLoading || !chartDataProp || isEmpty(chartDataProp) ? (
          <SkeletonDiv skeletonPadding={skeletonPadding}>
            <BarChartSkeleton layout={layout} factor={factor} animate={isLoading} />
          </SkeletonDiv>
        ) : (
          <StyledBarChart height={height}>
            <ResponsiveBar
              labelSkipHeight={labelSkipHeight ?? 0}
              labelSkipWidth={labelSkipWidth ?? 0}
              data={chartDataProp}
              keys={keys}
              valueFormat={valueFormat}
              tooltip={tooltip}
              onClick={(
                node: ComputedDatum<BarDatum> & {
                  color: string
                }
              ) => handleNodeClick && handleNodeClick(node)}
              onMouseEnter={(datum, event) => handleMouseEnter(datum, event)}
              onMouseLeave={(datum, event) => handleMouseLeave(datum, event)}
              indexBy={indexBy}
              labelTextColor={labelTextColor}
              margin={margin ?? { top: 50, right: 20, bottom: 20, left: 20 }}
              padding={padding}
              layout={layout}
              minValue={getYAxisMinValue()}
              maxValue={getYAxisMaxValue()}
              enableLabel={enableLabel}
              groupMode={groupMode}
              enableGridY={enableGridY}
              colors={typeof colors === 'string' ? { scheme: colors as ColorSchemeId } : colors}
              defs={defs}
              fill={fill}
              axisTop={axisTop ?? null}
              axisRight={axisRight ?? null}
              axisBottom={axisBottom ?? null}
              layers={['grid', 'axes', 'bars', Sum, 'markers', 'legends', ...Target]}
              axisLeft={axisLeft}
              theme={theme}
              legends={
                showLegends
                  ? [
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]
                  : undefined
              }
              {...props}
            />
          </StyledBarChart>
        )}
      </div>
    )
  }
)
export default BarChart
