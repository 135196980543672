import { SVGProps } from 'react'

function NotionOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.04334 6.38195C7.18405 7.30219 7.59665 7.22954 9.73245 7.08424L29.8526 5.87339C30.2895 5.87339 29.9255 5.43748 29.7798 5.38905L26.4305 2.99157C25.7995 2.50723 24.9257 1.92602 23.2996 2.07132L3.83473 3.50012C3.13089 3.57277 2.98526 3.93603 3.27651 4.20242L6.04334 6.38195ZM7.25686 11.0558V32.173C7.25686 33.3112 7.81508 33.7229 9.10142 33.6503L31.2118 32.3668C32.4981 32.2941 32.6437 31.5192 32.6437 30.5989V9.62702C32.6437 8.70677 32.2797 8.19822 31.503 8.27087L8.39757 9.62702C7.54811 9.69967 7.25686 10.1356 7.25686 11.0558ZM29.076 12.194C29.2216 12.8237 29.076 13.4775 28.445 13.5502L27.3771 13.7681V29.3639C26.4548 29.8482 25.6053 30.1388 24.8772 30.1388C23.7365 30.1388 23.4452 29.7755 22.5958 28.71L15.6302 17.7881V28.3467L17.8388 28.8311C17.8388 28.8311 17.8388 30.1146 16.067 30.1146L11.1644 30.4052C11.0188 30.1146 11.1644 29.4123 11.6498 29.267L12.9361 28.9037V14.9548L11.1644 14.8095C11.0188 14.1798 11.3828 13.2596 12.3779 13.1869L17.6446 12.8237L24.9015 23.8666V14.1072L23.0569 13.8892C22.9113 13.1143 23.4938 12.5331 24.1976 12.4604L29.076 12.194ZM2.20861 1.56276L22.4744 0.0855294C24.95 -0.132423 25.6053 0.0128789 27.1586 1.15108L33.6146 5.67965C34.6825 6.45459 35.0465 6.67255 35.0465 7.52014V32.391C35.0465 33.9409 34.4883 34.8611 32.4981 35.0064L8.98007 36.4352C7.4753 36.5079 6.77145 36.2899 5.9948 35.297L1.21352 29.1217C0.364057 27.9835 0 27.1359 0 26.143V4.0329C0 2.7494 0.58249 1.70807 2.20861 1.56276Z'
        fill='black'
      />
    </svg>
  )
}

export default NotionOriginal
