import { SVGProps } from 'react'
function ToastTriangle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={22}
      viewBox='0 0 25 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.383.512a1.018 1.018 0 00-1.766 0L.138 20.465A1.023 1.023 0 001.021 22H23.98c.785 0 1.275-.853.883-1.535L13.383.512zm-1.856 14.453h1.866l.294-6.802h-2.454l.294 6.802zm0 1.292c-.241.214-.362.49-.362.827 0 .337.12.615.362.834.246.214.552.321.916.321.365 0 .668-.107.91-.321.245-.219.369-.497.369-.834 0-.337-.123-.613-.37-.827-.241-.219-.544-.328-.909-.328-.364 0-.67.11-.916.328z'
        fill='#FF5C00'
      />
    </svg>
  )
}

export default ToastTriangle
