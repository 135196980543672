import { SVGProps } from 'react'
function AtlassianOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      {...props}>
      <g id='vertical-logo-gradient-blue-atlassian'>
        <g id='Group'>
          <g id='Group_2'>
            <g id='Group_3'>
              <path
                id='Vector'
                d='M11.5728 12.1189C11.1842 11.7015 10.5652 11.7303 10.3061 12.2629L3.87143 25.1178C3.61232 25.636 3.98659 26.2262 4.5624 26.2262H13.5162C13.8041 26.2262 14.0632 26.0823 14.2072 25.7944C16.1361 21.8069 14.9701 15.7321 11.5728 12.1189Z'
                fill='url(#paint0_linear)'
              />
              <path
                id='Vector_2'
                d='M16.2944 0.415678C12.6956 6.11617 12.9404 12.4356 15.3012 17.1717C17.662 21.9077 19.4902 25.5496 19.6197 25.8088C19.7493 26.0679 20.0228 26.2406 20.3107 26.2406H29.2645C29.8403 26.2406 30.2146 25.636 29.9555 25.1322C29.9555 25.1322 17.9067 1.02028 17.6044 0.415678C17.3309 -0.131338 16.6399 -0.145734 16.2944 0.415678Z'
                fill='#2681FF'
              />
            </g>
          </g>
          <g id='Group_4'>
            <g id='Group_5'>
              <g id='Group_6'>
                <path
                  id='Vector_3'
                  d='M17.8348 30.9334C17.8348 30.3144 17.5037 30.0265 16.5968 29.8394C16.0929 29.7242 15.9634 29.609 15.9634 29.4507C15.9634 29.2492 16.1505 29.1628 16.4816 29.1628C16.8847 29.1628 17.2878 29.2923 17.662 29.4507V28.6446C17.3885 28.515 16.9855 28.3998 16.5104 28.3998C15.6035 28.3998 15.1285 28.7885 15.1285 29.4363C15.1285 29.9545 15.3732 30.3576 16.3089 30.5447C16.8703 30.6599 16.9855 30.7463 16.9855 30.9334C16.9855 31.1205 16.8703 31.2357 16.4672 31.2357C16.0066 31.2357 15.4596 31.0773 15.1141 30.8614V31.7107C15.402 31.8547 15.7763 31.9986 16.4672 31.9986C17.4461 32.0274 17.8348 31.5812 17.8348 30.9334Z'
                  fill='#0052CC'
                />
                <path
                  id='Vector_4'
                  d='M27.7386 28.4718V31.9698H28.4871V29.2923L28.8038 30.0121L29.8547 31.9698H30.7904V28.4718H30.0418V30.7318L29.7683 30.0697L28.919 28.4718H27.7386Z'
                  fill='#0052CC'
                />
                <path
                  id='Vector_5'
                  d='M22.9882 28.4718H22.1677V31.9698H22.9882V28.4718Z'
                  fill='#0052CC'
                />
                <path
                  id='Vector_6'
                  d='M21.232 30.9334C21.232 30.3144 20.9009 30.0265 19.994 29.8394C19.4902 29.7242 19.3606 29.609 19.3606 29.4507C19.3606 29.2492 19.5477 29.1628 19.8788 29.1628C20.2819 29.1628 20.685 29.2923 21.0592 29.4507V28.6446C20.7857 28.515 20.3827 28.3998 19.9076 28.3998C19.0007 28.3998 18.5257 28.7885 18.5257 29.4363C18.5257 29.9545 18.7704 30.3576 19.7061 30.5447C20.2675 30.6599 20.3827 30.7463 20.3827 30.9334C20.3827 31.1205 20.2675 31.2357 19.8644 31.2357C19.4038 31.2357 18.8568 31.0773 18.5113 30.8614V31.7107C18.7992 31.8547 19.1735 31.9986 19.8644 31.9986C20.8433 32.0274 21.232 31.5812 21.232 30.9334Z'
                  fill='#0052CC'
                />
                <path
                  id='Vector_7'
                  d='M8.79456 28.4718V31.9698H10.4788L10.7379 31.2069H9.61508V28.4718H8.79456Z'
                  fill='#0052CC'
                />
                <path
                  id='Vector_8'
                  d='M5.48364 28.4716V29.2201H6.39054V31.9696H7.21106V29.2201H8.18993V28.4716H5.48364Z'
                  fill='#0052CC'
                />
              </g>
            </g>
            <path
              id='Vector_9'
              d='M4.30323 28.4716H3.22359L2 31.9696H2.93569L3.10843 31.3794C3.30996 31.437 3.54028 31.4801 3.75621 31.4801C3.98653 31.4801 4.20246 31.4514 4.40399 31.3794L4.57673 31.9696H5.51242L4.30323 28.4716ZM3.75621 30.746C3.59786 30.746 3.45391 30.7172 3.30996 30.6884L3.75621 29.1625L4.20246 30.6884C4.05851 30.7172 3.91456 30.746 3.75621 30.746Z'
              fill='#0052CC'
            />
            <path
              id='Vector_10'
              d='M13.3722 28.4718H12.2926L11.069 31.9699H12.0047L12.1774 31.3797C12.3789 31.4372 12.6093 31.4804 12.8252 31.4804C13.0555 31.4804 13.2714 31.4516 13.473 31.3797L13.6457 31.9699H14.5814L13.3722 28.4718ZM12.8252 30.7463C12.6668 30.7463 12.5229 30.7175 12.3789 30.6887L12.8252 29.1628L13.2714 30.6887C13.1275 30.7175 12.9835 30.7463 12.8252 30.7463Z'
              fill='#0052CC'
            />
            <path
              id='Vector_11'
              d='M25.9247 28.4718H24.8451L23.6215 31.9699H24.5572L24.7299 31.3797C24.9315 31.4372 25.1618 31.4804 25.3777 31.4804C25.6081 31.4804 25.824 31.4516 26.0255 31.3797L26.1983 31.9699H27.1339L25.9247 28.4718ZM25.3921 30.7463C25.2338 30.7463 25.0898 30.7175 24.9459 30.6887L25.3921 29.1628L25.8384 30.6887C25.6944 30.7175 25.5505 30.7463 25.3921 30.7463Z'
              fill='#0052CC'
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='15.1022'
          y1='14.0978'
          x2='8.31138'
          y2='25.8598'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#0052CC' />
          <stop offset='0.9228' stop-color='#2684FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AtlassianOriginal
