import { SVGProps } from 'react'
function DoubleCaratUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7803 11.7071C21.3898 12.0976 20.7567 12.0976 20.3661 11.7071L11.0732 2.41421L1.78035 11.7071C1.38983 12.0976 0.756662 12.0976 0.366136 11.7071C-0.0243879 11.3166 -0.0243878 10.6834 0.366136 10.2929L10.3661 0.292894C10.7567 -0.0976312 11.3898 -0.0976312 11.7804 0.292894L21.7803 10.2929C22.1709 10.6834 22.1709 11.3166 21.7803 11.7071Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7803 22.7071C21.3898 23.0976 20.7567 23.0976 20.3661 22.7071L11.0732 13.4142L1.78035 22.7071C1.38982 23.0976 0.756661 23.0976 0.366135 22.7071C-0.0243888 22.3166 -0.0243888 21.6834 0.366135 21.2929L10.3661 11.2929C10.7567 10.9024 11.3898 10.9024 11.7803 11.2929L21.7803 21.2929C22.1709 21.6834 22.1709 22.3166 21.7803 22.7071Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DoubleCaratUp
