import { useContext } from 'react'

import { StoryContext } from '../providers'

const useStory = () => {
  const context = useContext(StoryContext)
  if (!context) throw new Error('useStory is only usable as a child to StoryProvider')
  return context
}

export { useStory }
