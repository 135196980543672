import { classNames } from '@invisible/common/helpers'
import { IFilter, TFilterValue } from '@invisible/common/types'

interface IProps {
  filters: IFilter<TFilterValue>[]
  renderItem: (dynamicFilter: IFilter<TFilterValue>, itemKey: number | string) => JSX.Element | null
  noWrap?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const FiltersListV2: React.FC<IProps> = ({ filters, noWrap = true, renderItem }) => (
  <div className='flex w-fit gap-1 leading-[1]'>
    <div className={'flex w-full overflow-hidden'}>
      <div
        className={classNames(
          'flex gap-2 overflow-hidden',
          noWrap ? 'scrollbar-hide flex-nowrap overflow-x-scroll' : 'flex-wrap'
        )}>
        {filters.map((dynamicFilter, index) => renderItem(dynamicFilter, index))}
      </div>
    </div>
  </div>
)

export { default as renderFunction } from './renderFunctions'
export { FiltersListV2 }
