import { SVGProps } from 'react'
function WordMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15 13.427H1a.143.143 0 00-.143.143v1.428c0 .079.065.143.143.143h14a.143.143 0 00.143-.143V13.57a.143.143 0 00-.143-.143zM3.388 11.998h1.518a.174.174 0 00.166-.121l.959-2.964h3.914l.95 2.964c.023.071.09.121.166.121h1.591c.02 0 .04-.003.057-.008a.174.174 0 00.107-.222L9.1.973a.177.177 0 00-.164-.117H7.109a.171.171 0 00-.164.117L3.223 11.768a.154.154 0 00-.008.057.172.172 0 00.173.173zm4.57-9.216h.073l1.496 4.711H6.445l1.512-4.71z'
        fill='currentColor'
      />
    </svg>
  )
}

export default WordMode
