import { values } from 'lodash/fp'
import { z } from 'zod'

type TConstObject = Readonly<Record<string, string>>

/**
 * Given a constant Object of the form { [k: string]: string} (i.e. an enum),
 * create a zod schema to validate that a string is a valid value of that enum.
 */
const constObjectToZod = <T extends TConstObject>(e: T) =>
  z
    .string()
    .refine((str) => values(e).includes(str))
    .transform((str) => str as T[keyof T])

/**
 * Given a constant Object of the form { [k: string]: string} (i.e. an enum),
 * create a zod schema to validate that a string is a valid value of that enum,
 * which can be used by zod-to-json-schema.
 *
 * Note well that this function will NOT provide the correct type when using z.infer,
 * but it WILL correctly throw an error on parse if the value is not a valid value of the enum.
 */
const constObjectToZodForJsonSchema = <T extends TConstObject>(e: T) => z.enum(values(e) as any)

// From: https://www.npmjs.com/package/zod#json-type
type Literal = boolean | null | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null(), z.any()])
const JsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(JsonSchema), z.record(JsonSchema)])
)
type TJson = z.infer<typeof JsonSchema>

const UuidSchema = z.string().uuid()
type TUuid = z.infer<typeof UuidSchema>

const UrlSchema = z.string().url()
type TUrl = z.infer<typeof UrlSchema>

const JsonStringSchema = z
  .function()
  .args(z.string())
  .returns(z.any())
  .implement((a) => JSON.parse(a))

export {
  constObjectToZod,
  constObjectToZodForJsonSchema,
  JsonSchema,
  JsonStringSchema,
  UrlSchema,
  UuidSchema,
  z,
}
export type { TJson, TUrl, TUuid }
