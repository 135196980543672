import { Dropdown } from '@invisible/ui/dropdown'
import { SmallCheckbox } from '@invisible/ui/form'
import { Input } from '@invisible/ui/input'
import { RegistryWidgetsType, RJSFSchema, Widget } from '@rjsf/utils'
import { ChangeEvent } from 'react'

const customWidgets: RegistryWidgetsType<any, RJSFSchema, any> = {
  TextWidget: ((props) => (
    <div className='mt-1 mb-4'>
      <Input
        value={props.value}
        required={props.required}
        onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
      />
    </div>
  )) as Widget<any, RJSFSchema, any>,

  CheckboxWidget: ((props) => (
    <div className='mt-1 mb-4'>
      <SmallCheckbox
        checked={props.value}
        required={props.required}
        onClick={(checked) => props.onChange(checked)}
      />
    </div>
  )) as Widget<any, RJSFSchema, any>,

  SelectWidget: ((props) => (
    <div className='mt-1 mb-4'>
      <Dropdown
        width='100%'
        options={(props.options.enumOptions ?? []).map((option: { value: string }) => ({
          key: option.value,
          value: option.value,
        }))}
        selectedKey={props.value}
        onChange={(selected) => props.onChange(selected.key)}
      />
    </div>
  )) as Widget<any, RJSFSchema, any>,
}

export { customWidgets }
