import { SVGProps } from 'react'
function RotateRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='16'
      viewBox='0 0 27 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.9575 0.947266C26.5097 0.947266 26.9575 1.39498 26.9575 1.94727V9.94727C26.9575 10.4996 26.5097 10.9473 25.9575 10.9473H17.9575C17.4052 10.9473 16.9575 10.4996 16.9575 9.94727C16.9575 9.39498 17.4052 8.94727 17.9575 8.94727H24.9575V1.94727C24.9575 1.39498 25.4052 0.947266 25.9575 0.947266Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6171 3.64201C13.4828 3.21724 11.2704 3.43508 9.2599 4.26798C7.24941 5.10088 5.53115 6.5114 4.32251 8.32109C3.11387 10.1308 2.46917 12.2583 2.46997 14.4345C2.47018 14.9868 2.02263 15.4347 1.47034 15.4349C0.918057 15.4351 0.470176 14.9875 0.469972 14.4352C0.46902 11.8634 1.23094 9.34903 2.65933 7.2103C4.08773 5.07158 6.1184 3.4046 8.49444 2.42026C10.8705 1.43593 13.4851 1.17847 16.0075 1.68048C18.5298 2.18247 20.8465 3.42131 22.6646 5.24026V5.24026L26.6646 9.24026C27.0551 9.63078 27.0551 10.2639 26.6646 10.6545C26.2741 11.045 25.6409 11.045 25.2504 10.6545L21.2504 6.65447C19.712 5.11527 17.7514 4.06678 15.6171 3.64201Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RotateRight
