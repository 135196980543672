import { SVGProps } from 'react'

function Category(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={14}
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M5.804 1.662h-4.41a.233.233 0 00-.233.232v4.41c0 .128.105.233.232.233h4.411a.233.233 0 00.232-.233v-4.41a.233.233 0 00-.232-.232zM5.05 5.55H2.148V2.648H5.05V5.55zm6.558-3.888H7.197a.233.233 0 00-.232.232v4.41c0 .128.104.233.232.233h4.41a.233.233 0 00.233-.233v-4.41a.233.233 0 00-.232-.232zm-.755 3.888H7.951V2.648h2.902V5.55zm.755 1.915H7.197a.233.233 0 00-.232.232v4.411c0 .128.104.232.232.232h4.41a.233.233 0 00.233-.232v-4.41a.233.233 0 00-.232-.233zm-.755 3.889H7.951V8.452h2.902v2.902zm-5.63-1.451H3.368V8.046a.116.116 0 00-.116-.117h-.697a.116.116 0 00-.116.117v1.857H.581a.116.116 0 00-.116.116v.696c0 .064.052.116.116.116h1.857v1.857c0 .064.052.117.116.117h.697a.116.116 0 00.116-.117v-1.857h1.857a.116.116 0 00.116-.116v-.696a.116.116 0 00-.116-.116z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Category
