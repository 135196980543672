import { SVGProps } from 'react'
function Dropdown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 4.49942V13.5006C0 15.9848 1.89804 18 4.23773 18H43.7622C46.1009 18 48 15.9853 48 13.5006V4.49942C48 2.01517 46.102 0 43.7623 0H4.23773C1.89914 0 0 2.01467 0 4.49942ZM33.8824 3H43.7623C44.5431 3 45.1765 3.6725 45.1765 4.49942V13.5006C45.1765 14.3274 44.5424 15 43.7623 15H33.8824V3ZM2.82353 4.49942C2.82353 3.67258 3.45757 3 4.23773 3H31.0588V15H4.23773C3.45694 15 2.82353 14.3275 2.82353 13.5006V4.49942ZM32.4706 33C33.2503 33 33.8824 32.3284 33.8824 31.5V24C33.8824 23.1716 33.2503 22.5 32.4706 22.5H4.23529C3.45561 22.5 2.82353 23.1716 2.82353 24V31.5C2.82353 32.3284 3.45561 33 4.23529 33H32.4706ZM5.64706 30V25.5H31.0588V30H5.64706ZM26.8235 48C27.6032 48 28.2353 47.3284 28.2353 46.5V39C28.2353 38.1716 27.6032 37.5 26.8235 37.5H4.23529C3.45561 37.5 2.82353 38.1716 2.82353 39V46.5C2.82353 47.3284 3.45561 48 4.23529 48H26.8235ZM25.4118 45H5.64706V40.5H25.4118V45ZM40.6488 10.8107C41.2001 11.3964 42.094 11.3964 42.6453 10.8107C43.1966 10.2249 43.1966 9.27517 42.6453 8.68933L40.5277 6.43933C39.9764 5.85358 39.0824 5.85358 38.5311 6.43933L36.4135 8.68933C35.8622 9.27508 35.8622 10.2248 36.4135 10.8107C36.9648 11.3964 37.8587 11.3964 38.41 10.8107L39.5294 9.62133L40.6488 10.8107ZM7.05882 10.5H19.7807C20.5604 10.5 21.1925 9.82842 21.1925 9C21.1925 8.17158 20.5604 7.5 19.7807 7.5H7.05882C6.27914 7.5 5.64706 8.17158 5.64706 9C5.64706 9.82842 6.27914 10.5 7.05882 10.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Dropdown
