import { SVGProps } from 'react'
function Filter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M43.72 5.185H4.281c-1.313 0-2.132 1.43-1.473 2.572l12.46 21.182v12.889c0 .948.761 1.714 1.704 1.714h14.057c.943 0 1.704-.766 1.704-1.714v-12.89L45.199 7.758c.653-1.142-.166-2.572-1.479-2.572zm-14.823 34.5h-9.793v-8.357h9.798v8.357h-.005zm.514-12.675l-.509.89H19.1l-.51-.89L7.968 9.042h32.068L29.41 27.01z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Filter
