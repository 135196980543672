import { z } from 'zod'

const emailSchema = z.array(
  z
    .string()
    .email({ message: 'Invalid email address format. Please enter a valid email address.' })
    .refine(
      (value) =>
        value.toLowerCase().endsWith('@invisible.co') ||
        value.toLowerCase().endsWith('@invisible.email'),
      {
        message: 'Email address must belong to the Invisible.co/email domain.',
      }
    ),
  {
    invalid_type_error: 'Please add a valid email address from the Invisible.co/email domain.',
    required_error: 'Please add a valid email address from the Invisible.co/email domain.',
  }
)

const formSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Please provide a name for the export.' })
    .max(100, { message: 'Export name should be less than 100 characters.' }),
  description: z.string().optional(),
  selectedBase: z.object(
    {
      label: z.string(),
      value: z.string(),
      message: z.string().optional(),
    },
    {
      invalid_type_error: 'Please select a base to export data from.',
      required_error: 'Please select a base to export data from.',
    }
  ),
  selectedTransformationRule: z.object(
    {
      label: z.string(),
      value: z.string(),
      expression: z.string(),
      message: z.string().optional(),
    },
    {
      invalid_type_error: 'Please select a schema for your export.',
      required_error: 'Please select a schema for your export.',
    }
  ),
  to: emailSchema.nonempty({
    message: 'Please add a valid email address from the Invisible.co/email domain.',
  }),
  cc: emailSchema.optional(),
  bcc: emailSchema.optional(),
})
type TFormSchema = z.infer<typeof formSchema>

export { formSchema }
export type { TFormSchema }
