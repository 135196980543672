import { classNames } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { Icons } from '@invisible/ui/icons'
import { TIconName } from '@invisible/ui/icons'
import { motion } from 'framer-motion'
import { FC, MouseEvent as ReactMouseEvent } from 'react'
import Confetti from 'react-confetti'
import { Portal } from 'react-portal'

// eslint-disable-next-line @typescript-eslint/ban-types
const Card: FC<{ className?: string }> = ({ children, className = '' }) => (
  <div className={classNames('bg-void relative min-w-[300px] rounded-md shadow-lg', className)}>
    {children}
  </div>
)

interface IModal {
  onClose: (e?: ReactMouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => void | Promise<void>
  red?: boolean
  absolute?: boolean
  transparent?: boolean
  primaryButton?: JSX.Element
  secondaryButton?: JSX.Element
  title?: string
  icon?: TIconName
  iconColor?: string
  visible?: boolean
  blurred?: boolean
  withoutPadding?: boolean
  className?: string
  minWidth?: string
  minHeight?: number | string
  disableClickAway?: boolean
  width?: number | string
  confetti?: boolean
  id?: string
  disableClose?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Modal: FC<IModal> = ({
  red,
  transparent,
  blurred,
  absolute,
  children,
  onClose,
  primaryButton,
  secondaryButton,
  title,
  icon,
  iconColor,
  withoutPadding = false,
  visible = true,
  minWidth = 'unset',
  disableClickAway = false,
  minHeight = 'unset',
  width,
  id = 'modalDefaultId',
  confetti,
  disableClose = false,
  ...cardProps
}) => {
  const ParsedIcon = icon ? Icons?.[icon] : undefined
  return visible ? (
    <Portal>
      <motion.div
        id={id}
        className={classNames(
          'top-0 left-0 right-0 bottom-0 z-[100] flex items-center justify-center p-4',
          absolute ? 'absolute' : 'fixed'
        )}
        data-cy='modal'
        {...{ absolute }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0 }}>
        {/* The reason why we are using Tailwind default colors (which is a UI sin for us) below is
        that theme colors do not play well with alpha channel related css stuff and blur is dependent
        on alpha data. Using opacity as a tailwind attribute did not work either. I hope history will
        forgive us. */}
        <div
          data-testid='modal-overlay'
          className={classNames(
            'absolute inset-0',
            red ? 'bg-red-500/30' : transparent ? 'bg-header opacity-0' : 'bg-sky-400/30',
            blurred ? 'backdrop-blur-xs' : ''
          )}
          onClick={(e) => {
            if (disableClickAway) return
            onClose(e)
          }}
        />
        <div
          className='flex flex-col'
          style={{
            minWidth,
            height: minHeight,
            width: width ? width : 'auto',
          }}>
          <Card {...cardProps}>
            <div className='bg-theme-main text-void relative box-border flex h-12 items-center justify-between rounded-t-md py-3 pl-4 pr-2'>
              <div className='flex flex-row items-center justify-center'>
                {ParsedIcon ? (
                  <div className='flex items-center pr-2'>
                    <ParsedIcon height={20} width={20} color={iconColor} />
                  </div>
                ) : null}
                {title ? <h3 className='text-void text-lg font-bold'>{title}</h3> : null}
              </div>

              {!disableClose ? (
                <div>
                  <Button
                    variant='subtle'
                    icon='CloseIcon'
                    onClick={() => onClose()}
                    shape='square'
                    size='md'
                    color='void'
                  />
                </div>
              ) : null}
            </div>
            <div className={withoutPadding ? '' : 'p-4'}>{children}</div>
            {primaryButton || secondaryButton ? (
              <div className='flex items-center justify-end p-4'>
                <div className='pr-2'>{secondaryButton}</div>
                <div>{primaryButton}</div>
              </div>
            ) : null}
          </Card>
        </div>
      </motion.div>
      {confetti ? <Confetti width={1500} height={1500} numberOfPieces={400} /> : null}
    </Portal>
  ) : null
}

export { Modal }
