import { SVGProps } from 'react'

function Discover(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78038)'>
        <path
          d='M28.4469 -0.00292969H8.13518C7.41829 -0.00292969 6.94037 0.120147 6.34297 0.366301C5.74557 0.612455 5.14816 0.981685 4.78972 1.47399C4.3118 1.84322 3.95336 2.45861 3.7144 3.07399C3.47544 3.68938 3.35596 4.30476 3.35596 4.92015V29.5355V30.7663C3.35596 31.5048 3.83388 31.9971 4.55076 31.9971H26.0573C26.7741 31.9971 27.2521 31.5048 27.2521 30.7663C27.2521 30.0278 26.7741 29.5355 26.0573 29.5355H5.74557C5.74557 29.1663 5.86505 28.9201 5.86505 28.674C5.98453 28.4278 6.10401 28.0586 6.34297 27.9355C6.58193 27.6894 6.82089 27.5663 7.05985 27.4432C7.53778 27.074 7.89622 27.074 8.13518 27.074H28.4469C29.1638 27.074 29.6417 26.5817 29.6417 25.8432V1.22784C29.6417 0.489378 29.1638 -0.00292969 28.4469 -0.00292969ZM15.304 2.45861H22.4728V13.5355L19.6053 11.3201C19.1274 10.9509 18.6495 10.9509 18.1715 11.3201L15.304 13.5355V2.45861ZM27.2521 24.6125H8.13518C7.53778 24.6125 6.94037 24.7355 6.34297 24.9817C6.10401 25.1048 5.98453 25.1048 5.74557 25.2278V4.92015C5.74557 4.55092 5.74557 4.30476 5.86505 3.93553C5.98453 3.68938 6.22349 3.44322 6.46245 3.19707C6.70141 2.95092 6.94037 2.70476 7.17933 2.58169C7.53778 2.58169 7.77674 2.45861 8.13518 2.45861H12.9144V15.9971C12.9144 16.4894 13.1534 16.8586 13.6313 17.1048C13.9897 17.3509 14.4676 17.2278 14.8261 16.9817L18.8884 13.9048L22.9508 16.9817C23.1897 17.1048 23.4287 17.2278 23.6676 17.2278C23.9066 17.2278 24.0261 17.2278 24.1456 17.1048C24.504 16.8586 24.8625 16.4894 24.8625 15.9971V2.45861H27.2521V24.6125Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78038'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Discover
