import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useTosSowState } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  IStepRunType,
  toGlobalId,
  useGetOrCreateStatementOfWorkMutation,
  useStepRunStartV2Mutation,
} from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Checkbox } from '@invisible/ui/checkbox'
import { Dropdown } from '@invisible/ui/dropdown'
import { Text } from '@invisible/ui/text'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React, { forwardRef, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { Flex } from 'rebass'

import {
  findBaseVariableId,
  getBaseRunVariableFromQueryData,
  handleSetUserLifecycleStage,
  handleStepRunUpdatesOnLooopResourceStatus,
} from '../common/helpers'
import { useBaseRunVariablesWizardUpdate } from '../hooks/useBaseRunVariablesWizardUpdate'
import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import usePollAutoAssignment from '../hooks/usePollAutoAssignment'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

type IProps = WizardSchemas.WACConfig.TSchema & {
  baseRun: TBaseRun
  stepRun: TStepRun
  closeWizard: () => void
}

type IStatus = 'pass' | 'fail' | 'unknown'
type IReason = 'front' | 'back' | 'none'

// eslint-disable-next-line react/display-name
const BoltDocumentVerificationIdWAC: React.ForwardRefRenderFunction<HTMLElement, IProps> =
  forwardRef(({ form, baseRun, stepRun }, wizardContainerRef) => {
    const reactQueryContext = useContext()
    const { addToast } = useToasts()
    const { dispatch: dispatchSowState } = useTosSowState()
    const { state: wizardState, dispatch } = useWizardState()
    const reactQueryClient = useQueryClient()

    const [skipAutoAssign, setSkipAutoAssign] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const statusBaseVariableId = useMemo(() => findBaseVariableId(form, 'status'), [form])

    const reasonBaseVariableId = useMemo(() => findBaseVariableId(form, 'reason'), [form])

    const { data } = useQuery([
      'baseRunVariable.findByBaseRunIdAndBaseVariableId',
      [
        {
          baseRunId: baseRun.id,
          baseVariableIds: [statusBaseVariableId],
        },
      ],
    ])

    const initialStatus = useMemo(
      () => getBaseRunVariableFromQueryData<IStatus>(data, statusBaseVariableId) ?? '',
      [data, statusBaseVariableId]
    )

    const [status, setStatus] = useState<IStatus>('unknown')
    const [reason, setReason] = useState<IReason>('none')

    const disabledDueToMissingStatus = useMemo<boolean>(() => status === 'unknown', [status])
    const disabledDueToMissingReasonPass = useMemo<boolean>(
      () => status === 'pass' && reason !== 'none',
      [status, reason]
    )
    const disabledDueToMissingReasonFail = useMemo<boolean>(
      () => status === 'fail' && reason === 'none',
      [status, reason]
    )

    const disabled = useMemo<boolean>(
      () =>
        disabledDueToMissingStatus ||
        disabledDueToMissingReasonPass ||
        disabledDueToMissingReasonFail,
      [disabledDueToMissingStatus, disabledDueToMissingReasonPass, disabledDueToMissingReasonFail]
    )

    useEffect(() => {
      setStatus(initialStatus)
    }, [initialStatus])

    ///left this because we use it in the regular form not sure if it needs adjustment
    const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } =
      useCompleteStepRun({
        onSettled: () => {
          reactQueryClient.invalidateQueries('get-base-runs')
          reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
          reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
        },
      })

    const { mutateAsync: updateManyBaseRunVariables } = useBaseRunVariablesWizardUpdate({
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    })
    const { mutateAsync: getOrCreateSow } = useGetOrCreateStatementOfWorkMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        addToast(`Get or create SoW failed: ${errorMessage}`, {
          appearance: 'error',
        })
      },
    })
    const { mutateAsync: startStepRun } = useStepRunStartV2Mutation({
      onSuccess: (response) => {
        if (response.stepRunStartV2.__typename !== 'GraphQLErrorType') {
          const stepRunStartData = response.stepRunStartV2 as IStepRunType
          const transformedData = {
            id: fromGlobalId(stepRunStartData.id),
            stepId: fromGlobalId(stepRunStartData.step.id),
            assigneeId: fromGlobalId(stepRunStartData.assignee?.id),
            baseRunId: fromGlobalId(stepRunStartData.baseRun.id),
          }
          reactQueryContext.queryClient.setQueryData<
            inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
          >(['stepRun.findAssignedToMe'], (prevData) => {
            if (!prevData) return

            return prevData.map((p) => ({
              ...p,
              stepRuns: p.stepRuns.map((s) =>
                s.id === fromGlobalId(stepRunStartData.id) ? { ...s, status: 'running' } : s
              ),
            }))
          })
          handleSetUserLifecycleStage({
            stepId: transformedData.stepId,
            userId: transformedData.assigneeId,
          })
          handleStepRunUpdatesOnLooopResourceStatus({
            id: transformedData.id,
            stepId: transformedData.stepId,
            userId: transformedData.assigneeId,
            baseRunId: transformedData.baseRunId,
          })
        }
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      },
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        addToast(`Start failed: ${errorMessage}`, {
          appearance: 'error',
        })
      },
    })

    const autoAssignmentPolling = usePollAutoAssignment(stepRun, skipAutoAssign)

    const handleSubmit = async () => {
      try {
        const baseRunVariablesToUpdate = [
          {
            baseVariableId: statusBaseVariableId,
            baseRunId: baseRun.id,
            value: status,
          },
          {
            baseVariableId: reasonBaseVariableId,
            baseRunId: baseRun.id,
            value: reason,
          },
        ]
        await updateManyBaseRunVariables({
          stepRunId: stepRun.id,
          data: baseRunVariablesToUpdate,
        })

        await markStepRunDone({ id: stepRun.id, skipAutoAssign })
        const assignedStepRun = await autoAssignmentPolling()
        if (assignedStepRun) {
          const { wizardConfig, trainingLink } = (assignedStepRun?.step?.meta ?? {}) as Record<
            string,
            any
          >
          const openWizard = () => {
            dispatch({
              type: 'openWizard',
              stepRun: assignedStepRun as unknown as TStepRun,
              baseRun: assignedStepRun.baseRun as unknown as TBaseRun,
              stepName: assignedStepRun.step?.name,
              wizardInitialBRVs: assignedStepRun.wizardInitialBaseRunVariables,
              wizardData: wizardConfig,
              trainingLink: trainingLink as string,
              processId: assignedStepRun.step?.processId,
            })
            // Scroll wizard container to top
            ;(wizardContainerRef as RefObject<any>)?.current?.elementRef?.current?.scrollTo?.(0, 0)
          }

          if (assignedStepRun.status === 'running') {
            openWizard()
          }

          if (assignedStepRun.status === 'pending') {
            const stepRunStartData = await startStepRun({
              id: toGlobalId('StepRunType', assignedStepRun.id),
            })
            if (stepRunStartData.stepRunStartV2.__typename === 'GraphQLErrorType') {
              const { message, code } = stepRunStartData.stepRunStartV2

              if (message === 'User has not acknowledged the latest statement of work') {
                try {
                  const request = await getOrCreateSow({
                    stepRunId: toGlobalId('StepRunType', assignedStepRun.id),
                  })

                  if (request?.getOrCreateStatementOfWork?.__typename !== 'GraphQLErrorType') {
                    dispatchSowState({
                      type: 'setSowToAcknowledge',
                      showSowModal: true,
                      sowToAcknowledge: request.getOrCreateStatementOfWork,
                      stepRunId: assignedStepRun.id,
                      // When user acknowledges the SoW we open the wizard, else we just close the SoW modal
                      openWizard,
                    })
                  }
                } catch (err: unknown) {
                  addToast(
                    `Fetch Statement of Work failed: ${(err as Error | undefined)?.message}`,
                    {
                      appearance: 'error',
                    }
                  )
                }
              } else {
                addToast(`${code}: ${message}`, {
                  appearance: 'error',
                })
              }
            } else {
              // Start didn't fail, open the wizard
              openWizard()
            }
          }
        } else {
          dispatch({ type: 'closeWizard' })
        }
      } catch (error) {
        sendErrorToSentry(error)
        addToast(`Something went wrong: ${(error as Error).message}`, {
          appearance: 'error',
        })
      }
    }

    return (
      <div
        className='h-5/5 border-gray box-border overflow-auto rounded-lg border bg-white p-2.5 shadow'
        ref={ref}>
        <Text mb='10px' fontWeight='bold'>
          Check Id
        </Text>
        <Flex alignItems='center' justifyContent='center' mb={3}>
          <div className='w-7/12'>
            <Dropdown
              width='100%'
              options={[
                { value: 'Pass', key: 'pass' },
                { value: 'Fail', key: 'fail' },
              ]}
              onChange={({ key }) => {
                setStatus(key as IStatus)
                if (key === 'pass') {
                  setReason('none')
                }
              }}
              name=''
              placeholder=''
            />
          </div>
        </Flex>
        {status === 'fail' && (
          <Flex alignItems='center' justifyContent='center' mb={3}>
            <div className='w-7/12'>
              <Dropdown
                width='100%'
                options={[
                  { value: 'Front', key: 'front' },
                  { value: 'Back', key: 'back' },
                ]}
                onChange={({ key }) => {
                  setReason(key as IReason)
                }}
                name=''
                placeholder=''
              />
            </div>
          </Flex>
        )}
        <Checkbox checked={skipAutoAssign} onToggle={(e) => setSkipAutoAssign(e)}>
          Don't assign a new task after submission
        </Checkbox>
        <div className='mt-2'>
          <Button
            variant='primary'
            size='md'
            onClick={handleSubmit}
            disabled={disabled}
            loading={isMarkStepRunDoneLoading}
            wFull>
            {'Submit'}
          </Button>
        </div>
      </div>
    )
  })

export { BoltDocumentVerificationIdWAC }
