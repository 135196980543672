import { SVGProps } from 'react'
function Security(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={40}
      height={50}
      viewBox='0 0 40 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.018 6.316L20.814.112A2.635 2.635 0 0020.005 0c-.294 0-.589.037-.809.113L.993 6.316c-.445.15-.81.664-.81 1.136v25.843c0 .471.306 1.093.676 1.387l18.466 14.39c.187.144.428.22.675.22.246 0 .493-.076.675-.22l18.466-14.39c.37-.29.675-.91.675-1.387V7.452c.01-.472-.354-.98-.798-1.136zm-7.823 29.67l-3.208 2.5-7.982 6.22-10.303-8.029c.712-5.331 5.278-9.444 10.804-9.444 5.285 0 9.692 3.762 10.689 8.754zm2.747-2.14a14.138 14.138 0 00-8.38-8.88 8.5 8.5 0 10-10.112 0 14.133 14.133 0 00-8.566 9.515L4.04 32.266V9.36l15.964-5.443L35.97 9.36v22.907l-2.028 1.58zM20.506 23.433a5.3 5.3 0 100-10.6 5.3 5.3 0 000 10.6z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Security
