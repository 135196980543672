import { SVGProps } from 'react'

function ProcessFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.96625 15.1303C7.13056 15.0448 7.31385 15 7.5 15C7.68614 15 7.86944 15.0448 8.03375 15.1303L8.04153 15.1345L8.04756 15.1378L14.6953 18.8286C14.7192 18.8414 14.7421 18.8558 14.7639 18.8716C14.8927 18.9653 14.9807 19.1096 14.9971 19.2745C14.999 19.293 14.9999 19.3117 14.9999 19.3306L14.9999 19.3368V23.4905C15 23.4988 15 23.507 14.9999 23.5152V27.6549C15.0006 27.6875 14.9984 27.72 14.9936 27.752C14.9849 27.8093 14.9675 27.864 14.9427 27.9145C14.893 28.0163 14.8121 28.1048 14.7041 28.1648L8.04756 31.8606C8.04299 31.8631 8.03839 31.8656 8.03375 31.868C7.88852 31.9436 7.72847 31.9874 7.56473 31.9965C7.54345 31.9988 7.52182 32 7.49991 32C7.47794 32 7.45624 31.9988 7.4349 31.9965C7.27129 31.9873 7.11137 31.9436 6.96625 31.868L6.95718 31.8632L6.95244 31.8606L0.295887 28.1648C0.193204 28.1078 0.114928 28.0249 0.0647555 27.9293C0.0331403 27.8693 0.0120558 27.8031 0.00389471 27.7332C0.000893188 27.7078 -0.000387715 27.6823 0.00010161 27.6566V23.5135C-3.36938e-05 23.5064 -3.40461e-05 23.4993 0.00010161 23.4922V19.3306C0.00010161 19.1895 0.0523622 19.0603 0.138977 18.9606C0.161579 18.9346 0.186651 18.9104 0.213976 18.8885C0.242396 18.8657 0.273056 18.8455 0.305583 18.8282L6.95244 15.1378C6.95701 15.1352 6.96161 15.1328 6.96625 15.1303ZM13.8319 27.335L8.08391 30.5264V27.6752L13.8319 24.4846V27.335ZM1.1681 24.4845L6.91591 27.6751V30.5263L1.1681 27.335V24.4845ZM1.1681 20.3117V23.1706L6.91591 26.3611V23.5022L1.1681 20.3117ZM8.08391 23.5023L13.8319 20.3117V23.1706L8.08391 26.3612V23.5023ZM7.5 16.1478L1.76754 19.3305L7.5 22.5125L13.2325 19.3305L7.5 16.1478Z'
        fill='black'
      />
      <path
        d='M1.76754 19.3305L7.5 16.1478L13.2325 19.3305L7.5 22.5125L1.76754 19.3305Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.9662 15.1303C24.1306 15.0448 24.3139 15 24.5 15C24.6861 15 24.8694 15.0448 25.0338 15.1303L25.0415 15.1345L25.0476 15.1378L31.6953 18.8286C31.7192 18.8414 31.7421 18.8558 31.7639 18.8716C31.8927 18.9653 31.9807 19.1096 31.9971 19.2745C31.999 19.293 31.9999 19.3117 31.9999 19.3306L31.9999 19.3368V23.4905C32 23.4988 32 23.507 31.9999 23.5152V27.6549C32.0006 27.6875 31.9984 27.72 31.9936 27.752C31.9849 27.8093 31.9675 27.864 31.9427 27.9145C31.893 28.0163 31.8121 28.1048 31.7041 28.1648L25.0476 31.8606C25.043 31.8631 25.0384 31.8656 25.0338 31.868C24.8885 31.9436 24.7285 31.9874 24.5647 31.9965C24.5434 31.9988 24.5218 32 24.4999 32C24.4779 32 24.4562 31.9988 24.4349 31.9965C24.2713 31.9873 24.1114 31.9436 23.9662 31.868L23.9572 31.8632L23.9524 31.8606L17.2959 28.1648C17.1932 28.1078 17.1149 28.0249 17.0648 27.9293C17.0331 27.8693 17.0121 27.8031 17.0039 27.7332C17.0009 27.7078 16.9996 27.6823 17.0001 27.6566V23.5135C17 23.5064 17 23.4993 17.0001 23.4922V19.3306C17.0001 19.1895 17.0524 19.0603 17.139 18.9606C17.1616 18.9346 17.1867 18.9104 17.214 18.8885C17.2424 18.8657 17.2731 18.8455 17.3056 18.8282L23.9524 15.1378C23.957 15.1352 23.9616 15.1328 23.9662 15.1303ZM30.8319 27.335L25.0839 30.5264V27.6752L30.8319 24.4846V27.335ZM18.1681 24.4845L23.9159 27.6751V30.5263L18.1681 27.335V24.4845ZM18.1681 20.3117V23.1706L23.9159 26.3611V23.5022L18.1681 20.3117ZM25.0839 23.5023L30.8319 20.3117V23.1706L25.0839 26.3612V23.5023ZM24.5 16.1478L18.7675 19.3305L24.5 22.5125L30.2325 19.3305L24.5 16.1478Z'
        fill='black'
      />
      <path
        d='M18.7675 19.3305L24.5 16.1478L30.2325 19.3305L24.5 22.5125L18.7675 19.3305Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5018 0.130342C15.6552 0.0447598 15.8263 0 16 0C16.1737 0 16.3448 0.0447598 16.4982 0.130342L16.5054 0.134467L16.5111 0.137767L22.7156 3.82863C22.7379 3.84142 22.7593 3.8558 22.7796 3.87162C22.8998 3.96528 22.982 4.1096 22.9973 4.27446C22.999 4.29299 22.9999 4.31171 22.9999 4.33057L22.9999 4.33677V8.49053C23 8.49877 23 8.507 22.9999 8.51521V12.6549C23.0005 12.6875 22.9986 12.72 22.994 12.752C22.9859 12.8093 22.9697 12.864 22.9466 12.9145C22.9002 13.0163 22.8246 13.1048 22.7238 13.1648L16.5111 16.8606C16.5068 16.8631 16.5025 16.8656 16.4982 16.868C16.3626 16.9436 16.2132 16.9874 16.0604 16.9965C16.0405 16.9988 16.0204 17 15.9999 17C15.9794 17 15.9592 16.9988 15.9392 16.9965C15.7865 16.9873 15.6373 16.9436 15.5018 16.868L15.4934 16.8632L15.4889 16.8606L9.27616 13.1648C9.18032 13.1078 9.10727 13.0249 9.06044 12.9293C9.03093 12.8693 9.01125 12.8031 9.00364 12.7332C9.00083 12.7078 8.99964 12.6823 9.00009 12.6566V8.51353C8.99997 8.50643 8.99997 8.49933 9.00009 8.49222V4.33057C9.00009 4.18947 9.04887 4.06031 9.12971 3.96062C9.15081 3.93458 9.17421 3.91042 9.19971 3.88852C9.22624 3.86571 9.25485 3.84547 9.28521 3.82817L15.4889 0.137767C15.4932 0.135231 15.4975 0.132756 15.5018 0.130342ZM21.9097 12.335L16.545 15.5264V12.6752L21.9097 9.48456V12.335ZM10.0902 9.48454L15.4549 12.6751V15.5263L10.0902 12.335V9.48454ZM10.0902 5.31168V8.1706L15.4549 11.3611V8.50222L10.0902 5.31168ZM16.545 8.50232L21.9097 5.3117V8.17062L16.545 11.3612V8.50232ZM16 1.14776L10.6497 4.33048L16 7.51251L21.3503 4.33048L16 1.14776Z'
        fill='black'
      />
      <path
        d='M10.6497 4.33048L16 1.14776L21.3503 4.33048L16 7.51251L10.6497 4.33048Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3165 15V17.3807L11.3394 18L11 17.0431L12.2431 16.6538V15H13.3165ZM19.7569 16.6538V15H18.6835V17.3807L20.6606 18L21 17.0431L19.7569 16.6538Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 21L18 22.1749L17.3758 23L16 22.1918L14.6242 23L14 22.1749L16 21Z'
        fill='black'
      />
    </svg>
  )
}

export default ProcessFilled
