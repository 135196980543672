import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { Control, Controller, FieldError, FieldErrors, UseFormSetValue } from 'react-hook-form'

import { TFormSchema } from './schema'

interface IProps {
  name: 'to' | 'cc' | 'bcc'
  control: Control<TFormSchema, object>
  setValue: UseFormSetValue<TFormSchema>
  errors: FieldErrors<TFormSchema>
  label: string
  numOfRows?: number
}

export const ChipEmailInput = ({ name, control, setValue, errors, label, numOfRows }: IProps) => {
  const [inputValue, setInputValue] = useState('')

  const getErrorMessage = (errors: FieldErrors<TFormSchema>) => {
    const errorsByName = errors[name]
    if (Array.isArray(errorsByName)) {
      return errorsByName.find((error) => error)?.message
    } else if (errorsByName) {
      // react hook form sometimes returns a FieldError object but the type definition does not specify it
      return (errorsByName as unknown as FieldError).message
    }
    return ''
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          freeSolo
          autoSelect
          size='small'
          options={[]}
          value={field.value ?? []}
          inputValue={inputValue}
          limitTags={8}
          onChange={(_, newInputValue) => {
            setValue(name, newInputValue as string[])
          }}
          onInputChange={(_, newInputValue) => {
            const options = newInputValue.split(',')

            if (options.length > 1) {
              const allOptions = options
                .concat(field.value ?? [])
                .map((x) => x.trim())
                .filter((x) => x)

              setValue(name, allOptions)
              setInputValue('')
            } else {
              setInputValue(newInputValue)
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                variant='filled'
                sx={{
                  textTransform: 'lowercase',
                  alignSelf: 'flex-start',
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              rows={numOfRows ?? 1}
              InputLabelProps={{
                shrink: true,
              }}
              label={label}
              variant='outlined'
              placeholder={
                field.value && field.value.length > 0
                  ? ''
                  : 'Enter email addresses, separated by commas...'
              }
              error={!!errors[name]}
              helperText={getErrorMessage(errors)}
            />
          )}
        />
      )}
    />
  )
}
