import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { Input } from '@invisible/ui/input'
import { Text } from '@invisible/ui/text'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { Flex } from 'rebass'

import { findBaseVariableId, getBaseRunVariableFromQueryData } from '../common/helpers'
import { useBaseRunVariablesWizardUpdate } from '../hooks/useBaseRunVariablesWizardUpdate'
import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

type IProps = WizardSchemas.WACConfig.TSchema & {
  baseRun: TBaseRun
  stepRun: TStepRun
  closeWizard: () => void
}

const MANDATORY_PROVIDERS = ['Access denied', 'Other']
const PROVIDER_NO_BNPL = 'No BNPL'

// eslint-disable-next-line react/display-name
const KlarnaScrapingFormWAC = forwardRef(({ form, baseRun, stepRun }: IProps) => {
  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const { dispatch } = useWizardState()
  const ref = useRef<HTMLDivElement>(null)
  const reactQueryClient = useQueryClient()

  const pickListBaseVariableId = useMemo(() => findBaseVariableId(form, 'pick_list'), [form])
  const typeBaseVariableId = useMemo(() => findBaseVariableId(form, 'type'), [form])
  const pdpBaseVariableId = useMemo(() => findBaseVariableId(form, 'pdp'), [form])
  const checkoutBaseVariableId = useMemo(() => findBaseVariableId(form, 'checkout'), [form])
  const pdpFreeTextBaseVariableId = useMemo(() => findBaseVariableId(form, 'pdp_free_text'), [form])
  const checkoutFreeTextBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'checkout_free_text'),
    [form]
  )
  const additionalNotesBaseVariableId = useMemo(
    () => findBaseVariableId(form, 'additional_notes'),
    [form]
  )

  const { data } = useQuery([
    'baseRunVariable.findByBaseRunIdAndBaseVariableId',
    [
      {
        baseRunId: baseRun.id,
        baseVariableIds: [
          pickListBaseVariableId,
          typeBaseVariableId,
          pdpBaseVariableId,
          checkoutBaseVariableId,
          additionalNotesBaseVariableId,
          pdpFreeTextBaseVariableId,
          checkoutFreeTextBaseVariableId,
        ],
      },
    ],
  ])

  const pickList = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pickListBaseVariableId) ?? [],
    [data, pickListBaseVariableId]
  )
  const type = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, typeBaseVariableId) ?? '',
    [data, typeBaseVariableId]
  )

  const initialPdp = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpBaseVariableId) ?? [],
    [data, pdpBaseVariableId]
  )
  const initialCheckout = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutBaseVariableId) ?? [],
    [data, checkoutBaseVariableId]
  )
  const initialAdditionalNotes = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, additionalNotesBaseVariableId) ?? '',
    [data, additionalNotesBaseVariableId]
  )
  const initialPdpFreeText = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpFreeTextBaseVariableId) ?? [],
    [data, pdpFreeTextBaseVariableId]
  )
  const initialCheckoutFreeText = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeTextBaseVariableId) ?? [],
    [data, checkoutFreeTextBaseVariableId]
  )

  const [pdp, setPdp] = useState<(string | null)[]>(Array(3).fill(''))
  const [checkout, setCheckout] = useState<(string | null)[]>(Array(8).fill(''))
  const [additionalNotes, setAdditionalNotes] = useState<string>('')
  const [pdpFreeText, setPdpFreeText] = useState<(string | null)[]>(Array(2).fill(''))
  const [checkoutFreeText, setCheckoutFreeText] = useState<(string | null)[]>(Array(2).fill(''))

  const disabledDueToInputValidation: boolean =
    pdp[0] || pdpFreeText[0] || checkout[0] || checkoutFreeText[0] ? false : true

  const disabledDueToRepeatedProvidersPdp = useMemo<boolean>(
    () => new Set(pdp.filter((a) => a)).size !== pdp.filter((a) => a).length,
    [pdp]
  )
  const disabledDueToRepeatedProvidersCheckout = useMemo<boolean>(
    () =>
      type === 'complex' &&
      new Set(checkout.filter((a) => a)).size !== checkout.filter((a) => a).length,
    [type, checkout]
  )

  const disabledDueToMandatoryNotesPdp = useMemo<boolean>(
    () => !!pdp.find((p) => MANDATORY_PROVIDERS.includes(p || '')) && additionalNotes.trim() === '',
    [pdp, additionalNotes]
  )

  const disabledDueToMandatoryNotesCheckout = useMemo<boolean>(
    () =>
      type === 'complex' &&
      !!checkout.find((p) => MANDATORY_PROVIDERS.includes(p || '')) &&
      additionalNotes.trim() === '',
    [type, checkout, additionalNotes]
  )

  const disabledDueToNoBNPL = useMemo<boolean>(() => pdp[0] === PROVIDER_NO_BNPL, [pdp])

  const disabled = useMemo<boolean>(
    () =>
      disabledDueToRepeatedProvidersPdp ||
      disabledDueToRepeatedProvidersCheckout ||
      disabledDueToMandatoryNotesPdp ||
      disabledDueToMandatoryNotesCheckout ||
      disabledDueToInputValidation,
    [
      disabledDueToRepeatedProvidersPdp,
      disabledDueToRepeatedProvidersCheckout,
      disabledDueToMandatoryNotesPdp,
      disabledDueToMandatoryNotesCheckout,
      disabledDueToInputValidation,
    ]
  )

  const getOptions = useCallback(
    (type: 'pdp' | 'checkout', idx) => {
      const arr = type === 'pdp' ? pdp : checkout
      const newPickList = pickList.filter(
        (p) => type === 'checkout' || idx === 0 || p !== PROVIDER_NO_BNPL
      )
      return newPickList
        .slice(idx === 0 ? 0 : newPickList.indexOf(arr[idx - 1] as string) + 1)
        .map((p) => ({ key: p, value: p }))
    },
    [pickList, pdp, checkout]
  )

  useEffect(() => {
    const newPdp = pdp.slice()
    initialPdp.forEach((p, idx) => (newPdp[idx] = p))
    setPdp(newPdp)
  }, [initialPdp]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newCheckout = checkout.slice()
    initialCheckout.forEach((p, idx) => (newCheckout[idx] = p))
    setCheckout(newCheckout)
  }, [initialCheckout]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAdditionalNotes(initialAdditionalNotes)
  }, [initialAdditionalNotes])

  useEffect(() => {
    const newPdpFreeText = pdpFreeText.slice()
    initialPdpFreeText.forEach((p, idx) => (newPdpFreeText[idx] = p))
    setPdpFreeText(newPdpFreeText)
  }, [initialPdpFreeText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newCheckoutFreeText = checkoutFreeText.slice()
    initialCheckoutFreeText.forEach((p, idx) => (newCheckoutFreeText[idx] = p))
    setCheckoutFreeText(newCheckoutFreeText)
  }, [initialCheckoutFreeText]) // eslint-disable-line react-hooks/exhaustive-deps

  ///left this because we use it in the regular form not sure if it needs adjustment
  const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
  })

  const { mutateAsync: updateManyBaseRunVariables } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const handleSubmit = async () => {
    try {
      const baseVariablesToUpdate = [
        {
          baseVariableId: pdpBaseVariableId,
          baseRunId: baseRun.id,
          value: pdp,
        },
        {
          baseVariableId: checkoutBaseVariableId,
          baseRunId: baseRun.id,
          value: checkout,
        },
        {
          baseVariableId: pdpFreeTextBaseVariableId,
          baseRunId: baseRun.id,
          value: pdpFreeText,
        },
        {
          baseVariableId: checkoutFreeTextBaseVariableId,
          baseRunId: baseRun.id,
          value: checkoutFreeText,
        },
        {
          baseVariableId: additionalNotesBaseVariableId,
          baseRunId: baseRun.id,
          value: additionalNotes,
        },
      ]
      await updateManyBaseRunVariables({ stepRunId: stepRun.id, data: baseVariablesToUpdate })

      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    } catch (error) {
      sendErrorToSentry(error)
      addToast(`Something went wrong: ${(error as Error).message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <div
      className='h-5/5 border-gray box-border overflow-auto rounded-lg border bg-white p-2.5 shadow'
      ref={ref}>
      <Text mb='10px' fontWeight='bold'>
        {type === 'simple' ? 'Alternate Payment Providers' : 'PDP Providers'}
      </Text>
      {pdp?.map((name: string | null, id: any) => (
        <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
          <Text textAlign='end' width='40%' mr={2}>
            {id + 1}
          </Text>
          <div className='w-7/12'>
            <Dropdown
              name=''
              placeholder=''
              width='100%'
              selectedKey={name}
              clearText='(clear)'
              onClear={() => setPdp(pdp.map((p, i) => (i === id ? '' : p)))}
              disabled={id > 0 && disabledDueToNoBNPL}
              options={getOptions('pdp', id)}
              onChange={({ value }) => {
                setPdp(pdp.map((p, i) => (i === id ? (value as string) : p)))
              }}
            />
          </div>
        </Flex>
      ))}
      <Text mb='10px' fontWeight='bold'>
        {'PDP Free Text'}
      </Text>
      {pdpFreeText?.map((name: string | null, id: any) => (
        <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
          <Text textAlign='end' width='40%' mr={2}>
            {id + 1}
          </Text>
          <div className={`w-7/12`}>
            <Input
              width='100%'
              value={pdpFreeText[id] ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPdpFreeText(pdpFreeText.map((p, i) => (i === id ? e.target.value : p)))
              }}
            />
          </div>
        </Flex>
      ))}
      {type === 'complex' && (
        <>
          <Text mb='10px' fontWeight='bold'>
            {'Checkout Providers'}
          </Text>
          {checkout?.map((name: string | null, id: any) => (
            <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
              <Text textAlign='end' width='40%' mr={2}>
                {id + 1}
              </Text>
              <div className='w-7/12'>
                <Dropdown
                  name=''
                  placeholder=''
                  width='100%'
                  selectedKey={name}
                  clearText='(clear)'
                  onClear={() => setCheckout(checkout.map((p, i) => (i === id ? '' : p)))}
                  disabled={disabledDueToNoBNPL}
                  options={getOptions('checkout', id)}
                  onChange={({ value }) =>
                    setCheckout(checkout.map((p, i) => (i === id ? (value as string) : p)))
                  }
                />
              </div>
            </Flex>
          ))}
        </>
      )}
      {type === 'complex' && (
        <>
          <Text mb='10px' fontWeight='bold'>
            {'Checkout Free Text'}
          </Text>
          {checkoutFreeText?.map((name: string | null, id: any) => (
            <Flex key={id} alignItems='center' justifyContent='center' mb={3}>
              <Text textAlign='end' width='40%' mr={2}>
                {id + 1}
              </Text>
              <div className={`w-7/12`}>
                <Input
                  width='100%'
                  value={checkoutFreeText[id] ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCheckoutFreeText(
                      checkoutFreeText.map((p, i) => (i === id ? e.target.value : p))
                    )
                  }}
                />
              </div>
            </Flex>
          ))}
        </>
      )}
      <Text mb='10px' fontWeight='bold'>
        {'Additional Notes'}
      </Text>
      <Flex alignItems='center' justifyContent='center' mb={3}>
        <div className={`w-7/12`}>
          <Input
            width='100%'
            value={additionalNotes}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAdditionalNotes(e.target.value)}
          />
        </div>
      </Flex>
      <div className='mt-3'>
        <Button
          variant='primary'
          size='md'
          onClick={handleSubmit}
          disabled={disabled}
          loading={isMarkStepRunDoneLoading}
          wFull>
          {'Submit'}
        </Button>
      </div>
    </div>
  )
})

export { KlarnaScrapingFormWAC }
