import { createContext, PropsWithChildren, useState } from 'react'
import { ThemeProvider as BaseThemeProvider } from 'styled-components'

import { TTheme } from './base-theme'
import light from './dal3-light-theme'
import GlobalStyle from './globalStyles'

const SetThemeContext = createContext<any>({})

interface IThemePoviderProps extends PropsWithChildren<any> {
  theme?: TTheme
}

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends TTheme {}
}

const ThemeProvider = (props: IThemePoviderProps) => {
  const [theme, setTheme] = useState(props.theme)
  return (
    <BaseThemeProvider theme={theme || light}>
      <SetThemeContext.Provider value={{ theme, setTheme }}>
        <GlobalStyle />
        {props.children}
      </SetThemeContext.Provider>
    </BaseThemeProvider>
  )
}
export default ThemeProvider

export { SetThemeContext }
