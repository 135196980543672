import { SVGProps } from 'react'
function EditFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.0427 15.893V29.9699C31.0427 30.6487 30.773 31.2997 30.293 31.7797C29.8131 32.2596 29.1621 32.5293 28.4833 32.5293H2.88901C2.21021 32.5293 1.55921 32.2596 1.07923 31.7797C0.599243 31.2997 0.32959 30.6487 0.32959 29.9699V4.37563C0.32959 3.69683 0.599243 3.04583 1.07923 2.56585C1.55921 2.08586 2.21021 1.81621 2.88901 1.81621H16.9658C17.3052 1.81621 17.6307 1.95103 17.8707 2.19103C18.1107 2.43102 18.2456 2.75652 18.2456 3.09592C18.2456 3.43532 18.1107 3.76082 17.8707 4.00081C17.6307 4.24081 17.3052 4.37563 16.9658 4.37563H2.88901V29.9699H28.4833V15.893C28.4833 15.5536 28.6181 15.2281 28.8581 14.9881C29.0981 14.7482 29.4236 14.6133 29.763 14.6133C30.1024 14.6133 30.4279 14.7482 30.6679 14.9881C30.9079 15.2281 31.0427 15.5536 31.0427 15.893ZM31.9545 7.84685L16.5979 23.2034C16.3541 23.4411 16.0266 23.5732 15.6861 23.5713H10.5673C10.2279 23.5713 9.90239 23.4365 9.66239 23.1965C9.4224 22.9565 9.28757 22.631 9.28757 22.2916V17.1728C9.28566 16.8323 9.41782 16.5047 9.65549 16.261L25.012 0.904413C25.2547 0.664106 25.5823 0.529297 25.9238 0.529297C26.2653 0.529297 26.593 0.664106 26.8356 0.904413L31.9545 6.02326C32.1948 6.26589 32.3296 6.59357 32.3296 6.93506C32.3296 7.27654 32.1948 7.60423 31.9545 7.84685ZM29.2351 6.93506L25.9238 3.6238L23.8923 5.65534L27.2035 8.9666L29.2351 6.93506Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EditFilled
