import { SVGProps } from 'react'

function OnboardingRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.4969 19.1219C16.5789 19.0398 16.625 18.9285 16.625 18.8125C16.625 18.6965 16.5789 18.5852 16.4969 18.5031C16.4148 18.4211 16.3035 18.375 16.1875 18.375H8.3125C8.19647 18.375 8.08519 18.4211 8.00314 18.5031C7.92109 18.5852 7.875 18.6965 7.875 18.8125C7.875 18.9285 7.92109 19.0398 8.00314 19.1219C8.08519 19.2039 8.19647 19.25 8.3125 19.25H16.1875C16.3035 19.25 16.4148 19.2039 16.4969 19.1219Z'
        fill='currentColor'
      />
      <path
        d='M19.4507 10.4382L20.4701 11.7447H20.4684C20.5065 11.7936 20.5337 11.85 20.5483 11.9103C20.5629 11.9705 20.5646 12.0331 20.5531 12.094C20.5417 12.1549 20.5175 12.2127 20.482 12.2636C20.4466 12.3144 20.4008 12.3571 20.3476 12.3889L12.2861 17.2014C11.9501 17.3985 11.5677 17.5026 11.1781 17.5033C10.6232 17.5027 10.0895 17.29 9.68625 16.9088L9.67969 16.9023L7.71094 14.9734C7.60669 14.8744 7.52844 14.7513 7.4831 14.6148C7.43777 14.4784 7.42676 14.3329 7.45103 14.1912C7.47531 14.0495 7.53412 13.916 7.62228 13.8024C7.71044 13.6888 7.82523 13.5987 7.95648 13.5401L8.12055 13.4597C8.22416 13.4088 8.34367 13.401 8.45305 13.4378L9.99852 13.9595L11.1016 13.2923L9.90664 12.1335C9.79928 12.0346 9.71837 11.9104 9.67128 11.7722C9.6242 11.634 9.61243 11.4863 9.63705 11.3424C9.66168 11.1985 9.72191 11.0631 9.81227 10.9484C9.90262 10.8337 10.0202 10.7435 10.1544 10.6859L10.1719 10.6788L10.564 10.5295C10.6626 10.4928 10.7711 10.4928 10.8697 10.5295L13.8195 11.6145L16.6398 9.93125C17.0892 9.66377 17.6199 9.56697 18.1348 9.65854C18.6497 9.75012 19.1145 10.024 19.4441 10.43L19.4507 10.4382Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40608 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50334 20.9221 7.21716 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default OnboardingRibbon
