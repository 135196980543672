import { useQuery } from '@invisible/trpc/client'
import { format } from '@invisible/ui/helpers'
import { ArrowIndicatorIcon, HourglassFilledIcon } from '@invisible/ui/icons'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { Tag } from '@invisible/ui/tag'
import { useMemo, useState } from 'react'
import sanitize from 'sanitize-html'

import Chart from './Chart'

const ProcessOverview = ({
  processId,
  companyName,
  processCreatedAt,
  processUpdatedAt,
  processDescription,
  baseSla,
  baseQualityKpi,
}: {
  processId?: string
  companyName?: string
  processCreatedAt?: Date
  processUpdatedAt?: Date
  processDescription?: string | null
  baseSla: number
  baseQualityKpi?: string | null
}) => {
  const { data: reportData } = useQuery(['report.processOverview', { processId: processId ?? '' }])

  const formattedData = useMemo(
    () => [
      {
        id: 'Process revenue',
        color: 'hsl(253, 37%, 47%)',
        data: [
          { x: 'Baseline', y: 0 },
          { x: 'Two months ago', y: reportData?.two_months_ago_usage_revenue ?? 0 },
          { x: 'Previous month', y: reportData?.previous_month_usage_revenue ?? 0 },
          { x: 'Current month', y: reportData?.current_mtd_usage_revenue ?? 0 },
        ],
      },
      {
        id: 'Direct Labor',
        color: 'hsl(253, 10%, 10%)',
        data: [
          { x: 'Baseline', y: 0 },
          { x: 'Two months ago', y: reportData?.two_months_ago_direct_labor ?? 0 },
          { x: 'Previous month', y: reportData?.previous_month_direct_labor ?? 0 },
          { x: 'Current month', y: reportData?.current_mtd_direct_labor ?? 0 },
        ],
      },
    ],
    [reportData]
  )

  const reportChanges = useMemo(() => {
    const revenueChange =
      (reportData?.previous_month_usage_revenue ?? 0) -
      (reportData?.two_months_ago_usage_revenue ?? 0)
    const dlMarginChange =
      (reportData?.previous_month_dl_margin ?? 0) - (reportData?.two_months_ago_dl_margin ?? 0)
    const gmMarginChange =
      (reportData?.previous_month_client_gm ?? 0) - (reportData?.two_months_ago_client_gm ?? 0)
    return { revenueChange, dlMarginChange, gmMarginChange }
  }, [reportData])
  const [activeTab, setActiveTab] = useState('sla')

  const convertSecondsInDays = (value: number) => {
    let seconds = value
    const slaDay = Math.floor(seconds / (3600 * 24))
    seconds -= slaDay * 3600 * 24
    const slaHour = Math.floor(seconds / 3600)
    seconds -= slaHour * 3600
    const slaMinute = Math.floor(seconds / 60)
    seconds -= slaMinute * 60

    return {
      day: slaDay,
      hours: slaHour,
      minutes: slaMinute,
      seconds: seconds,
    }
  }

  const convertedSLA = useMemo(() => convertSecondsInDays(baseSla), [baseSla])
  return (
    <div className='w-full p-3'>
      <div className='grid w-full grid-cols-[28%_22%_22%_24%_4%] gap-4'>
        <div className='border-theme-weak-3 rounded-md border-2 border-solid shadow-sm'>
          <div className='p-4'>
            <div className='pb-2 text-xl font-semibold'>Process Overview</div>
            <div className='text-strong pb-2 text-sm'>
              <div className='max-h-[400px] overflow-auto'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(processDescription ?? ''),
                  }}
                />
              </div>
            </div>
            <Tabs
              currentActiveTab={activeTab}
              onChange={(tabName) => setActiveTab(tabName as string)}>
              <Tab name='KPIs' value='sla'>
                <div className='mt-3 flex flex-col gap-2'>
                  <div className='flex gap-2'>
                    <div className='font-semibold'>TAT:</div>
                    <div className='text-strong text-sm'>
                      <HourglassFilledIcon width={12} height={12} className='text-green-main' />

                      <span className='ml-1'>{`${
                        convertedSLA.day < 10 ? `0${convertedSLA.day}` : convertedSLA.day
                      }:${
                        convertedSLA.hours < 10 ? `0${convertedSLA.hours}` : convertedSLA.hours
                      }:${
                        convertedSLA.minutes < 10
                          ? `0${convertedSLA.minutes}`
                          : convertedSLA.minutes
                      }:${
                        convertedSLA.seconds < 10
                          ? `0${convertedSLA.seconds}`
                          : convertedSLA.seconds
                      }`}</span>
                    </div>
                  </div>
                  <div className='gap-2'>
                    <div className='font-semibold'>Quality:</div>
                    <div className='text-strong text-sm'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitize(baseQualityKpi ?? ''),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab name='Details' value='details'>
                <div className='mt-3 flex flex-col gap-2'>
                  <div className='flex gap-2'>
                    <div className='font-semibold'>Created:</div>
                    <div className='text-strong text-sm'>
                      {processCreatedAt ? new Date(processCreatedAt).toDateString() : ''}
                    </div>
                  </div>
                  <div className='flex gap-2'>
                    <div className='font-semibold'>Last Updated:</div>
                    <div className='text-strong text-sm'>
                      {processUpdatedAt ? new Date(processUpdatedAt).toDateString() : ''}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab name='Deliverables' value='deliverables'></Tab>
            </Tabs>
          </div>
        </div>
        <div
          className='border-theme-weak-3 col-span-3 col-start-2 rounded-md border-2 border-solid
            shadow-sm'>
          <div className=''>
            <div className='pl-4 pt-4 text-xl font-semibold'>Process Financial Summary</div>
            <div className='h-64 w-full'>
              <Chart data={formattedData} />
            </div>
          </div>
        </div>
        <div></div>
        <div className='border-theme-weak-3 h-32 rounded-md border-2 border-solid shadow-sm'>
          <div className='flex flex-col justify-center gap-2 p-6 text-center'>
            <div className='text-theme-main text-xl'>
              {reportData?.previous_month_usage_revenue
                ? format.formatCurrency(reportData.previous_month_usage_revenue)
                : 0}
            </div>
            <div className='text-strong text-sm'>Last month revenue</div>
            <div className='flex justify-center'>
              {reportChanges?.revenueChange && reportChanges.revenueChange < 0 ? (
                <Tag color='red'>
                  <ArrowIndicatorIcon className='rotate-180' />{' '}
                  {format.formatCurrency(Math.abs(reportChanges?.revenueChange))}
                </Tag>
              ) : (
                <Tag color='success'>
                  <ArrowIndicatorIcon /> {format.formatCurrency(reportChanges?.revenueChange)}
                </Tag>
              )}
            </div>
          </div>
        </div>
        <div className='border-theme-weak-3 h-32 rounded-md border-2 border-solid shadow-sm'>
          <div className='flex flex-col justify-center gap-2 p-6 text-center'>
            <div className='text-theme-main text-xl'>
              {((reportData?.previous_month_dl_margin ?? 0) * 100).toFixed(2) + '%'}
            </div>
            <div className='text-strong text-sm'>Last month DL Margin</div>
            <div className='flex justify-center'>
              {reportChanges?.dlMarginChange && reportChanges.dlMarginChange < 0 ? (
                <Tag color='red'>
                  <ArrowIndicatorIcon className='rotate-180' />{' '}
                  {(Math.abs(reportChanges?.dlMarginChange) * 100).toFixed(2) + '%'}
                </Tag>
              ) : (
                <Tag color='success'>
                  <ArrowIndicatorIcon /> {(reportChanges?.dlMarginChange * 100).toFixed(2) + '%'}
                </Tag>
              )}
            </div>
          </div>
        </div>
        <div className='border-theme-weak-3 h-32 rounded-md border-2 border-solid shadow-sm'>
          <div className='flex flex-col justify-center gap-2 p-6 text-center'>
            <div className='text-theme-main text-xl'>
              {((reportData?.previous_month_client_gm ?? 0) * 100).toFixed(2) + '%'}
            </div>
            <div className='text-strong text-sm'>{companyName ?? ''} last month GM</div>
            <div className='flex justify-center'>
              {reportChanges?.gmMarginChange && reportChanges.gmMarginChange < 0 ? (
                <Tag color='red'>
                  <ArrowIndicatorIcon className='rotate-180' />{' '}
                  {(Math.abs(reportChanges?.gmMarginChange) * 100).toFixed(2) + '%'}
                </Tag>
              ) : (
                <Tag color='success'>
                  <ArrowIndicatorIcon /> {(reportChanges?.gmMarginChange * 100).toFixed(2) + '%'}
                </Tag>
              )}
            </div>
          </div>
        </div>
        <div className='border-theme-weak-3 h-32 rounded-md border-2 border-solid shadow-sm'>
          <div className='flex flex-col justify-center gap-2 p-6 text-center'>
            <div className='text-theme-main text-xl'>
              {reportData?.lifetime_usage_revenue
                ? format.formatCurrency(reportData.lifetime_usage_revenue)
                : ''}
            </div>
            <div className='text-strong text-sm'>Lifetime Revenue</div>
            <div className='flex justify-center'>
              <Tag color='gray'>
                Since {processCreatedAt ? new Date(processCreatedAt).toDateString() : ''}
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessOverview
