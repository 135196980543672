import { SVGProps } from 'react'
function DangerOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9.822 16.107a1.179 1.179 0 102.357 0 1.179 1.179 0 00-2.357 0zm.393-7.464v4.518c0 .108.088.196.196.196h1.179a.197.197 0 00.196-.196V8.643a.197.197 0 00-.196-.197H10.41a.197.197 0 00-.196.197zm11.68 10.803L11.681 1.768a.777.777 0 00-.68-.393c-.266 0-.53.13-.68.393L.105 19.446a.786.786 0 00.68 1.179h20.429a.786.786 0 00.68-1.179zm-19.238-.685L11.001 4.32l8.343 14.442H2.657z'
        fill='currentColor'
      />
      <defs>
        <clipPath id='danger-outline_svg__clip0'>
          <path fill='#fff' d='M0 0h22v22H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DangerOutline
