import { SnackbarContext } from '@invisible/common/providers'
import { logger } from '@invisible/logger/server'
import { useContext } from 'react'
import { useQuery } from 'react-query'

import { fetchBaseRunIds, fetchBaseRunSample } from '../helpers'
import { TReducerState } from '../reducer'

type TParam = TReducerState[keyof TReducerState]

interface IProps {
  filterConfigId: string
  baseRunId?: string
  enabled: boolean
  params?: TParam[]
}

export const useFetchBaseRunSample = ({ filterConfigId, baseRunId, enabled, params }: IProps) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const {
    data: { base_run_count: baseRunCount, base_run_ids: baseRunIds },
  } = useQuery(['list-baserun-ids', ...(params ?? [])], () => fetchBaseRunIds(filterConfigId), {
    placeholderData: [],
    enabled,
    onSuccess: ({ base_run_count: baseRunCount }) => {
      if (baseRunCount === 0) {
        showSnackbar({
          message: 'No baseruns match the selected filters!',
          variant: 'error',
        })
        return
      }
    },
    onError: (err: { message: string }) => {
      logger.error(err.message, { filterConfigId })
      showSnackbar({
        message:
          'Encountered an error while trying to fetch base runs that match the selected filters.',
        variant: 'error',
      })
    },
  })

  const {
    data: baseRunSample,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['fetch-baserun-sample', baseRunId],
    // if baseRunId is not provided, use the first baseRunId from the list
    () => fetchBaseRunSample(baseRunId || baseRunIds[0], filterConfigId),
    {
      placeholderData: {},
      enabled: baseRunCount > 0,
      staleTime: 60000,
      onError: (err: { message: string }) => {
        logger.error(err.message, { filterConfigId })
        showSnackbar({
          message: 'Encountered an error while trying to fetch the base run data',
          variant: 'error',
        })
      },
    }
  )

  return {
    baseRunSample,
    baseRunIds,
    baseRunCount,
    isLoading,
    isError,
    error,
  }
}
