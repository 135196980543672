import 'reactflow/dist/style.css'

import {
  TProcessByIdStep,
  TProcessLifecycleStage,
  TProcessStepGoTo,
  useProcessById,
} from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { useQuery } from '@invisible/trpc/client'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useMemo } from 'react'
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  EdgeProps,
  OnNodesChange,
  useReactFlow,
} from 'reactflow'
import shallow from 'zustand/shallow'

import { DefaultEdge } from './BaseRunGraphEdge'
import { BaseRunGraphNode } from './BaseRunGraphNode'

const nodeTypes = {
  ExecGraph: BaseRunGraphNode,
}

const edgeTypes = {
  default: (props: EdgeProps) => <DefaultEdge {...props} data={props.data ?? {}} />,
}

interface IProps {
  baseRunId: string
}

const getNodeStatus = (stepRunStatus: string) =>
  ['failed', 'running', 'snoozed', 'done'].includes(stepRunStatus) ? stepRunStatus : 'none'

// eslint-disable-next-line @typescript-eslint/ban-types
const BaseRunGraph: FC<IProps> = ({ baseRunId }) => {
  const { setNodes, setEdges } = useReactFlow()
  const { convertProcessToNodesAndEdges, positionedEdges, positionedNodes, applyNodeChanges } =
    useStore(
      useCallback(
        (state) => ({
          positionedNodes: state.positionedNodes,
          positionedEdges: state.positionedEdges,
          convertProcessToNodesAndEdges: state.convertProcessToNodesAndEdges,
          applyNodeChanges: state.onNodeChanges,
        }),
        []
      ),
      shallow
    )

  const { query } = useRouter()

  const { data, isLoading: processDataLoading } = useProcessById({
    id: query.id as string,
  })

  const { data: stepRuns, isLoading: stepRunDataLoading } = useQuery([
    'stepRun.findManyForBaseRunExecutionGraph',
    { baseRunId },
  ])

  useEffect(() => {
    if (!data) return
    convertProcessToNodesAndEdges({
      steps: (data?.steps?.map((s) => ({ ...s, lifecycleStageId: null })) as TProcessByIdStep[]) ?? [],
      stepGoTos: (data?.stepGoTos as TProcessStepGoTo[]) ?? [],
      processId: data?.id,
      rootBaseId: data.rootBaseId ?? '',
      processStatus: data.status,
      stages: [],
    })
  }, [data])

  const nodes = useMemo(
    () =>
      positionedNodes.map((node) => {
        const stepRunEntry = (stepRuns ?? []).find((sr) => sr.stepId === node.id)

        return {
          ...node,
          type: 'ExecGraph',
          data: {
            ...node.data,
            metaData: stepRunEntry,
          },
        }
      }),
    [positionedNodes, stepRuns]
  )

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      const changesWithoutRemove = changes.filter((change) => change.type !== 'remove')
      applyNodeChanges(changesWithoutRemove)
    },
    [applyNodeChanges]
  )

  const unselectNodes = useCallback(() => {
    setNodes((pNodes) =>
      pNodes.map((node) => (node.selected ? { ...node, selected: false } : node))
    )
    setEdges((pEdges) =>
      pEdges.map((edge) => (edge.selected ? { ...edge, selected: false } : edge))
    )
  }, [setNodes, setEdges])

  return processDataLoading || stepRunDataLoading ? (
    <div className='mt-20 flex justify-center'>
      <LogoSpinner width={30} height={30} />
    </div>
  ) : (
    <div className='relative h-[calc(100vh-150px)] w-[calc(100vw-20px)]'>
      <div className='fixed bottom-6 right-10 z-50 h-52 w-40 tracking-wider'>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-green-500' />
          <span className='text-xs uppercase'>Completed</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-red-500' />
          <span className='text-xs uppercase'>Failed</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-yellow-500' />
          <span className='text-xs uppercase'>Snoozed</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-orange-700' />
          <span className='text-xs uppercase'>Expired</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-blue-500' />
          <span className='text-xs uppercase'>Running</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-violet-500' />
          <span className='text-xs uppercase'>Queued</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-black' />
          <span className='text-xs uppercase'>Disabled</span>
        </div>
        <div className='mb-3 flex items-center gap-4'>
          <div className='h-4 w-4 rounded-full bg-gray-400' />
          <span className='text-xs uppercase'>Not started</span>
        </div>
      </div>
      <ReactFlow
        minZoom={0.3}
        fitView
        snapToGrid={true}
        panOnScroll={true}
        nodesConnectable={true}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={positionedEdges}
        onNodesChange={onNodesChange}
        onPaneClick={unselectNodes}>
        <Background
          variant={BackgroundVariant.Dots}
          gap={22}
          size={0.5}
          style={{ color: '#D1D6EF', backgroundColor: '#F1F2F7' }}
        />
        <Controls />
      </ReactFlow>
    </div>
  )
}

export { BaseRunGraph }
