import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'

// eslint-disable-next-line @typescript-eslint/ban-types
export const LineChartSkeleton: React.FC<{ animate: boolean }> = ({ animate }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(false)
    const showTimeout = setTimeout(() => {
      setShow(true)
    }, 0)

    return () => {
      clearTimeout(showTimeout)
    }
  }, [animate])

  if (!show) return null

  return (
    <ContentLoader
      viewBox={`0 0 400 300`}
      width={400}
      height={300}
      speed={2}
      animate={animate}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'>
      {/* X-axis */}
      <rect x='50' y='290' rx='4' ry='4' width='350' height='12' />

      {/* Y-axis */}
      <rect x='50' y='0' rx='4' ry='4' width='12' height='300' />

      {/* Rectangles */}
      <rect x='80' y='65' rx='4' ry='4' width='200' height='8' />
      <rect x='150' y='115' rx='4' ry='4' width='200' height='8' />
      <rect x='120' y='165' rx='4' ry='4' width='200' height='8' />
      <rect x='170' y='215' rx='4' ry='4' width='200' height='8' />
    </ContentLoader>
  )
}
