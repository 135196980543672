import { constObjectToZod, z } from '@invisible/zod'
import { values } from 'lodash/fp'

const TAG_TYPES = {
  GENERAL: 'general',
  STEP_TEMPLATE: 'step_template',
  PROCESS: 'process',
} as const

const _TAG_TYPES = values(TAG_TYPES)
const TagTypesSchema = constObjectToZod(TAG_TYPES)
type TTagType = z.infer<typeof TagTypesSchema>

export { _TAG_TYPES, TagTypesSchema }
export type { TTagType }
