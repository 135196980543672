import type { TProcessStepTemplate } from '@invisible/common/components/process-base'
import { State, TBaseRunVariable } from '@invisible/common/components/providers/active-wizard-provider'
import { logger } from '@invisible/logger/client'
import { useQuery } from '@invisible/trpc/client'
import {
  BuildIcon,
  ClientIcon,
  FlagOutlineIcon,
  InvisibleIcon,
  RobotHeadIcon,
  WizardHatIcon,
} from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import axios from 'axios'
import { isEmpty } from 'lodash/fp'
import Papa from 'papaparse'
import { ChangeEvent } from 'react'

export const convertCsvToJson = ({
  e,
  setFormValues,
  label,
}: {
  e: ChangeEvent<HTMLInputElement>
  setFormValues: (p: any) => void
  label: string
}) => {
  const files = (e.target as HTMLInputElement).files
  if (isEmpty(files)) return
  Papa.parse(files?.[0] as File, {
    download: true,
    header: true,
    complete: (json: Papa.ParseResult<any>) => {
      setFormValues((prev: any) => ({
        ...prev,
        [label]: json.data,
      }))
    },
  })
}

export function findBaseVariableId(
  targetForm: WizardSchemas.WACConfig.TSchema['form'],
  label: string
) {
  return targetForm?.fields?.find((f) => f.label === label)?.baseVariableId ?? ''
}
export function getBaseRunVariableFromQueryData<T>(
  data?: ReturnType<typeof useQuery>['data'],
  targetId?: string
): T {
  if (!targetId || !data) {
    return null as unknown as T
  }
  return (
    (data as Array<any>).find(
      ({ baseVariableId }: { baseVariableId: string }) => baseVariableId === targetId
    )?.value ?? (null as unknown as T)
  )
}

export function getBaseRunVariableFromWizardState<T>(wizardState?: State, targetId?: string): T {
  if (!targetId || !wizardState) {
    return null as unknown as T
  }
  return ((wizardState?.baseRun?.baseRunVariables as TBaseRunVariable[]).find(
    (b) => b.baseVariableId === targetId
  )?.value ?? null) as unknown as T
}

export const blobToBase64 = (blob: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => {
      logger.error('blobToBase64 error', { error })
      resolve(undefined)
    }
  })

export const computeBuilderStepColors = (template?: TProcessStepTemplate) => {
  if (template?.companyId)
    return {
      border: 'border-admin-main',
      text: 'text-admin-main',
      bg: '!bg-admin-main',
      icon: ClientIcon,
    }
  if (template?.type === 'trigger')
    return {
      border: 'border-green-main',
      text: 'text-green-main',
      bg: '!bg-green-main',
      icon: FlagOutlineIcon,
    }
  if (template?.type === 'control_flow')
    return {
      border: 'border-orange-main',
      text: 'text-orange-main',
      bg: '!bg-orange-main',
      icon: BuildIcon,
    }
  if (template?.type === 'manual')
    return {
      border: 'border-fuchsia-main',
      text: 'text-fuchsia-main',
      bg: '!bg-fuchsia-main',
      icon: WizardHatIcon,
    }
  if (template?.type === 'full_auto' || template?.type === 'semi_auto')
    return {
      border: 'border-sky-main',
      text: 'text-sky-main',
      bg: '!bg-sky-main',
      icon: RobotHeadIcon,
    }

  return {
    border: 'border-primary',
    text: 'text-primary',
    bg: '!bg-primary',
    icon: InvisibleIcon,
  }
}

export const validateLooopResourceHub = async ({ resourceHubId }: { resourceHubId: string }) => {
  const { data } = await axios.post('/api/looop/get-resource-hub-details', {
    resourceHubId,
  })
  return data
}

export const getLooopUserDetails = async ({ email }: { email: string }) => {
  const { data } = await axios.post('/api/looop/get-user-data', {
    email,
  })
  return data
}
