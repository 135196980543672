import { SVGProps } from 'react'
function Help(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.187 3.638a3.995 3.995 0 00-.926-1.275A4.405 4.405 0 007.25 1.202a4.41 4.41 0 00-3.011 1.16 4.001 4.001 0 00-.927 1.276 3.77 3.77 0 00-.344 1.576v.422c0 .097.078.175.175.175h.844a.175.175 0 00.175-.175v-.422c0-1.554 1.384-2.818 3.088-2.818 1.703 0 3.087 1.264 3.087 2.818 0 .638-.227 1.238-.656 1.738a3.144 3.144 0 01-1.69 1A1.713 1.713 0 006.655 9.63v.49c0 .097.078.176.175.176h.844a.175.175 0 00.175-.175V9.63c0-.245.17-.461.406-.514a4.359 4.359 0 002.333-1.386c.298-.349.531-.736.692-1.156a3.783 3.783 0 00-.092-2.936zM7.25 11.546a.876.876 0 100 1.75.876.876 0 000-1.75z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Help
