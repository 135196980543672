import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useContext, useQuery } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { FC, useEffect, useMemo, useState } from 'react'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { MergeRank } from './MergeRank'
import { QuickRank } from './QuickRank'
import { Rank } from './rank'
import { TNormalizedBaseRun } from './types'
import { View } from './view'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  isReview?: boolean
  stepRun: TStepRun
  isReadOnly: boolean
}

const RankingWAC = ({ baseRun, ranking, stepRun, isReadOnly }: IProps) => {
  const config = ranking as NonNullable<WizardSchemas.Ranking.TSchema>
  const reactQueryContext = useContext()
  const { dispatch } = useWizardState()

  const [currentView, setCurrentView] = useState<'rank' | 'view'>('rank')

  // isFetchedAfterMount is False when component mounts and becomes true when the data is fetched from DB.
  const { data: rankingBaseRuns, isFetchedAfterMount } = useQuery(
    [
      'baseRun.findChildBaseRuns',
      {
        baseId: config.baseId as string,
        parentBaseRunId: baseRun.id,
      },
    ],
    /*
      We set the refetchOnMount to always to make sure that the data is fetched from DB when the component mounts.
      This is to avoid the bug where something in the background updates the data, but this component uses the stale cache set by the previous component.
    */
    { refetchOnMount: 'always' }
  )

  // When the WAC loads up, we set the readyForSubmit to false. This is to avoid the user to submit the form before ranking the base runs.
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'Ranking',
      value: false,
    })
  }, [])

  const { mutateAsync: updateManyVariables, isLoading: isUpdatingManyVariables } =
    useBaseRunVariablesWizardUpdate({
      onMutate: async (data) => {
        // We make an optimistic update to the cache to avoid a lag to the user while the updated data is re-fetched
        await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')
        reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config.baseId as string,
              parentBaseRunId: baseRun.id,
            },
          ],
          (prevData) => {
            if (!prevData) return

            const updatedData = data.reduce((acc, obj) => {
              acc[obj.baseRunId!] = { [obj.baseVariableId!]: obj.value }
              return acc
            }, {} as { [key: string]: { [key: string]: unknown } })

            const newData = prevData.map((baseRun) => ({
              ...baseRun,
              baseRunVariables: baseRun.baseRunVariables.map((variable) => ({
                ...variable,
                value: updatedData[baseRun.id]?.[variable.baseVariable.id] ?? variable.value,
              })),
            }))

            return newData as TFindChildBaseRunsData
          }
        )
      },
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
    })

  // Parses prompts base runs into a typed object with its base run variables
  const normalizedBaseRuns: TNormalizedBaseRun[] = useMemo(
    () =>
      (rankingBaseRuns ?? [])
        .map((baseRun, index) => ({
          id: baseRun.id,
          rank: (baseRun.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.rankBaseVariableId
          )?.value ?? index + 1) as number,
          message: baseRun.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.rankingMessageBaseVariableId
          )?.value as string,
          skipDecisionVariableValue: baseRun.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.skipDecisionVariableId
          )?.value as boolean,
        }))
        .filter((baseRun) => {
          if (!config.skipDecisionVariableId) return true
          return baseRun.skipDecisionVariableValue !== config.skipDecisionCondition
        })
        .sort((a, b) => a.rank - b.rank),
    [config.rankBaseVariableId, config.rankingCriteria, rankingBaseRuns]
  )

  const updateRankings = async (dataToBeRanked: TNormalizedBaseRun[]) => {
    if (dataToBeRanked.length > 0) {
      // We convert the data to be ranked into an array of objects that can be used to update the base run variables
      const updateData = dataToBeRanked.map((baseRun) => ({
        baseRunId: baseRun.id,
        baseVariableId: config.rankBaseVariableId as string,
        value: baseRun.rank,
      }))

      await updateManyVariables({ stepRunId: stepRun.id, data: updateData })
    }

    // At this point the ranking is done, so we set the readyForSubmit to true.
    dispatch({
      type: 'setReadyForSubmit',
      key: 'Ranking',
      value: true,
    })

    // We default to showing the User the updated Ordering so they can review it
    setCurrentView('view')
  }

  const renderRankComponent = () => {
    // NergeSort is the default algorithm as it's as efficient as QuickSort and also supports Equality Ranking.
    switch (config.rankingAlgorithm) {
      case 'QuickSort':
        return (
          <QuickRank
            onSave={async (dataToBeRanked: TNormalizedBaseRun[]) => updateRankings(dataToBeRanked)}
            allBaseRuns={normalizedBaseRuns}
            setCurrentView={setCurrentView}
            isUpdatingManyVariables={isUpdatingManyVariables}
            config={config}
            readOnly={isReadOnly}></QuickRank>
        )

      case 'BubbleSort':
        return (
          <Rank
            onSave={async (dataToBeRanked: TNormalizedBaseRun[]) => updateRankings(dataToBeRanked)}
            allBaseRuns={normalizedBaseRuns}
            setCurrentView={setCurrentView}
            isUpdatingManyVariables={isUpdatingManyVariables}
            config={config}
            readOnly={isReadOnly}></Rank>
        )

      default:
        return (
          <MergeRank
            onSave={async (dataToBeRanked: TNormalizedBaseRun[]) => updateRankings(dataToBeRanked)}
            allBaseRuns={normalizedBaseRuns}
            setCurrentView={setCurrentView}
            isUpdatingManyVariables={isUpdatingManyVariables}
            config={config}
            readOnly={isReadOnly}></MergeRank>
        )
    }
  }

  return (
    <div className='relative box-border flex h-full w-full flex-col justify-between gap-4  rounded-md border border-solid border-gray-300 bg-white'>
      {isFetchedAfterMount && currentView === 'rank' && normalizedBaseRuns.length > 1 ? (
        <div>{renderRankComponent()}</div>
      ) : isFetchedAfterMount ? (
        <View
          onSave={async (dataToBeRanked: TNormalizedBaseRun[]) => updateRankings(dataToBeRanked)}
          allBaseRuns={normalizedBaseRuns}
          isUpdatingManyVariables={isUpdatingManyVariables}
          setCurrentView={setCurrentView}></View>
      ) : (
        <div>
          <span>Loading...</span>
        </div>
      )}
    </div>
  )
}

export { RankingWAC }
