import { SVGProps } from 'react'
function CheckCircleFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.2454 0.5C13.0809 0.5 9.98743 1.43838 7.35624 3.19649C4.72506 4.95459 2.6743 7.45344 1.4633 10.3771C0.252294 13.3007 -0.0645594 16.5177 0.552804 19.6214C1.17017 22.7251 2.69402 25.5761 4.93166 27.8137C7.1693 30.0513 10.0202 31.5752 13.1239 32.1926C16.2276 32.8099 19.4447 32.4931 22.3683 31.2821C25.2919 30.0711 27.7908 28.0203 29.5489 25.3891C31.307 22.7579 32.2454 19.6645 32.2454 16.5C32.2372 12.259 30.5489 8.19409 27.5501 5.19527C24.5513 2.19645 20.4863 0.508132 16.2454 0.5ZM23.8607 13.7L14.8454 22.3154C14.6123 22.5346 14.3038 22.6558 13.9838 22.6538C13.8274 22.6561 13.6721 22.6273 13.5269 22.5692C13.3817 22.5111 13.2494 22.4249 13.1377 22.3154L8.62998 18.0077C8.50495 17.8986 8.40327 17.7654 8.33105 17.616C8.25883 17.4666 8.21756 17.3042 8.20972 17.1384C8.20188 16.9727 8.22763 16.8071 8.28542 16.6516C8.34322 16.496 8.43186 16.3538 8.54603 16.2334C8.6602 16.113 8.79754 16.0169 8.94979 15.951C9.10203 15.885 9.26604 15.8505 9.43196 15.8495C9.59788 15.8486 9.76228 15.8812 9.91529 15.9453C10.0683 16.0095 10.2067 16.104 10.3223 16.2231L13.9838 19.7154L22.1684 11.9154C22.4077 11.7066 22.7185 11.5984 23.0357 11.6134C23.353 11.6284 23.6521 11.7655 23.8706 11.9959C24.0892 12.2264 24.2102 12.5324 24.2083 12.8499C24.2065 13.1675 24.0819 13.4721 23.8607 13.7Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CheckCircleFilled
