import { SVGProps } from 'react'
function FileFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='29'
      viewBox='0 0 25 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24.0423 8.84516L16.4059 1.3067C16.1981 1.10669 15.9189 0.995472 15.6287 0.997088H2.53777C1.95912 0.997088 1.40417 1.22401 0.994996 1.62793C0.585826 2.03186 0.355957 2.5797 0.355957 3.15093V26.8432C0.355957 27.4145 0.585826 27.9623 0.994996 28.3662C1.40417 28.7701 1.95912 28.9971 2.53777 28.9971H22.1741C22.7528 28.9971 23.3077 28.7701 23.7169 28.3662C24.1261 27.9623 24.3559 27.4145 24.3559 26.8432V9.61247C24.3576 9.32595 24.2449 9.05032 24.0423 8.84516ZM15.6287 9.61247V3.68939L21.6287 9.61247H15.6287Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FileFilled
