export const NODE_ENV = process.env.NODE_ENV

export const NEXT_PUBLIC_AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
export const AUTH0_CLIENT_SECRET = process.env.AUTH0_CLIENT_SECRET
export const NEXT_PUBLIC_AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN
export const AUTH0_REDIRECT_URL_EMBEDDED = process.env.AUTH0_REDIRECT_URL_EMBEDDED
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET

// For Tests
export const getOriginPolyfill = () =>
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '')
export const NEXTAUTH_URL =
  typeof window !== 'undefined'
    ? window.location.origin ?? getOriginPolyfill()
    : process.env.NEXTAUTH_URL || `https://${process.env.VERCEL_URL}`

export const NEXT_PUBLIC_AUTH0_REDIRECT_URL =
  process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL || `${NEXTAUTH_URL}/api/auth/callback/auth0`

export const DATABASE_URL = process.env.DATABASE_URL
export const NEXT_PUBLIC_PORTAL_URL =
  process.env.NEXT_PUBLIC_PORTAL_URL ?? 'https://portal.inv.tech'

export const HEIMDALL_URL = process.env.HEIMDALL_URL

export const EXTERNAL_API_TOKENS = process.env.EXTERNAL_API_TOKENS ?? ''
export const NEXT_PUBLIC_AUTH0_DB_CONNECTION = process.env.NEXT_PUBLIC_AUTH0_DB_CONNECTION
