import { classNames } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { ArrowIndicatorIcon } from '@invisible/ui/icons'
import { TUuid } from '@invisible/zod'
import { FC } from 'react'

interface IProps {
  commentsExpanded?: boolean
  toggleComments: () => void
  questionId: TUuid
  handleQuestionTouch: (id: string) => void
  isEditVisible: boolean
  hasUnresolved: boolean
  isDPIAView: boolean
  hasChildren: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const QAControls: FC<IProps> = ({
  commentsExpanded,
  toggleComments,
  questionId,
  handleQuestionTouch,
  isEditVisible,
  hasUnresolved,
  hasChildren,
}) => (
  <div className='col mt-3 mb-2 flex flex-col'>
    {isEditVisible ? (
      <div className='flex justify-center'>
        <Button size='sm' variant='secondary' onClick={() => handleQuestionTouch(questionId)}>
          Change
        </Button>
      </div>
    ) : hasUnresolved ? (
      <div className='flex justify-center text-xs text-red-700'> HAS UNAPPROVED CHANGES </div>
    ) : null}

    {!isEditVisible && !hasChildren ? (
      <div className='mt-5 flex justify-center'>
        <ArrowIndicatorIcon
          onClick={toggleComments}
          width={20}
          height={20}
          className={classNames(
            'cursor-pointer font-thin text-gray-400 hover:text-purple-600',
            commentsExpanded ? 'rotate-180' : ''
          )}
        />
      </div>
    ) : null}
  </div>
)

export default QAControls
