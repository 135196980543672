import { SVGProps } from 'react'

function ControlFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.2092 5.01777V1.84277C13.2092 1.57756 13.1039 1.3232 12.9163 1.13567C12.7288 0.94813 12.4744 0.842773 12.2092 0.842773C11.944 0.842773 11.6897 0.94813 11.5021 1.13567C11.3146 1.3232 11.2092 1.57756 11.2092 1.84277V5.01777C10.6267 5.22597 10.1227 5.60918 9.76643 6.1149C9.41012 6.62063 9.21887 7.22414 9.21887 7.84277C9.21887 8.46141 9.41012 9.06492 9.76643 9.57064C10.1227 10.0764 10.6267 10.4596 11.2092 10.6678V23.8428C11.2092 24.108 11.3146 24.3623 11.5021 24.5499C11.6897 24.7374 11.944 24.8428 12.2092 24.8428C12.4744 24.8428 12.7288 24.7374 12.9163 24.5499C13.1039 24.3623 13.2092 24.108 13.2092 23.8428V10.6678C13.7918 10.4596 14.2957 10.0764 14.652 9.57064C15.0083 9.06492 15.1996 8.46141 15.1996 7.84277C15.1996 7.22414 15.0083 6.62063 14.652 6.1149C14.2957 5.60918 13.7918 5.22597 13.2092 5.01777Z'
        fill='currentColor'
      />
      <path
        d='M24.2092 17.8428C24.2085 17.223 24.0159 16.6186 23.6577 16.1128C23.2996 15.6069 22.7936 15.2244 22.2092 15.0178V1.84277C22.2092 1.57756 22.1039 1.3232 21.9163 1.13567C21.7288 0.94813 21.4744 0.842773 21.2092 0.842773C20.944 0.842773 20.6897 0.94813 20.5021 1.13567C20.3146 1.3232 20.2092 1.57756 20.2092 1.84277V15.0178C19.6267 15.226 19.1227 15.6092 18.7664 16.1149C18.4101 16.6206 18.2189 17.2241 18.2189 17.8428C18.2189 18.4614 18.4101 19.0649 18.7664 19.5706C19.1227 20.0764 19.6267 20.4596 20.2092 20.6678V23.8428C20.2092 24.108 20.3146 24.3623 20.5021 24.5499C20.6897 24.7374 20.944 24.8428 21.2092 24.8428C21.4744 24.8428 21.7288 24.7374 21.9163 24.5499C22.1039 24.3623 22.2092 24.108 22.2092 23.8428V20.6678C22.7936 20.4612 23.2996 20.0787 23.6577 19.5728C24.0159 19.0669 24.2085 18.4626 24.2092 17.8428Z'
        fill='currentColor'
      />
      <path
        d='M4.20923 11.0178V1.84277C4.20923 1.57756 4.10387 1.3232 3.91634 1.13567C3.7288 0.94813 3.47445 0.842773 3.20923 0.842773C2.94401 0.842773 2.68966 0.94813 2.50212 1.13567C2.31459 1.3232 2.20923 1.57756 2.20923 1.84277V11.0178C1.62668 11.226 1.12274 11.6092 0.766432 12.1149C0.410123 12.6206 0.218872 13.2241 0.218872 13.8428C0.218872 14.4614 0.410123 15.0649 0.766432 15.5706C1.12274 16.0764 1.62668 16.4596 2.20923 16.6678V23.8428C2.20923 24.108 2.31459 24.3623 2.50212 24.5499C2.68966 24.7374 2.94401 24.8428 3.20923 24.8428C3.47445 24.8428 3.7288 24.7374 3.91634 24.5499C4.10387 24.3623 4.20923 24.108 4.20923 23.8428V16.6678C4.79178 16.4596 5.29572 16.0764 5.65203 15.5706C6.00834 15.0649 6.19959 14.4614 6.19959 13.8428C6.19959 13.2241 6.00834 12.6206 5.65203 12.1149C5.29572 11.6092 4.79178 11.226 4.20923 11.0178Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ControlFilled
