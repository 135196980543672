import type { TQuestionnaireAnswerEntries, TQuestionTree } from '@invisible/common/types'
import { QuestionMeta } from '@invisible/ultron/zod'

export const getQuestionSkipCondition = (question: TQuestionTree) => {
  const parseResult = QuestionMeta.schema.safeParse(question.meta)
  if (!parseResult.success || !parseResult.data.skipCondition) return null
  return parseResult.data.skipCondition
}

export const isSkipped = (question: TQuestionTree, entries: TQuestionnaireAnswerEntries) => {
  const skipCondition = getQuestionSkipCondition(question)
  if (!skipCondition) return false
  return entries[skipCondition.dependsOn]?.choice === skipCondition.value
}

export const isQuestionSkipped = (
  question: TQuestionTree,
  questions: TQuestionTree[],
  entries: TQuestionnaireAnswerEntries
) => {
  const skipCondition = getQuestionSkipCondition(question)
  if (!skipCondition) return false
  if (isSkipped(question, entries)) return true
  const controller = questions.find((q) => q.id === skipCondition.dependsOn) as TQuestionTree
  if (isSkipped(controller, entries)) return true
  return false
}
