import { SVGProps } from 'react'
function EyeOpened(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={12}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M1.323 6.497C1.163 6.299 1.035 6.13.939 6a16.447 16.447 0 011.992-2.227c1.374-1.272 3.189-2.46 5.131-2.46 1.943 0 3.758 1.188 5.132 2.46A16.46 16.46 0 0115.186 6a16.461 16.461 0 01-1.992 2.227c-1.374 1.272-3.189 2.46-5.131 2.46-1.943 0-3.758-1.188-5.132-2.46a16.448 16.448 0 01-1.608-1.73z'
        stroke='currentColor'
      />
      <circle cx={8.063} cy={6} r={3.266} fill='currentColor' />
    </svg>
  )
}

export default EyeOpened
