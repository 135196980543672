import { SVGProps } from 'react'
function Suitcase(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.875103 4.25C0.875103 3.55964 1.43475 3 2.1251 3H15.8751C16.5655 3 17.1251 3.55964 17.1251 4.25V14.25C17.1251 14.9404 16.5655 15.5 15.8751 15.5H2.1251C1.43475 15.5 0.875103 14.9404 0.875103 14.25V4.25ZM15.8751 4.25H2.1251V14.25H15.8751V4.25Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.79928 1.04917C6.15091 0.697544 6.62782 0.5 7.1251 0.5H10.8751C11.3724 0.5 11.8493 0.697544 12.2009 1.04917C12.5526 1.40081 12.7501 1.87772 12.7501 2.375V3.625C12.7501 3.97018 12.4703 4.25 12.1251 4.25C11.7799 4.25 11.5001 3.97018 11.5001 3.625V2.375C11.5001 2.20924 11.4343 2.05027 11.317 1.93306C11.1998 1.81585 11.0409 1.75 10.8751 1.75H7.1251C6.95934 1.75 6.80037 1.81585 6.68316 1.93306C6.56595 2.05027 6.5001 2.20924 6.5001 2.375V3.625C6.5001 3.97018 6.22028 4.25 5.8751 4.25C5.52993 4.25 5.2501 3.97018 5.2501 3.625V2.375C5.2501 1.87772 5.44765 1.40081 5.79928 1.04917Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0411 7.55427C17.2139 7.85308 17.1118 8.2354 16.813 8.40821C14.4385 9.78144 11.7435 10.503 9.00051 10.5C6.2565 10.5069 3.55988 9.78489 1.18644 8.40776C0.887878 8.23453 0.786279 7.85207 0.959511 7.55351C1.13274 7.25495 1.51521 7.15335 1.81377 7.32658C3.99603 8.59278 6.47548 9.25655 8.99848 9.24999L9.00085 9.24999C11.524 9.25299 14.0031 8.58931 16.1872 7.32614C16.486 7.15333 16.8683 7.25547 17.0411 7.55427Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.4376 7.375C7.4376 7.02982 7.71743 6.75 8.0626 6.75H9.9376C10.2828 6.75 10.5626 7.02982 10.5626 7.375C10.5626 7.72018 10.2828 8 9.9376 8H8.0626C7.71743 8 7.4376 7.72018 7.4376 7.375Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Suitcase
