import { formatAsUTC } from '@invisible/common/date'
import { calculatePaymentCycle } from '@invisible/common/date'
import { moment } from '@invisible/legacy/moment'
import { useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@invisible/ui/dropdown'
import { formatMillicents } from '@invisible/ui/helpers'
import { CaretRightIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Skeleton } from '@invisible/ui/skeleton'
import { createColumnHelper, Table } from '@invisible/ui/table'
import type { PrismaAll } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { endOfMonth, startOfMonth } from 'date-fns/fp'
import { zonedTimeToUtc } from 'date-fns-tz'
import { capitalize, truncate, uniqBy } from 'lodash/fp'
import { FC, useEffect, useMemo, useState } from 'react'

import { MenuItem } from './baseViewComponents/MenuItem'

type TBill = NonNullable<inferQueryOutput<'bill.findByProcess'>>[number]
type TReconciliationData = inferQueryOutput<'process.getProcessReconciliationData'>[number]
type TEventsGroupedByStep = TReconciliationData['eventsGroupedByStep'][number]
type TEventsGroupedByStepRun = TEventsGroupedByStep['eventsGroupedByStepRun'][number]
type TEventStepRun = PrismaAll.StepRun & { step: PrismaAll.Step }

const ITEMS_PER_PAGE = 10

const columnHelper = createColumnHelper<TReconciliationData>()

const columns = [
  columnHelper.accessor('baseName', {
    header: 'Base',
    cell: ({ row }) => (
      <Table.Cell itemsCenter>
        <div
          className='text-theme-main group flex cursor-pointer items-center gap-2'
          onClick={() => row.toggleExpanded()}>
          <CaretRightIcon className={`${row.getIsExpanded() ? 'rotate-90' : ''}`} />
          <span className='group-hover:underline'>{row.original.baseName}</span>
        </div>
      </Table.Cell>
    ),
  }),
  columnHelper.accessor('baseRunQuantity', {
    header: 'BaseRuns',
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),
  columnHelper.accessor('stepRunQuantity', {
    header: 'StepRuns',
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),
  columnHelper.accessor('totalInvoiced', {
    header: 'Total Invoiced',
    cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
  }),
  columnHelper.accessor('totalPaid', {
    header: 'Total Paid',
    cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
  }),
]

// eslint-disable-next-line @typescript-eslint/ban-types
const ReconciliationPage: FC<{ companyId: string; processId: string }> = ({
  companyId,
  processId,
}) => {
  const [view, setView] = useState<'bi-weekly' | 'monthly'>('bi-weekly')

  const [cycleStartDate, setCycleStartDate] = useState(
    new Date(moment(calculatePaymentCycle({ offsetCycles: 1 }).start).format('YYYY-MM-DD'))
  )

  const [cycleEndDate, setCycleEndDate] = useState(
    new Date(moment(calculatePaymentCycle({ offsetCycles: 1 }).end).format('YYYY-MM-DD'))
  )

  const [monthlyStartDate, setMonthlyStartDate] = useState(
    zonedTimeToUtc(startOfMonth(new Date()), 'UTC')
  )

  const [monthlyEndDate, setMonthlyEndDate] = useState(
    zonedTimeToUtc(endOfMonth(new Date()), 'UTC')
  )

  const [selectedBillOptionKey, setSelectedBillOptionKey] = useState<string>()

  const [selectedInvoiceOptionKey, setSelectedInvoiceOptionKey] = useState<string>()

  const { data: bills, isLoading: isLoadingBills } = useQuery(
    ['bill.findByProcess', { processId }],
    {
      enabled: view === 'bi-weekly',
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery(
    ['invoice.findForCompany', { companyId }],
    {
      enabled: view === 'monthly',
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { data: reconciliationData, isLoading } = useQuery([
    'process.getProcessReconciliationData',
    {
      processId,
      startDate: view === 'bi-weekly' ? cycleStartDate : monthlyStartDate,
      endDate: view === 'bi-weekly' ? cycleEndDate : monthlyEndDate,
    },
  ])

  const uniqueBills = useMemo<TBill[]>(
    () =>
      uniqBy((b: TBill) => b?.startDate?.getTime())(
        bills?.sort((a, b) => b.startDate.getTime() - a.startDate.getTime()) as TBill[]
      ) ?? {},
    [bills]
  )

  const uniqueInvoices = useMemo(
    () =>
      uniqBy((inv: PrismaAll.Invoice) => inv.startDate.getTime())(
        invoices?.sort(
          (a, b) => b.startDate.getTime() - a.startDate.getTime()
        ) as PrismaAll.Invoice[]
      ),
    [invoices]
  )

  useEffect(() => {
    if (uniqueBills.length > 0) {
      const recentBill = uniqueBills[0]
      setCycleStartDate(recentBill.startDate)
      setCycleEndDate(recentBill.endDate)
      setSelectedBillOptionKey(recentBill.id)
    }

    if (uniqueInvoices.length > 0) {
      const recentInvoice = uniqueInvoices[0]
      setMonthlyStartDate(recentInvoice.startDate)
      setMonthlyEndDate(recentInvoice.endDate)
      setSelectedInvoiceOptionKey(recentInvoice.id)
    }
  }, [uniqueBills, uniqueInvoices])

  const changeView = () => {
    if (view === 'bi-weekly') {
      setView('monthly')
      if (uniqueInvoices.length > 0) {
        const recentInvoice = uniqueInvoices[0]
        setMonthlyStartDate(recentInvoice.startDate)
        setMonthlyEndDate(recentInvoice.endDate)
        setSelectedInvoiceOptionKey(recentInvoice.id)
      }

      return
    }

    setView('bi-weekly')
    if (uniqueBills.length > 0) {
      const recentBill = uniqueBills[0]
      setCycleStartDate(recentBill.startDate)
      setCycleEndDate(recentBill.endDate)
      setSelectedBillOptionKey(recentBill.id)
    }
    return
  }

  return (
    <div className='space-y-5 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <div className='text-lg font-bold'>
            {isLoadingBills || isLoadingInvoices ? (
              <Skeleton.Rectangle height={10} width={100} />
            ) : (
              <>
                {formatAsUTC(view === 'bi-weekly' ? cycleStartDate : monthlyStartDate, 'PPP')} -{' '}
                {formatAsUTC(view === 'bi-weekly' ? cycleEndDate : monthlyEndDate, 'PPP')}
              </>
            )}
          </div>

          <span className='text-theme-main'>{capitalize(view)}</span>
        </div>

        <div className='flex items-center gap-1'>
          {view === 'bi-weekly' ? (
            <Dropdown
              width='350px'
              name='Bills'
              options={uniqueBills.map((bill) => ({
                key: bill.id,
                value: truncate(
                  { length: 45 },
                  `${formatAsUTC(bill?.startDate, 'PPP')} - ${formatAsUTC(bill?.endDate, 'PPP')}`
                ),
              }))}
              onChange={({ key }) => {
                const bill = uniqueBills.filter((bill) => bill.id === key)[0]
                setCycleStartDate(bill?.startDate)
                setCycleEndDate(bill?.endDate)
                setSelectedBillOptionKey(key)
              }}
              selectedKey={selectedBillOptionKey}
            />
          ) : view === 'monthly' ? (
            <Dropdown
              width='350px'
              name='Invoices'
              options={uniqueInvoices.map((inv) => ({
                key: inv.id,
                value: truncate(
                  { length: 45 },
                  `${formatAsUTC(inv.startDate, 'PPP')} - ${formatAsUTC(inv.endDate, 'PPP')}`
                ),
              }))}
              onChange={({ key }) => {
                const invoice = uniqueInvoices.filter((inv) => inv.id === key)[0]
                setMonthlyStartDate(invoice?.startDate)
                setMonthlyEndDate(invoice?.endDate)
                setSelectedInvoiceOptionKey(key)
              }}
              selectedKey={selectedInvoiceOptionKey}
            />
          ) : null}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div>
                <Button variant='subtle' size='md' icon='HamburgerIcon' shape='square' />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent alignment='end'>
              <DropdownMenuItem key='Change View' internalKey='Change View' onSelect={changeView}>
                <MenuItem
                  label={view === 'bi-weekly' ? 'Monthly' : 'Bi-weekly'}
                  icon='EyeOpenedIcon'
                  hoveredIcon='EyeClosedIcon'
                />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div>
        <Table.PaginationProvider>
          <div className='w-full'>
            <Table
              showRowIndex
              defaultPageSize={ITEMS_PER_PAGE}
              data={reconciliationData ?? []}
              isDataLoading={isLoading || isLoadingBills}
              columns={columns}
              renderRowSubComponent={({ row }) => (
                <GroupedByStepTable eventsGroupedByStep={row.original.eventsGroupedByStep} />
              )}
            />
          </div>

          <div className='my-4 flex items-center justify-end'>
            <Table.Pagination />
          </div>
        </Table.PaginationProvider>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const GroupedByStepTable: FC<{
  eventsGroupedByStep: TEventsGroupedByStep[]
}> = ({ eventsGroupedByStep }) => {
  const groupedByStepColumnHelper = createColumnHelper<TEventsGroupedByStep>()

  const baseTableColumns = [
    groupedByStepColumnHelper.accessor('stepName', {
      header: 'Step',
      cell: ({ row }) => (
        <Table.Cell itemsCenter>
          <div
            className='text-theme-main group flex cursor-pointer items-center gap-2'
            onClick={() => row.toggleExpanded()}>
            <CaretRightIcon className={`${row.getIsExpanded() ? 'rotate-90' : ''}`} />
            <span className='group-hover:underline'>{row.original.stepName}</span>
          </div>
        </Table.Cell>
      ),
    }),
    groupedByStepColumnHelper.accessor('baseRunQuantity', {
      header: 'BaseRuns',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepColumnHelper.accessor('stepRunQuantity', {
      header: 'StepRuns',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepColumnHelper.accessor('invoicedRuns', {
      header: 'Invoiced Runs',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepColumnHelper.accessor('paidRuns', {
      header: 'Paid Runs',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepColumnHelper.accessor('totalInvoiced', {
      header: 'Total Invoiced',
      cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
    }),
    groupedByStepColumnHelper.accessor('totalPaid', {
      header: 'Total Paid',
      cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
    }),
  ]

  return (
    <div className='p-4'>
      <Table.PaginationProvider>
        <div className='w-full rounded-md border-0 border-l-4 border-solid border-l-fuchsia-500 shadow-lg'>
          <Table
            defaultPageSize={ITEMS_PER_PAGE}
            data={eventsGroupedByStep ?? []}
            columns={baseTableColumns}
            stickyHeader
            renderRowSubComponent={({ row }) => (
              <GroupedByStepRunTable eventsGroupedByStepRun={row.original.eventsGroupedByStepRun} />
            )}
          />
        </div>

        <div className='my-4 flex items-center justify-end'>
          <Table.Pagination />
        </div>
      </Table.PaginationProvider>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const GroupedByStepRunTable: FC<{
  eventsGroupedByStepRun: TEventsGroupedByStepRun[]
}> = ({ eventsGroupedByStepRun }) => {
  const [showBaseRunModal, setShowBaseRunModal] = useState(false)
  const [baseRunId, setBaseRunId] = useState<string>()
  const [showStepRunModal, setShowStepRunModal] = useState(false)
  const [stepRuns, setStepRuns] = useState<TEventStepRun[]>()

  const groupedByStepRunColumnHelper = createColumnHelper<TEventsGroupedByStepRun>()
  const groupedByStepRunTableColumns = [
    groupedByStepRunColumnHelper.display({
      id: 'details',
      header: 'Details',
      cell: ({ row }) => (
        <Table.Cell itemsCenter>
          <div className='flex items-center gap-1'>
            <Button
              variant='subtle'
              size='md'
              shape='square'
              icon='BaseOutlineIcon'
              color='theme'
              title='Baserun Details'
              onClick={() => {
                if (!row.original.baseRunId) return
                setBaseRunId(row.original.baseRunId)
                setShowBaseRunModal(true)
              }}
            />
            <Button
              variant='subtle'
              size='md'
              shape='square'
              icon='StepFilledIcon'
              color='theme'
              title='Step Details'
              onClick={() => {
                if (!row.original.stepRuns || !row.original.stepRuns.length) return
                setStepRuns(row.original.stepRuns)
                setShowStepRunModal(true)
              }}
            />
          </div>
        </Table.Cell>
      ),
    }),

    groupedByStepRunColumnHelper.accessor('stepRunId', {
      header: 'Step Run ID',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    groupedByStepRunColumnHelper.accessor('commodityName', {
      header: 'Commodity Name',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepRunColumnHelper.accessor('baseRunQuantity', {
      header: 'BaseRuns',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepRunColumnHelper.accessor('stepRunQuantity', {
      header: 'StepRuns',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
    groupedByStepRunColumnHelper.accessor('totalCharged', {
      header: 'Total Charged',
      cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
    }),
    groupedByStepRunColumnHelper.accessor('totalPaid', {
      header: 'Total Paid',
      cell: (props) => <Table.Cell itemsCenter>{formatMillicents(props.getValue())}</Table.Cell>,
    }),
  ]

  return (
    <div className='p-4'>
      <Table.PaginationProvider>
        <div className='w-full rounded-md border-0 border-l-4 border-solid border-l-fuchsia-500 shadow-lg'>
          <Table
            defaultPageSize={ITEMS_PER_PAGE}
            columns={groupedByStepRunTableColumns}
            data={eventsGroupedByStepRun ?? []}
          />
        </div>

        <div className='my-4 flex items-center justify-end'>
          <Table.Pagination />
        </div>
      </Table.PaginationProvider>
      {baseRunId ? (
        <BaseRunDetails
          showModal={showBaseRunModal}
          closeModal={() => {
            setShowBaseRunModal(false)
            setBaseRunId(undefined)
          }}
          baseRunId={baseRunId}
        />
      ) : null}
      {stepRuns && stepRuns.length ? (
        <StepRunDetails
          showModal={showStepRunModal}
          closeModal={() => {
            setShowStepRunModal(false)
            setStepRuns(undefined)
          }}
          stepRuns={stepRuns}
        />
      ) : null}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BaseRunDetails: FC<{ showModal: boolean; closeModal: () => void; baseRunId: string }> = ({
  showModal,
  closeModal,
  baseRunId,
}) => {
  const { data: baseRunDetails, isLoading } = useQuery([
    'baseRun.getBaseRunDetails',
    { id: baseRunId },
  ])
  const mappedBaseRunVariables = useMemo(
    () =>
      baseRunDetails?.baseRunVariables.reduce((acc, curr) => {
        const key = curr.baseVariable.name
        acc[key] = curr.value
        return acc
      }, {} as Record<string, any>),
    [baseRunDetails?.baseRunVariables]
  )
  const baseRunColumnHelper = createColumnHelper<Record<string, any>>()
  const baseRunTableColumns = useMemo(
    () =>
      Object.keys(mappedBaseRunVariables ?? {})
        .filter((key) => typeof mappedBaseRunVariables?.[key] !== 'object')
        .sort()
        .map((key) =>
          baseRunColumnHelper.accessor(key, {
            cell: ({ getValue }) => <Table.Cell itemsCenter>{getValue()}</Table.Cell>,
            enableColumnFilter: false,
            enableSorting: false,
          })
        ),
    [mappedBaseRunVariables]
  )

  return (
    <Modal
      title='Baserun Details'
      icon='BaseOutlineIcon'
      onClose={closeModal}
      visible={showModal}
      minWidth='100px'
      className='max-w-[1000px]'
      primaryButton={
        <Button size='md' variant='primary' onClick={closeModal}>
          Close
        </Button>
      }>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className='w-full'>
          <Table.PaginationProvider>
            <div className='flex w-full shadow-md'>
              <Table
                data={mappedBaseRunVariables ? [mappedBaseRunVariables] : []}
                columns={baseRunTableColumns}
              />
            </div>
          </Table.PaginationProvider>
        </div>
      )}
    </Modal>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StepRunDetails: FC<{
  showModal: boolean
  closeModal: () => void
  stepRuns: Array<TEventStepRun>
}> = ({ showModal, closeModal, stepRuns }) => {
  const stepRunColumnHelper = createColumnHelper<TEventStepRun>()
  const stepRunTableColumns = [
    stepRunColumnHelper.accessor('id', {
      header: 'ID',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('baseRunId', {
      header: 'Base Run ID',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('status', {
      header: 'Status',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('deliveredAt', {
      header: 'Delivered At',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('startedAt', {
      header: 'Started At',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('stoppedAt', {
      header: 'Stopped At',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),

    stepRunColumnHelper.accessor('assigneeId', {
      header: 'Assignee ID',
      cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    }),
  ]

  return (
    <Modal
      title='Step Run Details'
      icon='StepFilledIcon'
      onClose={closeModal}
      visible={showModal}
      minWidth='100px'
      className='max-w-[1000px]'
      primaryButton={
        <Button size='md' variant='primary' onClick={closeModal}>
          Close
        </Button>
      }>
      <div className='w-full'>
        <Table.PaginationProvider>
          <div className='flex w-full shadow-md'>
            <Table data={stepRuns ?? []} columns={stepRunTableColumns} />
          </div>
        </Table.PaginationProvider>
      </div>
    </Modal>
  )
}

export { ReconciliationPage }
