import { SVGProps } from 'react'

function Orchestration(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.49902 10.4999C7.68091 9.47729 6.77913 9.04029 5.99902 9.15472M5.99902 9.15472C4.87205 9.32003 3.49902 11.1362 3.49902 13.4999C3.49902 20.2999 8.99902 20.9999 11.999 20.9999V3.99993C9.99902 2.49994 4.79902 3.67855 5.99902 9.15472Z'
        stroke='#604CA5'
        stroke-width='2'
      />
      <path d='M11.999 12C11.999 13 11.299 15 8.49902 15' stroke='#604CA5' stroke-width='2' />
      <path d='M11.999 7H16.999V4' stroke='#604CA5' stroke-width='2' />
      <path d='M11.999 17H16.999V20' stroke='#604CA5' stroke-width='2' />
      <path d='M11.999 12H18.999' stroke='#604CA5' stroke-width='2' />
      <circle cx='16.999' cy='3' r='2' fill='#604CA5' />
      <circle cx='16.999' cy='21' r='2' fill='#604CA5' />
      <circle cx='19.999' cy='12' r='2' fill='#604CA5' />
    </svg>
  )
}

export default Orchestration
