import type { TProcessByIdStep, TProcessStepTemplate } from '@invisible/common/components/process-base'
import {
  useProcessById,
} from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { useStore } from '@invisible/common/stores/process-store'
import { useContext, useMutation } from '@invisible/trpc/client'
import { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { Handle, Position, useUpdateNodeInternals } from 'reactflow'
import shallow from 'zustand/shallow'

import { computeBuilderStepColors } from './helper'

interface IProps {
  selected: boolean
  isConnectable: boolean
  dragging: boolean
  data: {
    step: TProcessByIdStep
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TriggerStep: React.FC<IProps> = ({ selected, data, isConnectable, dragging }) => {
  const graphqlQueryClient = useQueryClient()
  const updateNodeInternals = useUpdateNodeInternals()
  const reactQueryContext = useContext()
  const { layoutOrientation } = useStore(
    useCallback(
      (state) => ({
        layoutOrientation: state.layoutOrientation,
      }),
      []
    ),
    shallow
  )

  const { data: process } = useProcessById({ id: data.step.processId })

  const step = useMemo(() => process?.steps.find((s) => s.id === data.step.id), [process])
  const colors = computeBuilderStepColors(step?.stepTemplate as TProcessStepTemplate)

  const { mutateAsync: createStepGoTo } = useMutation('stepGoTo.create', {
    onSettled: () => {
      graphqlQueryClient.invalidateQueries('ProcessById')
      reactQueryContext.invalidateQueries('process.findByIdWithStepsAndStepGoTos')
    },
  })

  const connectNodes = async (fromStepId: string | null, toStepId: string | null) => {
    if (!fromStepId || !toStepId) return
    const fromStep = process?.steps.find((s) => s.id === fromStepId)
    const toStep = process?.steps.find((s) => s.id === toStepId)

    if (
      (toStep?.stepTemplate?.subtype === 'audit' &&
        !['end_base_run', 'end_map', 'end_process', 'end_attended_map'].includes(
          fromStep?.stepTemplate?.subtype ?? ''
        )) ||
      (fromStep?.stepTemplate?.subtype === 'audit' &&
        !['end_base_run', 'end_map', 'end_process', 'end_attended_map'].includes(
          toStep?.stepTemplate?.subtype ?? ''
        ))
    )
      return

    await createStepGoTo({
      goFromStepId: fromStepId,
      goToStepId: toStepId,
      processId: data.step.processId,
    })
  }

  useEffect(() => {
    updateNodeInternals(data.step.id)
  }, [data.step.id, layoutOrientation, updateNodeInternals])

  return (
    <>
      <Handle
        type='source'
        position={layoutOrientation === 'horizontal' ? Position.Right : Position.Bottom}
        className={classNames('!h-2 !w-2', colors.bg)}
        isConnectable={process?.status !== 'active' && process?.status !== 'testing'}
        onConnect={(connection) => connectNodes(connection.source, connection.target)}
      />
      <div
        className={classNames(
          'text-primary box-border flex h-16 items-center gap-2 rounded border-2 border-solid bg-white px-2',
          selected ? 'border-black' : colors.border,
          !isConnectable ? 'cursor-not-allowed' : dragging ? 'cursor-move' : 'cursor-pointer'
        )}>
        <div
          className={classNames(
            'flex h-8 w-8 shrink-0 items-center justify-center rounded-md',
            colors.bg,
            colors.border
          )}>
          <colors.icon className='h-4 w-4 text-white' />
        </div>
        <div className='flex w-40 flex-col text-xs'>
          <span className='block truncate font-bold tracking-wider' title={step?.name ?? ''}>
            {step?.name ?? ''}
          </span>
          <span className='block truncate tracking-wide'>{data.step.stepTemplate.name}</span>
        </div>
      </div>
    </>
  )
}

export { TriggerStep }
