import { theme as baseTheme } from './base-theme'

const primaryFg = baseTheme.colors.primary
const secondaryFg = '#542cea'
const primaryBg = '#fafbfc'
const secondaryBg = 'white'
const ternaryBg = '#bfc5d2'
const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    purple: primaryFg,
    primaryFg,
    secondaryFg,
    primaryBg,
    secondaryBg,
    ternaryBg,
  },
}
export default theme
