import { useAbilityContext } from '@invisible/authorization/client'
import {
  TBaseRunQueryData,
  useStepRunsUpdateAssignee,
} from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Avatar } from '@invisible/ui/avatar'
import { BaseballCard } from '@invisible/ui/baseball-card'
import { Button } from '@invisible/ui/button'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { Modal } from '@invisible/ui/modal'
import { palette, theme } from '@invisible/ui/mui-theme-v2'
import { Text } from '@invisible/ui/text'
import { useToasts } from '@invisible/ui/toasts'
import { Tooltip } from '@invisible/ui/tooltip'
import MoreVert from '@mui/icons-material/MoreVert'
import { ListItemButton, ListItemText } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { format, isWithinInterval } from 'date-fns'
import { useSession } from 'next-auth/react'
import { isEmpty } from 'radash'
import { FC, MouseEvent, SyntheticEvent, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import { useMyRoles } from '../hooks/useMyRoles'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TAssigneeUser = TStepRun['assignee']

interface IAssigneeCellProps {
  assignee: TAssigneeUser
  stepRun: TStepRun
  baseId: string
  baseRunId: string
}

interface IUserOption {
  label: string
  handle: string
  key: string
  active: boolean
  todayAvailabilities: { startTime: Date; endTime: Date }[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const AssigneeCell: FC<IAssigneeCellProps> = ({ assignee, stepRun, baseId, baseRunId }) => {
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const [selectedOperator, setSelectedOperator] = useState<IUserOption | null>(null)

  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const [isSelfAssignModalOpen, setIsSelfAssignModalOpen] = useState(false)
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [isBaseballCardVisible, setIsBaseballCardVisible] = useState(false)
  const { data: session } = useSession()
  const { addToast } = useToasts()
  const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage, filterableColumns } =
    useStore(
      useCallback(
        (state) => ({
          itemsPerPage: state.itemsPerPage,
          filterOption: state.filterOption,
          sortOption: state.sortOption,
          searchTerm: state.searchTerm,
          currentPage: state.currentPage,
          filterableColumns: state.filterableColumns,
        }),
        []
      ),
      shallow
    )
  const { data: loggedInUser } = useMyRoles({ email: session?.user?.email ?? '' })

  const { data: allUsers, isLoading: loadingUsers } = useQuery(
    ['user.findManyToAssignToStepRun', { stepRunId: stepRun?.id }],
    {
      enabled: isAssignModalOpen,
    }
  )

  const [_, ability] = useAbilityContext()
  const canAssignStepRun = ability?.can('update', 'StepRun', null, 'assigneeId')

  const { mutateAsync: selfAssignMutation, isLoading: isAssignSelfsMutationLoading } =
    useStepRunsUpdateAssignee({
      onSuccess: (data) => {
        setIsAssignModalOpen(false)
        reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
          [
            'get-base-runs',
            {
              baseId,
              baseViewId,
              take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
              filters: filterOption ?? [],
              sort: sortOption ?? {},
              search: searchTerm ?? '',
              page: currentPage ?? 1,
            },
          ],
          (prevData) => {
            if (!prevData) return prevData

            const updatedBaseRunData = prevData.items.map((baseRun) => {
              if (baseRun.id === baseRunId) {
                return {
                  ...baseRun,
                  stepRuns: baseRun.stepRuns.map((sr) =>
                    sr.id !== stepRun.id ? sr : { ...sr, assignee: data.assignee as TAssigneeUser }
                  ),
                }
              }
              return baseRun
            })
            return {
              ...prevData,
              baseRuns: updatedBaseRunData,
            }
          }
        )

        reactQueryContext.queryClient.setQueryData(
          [
            'baseView.getColumnOptions',
            {
              baseId,
              filterableColumns,
            },
          ],
          (prevData: any) => {
            if (!data.assignee || !prevData) return prevData

            const updatedData: Record<string, string[]> = {}
            Object.keys(prevData).forEach((id) => {
              if (id !== stepRun.stepId) {
                updatedData[id] = prevData[id]
              } else {
                updatedData[id] = [...prevData[id], data.assignee?.name]
              }
            })
            return updatedData
          }
        )
      },
      onError: (error) => {
        addToast(`Assignment failed: ${error?.message}`, {
          appearance: 'error',
        })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    })
  const { mutateAsync: assignOthersMutation, isLoading: isAssignOthersMutationLoading } =
    useStepRunsUpdateAssignee({
      onSuccess: (data) => {
        setIsAssignModalOpen(false)
        reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
          [
            'get-base-runs',
            {
              baseId,
              baseViewId,
              take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
              filters: filterOption ?? [],
              sort: sortOption ?? {},
              search: searchTerm ?? '',
              page: currentPage ?? 1,
            },
          ],
          (prevData) => {
            if (!prevData) return prevData

            const updatedBaseRunData = prevData.items.map((baseRun) => {
              if (baseRun.id === baseRunId) {
                return {
                  ...baseRun,
                  stepRuns: baseRun.stepRuns.map((sr) =>
                    sr.id !== stepRun.id ? sr : { ...sr, assignee: data.assignee as TAssigneeUser }
                  ),
                }
              }
              return baseRun
            })
            return {
              ...prevData,
              baseRuns: updatedBaseRunData,
            }
          }
        )

        reactQueryContext.queryClient.setQueryData(
          [
            'baseView.getColumnOptions',
            {
              baseId,
              filterableColumns,
            },
          ],
          (prevData: any) => {
            if (!data.assignee || !prevData) return prevData

            const updatedData: Record<string, string[]> = {}
            Object.keys(prevData ?? {}).forEach((id) => {
              if (id !== stepRun.stepId) {
                updatedData[id] = prevData[id]
              } else {
                updatedData[id] = [...prevData[id], data.assignee]
              }
            })
            return updatedData
          }
        )
        setSelectedOperator(null)
      },
      onError: (error) => {
        addToast(`Assignment failed: ${error?.message}`, {
          appearance: 'error',
        })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    })

  const handleAssignButtonClick = () => {
    setAnchorEl(null)
    if (loggedInUser?.id && stepRun.id && !canAssignStepRun) {
      selfAssignMutation({
        stepRunId: stepRun.id,
        assigneeId: loggedInUser.id,
      })
    }
    if (canAssignStepRun) {
      setIsAssignModalOpen(true)
    }
  }

  const handleAssingSelfClick = async () => {
    setAnchorEl(null)
    if (loggedInUser?.id && stepRun?.id) {
      selfAssignMutation({
        stepRunId: stepRun.id,
        assigneeId: loggedInUser.id,
      })
    }
  }

  const handleAssignOperatorsClick = async () => {
    setAnchorEl(null)
    if (loggedInUser?.id && stepRun.id && canAssignStepRun && selectedOperator?.key) {
      await assignOthersMutation({
        stepRunId: stepRun.id,
        assigneeId: selectedOperator.key,
      })
    }
  }

  const handleUnassignClick = async () => {
    setAnchorEl(null)
    if (loggedInUser?.id && stepRun.id && canAssignStepRun) {
      const response = await assignOthersMutation({
        stepRunId: stepRun.id,
        assigneeId: null,
      })
      if (response) {
        setIsUnassignModalOpen(true)
      }
    }
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <ThemeProvider theme={theme}>
      <Box width='100%'>
        {assignee ? (
          <Stack alignItems='center' direction='row' justifyContent='space-between'>
            <div onClick={() => setIsBaseballCardVisible(true)} className='hover:cursor-pointer'>
              {assignee.preferredName ?? assignee.name}
            </div>
            <BaseballCard
              image={assignee.image}
              name={assignee.preferredName}
              email={assignee.email}
              isVisible={isBaseballCardVisible}
              setIsVisible={setIsBaseballCardVisible}
              userId={assignee.id}
              slack={assignee.slack}
            />
            {canAssignStepRun ? (
              <Stack justifyContent='flex-end'>
                <IconButton onClick={handleClick}>
                  <MoreVert fontSize='small' />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <ListItemButton component='button' onClick={handleUnassignClick}>
                    <ListItemText primary='Unassign ' />
                  </ListItemButton>
                  <ListItemButton component='button' onClick={() => setIsAssignModalOpen(true)}>
                    <ListItemText primary='Assign to Other' />
                  </ListItemButton>
                </Popover>
              </Stack>
            ) : null}
          </Stack>
        ) : stepRun && !['disabled', 'expired'].includes(stepRun.status) ? (
          <Button
            variant='primary'
            size='md'
            iconLeft='UserOutlineIcon'
            onClick={handleAssignButtonClick}>
            {canAssignStepRun ? 'Assign' : 'Assign to Me'}
          </Button>
        ) : null}
        {isSelfAssignModalOpen ? (
          <Modal
            icon='CheckCircleOutlineIcon'
            title='Task Assigned'
            onClose={() => setIsSelfAssignModalOpen(false)}>
            <Stack direction='column' alignItems='center' width={450} p={4} pl={0}>
              <Avatar src={assignee?.image || undefined} size={50} />
              <Text>This task has been assigned to you</Text>
            </Stack>
          </Modal>
        ) : null}
        {isUnassignModalOpen ? (
          <Modal
            icon='CheckCircleOutlineIcon'
            title='Task Unassigned'
            onClose={() => setIsUnassignModalOpen(false)}
            primaryButton={
              <Button variant='primary' size='md' onClick={() => setIsUnassignModalOpen(false)}>
                Done
              </Button>
            }
            secondaryButton={
              <Button
                variant='secondary'
                size='md'
                onClick={() => {
                  setIsUnassignModalOpen(false)
                  setIsAssignModalOpen(true)
                }}>
                Assign to Other
              </Button>
            }>
            <Stack direction='column' alignItems='center' width={450} p={4} pl={0}>
              <Avatar src={loggedInUser?.image || undefined} size={50} />
              <Text>You have unassigned this task</Text>
            </Stack>
          </Modal>
        ) : null}
        {isAssignModalOpen ? (
          <Modal
            icon='UserOutlineIcon'
            title='Assign This Task'
            onClose={() => setIsAssignModalOpen(false)}
            primaryButton={
              <Button
                variant='primary'
                size='md'
                onClick={async () => handleAssignOperatorsClick()}
                disabled={!selectedOperator?.key}
                loading={isAssignOthersMutationLoading}>
                Assign
              </Button>
            }
            secondaryButton={
              <Button
                variant='secondary'
                size='md'
                disabled={!!selectedOperator?.key}
                onClick={async () => handleAssingSelfClick()}
                loading={isAssignSelfsMutationLoading}>
                Assign to Me
              </Button>
            }>
            <Box width={600} p={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ fontSize: '14px' }}>Select a user from the team in the dropdown</Box>
              <Autocomplete
                disablePortal
                size='small'
                disableListWrap
                value={selectedOperator}
                onChange={(event: SyntheticEvent, newValue: IUserOption | null) => {
                  setSelectedOperator(newValue)
                }}
                options={
                  (allUsers
                    ?.filter((user) => user.id !== loggedInUser?.id)
                    .sort((a, b) =>
                      a.preferredName ?? a.name ?? '' > (b?.preferredName ?? b?.name ?? '') ? 1 : -1
                    )
                    .sort((a, b) =>
                      a.todayAvailabilities?.some((tda: { startTime: Date; endTime: Date }) =>
                        isWithinInterval(new Date(), {
                          start: new Date(tda.startTime),
                          end: new Date(tda.endTime),
                        })
                      )
                        ? b.todayAvailabilities?.some((tda: { startTime: Date; endTime: Date }) =>
                            isWithinInterval(new Date(), {
                              start: new Date(tda.startTime),
                              end: new Date(tda.endTime),
                            })
                          )
                          ? 1
                          : -1
                        : 1
                    )
                    .map((operator) => ({
                      label: operator?.preferredName ?? operator?.name,
                      handle: operator?.email?.includes('invisible')
                        ? `${operator?.email.split('@')[0]}@`
                        : operator?.email,
                      key: operator.id,
                      active: operator.lastActivity === 'online',
                      todayAvailabilities: operator.todayAvailabilities ?? [],
                    })) as IUserOption[]) ?? []
                }
                filterOptions={createFilterOptions({
                  stringify: (option) => `${option.label} ${option.handle}`,
                })}
                groupBy={(option) =>
                  option.todayAvailabilities?.some((tda) =>
                    isWithinInterval(new Date(), {
                      start: new Date(tda.startTime),
                      end: new Date(tda.endTime),
                    })
                  )
                    ? `Available Users (${
                        allUsers?.filter((user) =>
                          user.todayAvailabilities?.some((tda) =>
                            isWithinInterval(new Date(), {
                              start: new Date(tda.startTime),
                              end: new Date(tda.endTime),
                            })
                          )
                        ).length
                      })`
                    : `Not Available Users (${
                        allUsers?.filter(
                          (user) =>
                            !user.todayAvailabilities?.some((tda) =>
                              isWithinInterval(new Date(), {
                                start: new Date(tda.startTime),
                                end: new Date(tda.endTime),
                              })
                            )
                        ).length
                      })`
                }
                loading={loadingUsers}
                renderInput={(params) => <TextField {...params} label='Select a user...' />}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      justifyItems: 'space-between',
                      alignItems: 'center',
                      gap: 1,
                      width: '100%',
                      height: '32px',
                    }}
                    {...props}>
                    <Tooltip content={option.active ? 'Active' : 'Away'} side='right'>
                      <Box
                        component='div'
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          color: palette.success.main,
                          backgroundColor: option.active ? palette.success.main : 'white',
                          boxShadow: `0 0 0 2px white`,
                          border: `2px solid ${
                            option.active ? palette.success.main : palette.grey.paragraph
                          }`,
                        }}
                      />
                    </Tooltip>
                    <Box component='div'>{option.label}</Box>
                    <Box sx={{ color: palette.grey.paragraph }}>{option.handle}</Box>
                    <Box sx={{ textAlign: 'end', flexGrow: 1, textDecoration: 'underline' }}>
                      <Tooltip
                        content={
                          isEmpty(option.todayAvailabilities)
                            ? 'N/A'
                            : option.todayAvailabilities?.map(
                                (slot: { startTime: Date; endTime: Date }) => (
                                  <div>
                                    {format(new Date(slot.startTime), 'h:mm a')} -{' '}
                                    {format(new Date(slot.endTime), 'h:mm a')}
                                  </div>
                                )
                              )
                        }
                        side='right'>
                        Availability
                      </Tooltip>
                    </Box>
                  </Box>
                )}
              />
            </Box>
          </Modal>
        ) : null}
      </Box>
    </ThemeProvider>
  )
}
