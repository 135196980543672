import type { PrismaAll } from '@invisible/ultron/prisma'
import type { TDatePresetEnum } from '@invisible/ultron/zod'
import { BarDatum } from '@nivo/bar'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
export type LabelType = 'Series' | 'String'

export interface ITooltipLabelConfig {
  labelType: LabelType
  labelValue: string
  label: string
}

export interface ITooltipValueConfig {
  label: string
  postfixUnit?: string
  prefixUnit?: string
  valueType: string
}

export interface IAttribute {
  name: string
  value: string
  label: string
  labelType: LabelType
  labelValue: string
  valueType: string
  order?: number
  nextReport?: string
  primaryKey?: number | string
  foreignKey?: number | string
  intervalColumn?: string
  postfixUnit?: string
  prefixUnit?: string
  sourceLink?: string
  isFinalLevel?: boolean
  isNullable: boolean
  columns?: string[]
  isPrimary: boolean
  operator?: string
  defaultValue?: TFilterValue
  isVisible?: boolean
  isEditable?: boolean
  paramName?: string
  labelRotationAngle?: number
  deviationDirection?: string
  deviationLabel?: string
  deviationPercent?: string
  selectOptions?: string[]
  defaultSelectValue?: string
  deviationColor?: string
  target?: string
  // These are not part of AttributePropertyType enum in prisma schema
  groupId: string
  attributeId: string
  attributeName: string
}

export type IReportVisualization = PrismaAll.ReportVisualization
export type IReport = PrismaAll.Report
export type IReportAttributeValue = PrismaAll.AttributeValue & {
  visualizationAttribute: {
    name: PrismaAll.VisualizationAttribute['name']
  }
}
export type IReportAttributeGroup = PrismaAll.ReportAttributeGroup
export type IReportAttributeGroupWithAttributeValues = IReportAttributeGroup & {
  attributeValues: IReportAttributeValue[]
}
export type IReportVisualizationAttribute = PrismaAll.VisualizationAttribute
export type IReportAttributeProperty = PrismaAll.AttributeProperty
export interface IVisualization extends IReportVisualization {
  attributesMapping: IAttribute[]
  data: Record<string, unknown>[]
}

export interface IResponseReport extends IReport {
  visualizations: IVisualization[]
}

export interface IReportState {
  reportName?: string | undefined
  reportFilter: IReportFilter
  dataFilter: IDataFilter<TFilterValue>[]
}

export interface IBarchartData {
  tooltipConfig: {
    labelConfig: ITooltipLabelConfig
    valueConfig: ITooltipValueConfig
  }
  legends: IVisualizationLegend[]
  indexedBy: string
  KEYS: string[]
  data: BarDatum[]
  xAxisLegend?: string
  yAxisLegend?: string
  xAxisLabelRotationAngle?: number
  colors?: Record<string, string>
  baseDateViewLevel?: TDateViewLevel
  targetLine?: Line
}

export interface IPieChartDatum {
  id: string
  value: number
  label: string
  color?: string
  nextReport?: string
  drillId?: string | number
}

export interface IVisualizationLegend {
  color: string
  label: string
}

export interface IPiechartData {
  data: IPieChartDatum[]
  legends: IVisualizationLegend[]
  tooltipConfig: {
    labelConfig: ITooltipLabelConfig
    valueConfig: ITooltipValueConfig
  }
  nextReport?: string
  dataFilters?: IDataFilter<TFilterValue>[]
}

export interface ITableData {
  columns: ITableColumn[]
  data: Record<string, unknown>[]
}

export interface IChoroplethData {
  // legends: IVisualizationLegend[]
  type: string
  features: {
    type: string
    id: string
    properties: {
      name: string
      density: number
    }
    geometry: {
      type: string
      coordinates: number[][][]
    }
  }[]
}

export const enum DeviationDirection {
  Up = 'up',
  Down = 'down',
  Flat = 'flat',
}

export type TDeviationDirection =
  | DeviationDirection.Up
  | DeviationDirection.Down
  | DeviationDirection.Flat

export interface ITile {
  value: string
  label: string
  order: number
  deviationInfo: {
    direction?: TDeviationDirection
    color?: string
    percentage?: string
    label: string
  }
}

export interface ITableColumn {
  postfixUnit: string | undefined
  prefixUnit: string | undefined
  Header: string
  accessor: string
  order?: number
  valueType: string
  url?: string
  Cell?: (props: any) => string | null
}

export type TFilterValue = null | boolean | string | number | IInterval | string[]

export interface IDynamicFilter<T extends TFilterValue> {
  columnName: string
  value: T
  operator: string
  valueType: string
}
export type TFilterInputType = 'text' | 'number' | 'boolean' | 'date' | 'common'

export interface IFilterColumnOption {
  key: string
  label: string
  valueType: string
  filterName: string
  isVisible?: boolean
  isEditable?: boolean
  defaultValue?: TFilterValue
  operator?: string
  isConfigurable: boolean
}

export interface IFilter<T extends TFilterValue> {
  key: string
  value: T
  operator: string
  defaultOpen: boolean
  valueLabel: string
  label: string
  valueType: string
  filterName: string
  isEditable?: boolean
  isVisible?: boolean
  isConfigurable: boolean
  selectOptions?: string[]
  defaultSelectValue?: string
}

export interface IDataFilter<T extends TFilterValue> {
  name: string
  valueType: string
  operator: string
  value: T
  key?: string
  label?: string
}

export interface IReportVisualizationsMapping {
  reportName: string
  visualizations: string[]
}
export interface IReportFilter {
  reportName: string
  visualizations: string[]
  filters: IDataFilter<TFilterValue>[]
}

export interface IBulkUpdateData {
  tagName: string
  instanceId: number
}

export interface IInterval {
  from?: string
  to?: string
  preset?: TDatePresetEnum
}

export interface IFormattedPeriod {
  periodOption?: string
  to: string
  from: string
}

export interface IRange {
  key: string
  startDate: Date
  endDate: Date
}

export interface IAtrributeType {
  name: string
  type: string
}

export interface IParamsUltronAPI {
  dataFilter?: {
    name: string
    valueType: string
    timeZone?: string
    value: TFilterValue
  }[]
  reportFilter?: {
    companyId: number | null
    filters: {
      reportName: string
      visualizationType: string
    }[]
  }
}
export interface ISelection {
  name: string
  valueType?: string
  isNullable: boolean
  isPrimary: boolean
}

export interface IWarehouseFunctionFilter {
  key?: string
  value?: object | string
}

export interface IWarehouseResponse {
  data: Record<string, unknown>[]
  attributeTypes: {
    name: string
    type: string
  }[]
}

export interface ICSVColumn {
  accessor: string
  header: string
  prefixUnit: string | undefined
  postfixUnit: string | undefined
  valueType: string
}

export interface IResponseReportCSV {
  columns: ICSVColumn[]
  data: Record<string, unknown>[]
}

export type TVisualizationMeta = IReportVisualization & { attributesMapping: IAttribute[] }

export interface IReportMeta extends IReport {
  visualizations: IReportVisualization[]
  order?: number
}

export interface IReportWithAttributeValues extends IReport {
  attributeValues: IReportAttributeValue[]
}

export interface IFilterSourceInfo {
  reportName: string
  dataFilters: IDataFilter<TFilterValue>[]
}

export interface IPartialReportMeta
  extends Partial<Omit<IReportMeta, 'reportName' | 'visualizations'>> {
  reportName: string
  visualizations?: AtLeast<IReportVisualization, 'type'>[]
}

export type LineDatum = {
  x: string | number
  y: number
  yFormatted?: string
  prefixUnit?: string
  postfixUnit?: string
  valueType?: string
}

export interface Line {
  id: string | number
  color?: string
  data: LineDatum[]
}

export interface ILineChartData {
  data: Line[]
  legends: IVisualizationLegend[]
  xAxisLegend?: string
  yAxisLegend?: string
  xAxisLabelRotationAngle?: number
  baseDateViewLevel?: TDateViewLevel
  targetLines?: Line[]
}

export type TFormattedVisualizations = {
  pieChart?: IPiechartData
  table?: ITableData
  barChart?: IBarchartData
  choroplethChart?: IChoroplethData
  tileChart?: Record<string, string>
  linearChart?: Record<string, string>[]
  grid?: ITile[]
  lineChart?: ILineChartData
}

export interface IFormattedReport {
  reportName: string
  reportTitle: string
  id: string
  subtitle: string
  description: string | null
  visualizations: TFormattedVisualizations
  filterColumnOptions: IFilterColumnOption[]
}

export type TAttributePropertyValueMap = Partial<
  Record<PrismaAll.AttributePropertyType, string>
> & {
  attributeId: string
  groupId: string
}

export type ReportType = 'Custom' | 'Core'

export interface IReportCSVAPIParams {
  reportName: string
  dataFilter: IDataFilter<TFilterValue>[]
}

export interface IReportCSVResponse {
  columns: ITableColumn[]
  data: Record<string, unknown>[]
}

export type TReportingScope = PrismaAll.ReportingScope
export type TReportingDatabase = PrismaAll.ReportingDatabase
export type TReportingPlatform = PrismaAll.ReportingPlatform
export type TDateViewLevel = 'hourly' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly'
export type TVisualizationType = PrismaAll.ReportingVisualization

export enum ReportingPlatformEnum {
  Manticore = 'manticore',
  Portal = 'portal',
}

export enum ReportingScopeEnum {
  Process = 'process',
  Company = 'company',
}
