import { constObjectToZod, z } from '@invisible/zod'
import { values } from 'lodash/fp'

const FORMULA_ARGUMENT_TYPES = {
  CONSTANT: 'constant',
  VARIABLE: 'variable',
} as const
const _FORMULA_ARGUMENT_TYPES = values(FORMULA_ARGUMENT_TYPES)
const FormulaArgumentTypesSchema = constObjectToZod(FORMULA_ARGUMENT_TYPES)
type TFormulaArgumentType = z.infer<typeof FormulaArgumentTypesSchema>

// Just a small subset for now, but will eventually be all VARIABLE_TYPES
const FORMULA_VALUE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
} as const
const _FORMULA_VALUE_TYPES = values(FORMULA_VALUE_TYPES)
const FormulaValueTypesSchema = constObjectToZod(FORMULA_VALUE_TYPES)
type TFormulaValueType = z.infer<typeof FormulaValueTypesSchema>

// Just boolean for now, but will eventually be all VARIABLE_TYPES
const FORMULA_RESULT_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
} as const
const _FORMULA_RESULT_TYPES = values(FORMULA_RESULT_TYPES)
const FormulaResultTypesSchema = constObjectToZod(FORMULA_RESULT_TYPES)
type TFormulaResultType = z.infer<typeof FormulaResultTypesSchema>

// Just eq for now, but will eventually be all formulas supported by mongo (gt, gte, lt, lte etc),
// basic arithmetic (+, -, /, *), string functions (startsWith, contains) etc.
const FORMULA_NAMES = {
  EQ: 'eq',
  GT: 'gt',
  GTE: 'gte',
  IS_NOT_NULL: 'isNotNull',
  IS_NULL: 'isNull',
  LT: 'lt',
  LTE: 'lte',
  NEQ: 'neq',
} as const

const _FORMULA_NAMES = values(FORMULA_NAMES)
const FormulaNamesSchema = constObjectToZod(FORMULA_NAMES)
type TFormulaName = z.infer<typeof FormulaNamesSchema>

export {
  _FORMULA_ARGUMENT_TYPES,
  _FORMULA_NAMES,
  _FORMULA_RESULT_TYPES,
  _FORMULA_VALUE_TYPES,
  FORMULA_ARGUMENT_TYPES,
  FORMULA_NAMES,
  FORMULA_RESULT_TYPES,
  FORMULA_VALUE_TYPES,
  FormulaArgumentTypesSchema,
  FormulaNamesSchema,
  FormulaResultTypesSchema,
  FormulaValueTypesSchema,
}
export type { TFormulaArgumentType, TFormulaName, TFormulaResultType, TFormulaValueType }
