import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { CopyOutlineIcon, DeleteFilledIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BaseViewMeta } from '@invisible/ultron/zod'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { ChangeEvent as ReactChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { useMyRoles } from '../hooks/useMyRoles'
import { removeUndefinedFields, stringifyDateFilters } from './helpers'
import { PermissionWithQueryWrapper } from './Permissions'

type TStages = NonNullable<inferQueryOutput<'lifecycleStage.findManyByProcessId'>>

type TBase = TProcessWithBases['bases'][number]
type TBaseView = TBase['baseViews'][number]

interface IBaseViewGeneralSettingsProps {
  currentBaseView: TBaseView
  stages: TStages
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseViewGeneralSettings: FC<IBaseViewGeneralSettingsProps> = ({
  currentBaseView,
  stages,
}) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const router = useRouter()
  const { data: session } = useSession()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const {
    stageId,
    editedPermissions,
    isPrivate,
    filterOption,
    sortOption,
    hiddenColumns,
    columnOrder,
    filterableColumns,
    setStageId,
    baseViewName,
    setBaseViewName,
    setIsFreshNew,
  } = useStore(
    useCallback(
      (state) => ({
        stageId: state.stageId,
        editedPermissions: state.editedPermissions,
        isPrivate: state.isPrivate,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        hiddenColumns: state.hiddenColumns,
        columnOrder: state.columnOrder,
        filterableColumns: state.filterableColumns,
        setStageId: state.setStageId,
        baseViewName: state.baseViewName,
        setBaseViewName: state.setBaseViewName,
        setIsFreshNew: state.setIsFreshNew,
      }),
      []
    ),
    shallow
  )

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const { mutateAsync: deleteBaseView, isLoading: isDeleteBaseViewLoading } = useMutation(
    'baseView.delete',
    {
      onSettled: () => {
        reactQueryContext.invalidateQueries('process.findByIdWithBases')
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    }
  )

  const { mutateAsync: createBaseView } = useMutation('baseView.create', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('process.findByIdWithBases')
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const editBaseViewName = useCallback(
    (e: ReactChangeEvent<HTMLInputElement>) => {
      setIsFreshNew(false)
      setBaseViewName(e.target.value)
    },
    [setBaseViewName, setIsFreshNew]
  )

  const currentBaseViewConfig = useMemo(
    () => BaseViewMeta.schema.parse(currentBaseView?.config ?? {}),
    [currentBaseView?.config]
  )

  const { data: loggedInUser } = useMyRoles({ email: session?.user?.email ?? '' })

  const createBaseViewHandler = async () => {
    if (!currentBaseView.id || !router.query['id'] || !loggedInUser?.id) return
    await createBaseView({
      processId: router.query['id'] as string,
      baseId,
      privateBaseView: isPrivate,
      name: `${currentBaseView?.name ?? ''}: copy`,
      roleIds: editedPermissions,
      stageId: stageId || undefined,
      config: {
        ...currentBaseViewConfig,
        filters: stringifyDateFilters(filterOption),
        sortBy: removeUndefinedFields(sortOption),
        hiddenColumns,
        columnOrder,
        filterableColumns,
      },
    })
  }

  const deleteBaseViewHandler = async () => {
    await deleteBaseView({ id: baseViewId })
  }

  return (
    <div>
      <div className='w-1/2'>
        <div className=' pb-2 pt-3 font-semibold'>Base View Name</div>
        <Input id={currentBaseView.id} onChange={editBaseViewName} value={baseViewName ?? ''} />
      </div>
      <div className='w-1/2'>
        <div className='pb-2 pt-8 font-semibold'>Stage</div>
        <Dropdown
          options={stages.map((stage) => ({
            key: stage.id,
            value: stage.name,
          }))}
          onChange={({ key }) => {
            setIsFreshNew(false)
            setStageId(key)
          }}
          name='Stage'
          placeholder='Stage'
          width='100%'
          selectedKey={stages.find((s) => s.id === stageId)?.id ?? null}
          clearText='No Stage'
          onClear={() => {
            setIsFreshNew(false)
            setStageId(null)
          }}
        />
      </div>
      <PermissionWithQueryWrapper />
      <div className='flex'>
        <div
          onClick={createBaseViewHandler}
          className='hover:bg-weak-2 group flex h-8 cursor-pointer  items-center pl-2 pr-2 leading-8'>
          <CopyOutlineIcon width={12} height={12} />
          <div className='pl-1'>Duplicate Base View </div>
        </div>
        {!isDeleteConfirmationOpen ? (
          <div
            onClick={() => {
              setIsDeleteConfirmationOpen(true)
            }}
            className='text-error hover:bg-weak-2 group flex h-8  cursor-pointer items-center pl-2 pr-2 leading-8'>
            <DeleteFilledIcon width={12} height={12} />
            <div className='pl-1'>Delete Base View </div>
          </div>
        ) : null}
      </div>
      {isDeleteConfirmationOpen ? (
        <div className='mt-4 flex items-center'>
          <div className='mr-3'>{`Are you sure you want to delete ${
            currentBaseView?.name ?? ''
          } Base View?`}</div>
          <Button
            variant='danger'
            size='md'
            onClick={deleteBaseViewHandler}
            loading={isDeleteBaseViewLoading}>
            Confirm
          </Button>
        </div>
      ) : null}
    </div>
  )
}
