import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { PAPER_STYLE } from './ReviewExportTaskDialog'

const DataProfileRunning = () => (
  <Paper style={PAPER_STYLE}>
    <Typography variant='h6'>Data profile is being generated...</Typography>
    <Typography variant='caption'>Please try again at a later time.</Typography>
  </Paper>
)

export { DataProfileRunning }
