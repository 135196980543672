import { SVGProps } from 'react'

function TechnologiesFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M29.5385 3H7.38462C6.73177 3 6.10567 3.25541 5.64404 3.71004C5.18242 4.16468 4.92308 4.78129 4.92308 5.42424V7.84848H2.46154C1.8087 7.84848 1.1826 8.1039 0.720968 8.55853C0.25934 9.01316 0 9.62978 0 10.2727V27.2424C0 27.8854 0.25934 28.502 0.720968 28.9566C1.1826 29.4113 1.8087 29.6667 2.46154 29.6667H24.6154C25.2682 29.6667 25.8943 29.4113 26.356 28.9566C26.8176 28.502 27.0769 27.8854 27.0769 27.2424V24.8182H29.5385C30.1913 24.8182 30.8174 24.5628 31.279 24.1081C31.7407 23.6535 32 23.0369 32 22.3939V5.42424C32 4.78129 31.7407 4.16468 31.279 3.71004C30.8174 3.25541 30.1913 3 29.5385 3ZM24.6154 10.2727V12.697H2.46154V10.2727H24.6154ZM29.5385 22.3939H27.0769V10.2727C27.0769 9.62978 26.8176 9.01316 26.356 8.55853C25.8943 8.1039 25.2682 7.84848 24.6154 7.84848H7.38462V5.42424H29.5385V22.3939Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TechnologiesFilled
