import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@invisible/ui/button'
import { Input } from '@invisible/ui/input'
import { Switch } from '@invisible/ui/switch'
import { FC, forwardRef } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { ReadOnlyInterfaceNotAvailable } from '../../../interface/NotAvailable'
import type { TCustomWACProps } from '../../types'
import { BRANDS, ERROR_CATEGORY, ERROR_TYPES } from './lib/constants'
import { useAuditSubmit, useErrors } from './lib/hooks'
import { qaFormSchema } from './lib/schemas'
import type { QaFormData } from './lib/types'

type inputErrorProps = {
  error: any
  stepRunID: string
  index: number
}

const ErrorItem = ({ error, stepRunID, index }: inputErrorProps) => {
  const { register, getValues, setValue } = useForm<QaFormData>({
    mode: 'all',
    resolver: zodResolver(qaFormSchema),
    defaultValues: {
      brand: error['Brand'],
      categoryName: error['Category Name'],
      numberOfErrors: error['Number Of Errors'],
      productName: error['Product Name'],
      errorType: error['Error Type'],
      errorCategory: error['Error Category'],
      errorDescription: error['Error Description'],
      errorSummary: error['Error Summary'],
      dontCountError: error['Dont Count Error'],
      errorCorrection: error['Error Correction'],
    },
  })

  const { addToast } = useToasts()

  const { handleAuditSubmit, isUpdateBaseRunVariablesLoading } = useAuditSubmit({
    stepRunId: stepRunID,
  })

  const submitErrorQAForm = async () => {
    await handleAuditSubmit(error, getValues())

    addToast('Item Updated!', { appearance: 'info' })
  }

  return (
    <>
      <div data-cy='text' className='sc-bdvvtL sc-dkPtRN hkerGV sc-kDTinF wbTZT font-bold'>
        Error {index + 1}
      </div>
      <div className='block space-y-6'>
        <div className='flex w-full items-center space-x-2'>
          <label className='flex w-full flex-col'>
            <span className='text-header'>Brand</span>
            <select
              {...register('brand')}
              className='border-theme-weak-3 w-4/5 rounded px-2 py-1.5 focus:outline focus:outline-blue-800'>
              <option value={undefined}></option>
              {BRANDS.map((reason, i) => (
                <option key={i} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='w-full'>
            <span className='text-header'>Category Name</span>
            <Input {...register('categoryName')} type='text' size='medium' className='w-4/5' />
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='w-full'>
            <span className='text-header'>Number Of Error</span>
            <Input {...register('numberOfErrors')} type='number' size='medium' className='w-4/5' />
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='w-full'>
            <span className='text-header'>Product Name</span>
            <Input {...register('productName')} type='text' size='medium' className='w-4/5' />
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='flex w-full flex-col'>
            <span className='text-header'>Error Type</span>
            <select
              {...register('errorType')}
              className='border-theme-weak-3 w-4/5 rounded px-2 py-1.5 focus:outline focus:outline-blue-800'>
              <option value={undefined}></option>
              {ERROR_TYPES.map((reason, i) => (
                <option key={i} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='flex w-full flex-col'>
            <span className='text-header w-32'>Error Category</span>
            <select
              {...register('errorCategory')}
              className='border-theme-weak-3 w-4/5 rounded px-2 py-1.5 focus:outline focus:outline-blue-800'>
              <option value={undefined}></option>
              {ERROR_CATEGORY.map((reason, i) => (
                <option key={i} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='w-full'>
            <span className='text-header'>Error Description</span>
            <Input {...register('errorDescription')} type='text' size='medium' className='w-4/5' />
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='w-full'>
            <span className='text-header'>Error Summary</span>
            <Input {...register('errorSummary')} type='number' size='medium' className='w-4/5' />
          </label>
        </div>
        <div className='flex items-center space-x-2'>
          <label className='text-header'>Don`t Count Error:</label>
          <Switch
            isOn={getValues('dontCountError')}
            size='medium'
            onToggle={(value) => {
              setValue('dontCountError', value)
            }}
          />
        </div>

        <div className='flex items-center space-x-2'>
          <label className='flex w-full flex-col'>
            <span className='text-header w-32'>Error Correction:</span>
            <textarea
              {...register('errorCorrection')}
              className='border-theme-weak-3 h-[70px] w-[600px] appearance-none rounded p-2 focus:outline focus:outline-blue-800'
              rows={5}></textarea>
          </label>
        </div>

        <div className='flex w-full items-center space-x-2 bg-opacity-10'>
          <Button
            variant='primary'
            size='md'
            onClick={() => submitErrorQAForm()}
            loading={isUpdateBaseRunVariablesLoading}>
            Edit
          </Button>
        </div>
        <hr />
      </div>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuUpdateReviewQA: FC<TCustomWACProps> = forwardRef(
  ({ stepRun, baseRun, isReadOnly }: TCustomWACProps, wizardContainerRef) => {
    const { errors, isLoading } = useErrors({
      stepBaseRunId: stepRun.baseRunId,
    })

    if (isReadOnly) return <ReadOnlyInterfaceNotAvailable tooltipText={'Grubhub MUR QA WAC'} />

    return (
      <div className='flex h-[95%] rounded border bg-white p-4 shadow'>
        <div className='block w-full space-y-3'>
          <div data-cy='text' className='sc-bdvvtL sc-dkPtRN hkerGV sc-kDTinF wbTZT font-bold'>
            Captured Errors
          </div>

          <div className='block h-[90%] w-full space-y-6 overflow-auto'>
            {errors.map((error, index: number) => (
              <ErrorItem error={error} stepRunID={stepRun.id} index={index} key={index} />
            ))}
          </div>
        </div>
      </div>
    )
  }
)

MenuUpdateReviewQA.displayName = ''

export { MenuUpdateReviewQA }
