import { SVGProps } from 'react'

function Aim(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#aim_svg__clip0)' fill='#fff'>
        <path
          d='M13.812 6.449h-1.773a4.644 4.644 0 00-4.06-4.06V.616A.116.116 0 007.863.5h-.87a.116.116 0 00-.117.116V2.39a4.644 4.644 0 00-4.06 4.06H1.045a.116.116 0 00-.116.116v.87c0 .064.052.116.116.116h1.773a4.644 4.644 0 004.06 4.06v1.773c0 .064.052.116.115.116h.871a.116.116 0 00.116-.116V11.61a4.644 4.644 0 004.06-4.06h1.773a.116.116 0 00.116-.116v-.87a.116.116 0 00-.116-.116zM7.428 10.54a3.54 3.54 0 110-7.08 3.54 3.54 0 110 7.08z'
          fill='currentColor'
        />
        <path
          d='M7.428 5.26c-.466 0-.901.18-1.23.511-.33.33-.511.765-.511 1.23 0 .466.181.902.51 1.231a1.735 1.735 0 002.461 0 1.735 1.735 0 000-2.46 1.72 1.72 0 00-1.23-.511z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='aim_svg__clip0'>
          <path fill='currentColor' transform='translate(.928 .5)' d='M0 0h13v13H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Aim
