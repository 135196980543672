import { SVGProps } from 'react'
function Close(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.47452 4.35147C4.94315 3.88284 5.70295 3.88284 6.17158 4.35147L16.123 14.3029L26.0745 4.35147C26.5431 3.88284 27.3029 3.88284 27.7716 4.35147C28.2402 4.8201 28.2402 5.5799 27.7716 6.04853L17.8201 16L27.7716 25.9515C28.2402 26.4201 28.2402 27.1799 27.7716 27.6485C27.3029 28.1172 26.5431 28.1172 26.0745 27.6485L16.123 17.6971L6.17158 27.6485C5.70295 28.1172 4.94315 28.1172 4.47452 27.6485C4.00589 27.1799 4.00589 26.4201 4.47452 25.9515L14.426 16L4.47452 6.04853C4.00589 5.5799 4.00589 4.8201 4.47452 4.35147Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Close
