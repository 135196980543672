import { SVGProps } from 'react'

function GaugeFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78024)'>
        <path
          d='M16.356 -0.00292969C25.1928 -0.00292969 32.356 7.16027 32.356 15.9971C32.356 24.8339 25.1928 31.9971 16.356 31.9971C7.51916 31.9971 0.355957 24.8339 0.355957 15.9971C0.355957 7.16027 7.51916 -0.00292969 16.356 -0.00292969ZM23.7096 8.64347C23.3832 8.31547 22.868 8.27067 22.4888 8.53627C17.8648 11.7811 15.2568 13.7043 14.6584 14.2995C13.7224 15.2371 13.7224 16.7571 14.6584 17.6947C15.596 18.6307 17.116 18.6307 18.0536 17.6947C18.404 17.3427 20.3224 14.7331 23.8104 9.85947C24.0792 9.48507 24.036 8.96987 23.7096 8.64347ZM25.156 14.3971C24.2728 14.3971 23.556 15.1139 23.556 15.9971C23.556 16.8803 24.2728 17.5971 25.156 17.5971C26.0392 17.5971 26.756 16.8803 26.756 15.9971C26.756 15.1139 26.0392 14.3971 25.156 14.3971ZM7.55596 14.3971C6.67276 14.3971 5.95596 15.1139 5.95596 15.9971C5.95596 16.8803 6.67276 17.5971 7.55596 17.5971C8.43916 17.5971 9.15596 16.8803 9.15596 15.9971C9.15596 15.1139 8.43916 14.3971 7.55596 14.3971ZM11.2648 8.64347C10.6408 8.01947 9.62636 8.01947 9.00236 8.64347C8.37836 9.26747 8.37836 10.2803 9.00236 10.9059C9.62636 11.5299 10.6392 11.5299 11.2648 10.9059C11.8888 10.2819 11.8888 9.26747 11.2648 8.64347ZM16.356 5.59707C15.4728 5.59707 14.756 6.31387 14.756 7.19707C14.756 8.08027 15.4728 8.79707 16.356 8.79707C17.2392 8.79707 17.956 8.08027 17.956 7.19707C17.956 6.31387 17.2392 5.59707 16.356 5.59707Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78024'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GaugeFilled
