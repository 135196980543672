import { useExportTasksQuery } from '@invisible/concorde/gql-client'
import CloseIcon from '@mui/icons-material/Close'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react'

import { ExportTaskSummary } from './ExportTaskSummary'
import { ReadOnlyFilterConfiguration } from './FilterConfiguration'
import { ReadOnlyPreview } from './Preview'

interface IProps {
  exportTaskId: string | null
  setExportTaskId: Dispatch<SetStateAction<string | null>>
}

type TabName = 'Summary' | 'Filters' | 'Preview'

const ViewExportTaskDialog = ({ exportTaskId, setExportTaskId }: IProps) => {
  const { data } = useExportTasksQuery(
    {
      filters: {
        id: exportTaskId,
      },
    },
    {
      enabled: !!exportTaskId,
    }
  )
  const exportTasks = data?.exportTasks.edges ?? []
  const exportTask = exportTasks[0]
  const schedule = exportTask?.node.exportConfiguration.exportConfigurationTaskScheduleConfigs[0]
  const [selectedTab, setSelectedTab] = useState<TabName>('Summary')

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: TabName) => {
    setSelectedTab(newValue)
  }

  const handleClose = () => {
    setExportTaskId(null)
    setSelectedTab('Summary')
  }

  return (
    <Dialog open={!!exportTaskId} onClose={handleClose} maxWidth='xl' fullWidth>
      <DialogTitle>
        <Stack direction='row' gap='8px' alignItems='center'>
          <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
            Export process data
          </Typography>
          <Chip
            variant='filled'
            label='View only'
            sx={{
              color: 'white',
              backgroundColor: 'grey.700',
              borderRadius: '16px',
            }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: '90vh', px: 0 }}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>

        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', px: '16px' }}>
            <TabList onChange={handleTabChange}>
              <Tab
                label={<span>SUMMARY</span>}
                value='Summary'
                sx={{ fontWeight: 'normal' }}
                iconPosition='start'
                disableRipple
              />
              <Tab
                label={<span>FILTERS</span>}
                value='Filters'
                sx={{ fontWeight: 'normal' }}
                disableRipple
              />
              <Tab
                label={<span>PREVIEW</span>}
                value='Preview'
                sx={{ fontWeight: 'normal' }}
                disableRipple
              />
            </TabList>
          </Box>

          <TabPanel value='Summary'>
            {exportTasks.length > 0 ? (
              <ExportTaskSummary
                name={exportTask.node.exportConfiguration.name ?? '-'}
                description={exportTask.node.exportConfiguration.description ?? '-'}
                baseName={exportTask.node.exportConfiguration.base.name}
                recipients={exportTask.node.exportConfiguration.recipients}
                schedule={schedule}
              />
            ) : (
              <Stack direction={'row'} justifyContent={'center'}>
                <CircularProgress />
              </Stack>
            )}
          </TabPanel>
          <TabPanel value='Filters'>
            {exportTasks.length > 0 && exportTasks[0].node.calculatedFilterConfig ? (
              <ReadOnlyFilterConfiguration filters={exportTasks[0].node.calculatedFilterConfig} />
            ) : (
              // needs refinement: what to show when there are no calculated filters
              <Stack direction={'row'} justifyContent={'center'}>
                <CircularProgress />
              </Stack>
            )}
          </TabPanel>
          <TabPanel value='Preview'>
            {exportTasks.length > 0 ? (
              <ReadOnlyPreview
                ruleName={exportTasks[0].node.transformationRule.name ?? '-'}
                expression={exportTasks[0].node.transformationRule.expression}
                filterConfigId={exportTasks[0].node.filterConfig.id}
              />
            ) : (
              <Stack direction={'row'} justifyContent={'center'}>
                <CircularProgress />
              </Stack>
            )}
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}

export { ViewExportTaskDialog }
