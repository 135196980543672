import { Button } from '@invisible/ui/button'
import {
  CalendarOutlineIcon as _CalendarOutlineIcon,
  DateSeparatorIcon as _DateSeparatorIcon,
} from '@invisible/ui/icons'
import { ICalendar } from '@invisible/ui/single-date-picker'
import { color, fontSizes, gray, space, styled } from '@invisible/ui/themes'
import { baseTheme } from '@invisible/ui/themes'
import { addDays, addSeconds, addYears } from 'date-fns'
import defaultLocale from 'date-fns/locale/en-US'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

const TextContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '328px'};
  height: ${space(5)};
  padding: 6px 12px 6px 12px;
  border: 1px solid ${gray(5)};
  border-radius: 6px;
  position: relative;
  cursor: text;
  transition: border 0.3s ease-in-out;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};

  &:hover {
    border: 1px solid ${color('primary')};
  }

  &:focus {
    outline: ${color('primary')} auto 5px;
  }
`

const DateText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};

  span {
    font-size: ${fontSizes(1)};
    line-height: ${fontSizes(4)};
    padding-left: 6px;
  }

  .blank {
    color: black;
    font-weight: 400;
    display: flex;
    opacity: 0.25;
    align-items: center;
  }
  .selected {
    span {
      color: ${gray(9)};
      opacity: 1;
    }
    display: flex;
    align-items: center;

    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`

const CalendarOutlineIcon = styled(_CalendarOutlineIcon)`
  height: 14.29px;
  width: 14.29px;
  margin-left: 12px;
`

const DateSeparatorIcon = styled(_DateSeparatorIcon)`
  margin-left: 10px;
  margin-right: 6px;
`

export interface IRange {
  startDate: Date
  endDate: Date
  key: string
  color?: string
  autoFocus?: boolean
  disabled?: boolean
  showDateDisplay?: boolean
}

interface IDateRange extends Omit<ICalendar, 'onChange' | 'date' | 'onRangeFocusChange'> {
  ranges: IRange[]
  onChange: (range: Pick<IRange, 'startDate' | 'endDate' | 'key'>[]) => void
  moveRangeOnFirstSelection?: boolean
  retainEndDateOnFirstSelection?: boolean
  onRangeFocusChange?: (range: IRange) => void
  rangeColors?: string[]
}

interface IProps extends Partial<IDateRange> {
  width?: string
  standalone?: boolean
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  onClear?: () => void
  closeOnClickOut?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DateRangePicker: FC<IProps> = ({
  ranges,
  onChange,
  editableDateInputs = true,
  moveRangeOnFirstSelection = false,
  retainEndDateOnFirstSelection = false,
  color = baseTheme.colors.primary,
  rangeColors = [baseTheme.colors.primary],
  showMonthArrow = true,
  showMonthAndYearPickers = true,
  disabledDates = [],
  classNames = {},
  locale = defaultLocale,
  dateDisplayFormat = 'MMM d, yyyy',
  monthDisplayFormat = 'MMM yyyy',
  weekdayDisplayFormat = 'E',
  dayDisplayFormat = 'd',
  showDateDisplay = true,
  showPreview = true,
  displayMode = 'date',
  months = 1,
  scroll = {
    enabled: false,
  },
  direction = 'vertical',
  maxDate = addYears(new Date(), 20),
  minDate = addYears(new Date(), -100),
  startDatePlaceholder = 'Early',
  endDatePlaceholder = 'Continuous',
  dragSelectionEnabled = true,
  fixedHeight = false,
  width,
  standalone = false,
  isOpen = false,
  onOpenChange,
  onClear,
  closeOnClickOut = true,
  ...rest
}) => {
  const [open, setOpen] = useState(isOpen)
  const [selectedRange, setSelectedRange] = useState<IRange[] | null | undefined>(ranges)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const handleChange = (
    range: Record<'selection', Pick<IRange, 'startDate' | 'endDate' | 'key'>>
  ) => {
    const { endDate, ...rangeFields } = range['selection']
    // As endDate starts at 00 Hours, adding 24 hours to account for the whole day. Removed 1 sec to prevent rolling over the next day
    const ranges = [{ ...rangeFields, endDate: addSeconds(endDate, 86399) }]
    setSelectedRange(ranges)
    if (onChange) {
      return onChange(ranges)
    }
  }

  const handleClickOut = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        onOpenChange ? onOpenChange(false) : setOpen(false)
      }
    },
    [containerRef.current]
  )

  useEffect(() => {
    if (ranges !== selectedRange) {
      setSelectedRange(ranges)
    }
  }, [ranges])

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!closeOnClickOut) return
    document.addEventListener('click', handleClickOut)

    return () => {
      document.removeEventListener('click', handleClickOut)
    }
  }, [handleClickOut])

  return (
    <div
      ref={containerRef}
      style={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 100,
      }}
      data-cy='dateRangePicker'>
      {!standalone ? (
        <TextContainer tabIndex={0} width={width} onClick={() => setOpen((prev) => !prev)}>
          <DateText>
            {!selectedRange ? (
              <span className='blank'>
                <CalendarOutlineIcon />
                <span>Start Date</span>
                <DateSeparatorIcon />
                <CalendarOutlineIcon />
                <span>End Date</span>
              </span>
            ) : (
              <span className='blank'>
                <CalendarOutlineIcon />
                <span>
                  {selectedRange[0].startDate.toDateString().split(' ').slice(1).join(' ')}
                </span>
                <DateSeparatorIcon />
                <CalendarOutlineIcon />
                <span>{selectedRange[0].endDate.toDateString().split(' ').slice(1).join(' ')}</span>
              </span>
            )}
          </DateText>
        </TextContainer>
      ) : null}

      {open && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore broken typings */}
          <DateRange
            editableDateInputs={editableDateInputs}
            onChange={handleChange as any}
            moveRangeOnFirstSelection={moveRangeOnFirstSelection}
            retainEndDateOnFirstSelection={retainEndDateOnFirstSelection}
            ranges={
              selectedRange ?? [
                {
                  startDate: new Date(),
                  endDate: addDays(new Date(), 1),
                  key: 'selection',
                },
              ]
            }
            color={color}
            rangeColors={rangeColors}
            showMonthArrow={showMonthArrow}
            showMonthAndYearPickers={showMonthAndYearPickers}
            disabledDates={disabledDates as unknown as Date[]}
            classNames={classNames}
            locale={locale}
            dateDisplayFormat={dateDisplayFormat}
            monthDisplayFormat={monthDisplayFormat}
            weekdayDisplayFormat={weekdayDisplayFormat}
            dayDisplayFormat={dayDisplayFormat}
            showDateDisplay={showDateDisplay}
            showPreview={showPreview}
            displayMode={displayMode}
            months={months}
            scroll={scroll}
            direction={direction}
            maxDate={maxDate}
            minDate={minDate}
            startDatePlaceholder={startDatePlaceholder}
            endDatePlaceholder={endDatePlaceholder}
            dragSelectionEnabled={dragSelectionEnabled}
            fixedHeight={fixedHeight}
            {...rest}
          />
          {onClear ? (
            <div className='z-[101] mt-[408px] ml-[290px] w-[60px]'>
              <Button variant='secondary' size='sm' onClick={onClear}>
                Clear
              </Button>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export { DateRangePicker }
