import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { IDataFilter, TFilterValue } from '@invisible/common/types'
import { FILTER_OPERATOR, FILTER_TYPE } from '@invisible/ui/constants'
import { Dropdown, IOption } from '@invisible/ui/dropdown'
import { theme } from '@invisible/ui/mui-theme-v2'
import { PrismaSchemas } from '@invisible/ultron/zod'
import { Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'

type TBase = TProcessWithBases['bases'][0]

interface IProps {
  base?: TBase
  onChange: (baseVariableFilter?: IDataFilter<TFilterValue>) => void
}

const DEFAULT_OPTION: IOption = { key: 'null', value: 'No Base Variable Selected' }

export const BaseVariableFilter = ({ base, onChange }: IProps) => {
  const [selectedKey, setSelectedKey] = useState<string>(DEFAULT_OPTION.key)

  const baseVariableOptions = useMemo(() => {
    if (!base) return
    let baseVariableOptionMap = { [DEFAULT_OPTION.value as string]: DEFAULT_OPTION }
    baseVariableOptionMap = base.baseVariables.reduce(
      (baseVariableOptionMap: Record<string, IOption>, baseVariable) => {
        const isBaseVariableOptionInMap = !!baseVariableOptionMap[baseVariable.id]
        const isBaseVariableEnumType = baseVariable.type === PrismaSchemas.VariableType.enum
        if (isBaseVariableEnumType && !isBaseVariableOptionInMap) {
          baseVariableOptionMap[baseVariable.id] = {
            key: baseVariable.id,
            value: baseVariable.name,
          }
        }
        return baseVariableOptionMap
      },
      baseVariableOptionMap
    )

    return Object.values(baseVariableOptionMap)
  }, [base?.id])

  // select default option in case base is changed.
  useEffect(() => setSelectedKey(DEFAULT_OPTION.key), [base?.id])

  // call onChange whenever option is selected/unselected
  useEffect(() => {
    if (selectedKey !== DEFAULT_OPTION.key) {
      const updatedBaseVariableFilter = {
        name: FILTER_TYPE.BaseVariable,
        operator: FILTER_OPERATOR.equals,
        value: selectedKey,
        valueType: 'String',
      }
      onChange(updatedBaseVariableFilter)
    } else {
      onChange(undefined)
    }
  }, [selectedKey])

  const handleBaseVariablesChange = ({ key }: IOption) => setSelectedKey(key)

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant='body1' sx={{ my: 1, fontWeight: 'bold' }}>
          Base Variable
        </Typography>
      </ThemeProvider>
      <Dropdown
        name={FILTER_TYPE.BaseVariable}
        placeholder='Select a base variable'
        options={baseVariableOptions ?? []}
        onChange={handleBaseVariablesChange}
        selectedKey={selectedKey}
      />
    </div>
  )
}
