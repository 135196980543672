import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { useQuery } from '@invisible/trpc/client'
import { BASE_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { FC, useCallback, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import shallow from 'zustand/shallow'

import { Column } from './ColumnItem'

type TBaseView = TProcessWithBases['bases'][number]['baseViews'][number]
type TBase = TProcessWithBases['bases'][number]
type TStep = TBase['steps'][number]

interface IColumnOrderProps {
  steps: TStep[]
  base: TBase
  baseViews: TBaseView[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ColumnOrder: FC<IColumnOrderProps> = ({ base, steps, baseViews }) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const {
    hiddenColumns,
    filterableColumns,
    columnOrder,
    setHiddenColumns,
    setColumnOrder,
    setFilterableColumns,
    setIsFreshNew,
  } = useStore(
    useCallback(
      (state) => ({
        hiddenColumns: state.hiddenColumns,
        filterableColumns: state.filterableColumns,
        columnOrder: state.columnOrder,
        setHiddenColumns: state.setHiddenColumns,
        setColumnOrder: state.setColumnOrder,
        setFilterableColumns: state.setFilterableColumns,
        setIsFreshNew: state.setIsFreshNew,
      }),
      []
    ),
    shallow
  )

  const { data: baseWithBaseVariables } = useQuery([
    'base.findBaseVariables',
    {
      id: baseId,
    },
  ])

  const manualSteps = useMemo(
    () => steps.filter((step) => step.stepTemplate.type === 'manual'),
    [steps]
  )

  const automatedSteps = useMemo(
    () =>
      steps.filter(
        (step) => step.stepTemplate.type === 'full_auto' || step.stepTemplate.type === 'semi_auto'
      ),
    [steps]
  )

  const allColumns = useMemo(
    () => [
      { id: 'id', name: 'ID', type: 'baseRunId' as const },
      { id: 'baseRunStatus', name: 'BaseRun Status', type: 'baseRunStatus' as const },
      { id: 'timeleft', name: 'Time Left', type: 'timeLeft' as const },
      { id: 'deadline', name: 'Deadline', type: 'deadline' as const },
      { id: 'deliveredAt', name: 'Delivered At', type: 'deliveredAt' as const },
      { id: 'createdAt', name: 'Created At', type: 'createdAt' as const },
      ...(base.parentId
        ? [{ id: 'parentBaseRunId', name: 'Batch', type: 'parentBaseRunId' as const }]
        : []),
      ...(base.children?.map((childBase) => ({
        id: childBase.id,
        name: childBase.name,
        type: 'childBase' as const,
      })) ?? []),
      ...(baseWithBaseVariables?.baseVariables ?? []).map(({ id, name }) => ({
        id,
        name,
        type: 'baseVariable' as const,
      })),
      ...automatedSteps.map(({ id, name }) => ({ id: name, name, type: 'step' as const })),
      ...manualSteps.map(({ id, name }) => ({ id: name, name, type: 'step' as const })),
      ...manualSteps.map(({ id, name }) => ({
        id: id,
        name: `${name}: Assignee`,
        type: 'step' as const,
      })),
    ],
    [
      base.parentId,
      base.children,
      baseWithBaseVariables?.baseVariables,
      automatedSteps,
      manualSteps,
    ]
  )

  const allSortedColumns = useMemo(
    () =>
      allColumns
        .map((column) => ({
          ...column,
          visibility:
            hiddenColumns.find((hiddenColumnId) => hiddenColumnId === column.id) === undefined,
          filterable:
            filterableColumns.find((filterableColumnId) => filterableColumnId === column.id) !==
            undefined,
        }))
        .sort((a, b) => {
          const getIndex = (id: string) =>
            ((columnOrder ?? []) as string[])?.indexOf(id) < 0
              ? 1000
              : ((columnOrder ?? []) as string[])?.indexOf(id)

          return getIndex(a.id) - getIndex(b.id)
        }),
    [allColumns, hiddenColumns, columnOrder, filterableColumns]
  )

  const setColumnHidden = ({ column }: { column: { id: string; name: string } }) => {
    setIsFreshNew(false)
    setHiddenColumns([...hiddenColumns, column.id])
  }

  const setColumnVisible = ({ column }: { column: { id: string; name: string } }) => {
    setIsFreshNew(false)
    setHiddenColumns(hiddenColumns.filter((columnId) => columnId !== column.id))
  }

  const setColumnOrderHandle = ({ columnName, index }: { columnName: string; index: number }) => {
    setIsFreshNew(false)
    const newOrder = [
      ...allSortedColumns
        .map(({ id }) => id)
        .filter((id) => id !== columnName)
        .slice(0, index),
      columnName,
      ...allSortedColumns
        .map(({ id }) => id)
        .filter((id) => id !== columnName)
        .slice(index),
    ]
    setColumnOrder(newOrder)
  }

  const addFiltrableColumn = ({ column }: { column: { id: string; name: string } }) => {
    setIsFreshNew(false)
    setFilterableColumns([...filterableColumns, column.id])
  }

  const removeFiltrableColumn = ({ column }: { column: { id: string; name: string } }) => {
    setIsFreshNew(false)
    setFilterableColumns(filterableColumns.filter((columnId) => columnId !== column.id))
  }

  return (
    <div className='relative mt-2 max-h-[70vh] overflow-y-auto'>
      <DragDropContext
        onDragEnd={(data) => {
          if (data.destination?.index !== undefined) {
            setColumnOrderHandle({
              columnName: data.draggableId,
              index: data.destination?.index,
            })
          }
        }}>
        <Droppable droppableId='categories' type='category'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {allSortedColumns.map((column, index) => (
                <Column
                  column={column}
                  key={column.id}
                  setColumnHidden={setColumnHidden}
                  setColumnVisible={setColumnVisible}
                  addFiltrableColumn={addFiltrableColumn}
                  removeFiltrableColumn={removeFiltrableColumn}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
