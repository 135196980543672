import { SVGProps } from 'react'

function Redo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.6829 7.28973C14.5106 6.53309 16.5217 6.33553 18.4617 6.72205C20.4018 7.10856 22.1836 8.06178 23.5818 9.46112L26.1199 11.9918H22.5391C21.9868 11.9918 21.5391 12.4395 21.5391 12.9918C21.5391 13.5441 21.9868 13.9918 22.5391 13.9918H28.5391C28.6674 13.9918 28.7901 13.9677 28.9028 13.9236C29.0284 13.8747 29.146 13.7994 29.2473 13.6979C29.4455 13.4991 29.5427 13.2379 29.5391 12.9778V6.99182C29.5391 6.43954 29.0913 5.99182 28.5391 5.99182C27.9868 5.99182 27.5391 6.43954 27.5391 6.99182V10.5825L24.996 8.04694C23.3183 6.36803 21.1803 5.22436 18.8525 4.7606C16.5245 4.29678 14.1112 4.53385 11.9179 5.44181C9.72459 6.34977 7.84987 7.88784 6.53086 9.86144C5.21185 11.835 4.50781 14.1555 4.50781 16.5293C4.50781 18.9031 5.21185 21.2236 6.53086 23.1972C7.84987 25.1708 9.72459 26.7088 11.9179 27.6168C14.1112 28.5248 16.5245 28.7618 18.8525 28.298C21.1805 27.8342 23.3187 26.6903 24.9966 25.0111C25.3869 24.6204 25.3867 23.9873 24.996 23.5969C24.6053 23.2065 23.9721 23.2068 23.5818 23.5975C22.1836 24.9968 20.4018 25.95 18.4617 26.3366C16.5217 26.7231 14.5106 26.5255 12.6829 25.7689C10.8551 25.0122 9.29286 23.7305 8.19368 22.0859C7.09451 20.4412 6.50781 18.5075 6.50781 16.5293C6.50781 14.5511 7.09451 12.6174 8.19368 10.9728C9.29286 9.32808 10.8551 8.04636 12.6829 7.28973Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Redo
