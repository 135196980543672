import { SVGProps } from 'react'
function EyeCancelled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.59 8.082a1.531 1.531 0 01-1.215.443l1.658-1.659a1.531 1.531 0 01-.442 1.216zm3.402-6.403l.584.584a.109.109 0 01.024.12.109.109 0 01-.024.035L1.84 12.154a.11.11 0 01-.155 0l-.583-.584a.11.11 0 010-.155L2.548 9.97C1.82 9.313 1.198 8.44.68 7.352a.824.824 0 010-.704c1.293-2.737 3.254-4.106 5.882-4.106 1.029 0 1.956.211 2.78.632l1.495-1.495a.11.11 0 01.154 0zM4.414 5.814a2.406 2.406 0 00-.029 2.319l.665-.665A1.532 1.532 0 016.976 5.54l.665-.665a2.406 2.406 0 00-3.227.938zm6.312-1.647c.664.64 1.237 1.466 1.718 2.48a.824.824 0 010 .704c-1.293 2.737-3.254 4.106-5.882 4.106-.95 0-1.812-.18-2.587-.539l1.671-1.671a2.407 2.407 0 003.109-3.109l1.97-1.97z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EyeCancelled
