import axios from 'axios'
import { NEXT_PUBLIC_CONCORDE_URL } from 'libs/common/components/process-base/config/env'
import { useMutation, UseMutationOptions } from 'react-query'

type TVariables = { exportTaskId: string }
const useRetryExport = (options: UseMutationOptions<unknown, unknown, TVariables>) => {
  const { isLoading: isExportRetryLoading, mutateAsync: retryExport } = useMutation(
    ['retry-export'],
    async ({ exportTaskId }: TVariables) =>
      axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/data-delivery/retry/`,
        { export_task_id: exportTaskId },
        {
          withCredentials: true,
        }
      ),
    options
  )

  return { isExportRetryLoading, retryExport }
}

export { useRetryExport }
