import { FC } from 'react'

import { ITriggerFormProps, TriggerFormWAC } from './TriggerFormWAC'
import { TWAC } from './types' // This is Trigger types, not to confuse with Wizard step types. TODO: extract to common/types lib

// eslint-disable-next-line @typescript-eslint/ban-types
export const ManualTriggerAtomicComponents: { [key in TWAC]: FC<ITriggerFormProps> } = {
  // for now we use ITriggerFormProps because it's the only one
  triggerForm: TriggerFormWAC,
}
