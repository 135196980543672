import { parseDataFilters } from '@invisible/common/helpers'
import { IDataFilter, IFilter, TFilterValue } from '@invisible/common/types'
import { useStoryFiltersByIdQuery } from '@invisible/concorde/gql-client'
import { FILTER_TYPE } from '@invisible/ui/constants'
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { useStory } from '../hooks'
export type DataFiltersPerReport = {
  reportId: string
  filters: IDataFilter<TFilterValue>[]
}
type TStoryDynamicFiltersContext = {
  dataFiltersPerReport: DataFiltersPerReport[] | null
  storyUnionFilters: IFilter<TFilterValue>[]
  setStoryUnionFilters: Dispatch<SetStateAction<IFilter<TFilterValue>[]>>
}
const StoryDynamicFiltersContext = createContext<TStoryDynamicFiltersContext>({
  dataFiltersPerReport: null,
  storyUnionFilters: [],
  setStoryUnionFilters: () => console.log('union filters setter function'),
})
const segregateUnionisedStoryFilters = (
  reportFilters: IFilter<TFilterValue>[],
  unifiedFilters: IFilter<TFilterValue>[]
) =>
  reportFilters.map((reportFilter) => {
    const matchingLabelStoryFilter = unifiedFilters.find(
      (storyFilter) => storyFilter.label === reportFilter.label
    )
    const filterValue =
      matchingLabelStoryFilter?.value ??
      (reportFilter.filterName === FILTER_TYPE.CustomSelect
        ? reportFilter.defaultSelectValue
        : reportFilter.value)
    return {
      ...reportFilter,
      value: filterValue ?? null,
      operator: matchingLabelStoryFilter?.operator ?? reportFilter.operator,
      valueLabel: matchingLabelStoryFilter?.valueLabel ?? reportFilter.valueLabel,
    }
  })
/* eslint-disable @typescript-eslint/ban-types */
const StoryDynamicFiltersProvider: React.FC = ({ children }) => {
  const { id: storyGlobalId, scope, scopedId } = useStory()

  const [storyUnionFilters, setStoryUnionFilters] = useState<IFilter<TFilterValue>[]>([])
  const { data, isLoading: isLoadingFilters } = useStoryFiltersByIdQuery(
    {
      id: storyGlobalId,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
  // use cached or fetched data to initialization state.
  // useQuery onSuccess callback would not trigger in case of cached response.
  useEffect(() => {
    if (isLoadingFilters) return
    const storyUnionFilters = data?.storyFiltersById.storyFilters ?? []
    setStoryUnionFilters(storyUnionFilters as unknown as IFilter<TFilterValue>[])
  }, [isLoadingFilters, data])
  const dataFiltersPerReport = useMemo(() => {
    if (isLoadingFilters) return null
    const filters = data?.storyFiltersById
    const filtersPerReport = filters?.reportFilters.map((report) => {
      const reportDynamicFilters = segregateUnionisedStoryFilters(
        report.filters as unknown as IFilter<TFilterValue>[],
        storyUnionFilters
      )
      const dataFilters = parseDataFilters({
        scope: {
          type: scope,
          scopedId,
        },
        reportDynamicFilters: reportDynamicFilters as IFilter<TFilterValue>[],
        withLabels: true,
      })
      return {
        reportId: report.id,
        filters: dataFilters,
      }
    })
    return filtersPerReport ?? []
  }, [scopedId, storyUnionFilters])
  return (
    <StoryDynamicFiltersContext.Provider
      value={{
        dataFiltersPerReport,
        setStoryUnionFilters,
        storyUnionFilters,
      }}>
      {children}
    </StoryDynamicFiltersContext.Provider>
  )
}
export { StoryDynamicFiltersContext, StoryDynamicFiltersProvider }
