import { z } from '@invisible/zod'

const schema = z.object({
  conversationsBaseId: z.string().optional(),
  messagesBaseId: z.string().optional(),

  messageTypes: z.array(z.string()).default(['user_prompt', 'bot_response']),

  typeBaseVariableId: z.string().optional(),
  indexBaseVariableId: z.string().optional(),
  textBaseVariableId: z.string().optional(),
  timePerMessageBaseVariableId: z.string().optional(),

  allowInserts: z.boolean().optional(),
  allowDeletes: z.boolean().optional(),

  readOnly: z.boolean().optional(),
  disablePaste: z.boolean().optional(),

  metadata: z
    .array(
      z.object({
        name: z.string(),
        type: z.string(),
        baseVariableId: z.string(),
        requiredFor: z.array(z.string()).optional(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
