import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  Maybe,
  parseResponse,
  toGlobalId,
  useStepRunsUpdateAssigneeMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from 'react'

interface StepRunsUpdateAsigneeInput {
  stepRunId: string
  assigneeId: string | null
}

type OnMutateHandler = (variables: StepRunsUpdateAsigneeInput) => void

const useStepRunsUpdateAssignee = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: (data: { assignee: { id: string; name: string | null } | null }) => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: stepRunsUpdateAssignee, isLoading } = useStepRunsUpdateAssigneeMutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const { stepRunId, assigneeId } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useStepRunsUpdateAssigneeMutation via GraphQL resulted in an error: ${errorMessage}`,
        {
          stepRunId: fromGlobalId(stepRunId),
          assigneeId: fromGlobalId(assigneeId),
        }
      )
    },
    onSuccess: (data, _) => {
      const gqlResponse = parseResponse(data.stepRunsUpdateAssignee)
      if (gqlResponse?.id) {
        handleOnSuccess?.({
          assignee: {
            id: fromGlobalId(gqlResponse?.assignee?.id),
            name: gqlResponse?.assignee?.name as Maybe<string>,
          },
        })
        logger.info('Mutating from useStepRunsUpdateAssigneeMutation via GraphQL successful', {
          stepRunId: fromGlobalId(gqlResponse?.id),
          assigneeId: fromGlobalId(gqlResponse?.assignee?.id),
        })
      }
    },
    onMutate: () =>
      handleOnMutate
        ? (variables: StepRunsUpdateAsigneeInput) => {
            handleOnMutate({
              stepRunId: fromGlobalId(variables.stepRunId),
              assigneeId: fromGlobalId(variables.assigneeId),
            })
          }
        : undefined,
    onSettled,
  })

  const mutateAsync = async (data: StepRunsUpdateAsigneeInput) => {
    logger.info('Mutating from useStepRunsUpdateAssigneeMutation', { data })

    logger.info('Mutating from useStepRunsUpdateAssigneeMutation via GraphQL', {
      stepRunId: data.stepRunId,
      assigneeId: data.assigneeId,
    })
    const graphQLData = {
      stepRunId: toGlobalId('StepRunType', data.stepRunId),
      assigneeId: data.assigneeId ? toGlobalId('UserType', data.assigneeId as string) : null,
    }
    const response = await stepRunsUpdateAssignee(graphQLData)
    const gqlResponse = parseResponse(
      response.stepRunsUpdateAssignee,
      (code: number, message: string) => {
        const _error = new Error(`${code}: ${message}`)
        if (handleError) {
          handleError(_error)
        } else {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        }
      }
    )
    if (gqlResponse?.id) {
      return {
        assignee: {
          id: fromGlobalId(gqlResponse?.assignee?.id),
          name: gqlResponse?.assignee?.name,
        },
      }
    }
    return null
  }

  return { mutateAsync, isLoading }
}

export { useStepRunsUpdateAssignee }
