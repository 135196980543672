import { FC, ReactElement } from 'react'

interface IIf {
  conditional: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const If: FC<IIf> = (props) => {
  if (props.conditional && props.children) return props.children as ReactElement
  return null
}

export { If }
