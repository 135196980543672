import { useAbilityContext } from '@invisible/authorization/client'
import {
  getErrorMessage,
  toGlobalId,
  useBaseRunsCancelMutation,
} from '@invisible/concorde/gql-client'
import { useContext, useMutation } from '@invisible/trpc/client'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useGate } from 'statsig-react'

interface ICancelButtonProps {
  status: string
  baseRunId: string
  onCancelDone: () => void
}

export const CancelButton = ({ status, baseRunId, onCancelDone }: ICancelButtonProps) => {
  const { value: disableUncancelButton } = useGate('enable-disabling-of-un-cancel-button')

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const { addToast } = useToasts()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const [_, ability] = useAbilityContext()
  const canUpdateBaseRun = ability?.can('update', 'BaseRun')

  const { mutateAsync: cancelBaseRun, isLoading: isCancelBaseRunLoading } =
    useBaseRunsCancelMutation({
      onSuccess: () => {
        onCancelDone()
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryContext.invalidateQueries('baseRun.getBaseRunDetails')
      },
      onError: (error) => {
        addToast(`Cancel failed: ${getErrorMessage(error)}`, {
          appearance: 'error',
        })
      },
    })
  const { mutateAsync: unCancelBaseRun, isLoading: isUnCancelBaseRunLoading } = useMutation(
    'baseRun.uncancel',
    {
      onSuccess: () => {
        onCancelDone()
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryContext.invalidateQueries('baseRun.getBaseRunDetails')
      },
      onError: (error) => {
        addToast(`UnCancel failed: ${error?.message}`, {
          appearance: 'error',
        })
      },
    }
  )

  const handleCancelUnCancelButton = async () => {
    if (!canUpdateBaseRun) {
      return
    }
    if (status === 'disabled') {
      await unCancelBaseRun({
        baseRunId,
      })
      return
    }

    const ids = [toGlobalId('BaseRun', baseRunId)]
    await cancelBaseRun({
      ids,
    })
  }

  const isLoading = isUnCancelBaseRunLoading || isCancelBaseRunLoading

  return (
    <MUIThemeProvider>
      <Button
        variant='contained'
        onClick={() => setIsDeleteDialogOpen(true)}
        disabled={(status === 'disabled' && disableUncancelButton) || isLoading}
        sx={{ fontWeight: 'normal' }}
        color='error'>
        {status === 'disabled' ? 'Uncancel Base Run' : 'Cancel Base Run'}
      </Button>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle> {status === 'disabled' ? 'Uncancel' : 'Cancel'} Base Run</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will {status === 'disabled' ? 'uncancel' : 'cancel'} the base run. Do you
            want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            sx={{ fontWeight: 'normal' }}
            onClick={async () => {
              await handleCancelUnCancelButton()
              setIsDeleteDialogOpen(false)
            }}
            autoFocus>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </MUIThemeProvider>
  )
}
