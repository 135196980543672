import { zodResolver } from '@hookform/resolvers/zod'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { Button } from '@invisible/ui/button'
import { Checkbox } from '@invisible/ui/checkbox'
import { Divider } from '@invisible/ui/divider'
import { CopyOutlineIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Radio } from '@invisible/ui/radio'
import { useToasts } from '@invisible/ui/toasts'
import { FC, forwardRef, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ReadOnlyInterfaceNotAvailable } from '../../../interface/NotAvailable'
import { formatObject } from '../../helpers'
import type { TCustomWACProps } from '../../types'
import { FR_TYPE } from './lib/constants'
import { useOperatorSubmit } from './lib/hooks'
import { frCasesSchema } from './lib/schemas'
import { OperateFormData, PagesDone } from './lib/types'

// eslint-disable-next-line @typescript-eslint/ban-types
const FinalReviewOperateWAC: FC<TCustomWACProps> = forwardRef(
  ({ stepRun, baseRun, wizardInitialBRVs, isReadOnly }: TCustomWACProps, wizardContainerRef) => {
    const { dispatch } = useWizardState()
    const { addToast } = useToasts()
    const casesBaseRunVariables = useMemo(
      () => formatObject({ baseRun, wizardInitialBRVs }),
      [baseRun, wizardInitialBRVs]
    )
    const [currentWizardPage, setCurrentWizardPage] = useState<number>(1)
    const [dnsCheck, setDNSCheck] = useState<string>()
    const [tinCheck, setTinCheck] = useState<string>()
    const [repName, setRepName] = useState<boolean>()
    const [enterpriseBrand, setEnterpriseBrand] = useState<string>()
    const [virtualRestaurantCheck, setVirtualRestaurantCheck] = useState<string>()
    const [alcohalCheck, setAlcohalCheck] = useState<string>()
    const [externalNotes, setExternalNotes] = useState<string>()
    const [pageDone, setPageDone] = useState<PagesDone>({
      page1: undefined,
      page2: undefined,
      page3: undefined,
      page4: undefined,
      page5: undefined,
      page6: undefined,
      page7: undefined,
      page8: undefined,
    })

    const { handleOperateSubmit, isUpdateBaseRunVariablesLoading, isMarkStepRunDoneLoading } =
      useOperatorSubmit({
        stepRunId: stepRun.id,
        onFinish: () => dispatch({ type: 'closeWizard' }),
      })

    const { register, getValues, setValue } = useForm<OperateFormData>({
      mode: 'all',
      resolver: zodResolver(frCasesSchema),
      defaultValues: {
        goLiveID: casesBaseRunVariables['Go Live ID'],

        custID: casesBaseRunVariables['Cust ID'],

        accountName: casesBaseRunVariables['Account Name'],

        goLiveName: casesBaseRunVariables['Go Live Name'],

        caseUrl: casesBaseRunVariables['Case URL'],

        finalReviewType: casesBaseRunVariables['Final Review Type'],

        packageStatus: casesBaseRunVariables['Package Status'],

        virtualRestaurant: casesBaseRunVariables['Virtual Restaurant'],

        containsAlcohal: casesBaseRunVariables['Contains Alcohal'],

        externalNotes: casesBaseRunVariables['External Notes'],

        goLiveOwner: casesBaseRunVariables['Go Live Owner'],

        addressCopy: casesBaseRunVariables['Address Copy'],

        ghMenuMate: casesBaseRunVariables['GH Menu Mate'],

        ghEntryMethod: casesBaseRunVariables['GH Entry Method'],

        menuItemCount: casesBaseRunVariables['Menu Item Count'],

        queue: casesBaseRunVariables['Queue'],

        finalReviewRepName: casesBaseRunVariables['Final Review Rep Name'],

        finalReviewComplete: casesBaseRunVariables['Final Review Complete'],

        processType: casesBaseRunVariables['Process Type'],
      },
    })

    const nextPage = () => {
      switch (currentWizardPage) {
        case 2:
          if (!pageDone?.page2) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(3)
          return
        case 3:
          if (!pageDone?.page3) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(4)
          return
        case 4:
          if (!repName) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(5)
          return
        case 5:
          if (!pageDone?.page5) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(6)
          return
        case 6:
          if (!pageDone?.page6) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(7)
          return
        case 7:
          if (!pageDone?.page7) {
            addToast('Please confirm above that you have completed the above step in SalesForce', {
              appearance: 'error',
            })
            return
          }
          setCurrentWizardPage(8)
          return
        default:
          setCurrentWizardPage(currentWizardPage + 1)
      }
    }

    const prevPage = () => {
      if (currentWizardPage === 0) {
        return
      }
      setCurrentWizardPage(currentWizardPage - 1)
    }

    const submitForm = async () => {
      if ([tinCheck, dnsCheck, alcohalCheck].includes('Success')) {
        setValue('packageStatus', 'Inhouse Data Entry')
      }
      await handleOperateSubmit(casesBaseRunVariables, getValues())
    }

    const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text)
    }

    const renderCheckBox = (state: string, value?: boolean) => (
      <div className='mt-5'>
        <Checkbox checked={value} onToggle={(val) => setPageDone({ [state]: val })}>
          Im done with the above steps
        </Checkbox>
      </div>
    )

    if (isReadOnly) return <ReadOnlyInterfaceNotAvailable tooltipText={'Grubhub FR Operate WAC'} />

    return (
      <div className='flex h-full rounded border bg-white p-4 shadow'>
        <div className='block w-full space-y-1.5'>
          <div>
            <header>
              <div className='row mx-auto flex	 max-w-screen-xl flex-wrap items-center justify-between border-4 border-red-500 text-lg'>
                <h4>
                  Case: {casesBaseRunVariables['Cust ID'] + ' '}
                  Account: {casesBaseRunVariables['Account Name']}
                </h4>
                <h4>
                  <span className='mr-10'>Final Review</span>
                </h4>
              </div>
            </header>
            <Divider />
            <div className='grid grid-cols-6 gap-3'>
              <section className='col-span-2 border-0 border-r-2 border-solid border-stone-300  p-10'>
                <div className='flex flex-col'>
                  <div className='mb-5 '>
                    <div className='font-black'>Case URL</div>
                    <span>
                      <a href={casesBaseRunVariables['Case URL']} target='_blank' rel='noreferrer'>
                        {casesBaseRunVariables['Case URL']}
                      </a>
                    </span>
                  </div>
                  <div className='mb-5 '>
                    <div className='font-black'>Account Name</div>
                    <span>{casesBaseRunVariables['Account Name']}</span>
                  </div>
                  <div className='mb-5 '>
                    <div className='font-black'>Menu Item Count</div>
                    <span>{casesBaseRunVariables['Menu Item Count']}</span>
                  </div>
                  <div className='mb-5 '>
                    <div className='font-black'>Fraud Sheet</div>
                    <span>
                      <a
                        href='https://docs.google.com/spreadsheets/u/2/d/1SF2iDJmwA56OozNgB0ofbPLqLyRlfQALNLZuoSEb6PY/edit#gid=818369988'
                        target='_blank'
                        rel='noreferrer'>
                        Link
                      </a>
                    </span>
                  </div>
                  <div className='mb-5'>
                    <div className='font-black'>DNC Sheet</div>
                    <span>
                      <a
                        href='https://docs.google.com/spreadsheets/d/1WVDPalqP2oxCQN6q2B7P3ww0H0cOjkDw8q_Sne1vxkk/edit#gid=1814324438'
                        target='_blank'
                        rel='noreferrer'>
                        Link
                      </a>
                    </span>
                  </div>
                </div>
              </section>
              {currentWizardPage === 1 && (
                <div className='col-span-4'>
                  <div className='m-10'>
                    <h2>Welcome to Final Review</h2>
                    <div>
                      Final Review is a critical milestone which checks for all required information
                      before a restaurant can Go-Live on GrubHub platform.
                    </div>

                    <div className='mt-10'>
                      <div>Steps:</div>
                      <ul className='bullet-list'>
                        <li className='p-1'>Check for Fraud</li>
                        <li className='p-1'>Check for duplicates</li>
                        <li className='p-1'>Compare information against Contract Terms</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {currentWizardPage === 2 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>DNC Check</h2>
                      <div>
                        <div className='align-center  mb-5 flex'>
                          Account Name on SFDC:
                          <span className='pl-5 '>{casesBaseRunVariables['Account Name']}</span>
                          <div className='ml-1'>
                            <CopyOutlineIcon
                              onClick={(e) => handleCopy(casesBaseRunVariables['Account Name'])}
                              cursor='pointer'
                            />
                          </div>
                        </div>
                        <div className='mb-3'>
                          Please copy the Name above and search in the DNC Sheet in Name column{' '}
                          <br />
                          or <br />
                          Check for Account Name from SFDC and search in the DNC Sheet in Name
                          column
                        </div>
                        <div>
                          Did you find the Name in DNC Sheet?
                          <Radio.Group
                            name='dnsCheck'
                            selected={dnsCheck}
                            onChange={(value) => setDNSCheck(value)}
                            orientation='horizontal'>
                            <Radio label={'Yes'} value='Success' />
                            <Radio label={'No'} value='Rejected' />
                          </Radio.Group>
                        </div>
                        {dnsCheck === 'Success' ? (
                          <>
                            <div className='mt-10'>
                              <div>Please do the following:</div>
                              <ul>
                                <li className='p-1'>Add Notes - Case Flagged as DNC</li>
                                <li className='p-1'>Package Status on GFR as Inhouse Data Entry</li>
                                <li className='p-1'>Add Review Complete Date</li>
                              </ul>
                            </div>
                            {renderCheckBox('page2', pageDone?.page2)}
                          </>
                        ) : dnsCheck === 'Rejected' ? (
                          renderCheckBox('page2', pageDone?.page2)
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 3 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Fraud Check</h2>
                      <div>
                        <div className='align-center  mb-5 flex'>
                          TIN # found on SFDC:
                          <span className='pl-5'>
                            {casesBaseRunVariables['Tin Number'] || 'N/A'}
                          </span>
                          <div className='ml-1'>
                            <CopyOutlineIcon
                              onClick={(e) => handleCopy(casesBaseRunVariables['Tin Number'])}
                              cursor='pointer'
                            />
                          </div>
                        </div>
                        <div className='mb-3'>
                          Please copy the TIN# above and search in the Fraud Sheet in FEIN column
                        </div>
                        <div>
                          Did you find the TIN in Fraud Sheet?
                          <Radio.Group
                            name='tinCheck'
                            selected={tinCheck}
                            onChange={(value) => setTinCheck(value)}
                            orientation='horizontal'>
                            <Radio label={'Yes'} value='Success' />
                            <Radio label={'No'} value='Rejected' />
                          </Radio.Group>
                        </div>
                        {tinCheck === 'Success' ? (
                          <>
                            <div className='mt-10'>
                              <div>Please do the following:</div>
                              <ul>
                                <li className='p-1'>Add Notes - Case Flagged as Fraud</li>
                                <li className='p-1'>Package Status on GFR as Inhouse Data Entry</li>
                                <li className='p-1'>Add Review Complete Date</li>
                              </ul>
                            </div>
                            {renderCheckBox('page3', pageDone?.page3)}
                          </>
                        ) : tinCheck === 'Rejected' ? (
                          renderCheckBox('page3', pageDone?.page3)
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentWizardPage === 4 && (
                <div className='col-span-3'>
                  <div className='m-10'>
                    <h2>Final Review Rep Name Check</h2>
                    <div>
                      Final Review Rep Name:
                      <b>{casesBaseRunVariables['Rep Name'] || 'None'}</b>
                    </div>
                    <div className='mt-10'>
                      <div>Please do the following:</div>
                      <ul>
                        <li className='p-1'>Add your name as Rep Name from the dropdown.</li>
                        <li className='p-1'>
                          If you cant find your name, please select Daniel Rist
                        </li>
                      </ul>
                    </div>
                    <div className='mt-10'>
                      Final Review Rep Name is already filled. You dont need to do anything.
                    </div>
                    <div className='mt-5'>
                      <Checkbox onToggle={(val) => setRepName(val)}>
                        Im done with the above steps
                      </Checkbox>
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 5 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Contract Review: Enterprise Check</h2>
                      <div>
                        Is the Opportunity an Enterprise Brand?
                        <Radio.Group
                          name='enterpriseBrand'
                          selected={enterpriseBrand}
                          onChange={(value) => setEnterpriseBrand(value)}
                          orientation='horizontal'>
                          <Radio label={'Yes'} value='Success' />
                          <Radio label={'No'} value='Rejected' />
                        </Radio.Group>
                      </div>
                      {enterpriseBrand === 'Success' ? (
                        <>
                          <div className='mt-10'>
                            <div>Please do the following:</div>
                            <ol>
                              <li className='p-1'>Visit the Account Page on SalesForce</li>
                              <li className='p-1'>
                                Search for the related Enterprise Account in a new tab
                              </li>
                              <li className='p-1'>
                                Open the Enterprise Contract link to match below fields on
                                SalesForce:
                                <ul>
                                  <li className='p-1'>Restaurant Go-Live Name</li>
                                  <li className='p-1'>GHD/Non-GHD</li>
                                  <li className='p-1'>Sales Tax</li>
                                  <li className='p-1'>Marketing Commission %</li>
                                  <li className='p-1'>Delivery Commission %</li>
                                  <li className='p-1'>Order Processing Fees %</li>
                                  <li className='p-1'>Order Procesing Fee $</li>
                                </ul>
                              </li>
                              <li className='p-1'>
                                Open the Account Contract link to match below field on GFR:
                                <ul>
                                  <li className='p-1'>Activation Contact</li>
                                </ul>
                              </li>
                            </ol>
                          </div>
                          {renderCheckBox('page5', pageDone?.page5)}
                        </>
                      ) : enterpriseBrand === 'Rejected' ? (
                        <>
                          <div className='mt-10'>
                            <div>Please do the following:</div>
                            <ol>
                              <li className='p-1'>
                                Open the Contract link to match below fields on SalesForce:
                                <ul>
                                  <li className='p-1'>Restaurant Go-Live Name</li>
                                  <li className='p-1'>GHD/Non-GHD</li>
                                  <li className='p-1'>Sales Tax</li>
                                  <li className='p-1'>Marketing Commission %</li>
                                  <li className='p-1'>Delivery Commission %</li>
                                  <li className='p-1'>Order Processing Fees %</li>
                                  <li className='p-1'>Order Procesing Fee $</li>
                                </ul>
                              </li>
                              <li className='p-1'>
                                Match below field on GFR:
                                <ul>
                                  <li className='p-1'>Activation Contact</li>
                                </ul>
                              </li>
                            </ol>
                          </div>
                          {renderCheckBox('page5', pageDone?.page5)}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 6 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Virtual Restaurant Check</h2>
                      <div>
                        Is the Virtual Restaurant Checked for the Opportunity?:
                        <Radio.Group
                          name='virtualRestaurantCheck'
                          selected={virtualRestaurantCheck}
                          onChange={(value) => {
                            setVirtualRestaurantCheck(value)
                            setValue('virtualRestaurant', value === 'Success' ? true : false)
                          }}
                          orientation='horizontal'>
                          <Radio label={'Yes'} value='Success' />
                          <Radio label={'No'} value='Rejected' />
                        </Radio.Group>
                      </div>
                      {virtualRestaurantCheck === 'Success' ? (
                        <>
                          <div>
                            <div>Please do following on SalesForce:</div>
                            <ol>
                              <li className='p-1'>Add a Note that its a Virtual Restaurant</li>
                              <li className='p-1'>Make Package Status as In House Data Entry</li>
                            </ol>
                          </div>
                          <div className='mt-10'>
                            <div>Please do following on GFR:</div>
                            <ol>
                              <li className='p-1'>Set the retaurant as Just For Delivery</li>
                              <li className='p-1'>Tag the Restaurant as Virtual</li>
                            </ol>
                          </div>
                          {renderCheckBox('page6', pageDone?.page6)}
                        </>
                      ) : virtualRestaurantCheck === 'Rejected' ? (
                        renderCheckBox('page6', pageDone?.page6)
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 7 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Alcohol on Menu Check</h2>
                      <div>
                        Did you find Alcohol on GFR Menu or within Notes:
                        <Radio.Group
                          name='alcohalCheck'
                          selected={alcohalCheck}
                          onChange={(value) => {
                            setAlcohalCheck(value)
                            setValue('containsAlcohal', value === 'Success' ? true : false)
                          }}
                          orientation='horizontal'>
                          <Radio label={'Yes'} value='Success' />
                          <Radio label={'No'} value='Rejected' />
                        </Radio.Group>
                      </div>
                      {alcohalCheck === 'Success' ? (
                        <>
                          <ol>
                            <li className='p-1'>Check the Address of the restaurant</li>
                            <li className='p-1'>
                              If Address is of NY/Cook County:
                              <ul>
                                <li className='p-1'>Check For Liquor Licence attached in Notes</li>
                              </ul>
                            </li>
                            <li className='p-1'>
                              If Address is of anywhere else:
                              <ul>
                                <li className='p-1'>Check For Liquor Licence attached in Notes</li>
                                <li className='p-1'>
                                  Stripe Setup is marked as checked on Account in SFDC
                                </li>
                                <li className='p-1'>Direct Deposit on GFR is ready</li>
                              </ul>
                            </li>
                            <li className='p-1'>
                              If above conditions are not met:
                              <ul>
                                <li className='p-1'>Make Package Status as In House Data Entry</li>
                                <li className='p-1'>
                                  Add Notes to Data-Ops: Alcohol Menu - “Specific Condition” was not
                                  met
                                </li>
                              </ul>
                            </li>
                          </ol>
                          {renderCheckBox('page7', pageDone?.page7)}
                        </>
                      ) : alcohalCheck === 'Rejected' ? (
                        renderCheckBox('page7', pageDone?.page7)
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 8 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Final Submission</h2>
                      <div className='mt-10'>
                        <div className='mb-10 flex items-center space-x-2'>
                          <label className='flex w-full items-center'>
                            <span className='w-40 text-gray-900'>Final Review Type</span>
                            <select
                              {...register('finalReviewType')}
                              className='mr-2 w-[500px] rounded border-gray-300 px-2 py-1.5 focus:outline focus:outline-blue-800'>
                              <option value={undefined}></option>
                              {FR_TYPE.map((reason: string, i: number) => (
                                <option key={i} value={reason}>
                                  {reason}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>

                        <div>Add Review Complete Date</div>

                        <div className='mt-10 flex items-center justify-center'>
                          <div className='mr-2 w-40'>External Notes</div>

                          <Input
                            value={externalNotes}
                            {...register('externalNotes')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setExternalNotes(e.target.value)
                            }
                            mr={200}
                            width={500}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='flex w-full items-center justify-end space-x-2 bg-opacity-10'>
              {currentWizardPage === 1 && (
                <Button
                  variant='secondary'
                  size='md'
                  onClick={() => dispatch({ type: 'closeWizard' })}>
                  Cancel
                </Button>
              )}

              {currentWizardPage !== 1 && (
                <Button variant='secondary' size='md' onClick={() => prevPage()}>
                  Back
                </Button>
              )}
              {currentWizardPage !== 8 && (
                <Button variant='primary' size='md' onClick={() => nextPage()}>
                  Next
                </Button>
              )}
              {currentWizardPage === 8 && (
                <Button
                  variant='primary'
                  size='md'
                  onClick={() => submitForm()}
                  loading={isUpdateBaseRunVariablesLoading || isMarkStepRunDoneLoading}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

FinalReviewOperateWAC.displayName = 'FinalReviewOperateWAC'

export { FinalReviewOperateWAC }
