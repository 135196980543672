import { useQuery } from '@invisible/trpc/client'
import { map } from 'lodash'

import { useBaseRunVariablesWizardUpdate } from '../../../../hooks/useBaseRunVariablesWizardUpdate'
import { formatObject } from '../../../helpers'
import { ERRORS_BASE_ID } from './constants'
import { completeAuditStep } from './helpers'

type ItemsProps = {
  stepBaseRunId: string
  baseId: string
  searchValue?: string
  page?: number
}

export const useChildBaseRun = ({ stepBaseRunId, baseId, searchValue, page }: ItemsProps) =>
  useQuery([
    'baseRun.findChildBaseRuns',
    {
      parentBaseRunId: stepBaseRunId,
      baseId,
    },
  ])

export const useAuditSubmit = ({ stepRunId }: { stepRunId: string }) => {
  const { mutateAsync: updateBaseRunVariables, isLoading: isUpdateBaseRunVariablesLoading } =
    useBaseRunVariablesWizardUpdate()

  const handleAuditSubmit = async (init: Record<string, any>, values: any) => {
    await updateBaseRunVariables({ stepRunId, data: completeAuditStep(init, values) })
  }

  return { handleAuditSubmit, isUpdateBaseRunVariablesLoading }
}

export const useErrors = ({ stepBaseRunId }: Omit<ItemsProps, 'baseId'>) => {
  const { data, ...options } = useChildBaseRun({
    stepBaseRunId,
    baseId: ERRORS_BASE_ID,
  })
  return {
    errors: data ? map(data, (record) => formatObject({ baseRun: record })) : [],
    ...options,
  }
}
