import { SVGProps } from 'react'
function ColoredBurger(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.81237 9.5C5.71913 9.4998 5.62712 9.47874 5.54308 9.43836C5.45903 9.39799 5.38509 9.33932 5.32666 9.26666C5.26823 9.194 5.2268 9.10919 5.2054 9.01844C5.184 8.92768 5.18318 8.8333 5.203 8.74219C5.703 6.33594 8.55456 4.5 11.9999 4.5C15.4452 4.5 18.2967 6.33594 18.7967 8.74219C18.8166 8.8333 18.8157 8.92768 18.7943 9.01844C18.7729 9.10919 18.7315 9.194 18.6731 9.26666C18.6147 9.33932 18.5407 9.39799 18.4567 9.43836C18.3726 9.47874 18.2806 9.4998 18.1874 9.5H5.81237Z'
        stroke='#EEA44D'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18.25 15.125V16.375C18.25 17.038 17.9866 17.6739 17.5178 18.1428C17.0489 18.6116 16.413 18.875 15.75 18.875H8.25C7.58696 18.875 6.95107 18.6116 6.48223 18.1428C6.01339 17.6739 5.75 17.038 5.75 16.375V15.125'
        stroke='#EEA44D'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.1875 15.75L7.3125 14.5L10.4375 15.75L13.5625 14.5L16.6875 15.75L19.8125 14.5'
        stroke='#4DBC25'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.875 12H20.125'
        stroke='#5F4718'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default ColoredBurger
