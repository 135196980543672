import {
  convertSecondsToHoursMinutesSeconds,
  formatAsUTC,
  parseToNumber,
  secondsToHMS,
} from '@invisible/common/date'
import { classNames } from '@invisible/common/helpers'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { formatMillicents, formatToDollar } from '@invisible/ui/helpers'
import { Modal } from '@invisible/ui/modal'
import {
  BulkActionButton,
  createColumnHelper,
  RowSelectionState,
  Table,
  Updater,
} from '@invisible/ui/table'
import { useToasts } from '@invisible/ui/toasts'
import type { TInvoiceEventType } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { InvoiceEventMeta } from '@invisible/ultron/zod'
import axios from 'axios'
import { isEmpty } from 'lodash/fp'
import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

type InvoiceEvent =
  inferQueryOutput<'invoiceEvent.getProcessInvoiceOverview'>['summaryByCommodity'][number]['invoiceEvents'][number]

type TAction = {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}

interface DetailedInvoiceEventsProps {
  showModal: boolean
  closeModal: () => void
  invoiceEvents: InvoiceEvent[]
  canDeleteInvoiceEvent?: boolean
}

const ITEMS_PER_PAGE = 10

const columnHelper = createColumnHelper<InvoiceEvent>()

const columns = [
  columnHelper.accessor('stepName', {
    header: () => <div>Step</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('stepAssignee', {
    header: () => <div>Step Assignee</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('baseRunId', {
    header: () => <div>Base Run</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor(
    (row) => {
      const multiplier = row.timeTrackingEnabled
        ? secondsToHMS(row.multiplier / 1000)
        : row.multiplier
      if (row.timeTrackingEnabled) return multiplier

      return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
    },
    {
      id: 'multiplier',
      header: () => <div>Multiplier</div>,
      cell: (props) => (
        <Table.Cell itemsCenter>
          <MultiplierCell row={props.row.original} />
        </Table.Cell>
      ),
      meta: {
        width: '15%',
      },
      filterFn: (row, column, value: string[]) => {
        if (isEmpty(value)) return true

        const multiplier = row.original.timeTrackingEnabled
          ? secondsToHMS(row.original.multiplier / 1000)
          : row.original.multiplier

        if (row.original.timeTrackingEnabled) return value.includes(multiplier as string)

        return value.includes(`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`)
      },
    }
  ),

  columnHelper.accessor((row) => formatToDollar(row.rate), {
    id: 'rate',
    header: () => <div>Rate</div>,
    cell: (props) => {
      const row = props.row.original

      return (
        <Table.Cell itemsCenter>
          <div className='px-3'>{`${props.getValue()}${
            row.timeTrackingEnabled ? ' / Hour' : ' / Unit'
          }`}</div>
        </Table.Cell>
      )
    },
    meta: {
      width: '5%',
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(formatToDollar(row.original.rate))
    },
  }),

  columnHelper.accessor((row) => formatMillicents(row.amount), {
    id: 'amount',
    header: () => <div>Amount</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
    meta: {
      width: '5%',
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(formatMillicents(row.original.amount))
    },
  }),

  columnHelper.accessor('createdAt', {
    header: () => <div>Created At</div>,
    cell: (props) => (
      <Table.Cell itemsCenter>
        <div className='px-3'>{formatAsUTC(props.getValue(), 'yyyy-MM-dd')}</div>
      </Table.Cell>
    ),
  }),
]

// eslint-disable-next-line @typescript-eslint/ban-types
const DetailedInvoiceEvents: FC<DetailedInvoiceEventsProps> = ({
  showModal,
  closeModal,
  invoiceEvents,
  canDeleteInvoiceEvent,
}) => {
  const { addToast } = useToasts()

  const reactQueryClient = useContext()

  const { mutateAsync: deleteManyManualInvoiceEvents } = useMutation(
    'invoiceEvent.deleteManyManual',
    {
      onMutate: () => {
        addToast('Deleting Manual Invoice Events', {
          appearance: 'info',
          loading: true,
          loadingTime: 1200,
          lifeSpan: 1200,
        })
      },

      onSuccess: () => {
        addToast('Successfully deleted invoice event(s)', {
          appearance: 'success',
        })
      },

      onError: (error) => {
        addToast(`Deletion failed: ${error?.message}`, {
          appearance: 'error',
        })
      },

      onSettled: () => {
        reactQueryClient.invalidateQueries('invoiceEvent.getProcessInvoiceOverview')
      },
    }
  )

  const DeleteManualBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) => {
        const selectedInvoiceEvents = invoiceEvents?.filter((_, key) => rowSelection[key])

        // Don't show delete bulk action if all selected rows are not manual events
        if (
          !isEmpty(selectedInvoiceEvents) &&
          !selectedInvoiceEvents.every(({ type }) => (type as TInvoiceEventType) === 'manual')
        )
          return null

        return (
          <BulkActionButton
            title='Delete'
            text='Delete'
            icon='DeleteOutlineIcon'
            onClick={async () => {
              const selectedManualInvoiceEvents = invoiceEvents
                ?.filter((_, key) => rowSelection[key])
                ?.filter((event) => (event.type as TInvoiceEventType) === 'manual')
              const selectedInvoiceEventIds = selectedManualInvoiceEvents?.map((event) => event.id)
              await deleteManyManualInvoiceEvents({ invoiceEventIds: selectedInvoiceEventIds })
              setRowSelection({})
            }}
          />
        )
      },
    [invoiceEvents]
  )

  const MoveToPreviousCycleBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Request to Move to Previous Cycle'
            icon='HistoryIcon'
            onClick={async () => {
              const selectedInvoiceEvents = invoiceEvents?.filter((_, key) => rowSelection[key])
              const selectedInvoiceEventIds = selectedInvoiceEvents?.map((event) => event.id)
              await axios
                .post('/api/redis/change-invoice-request', {
                  selectedInvoiceEventIds,
                })
                .then((res) => {
                  if (res.status === 200) {
                    addToast('Invoice change request submitted', {
                      appearance: 'success',
                    })
                  } else {
                    addToast('Failed to submit invoice change request', {
                      appearance: 'error',
                    })
                  }
                })
                .catch(() => {
                  addToast('Failed to submit invoice change request', {
                    appearance: 'error',
                  })
                })
              setRowSelection({})
            }}
          />
        ),
    [invoiceEvents]
  )

  const BulkActions = useMemo(
    () => (props: TAction) =>
      (
        <>
          <MoveToPreviousCycleBulkAction {...props} />
          <DeleteManualBulkAction {...props} />
        </>
      ),
    [DeleteManualBulkAction, MoveToPreviousCycleBulkAction]
  )

  return (
    <Modal
      onClose={closeModal}
      visible={showModal}
      title='Detailed Invoice Events'
      icon='InfoFilledIcon'
      className='my-1 max-h-[100vh] overflow-y-auto'>
      <div className='w-full'>
        <Table.PaginationProvider>
          <div className='flex w-full'>
            <Table
              data={invoiceEvents}
              columns={columns}
              defaultPageSize={ITEMS_PER_PAGE}
              {...(canDeleteInvoiceEvent ? { BulkSelectActions: BulkActions } : {})}
            />
          </div>

          <div className='my-4 flex items-center justify-end'>
            <Table.Pagination />
          </div>
        </Table.PaginationProvider>
      </div>
    </Modal>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MultiplierCell: FC<{ row: InvoiceEvent }> = ({ row }) => {
  const [edit, setEdit] = useState(false)
  const [multiplierValue, setMultiplierValue] = useState(row.multiplier)

  const multiplier = row.timeTrackingEnabled
    ? secondsToHMS(multiplierValue / 1000)
    : multiplierValue

  if (edit)
    return (
      <EditTimeBasedInvoiceEventInput
        id={row.id}
        multiplier={row.multiplier / 1000}
        setEdit={setEdit}
        setMultiplierValue={setMultiplierValue}
      />
    )

  return (
    <div className='flex items-center gap-1 px-3'>
      <div
        className={classNames(
          'flex min-w-0 max-w-fit rounded-md border border-solid py-1 px-2',
          row.timeTrackingEnabled
            ? 'border-orange-400 bg-orange-100'
            : 'border-theme-strong bg-theme-weak-3'
        )}>
        {row.timeTrackingEnabled ? (
          <div className='flex items-center gap-1 text-orange-400'>
            <span>{multiplier}</span>
          </div>
        ) : (
          <div className='text-theme-strong flex items-center gap-1'>
            <span>{multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}</span>
          </div>
        )}
      </div>
      {row.timeTrackingEnabled && row.invoiceStatus !== 'finalized' ? (
        <Button
          variant='subtle'
          size='md'
          shape='square'
          icon='EditOutlineIcon'
          color='theme'
          title='Edit Multiplier'
          onClick={() => setEdit(true)}
        />
      ) : null}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const EditTimeBasedInvoiceEventInput: FC<{
  id: string
  multiplier: number
  setEdit: Dispatch<SetStateAction<boolean>>
  setMultiplierValue: Dispatch<SetStateAction<number>>
}> = ({ id, multiplier, setEdit, setMultiplierValue }) => {
  const { addToast } = useToasts()

  const reactQueryClient = useContext()

  const [hours, setHours] = useState(
    convertSecondsToHoursMinutesSeconds(multiplier).hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
  )
  const [minutes, setMinutes] = useState(
    convertSecondsToHoursMinutesSeconds(multiplier).minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
  )
  const [seconds, setSeconds] = useState(
    convertSecondsToHoursMinutesSeconds(multiplier).seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
  )

  const [divFocused, setDivFocused] = useState(false)

  const divRef = useRef<HTMLDivElement>(null)

  const { mutateAsync: updateTimeBasedInvoiceEvent } = useMutation(
    'invoiceEvent.updateTimeBasedInvoiceEvent',
    {
      onSuccess: (data) => {
        addToast('Successfully edited time based invoice event', {
          appearance: 'success',
        })
        setMultiplierValue((data.meta as InvoiceEventMeta.TSchema)?.multiplier)
      },

      onError: (error) => {
        addToast(`Update failed: ${error?.message}`, {
          appearance: 'error',
        })
      },

      onSettled: () => {
        reactQueryClient.invalidateQueries('invoiceEvent.getProcessInvoiceOverview')
      },
    }
  )

  const getDurationInSeconds = () => Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    setValue: Dispatch<SetStateAction<string>>,
    type: 'hour' | 'minute' | 'second'
  ) => {
    const inputValue = event.target.value
    let numericValue = parseToNumber(inputValue)

    if (isNaN(numericValue) || numericValue < 0) {
      numericValue = 0 // Default to 0 if parsing fails
    }

    if (type === 'hour') setValue(Math.max(numericValue, 0).toString())
    if (type === 'minute') setValue(Math.min(Math.max(numericValue, 0), 59).toString())
    if (type === 'second') setValue(Math.min(Math.max(numericValue, 0), 59).toString())
  }

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Enter key pressed
      await updateTimeBasedInvoiceEvent({
        id,
        multiplier: getDurationInSeconds(),
      })
      setEdit(false)
    }
  }

  useEffect(() => {
    if (divRef.current) {
      const currentDiv = divRef.current
      currentDiv.addEventListener('focusin', () => setDivFocused(true))
      currentDiv.addEventListener('focusout', () => setDivFocused(false))

      return () => {
        currentDiv.removeEventListener('focusin', () => setDivFocused(false))
        currentDiv.removeEventListener('focusout', () => setDivFocused(false))
      }
    }
  }, [])

  return (
    <div className='space-y-2'>
      <div
        ref={divRef}
        className='focus-within:ring-theme-weak flex min-w-0 max-w-fit items-center justify-center gap-0.5 rounded border border-solid border-gray-300 bg-white focus-within:shadow-lg focus-within:ring-1'>
        <input
          className='w-4 appearance-none border-none bg-transparent px-2 py-1 text-center focus:outline-0'
          type='text'
          value={hours}
          onChange={(e) => handleInputChange(e, setHours, 'hour')}
          onKeyDown={handleKeyDown}
        />
        <span>:</span>
        <input
          className='w-4 appearance-none border-none bg-transparent px-2 py-1 text-center focus:outline-0'
          type='text'
          value={minutes}
          onChange={(e) => handleInputChange(e, setMinutes, 'minute')}
          onKeyDown={handleKeyDown}
        />
        <span>:</span>
        <input
          className='w-4 appearance-none border-none bg-transparent px-2 py-1 text-center focus:outline-0'
          type='text'
          value={seconds}
          onChange={(e) => handleInputChange(e, setSeconds, 'second')}
          onKeyDown={handleKeyDown}
        />
      </div>
      {divFocused ? (
        <div className='flex items-center justify-center gap-1 text-sm text-gray-400'>
          <span>press enter &#9166;</span>
        </div>
      ) : null}
    </div>
  )
}

export { DetailedInvoiceEvents }
