import { SVGProps } from 'react'
function DownloadOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.72011 12.7345C9.24081 12.2138 10.085 12.2138 10.6057 12.7345L16.3296 18.4584L22.0534 12.7345C22.5741 12.2138 23.4184 12.2138 23.9391 12.7345C24.4598 13.2552 24.4598 14.0994 23.9391 14.6201L17.2724 21.2868C16.7517 21.8075 15.9075 21.8075 15.3868 21.2868L8.72011 14.6201C8.19942 14.0994 8.19942 13.2552 8.72011 12.7345Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3296 0.343994C17.066 0.343994 17.6629 0.940948 17.6629 1.67733V20.344C17.6629 21.0804 17.066 21.6773 16.3296 21.6773C15.5932 21.6773 14.9963 21.0804 14.9963 20.344V1.67733C14.9963 0.940948 15.5932 0.343994 16.3296 0.343994Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.66292 19.0107C2.3993 19.0107 2.99626 19.6076 2.99626 20.344V29.6773H29.6629V20.344C29.6629 19.6076 30.2599 19.0107 30.9963 19.0107C31.7326 19.0107 32.3296 19.6076 32.3296 20.344V29.6773C32.3296 30.3846 32.0486 31.0628 31.5485 31.5629C31.0484 32.063 30.3702 32.344 29.6629 32.344H2.99626C2.28901 32.344 1.61074 32.063 1.11064 31.5629C0.610542 31.0629 0.32959 30.3846 0.32959 29.6773V20.344C0.32959 19.6076 0.926543 19.0107 1.66292 19.0107Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DownloadOutline
