import { SVGProps } from 'react'
function ThumbsUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.27612 16.4739C1.29284 16.4906 1.31552 16.5 1.33917 16.5H4.9992V8.46578H1.33917C1.31552 8.46578 1.29284 8.47517 1.27612 8.49189C1.2594 8.50862 1.25 8.5313 1.25 8.55495V16.4108C1.25 16.4345 1.25939 16.4572 1.27612 16.4739ZM1.33917 17.75C0.984 17.75 0.643377 17.6089 0.392234 17.3578C0.141091 17.1066 0 16.766 0 16.4108V8.55495C0 8.19978 0.14109 7.85915 0.392234 7.60801C0.643378 7.35687 0.984002 7.21578 1.33917 7.21578H5.23795L8.63606 0.419559C8.74193 0.207819 8.95834 0.0740674 9.19508 0.0740674C10.1185 0.0740674 11.0041 0.440887 11.657 1.09383C12.3099 1.74677 12.6768 2.63235 12.6768 3.55575V5.07326H17.7653C18.0566 5.07335 18.3445 5.13539 18.6099 5.25529C18.8754 5.37519 19.1123 5.55018 19.3049 5.76866C19.4976 5.98714 19.6415 6.2441 19.7272 6.52248C19.8129 6.80086 19.8384 7.09429 19.802 7.38327L18.7307 15.9533C18.6682 16.4494 18.4268 16.9057 18.0518 17.2365C17.6769 17.5673 17.1939 17.7499 16.6939 17.75H5.62422C5.62421 17.75 5.62423 17.75 5.62422 17.75H1.33917ZM6.2492 16.5V7.98837L9.56584 1.35507C10.0202 1.43162 10.443 1.64763 10.7731 1.97771C11.1916 2.39623 11.4268 2.96387 11.4268 3.55575V5.69826C11.4268 6.04344 11.7066 6.32326 12.0518 6.32326H17.765C17.8788 6.32332 17.9916 6.3476 18.0954 6.39448C18.1993 6.44139 18.2919 6.50985 18.3673 6.59533C18.4427 6.68081 18.499 6.78134 18.5325 6.89025C18.566 6.99916 18.576 7.11396 18.5618 7.22702L17.4905 15.7971C17.4661 15.9912 17.3716 16.1697 17.2249 16.2991C17.0782 16.4285 16.8894 16.4999 16.6938 16.5H6.2492Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ThumbsUp
