import { withErrorBoundary } from '@invisible/common/components/error-boundary'
import { generateTargetLayer } from '@invisible/common/components/reports'
import { getMaxValue, getMinValue } from '@invisible/common/helpers'
import { Line, LineDatum } from '@invisible/common/types'
import { TableTooltip } from '@invisible/ui/chart-tooltip'
import { Box, Theme } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import { isEmpty } from 'lodash/fp'
import { useMemo } from 'react'

import { uiLineChartData } from './ui-line-chart-data.js'
import { LineChartSkeleton } from './ui-line-chart-skeleton'

interface ILineChart {
  width?: number
  height: string
  animate?: boolean
  data?: Line[]
  targetLines?: Line[]
  theme?: Theme
  isLoading?: boolean
  enableGridX?: boolean
  enableGridY?: boolean
  axisBottom?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  axisLeft?: {
    tickSize?: number
    tickPadding?: number
    tickRotation?: number
    legend?: string
    legendPosition?: 'middle' | undefined
    legendOffset?: number
    format?: (value: number) => string
  }
  margin?: Box
  maxValue?: number
}

export const LineChart = withErrorBoundary(
  ({
    width,
    height,
    data,
    animate,
    theme,
    enableGridX,
    enableGridY,
    axisLeft,
    margin,
    axisBottom,
    isLoading,
    targetLines,
    maxValue,
  }: ILineChart) => {
    const Target = targetLines ? [generateTargetLayer(targetLines)] : []

    // The Line Chart does not automatically update its max value and min value along Y-Axis according to the Target Lines
    // rendered by Target layer. This results in Target Lines being overflowed out of the SVG. This Object stores minumum
    // and the maximum value in all of the Target Lines which is then passed to nivo/line component to adjust its max and
    // min value
    const TargetMinMaxValues = useMemo(() => {
      if (!targetLines || isEmpty(targetLines)) return
      const targetYValues = targetLines.flatMap((line) => line.data.map((point) => point.y))
      return { max: Math.max(...targetYValues), min: Math.min(...targetYValues) }
    }, [targetLines])

    if (isLoading || isEmpty(data))
      return (
        <div className='my-4 flex items-center justify-center'>
          <LineChartSkeleton animate={!!isLoading} />
        </div>
      )
    return (
      <div style={{ width: (width && `${width}px`) ?? '100%', height: height }}>
        <ResponsiveLine
          data={data || uiLineChartData}
          margin={margin ?? { top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min:
              getMinValue(
                data
                  ?.reduce((acc, curr) => [...acc, ...curr.data], [] as LineDatum[])
                  ?.reduce((final, curr) => (curr.y < final ? curr.y : final), data[0]?.data[0]?.y),
                TargetMinMaxValues?.min
              ) ?? 'auto',
            max: getMaxValue(maxValue, TargetMinMaxValues?.max),
            stacked: false,
            reverse: false,
          }}
          enableGridX={enableGridX ?? false}
          enableGridY={enableGridY ?? false}
          theme={theme}
          axisTop={null}
          axisRight={null}
          animate={animate || true}
          colors={(line) => line.color}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'points',
            'slices',
            'mesh',
            ...Target,
          ]}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          tooltip={({ point }) => {
            const label = point.id.split('.')[0]
            const value = point.data.yFormatted
            return (
              <TableTooltip
                headers={['Label', 'Value']}
                dataRows={[[label, value]]}
                color={point.borderColor}
              />
            )
          }}
          axisLeft={
            axisLeft ?? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle',
            }
          }
          axisBottom={
            axisBottom ?? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'transportation',
              legendOffset: 36,
              legendPosition: 'middle',
            }
          }
        />
      </div>
    )
  }
)
export default LineChart
