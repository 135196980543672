import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAbilityContext } from '@invisible/authorization/client'
import { getPaymentCycles, secondsToDuration, secondsToHMS } from '@invisible/common/date'
import { classNames, downloadCsvData } from '@invisible/common/helpers'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@invisible/ui/dropdown'
import { formatMillicents, formatToDollar } from '@invisible/ui/helpers'
import { Skeleton } from '@invisible/ui/skeleton'
import {
  BulkActionButton,
  createColumnHelper,
  RowSelectionState,
  Table,
  Updater,
} from '@invisible/ui/table'
import { Tag } from '@invisible/ui/tag'
import { useToasts } from '@invisible/ui/toasts'
import type { TBillEventType } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { TUuid } from '@invisible/zod'
import axios from 'axios'
import { compact, filter, flatten, flow, isEmpty, map, sumBy, truncate } from 'lodash/fp'
import { unparse } from 'papaparse'
import { useMemo, useState } from 'react'

import { MenuItem } from './baseViewComponents/MenuItem'
import { DetailedBillEvents } from './financeViewComponents/DetailedBillEvents'
import { ManualBillEventModal } from './financeViewComponents/ManualBillEventModal'

type TBillEventOverview = NonNullable<
  inferQueryOutput<'billEvent.getCycleBreakdownForProcess'>
>[number]

type TBillEvent = TBillEventOverview['billEvents'][number]

type TAction = {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}

const ITEMS_PER_PAGE = 10

type TBillEventStatus = 'Not Approved' | 'Pre-Approved' | 'Drafted'

export const formatBillEventStatus = (billEventsStatus: TBillEventStatus[]) => {
  const draftedEvents = billEventsStatus.filter((status) => status === 'Drafted')
  if (draftedEvents.length === billEventsStatus.length) return 'Drafted'
  if (draftedEvents.length > 0) return 'Partially Drafted'

  const approvedEvents = billEventsStatus.filter((status) => status === 'Pre-Approved')
  if (approvedEvents.length === billEventsStatus.length) return 'Pre-Approved'
  if (approvedEvents.length > 0) return 'Partially Pre-Approved'

  return 'Not Approved'
}

const columnHelper = createColumnHelper<
  TBillEventOverview & {
    onRowClick: (row: TBillEventOverview) => void
    handlePaymentRowCSVDownload: (row: TBillEventOverview) => void
  }
>()

const columns = [
  columnHelper.accessor('userName', {
    header: () => <div>Agent</div>,
    cell: (props) => {
      const row = props.row.original
      return (
        <Table.Cell itemsCenter>
          <div className='space-y-1'>
            <span
              className='text-theme-main cursor-pointer hover:underline'
              onClick={() => row.onRowClick(row)}>
              {props.getValue()}
            </span>
            {(row.type as TBillEventType) === 'manual' ? <Tag color='pink'>Manual</Tag> : null}
          </div>
        </Table.Cell>
      )
    },
  }),

  columnHelper.accessor('commodityName', {
    header: () => <div>Commodity</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('stepName', {
    header: () => <div>Step</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('baseName', {
    header: () => <div>Base</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('totalRuns', {
    header: () => <div>Runs</div>,
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(String(row.original.totalRuns))
    },
  }),

  columnHelper.accessor(
    (row) => {
      const multiplier = row.timeTrackingEnabled ? secondsToHMS(row.quantity) : row.quantity
      if (row.timeTrackingEnabled) return multiplier

      return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
    },
    {
      id: 'quantity',
      header: () => <div>Quantity</div>,
      cell: (props) => {
        const row = props.row.original
        const multiplier = row.timeTrackingEnabled ? secondsToHMS(row.quantity) : row.quantity

        return (
          <Table.Cell itemsCenter>
            <div className='px-3'>
              <div
                className={classNames(
                  'flex min-w-0 max-w-fit rounded-md border border-solid py-1 px-2',
                  row.timeTrackingEnabled
                    ? 'border-orange-400 bg-orange-100'
                    : 'border-theme-strong bg-theme-weak-3'
                )}>
                {row.timeTrackingEnabled ? (
                  <div className='flex items-center gap-1 text-orange-400'>
                    <span>{multiplier}</span>
                  </div>
                ) : (
                  <div className='text-theme-strong flex items-center gap-1'>
                    <span>{multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}</span>
                  </div>
                )}
              </div>
            </div>
          </Table.Cell>
        )
      },
      filterFn: (row, column, value: string[]) => {
        if (isEmpty(value)) return true

        const multiplier = row.original.timeTrackingEnabled
          ? secondsToHMS(row.original.quantity)
          : row.original.quantity

        if (row.original.timeTrackingEnabled) return value.includes(multiplier as string)

        return value.includes(`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`)
      },
    }
  ),

  columnHelper.accessor((row) => formatToDollar(row.rate), {
    id: 'rate',
    header: () => <div>Rate</div>,
    cell: (props) => {
      const row = props.row.original

      return (
        <Table.Cell itemsCenter>
          <div className='px-3'>{`${props.getValue()}${
            row.timeTrackingEnabled ? ' / Hour' : ' / Unit'
          }`}</div>
        </Table.Cell>
      )
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(formatToDollar(row.original.rate))
    },
  }),

  columnHelper.accessor((row) => row.status, {
    id: 'totalAmount',
    header: () => <div>Total</div>,
    cell: (props) => {
      const row = props.row.original
      const status = formatBillEventStatus(row.billEvents.map((be) => be.status))
      const partiallyPreApprovedBillEventAmount = flow(
        filter((billEvent: TBillEvent) => billEvent.status === 'Pre-Approved'),
        sumBy('amount')
      )(row.billEvents)

      return (
        <Table.Cell itemsCenter>
          <div className='space-y-2'>
            <div>{formatMillicents(row.totalAmount)}</div>
            <div className='flex items-center gap-2'>
              <div
                className={classNames(
                  'flex h-2 w-2 rounded-full',
                  status === 'Drafted'
                    ? 'bg-theme-main'
                    : status === 'Pre-Approved'
                    ? 'bg-sky-main'
                    : status === 'Partially Pre-Approved'
                    ? 'bg-energy-main'
                    : status === 'Partially Drafted'
                    ? 'bg-green-main'
                    : 'bg-red-main'
                )}
              />
              {partiallyPreApprovedBillEventAmount && status === 'Not Approved' ? (
                <span className='text-green-main inline-flex'>
                  {formatMillicents(partiallyPreApprovedBillEventAmount)}
                </span>
              ) : null}
              {status}
            </div>
          </div>
        </Table.Cell>
      )
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(row.original.status)
    },
  }),

  columnHelper.display({
    id: 'download',
    cell: (props) => {
      const row = props.row.original
      return (
        <Table.Cell itemsCenter>
          <Button
            variant='subtle'
            size='md'
            shape='square'
            icon='DownloadFilledIcon'
            color='theme'
            title='Download CSV'
            onClick={() => row.handlePaymentRowCSVDownload(row)}
          />
        </Table.Cell>
      )
    },
    meta: {
      width: '5%',
    },
  }),
]

export const CostPage = ({ processId }: { processId: TUuid }) => {
  const [, ability] = useAbilityContext()

  const reactQueryClient = useContext()

  const canUpdateBillEvent = ability?.can('update', 'BillEvent')

  const canDeleteBillEvent = ability?.can('delete', 'BillEvent')

  const { addToast } = useToasts()

  const [selectedCycleKey, setSelectedCycleKey] = useState('0')

  const selectedCycle = getPaymentCycles()[Number(selectedCycleKey)]

  const startDate = selectedCycle.start.toDate()

  const endDate = selectedCycle.end.toDate()

  const [showDetailedBillEventsModal, setShowDetailedBillEventsModal] = useState(false)

  const [detailedBillEvents, setDetailedBillEvents] = useState<TBillEvent[]>()

  const [showManualBillEventModal, setShowManualBillEventModal] = useState(false)

  const { data: bills, isLoading: isLoadingBills } = useQuery(
    ['bill.findByProcess', { processId }],
    { refetchOnMount: false, refetchOnWindowFocus: false }
  )

  const {
    data: billEvents,
    isLoading,
    refetch: refetchBillEvents,
  } = useQuery([
    'billEvent.getCycleBreakdownForProcess',
    {
      startDate,
      endDate,
      processId,
    },
  ])

  const { data: processDLUR, isLoading: isFetchingDLUR } = useQuery(
    [
      'process.getProcessDLUR',
      {
        processId,
        startDate,
        endDate,
      },
    ],
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { mutateAsync: approveBillEvents } = useMutation('billEvent.approveMany', {
    onMutate: () => {
      addToast('Approving Bill events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: async (data) => {
      addToast(
        `Approved bill event(s): ${compact(data)
          .map(({ id }) => id)
          .join(', ')}`,
        {
          appearance: 'success',
        }
      )
    },

    onError: (error) => {
      addToast(`Approval failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const { mutateAsync: unapproveBillEvents } = useMutation('billEvent.unapproveMany', {
    onMutate: () => {
      addToast('Unapproving Bill events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: async (data) => {
      addToast(
        `Unapproved bill event(s): ${compact(data)
          .map(({ id }) => id)
          .join(', ')}`,
        {
          appearance: 'success',
        }
      )
    },

    onError: (error) => {
      addToast(`Unapproval failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const { mutateAsync: deleteManyManualBillEvents } = useMutation('billEvent.deleteManyManual', {
    onMutate: () => {
      addToast('Deleting Manual Bill Events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: () => {
      addToast('Successfully deleted bill event(s)', {
        appearance: 'success',
      })
    },

    onError: (error) => {
      addToast(`Deletion failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const totalAmount = useMemo<number>(
    () => billEvents?.reduce((acc: number, { totalAmount }) => acc + totalAmount, 0) ?? 0,
    [billEvents]
  )

  const onRowClick = (row: TBillEventOverview) => {
    setDetailedBillEvents(row.billEvents)
    setShowDetailedBillEventsModal(true)
  }

  const closeDetailedBillEventsModal = () => {
    setDetailedBillEvents(undefined)
    setShowDetailedBillEventsModal(false)
  }

  const UnapproveBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Unapprove'
            icon='DashCircleOutlineIcon'
            onClick={async () => {
              const selectedBillEventOverviews = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = flow(
                map((row: TBillEventOverview) => row.billEvents),
                flatten,
                map((row) => row.id)
              )(selectedBillEventOverviews)
              await unapproveBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const ApproveBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Approve'
            icon='CheckCircleOutlineIcon'
            onClick={async () => {
              const selectedBillEventOverviews = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = flow(
                map((row: TBillEventOverview) => row.billEvents),
                flatten,
                map((row) => row.id)
              )(selectedBillEventOverviews)
              await approveBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const MoveToPreviousCycleBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Request to Move to Previous Cycle'
            icon='HistoryIcon'
            onClick={async () => {
              const selectedBillEventOverviews = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = flow(
                map((row: TBillEventOverview) => row.billEvents),
                flatten,
                map((row) => row.id)
              )(selectedBillEventOverviews)
              await axios
                .post('/api/redis/change-bill-request', {
                  selectedBillEventIds,
                })
                .then((res) => {
                  if (res.status === 200) {
                    addToast('Bill change request submitted', {
                      appearance: 'success',
                    })
                  } else {
                    addToast('Failed to submit bill change request', {
                      appearance: 'error',
                    })
                  }
                })
                .catch(() => {
                  addToast('Failed to submit bill change request', {
                    appearance: 'error',
                  })
                })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const DeleteManualBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) => {
        const selectedBillEventOverviews = billEvents?.filter((_, key) => rowSelection[key])

        // Don't show delete bulk action if all selected rows are not manual events
        if (
          !isEmpty(selectedBillEventOverviews) &&
          !selectedBillEventOverviews?.every(({ type }) => (type as TBillEventType) === 'manual')
        )
          return null

        return (
          <BulkActionButton
            title='Delete'
            icon='DeleteOutlineIcon'
            onClick={async () => {
              const selectedManualBillEventOverviews = billEvents
                ?.filter((_, key) => rowSelection[key])
                ?.filter((overview) => (overview.type as TBillEventType) === 'manual')
              const selectedBillEventIds = flow(
                map((row: TBillEventOverview) => row.billEvents),
                flatten,
                map((row) => row.id)
              )(selectedManualBillEventOverviews)
              await deleteManyManualBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        )
      },
    [billEvents]
  )

  const BulkActions = useMemo(
    () => (props: TAction) =>
      (
        <>
          <ApproveBulkAction {...props} />
          <UnapproveBulkAction {...props} />
          <MoveToPreviousCycleBulkAction {...props} />
          {canDeleteBillEvent ? <DeleteManualBulkAction {...props} /> : null}
        </>
      ),
    [
      ApproveBulkAction,
      UnapproveBulkAction,
      DeleteManualBulkAction,
      MoveToPreviousCycleBulkAction,
      canDeleteBillEvent,
    ]
  )

  const handlePaymentSummaryCSVDownload = () => {
    const headers = ['Agent', 'Commodity', 'Step', 'Base', 'Runs', 'Quantity', 'Rate', 'Total']
    const billEventsData = compact(
      billEvents?.map((billEvent) => ({
        agent: billEvent.userName,
        commodityName: billEvent.commodityName,
        stepName: billEvent.stepName,
        baseName: billEvent.baseName,
        runs: `${billEvent.totalRuns}`,
        quantity: billEvent.timeTrackingEnabled
          ? secondsToDuration(billEvent.quantity)
          : `${billEvent.quantity}`,
        rate: `${formatToDollar(billEvent.rate)}${
          billEvent.timeTrackingEnabled ? ' / Hour' : ' / Unit'
        }`,
        total: `${formatMillicents(billEvent.totalAmount)} (${billEvent.status})`,
      }))
    )

    const csvData = unparse({
      fields: headers,
      data: billEventsData.map((row) => Object.values(row)),
    })

    downloadCsvData(csvData, `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`)
  }

  const handlePaymentRowCSVDownload = (row: TBillEventOverview) => {
    const headers = ['Agent', 'Step', 'Base Run', 'Multiplier', 'Rate', 'Amount', 'Created At']
    const billEventsData = compact(
      row.billEvents?.map((billEvent) => ({
        agent: billEvent.userName,
        stepName: billEvent.stepName,
        baseRun: billEvent.baseRunId,
        multiplier: billEvent.timeTrackingEnabled
          ? secondsToDuration(billEvent.multiplier)
          : `${billEvent.multiplier}`,
        rate: `${formatToDollar(billEvent.rate)}${
          billEvent.timeTrackingEnabled ? ' / Hour' : ' / Unit'
        }`,
        amount: `${formatMillicents(billEvent.amount)} (${billEvent.status})`,
        createdAt: billEvent.createdAt.toISOString(),
      }))
    )

    const csvData = unparse({
      fields: headers,
      data: billEventsData.map((row) => Object.values(row)),
    })

    downloadCsvData(
      csvData,
      `${row.userName}-${
        row.commodityName
      } (${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()})`
    )
  }

  return (
    <div className='space-y-5 p-4'>
      {!isLoadingBills ? (
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-3'>
            <div className='text-lg font-bold'>Cost</div>
            {startDate && (
              <span className='text-theme-main'>{`total: ${formatMillicents(totalAmount)}`}</span>
            )}
          </div>

          <div className='flex items-center gap-2'>
            {isFetchingDLUR ? (
              <Skeleton.Rectangle height={10} width={100} />
            ) : (
              <>
                <div className='flex items-center gap-1'>
                  <div
                    className={classNames(
                      'flex items-center gap-0.5',
                      processDLUR && processDLUR.DLUR < 30
                        ? 'text-green-main'
                        : processDLUR && processDLUR.DLUR > 30
                        ? 'text-red-main'
                        : 'text-header'
                    )}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size='sm'
                      transform={{ rotate: processDLUR && processDLUR.DLUR > 30 ? -45 : 45 }}
                    />
                    <span className='inline-flex'>
                      {typeof processDLUR?.DLUR === 'number' && !Number.isNaN(processDLUR?.DLUR)
                        ? processDLUR?.DLUR === Infinity
                          ? `No UR`
                          : `${processDLUR?.DLUR.toFixed(2)}%`
                        : `${(0).toFixed(2)}%`}
                    </span>
                  </div>
                  <span className='text-muted inline-flex uppercase'>DL/UR CB</span>
                </div>
                <div className='flex items-center gap-1'>
                  <span className='inline-flex'>{processDLUR?.activeUsers ?? 0}</span>
                  <span className='text-muted inline-flex capitalize'>Active Agents</span>
                </div>
              </>
            )}
          </div>

          <div className='flex items-center gap-1'>
            <Dropdown
              width='350px'
              name='Bills'
              options={getPaymentCycles().map((cycleRange, index) => ({
                key: `${index}`,
                value: truncate(
                  { length: 45 },
                  `${cycleRange.start.format('MMMM Do, YYYY')} - ${cycleRange.end.format(
                    'MMMM Do, YYYY'
                  )}`
                ),
              }))}
              onChange={({ key }) => {
                setSelectedCycleKey(key)
                refetchBillEvents()
              }}
              selectedKey={selectedCycleKey}
            />

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div>
                  <Button variant='subtle' size='md' icon='HamburgerIcon' shape='square' />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent alignment='end'>
                <DropdownMenuItem
                  key='Add Cost'
                  internalKey='Add Cost'
                  onSelect={() => setShowManualBillEventModal(true)}>
                  <MenuItem label='Add Cost' icon='BillingIcon' hoveredIcon='BillingIcon' />
                </DropdownMenuItem>
                <DropdownMenuItem
                  key='Download CSV'
                  internalKey='Download CSV'
                  onSelect={handlePaymentSummaryCSVDownload}>
                  <MenuItem
                    label='Download CSV'
                    icon='DownloadOutlineIcon'
                    hoveredIcon='DownloadFilledIcon'
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      ) : null}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Table.PaginationProvider>
            <div className='w-full'>
              <Table
                defaultPageSize={ITEMS_PER_PAGE}
                data={
                  billEvents
                    ? billEvents.map((billEvent) => ({
                        ...billEvent,
                        onRowClick,
                        handlePaymentRowCSVDownload,
                      }))
                    : []
                }
                columns={columns}
                {...(canUpdateBillEvent ? { BulkSelectActions: BulkActions } : {})}
              />
            </div>

            <div className='my-4 flex items-center justify-end'>
              <Table.Pagination />
            </div>
          </Table.PaginationProvider>
          {detailedBillEvents ? (
            <DetailedBillEvents
              showModal={showDetailedBillEventsModal}
              closeModal={closeDetailedBillEventsModal}
              billEvents={detailedBillEvents}
              canUpdateBillEvent={canUpdateBillEvent}
              canDeleteBillEvent={canDeleteBillEvent}
            />
          ) : null}
        </div>
      )}
      {showManualBillEventModal ? (
        <ManualBillEventModal
          bills={bills?.filter((bill) => bill.startDate === startDate && bill.endDate === endDate)}
          processId={processId}
          startDate={startDate}
          endDate={endDate}
          showModal={showManualBillEventModal}
          closeModal={() => setShowManualBillEventModal(false)}
        />
      ) : null}
    </div>
  )
}
