import { Moment, moment } from '@invisible/legacy/moment'

// TODO: switch to date-fns
export const formatTimeLeft = (date: moment.MomentInput, options?: string) => {
  if (!date) {
    return '-'
  }
  return moment
    .duration(moment.utc(date).diff(moment.utc()))
    .format(options ? options : 'h[h] m[m]')
}

// TODO: switch to date-fns
export const getMomentDateTimeObject = ({ date, time }: { date: string; time: string }): Moment => {
  const dateString = date?.substr(0, 10)
  return moment(`${dateString} ${time}+00`, 'YYYY-MM-DD HH:mm:ssZ')
}

export const formatDateAndTime = (date: moment.MomentInput, options?: string) => {
  if (!date) {
    return '-'
  }
  return moment.utc(date).format(options ? options : 'YYYY-MM-DD HH:mm:ssZ')
}
