import { z } from '@invisible/zod'

import { FORMULA_RESULT_TYPES, FORMULA_VALUE_TYPES } from '../../../constants/formula'
import type { TFormulaArg } from '../common'
import { constantArgSchema, variableArgSchema } from '../common'

/**
 * A constant string formula argument.
 */
const stringConstantArgSchema = constantArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.STRING),
    valueType: z.literal(FORMULA_VALUE_TYPES.STRING),
  })
  .strip()

/**
 * A constant string formula argument.
 */
type TStringConstantArg = z.infer<typeof stringConstantArgSchema>

/**
 * A variable string formula argument.
 */
const stringVariableArgSchema = variableArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.STRING),
    valueType: z.literal(FORMULA_VALUE_TYPES.STRING),
  })
  .strip()
/**
 * A variable string formula argument.
 */
type TStringVariableArg = z.infer<typeof stringVariableArgSchema>

/**
 * A string formula argument.
 */
const stringArgSchema = z.union([stringConstantArgSchema, stringVariableArgSchema])

/**
 * A string formula argument.
 */
type TStringArg = z.infer<typeof stringArgSchema>

const isStringArg = (v: TFormulaArg): v is TStringArg =>
  v.resultType === FORMULA_RESULT_TYPES.STRING && v.valueType === FORMULA_RESULT_TYPES.STRING

export { isStringArg, stringArgSchema, stringConstantArgSchema, stringVariableArgSchema }

export type { TStringArg, TStringConstantArg, TStringVariableArg }
