import sanitize from 'sanitize-html'

export const sanitizeDescriptionOptions: sanitize.IOptions = {
  allowedTags: [
    'a',
    'p',
    'div',
    'span',
    'br',
    'img',
    'ul',
    'ol',
    'li',
    'b',
    'strong',
    'em',
    'i',
    'table',
    'tr',
    'td',
    'th',
    'tbody',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'hr',
    'iframe',
  ],
  allowedAttributes: {
    div: ['style'],
    strong: ['style'],
    span: ['style'],
    p: ['style'],
    em: ['style'],
    a: ['href', 'target'],
    iframe: ['src', 'class', 'allowfullscreen', 'frameborder'],
    img: ['src', 'width', 'height', 'alt'],
  },
  allowedIframeHostnames: ['www.loom.com'],
}
