import { SVGProps } from 'react'
function GrammarlyOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.6877 16.786C33.6877 26.0564 26.1462 33.572 16.8439 33.572C7.54154 33.572 0 26.0564 0 16.786C0 7.51556 7.54154 0 16.8439 0C26.1462 0 33.6877 7.51556 33.6877 16.786Z'
        fill='#15C39A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.0449 20.4104C19.1441 20.9595 19.6626 21.3362 20.2235 21.3362H21.9878L23.0105 21.1945C21.3738 23.5871 18.5324 24.6878 15.4253 24.2024C12.8957 23.8068 10.7228 22.1003 9.80518 19.7184C7.72435 14.3108 11.6864 9.14886 16.8507 9.14886C19.5421 9.14886 21.9016 10.7183 23.255 12.6138C23.614 13.1298 24.3273 13.3188 24.8457 12.961C25.0812 12.8015 25.2474 12.5585 25.3105 12.2811C25.3737 12.0038 25.3291 11.7128 25.1859 11.4671C24.2267 9.97331 22.8832 8.76517 21.2964 7.96936C19.7095 7.17355 17.9378 6.8194 16.167 6.94403C11.2424 7.27352 7.2567 11.2899 6.97327 16.2003C6.64496 21.9161 11.1928 26.5808 16.8507 26.5808C18.2615 26.5867 19.6568 26.288 20.9416 25.7054C22.2264 25.1227 23.3704 24.2697 24.2954 23.2045L24.0805 24.402V25.4837C24.0805 26.0411 24.4584 26.5584 25.0099 26.6576C25.1745 26.6886 25.344 26.683 25.5063 26.6412C25.6685 26.5994 25.8196 26.5224 25.9487 26.4157C26.0779 26.3089 26.182 26.1751 26.2536 26.0236C26.3253 25.8722 26.3627 25.7068 26.3633 25.5392V19.0606H20.1668C19.9992 19.0603 19.8336 19.097 19.6818 19.1681C19.53 19.2391 19.3957 19.3428 19.2886 19.4717C19.1814 19.6007 19.104 19.7516 19.0619 19.9139C19.0198 20.0761 19.014 20.2456 19.0449 20.4104Z'
        fill='white'
      />
    </svg>
  )
}

export default GrammarlyOriginal
