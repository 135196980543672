import durationFormat from 'moment-duration-format'
import moment from 'moment-timezone'

durationFormat(moment as any)
declare module 'moment-timezone' {
  interface Duration {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    format: (format: moment.MomentFormatSpecification, args?: Record<string, unknown>) => string
  }
}

export { moment }
export type { Moment } from 'moment-timezone'
