import { SnackbarContext } from '@invisible/common/providers'
import { getErrorMessage } from '@invisible/concorde/gql-client'
import axios from 'axios'
import { NEXT_PUBLIC_CONCORDE_URL } from 'libs/common/components/process-base/config/env'
import { useContext } from 'react'
import { useMutation } from 'react-query'

type TResponse = {
  signed_url: string
  export_task_id: string
}
const useGenerateSignedUrl = () => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { isLoading: isGenerateSignedUrlLoading, mutateAsync: generateSignedUrl } = useMutation(
    ['generate-signed-url'],
    async ({
      exportTaskId,
      isYdataProfile,
    }: {
      exportTaskId: string
      isYdataProfile?: boolean
    }) => {
      const { data } = await axios.post<TResponse>(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/data-delivery/generate-signed-url/`,
        { export_task_id: exportTaskId, is_ydata_profile: isYdataProfile },
        {
          withCredentials: true,
        }
      )

      return data
    },
    {
      onError: (e) =>
        showSnackbar({
          message: `Failed to generate url: ${getErrorMessage(e)}`,
          variant: 'error',
        }),
    }
  )

  return { isGenerateSignedUrlLoading, generateSignedUrl }
}

export { useGenerateSignedUrl }
