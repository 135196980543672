import { SVGProps } from 'react'
function CancelBaseRunOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.0261 14.9739C17.4016 15.3494 17.4016 15.9583 17.0261 16.3338L13.8598 19.5L17.0261 22.6662C17.4016 23.0418 17.4016 23.6506 17.0261 24.0261C16.6506 24.4016 16.0417 24.4016 15.6662 24.0261L12.5 20.8598L9.33376 24.0261C8.95825 24.4016 8.34944 24.4016 7.97394 24.0261C7.59843 23.6506 7.59843 23.0418 7.97394 22.6662L11.1402 19.5L7.97394 16.3338C7.59843 15.9583 7.59843 15.3494 7.97394 14.9739C8.34944 14.5984 8.95825 14.5984 9.33376 14.9739L12.5 18.1402L15.6662 14.9739C16.0417 14.5984 16.6506 14.5984 17.0261 14.9739Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 19.5C0 12.5964 5.59644 7 12.5 7C19.4036 7 25 12.5964 25 19.5C25 26.4036 19.4036 32 12.5 32C5.59644 32 0 26.4036 0 19.5ZM12.5 8.92308C6.65853 8.92308 1.92308 13.6585 1.92308 19.5C1.92308 25.3415 6.65853 30.0769 12.5 30.0769C18.3415 30.0769 23.0769 25.3415 23.0769 19.5C23.0769 13.6585 18.3415 8.92308 12.5 8.92308Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7367 0.138009C23.9057 0.0473927 24.0942 0 24.2857 0C24.4772 0 24.6657 0.0473927 24.8347 0.138009L24.8427 0.142377L24.8489 0.145871L31.6865 4.05385C31.7111 4.06739 31.7347 4.08261 31.7571 4.09936C31.8896 4.19853 31.9801 4.35135 31.997 4.5259C31.9989 4.54552 31.9999 4.56534 31.9999 4.5853L31.9998 4.59187V8.98997C32 8.99869 32 9.00741 31.9998 9.01611V13.3993C32.0006 13.4338 31.9984 13.4682 31.9934 13.5021C31.9844 13.5628 31.9665 13.6207 31.9411 13.6741C31.89 13.782 31.8067 13.8757 31.6956 13.9392L25.8211 17.2967C25.7521 16.8764 25.6637 16.4627 25.5569 16.0564L30.7985 13.0606V10.0425L24.8863 13.4208V14.122C24.5525 13.3543 24.1494 12.6237 23.6849 11.938V9.00235L17.7729 5.62413V7.06856C17.3815 6.90236 16.9807 6.7541 16.5715 6.62481V4.5853C16.5715 4.43591 16.6253 4.29915 16.7144 4.1936C16.7376 4.16602 16.7634 4.14045 16.7915 4.11726C16.8207 4.0931 16.8523 4.07168 16.8857 4.05336L23.7225 0.145871L23.7367 0.138009ZM24.8863 9.00246L30.7985 5.62415V8.65125L24.8863 12.0296V9.00246ZM24.2857 1.21528L18.3894 4.58522L24.2857 7.95442L30.1819 4.58522L24.2857 1.21528Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CancelBaseRunOutline
