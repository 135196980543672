import { constObjectToZod, z } from '@invisible/zod'
import { values } from 'lodash/fp'

const PROCESS_STATUS = {
  draft: 'draft',
  active: 'active',
  archived: 'archived',
  testing: 'testing',
  inactive: 'inactive',
} as const
const _PROCESS_STATUS = values(PROCESS_STATUS)
const ProcessStatusSchema = constObjectToZod(PROCESS_STATUS)
type TProcessStatus = z.infer<typeof ProcessStatusSchema>

export { _PROCESS_STATUS, ProcessStatusSchema }
export type { TProcessStatus }
