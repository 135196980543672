import { SVGProps } from 'react'
function PauseCircleOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 2.96154C9.02291 2.96154 2.96154 9.02291 2.96154 16.5C2.96154 23.9771 9.02291 30.0385 16.5 30.0385C23.9771 30.0385 30.0385 23.9771 30.0385 16.5C30.0385 9.02291 23.9771 2.96154 16.5 2.96154ZM0.5 16.5C0.5 7.66344 7.66344 0.5 16.5 0.5C25.3366 0.5 32.5 7.66344 32.5 16.5C32.5 25.3366 25.3366 32.5 16.5 32.5C7.66344 32.5 0.5 25.3366 0.5 16.5ZM12.8077 10.3462C13.4875 10.3462 14.0385 10.8972 14.0385 11.577V21.4231C14.0385 22.1029 13.4875 22.6539 12.8077 22.6539C12.128 22.6539 11.577 22.1029 11.577 21.4231V11.577C11.577 10.8972 12.128 10.3462 12.8077 10.3462ZM21.4231 11.577C21.4231 10.8972 20.872 10.3462 20.1923 10.3462C19.5126 10.3462 18.9615 10.8972 18.9615 11.577V21.4231C18.9615 22.1029 19.5126 22.6539 20.1923 22.6539C20.872 22.6539 21.4231 22.1029 21.4231 21.4231V11.577Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PauseCircleOutline
