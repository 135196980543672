import { SVGProps } from 'react'
function FilledPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7601 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9959 5.87951 15.1518 3.84705 13.6524 2.34764C12.153 0.848226 10.1205 0.00406613 8 0ZM10.8 8.51538L7.10769 10.9769C7.00585 11.0399 6.88897 11.0745 6.76923 11.0769C6.66774 11.0768 6.56767 11.0531 6.47692 11.0077C6.37854 10.9546 6.29648 10.8757 6.23956 10.7795C6.18264 10.6832 6.15301 10.5733 6.15385 10.4615V5.53846C6.15301 5.42666 6.18264 5.31675 6.23956 5.22053C6.29648 5.12431 6.37854 5.04541 6.47692 4.99231C6.57571 4.9423 6.68579 4.91885 6.79638 4.92424C6.90696 4.92964 7.01425 4.9637 7.10769 5.02308L10.8 7.48461C10.8857 7.54053 10.9561 7.61695 11.0049 7.70694C11.0536 7.79693 11.0791 7.89766 11.0791 8C11.0791 8.10234 11.0536 8.20306 11.0049 8.29305C10.9561 8.38304 10.8857 8.45946 10.8 8.51538Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FilledPlay
