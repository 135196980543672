import { SVGProps } from 'react'

function Task(props: SVGProps<SVGSVGElement> & { filled?: boolean }) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ ...props, filled: undefined }}>
      <g clipPath='url(#clip0_5323_78019)'>
        <path
          d='M29.2489 7.14374H25.6933V3.55263C25.6933 1.58522 24.1052 -0.00292969 22.1378 -0.00292969H4.36C2.39259 -0.00292969 0.804443 1.58522 0.804443 3.55263V21.3304C0.804443 23.2978 2.39259 24.886 4.36 24.886H7.95111V28.4415C7.95111 30.4089 9.55111 31.9971 11.5067 31.9971H29.2489C31.2163 31.9971 32.8044 30.4089 32.8044 28.4415V10.6993C32.8044 8.74374 31.2163 7.14374 29.2489 7.14374ZM4.36 22.5156C3.70815 22.5156 3.17481 21.9823 3.17481 21.3304V3.55263C3.17481 2.90077 3.70815 2.36744 4.36 2.36744H22.1378C22.7896 2.36744 23.323 2.90077 23.323 3.55263V21.3304C23.323 21.9823 22.7896 22.5156 22.1378 22.5156H4.36ZM30.4341 28.4415C30.4341 29.0934 29.9007 29.6267 29.2489 29.6267H11.5067C10.8548 29.6267 10.3215 29.0934 10.3215 28.4415V24.886H22.1378C24.1052 24.886 25.6933 23.2978 25.6933 21.3304V9.51411H29.2489C29.9007 9.51411 30.4341 10.0474 30.4341 10.6993V28.4415Z'
          fill='currentColor'
        />
        <path
          d='M20.0518 9.51411C20.0281 9.77485 19.9096 10.0356 19.6963 10.2252L12.1822 17.3956C11.957 17.6089 11.6607 17.7274 11.3644 17.7274C11.0681 17.7274 10.7837 17.6089 10.5467 17.3956L10.3215 17.1823L7.95111 14.9186L6.80148 13.8163C6.32741 13.3541 6.31555 12.6074 6.76592 12.1334C6.99111 11.8963 7.31111 11.766 7.61926 11.766C7.72592 11.766 7.84444 11.7778 7.95111 11.8252C8.12889 11.8608 8.29481 11.9556 8.43704 12.0978L11.3644 14.8948L17.0178 9.51411L18.0607 8.51855C18.2859 8.29337 18.5822 8.1867 18.8785 8.1867C19.1748 8.1867 19.5067 8.30522 19.7318 8.55411C19.9807 8.8267 20.0874 9.1704 20.0518 9.51411Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78019'>
          <rect width='32' height='32' fill='white' transform='translate(0.804443)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Task
