import { z } from '@invisible/zod'

import { FORMULA_RESULT_TYPES, FORMULA_VALUE_TYPES } from '../../../constants/formula'
import type { TFormulaArg } from '../common'
import { constantArgSchema, variableArgSchema } from '../common'

/**
 * A constant numeric formula argument.
 */
const numericConstantArgSchema = constantArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.NUMBER),
    valueType: z.literal(FORMULA_VALUE_TYPES.NUMBER),
  })
  .strip()

/**
 * A constant numeric formula argument.
 */
type TNumericConstantArg = z.infer<typeof numericConstantArgSchema>

/**
 * A variable numeric formula argument.
 */
const numericVariableArgSchema = variableArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.NUMBER),
    valueType: z.literal(FORMULA_VALUE_TYPES.NUMBER),
  })
  .strip()

/**
 * A variable numeric formula argument.
 */
type TNumericVariableArg = z.infer<typeof numericVariableArgSchema>

/**
 * A numeric formula argument.
 */
const numericArgSchema = z.union([numericConstantArgSchema, numericVariableArgSchema])

/**
 * A numeric formula argument.
 */
type TNumericArg = z.infer<typeof numericArgSchema>

const isNumericArg = (v: TFormulaArg): v is TNumericArg =>
  v.resultType === FORMULA_RESULT_TYPES.NUMBER && v.valueType === FORMULA_RESULT_TYPES.NUMBER

export { isNumericArg, numericArgSchema, numericConstantArgSchema, numericVariableArgSchema }

export type { TNumericArg, TNumericConstantArg, TNumericVariableArg }
