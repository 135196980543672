import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard } from '@invisible/ultron/zod'
import { useState } from 'react'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import Markdown from '../common/Markdown'
import { MetadataForm } from '../MetadataForm'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

interface IProps {
  stepRunId: string
  prompt: {
    id: string
    text: string
    index: number
  }
  stepRun: TStepRun
  parentBaseRunId: string
  editMode?: boolean
  baseRunVariables: TBaseRunVariables
  wacConfig: Wizard.RLHF2.TSchema
  textDirection?: string
  handleClose: () => void
}

const PromptEdit = ({
  stepRunId,
  prompt: { id, text, index },
  baseRunVariables,
  parentBaseRunId,
  wacConfig,
  stepRun,
  editMode = false,
  textDirection,
  handleClose,
}: IProps) => {
  const reactQueryContext = useContext()
  const [isMetaDetailsOpen, setIsMetaDetailsOpen] = useState(true)
  const [isSelected, setIsSelected] = useState(editMode)
  const [promptText, setPromptText] = useState(text)

  const { mutateAsync: updateVariable, isLoading } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: wacConfig?.promptsBaseId as string,
            parentBaseRunId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return prevData.map((b) =>
            b.id !== data[0].baseRunId
              ? b
              : {
                  ...b,
                  baseRunVariables: b.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
          )
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
  })

  const handleSavePromptInfo = async () => {
    await Promise.all([
      updateVariable({
        stepRunId,
        data: [
          {
            baseRunId: id,
            baseVariableId: wacConfig?.promptTextBaseVariableId as string,
            value: promptText,
          },
        ],
      }),
    ])
    handleClose()
  }

  return (
    <div className='bg-weak-3 border-main w-[500px] rounded-md border border-solid p-3'>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>Prompt {index}</div>
        {wacConfig.allowEditPrompt ? (
          !isSelected ? (
            <Button size='sm' variant='secondary' onClick={() => setIsSelected(true)}>
              Select
            </Button>
          ) : (
            <Button size='sm' variant='primary' onClick={handleSavePromptInfo} loading={isLoading}>
              Save
            </Button>
          )
        ) : (
          <Button size='sm' variant='secondary' onClick={() => handleClose()}>
            Close
          </Button>
        )}
      </div>
      {isSelected ? (
        <div
          className={
            'my-3 box-border inline-block w-full whitespace-pre-wrap rounded border border-solid border-gray-300 bg-white p-2 outline-none'
          }
          contentEditable={true}
          dir={textDirection}
          onInput={(e) => setPromptText(e?.currentTarget?.textContent ?? '')}>
          {typeof text === 'string' ? text?.trim() : text}
        </div>
      ) : (
        <Markdown
          dir={textDirection}
          className='my-3 overflow-auto'
          components={{
            p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
          }}>
          {text as string}
        </Markdown>
      )}

      <div>
        <h4 className='flex justify-between'>
          {' '}
          <span>Metadata info</span>{' '}
          <span
            className='cursor-pointer font-thin'
            onClick={() => setIsMetaDetailsOpen(!isMetaDetailsOpen)}>
            {isMetaDetailsOpen ? 'Hide' : 'Show'}
          </span>{' '}
        </h4>

        {isMetaDetailsOpen ? (
          <MetadataForm
            form={{ ...wacConfig?.promptMetadata, type: 'update' } as Wizard.Form.TSchema}
            stepRun={stepRun}
            wacName='RLHF2-PromptEdit'
            baseRunId={id}
            baseRunVariables={baseRunVariables as TBaseRunVariables}
          />
        ) : null}
      </div>
    </div>
  )
}

export { PromptEdit }
