import { SVGProps } from 'react'
function InfoOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.7233 15.2692C13.7233 14.5895 14.2743 14.0385 14.954 14.0385H16.1848C16.8645 14.0385 17.4156 14.5895 17.4156 15.2692V22.6538C18.0953 22.6538 18.6464 23.2049 18.6464 23.8846C18.6464 24.5644 18.0953 25.1154 17.4156 25.1154H16.1848C15.5051 25.1154 14.954 24.5644 14.954 23.8846V16.5C14.2743 16.5 13.7233 15.949 13.7233 15.2692Z'
        fill='currentColor'
      />
      <path
        d='M17.7233 9.73077C17.7233 10.7504 16.8967 11.5769 15.8771 11.5769C14.8575 11.5769 14.031 10.7504 14.031 9.73077C14.031 8.71117 14.8575 7.88462 15.8771 7.88462C16.8967 7.88462 17.7233 8.71117 17.7233 9.73077Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.184814 16.5C0.184814 7.66344 7.34826 0.5 16.1848 0.5C25.0214 0.5 32.1848 7.66344 32.1848 16.5C32.1848 25.3366 25.0214 32.5 16.1848 32.5C7.34826 32.5 0.184814 25.3366 0.184814 16.5ZM16.1848 2.96154C8.70773 2.96154 2.64635 9.02291 2.64635 16.5C2.64635 23.9771 8.70773 30.0385 16.1848 30.0385C23.6619 30.0385 29.7233 23.9771 29.7233 16.5C29.7233 9.02291 23.6619 2.96154 16.1848 2.96154Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InfoOutline
