import Image, { ImageProps } from 'next/image'

import {
  SYMBOL_INVISIBLE,
  SYMBOL_INVISIBLE_ADMIN,
  SYMBOL_INVISIBLE_OPERATE,
  SYMBOL_INVISIBLE_PORTAL,
  WORDMARK_BUILDER,
  WORDMARK_INVISIBLE,
  WORDMARK_WIZARD_BUILDER,
} from './svg-components'
import {
  ARROW_LEFT,
  ASSISTANT_CLEANER,
  ASSISTANT_CONSTRUCTOR_ROBOT,
  ASSISTANT_COOK_WITH_LARGE_GREEN_TEXT_BOX,
  ASSISTANT_COOK_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_FRIENDLY_ROBOT,
  ASSISTANT_HOLDING_BADGE_AIRPLAIN,
  ASSISTANT_REPAIRER,
  ASSISTANT_REPAIRER_DRILL,
  ASSISTANT_WITH_GENERIC_BADGE,
  ASSISTANT_WITH_ONBOARDING_BADGE,
  ASTRONAUT,
  CARD_BACK_ICON,
  CARD_ICON,
  CARD_IMAGE,
  COLLABORATING,
  CREDIT_CARD_ONBOARDING,
  DEFAULT_AVATAR_PURPLE,
  DOCTOR_ROBOTS,
  DOT_GRID,
  DOUBLE_QUOTE,
  FLOPPY_DISK,
  INV_LOGO_FULL,
  JUMP_FOR_JOY,
  LADIES_WORKING,
  LADY_WORKING,
  MAN_WORKING,
  MISSING_PROFILE_IMAGE,
  NOT_FOUND_IMG,
  PICKER_CELL,
  PORTTAL,
  PURPLE_INVISIBLE_LOGO_ONBOARDING,
  Random_Agent_1,
  Random_Agent_2,
  Random_Agent_3,
  Random_Agent_4,
  Random_Agent_5,
  STRIPE_LOGO,
  STRIPE_PURPLE,
  STRIPE_TEAL,
} from './urls'

type Props = Omit<ImageProps, 'src'>

//a BlurDataURL must be passed as a prop for static import
//see doc : https://nextjs.org/docs/messages/placeholder-blur-data-url
const wrapImage = (src: ImageProps['src']) => (props: Props) =>
  (
    <Image
      data-cy='images'
      placeholder={typeof src !== 'string' ? 'blur' : 'empty'}
      {...props}
      src={src as ImageProps['src']}
      blurDataURL={src as ImageProps['src'] as string | undefined}
    />
  )

export const ArrowLeft = wrapImage(ARROW_LEFT)
export const StripePurple = wrapImage(STRIPE_PURPLE)
export const CardBackIcon = wrapImage(CARD_BACK_ICON)
export const CardIcon = wrapImage(CARD_ICON)
export const CreditCardOnboarding = wrapImage(CREDIT_CARD_ONBOARDING)
export const InvLogoFull = wrapImage(INV_LOGO_FULL)
export const NotFoundImg = wrapImage(NOT_FOUND_IMG)
export const PickerCell = wrapImage(PICKER_CELL)
export const CardImage = wrapImage(CARD_IMAGE)
export const StripeLogo = wrapImage(STRIPE_LOGO)
export const StripeTeal = wrapImage(STRIPE_TEAL)
export const Portal = wrapImage(PORTTAL)
export const Default_Avatar_Purple = wrapImage(DEFAULT_AVATAR_PURPLE)
export const RobotWithOnboardingBadge = wrapImage(ASSISTANT_WITH_ONBOARDING_BADGE)
export const RobotWithGenericBadge = wrapImage(ASSISTANT_WITH_GENERIC_BADGE)
export const RobotWithAirplainBadge = wrapImage(ASSISTANT_HOLDING_BADGE_AIRPLAIN)

export const AssistantRepairerWithDrill = wrapImage(ASSISTANT_REPAIRER_DRILL)
export const AssistantRepairer = wrapImage(ASSISTANT_REPAIRER)

export const InvisibleLogoOnboarding = wrapImage(PURPLE_INVISIBLE_LOGO_ONBOARDING)
export const Cleaner = wrapImage(ASSISTANT_CLEANER)
export const CookWithSmallGreenTextBox = wrapImage(ASSISTANT_COOK_WITH_SMALL_GREEN_TEXT_BOX)
export const ConstructorRobot = wrapImage(ASSISTANT_CONSTRUCTOR_ROBOT)
export const FriendlyRobot = wrapImage(ASSISTANT_FRIENDLY_ROBOT)

export const CookWithLargeGreenTextBox = wrapImage(ASSISTANT_COOK_WITH_LARGE_GREEN_TEXT_BOX)
export const DotGrid = wrapImage(DOT_GRID)
export const DoctorRobots = wrapImage(DOCTOR_ROBOTS)

export const Astronaut = wrapImage(ASTRONAUT)
export const LadyWorking = wrapImage(LADY_WORKING)
export const Collaborating = wrapImage(COLLABORATING)

export const DoubleQuote = wrapImage(DOUBLE_QUOTE)

export const JumpForJoy = wrapImage(JUMP_FOR_JOY)
export const LadiesWorking = wrapImage(LADIES_WORKING)
export const ManWorking = wrapImage(MAN_WORKING)

export const FloppyDisk = wrapImage(FLOPPY_DISK)

export const randomAgent1 = wrapImage(Random_Agent_1)
export const randomAgent2 = wrapImage(Random_Agent_2)
export const randomAgent3 = wrapImage(Random_Agent_3)
export const randomAgent4 = wrapImage(Random_Agent_4)
export const randomAgent5 = wrapImage(Random_Agent_5)

// SVG Components
export const MissingProfile = wrapImage(MISSING_PROFILE_IMAGE)
export const WordmarkInvisible = WORDMARK_INVISIBLE
export const WordmarkBuilder = WORDMARK_BUILDER
export const WordmarkWizardBuilder = WORDMARK_WIZARD_BUILDER
export const SymbolInvisible = SYMBOL_INVISIBLE
export const SymbolInvisibleAdmin = SYMBOL_INVISIBLE_ADMIN
export const SymbolInvisibleOperate = SYMBOL_INVISIBLE_OPERATE
export const SymbolInvisiblePortal = SYMBOL_INVISIBLE_PORTAL
