import { classNames } from '@invisible/common/helpers'
import { map } from 'lodash/fp'

type IButtonOption = {
  key: string
  value: string
}

interface IGroupedCapsuleButtonList {
  onChange: (key: string) => void
  selectedKey?: string
  buttonOptions: IButtonOption[]
}

interface ICapsuleButton {
  onClick?: () => void
  className?: string
}

const capsuleBttonGroupStyles =
  'flex w-fit flex-row items-center gap-2 rounded-3xl border border-solid border-weak bg-week-2 py-px px-0.5 text-sm'

const activeButtonStyles = 'bg-theme-main text-white'

const capsuleButtonBaseStyles = 'cursor-pointer rounded-3xl py-1 px-5'

// eslint-disable-next-line @typescript-eslint/ban-types
const CapsuleButton: React.FC<ICapsuleButton> = ({ children, className, onClick }) => (
  <div onClick={onClick} className={classNames(capsuleButtonBaseStyles, className ?? '')}>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const GroupedCapsuleButtonList: React.FC<IGroupedCapsuleButtonList> = ({
  onChange,
  selectedKey,
  buttonOptions,
}) => {
  const buttonsList = map(
    ({ key, value }) => (
      <CapsuleButton
        onClick={() => onChange(key)}
        className={selectedKey === key ? activeButtonStyles : 'text-weak'}
        key={key}>
        {value}
      </CapsuleButton>
    ),
    buttonOptions
  )
  return <div className={capsuleBttonGroupStyles}>{buttonsList}</div>
}

export { GroupedCapsuleButtonList }
