import {
  TProcessByIdStep,
  TProcessLifecycleStage,
  TProcessStepGoTo,
  useProcessById,
} from '@invisible/common/components/process-base'
import { NotFound } from '@invisible/common/pages'
import { useStore } from '@invisible/common/stores/process-store'
import { useQuery } from '@invisible/trpc/client'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { theme } from '@invisible/ui/mui-theme-v2'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { ThemeProvider } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import { useCallback, useEffect } from 'react'
import { ReactFlowProvider } from 'reactflow'
import shallow from 'zustand/shallow'

import { Canvas } from './Canvas'

export const ProcessCanvas = ({ processId }: { processId: string }) => {
  const {
    convertProcessToNodesAndEdges,
    setStageViewActive,
    autoLayout,
    currentLayoutId,
    setCurrentLayoutId,
    layoutOrientation,
    setLayoutOrientation,
    showStages,
  } = useStore(
    useCallback(
      (state) => ({
        convertProcessToNodesAndEdges: state.convertProcessToNodesAndEdges,
        setStageViewActive: state.setStageViewActive,
        autoLayout: state.autoLayout,
        currentLayoutId: state.currentLayoutId,
        setCurrentLayoutId: state.setCurrentLayoutId,
        layoutOrientation: state.layoutOrientation,
        setLayoutOrientation: state.setLayoutOrientation,
        showStages: state.showStages,
      }),
      []
    ),
    shallow
  )

  const { data, isLoading } = useProcessById({
    id: processId,
  })

  const { data: selectedLayoutData, isLoading: getLayoutLoading } = useQuery(
    ['process.getLayout', { layoutId: currentLayoutId as string }],
    { enabled: !!currentLayoutId }
  )

  const convertProcessToNodes = useCallback(
    ({
      processData,
      layoutData,
      currentLayoutId,
      forceAutoLayout,
    }: {
      processData: typeof data
      layoutData: typeof selectedLayoutData
      currentLayoutId?: string | null
      forceAutoLayout?: boolean
    }) => {
      if (!processData) return
      const stepGoTos = [...(processData?.stepGoTos ?? [])]

      convertProcessToNodesAndEdges({
        steps: (processData?.steps as TProcessByIdStep[]) ?? [],
        stepGoTos: (stepGoTos as TProcessStepGoTo[]) ?? [],
        processId: processData?.id,
        rootBaseId: processData.rootBaseId ?? '',
        processStatus: processData.status,
        stages: (processData?.lifecycleStages as TProcessLifecycleStage[]) ?? [],
        ...(currentLayoutId && layoutData ? { layout: layoutData } : {}),
        forceAutoLayout,
      })
    },
    [convertProcessToNodesAndEdges]
  )

  useEffect(() => {
    convertProcessToNodes({ processData: data, layoutData: selectedLayoutData, currentLayoutId })
  }, [data, selectedLayoutData, currentLayoutId, convertProcessToNodes, showStages])

  useEffect(() => {
    convertProcessToNodes({
      processData: data,
      layoutData: selectedLayoutData,
      currentLayoutId,
      forceAutoLayout: true,
    })
  }, [convertProcessToNodes, currentLayoutId, data, layoutOrientation, selectedLayoutData])

  if (isLoading || getLayoutLoading)
    return (
      <div className='mt-20 flex justify-center'>
        <LogoSpinner width={30} height={30} />
      </div>
    )
  if (!data) return <NotFound />

  return (
    <ReactFlowProvider>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          sx={{ position: 'absolute', bottom: 15, right: 130, zIndex: 100 }}
          value={layoutOrientation}
          exclusive
          size='small'
          onChange={(_, value) => {
            if (value) setLayoutOrientation(value)
          }}>
          <Tooltip title='Process orientation layout - horizontal' placement='top'>
            <ToggleButton
              value='horizontal'
              sx={{
                width: 30,
                height: 30,
                background: layoutOrientation === 'horizontal' ? '#e0e0e0' : 'white',
              }}>
              <SwapHorizIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>
          <Tooltip title='Process orientation layout - vertical' placement='top'>
            <ToggleButton
              value='vertical'
              sx={{
                width: 30,
                height: 30,
                background: layoutOrientation !== 'horizontal' ? '#e0e0e0' : 'white',
              }}>
              <SwapVertIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </ThemeProvider>
      <Canvas />
    </ReactFlowProvider>
  )
}
