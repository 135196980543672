import { SVGProps } from 'react'

function ToolsRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.5361 16.0223C18.6486 15.8121 19.6727 15.2737 20.4766 14.4766C21.2704 13.6854 21.8086 12.6744 22.0217 11.5741C22.2348 10.4739 22.1129 9.33498 21.6719 8.3047C21.6325 8.21089 21.5705 8.12827 21.4914 8.06421C21.4124 8.00014 21.3187 7.95662 21.2188 7.93751C21.1181 7.9178 21.0141 7.92317 20.916 7.95315C20.8179 7.98312 20.7287 8.03678 20.6563 8.10939L17.6172 11.1484L16.1407 10.8594L15.8516 9.38282L18.8907 6.34376C18.9633 6.27131 19.0169 6.18209 19.0469 6.08399C19.0769 5.9859 19.0822 5.88193 19.0625 5.78126C19.0434 5.68132 18.9999 5.58765 18.9358 5.50859C18.8718 5.42954 18.7892 5.36754 18.6953 5.32814C17.6582 4.88715 16.5124 4.76789 15.4067 4.98582C14.3009 5.20375 13.2861 5.74881 12.4938 6.55036C11.7016 7.35191 11.1683 8.37297 10.9633 9.48118C10.7582 10.5894 10.8908 11.7337 11.3438 12.7656L6.64065 16.8281L6.6094 16.8594C6.37697 17.0909 6.19254 17.3661 6.06669 17.6691C5.94085 17.9721 5.87607 18.2969 5.87607 18.625C5.87607 18.9531 5.94085 19.278 6.06669 19.5809C6.19254 19.8839 6.37697 20.1591 6.6094 20.3906C6.84095 20.6231 7.11611 20.8075 7.4191 20.9333C7.72209 21.0592 8.04694 21.124 8.37503 21.124C8.70311 21.124 9.02797 21.0592 9.33096 20.9333C9.63395 20.8075 9.90911 20.6231 10.1407 20.3906C10.143 20.3883 10.1461 20.3859 10.1495 20.3833C10.1572 20.3774 10.1665 20.3703 10.1719 20.3594L14.2344 15.6563C15.2738 16.1051 16.4237 16.2326 17.5361 16.0223Z'
        fill='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40608 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50334 20.9221 7.21716 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='none'
      />
    </svg>
  )
}

export default ToolsRibbon
