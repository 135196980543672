import { SnackbarContext } from '@invisible/common/providers'
import { fromGlobalId, toGlobalId } from '@invisible/concorde/gql-client'
import {
  IProcessBaseVariableStageEnum,
  useBaseVariableByBaseIdQuery,
} from '@invisible/concorde/gql-client'
import { getErrorMessage } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useQuery } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { TVariableType } from '@invisible/ultron/zod'
import { useContext } from 'react'
import { useGate } from 'statsig-react'
import { JsonValue } from 'type-fest'

type UseBaseVariableByBaseIdOptions = {
  baseId: string
  enabled: boolean
  onError?: (error: Error) => void
}

export type TRPCBaseVariableType = NonNullable<
  inferQueryOutput<'baseVariable.findByBaseId'>
>[number]

export type GraphQLBaseVariableType = {
  __typename?: 'BaseVariableType'
  id: string
  createdAt: Date
  defaultValue: JsonValue | null
  description: string | null
  hidden: boolean
  key: string
  name: string
  options: string[] | null
  position: number
  required: boolean
  schema: JsonValue
  stage: IProcessBaseVariableStageEnum
  type: TVariableType
  updatedAt: Date
  deletedAt: Date | null
  baseId: string
  stepTemplateVariableId: string | null
}

export type TBaseVariable = GraphQLBaseVariableType | TRPCBaseVariableType

const useBaseVariableByBaseId = ({
  baseId,
  enabled,
  onError: handleError,
}: UseBaseVariableByBaseIdOptions) => {
  const { value: isGraphQLEnabled } = useGate('enable-graphql-base-variable-by-baseid')
  const { showSnackbar } = useContext(SnackbarContext)

  const showErrorMessage = (error: Error) => {
    if (handleError) {
      handleError(error)
      return
    }
    logger.error(`Query baseVariable.findByBaseId resulted in an error: ${error.message}`, error)
    showSnackbar({
      message: error.message,
      variant: 'error',
    })
  }

  const { data: graphqlData } = useBaseVariableByBaseIdQuery(
    { baseId: toGlobalId('BaseType', baseId) },
    {
      enabled: enabled && isGraphQLEnabled,
      onError: (error) => {
        const _error = new Error(getErrorMessage(error))
        showErrorMessage(_error)
      },
    }
  )

  const { data: trpcData } = useQuery(['baseVariable.findByBaseId', { baseId }], {
    enabled: enabled && !isGraphQLEnabled,
    onError: (error) => {
      const _error = new Error(error.message)
      showErrorMessage(_error)
    },
  })

  const normalizedGraphQLData: GraphQLBaseVariableType[] = (
    graphqlData?.baseVariables.edges ?? []
  ).map((edge) => ({
    __typename: 'BaseVariableType',
    id: fromGlobalId(edge.node.id),
    name: edge.node.name,
    description: edge.node.description ?? null,
    type: edge.node.type,
    stage: edge.node.stage,
    schema: edge.node.schema,
    required: edge.node.required,
    position: edge.node.position,
    options: edge.node.options ?? null,
    key: edge.node.key,
    createdAt: new Date(edge.node.createdAt),
    updatedAt: new Date(edge.node.updatedAt),
    deletedAt: edge.node.deletedAt ? new Date(edge.node.deletedAt) : null,
    defaultValue: edge.node.defaultValue,
    hidden: edge.node.hidden,
    baseId: fromGlobalId(edge?.node?.base?.id),
    stepTemplateVariableId: edge?.node?.stepTemplateVariable?.id,
  }))

  const data = (isGraphQLEnabled ? normalizedGraphQLData : trpcData) as TBaseVariable[]

  return { data }
}

export { useBaseVariableByBaseId }
