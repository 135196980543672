import { format, isToday, isYesterday } from 'date-fns'
import { enUS, es, ja } from 'date-fns/locale'

const formatChatTimestamp = (timestamp: Date) => {
  if (isToday(timestamp)) return `Today ${format(timestamp, 'h:mm a')}`
  if (isYesterday(timestamp)) return `Yesterday ${format(timestamp, 'h:mm a')}`
  return formatByLocale(timestamp, 'dd/MM h:mm a')
}

const formatByLocale = (time: Date, formatToken: string) => {
  let locale = enUS
  if (navigator.language.startsWith('es')) locale = es
  else if (navigator.language.startsWith('ja')) locale = ja
  return format(time, formatToken, { locale })
}

export { formatByLocale, formatChatTimestamp }
