import {
  fromGlobalId,
  toGlobalId,
  useReadOnlyExportTaskDataQuery,
} from '@invisible/concorde/gql-client'
import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { capitalize } from 'lodash/fp'

import { BaseRunVariableValue } from '../../common/BaseRunVariableValue'
import { EXPORT_STATUS_OPTIONS, exportStatusValues } from '../FilterConfiguration'
import { ReadOnlyStepRunFilters } from './StepRunFilters'

interface IProps {
  filters: {
    extra_filters: IExtraFilters[]
    step_run_filters: IStepRunFilters[]
    exported_base_run_status?: string[]
  }
}

export type TDateRange = {
  start_date: string
  end_date: string
} | null

export interface IExtraFilters {
  key: string
  value: string | string[] | TDateRange
  is_step_status?: boolean
  is_base_run_status?: boolean
}

export interface IStepRunFilters {
  [key: string]: string | TDateRange
  step_id: string
}

const READ_ONLY_FILTER_COLOR = 'grey.500'

const ReadOnlyFilterConfiguration = ({ filters }: IProps) => {
  const steps = filters['extra_filters'].filter((filter) => filter.is_step_status === true)
  const baseVariables = filters['extra_filters'].filter(
    (filter) =>
      !filter.is_step_status &&
      !filter.is_base_run_status &&
      ['created_at', 'delivered_at'].indexOf(filter.key) === -1
  )

  const { data: readOnlyExportTaskData } = useReadOnlyExportTaskDataQuery({
    baseVariableIds: baseVariables.map((bv) => toGlobalId('BaseVariableType', bv.key)),
    stepIds: steps.map((step) => toGlobalId('StepType', step.key)),
  })

  const stepsNodes = readOnlyExportTaskData?.steps.edges ?? []
  const baseVariablesNodes = readOnlyExportTaskData?.baseVariables.edges ?? []

  const createdAtFilterValue = filters['extra_filters'].find((f) => f.key === 'created_at')
    ?.value as TDateRange

  const createdAtFormattedText = createdAtFilterValue
    ? `${format(new Date(createdAtFilterValue.start_date), 'd MMM yyyy')} - ${format(
        new Date(createdAtFilterValue.end_date),
        'd MMM yyyy'
      )}`
    : ''

  const deliveredAtFilterValue = filters['extra_filters'].find((f) => f.key === 'delivered_at')
    ?.value as TDateRange

  const deliveredAtFormattedText = deliveredAtFilterValue
    ? `${format(new Date(deliveredAtFilterValue.start_date), 'd MMM yyyy')} - ${format(
        new Date(deliveredAtFilterValue.end_date),
        'd MMM yyyy'
      )}`
    : ''

  const baseRunStatusFilterValue =
    (filters['extra_filters'].find((f) => f.key === 'baseRunStatus')?.value as string[]) ?? []
  const baseRunStatusFilters = baseRunStatusFilterValue.map(capitalize).join(', ')

  const exportedBaseRunExcludeValues = filters.exported_base_run_status ?? []
  const exportedBaseRunStatusValues = exportStatusValues.filter(
    (v) => !exportedBaseRunExcludeValues.includes(v)
  )
  const exportedBaseRunStatusFilters = exportedBaseRunStatusValues
    .map((s) => EXPORT_STATUS_OPTIONS.find((o) => s === o.value)?.label)
    .join(', ')

  return (
    <Stack gap='16px'>
      <Stack direction='row' justifyContent='space-between' gap='8px'>
        <Stack gap='4px'>
          <Typography fontWeight='bold'>Filters set for the export are below.</Typography>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' gap='16px' mt='8px'>
        <Typography width='300px'>
          Filter export data by Created by and Delivered by time range(s)
        </Typography>
        <Tooltip title={createdAtFormattedText} enterDelay={500}>
          <Typography
            noWrap
            align='center'
            width='290px'
            color={READ_ONLY_FILTER_COLOR}
            pl={1}
            py={1}
            border='1px solid'
            borderRadius='4px'>
            {createdAtFormattedText ? `Created ${createdAtFormattedText}` : 'Not specified'}
            <FilterListIcon
              sx={{
                color: READ_ONLY_FILTER_COLOR,
                fontSize: '18px',
                ml: '6px',
                verticalAlign: 'text-top',
              }}
            />
          </Typography>
        </Tooltip>
        <Typography color={READ_ONLY_FILTER_COLOR}>and</Typography>
        <Tooltip title={deliveredAtFormattedText} enterDelay={500}>
          <Typography
            noWrap
            align='center'
            width='290px'
            color={READ_ONLY_FILTER_COLOR}
            pl={1}
            py={1}
            border='1px solid'
            borderRadius='4px'>
            {deliveredAtFormattedText ? `Delivered ${deliveredAtFormattedText}` : 'Not specified'}
            <FilterListIcon
              sx={{
                color: READ_ONLY_FILTER_COLOR,
                fontSize: '18px',
                ml: '6px',
                verticalAlign: 'text-top',
              }}
            />
          </Typography>
        </Tooltip>
      </Stack>

      {baseRunStatusFilters ? (
        <Stack direction='row' alignItems='center' gap='16px'>
          <Typography width='300px'>Filter the data by status </Typography>
          <Tooltip title={baseRunStatusFilters}>
            <Typography
              noWrap
              align='center'
              width='220px'
              color={READ_ONLY_FILTER_COLOR}
              px={2}
              py={1}
              border='1px solid'
              borderRadius='4px'>
              {baseRunStatusFilters}
            </Typography>
          </Tooltip>
        </Stack>
      ) : null}

      {exportedBaseRunStatusFilters ? (
        <Stack direction='row' alignItems='center' gap='16px'>
          <Typography width='300px'>Exclude data by Export Status </Typography>
          <Tooltip title={exportedBaseRunStatusFilters}>
            <Typography
              noWrap
              align='center'
              width='220px'
              color={READ_ONLY_FILTER_COLOR}
              px={2}
              py={1}
              border='1px solid'
              borderRadius='4px'>
              {exportedBaseRunStatusFilters}
            </Typography>
          </Tooltip>
        </Stack>
      ) : null}

      <Box>
        {stepsNodes.length > 0 || baseVariablesNodes.length > 0 ? (
          <Typography fontWeight='bold'>Specific data selected</Typography>
        ) : null}
        {stepsNodes.length > 0 ? (
          <Typography mb='8px'>Stages appear below. Expand and select.</Typography>
        ) : null}
        <ReadOnlyStepRunFilters filters={filters} stepsData={readOnlyExportTaskData?.steps} />

        {baseVariablesNodes.length > 0 ? (
          <Typography mt='8px'>Base variables Filters appear below.</Typography>
        ) : null}
        <Stack>
          {baseVariablesNodes
            .sort((a, b) => a.node.name.localeCompare(b.node.name))
            .map((variable, index) => {
              const filter = filters['extra_filters'].find(
                (filter) => filter.key === fromGlobalId(variable.node.id)
              )
              return (
                <Stack key={index} direction='row' alignItems='center'>
                  <FormControlLabel
                    control={<Checkbox disabled size='small' checked />}
                    label={<Typography color='grey.800'>{variable.node.name}</Typography>}
                  />
                  <Typography mr='auto' fontStyle='italic' color='grey.500'>
                    {variable.node.type}
                  </Typography>
                  <BaseRunVariableValue
                    value={filter ? filter.value : ''}
                    type={variable.node.type}
                  />
                  {filter?.value === null && <Typography color='grey.800'>NULL</Typography>}
                </Stack>
              )
            })}
        </Stack>
      </Box>
    </Stack>
  )
}

export { ReadOnlyFilterConfiguration }
