import { z } from '@invisible/zod'

const schema = z.object({
  baseId: z.string().optional(),

  rankingCriteria: z.string().optional(),
  rankBaseVariableId: z.string().optional(),
  rankingMessageBaseVariableId: z.string().optional(),

  skipDecisionVariableId: z.string().optional(),
  skipDecisionCondition: z.boolean().optional(),

  disallowEqualRanking: z.boolean().optional(),
  rankingAlgorithm: z.enum(['BubbleSort', 'QuickSort', 'MergeSort']).optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
