import { SVGProps } from 'react'
function PlaneTakeoffFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='22'
      viewBox='0 0 31 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M21.977 21C21.977 21.2652 21.8717 21.5196 21.6841 21.7071C21.4966 21.8946 21.2423 22 20.977 22H2.97703C2.71182 22 2.45746 21.8946 2.26993 21.7071C2.08239 21.5196 1.97703 21.2652 1.97703 21C1.97703 20.7348 2.08239 20.4804 2.26993 20.2929C2.45746 20.1054 2.71182 20 2.97703 20H20.977C21.2423 20 21.4966 20.1054 21.6841 20.2929C21.8717 20.4804 21.977 20.7348 21.977 21ZM30.7658 4.845L28.4358 1.85874L28.4208 1.83999C27.6673 0.911905 26.605 0.285987 25.428 0.0766659C24.251 -0.132655 23.0381 0.088604 22.0108 0.699995L15.5645 4.54749L8.82203 2.06749C8.5967 1.98347 8.34862 1.98347 8.12328 2.06749L7.22703 2.40874L7.18703 2.42499C6.88042 2.55661 6.6116 2.76285 6.40508 3.02492C6.19855 3.28699 6.06087 3.59657 6.00459 3.92546C5.9483 4.25435 5.9752 4.5921 6.08282 4.90793C6.19045 5.22377 6.37538 5.50766 6.62078 5.73375L9.35203 8.38249L6.83078 9.9075L3.29828 8.715C3.04827 8.63076 2.7751 8.64873 2.53828 8.765L2.16328 8.94874C1.86326 9.08281 1.6009 9.28874 1.3994 9.54832C1.19789 9.8079 1.06345 10.1131 1.00796 10.437C0.952479 10.7609 0.977656 11.0935 1.08127 11.4053C1.18489 11.7172 1.36375 11.9987 1.60203 12.225L6.10203 16.6337L6.11703 16.6487C7.03881 17.52 8.25868 18.0061 9.52703 18.0075C10.4174 18.006 11.2915 17.7679 12.0595 17.3175L30.4858 6.31749C30.6074 6.24483 30.7121 6.14717 30.7931 6.03097C30.8741 5.91478 30.9295 5.7827 30.9557 5.64348C30.9818 5.50427 30.978 5.36109 30.9446 5.22343C30.9113 5.08578 30.849 4.95679 30.762 4.845H30.7658Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PlaneTakeoffFilled
