import { useContext } from 'react'

import { StoriesContext, TStoriesContext, TStory, TStoryContext } from '../providers'

// Overloads
function useStories(): TStoriesContext
function useStories(findFn: (story: TStory) => boolean): TStoryContext
//implementation
function useStories(findFn?: (story: TStory) => boolean) {
  const context = useContext(StoriesContext)
  if (!context) throw new Error('useStories is only useable as a child to StoriesProvider')

  if (findFn) {
    const story = context.data?.stories.edges.find((story) => findFn(story.node))?.node as TStory
    return { ...story, scopedId: context.scopedId }
  } else {
    return context
  }
}

export { useStories }
