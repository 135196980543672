import tools from './tools'
import { TActions, TParameters, TToolNames } from './ToolSelector'

type TReducerState = {
  isMultiActionMode: boolean
  // Indicates if the first submitted action for multi-action requires confirmation
  currentActionType: boolean | null
  // List of submitted request and responses for  mulit-action
  dataList: {
    action: string
    tool: string
    requestValues: Record<string, unknown> | null
    responseValues: Record<string, unknown> | null
    schemaVersion: string
    lastUpdated: string
  }[]
  currentResponseIndex: number
  selectedToolName: TToolNames | null
  selectedAction: TActions[number] | null
  activeTab: 'Request' | 'Response'
  selectedResponseCode: string | null
  isActionConfirmed: boolean
  requestValues: Partial<Record<string, unknown>> | null
  responseValues: Record<string, unknown>
}

type TReducerAction =
  | { type: 'SET_MULTIACTION_MODE'; payload: boolean }
  | { type: 'SET_REQUIRES_CONFIRMATION_ACTION_TYPE'; payload: boolean }
  | {
      type: 'ADD_DATA'
      payload: {
        action: string
        tool: string
        requestValues: Record<string, unknown> | null
        requiresConfirmation: boolean
        schemaVersion: string
        lastUpdated: string
      }
    }
  | { type: 'SET_REQUEST_VALUES'; payload: Record<string, unknown> }
  | { type: 'SET_RESPONSE_VALUES'; payload: Record<string, unknown> }
  | { type: 'SELECT_ACTION'; payload: TActions[number] | null }
  | { type: 'SELECT_RESPONSE_CODE'; payload: string | null }
  | { type: 'SELECT_TOOL'; payload: TToolNames | null }
  | { type: 'SET_ACTIVE_TAB'; payload: 'Request' | 'Response' }
  | { type: 'SET_IS_ACTION_CONFIRMED'; payload: boolean }
  | { type: 'ADVANCE_RESPONSE' }
  | { type: 'SET_CURRENT_RESPONSE_INDEX'; payload: number }

const stateReducer = (state: TReducerState, action: TReducerAction): TReducerState => {
  switch (action.type) {
    case 'SET_REQUIRES_CONFIRMATION_ACTION_TYPE': {
      return { ...state, currentActionType: action.payload }
    }
    case 'ADD_DATA': {
      const updatedDataList = [...state.dataList, { ...action.payload, responseValues: {} }]
      return {
        ...state,
        currentActionType:
          state.dataList.length === 0
            ? action.payload.requiresConfirmation
            : state.currentActionType,
        dataList: updatedDataList,
        selectedToolName: null,
        selectedAction: null,
        selectedResponseCode: null,
        requestValues: null,
        responseValues: {},
      }
    }
    case 'SELECT_TOOL': {
      return {
        ...state,
        selectedToolName: action.payload,
        selectedAction: null,
        selectedResponseCode: null,
        requestValues: null,
        responseValues: {},
      }
    }
    case 'SELECT_ACTION': {
      return {
        ...state,
        selectedAction: action.payload,
        requestValues: !action.payload
          ? null
          : action.payload.parameters.reduce<
              Record<TParameters[number]['parameter_name'], unknown>
            >((acc, curr) => {
              acc[curr.parameter_name] = null
              return acc
            }, {}),
        responseValues: {},
        selectedResponseCode: null,
      }
    }
    case 'SELECT_RESPONSE_CODE': {
      return {
        ...state,
        selectedResponseCode: action.payload,
        responseValues: {},
      }
    }
    case 'SET_REQUEST_VALUES': {
      return {
        ...state,
        requestValues: {
          ...state.requestValues,
          ...action.payload,
        },
      }
    }
    case 'SET_RESPONSE_VALUES': {
      return {
        ...state,
        responseValues: {
          ...state.responseValues,
          ...action.payload,
        },
      }
    }
    case 'SET_ACTIVE_TAB': {
      const activeData = state.dataList[state.currentResponseIndex]

      return {
        ...state,
        activeTab: action.payload,
        selectedAction: state.isMultiActionMode
          ? tools
              .find((t) => t.tool_name === activeData?.tool)
              ?.actions.find((a) => a.action_name === activeData?.action) ?? null
          : state.selectedAction,
      }
    }
    case 'SET_IS_ACTION_CONFIRMED': {
      const activeData = state.dataList[state.currentResponseIndex]

      return {
        ...state,
        isActionConfirmed: action.payload,
        selectedAction: state.isMultiActionMode
          ? tools
              .find((t) => t.tool_name === activeData?.tool)
              ?.actions.find((a) => a.action_name === activeData?.action) ?? null
          : state.selectedAction,
      }
    }
    case 'ADVANCE_RESPONSE': {
      const dataAtNextIndex = state.dataList[state.currentResponseIndex + 1]

      return {
        ...state,
        currentResponseIndex: state.currentResponseIndex + 1,
        dataList: state.dataList.map((data, index) =>
          index === state.currentResponseIndex
            ? { ...data, responseValues: state.responseValues }
            : data
        ),
        responseValues: {},
        selectedResponseCode: null,
        selectedToolName:
          tools.find((t) => t.tool_name === dataAtNextIndex?.tool)?.tool_name ?? null,
        selectedAction:
          tools
            .find((t) => t.tool_name === dataAtNextIndex?.tool)
            ?.actions.find((a) => a.action_name === dataAtNextIndex?.action) ?? null,
      }
    }
    case 'SET_MULTIACTION_MODE': {
      return {
        ...state,
        isMultiActionMode: action.payload,
      }
    }
    case 'SET_CURRENT_RESPONSE_INDEX': {
      return {
        ...state,
        currentResponseIndex: action.payload,
      }
    }
    default:
      return state
  }
}

export { stateReducer }
