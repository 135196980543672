import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const HELP_DESK_URL =
  'https://invisible.atlassian.net/servicedesk/customer/portal/6/group/28/create/88' // can be public and is mostly static

const errorFallbackStyles = {
  container: {
    height: 'calc(100vh - 20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  errorIcon: {
    fontSize: 40,
    color: 'black',
  },
  submitTicketButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  tryAgainButton: {
    backgroundColor: 'white',
    borderColor: 'gray',
    color: 'black',
    '&:hover': {
      borderColor: 'gray',
      backgroundColor: 'white',
    },
  },
}

const DefaultFallback = () => (
  <MUIThemeProvider>
    <Container maxWidth='sm' sx={errorFallbackStyles.container}>
      <Stack spacing={3}>
        <ErrorOutlineOutlinedIcon sx={errorFallbackStyles.errorIcon} />

        <Typography variant='h5'>Oops! An unexpected error occurred.</Typography>

        <Typography variant='body2'>
          We use Sentry to catch errors, but your input through a support ticket can help us
          understand and fix the issue sooner!
        </Typography>

        <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            href={HELP_DESK_URL}
            target='_blank'
            rel='noopener noreferrer'
            sx={errorFallbackStyles.submitTicketButton}>
            Submit a ticket
          </Button>

          <Button
            variant='outlined'
            onClick={() => window.location.reload()}
            sx={errorFallbackStyles.tryAgainButton}>
            Try again
          </Button>
        </Stack>
      </Stack>
    </Container>
  </MUIThemeProvider>
)

export default DefaultFallback
