import { SVGProps } from 'react'

function ArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      width='36'
      height='36'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75'
      />
    </svg>
  )
}

export default ArrowDown
