import { z } from '@invisible/zod'

import { FORMULA_NAMES } from '../../../constants/formula'
import { numericComparisonSchema } from '../numeric'

/**
 * Takes two numeric arguments and returns true if the first is greater than the second.
 */
const gtFormulaSchema = numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.GT) })

/**
 * Takes two numeric arguments and returns true if the first is greater than the second.
 */
type TGtFormula = z.infer<typeof gtFormulaSchema>

/**
 * Takes two numeric arguments and returns true if the first is greater than or equal to the second.
 */
const gteFormulaSchema = numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.GTE) })

/**
 * Takes two numeric arguments and returns true if the first is greater than or equal to the second.
 */
type TGteFormula = z.infer<typeof gteFormulaSchema>

/**
 * Takes two numeric arguments and returns true if the first is less than the second.
 */
const ltFormulaSchema = numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.LT) })

/**
 * Takes two numeric arguments and returns true if the first is less than the second.
 */
type TLtFormula = z.infer<typeof ltFormulaSchema>

/**
 * Takes two numeric arguments and returns true if the first is less than or equal to the second.
 */
const lteFormulaSchema = numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.LTE) })

/**
 * Takes two numeric arguments and returns true if the first is less than or equal to the second.
 */
type TLteFormula = z.infer<typeof lteFormulaSchema>

/**
 * All defined numeric predicates.
 * This schema differs from the generic numericPredicateSchema.
 */
const definedNumericPredicateSchema = z.union([
  gtFormulaSchema,
  gteFormulaSchema,
  ltFormulaSchema,
  lteFormulaSchema,
])

/**
 * All defined numeric predicates.
 * This schema differs from the generic numericPredicateSchema.
 */
type TDefinedNumericPredicate = z.infer<typeof definedNumericPredicateSchema>

export {
  definedNumericPredicateSchema,
  gteFormulaSchema,
  gtFormulaSchema,
  lteFormulaSchema,
  ltFormulaSchema,
}
export type { TDefinedNumericPredicate, TGteFormula, TGtFormula, TLteFormula, TLtFormula }
