import React, { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const Legend: React.FC<any> = ({ map, gradesProp, colorTheme }) => {
  const [gradesArray, setGradesArray] = useState([0, 10, 20, 50, 100, 200, 500, 1000])
  const [L, setL] = useState<any>(null)
  gradesProp && setGradesArray(gradesProp)
  const getColor = (d: number) =>
    d > 1000
      ? colorTheme[8]
      : d > 500
      ? colorTheme[7]
      : d > 200
      ? colorTheme[6]
      : d > 100
      ? colorTheme[5]
      : d > 50
      ? colorTheme[4]
      : d > 20
      ? colorTheme[3]
      : d > 10
      ? colorTheme[2]
      : colorTheme[1]

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const L = require('leaflet')
      setL(L)
    }
  }, [])
  useEffect(() => {
    if (!L) return
    const timer = setTimeout(() => {
      const legend = L.control({ position: 'bottomright' })

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend'),
          grades = gradesArray

        for (let i = 0; i < grades.length; i++) {
          div.innerHTML +=
            '<i style="background:' +
            getColor(grades[i] + 1) +
            '"></i> ' +
            grades[i] +
            (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+')
        }

        return div
      }

      legend.addTo(map)
    }, 1000)
    return () => clearTimeout(timer)
  }, [map])
  return null
}
export default Legend
