import { SVGProps } from 'react'
function Saas(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511 511' width={20} height={20} {...props}>
      <g>
        <path
          d='M343.5,292h-176c-21.78,0-39.5,17.72-39.5,39.5v96c0,21.78,17.72,39.5,39.5,39.5h176c21.78,0,39.5-17.72,39.5-39.5v-96   C383,309.72,365.28,292,343.5,292z M368,427.5c0,13.509-10.991,24.5-24.5,24.5h-176c-13.509,0-24.5-10.991-24.5-24.5v-96   c0-13.509,10.991-24.5,24.5-24.5h176c13.509,0,24.5,10.991,24.5,24.5V427.5z'
          fill='currentColor'
        />
        <path
          d='M220.803,334.197c-2.929-2.929-7.678-2.929-10.606,0l-40,40c-2.929,2.929-2.929,7.678,0,10.606l40,40   c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.606L186.106,379.5l34.697-34.697   C223.732,341.875,223.732,337.125,220.803,334.197z'
          fill='currentColor'
        />
        <path
          d='M300.803,334.197c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606l34.697,34.697l-34.697,34.697   c-2.929,2.929-2.929,7.678,0,10.606c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197l40-40   c2.929-2.929,2.929-7.678,0-10.606L300.803,334.197z'
          fill='currentColor'
        />
        <path
          d='M255.5,388c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3c0,1.97,0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3c0-1.98-0.8-3.91-2.2-5.3C259.41,388.8,257.47,388,255.5,388z'
          fill='currentColor'
        />
        <path
          d='M279.5,388c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3c0,1.97,0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3c0-1.97-0.8-3.91-2.2-5.3C283.41,388.8,281.47,388,279.5,388z'
          fill='currentColor'
        />
        <path
          d='M231.5,388c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3c0,1.97,0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3c0-1.97-0.8-3.91-2.2-5.3C235.41,388.8,233.48,388,231.5,388z'
          fill='currentColor'
        />
        <path
          d='M493.609,207.138c-9.949-13.301-23.718-23.639-39.172-29.504c-2.354-26.829-14.217-51.7-33.736-70.504   C399.864,87.055,372.447,76,343.5,76c-9.62,0-19.168,1.238-28.447,3.684C289.97,56.639,257.631,44,223.5,44   c-27.572,0-54.102,8.246-76.719,23.846c-20.784,14.335-37.015,33.94-47.164,56.907C43.39,130.734,0,178.501,0,235.5   c0,28.288,10.609,55.263,29.874,75.957c19.163,20.585,45.109,33.092,73.059,35.218c4.13,0.312,7.733-2.779,8.047-6.91   c0.314-4.13-2.779-7.733-6.91-8.047c-24.179-1.839-46.63-12.665-63.217-30.482C24.181,283.328,15,259.982,15,235.5   c0-50.68,39.642-92.925,90.248-96.176c2.882-0.185,5.402-2.008,6.479-4.688c8.871-22.075,23.938-40.9,43.571-54.443   C175.399,66.329,198.983,59,223.5,59c31.649,0,61.569,12.227,84.248,34.427c1.962,1.921,4.818,2.614,7.444,1.812   C324.369,92.426,333.894,91,343.5,91c51.814,0,94.107,40.591,96.284,92.409c0.133,3.166,2.242,5.906,5.267,6.847   C475.525,199.729,496,227.556,496,259.5c0,18.1-6.707,35.429-18.885,48.797c-12.099,13.281-28.562,21.568-46.356,23.335   c-0.587,0.059-1.153,0.184-1.69,0.367h-21.571c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H431.5c1.15,0,2.24-0.259,3.214-0.722   c20.538-2.631,39.452-12.47,53.49-27.879C502.904,302.262,511,281.345,511,259.5C511,240.455,504.986,222.349,493.609,207.138z'
          fill='currentColor'
        />
        <path
          d='M62.985,181.923c-3.08-2.77-7.822-2.52-10.592,0.559C39.243,197.099,32,215.927,32,235.5   c0,15.741,4.665,31.01,13.49,44.154c1.447,2.156,3.818,3.32,6.233,3.32c1.438,0,2.891-0.413,4.174-1.274   c3.439-2.309,4.355-6.968,2.046-10.407C50.784,260.63,47,248.252,47,235.5c0-15.862,5.875-31.127,16.544-42.985   C66.314,189.436,66.064,184.693,62.985,181.923z'
          fill='currentColor'
        />
        <path
          d='M275.105,107.646c1.328,0.959,2.864,1.42,4.385,1.42c2.327,0,4.62-1.079,6.086-3.109c2.425-3.358,1.669-8.046-1.688-10.472   C266.24,82.738,245.357,76,223.5,76c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5C242.184,91,260.029,96.756,275.105,107.646z'
          fill='currentColor'
        />
        <path
          d='M102.677,156.609c-6.03,0.667-11.979,2.028-17.68,4.046c-3.905,1.382-5.95,5.668-4.568,9.573c1.089,3.078,3.982,5,7.07,5   c0.83,0,1.675-0.139,2.502-0.432c4.617-1.634,9.436-2.737,14.324-3.277c4.117-0.456,7.085-4.162,6.63-8.279   C110.501,159.122,106.802,156.158,102.677,156.609z'
          fill='currentColor'
        />
        <path
          d='M357.848,124.593c0.557,0.126,1.114,0.186,1.662,0.186c3.427,0,6.521-2.364,7.309-5.847c0.914-4.04-1.621-8.056-5.661-8.97   C355.401,108.66,349.46,108,343.5,108c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5C348.349,123,353.176,123.536,357.848,124.593z   '
          fill='currentColor'
        />
        <path
          d='M427.793,217.456c1.168,0.662,2.438,0.977,3.691,0.977c2.613,0,5.151-1.368,6.532-3.803   c2.042-3.603,0.777-8.18-2.827-10.223c-7.401-4.195-12.034-11.778-12.391-20.284c-0.958-22.817-11.629-44.116-29.277-58.436   c-3.216-2.61-7.94-2.119-10.549,1.098c-2.61,3.216-2.119,7.939,1.098,10.549c14.31,11.612,22.963,28.895,23.742,47.418   C408.388,198.464,415.857,210.69,427.793,217.456z'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export default Saas
