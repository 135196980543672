import type { TMatch } from '../types'
import {
  escapeRegex,
  getColorHighlight,
  getErrorString,
  getTextNodes,
  identifyIssueType,
} from './common'
const HIGHLIGHT_CLASSNAME = 'highlighted'

export const highlightTextNodeErrors = ({ matches }: { matches: TMatch[] }) => {
  const textNodes = getTextNodes()

  textNodes.forEach((node) => {
    const originalText = node.nodeValue
    let newText = originalText
    if (newText !== null) {
      matches.forEach((match) => {
        const errorString = getErrorString(match)
        const regex = new RegExp(`\\b${escapeRegex(errorString)}\\b`, 'gi')
        newText = (newText as string).replace(regex, (stringMatch) => {
          const parent = node.parentNode as HTMLElement

          if (parent?.classList.contains(HIGHLIGHT_CLASSNAME)) {
            return stringMatch // Skip highlighting this time
          }

          // Check if the string is already wrapped inside a highlighted span
          if (/span class="highlighted"/.test(stringMatch)) {
            return stringMatch // If already highlighted, skip
          }

          const issueType = identifyIssueType(match)

          const color = getColorHighlight(issueType)

          return `<span class="${HIGHLIGHT_CLASSNAME}" spellcheck="false" style="text-decoration: underline; text-decoration-color: ${color}; text-decoration-thickness: 3px; position: relative; cursor: pointer;" title="${match.replacements[0].value}">${stringMatch}</span>`
        })
      })

      if (newText !== originalText) {
        const span = document.createElement('span')
        span.innerHTML = newText
        node.parentNode?.replaceChild(span, node)
      }
    }
  })
}
