import { UuidSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

const schema = z
  .object({
    mapStepId: UuidSchema,
  })
  .strict()
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema.strip()
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates EndMapStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates EndMapStepMeta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({ endMapStepMeta, data }: { endMapStepMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(endMapStepMeta, updateArgs)
}

export { create, createArgsSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
