import { IExportConfigurationDataQuery } from '@invisible/concorde/gql-client'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import { z } from 'zod'

import { TReducerState } from './reducer'
import { TFormSchema } from './schema'

interface IProps {
  state: TReducerState
  bases: IExportConfigurationDataQuery['process']['bases']
  register: UseFormRegister<TFormSchema>
  errors: FieldErrors<TFormSchema>
  control: Control<TFormSchema, object>
}

const ExportDetails = ({ state, bases, register, errors, control }: IProps) => {
  const selectableBases = useMemo(() => bases.map((b) => ({ label: b.name, value: b.id })), [bases])

  return (
    <Stack gap='16px' width='500px'>
      <Typography fontWeight='bold' variant='body1'>
        Provide details about your export.
      </Typography>
      <TextField
        label='Export name *'
        placeholder='Enter name...'
        size='small'
        error={!!errors.name}
        helperText={errors.name && errors.name.message}
        {...register('name', {
          required: true,
        })}
      />
      <TextField
        rows={4}
        multiline
        label='Description'
        placeholder='Enter comments or description associated with this export...'
        size='small'
        defaultValue={state.description}
        {...register('description', {
          required: false,
        })}
      />

      <Controller
        control={control}
        name='selectedBase'
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(_, item) => {
              onChange(item)
            }}
            value={value}
            options={selectableBases}
            // Base cannot be changed for existing export configurations
            disabled={Boolean(state.exportConfigurationId)}
            isOptionEqualToValue={(left, right) => left?.value === right?.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select base *'
                placeholder='Select a base to export data from...'
                error={!!errors.selectedBase}
                helperText={errors.selectedBase && errors.selectedBase.message}
              />
            )}
            size='small'
            disableClearable
          />
        )}
      />
    </Stack>
  )
}

export { ExportDetails }
