import { SmallCheckbox } from '@invisible/ui/form'
import {
  AGENT_ADMIN_ROLE_ID,
  AGENT_OPERATOR_ROLE_ID,
  AGENT_SQUAD_LEAD_ROLE_ID,
  AGENT_TEAM_LEAD_ROLE_ID,
  CLIENT_ADMIN_ROLE_ID,
  CLIENT_LEAD_ROLE_ID,
  CLIENT_OPERATOR_ROLE_ID,
} from '@invisible/ultron/shared'
import { unique } from 'radash'
import { FC, useCallback } from 'react'

const GenericRoledIds = [
  AGENT_ADMIN_ROLE_ID,
  AGENT_OPERATOR_ROLE_ID,
  AGENT_SQUAD_LEAD_ROLE_ID,
  AGENT_TEAM_LEAD_ROLE_ID,
  CLIENT_ADMIN_ROLE_ID,
  CLIENT_LEAD_ROLE_ID,
  CLIENT_OPERATOR_ROLE_ID,
]

const clientHierarchy = [CLIENT_OPERATOR_ROLE_ID, CLIENT_LEAD_ROLE_ID, CLIENT_ADMIN_ROLE_ID]
const agentHierarchy = [
  AGENT_OPERATOR_ROLE_ID,
  AGENT_SQUAD_LEAD_ROLE_ID,
  AGENT_TEAM_LEAD_ROLE_ID,
  AGENT_ADMIN_ROLE_ID,
]

type TGenericRoleId = typeof GenericRoledIds[number]

interface IGenericRolesSelectorProps {
  canChangePermissions: boolean
  editedPermissions: string[]
  setIsFreshNew?: (isFreshNew: boolean) => void
  setIsPrivate?: (privacy: boolean) => void
  setEditedPermissions: (permissions: string[]) => void
  isPrivate?: boolean
  vertical?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const GenericRolesSelector: FC<IGenericRolesSelectorProps> = ({
  canChangePermissions,
  editedPermissions,
  setIsFreshNew,
  setIsPrivate,
  setEditedPermissions,
  isPrivate,
  vertical,
}) => {
  const handleAgentGroupChange = useCallback(
    ({ selected }: { selected: boolean }) => {
      setIsFreshNew?.(false)
      if (selected) {
        setIsPrivate?.(false)
        setEditedPermissions(unique([...(editedPermissions ?? []), ...agentHierarchy]))
        return
      }

      setEditedPermissions([
        ...(editedPermissions ?? []).filter(
          (permissionId) => !(agentHierarchy as string[]).includes(permissionId)
        ),
      ])
    },
    [editedPermissions, setEditedPermissions, setIsFreshNew, setIsPrivate]
  )

  const handleClientGroupChange = useCallback(
    ({ selected }: { selected: boolean }) => {
      setIsFreshNew?.(false)
      if (selected) {
        setIsPrivate?.(false)
        setEditedPermissions(unique([...(editedPermissions ?? []), ...clientHierarchy]))
        return
      }

      setEditedPermissions([
        ...(editedPermissions ?? []).filter(
          (permissionId) => !(clientHierarchy as string[]).includes(permissionId)
        ),
      ])
    },
    [editedPermissions, setEditedPermissions, setIsFreshNew, setIsPrivate]
  )

  const handlePrivateChange = useCallback(
    ({ selected }: { selected: boolean }) => {
      setIsFreshNew?.(false)
      if (selected) {
        setEditedPermissions([])
        setIsPrivate?.(true)
        return
      }

      setIsPrivate?.(false)
    },
    [setEditedPermissions, setIsFreshNew, setIsPrivate]
  )

  const handlePermissionsChange = useCallback(
    ({ id, selected }: { id: TGenericRoleId; selected: boolean }) => {
      setIsFreshNew?.(false)
      const clientIndex = clientHierarchy.findIndex((clientRoleId) => id === clientRoleId)
      const agentIndex = agentHierarchy.findIndex((agentRoleId) => id === agentRoleId)

      if (clientIndex >= 0 && selected) {
        setIsPrivate?.(false)
        setEditedPermissions(
          unique([...(editedPermissions ?? []), ...clientHierarchy.slice(clientIndex)])
        )
        return
      }
      if (agentIndex >= 0 && selected) {
        setIsPrivate?.(false)
        setEditedPermissions(
          unique([...(editedPermissions ?? []), ...agentHierarchy.slice(agentIndex)])
        )
        return
      }

      if (agentIndex >= 0 && !selected) {
        setEditedPermissions([
          ...(editedPermissions ?? []).filter(
            (permissionId) =>
              !(agentHierarchy.slice(0, agentIndex + 1) as string[]).includes(permissionId)
          ),
        ])
        return
      }

      if (clientIndex >= 0 && !selected) {
        setEditedPermissions([
          ...(editedPermissions ?? []).filter(
            (permissionId) =>
              !(clientHierarchy.slice(0, clientIndex + 1) as string[]).includes(permissionId)
          ),
        ])
        return
      }
    },
    [editedPermissions, setEditedPermissions, setIsFreshNew, setIsPrivate]
  )

  return (
    <div className={vertical ? '' : 'flex'}>
      <div className='w-1/3'>
        <div className='flex pt-3 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={
              editedPermissions.includes(CLIENT_ADMIN_ROLE_ID) &&
              editedPermissions.includes(CLIENT_LEAD_ROLE_ID) &&
              editedPermissions.includes(CLIENT_OPERATOR_ROLE_ID)
            }
            onClick={(selected) => handleClientGroupChange({ selected })}
          />
          <div>Client</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(CLIENT_ADMIN_ROLE_ID)}
            onClick={(selected) => handlePermissionsChange({ id: CLIENT_ADMIN_ROLE_ID, selected })}
          />
          <div>Admin</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(CLIENT_LEAD_ROLE_ID)}
            onClick={(selected) => handlePermissionsChange({ id: CLIENT_LEAD_ROLE_ID, selected })}
          />
          <div>Lead</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(CLIENT_OPERATOR_ROLE_ID)}
            onClick={(selected) =>
              handlePermissionsChange({ id: CLIENT_OPERATOR_ROLE_ID, selected })
            }
          />
          <div>Operator</div>
        </div>
      </div>
      <div className='w-1/3'>
        <div className='flex pt-3 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={
              editedPermissions.includes(AGENT_ADMIN_ROLE_ID) &&
              editedPermissions.includes(AGENT_TEAM_LEAD_ROLE_ID) &&
              editedPermissions.includes(AGENT_SQUAD_LEAD_ROLE_ID) &&
              editedPermissions.includes(AGENT_OPERATOR_ROLE_ID)
            }
            onClick={(selected) => handleAgentGroupChange({ selected })}
          />
          <div>Agent</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(AGENT_ADMIN_ROLE_ID)}
            onClick={(selected) => handlePermissionsChange({ id: AGENT_ADMIN_ROLE_ID, selected })}
          />
          <div>Admin</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(AGENT_TEAM_LEAD_ROLE_ID)}
            onClick={(selected) =>
              handlePermissionsChange({ id: AGENT_TEAM_LEAD_ROLE_ID, selected })
            }
          />
          <div>Team Lead</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(AGENT_SQUAD_LEAD_ROLE_ID)}
            onClick={(selected) =>
              handlePermissionsChange({ id: AGENT_SQUAD_LEAD_ROLE_ID, selected })
            }
          />
          <div>Squad Lead</div>
        </div>
        <div className='flex pl-4 pb-2'>
          <SmallCheckbox
            disabled={!canChangePermissions}
            checked={editedPermissions.includes(AGENT_OPERATOR_ROLE_ID)}
            onClick={(selected) =>
              handlePermissionsChange({ id: AGENT_OPERATOR_ROLE_ID, selected })
            }
          />
          <div>Operator</div>
        </div>
      </div>
      {setIsPrivate ? (
        <div className='w-1/3'>
          <div className='flex py-2'>
            <SmallCheckbox
              disabled={!canChangePermissions}
              checked={isPrivate}
              onClick={(selected) => handlePrivateChange({ selected })}
            />
            <div>Only Me</div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export { GenericRolesSelector }
