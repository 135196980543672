import { UuidSchema, z } from '@invisible/zod'

/**
 * A pair of baseVariableId and key. The key matches up with the trigger's payload.
 */
const schema = z
  .object({
    baseVariableId: UuidSchema,
    key: z.string(),
  })
  .strict()
/**
 * A pair of baseVariableId and key. The key matches up with the trigger's payload.
 */
type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
