import { SVGProps } from 'react'

function Progress(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14 8C14 11.3137 11.4576 14 8 14C6 14 3.5 12.5 2.5 10.5C7.73954 8 7.73954 8 7.73954 8C7.73954 8 7.73954 8 7.73954 2C11.1971 2 14 4.68629 14 8Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58172 3.58173 0 8 0C12.4183 0 16 3.58172 16 8ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13403 1 1 4.13401 1 8C1 11.866 4.13403 15 8 15Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Progress
