import { SVGProps } from 'react'
function CalendarCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      viewBox='0 0 30 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M21.1858 17.7278C21.5877 17.3491 21.6066 16.7162 21.2278 16.3142C20.8491 15.9123 20.2162 15.8934 19.8142 16.2722L14.6648 21.1239L12.1882 18.7745C11.7875 18.3944 11.1546 18.4111 10.7745 18.8118C10.3944 19.2125 10.4111 19.8454 10.8118 20.2255L13.9743 23.2255C14.3592 23.5906 14.9621 23.5916 15.3483 23.2278L21.1858 17.7278Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 3C22.5523 3 23 3.44772 23 4V5H26C27.1046 5 28 5.89543 28 7V27C28 28.1046 27.1046 29 26 29H6C4.89543 29 4 28.1046 4 27V7C4 5.89543 4.89543 5 6 5H9V4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4V5H21V4C21 3.44772 21.4477 3 22 3ZM23 7H26V11H6V7H9V8C9 8.55228 9.44772 9 10 9C10.5523 9 11 8.55228 11 8V7H21V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V7ZM26 13H6V27H26V13Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CalendarCheck
