/* eslint-disable @typescript-eslint/ban-types */
import { subject } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'
import { useSession } from '@invisible/authentication/client'
import {
  AdditionalPermissionSubject,
  createAbilityFromPermissions,
  TAbility,
  Unwrap,
} from '@invisible/authorization/internal'
import { useLoggedInUserPermissions } from '@invisible/common/components/process-base'
import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { withTRPC } from '@invisible/trpc/client'
import { LoadingModal } from '@invisible/ui/loading-modal'
import { PermissionAction, PermissionSubject as PS, PrismaClient } from '@invisible/ultron/prisma'
import { first, keys } from 'lodash/fp'
import { createContext, FC, useContext, useMemo } from 'react'

export type PermissionSubject = Exclude<PS, 'all' | AdditionalPermissionSubject>

type ForcedSubjectTAbility =
  | (TAbility & {
      can: (
        action: PermissionAction,
        subject: AdditionalPermissionSubject | PermissionSubject,
        conditions: any,
        field?: string
      ) => boolean
      cannot: (
        action: PermissionAction,
        subject: AdditionalPermissionSubject | PermissionSubject,
        conditions: any,
        field?: string
      ) => boolean
    })
  | undefined

type CanProps = { I: PermissionAction; Field?: string } & (
  | {
      a?: never
      not?: boolean
      an?: never
      this: {
        [key in PermissionSubject]?: Partial<
          Unwrap<ReturnType<PrismaClient[Uncapitalize<key>]['findUnique']>>
        >
      }
    }
  | {
      this?: never
      a?: PermissionSubject | AdditionalPermissionSubject
      not?: boolean
    }
  | {
      this?: never
      an?: PermissionSubject | AdditionalPermissionSubject
      not?: boolean
    }
) & { children: any }

const initialState = createAbilityFromPermissions<
  PermissionAction,
  AdditionalPermissionSubject | PermissionSubject
>([])

const AbilityContext = createContext<
  // eslint-disable-next-line @typescript-eslint/ban-types
  [Can: FC<CanProps>, ability?: ForcedSubjectTAbility, isLoading?: boolean]
>([() => null, initialState, false])

export const AbilityContextProvider = withTRPC(({ children }) => {
  const { data: session } = useSession()

  const [loggedInUser, isUserLoading] = useLoggedInUser()
  const { data: permissions, isLoading: isPermissionsLoading } = useLoggedInUserPermissions({
    onlyBaseView: false,
    refetchBehaviour: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 15 * 1000 * 60, // 15min
      keepPreviousData: true,
    },
  })

  const isLoading = isUserLoading || isPermissionsLoading

  const ability = useMemo(
    () =>
      permissions &&
      createAbilityFromPermissions(permissions as any, {
        ...session,
        userId: loggedInUser?.id ?? '',
      }), // for some reason ts thinks this is type never
    [permissions, loggedInUser]
  )

  const CanComponent = useMemo(() => {
    if (ability) return createCanBoundTo(ability)

    // return happy path if permissions not loaded yet for SSG purposes
    return ({ children, not }: CanProps) => {
      if (not) return null
      return <LoadingModal />
    }
  }, [ability])

  const Can = useMemo(
    () => (props: CanProps) => {
      if (keys(props?.this).length > 1)
        throw new Error(`'props.this' needs to be an object with key being a permission subject`)
      const type = first(keys(props?.this)) as PermissionSubject
      const _this = type && subject(type, props.this?.[type] as any)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <CanComponent {...props} this={_this} />
    },
    [CanComponent]
  )

  const abilityWithForcedSubject: ForcedSubjectTAbility = useMemo(() => {
    if (!ability) return undefined
    return {
      ...ability,
      can: (
        action: PermissionAction,
        sub: AdditionalPermissionSubject | PermissionSubject,
        conditions: any,
        field?: string
      ) => {
        const forcedSub = conditions ? subject(sub, conditions) : sub
        return (ability as TAbility).can(action, forcedSub, field)
      },
      cannot: (
        action: PermissionAction,
        sub: AdditionalPermissionSubject | PermissionSubject,
        conditions: any,
        field?: string
      ) => {
        const forcedSub = conditions ? subject(sub, conditions) : sub
        return (ability as TAbility).cannot(action, forcedSub, field)
      },
    } as unknown as ForcedSubjectTAbility
  }, [ability])

  return (
    <AbilityContext.Provider value={[Can, abilityWithForcedSubject, isLoading]}>
      {children}
    </AbilityContext.Provider>
  )
})

export const useAbilityContext = () => useContext(AbilityContext)

export const withAbilityContext =
  // eslint-disable-next-line @typescript-eslint/ban-types


    <T,>(Component: FC<T>): FC<T> =>
    (props) =>
      (
        <AbilityContextProvider>
          <Component {...props} />
        </AbilityContextProvider>
      )
