import { theme } from '@invisible/ui/themes'

export const CHILL_THEME = [
  theme.purpleScale[1],
  theme.purpleScale[2],
  theme.purpleScale[3],
  theme.purpleScale[4],
  theme.purpleScale[5],
  theme.purpleScale[6],
  theme.purpleScale[7],
  theme.purpleScale[8],
  theme.purpleScale[9],
  theme.purpleScale[12],
]

export const BRIGHT_THEME = [
  theme.colors.darkBlue,
  theme.colors.magenta,
  theme.colors.blue,
  theme.colors.extendedBrandOrange,
  theme.colors.neonBlue,
  theme.colors.extendedBrandMagenta,
  theme.colors.aqua,
  theme.colors.indigo,
  theme.colors.orange,
  theme.colors.red,
]
