import {
  DragHandleVerticalIcon,
  EyeCancelledIcon,
  EyeOpenedIcon,
  FilledFilterIcon,
  FilterIcon,
} from '@invisible/ui/icons'
import { FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'

const getItemStyle = ({
  isDragging,
  draggableStyle,
}: {
  isDragging: boolean
  draggableStyle: any
}) => ({
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle,
  boxShadow: isDragging ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
  background: isDragging ? 'white' : 'transparent',
  zIndex: isDragging ? 3000000000 : 10, // to be on top of radix popover
})

type TColumn = {
  id: string
  name: string
}

interface IColumnProps {
  index: number
  column: {
    id: string
    name: string
    visibility: boolean
    filterable: boolean
    type:
      | 'step'
      | 'baseRunStatus'
      | 'baseVariable'
      | 'baseRunId'
      | 'createdAt'
      | 'deadline'
      | 'deliveredAt'
      | 'timeLeft'
      | 'parentBaseRunId'
      | 'childBase'
  }
  setColumnHidden: ({ column }: { column: TColumn }) => void
  setColumnVisible: ({ column }: { column: TColumn }) => void
  addFiltrableColumn: ({ column }: { column: TColumn }) => void
  removeFiltrableColumn: ({ column }: { column: TColumn }) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Column: FC<IColumnProps> = ({
  column,
  setColumnHidden,
  setColumnVisible,
  addFiltrableColumn,
  removeFiltrableColumn,
  index,
}) => {
  const isFilterable =
    column.filterable ||
    column.type === 'step' ||
    column.type === 'createdAt' ||
    column.type === 'deliveredAt' ||
    column.type === 'parentBaseRunId' ||
    column.type === 'deadline' ||
    column.type === 'baseRunStatus'

  const isFilterableToggleLocked =
    column.type === 'step' ||
    column.type === 'createdAt' ||
    column.type === 'deliveredAt' ||
    column.type === 'deadline' ||
    column.type === 'timeLeft' ||
    column.type === 'parentBaseRunId' ||
    column.type === 'childBase' ||
    column.type === 'baseRunId' ||
    column.type === 'baseRunStatus'

  return (
    <Draggable key={column.id} draggableId={column.id} index={index}>
      {(provided, snapshot) => (
        <div
          className='flex items-center'
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle({
            isDragging: snapshot.isDragging,
            draggableStyle: provided.draggableProps.style,
          })}>
          <div {...provided.dragHandleProps}>
            <DragHandleVerticalIcon className='mr-2 mt-1' height={15} width={15} />
          </div>
          {isFilterable ? (
            <FilledFilterIcon
              aria-disabled={isFilterableToggleLocked}
              className={`mr-2  ${isFilterableToggleLocked ? 'cursor-default' : 'cursor-pointer'}`}
              onClick={() => {
                if (isFilterableToggleLocked) {
                  return
                }
                removeFiltrableColumn({ column: column })
              }}
              height={20}
              width={20}
            />
          ) : (
            <FilterIcon
              aria-disabled={isFilterableToggleLocked}
              className={`mr-2  ${
                isFilterableToggleLocked ? 'cursor-default' : 'cursor-pointer'
              } text-gray-300`}
              onClick={() => {
                if (isFilterableToggleLocked) {
                  return
                }
                addFiltrableColumn({ column })
              }}
              height={20}
              width={20}
            />
          )}
          {column.visibility ? (
            <EyeOpenedIcon
              className='mr-2 cursor-pointer'
              onClick={() => {
                setColumnHidden({ column: column })
              }}
              height={20}
              width={20}
            />
          ) : (
            <EyeCancelledIcon
              className='grayscale-3 mr-2 cursor-pointer'
              onClick={() => {
                setColumnVisible({ column })
              }}
              height={20}
              width={20}
            />
          )}
          {column.name}
        </div>
      )}
    </Draggable>
  )
}
