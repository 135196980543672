import Tooltip from '@mui/material/Tooltip'
import { Instance } from '@popperjs/core'
import { MouseEvent, useEffect, useRef, useState } from 'react'

import { formatTime } from './helpers'

type VideoTimeLineProps = {
  duration: number
  currentTime: number
  onTimelineClick?: (seekTime: number) => void
}

export const VideoTimeline = ({ duration, currentTime, onTimelineClick }: VideoTimeLineProps) => {
  const [timelineWidth, setTimelineWidth] = useState(0)
  const [hoverTime, setHoverTime] = useState(0)
  const timelineRef = useRef<HTMLDivElement>(null)
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const popperRef = useRef<Instance>(null)

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    positionRef.current = { x: event.clientX, y: event.clientY }

    if (popperRef.current != null) {
      popperRef.current.update()
    }
    const clickPosition = event.nativeEvent.offsetX
    const clickPercentage = clickPosition / timelineWidth
    setHoverTime(clickPercentage * duration)
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    const clickPosition = event.nativeEvent.offsetX
    const clickPercentage = clickPosition / timelineWidth
    const seekTime = clickPercentage * duration
    onTimelineClick?.(seekTime)
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setTimelineWidth(entry.target.clientWidth)
      }
    })

    if (timelineRef.current) {
      observer.observe(timelineRef.current)
    }

    return () => {
      if (timelineRef.current) {
        observer.unobserve(timelineRef.current)
      }
    }
  }, [])

  return (
    <Tooltip
      title={formatTime(hoverTime)}
      placement='bottom'
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () =>
            timelineRef.current
              ? new DOMRect(
                  positionRef.current.x,
                  timelineRef.current?.getBoundingClientRect().y,
                  0,
                  0
                )
              : new DOMRect(0, 0, 0, 0),
        },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      }}>
      <div
        className='h-3 w-full cursor-pointer overflow-hidden bg-[#525252]'
        ref={timelineRef}
        onMouseMove={handleMouseMove}
        onClick={onTimelineClick ? handleClick : undefined}>
        <div
          className='h-full bg-blue-500'
          style={{ width: `${(currentTime / duration) * 100}%` }}></div>
      </div>
    </Tooltip>
  )
}
