import { TPeriodOption } from '@invisible/common/helpers'
import { IFilter, IInterval, TFilterValue } from '@invisible/common/types'
import { FILTER_OPERATOR, FILTER_TYPE } from '@invisible/ui/constants'
import { Dropdown } from '@invisible/ui/dropdown'
import { theme } from '@invisible/ui/mui-theme-v2'
import { TDatePresetEnum } from '@invisible/ultron/zod'
import { Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { findIndex, snakeCase, startCase } from 'lodash/fp'

import { FiltersListV2, renderFunction } from './FiltersListV2'
import { useStoryDynamicFilters } from './hooks'

const isIntervalDynamicFilter = (
  dynamicFilter: IFilter<TFilterValue>
): dynamicFilter is IFilter<IInterval> =>
  (dynamicFilter.valueType === 'DateTime' || dynamicFilter.valueType === 'Date') &&
  (!!(dynamicFilter.value as IInterval)?.preset ||
    !!(dynamicFilter.value as IInterval)?.from ||
    !!(dynamicFilter.value as IInterval)?.to)

// eslint-disable-next-line @typescript-eslint/ban-types
const StoryDynamicFilters: React.FC = () => {
  const { storyUnionFilters, setStoryUnionFilters } = useStoryDynamicFilters()

  const handleFilterUpdate = (updatedFilter: IFilter<TFilterValue>) => {
    const updatedFilters = [...(storyUnionFilters ?? [])]
    const index = findIndex((filter: IFilter<TFilterValue>) => filter.key === updatedFilter.key)(
      updatedFilters
    )
    updatedFilters[index] = { ...updatedFilter }
    setStoryUnionFilters(updatedFilters)
  }

  const handleIntervalChange = (interval: any, dynamicFilter: IFilter<TFilterValue>) => {
    const updatedFilter: IFilter<TFilterValue> = {
      ...dynamicFilter,
      operator: FILTER_OPERATOR.between,
      value: {
        from: interval?.from,
        to: interval?.to,
        preset: interval?.periodOption
          ? (snakeCase(interval.periodOption) as TDatePresetEnum)
          : undefined,
      },
    }

    handleFilterUpdate(updatedFilter)
  }

  const handleDropdownChange = (dynamicFilter: IFilter<TFilterValue>, selectedKey: string) => {
    const updatedFilter = {
      ...dynamicFilter,
      value: selectedKey,
    }

    handleFilterUpdate(updatedFilter)
  }

  const renderFilterItem = (dynamicFilter: IFilter<TFilterValue>, itemKey: number | string) => {
    if (isIntervalDynamicFilter(dynamicFilter)) {
      return (
        <div>
          <ThemeProvider theme={theme}>
            <Typography variant='body1' sx={{ my: 1, fontWeight: 'bold' }}>
              {dynamicFilter.label}
            </Typography>
          </ThemeProvider>
          {renderFunction.renderIntervalDropDown({
            onChange: (interval) => handleIntervalChange(interval, dynamicFilter),
            selectedInterval: {
              ...dynamicFilter.value,
              periodOption: dynamicFilter.value?.preset
                ? (startCase(dynamicFilter.value?.preset) as TPeriodOption)
                : undefined,
            },
            itemKey,
          })}
        </div>
      )
    } else if (dynamicFilter.filterName === FILTER_TYPE.CustomSelect) {
      const options = (dynamicFilter.selectOptions ?? []).map((option) => ({
        key: option,
        value: option,
      }))

      return (
        <div>
          <ThemeProvider theme={theme}>
            <Typography variant='body1' sx={{ my: 1, fontWeight: 'bold' }}>
              {dynamicFilter.label}
            </Typography>
          </ThemeProvider>
          <Dropdown
            onChange={({ key }) => handleDropdownChange(dynamicFilter, key)}
            options={options}
            selectedKey={
              (dynamicFilter.value as string | undefined) ?? dynamicFilter.defaultSelectValue
            }
            key={itemKey}
          />
        </div>
      )
    }

    return renderFunction.renderFilterWizard({
      dynamicFilter,
      onChange: handleFilterUpdate,
      itemKey,
    })
  }

  return <FiltersListV2 filters={storyUnionFilters ?? []} renderItem={renderFilterItem} />
}

export { StoryDynamicFilters }
