import { SVGProps } from 'react'

function ArrowInSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.75 15.1648C8.75 15.1648 7.5 15.1648 7.5 13.9426C7.5 12.7204 8.75 9.05373 13.75 9.05373C18.75 9.05373 20 12.7204 20 13.9426C20 15.1648 18.75 15.1648 18.75 15.1648H8.75ZM13.75 7.8315C14.7446 7.8315 15.6984 7.44519 16.4017 6.75756C17.1049 6.06993 17.5 5.1373 17.5 4.16484C17.5 3.19238 17.1049 2.25974 16.4017 1.57211C15.6984 0.884477 14.7446 0.498169 13.75 0.498169C12.7554 0.498169 11.8016 0.884477 11.0983 1.57211C10.3951 2.25974 10 3.19238 10 4.16484C10 5.1373 10.3951 6.06993 11.0983 6.75756C11.8016 7.44519 12.7554 7.8315 13.75 7.8315Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.53132 15.1648C5.99041 15.1648 6.30208 14.6842 6.26085 14.2371C6.25185 14.1395 6.2482 14.0411 6.25 13.9426C6.25 12.6302 6.78379 11.2871 7.78667 10.1962C8.12537 9.82771 7.99648 9.20816 7.49525 9.1359C7.08365 9.07655 6.66728 9.04898 6.25 9.05373C1.25 9.05373 0 12.7204 0 13.9426C0 15.1648 1.25 15.1648 1.25 15.1648H5.53132Z'
        fill='currentColor'
      />
      <path
        d='M5.625 7.8315C6.4538 7.8315 7.24866 7.50958 7.83471 6.93655C8.42076 6.36352 8.75 5.58633 8.75 4.77595C8.75 3.96556 8.42076 3.18837 7.83471 2.61534C7.24866 2.04232 6.4538 1.72039 5.625 1.72039C4.7962 1.72039 4.00134 2.04232 3.41529 2.61534C2.82924 3.18837 2.5 3.96556 2.5 4.77595C2.5 5.58633 2.82924 6.36352 3.41529 6.93655C4.00134 7.50958 4.7962 7.8315 5.625 7.8315Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowInSquare
