import { createLogger, format, Logger, transports } from 'winston'

import { JEST_WORKER_ID, LOG_LEVEL, NODE_ENV } from '../config/env'

const DEV_MODE = NODE_ENV === 'development'

const { Console } = transports

const humanReadableFormat = format.printf(({ level, message, timestamp, ...metadata }) => {
  const metadataString = JSON.stringify(metadata, null, 2).split('\\n').join('\n')
  return `${timestamp} [${level}]: ${message} \n${metadataString}`
})

const DEV_FORMAT = format.combine(
  format((info) => {
    info.level = info.level.toUpperCase()
    return info
  })(),
  format.timestamp(),
  format.errors({ stack: true }),
  format.colorize(),
  humanReadableFormat
)

const PROD_FORMAT = format.combine(
  format.json(),
  format.timestamp({
    format: 'YYYY-MM-DD HH:mm:ss',
  }),
  format.metadata(),
  format.errors({ stack: true })
)

let loggerInstance: Logger | undefined

const getLogger = () => {
  if (loggerInstance) return loggerInstance
  loggerInstance = createLogger({
    level: LOG_LEVEL ?? 'info',
    format: DEV_MODE ? DEV_FORMAT : PROD_FORMAT,
    transports: [new Console()],
    silent: !!JEST_WORKER_ID,
    exitOnError: false,
  })

  return loggerInstance
}

export const logger = getLogger()
