import { UuidSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

const qaStepConfigSchema = z
  .object({
    stepsToQa: z.array(UuidSchema),
  })
  .strict()

type TQaStepConfig = z.infer<typeof qaStepConfigSchema>

const schema = z
  .object({
    config: qaStepConfigSchema,
  })
  .strict()

type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>

const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.partial().strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>

const update = ({ branchStepMeta, data }: { branchStepMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(branchStepMeta, updateArgs)
}

export { create, createArgsSchema, qaStepConfigSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TQaStepConfig, TSchema, TUpdateArgs }
