import { SVGProps } from 'react'
function CircleMinus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.923 8.18463C4.58313 8.18463 4.30762 8.46015 4.30762 8.80002C4.30762 9.13988 4.58313 9.4154 4.923 9.4154H11.0768C11.4167 9.4154 11.6922 9.13988 11.6922 8.80002C11.6922 8.46015 11.4167 8.18463 11.0768 8.18463H4.923Z'
        fill='#EDA304'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0.799988C3.58172 0.799988 0 4.38171 0 8.79999C0 13.2183 3.58172 16.8 8 16.8C12.4183 16.8 16 13.2183 16 8.79999C16 4.38171 12.4183 0.799988 8 0.799988ZM1.23077 8.79999C1.23077 5.06144 4.26146 2.03076 8 2.03076C11.7385 2.03076 14.7692 5.06144 14.7692 8.79999C14.7692 12.5385 11.7385 15.5692 8 15.5692C4.26146 15.5692 1.23077 12.5385 1.23077 8.79999Z'
        fill='#EDA304'
      />
    </svg>
  )
}

export default CircleMinus
