import { SVGProps } from 'react'

function OrchestrationFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20 10C19.3 10 18.6 10.4 18.3 11H13V8H18V4.7C18.6 4.4 19 3.7 19 3C19 1.9 18.1 1 17 1C15.9 1 15 1.9 15 3C15 3.7 15.4 4.4 16 4.7V6H13V3.5L12.6 3.2C11.2 2.2 9 2.1 7.3 3.1C6.4 3.6 5.7 4.4 5.2 5.5C4.9 6.3 4.7 7.4 4.9 8.5C4.3 8.8 3.9 9.4 3.5 9.9C2.9 10.8 2.5 12.1 2.5 13.4C2.5 17.1 4 19.4 6.1 20.6C8.1 21.8 10.4 21.9 12 21.9H13V17.9H16V19.2C15.4 19.5 15 20.2 15 20.9C15 22 15.9 22.9 17 22.9C18.1 22.9 19 22 19 20.9C19 20.2 18.6 19.5 18 19.2V16H13V13H18.3C18.6 13.6 19.3 14 20 14C21.1 14 22 13.1 22 12C22 10.9 21.1 10 20 10Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default OrchestrationFilled
