import axios from 'axios'
import { useMutation as rqUseMutation, UseMutationOptions } from 'react-query'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type TVariables = { id: string }

const useStepRunExecute = (options?: UseMutationOptions<unknown, unknown, TVariables>) => {
  const { isLoading, mutateAsync } = rqUseMutation(
    ['step-run-execute'],
    async ({ id }: TVariables) =>
      axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/execute`,
        { stepRunId: id },
        {
          withCredentials: true,
        }
      ),
    options
  )

  return { isLoading, mutateAsync }
}

export { useStepRunExecute }
