import { MetaError } from '@invisible/errors'
import { GraphQLClient } from 'graphql-request'
import fetch from 'node-fetch'

import { NEXT_PUBLIC_CONCORDE_URL } from './config/env'

const endpoint = `${NEXT_PUBLIC_CONCORDE_URL}/graphql`

export const graphqlClient = new GraphQLClient(endpoint, {
  fetch,
  credentials: 'include',
})

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
  requestHeaders?: HeadersInit
) {
  return async (): Promise<TData> =>
    graphqlClient
      .request<TData, TVariables>(query, variables, requestHeaders)
      .catch(({ response }) => {
        if (Boolean((process as any).browser) && response?.status === 401) {
          return {} as TData
        } else {
          throw new MetaError(response?.error, response?.status, { response })
        }
      })
}
