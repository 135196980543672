import { SVGProps } from 'react'

function Technologies(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.64389 3.71004C6.10551 3.25541 6.73161 3 7.38446 3H29.5383C30.1911 3 30.8172 3.25541 31.2789 3.71004C31.7405 4.16468 31.9998 4.7813 31.9998 5.42424V22.3939C31.9998 23.0369 31.7405 23.6535 31.2789 24.1081C30.8172 24.5628 30.1911 24.8182 29.5383 24.8182H27.0769V27.2422C27.0769 28.5811 25.9749 29.6665 24.6154 29.6665H2.46154C1.10207 29.6665 0 28.5811 0 27.2422V13.9087V10.2725C0 8.93368 1.10207 7.84831 2.46154 7.84831H4.92292V5.42424C4.92292 4.78129 5.18226 4.16468 5.64389 3.71004ZM2.46154 15.1209V27.2422H24.6154V23.6258L24.6152 23.6061L24.6154 23.5863V15.1209H2.46154ZM24.6154 12.6966H2.46154V10.2725H6.13239L6.15369 10.2727L6.17499 10.2725H24.6154V12.6966ZM27.0769 13.9087V22.3939H29.5383V5.42424L7.38446 5.42424V7.84831H24.6154C25.9749 7.84831 27.0769 8.93368 27.0769 10.2725V13.9087Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Technologies
