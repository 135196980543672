import { TCoordinates, TMatch } from '../types'
import {
  getCaretCoordinates,
  getColorHighlight,
  getIsWithinVisibleAreaHOF,
  identifyIssueType,
} from './common'
import { createTooltip } from './createTooltip'

const HIGHLIGHT_HEIGHT = 12

const applyHighlightStyles = ({
  highlight,
  left,
  top,
  width,
  color,
}: {
  highlight: HTMLDivElement
  left: number
  top: number
  width: number
  color: string
}) => {
  highlight.classList.add('input-highlight') // Add a class for easy identification
  highlight.style.position = 'absolute'
  highlight.style.pointerEvents = 'none' // Make the highlight unclickable
  highlight.style.left = `${left}px`
  highlight.style.top = `${top}px`
  highlight.style.width = `${width}px`
  highlight.style.height = `${HIGHLIGHT_HEIGHT}px` // Small height for the element itself
  highlight.style.zIndex = '100'
  highlight.style.backgroundColor = 'transparent' // Make the background transparent
  highlight.style.borderBottom = `3px solid ${color}` // Underline effect with border
}

export const highlightInputFieldError = ({
  input,
  startPos,
  endPos,
  match,
}: {
  input: HTMLInputElement | HTMLTextAreaElement
  startPos: number // Start position of the error string in number of characters
  endPos: number // End position of the error string in number of characters
  match: TMatch
}) => {
  const issueType = identifyIssueType(match)

  const color = getColorHighlight(issueType)

  const spellcheckSuggestion = match.replacements[0].value
  const startCoords: TCoordinates = getCaretCoordinates({
    element: input,
    startPosition: startPos,
  })
  const endCoords: TCoordinates = getCaretCoordinates({
    element: input,
    startPosition: endPos,
  })

  const { scrollLeft, scrollTop } = input
  const inputRect = input.getBoundingClientRect()
  const inputTop = inputRect.top + window.scrollY
  const inputBottom = inputRect.bottom + window.scrollY
  const inputLeft = inputRect.left + window.scrollX
  const inputRight = inputRect.right + window.scrollX

  const isWithinVisibleArea = getIsWithinVisibleAreaHOF({
    inputTop,
    inputRight,
    inputBottom,
    inputLeft,
  })

  if (startCoords.top === endCoords.top) {
    // Single line highlight
    const left = startCoords.left - scrollLeft + window.scrollX
    const top = startCoords.top - scrollTop + window.scrollY
    const right = endCoords.left - scrollLeft + window.scrollX

    if (isWithinVisibleArea({ left, top, right })) {
      const highlight = document.createElement('div')

      applyHighlightStyles({
        highlight,
        left: startCoords.left - scrollLeft,
        top: startCoords.top - scrollTop - HIGHLIGHT_HEIGHT,
        width: endCoords.left - startCoords.left,
        color,
      })

      document.body.appendChild(highlight)
      createTooltip({
        highlightElement: highlight,
        tooltipText: spellcheckSuggestion,
        left,
        top,
      })
    }
  } else {
    const inputRightEdge = input.getBoundingClientRect().right
    const inputLeftEdge = input.getBoundingClientRect().left
    // Multi-line highlight
    const firstLineLeft = startCoords.left - scrollLeft + window.scrollX
    const firstLineTop = startCoords.top - scrollTop + window.scrollY
    const firstLineRight = inputRight
    // First line
    const firstLinewidth = inputRightEdge - startCoords.left
    if (
      isWithinVisibleArea({
        left: firstLineLeft,
        top: firstLineTop,
        right: firstLineRight,
      })
    ) {
      const highlightFirstLine = document.createElement('div')

      applyHighlightStyles({
        highlight: highlightFirstLine,
        left: startCoords.left - scrollLeft,
        top: startCoords.top - scrollTop - HIGHLIGHT_HEIGHT,
        width: firstLinewidth,
        color,
      })

      document.body.appendChild(highlightFirstLine)
      createTooltip({
        highlightElement: highlightFirstLine,
        tooltipText: spellcheckSuggestion,
        left: firstLineLeft,
        top: firstLineTop,
      })
    }
    // Intermediate lines (if any)
    const lineHeight = parseFloat(window.getComputedStyle(input).lineHeight)
    let currentTop = startCoords.top + lineHeight

    // Define a small tolerance value (e.g., 1 pixel) because of  how browsers handle subpixel rendering and rounding.
    const tolerance = 2

    while (currentTop < endCoords.top - tolerance) {
      const middleLineLeft = inputLeft
      const middleLineRight = inputRight
      if (
        isWithinVisibleArea({
          left: middleLineLeft,
          top: currentTop,
          right: middleLineRight,
        })
      ) {
        const highlightMiddleLine = document.createElement('div')

        applyHighlightStyles({
          highlight: highlightMiddleLine,
          left: inputLeftEdge - scrollLeft,
          top: currentTop - scrollTop - HIGHLIGHT_HEIGHT,
          width: input.clientWidth - parseFloat(window.getComputedStyle(input).paddingRight),
          color,
        })

        document.body.appendChild(highlightMiddleLine)
        createTooltip({
          highlightElement: highlightMiddleLine,
          tooltipText: spellcheckSuggestion,
          left: middleLineLeft,
          top: currentTop,
        })
      }
      currentTop += lineHeight
    }

    // Last line
    // Multi-line highlight
    const lastLineLeft = inputLeft
    const lastLineTop = endCoords.top - scrollTop + window.scrollY
    const lastLineRight = endCoords.left - scrollLeft + window.scrollX

    if (
      isWithinVisibleArea({
        left: lastLineLeft,
        top: lastLineTop,
        right: lastLineRight,
      })
    ) {
      const lastLineWidth =
        endCoords.left - parseFloat(window.getComputedStyle(input).paddingLeft) - inputLeftEdge
      const highlightLastLine = document.createElement('div')

      applyHighlightStyles({
        highlight: highlightLastLine,
        left: inputLeftEdge - scrollLeft,
        top: endCoords.top - scrollTop - HIGHLIGHT_HEIGHT,
        width: lastLineWidth,
        color,
      })

      document.body.appendChild(highlightLastLine)
      createTooltip({
        highlightElement: highlightLastLine,
        tooltipText: spellcheckSuggestion,
        left: lastLineLeft,
        top: lastLineTop,
      })
    }
  }
}
