import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { useQuery } from '@invisible/trpc/client'
import { Dropdown } from '@invisible/ui/dropdown'
import { Input } from '@invisible/ui/input'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { Pagination } from '@invisible/ui/pagination'
import { ReactTable } from '@invisible/ui/react-table'
import { TableStyles } from '@invisible/ui/table'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { debounce, flow, map, reduce } from 'lodash/fp'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Flex } from 'rebass'

import { HeaderCellCustomSort } from '../baseViewComponents/HeaderCell'
import { rowNumbersHook } from '../BaseViewTable'
import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import { getCustomCell } from '../customCells/GetCustomCell'

export interface TModel extends Record<string, unknown> {
  [key: string]: any
}

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>
type TBaseRunData = NonNullable<inferQueryOutput<'baseRun.fuzzySearch'>>

type IProps = WizardSchemas.WACConfig.TSchema & {
  height: number
  stepRun: TStepRun
}

const Container = styled.div<{ height?: number | string }>`
  border-radius: 8px;
  height: ${({ height }) => height ?? '100%'};
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

const calculateTextWidth = (text: string, font = 14) => (font * text.length) / 2
const columnHelper = createColumnHelper<Record<string, any>>()

export const baseRunIdHook = (
  columns: ColumnDef<TModel, unknown>[]
): ColumnDef<TModel, unknown>[] => [
  columnHelper.accessor('id', {
    header: () => (
      <div
        className='text-theme-main flex px-3 text-left text-xs font-bold uppercase tracking-wide'
        style={{ width: '40px' }}>
        ID
      </div>
    ),
    cell: (info: CellContext<Record<string, any>, any>) => (
      <div style={{ width: '200px' }}>{info.getValue()?.actualValue}</div>
    ),
  }),
  ...columns,
]

export const matchPercentHook = (
  columns: ColumnDef<TModel, unknown>[]
): ColumnDef<TModel, unknown>[] => [
  columnHelper.accessor('matchPercent', {
    header: () => (
      <div
        className='text-theme-main flex px-3 text-left text-xs font-bold uppercase tracking-wide'
        style={{ width: '40px' }}>
        Match Percent
      </div>
    ),
    cell: (info: CellContext<Record<string, any>, any>) => (
      <div style={{ width: '200px' }}>{info.getValue()?.actualValue}</div>
    ),
  }),
  ...columns,
]

// eslint-disable-next-line @typescript-eslint/ban-types
export const InformationBaseWAC: FC<IProps> = ({
  showName,
  name,
  informationBase,
  height,
  stepRun,
}) => {
  const [currentPage, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState<number>(DEFAULT_ITEMS_PER_PAGE)

  const fields: Record<string, any> = useMemo(
    () =>
      reduce(
        (acc, f) => ({ ...acc, [f.baseVariableId as string]: f }),
        {},
        informationBase?.fields
      ),
    [informationBase?.fields]
  )

  const debouncedSetSearchTerm = useCallback(debounce(500, setSearchValue), [setSearchValue])

  const { data, isLoading } = useQuery([
    'baseRun.fuzzySearch',
    {
      searchValue,
      baseId: informationBase?.fields[0].baseId as string,
      page: currentPage,
      limit: 10,
    },
  ])

  const itemCount = useMemo(() => (data as TBaseRunData)?.itemCount ?? 0, [data])

  const previousPageCount = useRef(1)
  const pageCount = useMemo(() => {
    const currentValue =
      itemCount > 0 ? Math.ceil(itemCount / (itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE)) : 0

    if (itemCount === 0 && isLoading) return previousPageCount.current
    previousPageCount.current = currentValue
    return currentValue
  }, [itemCount, isLoading, itemsPerPage])

  const columns = useMemo(
    () =>
      map((field) => {
        const width = calculateTextWidth(field.label as string) + 120
        return columnHelper.accessor(field.baseVariableId as string, {
          header: (props) => (
            <HeaderCellCustomSort
              name={field.label as string}
              headerContext={props}
              sortId={field.baseVariableId}
              filter='normal'
              style={{ width: `${width}px` }} // we use inline style to override default width fit-content
            />
          ),
          cell: getCustomCell(field.type, width).Cell,
        })
      }, informationBase?.fields),
    [informationBase?.fields]
  )

  const parsedData = useMemo(
    () =>
      ((data as TBaseRunData)?.items ?? [])?.map((br: any) => ({
        id: {
          id: '',
          baseRunId: br.id,
          actualValue: br.id,
          isEditable: false,
          baseVariableId: '',
          matchPercent: 0,
        },
        matchPercent: {
          id: '',
          baseRunId: br.id,
          actualValue: br.matchPercent?.toFixed(2),
          isEditable: false,
          baseVariableId: '',
        },
        ...br.baseRunVariables.reduce(
          (acc: Record<string, any>, brv: Record<string, any>) => ({
            ...acc,
            [brv.baseVariable.id]: {
              id: brv.id,
              baseVariableId: brv.baseVariable.id,
              actualValue: brv.value,
              baseRunId: br.id,
              isEditable: fields[brv.baseVariable.id]?.editable,
            },
          }),
          {}
        ),
      })),
    [data]
  )

  const columnsWithHooks = useMemo(
    () => flow(matchPercentHook, baseRunIdHook, rowNumbersHook)(columns),
    [columns]
  )

  const { getRowModel, getHeaderGroups } = useReactTable({
    data: parsedData,
    columns: columnsWithHooks,
    getCoreRowModel: getCoreRowModel(),
    filterFns: { includes: () => true }, // required by the lib but we are not using
    meta: {
      pageSize: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      currentPage,
      updateBaseRunVariable: () => undefined,
    },
  })

  return (
    <Container height={height}>
      <div className='my-2 flex w-full justify-between'>
        <div>
          {showName ? (
            <Text mb='10px' fontWeight='bold'>
              {name}
            </Text>
          ) : null}
        </div>

        <Input
          placeholder={searchValue || 'Search...'}
          // Using placeholder for initial value for now, we might want to add an initialValue prop to the component
          prefix='SearchOutlineIcon'
          onChange={(e) => debouncedSetSearchTerm((e.target as HTMLInputElement).value.trim())}
        />
      </div>
      <ErrorBoundary>
        <TableStyles.Table
          style={{ overflow: 'auto', minHeight: '400px', borderSpacing: 0 }}
          className='inline-block w-full border-collapse rounded-tl-none'>
          <thead>
            {getHeaderGroups()?.map((headerGroup) => (
              <TableStyles.HeaderRow
                key={headerGroup.id}
                className='h-[48px]'
                style={{ position: 'sticky', top: 0, zIndex: 2 }}>
                {headerGroup.headers.map((header, index) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </TableStyles.HeaderRow>
            ))}
          </thead>
          <tbody className='w-full overflow-y-visible bg-white'>
            {isLoading ? (
              <Flex
                alignItems='center'
                justifyContent='center'
                height='300px'
                width='40px'
                style={{ position: 'fixed', left: '58%' }}>
                <LogoSpinner height={40} width={40} />
              </Flex>
            ) : (
              getRowModel().rows?.map((row) => (
                <TableStyles.BodyRow key={row.id} className='h-[48px]'>
                  {row?.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <ReactTable.CellContainer>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </ReactTable.CellContainer>
                    </td>
                  ))}
                </TableStyles.BodyRow>
              ))
            )}
          </tbody>
        </TableStyles.Table>
      </ErrorBoundary>
      <Flex my='10px' alignItems='center'>
        <Flex alignItems='center' mr='20px'>
          <Text mr='8px'>Showing</Text>
          <Dropdown
            name='Base runs'
            placeholder='10'
            width='70px'
            search={false}
            dropdownWidth='70px'
            options={[10, 25, 30, 40, 50, 75].map((o) => ({
              key: String(o),
              value: o,
            }))}
            selectedKey={String(itemsPerPage)}
            onChange={({ value }) => setItemsPerPage(value as number)}
          />
          <Text ml='8px'>items per page</Text>
        </Flex>

        <Pagination
          pages={pageCount}
          selectedPage={currentPage ?? 1}
          onClick={(page) => setPage(page)}
        />

        <Flex alignItems='center' ml='20px'>
          <Text mr='8px'>Page</Text>
          <Dropdown
            name='Base runs'
            placeholder='1'
            maxHeight='400px'
            width='70px'
            dropdownWidth='120px'
            alignment='center'
            options={Array(pageCount)
              .fill('_')
              .map((o, i) => ({
                key: String(i + 1),
                value: i + 1,
              }))}
            selectedKey={String(currentPage ?? 1)}
            onChange={({ value }) => setPage(value as number)}
          />
          <Text ml='8px'>
            of {pageCount} ({itemCount} total items)
          </Text>
        </Flex>
      </Flex>
    </Container>
  )
}
