import { SVGProps } from 'react'

function ClockOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='26'
      viewBox='0 0 27 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.356 2C7.28082 2 2.35596 6.92487 2.35596 13C2.35596 19.0751 7.28082 24 13.356 24C19.4311 24 24.356 19.0751 24.356 13C24.356 6.92487 19.4311 2 13.356 2ZM0.355957 13C0.355957 5.8203 6.17626 0 13.356 0C20.5357 0 26.356 5.8203 26.356 13C26.356 20.1797 20.5357 26 13.356 26C6.17626 26 0.355957 20.1797 0.355957 13Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.356 5C13.9082 5 14.356 5.44772 14.356 6V12H20.356C20.9082 12 21.356 12.4477 21.356 13C21.356 13.5523 20.9082 14 20.356 14H13.356C12.8037 14 12.356 13.5523 12.356 13V6C12.356 5.44772 12.8037 5 13.356 5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ClockOutline
