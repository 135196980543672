import { SVGProps } from 'react'

function Ranking(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19.7925 2.90783L18.999 1L18.2055 2.90783L16.1459 3.07295L17.7151 4.41717L17.2357 6.42705L18.999 5.35L20.7624 6.42705L20.2829 4.41717L21.8522 3.07295L19.7925 2.90783ZM21.999 23V7H15.999V23H21.999ZM14.999 12H8.99902V23H14.999V12ZM1.99902 17H7.99902V23H1.99902V17Z'
        fill='#604CA5'
      />
    </svg>
  )
}

export default Ranking
