import { formatFilterValueLabel, mapTypeToInputType } from '@invisible/common/helpers'
import { IFilter, IFilterSourceInfo, TFilterValue } from '@invisible/common/types'
import { REPORT_FAC } from '@invisible/ui/constants'
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@invisible/ui/dropdown'
import { find, flow, includes, isEqual } from 'lodash/fp'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { FilterAtomicComponents } from '../filter-atomic-components'
import { getFilterMappings, parseFilterMappingsToFilterTypeOptions } from './helpers'
import { IFilterTypeOption, TFACName, TOperatorName } from './types'

const FilterWizardContext = createContext<{
  open: boolean
  selectedFilterTypeKey: TOperatorName
  inputValue: TFilterValue
  filterLabel: string
  filterValueType: string
  filterOptionLabel: string
}>({
  inputValue: null,
  open: true,
  selectedFilterTypeKey: '=',
  filterLabel: '',
  filterValueType: 'text',
  filterOptionLabel: '=',
})

export const useFilterWizardContext = () => useContext(FilterWizardContext)

// eslint-disable-next-line @typescript-eslint/ban-types
const FilterWizardProvider: React.FC<{
  filter: IFilter<TFilterValue>
  onChange: (val: IFilter<TFilterValue>) => void
  sourceInfo: IFilterSourceInfo
}> = ({ filter, onChange, sourceInfo, children }) => {
  const filterTypeOptions = useMemo(
    () => flow(getFilterMappings, parseFilterMappingsToFilterTypeOptions)(filter.valueType),
    [filter.valueType]
  )
  const [inputValue, setInputValue] = useState(filter.value)
  const [open, setOpen] = useState(filter.defaultOpen)
  const [selectedFilterTypeKey, setSelectedFilterTypeKey] = useState<TOperatorName>(
    filter.operator || filterTypeOptions[0]?.key || '='
  )

  useEffect(() => {
    if (open) return
    if (isEqual(inputValue, filter.value) && selectedFilterTypeKey === filter.operator) return
    onChange({
      ...filter,
      value: inputValue,
      defaultOpen: false,
      operator: selectedFilterTypeKey,
      valueLabel: formatFilterValueLabel({
        filterOperatorType: selectedFilterTypeKey,
        filterValue: inputValue,
        filterValueType: filter.valueType,
      }),
    } as IFilter<TFilterValue>)
  }, [open])

  const handleSelect = (key: string) => {
    const filterType = find((fopt: IFilterTypeOption) => fopt.key === key)(filterTypeOptions)
    if (!filterType) return
    setSelectedFilterTypeKey(filterType.key)
    setInputValue(null)
  }

  return (
    <DropdownMenu open={open} onOpenChange={(open) => setOpen(open)}>
      <FilterWizardContext.Provider
        value={{
          open,
          inputValue,
          selectedFilterTypeKey,
          filterLabel: filter.label,
          filterValueType: filter.valueType,
          filterOptionLabel:
            find((option: IFilterTypeOption) => option.key === selectedFilterTypeKey)(
              filterTypeOptions
            )?.value || '',
        }}>
        {children}
      </FilterWizardContext.Provider>
      <FilterWizardContent
        filterTypeOptions={filterTypeOptions}
        filterableColumnName={filter.key}
        filterInputType={filter.valueType}
        onChange={(val) => setInputValue(val)}
        inputValue={inputValue}
        onSelect={handleSelect}
        sourceInfo={sourceInfo}
        selectedFilterTypeKey={selectedFilterTypeKey}
      />
    </DropdownMenu>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterWizardTrigger: React.FC = ({ children }) => (
  <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
)

interface IFWAC {
  inputType: string
  componentType: TFACName
  onInputChange: (value: TFilterValue) => void
  inputValue: TFilterValue
  sourceInfo?: IFilterSourceInfo
  filterKey: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const FWAC: React.FC<IFWAC> = ({
  componentType,
  onInputChange,
  inputType,
  inputValue,
  sourceInfo,
  filterKey,
}) => {
  // @ts-expect-error Bad Typings
  if (!componentType || !includes(componentType)(Object.values(REPORT_FAC))) return null
  const props = {
    onChange: onInputChange,
    type: mapTypeToInputType(inputType),
    value: inputValue,
    sourceInfo,
    filterKey,
  }
  const Component = FilterAtomicComponents[componentType as any]
  return <div>{<Component {...props} />}</div>
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterWizardContent: React.FC<{
  width?: string
  filterTypeOptions: IFilterTypeOption[]
  inputValue: TFilterValue
  selectedFilterTypeKey: TOperatorName
  onSelect: (val: TOperatorName) => void
  onChange: (val: TFilterValue) => void
  filterableColumnName: string
  filterInputType: string
  sourceInfo: IFilterSourceInfo
}> = ({
  width = '176px',
  filterTypeOptions,
  filterableColumnName,
  filterInputType,
  inputValue,
  onChange,
  onSelect,
  selectedFilterTypeKey,
  sourceInfo,
}) => (
  <DropdownMenuContent className='border-main flex w-[192px] flex-col items-center rounded-md border border-solid bg-white py-1'>
    <div className='m-2'>
      <Dropdown
        width={width}
        search={false}
        dropdownWidth={width}
        options={filterTypeOptions}
        onChange={(option) => onSelect(option.key as TOperatorName)}
        selectedKey={selectedFilterTypeKey}
      />
    </div>
    <div className='w-[176px] px-1'>
      <FWAC
        onInputChange={onChange}
        inputValue={inputValue}
        componentType={
          filterTypeOptions.find(
            (option: IFilterTypeOption) => option.key === selectedFilterTypeKey
          )?.FAC || 'genericInput'
        }
        inputType={filterInputType}
        sourceInfo={sourceInfo}
        filterKey={filterableColumnName}
      />
    </div>
  </DropdownMenuContent>
)

export const FilterWizard = Object.assign(FilterWizardProvider, {
  Trigger: FilterWizardTrigger,
})
