import { SVGProps } from 'react'
function WrenchFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.4765 10.4766C14.6726 11.2737 13.6485 11.8121 12.5361 12.0223C11.4236 12.2326 10.2737 12.1051 9.23431 11.6562L5.17181 16.3594C5.164 16.375 5.14837 16.3828 5.14056 16.3906C4.90902 16.6231 4.63386 16.8075 4.33087 16.9333C4.02788 17.0592 3.70302 17.124 3.37494 17.124C3.04685 17.124 2.72199 17.0592 2.41901 16.9333C2.11602 16.8075 1.84086 16.6231 1.60931 16.3906C1.37687 16.1591 1.19244 15.8839 1.0666 15.5809C0.940757 15.2779 0.875977 14.9531 0.875977 14.625C0.875977 14.2969 0.940757 13.9721 1.0666 13.6691C1.19244 13.3661 1.37687 13.0909 1.60931 12.8594L1.64056 12.8281L6.34369 8.76562C5.89067 7.73365 5.75811 6.58939 5.96317 5.48117C6.16823 4.37295 6.70147 3.35189 7.49376 2.55035C8.28604 1.7488 9.30084 1.20373 10.4066 0.985804C11.5124 0.767875 12.6581 0.887135 13.6952 1.32812C13.7891 1.36753 13.8717 1.42952 13.9357 1.50858C13.9998 1.58763 14.0433 1.6813 14.0624 1.78125C14.0821 1.88191 14.0768 1.98588 14.0468 2.08398C14.0168 2.18208 13.9632 2.27129 13.8906 2.34375L10.8515 5.38281L11.1406 6.85937L12.6171 7.14844L15.6562 4.10937C15.7286 4.03676 15.8179 3.98311 15.916 3.95313C16.0141 3.92316 16.118 3.91779 16.2187 3.9375C16.3186 3.95661 16.4123 4.00013 16.4914 4.06419C16.5704 4.12825 16.6324 4.21087 16.6718 4.30469C17.1128 5.33497 17.2347 6.47385 17.0216 7.57411C16.8085 8.67438 16.2703 9.68542 15.4765 10.4766Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default WrenchFilled
