import { RRule } from 'rrule'
import { z } from 'zod'

// Export task scheduler constants
export const SCHEDULING_OPTIONS = [
  { label: 'Do not schedule', value: 'default' },
  { label: 'One time schedule', value: 'once' },
  { label: 'Custom recurrence', value: 'custom' },
] as const

export const SCHEDULE_TIMEZONE = 'UTC' as const

export const DEFAULT_SCHEDULE_DESCRIPTION = 'No schedule set' as const

export enum FREQUENCIES {
  Year,
  Month,
  Week,
  Day,
}

export enum FREQUENCIES_PLURAL {
  Years,
  Months,
  Weeks,
  Days,
}

export const FREQUENCY_SCHEMA = z.nativeEnum(FREQUENCIES)

export const WEEKDAYS = {
  Sunday: RRule.SU,
  Monday: RRule.MO,
  Tuesday: RRule.TU,
  Wednesday: RRule.WE,
  Thursday: RRule.TH,
  Friday: RRule.FR,
  Saturday: RRule.SA,
}

type WeekdayLiteralTuple = [
  z.ZodLiteral<number>,
  z.ZodLiteral<number>,
  z.ZodLiteral<number>,
  z.ZodLiteral<number>,
  z.ZodLiteral<number>,
  z.ZodLiteral<number>,
  z.ZodLiteral<number>
]

export const WEEKDAY_LITERALS: WeekdayLiteralTuple = Object.values(WEEKDAYS).map((v) =>
  z.literal(v.weekday)
) as WeekdayLiteralTuple

// known issue
// https://github.com/colinhacks/zod/issues/831
export const WEEKDAY_SCHEMA = z.array(z.union(WEEKDAY_LITERALS)).nullable()

// Round minutes to nearest multiple of 5, and advance by 20 minutes
const dtstart = new Date()
const minutes = dtstart.getMinutes() + 20
const roundedMinutes = Math.round(minutes / 5) * 5
dtstart.setMinutes(roundedMinutes)

export const DEFAULT_RRULE = {
  freq: FREQUENCIES.Week,
  interval: 1,
  dtstart: dtstart,
  byweekday: [RRule.FR.weekday] as any[],
  byhour: 12,
  byminute: 0,
  until: null,
  count: null,
  tzid: SCHEDULE_TIMEZONE,
  bymonthday: null,
  bysetpos: null,
} as const

export const DEFAULT_SCHEDULE_STATE = {
  ...DEFAULT_RRULE,
  taskSchedule: 'default',
  isCustom: false,
  endCondition: 'never',
  description: DEFAULT_SCHEDULE_DESCRIPTION,
  rruleString: '',
  monthOption: null,
} as const
