import { color, gray, purple, space, styled, textColor } from '@invisible/ui/themes'
import { Box } from '@mui/material'
import { isNil } from 'lodash/fp'
import {
  FC,
  InputHTMLAttributes,
  RefObject,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Flex } from 'rebass'

interface IToggleProps {
  isDisabled: boolean | undefined
}

const Toggle = styled(Flex)<IToggleProps>`
  > label {
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    position: relative;
    margin-right: 10px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  > label svg {
    width: ${space(3)};
    height: ${space(3)};
    position: relative;
    z-index: 1;
    fill: none;
    border-radius: 2px;
    align-self: center;
    border: ${({ theme }) => `1px solid ${theme.grayScale[6]}`};
    box-sizing: border-box;
  }
  > label:hover:before {
    opacity: 1;
  }
  > label.checked svg {
    border: none;
  }
`

interface ICheckbox extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onClick'> {
  onClick: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
}

const SmallCheckbox = ({ checked, disabled, onClick, children, ...rest }: ICheckbox) => {
  const [isChecked, toggleChecked] = useState(checked || false)
  const isControlled = checked !== undefined
  const isDisabled = disabled

  const handleChange = async () => {
    if (isDisabled) return
    const newState = !isChecked
    onClick(newState)
    if (!isControlled) {
      toggleChecked(newState)
    }
  }

  useEffect(() => {
    if (isControlled && !isNil(checked)) toggleChecked(checked)
  }, [isControlled, checked])
  const getBgColor = () => {
    if (isDisabled) {
      if (isChecked) {
        return 'bg-theme-weak'
      }
      return 'bg-weak-2'
    }
    if (isChecked) {
      return 'bg-theme-main'
    }
    return 'bg-white'
  }
  return (
    <Toggle isDisabled={isDisabled}>
      <label className={isChecked ? 'checked' : ''}>
        <input
          type='checkbox'
          aria-disabled={isDisabled}
          style={{ display: 'none', width: 16, height: 16 }}
          onChange={handleChange}
          checked={isChecked}
          {...rest}
        />

        <svg className={getBgColor()} width='16' height='16' viewBox='0 0 16 16'>
          <g transform='translate(3.2 4)'>
            {isChecked ? (
              <path
                d='M3.60545 8.22193 L3.58783 8.23955 L0.187988 4.8397 L1.62067 3.40703 L3.60551 5.39188 L8.37953 0.617859 L9.81221 2.05054 L3.62313 8.23961 L3.60545 8.22193Z'
                fill='white'
              />
            ) : null}
          </g>
        </svg>
      </label>
      <span>{children}</span>
    </Toggle>
  )
}

const TextArea = styled.textarea<{ fontSize?: number; disableResize?: boolean }>`
  font-size: ${({ fontSize, theme }) => (fontSize ? `${fontSize}px` : theme.fontSizes[1])};
  line-height: 125%;
  outline: none;
  width: 100%;
  padding: 5px 12px;
  background-color: ${color('white')};
  border: 1px solid ${gray(5)};
  border-radius: 2px;
  align-self: stretch;
  resize: ${({ disableResize }) => (disableResize ? 'none' : 'vertical')};
  &::placeholder {
    color: ${textColor('disabled')};
  }
  &:focus-within {
    border: 1px solid ${purple(6)};
  }
`
const AutoresizeTextArea = styled(TextareaAutosize)<{
  fontSize?: number
  fontWeight?: string | number
}>`
  font-size: ${({ fontSize, theme }) => (fontSize ? `${fontSize}px` : theme.fontSizes[1])};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  line-height: 125%;
  outline: none;
  width: 100%;
  padding: 5px 12px;
  background-color: ${color('white')};
  border: 1px solid ${gray(5)};
  border-radius: 2px;
  align-self: stretch;
  &::placeholder {
    color: ${textColor('disabled')};
  }
  &:focus-within {
    border: 1px solid ${purple(6)};
  }
`

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  alt?: string
  autoresize?: boolean
  width?: string
  ref?: RefObject<HTMLTextAreaElement>
  value: string
  fontSize?: number
  fontWeight?: string | number
  disableResize?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TextAreaComponent: FC<ITextArea> = ({
  autoresize = false,
  ref,
  value,
  onChange,
  onKeyPress,
  placeholder,
  disableResize = false,
  ...props
}) => {
  const refFromHook = useRef<HTMLTextAreaElement>(null)
  const textAreaRef = ref || refFromHook

  return (
    <Box display='flex' width='100%'>
      {autoresize ? (
        <AutoresizeTextArea
          placeholder={placeholder}
          ref={textAreaRef}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={props.onBlur}
          value={value}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}
          maxLength={props.maxLength}
        />
      ) : (
        <TextArea
          placeholder={placeholder}
          ref={textAreaRef}
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value}
          disableResize={disableResize}
          {...props}
        />
      )}
    </Box>
  )
}

export { SmallCheckbox, TextAreaComponent as TextArea }
