import { SVGProps } from 'react'
function SortDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='20'
      viewBox='0 0 25 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6489 13.2929C13.0394 12.9024 13.6725 12.9024 14.0631 13.2929L18.356 17.5858L22.6488 13.2929C23.0394 12.9024 23.6725 12.9024 24.0631 13.2929C24.4536 13.6834 24.4536 14.3166 24.0631 14.7071L19.0631 19.7071C18.6725 20.0976 18.0394 20.0976 17.6488 19.7071L12.6489 14.7071C12.2583 14.3166 12.2583 13.6834 12.6489 13.2929Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.356 6C18.9082 6 19.356 6.44772 19.356 7V19C19.356 19.5523 18.9082 20 18.356 20C17.8037 20 17.356 19.5523 17.356 19V7C17.356 6.44772 17.8037 6 18.356 6Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 9C0.355957 8.44772 0.803672 8 1.35596 8H10.356C10.9082 8 11.356 8.44772 11.356 9C11.356 9.55228 10.9082 10 10.356 10H1.35596C0.803672 10 0.355957 9.55228 0.355957 9Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 1C0.355957 0.447715 0.803672 0 1.35596 0H18.356C18.9082 0 19.356 0.447715 19.356 1C19.356 1.55228 18.9082 2 18.356 2H1.35596C0.803672 2 0.355957 1.55228 0.355957 1Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 17C0.355957 16.4477 0.803672 16 1.35596 16H8.35596C8.90824 16 9.35596 16.4477 9.35596 17C9.35596 17.5523 8.90824 18 8.35596 18H1.35596C0.803672 18 0.355957 17.5523 0.355957 17Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SortDown
