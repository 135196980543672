import { UuidSchema, z } from '@invisible/zod'

const schema = z.object({
  disableNavigation: z.boolean().optional(),
  /**
   * Embeds can have a fixed list of urls to cycle between
   */
  options: z
    .array(
      z.object({
        name: z.string(),
        url: z.string().optional(),
        baseVariableId: UuidSchema.optional(),
        stepTemplateVariableId: UuidSchema.optional(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
