import { blue, grey, red } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import { gridClasses, GridColType } from '@mui/x-data-grid-pro'

const getColumnType = (type: string) =>
  (({
    enum: 'singleSelect',
    boolean: 'boolean',
    number: 'number',
    datetime: 'dateTime',
    date: 'date',
  }[type] ?? 'string') as GridColType)

const parsColumnValue = (value: any, type: string) => {
  if (value === null || value === undefined) {
    return null
  }

  if (['datetime', 'date'].includes(type)) {
    return new Date(value)
  }
  return value
}

const isValidFieldValue = (value: any): boolean => {
  if ([null, undefined, ''].includes(typeof value === 'string' ? value.trim() : value)) {
    return false
  }
  return true
}

const calculateTextWidth = (text: string, font = 14) => (font * text.length) / 2

const themeStyleOverides = {
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: '#aea4d2 solid 2px',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: '#aea4d2 solid 10px',
  },
  '& .row--even': {
    backgroundColor: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(grey[200], 0.6),
    },
  },
  '& .row--odd': {
    backgroundColor: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(grey[200], 0.6),
    },
  },
  '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within': {
    backgroundColor: alpha(blue[100], 0.1),
  },
  '& .Mui-error': {
    backgroundColor: alpha(red[100], 0.5),
  },
}

export { calculateTextWidth, getColumnType, isValidFieldValue, parsColumnValue, themeStyleOverides }
