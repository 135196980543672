import { trackEvent } from '@invisible/segment'
import { useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { isEmpty } from 'lodash/fp'
import { useEffect } from 'react'

import { withStories } from './HOCs/withStories'
import { useStories } from './hooks'
import { Story } from './Story'

// eslint-disable-next-line @typescript-eslint/ban-types
const StoriesComponent: React.FC = ({ children }) => {
  const { data, isLoading } = useStories()
  const [storyParam, setStoryParam] = useQueryParam<string | undefined>('story')

  useEffect(() => {
    if (!isEmpty(data) && !isLoading && !storyParam) {
      setStoryParam(data.stories.edges[0]?.node.name.toLowerCase())
    }
  }, [data, isLoading])

  // Remove "story" query param on component un-mount
  useEffect(() => () => setStoryParam(undefined), [])

  const onTabChange = (tab: string | number) => {
    const tabValue = String(tab)
    trackEvent('storyTabChange', {
      tab,
      tabValue,
    })
    setStoryParam(tabValue)
  }

  if (isLoading)
    return (
      <div className='flex h-full items-center justify-center'>
        <LogoSpinner width={30} />
      </div>
    )

  if (data && !isEmpty(data.stories.edges))
    return (
      <div className='relative w-full' data-testid='story-container'>
        <div className='relative'>
          <Tabs value={storyParam?.toLowerCase()} style={{ height: '40px' }} onChange={onTabChange}>
            {data.stories.edges
              .sort((storyA, storyB) => storyA.node.order - storyB.node.order)
              .map((story) => (
                <Tab value={story.node.name.toLowerCase()} name={story.node.title}>
                  <Story key={story.node.id} storyId={story.node.id}>
                    {children}
                  </Story>
                </Tab>
              ))}
          </Tabs>
        </div>
      </div>
    )

  return (
    <div className='flex items-center justify-center py-10 text-2xl font-semibold'>
      No Stories to show
    </div>
  )
}

const Stories = withStories(StoriesComponent)

export { Stories }
