import { SVGProps } from 'react'
function LanguageFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.4902 0.152588C10.9191 0.152588 8.40567 0.915025 6.26783 2.34348C4.12999 3.77194 2.46375 5.80226 1.47981 8.1777C0.495867 10.5531 0.238424 13.167 0.740032 15.6888C1.24164 18.2105 2.47977 20.5269 4.29785 22.345C6.11593 24.1631 8.43231 25.4012 10.9541 25.9028C13.4758 26.4044 16.0897 26.147 18.4651 25.163C20.8406 24.1791 22.8709 22.5128 24.2993 20.375C25.7278 18.2372 26.4902 15.7237 26.4902 13.1526C26.4866 9.70589 25.1158 6.4014 22.6786 3.96422C20.2414 1.52704 16.9369 0.156228 13.4902 0.152588ZM24.4902 13.1526C24.4913 14.5638 24.2197 15.9619 23.6902 17.2701L18.1027 13.8338C17.8652 13.6872 17.5994 13.5923 17.3227 13.5551L14.4702 13.1701C14.0772 13.1188 13.6777 13.1847 13.322 13.3595C12.9662 13.5343 12.6699 13.8102 12.4702 14.1526H11.3802L10.9052 13.1701C10.7739 12.8965 10.5817 12.6566 10.3434 12.4688C10.1051 12.2809 9.82692 12.1501 9.53024 12.0863L8.53024 11.8701L9.50774 10.1526H11.5965C11.9345 10.1519 12.2668 10.0659 12.5627 9.90259L14.094 9.05759C14.2285 8.9826 14.3543 8.89288 14.469 8.79009L17.8327 5.74759C18.17 5.44535 18.3941 5.03719 18.4682 4.59043C18.5423 4.14367 18.4619 3.685 18.2402 3.29009L18.1952 3.20884C20.0775 4.10133 21.668 5.50933 22.7823 7.26941C23.8965 9.02948 24.4887 11.0695 24.4902 13.1526ZM2.49024 13.1526C2.48857 11.5176 2.85334 9.90304 3.55774 8.42759L4.97524 12.2113C5.09337 12.5246 5.28826 12.8032 5.54203 13.0216C5.79581 13.24 6.10035 13.3912 6.42774 13.4613L9.10649 14.0376L9.58274 15.0276C9.74802 15.3643 10.0042 15.6481 10.3222 15.8469C10.6403 16.0457 11.0077 16.1516 11.3827 16.1526H11.5677L10.664 18.1813C10.5048 18.5386 10.4538 18.9346 10.5173 19.3205C10.5808 19.7065 10.7561 20.0653 11.0215 20.3526L11.039 20.3701L13.4902 22.8951L13.2477 24.1451C10.375 24.0782 7.64219 22.8913 5.63239 20.8376C3.6226 18.7839 2.49501 16.0261 2.49024 13.1526Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LanguageFilled
