import { SVGProps } from 'react'

function Gauge(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78027)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.9563 15.9971C29.9563 23.5083 23.8672 29.5974 16.356 29.5974C8.84472 29.5974 2.75566 23.5083 2.75566 15.9971C2.75566 8.48583 8.84472 2.39677 16.356 2.39677C23.8672 2.39677 29.9563 8.48583 29.9563 15.9971ZM32.356 15.9971C32.356 24.8336 25.1925 31.9971 16.356 31.9971C7.5194 31.9971 0.355957 24.8336 0.355957 15.9971C0.355957 7.16052 7.5194 -0.00292969 16.356 -0.00292969C25.1925 -0.00292969 32.356 7.16052 32.356 15.9971ZM23.7022 8.63823C23.3758 8.31028 22.8607 8.26548 22.4816 8.53105C17.8597 11.7754 15.2505 13.6968 14.6521 14.2935C13.7163 15.231 13.7163 16.7508 14.6521 17.6883C15.5896 18.6242 17.1094 18.6242 18.0469 17.6883C18.3957 17.3379 20.3154 14.7271 23.803 9.85408C24.0702 9.47973 24.0286 8.96459 23.7022 8.63823ZM26.7482 15.9909C26.7482 15.1078 26.0315 14.3911 25.1484 14.3911C24.2653 14.3911 23.5486 15.1078 23.5486 15.9909C23.5486 16.874 24.2653 17.5907 25.1484 17.5907C26.0315 17.5907 26.7482 16.874 26.7482 15.9909ZM9.15042 15.9909C9.15042 15.1078 8.43371 14.3911 7.55062 14.3911C6.66753 14.3911 5.95082 15.1078 5.95082 15.9909C5.95082 16.874 6.66753 17.5907 7.55062 17.5907C8.43371 17.5907 9.15042 16.874 9.15042 15.9909ZM11.259 10.9004C11.8829 10.2748 11.8829 9.26216 11.259 8.63823C10.6334 8.01431 9.62076 8.01431 8.99684 8.63823C8.37292 9.26216 8.37292 10.2764 8.99684 10.9004C9.62076 11.5243 10.635 11.5243 11.259 10.9004ZM17.9493 7.19202C17.9493 6.30893 17.2326 5.59222 16.3495 5.59222C15.4664 5.59222 14.7497 6.30893 14.7497 7.19202C14.7497 8.0751 15.4664 8.79182 16.3495 8.79182C17.2326 8.79182 17.9493 8.0751 17.9493 7.19202Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78027'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Gauge
