import { createContext, FC, useContext, useReducer } from 'react'

interface Action {
  type: 'setAutoAssignedStepRunId'
  value: string | null
}

type Dispatch = (action: Action) => void

interface State {
  assignedStepRunId: string | null
}

const StepRunAssignmentContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

export const stepRunAssignmentReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setAutoAssignedStepRunId': {
      return { ...state, assignedStepRunId: action.value }
    }

    default: {
      throw new Error('Unhandled action type')
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const StepRunAssignmentProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(stepRunAssignmentReducer, {
    assignedStepRunId: null,
  })
  const value = { state, dispatch }
  return (
    <StepRunAssignmentContext.Provider value={value}>{children}</StepRunAssignmentContext.Provider>
  )
}

export const useStepRunAssignment = () => {
  const context = useContext(StepRunAssignmentContext)
  if (context === undefined) {
    throw new Error('useStepRunAssignment must be used within a StepRunAssignmentProvider')
  }
  return context
}
