import { SVGProps } from 'react'
function GoogleSheetOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='35'
      height='50'
      viewBox='0 0 35 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M34.7811 46.6201C34.7805 47.3557 34.4928 48.061 33.9811 48.5811C33.4694 49.1013 32.7756 49.3938 32.0519 49.3943H2.93175C2.20811 49.3943 1.51405 49.1024 1.00196 48.5827C0.489863 48.063 0.201593 47.3579 0.20044 46.6223V3.29587C0.200154 2.93118 0.270606 2.57002 0.407766 2.23303C0.544926 1.89605 0.746101 1.58986 0.999784 1.33199C1.25347 1.07412 1.55468 0.869616 1.88618 0.73019C2.21769 0.590765 2.57299 0.51915 2.93175 0.519441H21.6203L34.7811 14.1629V46.6201Z'
        fill='#23A566'
      />
      <path
        d='M23.0686 13.8072L34.781 25.1871V14.0836H24.406C23.953 14.0888 23.5065 13.9745 23.1099 13.752L23.0686 13.8072Z'
        fill='#1C8F5A'
      />
      <path
        d='M34.7809 14.141H24.3776C23.6539 14.1404 22.9601 13.8479 22.4484 13.3278C21.9367 12.8076 21.649 12.1024 21.6484 11.3668V0.444519L34.7809 14.141Z'
        fill='#8ED1B1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.4544 40.3843H8.45947V24.5237H26.4544V40.3843ZM18.5757 26.8116V29.588H24.2428V26.816L18.5757 26.8116ZM18.5757 31.3365V33.9892H24.2428V31.3233L18.5757 31.3365ZM18.5757 35.6802V38.1361H24.2428V35.667L18.5757 35.6802ZM16.3511 38.1229V35.667H10.6863V38.1229H16.3511ZM16.3511 33.9604V31.3233H10.6863V33.9737L16.3511 33.9604ZM16.3511 29.6167V26.8116H10.6863V29.588L16.3511 29.6167Z'
        fill='white'
      />
    </svg>
  )
}

export default GoogleSheetOriginal
