import { SVGProps } from 'react'
function BaseFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='30'
      height='23'
      viewBox='0 0 30 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.9375 0C0.419733 0 0 0.411167 0 0.918367V20.4337C0 20.9817 0.222237 21.5073 0.617822 21.8948C1.01341 22.2823 1.54994 22.5 2.10938 22.5H27.8906C28.4501 22.5 28.9866 22.2823 29.3822 21.8948C29.7778 21.5073 30 20.9817 30 20.4337V0.918367C30 0.411167 29.5803 0 29.0625 0H0.9375ZM10.0781 20.6633H27.8906C27.9528 20.6633 28.0124 20.6391 28.0564 20.596C28.1003 20.553 28.125 20.4946 28.125 20.4337V15.6122H10.0781V20.6633ZM8.20312 15.6122V20.6633H2.10938C2.04721 20.6633 1.9876 20.6391 1.94365 20.596C1.89969 20.553 1.875 20.4946 1.875 20.4337V15.6122H8.20312ZM10.0781 13.7755H28.125V8.72449H10.0781V13.7755ZM8.20312 8.72449V13.7755H1.875V8.72449H8.20312ZM28.125 6.88775V1.83673H1.875V6.88775H28.125Z'
        fill='currentColor'
      />
      <path d='M28.125 1.83673V6.88775H1.875V1.83673H28.125Z' fill='currentColor' />
    </svg>
  )
}

export default BaseFilled
