import { classNames } from '@invisible/common/helpers'
import { DEFAULT_AVATAR_PURPLE } from '@invisible/ui/images'
import { isNil } from 'lodash'
import Image from 'next/image'
import { FC } from 'react'

interface Props {
  src?: string
  size?: number
  marginLeft?: number
  optimized?: boolean
  objectFit?: 'cover' | 'scale-down' | 'contain' | 'fill'
  isOnline?: boolean
}
// eslint-disable-next-line @typescript-eslint/ban-types
export const Avatar: FC<Props> = ({
  size = 40,
  src = DEFAULT_AVATAR_PURPLE,
  marginLeft = 19,
  optimized = true,
  objectFit,
  isOnline,
}) => (
  <div className='flex items-end gap-0'>
    <Image
      width={size}
      height={size}
      src={src}
      style={{ marginLeft: `${marginLeft}px` }}
      className='rounded-full'
      unoptimized={!optimized}
      data-cy='avatar'
      objectFit={objectFit}
    />
    {!isNil(isOnline) ? (
      <div
        className={classNames(
          'relative right-2 h-2 w-2 rounded-full border border-2 border-solid',
          isOnline ? 'bg-success-main border-white' : 'border-paragraphs bg-white '
        )}
      />
    ) : null}
  </div>
)
