import { fromGlobalId } from '@invisible/concorde/gql-client'
import axios from 'axios'
import { format } from 'date-fns'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'
import { DATE_PRESETS, lastXDaysRegex } from './DateFilter'

const fetchBaseRunIds = async (filterConfigId: string) => {
  const response = await axios.get(
    `${NEXT_PUBLIC_CONCORDE_URL}/api/data-delivery/base-runs/list/`,
    {
      params: {
        filter_config_id: fromGlobalId(filterConfigId),
      },
      withCredentials: true,
    }
  )

  return response.data
}

const fetchBaseRunSample = async (baseRunId: string, filterConfigId: string) => {
  const response = await axios.get(
    `${NEXT_PUBLIC_CONCORDE_URL}/api/data-delivery/base-runs/get-by-id/`,
    {
      params: {
        filter_config_id: fromGlobalId(filterConfigId),
        base_run_id: baseRunId,
      },
      withCredentials: true,
    }
  )

  return response.data
}

const convertDateRangeToHumanReadableText = (dateRange: {
  start_date?: string
  end_date?: string
}) => {
  if (!dateRange || !dateRange.start_date || !dateRange.end_date) return ''

  // Dynamic date range
  if (dateRange.start_date.includes('now') || dateRange.end_date.includes('now')) {
    const xDays = dateRange.start_date?.match(lastXDaysRegex)?.groups?.days
    if (xDays) return `Last ${xDays} days`

    const keys = Object.keys(DATE_PRESETS) as (keyof typeof DATE_PRESETS)[]
    return keys.find((key) => {
      const [start, end] = DATE_PRESETS[key]
      return start === dateRange.start_date && end === dateRange.end_date
    })
  }

  // Fixed date range with date and time
  const startDate = format(new Date(dateRange.start_date), "d MMM yyyy 'at' hh:mm a")
  const endDate = format(new Date(dateRange.end_date), "d MMM yyyy 'at' hh:mm a")

  return `${startDate} - ${endDate}`
}
export { convertDateRangeToHumanReadableText, fetchBaseRunIds, fetchBaseRunSample }
