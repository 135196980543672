import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MouseEvent, ReactNode, useState } from 'react'

type TProps = {
  children?: ReactNode
  menuItems: { title: string; handleClick: () => void; icon?: React.ReactNode }[]
  className?: string
}

export function ContextMenu({ children, menuItems, className }: TProps) {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  const handleContextMenu = (event: MouseEvent) => {
    if (menuItems.length === 0) return

    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    )
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  return (
    <div onContextMenu={handleContextMenu} className={className}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }>
        {menuItems.map((menu, index) => (
          <MenuItem
            key={`${menu.title}-${index}`}
            onClick={() => {
              menu.handleClick()
              handleClose()
            }}>
            {menu?.icon} {'  '}
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
