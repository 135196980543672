import { SmallCheckbox } from '@invisible/ui/form'
import { CloseIcon } from '@invisible/ui/icons'
import { RowSelectionState, Table as TTable, Updater } from '@tanstack/react-table'
import { motion } from 'framer-motion'
import React, { CSSProperties, FC, PropsWithChildren } from 'react'

interface IBulkSelectActionProps {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}

type FloatingToolbarProps = {
  table: TTable<any>

  // eslint-disable-next-line @typescript-eslint/ban-types
  BulkSelectActionsComponent?: FC<IBulkSelectActionProps>
}

type CssProps = {
  style?: CSSProperties
  className?: string
}

type SelectCounterProps = {
  count: number
  singular: string
  plural: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BulkActionContainer: FC<PropsWithChildren<CssProps>> = ({ children, ...props }) => (
  <motion.div
    className='border-theme-main bg-void absolute left-6 z-40 flex h-10 items-center rounded-lg border-2 border-solid shadow-lg'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.2 }}
    exit={{ opacity: 0 }}
    {...props}>
    {children}
  </motion.div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const SelectCounter: FC<SelectCounterProps> = ({ count, singular, plural }) => (
  <div className='border-theme-main flex h-10 w-[80px] items-center justify-center  border-0 border-r-2 border-solid px-3'>
    <p>
      {count} {count > 1 ? plural : singular}
    </p>
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const CloseBulkAction: FC<PropsWithChildren<CssProps>> = ({ children, className, ...props }) => (
  <tr className='flex h-10  cursor-pointer items-center px-2' {...props}>
    {children}
  </tr>
)

const FloatingToolbar: React.FunctionComponent<FloatingToolbarProps> = (props) => {
  const { table, BulkSelectActionsComponent } = props
  return (
    <div
      className='text-theme-main flex text-left text-xs font-bold uppercase tracking-wide'
      title='Select All Rows'>
      <SmallCheckbox
        data-testid='select-all'
        checked={table.getIsAllRowsSelected()}
        onClick={() => table.toggleAllRowsSelected()}
      />
      <div className=' relative font-normal normal-case tracking-normal'>
        {table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? (
          <BulkActionContainer>
            <SelectCounter
              count={Object.keys(table.getState().rowSelection).length}
              singular='row'
              plural='rows'></SelectCounter>
            {BulkSelectActionsComponent ? (
              <BulkSelectActionsComponent
                rowSelection={table.getState().rowSelection}
                setRowSelection={table.setRowSelection}
              />
            ) : null}
            <CloseBulkAction>
              <div
                className='hover:bg-weak-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded'
                onClick={() => {
                  table.resetRowSelection()
                }}
                title='Unselect'>
                <CloseIcon width={16} height={16} />
              </div>
            </CloseBulkAction>
          </BulkActionContainer>
        ) : null}
      </div>
    </div>
  )
}

export { BulkActionContainer, CloseBulkAction, FloatingToolbar, SelectCounter }
