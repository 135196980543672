export const formatTime = (time: number) => {
  if (isNaN(time)) {
    return '--:--.--'
  }
  const hour = Math.floor(time / (60 * 60)) //
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  const milliseconds = Math.floor((time - Math.floor(time)) * 100)

  return `${padZero(hour)}:${padZero(minutes)}:${padZero(seconds)}.${padZero(milliseconds)}`
}

export const padZero = (number: number) => ('0' + number).slice(-2)

export const timeStringToSeconds = (timeString: string) => {
  if (!timeString) return 0

  // if time is in the format of hh:mm:ss.SS
  if (/^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)\.\d{2}$/.test(timeString)) {
    const [hoursStr, minutesStr, secondsWithMilliseconds] = timeString.split(':')
    const [secondsStr, millisecondsStr] = secondsWithMilliseconds.split('.')
    const [hours, minutes, seconds, milliseconds] = [
      hoursStr,
      minutesStr,
      secondsStr,
      millisecondsStr,
    ].map(parseFloat)
    return hours * 3600 + minutes * 60 + seconds + milliseconds / 100
  }
  // if time is in the format of mm:ss.SS
  if (/^(?:[0-5]\d):(?:[0-5]\d)\.\d{2}$/.test(timeString)) {
    const [minutesStr, secondsWithMilliseconds] = timeString.split(':')
    const [secondsStr, millisecondsStr] = secondsWithMilliseconds.split('.')
    const [minutes, seconds, milliseconds] = [minutesStr, secondsStr, millisecondsStr].map(
      parseFloat
    )
    return minutes * 60 + seconds + milliseconds / 100
  }
  return 0
}
