import { SVGProps } from 'react'

function BuildFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78046)'>
        <path
          d='M28.7029 20.1452H26.219V18.96C26.219 18.3437 26.0996 17.7274 25.8488 17.1467C25.5861 16.566 25.2278 16.0445 24.7621 15.606C24.2963 15.1674 23.747 14.8119 23.1499 14.5748C22.5409 14.3378 21.8841 14.2667 21.2511 14.2193H17.5253V10.6637H19.3882C20.7615 10.6637 21.8721 9.59707 21.8721 8.29337V2.36744C21.8721 1.06374 20.7496 -0.00292969 19.3882 -0.00292969H13.1665C11.7812 -0.00292969 10.6826 1.06374 10.6826 2.36744V8.29337C10.6826 9.59707 11.7932 10.6637 13.1665 10.6637H15.0294V14.2193H11.2677C10.6229 14.2193 9.98994 14.3378 9.38091 14.5748C8.78381 14.8119 8.23449 15.1556 7.76875 15.606C7.30302 16.0563 6.94476 16.566 6.69398 17.1467C6.43126 17.7274 6.31184 18.3319 6.32378 18.96V20.1452H3.83987C2.45461 20.1452 1.35596 21.2119 1.35596 22.5156V29.6267C1.35596 30.9304 2.46655 31.9971 3.83987 31.9971H11.3036C12.6769 31.9971 13.7875 30.9304 13.7875 29.6267V22.5156C13.7875 21.2119 12.6649 20.1452 11.3036 20.1452H8.81964V18.96C8.8077 18.64 8.86741 18.3319 8.99877 18.0474C9.13013 17.763 9.30926 17.5023 9.54809 17.2771C9.77499 17.0637 10.0497 16.886 10.3601 16.7674C10.6587 16.6489 10.9811 16.6252 11.3155 16.5897H21.275C21.5975 16.5897 21.9079 16.6489 22.2065 16.7674C22.517 16.886 22.7916 17.0637 23.0185 17.2771C23.2574 17.5023 23.4365 17.763 23.5559 18.0474C23.6873 18.3319 23.7589 18.64 23.7589 18.96V20.1452H21.275C19.8898 20.1452 18.7911 21.2119 18.7911 22.5156V29.6267C18.7911 30.9304 19.9017 31.9971 21.275 31.9971H28.7387C30.112 31.9971 31.2226 30.9304 31.2226 29.6267V22.5156C31.2226 21.2119 30.1001 20.1452 28.7387 20.1452H28.7029Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78046'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BuildFilled
