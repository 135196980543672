import { Text } from '@invisible/ui/text'
import { gray, styled, useTheme } from '@invisible/ui/themes'
import { FC, ReactNode } from 'react'
import {
  HeaderGroup,
  UseFiltersColumnProps,
  UseSortByColumnProps,
  UseSortByOptions,
  UseTableColumnProps,
} from 'react-table'
import { Box, Flex } from 'rebass'

import { BooleanCell } from './components/BooleanCell'
import { CurrencyCell } from './components/CurrencyCell'
import { DateCell } from './components/DateCell'
import { DateFilter } from './components/DateFilter'
import { DateTimeCell } from './components/DateTimeCell'
import { DurationCell } from './components/DurationCell'
import { FilterDropdown } from './components/FilterDropdown'
import { InputCell } from './components/InputCell'
import { MultiLineInputCell } from './components/MultiLineInputCell'
import { ProgressCell } from './components/ProgressCell'
import { RichTextCell } from './components/RichTextCell'
import { SortIcon } from './components/SortIcon'
import { StepRunControlsCell } from './components/StepRunControlsCell'
import { UrlCell } from './components/UrlCell'
import { WizardCell } from './components/WizardCell'

const Table = styled.table`
  display: inline-block;
  border-spacing: 0;
  width: 100%;
  overflow-y: visible;
`

const HeaderRow = styled.tr`
  background: ${gray(2)};
  box-shadow: inset 0px -1px 0px #f0f0f0;
  box-sizing: border-box;
  height: 50px;
`

const TableBody = styled.tbody`
  overflow-y: visible;
  width: 100%;
`

const DataRow = styled.tr`
  width: fit-content;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 48px;
  background-color: white;
`

const CellContainer = styled.div`
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`

export const IconContainer = styled(Flex)<{ visible: boolean | undefined }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  cursor: pointer;
`

const HeaderCellContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 15px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  background: ${gray(2)};
  &:hover ${IconContainer} {
    visibility: visible;
  }
`

interface IHeaderProps {
  column: HeaderGroup<any> &
    UseTableColumnProps<any> &
    UseSortByColumnProps<any> &
    UseFiltersColumnProps<any>
  sort?: boolean
}
// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderCell: FC<IHeaderProps> = ({ column, sort }) => {
  const { textColor } = useTheme()
  return (
    <HeaderCellContainer {...column.getHeaderProps()}>
      <Flex alignItems='center'>
        <Text fontWeight={500} color={textColor.title}>
          {column.render('Header')}
        </Text>
        {sort ? (
          <IconContainer ml='5px' alignItems='center' visible={column.isSorted}>
            <SortIcon
              {...column.getSortByToggleProps()}
              direction={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined}
            />
          </IconContainer>
        ) : null}
        <div>{column.canFilter ? column.render('Filter') : null}</div>
      </Flex>
    </HeaderCellContainer>
  )
}

interface IHeaderCellCustomSortProps {
  column: HeaderGroup<any> & { icon?: ReactNode } & UseFiltersColumnProps<any> &
    UseTableColumnProps<any> &
    UseSortByColumnProps<any> &
    UseSortByOptions<any>
  sortDirection?: 'asc' | 'desc'
  setSortDirection?: (value: 'asc' | 'desc' | undefined) => void
}

// This component is used for sorting and filtering manually (like on backend for example)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderCellCustomSort: FC<IHeaderCellCustomSortProps> = ({
  column,
  sortDirection,
  setSortDirection,
}) => {
  const { textColor } = useTheme()
  return (
    <HeaderCellContainer {...column.getHeaderProps()}>
      <Flex alignItems='center'>
        {column?.icon ? <Box mr={1}>{column.icon}</Box> : null}

        <Text fontWeight={500} color={textColor.title}>
          {column.render('Header')}
        </Text>
        {setSortDirection && !column.disableSortBy ? (
          <IconContainer
            ml='5px'
            alignItems='center'
            visible={sortDirection !== undefined}
            onClick={() => {
              setSortDirection(
                sortDirection === 'desc' ? 'asc' : sortDirection === 'asc' ? undefined : 'desc'
              )
            }}>
            <SortIcon direction={sortDirection ? sortDirection : undefined} />
          </IconContainer>
        ) : null}

        <div>{column.canFilter ? column.render('Filter') : null}</div>
      </Flex>
    </HeaderCellContainer>
  )
}

const ReactTable = Object.assign(Table, {
  HeaderRow,
  TableBody,
  DataRow,
  CellContainer,
  HeaderCell,
  HeaderCellCustomSort,
})

export {
  BooleanCell,
  CurrencyCell,
  DateCell,
  DateFilter,
  DateTimeCell,
  DurationCell,
  FilterDropdown,
  InputCell,
  MultiLineInputCell,
  ProgressCell,
  ReactTable,
  RichTextCell,
  StepRunControlsCell,
  UrlCell,
  WizardCell,
}
