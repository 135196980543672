import { SVGProps } from 'react'

function Client(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.80444 27C1.80444 26.4477 2.25216 26 2.80444 26H30.8044C31.3567 26 31.8044 26.4477 31.8044 27C31.8044 27.5523 31.3567 28 30.8044 28H2.80444C2.25216 28 1.80444 27.5523 1.80444 27Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.39023 3.58579C4.7653 3.21071 5.27401 3 5.80444 3H17.8044C18.3349 3 18.8436 3.21071 19.2187 3.58579C19.5937 3.96086 19.8044 4.46957 19.8044 5V27C19.8044 27.5523 19.3567 28 18.8044 28C18.2522 28 17.8044 27.5523 17.8044 27V5H5.80444V27C5.80444 27.5523 5.35673 28 4.80444 28C4.25216 28 3.80444 27.5523 3.80444 27V5C3.80444 4.46957 4.01516 3.96086 4.39023 3.58579Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8044 12C17.8044 11.4477 18.2522 11 18.8044 11H27.8044C28.3349 11 28.8436 11.2107 29.2187 11.5858C29.5937 11.9609 29.8044 12.4696 29.8044 13V27C29.8044 27.5523 29.3567 28 28.8044 28C28.2522 28 27.8044 27.5523 27.8044 27V13H18.8044C18.2522 13 17.8044 12.5523 17.8044 12Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.80444 9C7.80444 8.44772 8.25216 8 8.80444 8H12.8044C13.3567 8 13.8044 8.44772 13.8044 9C13.8044 9.55228 13.3567 10 12.8044 10H8.80444C8.25216 10 7.80444 9.55228 7.80444 9Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.80444 17C9.80444 16.4477 10.2522 16 10.8044 16H14.8044C15.3567 16 15.8044 16.4477 15.8044 17C15.8044 17.5523 15.3567 18 14.8044 18H10.8044C10.2522 18 9.80444 17.5523 9.80444 17Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.80444 22C7.80444 21.4477 8.25216 21 8.80444 21H12.8044C13.3567 21 13.8044 21.4477 13.8044 22C13.8044 22.5523 13.3567 23 12.8044 23H8.80444C8.25216 23 7.80444 22.5523 7.80444 22Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8044 22C21.8044 21.4477 22.2522 21 22.8044 21H24.8044C25.3567 21 25.8044 21.4477 25.8044 22C25.8044 22.5523 25.3567 23 24.8044 23H22.8044C22.2522 23 21.8044 22.5523 21.8044 22Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8044 17C21.8044 16.4477 22.2522 16 22.8044 16H24.8044C25.3567 16 25.8044 16.4477 25.8044 17C25.8044 17.5523 25.3567 18 24.8044 18H22.8044C22.2522 18 21.8044 17.5523 21.8044 17Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Client
