import type {
  TChoiceFreeTextHandler,
  TQuestionnaireAnswerChangeHandler,
  TQuestionTree,
} from '@invisible/common/types'
import { SmallCheckbox } from '@invisible/ui/form'
import React, { FC } from 'react'

interface IProps {
  onChange?: TQuestionnaireAnswerChangeHandler
  onChoiceFreeTextChange?: TChoiceFreeTextHandler
  question: TQuestionTree
  choices: string[]
  readOnly?: boolean
  freeText?: { [optionId: string]: string }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MultiChoice: FC<IProps> = ({
  question,
  onChange,
  choices,
  readOnly,
  freeText,
  onChoiceFreeTextChange,
}) => {
  const handleClick = (c: string) => {
    if (readOnly || !onChange) return
    onChange({ questionId: question.id, value: c, type: 'multi_choice' })
  }

  const handleFreeTextChange = (optionId: string, value: string) => {
    if (readOnly || !onChoiceFreeTextChange) return
    onChoiceFreeTextChange({ questionId: question.id, optionId, value })
  }

  const computeChecked = (c: string) => (!choices.length ? false : choices.includes(c))

  return (
    <div>
      {question.options.map((o) => (
        <div key={o.id} className='mt-2 flex'>
          <div>
            <SmallCheckbox checked={computeChecked(o.id)} onClick={() => handleClick(o.id)} />
          </div>
          <div className='flex items-center space-x-3'>
            <div>{o.text}</div>
            {o.isCustom && choices.includes(o.id) ? (
              <div>
                <input
                  type='text'
                  value={freeText?.[o.id] ?? ''}
                  disabled={Boolean(readOnly)}
                  placeholder='Free text entry'
                  className='border-b border-transparent border-gray-300 focus:border-blue-500'
                  onChange={(e) => handleFreeTextChange(o.id, e.target.value)}
                />
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
}

export { MultiChoice }
