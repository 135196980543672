import { z } from '@invisible/zod'

const schema = z.object({
  ids: z.array(z.string()).optional(),
  companyId: z.number().optional(),
  platform: z.enum(['portal', 'manticore']).optional(),
  scope: z.enum(['company', 'process']).optional(),
  withCoreReports: z.boolean().optional(),
  reports: z
    .array(
      z.object({
        reportName: z.string(),
        visualizations: z.array(z.string()).optional(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
