export const copyToClipboard = async (text: string | number) => {
  const clipboardWritePermission = 'clipboard-write' as PermissionName

  // Ensure document is focused else early return
  if (!document.hasFocus()) return

  const result = await navigator.permissions.query({
    name: clipboardWritePermission,
  })
  if (result.state === 'granted' || result.state === 'prompt') {
    return navigator.clipboard.writeText(String(text))
  }
}
