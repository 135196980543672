import { ITableColumn } from '@invisible/common/types'
import { createColumnHelper, Table } from '@invisible/ui/table'
import { map } from 'lodash/fp'

const columnHelper = createColumnHelper<Record<string, unknown>>()

const formatColumns = (columns: ITableColumn[]) =>
  map(
    (column: ITableColumn) =>
      columnHelper.accessor(column.accessor, {
        header: column.Header,
      }),
    columns
  )

export const ReportClassicTable = ({
  data,
  columns,
  isLoading,
}: {
  columns: ITableColumn[]
  data: Record<string, unknown>[]
  isLoading: boolean
}) => (
  <Table.Container>
    <Table.PaginationProvider>
      <Table
        data={data}
        columns={formatColumns(columns)}
        isDataLoading={isLoading}
        variant='rounded'
        stickyHeader={true}
        maxHeight='300px'
        manualPagination={false}
      />
      <Table.Pagination />
    </Table.PaginationProvider>
  </Table.Container>
)
