import { BranchStepMeta } from '@invisible/ultron/zod'
import { values } from 'lodash/fp'

export const MAXIMUM_EXPORTABLE_BASERUNS = 75000 as const
export const DEFAULT_ITEMS_PER_PAGE = 40 as const

// Process Builder
export const DND_DRAG_TYPES = {
  CANVAS_STEP: 'CanvasStep',
  STEP_TEMPLATE: 'StepTemplate',
  TRIGGER_STEP: 'TriggerStep',
} as const

export const NODE_TYPES = {
  START: 'start',
  END: 'end',
  REGULAR: 'regular',
  TRIGGER: 'trigger',
  TRIGGER_PLACEHOLDER: 'triggerPlaceholder',
  STAGE: 'stage',
} as const
export const _NODE_TYPES = values(NODE_TYPES)

export const EDGE_TYPES = {
  DEFAULT: 'default',
  INITIAL_TRIGGER: 'initialTrigger',
} as const
export const _EDGE_TYPES = values(EDGE_TYPES)

export const conditionSymbols: Record<BranchStepMeta.TBranch['condition']['name'], string> = {
  eq: '=',
  neq: '≠',
  isNull: '= NULL',
  isNotNull: '≠ NULL',
}

// Code editor constants
export const SUPPORTED_LANGUAGES = {
  PYTHON: 'Python',
  JAVA: 'Java',
  C: 'C',
  CPP: 'Cpp',
  JAVASCRIPT: 'Javascript',
  TYPESCRIPT: 'Typescript',
} as const
export const _SUPPORTED_LANGUAGES = values(SUPPORTED_LANGUAGES)

export const DEFAULT_CODE_SNIPPETS = {
  PYTHON: '# Enter your code here',
  JAVA: '// Enter your code here',
  C: '// Enter your code here',
  CPP: '// Enter your code here',
  JAVASCRIPT: '// Enter your code here',
  TYPESCRIPT: '// Enter your code here',
} as const

export const INVISIBLE_TRAINING_ENROLLEMENT_ID = 'bc74d8fb-518c-4924-9e51-199e612d65b8'
export const ONBOARDING_TRAINING_ENROLLEMENT_ID = 'ac474848-aa2b-4839-9315-f3797a5a664c'
export const LOOOP_INV_URL = 'https://inv.looop.co/api/v1'
