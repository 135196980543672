import { SVGProps } from 'react'

function EmailCom(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={48}
      viewBox='0 0 50 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M36.643 19.116c-6.86 0-12.442 5.581-12.442 12.442 0 1.541.687 3.807 1.018 4.804l-1.243 3.795c-.151.458-.061.965.241 1.34.303.377.773.581 1.256.532l4.015-.388C30.706 42.287 34.16 44 36.643 44c6.86 0 12.442-5.581 12.442-12.442 0-6.86-5.581-12.442-12.442-12.442zm0 22.018c-1.811 0-5.058-1.599-6.158-2.212a1.451 1.451 0 00-.699-.184c-.045 0-.09.004-.135.008l-2.273.22.711-2.166c.099-.303.094-.63-.012-.928-.282-.794-1.014-3.112-1.014-4.314 0-5.283 4.297-9.58 9.58-9.58s9.58 4.297 9.58 9.58c0 5.279-4.297 9.576-9.58 9.576z'
        fill='currentColor'
      />
      <path
        d='M3.144 34.228V8.354l17.152 13.322a1.432 1.432 0 001.758 0L39.206 8.354v8.117c.99.167 1.947.429 2.863.776V5.431c0-.016-.005-.029-.005-.045a.95.95 0 00-.016-.155 1.093 1.093 0 00-.02-.123c-.012-.045-.029-.094-.041-.139-.017-.045-.029-.086-.05-.13-.016-.042-.04-.079-.06-.115a.843.843 0 00-.082-.127c-.009-.012-.017-.029-.025-.04-.016-.025-.04-.042-.057-.062a2.508 2.508 0 00-.102-.11.698.698 0 00-.107-.087c-.036-.028-.073-.057-.114-.081a1.712 1.712 0 00-.25-.123c-.04-.016-.085-.029-.13-.04-.045-.013-.09-.025-.135-.034-.05-.008-.098-.012-.148-.016-.032.004-.06-.004-.09-.004H1.714c-.029 0-.057.008-.086.008-.049.004-.098.008-.147.017a1.589 1.589 0 00-.266.069c-.045.016-.086.037-.13.057-.042.02-.083.041-.123.066-.041.024-.078.053-.115.081a.949.949 0 00-.102.086c-.037.033-.07.07-.102.107-.02.024-.041.04-.058.061-.012.012-.016.029-.024.04a2.204 2.204 0 00-.147.246l-.05.127c-.012.049-.028.094-.04.139-.008.04-.017.082-.02.127-.009.053-.017.102-.017.155 0 .016-.004.029-.004.045V35.66c0 .79.642 1.431 1.43 1.431h20.256l.246-.748a27.405 27.405 0 01-.544-2.114H3.144zm18.031-15.492L5.887 6.862H36.46L21.175 18.736zM31.352 29.82c-.96 0-1.738.777-1.738 1.738 0 .96.777 1.738 1.738 1.738.96 0 1.738-.777 1.738-1.738 0-.96-.777-1.738-1.738-1.738zM36.643 33.296a1.738 1.738 0 100-3.476 1.738 1.738 0 000 3.476zM41.934 29.82c-.961 0-1.738.777-1.738 1.738 0 .96.777 1.738 1.738 1.738.96 0 1.738-.777 1.738-1.738 0-.96-.777-1.738-1.738-1.738z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EmailCom
