import { UuidSchema, z } from '@invisible/zod'

const conditionSchema = z
  .object({
    dependsOn: UuidSchema,
    condition: z.enum(['is', 'not', 'greaterThan', 'lessThan']),
    value: UuidSchema.or(z.string()).or(z.number()),
  })
  .strict()

const schema = z.object({
  skipCondition: conditionSchema,
})

type TSchema = z.infer<typeof schema>
type TQuestionConditionSchema = z.infer<typeof conditionSchema>

export { conditionSchema, schema }
export type { TQuestionConditionSchema, TSchema }
