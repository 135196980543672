import { zodResolver } from '@hookform/resolvers/zod'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { Button } from '@invisible/ui/button'
import { Divider } from '@invisible/ui/divider'
import { CopyOutlineIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { FC, forwardRef, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ReadOnlyInterfaceNotAvailable } from '../../../interface/NotAvailable'
import { formatObject } from '../../helpers'
import type { TCustomWACProps } from '../../types'
import { useOperatorSubmit } from './lib/hooks'
import { frCasesSchema } from './lib/schemas'
import { OperateFormData } from './lib/types'

// eslint-disable-next-line @typescript-eslint/ban-types
const FinalReviewQaOperateWAC: FC<TCustomWACProps> = forwardRef(
  ({ stepRun, baseRun, wizardInitialBRVs, isReadOnly }: TCustomWACProps, wizardContainerRef) => {
    const { dispatch } = useWizardState()
    const casesBaseRunVariables = useMemo(
      () => formatObject({ baseRun, wizardInitialBRVs }),
      [baseRun, wizardInitialBRVs]
    )
    const [currentWizardPage, setCurrentWizardPage] = useState<number>(1)
    const [packageStatus, setPackageStatus] = useState<string>('Inhouse Data Entry')
    const { handleOperateSubmit, isUpdateBaseRunVariablesLoading, isMarkStepRunDoneLoading } =
      useOperatorSubmit({
        stepRunId: stepRun.id,
        onFinish: () => dispatch({ type: 'closeWizard' }),
      })

    const { register, getValues, setValue } = useForm<OperateFormData>({
      mode: 'all',
      resolver: zodResolver(frCasesSchema),
      defaultValues: {
        goLiveID: casesBaseRunVariables['Go Live ID'],

        custID: casesBaseRunVariables['Cust ID'],

        accountName: casesBaseRunVariables['Account Name'],

        goLiveName: casesBaseRunVariables['Go Live Name'],

        caseUrl: casesBaseRunVariables['Case URL'],

        finalReviewType: casesBaseRunVariables['Final Review Type'],

        packageStatus: casesBaseRunVariables['Package Status'],

        virtualRestaurant: casesBaseRunVariables['Virtual Restaurant'],

        containsAlcohal: casesBaseRunVariables['Contains Alcohal'],

        externalNotes: casesBaseRunVariables['External Notes'],

        menuItemCount: casesBaseRunVariables['Menu Item Count'],

        menuCopy: casesBaseRunVariables['Menu Copy'],
      },
    })

    const nextPage = () => {
      switch (currentWizardPage) {
        case 2:
          setCurrentWizardPage(3)
          return
        default:
          setCurrentWizardPage(currentWizardPage + 1)
      }
    }

    const prevPage = () => {
      if (currentWizardPage === 0) {
        return
      }
      setCurrentWizardPage(currentWizardPage - 1)
    }

    const submitForm = async () => {
      setValue('packageStatus', 'Inhouse Data Entry')

      await handleOperateSubmit(casesBaseRunVariables, getValues())
    }

    const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text)
    }

    if (isReadOnly) return <ReadOnlyInterfaceNotAvailable tooltipText={'Grubhub FR QA WAC'} />

    return (
      <div className='flex h-full rounded border bg-white p-4 shadow'>
        <div className='block w-full space-y-1.5'>
          <div>
            <header>
              <div className='row mx-auto flex	 max-w-screen-xl flex-wrap items-center justify-between border-4 border-red-500 text-lg'>
                <h4>
                  GoLive: ID - {casesBaseRunVariables['Go Live ID']} (
                  {casesBaseRunVariables['Account Name']})
                </h4>
                <h4>
                  <span className='mr-10'>Final Review QA</span>
                </h4>
              </div>
            </header>
            <Divider />
            <div className='grid grid-cols-6 gap-3'>
              <section className='col-span-2 border-0 border-r-2 border-solid border-stone-300  p-10'>
                <div className='flex flex-col'>
                  <div className='mb-10 '>
                    <div className='font-black'>Name</div>
                    <span>{casesBaseRunVariables['Account Name']}</span>
                  </div>
                  <div className='mb-5 '>
                    <div className='font-black'>Customer ID</div>
                    <span>{casesBaseRunVariables['Cust ID']}</span>
                  </div>
                  <div className='mb-5 '>
                    <div className='font-black'>SFDC URL</div>
                    <span>
                      <a href={casesBaseRunVariables['Case URL']} target='_blank' rel='noreferrer'>
                        {casesBaseRunVariables['Case URL']}
                      </a>
                    </span>
                  </div>
                </div>
              </section>
              {currentWizardPage === 1 && (
                <div className='col-span-4'>
                  <div className='m-10'>
                    <h2>Welcome to Final Review QA Process</h2>
                    <div>
                      The goal of this process is to perform internal QA of the final reviews.
                    </div>

                    <div className='mt-10'>
                      <div>Steps:</div>
                      <ul className='bullet-list'>
                        <li className='p-1'>Open the Case</li>
                        <li className='p-1'>Understand the request</li>
                        <li className='p-1'>Perform the necessary actions to complete the QA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {currentWizardPage === 2 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Welcome to Final Review QA Process</h2>
                      <div className='mt-10'>
                        <div className='mt-10 flex items-center'>
                          <div className='mr-2 w-40'>Go Live ID:</div>

                          <Input
                            value={casesBaseRunVariables['Go Live ID']}
                            mr={200}
                            width={500}
                            disabled
                          />
                          <CopyOutlineIcon
                            onClick={(e) => handleCopy(casesBaseRunVariables['Go Live ID'])}
                            cursor='pointer'
                          />
                        </div>
                        <div className='mt-10 flex items-center'>
                          <div className='mr-2 w-40'>Package Status</div>

                          <Input value={packageStatus} mr={200} width={500} disabled />
                        </div>
                        <div className='mt-10 flex items-center'>
                          <div className='mr-2 w-40'>Final Review Type</div>

                          <Input {...register('finalReviewType')} mr={200} width={500} disabled />
                        </div>

                        <div className='mt-10 flex items-center'>
                          <div className='mr-2 w-40'>External Notes</div>

                          <Input {...register('externalNotes')} mr={200} width={500} />
                        </div>
                        <div className='mt-10 flex items-center'>
                          <div className='mr-2 w-40'>Final Review Agent</div>

                          <Input
                            value={casesBaseRunVariables['Final Review Rep Name']}
                            mr={200}
                            width={500}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentWizardPage === 3 && (
                <div className='col-span-3'>
                  <div className='p-1'>
                    <div className='m-10'>
                      <h2>Ready to submit</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='flex w-full items-center justify-end space-x-2 bg-opacity-10'>
              {currentWizardPage === 1 && (
                <Button
                  variant='secondary'
                  size='md'
                  onClick={() => dispatch({ type: 'closeWizard' })}>
                  Cancel
                </Button>
              )}

              {currentWizardPage !== 1 && (
                <Button variant='secondary' size='md' onClick={() => prevPage()}>
                  Back
                </Button>
              )}
              {currentWizardPage !== 3 && (
                <Button variant='primary' size='md' onClick={() => nextPage()}>
                  Next
                </Button>
              )}
              {currentWizardPage === 3 && (
                <Button
                  variant='primary'
                  size='md'
                  onClick={() => submitForm()}
                  loading={isUpdateBaseRunVariablesLoading || isMarkStepRunDoneLoading}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

FinalReviewQaOperateWAC.displayName = 'FinalReviewQaOperateWAC'

export { FinalReviewQaOperateWAC }
