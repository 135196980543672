import { SVGProps } from 'react'
function SignOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g
        id='Sign-Out-Outlined'
        transform='translate(.125 .125)'
        fill='currentColor'
        fillRule='nonzero'>
        <path
          d='M6.882 0c.422 0 .765.336.765.75s-.343.75-.765.75H1.529v15h5.353c.422 0 .765.336.765.75s-.343.75-.765.75H1.529c-.405 0-.794-.158-1.081-.44A1.486 1.486 0 0 1 0 16.5v-15C0 1.102.161.72.448.44.735.157 1.124 0 1.529 0Zm5.932 4.093c.183 0 .345.06.488.18l4.484 4.248c.143.13.214.287.214.472a.614.614 0 0 1-.214.47l-4.515 4.265a.661.661 0 0 1-.457.18.646.646 0 0 1-.488-.21.602.602 0 0 1-.191-.464.655.655 0 0 1 .206-.464l3.295-3.112h-8.74a.661.661 0 0 1-.458-.18.63.63 0 0 1-.023-.928.67.67 0 0 1 .495-.208h8.756L12.371 5.23a.607.607 0 0 1-.213-.464c0-.18.063-.337.19-.471a.616.616 0 0 1 .466-.202Z'
          id='SignOut'
        />
      </g>
    </svg>
  )
}

export default SignOut
