import { Ability, ForcedSubject, RawRuleOf, Subject as TSubject } from '@casl/ability'
import type { Session } from 'next-auth'

import interpolate from './helpers/interpolate'
export * from '@casl/ability'

export type Abilities<Action, Subject> = [Action, Subject | ForcedSubject<Exclude<Subject, 'all'>>]
export type AppAbility<Action extends string, Subject> = Ability<Abilities<Action, Subject extends TSubject ? Subject : never>>
export function createAbility<Action extends string, Subject>(
  rules: RawRuleOf<AppAbility<Action, Subject>>[],
  context?: Record<string, unknown>
) {
  if (context) {
    rules = interpolate(JSON.stringify(rules), context)
  }
  return new Ability<Abilities<Action, Subject extends TSubject ? Subject : never>>(rules)
}

export function createAbilityFromPermissions<Action extends string, Subject>(
  permissions: RawRuleOf<AppAbility<Action, Subject>>[],
  session?: (Partial<Session> & { userId?: string }) | null
): AppAbility<Action, Subject> {
  return createAbility<Action, Subject>(
    permissions,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore next-auth typings
    session
  )
}
