import { SVGProps } from 'react'
function FileOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='29'
      viewBox='0 0 25 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.994997 1.62792C1.40417 1.22399 1.95912 0.99707 2.53778 0.99707H15.6287C15.918 0.99707 16.1955 1.11053 16.4001 1.31249L24.0364 8.85096C24.241 9.05292 24.356 9.32684 24.356 9.61246V26.8432C24.356 27.4145 24.1261 27.9623 23.7169 28.3662C23.3077 28.7702 22.7528 28.9971 22.1741 28.9971H2.53778C1.95912 28.9971 1.40417 28.7702 0.994997 28.3662C0.585827 27.9623 0.355957 27.4145 0.355957 26.8432V3.15092C0.355957 2.57968 0.585827 2.03184 0.994997 1.62792ZM15.1768 3.15092L2.53778 3.15092L2.53778 26.8432H22.1741V10.0585L15.1768 3.15092Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6287 0.99707C16.2312 0.99707 16.7196 1.47923 16.7196 2.07399V8.53553H23.265C23.8675 8.53553 24.356 9.01769 24.356 9.61246C24.356 10.2072 23.8675 10.6894 23.265 10.6894H15.6287C15.0262 10.6894 14.5378 10.2072 14.5378 9.61246V2.07399C14.5378 1.47923 15.0262 0.99707 15.6287 0.99707Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FileOutline
