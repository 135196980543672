import { SVGProps } from 'react'
function PortalDashboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.45 4.53a6.98 6.98 0 00-1.5-2.225A6.98 6.98 0 007 .255a6.98 6.98 0 00-4.95 2.05A6.98 6.98 0 000 7.255c0 2.073.91 4.026 2.498 5.36l.027.022c.09.075.205.118.322.118h8.308a.507.507 0 00.322-.118l.026-.021A6.99 6.99 0 0014 7.255c0-.946-.186-1.861-.55-2.725zm-2.553 7.037H3.103a5.798 5.798 0 01-1.916-4.312c0-1.554.605-3.013 1.704-4.11A5.774 5.774 0 017 1.442c1.553 0 3.012.605 4.11 1.703a5.774 5.774 0 011.703 4.11c0 1.65-.696 3.21-1.916 4.312zM8.742 5.091a.125.125 0 00-.176 0l-1.32 1.32a.873.873 0 00-1.103.845.874.874 0 101.721-.226l1.32-1.32a.125.125 0 000-.177l-.442-.442zM6.656 3.505h.688a.125.125 0 00.125-.125V2.13a.125.125 0 00-.125-.125h-.688a.125.125 0 00-.125.125v1.25c0 .068.057.125.125.125zM10.72 6.91v.687c0 .07.056.125.125.125h1.25a.125.125 0 00.125-.125v-.687a.125.125 0 00-.125-.125h-1.25a.125.125 0 00-.125.125zm.198-3.081l-.486-.486a.125.125 0 00-.176 0l-.885.884a.125.125 0 000 .177l.486.486a.125.125 0 00.177 0l.884-.885a.125.125 0 000-.176zm-7.165-.486a.125.125 0 00-.177 0l-.486.486a.125.125 0 000 .176l.884.885a.125.125 0 00.177 0l.486-.486a.125.125 0 000-.177l-.884-.884zm-.658 3.442h-1.25a.125.125 0 00-.125.125v.687c0 .07.056.125.125.125h1.25a.125.125 0 00.125-.125v-.687a.125.125 0 00-.125-.125z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PortalDashboard
