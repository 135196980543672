import { SVGProps } from 'react'
function ViewProcess(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      {...props}>
      <path
        d='M4.74671 11.9467C4.94004 11.48 5.18671 11.0533 5.50004 10.6667H3.66671V8.66666H8.30004C8.93337 8.43999 9.61337 8.33332 10.3334 8.33332C10.7934 8.33332 11.24 8.37999 11.6667 8.46666V7.33332H7.66671V5.33332H9.66671V0.666656H4.33337V5.33332H6.33337V7.33332H2.33337V10.6667H0.333374V15.3333H5.66671V14.2133C5.28004 13.7667 4.97337 13.2667 4.74671 12.7199C4.64004 12.4733 4.64004 12.1933 4.74671 11.9467ZM5.66671 3.99999V1.99999H8.33337V3.99999H5.66671ZM4.33337 14H1.66671V12H4.33337V14Z'
        fill='currentColor'
      />
      <path
        d='M10.3334 11.3333C10.0534 11.3333 9.82004 11.4333 9.62671 11.6267C9.43337 11.82 9.33337 12.0533 9.33337 12.3333C9.33337 12.6133 9.43337 12.8467 9.62671 13.04C9.82004 13.2333 10.0534 13.3333 10.3334 13.3333C10.6134 13.3333 10.8467 13.2333 11.04 13.04C11.2334 12.8467 11.3334 12.6133 11.3334 12.3333C11.3334 12.0533 11.2334 11.82 11.04 11.6267C10.8467 11.4333 10.6134 11.3333 10.3334 11.3333Z'
        fill='currentColor'
      />
      <path
        d='M13.1934 10.1733C12.92 9.99332 12.6334 9.83999 12.3334 9.71992C11.8867 9.52659 11.4134 9.41326 10.9067 9.36659C10.72 9.33992 10.5267 9.33326 10.3334 9.33326C9.26671 9.33326 8.31337 9.61326 7.47337 10.1733C6.63337 10.7333 6.03337 11.4533 5.66671 12.3333C6.03337 13.2133 6.63337 13.9333 7.47337 14.4933C8.31331 15.0533 9.26671 15.3333 10.3334 15.3333C11.4 15.3333 12.3534 15.0533 13.1934 14.4933C14.0334 13.9333 14.6334 13.2133 15 12.3333C14.6334 11.4533 14.0334 10.7333 13.1934 10.1733ZM12.16 13.5467C11.6 13.8467 10.9867 14 10.3334 14C9.68004 14 9.06671 13.8467 8.50671 13.5467C7.94671 13.2467 7.50004 12.8467 7.16671 12.3333C7.50004 11.82 7.94671 11.4133 8.50671 11.1133C9.06671 10.8133 9.68004 10.6667 10.3334 10.6667C10.9867 10.6667 11.6 10.8133 12.16 11.1133C12.72 11.4133 13.1667 11.82 13.5 12.3333C13.1667 12.8467 12.72 13.2467 12.16 13.5467Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ViewProcess
