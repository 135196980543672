import {
  fromGlobalId,
  ILoggedInUserQuery,
  useLoggedInUserQuery,
} from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { useGate } from 'statsig-react'

const mapGraphQLData = (user?: NonNullable<ILoggedInUserQuery['me']>) => {
  if (!user) return undefined
  return {
    id: fromGlobalId(user?.id),
    email: user?.email,
  }
}

const useGetLoggedInUser = () => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-get-logged-in-user')

  // tRPC query
  const { data: trpcResult, isLoading: isTrpcResultLoading } = useTrpcQuery(
    ['user.getLoggedInUser'],
    {
      enabled: !isGraphqlEnabled,
      refetchOnWindowFocus: false,
    }
  )

  // GraphQL query
  const { data: graphqlResult, isLoading: isGraphqlResultLoading } = useLoggedInUserQuery(
    {},
    {
      enabled: isGraphqlEnabled,
      refetchOnWindowFocus: false,
    }
  )

  const data = isGraphqlEnabled ? mapGraphQLData(graphqlResult?.me) : trpcResult
  return {
    data,
    isLoading: isGraphqlEnabled ? isGraphqlResultLoading : isTrpcResultLoading,
  }
}

export { useGetLoggedInUser }
