import { copyToClipboard } from '@invisible/ui/helpers'
import { CopyOutlineIcon } from '@invisible/ui/icons'
import { gray, styled } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { FC } from 'react'

type IProps = WizardSchemas.WACConfig.TSchema

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const TextWAC: FC<IProps> = ({ value, showName, name }) => {
  const { addToast } = useToasts()

  return (
    <Container>
      {showName ? <div className='mb-2.5 font-bold'>{name}</div> : null}
      <div className='flex items-center gap-3'>
        <div>{value}</div>
        <div className='text-theme-main cursor-pointer'>
          <button
            onClick={() => {
              copyToClipboard(String(value))
              addToast(`Text successfully copied to clipboard`, {
                appearance: 'success',
              })
            }}
            className='flex cursor-pointer appearance-none items-center rounded-full border-none bg-transparent text-violet-900 hover:bg-gray-200 focus:bg-gray-200'>
            <CopyOutlineIcon width='20px' />
          </button>
        </div>
      </div>
    </Container>
  )
}
