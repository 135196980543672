import { z } from '@invisible/zod'

import { schema as dataFilterSchema } from '../../DataFilter'

const schema = z.object({
  id: z.string(),
  filters: z.array(dataFilterSchema),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
