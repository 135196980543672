import { TBaseViewDownloadFormat, TFilterOption } from '@invisible/common/stores/process-store'
import { BaseViewMeta } from '@invisible/ultron/zod'
import axios from 'axios'
import { isPlainObject, snakeCase } from 'lodash/fp'
import { useMutation } from 'react-query'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

interface IProps {
  filters: TFilterOption[]
  stepRunFilters: BaseViewMeta.TSchema['stepRunFilters']
  sort: Record<string, 'asc' | 'desc' | undefined>
  baseId: string
  baseViewId: string
  search: string
  downloadFormat: TBaseViewDownloadFormat
  useAssigneeIdsAtExport?: boolean
}

const useExportBaseView = ({
  filters,
  sort,
  baseId,
  baseViewId,
  search,
  downloadFormat,
  useAssigneeIdsAtExport,
  stepRunFilters,
}: IProps) => {
  const { isLoading, mutateAsync } = useMutation(['export-base-view'], async () => {
    // Transforms createdAt and deliveredAt to snake_case
    const transformedSort = sort?.createdAt
      ? { created_at: sort.createdAt }
      : sort?.deliveredAt
      ? { delivered_at: sort.deliveredAt }
      : sort

    const transformedFilters = filters.map((filterItem) => {
      // We also want to transform {startDate: Date, endDate: Date} to {start_date: Date, end_date: Date} if present as the value
      const value =
        typeof filterItem.value === 'object' &&
        isPlainObject(filterItem.value) &&
        'startDate' in filterItem.value &&
        'endDate' in filterItem.value
          ? { start_date: filterItem.value.startDate, end_date: filterItem.value.endDate }
          : filterItem.value

      return {
        key: ['parentBaseRunId', 'createdAt', 'deliveredAt'].includes(filterItem.id)
          ? snakeCase(filterItem.id)
          : filterItem.id,
        value,
        ...(filterItem.isStepAssignee ? { is_step_assignee: filterItem.isStepAssignee } : {}),
        ...(filterItem.isStepStatus ? { is_step_status: filterItem.isStepStatus } : {}),
        ...(filterItem.isBaseRunStatus ? { is_base_run_status: filterItem.isBaseRunStatus } : {}),
      }
    })

    const queryUrl = new URL(
      `${NEXT_PUBLIC_CONCORDE_URL}/data/api/base-runs/find-by-base-id/export/`
    )
    queryUrl.searchParams.set('base_id', baseId)
    queryUrl.searchParams.set('base_view_id', baseViewId)
    queryUrl.searchParams.set('search', search)
    queryUrl.searchParams.set('sort', JSON.stringify(transformedSort))
    queryUrl.searchParams.set('extra_filters', JSON.stringify(transformedFilters))
    queryUrl.searchParams.set('output_format', downloadFormat)
    queryUrl.searchParams.set('step_run_filters', JSON.stringify(stepRunFilters))
    if (useAssigneeIdsAtExport) {
      queryUrl.searchParams.set(
        'field_options',
        JSON.stringify({ show_step_run_assignee_id: true })
      )
    }

    await axios.get(queryUrl.toString(), {
      withCredentials: true,
    })
  })

  return { isLoading, mutateAsync }
}

export { useExportBaseView }
