import { convertSecondsToHoursMinutesSeconds } from '@invisible/common/date'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { TextArea } from '@invisible/ui/form'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { useToasts } from '@invisible/ui/toasts'
import type { PrismaAll, TInvoiceEventBalanceType } from '@invisible/ultron/prisma'
import { ChangeEvent, FC, useState } from 'react'

import { TimeInput } from './CommonComponents'
import { getDurationInHours, getDurationInSeconds } from './helpers'

interface PreviousPeriodAdjustmentInvoiceEventModalProps {
  invoice?: PrismaAll.Invoice
  processId: string
  companyId: string
  showModal: boolean
  closeModal: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const PreviousPeriodAdjustmentInvoiceEventModal: FC<
  PreviousPeriodAdjustmentInvoiceEventModalProps
> = ({ invoice, processId, companyId, showModal, closeModal }) => {
  const [activeTab, setActiveTab] = useState('Results')
  const handleTabChange = (value: string | number) => {
    setActiveTab(value as string)
  }
  const { data: commodities, isLoading: isFetchingCommodities } = useQuery(
    ['commodity.findManyPricingCommoditiesForProcessWithManual', { processId }],
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Modal
      title='Create Previous Period Adjustment'
      icon='BillingIcon'
      visible={showModal}
      onClose={closeModal}
      minWidth='150px'
      className='my-1 max-h-[100vh] overflow-y-auto'>
      <div className='border-weak flex w-full border-0 border-b border-solid'>
        <div className='w-full translate-y-[1px]'>
          <Tabs orientation='horizontal' size='small' value={activeTab} onChange={handleTabChange}>
            <Tab name='Results Based' value='Results' />
            <Tab name='Time Based' value='Time' />
          </Tabs>
        </div>
      </div>

      <div className='mt-4'>
        {isFetchingCommodities ? (
          <div>Loading...</div>
        ) : activeTab === 'Results' ? (
          <ResultsBasedTabComponent
            invoice={invoice}
            processId={processId}
            companyId={companyId}
            commodities={commodities}
            closeModal={closeModal}
          />
        ) : activeTab === 'Time' ? (
          <TimeBasedTabComponent
            invoice={invoice}
            processId={processId}
            companyId={companyId}
            commodities={commodities}
            closeModal={closeModal}
          />
        ) : null}
      </div>
    </Modal>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CommodityConfiguration: FC<{
  commodities?: PrismaAll.Commodity[]
  setManualCommodityName?: (commodityName: string) => void
}> = ({ commodities, setManualCommodityName }) => (
  <div className='space-y-1'>
    <label className='font-bold'>Name/Title of Commodity</label>
    <Dropdown
      name='Select a commodity'
      width='350px'
      options={
        commodities
          ? commodities.map((commodity) => ({ key: commodity.id, value: commodity.name }))
          : []
      }
      custom
      onChange={({ value }) => setManualCommodityName?.(value as string)}
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const ResultsBasedTabComponent: FC<{
  invoice?: PrismaAll.Invoice
  processId: string
  companyId: string
  commodities?: PrismaAll.Commodity[]
  users?: PrismaAll.User[]
  closeModal: () => void
}> = ({ invoice, processId, companyId, commodities, closeModal }) => {
  const reactQueryClient = useContext()
  const { addToast } = useToasts()
  const [rate, setRate] = useState<string>('0.0')
  const [quantity, setQuantity] = useState<number>()
  const [manualCommodityName, setManualCommodityName] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [deliveredAt, setDeliveredAt] = useState<Date | null>(null)
  const [showError, setShowError] = useState(false)

  const { mutateAsync: createManualInvoiceEvent, isLoading: isCreatingManualInvoiceEvent } =
    useMutation('invoiceEvent.createManual', {
      onSuccess: () => {
        addToast('Previous Period Adjustment Invoice Event Created', { appearance: 'success' })
        closeModal()
      },
      onError: (error) => {
        addToast(error?.message, { appearance: 'error' })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('invoiceEvent.getProcessInvoiceOverview')
      },
    })

  return (
    <div className='space-y-4'>
      <CommodityConfiguration
        commodities={commodities}
        setManualCommodityName={setManualCommodityName}
      />

      <div className='space-y-1'>
        <div className='flex w-full items-center gap-1'>
          <Input
            placeholder='Rate'
            addOnLeft='$'
            width='160px'
            type='number'
            step='any'
            value={rate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRate(e.target.value)}
          />
          <div>x</div>
          <Input
            placeholder='Quantity'
            addOnRight='Unit(s)'
            width='160px'
            type='number'
            min={0}
            step='any'
            value={String(quantity)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='block font-bold'>Total</label>
        <span className='text-sm text-gray-400'>
          {Number(rate) < 0 ? '-' : ''}${(Math.abs(Number(rate) ?? 0) * (quantity ?? 0)).toFixed(3)}
        </span>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>This work was originally delivered on</label>
        <div>
          <DateTimePicker
            fullWidth
            hideTime
            value={deliveredAt}
            onChange={(date) => setDeliveredAt(date)}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Notes</label>
        <TextArea
          placeholder='Please add context'
          rows={5}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {showError ? (
          <span className='text-xs text-red-400'>
            *Description must be min 7 & max 140 characters long.
          </span>
        ) : null}
      </div>

      <div className='flex items-center justify-end gap-2'>
        <Button size='md' variant='secondary' onClick={closeModal}>
          Cancel
        </Button>

        <Button
          size='md'
          variant='primary'
          loading={isCreatingManualInvoiceEvent}
          onClick={async () => {
            if (description.trim().length < 7 || description.trim().length > 140) {
              setShowError(true)
              return
            }
            await createManualInvoiceEvent({
              processId,
              companyId,
              invoiceId: invoice?.id,
              manualCommodityName,
              customRate: Number(rate),
              customMultiplier: quantity,
              description,
              isTimeBased: false,
              deliveredAt: deliveredAt ? deliveredAt : undefined,
              balanceType: 'previous_period_adjustment' as TInvoiceEventBalanceType,
            })
          }}>
          Create
        </Button>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TimeBasedTabComponent: FC<{
  invoice?: PrismaAll.Invoice
  processId: string
  companyId: string
  commodities?: PrismaAll.Commodity[]
  closeModal: () => void
}> = ({ invoice, processId, companyId, commodities, closeModal }) => {
  const reactQueryClient = useContext()
  const { addToast } = useToasts()
  const [rate, setRate] = useState<string>('0.0')
  const [manualCommodityName, setManualCommodityName] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [deliveredAt, setDeliveredAt] = useState<Date | null>(null)
  const [customMultiplier, setCustomMultiplier] = useState<number>()
  const [showError, setShowError] = useState(false)

  const { mutateAsync: createManualInvoiceEvent, isLoading: isCreatingManualInvoiceEvent } =
    useMutation('invoiceEvent.createManual', {
      onSuccess: () => {
        addToast('Previous Period Adjustment Invoice Event Created', { appearance: 'success' })
        closeModal()
      },
      onError: (error) => {
        addToast(error?.message, { appearance: 'error' })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('invoiceEvent.getProcessInvoiceOverview')
      },
    })

  return (
    <div className='space-y-4'>
      <CommodityConfiguration
        commodities={commodities}
        setManualCommodityName={setManualCommodityName}
      />

      <div className='space-y-1'>
        <label className='font-bold'>Rate</label>
        <Input
          width='130px'
          placeholder='Rate'
          addOnLeft='$'
          type='number'
          step='any'
          value={rate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setRate(e.target.value)}
        />
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Duration</label>
        <TimeInput
          hour={convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).hours}
          minute={convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).minutes}
          onChange={(hour, minute) => setCustomMultiplier(getDurationInSeconds(hour, minute))}
        />
      </div>

      <div className='space-y-1'>
        <label className='block font-bold'>Total</label>

        <span className='text-sm text-gray-400'>
          {Number(rate) < 0 ? '-' : ''}$
          {(
            Math.abs(Number(rate) ?? 0) *
            getDurationInHours(
              String(convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).hours),
              String(convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).minutes)
            )
          ).toFixed(3)}
        </span>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>This work was originally delivered on</label>
        <div>
          <DateTimePicker
            fullWidth
            hideTime
            value={deliveredAt}
            onChange={(date) => setDeliveredAt(date)}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Notes</label>
        <TextArea
          placeholder='Please add context'
          rows={5}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {showError ? (
          <span className='text-xs text-red-400'>
            *Description must be min 7 & max 140 characters long.
          </span>
        ) : null}
      </div>

      <div className='flex items-center justify-end gap-2'>
        <Button size='md' variant='secondary' onClick={closeModal}>
          Cancel
        </Button>

        <Button
          size='md'
          variant='primary'
          loading={isCreatingManualInvoiceEvent}
          onClick={async () => {
            if (description.trim().length < 7 || description.trim().length > 140) {
              setShowError(true)
              return
            }
            await createManualInvoiceEvent({
              processId,
              companyId,
              invoiceId: invoice?.id,
              manualCommodityName,
              customRate: Number(rate) * 1000, // Pass rate as multiple of a thousand as it is converted to unit for time based
              customMultiplier,
              description,
              isTimeBased: true,
              deliveredAt: deliveredAt ? deliveredAt : undefined,
              balanceType: 'previous_period_adjustment' as TInvoiceEventBalanceType,
            })
          }}>
          Create
        </Button>
      </div>
    </div>
  )
}

export { PreviousPeriodAdjustmentInvoiceEventModal }
