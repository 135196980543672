import { SVGProps } from 'react'

function Section(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_1215_113993)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.25 1.75H1.75V5.6875H12.25V1.75ZM1.75 0.875C1.26675 0.875 0.875 1.26675 0.875 1.75V5.6875C0.875 6.17075 1.26675 6.5625 1.75 6.5625H12.25C12.7332 6.5625 13.125 6.17075 13.125 5.6875V1.75C13.125 1.26675 12.7332 0.875 12.25 0.875H1.75Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.25 8.3125H1.75V12.25H12.25V8.3125ZM1.75 7.4375C1.26675 7.4375 0.875 7.82925 0.875 8.3125V12.25C0.875 12.7332 1.26675 13.125 1.75 13.125H12.25C12.7332 13.125 13.125 12.7332 13.125 12.25V8.3125C13.125 7.82925 12.7332 7.4375 12.25 7.4375H1.75Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1215_113993'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Section
