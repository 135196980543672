import { styled } from '@invisible/ui/themes'
import { useEffect, useState } from 'react'

interface SwitchComponentProps {
  isOn: boolean | null
  size: 'tiny' | 'small' | 'medium'
  activeColor?: string
}

interface ISwitchProps {
  isOn?: boolean | null
  activeColor?: string
  onToggle?: (value: boolean) => void
  onText?: string | symbol
  offText?: string | symbol
  loading?: boolean
  size?: 'tiny' | 'small' | 'medium'
  disabled?: boolean
}

const SwitchButton = styled.span<SwitchComponentProps>`
  display: block;
  width: ${(props) => (props.size === 'medium' ? '18px' : props.size === 'small' ? '12px' : '9px')};
  height: ${(props) =>
    props.size === 'medium' ? '18px' : props.size === 'small' ? '12px' : '9px'};
  position: absolute;
  left: 2px;
  border-radius: 16px;
  background-color: #ffffff;
  transform: ${(props) =>
    props.isOn === null
      ? 'translateX(15px)'
      : props.isOn === true && props.size === 'tiny'
      ? 'translateX(14px)'
      : props.isOn === true && (props.size === 'small' || props.size === 'medium')
      ? 'translateX(30px)'
      : 'inherit'};
  transition: 0.5s;
`

const SwitchContainer = styled.button<SwitchComponentProps>`
  position: relative;
  display: flex;
  width: ${(props) =>
    props.size === 'medium' ? '52px' : props.size === 'small' ? '46px' : '28px'};
  height: ${(props) => (props.size === 'medium' ? '22px' : '16px')};
  background-color: ${(props) => (props.isOn ? props.activeColor ?? '#604CA5' : '#A19FA5')};
  border-radius: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  align-items: center;
`
const OnText = styled.span<SwitchComponentProps>`
  display: ${(props) => (props.isOn ? 'block' : 'none')};
  position: absolute;
  left: 6px;
  border: none;
  background: transparent;
  color: #ffffff;
  font-size: 12px;
`
const OffText = styled.span<SwitchComponentProps>`
  display: ${(props) => (props.isOn === false ? 'block' : 'none')};
  position: absolute;
  right: 6px;
  border: none;
  background: transparent;
  color: #ffffff;
  font-size: 12px;
`

const Switch = (props: ISwitchProps) => {
  const [isOn, setIsOn] = useState<boolean | null>(null)
  const isControlled = props.isOn !== undefined

  const handleOnClick = () => {
    if (props.disabled) return
    setIsOn((on) => !on)
    if (isControlled) {
      props.onToggle?.(!isOn)
    }
  }

  useEffect(() => {
    if (!isControlled) props.onToggle?.(Boolean(isOn))
  }, [isOn, isControlled, props])

  useEffect(() => {
    if (props.isOn !== undefined) setIsOn(props.isOn)
  }, [props.isOn])

  return (
    <SwitchContainer
      onClick={handleOnClick}
      isOn={isOn}
      size={props.size ?? 'small'}
      activeColor={props.activeColor}
      data-cy='switch'>
      <OnText isOn={isOn} size={props.size ?? 'small'}>
        {props.onText}
      </OnText>
      <OffText isOn={isOn} size={props.size ?? 'small'}>
        {props.offText}
      </OffText>
      <SwitchButton isOn={isOn} size={props.size ?? 'small'} />
    </SwitchContainer>
  )
}

export { Switch }
