import { useEffect } from 'react'
import { focusManager } from 'react-query'

/**
 * Custom hook to disable query refetching on window focus when the containing component is mounted
 */
export const useDisableRefetchOnFocus = ({ isDisabled }: { isDisabled?: boolean }) => {
  useEffect(() => {
    if (isDisabled) return focusManager.setFocused(undefined)

    focusManager.setFocused(false)
    return () => focusManager.setFocused(undefined)
  }, [isDisabled])
}
