import { fromGlobalId, useMyRolesQuery } from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { useGate } from 'statsig-react'

const useMyRoles = ({ email }: { email: string }) => {
  const { value: isGraphQLEnabled } = useGate('enable-concorde-user-find-by-email-with-role')

  const { data: trpcResult, isLoading: isTrpcLoading } = useTrpcQuery(
    ['user.findByEmailWithRole', { email }],
    {
      enabled: !isGraphQLEnabled,
    }
  )
  const { data: graphqlResult, isLoading: isGraphqlLoading } = useMyRolesQuery(
    {},
    {
      enabled: isGraphQLEnabled,
    }
  )
  const data = isGraphQLEnabled
    ? {
        id: fromGlobalId(graphqlResult?.me.id),
        image: graphqlResult?.me.image,
        name: graphqlResult?.me.name,
        email: graphqlResult?.me.email,
        userToRoles: graphqlResult?.me.roles.map((role) => ({
          role: {
            id: fromGlobalId(role.id),
            genericRole: role.genericRole,
            name: role.name,
          },
        })),
      }
    : trpcResult
  return {
    data,
    isLoading: isGraphQLEnabled ? isGraphqlLoading : isTrpcLoading,
  }
}

export { useMyRoles }
