export const skeletonData = {
  type: 'Topology',
  arcs: [
    [
      [16814, 15074],
      [71, -45],
      [53, 16],
      [15, 54],
      [55, 18],
      [39, 37],
      [14, 95],
      [59, 24],
      [11, 42],
      [32, -32],
      [21, -3],
    ],
    [
      [17184, 15280],
      [39, -1],
      [53, -26],
    ],
    [
      [17276, 15253],
      [21, -14],
      [51, 38],
      [23, -23],
      [23, 55],
      [41, -2],
      [11, 17],
      [7, 49],
      [30, 41],
      [38, -27],
      [-8, -37],
      [22, -5],
      [-7, -101],
      [28, -39],
      [24, 25],
      [31, 12],
      [43, 53],
      [48, -8],
      [72, -1],
    ],
    [
      [17774, 15286],
      [13, -34],
    ],
    [
      [17787, 15252],
      [-41, -13],
      [-35, -23],
      [-80, -14],
      [-75, -25],
      [-41, -52],
      [17, -51],
      [8, -60],
      [-35, -50],
      [3, -46],
      [-19, -43],
      [-67, 4],
      [28, -80],
      [-45, -30],
      [-29, -73],
      [4, -72],
      [-28, -33],
      [-26, 11],
      [-53, -16],
      [-7, -33],
      [-52, 0],
      [-39, -68],
      [-3, -102],
      [-90, -50],
      [-49, 10],
      [-14, -26],
      [-42, 15],
      [-69, -17],
      [-117, 61],
    ],
    [
      [16791, 14376],
      [63, 109],
      [-6, 77],
      [-52, 20],
      [-6, 76],
      [-23, 96],
      [30, 66],
      [-30, 17],
      [19, 88],
      [28, 149],
    ],
    [
      [14166, 8695],
      [-128, -49],
      [-169, 17],
      [-48, 58],
      [-283, -6],
      [-11, -8],
      [-41, 54],
      [-45, 4],
      [-42, -21],
      [-34, -22],
    ],
    [
      [13365, 8722],
      [-6, 75],
      [10, 105],
      [24, 110],
      [3, 52],
      [23, 108],
      [16, 49],
      [41, 79],
      [22, 53],
      [7, 89],
      [-3, 68],
      [-21, 43],
      [-19, 72],
      [-17, 72],
      [4, 25],
      [21, 48],
      [-21, 116],
      [-14, 80],
      [-35, 76],
      [6, 23],
    ],
    [
      [13406, 10065],
      [29, 16],
      [20, -2],
      [25, 15],
      [206, -2],
      [17, -89],
      [20, -72],
      [16, -39],
      [27, -63],
      [46, 10],
      [23, 17],
      [38, -17],
      [11, 30],
      [17, 70],
      [43, 4],
      [4, 21],
      [36, 1],
      [-6, -44],
      [84, 2],
      [1, -76],
      [15, -46],
      [-11, -73],
      [5, -73],
      [24, -45],
      [-4, -143],
      [17, 11],
      [30, -3],
      [44, 18],
      [31, -7],
    ],
    [
      [14214, 9486],
      [8, -37],
      [-8, -58],
      [12, -56],
      [-10, -45],
      [6, -42],
      [-146, 2],
      [-3, -382],
      [47, -98],
      [46, -75],
    ],
    [
      [13397, 10103],
      [-19, 90],
    ],
    [
      [13378, 10193],
      [28, 52],
      [21, 20],
      [26, -41],
    ],
    [
      [13453, 10224],
      [-25, -26],
      [-11, -30],
      [-3, -53],
      [-17, -12],
    ],
    [
      [14013, 15697],
      [-2, -31],
      [-22, -18],
      [-4, -39],
      [-33, -58],
    ],
    [
      [13952, 15551],
      [-12, 8],
      [-1, 27],
      [-39, 40],
      [-6, 57],
      [6, 82],
      [10, 37],
      [-12, 19],
    ],
    [
      [13898, 15821],
      [-5, 38],
      [30, 59],
      [5, -22],
      [19, 11],
    ],
    [
      [13947, 15907],
      [14, -33],
      [17, -12],
      [5, -43],
    ],
    [
      [13983, 15819],
      [-9, -41],
      [10, -52],
      [29, -29],
    ],
    [
      [16143, 13706],
      [12, 6],
      [3, -33],
      [55, 19],
      [57, -3],
      [42, -4],
      [48, 81],
      [52, 77],
      [44, 74],
    ],
    [
      [16456, 13923],
      [13, -41],
    ],
    [
      [16469, 13882],
      [10, -95],
    ],
    [
      [16479, 13787],
      [-36, 0],
      [-5, -78],
      [12, -17],
      [-32, -24],
      [0, -49],
      [-20, -49],
      [-2, -49],
    ],
    [
      [16396, 13521],
      [-14, -25],
      [-210, 61],
      [-26, 121],
      [-3, 28],
    ],
    [
      [7880, 4211],
      [-42, 4],
      [-75, 0],
      [0, 267],
    ],
    [
      [7763, 4482],
      [27, -55],
      [35, -90],
      [90, -72],
      [98, -30],
      [-31, -60],
      [-67, -6],
      [-35, 42],
    ],
    [
      [7767, 4523],
      [-64, 19],
      [-169, 16],
      [-28, 70],
      [1, 90],
      [-47, -8],
      [-24, 43],
      [-6, 128],
      [53, 52],
      [22, 76],
      [-8, 61],
      [37, 102],
      [26, 159],
      [-8, 71],
      [31, 22],
      [-8, 46],
      [-32, 24],
      [23, 50],
      [-32, 46],
      [-16, 138],
      [28, 24],
      [-12, 147],
      [17, 122],
      [18, 107],
      [42, 44],
      [-21, 117],
      [0, 110],
      [52, 79],
      [-1, 100],
      [40, 117],
      [0, 110],
      [-18, 22],
      [-32, 207],
      [43, 124],
      [-7, 116],
      [25, 109],
      [46, 113],
      [49, 74],
      [-21, 47],
      [14, 39],
      [-2, 200],
      [76, 59],
      [24, 125],
      [-8, 30],
    ],
    [
      [7870, 8070],
      [58, 108],
      [91, -29],
      [41, -87],
      [27, 97],
      [80, -5],
      [11, -26],
    ],
    [
      [8178, 8128],
      [128, -196],
      [57, -18],
      [85, -89],
      [72, -47],
      [10, -52],
      [-69, -183],
      [71, -32],
      [78, -19],
      [55, 20],
      [63, 91],
      [12, 106],
    ],
    [
      [8740, 7709],
      [34, 23],
      [35, -69],
      [-1, -96],
      [-59, -66],
      [-47, -49],
      [-78, -116],
      [-93, -164],
    ],
    [
      [8531, 7172],
      [-18, -96],
      [-19, -123],
      [1, -120],
      [-15, -26],
      [-5, -78],
    ],
    [
      [8475, 6729],
      [-5, -63],
      [88, -102],
      [-9, -83],
      [43, -52],
      [-3, -59],
      [-67, -154],
      [-103, -64],
      [-140, -25],
      [-77, 12],
      [15, -71],
      [-14, -90],
      [12, -61],
      [-41, -42],
      [-72, -17],
      [-67, 44],
      [-27, -31],
      [10, -119],
      [47, -37],
      [38, 38],
      [21, -62],
      [-64, -37],
      [-56, -75],
      [-10, -121],
      [-17, -64],
      [-66, 0],
      [-54, -62],
      [-20, -90],
      [68, -87],
      [67, -25],
      [-24, -107],
      [-83, -68],
      [-45, -141],
      [-63, -47],
      [-29, -56],
      [22, -125],
      [47, -69],
      [-30, 6],
    ],
    [
      [15586, 15727],
      [96, 19],
    ],
    [
      [15682, 15746],
      [15, -32],
      [26, -21],
      [-14, -30],
      [38, -41],
      [-20, -38],
      [29, -33],
      [32, -19],
      [1, -84],
    ],
    [
      [15789, 15448],
      [-25, -3],
    ],
    [
      [15764, 15445],
      [-28, 69],
      [0, 19],
      [-31, 0],
      [-20, 32],
      [-15, -3],
    ],
    [
      [15670, 15562],
      [-27, 35],
      [-52, 29],
      [6, 59],
      [-11, 42],
    ],
    [
      [8395, 1195],
      [-21, -61],
      [-20, -54],
      [-146, 16],
      [-156, -7],
      [-87, 40],
      [0, 5],
      [-38, 35],
      [157, -5],
      [150, -11],
      [52, 49],
      [36, 42],
      [73, -49],
    ],
    [
      [1449, 1260],
      [-133, -16],
      [-92, 42],
      [-41, 42],
      [-3, 7],
      [-45, 33],
      [43, 45],
      [129, -19],
      [70, -38],
      [53, -42],
      [19, -54],
    ],
    [
      [9400, 1434],
      [86, -52],
      [30, -73],
      [8, -51],
      [3, -61],
      [-108, -38],
      [-113, -31],
      [-131, -28],
      [-147, -23],
      [-165, 7],
      [-91, 40],
      [12, 49],
      [149, 33],
      [60, 40],
      [44, 52],
      [31, 44],
      [42, 43],
      [45, 49],
      [36, 0],
      [104, 26],
      [105, -26],
    ],
    [
      [4098, 1979],
      [90, -18],
      [83, 21],
      [-39, -43],
      [-66, -30],
      [-97, 9],
      [-69, 43],
      [15, 40],
      [83, -22],
    ],
    [
      [3795, 1982],
      [106, -47],
      [-41, 4],
      [-90, 12],
      [-95, 33],
      [50, 26],
      [70, -28],
    ],
    [
      [5648, 2167],
      [76, -16],
      [77, 14],
      [41, -68],
      [-55, 9],
      [-85, -4],
      [-86, 4],
      [-94, -7],
      [-71, 24],
      [-37, 49],
      [44, 21],
      [89, -16],
      [101, -10],
    ],
    [
      [7776, 2285],
      [8, -54],
      [-12, -47],
      [-19, -45],
      [-82, -16],
      [-78, -24],
      [-92, 2],
      [35, 47],
      [-82, -16],
      [-78, -17],
      [-53, 36],
      [-5, 49],
      [77, 47],
      [48, 14],
      [80, -5],
      [21, 62],
      [4, 44],
      [-2, 97],
      [40, 56],
      [64, 19],
      [37, -45],
      [17, -44],
      [30, -55],
      [23, -51],
      [19, -54],
    ],
    [
      [8462, 3101],
      [-30, -26],
      [-52, 19],
      [-58, -12],
      [-47, -28],
      [-51, -31],
      [-34, -35],
      [-10, -47],
      [4, -45],
      [33, -40],
      [-48, -28],
      [-65, -9],
      [-38, -40],
      [-41, -38],
      [-44, -51],
      [-11, -45],
      [25, -50],
      [37, -37],
      [57, -28],
      [53, -38],
      [29, -47],
      [15, -45],
      [20, -47],
      [33, -40],
      [21, -44],
      [9, -111],
      [21, -44],
      [5, -47],
      [22, -47],
      [-10, -64],
      [-38, -49],
      [-41, -40],
      [-93, -17],
      [-31, -42],
      [-42, -40],
      [-106, -45],
      [-92, -18],
      [-88, -26],
      [-94, -26],
      [-56, -50],
      [-112, -4],
      [-123, 4],
      [-110, -9],
      [-118, 0],
      [22, -47],
      [107, -21],
      [77, -33],
      [44, -42],
      [-78, -38],
      [-120, 12],
      [-100, -31],
      [-4, -49],
      [-2, -47],
      [82, -40],
      [15, -45],
      [88, -44],
      [148, -19],
      [125, -33],
      [100, -38],
      [127, -37],
      [173, -19],
      [171, -33],
      [119, -35],
      [130, -40],
      [68, -57],
      [34, -44],
      [85, 42],
      [114, 35],
      [122, 38],
      [144, 30],
      [125, 33],
      [173, 3],
      [171, -17],
      [140, -28],
      [45, 52],
      [97, 35],
      [177, 2],
      [137, 26],
      [131, 26],
      [145, 16],
      [154, 22],
      [108, 30],
      [-49, 42],
      [-30, 43],
      [0, 44],
      [-135, -4],
      [-143, -19],
      [-137, 0],
      [-19, 45],
      [10, 89],
      [31, 26],
      [100, 28],
      [117, 28],
      [85, 35],
      [84, 36],
      [63, 47],
      [96, 21],
      [94, 16],
      [48, 10],
      [108, 4],
      [102, 17],
      [86, 23],
      [85, 29],
      [76, 28],
      [97, 37],
      [61, 40],
      [66, 36],
      [20, 47],
      [-73, 28],
      [24, 49],
      [47, 38],
      [72, 23],
      [77, 29],
      [71, 37],
      [54, 47],
      [34, 57],
      [51, 33],
      [83, -7],
      [34, -40],
      [83, -5],
      [3, 45],
      [36, 47],
      [75, -12],
      [18, -45],
      [83, -7],
      [90, 21],
      [87, 14],
      [80, -7],
      [30, -49],
      [76, 40],
      [71, 21],
      [79, 16],
      [78, 17],
      [71, 28],
      [78, 19],
      [60, 26],
      [42, 42],
      [52, -30],
      [72, 16],
      [51, -56],
      [40, -43],
      [79, 24],
      [31, 47],
      [71, 33],
      [92, -7],
      [27, -45],
      [57, 45],
      [75, 14],
      [82, 4],
      [74, -2],
      [78, -14],
      [75, -7],
      [33, -40],
      [45, -35],
      [76, 21],
      [82, 5],
      [79, 0],
      [78, 2],
      [70, 16],
      [74, 15],
      [61, 32],
      [65, 22],
      [71, 11],
      [54, 33],
      [38, 66],
      [40, 40],
      [72, -19],
      [27, -42],
      [60, -28],
      [73, 9],
      [49, -42],
      [52, -31],
      [71, 28],
      [24, 52],
      [63, 21],
      [72, 40],
      [69, 17],
      [82, 23],
      [54, 26],
      [58, 28],
      [54, 26],
      [66, -14],
      [63, 42],
      [45, 33],
      [65, -2],
      [57, 28],
      [14, 42],
      [59, 33],
      [57, 24],
      [70, 19],
      [64, 9],
      [61, -7],
      [66, -12],
      [56, -33],
      [7, -51],
      [61, -40],
      [42, -33],
      [84, -14],
      [46, -33],
      [58, -33],
      [66, -7],
      [56, 23],
      [60, 50],
      [66, -26],
      [68, -14],
      [66, -14],
      [68, -10],
      [70, 0],
      [57, -124],
      [-3, -31],
      [-8, -54],
      [-67, -31],
      [-54, -44],
      [9, -47],
      [78, 2],
      [-10, -47],
      [-35, -45],
      [-33, -49],
      [53, -38],
      [81, -11],
      [81, 21],
      [38, 47],
      [23, 45],
      [38, 37],
      [44, 35],
      [18, 43],
      [36, 58],
      [44, 12],
      [79, 5],
      [70, 14],
      [71, 19],
      [34, 47],
      [21, 45],
      [47, 44],
      [69, 31],
      [58, 23],
      [39, 40],
      [39, 21],
      [51, 19],
      [69, -12],
      [63, 12],
      [68, 14],
      [77, -7],
      [50, 33],
      [36, 80],
      [26, -33],
      [33, -56],
      [58, -24],
      [67, -9],
      [67, 14],
      [71, -9],
      [66, -3],
      [43, 12],
      [59, -7],
      [53, -26],
      [63, 16],
      [75, 0],
      [64, 17],
      [73, -17],
      [46, 40],
      [36, 40],
      [47, 33],
      [88, 90],
      [45, -17],
      [53, -33],
      [46, -42],
      [89, -73],
      [69, -2],
      [64, 0],
      [75, 14],
      [75, 16],
      [57, 33],
      [48, 35],
      [78, 5],
      [52, 26],
      [54, -23],
      [36, -38],
      [49, -38],
      [76, 5],
      [48, -31],
      [83, -30],
      [88, -12],
      [72, 10],
      [55, 37],
      [46, 38],
      [63, 9],
      [63, -16],
      [72, -12],
      [66, 19],
      [63, 0],
      [61, -12],
      [64, -12],
      [63, 21],
      [75, 19],
      [71, 5],
      [79, 0],
      [64, 12],
      [63, 9],
      [19, 59],
      [3, 49],
      [44, -33],
      [12, -54],
      [23, -49],
      [29, -40],
      [59, -21],
      [79, 7],
      [91, 2],
      [63, 7],
      [92, 0],
      [65, 3],
      [92, -5],
      [77, -10],
      [50, -37],
      [-14, -45],
      [45, -35],
      [75, -28],
      [78, -31],
      [90, -21],
      [94, -19],
      [71, -19],
      [79, -2],
      [45, 40],
      [62, -33],
      [53, -38],
      [62, -28],
      [84, -12],
      [81, -14],
      [34, -47],
      [79, -28],
      [53, -42],
      [78, -19],
      [81, 2],
      [75, -7],
      [83, 3],
      [83, -10],
      [78, -16],
      [73, -28],
      [72, -24],
      [49, -35],
      [-8, -47],
      [-37, -42],
      [-31, -55],
      [-25, -42],
      [-33, -49],
      [-91, -19],
      [-41, -42],
      [-90, -26],
      [-32, -47],
      [-47, -45],
      [-51, -38],
      [-29, -49],
      [-17, -45],
      [-7, -54],
      [1, -44],
      [40, -47],
      [15, -45],
      [32, -42],
      [130, -17],
      [27, -51],
      [-125, -19],
      [-107, -26],
      [-132, -5],
      [-59, -68],
      [-12, -56],
      [-30, -45],
      [-37, -45],
      [93, -40],
      [35, -49],
      [60, -45],
      [85, -40],
      [97, -37],
      [105, -38],
      [160, -38],
      [35, -58],
      [201, -26],
      [14, -9],
      [52, -36],
      [192, 31],
      [160, -38],
      [120, -29],
      [0, -634],
      [-25095, 0],
      [0, 634],
      [4, -1],
      [62, 70],
      [125, -38],
      [8, 5],
      [74, 38],
      [10, -1],
      [8, -1],
      [101, -50],
      [88, 50],
      [16, 6],
      [204, 22],
      [67, -28],
      [33, -15],
      [105, -40],
      [198, -30],
      [157, -38],
      [269, -28],
      [200, 33],
      [297, -24],
      [168, -37],
      [184, 35],
      [194, 33],
      [15, 56],
      [-275, 5],
      [-225, 28],
      [-59, 47],
      [-187, 26],
      [13, 54],
      [25, 50],
      [26, 44],
      [-13, 50],
      [-116, 33],
      [-54, 42],
      [-107, 37],
      [169, -7],
      [161, 19],
      [101, -40],
      [124, 36],
      [115, 44],
      [56, 40],
      [-25, 50],
      [-90, 32],
      [-102, 36],
      [-143, 7],
      [-126, 16],
      [-135, 12],
      [-45, 45],
      [-90, 37],
      [-55, 43],
      [-22, 136],
      [34, -12],
      [63, -37],
      [115, 11],
      [110, 17],
      [58, -52],
      [110, 12],
      [93, 26],
      [87, 33],
      [80, 39],
      [105, 12],
      [-3, 45],
      [-24, 45],
      [20, 42],
      [90, 21],
      [41, -40],
      [107, 24],
      [80, 30],
      [100, 3],
      [94, 11],
      [94, 28],
      [75, 26],
      [85, 26],
      [55, -7],
      [47, -9],
      [104, 16],
      [93, -21],
      [95, 2],
      [92, 17],
      [94, -12],
      [104, -12],
      [97, 5],
      [101, -2],
      [104, -3],
      [95, 5],
      [71, 35],
      [85, 19],
      [87, -26],
      [84, 21],
      [75, 43],
      [45, -38],
      [24, -42],
      [45, -40],
      [73, 35],
      [83, -45],
      [94, -14],
      [81, -33],
      [98, 7],
      [89, 22],
      [105, -5],
      [94, -17],
      [96, -21],
      [37, 52],
      [-46, 40],
      [-34, 42],
      [-90, 10],
      [-39, 44],
      [-15, 45],
      [-25, 89],
      [53, -16],
      [92, -7],
      [90, 7],
      [82, -19],
      [71, -35],
      [30, -42],
      [94, -8],
      [90, 17],
      [96, 23],
      [86, 15],
      [71, -29],
      [93, 10],
      [60, 91],
      [56, -54],
      [80, -21],
      [88, 12],
      [57, -47],
      [91, -5],
      [85, -14],
      [83, -26],
      [55, 45],
      [27, 42],
      [70, -47],
      [95, 12],
      [71, -26],
      [48, -40],
      [93, 12],
      [72, 26],
      [71, 30],
      [85, 17],
      [98, 14],
      [89, 16],
      [68, 26],
      [41, 38],
      [17, 52],
      [-8, 49],
      [-22, 47],
      [-25, 47],
      [-22, 47],
      [-18, 42],
      [-4, 47],
      [7, 47],
      [33, 45],
      [27, 49],
      [11, 47],
      [-13, 52],
      [-9, 47],
      [35, 54],
      [38, 35],
      [45, 45],
      [48, 38],
      [56, 35],
      [27, 52],
      [38, 33],
      [44, 30],
      [67, 7],
      [43, 38],
      [50, 23],
      [57, 14],
      [50, 31],
      [40, 38],
      [55, 14],
      [41, -31],
      [-26, -40],
      [-71, -35],
    ],
    [
      [17353, 4964],
      [45, -38],
      [66, -15],
      [2, -23],
      [-19, -54],
      [-107, -8],
      [-2, 64],
      [10, 49],
      [5, 25],
    ],
    [
      [22683, 5903],
      [67, -41],
      [38, 16],
      [55, 23],
      [41, -8],
      [5, -142],
      [-23, -41],
      [-8, -97],
      [-24, 33],
      [-48, -84],
      [-15, 7],
      [-43, 4],
      [-43, 102],
      [-9, 79],
      [-40, 105],
      [1, 55],
      [46, -11],
    ],
    [
      [22555, 9146],
      [25, -94],
      [45, 45],
      [23, -51],
      [33, -47],
      [-7, -53],
      [15, -103],
      [11, -59],
      [17, -15],
      [19, -103],
      [-7, -62],
      [23, -81],
      [75, -63],
      [50, -57],
      [46, -52],
      [-9, -29],
      [40, -75],
      [27, -130],
      [28, 26],
      [28, -52],
      [17, 19],
      [12, -128],
      [50, -73],
      [32, -46],
      [55, -97],
      [19, -97],
      [2, -68],
      [-5, -74],
      [34, -102],
      [-4, -106],
      [-12, -56],
      [-19, -107],
      [1, -69],
      [-14, -86],
      [-30, -109],
      [-52, -59],
      [-26, -93],
      [-23, -59],
      [-20, -104],
      [-27, -59],
      [-18, -90],
      [-9, -83],
      [4, -38],
      [-40, -41],
      [-78, -5],
      [-65, -49],
      [-32, -46],
      [-42, -52],
      [-58, 53],
      [-42, 21],
      [10, 63],
      [-38, -23],
      [-61, -87],
      [-60, 33],
      [-39, 19],
      [-40, 8],
      [-68, 35],
      [-45, 74],
      [-13, 91],
      [-16, 61],
      [-34, 48],
      [-67, 15],
      [23, 58],
      [-17, 89],
      [-34, -83],
      [-62, -22],
      [36, 66],
      [11, 70],
      [27, 58],
      [-6, 89],
      [-57, -102],
      [-43, -41],
      [-27, -96],
      [-54, 50],
      [2, 63],
      [-44, 87],
      [-37, 45],
      [14, 28],
      [-90, 73],
      [-49, 3],
      [-67, 59],
      [-125, -12],
      [-90, -43],
      [-79, -40],
      [-67, 8],
      [-74, -61],
      [-60, -28],
      [-14, -63],
      [-25, -49],
      [-60, -2],
      [-43, -11],
      [-62, 22],
      [-50, -13],
      [-48, -6],
      [-41, -64],
      [-21, 6],
      [-35, -34],
      [-33, -38],
      [-51, 4],
      [-47, 0],
      [-74, 77],
      [-37, 23],
      [1, 68],
      [35, 17],
      [12, 27],
      [-3, 43],
      [9, 84],
      [-8, 71],
      [-37, 121],
      [-11, 68],
      [3, 69],
      [-28, 78],
      [-2, 35],
      [-31, 48],
      [-8, 94],
      [-40, 95],
      [-10, 51],
      [31, -52],
      [-24, 111],
      [35, -34],
      [20, -47],
      [-1, 62],
      [-34, 94],
      [-7, 38],
      [-16, 36],
      [8, 69],
      [14, 30],
      [9, 60],
      [-7, 70],
      [29, 86],
      [5, -91],
      [29, 82],
      [57, 40],
      [34, 52],
      [53, 44],
      [32, 9],
      [19, -15],
      [55, 45],
      [42, 13],
      [11, 27],
      [18, 10],
      [39, -2],
      [73, 35],
      [38, 53],
      [18, 64],
      [41, 61],
      [3, 48],
      [2, 65],
      [49, 102],
      [29, -103],
      [30, 23],
      [-25, 57],
      [22, 58],
      [30, -26],
      [9, 92],
      [38, 59],
      [17, 47],
      [35, 20],
      [1, 34],
      [30, -14],
      [2, 30],
      [30, 17],
      [34, 16],
      [52, -55],
      [38, -71],
      [44, 0],
      [44, -12],
      [-15, 66],
      [34, 96],
      [31, 32],
      [-11, 30],
      [31, 68],
      [42, 43],
      [36, -15],
      [58, 23],
      [-1, 61],
      [-51, 40],
      [37, 17],
      [46, -30],
      [37, -49],
      [59, -31],
      [20, 13],
      [43, -37],
      [41, 34],
      [26, -10],
      [16, 23],
      [32, -60],
      [-18, -64],
      [-27, -48],
      [-24, -4],
      [8, -48],
      [-20, -60],
      [-25, -59],
      [5, -34],
      [55, -66],
      [54, -39],
      [36, -41],
      [50, -71],
      [20, 0],
      [37, -31],
      [10, -37],
      [67, -41],
      [46, 41],
      [13, 65],
      [14, 53],
      [9, 66],
      [21, 95],
      [-9, 58],
      [5, 35],
      [-8, 69],
      [9, 90],
      [13, 25],
      [-11, 40],
      [17, 63],
      [13, 66],
      [2, 34],
      [26, 45],
      [20, -58],
      [5, -76],
      [17, -14],
      [3, -51],
      [25, -61],
      [5, -67],
      [-2, -44],
    ],
    [
      [13731, 16571],
      [-5, -50],
      [-39, 0],
      [13, -26],
      [-23, -77],
    ],
    [
      [13677, 16418],
      [-13, -20],
      [-61, -3],
      [-35, -27],
      [-58, 9],
    ],
    [
      [13510, 16377],
      [-100, 31],
      [-15, 42],
      [-69, -21],
      [-8, -23],
      [-43, 17],
    ],
    [
      [13275, 16423],
      [-35, 3],
      [-32, 22],
      [11, 29],
      [-3, 22],
    ],
    [
      [13216, 16499],
      [21, 6],
      [36, -33],
      [10, 32],
      [61, -5],
      [50, 21],
      [33, -4],
      [22, -24],
      [7, 20],
      [-10, 78],
      [25, 16],
      [24, 55],
    ],
    [
      [13495, 16661],
      [52, -39],
      [39, 49],
      [25, 9],
      [54, -36],
      [33, 6],
      [32, -23],
    ],
    [
      [13730, 16627],
      [-6, -15],
      [7, -41],
    ],
    [
      [15682, 15746],
      [18, 19],
      [51, -34],
      [38, -7],
      [10, 14],
      [-35, 65],
      [18, 16],
    ],
    [
      [15782, 15819],
      [20, -4],
      [48, -73],
      [31, -8],
      [12, 31],
      [41, 48],
    ],
    [
      [15934, 15813],
      [37, -63],
      [35, -85],
      [33, -6],
      [21, -32],
      [-57, -10],
      [-12, -93],
      [-12, -42],
      [-26, -28],
      [2, -60],
    ],
    [
      [15955, 15394],
      [-17, -6],
      [-44, 63],
      [24, 60],
      [-20, 35],
      [-26, -9],
      [-83, -89],
    ],
    [
      [15764, 15445],
      [-48, 16],
      [-35, 55],
      [-11, 46],
    ],
    [
      [14593, 10257],
      [-5, 145],
      [-17, 55],
    ],
    [
      [14571, 10457],
      [42, -10],
      [21, 68],
      [37, -7],
    ],
    [
      [14671, 10508],
      [5, -48],
      [15, -27],
      [0, -39],
      [-17, -25],
      [-27, -62],
      [-25, -44],
      [-29, -6],
    ],
    [
      [12977, 16892],
      [-8, -81],
    ],
    [
      [12969, 16811],
      [-18, -5],
      [-8, -67],
    ],
    [
      [12943, 16739],
      [-61, 55],
      [-36, -9],
      [-48, 56],
      [-33, 48],
      [-32, 2],
      [-10, 42],
    ],
    [
      [12723, 16933],
      [56, 24],
    ],
    [
      [12779, 16957],
      [51, -9],
      [64, 25],
      [44, -53],
      [39, -28],
    ],
    [
      [12735, 11548],
      [-57, -14],
    ],
    [
      [12678, 11534],
      [-18, 83],
      [4, 275],
      [-15, 25],
      [-2, 59],
      [-24, 42],
      [-22, 35],
      [9, 64],
    ],
    [
      [12610, 12117],
      [24, 13],
      [14, 53],
      [34, 11],
      [16, 36],
    ],
    [
      [12698, 12230],
      [23, 35],
      [25, 0],
      [53, -69],
    ],
    [
      [12799, 12196],
      [-2, -40],
      [15, -71],
      [-14, -48],
      [8, -33],
      [-34, -74],
      [-21, -37],
      [-14, -75],
      [2, -77],
      [-4, -193],
    ],
    [
      [12610, 12117],
      [-61, 2],
    ],
    [
      [12549, 12119],
      [-32, 10],
      [-23, -20],
      [-30, 9],
      [-121, -6],
      [-2, -68],
      [9, -90],
    ],
    [
      [12350, 11954],
      [-47, 31],
      [-33, -5],
      [-24, -30],
      [-32, 26],
      [-12, 39],
      [-31, 26],
    ],
    [
      [12171, 12041],
      [-5, 70],
      [19, 51],
      [-1, 40],
      [55, 100],
      [10, 82],
      [19, 29],
      [34, -16],
      [29, 25],
      [10, 31],
      [54, 53],
      [13, 38],
      [65, 50],
      [39, 17],
      [17, -23],
      [45, 0],
    ],
    [
      [12574, 12588],
      [-6, -58],
      [9, -55],
      [40, -78],
      [2, -58],
      [80, -27],
      [-1, -82],
    ],
    [
      [19008, 13441],
      [-2, -86],
      [-24, 19],
      [4, -97],
    ],
    [
      [18986, 13277],
      [-20, 63],
      [-4, 61],
      [-13, 57],
      [-29, 70],
      [-64, 5],
      [6, -49],
      [-22, -67],
      [-29, 24],
      [-11, -22],
      [-19, 13],
      [-27, 11],
    ],
    [
      [18754, 13443],
      [-11, 99],
      [-24, 90],
      [12, 72],
      [-43, 33],
      [15, 43],
      [44, 45],
      [-51, 64],
      [25, 81],
      [55, -52],
      [34, -6],
      [6, -83],
      [66, -17],
      [65, 2],
      [40, -20],
      [-32, -102],
      [-31, -7],
      [-22, -68],
      [38, -62],
      [12, 76],
      [19, 1],
      [37, -191],
    ],
    [
      [14127, 16104],
      [20, -49],
      [27, 8],
      [54, -18],
      [102, -7],
      [34, 31],
      [83, 28],
      [50, -44],
      [41, -12],
    ],
    [
      [14538, 16041],
      [-36, -50],
      [-25, -86],
      [22, -68],
    ],
    [
      [14499, 15837],
      [-60, 16],
      [-71, -38],
    ],
    [
      [14368, 15815],
      [-1, -60],
      [-63, -11],
      [-49, 42],
      [-56, -33],
      [-52, 3],
    ],
    [
      [14147, 15756],
      [-4, 80],
      [-35, 38],
    ],
    [
      [14108, 15874],
      [11, 17],
      [-7, 15],
      [11, 38],
      [27, 37],
      [-34, 52],
      [-6, 44],
      [17, 27],
    ],
    [
      [7143, 13648],
      [-17, -6],
      [-18, 69],
      [-26, 35],
      [15, 76],
      [21, -5],
      [24, -100],
      [1, -69],
    ],
    [
      [7123, 13986],
      [-76, -19],
      [-5, 44],
      [33, 10],
      [46, -4],
      [2, -31],
    ],
    [
      [7180, 13987],
      [-12, -85],
      [-13, 15],
      [1, 63],
      [-31, 47],
      [0, 14],
      [55, -54],
    ],
    [
      [13887, 16019],
      [-13, -11],
      [-23, -28],
      [-10, -66],
    ],
    [
      [13841, 15914],
      [-61, 45],
      [-27, 50],
      [-26, 27],
      [-32, 45],
      [-15, 37],
      [-35, 56],
      [15, 50],
      [25, -28],
      [15, 25],
      [33, 3],
      [60, -20],
      [48, 2],
      [31, -27],
    ],
    [
      [13872, 16179],
      [26, 0],
      [-18, -52],
      [34, -47],
      [-10, -56],
      [-17, -5],
    ],
    [
      [14185, 17265],
      [67, -1],
      [76, 45],
      [16, 68],
      [57, 39],
      [-7, 53],
    ],
    [
      [14394, 17469],
      [43, 20],
      [75, 47],
    ],
    [
      [14512, 17536],
      [73, -30],
      [10, -30],
      [37, 14],
      [68, -28],
      [6, -57],
      [-14, -32],
      [43, -79],
      [29, -22],
      [-5, -21],
      [47, -21],
      [21, -32],
      [-28, -27],
      [-56, 5],
      [-13, -12],
      [16, -39],
      [17, -77],
    ],
    [
      [14763, 17048],
      [-60, -7],
      [-21, -27],
      [-5, -60],
      [-27, 12],
      [-63, -6],
      [-18, 28],
      [-27, -21],
      [-26, 17],
      [-55, 3],
      [-78, 28],
      [-70, 10],
      [-54, -3],
      [-38, -32],
      [-33, -5],
    ],
    [
      [14188, 16985],
      [-2, 53],
      [-21, 56],
      [42, 24],
      [0, 48],
      [-19, 46],
      [-3, 53],
    ],
    [
      [6333, 12934],
      [0, 17],
      [8, 6],
      [13, -14],
      [25, 72],
      [13, 2],
    ],
    [
      [6392, 13017],
      [1, -18],
      [13, -1],
      [-1, -32],
      [-12, -52],
      [6, -19],
      [-7, -43],
      [4, -11],
      [-8, -61],
      [-13, -31],
      [-13, -4],
      [-14, -42],
    ],
    [
      [6348, 12703],
      [-21, 0],
      [6, 136],
      [0, 95],
    ],
    [
      [7870, 8070],
      [-51, -17],
      [-27, 166],
      [-37, 134],
      [22, 116],
      [-37, 51],
      [-9, 87],
      [-35, 81],
    ],
    [
      [7696, 8688],
      [44, 130],
      [-30, 100],
      [16, 41],
      [-12, 44],
      [27, 60],
      [2, 102],
      [3, 85],
      [15, 40],
      [-60, 193],
    ],
    [
      [7701, 9483],
      [52, -10],
      [35, 3],
      [16, 36],
      [61, 49],
      [37, 45],
      [91, 20],
      [-8, -90],
      [9, -46],
      [-6, -80],
      [76, -108],
      [78, -20],
      [28, -44],
      [47, -24],
      [29, -35],
      [43, 1],
      [41, -35],
      [3, -70],
      [14, -35],
      [0, -52],
      [-20, -2],
      [27, -139],
      [134, -5],
      [-11, -70],
      [8, -47],
      [38, -34],
      [16, -74],
      [-12, -95],
      [-19, -52],
      [7, -69],
      [-22, -24],
    ],
    [
      [8493, 8377],
      [-1, 37],
      [-65, 61],
      [-65, 2],
      [-122, -35],
      [-33, -106],
      [-2, -64],
      [-27, -144],
    ],
    [
      [8740, 7709],
      [13, 70],
      [10, 70],
      [0, 66],
      [-25, 22],
      [-26, -19],
      [-26, 5],
      [-9, 46],
      [-6, 110],
      [-13, 36],
      [-47, 33],
      [-29, -24],
      [-73, 23],
      [4, 163],
      [-20, 67],
    ],
    [
      [7701, 9483],
      [-40, -20],
      [-31, 13],
      [4, 183],
      [-57, -71],
      [-61, 3],
      [-27, 64],
      [-46, 7],
      [15, 52],
      [-39, 73],
      [-29, 108],
      [18, 22],
      [0, 50],
      [42, 35],
      [-7, 65],
      [18, 41],
      [5, 56],
      [80, 82],
      [57, 23],
      [10, 18],
      [62, -5],
    ],
    [
      [7675, 10282],
      [32, 328],
      [1, 53],
      [-11, 68],
      [-31, 44],
      [1, 87],
      [39, 20],
      [14, -13],
      [2, 46],
      [-40, 13],
      [-1, 75],
      [135, -3],
      [24, 42],
      [19, -38],
      [14, -71],
      [13, 15],
    ],
    [
      [7886, 10948],
      [38, -64],
      [54, 8],
      [14, 37],
      [52, 28],
      [28, 19],
      [8, 51],
      [50, 34],
      [-4, 25],
      [-59, 11],
      [-9, 75],
      [2, 81],
      [-31, 31],
      [13, 11],
      [52, -15],
      [55, -30],
      [21, 28],
      [50, 19],
      [78, 44],
      [25, 46],
      [-9, 34],
    ],
    [
      [8314, 11421],
      [36, 5],
      [16, -27],
      [-9, -53],
      [24, -18],
      [16, -56],
      [-19, -42],
      [-11, -102],
      [18, -61],
      [5, -55],
      [43, -57],
      [34, -6],
      [7, 24],
      [23, 5],
      [31, 21],
      [23, 32],
      [38, -10],
      [17, 4],
    ],
    [
      [8606, 11025],
      [38, -10],
      [6, 25],
      [-11, 24],
      [7, 34],
      [28, -10],
      [33, 12],
      [40, -25],
    ],
    [
      [8747, 11075],
      [30, -25],
      [22, 32],
      [15, -5],
      [10, -33],
      [33, 8],
      [27, 46],
      [21, 88],
      [42, 110],
    ],
    [
      [8947, 11296],
      [23, 5],
      [18, -66],
      [39, -210],
      [37, -19],
      [2, -83],
      [-53, -99],
      [22, -36],
      [123, -19],
      [3, -120],
      [53, 78],
      [87, -43],
      [116, -73],
      [34, -70],
      [-11, -67],
      [81, 37],
      [136, -63],
      [104, 5],
      [103, -100],
      [89, -134],
      [53, -35],
      [60, -5],
      [25, -37],
      [24, -153],
      [12, -73],
      [-28, -198],
      [-36, -78],
      [-98, -167],
      [-44, -136],
      [-52, -104],
      [-17, -2],
      [-20, -89],
      [5, -224],
      [-19, -185],
      [-8, -79],
      [-22, -48],
      [-12, -160],
      [-71, -157],
      [-12, -124],
      [-56, -52],
      [-16, -71],
      [-76, 0],
      [-110, -46],
      [-49, -54],
      [-78, -35],
      [-82, -95],
      [-59, -119],
      [-10, -90],
      [11, -66],
      [-13, -121],
      [-15, -59],
      [-49, -66],
      [-77, -211],
      [-62, -95],
      [-47, -56],
      [-32, -114],
      [-46, -69],
    ],
    [
      [8827, 6746],
      [-19, 68],
      [30, 57],
      [-40, 82],
      [-55, 66],
      [-71, 77],
      [-26, -4],
      [-70, 93],
      [-45, -13],
    ],
    [
      [20508, 11340],
      [28, 45],
      [59, 66],
    ],
    [
      [20595, 11451],
      [-3, -59],
      [-4, -77],
      [-33, 4],
      [-15, -41],
      [-32, 62],
    ],
    [
      [18940, 14129],
      [28, -38],
      [-5, -74],
      [-57, -4],
      [-59, 8],
      [-44, -18],
      [-63, 45],
      [-1, 24],
    ],
    [
      [18739, 14072],
      [46, 89],
      [37, 31],
      [50, -28],
      [37, -3],
      [31, -32],
    ],
    [
      [14599, 8147],
      [-98, -88],
      [-63, -90],
      [-23, -80],
      [-21, -45],
      [-38, -10],
      [-12, -57],
      [-7, -37],
      [-45, -28],
      [-57, 6],
      [-33, 33],
      [-29, 15],
      [-34, -28],
      [-18, -58],
      [-33, -36],
      [-34, -53],
      [-50, -12],
      [-16, 42],
      [7, 73],
      [-42, 114],
      [-19, 18],
    ],
    [
      [13934, 7826],
      [0, 350],
      [69, 4],
      [2, 427],
      [52, 4],
      [108, 42],
      [26, -49],
      [45, 47],
      [21, 0],
      [39, 27],
    ],
    [
      [14296, 8678],
      [13, -9],
    ],
    [
      [14309, 8669],
      [26, -96],
      [14, -21],
      [22, -69],
      [79, -132],
      [30, -13],
      [0, -42],
      [21, -76],
      [54, -19],
      [44, -54],
    ],
    [
      [13613, 11688],
      [57, 9],
      [13, 30],
      [12, -2],
      [17, -27],
      [88, 46],
      [29, 47],
      [37, 42],
      [-7, 42],
      [20, 11],
      [67, -8],
      [65, 56],
      [51, 131],
      [35, 48],
      [44, 21],
    ],
    [
      [14141, 12134],
      [8, -51],
      [40, -75],
      [1, -49],
      [-12, -50],
      [5, -38],
      [24, -34],
      [53, -53],
    ],
    [
      [14260, 11784],
      [38, -48],
      [1, -39],
      [47, -63],
      [29, -51],
      [17, -72],
      [53, -48],
      [11, -38],
    ],
    [
      [14456, 11425],
      [-23, -13],
      [-45, 3],
      [-52, 13],
      [-26, -11],
      [-11, -29],
      [-22, -3],
      [-28, 25],
      [-77, -60],
      [-32, 12],
      [-10, -9],
      [-21, -72],
      [-52, 23],
      [-51, 12],
      [-44, 44],
      [-57, 41],
      [-38, -39],
      [-27, -61],
      [-6, -83],
    ],
    [
      [13834, 11218],
      [-45, 6],
      [-47, 20],
      [-42, -63],
      [-36, -112],
    ],
    [
      [13664, 11069],
      [-8, 35],
      [-3, 55],
      [-32, 38],
      [-25, 62],
      [-6, 43],
      [-33, 63],
      [5, 36],
      [-7, 50],
      [6, 93],
      [17, 22],
      [35, 122],
    ],
    [
      [8110, 16382],
      [50, -16],
      [65, 3],
      [-35, -49],
      [-25, -8],
      [-89, 51],
      [-17, 40],
      [26, 37],
      [25, -58],
    ],
    [
      [8239, 16688],
      [-34, -2],
      [-90, 38],
      [-65, 56],
      [24, 10],
      [92, -30],
      [71, -50],
      [2, -22],
    ],
    [
      [3938, 16617],
      [-35, -17],
      [-115, 55],
      [-21, 42],
      [-62, 42],
      [-13, 34],
      [-71, 22],
      [-27, 65],
      [6, 28],
      [73, -26],
      [43, -18],
      [65, -13],
      [24, -41],
      [34, -57],
      [70, -50],
      [29, -66],
    ],
    [
      [8634, 16878],
      [-46, -105],
      [46, 41],
      [47, -26],
      [-25, -42],
      [62, -33],
      [32, 29],
      [70, -36],
      [-22, -88],
      [49, 20],
      [9, -63],
      [21, -75],
      [-29, -106],
      [-31, -4],
      [-46, 23],
      [15, 98],
      [-20, 15],
      [-80, -104],
      [-42, 4],
      [49, 56],
      [-67, 30],
      [-75, -8],
      [-135, 4],
      [-11, 36],
      [44, 42],
      [-30, 32],
      [58, 73],
      [72, 191],
      [43, 68],
      [61, 41],
      [32, -5],
      [-13, -32],
      [-38, -76],
    ],
    [
      [3264, 17296],
      [33, -16],
      [66, 10],
      [-20, -136],
      [60, -97],
      [-28, 0],
      [-42, 55],
      [-25, 56],
      [-36, 37],
      [-12, 53],
      [4, 38],
    ],
    [
      [7022, 18254],
      [-27, -63],
      [-31, 10],
      [-18, 36],
      [3, 9],
      [27, 36],
      [28, -3],
      [18, -25],
    ],
    [
      [6839, 18321],
      [-82, -67],
      [-49, 3],
      [-16, 33],
      [52, 55],
      [96, -1],
      [-1, -23],
    ],
    [
      [6611, 18674],
      [13, -53],
      [36, 19],
      [40, -32],
      [77, -41],
      [79, -37],
      [7, -57],
      [51, 9],
      [50, -40],
      [-62, -37],
      [-109, 28],
      [-39, 54],
      [-69, -63],
      [-99, -62],
      [-24, 70],
      [-95, -12],
      [61, 59],
      [9, 95],
      [24, 110],
      [50, -10],
    ],
    [
      [7259, 18853],
      [-78, -6],
      [-18, 59],
      [30, 67],
      [64, 17],
      [54, -34],
      [1, -51],
      [-8, -17],
      [-45, -35],
    ],
    [
      [5880, 19088],
      [-43, -42],
      [-94, 36],
      [-57, -13],
      [-95, 54],
      [61, 37],
      [49, 52],
      [74, -34],
      [42, -21],
      [21, -23],
      [42, -46],
    ],
    [
      [3985, 16676],
      [-10, 0],
      [-135, 118],
      [-50, 52],
      [-126, 49],
      [-39, 106],
      [10, 74],
      [-89, 51],
      [-12, 97],
      [-84, 87],
      [-2, 62],
    ],
    [
      [3448, 17372],
      [39, 58],
      [-2, 75],
      [-119, 77],
      [-71, 137],
      [-43, 86],
      [-64, 54],
      [-47, 49],
      [-37, 62],
      [-70, -39],
      [-68, -67],
      [-62, 79],
      [-49, 52],
      [-68, 34],
      [-68, 3],
      [0, 683],
      [1, 445],
    ],
    [
      [2720, 19160],
      [130, -28],
      [109, -58],
      [73, -11],
      [61, 50],
      [85, 37],
      [103, -14],
      [105, 52],
      [114, 30],
      [48, -49],
      [52, 28],
      [15, 56],
      [48, -13],
      [118, -107],
      [93, 81],
      [9, -91],
      [86, 20],
      [26, 35],
      [85, -7],
      [106, -51],
      [164, -44],
      [96, -20],
      [68, 8],
      [94, -61],
      [-98, -60],
      [126, -25],
      [188, 14],
      [59, 21],
      [75, -72],
      [75, 61],
      [-71, 50],
      [45, 42],
      [85, 5],
      [56, 12],
      [56, -29],
      [70, -65],
      [78, 10],
      [123, -54],
      [109, 19],
      [101, -3],
      [-8, 75],
      [62, 20],
      [108, -40],
      [0, -114],
      [44, 96],
      [56, -3],
      [32, 120],
      [-75, 74],
      [-81, 49],
      [5, 132],
      [83, 87],
      [92, -19],
      [70, -53],
      [95, -135],
      [-62, -59],
      [130, -24],
      [-1, -123],
      [93, 94],
      [84, -77],
      [-21, -89],
      [67, -81],
      [73, 87],
      [51, 103],
      [4, 132],
      [99, -9],
      [103, -18],
      [94, -60],
      [4, -59],
      [-52, -64],
      [49, -64],
      [-9, -59],
      [-136, -83],
      [-97, -19],
      [-72, 36],
      [-21, -60],
      [-67, -101],
      [-21, -53],
      [-80, -81],
      [-100, -8],
      [-55, -51],
      [-5, -78],
      [-81, -15],
      [-85, -97],
      [-76, -135],
      [-27, -94],
      [-4, -140],
      [103, -20],
      [31, -112],
      [33, -91],
      [97, 24],
      [130, -52],
      [69, -46],
      [50, -57],
      [88, -33],
      [73, -50],
      [116, -7],
      [75, -12],
      [-11, -104],
      [22, -120],
      [50, -134],
      [104, -114],
      [54, 39],
      [37, 123],
      [-36, 189],
      [-49, 64],
      [111, 56],
      [79, 84],
      [39, 84],
      [-6, 80],
      [-47, 102],
      [-85, 90],
      [82, 126],
      [-30, 108],
      [-23, 188],
      [48, 27],
      [120, -32],
      [72, -12],
      [57, 32],
      [65, -41],
      [86, -70],
      [21, -46],
      [124, -9],
      [-2, -101],
      [24, -152],
      [63, -19],
      [51, -70],
      [101, 66],
      [66, 133],
      [46, 56],
      [55, -108],
      [91, -153],
      [77, -143],
      [-28, -76],
      [92, -67],
      [63, -69],
      [111, -31],
      [45, -38],
      [28, -102],
      [54, -16],
      [28, -45],
      [5, -135],
      [-51, -45],
      [-50, -42],
      [-115, -43],
      [-87, -98],
      [-118, -20],
      [-149, 26],
      [-105, 0],
      [-72, -8],
      [-58, -86],
      [-89, -53],
      [-101, -159],
      [-80, -111],
      [59, 20],
      [112, 158],
      [146, 100],
      [105, 12],
      [61, -59],
      [-66, -81],
      [23, -129],
      [22, -91],
      [91, -60],
      [115, 18],
      [70, 135],
      [5, -87],
      [45, -44],
      [-86, -78],
      [-155, -72],
      [-69, -48],
      [-78, -87],
      [-53, 9],
      [-3, 102],
      [122, 99],
      [-112, -4],
      [-78, -15],
    ],
    [
      [7867, 16212],
      [-45, 68],
      [0, 164],
      [-31, 34],
      [-47, -20],
      [-23, 31],
      [-53, -90],
      [-21, -93],
      [-25, -55],
      [-30, -19],
      [-22, -6],
      [-7, -29],
      [-128, 0],
      [-106, -1],
      [-32, -22],
      [-73, -87],
      [-9, -9],
      [-22, -47],
      [-64, 0],
      [-69, 0],
      [-31, -19],
      [11, -24],
      [6, -36],
      [-1, -13],
      [-91, -59],
      [-72, -19],
      [-81, -64],
      [-18, 0],
      [-23, 19],
      [-8, 17],
      [1, 12],
      [16, 42],
      [32, 66],
      [21, 71],
      [-14, 105],
      [-15, 108],
      [-73, 57],
      [9, 21],
      [-10, 15],
      [-19, 0],
      [-14, 19],
      [-4, 28],
      [-13, -12],
      [-19, 3],
      [4, 12],
      [-16, 12],
      [-7, 32],
      [-54, 38],
      [-57, 40],
      [-68, 46],
      [-65, 44],
      [-63, -34],
      [-22, -1],
      [-86, 31],
      [-57, -16],
      [-67, 38],
      [-71, 19],
      [-49, 7],
      [-22, 20],
      [-12, 66],
      [-24, 0],
      [0, -46],
      [-144, 0],
      [-239, 0],
      [-237, 0],
      [-209, 0],
      [-209, 0],
      [-206, 0],
      [-212, 0],
      [-69, 0],
      [-207, 0],
      [-197, 0],
    ],
    [
      [4589, 19569],
      [-35, -56],
      [155, 37],
      [97, -61],
      [79, 61],
      [64, -39],
      [57, -118],
      [35, 50],
      [-50, 123],
      [62, 17],
      [69, -19],
      [78, -48],
      [44, -117],
      [21, -85],
      [118, -59],
      [125, -57],
      [-7, -53],
      [-115, -9],
      [45, -47],
      [-24, -44],
      [-126, 19],
      [-120, 33],
      [-81, -8],
      [-131, -40],
      [-176, -18],
      [-124, -12],
      [-38, 57],
      [-95, 33],
      [-62, -14],
      [-86, 95],
      [46, 13],
      [108, 20],
      [98, -5],
      [91, 21],
      [-135, 28],
      [-149, -10],
      [-98, 3],
      [-37, 44],
      [161, 48],
      [-107, -2],
      [-122, 32],
      [59, 90],
      [48, 48],
      [187, 73],
      [71, -24],
    ],
    [
      [5263, 19605],
      [-61, -79],
      [-109, 84],
      [24, 17],
      [93, 5],
      [53, -27],
    ],
    [
      [7226, 19567],
      [6, -33],
      [-74, 4],
      [-75, 2],
      [-76, -16],
      [-21, 7],
      [-76, 64],
      [3, 43],
      [33, 8],
      [160, -13],
      [120, -66],
    ],
    [
      [6513, 19574],
      [55, -75],
      [65, 97],
      [176, 49],
      [120, -124],
      [-10, -79],
      [138, 35],
      [65, 48],
      [155, -61],
      [96, -57],
      [9, -52],
      [130, 27],
      [72, -77],
      [169, -47],
      [60, -48],
      [66, -113],
      [-128, -56],
      [164, -78],
      [111, -26],
      [100, -110],
      [110, -8],
      [-22, -85],
      [-122, -139],
      [-86, 51],
      [-110, 116],
      [-90, -15],
      [-9, -69],
      [74, -70],
      [94, -55],
      [29, -32],
      [46, -119],
      [-25, -86],
      [-87, 33],
      [-175, 96],
      [98, -104],
      [73, -72],
      [11, -42],
      [-189, 48],
      [-149, 70],
      [-85, 58],
      [24, 34],
      [-104, 61],
      [-101, 59],
      [1, -35],
      [-202, -19],
      [-59, 41],
      [46, 88],
      [131, 2],
      [144, 16],
      [-23, 43],
      [24, 59],
      [90, 117],
      [-19, 53],
      [-27, 41],
      [-107, 59],
      [-141, 40],
      [45, 31],
      [-74, 74],
      [-62, 7],
      [-54, 41],
      [-38, -35],
      [-126, -16],
      [-254, 27],
      [-147, 35],
      [-113, 18],
      [-58, 42],
      [73, 55],
      [-99, 1],
      [-23, 121],
      [54, 107],
      [72, 49],
      [180, 32],
      [-52, -77],
    ],
    [
      [5552, 19656],
      [83, -25],
      [124, 15],
      [18, -35],
      [-65, -57],
      [106, -52],
      [-13, -108],
      [-114, -46],
      [-67, 10],
      [-48, 46],
      [-174, 92],
      [2, 39],
      [142, -15],
      [-77, 78],
      [83, 58],
    ],
    [
      [6051, 19528],
      [-75, -90],
      [-79, 4],
      [-44, 106],
      [1, 59],
      [37, 51],
      [69, 33],
      [145, -4],
      [133, -29],
      [-104, -107],
      [-83, -23],
    ],
    [
      [4150, 19361],
      [-183, -58],
      [-37, 53],
      [-161, 63],
      [30, 51],
      [48, 88],
      [61, 78],
      [-68, 74],
      [235, 19],
      [100, -25],
      [178, -7],
      [68, -35],
      [74, -50],
      [-87, -30],
      [-171, -85],
      [-87, -84],
      [0, -52],
    ],
    [
      [6022, 19792],
      [-38, -46],
      [-101, 9],
      [-85, 31],
      [37, 54],
      [101, 32],
      [60, -42],
      [26, -38],
    ],
    [
      [5681, 20001],
      [54, -55],
      [2, -62],
      [-32, -89],
      [-115, -12],
      [-75, 19],
      [2, 70],
      [-115, -10],
      [-4, 93],
      [75, -4],
      [105, 41],
      [98, -7],
      [5, 16],
    ],
    [
      [5004, 19939],
      [28, -43],
      [62, 20],
      [73, -5],
      [12, -59],
      [-42, -57],
      [-237, -18],
      [-175, -52],
      [-106, -3],
      [-9, 39],
      [145, 53],
      [-315, -14],
      [-98, 22],
      [95, 117],
      [66, 33],
      [196, -40],
      [124, -71],
      [122, -9],
      [-100, 114],
      [64, 44],
      [72, -14],
      [23, -57],
    ],
    [
      [5947, 20047],
      [78, -39],
      [137, 0],
      [60, -39],
      [-16, -45],
      [80, -27],
      [44, -29],
      [94, -5],
      [102, -10],
      [111, 26],
      [142, 10],
      [113, -8],
      [75, -46],
      [15, -49],
      [-43, -32],
      [-104, -26],
      [-89, 15],
      [-200, -19],
      [-143, -2],
      [-113, 15],
      [-185, 38],
      [-24, 66],
      [-9, 60],
      [-70, 52],
      [-144, 15],
      [-81, 37],
      [27, 49],
      [143, -7],
    ],
    [
      [4447, 20112],
      [-9, -92],
      [-54, -42],
      [-65, -5],
      [-129, -52],
      [-112, -18],
      [-95, 26],
      [119, 90],
      [143, 77],
      [107, -1],
      [95, 17],
    ],
    [
      [6006, 20097],
      [-32, -3],
      [-130, 7],
      [-19, 34],
      [140, -2],
      [49, -22],
      [-8, -14],
    ],
    [
      [4867, 20118],
      [-130, -34],
      [-104, 39],
      [57, 38],
      [101, 12],
      [99, -19],
      [-23, -36],
    ],
    [
      [4903, 20227],
      [-85, -23],
      [-116, 0],
      [2, 17],
      [71, 36],
      [37, -6],
      [91, -24],
    ],
    [
      [5867, 20162],
      [-103, -25],
      [-57, 28],
      [-29, 45],
      [-6, 49],
      [90, -4],
      [41, -8],
      [83, -42],
      [-19, -43],
    ],
    [
      [5572, 20194],
      [28, -50],
      [-114, 13],
      [-115, 39],
      [-155, 4],
      [67, 36],
      [-84, 29],
      [-5, 46],
      [137, -16],
      [188, -44],
      [53, -57],
    ],
    [
      [6481, 20354],
      [85, -39],
      [-96, -36],
      [-129, -90],
      [-123, -8],
      [-145, 15],
      [-75, 49],
      [1, 43],
      [56, 32],
      [-128, -1],
      [-77, 40],
      [-44, 55],
      [48, 53],
      [49, 37],
      [71, 8],
      [-30, 27],
      [162, 7],
      [89, -65],
      [117, -25],
      [114, -23],
      [55, -79],
    ],
    [
      [7772, 20767],
      [187, -9],
      [149, -15],
      [128, -33],
      [-3, -32],
      [-170, -52],
      [-169, -24],
      [-63, -27],
      [152, 0],
      [-165, -72],
      [-113, -34],
      [-119, -98],
      [-144, -20],
      [-45, -25],
      [-211, -13],
      [96, -15],
      [-48, -21],
      [58, -59],
      [-66, -41],
      [-108, -34],
      [-33, -47],
      [-97, -36],
      [9, -27],
      [119, 4],
      [2, -29],
      [-186, -72],
      [-182, 33],
      [-205, -18],
      [-104, 14],
      [-132, 6],
      [-8, 58],
      [128, 27],
      [-34, 87],
      [43, 8],
      [186, -52],
      [-95, 77],
      [-113, 23],
      [56, 47],
      [124, 28],
      [20, 42],
      [-99, 47],
      [-29, 62],
      [190, -5],
      [55, -13],
      [109, 43],
      [-157, 14],
      [-244, -7],
      [-123, 40],
      [-58, 49],
      [-82, 35],
      [-15, 41],
      [104, 23],
      [81, 4],
      [137, 19],
      [102, 45],
      [87, -6],
      [75, -34],
      [53, 65],
      [92, 19],
      [125, 13],
      [213, 5],
      [37, -13],
      [202, 21],
      [151, -8],
      [150, -8],
    ],
    [
      [13275, 16423],
      [-5, -49],
      [-31, -20],
      [-51, 15],
      [-15, -49],
      [-34, -4],
      [-12, 19],
      [-39, -40],
      [-33, -6],
      [-30, 26],
    ],
    [
      [13025, 16315],
      [-24, 52],
      [-34, -18],
      [1, 54],
      [51, 67],
      [-2, 31],
      [32, -11],
      [19, 20],
    ],
    [
      [13068, 16510],
      [59, -1],
      [15, 26],
      [74, -36],
    ],
    [
      [7880, 4211],
      [-23, -48],
      [-60, -37],
      [-34, 3],
      [-42, 10],
      [-50, 36],
      [-73, 17],
      [-88, 67],
      [-71, 65],
      [-96, 134],
      [57, -25],
      [98, -80],
      [93, -43],
      [36, 55],
      [22, 82],
      [65, 50],
      [49, -15],
    ],
    [
      [7767, 4523],
      [-62, 1],
      [-33, -30],
      [-63, -43],
      [-11, -112],
      [-30, -3],
      [-78, 39],
      [-80, 84],
      [-87, 68],
      [-22, 76],
      [20, 71],
      [-35, 79],
      [-9, 205],
      [30, 115],
      [73, 93],
      [-106, 35],
      [67, 106],
      [24, 199],
      [77, -42],
      [36, 249],
      [-46, 31],
      [-22, -149],
      [-44, 17],
      [22, 171],
      [24, 222],
      [32, 82],
      [-20, 117],
      [-6, 136],
      [29, 3],
      [43, 194],
      [48, 192],
      [30, 179],
      [-16, 180],
      [20, 99],
      [-8, 148],
      [41, 146],
      [12, 232],
      [23, 249],
      [22, 269],
      [-6, 196],
      [-14, 169],
    ],
    [
      [7642, 8596],
      [36, 31],
      [18, 61],
    ],
    [
      [17774, 15286],
      [-10, 69],
      [2, 46],
      [-42, 28],
      [-23, -12],
      [-18, 111],
    ],
    [
      [17683, 15528],
      [20, 27],
      [-9, 28],
      [66, 57],
      [48, 23],
      [74, -16],
      [26, 77],
      [90, 14],
      [25, 48],
      [109, 65],
      [10, 27],
    ],
    [
      [18142, 15878],
      [-5, 68],
      [48, 31],
      [-63, 209],
      [138, 48],
      [36, 27],
      [50, 214],
      [138, -39],
      [39, 54],
      [3, 120],
      [58, 12],
      [53, 79],
    ],
    [
      [18637, 16701],
      [27, 10],
    ],
    [
      [18664, 16711],
      [19, -83],
      [58, -64],
      [100, -45],
      [48, -97],
      [-27, -140],
      [25, -52],
      [83, -20],
      [94, -17],
      [84, -75],
      [43, -13],
      [32, -111],
      [41, -71],
      [77, 3],
      [144, -27],
      [92, 17],
      [69, -18],
      [103, -73],
      [85, 0],
      [30, -37],
      [82, 64],
      [112, 42],
      [105, 4],
      [81, 42],
      [50, 65],
      [49, 40],
      [-11, 40],
      [-23, 46],
      [37, 77],
      [39, -11],
      [72, -24],
      [69, 64],
      [107, 46],
      [51, 79],
      [49, 34],
      [101, 16],
      [55, -13],
      [8, 42],
      [-64, 84],
      [-55, 39],
      [-54, -45],
      [-69, 19],
      [-39, -15],
      [-18, 49],
      [49, 120],
      [34, 90],
    ],
    [
      [20681, 16782],
      [84, -45],
      [98, 76],
      [-1, 53],
      [63, 127],
      [39, 38],
      [-1, 67],
      [-38, 28],
      [57, 60],
      [87, 21],
      [92, 4],
      [105, -36],
      [61, -44],
      [43, -121],
      [26, -52],
      [24, -74],
      [26, -117],
      [122, -38],
      [82, -86],
      [28, -112],
      [106, -1],
      [61, 48],
      [115, 35],
      [-37, -108],
      [-27, -44],
      [-24, -131],
      [-47, -117],
      [-84, 21],
      [-60, -42],
      [18, -103],
      [-10, -142],
      [-35, -3],
      [0, -61],
    ],
    [
      [21654, 15883],
      [-45, 71],
      [-28, -67],
      [-107, -52],
      [11, -63],
      [-61, 4],
      [-33, 38],
      [-48, -85],
      [-76, -65],
      [-57, -77],
    ],
    [
      [21210, 15587],
      [-98, -35],
      [-51, -56],
      [-75, -32],
      [37, 55],
      [-15, 47],
      [56, 81],
      [-37, 62],
      [-61, -42],
      [-79, -83],
      [-43, -78],
      [-68, -6],
      [-35, -55],
      [36, -82],
      [57, -19],
      [3, -54],
      [55, -35],
      [78, 85],
      [62, -46],
      [45, -3],
      [11, -63],
      [-99, -34],
      [-32, -65],
      [-68, -60],
      [-36, -84],
      [75, -66],
      [28, -118],
      [42, -110],
      [48, -92],
      [-2, -89],
      [-43, -33],
      [16, -64],
      [41, -37],
      [-10, -98],
      [-18, -95],
      [-39, -10],
      [-51, -130],
      [-56, -158],
      [-65, -143],
      [-96, -111],
      [-97, -101],
      [-79, -13],
      [-42, -54],
      [-24, 39],
      [-40, -59],
      [-97, -60],
      [-74, -19],
      [-24, -127],
      [-38, -7],
      [-19, 88],
      [17, 46],
      [-94, 38],
      [-33, -19],
    ],
    [
      [20079, 13383],
      [-70, 31],
      [-33, 49],
      [11, 69],
      [-64, 22],
      [-33, 45],
      [-60, -64],
      [-67, -14],
      [-56, 1],
      [-37, -30],
    ],
    [
      [19670, 13492],
      [-37, -17],
      [11, -138],
      [-37, 4],
      [-6, 28],
    ],
    [
      [19601, 13369],
      [-2, 50],
      [-52, -35],
      [-30, 22],
      [-52, 45],
      [21, 99],
      [-44, 24],
      [-17, 110],
      [-74, -20],
      [9, 142],
      [66, 101],
      [3, 99],
      [-2, 91],
      [-31, 29],
      [-23, 71],
      [-41, -9],
    ],
    [
      [19332, 14188],
      [-75, 18],
      [23, 50],
      [-32, 75],
      [-50, -51],
      [-58, 30],
      [-81, -77],
      [-63, -89],
      [-56, -15],
    ],
    [
      [18739, 14072],
      [-6, 95],
      [-43, -25],
    ],
    [
      [18690, 14142],
      [-81, 11],
      [-79, 28],
      [-56, 52],
      [-55, 24],
      [-23, 58],
      [-39, 17],
      [-71, 78],
      [-55, 37],
      [-29, -29],
    ],
    [
      [18202, 14418],
      [-97, 84],
      [-69, 76],
      [-19, 132],
      [50, -16],
      [2, 61],
      [-28, 62],
      [7, 98],
      [-75, 140],
    ],
    [
      [17973, 15055],
      [-114, 49],
      [-21, 92],
      [-51, 56],
    ],
    [
      [20239, 13038],
      [-60, -58],
      [-57, 38],
      [-2, 103],
      [34, 54],
      [76, 34],
      [40, -3],
      [16, -46],
      [-31, -53],
      [-16, -69],
    ],
    [
      [12350, 11954],
      [19, -171],
      [-29, -100],
      [-19, -136],
      [31, -103],
      [-4, -48],
    ],
    [
      [12348, 11396],
      [-31, -1],
      [-49, 24],
      [-45, -2],
      [-82, -21],
      [-49, -34],
      [-69, -44],
      [-13, 3],
    ],
    [
      [12010, 11321],
      [5, 99],
      [7, 15],
      [-2, 47],
      [-30, 50],
      [-22, 8],
      [-20, 33],
      [15, 53],
      [-7, 58],
      [3, 35],
    ],
    [
      [11959, 11719],
      [11, 0],
      [4, 53],
      [-5, 23],
      [7, 17],
      [26, 14],
      [-18, 96],
      [-16, 50],
      [6, 40],
      [14, 10],
    ],
    [
      [11988, 12022],
      [9, 11],
      [19, -18],
      [54, -1],
      [13, 35],
      [12, -3],
      [20, 14],
      [11, -52],
      [16, 16],
      [29, 17],
    ],
    [
      [13664, 11069],
      [-5, -65],
      [-56, 29],
      [-56, 31],
      [-88, 5],
    ],
    [
      [13459, 11069],
      [-9, 7],
      [-41, -16],
      [-42, 16],
      [-33, -8],
    ],
    [
      [13334, 11068],
      [-114, 3],
    ],
    [
      [13220, 11071],
      [10, 95],
      [-27, 79],
      [-32, 21],
      [-14, 53],
      [-18, 18],
      [1, 33],
    ],
    [
      [13140, 11370],
      [18, 85],
      [33, 115],
      [20, 1],
      [42, 71],
      [26, 2],
      [39, -50],
      [48, 41],
      [7, 50],
      [15, 48],
      [11, 61],
      [38, 49],
      [14, 84],
      [14, 27],
      [10, 62],
      [19, 77],
      [58, 93],
      [4, 39],
      [8, 22],
      [-28, 48],
    ],
    [
      [13536, 12295],
      [2, 38],
      [20, 7],
    ],
    [
      [13558, 12340],
      [28, -77],
      [4, -79],
      [-2, -80],
      [38, -109],
      [-39, 1],
      [-20, -9],
      [-32, 12],
      [-15, -56],
      [41, -70],
      [31, -21],
      [10, -49],
      [22, -83],
      [-11, -32],
    ],
    [
      [13406, 10065],
      [-9, 38],
    ],
    [
      [13453, 10224],
      [19, -13],
      [24, 46],
      [38, -1],
      [4, -34],
      [26, -21],
      [41, 75],
      [41, 59],
      [17, 38],
      [-2, 99],
      [30, 116],
      [32, 62],
      [46, 58],
      [8, 38],
      [2, 44],
      [11, 42],
      [-3, 68],
      [8, 106],
      [14, 75],
      [21, 64],
      [4, 73],
    ],
    [
      [14456, 11425],
      [42, -99],
      [31, -14],
      [19, 20],
      [32, -8],
      [39, 25],
      [17, -51],
      [61, -80],
    ],
    [
      [14697, 11218],
      [-4, -140],
      [28, -16],
      [-23, -43],
      [-27, -32],
      [-26, -62],
      [-15, -56],
      [-4, -96],
      [-16, -46],
      [-1, -91],
    ],
    [
      [14609, 10636],
      [-20, -33],
      [-2, -72],
      [-10, -9],
      [-6, -65],
    ],
    [
      [14593, 10257],
      [12, -110],
      [-7, -62],
      [14, -70],
      [41, -67],
      [37, -151],
    ],
    [
      [14690, 9797],
      [-27, 12],
      [-94, -20],
      [-18, -15],
      [-20, -76],
      [15, -53],
      [-12, -142],
      [-9, -121],
      [19, -21],
      [49, -47],
      [19, 22],
      [6, -129],
      [-54, 1],
      [-28, 66],
      [-26, 51],
      [-53, 17],
      [-16, 63],
      [-43, -38],
      [-55, 16],
      [-24, 55],
      [-44, 11],
      [-33, -3],
      [-4, 37],
      [-24, 3],
    ],
    [
      [13378, 10193],
      [-57, 127],
    ],
    [
      [13321, 10320],
      [53, 66],
      [-26, 79],
      [24, 31],
      [47, 14],
      [5, 53],
      [37, -57],
      [62, -5],
      [21, 56],
      [9, 80],
      [-8, 94],
      [-33, 71],
      [31, 139],
      [-18, 24],
      [-52, -10],
      [-19, 62],
      [5, 52],
    ],
    [
      [7675, 10282],
      [-35, 63],
      [-20, 3],
      [45, 122],
      [-54, 56],
      [-42, -10],
      [-25, 21],
      [-38, -32],
      [-52, 15],
      [-41, 126],
      [-32, 31],
      [-23, 57],
      [-46, 56],
      [-19, -11],
    ],
    [
      [7293, 10779],
      [-29, 28],
      [-35, 40],
      [-20, -19],
      [-59, 17],
      [-17, 51],
      [-13, -2],
      [-69, 69],
    ],
    [
      [7051, 10963],
      [-10, 37],
      [26, 9],
      [-3, 60],
      [16, 44],
      [35, 8],
      [29, 75],
      [27, 63],
      [-26, 29],
      [14, 69],
      [-16, 110],
      [15, 31],
      [-11, 102],
      [-28, 64],
    ],
    [
      [7119, 11664],
      [8, 58],
      [23, -8],
      [13, 35],
      [-16, 71],
      [8, 17],
    ],
    [
      [7155, 11837],
      [36, -3],
      [53, 83],
      [28, 13],
      [1, 40],
      [13, 101],
      [40, 56],
      [44, 2],
      [5, 25],
      [55, -10],
      [55, 61],
      [27, 26],
      [34, 58],
      [24, -7],
      [19, -32],
      [-14, -40],
    ],
    [
      [7575, 12210],
      [-45, -20],
      [-17, -60],
      [-27, -35],
      [-21, -44],
      [-8, -86],
      [-19, -70],
      [36, -8],
      [8, -55],
      [16, -26],
      [5, -49],
      [-8, -44],
      [3, -25],
      [17, -10],
      [16, -42],
      [90, 12],
      [40, -16],
      [49, -103],
      [29, 13],
      [50, -7],
      [40, 14],
      [24, -21],
      [-12, -64],
      [-16, -40],
      [-5, -86],
      [14, -80],
      [20, -36],
      [2, -27],
      [-35, -59],
      [25, -27],
      [18, -42],
      [22, -119],
    ],
    [
      [6764, 11784],
      [-38, 27],
      [-14, 25],
      [8, 21],
      [-2, 26],
      [-20, 29],
      [-27, 23],
      [-24, 16],
      [-5, 35],
      [-18, 21],
      [4, -35],
      [-13, -28],
      [-16, 33],
      [-23, 12],
      [-9, 24],
      [0, 37],
      [9, 37],
      [-19, 17],
      [16, 23],
    ],
    [
      [6573, 12127],
      [10, 16],
      [46, -32],
      [16, 16],
      [22, -10],
      [12, -25],
      [20, -8],
      [17, 26],
    ],
    [
      [6716, 12110],
      [18, -66],
      [27, -48],
      [32, -51],
    ],
    [
      [6793, 11945],
      [-27, -11],
      [1, -48],
      [14, -18],
      [-10, -14],
      [3, -22],
      [-6, -24],
      [-4, -24],
    ],
    [
      [6813, 13579],
      [60, -8],
      [55, -2],
      [65, -41],
      [28, -44],
      [65, 14],
      [25, -28],
      [59, -75],
      [43, -54],
      [23, 2],
      [42, -24],
      [-5, -34],
      [51, -5],
      [53, -49],
      [-9, -28],
      [-46, -16],
      [-47, -6],
      [-48, 10],
      [-100, -12],
      [47, 67],
      [-28, 31],
      [-45, 8],
      [-24, 35],
      [-17, 68],
      [-39, -4],
      [-65, 32],
      [-21, 25],
      [-91, 19],
      [-24, 23],
      [26, 30],
      [-69, 6],
      [-50, -62],
      [-29, -2],
      [-10, -29],
      [-34, -13],
      [-30, 11],
      [37, 37],
      [15, 43],
      [31, 27],
      [36, 23],
      [53, 12],
      [17, 13],
    ],
    [
      [14829, 15013],
      [5, 1],
      [10, 28],
      [50, -1],
      [64, 36],
      [-47, -51],
      [5, -23],
    ],
    [
      [14916, 15003],
      [-8, 4],
      [-13, -9],
      [-10, 3],
      [-4, -5],
      [-1, 12],
      [-5, 8],
      [-14, 1],
      [-19, -10],
      [-13, 6],
    ],
    [
      [14916, 15003],
      [2, -10],
      [-72, -48],
      [-34, 15],
      [-16, 48],
      [33, 5],
    ],
    [
      [13495, 16661],
      [-39, 52],
      [-36, 28],
      [-7, 51],
      [-12, 36],
      [50, 26],
      [26, 30],
      [50, 23],
      [18, 23],
      [18, -14],
      [31, 12],
    ],
    [
      [13594, 16928],
      [33, -38],
      [52, -11],
      [-4, -33],
      [38, -24],
      [10, 30],
      [48, -13],
      [7, -37],
      [52, -8],
      [32, -59],
    ],
    [
      [13862, 16735],
      [-21, 0],
      [-11, -22],
      [-16, -5],
      [-4, -27],
      [-14, -6],
      [-2, -11],
      [-23, -12],
      [-31, 2],
      [-10, -27],
    ],
    [
      [13068, 16510],
      [9, 86],
      [35, 82],
      [-100, 22],
      [-33, 31],
    ],
    [
      [12979, 16731],
      [4, 53],
      [-14, 27],
    ],
    [
      [12977, 16892],
      [-12, 126],
      [42, 0],
      [18, 45],
      [17, 110],
      [-13, 40],
    ],
    [
      [13029, 17213],
      [13, 26],
      [59, 6],
      [13, -26],
      [47, 59],
      [-16, 45],
      [-3, 68],
    ],
    [
      [13142, 17391],
      [53, -16],
      [44, 18],
    ],
    [
      [13239, 17393],
      [1, -46],
      [71, -28],
      [-1, -42],
      [71, 22],
      [39, 33],
      [79, -47],
      [33, -39],
    ],
    [
      [13532, 17246],
      [16, -61],
      [-19, -32],
      [25, -42],
      [17, -65],
      [-5, -41],
      [28, -77],
    ],
    [
      [15551, 12321],
      [16, -37],
      [-2, -50],
      [-40, -29],
      [30, -33],
    ],
    [
      [15555, 12172],
      [-26, -64],
    ],
    [
      [15529, 12108],
      [-15, 21],
      [-17, -8],
      [-39, 2],
      [-1, 36],
      [-5, 34],
      [23, 56],
      [25, 53],
    ],
    [
      [15500, 12302],
      [30, -11],
      [21, 30],
    ],
    [
      [13142, 17391],
      [-28, 67],
      [-3, 122],
      [12, 33],
      [20, 36],
      [61, 7],
      [25, 33],
      [56, 34],
      [-2, -62],
      [-21, -39],
      [8, -33],
      [38, -19],
      [-17, -45],
      [-21, 13],
      [-50, -86],
      [19, -59],
    ],
    [
      [13432, 17469],
      [-42, -98],
      [-73, 68],
      [-9, 50],
      [102, 40],
      [22, -60],
    ],
    [
      [7549, 13162],
      [8, 21],
      [55, -1],
      [41, -31],
      [18, 3],
      [13, -42],
      [38, 2],
      [-2, -36],
      [31, -4],
      [34, -44],
      [-26, -49],
      [-33, 26],
      [-32, -5],
      [-23, 6],
      [-12, -22],
      [-27, -7],
      [-11, 29],
      [-23, -17],
      [-28, -83],
      [-18, 20],
      [-3, 34],
    ],
    [
      [7549, 12962],
      [1, 33],
      [-18, 36],
      [17, 20],
      [6, 46],
      [-6, 65],
    ],
    [
      [12845, 13095],
      [-77, -12],
      [-1, 77],
      [-32, 19],
      [-44, 35],
      [-16, 56],
      [-236, 262],
      [-235, 261],
    ],
    [
      [12204, 13793],
      [-262, 291],
    ],
    [
      [11942, 14084],
      [1, 23],
      [0, 8],
    ],
    [
      [11943, 14115],
      [0, 142],
      [112, 89],
      [70, 18],
      [57, 32],
      [27, 60],
      [81, 48],
      [3, 89],
      [41, 10],
      [31, 45],
      [91, 20],
      [13, 46],
      [-18, 26],
      [-24, 127],
      [-4, 72],
      [-27, 77],
    ],
    [
      [12396, 15016],
      [67, 66],
      [76, 21],
      [44, 49],
      [67, 37],
      [118, 21],
      [115, 10],
      [35, -18],
      [66, 47],
      [74, 1],
      [29, -28],
      [48, 8],
    ],
    [
      [13135, 15230],
      [-15, -62],
      [11, -114],
      [-16, -99],
      [-43, -67],
      [6, -91],
      [57, -71],
      [1, -29],
      [43, -48],
      [29, -216],
    ],
    [
      [13208, 14433],
      [23, -106],
      [4, -56],
      [-12, -97],
      [5, -55],
      [-9, -66],
      [6, -75],
      [-28, -50],
      [41, -88],
      [3, -51],
      [25, -67],
      [32, 22],
      [55, -56],
      [31, -75],
    ],
    [
      [13384, 13613],
      [-239, -229],
      [-202, -235],
      [-98, -54],
    ],
    [
      [7293, 10779],
      [10, -91],
      [-22, -78],
      [-76, -126],
      [-83, -47],
      [-43, -104],
      [-13, -81],
      [-40, -50],
      [-29, 61],
      [-28, 13],
      [-29, -10],
      [-2, 44],
      [20, 29],
      [-8, 50],
    ],
    [
      [6950, 10389],
      [37, 89],
      [-15, 53],
      [-27, -56],
      [-42, 53],
      [15, 33],
      [-12, 109],
      [24, 18],
      [13, 75],
      [26, 77],
      [-4, 49],
      [38, 26],
      [48, 48],
    ],
    [
      [15117, 13437],
      [-276, 0],
      [-271, 0],
      [-280, 0],
    ],
    [
      [14290, 13437],
      [0, 441],
      [0, 427],
      [-21, 97],
      [18, 74],
      [-11, 51],
      [26, 58],
    ],
    [
      [14302, 14585],
      [92, 1],
      [68, -31],
      [69, -36],
      [32, -18],
      [54, 38],
      [28, 34],
      [62, 10],
      [49, -15],
      [19, -60],
      [17, 39],
      [55, -28],
      [55, -7],
      [34, 31],
    ],
    [
      [14936, 14543],
      [39, -175],
      [7, -32],
    ],
    [
      [14982, 14336],
      [-20, -48],
      [-15, -90],
      [-19, -63],
      [-16, -21],
      [-23, 39],
      [-32, 53],
      [-49, 172],
      [-7, -10],
      [28, -127],
      [43, -121],
      [53, -187],
      [26, -65],
      [22, -68],
      [63, -132],
      [-14, -21],
      [2, -78],
      [81, -108],
      [12, -24],
    ],
    [
      [15500, 12302],
      [-24, 39],
      [-29, 70],
      [-31, 39],
      [-18, 41],
      [-60, 48],
      [-48, 2],
      [-17, 25],
      [-41, -29],
      [-42, 55],
      [-22, -90],
      [-81, 25],
    ],
    [
      [15087, 12527],
      [-7, 48],
      [30, 177],
      [6, 79],
      [22, 37],
      [52, 20],
      [35, 68],
    ],
    [
      [15225, 12956],
      [40, -138],
      [20, -111],
      [38, -58],
      [95, -113],
      [39, -69],
      [38, -69],
      [21, -41],
      [35, -36],
    ],
    [
      [11918, 15822],
      [3, 85],
      [-28, 52],
      [98, 87],
      [86, -22],
      [93, 1],
      [74, -21],
      [58, 7],
      [113, -4],
    ],
    [
      [12415, 16007],
      [28, -47],
      [128, -55],
      [25, 26],
      [79, -54],
      [81, 16],
    ],
    [
      [12756, 15893],
      [3, -70],
      [-66, -80],
      [-89, -25],
      [-6, -41],
      [-43, -66],
      [-27, -98],
      [27, -68],
      [-40, -54],
      [-15, -78],
      [-53, -24],
      [-49, -92],
      [-89, -2],
      [-66, 2],
      [-44, -42],
      [-26, -45],
      [-34, 10],
      [-26, 40],
      [-20, 69],
      [-65, 19],
    ],
    [
      [12028, 15248],
      [-6, 39],
      [26, 45],
      [10, 33],
      [-25, 36],
      [20, 79],
      [-28, 72],
      [30, 9],
      [3, 57],
      [11, 18],
      [1, 93],
      [32, 33],
      [-19, 60],
      [-41, 4],
      [-12, -15],
      [-41, 0],
      [-18, 59],
      [-28, -18],
      [-25, -30],
    ],
    [
      [14242, 17731],
      [8, 70],
      [-25, -15],
      [-44, 43],
      [-7, 69],
      [89, 33],
      [87, 18],
      [76, -20],
      [72, 3],
    ],
    [
      [14498, 17932],
      [11, -21],
      [-50, -69],
      [21, -112],
      [-30, -38],
    ],
    [
      [14450, 17692],
      [-58, 1],
      [-60, 44],
      [-30, 15],
      [-60, -21],
    ],
    [
      [15529, 12108],
      [-15, -42],
      [26, -66],
      [26, -58],
      [26, -43],
      [228, -142],
      [59, 0],
    ],
    [
      [15879, 11757],
      [-197, -360],
      [-91, -5],
      [-62, -85],
      [-45, -2],
      [-19, -38],
    ],
    [
      [15465, 11267],
      [-47, 0],
      [-29, 41],
      [-63, -50],
      [-21, -50],
      [-46, 9],
      [-16, 14],
      [-16, -3],
      [-22, 1],
      [-88, 102],
      [-49, 0],
      [-24, 39],
      [0, 68],
      [-36, 20],
    ],
    [
      [15008, 11458],
      [-41, 130],
      [-32, 28],
      [-12, 48],
      [-36, 59],
      [-42, 8],
      [23, 68],
      [37, 3],
      [11, 37],
    ],
    [
      [14916, 11839],
      [-1, 108],
      [21, 125],
      [33, 34],
      [7, 49],
      [29, 92],
      [42, 59],
      [29, 118],
      [11, 103],
    ],
    [
      [14214, 18716],
      [-24, 47],
      [-2, 184],
      [-108, 82],
      [-93, 59],
    ],
    [
      [13987, 19088],
      [41, 31],
      [78, -63],
      [91, 6],
      [75, -29],
      [66, 53],
      [34, 88],
      [109, 41],
      [89, -48],
      [-29, -84],
    ],
    [
      [14541, 19083],
      [-11, -84],
      [107, -80],
      [-64, -91],
      [81, -136],
      [-47, -103],
      [63, -89],
      [-29, -78],
      [103, -83],
      [-26, -61],
      [-65, -69],
      [-149, -153],
    ],
    [
      [14504, 18056],
      [-126, -10],
      [-123, -44],
      [-113, -25],
      [-41, 65],
      [-67, 40],
      [15, 118],
      [-33, 108],
      [33, 70],
      [63, 75],
      [159, 130],
      [47, 26],
      [-7, 50],
      [-97, 57],
    ],
    [
      [24982, 8717],
      [24, -35],
      [-12, -62],
      [-43, -17],
      [-39, 15],
      [-6, 53],
      [27, 41],
      [31, -15],
      [18, 20],
    ],
    [
      [25051, 8782],
      [-45, -26],
      [-9, 45],
      [35, 25],
      [22, 6],
      [41, 38],
      [0, -59],
      [-44, -29],
    ],
    [
      [6, 8817],
      [-6, -6],
      [0, 59],
      [14, 5],
      [-8, -58],
    ],
    [
      [8281, 4577],
      [84, 72],
      [59, -30],
      [42, 48],
      [56, -54],
      [-21, -42],
      [-94, -36],
      [-32, 42],
      [-59, -54],
      [-35, 54],
    ],
    [
      [12943, 16739],
      [16, -10],
      [20, 2],
    ],
    [
      [13025, 16315],
      [-3, -34],
      [20, -45],
      [-24, -37],
      [18, -93],
      [38, -15],
      [-8, -52],
    ],
    [
      [13066, 16039],
      [-63, -68],
      [-138, 33],
      [-101, -39],
      [-8, -72],
    ],
    [
      [12415, 16007],
      [36, 72],
      [13, 239],
      [-72, 125],
      [-51, 61],
      [-107, 46],
      [-7, 88],
      [91, 26],
      [117, -31],
      [-22, 136],
      [66, -52],
      [162, 94],
      [21, 98],
      [61, 24],
    ],
    [
      [8747, 11075],
      [17, 51],
      [6, 54],
      [12, 52],
      [-27, 71],
      [-5, 82],
      [36, 103],
    ],
    [
      [8786, 11488],
      [24, -13],
      [51, -29],
      [74, -101],
      [12, -49],
    ],
    [
      [13214, 15854],
      [-23, -92],
      [-32, 24],
      [-16, 81],
      [14, 44],
      [45, 46],
      [12, -103],
    ],
    [
      [13321, 10320],
      [-72, 121],
      [-46, 99],
      [-42, 124],
      [2, 40],
      [15, 38],
      [17, 87],
      [14, 89],
    ],
    [
      [13209, 10918],
      [24, 7],
      [101, -1],
      [0, 144],
    ],
    [
      [12115, 17260],
      [-52, 24],
      [-43, -1],
      [14, 64],
      [-14, 64],
    ],
    [
      [12020, 17411],
      [58, 5],
      [75, -74],
      [-38, -82],
    ],
    [
      [12338, 17832],
      [-74, -130],
      [71, 16],
      [76, 0],
      [-18, -98],
      [-63, -108],
      [72, -7],
      [6, -13],
      [62, -142],
      [47, -19],
      [43, -136],
      [20, -48],
      [85, -23],
      [-9, -76],
      [-35, -36],
      [28, -62],
      [-63, -63],
      [-93, 2],
      [-119, -33],
      [-33, 23],
      [-46, -56],
      [-64, 14],
      [-49, -46],
      [-37, 24],
      [102, 126],
      [62, 26],
      [0, 0],
      [-109, 20],
      [-20, 48],
      [73, 37],
      [-38, 64],
      [13, 79],
      [104, -11],
      [10, 70],
      [-46, 74],
      [-2, 1],
      [-84, 21],
      [-17, 33],
      [26, 53],
      [-23, 34],
      [-38, -57],
      [-4, 115],
      [-35, 62],
      [25, 124],
      [54, 97],
      [56, -10],
      [84, 11],
    ],
    [
      [15586, 15727],
      [-68, 59],
      [-74, -6],
    ],
    [
      [15444, 15780],
      [11, 51],
      [-18, 82],
      [-40, 44],
      [-39, 14],
      [-25, 37],
    ],
    [
      [15333, 16008],
      [8, 14],
      [59, -20],
      [103, -20],
      [95, -57],
      [12, -23],
      [42, 19],
      [65, -25],
      [21, -49],
      [44, -28],
    ],
    [
      [12549, 12119],
      [-5, -37],
      [29, -62],
      [0, -87],
      [7, -95],
      [17, -44],
      [-15, -108],
      [5, -59],
      [19, -76],
      [15, -43],
    ],
    [
      [12621, 11508],
      [-109, -70],
      [-39, -41],
      [-62, -35],
      [-63, 34],
    ],
    [
      [11959, 11719],
      [-20, 3],
      [-14, -48],
      [-19, 1],
      [-14, 25],
      [5, 48],
      [-30, 74],
      [-18, -14],
      [-15, -2],
    ],
    [
      [11834, 11806],
      [-19, -7],
      [1, 44],
      [-11, 31],
      [2, 35],
      [-15, 50],
      [-19, 43],
      [-56, 1],
      [-16, -23],
      [-20, -3],
      [-12, -26],
      [-8, -33],
      [-37, -53],
    ],
    [
      [11624, 11865],
      [-30, 71],
      [-28, 47],
      [-17, 16],
      [-18, 24],
      [-8, 53],
      [-10, 26],
      [-20, 20],
    ],
    [
      [11493, 12122],
      [31, 58],
      [21, -2],
      [18, 20],
      [15, 0],
      [11, 16],
      [-5, 40],
      [7, 12],
      [1, 41],
    ],
    [
      [11592, 12307],
      [34, -1],
      [50, -29],
      [16, 2],
      [5, 14],
      [38, -10],
      [10, 7],
    ],
    [
      [11745, 12290],
      [4, -44],
      [11, 0],
      [18, 16],
      [12, -4],
      [19, -30],
      [30, -10],
      [19, 26],
      [23, 16],
      [16, 17],
      [14, -3],
      [16, -27],
      [8, -33],
      [29, -50],
      [-15, -31],
      [-2, -39],
      [14, 12],
      [9, -14],
      [-4, -36],
      [22, -34],
    ],
    [
      [11374, 12375],
      [8, 53],
    ],
    [
      [11382, 12428],
      [76, 4],
      [16, 28],
      [22, 2],
      [28, -30],
      [21, 0],
      [23, 20],
      [14, -35],
      [-30, -27],
      [-30, 3],
      [-30, 25],
      [-26, -28],
      [-12, -1],
      [-17, -17],
      [-63, 3],
    ],
    [
      [11493, 12122],
      [-37, 50],
      [-30, 8],
      [-16, 34],
      [1, 18],
      [-22, 25],
      [-4, 26],
    ],
    [
      [11385, 12283],
      [37, 20],
      [23, -4],
      [19, 13],
      [128, -5],
    ],
    [
      [13209, 10918],
      [-13, 18],
      [24, 135],
    ],
    [
      [14013, 15697],
      [45, 11],
      [27, 26],
      [38, -2],
      [11, 20],
      [13, 4],
    ],
    [
      [14368, 15815],
      [34, -32],
      [-22, -75],
      [-16, -13],
    ],
    [
      [14364, 15695],
      [-43, 3],
      [-36, 12],
      [-84, -32],
      [48, -67],
      [-35, -20],
      [-39, 0],
      [-37, 62],
      [-13, -26],
      [15, -72],
      [35, -56],
      [-26, -27],
      [39, -55],
      [34, -35],
      [1, -67],
      [-64, 31],
      [20, -61],
      [-44, -12],
      [27, -106],
      [-47, -2],
      [-57, 52],
      [-26, 96],
      [-12, 80],
      [-27, 55],
      [-36, 69],
      [-5, 34],
    ],
    [
      [14200, 15081],
      [38, -41],
      [54, 7],
      [52, -8],
      [-2, -21],
      [38, 14],
      [-9, -35],
      [-100, -10],
      [1, 19],
      [-85, 24],
      [13, 51],
    ],
    [
      [9288, 20710],
      [234, 72],
      [244, -6],
      [89, 44],
      [247, 12],
      [556, -15],
      [436, -95],
      [-128, -46],
      [-267, -6],
      [-375, -11],
      [35, -22],
      [247, 13],
      [210, -41],
      [135, 37],
      [58, -43],
      [-77, -70],
      [178, 45],
      [338, 46],
      [209, -23],
      [39, -51],
      [-284, -86],
      [-39, -27],
      [-223, -21],
      [162, -6],
      [-82, -87],
      [-56, -78],
      [2, -134],
      [84, -78],
      [-109, -5],
      [-115, -38],
      [129, -63],
      [16, -102],
      [-74, -11],
      [90, -104],
      [-155, -8],
      [81, -49],
      [-23, -42],
      [-98, -19],
      [-97, 0],
      [87, -82],
      [1, -53],
      [-138, 50],
      [-36, -32],
      [94, -30],
      [92, -74],
      [26, -96],
      [-124, -23],
      [-54, 46],
      [-86, 69],
      [24, -82],
      [-81, -63],
      [184, -5],
      [96, -6],
      [-187, -105],
      [-190, -94],
      [-204, -42],
      [-77, 0],
      [-72, -47],
      [-97, -126],
      [-150, -84],
      [-48, -5],
      [-93, -30],
      [-100, -28],
      [-59, -74],
      [-1, -84],
      [-36, -79],
      [-113, -96],
      [28, -94],
      [-32, -99],
      [-35, -117],
      [-99, -7],
      [-102, 98],
      [-140, 0],
      [-67, 66],
      [-47, 117],
      [-121, 149],
      [-35, 79],
      [-10, 107],
      [-96, 111],
      [25, 88],
      [-47, 43],
      [69, 140],
      [105, 45],
      [28, 50],
      [14, 94],
      [-79, -43],
      [-38, -18],
      [-63, -17],
      [-85, 39],
      [-5, 82],
      [27, 64],
      [65, 1],
      [142, -32],
      [-120, 77],
      [-62, 41],
      [-69, -17],
      [-59, 29],
      [78, 112],
      [-42, 45],
      [-56, 83],
      [-83, 127],
      [-89, 47],
      [1, 50],
      [-187, 70],
      [-148, 9],
      [-187, -5],
      [-170, -9],
      [-81, 38],
      [-121, 76],
      [183, 38],
      [140, 6],
      [-298, 31],
      [-157, 49],
      [10, 47],
      [264, 57],
      [255, 58],
      [27, 44],
      [-188, 43],
      [60, 48],
      [242, 83],
      [101, 13],
      [-29, 54],
      [165, 32],
      [215, 19],
      [214, 1],
      [76, -38],
      [185, 66],
      [166, -45],
      [98, -9],
      [145, -39],
      [-166, 65],
      [10, 51],
    ],
    [
      [6348, 12703],
      [23, -22],
      [6, 18],
      [20, -15],
    ],
    [
      [6397, 12684],
      [-31, -46],
      [-33, -33],
      [-5, -23],
      [5, -24],
      [-14, -30],
    ],
    [
      [6319, 12528],
      [-16, -8],
      [3, -14],
      [-13, -13],
      [-24, -30],
      [-2, -18],
    ],
    [
      [6267, 12445],
      [-36, 21],
      [-43, 2],
      [-32, 24],
      [-38, 49],
    ],
    [
      [6118, 12541],
      [2, 35],
      [8, 28],
      [-10, 23],
      [34, 98],
      [89, 0],
      [2, 41],
      [-11, 7],
      [-8, 26],
      [-26, 28],
      [-26, 40],
      [32, 0],
      [0, 68],
      [65, 0],
      [64, -1],
    ],
    [
      [8314, 11421],
      [-47, 91],
      [19, 33],
      [-2, 56],
      [43, 19],
      [17, 22],
      [-23, 45],
      [6, 44],
      [55, 70],
    ],
    [
      [8382, 11801],
      [46, -44],
      [43, -78],
      [2, -62],
      [26, -3],
      [37, -58],
      [28, -42],
    ],
    [
      [8564, 11514],
      [-11, -108],
      [-43, -31],
      [4, -29],
      [-13, -62],
      [31, -87],
      [23, 0],
      [9, -68],
      [42, -104],
    ],
    [
      [6397, 12684],
      [8, -5],
      [15, 21],
      [20, 2],
      [6, -10],
      [11, 6],
      [33, -10],
      [32, 3],
      [22, 13],
      [8, 13],
      [23, -6],
      [16, -8],
      [19, 3],
      [13, 10],
      [32, -16],
      [11, -3],
      [22, -23],
      [20, -26],
      [25, -19],
      [18, -33],
    ],
    [
      [6751, 12596],
      [-23, 3],
      [-10, -17],
      [-24, -15],
      [-18, 0],
      [-15, -16],
      [-14, 6],
      [-12, 18],
      [-7, -3],
      [-9, -29],
      [-7, 1],
      [-1, -25],
      [-25, -33],
      [-12, -14],
      [-8, -15],
      [-20, 24],
      [-15, -32],
      [-15, 1],
      [-16, -3],
      [1, -59],
      [-10, -1],
      [-9, -27],
      [-21, -5],
    ],
    [
      [6461, 12355],
      [-12, 37],
      [-21, 11],
    ],
    [
      [6428, 12403],
      [4, 48],
      [-9, 13],
      [-14, 9],
      [-31, -15],
      [-3, 16],
      [-21, 20],
      [-15, 24],
      [-20, 10],
    ],
    [
      [13841, 15914],
      [-7, -21],
    ],
    [
      [13834, 15893],
      [-66, 45],
      [-40, 43],
      [-64, 36],
      [-59, 88],
      [14, 9],
      [-31, 50],
      [-2, 41],
      [-45, 19],
      [-21, -52],
      [-20, 40],
      [1, 42],
      [3, 2],
    ],
    [
      [13504, 16256],
      [48, -4],
      [13, 20],
      [24, -20],
      [27, -2],
      [0, 34],
      [24, 12],
      [7, 48],
      [55, 32],
    ],
    [
      [13702, 16376],
      [22, -15],
      [52, -51],
      [58, -23],
      [26, 18],
    ],
    [
      [13860, 16305],
      [17, -47],
      [22, -34],
      [-27, -45],
    ],
    [
      [7549, 12962],
      [-46, 20],
      [-33, -8],
      [-43, 9],
      [-33, -23],
      [-37, 38],
      [6, 38],
      [64, -16],
      [53, -10],
      [25, 27],
      [-32, 52],
      [1, 46],
      [-44, 18],
      [16, 33],
      [42, -5],
      [61, -19],
    ],
    [
      [13731, 16571],
      [36, -31],
      [25, -13],
      [59, 14],
      [5, 25],
      [28, 3],
      [34, 19],
      [8, -8],
      [32, 15],
      [17, 28],
      [23, 8],
      [74, -37],
      [15, 12],
    ],
    [
      [14087, 16606],
      [39, -32],
      [5, -32],
    ],
    [
      [14131, 16542],
      [-43, -26],
      [-33, -81],
      [-42, -81],
      [-56, -23],
    ],
    [
      [13957, 16331],
      [-43, 5],
      [-54, -31],
    ],
    [
      [13702, 16376],
      [-13, 41],
      [-12, 1],
    ],
    [
      [20962, 9569],
      [-29, -3],
      [-92, 85],
      [65, 23],
      [36, -36],
      [25, -37],
      [-5, -32],
    ],
    [
      [21259, 9730],
      [7, -23],
      [1, -37],
    ],
    [
      [21267, 9670],
      [-45, -89],
      [-60, -27],
      [-8, 15],
      [6, 40],
      [30, 74],
      [69, 47],
    ],
    [
      [20766, 9826],
      [25, -32],
      [43, 10],
      [18, -51],
      [-81, -24],
      [-48, -16],
      [-38, 1],
      [24, 69],
      [38, 1],
      [19, 42],
    ],
    [
      [21115, 9826],
      [-10, -67],
      [-105, -34],
      [-93, 15],
      [0, 44],
      [55, 25],
      [44, -36],
      [46, 9],
      [63, 44],
    ],
    [
      [20119, 9984],
      [134, -12],
      [15, 50],
      [130, -58],
      [25, -78],
      [105, -22],
      [85, -71],
      [-79, -46],
      [-77, 49],
      [-63, -4],
      [-72, 9],
      [-66, 22],
      [-80, 46],
      [-52, 11],
      [-29, -15],
      [-127, 50],
      [-12, 51],
      [-64, 9],
      [48, 115],
      [85, -7],
      [56, -47],
      [29, -9],
      [9, -43],
    ],
    [
      [21939, 10052],
      [-36, -82],
      [-7, 90],
      [13, 43],
      [14, 41],
      [16, -35],
      [0, -57],
    ],
    [
      [21418, 10382],
      [-26, -40],
      [-48, 22],
      [-14, 52],
      [71, 6],
      [17, -40],
    ],
    [
      [21642, 10426],
      [26, -92],
      [-59, 50],
      [-58, 10],
      [-40, -8],
      [-48, 4],
      [17, 66],
      [86, 5],
      [76, -35],
    ],
    [
      [22376, 10485],
      [2, -391],
      [1, -391],
    ],
    [
      [22379, 9703],
      [-62, 99],
      [-71, 24],
      [-17, -34],
      [-89, -4],
      [30, 98],
      [44, 33],
      [-18, 130],
      [-34, 101],
      [-135, 102],
      [-57, 10],
      [-105, 111],
      [-21, -59],
      [-26, -10],
      [-16, 44],
      [0, 52],
      [-54, 59],
      [75, 43],
      [50, -2],
      [-6, 32],
      [-102, 0],
      [-27, 71],
      [-63, 22],
      [-29, 60],
      [94, 29],
      [35, 39],
      [112, -49],
      [11, -45],
      [20, -194],
      [72, -72],
      [58, 127],
      [80, 73],
      [62, 0],
      [60, -42],
      [52, -43],
      [74, -23],
    ],
    [
      [21278, 10968],
      [-56, -119],
      [-53, -24],
      [-67, 24],
      [-116, -6],
      [-61, -17],
      [-10, -91],
      [63, -107],
      [37, 55],
      [130, 40],
      [-5, -55],
      [-31, 18],
      [-30, -71],
      [-61, -46],
      [66, -154],
      [-13, -41],
      [63, -139],
      [-1, -79],
      [-37, -35],
      [-28, 42],
      [34, 99],
      [-68, -47],
      [-18, 33],
      [9, 47],
      [-50, 70],
      [5, 117],
      [-46, -37],
      [6, -139],
      [3, -172],
      [-45, -17],
      [-30, 35],
      [20, 110],
      [-10, 116],
      [-30, 1],
      [-21, 82],
      [28, 79],
      [10, 95],
      [35, 181],
      [15, 49],
      [59, 89],
      [55, -35],
      [88, -17],
      [80, 5],
      [69, 87],
      [12, -26],
    ],
    [
      [21518, 10933],
      [-4, -105],
      [-35, 12],
      [-11, -73],
      [29, -63],
      [-20, -15],
      [-28, 76],
      [-21, 154],
      [14, 95],
      [23, 44],
      [5, -65],
      [42, -11],
      [6, -49],
    ],
    [
      [20192, 11038],
      [12, -80],
      [47, -68],
      [45, 24],
      [45, -8],
      [40, 60],
      [34, 11],
      [66, -34],
      [57, 26],
      [35, 167],
      [27, 41],
      [24, 137],
      [80, 0],
      [61, -20],
    ],
    [
      [20765, 11294],
      [-40, -109],
      [51, -113],
      [-12, -56],
      [79, -111],
      [-83, -14],
      [-23, -82],
      [3, -108],
      [-67, -82],
      [-2, -120],
      [-27, -183],
      [-10, 42],
      [-79, -54],
      [-28, 74],
      [-50, 7],
      [-35, 38],
      [-82, -43],
      [-26, 58],
      [-46, -7],
      [-57, 14],
      [-11, 161],
      [-34, 33],
      [-34, 103],
      [-10, 105],
      [9, 111],
      [41, 80],
    ],
    [
      [19924, 10095],
      [-77, -2],
      [-59, 100],
      [-90, 98],
      [-29, 73],
      [-53, 97],
      [-35, 90],
      [-53, 168],
      [-61, 100],
      [-20, 103],
      [-26, 94],
      [-63, 75],
      [-36, 103],
      [-53, 67],
      [-73, 133],
      [-6, 61],
      [45, -5],
      [108, -23],
      [62, -118],
      [54, -81],
      [38, -50],
      [66, -129],
      [71, -2],
      [58, -82],
      [41, -100],
      [53, -55],
      [-28, -98],
      [40, -42],
      [25, -3],
      [12, -84],
      [24, -67],
      [51, -10],
      [34, -76],
      [-17, -149],
      [-3, -186],
    ],
    [
      [18754, 13443],
      [-10, -44],
      [-48, 2],
      [-86, -25],
      [4, -90],
      [-37, -71],
      [-100, -81],
      [-78, -141],
      [-53, -76],
      [-69, -78],
      [0, -56],
      [-35, -29],
      [-63, -43],
      [-32, -6],
      [-21, -92],
      [14, -156],
      [4, -99],
      [-29, -114],
      [-1, -204],
      [-36, -6],
      [-32, -92],
      [22, -39],
      [-64, -34],
      [-23, -82],
      [-28, -34],
      [-66, 112],
      [-33, 168],
      [-26, 121],
      [-25, 57],
      [-37, 115],
      [-17, 150],
      [-12, 75],
      [-64, 165],
      [-28, 232],
      [-21, 154],
      [0, 145],
      [-14, 112],
      [-101, -72],
      [-49, 15],
      [-91, 145],
      [33, 44],
      [-20, 47],
      [-82, 101],
    ],
    [
      [17300, 13639],
      [46, 81],
      [154, -1],
      [-14, 103],
      [-39, 61],
      [-8, 92],
      [-46, 54],
      [77, 126],
      [81, -9],
      [73, 126],
      [44, 121],
      [67, 121],
      [-1, 85],
      [60, 70],
      [-57, 59],
      [-24, 81],
      [-25, 105],
      [35, 52],
      [105, -29],
      [78, 18],
      [67, 100],
    ],
    [
      [18202, 14418],
      [-45, -54],
      [-27, -112],
      [68, -46],
      [66, -59],
      [91, -67],
      [95, -15],
      [40, -61],
      [54, -12],
      [84, -28],
      [58, 2],
      [8, 48],
      [-9, 76],
      [5, 52],
    ],
    [
      [19332, 14188],
      [5, -46],
      [-24, -22],
      [6, -74],
      [-50, 22],
      [-91, -83],
      [3, -68],
      [-39, -101],
      [-3, -59],
      [-31, -98],
      [-55, 27],
      [-3, -124],
      [-15, -41],
      [7, -51],
      [-34, -29],
    ],
    [
      [12115, 17260],
      [12, -86],
      [-53, -107],
      [-123, -71],
      [-99, 18],
      [57, 125],
      [-37, 122],
      [95, 94],
      [53, 56],
    ],
    [
      [16791, 14376],
      [34, -63],
      [29, -73],
      [66, -53],
      [2, -105],
      [33, -20],
      [6, -55],
      [-100, -62],
      [-27, -139],
    ],
    [
      [16834, 13806],
      [-131, 36],
      [-76, 28],
      [-78, 15],
      [-30, 147],
      [-34, 22],
      [-53, -22],
      [-70, -58],
      [-86, 40],
      [-70, 92],
      [-67, 34],
      [-47, 114],
      [-51, 160],
      [-38, -19],
      [-44, 39],
      [-26, -47],
    ],
    [
      [15933, 14387],
      [-38, 64],
      [-1, 63],
      [-22, 0],
      [11, 87],
      [-36, 91],
      [-85, 66],
      [-49, 114],
      [17, 94],
      [35, 41],
      [-6, 70],
      [-45, 36],
      [-45, 143],
    ],
    [
      [15669, 15256],
      [-39, 97],
      [14, 37],
      [-22, 137],
      [48, 35],
    ],
    [
      [15955, 15394],
      [22, -88],
      [66, -25],
      [49, -60],
      [99, -21],
      [109, 32],
      [6, 28],
    ],
    [
      [16306, 15260],
      [62, 23],
      [49, 69],
      [47, -4],
      [30, 23],
      [50, -11],
      [77, -61],
      [56, -13],
      [79, -107],
      [52, -4],
      [6, -101],
    ],
    [
      [15933, 14387],
      [-41, 6],
    ],
    [
      [15892, 14393],
      [-47, 10],
      [-51, -115],
    ],
    [
      [15794, 14288],
      [-130, 10],
      [-196, 241],
      [-104, 84],
      [-84, 33],
    ],
    [
      [15280, 14656],
      [-28, 146],
    ],
    [
      [15252, 14802],
      [154, 124],
      [26, 145],
      [-6, 88],
      [38, 30],
      [36, 75],
    ],
    [
      [15500, 15264],
      [30, 18],
      [81, -15],
      [24, -31],
      [34, 20],
    ],
    [
      [11536, 18770],
      [-16, -78],
      [79, -82],
      [-91, -91],
      [-201, -82],
      [-60, -22],
      [-92, 17],
      [-194, 38],
      [68, 53],
      [-151, 59],
      [123, 23],
      [-3, 36],
      [-146, 27],
      [47, 79],
      [106, 17],
      [108, -81],
      [106, 65],
      [88, -34],
      [113, 64],
      [116, -8],
    ],
    [
      [14936, 14543],
      [20, 39],
      [-4, 7],
      [18, 56],
      [14, 90],
      [10, 31],
      [2, 1],
    ],
    [
      [14996, 14767],
      [23, 0],
      [7, 21],
      [19, 1],
    ],
    [
      [15045, 14789],
      [1, -49],
      [-10, -18],
      [1, -1],
    ],
    [
      [15037, 14721],
      [-12, -38],
    ],
    [
      [15025, 14683],
      [-25, 17],
      [-14, -80],
      [17, -13],
      [-18, -17],
      [-3, -31],
      [33, 16],
    ],
    [
      [15015, 14575],
      [2, -47],
      [-35, -192],
    ],
    [
      [13510, 16377],
      [-8, -59],
      [17, -51],
    ],
    [
      [13519, 16267],
      [-55, 17],
      [-57, -42],
      [4, -60],
      [-9, -34],
      [23, -61],
      [65, -61],
      [35, -99],
      [78, -96],
      [55, 0],
      [17, -26],
      [-20, -24],
      [63, -44],
      [51, -36],
      [60, -62],
      [7, -23],
      [-13, -43],
      [-39, 56],
      [-61, 20],
      [-29, -78],
      [50, -44],
      [-8, -63],
      [-29, -7],
      [-37, -103],
      [-29, -9],
      [0, 37],
      [14, 64],
      [15, 26],
      [-27, 69],
      [-21, 61],
      [-29, 15],
      [-21, 51],
      [-44, 22],
      [-31, 49],
      [-51, 7],
      [-55, 54],
      [-63, 79],
      [-48, 69],
      [-21, 118],
      [-35, 14],
      [-57, 40],
      [-32, -16],
      [-40, -56],
      [-29, -9],
    ],
    [
      [13629, 15384],
      [-25, -95],
      [11, -37],
      [-15, -62],
      [-53, 46],
      [-36, 13],
      [-97, 61],
      [10, 61],
      [81, -11],
      [71, 13],
      [53, 11],
    ],
    [
      [13190, 15741],
      [41, -85],
      [-9, -159],
      [-32, 8],
      [-29, -40],
      [-26, 32],
      [-3, 144],
      [-16, 69],
      [39, -6],
      [35, 37],
    ],
    [
      [7140, 13015],
      [47, -10],
      [37, -29],
      [12, -33],
      [-49, -2],
      [-21, -20],
      [-39, 19],
      [-40, 44],
      [8, 27],
      [29, 9],
      [16, -5],
    ],
    [
      [15280, 14656],
      [-14, -19],
      [-139, -60],
      [69, -120],
      [-23, -20],
      [-11, -40],
      [-53, -17],
      [-17, -43],
      [-30, -37],
      [-78, 19],
    ],
    [
      [14984, 14319],
      [-2, 17],
    ],
    [
      [15015, 14575],
      [10, 35],
      [0, 73],
    ],
    [
      [15037, 14721],
      [78, -47],
      [137, 128],
    ],
    [
      [21933, 14894],
      [9, -41],
      [-39, -73],
      [-29, 39],
      [-36, -28],
      [-18, -70],
      [-46, 34],
      [1, 57],
      [38, 71],
      [40, -14],
      [29, 51],
      [51, -26],
    ],
    [
      [22375, 15253],
      [-27, -96],
      [13, -60],
      [-37, -84],
      [-89, -57],
      [-122, -7],
      [-100, -137],
      [-46, 46],
      [-3, 90],
      [-122, -27],
      [-82, -56],
      [-82, -3],
      [71, -88],
      [-47, -204],
      [-45, -50],
      [-33, 46],
      [17, 109],
      [-44, 34],
      [-29, 83],
      [66, 37],
      [37, 75],
      [70, 62],
      [51, 82],
      [139, 36],
      [74, -25],
      [73, 214],
      [47, -58],
      [102, 120],
      [40, 47],
      [43, 147],
      [-11, 135],
      [29, 75],
      [74, 22],
      [38, -166],
      [-2, -97],
      [-64, -121],
      [1, -124],
    ],
    [
      [22579, 16097],
      [49, -26],
      [50, 51],
      [15, -135],
      [-103, -33],
      [-61, -119],
      [-110, 82],
      [-38, -131],
      [-77, -2],
      [-10, 120],
      [34, 92],
      [75, 6],
      [20, 166],
      [21, 94],
      [82, -125],
      [53, -40],
    ],
    [
      [18142, 15878],
      [-43, 17],
      [-35, 44],
      [-103, 12],
      [-116, 3],
      [-25, -13],
      [-99, 51],
      [-40, -25],
      [-11, -71],
      [-114, 41],
      [-46, -17],
      [-16, -52],
    ],
    [
      [17494, 15868],
      [-40, -22],
      [-92, -84],
      [-30, -86],
      [-26, -1],
      [-19, 57],
      [-89, 4],
      [-14, 98],
      [-34, 1],
      [5, 121],
      [-83, 87],
      [-120, -9],
      [-82, -18],
      [-66, 109],
      [-57, 45],
      [-108, 86],
      [-13, 10],
      [-180, -71],
      [3, -442],
    ],
    [
      [16449, 15753],
      [-36, -6],
      [-49, 94],
      [-47, 34],
      [-79, -25],
      [-31, -40],
    ],
    [
      [16207, 15810],
      [-4, 29],
      [18, 50],
      [-14, 42],
      [-81, 41],
      [-31, 108],
      [-38, 30],
      [-3, 39],
      [68, -11],
      [3, 87],
      [59, 20],
      [61, -18],
      [12, 117],
      [-12, 74],
      [-70, -6],
      [-59, 30],
      [-81, -53],
      [-65, -25],
    ],
    [
      [15970, 16364],
      [-35, 19],
      [7, 62],
      [-45, 80],
      [-51, -3],
      [-59, 81],
      [40, 91],
      [-21, 24],
      [56, 132],
      [72, -69],
      [8, 87],
      [144, 131],
      [109, 3],
      [154, -83],
      [82, -49],
      [74, 51],
      [111, 2],
      [89, -62],
      [20, 36],
      [98, -6],
      [18, 57],
      [-113, 83],
      [67, 58],
      [-13, 33],
      [67, 31],
      [-51, 82],
      [32, 41],
      [261, 42],
      [34, 30],
      [174, 44],
      [63, 50],
      [125, -26],
      [22, -125],
      [73, 30],
      [90, -41],
      [-6, -66],
      [67, 7],
      [174, 113],
      [-25, -37],
      [89, -93],
      [156, -305],
      [37, 63],
      [96, -69],
      [100, 31],
      [38, -22],
      [34, -69],
      [49, -23],
      [29, -51],
      [90, 16],
      [37, -74],
    ],
    [
      [15465, 11267],
      [-61, -136],
      [1, -437],
      [41, -99],
    ],
    [
      [15446, 10595],
      [-48, -48],
      [-18, -50],
      [-26, -8],
      [-10, -85],
      [-22, -48],
      [-14, -80],
      [-28, -40],
    ],
    [
      [15280, 10236],
      [-100, 120],
      [-5, 70],
      [-252, 244],
      [-12, 13],
    ],
    [
      [14911, 10683],
      [-1, 127],
      [20, 49],
      [34, 79],
      [26, 88],
      [-31, 138],
      [-8, 60],
      [-33, 83],
    ],
    [
      [14918, 11307],
      [43, 72],
      [47, 79],
    ],
    [
      [17683, 15528],
      [-132, -18],
      [-86, 38],
      [-75, -9],
      [6, 69],
      [76, -20],
      [26, 37],
    ],
    [
      [17498, 15625],
      [53, -12],
      [89, 87],
      [-83, 63],
      [-49, -30],
      [-52, 45],
      [59, 78],
      [-21, 12],
    ],
    [
      [19699, 12259],
      [-17, 145],
      [45, 100],
      [90, 23],
      [65, -17],
    ],
    [
      [19882, 12510],
      [58, -48],
      [31, 83],
      [62, -44],
    ],
    [
      [20033, 12501],
      [16, -80],
      [-8, -144],
      [-118, -92],
      [31, -73],
      [-73, -8],
      [-61, -49],
    ],
    [
      [19820, 12055],
      [-58, 18],
      [-28, 62],
      [-35, 124],
    ],
    [
      [21495, 15429],
      [60, -141],
      [17, -78],
      [1, -138],
      [-27, -66],
      [-63, -23],
      [-56, -50],
      [-62, -10],
      [-8, 65],
      [13, 90],
      [-31, 125],
      [52, 20],
      [-48, 103],
    ],
    [
      [21343, 15326],
      [4, 11],
      [31, -4],
      [28, 54],
      [49, 6],
      [30, 7],
      [10, 29],
    ],
    [
      [13947, 15907],
      [13, 26],
    ],
    [
      [13960, 15933],
      [16, 9],
      [10, 40],
      [12, 6],
      [10, -16],
      [13, -8],
      [9, -19],
      [12, -6],
      [14, -22],
      [9, 1],
      [-7, -29],
      [-9, -15],
      [3, -9],
    ],
    [
      [14052, 15865],
      [-16, -4],
      [-41, -19],
      [-3, -24],
      [-9, 1],
    ],
    [
      [15892, 14393],
      [14, -53],
      [-6, -27],
      [23, -90],
    ],
    [
      [15923, 14223],
      [-50, -4],
      [-17, 58],
      [-62, 11],
    ],
    [
      [19670, 13492],
      [40, -94],
      [32, -109],
      [85, -1],
      [28, -105],
      [-45, -31],
      [-20, -44],
      [83, -71],
      [58, -142],
      [44, -106],
      [53, -83],
      [18, -85],
      [-13, -120],
    ],
    [
      [19882, 12510],
      [23, 54],
      [3, 101],
      [-57, 105],
      [-4, 118],
      [-53, 98],
      [-53, 8],
      [-14, -42],
      [-40, -3],
      [-21, 21],
      [-74, -72],
      [-1, 108],
      [17, 126],
      [-47, 6],
      [-4, 72],
      [-31, 37],
    ],
    [
      [19526, 13247],
      [15, 44],
      [60, 78],
    ],
    [
      [14996, 14767],
      [25, 98],
      [35, 84],
      [1, 5],
    ],
    [
      [15057, 14954],
      [31, -7],
      [12, -47],
      [-38, -45],
      [-17, -66],
    ],
    [
      [12010, 11321],
      [-18, -1],
      [-72, 57],
      [-64, 91],
      [-59, 66],
      [-47, 77],
    ],
    [
      [11750, 11611],
      [17, 39],
      [3, 35],
      [32, 65],
      [32, 56],
    ],
    [
      [13208, 14433],
      [34, 28],
      [7, 51],
      [-8, 49],
      [48, 47],
      [21, 38],
      [34, 34],
      [4, 93],
    ],
    [
      [13348, 14773],
      [82, -42],
      [30, 11],
      [58, -20],
      [92, -54],
      [33, -107],
      [62, -23],
      [99, -50],
      [74, -60],
      [34, 31],
      [33, 56],
      [-16, 91],
      [22, 59],
      [50, 56],
      [48, 16],
      [95, -24],
      [23, -54],
      [26, 0],
      [22, -21],
      [70, -14],
      [17, -39],
    ],
    [
      [14290, 13437],
      [0, -240],
      [-80, 0],
      [-1, -51],
    ],
    [
      [14209, 13146],
      [-278, 230],
      [-278, 230],
      [-70, -66],
    ],
    [
      [13583, 13540],
      [-50, -45],
      [-39, 66],
      [-110, 52],
    ],
    [
      [18249, 11700],
      [-11, -125],
      [-29, -34],
      [-61, -28],
      [-33, 96],
      [-12, 172],
      [31, 195],
      [49, -67],
      [32, -84],
      [34, -125],
    ],
    [
      [14568, 7323],
      [24, -36],
      [-22, -58],
      [-12, -39],
      [-38, -19],
      [-13, -38],
      [-25, -12],
      [-52, 92],
      [37, 76],
      [38, 47],
      [32, 24],
      [31, -37],
    ],
    [
      [14185, 17265],
      [-17, 37],
      [-36, 13],
    ],
    [
      [14132, 17315],
      [-6, 30],
      [8, 33],
      [-31, 19],
      [-73, 21],
    ],
    [
      [14030, 17418],
      [-15, 101],
    ],
    [
      [14015, 17519],
      [80, 37],
      [117, -8],
      [68, 12],
      [10, -25],
      [37, -8],
      [67, -58],
    ],
    [
      [14015, 17519],
      [3, 90],
      [34, 76],
      [66, 41],
      [55, -90],
      [56, 2],
      [13, 93],
    ],
    [
      [14450, 17692],
      [33, -27],
      [6, -58],
      [23, -71],
    ],
    [
      [11943, 14115],
      [-10, 0],
      [1, -64],
      [-43, -4],
      [-22, -27],
      [-32, 0],
      [-25, 15],
      [-59, -13],
      [-22, -93],
      [-22, -9],
      [-33, -151],
      [-97, -130],
      [-23, -165],
      [-28, -54],
      [-9, -43],
      [-157, -10],
      [-1, 0],
    ],
    [
      [11361, 13367],
      [3, 56],
      [27, 32],
      [23, 63],
      [-5, 41],
      [24, 84],
      [39, 77],
      [24, 19],
      [18, 70],
      [2, 64],
      [25, 74],
      [46, 44],
      [45, 122],
      [1, 2],
      [35, 46],
      [65, 13],
      [55, 82],
      [35, 32],
      [58, 100],
      [-18, 150],
      [27, 103],
      [9, 63],
      [45, 81],
      [70, 55],
      [52, 49],
      [46, 125],
      [22, 73],
      [51, 0],
      [42, -51],
      [67, 8],
      [72, -26],
      [30, -2],
    ],
    [
      [14403, 16582],
      [17, 18],
      [46, 12],
      [51, -38],
      [29, -4],
      [32, -32],
      [-5, -41],
      [25, -20],
      [10, -50],
      [24, -30],
      [-5, -18],
      [13, -12],
      [-18, -9],
      [-41, 3],
      [-7, 17],
      [-15, -10],
      [5, -21],
      [-19, -38],
      [-12, -42],
      [-17, -13],
    ],
    [
      [14516, 16254],
      [-13, 55],
      [7, 51],
      [-2, 53],
      [-40, 71],
      [-22, 51],
      [-22, 35],
      [-21, 12],
    ],
    [
      [16001, 9301],
      [19, -51],
      [17, -79],
      [11, -144],
      [18, -57],
      [-7, -57],
      [-12, -35],
      [-24, 70],
      [-13, -36],
      [13, -88],
      [-6, -51],
      [-19, -28],
      [-4, -102],
      [-28, -139],
      [-34, -166],
      [-43, -227],
      [-27, -167],
      [-32, -139],
      [-56, -28],
      [-61, -51],
      [-40, 30],
      [-56, 43],
      [-19, 64],
      [-4, 106],
      [-25, 96],
      [-6, 86],
      [12, 86],
      [32, 21],
      [0, 40],
      [34, 91],
      [6, 77],
      [-16, 56],
      [-13, 76],
      [-6, 111],
      [24, 67],
      [10, 76],
      [35, 4],
      [38, 25],
      [26, 21],
      [31, 2],
      [40, 68],
      [57, 74],
      [21, 61],
      [-10, 51],
      [30, -14],
      [38, 83],
      [2, 72],
      [23, 54],
      [24, -52],
    ],
    [
      [6118, 12541],
      [-78, 130],
      [-36, 39],
      [-57, 31],
      [-39, -9],
      [-56, -45],
      [-35, -12],
      [-50, 32],
      [-52, 23],
      [-65, 55],
      [-52, 16],
      [-79, 56],
      [-58, 58],
      [-18, 32],
      [-39, 7],
      [-71, 38],
      [-29, 54],
      [-75, 69],
      [-35, 75],
      [-17, 59],
      [23, 11],
      [-7, 35],
      [16, 31],
      [1, 41],
      [-24, 54],
      [-6, 48],
      [-24, 60],
      [-61, 120],
      [-70, 93],
      [-34, 75],
      [-60, 49],
      [-13, 29],
      [11, 75],
      [-36, 28],
      [-41, 58],
      [-17, 84],
      [-38, 9],
      [-40, 63],
      [-33, 59],
      [-3, 37],
      [-37, 91],
      [-25, 92],
      [1, 46],
      [-50, 47],
      [-24, -5],
      [-39, 33],
      [-12, -49],
      [12, -57],
      [7, -90],
      [24, -50],
      [51, -82],
      [12, -29],
      [10, -8],
      [10, -41],
      [12, 1],
      [14, -77],
      [21, -31],
      [15, -42],
      [44, -61],
      [23, -112],
      [21, -52],
      [19, -56],
      [4, -64],
      [34, -4],
      [27, -54],
      [26, -54],
      [-2, -21],
      [-29, -44],
      [-13, 0],
      [-18, 73],
      [-46, 69],
      [-50, 58],
      [-36, 30],
      [3, 88],
      [-11, 65],
      [-33, 37],
      [-48, 54],
      [-9, -16],
      [-18, 31],
      [-43, 29],
      [-41, 70],
      [5, 9],
      [29, -7],
      [26, 45],
      [2, 54],
      [-53, 86],
      [-41, 33],
      [-26, 75],
      [-26, 79],
      [-32, 95],
      [-28, 108],
    ],
    [
      [4383, 14700],
      [79, 10],
      [88, 13],
      [-6, -24],
      [105, -58],
      [159, -85],
      [139, 1],
      [55, 0],
      [0, 50],
      [121, 0],
      [25, -43],
      [36, -38],
      [42, -52],
      [23, -63],
      [17, -66],
      [36, -36],
      [58, -36],
      [44, 94],
      [57, 3],
      [49, -48],
      [35, -82],
      [24, -70],
      [41, -69],
      [15, -84],
      [20, -56],
      [54, -37],
      [50, -27],
      [27, 4],
    ],
    [
      [5776, 13901],
      [-27, -106],
      [-12, -86],
      [-5, -161],
      [-7, -58],
      [12, -66],
      [22, -58],
      [14, -93],
      [46, -90],
      [16, -68],
      [27, -59],
      [74, -32],
      [29, -50],
      [61, 33],
      [54, 13],
      [52, 21],
      [44, 21],
      [44, 49],
      [17, 70],
      [5, 100],
      [12, 36],
      [48, 31],
      [73, 28],
      [62, -4],
      [42, 10],
      [17, -26],
      [-2, -57],
      [-38, -72],
      [-16, -73],
      [12, -21],
      [-10, -52],
      [-17, -93],
      [-18, 31],
      [-15, -2],
    ],
    [
      [14052, 15865],
      [23, 7],
      [33, 2],
    ],
    [
      [11745, 12290],
      [3, 37],
      [-6, 47],
      [-26, 33],
      [-14, 69],
      [-3, 75],
    ],
    [
      [11699, 12551],
      [24, 22],
      [11, 70],
      [22, 3],
      [49, -33],
      [39, 23],
      [27, -8],
      [11, 27],
      [279, 2],
      [16, 84],
      [-12, 15],
      [-34, 517],
      [-33, 518],
      [106, 2],
    ],
    [
      [12845, 13095],
      [0, -276],
      [-38, -80],
      [-6, -74],
      [-62, -19],
      [-95, -10],
      [-26, -43],
      [-44, -5],
    ],
    [
      [19526, 13247],
      [-40, -28],
      [-40, -52],
      [-49, -5],
      [-32, -130],
      [-30, -22],
      [34, -105],
      [44, -88],
      [29, -79],
      [-26, -104],
      [-24, -22],
      [17, -61],
      [46, -95],
      [8, -67],
      [-1, -56],
      [28, -109],
      [-39, -112],
      [-33, -123],
    ],
    [
      [19418, 11989],
      [-7, 89],
      [21, 92],
      [-23, 71],
      [5, 130],
      [-28, 63],
      [-23, 143],
      [-12, 152],
      [-30, 99],
      [-46, -60],
      [-79, -86],
      [-40, 11],
      [-43, 28],
      [24, 149],
      [-14, 112],
      [-55, 139],
      [9, 43],
      [-41, 15],
      [-50, 98],
    ],
    [
      [13898, 15821],
      [-15, 9],
      [-19, 40],
      [-30, 23],
    ],
    [
      [13887, 16019],
      [19, -21],
      [10, -17],
      [23, -12],
      [26, -25],
      [-5, -11],
    ],
    [
      [18664, 16711],
      [74, 21],
      [133, 103],
      [106, 57],
      [61, -37],
      [72, -2],
      [47, -56],
      [70, -4],
      [100, -30],
      [68, 83],
      [-28, 71],
      [72, 124],
      [78, -49],
      [63, -14],
      [82, -31],
      [14, -90],
      [99, -51],
      [65, 23],
      [89, 15],
      [70, -15],
      [68, -58],
      [42, -61],
      [65, 1],
      [88, -20],
      [64, 30],
      [91, 20],
      [103, 84],
      [41, -13],
      [37, -40],
      [83, 10],
    ],
    [
      [14957, 9415],
      [52, 10],
      [84, -34],
      [18, 15],
      [49, 3],
      [24, 36],
      [42, -2],
      [76, 47],
      [56, 69],
    ],
    [
      [15358, 9559],
      [11, -53],
      [-3, -120],
      [9, -105],
      [3, -188],
      [12, -58],
      [-21, -86],
      [-27, -83],
      [-44, -75],
      [-64, -45],
      [-79, -59],
      [-78, -128],
      [-27, -22],
      [-49, -86],
      [-29, -27],
      [-5, -86],
      [33, -91],
      [13, -70],
      [1, -36],
      [13, 6],
      [-2, -118],
      [-12, -55],
      [17, -21],
      [-11, -50],
      [-29, -42],
      [-57, -41],
      [-84, -65],
      [-31, -44],
      [6, -51],
      [18, -8],
      [-6, -63],
    ],
    [
      [14836, 7589],
      [-53, 1],
    ],
    [
      [14783, 7590],
      [-6, 53],
      [-10, 54],
    ],
    [
      [14767, 7697],
      [-6, 43],
      [12, 134],
      [-18, 85],
      [-33, 169],
    ],
    [
      [14722, 8128],
      [73, 136],
      [19, 86],
      [10, 11],
      [8, 71],
      [-11, 35],
      [3, 90],
      [13, 83],
      [0, 152],
      [-36, 39],
      [-33, 8],
      [-15, 30],
      [-32, 25],
      [-59, -2],
      [-4, 45],
    ],
    [
      [14658, 8937],
      [-7, 85],
      [212, 99],
    ],
    [
      [14863, 9121],
      [40, -58],
      [19, 11],
      [28, -30],
      [4, -48],
      [-15, -56],
      [5, -84],
      [46, -74],
      [21, 83],
      [30, 25],
      [-6, 154],
      [-29, 87],
      [-25, 39],
      [-24, -2],
      [-20, 156],
      [20, 91],
    ],
    [
      [11699, 12551],
      [-46, 82],
      [-42, 88],
      [-46, 32],
      [-34, 35],
      [-39, -1],
      [-34, -26],
      [-34, 10],
      [-24, -38],
    ],
    [
      [11400, 12733],
      [-6, 65],
      [19, 59],
      [9, 113],
      [-8, 118],
      [-8, 60],
      [7, 60],
      [-18, 57],
      [-37, 52],
    ],
    [
      [11358, 13317],
      [15, 40],
      [273, -1],
      [-13, 173],
      [17, 62],
      [65, 10],
      [-2, 307],
      [229, -6],
      [0, 182],
    ],
    [
      [14863, 9121],
      [-37, 31],
      [21, 112],
      [22, 41],
      [-13, 100],
      [14, 97],
      [12, 32],
      [-18, 102],
      [-33, 54],
    ],
    [
      [14831, 9690],
      [68, -23],
      [14, -33],
      [24, -56],
      [20, -163],
    ],
    [
      [20595, 11451],
      [54, 83],
      [35, 94],
      [28, 0],
      [36, -60],
      [3, -52],
      [46, -34],
      [58, -36],
      [-4, -47],
      [-47, -6],
      [12, -59],
      [-51, -40],
    ],
    [
      [20192, 11038],
      [51, -41],
      [54, 22],
      [14, 102],
      [30, 22],
      [83, 26],
      [50, 95],
      [34, 76],
    ],
    [
      [19668, 11544],
      [16, -12],
      [41, -72],
      [29, -80],
      [4, -81],
      [-7, -55],
      [6, -41],
      [5, -71],
      [25, -33],
      [27, -106],
      [-1, -41],
      [-49, -8],
      [-66, 89],
      [-83, 95],
      [-8, 62],
      [-40, 80],
      [-10, 99],
      [-25, 66],
      [8, 87],
      [-16, 51],
    ],
    [
      [19524, 11573],
      [12, 21],
      [57, -52],
      [6, -62],
      [46, 14],
      [23, 50],
    ],
    [
      [14166, 8695],
      [57, 27],
      [45, -7],
      [28, -27],
      [0, -10],
    ],
    [
      [13934, 7826],
      [0, -443],
      [-62, -62],
      [-37, -8],
      [-44, 22],
      [-31, 9],
      [-12, 51],
      [-28, 33],
      [-33, -59],
    ],
    [
      [13687, 7369],
      [-52, 91],
      [-27, 87],
      [-16, 117],
      [-17, 87],
      [-23, 185],
      [-2, 143],
      [-9, 66],
      [-27, 49],
      [-36, 99],
      [-36, 144],
      [-16, 75],
      [-56, 117],
      [-5, 93],
    ],
    [
      [24104, 8268],
      [57, -74],
      [36, -55],
      [-26, -29],
      [-39, 32],
      [-50, 54],
      [-44, 64],
      [-47, 84],
      [-9, 41],
      [30, -2],
      [39, -40],
      [30, -41],
      [23, -34],
    ],
    [
      [13583, 13540],
      [17, -186],
      [26, -32],
      [1, -38],
      [29, -41],
      [-15, -52],
      [-27, -243],
      [-4, -156],
      [-89, -113],
      [-30, -158],
      [29, -45],
      [0, -77],
      [45, -3],
      [-7, -56],
    ],
    [
      [13536, 12295],
      [-13, -3],
      [-47, 132],
      [-16, 4],
      [-55, -67],
      [-54, 35],
      [-37, 7],
      [-21, -17],
      [-40, 4],
      [-42, -51],
      [-35, -3],
      [-84, 62],
      [-33, -29],
      [-36, 2],
      [-26, 45],
      [-70, 45],
      [-75, -15],
      [-18, -25],
      [-10, -69],
      [-20, -49],
      [-5, -107],
    ],
    [
      [13140, 11370],
      [-72, -43],
      [-27, 6],
      [-27, -27],
      [-55, 3],
      [-38, 75],
      [-23, 86],
      [-49, 79],
      [-52, -1],
      [-62, 0],
    ],
    [
      [6573, 12127],
      [-24, 38],
      [-33, 49],
      [-15, 40],
      [-30, 38],
      [-35, 54],
      [8, 19],
      [12, -19],
      [5, 9],
    ],
    [
      [6751, 12596],
      [-6, -11],
      [-3, -27],
      [7, -44],
      [-16, -41],
      [-8, -48],
      [-2, -53],
      [4, -31],
      [2, -54],
      [-11, -12],
      [-6, -51],
      [4, -32],
      [-14, -30],
      [3, -33],
      [11, -19],
    ],
    [
      [12779, 16957],
      [36, 33],
      [61, 177],
      [95, 50],
      [58, -4],
    ],
    [
      [13987, 19088],
      [-44, -5],
      [-10, -79],
      [-131, 19],
      [-19, -67],
      [-67, 1],
      [-46, -86],
      [-69, -133],
      [-109, -168],
      [26, -41],
      [-24, -48],
      [-70, 2],
      [-45, -112],
      [4, -160],
      [45, -60],
      [-23, -142],
      [-58, -82],
      [-31, -69],
    ],
    [
      [13316, 17858],
      [-47, 74],
      [-137, -139],
      [-93, -28],
      [-97, 61],
      [-24, 129],
      [-23, 277],
      [65, 77],
      [184, 101],
      [137, 124],
      [128, 167],
      [167, 231],
      [117, 91],
      [192, 150],
      [153, 53],
      [114, -7],
      [107, 100],
      [127, -6],
      [125, 24],
      [218, -88],
      [-90, -32],
      [77, -75],
    ],
    [
      [14716, 19142],
      [-119, -48],
      [-56, -11],
    ],
    [
      [14271, 20137],
      [-156, -49],
      [-123, 28],
      [48, 31],
      [-42, 38],
      [145, 24],
      [27, -45],
      [101, -27],
    ],
    [
      [13820, 20359],
      [229, -90],
      [-175, -47],
      [-39, -88],
      [-61, -23],
      [-33, -99],
      [-84, -5],
      [-150, 73],
      [63, 43],
      [-104, 35],
      [-136, 101],
      [-54, 94],
      [190, 43],
      [38, -42],
      [99, 2],
      [27, 41],
      [102, 4],
      [88, -42],
    ],
    [
      [14321, 20444],
      [137, -43],
      [-103, -64],
      [-203, -14],
      [-205, 20],
      [-12, 33],
      [-101, 2],
      [-76, 55],
      [215, 33],
      [102, -28],
      [70, 36],
      [176, -30],
    ],
    [
      [24608, 5888],
      [16, -49],
      [50, 48],
      [20, -50],
      [0, -51],
      [-26, -55],
      [-45, -89],
      [-36, -48],
      [26, -58],
      [-54, -1],
      [-60, -46],
      [-18, -78],
      [-40, -121],
      [-55, -54],
      [-35, -34],
      [-64, 2],
      [-45, 40],
      [-76, 8],
      [-11, 44],
      [37, 89],
      [88, 119],
      [45, 22],
      [50, 46],
      [60, 63],
      [41, 62],
      [31, 89],
      [27, 31],
      [10, 67],
      [49, 55],
      [15, -51],
    ],
    [
      [24719, 6460],
      [51, -127],
      [1, 82],
      [32, -33],
      [10, -90],
      [56, -39],
      [47, -10],
      [40, 46],
      [36, -14],
      [-17, -107],
      [-21, -70],
      [-54, 3],
      [-18, -37],
      [6, -51],
      [-10, -22],
      [-26, -65],
      [-35, -82],
      [-54, -48],
      [-12, 31],
      [-29, 18],
      [40, 98],
      [-23, 66],
      [-75, 48],
      [2, 44],
      [51, 42],
      [12, 92],
      [-4, 78],
      [-28, 80],
      [2, 21],
      [-33, 50],
      [-55, 106],
      [-29, 85],
      [26, 9],
      [37, -66],
      [55, -32],
      [19, -106],
    ],
    [
      [16456, 13923],
      [20, 41],
      [9, -11],
      [-7, -49],
      [-9, -22],
    ],
    [
      [16479, 13787],
      [31, -82],
      [39, -43],
      [51, -16],
      [41, -22],
      [32, -68],
      [19, -40],
      [25, -15],
      [-1, -27],
      [-25, -72],
      [-11, -33],
      [-29, -39],
      [-26, -82],
      [-32, 6],
      [-15, -28],
      [-11, -61],
      [9, -80],
      [-7, -15],
      [-32, 0],
      [-43, -44],
      [-7, -59],
      [-16, -25],
      [-43, 1],
      [-28, -30],
      [1, -49],
      [-34, -33],
      [-39, 11],
      [-46, -40],
      [-32, -7],
    ],
    [
      [16250, 12795],
      [-23, 84],
      [-55, 198],
    ],
    [
      [16172, 13077],
      [209, 120],
      [47, 240],
      [-32, 84],
    ],
    [
      [17300, 13639],
      [-51, 31],
      [-21, 86],
      [-54, 91],
      [-128, -22],
      [-113, -2],
      [-99, -17],
    ],
    [
      [7119, 11664],
      [-24, 34],
      [-15, 65],
      [18, 32],
      [-18, 8],
      [-13, 40],
      [-35, 33],
      [-30, -7],
      [-14, -42],
      [-29, -30],
      [-15, -4],
      [-7, -25],
      [34, -65],
      [-19, -16],
      [-11, -17],
      [-32, -7],
      [-12, 72],
      [-9, -20],
      [-23, 7],
      [-14, 48],
      [-29, 8],
      [-18, 14],
      [-30, 0],
      [-2, -26],
      [-8, 18],
    ],
    [
      [6793, 11945],
      [25, -43],
      [-1, -26],
      [28, -5],
      [6, 10],
      [20, -30],
      [34, 9],
      [29, 30],
      [43, 24],
      [24, 36],
      [38, -7],
      [-3, -12],
      [39, -4],
      [31, -20],
      [23, -36],
      [26, -34],
    ],
    [
      [7642, 8596],
      [-70, 69],
      [-6, 49],
      [-138, 121],
      [-125, 131],
      [-54, 74],
      [-29, 99],
      [12, 34],
      [-59, 158],
      [-69, 221],
      [-66, 239],
      [-29, 55],
      [-21, 88],
      [-55, 78],
      [-49, 49],
      [22, 54],
      [-34, 114],
      [22, 84],
      [56, 76],
    ],
    [
      [21357, 11807],
      [7, -80],
      [4, -67],
      [-24, -110],
      [-25, 122],
      [-33, -61],
      [23, -88],
      [-20, -56],
      [-82, 69],
      [-20, 87],
      [21, 57],
      [-44, 57],
      [-22, -50],
      [-33, 5],
      [-51, -67],
      [-12, 35],
      [28, 101],
      [44, 34],
      [38, 45],
      [24, -54],
      [53, 33],
      [12, 53],
      [49, 3],
      [-4, 93],
      [56, -57],
      [6, -60],
      [5, -44],
    ],
    [
      [21190, 12030],
      [-25, -39],
      [-22, -76],
      [-22, -35],
      [-43, 82],
      [15, 33],
      [17, 33],
      [8, 75],
      [38, 7],
      [-11, -81],
      [52, 116],
      [-7, -115],
    ],
    [
      [20808, 11915],
      [-92, -114],
      [34, 84],
      [50, 74],
      [42, 83],
      [36, 119],
      [13, -98],
      [-46, -66],
      [-37, -82],
    ],
    [
      [21044, 12224],
      [42, -37],
      [44, 0],
      [-1, -50],
      [-33, -51],
      [-44, -36],
      [-2, 56],
      [5, 61],
      [-11, 57],
    ],
    [
      [21296, 12256],
      [20, -134],
      [-54, 32],
      [1, -40],
      [17, -74],
      [-33, -27],
      [-3, 84],
      [-21, 7],
      [-11, 72],
      [41, -9],
      [0, 45],
      [-43, 92],
      [67, -3],
      [19, -45],
    ],
    [
      [21019, 12365],
      [-19, -104],
      [-29, 60],
      [-36, 92],
      [60, -5],
      [24, -43],
    ],
    [
      [21005, 13017],
      [43, -34],
      [21, 31],
      [6, -30],
      [-11, -50],
      [24, -86],
      [-18, -100],
      [-42, -40],
      [-11, -96],
      [16, -96],
      [37, -13],
      [31, 14],
      [87, -66],
      [-7, -66],
      [23, -29],
      [-7, -55],
      [-55, 59],
      [-25, 63],
      [-18, -44],
      [-45, 72],
      [-63, -18],
      [-35, 27],
      [4, 49],
      [22, 31],
      [-21, 28],
      [-9, -44],
      [-35, 69],
      [-10, 52],
      [-3, 115],
      [28, -39],
      [8, 188],
      [22, 108],
      [43, 0],
    ],
    [
      [22376, 10485],
      [121, -82],
      [129, -69],
      [48, -62],
      [39, -60],
      [11, -71],
      [116, -74],
      [17, -63],
      [-64, -13],
      [15, -80],
      [62, -79],
      [46, -127],
      [39, 4],
      [-2, -53],
      [53, -21],
      [-20, -22],
      [74, -51],
      [-8, -34],
      [-46, -9],
      [-17, 31],
      [-60, 14],
      [-71, 18],
      [-54, 76],
      [-39, 66],
      [-37, 105],
      [-91, 53],
      [-59, -34],
      [-42, -40],
      [9, -88],
      [-55, -42],
      [-39, 20],
      [-72, 5],
    ],
    [
      [23414, 9979],
      [-20, -12],
      [-30, 46],
      [-31, 76],
      [-15, 92],
      [10, 11],
      [8, -35],
      [21, -28],
      [33, -76],
      [33, -40],
      [-9, -34],
    ],
    [
      [23142, 10140],
      [-37, -10],
      [-11, -34],
      [-38, -29],
      [-35, -28],
      [-37, 0],
      [-58, 35],
      [-39, 34],
      [5, 37],
      [63, -18],
      [38, 10],
      [10, 57],
      [10, 3],
      [7, -64],
      [40, 10],
      [20, 41],
      [39, 42],
      [-8, 71],
      [42, 2],
      [14, -19],
      [-2, -67],
      [-23, -73],
    ],
    [
      [23223, 10257],
      [-22, -32],
      [-13, 71],
      [-17, 47],
      [-31, 39],
      [-40, 51],
      [-50, 35],
      [19, 29],
      [38, -33],
      [24, -27],
      [29, -29],
      [28, -50],
      [26, -38],
      [9, -63],
    ],
    [
      [14188, 16985],
      [35, -105],
      [-8, -33],
      [-34, -14],
      [-64, -100],
      [18, -54],
      [-15, 7],
    ],
    [
      [14120, 16686],
      [-66, 46],
      [-50, -17],
      [-33, 12],
      [-42, -25],
      [-35, 42],
      [-28, -16],
      [-4, 7],
    ],
    [
      [13532, 17246],
      [47, 36],
      [109, 55],
      [88, 41],
      [70, -21],
      [5, -29],
      [67, -1],
    ],
    [
      [13918, 17327],
      [86, -14],
      [128, 2],
    ],
    [
      [7927, 13018],
      [36, -10],
      [12, -24],
      [-18, -30],
      [-52, 0],
      [-41, -4],
      [-4, 52],
      [10, 17],
      [57, -1],
    ],
    [
      [21654, 15883],
      [10, -21],
    ],
    [
      [21664, 15862],
      [-27, 7],
      [-30, -40],
      [-21, -41],
      [3, -86],
      [-36, -27],
      [-12, -21],
      [-27, -35],
      [-46, -20],
      [-30, -32],
      [-3, -52],
      [-8, -13],
      [28, -20],
      [40, -53],
    ],
    [
      [21343, 15326],
      [-34, 23],
      [-8, -23],
      [-21, -10],
      [-2, 23],
      [-18, 11],
      [-19, 19],
      [19, 53],
      [17, 14],
      [-7, 22],
      [18, 65],
      [-5, 19],
      [-40, 13],
      [-33, 32],
    ],
    [
      [12028, 15248],
      [-28, -31],
      [-37, 17],
      [-36, -14],
      [11, 94],
      [-7, 74],
      [-31, 11],
      [-17, 45],
      [6, 79],
      [28, 44],
      [5, 48],
      [14, 72],
      [-1, 51],
      [-14, 43],
      [-3, 41],
    ],
    [
      [16089, 13767],
      [-4, 87],
      [19, 63],
      [19, 13],
      [21, -37],
      [1, -71],
      [-15, -70],
    ],
    [
      [16130, 13752],
      [-20, -9],
      [-21, 24],
    ],
    [
      [14127, 16104],
      [-13, 21],
      [16, 20],
      [-17, 15],
      [-22, -27],
      [-40, 35],
      [-6, 50],
      [-42, 28],
      [-8, 38],
      [-38, 47],
    ],
    [
      [14131, 16542],
      [30, 25],
      [43, -13],
      [45, 0],
      [32, -30],
      [24, 19],
      [51, 11],
      [18, 28],
      [29, 0],
    ],
    [
      [14516, 16254],
      [31, -22],
      [32, 20],
      [32, -21],
    ],
    [
      [14611, 16231],
      [2, -31],
      [-34, -26],
      [-21, 11],
      [-20, -144],
    ],
    [
      [15333, 16008],
      [-89, 101],
      [-80, 46],
      [-60, 70],
      [51, 19],
      [58, 101],
      [-39, 47],
      [102, 49],
      [-1, 26],
      [-63, -19],
    ],
    [
      [15212, 16448],
      [2, 53],
      [36, 34],
      [68, 9],
      [11, 40],
      [-16, 66],
      [28, 63],
      [0, 35],
      [-103, 39],
      [-41, -1],
      [-43, 56],
      [-53, -19],
      [-89, 42],
      [2, 23],
      [-25, 53],
      [-56, 5],
      [-6, 38],
      [18, 24],
      [-45, 68],
      [-72, -12],
      [-21, 6],
      [-18, -27],
      [-26, 5],
    ],
    [
      [14498, 17932],
      [79, 67],
      [-73, 57],
    ],
    [
      [14716, 19142],
      [71, 42],
      [115, -73],
      [191, -28],
      [263, -136],
      [54, -57],
      [4, -80],
      [-77, -63],
      [-114, -32],
      [-311, 91],
      [-51, -15],
      [113, -88],
      [5, -56],
      [4, -122],
      [90, -37],
      [55, -31],
      [9, 58],
      [-42, 52],
      [44, 45],
      [168, -74],
      [59, 29],
      [-47, 88],
      [163, 117],
      [64, -7],
      [65, -42],
      [41, 83],
      [-58, 71],
      [34, 72],
      [-51, 75],
      [195, -39],
      [39, -67],
      [-88, -15],
      [1, -67],
      [54, -41],
      [108, 26],
      [17, 77],
      [146, 57],
      [243, 103],
      [53, -6],
      [-69, -73],
      [86, -12],
      [50, 41],
      [131, 3],
      [103, 50],
      [80, -73],
      [79, 80],
      [-73, 69],
      [36, 40],
      [206, -36],
      [97, -38],
      [252, -137],
      [47, 63],
      [-71, 63],
      [-2, 26],
      [-84, 12],
      [23, 56],
      [-37, 94],
      [-2, 38],
      [128, 109],
      [46, 109],
      [52, 24],
      [184, -32],
      [15, -67],
      [-66, -97],
      [43, -38],
      [23, -84],
      [-16, -164],
      [77, -74],
      [-30, -80],
      [-137, -170],
      [80, -18],
      [28, 43],
      [76, 31],
      [19, 59],
      [60, 57],
      [-40, 69],
      [32, 79],
      [-76, 10],
      [-17, 66],
      [56, 121],
      [-91, 98],
      [125, 80],
      [-16, 86],
      [35, 3],
      [36, -67],
      [-27, -116],
      [74, -22],
      [-31, 87],
      [116, 47],
      [145, 6],
      [129, -68],
      [-62, 100],
      [-7, 128],
      [121, 24],
      [168, -5],
      [151, 15],
      [-57, 63],
      [81, 79],
      [80, 3],
      [135, 60],
      [184, 16],
      [24, 32],
      [183, 12],
      [57, -27],
      [156, 63],
      [128, -2],
      [20, 52],
      [66, 51],
      [165, 50],
      [119, -39],
      [-95, -30],
      [158, -18],
      [19, -60],
      [64, 30],
      [204, -2],
      [157, -59],
      [56, -44],
      [-18, -63],
      [-77, -35],
      [-183, -67],
      [-52, -36],
      [86, -16],
      [103, -31],
      [63, 23],
      [35, -77],
      [31, 31],
      [112, 19],
      [223, -20],
      [17, -56],
      [292, -18],
      [4, 92],
      [148, -21],
      [111, 1],
      [112, -63],
      [32, -77],
      [-41, -50],
      [88, -95],
      [109, -49],
      [68, 126],
      [111, -54],
      [119, 33],
      [135, -37],
      [52, 33],
      [114, -16],
      [-51, 111],
      [92, 52],
      [630, -78],
      [59, -71],
      [183, -92],
      [281, 23],
      [139, -20],
      [58, -50],
      [-8, -87],
      [85, -34],
      [94, 24],
      [123, 3],
      [132, -23],
      [132, 13],
      [121, -107],
      [87, 39],
      [-57, 76],
      [32, 54],
      [222, -34],
      [145, 7],
      [200, -57],
      [98, -52],
      [0, -478],
      [-1, -1],
      [-89, -53],
      [-90, 9],
      [62, -64],
      [42, -99],
      [32, -32],
      [8, -49],
      [-18, -32],
      [-130, 26],
      [-195, -90],
      [-62, -14],
      [-106, -85],
      [-101, -73],
      [-26, -55],
      [-100, 83],
      [-181, -94],
      [-32, 45],
      [-67, -52],
      [-93, 17],
      [-23, -79],
      [-84, -116],
      [3, -49],
      [79, -27],
      [-9, -174],
      [-65, -5],
      [-30, -100],
      [29, -52],
      [-121, -61],
      [-25, -137],
      [-104, -29],
      [-20, -122],
      [-101, -112],
      [-26, 83],
      [-30, 175],
      [-38, 266],
      [33, 167],
      [59, 71],
      [3, 56],
      [109, 27],
      [124, 151],
      [120, 123],
      [126, 96],
      [56, 169],
      [-85, -10],
      [-42, -99],
      [-177, -131],
      [-57, 147],
      [-180, -41],
      [-174, -201],
      [57, -73],
      [-155, -32],
      [-108, -12],
      [5, 87],
      [-108, 18],
      [-87, -59],
      [-213, 21],
      [-229, -36],
      [-226, -234],
      [-267, -283],
      [110, -15],
      [34, -75],
      [68, -27],
      [44, 60],
      [77, -8],
      [100, -132],
      [3, -102],
      [-55, -120],
      [-6, -143],
      [-31, -192],
      [-105, -173],
      [-23, -83],
      [-95, -140],
      [-94, -138],
      [-45, -71],
      [-93, -71],
      [-44, -1],
      [-44, 58],
      [-93, -88],
      [-11, -40],
    ],
    [
      [15970, 16364],
      [-32, -71],
      [-67, -20],
      [-69, -124],
      [63, -114],
      [-7, -81],
      [76, -141],
    ],
    [
      [13918, 17327],
      [16, 52],
      [96, 39],
    ],
    [
      [14878, 16312],
      [19, 30],
      [49, -26],
      [23, -4],
      [9, -24],
      [10, -4],
    ],
    [
      [14988, 16284],
      [1, -10],
      [34, -29],
      [71, 7],
      [-14, -43],
      [-76, -20],
      [-95, -70],
      [-38, 25],
      [15, 56],
      [-76, 35],
      [12, 23],
      [67, 40],
      [-11, 14],
    ],
    [
      [22561, 16885],
      [70, -212],
      [-103, 39],
      [-43, -173],
      [68, -123],
      [-2, -84],
      [-53, 73],
      [-46, -93],
      [-12, 100],
      [7, 117],
      [-8, 130],
      [17, 90],
      [3, 161],
      [-41, 118],
      [6, 164],
      [64, 55],
      [-27, 56],
      [31, 16],
      [18, -79],
      [24, -116],
      [-2, -118],
      [29, -121],
    ],
    [
      [348, 18785],
      [47, -30],
      [-17, 88],
      [190, -18],
      [136, -113],
      [-69, -52],
      [-114, -12],
      [-2, -118],
      [-28, -24],
      [-65, 3],
      [-53, 42],
      [-93, 35],
      [-16, 52],
      [-70, 20],
      [-80, -16],
      [-38, 42],
      [16, 45],
      [-84, -29],
      [32, -56],
      [-40, -51],
      [0, 478],
      [171, -92],
      [183, -119],
      [-6, -75],
    ],
    [
      [25095, 19295],
      [-76, -6],
      [-13, 38],
      [89, 50],
      [0, -82],
    ],
    [
      [91, 19302],
      [-91, -7],
      [0, 82],
      [9, 5],
      [59, 0],
      [101, -35],
      [-6, -16],
      [-72, -29],
    ],
    [
      [22558, 19580],
      [-106, 0],
      [-143, 13],
      [-12, 6],
      [66, 48],
      [87, 11],
      [99, -46],
      [9, -32],
    ],
    [
      [23055, 19805],
      [-81, -47],
      [-111, 10],
      [-130, 48],
      [17, 38],
      [130, -18],
      [175, -31],
    ],
    [
      [22661, 19862],
      [-55, -89],
      [-257, 4],
      [-115, -29],
      [-138, 78],
      [37, 83],
      [92, 22],
      [184, -5],
      [252, -64],
    ],
    [
      [16558, 19281],
      [-41, -10],
      [-228, 16],
      [-18, 53],
      [-126, 32],
      [-11, 65],
      [72, 25],
      [-3, 66],
      [139, 102],
      [-65, 15],
      [167, 105],
      [-18, 55],
      [155, 63],
      [231, 77],
      [232, 22],
      [119, 45],
      [136, 16],
      [48, -48],
      [-47, -37],
      [-247, -60],
      [-213, -57],
      [-216, -114],
      [-104, -117],
      [-109, -116],
      [14, -99],
      [133, -99],
    ],
    [
      [19872, 20192],
      [-393, -47],
      [128, 158],
      [57, 13],
      [52, -8],
      [177, -68],
      [-21, -48],
    ],
    [
      [16112, 20460],
      [-93, -15],
      [-63, -10],
      [-10, -19],
      [-81, -20],
      [-76, 28],
      [40, 38],
      [-155, 3],
      [136, 22],
      [106, 2],
      [14, -33],
      [40, 29],
      [66, 20],
      [103, -26],
      [-27, -19],
    ],
    [
      [19514, 20260],
      [-152, -15],
      [-194, 35],
      [-116, 46],
      [-53, 86],
      [-95, 24],
      [181, 82],
      [150, 27],
      [136, -61],
      [160, -116],
      [-17, -108],
    ],
    [
      [14609, 10636],
      [17, -12],
      [42, 37],
    ],
    [
      [14668, 10661],
      [28, -68],
      [-4, -70],
      [-21, -15],
    ],
    [
      [11358, 13317],
      [3, 50],
    ],
    [
      [16172, 13077],
      [-201, -46],
      [-65, -54],
      [-50, -126],
      [-32, -20],
      [-18, 40],
      [-26, -6],
      [-68, 12],
      [-13, 12],
      [-80, -3],
      [-19, -11],
      [-28, 31],
      [-19, -59],
      [7, -50],
      [-30, -39],
    ],
    [
      [15530, 12758],
      [-9, 52],
      [-21, 36],
      [-6, 48],
      [-36, 43],
      [-37, 100],
      [-20, 98],
      [-48, 83],
      [-31, 19],
      [-46, 115],
      [-8, 83],
      [3, 71],
      [-40, 133],
      [-33, 47],
      [-38, 25],
      [-22, 68],
      [3, 28],
      [-19, 62],
      [-20, 27],
      [-28, 89],
      [-42, 97],
      [-36, 82],
      [-34, -1],
      [10, 66],
      [4, 42],
      [8, 48],
    ],
    [
      [15923, 14223],
      [27, -104],
      [34, -27],
      [12, -42],
      [48, -51],
      [4, -49],
      [-7, -40],
      [9, -41],
      [20, -33],
      [9, -40],
      [10, -29],
    ],
    [
      [16130, 13752],
      [13, -46],
    ],
    [
      [14141, 12134],
      [1, 29],
      [-25, 35],
      [-1, 70],
      [-15, 46],
      [-24, -7],
      [7, 44],
      [18, 50],
      [-8, 50],
      [23, 37],
      [-15, 28],
      [19, 74],
      [32, 88],
      [60, -8],
      [-4, 476],
    ],
    [
      [15117, 13437],
      [23, -118],
      [-15, -22],
      [10, -123],
      [25, -144],
      [27, -29],
      [38, -45],
    ],
    [
      [14916, 11839],
      [-1, 94],
      [-10, 2],
      [2, 60],
      [-9, 41],
      [-36, 47],
      [-8, 87],
      [8, 88],
      [-32, 9],
      [-5, -27],
      [-42, -6],
      [17, -35],
      [6, -72],
      [-38, -66],
      [-35, -87],
      [-36, -12],
      [-58, 70],
      [-27, -25],
      [-7, -35],
      [-36, -23],
      [-2, -24],
      [-70, 0],
      [-9, 24],
      [-51, 5],
      [-25, -21],
      [-19, 10],
      [-36, 70],
      [-12, 33],
      [-50, -16],
      [-19, -56],
      [-18, -107],
      [-24, -23],
      [-21, -13],
      [47, -47],
    ],
    [
      [14918, 11307],
      [-43, -55],
      [-49, 0],
      [-56, -28],
      [-44, 27],
      [-29, -33],
    ],
    [
      [11385, 12283],
      [-11, 92],
    ],
    [
      [11382, 12428],
      [-28, 94],
      [-35, 42],
      [31, 23],
      [33, 84],
      [17, 62],
    ],
    [
      [23849, 9540],
      [19, -42],
      [-49, 1],
      [-26, 74],
      [41, -29],
      [15, -4],
    ],
    [
      [23760, 9613],
      [-27, -3],
      [-43, 12],
      [-14, 19],
      [4, 47],
      [46, -19],
      [23, -25],
      [11, -31],
    ],
    [
      [23818, 9645],
      [-11, -22],
      [-51, 104],
      [-15, 72],
      [24, 0],
      [25, -96],
      [28, -58],
    ],
    [
      [23692, 9797],
      [3, -24],
      [-55, 51],
      [-38, 43],
      [-26, 40],
      [11, 12],
      [32, -29],
      [57, -55],
      [16, -38],
    ],
    [
      [23529, 9916],
      [-14, -7],
      [-30, 27],
      [-29, 49],
      [4, 20],
      [41, -50],
      [28, -39],
    ],
    [
      [11750, 11611],
      [-19, 9],
      [-50, 49],
      [-36, 64],
      [-12, 44],
      [-9, 88],
    ],
    [
      [6428, 12403],
      [-8, -28],
      [-41, 1],
      [-25, 12],
      [-28, 24],
      [-39, 7],
      [-20, 26],
    ],
    [
      [15555, 12172],
      [23, -22],
      [13, -49],
      [32, -51],
      [34, 0],
      [66, 31],
      [76, 14],
      [61, 37],
      [35, 8],
      [25, 22],
      [40, 4],
    ],
    [
      [15960, 12166],
      [-1, -2],
      [0, -49],
      [0, -121],
      [0, -63],
      [-32, -74],
      [-48, -100],
    ],
    [
      [15960, 12166],
      [22, 2],
      [32, 18],
      [37, 12],
      [33, 41],
      [26, 1],
      [2, -33],
      [-6, -70],
      [0, -63],
      [-15, -44],
      [-20, -129],
      [-33, -134],
      [-43, -153],
      [-60, -176],
      [-60, -135],
      [-82, -163],
      [-69, -97],
      [-105, -120],
      [-65, -91],
      [-76, -145],
      [-16, -63],
      [-16, -29],
    ],
    [
      [8564, 11514],
      [83, -24],
      [8, 21],
      [56, 9],
      [75, -32],
    ],
    [
      [14120, 16686],
      [-19, -31],
      [-14, -49],
    ],
    [
      [13504, 16256],
      [15, 11],
    ],
    [
      [14214, 18716],
      [-120, -34],
      [-68, -84],
      [11, -73],
      [-111, -97],
      [-134, -103],
      [-51, -169],
      [49, -84],
      [67, -67],
      [-64, -135],
      [-72, -28],
      [-27, -202],
      [-40, -112],
      [-84, 12],
      [-40, -96],
      [-80, -5],
      [-22, 113],
      [-59, 136],
      [-53, 170],
    ],
    [
      [14783, 7590],
      [-14, -53],
      [-41, -13],
      [-41, 65],
      [-1, 41],
      [19, 45],
      [7, 35],
      [20, 9],
      [35, -22],
    ],
    [
      [15057, 14954],
      [-7, 91],
      [17, 50],
    ],
    [
      [15067, 15095],
      [19, 26],
      [19, 26],
      [4, 67],
      [22, -23],
      [77, 33],
      [37, -22],
      [58, 0],
      [80, 45],
      [37, -2],
      [80, 19],
    ],
    [
      [12678, 11534],
      [-57, -26],
    ],
    [
      [19699, 12259],
      [-63, 55],
      [-60, -2],
      [11, 94],
      [-62, 0],
      [-5, -132],
      [-38, -176],
      [-23, -106],
      [5, -86],
      [46, -4],
      [28, -110],
      [12, -103],
      [39, -69],
      [42, -14],
      [37, -62],
    ],
    [
      [19524, 11573],
      [-27, 46],
      [-12, 59],
      [-37, 68],
      [-34, 57],
      [-11, -71],
      [-14, 67],
      [8, 75],
      [21, 115],
    ],
    [
      [17276, 15253],
      [39, 122],
      [-15, 89],
      [-51, 29],
      [18, 53],
      [58, -6],
      [33, 66],
      [22, 77],
      [94, 28],
      [-15, -55],
      [10, -34],
      [29, 3],
    ],
    [
      [16306, 15260],
      [-13, 85],
      [10, 125],
      [-54, 41],
      [18, 82],
      [-46, 7],
      [15, 101],
      [66, -29],
      [61, 38],
      [-51, 72],
      [-20, 69],
      [-56, -31],
      [-7, -88],
      [-22, 78],
    ],
    [
      [16449, 15753],
      [79, 2],
      [-12, 60],
      [60, 41],
      [58, 70],
      [94, -63],
      [8, -96],
      [26, -25],
      [76, 6],
      [23, -22],
      [35, -124],
      [79, -82],
      [46, -57],
      [73, -59],
      [92, -51],
      [-2, -73],
    ],
    [
      [21259, 9730],
      [8, 29],
      [60, 27],
      [49, 4],
      [21, 15],
      [27, -15],
      [-26, -33],
      [-72, -52],
      [-59, -35],
    ],
    [
      [8248, 12088],
      [40, 16],
      [15, -5],
      [-3, -89],
      [-58, -13],
      [-13, 11],
      [20, 33],
      [-1, 47],
    ],
    [
      [13135, 15230],
      [75, 48],
      [49, -14],
      [-2, -61],
      [59, 44],
      [5, -23],
      [-35, -59],
      [0, -55],
      [24, -30],
      [-9, -104],
      [-46, -60],
      [13, -66],
      [36, -2],
      [18, -57],
      [26, -18],
    ],
    [
      [15067, 15095],
      [-25, 54],
      [26, 45],
      [-42, -10],
      [-59, 28],
      [-48, -70],
      [-105, -13],
      [-57, 64],
      [-75, 4],
      [-16, -49],
      [-48, -15],
      [-68, 64],
      [-76, -2],
      [-41, 119],
      [-51, 67],
      [34, 93],
      [-44, 58],
      [77, 114],
      [107, 5],
      [30, 91],
      [133, -16],
      [83, 78],
      [82, 34],
      [115, 3],
      [122, -85],
      [100, -46],
      [81, 18],
      [60, -10],
      [82, 62],
    ],
    [
      [14499, 15837],
      [8, -46],
      [61, -39],
      [-12, -29],
      [-83, -7],
      [-30, -37],
      [-58, -65],
      [-22, 56],
      [1, 25],
    ],
    [
      [21036, 13724],
      [-42, -193],
      [-29, -98],
      [-37, 101],
      [-8, 89],
      [41, 118],
      [56, 91],
      [32, -36],
      [-13, -72],
    ],
    [
      [14668, 10661],
      [24, 14],
      [77, -1],
      [142, 9],
    ],
    [
      [15280, 10236],
      [-32, -148],
      [4, -68],
      [45, -43],
      [2, -32],
      [-19, -72],
      [4, -36],
      [-5, -58],
      [24, -75],
      [29, -118],
      [26, -27],
    ],
    [
      [14831, 9690],
      [-39, 36],
      [-45, 20],
      [-28, 20],
      [-29, 31],
    ],
    [
      [15212, 16448],
      [-56, -10],
      [-46, -38],
      [-65, -7],
      [-60, -44],
      [3, -65],
    ],
    [
      [14878, 16312],
      [-9, 13],
      [-109, 31],
      [-4, 44],
      [-65, -14],
      [-26, -66],
      [-54, -89],
    ],
    [
      [8827, 6746],
      [-30, -75],
      [-79, -67],
      [-51, 24],
      [-38, -13],
      [-65, 52],
      [-47, -4],
      [-42, 66],
    ],
    [
      [7867, 16212],
      [13, -39],
      [-75, -58],
      [-72, -42],
      [-73, -35],
      [-37, -71],
      [-12, -27],
      [-1, -64],
      [23, -64],
      [29, -3],
      [-7, 44],
      [21, -26],
      [-6, -35],
      [-47, -19],
      [-33, 2],
      [-52, -21],
      [-30, -6],
      [-41, -6],
      [-58, -34],
      [103, 22],
      [20, -22],
      [-97, -36],
      [-45, -1],
      [2, 15],
      [-21, -33],
      [21, -6],
      [-15, -86],
      [-51, -92],
      [-5, 31],
      [-16, 6],
      [-22, 30],
      [14, -65],
      [17, -21],
      [1, -46],
      [-22, -46],
      [-39, -96],
      [-7, 5],
      [22, 81],
      [-36, 46],
      [-8, 100],
      [-13, -52],
      [15, -76],
      [-46, 19],
      [48, -39],
      [3, -114],
      [20, -8],
      [7, -42],
      [10, -120],
      [-45, -89],
      [-72, -35],
      [-46, -71],
      [-34, -8],
      [-36, -44],
      [-10, -40],
      [-76, -78],
      [-39, -57],
      [-33, -71],
      [-11, -85],
      [12, -83],
      [24, -103],
      [30, -85],
      [1, -52],
      [33, -139],
      [-2, -81],
      [-3, -47],
      [-18, -73],
      [-21, -15],
      [-34, 15],
      [-11, 52],
      [-26, 28],
      [-37, 103],
      [-33, 92],
      [-10, 47],
      [14, 79],
      [-19, 66],
      [-55, 101],
      [-27, 18],
      [-70, -54],
      [-13, 6],
      [-34, 56],
      [-43, 29],
      [-79, -15],
      [-62, 13],
      [-53, -8],
      [-29, -19],
      [13, -31],
      [-2, -49],
      [15, -24],
      [-13, -16],
      [-26, 18],
      [-26, -23],
      [-51, 4],
      [-52, 64],
      [-60, -15],
      [-51, 27],
      [-44, -8],
      [-58, -28],
      [-64, -89],
      [-69, -52],
      [-38, -57],
      [-16, -54],
      [-1, -83],
      [4, -57],
      [13, -41],
    ],
    [
      [4383, 14700],
      [-12, 62],
      [-45, 69],
      [-33, 14],
      [-7, 34],
      [-39, 6],
      [-25, 33],
      [-65, 12],
      [-18, 19],
      [-8, 66],
      [-68, 120],
      [-58, 167],
      [2, 28],
      [-30, 40],
      [-54, 100],
      [-10, 98],
      [-37, 66],
      [15, 99],
      [-2, 103],
      [-22, 92],
      [27, 113],
      [8, 109],
      [9, 109],
      [-13, 161],
      [-22, 102],
      [-20, 56],
      [8, 23],
      [101, -41],
      [37, -113],
      [17, 32],
      [-11, 98],
      [-23, 99],
    ],
    [
      [3448, 17372],
      [-38, 45],
      [-62, 38],
      [-19, 105],
      [-90, 97],
      [-38, 113],
      [-67, 8],
      [-111, 3],
      [-81, 34],
      [-144, 125],
      [-67, 23],
      [-122, 42],
      [-97, -10],
      [-137, 55],
      [-83, 51],
      [-77, -25],
      [14, -83],
      [-38, -8],
      [-81, -25],
      [-61, -40],
      [-77, -26],
      [-10, 71],
      [31, 117],
      [74, 37],
      [-19, 30],
      [-89, -66],
      [-47, -80],
      [-101, -86],
      [51, -58],
      [-66, -86],
      [-75, -50],
      [-69, -37],
      [-18, -53],
      [-109, -62],
      [-22, -56],
      [-81, -52],
      [-48, 10],
      [-65, -34],
      [-71, -41],
      [-58, -40],
      [-119, -34],
      [-11, 20],
      [76, 56],
      [68, 37],
      [74, 66],
      [87, 13],
      [34, 50],
      [97, 71],
      [15, 24],
      [52, 43],
      [12, 91],
      [35, 71],
      [-80, -37],
      [-22, 21],
      [-38, -44],
      [-46, 61],
      [-19, -43],
      [-26, 60],
      [-69, -48],
      [-43, 0],
      [-6, 71],
      [13, 44],
      [-45, 43],
      [-91, -23],
      [-59, 56],
      [-48, 29],
      [0, 68],
      [-54, 51],
      [27, 69],
      [57, 67],
      [25, 62],
      [57, 9],
      [47, -20],
      [57, 58],
      [50, -10],
      [53, 37],
      [-13, 55],
      [-39, 22],
      [52, 46],
      [-43, -2],
      [-74, -26],
      [-21, -26],
      [-55, 26],
      [-99, -13],
      [-102, 29],
      [-29, 48],
      [-88, 70],
      [98, 50],
      [155, 58],
      [58, 0],
      [-10, -60],
      [147, 5],
      [-56, 74],
      [-86, 46],
      [-50, 60],
      [-67, 51],
      [-95, 38],
      [39, 63],
      [123, 4],
      [88, 55],
      [17, 58],
      [71, 57],
      [68, 14],
      [132, 53],
      [64, -8],
      [108, 64],
      [105, -25],
      [50, -54],
      [31, 23],
      [118, -7],
      [-4, -28],
      [107, -20],
      [71, 12],
      [147, -38],
      [134, -12],
      [53, -15],
      [93, 19],
      [106, -36],
      [76, -17],
    ],
    [
      [1705, 13087],
      [-10, -20],
      [-18, 17],
      [2, 33],
      [-11, 44],
      [3, 13],
      [12, 20],
      [-4, 23],
      [4, 12],
      [5, -3],
      [27, -20],
      [12, -10],
      [11, -16],
      [18, -42],
      [-2, -7],
      [-27, -26],
      [-22, -18],
    ],
    [
      [1667, 13274],
      [-23, -9],
      [-12, 26],
      [-8, 9],
      [-1, 8],
      [7, 10],
      [25, -11],
      [18, -19],
      [-6, -14],
    ],
    [
      [1620, 13338],
      [-2, -13],
      [-37, 3],
      [5, 15],
      [34, -5],
    ],
    [
      [1558, 13355],
      [-4, -7],
      [-5, 2],
      [-24, 4],
      [-9, 27],
      [-3, 5],
      [19, 17],
      [6, -8],
      [20, -40],
    ],
    [
      [1440, 13434],
      [-8, -12],
      [-24, 22],
      [4, 9],
      [10, 12],
      [16, -3],
      [2, -28],
    ],
    [
      [1882, 17649],
      [-70, -45],
      [-36, 31],
      [-10, 56],
      [63, 42],
      [37, 19],
      [46, -8],
      [30, -38],
      [-60, -57],
    ],
    [
      [1005, 17985],
      [-43, -19],
      [-45, 22],
      [-43, 33],
      [69, 20],
      [56, -10],
      [6, -46],
    ],
    [
      [576, 18449],
      [43, -23],
      [44, 13],
      [56, -32],
      [69, -16],
      [-5, -13],
      [-53, -26],
      [-53, 27],
      [-27, 21],
      [-61, -7],
      [-17, 11],
      [4, 45],
    ],
    [
      [7575, 12210],
      [-2, -28],
      [-41, -14],
      [23, -55],
      [-1, -63],
      [-31, -69],
      [27, -95],
      [30, 7],
      [15, 87],
      [-21, 42],
      [-4, 91],
      [87, 49],
      [-10, 56],
      [25, 38],
      [25, -84],
      [49, -2],
      [45, -67],
      [3, -40],
      [62, -1],
      [75, 13],
      [40, -54],
      [53, -15],
      [39, 38],
      [1, 30],
      [86, 7],
      [84, 2],
      [-59, -36],
      [24, -56],
      [55, -9],
      [53, -59],
      [11, -96],
      [37, 2],
      [27, -28],
    ],
    [
      [20079, 13383],
      [-93, -103],
      [-58, -113],
      [-15, -83],
      [53, -127],
      [66, -157],
      [63, -74],
      [42, -96],
      [32, -222],
      [-9, -211],
      [-58, -79],
      [-80, -77],
      [-57, -100],
      [-87, -112],
      [-25, 77],
      [19, 81],
      [-52, 68],
    ],
    [
      [24248, 8822],
      [-23, -16],
      [-24, 52],
      [3, 33],
      [44, -69],
    ],
    [
      [24196, 9006],
      [12, -97],
      [-19, 15],
      [-15, -7],
      [-10, 34],
      [-1, 91],
      [33, -36],
    ],
    [
      [16250, 12795],
      [-51, -32],
      [-13, -54],
      [-2, -41],
      [-69, -50],
      [-112, -56],
      [-62, -85],
      [-31, -6],
      [-21, 7],
      [-40, -50],
      [-45, -23],
      [-58, -6],
      [-18, -7],
      [-15, -32],
      [-19, -9],
      [-10, -30],
      [-35, 2],
      [-22, -16],
      [-48, 6],
      [-19, 70],
      [2, 66],
      [-11, 35],
      [-14, 89],
      [-20, 49],
      [14, 6],
      [-7, 55],
      [9, 23],
      [-3, 52],
    ],
    [
      [14599, 8147],
      [29, -1],
      [33, -21],
      [24, 15],
      [37, -12],
    ],
    [
      [14836, 7589],
      [-17, -87],
      [-9, -100],
      [-18, -54],
      [-47, -61],
      [-14, -17],
      [-29, -61],
      [-20, -62],
      [-39, -86],
      [-79, -123],
      [-49, -72],
      [-53, -55],
      [-73, -47],
      [-35, -6],
      [-9, -33],
      [-43, 18],
      [-34, -23],
      [-76, 23],
      [-42, -15],
      [-29, 7],
      [-72, -48],
      [-59, -19],
      [-43, -45],
      [-32, -3],
      [-30, 43],
      [-23, 2],
      [-30, 54],
      [-3, -17],
      [-10, 32],
      [1, 70],
      [-23, 81],
      [23, 22],
      [-2, 92],
      [-46, 112],
      [-35, 102],
      [0, 0],
      [-50, 156],
    ],
    [
      [14658, 8937],
      [-53, -17],
      [-40, -47],
      [-8, -42],
      [-25, -10],
      [-61, -98],
      [-38, -78],
      [-24, -3],
      [-22, 14],
      [-78, 13],
    ],
  ],
  transform: { scale: [0.01434548714883443, 0.008335499711981569], translate: [-180, -90] },
  objects: {
    ne_110m_admin_0_countries: {
      type: 'GeometryCollection',
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: 'Polygon',
          properties: {
            NAME: 'Afghanistan',
            NAME_LONG: 'Afghanistan',
            ABBREV: 'Afg.',
            FORMAL_EN: 'Islamic State of Afghanistan',
            POP_EST: 34124811,
            POP_RANK: 15,
            GDP_MD_EST: 64080,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AF',
            ISO_A3: 'AFG',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[[6, 7, 8, 9]], [[10, 11, 12]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Angola',
            NAME_LONG: 'Angola',
            ABBREV: 'Ang.',
            FORMAL_EN: "People's Republic of Angola",
            POP_EST: 29310273,
            POP_RANK: 15,
            GDP_MD_EST: 189000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AO',
            ISO_A3: 'AGO',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[13, 14, 15, 16, 17]],
          type: 'Polygon',
          properties: {
            NAME: 'Albania',
            NAME_LONG: 'Albania',
            ABBREV: 'Alb.',
            FORMAL_EN: 'Republic of Albania',
            POP_EST: 3047987,
            POP_RANK: 12,
            GDP_MD_EST: 33900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AL',
            ISO_A3: 'ALB',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[18, 19, 20, 21, 22]],
          type: 'Polygon',
          properties: {
            NAME: 'United Arab Emirates',
            NAME_LONG: 'United Arab Emirates',
            ABBREV: 'U.A.E.',
            FORMAL_EN: 'United Arab Emirates',
            POP_EST: 6072475,
            POP_RANK: 13,
            GDP_MD_EST: 667200,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AE',
            ISO_A3: 'ARE',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[[23, 24]], [[25, 26, 27, 28, 29, 30]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Argentina',
            NAME_LONG: 'Argentina',
            ABBREV: 'Arg.',
            FORMAL_EN: 'Argentine Republic',
            POP_EST: 44293293,
            POP_RANK: 15,
            GDP_MD_EST: 879400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AR',
            ISO_A3: 'ARG',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[31, 32, 33, 34, 35]],
          type: 'Polygon',
          properties: {
            NAME: 'Armenia',
            NAME_LONG: 'Armenia',
            ABBREV: 'Arm.',
            FORMAL_EN: 'Republic of Armenia',
            POP_EST: 3045191,
            POP_RANK: 12,
            GDP_MD_EST: 26300,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AM',
            ISO_A3: 'ARM',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[[36]], [[37]], [[38]], [[39]], [[40]], [[41]], [[42]], [[43]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Antarctica',
            NAME_LONG: 'Antarctica',
            ABBREV: 'Ant.',
            FORMAL_EN: '',
            POP_EST: 4050,
            POP_RANK: 4,
            GDP_MD_EST: 810,
            POP_YEAR: 2013,
            GDP_YEAR: 2013,
            ISO_A2: 'AQ',
            ISO_A3: 'ATA',
            CONTINENT: 'Antarctica',
            REGION_UN: 'Antarctica',
            SUBREGION: 'Antarctica',
          },
        },
        {
          arcs: [[44]],
          type: 'Polygon',
          properties: {
            NAME: 'Fr. S. Antarctic Lands',
            NAME_LONG: 'French Southern and Antarctic Lands',
            ABBREV: 'Fr. S.A.L.',
            FORMAL_EN: 'Territory of the French Southern and Antarctic Lands',
            POP_EST: 140,
            POP_RANK: 1,
            GDP_MD_EST: 16,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TF',
            ISO_A3: 'ATF',
            CONTINENT: 'Seven seas (open ocean)',
            REGION_UN: 'Seven seas (open ocean)',
            SUBREGION: 'Seven seas (open ocean)',
          },
        },
        {
          arcs: [[[45]], [[46]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Australia',
            NAME_LONG: 'Australia',
            ABBREV: 'Auz.',
            FORMAL_EN: 'Commonwealth of Australia',
            POP_EST: 23232413,
            POP_RANK: 15,
            GDP_MD_EST: 1189000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AU',
            ISO_A3: 'AUS',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Australia and New Zealand',
          },
        },
        {
          arcs: [[47, 48, 49, 50, 51, 52, 53]],
          type: 'Polygon',
          properties: {
            NAME: 'Austria',
            NAME_LONG: 'Austria',
            ABBREV: 'Aust.',
            FORMAL_EN: 'Republic of Austria',
            POP_EST: 8754413,
            POP_RANK: 13,
            GDP_MD_EST: 416600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AT',
            ISO_A3: 'AUT',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[[-33, 54, 55, 56, 57]], [[-35, 58]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Azerbaijan',
            NAME_LONG: 'Azerbaijan',
            ABBREV: 'Aze.',
            FORMAL_EN: 'Republic of Azerbaijan',
            POP_EST: 9961396,
            POP_RANK: 13,
            GDP_MD_EST: 167900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'AZ',
            ISO_A3: 'AZE',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[59, 60, 61]],
          type: 'Polygon',
          properties: {
            NAME: 'Burundi',
            NAME_LONG: 'Burundi',
            ABBREV: 'Bur.',
            FORMAL_EN: 'Republic of Burundi',
            POP_EST: 11466756,
            POP_RANK: 14,
            GDP_MD_EST: 7892,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BI',
            ISO_A3: 'BDI',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[62, 63, 64, 65, 66]],
          type: 'Polygon',
          properties: {
            NAME: 'Belgium',
            NAME_LONG: 'Belgium',
            ABBREV: 'Belg.',
            FORMAL_EN: 'Kingdom of Belgium',
            POP_EST: 11491346,
            POP_RANK: 14,
            GDP_MD_EST: 508600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BE',
            ISO_A3: 'BEL',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[67, 68, 69, 70, 71]],
          type: 'Polygon',
          properties: {
            NAME: 'Benin',
            NAME_LONG: 'Benin',
            ABBREV: 'Benin',
            FORMAL_EN: 'Republic of Benin',
            POP_EST: 11038805,
            POP_RANK: 14,
            GDP_MD_EST: 24310,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BJ',
            ISO_A3: 'BEN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-70, 72, 73, 74, 75, 76]],
          type: 'Polygon',
          properties: {
            NAME: 'Burkina Faso',
            NAME_LONG: 'Burkina Faso',
            ABBREV: 'B.F.',
            FORMAL_EN: 'Burkina Faso',
            POP_EST: 20107509,
            POP_RANK: 15,
            GDP_MD_EST: 32990,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BF',
            ISO_A3: 'BFA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[77, 78, 79]],
          type: 'Polygon',
          properties: {
            NAME: 'Bangladesh',
            NAME_LONG: 'Bangladesh',
            ABBREV: 'Bang.',
            FORMAL_EN: "People's Republic of Bangladesh",
            POP_EST: 157826578,
            POP_RANK: 17,
            GDP_MD_EST: 628400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BD',
            ISO_A3: 'BGD',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[80, 81, 82, 83, 84, 85]],
          type: 'Polygon',
          properties: {
            NAME: 'Bulgaria',
            NAME_LONG: 'Bulgaria',
            ABBREV: 'Bulg.',
            FORMAL_EN: 'Republic of Bulgaria',
            POP_EST: 7101510,
            POP_RANK: 13,
            GDP_MD_EST: 143100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BG',
            ISO_A3: 'BGR',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[[86]], [[87]], [[88]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Bahamas',
            NAME_LONG: 'Bahamas',
            ABBREV: 'Bhs.',
            FORMAL_EN: 'Commonwealth of the Bahamas',
            POP_EST: 329988,
            POP_RANK: 10,
            GDP_MD_EST: 9066,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BS',
            ISO_A3: 'BHS',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[89, 90, 91]],
          type: 'Polygon',
          properties: {
            NAME: 'Bosnia and Herz.',
            NAME_LONG: 'Bosnia and Herzegovina',
            ABBREV: 'B.H.',
            FORMAL_EN: 'Bosnia and Herzegovina',
            POP_EST: 3856181,
            POP_RANK: 12,
            GDP_MD_EST: 42530,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BA',
            ISO_A3: 'BIH',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[92, 93, 94, 95, 96]],
          type: 'Polygon',
          properties: {
            NAME: 'Belarus',
            NAME_LONG: 'Belarus',
            ABBREV: 'Bela.',
            FORMAL_EN: 'Republic of Belarus',
            POP_EST: 9549747,
            POP_RANK: 13,
            GDP_MD_EST: 165400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BY',
            ISO_A3: 'BLR',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[97, 98, 99]],
          type: 'Polygon',
          properties: {
            NAME: 'Belize',
            NAME_LONG: 'Belize',
            ABBREV: 'Belize',
            FORMAL_EN: 'Belize',
            POP_EST: 360346,
            POP_RANK: 10,
            GDP_MD_EST: 3088,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BZ',
            ISO_A3: 'BLZ',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-27, 100, 101, 102, 103]],
          type: 'Polygon',
          properties: {
            NAME: 'Bolivia',
            NAME_LONG: 'Bolivia',
            ABBREV: 'Bolivia',
            FORMAL_EN: 'Plurinational State of Bolivia',
            POP_EST: 11138234,
            POP_RANK: 14,
            GDP_MD_EST: 78350,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BO',
            ISO_A3: 'BOL',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[-29, 104, -103, 105, 106, 107, 108, 109, 110, 111, 112]],
          type: 'Polygon',
          properties: {
            NAME: 'Brazil',
            NAME_LONG: 'Brazil',
            ABBREV: 'Brazil',
            FORMAL_EN: 'Federative Republic of Brazil',
            POP_EST: 207353391,
            POP_RANK: 17,
            GDP_MD_EST: 3081000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BR',
            ISO_A3: 'BRA',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[113, 114]],
          type: 'Polygon',
          properties: {
            NAME: 'Brunei',
            NAME_LONG: 'Brunei Darussalam',
            ABBREV: 'Brunei',
            FORMAL_EN: 'Negara Brunei Darussalam',
            POP_EST: 443593,
            POP_RANK: 10,
            GDP_MD_EST: 33730,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BN',
            ISO_A3: 'BRN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[115, 116]],
          type: 'Polygon',
          properties: {
            NAME: 'Bhutan',
            NAME_LONG: 'Bhutan',
            ABBREV: 'Bhutan',
            FORMAL_EN: 'Kingdom of Bhutan',
            POP_EST: 758288,
            POP_RANK: 11,
            GDP_MD_EST: 6432,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BT',
            ISO_A3: 'BTN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[117, 118, 119, 120]],
          type: 'Polygon',
          properties: {
            NAME: 'Botswana',
            NAME_LONG: 'Botswana',
            ABBREV: 'Bwa.',
            FORMAL_EN: 'Republic of Botswana',
            POP_EST: 2214858,
            POP_RANK: 12,
            GDP_MD_EST: 35900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'BW',
            ISO_A3: 'BWA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Southern Africa',
          },
        },
        {
          arcs: [[121, 122, 123, 124, 125, 126]],
          type: 'Polygon',
          properties: {
            NAME: 'Central African Rep.',
            NAME_LONG: 'Central African Republic',
            ABBREV: 'C.A.R.',
            FORMAL_EN: 'Central African Republic',
            POP_EST: 5625118,
            POP_RANK: 13,
            GDP_MD_EST: 3206,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CF',
            ISO_A3: 'CAF',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [
            [[127]],
            [[128]],
            [[129]],
            [[130]],
            [[131]],
            [[132]],
            [[133]],
            [[134]],
            [[135]],
            [[136]],
            [[137, 138, 139, 140]],
            [[141]],
            [[142]],
            [[143]],
            [[144]],
            [[145]],
            [[146]],
            [[147]],
            [[148]],
            [[149]],
            [[150]],
            [[151]],
            [[152]],
            [[153]],
            [[154]],
            [[155]],
            [[156]],
            [[157]],
            [[158]],
            [[159]],
          ],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Canada',
            NAME_LONG: 'Canada',
            ABBREV: 'Can.',
            FORMAL_EN: 'Canada',
            POP_EST: 35623680,
            POP_RANK: 15,
            GDP_MD_EST: 1674000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CA',
            ISO_A3: 'CAN',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Northern America',
          },
        },
        {
          arcs: [[-51, 160, 161, 162]],
          type: 'Polygon',
          properties: {
            NAME: 'Switzerland',
            NAME_LONG: 'Switzerland',
            ABBREV: 'Switz.',
            FORMAL_EN: 'Swiss Confederation',
            POP_EST: 8236303,
            POP_RANK: 13,
            GDP_MD_EST: 496300,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CH',
            ISO_A3: 'CHE',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[[-24, 163]], [[-26, 164, 165, -101]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Chile',
            NAME_LONG: 'Chile',
            ABBREV: 'Chile',
            FORMAL_EN: 'Republic of Chile',
            POP_EST: 17789267,
            POP_RANK: 14,
            GDP_MD_EST: 436100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CL',
            ISO_A3: 'CHL',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [
            [
              [
                -4, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, -117, 178, 179, 180,
                181,
              ],
            ],
            [[182]],
          ],
          type: 'MultiPolygon',
          properties: {
            NAME: 'China',
            NAME_LONG: 'China',
            ABBREV: 'China',
            FORMAL_EN: "People's Republic of China",
            POP_EST: 1379302771,
            POP_RANK: 18,
            GDP_MD_EST: 21140000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CN',
            ISO_A3: 'CHN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[-75, 183, 184, 185, 186, 187]],
          type: 'Polygon',
          properties: {
            NAME: "Côte d'Ivoire",
            NAME_LONG: "Côte d'Ivoire",
            ABBREV: 'I.C.',
            FORMAL_EN: 'Republic of Ivory Coast',
            POP_EST: 24184810,
            POP_RANK: 15,
            GDP_MD_EST: 87120,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CI',
            ISO_A3: 'CIV',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-127, 188, 189, 190, 191, 192, 193, 194]],
          type: 'Polygon',
          properties: {
            NAME: 'Cameroon',
            NAME_LONG: 'Cameroon',
            ABBREV: 'Cam.',
            FORMAL_EN: 'Republic of Cameroon',
            POP_EST: 24994885,
            POP_RANK: 15,
            GDP_MD_EST: 77240,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CM',
            ISO_A3: 'CMR',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[-9, 195, -13, 196, -125, 197, 198, 199, -60, 200, 201]],
          type: 'Polygon',
          properties: {
            NAME: 'Dem. Rep. Congo',
            NAME_LONG: 'Democratic Republic of the Congo',
            ABBREV: 'D.R.C.',
            FORMAL_EN: 'Democratic Republic of the Congo',
            POP_EST: 83301151,
            POP_RANK: 16,
            GDP_MD_EST: 66010,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CD',
            ISO_A3: 'COD',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[-12, 202, 203, -189, -126, -197]],
          type: 'Polygon',
          properties: {
            NAME: 'Congo',
            NAME_LONG: 'Republic of the Congo',
            ABBREV: 'Rep. Congo',
            FORMAL_EN: 'Republic of the Congo',
            POP_EST: 4954674,
            POP_RANK: 12,
            GDP_MD_EST: 30270,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CG',
            ISO_A3: 'COG',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[-107, 204, 205, 206, 207, 208, 209]],
          type: 'Polygon',
          properties: {
            NAME: 'Colombia',
            NAME_LONG: 'Colombia',
            ABBREV: 'Col.',
            FORMAL_EN: 'Republic of Colombia',
            POP_EST: 47698524,
            POP_RANK: 15,
            GDP_MD_EST: 688000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CO',
            ISO_A3: 'COL',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[210, 211, 212, 213]],
          type: 'Polygon',
          properties: {
            NAME: 'Costa Rica',
            NAME_LONG: 'Costa Rica',
            ABBREV: 'C.R.',
            FORMAL_EN: 'Republic of Costa Rica',
            POP_EST: 4930258,
            POP_RANK: 12,
            GDP_MD_EST: 79260,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CR',
            ISO_A3: 'CRI',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[214]],
          type: 'Polygon',
          properties: {
            NAME: 'Cuba',
            NAME_LONG: 'Cuba',
            ABBREV: 'Cuba',
            FORMAL_EN: 'Republic of Cuba',
            POP_EST: 11147407,
            POP_RANK: 14,
            GDP_MD_EST: 132900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CU',
            ISO_A3: 'CUB',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[215, 216]],
          type: 'Polygon',
          properties: {
            NAME: 'N. Cyprus',
            NAME_LONG: 'Northern Cyprus',
            ABBREV: 'N. Cy.',
            FORMAL_EN: 'Turkish Republic of Northern Cyprus',
            POP_EST: 265100,
            POP_RANK: 10,
            GDP_MD_EST: 3600,
            POP_YEAR: 2013,
            GDP_YEAR: 2013,
            ISO_A2: '-99',
            ISO_A3: '-99',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-217, 217]],
          type: 'Polygon',
          properties: {
            NAME: 'Cyprus',
            NAME_LONG: 'Cyprus',
            ABBREV: 'Cyp.',
            FORMAL_EN: 'Republic of Cyprus',
            POP_EST: 1221549,
            POP_RANK: 12,
            GDP_MD_EST: 29260,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CY',
            ISO_A3: 'CYP',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-53, 218, 219, 220]],
          type: 'Polygon',
          properties: {
            NAME: 'Czechia',
            NAME_LONG: 'Czech Republic',
            ABBREV: 'Cz.',
            FORMAL_EN: 'Czech Republic',
            POP_EST: 10674723,
            POP_RANK: 14,
            GDP_MD_EST: 350900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'CZ',
            ISO_A3: 'CZE',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[-52, -163, 221, 222, -63, 223, 224, 225, 226, 227, -219]],
          type: 'Polygon',
          properties: {
            NAME: 'Germany',
            NAME_LONG: 'Germany',
            ABBREV: 'Ger.',
            FORMAL_EN: 'Federal Republic of Germany',
            POP_EST: 80594017,
            POP_RANK: 16,
            GDP_MD_EST: 3979000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'DE',
            ISO_A3: 'DEU',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[228, 229, 230, 231]],
          type: 'Polygon',
          properties: {
            NAME: 'Djibouti',
            NAME_LONG: 'Djibouti',
            ABBREV: 'Dji.',
            FORMAL_EN: 'Republic of Djibouti',
            POP_EST: 865267,
            POP_RANK: 11,
            GDP_MD_EST: 3345,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'DJ',
            ISO_A3: 'DJI',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[[-226, 232]], [[233]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Denmark',
            NAME_LONG: 'Denmark',
            ABBREV: 'Den.',
            FORMAL_EN: 'Kingdom of Denmark',
            POP_EST: 5605948,
            POP_RANK: 13,
            GDP_MD_EST: 264800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'DK',
            ISO_A3: 'DNK',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[234, 235]],
          type: 'Polygon',
          properties: {
            NAME: 'Dominican Rep.',
            NAME_LONG: 'Dominican Republic',
            ABBREV: 'Dom. Rep.',
            FORMAL_EN: 'Dominican Republic',
            POP_EST: 10734247,
            POP_RANK: 14,
            GDP_MD_EST: 161900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'DO',
            ISO_A3: 'DOM',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[236, 237, 238, 239, 240, 241, 242, 243]],
          type: 'Polygon',
          properties: {
            NAME: 'Algeria',
            NAME_LONG: 'Algeria',
            ABBREV: 'Alg.',
            FORMAL_EN: "People's Democratic Republic of Algeria",
            POP_EST: 40969443,
            POP_RANK: 15,
            GDP_MD_EST: 609400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'DZ',
            ISO_A3: 'DZA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[-206, 244, 245]],
          type: 'Polygon',
          properties: {
            NAME: 'Ecuador',
            NAME_LONG: 'Ecuador',
            ABBREV: 'Ecu.',
            FORMAL_EN: 'Republic of Ecuador',
            POP_EST: 16290913,
            POP_RANK: 14,
            GDP_MD_EST: 182400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'EC',
            ISO_A3: 'ECU',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[246, 247, 248, 249, 250]],
          type: 'Polygon',
          properties: {
            NAME: 'Egypt',
            NAME_LONG: 'Egypt',
            ABBREV: 'Egypt',
            FORMAL_EN: 'Arab Republic of Egypt',
            POP_EST: 97041072,
            POP_RANK: 16,
            GDP_MD_EST: 1105000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'EG',
            ISO_A3: 'EGY',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[-232, 251, 252, 253]],
          type: 'Polygon',
          properties: {
            NAME: 'Eritrea',
            NAME_LONG: 'Eritrea',
            ABBREV: 'Erit.',
            FORMAL_EN: 'State of Eritrea',
            POP_EST: 5918919,
            POP_RANK: 13,
            GDP_MD_EST: 9169,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ER',
            ISO_A3: 'ERI',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[254, 255, 256, 257]],
          type: 'Polygon',
          properties: {
            NAME: 'Spain',
            NAME_LONG: 'Spain',
            ABBREV: 'Sp.',
            FORMAL_EN: 'Kingdom of Spain',
            POP_EST: 48958159,
            POP_RANK: 15,
            GDP_MD_EST: 1690000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ES',
            ISO_A3: 'ESP',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[258, 259, 260]],
          type: 'Polygon',
          properties: {
            NAME: 'Estonia',
            NAME_LONG: 'Estonia',
            ABBREV: 'Est.',
            FORMAL_EN: 'Republic of Estonia',
            POP_EST: 1251581,
            POP_RANK: 12,
            GDP_MD_EST: 38700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'EE',
            ISO_A3: 'EST',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-231, 261, 262, 263, 264, 265, -252]],
          type: 'Polygon',
          properties: {
            NAME: 'Ethiopia',
            NAME_LONG: 'Ethiopia',
            ABBREV: 'Eth.',
            FORMAL_EN: 'Federal Democratic Republic of Ethiopia',
            POP_EST: 105350020,
            POP_RANK: 17,
            GDP_MD_EST: 174700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ET',
            ISO_A3: 'ETH',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[266, 267, 268, 269]],
          type: 'Polygon',
          properties: {
            NAME: 'Finland',
            NAME_LONG: 'Finland',
            ABBREV: 'Fin.',
            FORMAL_EN: 'Republic of Finland',
            POP_EST: 5491218,
            POP_RANK: 13,
            GDP_MD_EST: 224137,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'FI',
            ISO_A3: 'FIN',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[[270]], [[271]], [[272]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Fiji',
            NAME_LONG: 'Fiji',
            ABBREV: 'Fiji',
            FORMAL_EN: 'Republic of Fiji',
            POP_EST: 920938,
            POP_RANK: 11,
            GDP_MD_EST: 8374,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'FJ',
            ISO_A3: 'FJI',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Melanesia',
          },
        },
        {
          arcs: [[273]],
          type: 'Polygon',
          properties: {
            NAME: 'Falkland Is.',
            NAME_LONG: 'Falkland Islands',
            ABBREV: 'Flk. Is.',
            FORMAL_EN: 'Falkland Islands',
            POP_EST: 2931,
            POP_RANK: 4,
            GDP_MD_EST: 281.8,
            POP_YEAR: 2014,
            GDP_YEAR: 2012,
            ISO_A2: 'FK',
            ISO_A3: 'FLK',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[[-65, 274, -222, -162, 275, 276, -256, 277]], [[-111, 278, 279]], [[280]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'France',
            NAME_LONG: 'France',
            ABBREV: 'Fr.',
            FORMAL_EN: 'French Republic',
            POP_EST: 67106161,
            POP_RANK: 16,
            GDP_MD_EST: 2699000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'FR',
            ISO_A3: 'FRA',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[-190, -204, 281, 282]],
          type: 'Polygon',
          properties: {
            NAME: 'Gabon',
            NAME_LONG: 'Gabon',
            ABBREV: 'Gabon',
            FORMAL_EN: 'Gabonese Republic',
            POP_EST: 1772255,
            POP_RANK: 12,
            GDP_MD_EST: 35980,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GA',
            ISO_A3: 'GAB',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[[283, 284]], [[285]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'United Kingdom',
            NAME_LONG: 'United Kingdom',
            ABBREV: 'U.K.',
            FORMAL_EN: 'United Kingdom of Great Britain and Northern Ireland',
            POP_EST: 64769452,
            POP_RANK: 16,
            GDP_MD_EST: 2788000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GB',
            ISO_A3: 'GBR',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-32, 286, 287, 288, -55]],
          type: 'Polygon',
          properties: {
            NAME: 'Georgia',
            NAME_LONG: 'Georgia',
            ABBREV: 'Geo.',
            FORMAL_EN: 'Georgia',
            POP_EST: 4926330,
            POP_RANK: 12,
            GDP_MD_EST: 37270,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GE',
            ISO_A3: 'GEO',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-74, 289, 290, -184]],
          type: 'Polygon',
          properties: {
            NAME: 'Ghana',
            NAME_LONG: 'Ghana',
            ABBREV: 'Ghana',
            FORMAL_EN: 'Republic of Ghana',
            POP_EST: 27499924,
            POP_RANK: 15,
            GDP_MD_EST: 120800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GH',
            ISO_A3: 'GHA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-187, 291, 292, 293, 294, 295, 296]],
          type: 'Polygon',
          properties: {
            NAME: 'Guinea',
            NAME_LONG: 'Guinea',
            ABBREV: 'Gin.',
            FORMAL_EN: 'Republic of Guinea',
            POP_EST: 12413867,
            POP_RANK: 14,
            GDP_MD_EST: 16080,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GN',
            ISO_A3: 'GIN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[297, 298]],
          type: 'Polygon',
          properties: {
            NAME: 'Gambia',
            NAME_LONG: 'The Gambia',
            ABBREV: 'Gambia',
            FORMAL_EN: 'Republic of the Gambia',
            POP_EST: 2051363,
            POP_RANK: 12,
            GDP_MD_EST: 3387,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GM',
            ISO_A3: 'GMB',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-295, 299, 300]],
          type: 'Polygon',
          properties: {
            NAME: 'Guinea-Bissau',
            NAME_LONG: 'Guinea-Bissau',
            ABBREV: 'GnB.',
            FORMAL_EN: 'Republic of Guinea-Bissau',
            POP_EST: 1792338,
            POP_RANK: 12,
            GDP_MD_EST: 2851,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GW',
            ISO_A3: 'GNB',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-191, -283, 301]],
          type: 'Polygon',
          properties: {
            NAME: 'Eq. Guinea',
            NAME_LONG: 'Equatorial Guinea',
            ABBREV: 'Eq. G.',
            FORMAL_EN: 'Republic of Equatorial Guinea',
            POP_EST: 778358,
            POP_RANK: 11,
            GDP_MD_EST: 31770,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GQ',
            ISO_A3: 'GNQ',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[[-14, 302, -84, 303, 304]], [[305]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Greece',
            NAME_LONG: 'Greece',
            ABBREV: 'Greece',
            FORMAL_EN: 'Hellenic Republic',
            POP_EST: 10768477,
            POP_RANK: 14,
            GDP_MD_EST: 290500,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GR',
            ISO_A3: 'GRC',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[306]],
          type: 'Polygon',
          properties: {
            NAME: 'Greenland',
            NAME_LONG: 'Greenland',
            ABBREV: 'Grlnd.',
            FORMAL_EN: 'Greenland',
            POP_EST: 57713,
            POP_RANK: 8,
            GDP_MD_EST: 2173,
            POP_YEAR: 2017,
            GDP_YEAR: 2015,
            ISO_A2: 'GL',
            ISO_A3: 'GRL',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Northern America',
          },
        },
        {
          arcs: [[-100, 307, 308, 309, 310, 311]],
          type: 'Polygon',
          properties: {
            NAME: 'Guatemala',
            NAME_LONG: 'Guatemala',
            ABBREV: 'Guat.',
            FORMAL_EN: 'Republic of Guatemala',
            POP_EST: 15460732,
            POP_RANK: 14,
            GDP_MD_EST: 131800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GT',
            ISO_A3: 'GTM',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-109, 312, 313, 314]],
          type: 'Polygon',
          properties: {
            NAME: 'Guyana',
            NAME_LONG: 'Guyana',
            ABBREV: 'Guy.',
            FORMAL_EN: 'Co-operative Republic of Guyana',
            POP_EST: 737718,
            POP_RANK: 11,
            GDP_MD_EST: 6093,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'GY',
            ISO_A3: 'GUY',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[-309, 315, 316, 317, 318]],
          type: 'Polygon',
          properties: {
            NAME: 'Honduras',
            NAME_LONG: 'Honduras',
            ABBREV: 'Hond.',
            FORMAL_EN: 'Republic of Honduras',
            POP_EST: 9038741,
            POP_RANK: 13,
            GDP_MD_EST: 43190,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'HN',
            ISO_A3: 'HND',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-91, 319, 320, 321, 322, 323]],
          type: 'Polygon',
          properties: {
            NAME: 'Croatia',
            NAME_LONG: 'Croatia',
            ABBREV: 'Cro.',
            FORMAL_EN: 'Republic of Croatia',
            POP_EST: 4292095,
            POP_RANK: 12,
            GDP_MD_EST: 94240,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'HR',
            ISO_A3: 'HRV',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-236, 324]],
          type: 'Polygon',
          properties: {
            NAME: 'Haiti',
            NAME_LONG: 'Haiti',
            ABBREV: 'Haiti',
            FORMAL_EN: 'Republic of Haiti',
            POP_EST: 10646714,
            POP_RANK: 14,
            GDP_MD_EST: 19340,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'HT',
            ISO_A3: 'HTI',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[-48, 325, 326, 327, 328, -323, 329]],
          type: 'Polygon',
          properties: {
            NAME: 'Hungary',
            NAME_LONG: 'Hungary',
            ABBREV: 'Hun.',
            FORMAL_EN: 'Republic of Hungary',
            POP_EST: 9850845,
            POP_RANK: 13,
            GDP_MD_EST: 267600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'HU',
            ISO_A3: 'HUN',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [
            [[330]],
            [[331, 332]],
            [[333]],
            [[334]],
            [[335]],
            [[336]],
            [[337]],
            [[338]],
            [[339, 340]],
            [[341]],
            [[342]],
            [[343, 344]],
            [[345]],
          ],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Indonesia',
            NAME_LONG: 'Indonesia',
            ABBREV: 'Indo.',
            FORMAL_EN: 'Republic of Indonesia',
            POP_EST: 260580739,
            POP_RANK: 17,
            GDP_MD_EST: 3028000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ID',
            ISO_A3: 'IDN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[-80, 346, 347, -181, 348, -179, -116, -178, 349]],
          type: 'Polygon',
          properties: {
            NAME: 'India',
            NAME_LONG: 'India',
            ABBREV: 'India',
            FORMAL_EN: 'Republic of India',
            POP_EST: 1281935911,
            POP_RANK: 18,
            GDP_MD_EST: 8721000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IN',
            ISO_A3: 'IND',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[-284, 350]],
          type: 'Polygon',
          properties: {
            NAME: 'Ireland',
            NAME_LONG: 'Ireland',
            ABBREV: 'Ire.',
            FORMAL_EN: 'Ireland',
            POP_EST: 5011102,
            POP_RANK: 13,
            GDP_MD_EST: 322000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IE',
            ISO_A3: 'IRL',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-6, 351, 352, 353, 354, -59, -34, -58, 355, 356]],
          type: 'Polygon',
          properties: {
            NAME: 'Iran',
            NAME_LONG: 'Iran',
            ABBREV: 'Iran',
            FORMAL_EN: 'Islamic Republic of Iran',
            POP_EST: 82021564,
            POP_RANK: 16,
            GDP_MD_EST: 1459000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IR',
            ISO_A3: 'IRN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[-354, 357, 358, 359, 360, 361, 362]],
          type: 'Polygon',
          properties: {
            NAME: 'Iraq',
            NAME_LONG: 'Iraq',
            ABBREV: 'Iraq',
            FORMAL_EN: 'Republic of Iraq',
            POP_EST: 39192111,
            POP_RANK: 15,
            GDP_MD_EST: 596700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IQ',
            ISO_A3: 'IRQ',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[363]],
          type: 'Polygon',
          properties: {
            NAME: 'Iceland',
            NAME_LONG: 'Iceland',
            ABBREV: 'Iceland',
            FORMAL_EN: 'Republic of Iceland',
            POP_EST: 339747,
            POP_RANK: 10,
            GDP_MD_EST: 16150,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IS',
            ISO_A3: 'ISL',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[364, 365, 366, 367, 368, 369, -250]],
          type: 'Polygon',
          properties: {
            NAME: 'Israel',
            NAME_LONG: 'Israel',
            ABBREV: 'Isr.',
            FORMAL_EN: 'State of Israel',
            POP_EST: 8299706,
            POP_RANK: 13,
            GDP_MD_EST: 297000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IL',
            ISO_A3: 'ISR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[[-50, 370, 371, -276, -161]], [[372]], [[373]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Italy',
            NAME_LONG: 'Italy',
            ABBREV: 'Italy',
            FORMAL_EN: 'Italian Republic',
            POP_EST: 62137802,
            POP_RANK: 16,
            GDP_MD_EST: 2221000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'IT',
            ISO_A3: 'ITA',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[374]],
          type: 'Polygon',
          properties: {
            NAME: 'Jamaica',
            NAME_LONG: 'Jamaica',
            ABBREV: 'Jam.',
            FORMAL_EN: 'Jamaica',
            POP_EST: 2990561,
            POP_RANK: 12,
            GDP_MD_EST: 25390,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'JM',
            ISO_A3: 'JAM',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[-361, 375, 376, -370, 377, -368, 378]],
          type: 'Polygon',
          properties: {
            NAME: 'Jordan',
            NAME_LONG: 'Jordan',
            ABBREV: 'Jord.',
            FORMAL_EN: 'Hashemite Kingdom of Jordan',
            POP_EST: 10248069,
            POP_RANK: 14,
            GDP_MD_EST: 86190,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'JO',
            ISO_A3: 'JOR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[[379]], [[380]], [[381]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Japan',
            NAME_LONG: 'Japan',
            ABBREV: 'Japan',
            FORMAL_EN: 'Japan',
            POP_EST: 126451398,
            POP_RANK: 17,
            GDP_MD_EST: 4932000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'JP',
            ISO_A3: 'JPN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[-169, 382, 383, 384, 385, 386]],
          type: 'Polygon',
          properties: {
            NAME: 'Kazakhstan',
            NAME_LONG: 'Kazakhstan',
            ABBREV: 'Kaz.',
            FORMAL_EN: 'Republic of Kazakhstan',
            POP_EST: 18556698,
            POP_RANK: 14,
            GDP_MD_EST: 460700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KZ',
            ISO_A3: 'KAZ',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Central Asia',
          },
        },
        {
          arcs: [[-264, 387, 388, 389, 390, 391]],
          type: 'Polygon',
          properties: {
            NAME: 'Kenya',
            NAME_LONG: 'Kenya',
            ABBREV: 'Ken.',
            FORMAL_EN: 'Republic of Kenya',
            POP_EST: 47615739,
            POP_RANK: 15,
            GDP_MD_EST: 152700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KE',
            ISO_A3: 'KEN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-168, 392, 393, -383]],
          type: 'Polygon',
          properties: {
            NAME: 'Kyrgyzstan',
            NAME_LONG: 'Kyrgyzstan',
            ABBREV: 'Kgz.',
            FORMAL_EN: 'Kyrgyz Republic',
            POP_EST: 5789122,
            POP_RANK: 13,
            GDP_MD_EST: 21010,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KG',
            ISO_A3: 'KGZ',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Central Asia',
          },
        },
        {
          arcs: [[394, 395, 396, 397]],
          type: 'Polygon',
          properties: {
            NAME: 'Cambodia',
            NAME_LONG: 'Cambodia',
            ABBREV: 'Camb.',
            FORMAL_EN: 'Kingdom of Cambodia',
            POP_EST: 16204486,
            POP_RANK: 14,
            GDP_MD_EST: 58940,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KH',
            ISO_A3: 'KHM',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[398, 399]],
          type: 'Polygon',
          properties: {
            NAME: 'South Korea',
            NAME_LONG: 'Republic of Korea',
            ABBREV: 'S.K.',
            FORMAL_EN: 'Republic of Korea',
            POP_EST: 51181299,
            POP_RANK: 16,
            GDP_MD_EST: 1929000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KR',
            ISO_A3: 'KOR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[-17, 400, 401, 402]],
          type: 'Polygon',
          properties: {
            NAME: 'Kosovo',
            NAME_LONG: 'Kosovo',
            ABBREV: 'Kos.',
            FORMAL_EN: 'Republic of Kosovo',
            POP_EST: 1895250,
            POP_RANK: 12,
            GDP_MD_EST: 18490,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'XK',
            ISO_A3: '-99',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-359, 403, 404]],
          type: 'Polygon',
          properties: {
            NAME: 'Kuwait',
            NAME_LONG: 'Kuwait',
            ABBREV: 'Kwt.',
            FORMAL_EN: 'State of Kuwait',
            POP_EST: 2875422,
            POP_RANK: 12,
            GDP_MD_EST: 301100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'KW',
            ISO_A3: 'KWT',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-176, 405, -396, 406, 407]],
          type: 'Polygon',
          properties: {
            NAME: 'Laos',
            NAME_LONG: 'Lao PDR',
            ABBREV: 'Laos',
            FORMAL_EN: "Lao People's Democratic Republic",
            POP_EST: 7126706,
            POP_RANK: 13,
            GDP_MD_EST: 40960,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LA',
            ISO_A3: 'LAO',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[-366, 408, 409]],
          type: 'Polygon',
          properties: {
            NAME: 'Lebanon',
            NAME_LONG: 'Lebanon',
            ABBREV: 'Leb.',
            FORMAL_EN: 'Lebanese Republic',
            POP_EST: 6229794,
            POP_RANK: 13,
            GDP_MD_EST: 85160,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LB',
            ISO_A3: 'LBN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-186, 410, 411, -292]],
          type: 'Polygon',
          properties: {
            NAME: 'Liberia',
            NAME_LONG: 'Liberia',
            ABBREV: 'Liberia',
            FORMAL_EN: 'Republic of Liberia',
            POP_EST: 4689021,
            POP_RANK: 12,
            GDP_MD_EST: 3881,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LR',
            ISO_A3: 'LBR',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-243, 412, 413, -248, 414, 415, 416]],
          type: 'Polygon',
          properties: {
            NAME: 'Libya',
            NAME_LONG: 'Libya',
            ABBREV: 'Libya',
            FORMAL_EN: 'Libya',
            POP_EST: 6653210,
            POP_RANK: 13,
            GDP_MD_EST: 90890,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LY',
            ISO_A3: 'LBY',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[417]],
          type: 'Polygon',
          properties: {
            NAME: 'Sri Lanka',
            NAME_LONG: 'Sri Lanka',
            ABBREV: 'Sri L.',
            FORMAL_EN: 'Democratic Socialist Republic of Sri Lanka',
            POP_EST: 22409381,
            POP_RANK: 15,
            GDP_MD_EST: 236700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LK',
            ISO_A3: 'LKA',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[418]],
          type: 'Polygon',
          properties: {
            NAME: 'Lesotho',
            NAME_LONG: 'Lesotho',
            ABBREV: 'Les.',
            FORMAL_EN: 'Kingdom of Lesotho',
            POP_EST: 1958042,
            POP_RANK: 12,
            GDP_MD_EST: 6019,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LS',
            ISO_A3: 'LSO',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Southern Africa',
          },
        },
        {
          arcs: [[-93, 419, 420, 421, 422]],
          type: 'Polygon',
          properties: {
            NAME: 'Lithuania',
            NAME_LONG: 'Lithuania',
            ABBREV: 'Lith.',
            FORMAL_EN: 'Republic of Lithuania',
            POP_EST: 2823859,
            POP_RANK: 12,
            GDP_MD_EST: 85620,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LT',
            ISO_A3: 'LTU',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-64, -223, -275]],
          type: 'Polygon',
          properties: {
            NAME: 'Luxembourg',
            NAME_LONG: 'Luxembourg',
            ABBREV: 'Lux.',
            FORMAL_EN: 'Grand Duchy of Luxembourg',
            POP_EST: 594130,
            POP_RANK: 11,
            GDP_MD_EST: 58740,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LU',
            ISO_A3: 'LUX',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[-94, -423, 423, -261, 424]],
          type: 'Polygon',
          properties: {
            NAME: 'Latvia',
            NAME_LONG: 'Latvia',
            ABBREV: 'Lat.',
            FORMAL_EN: 'Republic of Latvia',
            POP_EST: 1944643,
            POP_RANK: 12,
            GDP_MD_EST: 50650,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'LV',
            ISO_A3: 'LVA',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-240, 425, 426]],
          type: 'Polygon',
          properties: {
            NAME: 'Morocco',
            NAME_LONG: 'Morocco',
            ABBREV: 'Mor.',
            FORMAL_EN: 'Kingdom of Morocco',
            POP_EST: 33986655,
            POP_RANK: 15,
            GDP_MD_EST: 282800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MA',
            ISO_A3: 'MAR',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[427, 428]],
          type: 'Polygon',
          properties: {
            NAME: 'Moldova',
            NAME_LONG: 'Moldova',
            ABBREV: 'Mda.',
            FORMAL_EN: 'Republic of Moldova',
            POP_EST: 3474121,
            POP_RANK: 12,
            GDP_MD_EST: 18540,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MD',
            ISO_A3: 'MDA',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[429]],
          type: 'Polygon',
          properties: {
            NAME: 'Madagascar',
            NAME_LONG: 'Madagascar',
            ABBREV: 'Mad.',
            FORMAL_EN: 'Republic of Madagascar',
            POP_EST: 25054161,
            POP_RANK: 15,
            GDP_MD_EST: 36860,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MG',
            ISO_A3: 'MDG',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-98, -312, 430, 431, 432]],
          type: 'Polygon',
          properties: {
            NAME: 'Mexico',
            NAME_LONG: 'Mexico',
            ABBREV: 'Mex.',
            FORMAL_EN: 'United Mexican States',
            POP_EST: 124574795,
            POP_RANK: 17,
            GDP_MD_EST: 2307000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MX',
            ISO_A3: 'MEX',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-18, -403, 433, -85, -303]],
          type: 'Polygon',
          properties: {
            NAME: 'Macedonia',
            NAME_LONG: 'Macedonia',
            ABBREV: 'Mkd.',
            FORMAL_EN: 'Former Yugoslav Republic of Macedonia',
            POP_EST: 2103721,
            POP_RANK: 12,
            GDP_MD_EST: 29520,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MK',
            ISO_A3: 'MKD',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-76, -188, -297, 434, 435, -237, 436]],
          type: 'Polygon',
          properties: {
            NAME: 'Mali',
            NAME_LONG: 'Mali',
            ABBREV: 'Mali',
            FORMAL_EN: 'Republic of Mali',
            POP_EST: 17885245,
            POP_RANK: 14,
            GDP_MD_EST: 38090,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ML',
            ISO_A3: 'MLI',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-78, -350, -177, -408, 437, 438]],
          type: 'Polygon',
          properties: {
            NAME: 'Myanmar',
            NAME_LONG: 'Myanmar',
            ABBREV: 'Myan.',
            FORMAL_EN: 'Republic of the Union of Myanmar',
            POP_EST: 55123814,
            POP_RANK: 16,
            GDP_MD_EST: 311100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MM',
            ISO_A3: 'MMR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[-16, 439, -320, -90, 440, -401]],
          type: 'Polygon',
          properties: {
            NAME: 'Montenegro',
            NAME_LONG: 'Montenegro',
            ABBREV: 'Mont.',
            FORMAL_EN: 'Montenegro',
            POP_EST: 642550,
            POP_RANK: 11,
            GDP_MD_EST: 10610,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ME',
            ISO_A3: 'MNE',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-171, 441]],
          type: 'Polygon',
          properties: {
            NAME: 'Mongolia',
            NAME_LONG: 'Mongolia',
            ABBREV: 'Mong.',
            FORMAL_EN: 'Mongolia',
            POP_EST: 3068243,
            POP_RANK: 12,
            GDP_MD_EST: 37000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MN',
            ISO_A3: 'MNG',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[442, 443, 444, 445, 446, 447, 448, 449]],
          type: 'Polygon',
          properties: {
            NAME: 'Mozambique',
            NAME_LONG: 'Mozambique',
            ABBREV: 'Moz.',
            FORMAL_EN: 'Republic of Mozambique',
            POP_EST: 26573706,
            POP_RANK: 15,
            GDP_MD_EST: 35010,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MZ',
            ISO_A3: 'MOZ',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-238, -436, 450, 451, 452]],
          type: 'Polygon',
          properties: {
            NAME: 'Mauritania',
            NAME_LONG: 'Mauritania',
            ABBREV: 'Mrt.',
            FORMAL_EN: 'Islamic Republic of Mauritania',
            POP_EST: 3758571,
            POP_RANK: 12,
            GDP_MD_EST: 16710,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MR',
            ISO_A3: 'MRT',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-450, 453, 454]],
          type: 'Polygon',
          properties: {
            NAME: 'Malawi',
            NAME_LONG: 'Malawi',
            ABBREV: 'Mal.',
            FORMAL_EN: 'Republic of Malawi',
            POP_EST: 19196246,
            POP_RANK: 14,
            GDP_MD_EST: 21200,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MW',
            ISO_A3: 'MWI',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[[-115, 455, -344, 456]], [[457, 458]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Malaysia',
            NAME_LONG: 'Malaysia',
            ABBREV: 'Malay.',
            FORMAL_EN: 'Malaysia',
            POP_EST: 31381992,
            POP_RANK: 15,
            GDP_MD_EST: 863000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'MY',
            ISO_A3: 'MYS',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[-7, 459, -119, 460, 461]],
          type: 'Polygon',
          properties: {
            NAME: 'Namibia',
            NAME_LONG: 'Namibia',
            ABBREV: 'Nam.',
            FORMAL_EN: 'Republic of Namibia',
            POP_EST: 2484780,
            POP_RANK: 12,
            GDP_MD_EST: 25990,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NA',
            ISO_A3: 'NAM',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Southern Africa',
          },
        },
        {
          arcs: [[462]],
          type: 'Polygon',
          properties: {
            NAME: 'New Caledonia',
            NAME_LONG: 'New Caledonia',
            ABBREV: 'New C.',
            FORMAL_EN: 'New Caledonia',
            POP_EST: 279070,
            POP_RANK: 10,
            GDP_MD_EST: 10770,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NC',
            ISO_A3: 'NCL',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Melanesia',
          },
        },
        {
          arcs: [[-71, -77, -437, -244, -417, 463, -194, 464]],
          type: 'Polygon',
          properties: {
            NAME: 'Niger',
            NAME_LONG: 'Niger',
            ABBREV: 'Niger',
            FORMAL_EN: 'Republic of Niger',
            POP_EST: 19245344,
            POP_RANK: 14,
            GDP_MD_EST: 20150,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NE',
            ISO_A3: 'NER',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-72, -465, -193, 465]],
          type: 'Polygon',
          properties: {
            NAME: 'Nigeria',
            NAME_LONG: 'Nigeria',
            ABBREV: 'Nigeria',
            FORMAL_EN: 'Federal Republic of Nigeria',
            POP_EST: 190632261,
            POP_RANK: 17,
            GDP_MD_EST: 1089000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NG',
            ISO_A3: 'NGA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-212, 466, -317, 467]],
          type: 'Polygon',
          properties: {
            NAME: 'Nicaragua',
            NAME_LONG: 'Nicaragua',
            ABBREV: 'Nic.',
            FORMAL_EN: 'Republic of Nicaragua',
            POP_EST: 6025951,
            POP_RANK: 13,
            GDP_MD_EST: 33550,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NI',
            ISO_A3: 'NIC',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-67, 468, -224]],
          type: 'Polygon',
          properties: {
            NAME: 'Netherlands',
            NAME_LONG: 'Netherlands',
            ABBREV: 'Neth.',
            FORMAL_EN: 'Kingdom of the Netherlands',
            POP_EST: 17084719,
            POP_RANK: 14,
            GDP_MD_EST: 870800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NL',
            ISO_A3: 'NLD',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Western Europe',
          },
        },
        {
          arcs: [[[-268, 469, 470, 471]], [[472]], [[473]], [[474]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Norway',
            NAME_LONG: 'Norway',
            ABBREV: 'Nor.',
            FORMAL_EN: 'Kingdom of Norway',
            POP_EST: 5320045,
            POP_RANK: 13,
            GDP_MD_EST: 364700,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NO',
            ISO_A3: 'NOR',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-180, -349]],
          type: 'Polygon',
          properties: {
            NAME: 'Nepal',
            NAME_LONG: 'Nepal',
            ABBREV: 'Nepal',
            FORMAL_EN: 'Nepal',
            POP_EST: 29384297,
            POP_RANK: 15,
            GDP_MD_EST: 71520,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NP',
            ISO_A3: 'NPL',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[[475]], [[476]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'New Zealand',
            NAME_LONG: 'New Zealand',
            ABBREV: 'N.Z.',
            FORMAL_EN: 'New Zealand',
            POP_EST: 4510327,
            POP_RANK: 12,
            GDP_MD_EST: 174800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'NZ',
            ISO_A3: 'NZL',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Australia and New Zealand',
          },
        },
        {
          arcs: [[[-20, 477]], [[-22, 478, 479, 480]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Oman',
            NAME_LONG: 'Oman',
            ABBREV: 'Oman',
            FORMAL_EN: 'Sultanate of Oman',
            POP_EST: 3424386,
            POP_RANK: 12,
            GDP_MD_EST: 173100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'OM',
            ISO_A3: 'OMN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-5, -182, -348, 481, -352]],
          type: 'Polygon',
          properties: {
            NAME: 'Pakistan',
            NAME_LONG: 'Pakistan',
            ABBREV: 'Pak.',
            FORMAL_EN: 'Islamic Republic of Pakistan',
            POP_EST: 204924861,
            POP_RANK: 17,
            GDP_MD_EST: 988200,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PK',
            ISO_A3: 'PAK',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Southern Asia',
          },
        },
        {
          arcs: [[-208, 482, -214, 483]],
          type: 'Polygon',
          properties: {
            NAME: 'Panama',
            NAME_LONG: 'Panama',
            ABBREV: 'Pan.',
            FORMAL_EN: 'Republic of Panama',
            POP_EST: 3753142,
            POP_RANK: 12,
            GDP_MD_EST: 93120,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PA',
            ISO_A3: 'PAN',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-102, -166, 484, -245, -205, -106]],
          type: 'Polygon',
          properties: {
            NAME: 'Peru',
            NAME_LONG: 'Peru',
            ABBREV: 'Peru',
            FORMAL_EN: 'Republic of Peru',
            POP_EST: 31036656,
            POP_RANK: 15,
            GDP_MD_EST: 410400,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PE',
            ISO_A3: 'PER',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[[485]], [[486]], [[487]], [[488]], [[489]], [[490]], [[491]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Philippines',
            NAME_LONG: 'Philippines',
            ABBREV: 'Phil.',
            FORMAL_EN: 'Republic of the Philippines',
            POP_EST: 104256076,
            POP_RANK: 17,
            GDP_MD_EST: 801900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PH',
            ISO_A3: 'PHL',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[[-340, 492]], [[493]], [[494]], [[495]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Papua New Guinea',
            NAME_LONG: 'Papua New Guinea',
            ABBREV: 'P.N.G.',
            FORMAL_EN: 'Independent State of Papua New Guinea',
            POP_EST: 6909701,
            POP_RANK: 13,
            GDP_MD_EST: 28020,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PG',
            ISO_A3: 'PNG',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Melanesia',
          },
        },
        {
          arcs: [[-97, 496, 497, -220, -228, 498, 499, -420]],
          type: 'Polygon',
          properties: {
            NAME: 'Poland',
            NAME_LONG: 'Poland',
            ABBREV: 'Pol.',
            FORMAL_EN: 'Republic of Poland',
            POP_EST: 38476269,
            POP_RANK: 15,
            GDP_MD_EST: 1052000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PL',
            ISO_A3: 'POL',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[500]],
          type: 'Polygon',
          properties: {
            NAME: 'Puerto Rico',
            NAME_LONG: 'Puerto Rico',
            ABBREV: 'P.R.',
            FORMAL_EN: 'Commonwealth of Puerto Rico',
            POP_EST: 3351827,
            POP_RANK: 12,
            GDP_MD_EST: 131000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PR',
            ISO_A3: 'PRI',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[-173, 501, 502, -400, 503]],
          type: 'Polygon',
          properties: {
            NAME: 'North Korea',
            NAME_LONG: 'Dem. Rep. Korea',
            ABBREV: 'N.K.',
            FORMAL_EN: "Democratic People's Republic of Korea",
            POP_EST: 25248140,
            POP_RANK: 15,
            GDP_MD_EST: 40000,
            POP_YEAR: 2013,
            GDP_YEAR: 2016,
            ISO_A2: 'KP',
            ISO_A3: 'PRK',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[-258, 504]],
          type: 'Polygon',
          properties: {
            NAME: 'Portugal',
            NAME_LONG: 'Portugal',
            ABBREV: 'Port.',
            FORMAL_EN: 'Portuguese Republic',
            POP_EST: 10839514,
            POP_RANK: 14,
            GDP_MD_EST: 297100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PT',
            ISO_A3: 'PRT',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-28, -104, -105]],
          type: 'Polygon',
          properties: {
            NAME: 'Paraguay',
            NAME_LONG: 'Paraguay',
            ABBREV: 'Para.',
            FORMAL_EN: 'Republic of Paraguay',
            POP_EST: 6943739,
            POP_RANK: 13,
            GDP_MD_EST: 64670,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PY',
            ISO_A3: 'PRY',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[-369, -378]],
          type: 'Polygon',
          properties: {
            NAME: 'Palestine',
            NAME_LONG: 'Palestine',
            ABBREV: 'Pal.',
            FORMAL_EN: 'West Bank and Gaza',
            POP_EST: 4543126,
            POP_RANK: 12,
            GDP_MD_EST: 21220.77,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'PS',
            ISO_A3: 'PSE',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[505, 506]],
          type: 'Polygon',
          properties: {
            NAME: 'Qatar',
            NAME_LONG: 'Qatar',
            ABBREV: 'Qatar',
            FORMAL_EN: 'State of Qatar',
            POP_EST: 2314307,
            POP_RANK: 12,
            GDP_MD_EST: 334500,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'QA',
            ISO_A3: 'QAT',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-81, 507, -328, 508, -429, 509, 510]],
          type: 'Polygon',
          properties: {
            NAME: 'Romania',
            NAME_LONG: 'Romania',
            ABBREV: 'Rom.',
            FORMAL_EN: 'Romania',
            POP_EST: 21529967,
            POP_RANK: 15,
            GDP_MD_EST: 441000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'RO',
            ISO_A3: 'ROU',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [
            [
              [
                -56, -289, 511, 512, -95, -425, -260, 513, -269, -472, 514, -502, -172, -442, -170,
                -387, 515,
              ],
            ],
            [[-421, -500, 516]],
            [[517, 518]],
            [[519]],
            [[520]],
            [[521]],
            [[522]],
            [[523]],
            [[524]],
            [[525]],
            [[526]],
            [[527]],
            [[528]],
            [[529]],
          ],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Russia',
            NAME_LONG: 'Russian Federation',
            ABBREV: 'Rus.',
            FORMAL_EN: 'Russian Federation',
            POP_EST: 142257519,
            POP_RANK: 17,
            GDP_MD_EST: 3745000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'RU',
            ISO_A3: 'RUS',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[-61, -200, 530, 531]],
          type: 'Polygon',
          properties: {
            NAME: 'Rwanda',
            NAME_LONG: 'Rwanda',
            ABBREV: 'Rwa.',
            FORMAL_EN: 'Republic of Rwanda',
            POP_EST: 11901484,
            POP_RANK: 14,
            GDP_MD_EST: 21970,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'RW',
            ISO_A3: 'RWA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-239, -453, 532, -426]],
          type: 'Polygon',
          properties: {
            NAME: 'W. Sahara',
            NAME_LONG: 'Western Sahara',
            ABBREV: 'W. Sah.',
            FORMAL_EN: 'Sahrawi Arab Democratic Republic',
            POP_EST: 603253,
            POP_RANK: 11,
            GDP_MD_EST: 906.5,
            POP_YEAR: 2017,
            GDP_YEAR: 2007,
            ISO_A2: 'EH',
            ISO_A3: 'ESH',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[-23, -481, 533, 534, -376, -360, -405, 535, -507, 536]],
          type: 'Polygon',
          properties: {
            NAME: 'Saudi Arabia',
            NAME_LONG: 'Saudi Arabia',
            ABBREV: 'Saud.',
            FORMAL_EN: 'Kingdom of Saudi Arabia',
            POP_EST: 28571770,
            POP_RANK: 15,
            GDP_MD_EST: 1731000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SA',
            ISO_A3: 'SAU',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-123, 537, -415, -247, 538, -253, -266, 539]],
          type: 'Polygon',
          properties: {
            NAME: 'Sudan',
            NAME_LONG: 'Sudan',
            ABBREV: 'Sudan',
            FORMAL_EN: 'Republic of the Sudan',
            POP_EST: 37345935,
            POP_RANK: 15,
            GDP_MD_EST: 176300,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SD',
            ISO_A3: 'SDN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[-124, -540, -265, -392, 540, -198]],
          type: 'Polygon',
          properties: {
            NAME: 'S. Sudan',
            NAME_LONG: 'South Sudan',
            ABBREV: 'S. Sud.',
            FORMAL_EN: 'Republic of South Sudan',
            POP_EST: 13026129,
            POP_RANK: 14,
            GDP_MD_EST: 20880,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SS',
            ISO_A3: 'SSD',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-296, -301, 541, -299, 542, -451, -435]],
          type: 'Polygon',
          properties: {
            NAME: 'Senegal',
            NAME_LONG: 'Senegal',
            ABBREV: 'Sen.',
            FORMAL_EN: 'Republic of Senegal',
            POP_EST: 14668522,
            POP_RANK: 14,
            GDP_MD_EST: 39720,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SN',
            ISO_A3: 'SEN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[[543]], [[544]], [[545]], [[546]], [[547]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Solomon Is.',
            NAME_LONG: 'Solomon Islands',
            ABBREV: 'S. Is.',
            FORMAL_EN: '',
            POP_EST: 647581,
            POP_RANK: 11,
            GDP_MD_EST: 1198,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SB',
            ISO_A3: 'SLB',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Melanesia',
          },
        },
        {
          arcs: [[-293, -412, 548]],
          type: 'Polygon',
          properties: {
            NAME: 'Sierra Leone',
            NAME_LONG: 'Sierra Leone',
            ABBREV: 'S.L.',
            FORMAL_EN: 'Republic of Sierra Leone',
            POP_EST: 6163195,
            POP_RANK: 13,
            GDP_MD_EST: 10640,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SL',
            ISO_A3: 'SLE',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-310, -319, 549]],
          type: 'Polygon',
          properties: {
            NAME: 'El Salvador',
            NAME_LONG: 'El Salvador',
            ABBREV: 'El. S.',
            FORMAL_EN: 'Republic of El Salvador',
            POP_EST: 6172011,
            POP_RANK: 13,
            GDP_MD_EST: 54790,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SV',
            ISO_A3: 'SLV',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Central America',
          },
        },
        {
          arcs: [[-230, 550, 551, -262]],
          type: 'Polygon',
          properties: {
            NAME: 'Somaliland',
            NAME_LONG: 'Somaliland',
            ABBREV: 'Solnd.',
            FORMAL_EN: 'Republic of Somaliland',
            POP_EST: 3500000,
            POP_RANK: 12,
            GDP_MD_EST: 12250,
            POP_YEAR: 2013,
            GDP_YEAR: 2013,
            ISO_A2: '-99',
            ISO_A3: '-99',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-263, -552, 552, -388]],
          type: 'Polygon',
          properties: {
            NAME: 'Somalia',
            NAME_LONG: 'Somalia',
            ABBREV: 'Som.',
            FORMAL_EN: 'Federal Republic of Somalia',
            POP_EST: 7531386,
            POP_RANK: 13,
            GDP_MD_EST: 4719,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SO',
            ISO_A3: 'SOM',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-86, -434, -402, -441, -92, -324, -329, -508]],
          type: 'Polygon',
          properties: {
            NAME: 'Serbia',
            NAME_LONG: 'Serbia',
            ABBREV: 'Serb.',
            FORMAL_EN: 'Republic of Serbia',
            POP_EST: 7111024,
            POP_RANK: 13,
            GDP_MD_EST: 101800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'RS',
            ISO_A3: 'SRB',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-110, -315, 553, -279]],
          type: 'Polygon',
          properties: {
            NAME: 'Suriname',
            NAME_LONG: 'Suriname',
            ABBREV: 'Sur.',
            FORMAL_EN: 'Republic of Suriname',
            POP_EST: 591919,
            POP_RANK: 11,
            GDP_MD_EST: 8547,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SR',
            ISO_A3: 'SUR',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[-54, -221, -498, 554, -326]],
          type: 'Polygon',
          properties: {
            NAME: 'Slovakia',
            NAME_LONG: 'Slovakia',
            ABBREV: 'Svk.',
            FORMAL_EN: 'Slovak Republic',
            POP_EST: 5445829,
            POP_RANK: 13,
            GDP_MD_EST: 168800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SK',
            ISO_A3: 'SVK',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[-49, -330, -322, 555, -371]],
          type: 'Polygon',
          properties: {
            NAME: 'Slovenia',
            NAME_LONG: 'Slovenia',
            ABBREV: 'Slo.',
            FORMAL_EN: 'Republic of Slovenia',
            POP_EST: 1972126,
            POP_RANK: 12,
            GDP_MD_EST: 68350,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SI',
            ISO_A3: 'SVN',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Southern Europe',
          },
        },
        {
          arcs: [[-267, 556, -470]],
          type: 'Polygon',
          properties: {
            NAME: 'Sweden',
            NAME_LONG: 'Sweden',
            ABBREV: 'Swe.',
            FORMAL_EN: 'Kingdom of Sweden',
            POP_EST: 9960487,
            POP_RANK: 13,
            GDP_MD_EST: 498100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SE',
            ISO_A3: 'SWE',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Northern Europe',
          },
        },
        {
          arcs: [[-446, 557]],
          type: 'Polygon',
          properties: {
            NAME: 'Swaziland',
            NAME_LONG: 'Swaziland',
            ABBREV: 'Swz.',
            FORMAL_EN: 'Kingdom of Swaziland',
            POP_EST: 1467152,
            POP_RANK: 12,
            GDP_MD_EST: 11060,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'SZ',
            ISO_A3: 'SWZ',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Southern Africa',
          },
        },
        {
          arcs: [[-362, -379, -367, -410, 558, 559]],
          type: 'Polygon',
          properties: {
            NAME: 'Syria',
            NAME_LONG: 'Syria',
            ABBREV: 'Syria',
            FORMAL_EN: 'Syrian Arab Republic',
            POP_EST: 18028549,
            POP_RANK: 14,
            GDP_MD_EST: 50280,
            POP_YEAR: 2017,
            GDP_YEAR: 2015,
            ISO_A2: 'SY',
            ISO_A3: 'SYR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-122, -195, -464, -416, -538]],
          type: 'Polygon',
          properties: {
            NAME: 'Chad',
            NAME_LONG: 'Chad',
            ABBREV: 'Chad',
            FORMAL_EN: 'Republic of Chad',
            POP_EST: 12075985,
            POP_RANK: 14,
            GDP_MD_EST: 30590,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TD',
            ISO_A3: 'TCD',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Middle Africa',
          },
        },
        {
          arcs: [[-69, 560, -290, -73]],
          type: 'Polygon',
          properties: {
            NAME: 'Togo',
            NAME_LONG: 'Togo',
            ABBREV: 'Togo',
            FORMAL_EN: 'Togolese Republic',
            POP_EST: 7965055,
            POP_RANK: 13,
            GDP_MD_EST: 11610,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TG',
            ISO_A3: 'TGO',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Western Africa',
          },
        },
        {
          arcs: [[-395, 561, -459, 562, -438, -407]],
          type: 'Polygon',
          properties: {
            NAME: 'Thailand',
            NAME_LONG: 'Thailand',
            ABBREV: 'Thai.',
            FORMAL_EN: 'Kingdom of Thailand',
            POP_EST: 68414135,
            POP_RANK: 16,
            GDP_MD_EST: 1161000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TH',
            ISO_A3: 'THA',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[-3, 563, -393, -167]],
          type: 'Polygon',
          properties: {
            NAME: 'Tajikistan',
            NAME_LONG: 'Tajikistan',
            ABBREV: 'Tjk.',
            FORMAL_EN: 'Republic of Tajikistan',
            POP_EST: 8468555,
            POP_RANK: 13,
            GDP_MD_EST: 25810,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TJ',
            ISO_A3: 'TJK',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Central Asia',
          },
        },
        {
          arcs: [[-1, -357, 564, -385, 565]],
          type: 'Polygon',
          properties: {
            NAME: 'Turkmenistan',
            NAME_LONG: 'Turkmenistan',
            ABBREV: 'Turkm.',
            FORMAL_EN: 'Turkmenistan',
            POP_EST: 5351277,
            POP_RANK: 13,
            GDP_MD_EST: 94720,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TM',
            ISO_A3: 'TKM',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Central Asia',
          },
        },
        {
          arcs: [[-332, 566]],
          type: 'Polygon',
          properties: {
            NAME: 'Timor-Leste',
            NAME_LONG: 'Timor-Leste',
            ABBREV: 'T.L.',
            FORMAL_EN: 'Democratic Republic of Timor-Leste',
            POP_EST: 1291358,
            POP_RANK: 12,
            GDP_MD_EST: 4975,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TL',
            ISO_A3: 'TLS',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[567]],
          type: 'Polygon',
          properties: {
            NAME: 'Trinidad and Tobago',
            NAME_LONG: 'Trinidad and Tobago',
            ABBREV: 'Tr.T.',
            FORMAL_EN: 'Republic of Trinidad and Tobago',
            POP_EST: 1218208,
            POP_RANK: 12,
            GDP_MD_EST: 43570,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TT',
            ISO_A3: 'TTO',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Caribbean',
          },
        },
        {
          arcs: [[-242, 568, -413]],
          type: 'Polygon',
          properties: {
            NAME: 'Tunisia',
            NAME_LONG: 'Tunisia',
            ABBREV: 'Tun.',
            FORMAL_EN: 'Republic of Tunisia',
            POP_EST: 11403800,
            POP_RANK: 14,
            GDP_MD_EST: 130800,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TN',
            ISO_A3: 'TUN',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Northern Africa',
          },
        },
        {
          arcs: [[[-36, -355, -363, -560, 569, -287]], [[-83, 570, -304]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Turkey',
            NAME_LONG: 'Turkey',
            ABBREV: 'Tur.',
            FORMAL_EN: 'Republic of Turkey',
            POP_EST: 80845215,
            POP_RANK: 16,
            GDP_MD_EST: 1670000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TR',
            ISO_A3: 'TUR',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[571]],
          type: 'Polygon',
          properties: {
            NAME: 'Taiwan',
            NAME_LONG: 'Taiwan',
            ABBREV: 'Taiwan',
            FORMAL_EN: '',
            POP_EST: 23508428,
            POP_RANK: 15,
            GDP_MD_EST: 1127000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TW',
            ISO_A3: 'TWN',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Eastern Asia',
          },
        },
        {
          arcs: [[-62, -532, 572, -390, 573, -443, -455, 574, -201]],
          type: 'Polygon',
          properties: {
            NAME: 'Tanzania',
            NAME_LONG: 'Tanzania',
            ABBREV: 'Tanz.',
            FORMAL_EN: 'United Republic of Tanzania',
            POP_EST: 53950935,
            POP_RANK: 16,
            GDP_MD_EST: 150600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'TZ',
            ISO_A3: 'TZA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-199, -541, -391, -573, -531]],
          type: 'Polygon',
          properties: {
            NAME: 'Uganda',
            NAME_LONG: 'Uganda',
            ABBREV: 'Uga.',
            FORMAL_EN: 'Republic of Uganda',
            POP_EST: 39570125,
            POP_RANK: 15,
            GDP_MD_EST: 84930,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'UG',
            ISO_A3: 'UGA',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-96, -513, 575, -518, 576, -510, -428, -509, -327, -555, -497]],
          type: 'Polygon',
          properties: {
            NAME: 'Ukraine',
            NAME_LONG: 'Ukraine',
            ABBREV: 'Ukr.',
            FORMAL_EN: 'Ukraine',
            POP_EST: 44033874,
            POP_RANK: 15,
            GDP_MD_EST: 352600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'UA',
            ISO_A3: 'UKR',
            CONTINENT: 'Europe',
            REGION_UN: 'Europe',
            SUBREGION: 'Eastern Europe',
          },
        },
        {
          arcs: [[-30, -113, 577]],
          type: 'Polygon',
          properties: {
            NAME: 'Uruguay',
            NAME_LONG: 'Uruguay',
            ABBREV: 'Ury.',
            FORMAL_EN: 'Oriental Republic of Uruguay',
            POP_EST: 3360148,
            POP_RANK: 12,
            GDP_MD_EST: 73250,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'UY',
            ISO_A3: 'URY',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [
            [[-141, 578, -432, 579]],
            [[-139, 580]],
            [[581]],
            [[582]],
            [[583]],
            [[584]],
            [[585]],
            [[586]],
            [[587]],
            [[588]],
          ],
          type: 'MultiPolygon',
          properties: {
            NAME: 'United States of America',
            NAME_LONG: 'United States',
            ABBREV: 'U.S.A.',
            FORMAL_EN: 'United States of America',
            POP_EST: 326625791,
            POP_RANK: 17,
            GDP_MD_EST: 18560000,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'US',
            ISO_A3: 'USA',
            CONTINENT: 'North America',
            REGION_UN: 'Americas',
            SUBREGION: 'Northern America',
          },
        },
        {
          arcs: [[-2, -566, -384, -394, -564]],
          type: 'Polygon',
          properties: {
            NAME: 'Uzbekistan',
            NAME_LONG: 'Uzbekistan',
            ABBREV: 'Uzb.',
            FORMAL_EN: 'Republic of Uzbekistan',
            POP_EST: 29748859,
            POP_RANK: 15,
            GDP_MD_EST: 202300,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'UZ',
            ISO_A3: 'UZB',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Central Asia',
          },
        },
        {
          arcs: [[-108, -210, 589, -313]],
          type: 'Polygon',
          properties: {
            NAME: 'Venezuela',
            NAME_LONG: 'Venezuela',
            ABBREV: 'Ven.',
            FORMAL_EN: 'Bolivarian Republic of Venezuela',
            POP_EST: 31304016,
            POP_RANK: 15,
            GDP_MD_EST: 468600,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'VE',
            ISO_A3: 'VEN',
            CONTINENT: 'South America',
            REGION_UN: 'Americas',
            SUBREGION: 'South America',
          },
        },
        {
          arcs: [[-175, 590, -397, -406]],
          type: 'Polygon',
          properties: {
            NAME: 'Vietnam',
            NAME_LONG: 'Vietnam',
            ABBREV: 'Viet.',
            FORMAL_EN: 'Socialist Republic of Vietnam',
            POP_EST: 96160163,
            POP_RANK: 16,
            GDP_MD_EST: 594900,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'VN',
            ISO_A3: 'VNM',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'South-Eastern Asia',
          },
        },
        {
          arcs: [[[591]], [[592]]],
          type: 'MultiPolygon',
          properties: {
            NAME: 'Vanuatu',
            NAME_LONG: 'Vanuatu',
            ABBREV: 'Van.',
            FORMAL_EN: 'Republic of Vanuatu',
            POP_EST: 282814,
            POP_RANK: 10,
            GDP_MD_EST: 723,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'VU',
            ISO_A3: 'VUT',
            CONTINENT: 'Oceania',
            REGION_UN: 'Oceania',
            SUBREGION: 'Melanesia',
          },
        },
        {
          arcs: [[-480, 593, -534]],
          type: 'Polygon',
          properties: {
            NAME: 'Yemen',
            NAME_LONG: 'Yemen',
            ABBREV: 'Yem.',
            FORMAL_EN: 'Republic of Yemen',
            POP_EST: 28036829,
            POP_RANK: 15,
            GDP_MD_EST: 73450,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'YE',
            ISO_A3: 'YEM',
            CONTINENT: 'Asia',
            REGION_UN: 'Asia',
            SUBREGION: 'Western Asia',
          },
        },
        {
          arcs: [[-118, 594, -447, -558, -445, 595, -461], [-419]],
          type: 'Polygon',
          properties: {
            NAME: 'South Africa',
            NAME_LONG: 'South Africa',
            ABBREV: 'S.Af.',
            FORMAL_EN: 'Republic of South Africa',
            POP_EST: 54841552,
            POP_RANK: 16,
            GDP_MD_EST: 739100,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ZA',
            ISO_A3: 'ZAF',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Southern Africa',
          },
        },
        {
          arcs: [[-10, -202, -575, -454, -449, 596, -120, -460]],
          type: 'Polygon',
          properties: {
            NAME: 'Zambia',
            NAME_LONG: 'Zambia',
            ABBREV: 'Zambia',
            FORMAL_EN: 'Republic of Zambia',
            POP_EST: 15972000,
            POP_RANK: 14,
            GDP_MD_EST: 65170,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ZM',
            ISO_A3: 'ZMB',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
        {
          arcs: [[-121, -597, -448, -595]],
          type: 'Polygon',
          properties: {
            NAME: 'Zimbabwe',
            NAME_LONG: 'Zimbabwe',
            ABBREV: 'Zimb.',
            FORMAL_EN: 'Republic of Zimbabwe',
            POP_EST: 13805084,
            POP_RANK: 14,
            GDP_MD_EST: 28330,
            POP_YEAR: 2017,
            GDP_YEAR: 2016,
            ISO_A2: 'ZW',
            ISO_A3: 'ZWE',
            CONTINENT: 'Africa',
            REGION_UN: 'Africa',
            SUBREGION: 'Eastern Africa',
          },
        },
      ],
    },
  },
}
