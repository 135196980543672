import { SVGProps } from 'react'
function MicrosoftOfficeOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='35'
      viewBox='0 0 29 35'
      fill='none'
      {...props}>
      <path
        d='M0 27.9939V6.89701L18.6624 0L28.8047 3.24567V31.645L18.6624 34.7023L0 27.9937L18.6624 30.2396V5.6801L6.49105 8.52002V25.1541L0 27.9939Z'
        fill='#EB3C00'
      />
    </svg>
  )
}

export default MicrosoftOfficeOriginal
