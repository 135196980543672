import { SnackbarContext } from '@invisible/common/providers'
import { fromGlobalId, useExportTaskProfilerDataQuery } from '@invisible/concorde/gql-client'
import DownloadIcon from '@mui/icons-material/Download'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { omit } from 'lodash/fp'
import { useContext } from 'react'

import { computeFileSize, IExportTaskInReview } from '../Activity'
import { useGenerateSignedUrl } from '../hooks/useGenerateSignedUrl'
import { PAPER_STYLE } from './ReviewExportTaskDialog'
import { ReviewInstructions } from './ReviewInstructions'

interface IProps {
  exportTask: IExportTaskInReview | null
}

const KEY_TO_TITLE_MAPPING = {
  description: 'Description',
  numVariables: 'Number of variables',
  numObservations: 'Number of observations',
  cellsMissing: 'Missing cells',
  percentCellsMissing: 'Missing cells (%)',
  duplicateRows: 'Duplicate rows',
  percentDuplicateRows: 'Duplicate rows (%)',
  totalSizeInMemory: 'Total size in memory',
  avgRecordSizeInMemory: 'Average record size in memory',
}
type Key = keyof typeof KEY_TO_TITLE_MAPPING

const getTitle = (key: Key) => KEY_TO_TITLE_MAPPING[key]

const transformValueByKey = (key: string, value: number) => {
  if (['percentCellsMissing', 'percentDuplicateRows'].includes(key)) {
    const percentage = (value * 100).toFixed(2)
    return `${percentage}%`
  }

  if (['totalSizeInMemory', 'avgRecordSizeInMemory'].includes(key)) {
    return computeFileSize(value)
  }

  return value
}

const DataProfileOverview = ({ exportTask }: IProps) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { generateSignedUrl, isGenerateSignedUrlLoading } = useGenerateSignedUrl()

  const { data } = useExportTaskProfilerDataQuery(
    {
      id: exportTask?.exportTaskId,
    },
    {
      enabled: !!exportTask?.exportTaskId,
      onError: () => {
        showSnackbar({
          message: 'Unexpected error occurred while fetching export task profiler data.',
          variant: 'error',
        })
      },
    }
  )
  const profileData = data?.exportTasks.edges[0]?.node.outputFileMetadata

  const handleDownloadDataProfile = async () => {
    if (!exportTask?.exportTaskId) return
    const data = await generateSignedUrl({
      exportTaskId: fromGlobalId(exportTask?.exportTaskId),
      isYdataProfile: true,
    })
    if (data.signed_url) {
      window.open(data.signed_url, '_blank')
      return
    }
    showSnackbar({
      message: 'Unexpected error occurred while downloading data profile.',
      variant: 'error',
    })
  }

  if (!profileData)
    return (
      <Paper style={PAPER_STYLE}>
        <Typography variant='h6'>Data profile is unavailable.</Typography>
      </Paper>
    )

  return (
    <Paper style={PAPER_STYLE}>
      <Stack direction={'row'} spacing={2} justifyContent='space-between'>
        <Stack direction={'column'} spacing={2}>
          <Typography variant='h6'>Overview</Typography>

          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='caption'>How to review an export</Typography>
            <Tooltip
              title={<ReviewInstructions />}
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#ffff',
                  },
                },
              }}>
              <InfoOutlinedIcon sx={{ fontSize: '16px', opacity: '70%' }} />
            </Tooltip>
          </Stack>
        </Stack>

        <Stack direction={'column'} spacing={2}>
          <LoadingButton
            variant='text'
            startIcon={<OpenInNewIcon />}
            loading={isGenerateSignedUrlLoading}
            disabled={!profileData}
            onClick={handleDownloadDataProfile}>
            OPEN DATA PROFILE
          </LoadingButton>
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', maxWidth: 520, bgcolor: 'background.paper' }}>
            <ListItem alignItems='flex-start'>
              <Typography variant='body2' fontSize='16px' fontWeight='bold'>
                Dataset statistics
              </Typography>
            </ListItem>

            <Divider component='li' />

            {Object.entries(omit(['types', 'description'], profileData)).map(([key, value]) => (
              <Box key={key}>
                <ListItem
                  alignItems='flex-start'
                  secondaryAction={
                    <Typography variant='body2'>{transformValueByKey(key, value)}</Typography>
                  }>
                  <Typography variant='body2' fontWeight='bold'>
                    {getTitle(key as Key)}
                  </Typography>
                </ListItem>

                <Divider component='li' />
              </Box>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', maxWidth: 520, bgcolor: 'background.paper' }}>
            <ListItem alignItems='flex-start'>
              <Typography variant='body2' fontSize='16px' fontWeight='bold'>
                Variable types
              </Typography>
            </ListItem>

            <Divider component='li' />

            {Object.entries(profileData.types).map(([key, value]) => (
              <Box key={key}>
                <ListItem
                  alignItems='flex-start'
                  secondaryAction={<Typography variant='body2'>{value}</Typography>}>
                  <Typography variant='body2' fontWeight='bold'>
                    {key}
                  </Typography>
                </ListItem>

                <Divider component='li' />
              </Box>
            ))}

            <Divider component='li' />
          </List>
        </Grid>
      </Grid>

      {profileData.description ? (
        <Alert
          severity='warning'
          sx={{ fontSize: '12px', position: 'absolute', left: 0, bottom: 0 }}>
          {profileData.description}
        </Alert>
      ) : null}
    </Paper>
  )
}

export { DataProfileOverview }
