import { z } from '@invisible/zod'

import { predicateSchema } from '../predicate'
import { numericArgSchema } from './args'

/**
 * Formula that takes 1 or more numeric arguments and returns a boolean.
 */
const numericPredicateSchema = predicateSchema
  .omit({ args: true })
  .extend({ args: numericArgSchema.array().min(1) })
  .strict()

/**
 * Formula that takes 1 or more numeric arguments and returns a boolean.
 */
type TNumericPredicate = z.infer<typeof numericPredicateSchema>

/**
 * Formula that takes exactly two numeric arguments and returns a boolean.
 */
const numericComparisonSchema = numericPredicateSchema
  .omit({ args: true })
  .extend({ args: numericArgSchema.array().length(2) })
  .strict()

/**
 * Formula that takes exactly two numeric arguments and returns a boolean.
 */
type TNumericComparison = z.infer<typeof numericComparisonSchema>

/**
 * Formula that takes exactly one numeric argument and returns a boolean.
 */
const numericUnaryPredicateSchema = numericPredicateSchema
  .omit({ args: true })
  .extend({ args: numericArgSchema.array().length(1) })
  .strict()

/**
 * Formula that takes exactly one numeric argument and returns a boolean.
 */
type TNumericUnaryPredicate = z.infer<typeof numericUnaryPredicateSchema>

export { numericComparisonSchema, numericPredicateSchema, numericUnaryPredicateSchema }
export type { TNumericComparison, TNumericPredicate, TNumericUnaryPredicate }
