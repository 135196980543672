import { z } from '@invisible/zod'


const schema = z.object({
  baseVariableId: z.string().uuid().optional(),
  stepId: z.string().uuid().optional(),
})

type TSchema = z.infer<typeof schema>


export { schema }
export type { TSchema }
