import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { toGlobalId, useStepRunWithTimeLogDurationQuery } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { InfoOutlineIcon } from '@invisible/ui/icons'
import { Tooltip } from '@invisible/ui/tooltip'
import Box from '@mui/material/Box'
import { differenceInSeconds } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGate } from 'statsig-react'

import { getFormattedTimeString } from './common/helpers'
import { useSnoozeActivitiesFindAllInactiveForStepRun } from './hooks/useSnoozeActivitiesFindAllInactiveForStepRun'

// eslint-disable-next-line @typescript-eslint/ban-types
const WizardTimer: React.FC<{
  isOffline: boolean
  isAutoSnoozed: boolean
}> = ({ isOffline, isAutoSnoozed }) => {
  const { state } = useWizardState()
  const { value: enableAutoSnoozeV2 } = useGate('enable-auto-snooze-v2')

  const [elapsedTime, setElapsedTime] = useState(0)
  const [timeLogStartTime, setTimeLogStartTime] = useState(0)
  const intervalRef = useRef<number | null>(null)

  const { data: stepRunSnoozeActivities } = useSnoozeActivitiesFindAllInactiveForStepRun({
    stepRunId: state.stepRun?.id as string,
    enabled: !enableAutoSnoozeV2,
  })

  useStepRunWithTimeLogDurationQuery(
    {
      stepRunId: toGlobalId('StepRunType', state.stepRun?.id as string),
    },
    {
      enabled: Boolean(state.stepRun?.id as string) && enableAutoSnoozeV2,
      onSuccess: (data) => {
        if (data.stepRunsV2.edges.length) {
          const stepRunElapsedTime = data.stepRunsV2.edges[0]?.node?.totalDuration ?? 0
          const clockTime = Date.now() // Current time in milliseconds
          setTimeLogStartTime(clockTime - stepRunElapsedTime * 1000) // Convert elapsed time to milliseconds
          setElapsedTime(stepRunElapsedTime)
        }
      },
    }
  )

  const calculateStepRunActiveTime = useCallback(() => {
    const startedAt = state.stepRun?.startedAt ? new Date(state.stepRun?.startedAt) : null

    if (!state.isWizardOpen || !startedAt) return

    let newElapsedTime = 0
    if (enableAutoSnoozeV2) {
      if (isOffline || isAutoSnoozed) return

      const now = Date.now()
      newElapsedTime = (now - timeLogStartTime) / 1000 // Calculate elapsed time in seconds
    } else {
      if (!stepRunSnoozeActivities) return

      const totalSnoozeTime = stepRunSnoozeActivities.reduce((acc, { snoozedAt, unsnoozedAt }) => {
        // If null, means that the stepRun is currently snoozed. Ignoring it for handling time calculation.
        if (unsnoozedAt === null) return acc

        if (
          snoozedAt.getTime() < startedAt.getTime() ||
          unsnoozedAt.getTime() < startedAt.getTime()
        )
          return acc

        return acc + differenceInSeconds(unsnoozedAt, snoozedAt)
      }, 0)

      newElapsedTime = differenceInSeconds(new Date(), startedAt) - totalSnoozeTime
    }
    setElapsedTime(newElapsedTime)
  }, [
    state.stepRun?.startedAt,
    state.isWizardOpen,
    enableAutoSnoozeV2,
    isOffline,
    isAutoSnoozed,
    stepRunSnoozeActivities,
    timeLogStartTime,
  ])

  useEffect(() => {
    logger.info('Wizard Timer Info:', {
      startedAt: state.stepRun?.startedAt,
      isWizardOpen: state.isWizardOpen,
      enableAutoSnoozeV2,
      isOffline,
      isAutoSnoozed,
    })
  }, [state.stepRun?.startedAt, state.isWizardOpen, enableAutoSnoozeV2, isOffline, isAutoSnoozed])

  useEffect(() => {
    // Start the interval only after timeLogStartTime has been set
    if (timeLogStartTime > 0 || !enableAutoSnoozeV2) {
      intervalRef.current = window.setInterval(calculateStepRunActiveTime, 250)
    }
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [timeLogStartTime, calculateStepRunActiveTime, enableAutoSnoozeV2])

  const formattedElapsedTime = useMemo(() => getFormattedTimeString(elapsedTime), [elapsedTime])

  return stepRunSnoozeActivities || timeLogStartTime ? (
    <Box className='flex items-center gap-0.5'>
      <Box className='text-white'>{formattedElapsedTime}</Box>
      <Tooltip content='Time spent in Wizard' side='left'>
        <InfoOutlineIcon width={10} height={10} className='cursor-pointer text-white' />
      </Tooltip>
    </Box>
  ) : null
}
export { WizardTimer }
