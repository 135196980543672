import {
  ReportingPlatformEnum,
  ReportingScopeEnum,
  TReportingPlatform,
  TReportingScope,
} from '@invisible/common/types'
import { IStoriesQuery } from '@invisible/concorde/gql-client'
import { createContext } from 'react'

import { useStories } from '../hooks/useStories'

export type TStory = IStoriesQuery['stories']['edges'][0]['node']

export type TStoryContext = TStory & {
  scopedId: string
}

const currentData = new Date()
const defaultStory: TStoryContext = {
  id: '',
  name: '',
  title: '',
  subtitle: null,
  description: null,
  // @ts-expect-error Generated Enum type not assignable to self defined Enum type
  platform: ReportingPlatformEnum.Manticore,
  // @ts-expect-error Generated Enum type not assignable to self defined Enum type
  scope: ReportingScopeEnum.Process,
  order: 1,
  createdAt: currentData,
  updatedAt: currentData,
  scopedId: '',
}

const StoryContext = createContext<TStoryContext>(defaultStory)

// eslint-disable-next-line @typescript-eslint/ban-types
const StoryProvider: React.FC<{ storyId: string }> = ({ storyId, children }) => {
  const storyWithScopedId = useStories((story) => story.id === storyId)
  return <StoryContext.Provider value={storyWithScopedId}>{children}</StoryContext.Provider>
}

export { StoryContext, StoryProvider }
