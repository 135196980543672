import { SVGProps } from 'react'

function Sales(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.23077 3C1.9105 3 2.46154 3.54269 2.46154 4.21212V27.2424H30.7692C31.449 27.2424 32 27.7851 32 28.4545C32 29.124 31.449 29.6667 30.7692 29.6667H1.23077C0.551034 29.6667 1.83399e-08 29.124 1.83399e-08 28.4545V4.21212C1.83399e-08 3.54269 0.551034 3 1.23077 3Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.178 5.77926C29.6586 6.25263 29.6586 7.0201 29.178 7.49346L16.8703 19.6147C16.3896 20.088 15.6104 20.088 15.1297 19.6147L11.0769 15.6233L2.10105 24.4632C1.62041 24.9365 0.841129 24.9365 0.360484 24.4632C-0.120161 23.9898 -0.120161 23.2223 0.360484 22.749L10.2066 13.052C10.6873 12.5786 11.4666 12.5786 11.9472 13.052L16 17.0434L27.4374 5.77926C27.9181 5.3059 28.6973 5.3059 29.178 5.77926Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.9231 6.63636C20.9231 5.96693 21.4741 5.42424 22.1538 5.42424H28.3077C28.9874 5.42424 29.5385 5.96693 29.5385 6.63636V12.697C29.5385 13.3664 28.9874 13.9091 28.3077 13.9091C27.628 13.9091 27.0769 13.3664 27.0769 12.697V7.84848H22.1538C21.4741 7.84848 20.9231 7.3058 20.9231 6.63636Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Sales
