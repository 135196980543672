import { IExportConfigurationDataQuery } from '@invisible/concorde/gql-client'
import { TVariableType } from '@invisible/ultron/zod'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { Dispatch } from 'react'

import { TReducerAction, TReducerState } from './reducer'

interface IProps {
  baseVariables: IExportConfigurationDataQuery['process']['bases'][number]['baseVariables']
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
}

const FilterComponent = ({
  type,
  handleSave,
  value,
}: {
  type: TVariableType
  value: unknown
  handleSave: (value: unknown) => void
}) => {
  const parsedValue = Array.isArray(value) ? value[0] : value
  const isDateRange =
    parsedValue &&
    typeof parsedValue === 'object' &&
    'start_date' in parsedValue &&
    'end_date' in parsedValue

  switch (type) {
    case 'number':
      return (
        <TextField
          type='number'
          variant='outlined'
          size='small'
          value={parsedValue}
          onChange={(e) => handleSave([Number(e.target.value)])}
        />
      )
    case 'boolean':
      return (
        <Checkbox
          size='small'
          onChange={(e) => handleSave([e.target.checked])}
          checked={Boolean(parsedValue)}
        />
      )
    case 'date':
    case 'datetime':
      return (
        <DateRangePicker
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
          slots={{ field: SingleInputDateRangeField }}
          onChange={(value) => {
            if (value[0] && value[0])
              handleSave({
                start_date: (value[0] as Date).toISOString(),
                end_date: (value[1] as Date).toISOString(),
              })
          }}
          value={
            isDateRange
              ? [
                  new Date(parsedValue.start_date as string),
                  new Date(parsedValue.end_date as string),
                ]
              : undefined
          }
        />
      )

    default:
      return (
        <TextField
          variant='outlined'
          size='small'
          value={parsedValue}
          onChange={(e) => handleSave([e.target.value])}
        />
      )
  }
}

const BaseVariableFilters = ({ baseVariables, dispatch, state }: IProps) => (
  <Stack>
    {baseVariables
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((variable) => {
        const filter = state.filters.find((filter) => filter.key === variable.id)
        return (
          <Stack
            direction='row'
            alignItems='center'
            key={variable.id}
            sx={{
              // Show filter container on hover
              '&:hover .filter-container': {
                visibility: 'visible',
              },
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.filters.some((filter) => filter.key === variable.id)}
                  onChange={(e) => {
                    e.target.checked
                      ? dispatch({
                          type: 'ADD_FILTERS',
                          payload: [{ key: variable.id, value: [null] }],
                        })
                      : dispatch({ type: 'REMOVE_FILTERS', payload: [variable.id] })
                  }}
                />
              }
              label={variable.name}
            />
            <Typography mr='auto' fontStyle='italic' color='grey.500'>
              {variable.type}
            </Typography>

            <Box
              sx={{ visibility: filter ? 'visible' : 'hidden' }}
              className='filter-container'
              onClick={() =>
                !filter
                  ? dispatch({
                      type: 'ADD_FILTERS',
                      payload: [{ key: variable.id, value: null }],
                    })
                  : null
              }>
              <FilterComponent
                value={filter?.value}
                type={variable.type}
                handleSave={(value: unknown) =>
                  dispatch({
                    type: 'UPDATE_FILTER',
                    payload: {
                      key: variable.id,
                      value,
                    },
                  })
                }
              />
            </Box>
          </Stack>
        )
      })}
  </Stack>
)

export { BaseVariableFilters }
