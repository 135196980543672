import { LinkIcon } from '@invisible/ui/icons'
import { styled, useTheme } from '@invisible/ui/themes'
import { Box } from 'rebass'

import { InputCell } from './InputCell'

const Container = styled(Box)<{ showBorder: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  border: ${({ showBorder, theme }) =>
    `1px solid ${!showBorder ? 'transparent' : theme.grayScale[5]}`};
  border-radius: 5px;
`

const StyledA = styled.a`
  text-decoration: none;
  margin-left: 13px;
  color: ${({ theme }) => theme.colors.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export interface IUrlCell {
  id: number | string
  value?: string
  isEditable?: boolean
  debounceDelay?: number
  onChange: ({ value, id }: { value: string; id: number | string }) => void
  placeholder?: string
  width: number
  onLinkClick?: ((link: string) => void) | null
}

const UrlCell = ({ value, isEditable, id, width, onChange, onLinkClick }: IUrlCell) => {
  const { colors } = useTheme()
  const link = !value || value.startsWith('http') ? value : `https://${value}`

  if (isEditable)
    return (
      <div className='flex items-center' style={{ width: `${width}px` }}>
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          onClick={(e) => {
            if (onLinkClick) {
              e.preventDefault()
              onLinkClick((e.target as HTMLLinkElement).href)
            }
          }}>
          <LinkIcon
            height={18}
            width={18}
            color={colors.primary}
            style={{ pointerEvents: 'none' }}
          />
        </a>
        <div className='pl-3' style={{ width: `${width - 30}px` }}>
          <InputCell value={value} id={id} onChange={({ value, id }) => onChange({ value, id })} />
        </div>
      </div>
    )

  return (
    <Container showBorder={false} style={{ width: `${width}px` }}>
      <LinkIcon height={14} width={14} color={colors.primary} style={{ flexShrink: 0 }} />
      <StyledA
        href={value}
        target='_blank'
        title={value}
        rel='noreferrer'
        onClick={(e) => {
          if (onLinkClick) {
            e.preventDefault()
            onLinkClick((e.target as HTMLLinkElement).href)
          }
        }}>
        {value}
      </StyledA>
    </Container>
  )
}

export { UrlCell }
