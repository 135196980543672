export const ERRORS_BASE_ID = 'd6b0c996-ffda-434c-a4e0-780fbca6f4ac'

export const BRANDS = ['GrubHub/Seamless', 'GrubHub', 'Seamless', 'GFW'] as const

export const ERROR_KEYS: Record<string, any> = {
  brand: 'Brand',
  categoryName: 'Category Name',
  numberOfErrors: 'Number Of Errors',
  productName: 'Product Name',
  errorType: 'Error Type',
  errorCategory: 'Error Category',
  errorDescription: 'Error Description',
  errorSummary: 'Error Summary',
  dontCountError: 'Dont Count Error',
  errorCorrection: 'Error Correction',
}

export const ERROR_TYPES = [
  'Section',
  'Item',
  'Sizes',
  'Modifier',
  'Modifier Item',
  'Description',
  'Min/Max',
  'Price',
  'Upcharge',
  'Order Level Coupon',
  'Lables',
  'Schedule',
  'GH Style/Spelling and Grammar',
  'Advanced Notice',
  'Minimum Quantity',
  'Serving Size',
] as const

export const ERROR_CATEGORY = ['Missing', 'Incorrect', 'Other'] as const
