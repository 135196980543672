import { SVGProps } from 'react'

function Minus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='2'
      viewBox='0 0 25 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.384277 1C0.384277 0.447715 0.831993 0 1.38428 0H23.3843C23.9366 0 24.3843 0.447715 24.3843 1C24.3843 1.55228 23.9366 2 23.3843 2H1.38428C0.831993 2 0.384277 1.55228 0.384277 1Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Minus
