import { SVGProps } from 'react'
function ToolFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='26'
      viewBox='0 0 27 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24.1675 15.3625C22.8812 16.638 21.2427 17.4993 19.4628 17.8357C17.6828 18.1721 15.843 17.9681 14.18 17.25L7.67997 24.775C7.66747 24.8 7.64247 24.8125 7.62997 24.825C7.2595 25.1969 6.81925 25.492 6.33446 25.6934C5.84968 25.8947 5.32991 25.9984 4.80497 25.9984C4.28004 25.9984 3.76027 25.8947 3.27549 25.6934C2.7907 25.492 2.35045 25.1969 1.97997 24.825C1.60808 24.4545 1.31299 24.0143 1.11164 23.5295C0.910289 23.0447 0.806641 22.525 0.806641 22C0.806641 21.4751 0.910289 20.9553 1.11164 20.4705C1.31299 19.9858 1.60808 19.5455 1.97997 19.175L2.02997 19.125L9.55497 12.625C8.83016 10.9739 8.61805 9.14304 8.94615 7.3699C9.27425 5.59675 10.1274 3.96305 11.3951 2.68058C12.6627 1.3981 14.2864 0.525996 16.0556 0.17731C17.8248 -0.171376 19.658 0.0194398 21.3175 0.72502C21.4676 0.788071 21.5998 0.887257 21.7023 1.01375C21.8048 1.14024 21.8744 1.29011 21.905 1.45002C21.9365 1.61108 21.9279 1.77743 21.88 1.93439C21.832 2.09135 21.7461 2.23409 21.63 2.35002L16.7675 7.21252L17.23 9.57502L19.5925 10.0375L24.455 5.17502C24.5709 5.05885 24.7136 4.97299 24.8706 4.92503C25.0276 4.87707 25.1939 4.86848 25.355 4.90002C25.5149 4.93059 25.6648 5.00023 25.7912 5.10273C25.9177 5.20523 26.0169 5.33742 26.08 5.48752C26.7856 7.13597 26.9806 8.95819 26.6396 10.7186C26.2987 12.479 25.4375 14.0967 24.1675 15.3625Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ToolFilled
