import { IFormattedReport, LabelType } from '@invisible/common/types'

const DEFAULT_TOOLTIP_CONFIG = {
  labelConfig: {
    label: '',
    labelValue: '',
    labelType: 'String' as LabelType,
  },
  valueConfig: {
    label: '',
    valueType: '',
  },
}

export const DEFAULT_VISUALIZATION_DATA: IFormattedReport['visualizations'] = {
  pieChart: {
    data: [],
    tooltipConfig: DEFAULT_TOOLTIP_CONFIG,
    legends: [],
  },
  barChart: {
    indexedBy: '',
    KEYS: [],
    data: [],
    tooltipConfig: DEFAULT_TOOLTIP_CONFIG,
    legends: [],
  },
  table: {
    columns: [],
    data: [],
  },
  tileChart: {
    title: '',
    value: '0',
  },
  linearChart: [],
  choroplethChart: {
    type: '',
    features: [],
  },
}

export const DEFAULT_REPORT_DATA: IFormattedReport = {
  id: '',
  reportTitle: '',
  subtitle: '',
  reportName: '',
  visualizations: DEFAULT_VISUALIZATION_DATA,
  description: '',
  filterColumnOptions: [],
}
