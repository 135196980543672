import { SVGProps } from 'react'
function ToastSuccess(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill={props.fill ? props.fill : '#2CE28B'}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm-.48-5.288l7.351-9-1.742-1.424-6.622 8.107-2.774-2.383-1.466 1.707 3.65 3.134.874.752.73-.893z'
        fill='current'
      />
    </svg>
  )
}

export default ToastSuccess
