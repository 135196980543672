import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Control, Controller } from 'react-hook-form';
import { RRule } from 'rrule';

import { TForm } from './ExportTaskScheduleConfiguration';
import {
    WEEKDAYS,
} from './SchedulerConstants';
import { getFrequencies } from './schedulerHelpers';

interface MonthOption {
    label: string;
    value: string;
};

interface CustomRecurrenceProps {
    control: Control<TForm>;
    formValues: TForm;
    updateState: (fields: { [key: string]: any }) => void;
    monthOptions: MonthOption[];
    onUpdateMonthlyOption: (value: string | null) => void;
};


const CustomRecurrence = ({ control, formValues, updateState, monthOptions, onUpdateMonthlyOption }: CustomRecurrenceProps) => (
    <>
        <Stack direction='row' justifyContent='space-between' gap='8px'>
            <Stack gap='4px'>
                <Typography fontWeight='bold'>Setup custom recurrence</Typography>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    Set how regularly your export is sent. Recurring exports can be deactivated or paused at any time.
                </Typography>
            </Stack>
        </Stack>

        <Stack direction='row' alignItems='center' gap='16px'>
            <Typography width='180px'>Repeat every </Typography>
            <Box display="flex" alignItems="flex-start">
                <Controller
                    name='interval'
                    control={control}
                    render={({ field }) => (
                        <TextField
                            size='small'
                            type='number'
                            inputProps={{ min: 1 }}
                            value={field.value}
                            onChange={(e) => {
                                let newInterval = parseInt(e.target.value, 10);
                                newInterval = newInterval < 1 ? 1 : newInterval;
                                updateState({ interval: newInterval });
                            }}
                            style={{ height: '40px', width: 'auto', maxWidth: '60px', display: 'flex', alignItems: 'center' }}
                        />
                    )}
                />
            </Box>
            <Box display="flex" alignItems="flex-start">
                <Controller
                    name='freq'
                    control={control}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            onChange={(e) => {
                                const newFreq = Number(e.target.value);
                                const fields: { [key: string]: any } = { freq: newFreq };

                                if ([RRule.YEARLY, RRule.DAILY].includes(newFreq)) {
                                    fields.bymonthday = null;
                                    fields.byweekday = null;
                                    fields.bysetpos = null;
                                    fields.monthOption = null;
                                } else if (newFreq === RRule.MONTHLY) {
                                    fields.byweekday = null;
                                } else {
                                    fields.byweekday = [RRule.FR.weekday];
                                    fields.bysetpos = null;
                                    fields.bymonthday = null;
                                    fields.monthOption = null;
                                }

                                updateState(fields);
                            }}
                            input={<OutlinedInput size='small' sx={{ width: '180px', height: '37px', marginTop: '-2px' }} />}
                        >
                            {Object.keys(getFrequencies(formValues.interval))
                                .filter((key) => !isNaN(Number(key)))
                                .map((key) => {
                                    const frequencies = getFrequencies(formValues.interval);
                                    const frequency = frequencies[key as keyof typeof frequencies];
                                    return (
                                        <MenuItem key={key} value={key} sx={{ p: '2px', px: '4px' }}>
                                            <ListItemText primary={frequency} />
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    )}
                />
            </Box>
        </Stack>

        <Stack direction='row' justifyContent='space-between' gap='8px'>
            { [1, 2].includes(formValues.freq) ? (
                <Stack direction='row' alignItems='center' gap='16px' maxWidth={'750px'}>
                    <Typography width='180px'>Repeat on </Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap='16px'
                        style={{ maxWidth: '750px' }}
                    >
                        { formValues.freq === 2 ? (
                            <>
                                { Object.entries(WEEKDAYS).map(([key, value]) => (
                                    <Controller
                                        key={key}
                                        name='byweekday'
                                        control={control}
                                        render={({ field }) => (
                                            <Box
                                                key={key}
                                                sx={{ width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: (Array.isArray(field.value) && (field.value || []).includes(value.weekday)) ? 'black' : 'lightgrey',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '5px' }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        const newWeekDays = field.value?.includes(value.weekday)
                                                            ? field.value.filter((d: unknown) => d !== value.weekday)
                                                            : [...(field.value || []), value.weekday];
                                                        const fields: {[key: string]: unknown} = {
                                                            byweekday: newWeekDays,
                                                            monthOption: null,
                                                        };
                                                        updateState(fields);
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        lineHeight: '40px',
                                                        color: (Array.isArray(field.value) && (field.value || []).includes(value.weekday)) ? 'white' : 'black',
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        textAlign: 'center',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    {key.substring(0, 2)}
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                )) }
                            </>
                        )
                        : formValues.freq === 1
                        ? (
                            <Controller
                                name='monthOption'
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={monthOptions}
                                        value={monthOptions.find((b) => b.value === field.value) || null}
                                        renderInput={(params) => <TextField {...params} label='Select monthly option...' />}
                                        size='small'
                                        sx={{
                                            width: '250px',
                                        }}
                                        onChange={(_, newValue) => onUpdateMonthlyOption(newValue?.value ?? null)}
                                    />
                                )}
                            />
                        )
                        : null}
                    </Stack>
                </Stack>
            ) : null}
        </Stack>

        <Stack direction='row' justifyContent='space-between' gap='16px'>
            <Stack justifyContent='space-between' gap='16px'>
                <Typography>Ends</Typography>
                <RadioGroup
                    key='key'
                    name='name'
                    onChange={(event) => {
                        const value = event.target.value;
                        const fields: {[key: string]: unknown} = {
                            endCondition: value,
                        };
                        
                        if (value === 'never') {
                            fields.until = null;
                            fields.count = null;
                        } else if (value === 'on') {
                            fields.count = null;
                        } else {
                            fields.until = null;
                        }
                        updateState(fields);
                    }}
                    value={formValues.endCondition}
                    row={false}
                >
                    <FormControlLabel value='never' control={<Radio />} label='Never' />
                    <Stack direction='row' alignItems='center' gap='16px'>
                        <div style={{ width: '180px' }}>
                            <FormControlLabel value='on' control={<Radio />} label='On' />
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name='until'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        onChange={(date) => {
                                            if (date) {
                                                const utcDate: Date = new Date(date?.toISOString());
                                                const fields: {[key: string]: unknown} = {
                                                    until: utcDate,
                                                    count: null,
                                                };
                                                updateState(fields);
                                            }
                                        }}
                                        disabled={formValues.endCondition !== 'on'}
                                        value={dayjs(field.value)}
                                        minDate={dayjs(formValues.dtstart).add(1, 'day')}
                                        slotProps={{ textField: { size: 'small', disabled: true } }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction='row' alignItems='center' gap='16px'>
                        <div style={{ width: '180px' }}>
                            <FormControlLabel value='after' control={<Radio />} label='After' />
                        </div>
                        <Controller
                            name='count'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    disabled={formValues.endCondition !== 'after'}
                                    size='small'
                                    type='number'
                                    inputProps={{ min: 1 }}
                                    value={field.value || ''}
                                    onChange={(e) => {
                                        const fields: {[key: string]: unknown} = {
                                            count: e.target.value,
                                        };

                                        if (e.target.value) {
                                            let count = parseInt(e.target.value, 10) || 0;
                                            count = count < 1 ? 1 : count;

                                            fields.until = null;
                                            fields.count = count;
                                        }

                                        updateState(fields);
                                    }}
                                    style={{ height: '40px', width: 'auto', maxWidth: '60px', display: 'flex', alignItems: 'center' }}
                                />
                            )}
                        />
                        <Typography>
                            {(formValues.count && (formValues.count >= 2)) ? 'occurrences' : 'occurrence'}
                        </Typography>
                    </Stack>
                </RadioGroup>
            </Stack>
        </Stack>
    </>
);

export { CustomRecurrence }
