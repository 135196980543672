import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  toGlobalId,
  useBaseRunsDeleteMutation,
} from '@invisible/concorde/gql-client'
import { parseResponse } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { map } from 'lodash'
import { useContext } from 'react'

interface BaseRunsDeleteInput {
  baseRunIds: string[]
}

type OnMutateHandler = (variables: BaseRunsDeleteInput) => void

const useBaseRunsDelete = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: () => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: baseRunsDelete, isLoading } = useBaseRunsDeleteMutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const { ids } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useBaseRunsDeleteMutation via GraphQL resulted in an error: ${errorMessage}`,
        {
          baseRunIds: map(ids, fromGlobalId),
        }
      )
    },
    onSuccess: (_, variables) => {
      const { ids } = variables
      handleOnSuccess?.()
      logger.info('Mutating from useBaseRunsDelete via GraphQL successful', {
        baseRunIds: map(ids, fromGlobalId),
      })
    },
    onMutate: () =>
      handleOnMutate
        ? (variables: BaseRunsDeleteInput) => {
            handleOnMutate({
              baseRunIds: map(variables.baseRunIds, fromGlobalId),
            })
          }
        : undefined,
    onSettled,
  })

  const mutateAsync = async (data: BaseRunsDeleteInput) => {
    logger.info('Mutating from useBaseRunsDelete', data.baseRunIds)

    logger.info('Mutating from useBaseRunsDelete via GraphQL', {
      ids: data.baseRunIds,
    })
    const graphQLData = {
      ids: map(data.baseRunIds, (baseRunId) => toGlobalId('BaseRunType', baseRunId)),
    }
    const response = await baseRunsDelete(graphQLData)
    const gqlResponse = parseResponse(response.baseRunsDelete, (code: number, message: string) => {
      const _error = new Error(`${code}: ${message}`)
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: message,
          variant: 'error',
        })
      }
    })
    return {
      ...response,
      baseRunsIds: map(gqlResponse ? gqlResponse.baseRuns : [], (baseRun) =>
        fromGlobalId(baseRun.id)
      ),
    }
  }

  return { mutateAsync, isLoading }
}

export { useBaseRunsDelete }
