import { z } from '@invisible/zod'

const paymentConfigSchema = z.object({
  paymentConfigId: z.string(),
  updatedFields: z
    .object({
      rate: z.boolean().optional(),
      multiplier: z.boolean().optional(),
      startDate: z.boolean().optional(),
      endDate: z.boolean().optional(),
      useAgentDefaultRate: z.boolean().optional(),
    })
    .optional(),
})

type TPaymentConfig = z.infer<typeof paymentConfigSchema>

const periodicTTPaymentConfigSchema = z.object({
  periodicTTPaymentConfigId: z.string(),
  updatedFields: z
    .object({
      rate: z.boolean().optional(),
      startDate: z.boolean().optional(),
      endDate: z.boolean().optional(),
      useAgentDefaultRate: z.boolean().optional(),
      stepRunLimit: z.boolean().optional(),
      dailyLimit: z.boolean().optional(),
      weeklyLimit: z.boolean().optional(),
      cycleLimit: z.boolean().optional(),
    })
    .optional(),
})

type TPeriodTTPaymentConfigMeta = z.infer<typeof periodicTTPaymentConfigSchema>

const pricingConfigSchema = z.object({
  pricingConfigId: z.string(),
  updatedFields: z
    .object({
      rate: z.boolean().optional(),
      multiplier: z.boolean().optional(),
      startDate: z.boolean().optional(),
      endDate: z.boolean().optional(),
    })
    .optional(),
})

type TPricingConfig = z.infer<typeof pricingConfigSchema>

const fixedPaymentConfigSchema = z.object({
  fixedPaymentConfigId: z.string().uuid(),
  updatedFields: z
    .object({
      amount: z.boolean().optional(),
      startDate: z.boolean().optional(),
      endDate: z.boolean().optional(),
    })
    .optional(),
  billEventId: z.string().uuid().optional(),
})

type TFixedPaymentConfig = z.infer<typeof fixedPaymentConfigSchema>

export {
  fixedPaymentConfigSchema,
  paymentConfigSchema,
  periodicTTPaymentConfigSchema,
  pricingConfigSchema,
}
export type { TFixedPaymentConfig, TPaymentConfig, TPeriodTTPaymentConfigMeta, TPricingConfig }
