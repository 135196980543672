import { styled, TTheme } from '@invisible/ui/themes'
import { CSSProperties, FC } from 'react'
import { Text as RebassText, TextProps } from 'rebass'

export const TEXT_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  DISABLED: 'disabled',
  WARNING: 'warning',
  MARK: 'mark',
  WHITE: 'white',
} as const
export type TextType = typeof TEXT_TYPE[keyof typeof TEXT_TYPE]

export const TEXT_WEIGHT = {
  REGULAR: 'regular',
  MEDIUM: 'medium',
  BOLD: 'bold',
} as const
type TextWeight = typeof TEXT_WEIGHT[keyof typeof TEXT_WEIGHT]

export interface IText extends TextProps {
  type?: TextType
  underline?: boolean
  strikethrough?: boolean
  code?: boolean
  fontSize?: number | string
  style?: CSSProperties
  className?: string
  fontWeight?: string | number | TextWeight
}

export const getColor = (theme: TTheme, type: TextType) => theme.textColor[type]

// eslint-disable-next-line @typescript-eslint/ban-types
const _Text: FC<IText> = ({ style, fontWeight, fontSize, ...props }) => (
  <RebassText
    style={style}
    fontSize={fontSize}
    fontWeight={fontWeight === TEXT_WEIGHT.MEDIUM ? 500 : fontWeight}
    {...props}
  />
)

const Text = styled(_Text).attrs({ 'data-cy': 'text' })<IText>`
  color: ${({ theme, type }) => (type ? getColor(theme, type) : '')};
  text-decoration-line: ${({ underline, strikethrough }) =>
    underline ? 'underline' : strikethrough ? 'line-through' : 'none'};
  ${({ code }) => (code ? 'font-family: Menlo;' : '')}
  line-height: 22px;
`
interface IHeading {
  type?: TextType
  size?: number
  style?: CSSProperties
  fontWeight?: string | number | TextWeight
  className?: string
  id?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const _Heading: FC<IHeading> = ({ size = 4, fontWeight, ...props }) => {
  switch (size) {
    case 1:
      return <h1 {...props}>{props.children}</h1>
    case 2:
      return <h2 {...props}>{props.children}</h2>
    case 3:
      return <h3 {...props}>{props.children}</h3>
    case 4:
      return <h4 {...props}>{props.children}</h4>
    case 5:
      return <h5 {...props}> {props.children}</h5>
    default:
      throw new Error('Invalid size')
  }
}

const getFontSize = (size: number) => [0, '38px', '30px', '24px', '20px', '16px'][size]
const getLineHeight = (size: number) => [0, '46px', '40px', '32px', '28px', '24px'][size]

const Heading = styled(_Heading).attrs({ 'data-cy': 'heading' })<IHeading>`
  color: ${({ theme, type }) => (type ? getColor(theme, type) : '')};
  font-weight: ${({ fontWeight }) => (fontWeight === TEXT_WEIGHT.MEDIUM ? 700 : fontWeight)};
  font-size: ${({ size }) => getFontSize(size ?? 4)};
  line-height: ${({ size }) => getLineHeight(size ?? 4)};
`

export { Heading, Text }
