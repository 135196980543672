import { SVGProps } from 'react'
function Fork(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect x='2.5' y='10.1599' width='3.82606' height='3.84' rx='0.625' stroke='currentColor' />
      <rect x='2.5' y='2' width='3.82606' height='3.84' rx='0.625' stroke='currentColor' />
      <rect x='9.67383' y='2' width='3.82606' height='3.84' rx='0.625' stroke='currentColor' />
      <path
        d='M4.4131 9.68031C4.41231 9.49099 4.44888 9.30338 4.5207 9.12831C4.59253 8.95324 4.69818 8.79418 4.83157 8.66031C4.96496 8.52643 5.12344 8.42039 5.29787 8.34831C5.4723 8.27622 5.65923 8.23952 5.84787 8.24031H10.1522C10.3408 8.24111 10.5278 8.2044 10.7022 8.13232C10.8766 8.06023 11.0351 7.95419 11.1685 7.82032C11.3019 7.68644 11.4075 7.52738 11.4794 7.35231C11.5512 7.17725 11.5878 6.98964 11.587 6.80031V6.32031'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.41309 6.32031V10.1603'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default Fork
