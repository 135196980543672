import { flow, join, nth, random, split, times } from 'lodash/fp'

export const alphaNumericString = flow(times(flow(random(35), (num) => num.toString(36))), join(''))

export const isAlphaNumeric = (input: string) => {
  const hasNumbers = /[0-9]/.test(input)
  const hasAlphabets = /[a-zA-Z]/.test(input)

  return hasNumbers && hasAlphabets
}

export const dollarStringToNumber = (str: string) => {
  const numberStr = str?.includes('$') ? flow(split('$'), nth(1))(str) : str
  if (numberStr?.includes(',')) {
    const commaNumber = numberStr?.split(',').join('')
    return Number(commaNumber)
  } else {
    return Number(numberStr)
  }
}
