import { SVGProps } from 'react'
function WrenchOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2741 2.19167C11.7066 2.08778 11.1209 2.09677 10.5511 2.22186C9.6613 2.41721 8.85488 2.88592 8.24471 3.56238C7.63455 4.23883 7.2512 5.08916 7.14833 5.99431C7.04546 6.89947 7.22822 7.81413 7.67103 8.61025C7.81504 8.86917 7.75748 9.19362 7.5332 9.3872L2.47419 13.7535C2.24821 13.987 2.12158 14.2995 2.12158 14.625C2.12158 14.9574 2.25362 15.2761 2.48865 15.5112C2.72368 15.7462 3.04245 15.8782 3.37483 15.8782C3.70034 15.8782 4.01278 15.7516 4.24631 15.5256L8.61263 10.4666C8.8062 10.2423 9.13065 10.1848 9.38957 10.3288C10.1857 10.7716 11.1004 10.9544 12.0055 10.8515C12.9107 10.7486 13.761 10.3653 14.4374 9.75511C15.1139 9.14494 15.5826 8.33852 15.778 7.44873C15.9031 6.87894 15.912 6.29323 15.8081 5.72575L13.2704 8.26953C13.1224 8.41787 12.91 8.48218 12.7046 8.44081L10.4936 7.9955C10.2469 7.9458 10.054 7.75295 10.0043 7.50621L9.55901 5.29527C9.51764 5.08986 9.58195 4.87738 9.73029 4.72939L12.2741 2.19167ZM10.283 1.00094C11.4275 0.749679 12.6219 0.862601 13.699 1.3239C13.8898 1.40561 14.0276 1.5767 14.0667 1.78052C14.1059 1.98435 14.0413 2.19431 13.8944 2.34089L10.8507 5.37737L11.1477 6.85213L12.6225 7.14917L15.6589 4.10545C15.8055 3.95851 16.0155 3.89393 16.2193 3.93308C16.4231 3.97224 16.5942 4.11002 16.6759 4.30081C17.1372 5.37791 17.2501 6.5723 16.9989 7.71677C16.7476 8.86125 16.1447 9.89849 15.2747 10.6833C14.4046 11.4681 13.3109 11.9612 12.1467 12.0935C11.1533 12.2064 10.1511 12.0518 9.24224 11.6503L5.1761 16.3615C5.16612 16.373 5.15571 16.3842 5.1449 16.3951C4.67545 16.8645 4.03874 17.1282 3.37483 17.1282C2.71093 17.1282 2.07422 16.8645 1.60477 16.3951C1.13532 15.9256 0.871582 15.2889 0.871582 14.625C0.871582 13.9611 1.13532 13.3244 1.60477 12.8549C1.61558 12.8441 1.62678 12.8337 1.63835 12.8237L6.34955 8.75759C5.94799 7.84874 5.79343 6.8465 5.90633 5.85316C6.03864 4.68893 6.53172 3.59521 7.31652 2.72514C8.10133 1.85507 9.13858 1.2522 10.283 1.00094Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default WrenchOutlined
