import { z } from '@invisible/zod'

import { schema as filterValueSchema } from './DynamicFilterValue'

const schema = z.object({
  name: z.string().optional(),
  value: z.string().optional(),
  label: z.string().nullable().optional(),
  labelType: z.enum(['Series', 'String']).nullable().optional(),
  labelValue: z.string().nullable().optional(),
  labelRotationAngle: z.number().min(-360).max(360).optional(),
  groupMode: z.string().nullable().optional(),
  valueType: z.string().nullable().optional(),
  order: z.union([z.string(), z.number()]).nullable().optional(),
  nextReport: z.string().nullable().optional(),
  primaryKey: z.union([z.string(), z.number()]).nullable().optional(),
  foreignKey: z.union([z.string(), z.number()]).nullable().optional(),
  intervalColumn: z.string().nullable().optional(),
  postfixUnit: z.string().nullable().optional(),
  prefixUnit: z.string().nullable().optional(),
  sourceLink: z.string().nullable().optional(),
  isFinalLevel: z.boolean().nullable().optional(),
  isNullable: z.boolean().optional(),
  columns: z.array(z.string()).nullable().optional(),
  isPrimary: z.boolean().optional(),
  isVisible: z.boolean().optional(),
  isEditable: z.boolean().optional(),
  paramName: z.string().optional(),
  operator: z.string().optional(),
  defaultValue: filterValueSchema.optional(),
  deviationPercent: z.string().optional(),
  deviationLabel: z.string().optional(),
  deviationDirection: z.string().optional(),
  selectOptions: z.array(z.string()).optional(),
  defaultSelectValue: z.string().optional(),
  deviationColor: z.string().optional(),
  target: z.string().optional(),
  // These are not part of AttributePropertyType enum in prisma schema
  attributeName: z.string().optional(),
  groupId: z.string(),
  attributeId: z.string(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
