const MuiTabPanel = {
  variants: [
    {
      props: { variant: 'table' },
      style: {
        padding: '0px',
        marginTop: '-1px',
      },
    },
  ],
}

export default MuiTabPanel
