import { TMatch } from '../types'
import { getErrorString } from './common'
import { highlightInputFieldError } from './highlightInputFieldError'

const clearInputFieldHighlights = () => {
  const highlights = document.querySelectorAll('.input-highlight')
  highlights.forEach((highlight) => {
    highlight.remove()
  })
}

export const highlightInputFieldErrors = ({ matches }: { matches: TMatch[] }) => {
  // Clear previous highlights to avoid duplicates
  clearInputFieldHighlights()
  const inputs = document.querySelectorAll('input[type="text"], textarea') as NodeListOf<
    HTMLInputElement | HTMLTextAreaElement
  >

  inputs.forEach((input) => {
    const { value } = input
    matches.forEach((match) => {
      const errorString = getErrorString(match)
      const regex = new RegExp(`\\b${errorString}\\b`, 'gi')
      let matchString
      while ((matchString = regex.exec(value))) {
        const startPos = matchString.index
        const endPos = startPos + matchString[0].length
        highlightInputFieldError({ input, startPos, endPos, match })
      }
    })
  })
}
