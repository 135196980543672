import { z } from '@invisible/zod'

const schema = z.object({
  enableNativeVideoControls: z.boolean().optional(),
  enableVideoAnnotation: z.boolean().optional(),
  annotationBaseId: z.string().optional(),
  timecodeStartBaseVariableId: z.string().optional(),
  timecodeEndBaseVariableId: z.string().optional(),
  durationBaseVariableId: z.string().optional(),
  textBaseVariableId: z.string().optional(),
  preProvidedInfoBaseVariableId: z.string().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
