import { useAbilityContext } from '@invisible/authorization/client'
import { TReportingPlatform } from '@invisible/common/types'
import {
  toGlobalId,
  useDocumentDeleteMutation,
  useDocumentsCreateMutation,
  useDocumentsQuery,
} from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { UploadedFile, UploaderDropzone } from '@invisible/ui/file-uploader'
import { copyToClipboard } from '@invisible/ui/helpers'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { portalTheme, theme } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Divider } from '@mui/material'
import { Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import {
  DataGridPro,
  GridColDef,
  GridPagination,
  GridPaginationModel,
  GridRowsProp,
} from '@mui/x-data-grid-pro'
import { useSession } from 'next-auth/react'
import { ChangeEvent, FC, useState } from 'react'

type TPaginationCursor = { before?: string } | { after?: string }

type TPaginationSize = { first?: number } | { last?: number }

type TPagination = TPaginationCursor & TPaginationSize

const PAGE_SIZE = 10

const TYPES = [
  'SOP (How we do it) ',
  'SOW (What we do)',
  'CSAT Survey',
  'Order Form',
  'Process Notes',
  'Zoom Meeting',
  'Process Recording',
  'Contract',
]
// eslint-disable-next-line @typescript-eslint/ban-types
export const ProcessDocuments: FC<{
  companyId: string
  processId: string
  platform: TReportingPlatform
}> = ({ companyId, processId, platform }) => {
  const themeToApply = platform === 'portal' ? portalTheme : theme
  const [Can] = useAbilityContext()

  const { addToast } = useToasts()
  const { data: session } = useSession()

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [fileCount, setFileCount] = useState(0)
  const [linkName, setLinkName] = useState('')
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  })
  const [pagination, setPagination] = useState<TPagination>({
    after: '',
    first: PAGE_SIZE,
  })

  const { data: loggedInUser } = useQuery([
    'user.findByEmail',
    { email: session?.user?.email ?? '' },
  ])

  const {
    data: documents,
    isLoading,
    refetch: getAllDocuments,
  } = useDocumentsQuery(
    {
      processId: toGlobalId('ProcessType', processId),
      ...pagination,
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, enabled: !!processId }
  )

  const { mutateAsync: createDocuments } = useDocumentsCreateMutation({
    onSuccess: () => {
      getAllDocuments()
    },
    onError: () => {
      addToast('Could not create documents', { appearance: 'error' })
    },
  })

  const { mutateAsync: softDeleteDocument } = useDocumentDeleteMutation({
    onSuccess: () => {
      getAllDocuments()
    },
    onError: () => {
      addToast('Could not delete a document', { appearance: 'error' })
    },
  })

  const handleCreateDocuments = async (files: UploadedFile[]) => {
    if (!loggedInUser?.id) return
    if (url && linkName) {
      await createDocuments({
        dataList: [
          {
            companyId: toGlobalId('CompanyType', companyId),
            userId: toGlobalId('UserType', loggedInUser.id),
            name: linkName,
            url: url,
            type: type,
            source: 'internal',
            processId: toGlobalId('ProcessType', processId),
          },
        ],
      })
      setUrl('')
      setLinkName('')
    } else {
      const documents = files.map((file) => ({
        companyId: toGlobalId('CompanyType', companyId),
        userId: toGlobalId('UserType', loggedInUser.id),
        name: file.fileName,
        url: file.url,
        type: file.type,
        source: 'internal',
        processId: toGlobalId('ProcessType', processId),
      }))
      await createDocuments({ dataList: documents })
    }
    setIsUploadModalOpen(false)
  }

  const handleSoftDeleteDocument = async (documentId: string) => {
    await softDeleteDocument({ id: documentId })
  }

  const onValueChange = ({ type }: { type: string }) => {
    setType(type)
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    const currentPage = paginationModel.page
    const newPage = newPaginationModel.page

    if (newPage > currentPage) {
      // Moving forward
      const currentCursor = documents?.documents?.pageInfo?.endCursor
      if (!currentCursor) return

      setPagination({ after: currentCursor, first: PAGE_SIZE })
    } else if (newPage < currentPage) {
      // Moving backward
      const currentCursor = documents?.documents?.pageInfo?.startCursor
      if (!currentCursor) return

      setPagination({ before: currentCursor, last: PAGE_SIZE })
    }

    setPaginationModel(newPaginationModel)
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 450,
      renderCell: ({ row }) => (
        <Typography variant='body2' noWrap sx={{ fontWeight: 'bold' }}>
          {row?.name}
        </Typography>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 100,
      renderCell: ({ row: { url } }) => (
        <IconButton
          aria-label='delete'
          size='small'
          color='default'
          onClick={() => {
            if (url.includes('storage.googleapis.com')) {
              window.open(url, '_blank')
            } else copyToClipboard(String(url))
          }}>
          <ContentCopyIcon fontSize='inherit' />
        </IconButton>
      ),
    },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'userName', headerName: 'Created By', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: ({ row }) => (
        <Can I='delete' a='Document'>
          <IconButton
            aria-label='delete'
            size='small'
            color='error'
            onClick={() => {
              handleSoftDeleteDocument(row.id)
            }}>
            <DeleteOutlineOutlinedIcon fontSize='inherit' />
          </IconButton>
        </Can>
      ),
    },
  ]

  const rows: GridRowsProp =
    documents?.documents?.edges?.map((doc) => ({
      id: doc.node.id,
      name: doc.node.name,
      url: doc.node.url,
      type: doc.node.type,
      userId: doc.node.createdByUser.id,
      userName: doc.node.createdByUser.name,
    })) ?? []

  if (isLoading) {
    return (
      <div className='flex h-full items-center justify-center'>
        <LogoSpinner width={30} />
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-4 p-2'>
      <Button size='sm' onClick={() => setIsUploadModalOpen(true)}>
        Upload
      </Button>
      <ThemeProvider theme={themeToApply}>
        <DataGridPro
          slots={{
            pagination: GridPagination,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          checkboxSelection={false}
          pageSizeOptions={[PAGE_SIZE]}
          rowCount={documents?.documents?.totalCount ?? 0}
          paginationMode='server'
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
        />
        <Dialog open={isUploadModalOpen} onClose={() => setIsUploadModalOpen(false)}>
          <DialogTitle>Upload File(s)</DialogTitle>

          <DialogContent>
            <div className='mt-2 flex flex-col gap-3'>
              <TextField
                type='text'
                label='Link Name'
                variant='outlined'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLinkName(e.target.value)
                }}
                value={linkName}
                fullWidth
              />
              <TextField
                type='url'
                label='URL'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setUrl(e.target.value)
                }}
                value={url}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id='select'>Select Type</InputLabel>
                <Select
                  labelId='select'
                  value={type}
                  label='Select Type'
                  onChange={(e: SelectChangeEvent) => {
                    onValueChange({ type: e.target.value as string })
                  }}>
                  {TYPES
                    ? TYPES?.map((action) => <MenuItem value={action}>{action}</MenuItem>)
                    : []}
                </Select>
              </FormControl>

              <Divider>OR</Divider>
              <UploaderDropzone
                defaultValues={[]}
                directoryName={`companies/${companyId}/documents`}
                setShowModal={setIsUploadModalOpen}
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                saveUploadedFiles={handleCreateDocuments}
              />
              {fileCount > 0 ? <Typography>{fileCount} file(s) uploaded</Typography> : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsUploadModalOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                handleCreateDocuments(uploadedFiles)
                if (setFileCount) setFileCount(uploadedFiles.length)
                setIsUploadModalOpen(false)
              }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  )
}
