export const PLAYBACK_RATES = [
  { key: '0.25', value: 0.25 },
  { key: '0.5', value: 0.5 },
  { key: '0.75', value: 0.75 },
  { key: 'Normal', value: 1 },
  { key: '1.25', value: 1.25 },
  { key: '1.5', value: 1.5 },
  { key: '2', value: 2 },
]

export const FAST_REWIND_BY = [
  { key: '-1s', value: -1 },
  { key: '-0.1s', value: -0.1 },
  { key: '-0.01s', value: -0.01 },
]

export const FAST_FORWARD_BY = [
  { key: '0.01s', value: 0.01 },
  { key: '0.1s', value: 0.1 },
  { key: '1s', value: 1 },
]
