import { FilterPillButton, FilterWizard } from '@invisible/ui/report-filters'

import { DateFilterDropdown } from './components/Dropdown'
import { IFilterWizardProps, IRenderIntervalDropDown } from './types'

const renderIntervalDropDown = ({
  onChange,
  selectedInterval,
  width,
  itemKey,
}: IRenderIntervalDropDown) => (
  <DateFilterDropdown
    key={itemKey}
    width={width}
    selectedInterval={selectedInterval}
    onChange={onChange}
  />
)

const renderFilterWizard = ({ dynamicFilter, onChange, itemKey }: IFilterWizardProps) => (
  <FilterWizard
    key={itemKey}
    filter={dynamicFilter}
    onChange={onChange}
    sourceInfo={{
      dataFilters: [],
      reportName: '',
    }}>
    <FilterPillButton disable={!(dynamicFilter.isEditable ?? true)} />
  </FilterWizard>
)

export default { renderIntervalDropDown, renderFilterWizard }
