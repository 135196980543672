import { SVGProps } from 'react'
function ToastError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.841 14.33l9.49-9.489a7.003 7.003 0 00-9.49 9.49zm2.828 2.829a7.003 7.003 0 009.49-9.49l-9.49 9.49zm11.11 1.62c-4.297 4.295-11.261 4.295-15.557 0-4.296-4.297-4.296-11.261 0-15.557 4.296-4.296 11.26-4.296 15.556 0 4.296 4.296 4.296 11.26 0 15.556z'
        fill='#FF002E'
      />
    </svg>
  )
}

export default ToastError
