import { format, parseISO } from 'date-fns'
import { round } from 'lodash'

import { invalidNamePattern } from './regex'
import { TIMEZONE } from './reports'

const formatCurrency = (n: any, showDecimals?: boolean, numOfDecimals?: number) => {
  const decimalDigits = numOfDecimals ?? 2
  const rounded = round(n, decimalDigits)
  const decimals = showDecimals || n % 1 !== 0 ? decimalDigits : 0
  const amount = rounded.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  return amount.includes('-') ? amount.replace('-', '-$') : `$${amount}`
}

const CENTS_IN_DOLLAR = 100

const formatCents = (n: number, showDecimals?: boolean, numOfDecimals?: number) =>
  formatCurrency(n / CENTS_IN_DOLLAR, showDecimals, numOfDecimals)

const formatString = (string: string | null | undefined) =>
  string != null
    ? string.split(',').join('-').split('#').join('').replace(invalidNamePattern, '')
    : '-'

const formatAmount = (amount: number | null | undefined, numOfDecimals?: number) =>
  amount ? `${formatCents(amount, true, numOfDecimals).split(',').join('')}` : '-'

const formatDate = (date: string | null | undefined) =>
  date ? format(parseISO(date), 'yyyy-MM-dd') + ' ' + TIMEZONE.UTC : '-'

const formatNumber = (number: number | null | undefined) => (number ?? 0).toString()

const formatBoolean = (boolean: boolean | undefined | null) =>
  typeof boolean != null ? String(boolean) : '-'

const downloadCsvData = (data: string, filename: string) => {
  const downloadCsvEncodedUri = `data:text/csv;charset=utf-8,${data}`
  const downloadCsvFilename = `${filename}.csv`

  const downloadLink = document.createElement('a')
  downloadLink.href = downloadCsvEncodedUri
  downloadLink.download = downloadCsvFilename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export { downloadCsvData, formatAmount, formatBoolean, formatDate, formatNumber, formatString }
