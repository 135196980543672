import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MouseEvent, useState } from 'react'

type IProps = {
  id: string
  menuItems: { label: string; value: any; handleClick: (value: any) => void }[]
  children: React.ReactNode
}
export const MenuList = ({ id, menuItems, children }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <div
        id='basic-button'
        aria-controls={open ? id : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {children}
      </div>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1600 }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item?.handleClick(item.value)
              handleClose()
            }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
