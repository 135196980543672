import { z } from 'zod'

const qaFormSchema = z.object({
  brand: z.string().optional(),
  categoryName: z.string().optional(),
  numberOfErrors: z.number().optional(),
  productName: z.string().optional(),
  errorType: z.string().optional(),
  errorCategory: z.string().optional(),
  errorDescription: z.string().optional(),
  errorSummary: z.number().optional(),
  dontCountError: z.boolean().optional(),
  errorCorrection: z.string().optional(),
})

export { qaFormSchema }
