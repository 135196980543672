import { SVGProps } from 'react'
function Branch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M32.57 20.078c-6.444 0-11.78 4.741-12.712 10.928h-6.144c-.83 0-1.5-.67-1.5-1.5v-19.5h15.589a5.144 5.144 0 009.91-1.929 5.144 5.144 0 00-9.91-1.928H2.571v3.858h5.786v19.5a5.36 5.36 0 005.357 5.357h6.144c.933 6.187 6.268 10.928 12.713 10.928 7.098 0 12.857-5.759 12.857-12.857 0-7.098-5.759-12.857-12.857-12.857zm6.858 14.25a.43.43 0 01-.429.428h-4.607v4.608a.43.43 0 01-.428.428h-2.786a.43.43 0 01-.429-.428v-4.607h-4.607a.43.43 0 01-.428-.43v-2.785a.43.43 0 01.428-.428h4.607v-4.608a.43.43 0 01.429-.428h2.786a.43.43 0 01.428.428v4.608H39a.43.43 0 01.429.428v2.786z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Branch
