import { Button } from '@invisible/ui/button'
import { Dispatch, FC, SetStateAction } from 'react'

import { TNormalizedBaseRun } from './types'

interface IProps {
  allBaseRuns: TNormalizedBaseRun[]
  isUpdatingManyVariables: boolean
  onSave: (dataToBeRanked: TNormalizedBaseRun[]) => void
  setCurrentView: Dispatch<SetStateAction<'view' | 'rank'>>
}

// eslint-disable-next-line @typescript-eslint/ban-types
const View: FC<IProps> = ({ allBaseRuns, isUpdatingManyVariables, onSave, setCurrentView }) => (
  <div className='relative flex h-full w-full flex-col justify-between'>
    <div className='overflow-auto p-6'>
      <h2>Current Order:</h2>
      <p>
        This is the last saved order. If you wish to update this, please go through the Ranking flow
        and click Save at the end of it.
      </p>
      <div className='grid grid-cols-2 gap-0'>
        {allBaseRuns.map((baseRun, index) => (
          <div
            className='m-4 my-10 mr-0 max-w-md rounded-lg bg-gray-300 py-2 px-5 shadow-lg'
            key={index}>
            <p>{baseRun.rank}</p>
            <p>{baseRun.message}</p>
          </div>
        ))}
      </div>
    </div>
    <div className='flex items-center justify-center gap-6 p-3'>
      <Button
        disabled={allBaseRuns.length > 1 || isUpdatingManyVariables}
        onClick={() => {
          // This will only be active if there are 0 or 1 Base Run, so we set its rank to 1. When there are 0, this wouldn't matter.
          onSave(allBaseRuns.map((baseRun) => ({ ...baseRun, rank: 1 })))
        }}>
        Confirm that this ordering is correct
      </Button>

      <Button
        onClick={() => {
          setCurrentView('rank')
        }}>
        Back to re-ordering
      </Button>
    </div>
  </div>
)

export { View }
