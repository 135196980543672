import {
  createContext,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const RadioInput = (
  props: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) => (
  <input
    className='border-main active:border-theme-main active:bg-theme-main checked:border-theme-main checked:bg-theme-main h-4 w-4 cursor-pointer appearance-none rounded-full border border-solid bg-transparent bg-clip-content p-[2.5px] outline-none checked:p-[2.5px] active:p-[3.5px]'
    {...props}></input>
)

const RadioContext = createContext<{
  selectedValue: string | null
  name: string
  handleChange: (value: string) => void
  variant?: 'button'
}>({
  selectedValue: null,
  name: '',
  handleChange: (value) => undefined,
})

interface Radio extends InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element
  value?: string
  color?: 'danger' | 'warning' | 'success'
  render?: (props: { selected: boolean }) => JSX.Element
}

interface IRadioGroup {
  name: string
  selected?: string
  onChange?: (value: string) => void
  orientation: 'vertical' | 'horizontal'
  variant?: 'button'
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RadioGroup: FC<IRadioGroup> = ({
  selected = null,
  onChange = () => undefined,
  orientation = 'vertical',
  variant,
  name,
  children,
}) => {
  const [selectedValue, setSelectedValue] = useState(selected)

  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) setSelectedValue(selected)
    else isMounted.current = true
  }, [selected])

  const handleChange = (value: string) => {
    setSelectedValue(value)
    onChange(value)
  }

  return (
    <RadioContext.Provider value={{ selectedValue, name, handleChange, variant }}>
      <div
        className={`flex ${orientation === 'vertical' ? 'flex-col' : 'flex-row '} ${
          variant === 'button' || orientation === 'vertical' ? 'gap-2' : 'gap-3'
        }`}>
        {children}
      </div>
    </RadioContext.Provider>
  )
}

const BaseStyles =
  'flex h-8 w-full cursor-pointer items-center justify-center rounded-md border border-solid'

// eslint-disable-next-line @typescript-eslint/ban-types
const Radio: FC<Radio> & { Group: FC<IRadioGroup> } = ({
  label,
  value,
  render,
  color,
  ...props
}) => {
  const context = useContext(RadioContext)

  const { name, handleChange, selectedValue, variant } = context
  const SelectedColorStyles =
    color === 'danger'
      ? 'border-red-main bg-red-weak'
      : color === 'warning'
      ? 'border-amber-main bg-amber-weak'
      : color === 'success'
      ? 'border-green-main bg-green-weak'
      : ''
  if (variant === 'button') {
    return (
      <button
        className={`${BaseStyles} ${selectedValue === value ? SelectedColorStyles : ''}`}
        onClick={() => handleChange(value ?? '')}>
        {render ? render({ selected: selectedValue === value }) : label}
      </button>
    )
  }

  return typeof label === 'string' ? (
    <label className='flex items-center gap-2 leading-5' data-cy='radio'>
      <RadioInput
        type='radio'
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={() => handleChange(value ?? '')}
        {...props}
      />
      {label}
    </label>
  ) : (
    <div className='flex items-center' data-cy='radio'>
      <RadioInput
        type='radio'
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={() => handleChange(value ?? '')}
        {...props}
      />
      {label}
    </div>
  )
}

Radio.Group = RadioGroup

export { Radio }
