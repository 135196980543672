import { useAbilityContext } from '@invisible/authorization/client'
import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { Stories } from '@invisible/common/components/reports'
import {
  IDataFilter,
  ReportingScopeEnum,
  TFilterValue,
  TReportingPlatform,
} from '@invisible/common/types'
import { FILTER_TYPE } from '@invisible/ui/constants'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { useGate } from 'statsig-react'

import { ExportsHome } from './exports/Home'
import { ProcessBase } from './ProcessBase'
import { ProcessCanvas } from './ProcessCanvas'
import { ProcessDocuments } from './ProcessDocuments'
import { ProcessFinances } from './ProcessFinances'
import ProcessOverview from './ProcessOverview'
import { BaseFilter, BaseVariableFilter } from './processStoryStaticFilters'

type ProcessViews =
  | 'overview'
  | 'process'
  | 'finance'
  | 'reports'
  | 'documents'
  | 'canvas'
  | 'exports'
interface IProps {
  processData: TProcessWithBases
  view: ProcessViews
  platform: TReportingPlatform
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProcessHome: React.FC<IProps> = ({ view, platform, processData }) => {
  const router = useRouter()
  const [storiesStaticFilters, setStoriesStaticFilters] = useState<IDataFilter<TFilterValue>[]>([])
  const [Can] = useAbilityContext()

  const { value: enableManticoreProcessReports } = useGate('enable-manticore-process-reports')
  const { value: showBaseVariableFilter } = useGate('enable-base-variable-reporting')

  // For now we are only dealing with BASE filter via dropdown.
  const handleBaseChange = (updatedBaseFilter: IDataFilter<TFilterValue>) => {
    setStoriesStaticFilters((prev) => {
      const noBaseStaticFilters = prev.filter(
        (staticFilter) => staticFilter.name !== FILTER_TYPE.BASE
      )
      return [...noBaseStaticFilters, updatedBaseFilter]
    })
  }

  const handleBaseVariablesChange = (updatedBaseVariableFilter?: IDataFilter<TFilterValue>) => {
    setStoriesStaticFilters((prev) => {
      const noBaseVariableStaticFilters = prev.filter(
        (staticFilter) => staticFilter.name !== FILTER_TYPE.BaseVariable
      )
      return [
        ...noBaseVariableStaticFilters,
        ...(updatedBaseVariableFilter ? [updatedBaseVariableFilter] : []),
      ]
    })
  }

  const selectedBase = useMemo(() => {
    const baseFilter = storiesStaticFilters.find(
      (staticFilter) => staticFilter.name === FILTER_TYPE.BASE
    )
    const selectedBaseId = (baseFilter?.value as string[])?.[0]

    return processData.bases.find((base) => base.id === selectedBaseId)
  }, [storiesStaticFilters, processData])

  const baseSla = useMemo(() => {
    const baseId = processData.defaultReportBaseId ?? processData.rootBaseId

    const base = processData.bases.find((base) => base.id === baseId) ?? processData.bases[0]
    return base.sla
  }, [processData.defaultReportBaseId, processData.rootBaseId, processData.bases])

  return (
    <div className='flex h-full flex-col'>
      {view === 'overview' ? (
        <ProcessOverview
          processId={processData?.id}
          companyName={processData?.company?.name}
          processCreatedAt={processData?.createdAt}
          processUpdatedAt={processData?.updatedAt}
          processDescription={processData?.description}
          baseSla={baseSla}
          baseQualityKpi={processData?.bases[0].qualityKpi}
        />
      ) : view === 'process' ? (
        <ProcessBase showGetNextTask={true} showImportWizard={true} processData={processData} />
      ) : view === 'finance' ? (
        <ProcessFinances
          companyId={processData.companyId as string}
          processId={router.query['id'] as string}
        />
      ) : view === 'reports' && (enableManticoreProcessReports ?? true) ? (
        <Stories
          scopedId={router.query['id'] as string}
          scope={ReportingScopeEnum.Process}
          platform={platform}
          staticFilters={storiesStaticFilters}>
          <BaseFilter
            bases={processData.bases}
            defaultReportBaseId={processData.defaultReportBaseId}
            onChange={handleBaseChange}
          />
          {showBaseVariableFilter ? (
            <BaseVariableFilter base={selectedBase} onChange={handleBaseVariablesChange} />
          ) : null}
        </Stories>
      ) : view === 'documents' ? (
        <ProcessDocuments
          companyId={processData.companyId as string}
          processId={router.query['id'] as string}
          platform={platform}
        />
      ) : view === 'canvas' ? (
        <ProcessCanvas processId={router.query['id'] as string} />
      ) : view === 'exports' ? (
        <Can I='read' a='ProcessExports'>
          <ExportsHome />
        </Can>
      ) : null}
    </div>
  )
}

export { ProcessHome }
