import { SVGProps } from 'react'
function HeartOff(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.422 3.428a4.063 4.063 0 00-.889-1.293A4.146 4.146 0 009.62.95 4.173 4.173 0 007 1.873 4.173 4.173 0 004.38.95a4.146 4.146 0 00-2.912 1.184A4.038 4.038 0 00.25 5.02c0 .52.107 1.062.318 1.614.176.46.43.939.753 1.421.512.765 1.217 1.561 2.092 2.37a23.385 23.385 0 002.947 2.3l.37.238a.502.502 0 00.54 0l.37-.237c.06-.04 1.495-.963 2.946-2.302.875-.808 1.58-1.604 2.092-2.369.324-.482.579-.96.754-1.421.21-.552.317-1.094.317-1.614a3.977 3.977 0 00-.327-1.593zM7 11.728S1.438 8.164 1.438 5.021c0-1.593 1.317-2.883 2.942-2.883A2.95 2.95 0 017 3.706a2.95 2.95 0 012.62-1.568c1.626 0 2.943 1.29 2.943 2.883C12.563 8.164 7 11.728 7 11.728z'
        fill='currentColor'
      />
    </svg>
  )
}

export default HeartOff
