import { theme as baseTheme } from './base-theme'

const primaryFg = baseTheme.grayScale[8]
const secondaryFg = '#dbd5f2'
const primaryBg = '#444052'
const secondaryBg = '#6f6b7d'

const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primaryFg,
    secondaryFg,
    primaryBg,
    secondaryBg,
  },
}

export default theme
