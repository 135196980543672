import { z } from '@invisible/zod'

import { schema as dataFilterSchema } from '../../DataFilter'

const schema = z.object({
  columnName: z.string(),
  reportName: z.string(),
  limit: z.number(),
  page: z.number(),
  dataFilters: z.array(dataFilterSchema),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
