import { UuidSchema, z } from '@invisible/zod'

const schema = z.object({
  id: UuidSchema,
  label: z.string(),
  sectionId: UuidSchema.optional(),
  checkItems: z
    .array(
      z.object({
        id: UuidSchema,
        value: z.string(),
        isRequired: z.boolean(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
