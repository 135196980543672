import { SVGProps } from 'react'
function DatabricksOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='28'
      viewBox='0 0 26 28'
      fill='none'
      {...props}>
      <path
        d='M24.0516 11.3689L12.6748 17.8214L0.572657 10.9464L0 11.2536V16.2467L12.6748 23.4675L24.0516 17.0149V19.665L12.6748 26.1177L0.572657 19.2425L0 19.5498V20.3948L12.6748 27.6155L25.3879 20.3948V15.4017L24.8152 15.0944L12.6748 21.9695L1.33617 15.5169V12.9052L12.6748 19.3194L25.3879 12.0986V7.18235L24.7388 6.83668L12.6748 13.6733L1.94697 7.56643L12.6748 1.49792L21.5319 6.491L22.3336 6.06851V5.45398L12.6748 0L0 7.18235V7.95052L12.6748 15.1713L24.0516 8.71868V11.3689Z'
        fill='#FF3621'
      />
    </svg>
  )
}

export default DatabricksOriginal
