import { SVGProps } from 'react'
function AddUserFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M22.448 13.8966C20.6307 13.8966 18.9203 14.4738 17.5306 15.4572C17.103 15.2434 16.654 15.0724 16.2051 14.9014C18.4072 13.4476 19.8824 10.9462 19.8824 8.12414C19.8824 3.63448 16.2264 0 11.758 0C7.28963 0 3.63365 3.63448 3.63365 8.12414C3.63365 10.9462 5.10887 13.4476 7.31101 14.9014C6.51995 15.1793 5.77165 15.5428 5.04473 15.9703C3.01363 17.189 1.30324 18.9634 0.127339 21.0586C-0.150601 21.5931 0.0418188 22.2345 0.554938 22.5338C1.06806 22.8117 1.70946 22.6193 2.00878 22.1062C2.99225 20.3317 4.42471 18.8352 6.13511 17.809C9.06416 16.0559 12.6988 15.7993 15.8202 17.0393C14.623 18.5145 13.896 20.3959 13.896 22.4483C13.896 27.1731 17.723 31 22.448 31C27.173 31 31 27.1731 31 22.4483C31 17.7234 27.173 13.8966 22.448 13.8966ZM11.758 14.1103C8.46552 14.1103 5.77165 11.4166 5.77165 8.12414C5.77165 4.83172 8.46552 2.13793 11.758 2.13793C15.0506 2.13793 17.7444 4.83172 17.7444 8.12414C17.7444 11.4166 15.0506 14.1103 11.758 14.1103ZM25.655 23.5172H23.517V25.6552C23.517 26.2538 23.0467 26.7241 22.448 26.7241C21.8494 26.7241 21.379 26.2538 21.379 25.6552V23.5172H19.241C18.6424 23.5172 18.172 23.0469 18.172 22.4483C18.172 21.8497 18.6424 21.3793 19.241 21.3793H21.379V19.2414C21.379 18.9848 21.4645 18.7497 21.5928 18.5786C21.8066 18.3221 22.1059 18.1724 22.448 18.1724C23.0467 18.1724 23.517 18.6428 23.517 19.2414V21.3793H25.655C26.2536 21.3793 26.724 21.8497 26.724 22.4483C26.724 23.0469 26.2536 23.5172 25.655 23.5172Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AddUserFilled
