import { SVGProps } from 'react'
function UserOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='31'
      viewBox='0 0 32 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3715 20.7415C7.171 18.7921 5.02851 15.2305 5.02851 11.16C5.02851 4.99649 9.94052 0 15.9998 0C22.0591 0 26.9711 4.99649 26.9711 11.16C26.9711 15.2305 24.8287 18.792 21.6282 20.7414C22.8467 21.1424 24.0253 21.674 25.1435 22.3308C27.9234 23.9635 30.2318 26.3119 31.8365 29.1399C32.1731 29.733 31.9733 30.4914 31.3902 30.8337C30.8071 31.1761 30.0616 30.9728 29.725 30.3797C28.3342 27.9288 26.3336 25.8935 23.9243 24.4785C21.5554 23.0871 18.8734 22.3436 16.1396 22.3191C16.0931 22.3196 16.0465 22.3199 15.9998 22.3199C15.9532 22.3199 15.9066 22.3196 15.8601 22.3191C13.1264 22.3436 10.4445 23.0871 8.07566 24.4785C5.6664 25.8935 3.66581 27.9288 2.275 30.3797C1.93843 30.9728 1.1929 31.1761 0.609817 30.8337C0.0267318 30.4914 -0.173106 29.733 0.163467 29.1399C1.76824 26.3119 4.07661 23.9635 6.85653 22.3308C7.97463 21.6741 9.15315 21.1425 10.3715 20.7415ZM7.46657 11.16C7.46657 6.36615 11.287 2.47999 15.9998 2.47999C20.7126 2.47999 24.533 6.36615 24.533 11.16C24.533 15.9088 20.784 19.7669 16.1324 19.8389C16.0883 19.8386 16.0441 19.8384 16 19.8384C15.9558 19.8384 15.9116 19.8386 15.8673 19.8389C11.2157 19.7669 7.46657 15.9088 7.46657 11.16Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default UserOutline
