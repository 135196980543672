import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
interface CustomNodeJsProcess extends NodeJS.Process {
  browser: boolean
}

const safeJSONParse = (a: string) => {
  try {
    return JSON.parse(a)
  } catch {
    return undefined
  }
}

const isCustomEvent = (event: Event): event is StorageEvent => 'newValue' in event

const get = (key: string) => {
  if (!(process as CustomNodeJsProcess).browser) return
  const value = String(localStorage.getItem(key)) // if key not set, value = 'null'
  const validValue = value !== 'undefined'
  return safeJSONParse(validValue ? value : 'null')
}

const set = (key: string, data: unknown) => {
  if (!(process as CustomNodeJsProcess).browser) return
  const jsonData = JSON.stringify(data)
  localStorage.setItem(key, jsonData)
  const evt = new StorageEvent('storage', { key, newValue: jsonData })
  document.dispatchEvent(evt)
}

const remove = (key: string) =>
  (process as CustomNodeJsProcess).browser && localStorage.removeItem(key)

const clear = () => (process as CustomNodeJsProcess).browser && localStorage.clear()

export const localStore = {
  get,
  set,
  remove,
  clear,
}

export const useLocalStore = <T>(
  key: string,
  initialState?: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(() => {
    try {
      const storedValue = localStore.get(key)
      return storedValue ?? initialState
    } catch (err) {
      return initialState
    }
  })
  const setValue = useCallback(
    (dataOrFn: Omit<T, 'Function'> | ((state: T) => T)) => {
      try {
        const isFn = typeof dataOrFn === 'function'
        localStore.set(key, isFn ? dataOrFn(state) : dataOrFn)
        setState(isFn ? dataOrFn(state) : dataOrFn)
      } catch (err) {
        throw new Error(`useLocalStore hook error for key: ${key} ${(err as Error).message}`)
      }
    },
    [key, state]
  )
  useEffect(() => {
    const eventListener = (e: Event) => {
      if (!isCustomEvent(e)) throw new Error('Not a storage event')

      if (e.key !== key) return
      const value = e.newValue ? safeJSONParse(e.newValue) : null
      setState(value)
    }
    document.addEventListener('storage', eventListener)
    return () => document.removeEventListener('storage', eventListener)
  }, [key])
  return [state, setValue]
}
