import * as dateFns from 'date-fns'
import { addMinutes, endOfDay, parseISO, startOfDay, subMinutes } from 'date-fns/fp'
import * as dateFnsTz from 'date-fns-tz'
import { toLower } from 'lodash/fp'
import { flow, times } from 'lodash/fp'
import { chain } from 'radash'

import { calculatePaymentCycle } from './calculatePaymentCycle'

const DAY_OF_WEEK_NAMES = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
} as const

const SHORT_DATE_FORMAT = 'MM/dd/yy'
const DATE_TIME_FORMAT = 'MM/dd/yy, HH:mm'
const DATE_TIME_WITH_SEC_FORMAT = 'MM/dd/yy, HH:mm aa'

const formatToShortDate = (date: Date) => dateFns.format(date, SHORT_DATE_FORMAT)
const formatToDateTime = (date: Date) => dateFns.format(date, DATE_TIME_FORMAT)
const formatToDateTimeWithSec = (date: Date) => dateFns.format(date, DATE_TIME_WITH_SEC_FORMAT)

type TDayOfWeekName = typeof DAY_OF_WEEK_NAMES[keyof typeof DAY_OF_WEEK_NAMES]

const ISO_DOW_NUMBERS = {
  [DAY_OF_WEEK_NAMES.MONDAY]: 1,
  [DAY_OF_WEEK_NAMES.TUESDAY]: 2,
  [DAY_OF_WEEK_NAMES.WEDNESDAY]: 3,
  [DAY_OF_WEEK_NAMES.THURSDAY]: 4,
  [DAY_OF_WEEK_NAMES.FRIDAY]: 5,
  [DAY_OF_WEEK_NAMES.SATURDAY]: 6,
  [DAY_OF_WEEK_NAMES.SUNDAY]: 7,
} as const

// 23 hours 59 minutes 59 seconds
const LAST_SECOND_OF_DAY_IN_SECONDS = 86399

/**
 * Given a date, format it as a string in the given time zone, with the given format
 */
const formatInTimeZone = ({
  date,
  tz = 'UTC',
  fmt = `dd MMM yyyy h:mm a z`,
}: {
  date?: Date | string | null
  tz?: string
  fmt?: string
}) => {
  if (!date) return '-'
  const d = typeof date === 'string' ? new Date(date) : date
  return dateFnsTz.formatInTimeZone(d, tz, fmt)
}

/**
 * Given a string with no time zone, parse it as a date in UTC
 */
const parseAsUTC = (s: string) => dateFnsTz.zonedTimeToUtc(s, 'UTC')

/**
 * Given a date (with or without time zone), format it in UTC time
 */
const formatAsUTC = (d: Date, fmt?: string) => formatInTimeZone({ date: d, tz: 'UTC', fmt })

const parseReparseUTC = (x: string | Date) => (typeof x === 'string' ? parseAsUTC(x) : x)

/**
 * Get the start of day formatted to ISO string at UTC time
 */
const getStartOfDayInUTCAsISOString = (date: Date = new Date()) => {
  const today = dateFns.parseISO(date.toISOString())
  const zonedTime = dateFnsTz.utcToZonedTime(today, 'Etc/GMT')
  const dayStartZoned = dateFns.startOfDay(zonedTime)
  const dayStart = dateFnsTz.zonedTimeToUtc(dayStartZoned, 'Etc/GMT')
  return dayStart.toISOString()
}

/**
 * Get the end of day formatted to ISO string at UTC time
 */
const getEndOfDayInUTCAsISOString = (date: Date = new Date()) => {
  const today = dateFns.parseISO(date.toISOString())
  const zonedTime = dateFnsTz.utcToZonedTime(today, 'Etc/GMT')
  const dayEndZoned = dateFns.endOfDay(zonedTime)
  const dayEnd = dateFnsTz.zonedTimeToUtc(dayEndZoned, 'Etc/GMT')
  return dayEnd.toISOString()
}

/**
 * Get the start of month formatted to ISO string at UTC time
 */
const getStartOfMonthInUTCAsISOString = (date: Date = new Date()) => {
  const today = dateFns.parseISO(date.toISOString())
  const zonedTime = dateFnsTz.utcToZonedTime(today, 'Etc/GMT')
  const monthStartZoned = dateFns.startOfMonth(zonedTime)
  const monthStart = dateFnsTz.zonedTimeToUtc(monthStartZoned, 'Etc/GMT')
  return monthStart.toISOString()
}

/**
 * Get the end of month formatted to ISO string at UTC time
 */
const getEndOfMonthInUTCAsISOString = (date: Date = new Date()) => {
  const today = dateFns.parseISO(date.toISOString())
  const zonedTime = dateFnsTz.utcToZonedTime(today, 'Etc/GMT')
  const monthEndZoned = dateFns.endOfMonth(zonedTime)
  const monthEnd = dateFnsTz.zonedTimeToUtc(monthEndZoned, 'Etc/GMT')
  return monthEnd.toISOString()
}

/**
 * date-fns subMonths does not take DST into account, so we need to offset manually.
 * https://github.com/date-fns/date-fns/issues/571
 */
const agnosticSubMonths = (date: Date, amount: number) => {
  const originalTZO = date.getTimezoneOffset()
  const endDate = dateFns.subMonths(date, amount)
  const endTZO = endDate.getTimezoneOffset()

  const dstDiff = originalTZO - endTZO

  return dstDiff >= 0
    ? dateFns.addMinutes(endDate, dstDiff)
    : dateFns.subMinutes(endDate, Math.abs(dstDiff))
}

const epochMs = (s: string | Date) => parseReparseUTC(s).valueOf()

const epochSeconds = (s: string | Date) => parseReparseUTC(s).valueOf() / 1000

/**
 * Rounds a given date to a given interval (default to 1 hour)
 */
const roundTime = (d: Date, intervalMs = 60 * 60 * 1000) =>
  new Date(Math.round(d.getTime() / intervalMs) * intervalMs)

/**
 * Returns true if A is after B
 */
const flexibleIsAfter = (dateTimeA: string | Date, dateTimeB: string | Date) => {
  const a = parseReparseUTC(dateTimeA)
  const b = parseReparseUTC(dateTimeB)
  return dateFns.isAfter(a, b)
}

/**
 * Returns true if A is before B
 */
const flexibleIsBefore = (dateTimeA: string | Date, dateTimeB: string | Date) => {
  const a = parseReparseUTC(dateTimeA)
  const b = parseReparseUTC(dateTimeB)
  return dateFns.isBefore(a, b)
}

/**
 * Returns true if A is the same millisecond as B
 */
const flexibleIsSameDate = (dateTimeA: string | Date, dateTimeB: string | Date) => {
  const a = parseReparseUTC(dateTimeA)
  const b = parseReparseUTC(dateTimeB)
  return a.valueOf() === b.valueOf()
}

const flexibleIsBeforeOrEqual = (dateTimeA: string | Date, dateTimeB: string | Date) =>
  flexibleIsBefore(dateTimeA, dateTimeB) || flexibleIsSameDate(dateTimeA, dateTimeB)

/**
 * Given a day of the week and a timezone, returns the date for the next occurrence of that day of the week
 * Returns the same date if given the current day of the week
 */
const nextDateForDow = ({ dow, tz = 'America/New_York' }: { dow: TDayOfWeekName; tz?: string }) => {
  const now = new Date()
  const nowDow = toLower(dateFnsTz.format(now, 'iiii', { timeZone: tz })) as TDayOfWeekName
  if (dow === nowDow) return now
  const dayDiff = ISO_DOW_NUMBERS[dow] - ISO_DOW_NUMBERS[nowDow]
  return dayDiff < 0 ? dateFns.addDays(now, 7 + dayDiff) : dateFns.addDays(now, dayDiff)
}

/**
 * Given a time in HH:mm format, add a given number of minutes and return the new time (in HH:mm format)
 */
const addMinutesString = (s: string, minutes: number) =>
  dateFns.format(dateFns.addMinutes(dateFns.parse(s, 'HH:mm', new Date()), minutes), 'HH:mm')

const getEndOfDayUtc = (date: Date) =>
  chain(
    (date) => date.toISOString(),
    parseISO,
    endOfDay,
    subMinutes(new Date().getTimezoneOffset())
  )(date)

const getStartOfDayUtc = (date: Date) =>
  chain(
    (date) => date.toISOString(),
    parseISO,
    startOfDay,
    subMinutes(new Date().getTimezoneOffset())
  )(date)

const getUtcDate = (date: Date) =>
  chain((date) => date.toISOString(), parseISO, addMinutes(new Date().getTimezoneOffset()))(date)

const convertNumberToHours = (number: number) => {
  const min = 1 / 60
  const hour = Math.floor(number)
  const decimal = number - hour
  const decimals = min * Math.round(decimal / min)
  const minute = Math.floor(decimals * 60) + ''
  const minStr = minute.length === 1 ? '0' + minute : minute
  return `${hour}:${minStr}`
}

const secondsToHMS = (d: number) => {
  const isNegative = d < 0
  const absSeconds = Math.abs(d)

  const hours = Math.floor(absSeconds / 3600)
  const remainingSecondsAfterHours = absSeconds % 3600
  const minutes = Math.floor(remainingSecondsAfterHours / 60)
  const secs = Math.floor(remainingSecondsAfterHours % 60)

  let formattedString = `${hours}h ${minutes}min ${secs}secs`
  if (isNegative) {
    formattedString = `-${formattedString}`
  }

  return formattedString
}

const secondsToDuration = (d: number) => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)
  const hDisplay = h.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':'
  const mDisplay = m.toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':'
  const sDisplay = s.toLocaleString('en-US', { minimumIntegerDigits: 2 })
  return hDisplay + mDisplay + sDisplay
}

const parseAsLocale = (date: Date) =>
  new Date(
    date.toLocaleString('en-US', {
      ...(Intl.DateTimeFormat().resolvedOptions().timeZone
        ? { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
        : {}),
    })
  )

const parseToNumber = (value: string) => parseInt(value.replace(/\D/g, ''), 10)

const getDurationInSeconds = (hour: string, minute: string) =>
  Number(hour) * 3600 + Number(minute) * 60

const convertSecondsToHoursMinutesSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  return {
    hours,
    minutes,
    seconds: remainingSeconds,
  }
}

const removeTimezoneOffsetFromDate = flow(
  (d) => d.toISOString(),
  parseISO,
  subMinutes(new Date().getTimezoneOffset()),
  (d) => d.toISOString()
)

const getPaymentCycles = () =>
  times((i: number) => calculatePaymentCycle({ offsetCycles: 1 - i }), 12)

const getInvoiceDateRange = (date?: Date) => {
  const year = date ? date.getUTCFullYear() : new Date().getUTCFullYear()
  const invoiceDate = date ? new Date(date) : new Date()
  const startDate = new Date(year, invoiceDate.getUTCMonth(), 1)
  const endDate = new Date(year, invoiceDate.getUTCMonth() + 1, 0).setUTCHours(23, 59, 59, 999)
  return {
    startDate: dateFnsTz.zonedTimeToUtc(startDate, 'UTC'),
    endDate: dateFnsTz.zonedTimeToUtc(endDate, 'UTC'),
  }
}

export {
  addMinutesString,
  agnosticSubMonths,
  convertNumberToHours,
  convertSecondsToHoursMinutesSeconds,
  DATE_TIME_FORMAT,
  epochMs,
  epochSeconds,
  flexibleIsAfter,
  flexibleIsBefore,
  flexibleIsBeforeOrEqual,
  flexibleIsSameDate,
  formatAsUTC,
  formatInTimeZone,
  formatToDateTime,
  formatToDateTimeWithSec,
  formatToShortDate,
  getDurationInSeconds,
  getEndOfDayInUTCAsISOString,
  getEndOfDayUtc,
  getEndOfMonthInUTCAsISOString,
  getInvoiceDateRange,
  getPaymentCycles,
  getStartOfDayInUTCAsISOString,
  getStartOfDayUtc,
  getStartOfMonthInUTCAsISOString,
  getUtcDate,
  ISO_DOW_NUMBERS,
  LAST_SECOND_OF_DAY_IN_SECONDS,
  nextDateForDow,
  parseAsLocale,
  parseAsUTC,
  parseReparseUTC,
  parseToNumber,
  removeTimezoneOffsetFromDate,
  roundTime,
  secondsToDuration,
  secondsToHMS,
  SHORT_DATE_FORMAT,
}

export type { TDayOfWeekName }
