import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { useQuery } from '@invisible/trpc/client'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { FC, useMemo } from 'react'

import { BaseViewSettings } from './BaseViewSettings'

type TStages = NonNullable<inferQueryOutput<'lifecycleStage.findManyByProcessId'>>
type TBase = TProcessWithBases['bases'][number]
type TBaseView = TBase['baseViews'][number]
type TStep = TBase['steps'][number]

interface IBaseViewEditorProps {
  base: TBase
  baseViews: TBaseView[]
  steps: TStep[]
  stages: TStages
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseViewEditor: FC<IBaseViewEditorProps> = ({ baseViews, steps, base, stages }) => {
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)

  const { data: permissions } = useQuery(['baseView.getPermissions', { id: baseViewId }])

  const currentBaseView = useMemo(
    () => baseViews.find((bv) => bv.id === baseViewId),
    [baseViews, baseViewId]
  )

  return currentBaseView && permissions ? (
    <BaseViewSettings
      permissions={permissions}
      currentBaseView={currentBaseView}
      base={base}
      steps={steps}
      stages={stages}
    />
  ) : null
}
