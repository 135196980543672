import { z } from '@invisible/zod'

import { schema as ReactGridLayout } from './ReactGridLayout'
import { schema as WACConfigSchema } from './WACConfig'

const schema = z.array(
  z
    .object({
      layout: ReactGridLayout,
      config: WACConfigSchema,
    })
    .strict()
)
type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
