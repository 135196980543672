import { CHART_TYPE } from '@invisible/ui/constants'
import { BaseOutlineIcon, GlobalOutlineIcon, SalesFilledIcon } from '@invisible/ui/icons'
import BarChartIcon from '@mui/icons-material/BarChart'
import PieChartIcon from '@mui/icons-material/PieChart'
import { SVGProps } from 'react'

export const VISUALIZATION_ICONS = {
  [CHART_TYPE.Choropleth]: GlobalOutlineIcon,
  [CHART_TYPE.BarChart]: BarChartIcon,
  [CHART_TYPE.PieChart]: PieChartIcon,
  [CHART_TYPE.Table]: BaseOutlineIcon,
  [CHART_TYPE.LineChart]: SalesFilledIcon,
  // eslint-disable-next-line @typescript-eslint/ban-types
} as unknown as Record<string, React.FC<SVGProps<SVGSVGElement>>>
