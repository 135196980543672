import { TProcessByIdStep, useProcessById } from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { BuildIcon, RobotIcon, WizardHatIcon } from '@invisible/ui/icons'
import { useMemo } from 'react'
import { Handle, Position } from 'reactflow'

interface IProps {
  selected: boolean
  data: {
    step: TProcessByIdStep
    metaData?: {
      stepId: string
      status?: string
      count?: {
        done?: number
        failed?: number
        pending?: number
        queued?: number
        disabled?: number
        running?: number
        snoozed?: number
        expired?: number
      }
      childBaseRuns?: number
    }
  }
  xPos: number
  yPos: number
}

const statusBackgroundColors = {
  done: 'bg-green-500',
  failed: 'bg-red-500',
  snoozed: 'bg-yellow-500',
  running: 'bg-blue-500',
  queued: 'bg-violet-500',
  disabled: 'bg-black',
  pending: 'bg-gray-400',
  expired: 'bg-orange-700',
}

const statusBorderColors = {
  done: 'border-green-500',
  failed: 'border-red-500',
  snoozed: 'border-yellow-500',
  running: 'border-blue-500',
  queued: 'border-violet-500',
  disabled: 'border-black',
  pending: 'border-gray-400',
  expired: 'border-orange-700',
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BaseRunGraphNode: React.FC<IProps> = ({ selected, data, xPos, yPos }) => {
  const { data: process } = useProcessById({
    id: data.step.processId,
  })

  const step = useMemo(() => process?.steps.find((s) => s.id === data.step.id), [process])
  const count = data.metaData?.count

  // Do not render if it was not auto-layed out by algo
  if (xPos === 0 && yPos === 0) return null

  if (!process) return null

  return (
    <div className='relative'>
      <Handle
        type='target'
        isConnectable={process.status !== 'active' && process.status !== 'testing'}
        position={Position.Top}
        className='!h-1 !w-1 !bg-purple-500'
      />
      <Handle type='source' position={Position.Bottom} className='!h-1 !w-1 !bg-purple-500' />
      <div
        className={classNames(
          'text-primary box-border flex h-16 w-64 cursor-default items-center gap-3 rounded border-0 border-l-[6px] border-solid border-white bg-white pl-3',
          selected ? 'outline outline-rose-500' : '',
          data?.metaData?.status
            ? statusBorderColors[data.metaData.status as keyof typeof statusBorderColors]
            : ''
        )}>
        <div className='ml-2 flex flex-col text-xs'>
          <span className='w-36 truncate font-bold tracking-wider' title={step?.name ?? ''}>
            {step?.name ?? ''}
          </span>
          <span className='w-36 truncate tracking-wide' title={data.step.stepTemplate.name}>
            {data.step.stepTemplate.name}
          </span>
        </div>
        <div className='flex flex-col items-center gap-2'>
          {data.step.stepTemplate.type === 'manual' ? (
            <WizardHatIcon className='h-4.5 w-4.5 text-gray-900' />
          ) : data.step.stepTemplate.type === 'control_flow' ||
            data.step.stepTemplate.type === 'trigger' ? (
            <BuildIcon className='h-4 w-4 text-gray-700' />
          ) : (
            <RobotIcon className='h-4 w-4 text-gray-900' />
          )}

          {data.metaData?.childBaseRuns ? (
            <div className='font-light text-gray-900'>{data.metaData.childBaseRuns}</div>
          ) : null}
        </div>
        <div className='flex flex-col gap-1'>
          {Object.keys(count ?? {}).map((status) => (
            <div className='flex items-center gap-1' key={status}>
              <div
                className={classNames(
                  'h-2 w-2 rounded-full',
                  statusBackgroundColors[status as keyof typeof statusBackgroundColors]
                )}
              />
              <div className='text-[8px] text-black'>{count?.[status as keyof typeof count]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { BaseRunGraphNode }
