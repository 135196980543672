import { z } from '@invisible/zod'
import { merge } from 'lodash/fp'

import * as TriggerStepMeta from './TriggerStepMeta'

const cronExpressionSchema = z.string().regex(/(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7}/)

/**
 * Cron Trigger step metadata
 */
const schema = z.union([
  TriggerStepMeta.triggerWithPayloadSchema.extend({
    cronExpression: cronExpressionSchema.optional(), // keeping this for any existing references?
    jobId: z.string().optional(),
  }),
  TriggerStepMeta.triggerWithoutPayloadSchema.extend({
    cronExpression: cronExpressionSchema.optional(),
    jobId: z.string().optional(),
  }),
])

/**
 * Cron Trigger step metadata
 */
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates CronTriggerStepTemplateMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates CronTriggerStepTemplateMeta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({
  stepTemplateMeta,
  data,
}: {
  stepTemplateMeta: TSchema
  data: TUpdateArgs
}): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(stepTemplateMeta, updateArgs)
}

export { create, createArgsSchema, cronExpressionSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
