import { SVGProps } from 'react'

function Build(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78044)'>
        <path
          d='M28.7039 20.1452H26.221V18.96C26.221 18.3319 26.1016 17.7274 25.8509 17.1348C25.6002 16.5541 25.2302 16.0326 24.7646 15.5941C24.2991 15.1556 23.75 14.8 23.1531 14.563C22.5443 14.326 21.8878 14.2548 21.2432 14.2074H17.5188V10.6519H19.381C20.7538 10.6519 21.8639 9.58522 21.8639 8.28151V2.36744C21.8639 1.06374 20.7538 -0.00292969 19.381 -0.00292969H13.1618C11.789 -0.00292969 10.6788 1.06374 10.6788 2.36744V8.29337C10.6788 9.59707 11.789 10.6637 13.1618 10.6637H15.024V14.2193H11.2757C10.6192 14.2193 9.98649 14.3378 9.38964 14.5748C8.78084 14.8119 8.23174 15.1556 7.76619 15.606C7.30064 16.0445 6.94253 16.566 6.69185 17.1467C6.44117 17.7274 6.3218 18.3437 6.3218 18.96V20.1452H3.83888C2.46611 20.1452 1.35596 21.2119 1.35596 22.5156V29.6267C1.35596 30.9304 2.46611 31.9971 3.83888 31.9971H11.2996C12.6723 31.9971 13.7825 30.9304 13.7825 29.6267V22.5156C13.7825 21.2119 12.6723 20.1452 11.2996 20.1452H8.81665V18.96C8.81665 18.6519 8.87634 18.3437 9.00765 18.0474C9.12702 17.763 9.31801 17.5023 9.54482 17.2771C9.77162 17.0519 10.0462 16.886 10.3565 16.7674C10.655 16.6489 10.9773 16.6252 11.3115 16.5897H21.279C21.6013 16.5897 21.9236 16.6489 22.222 16.7674C22.5205 16.886 22.795 17.0637 23.0338 17.2771C23.2606 17.5023 23.4396 17.7511 23.5709 18.0474C23.7022 18.3437 23.7619 18.64 23.7619 18.96V20.1452H21.279C19.9062 20.1452 18.7961 21.2119 18.7961 22.5156V29.6267C18.7961 30.9304 19.9062 31.9971 21.279 31.9971H28.7397C30.1125 31.9971 31.2226 30.9304 31.2226 29.6267V22.5156C31.2226 21.2119 30.1125 20.1452 28.7397 20.1452H28.7039ZM13.1618 2.36744H19.381V8.29337H13.1618V2.36744ZM11.2996 29.6267H3.82694V22.5156H11.2876V29.6267H11.2996ZM28.7039 29.6267H21.2432V22.5156H28.7039V29.6267Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78044'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Build
