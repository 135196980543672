import { UuidSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

const baseOperatingBaseSchema = z.object({
  baseId: UuidSchema,
  // indicates if the base should be completed.
  // this is useful for multi-level base structures, where we don't want to complete a base run,
  // but we want to complete its parent and children.
  completeBase: z.boolean(),
})

type TChild = z.infer<typeof baseOperatingBaseSchema> & {
  children: TChild[]
}

const operatingBasesSchema: z.ZodType<TChild> = baseOperatingBaseSchema.extend({
  children: z.lazy(() => operatingBasesSchema.array()),
})

const schema = z.object({ operatingBases: operatingBasesSchema })

type TSchema = z.infer<typeof schema>

const createArgsSchema = schema.strip()
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates EndBaseRunStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates EndMapStepMeta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({ endBaseRunMeta, data }: { endBaseRunMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(endBaseRunMeta, updateArgs)
}

export { create, createArgsSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
