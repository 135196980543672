import { SVGProps } from 'react'

function FileImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_2_290)'>
        <path
          d='M13.101 11.9223L11.0171 14.5795L9.91618 13.1759C9.89613 13.1503 9.87052 13.1296 9.84128 13.1153C9.81205 13.1011 9.77995 13.0937 9.74743 13.0937C9.7149 13.0937 9.68281 13.1011 9.65357 13.1153C9.62433 13.1296 9.59872 13.1503 9.57868 13.1759L6.90546 16.583C6.88073 16.6146 6.86539 16.6525 6.86118 16.6924C6.85698 16.7323 6.86408 16.7726 6.88168 16.8086C6.89929 16.8447 6.92668 16.875 6.96072 16.8962C6.99477 16.9175 7.0341 16.9287 7.07421 16.9286H16.9287C17.1081 16.9286 17.2073 16.7223 17.0974 16.583L13.4412 11.9223C13.4209 11.8967 13.395 11.8759 13.3656 11.8617C13.3361 11.8475 13.3038 11.8401 13.2711 11.8401C13.2384 11.8401 13.2061 11.8475 13.1766 11.8617C13.1472 11.8759 13.1213 11.8967 13.101 11.9223V11.9223ZM7.92868 10.125C7.92868 10.4092 8.04156 10.6817 8.24249 10.8826C8.44342 11.0835 8.71595 11.1964 9.00011 11.1964C9.28427 11.1964 9.55679 11.0835 9.75772 10.8826C9.95865 10.6817 10.0715 10.4092 10.0715 10.125C10.0715 9.84084 9.95865 9.56832 9.75772 9.36739C9.55679 9.16645 9.28427 9.05357 9.00011 9.05357C8.71595 9.05357 8.44342 9.16645 8.24249 9.36739C8.04156 9.56832 7.92868 9.84084 7.92868 10.125ZM21.1769 6.01607L15.4126 0.251786C15.2519 0.0910715 15.0349 0 14.8072 0H3.42868C2.95457 0 2.57153 0.383036 2.57153 0.857143V23.1429C2.57153 23.617 2.95457 24 3.42868 24H20.5715C21.0456 24 21.4287 23.617 21.4287 23.1429V6.62411C21.4287 6.39643 21.3376 6.17679 21.1769 6.01607ZM19.4519 7.01786H14.4108V1.97679L19.4519 7.01786ZM19.5001 22.0714H4.50011V1.92857H12.5894V7.71429C12.5894 8.01265 12.7079 8.2988 12.9189 8.50978C13.1299 8.72076 13.416 8.83929 13.7144 8.83929H19.5001V22.0714Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_290'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FileImage
