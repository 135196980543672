// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { FC } from 'react'

import { components } from './components'
import { adminPalette, palette, portalPalette } from './palette'
import typography from './typography'

const createCustomTheme = (customPalette) => {
  const customComponents = components(customPalette)

  return createTheme({
    palette: customPalette,
    typography,
    components: customComponents,
    mixins: {
      MuiDataGrid: {
        containerBackground: customPalette['primary'][50],
      },
    },
  })
}

const theme = createCustomTheme(palette)
const adminTheme = createCustomTheme(adminPalette)
const portalTheme = createCustomTheme(portalPalette)

/**
 * Seperate theme provider mostly used for server side rendering:
 *  https://mui.com/material-ui/guides/server-rendering/#material-ui-on-the-server
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const MUIThemeProvider: FC<{ customTheme?: Theme }> = ({ customTheme, children }) => (
  <ThemeProvider theme={customTheme ?? theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
  </ThemeProvider>
)

export { adminTheme, MUIThemeProvider, palette, portalTheme, theme }
