import { SVGProps } from 'react'
function Play(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9.349 6.747l-5.206-4.49c-.195-.167-.479-.016-.479.253v8.98c0 .269.284.42.479.252l5.206-4.49a.342.342 0 000-.505z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Play
