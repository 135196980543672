import { TDateViewLevel } from '@invisible/common/types'
import { VIEW_TYPES } from '@invisible/ui/constants'
import { GroupedCapsuleButtonList } from '@invisible/ui/grouped-capsule-button-list'
import { findIndex, flow, slice } from 'lodash/fp'

interface IDateViewSelector {
  baseLevel: TDateViewLevel
  selectedLevel?: TDateViewLevel
  onChange: (level: TDateViewLevel) => void
}

interface IDateViewMap {
  key: TDateViewLevel
  value: string
}

const SORTED_REPORT_DATE_VIEW_MAPS: IDateViewMap[] = [
  { key: VIEW_TYPES.HOURLY, value: 'Hour' },
  { key: VIEW_TYPES.DAILY, value: 'Day' },
  { key: VIEW_TYPES.WEEKLY, value: 'Week' },
  { key: VIEW_TYPES.MONTHLY, value: 'Month' },
  { key: VIEW_TYPES.QUARTERLY, value: 'Quarter' },
  { key: VIEW_TYPES.YEARLY, value: 'Year' },
]

const findIndexByDateViewLevel = (baseLevel: TDateViewLevel) =>
  findIndex((datum: { key: TDateViewLevel; value: string }) => datum.key === baseLevel)(
    SORTED_REPORT_DATE_VIEW_MAPS
  )

const sliceDateViewMapListFromIndex = (index: number) =>
  index < 0
    ? SORTED_REPORT_DATE_VIEW_MAPS
    : slice(index, SORTED_REPORT_DATE_VIEW_MAPS.length, SORTED_REPORT_DATE_VIEW_MAPS)

const sliceDateViewMapByLevel = (baseLevel: TDateViewLevel) =>
  flow(findIndexByDateViewLevel, sliceDateViewMapListFromIndex)(baseLevel)

// eslint-disable-next-line @typescript-eslint/ban-types
const DateViewSelector: React.FC<IDateViewSelector> = ({ baseLevel, onChange, selectedLevel }) => {
  const slicedDateViewMap = sliceDateViewMapByLevel(baseLevel)
  return (
    <GroupedCapsuleButtonList
      buttonOptions={slicedDateViewMap}
      onChange={(key) => onChange(key as TDateViewLevel)}
      selectedKey={selectedLevel ?? baseLevel}
    />
  )
}

export { DateViewSelector }
