import { SVGProps } from 'react'
function UserFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='30'
      viewBox='0 0 32 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.8293 28.2007C29.5551 24.3451 25.9149 21.4514 21.6065 20.0742C23.6716 18.8656 25.2764 17.0233 26.1744 14.8301C27.0723 12.6369 27.2138 10.2142 26.577 7.93425C25.9402 5.65427 24.5605 3.6431 22.6496 2.20972C20.7388 0.776326 18.4026 0 16 0C13.5974 0 11.2612 0.776326 9.35037 2.20972C7.43954 3.6431 6.05976 5.65427 5.42298 7.93425C4.78621 10.2142 4.92766 12.6369 5.82563 14.8301C6.72359 17.0233 8.32838 18.8656 10.3935 20.0742C6.08513 21.4514 2.44491 24.3451 0.170708 28.2007C0.0590516 28.3814 0 28.5889 0 28.8004C0 29.012 0.0590516 29.2194 0.170708 29.4002C0.274881 29.584 0.427524 29.7367 0.61254 29.8423C0.797556 29.9478 1.00809 30.0023 1.22193 29.9999H30.7781C30.9919 30.0023 31.2024 29.9478 31.3875 29.8423C31.5725 29.7367 31.7251 29.584 31.8293 29.4002C31.9409 29.2194 32 29.012 32 28.8004C32 28.5889 31.9409 28.3814 31.8293 28.2007Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default UserFilled
