import { MutableRefObject, Ref } from 'react'

const isMutableRefObject = <T>(arg: any): arg is MutableRefObject<T> =>
  (arg as MutableRefObject<T>) !== undefined

export const combineRefs = <T>(...refs: Ref<T>[]) => {
  const validRefs = refs.filter(Boolean)
  if (!validRefs.length) return null
  if (validRefs.length === 1) return validRefs[0]

  return (element: T) => {
    for (const ref of validRefs) {
      if (typeof ref === 'function') {
        ref(element)
      } else if (isMutableRefObject<T>(ref)) {
        ref.current = element
      }
    }
  }
}
