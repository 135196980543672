import { SmallCheckbox } from '@invisible/ui/form'
import { CaretDownIcon, CaretUpIcon } from '@invisible/ui/icons'
import { color, gray, purple, styled, textColor, theme } from '@invisible/ui/themes'
import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from 'rebass'

type THeaderSizes = keyof typeof HEADER_SIZES
type TCellType = 'actions' | 'tags' | 'status'
type TDirection = 'asc' | 'desc' | null
type TCheckBoxType = 'header' | 'cell' | null
interface ITableProps extends React.HTMLAttributes<unknown> {
  widthSize?: string
  headerSize?: THeaderSizes
  activeHeader?: boolean
  to?: string
  cellType?: TCellType
  text?: string
  color?: string
  headerBackground?: string
  textColor?: string
  textBold?: boolean
  stickyHeader?: boolean
}

interface IHeaderSortable extends ITableProps {
  handleChange: (sortDirection?: TDirection, columnName?: string) => void
  sortDirection?: TDirection
  arrowColor?: string
  noSort?: boolean
  textBold?: boolean
  stickyHeader?: boolean
}
interface ICheckBox {
  checked?: boolean
  disabled?: boolean
  checkBoxType?: TCheckBoxType
  onToggle: (value: boolean) => void
}
const HEADER_SIZES = {
  small: 40,
  medium: 48,
  large: 56,
} as const

const TableContainer = styled.table.attrs({ 'data-cy': 'tableContainer' })`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${gray(4)};
`
const TableHeader = styled.th<Partial<ITableProps>>`
  ${({ headerSize = 'large' }) => `height: ${HEADER_SIZES[headerSize]}px`};
  ${({ widthSize }) => `width: ${widthSize}`};
  text-align: left;
  padding: 0px 10px;
  cursor: pointer;
  top: 16px;
  background: ${({ headerBackground }) =>
    headerBackground ? headerBackground : theme.grayScale[2]};
  outline: none;
  box-shadow: inset 0px -1px 0px ${gray(3)};
  border-radius: 0px;
  font-size: 14px;
  line-height: inherit;
  color: ${({ textColor }) => (textColor ? textColor : theme.grayScale[13])};
  flex: none;
  order: 0;
  flex-grow: 0;
  font-weight: ${({ textBold }) => (textBold ? 'bold' : 'normal')};
  margin: 0px 8px;
  position: ${({ stickyHeader }) => (stickyHeader ? 'sticky' : 'relative')};
  top: 0;
`
const HeaderSortContainer = styled(Box)<{ textBold?: boolean }>`
  flex-direction: column;
  display: flex;
  margin-left: 9.25px;
  width: 8.51px;
  height: auto;
  margin-top: 4px;
  font-weight: ${({ textBold }) => (textBold ? 'bold' : 'normal')};
`

const TableCell = styled.td<Partial<ITableProps>>`
  ${({ widthSize }) => `width: ${widthSize}`};
  left: 16px;
  top: 16px;
  background: ${({ color }) => (color ? color : 'transparent')};
  outline: none;
  border-radius: 0px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ textColor }) => (textColor ? textColor : theme.grayScale[13])};
  ${({ cellType }) => cellType && ['actions', 'tags'].includes(cellType) && 'white-space: nowrap;'};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  padding: 0px 10px;
  height: 55px;
  border-bottom: 1px solid  ${gray(4)};
  &:hover {
    background: ${purple(1)};
  }

`

const Link = styled.a`
  color: ${color('primary')};
  text-decoration: none;
`
const ActionText = styled.p`
  color: ${color('primary')};
  cursor: pointer;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  &:first-child {
    margin-left: 0px;
    padding-left: 0px;
  }
  border-right: 1px solid ${gray(3)};
  &:last-child {
    border-right: none;
  }
`

const Row = styled.tr<{ hoverColor?: string }>`
 &:hover {
    background: ${({ hoverColor }) => (hoverColor ? hoverColor : purple(1))};
    ${TableCell} {
      background: ${({ hoverColor }) => (hoverColor ? hoverColor : purple(1))};
    }
  }
`

const Head = styled.thead``

const Body = styled.tbody``

const StatusList = styled.li`
  list-style: none;
  margin-left: 0px;
  font-size: 14px;
  line-height: 22px;
  color: ${textColor('primary')};
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  padding: 0px 10px;
  list-style-image: url('../../public/status.svg');
  span {
    padding-left: 8px;
  }
`

const CheckBoxHeader = styled.th<{ checked?: boolean }>`
  padding: 16px;
  margin: 0px 0px;
  box-shadow: inset 0px -1px 0px ${gray(3)};
  flex: none;
  order: 0;
  flex-grow: 0;
  outline: none;
  text-align: center;
  vertical-align: middle;
  background: ${({ checked, theme }) => (checked ? theme.purpleScale[2] : theme.grayScale[2])};
`
const CheckBoxCell = styled.td<{ checked?: boolean }>`
  padding: 16px;
  margin: 0px 0px;
  border-bottom: 1px solid ${gray(4)};
  flex: none;
  order: 0;
  flex-grow: 0;
  outline: none;
  text-align: center;
  vertical-align: middle;
  background: ${({ checked, theme }) => (checked ? theme.purpleScale[2] : theme.grayScale[1])};
`

const StyledCaretUp = styled(CaretUpIcon)<{ clicked: boolean; color?: string }>`
  path {
    line-height: 9px;
    position: relative;
    background-color: transparent;
    width: 8.51px;
    height: 5.12px;
    &:first-child {
      margin-bottom: 2.88px;
    }
    fill: ${({ clicked, theme, color }) =>
      clicked ? (color ? color : theme.colors.primary) : null};
    fill-opacity: ${({ clicked }) => (clicked ? 1 : null)};
  }
`
const StyledCaretDown = styled(CaretDownIcon)<{
  clicked: boolean
  color?: string
}>`
  path {
    line-height: 9px;
    position: relative;
    background-color: transparent;
    width: 8.51px;
    height: 5.12px;
    &:first-child {
      margin-bottom: 2.88px;
    }
    fill: ${({ clicked, theme, color }) =>
      clicked ? (color ? color : theme.colors.primary) : null};
    fill-opacity: ${({ clicked }) => (clicked ? 1 : null)};
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderSort: FC<{
  onChange: (sortDirection: TDirection) => void
  sortDirection: TDirection
  arrowColor?: string
  noSort?: boolean
  textBold?: boolean
  stickyHeader?: boolean
}> = ({ onChange, sortDirection, arrowColor, noSort, textBold }) => (
  <HeaderSortContainer
    textBold={textBold}
    onClick={() => {
      if (!sortDirection || sortDirection === 'desc') {
        onChange('asc')
      } else {
        onChange('desc')
      }
    }}>
    {noSort ? (
      <div></div>
    ) : (
      <>
        <StyledCaretUp clicked={sortDirection === 'asc'} color={arrowColor} />
        <StyledCaretDown clicked={sortDirection === 'desc'} color={arrowColor} />
      </>
    )}
  </HeaderSortContainer>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const Action: FC<Partial<ITableProps>> = ({ children, text, ...props }) => (
  <ActionText {...props} title={text || children?.toString()}>
    {text || children}
  </ActionText>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const Cell: React.FC<Partial<ITableProps>> = ({
  to,
  cellType,
  children,
  widthSize,
  color,
  textColor,
}) => {
  let element
  if (to) {
    element = (
      <Link href={to} target='_blank'>
        {children}
      </Link>
    )
  } else if (cellType === 'status') {
    element = (
      <StatusList>
        <span>{children}</span>
      </StatusList>
    )
  } else {
    element = children
  }

  return (
    <TableCell cellType={cellType} widthSize={widthSize} color={color} textColor={textColor}>
      {element}
    </TableCell>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Header: React.FC<Partial<ITableProps>> = ({
  headerSize,
  children,
  widthSize,
  headerBackground,
  textColor,
  textBold = true,
  stickyHeader,
}) => (
  <TableHeader
    stickyHeader={stickyHeader}
    headerSize={headerSize}
    widthSize={widthSize}
    headerBackground={headerBackground}
    textColor={textColor}
    textBold={textBold}>
    {children}
  </TableHeader>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderSortable: FC<IHeaderSortable> = ({
  headerSize,
  sortDirection,
  arrowColor,
  handleChange,
  headerBackground,
  textColor,
  children,
  widthSize,
  noSort,
  textBold = true,
  stickyHeader,
}) => {
  const [active, setActive] = useState(false)
  const [direction, setSortDirection] = useState<TDirection>(sortDirection ?? null)
  const [columnName] = useState(children?.toString())
  const handleSortDirection = (direction: TDirection) => {
    if (sortDirection === undefined) {
      setSortDirection(direction)
    }
    handleChange(direction, columnName)
  }
  useEffect(() => {
    if (sortDirection !== undefined) setSortDirection(sortDirection)
  }, [sortDirection])

  useMemo(() => setActive(!!direction), [direction])
  return (
    <TableHeader
      headerSize={headerSize}
      activeHeader={active}
      widthSize={widthSize}
      textBold={textBold}
      stickyHeader={stickyHeader}
      headerBackground={headerBackground}
      textColor={textColor}>
      <Flex flexDirection='row'>
        {children}
        <HeaderSort
          textBold={textBold}
          onChange={handleSortDirection}
          sortDirection={direction}
          arrowColor={arrowColor}
          noSort={noSort}
        />
      </Flex>
    </TableHeader>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CheckBox: FC<ICheckBox> = ({
  checked = false,
  disabled = false,
  onToggle,
  checkBoxType = 'cell',
}) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {checkBoxType === 'header' ? (
      <CheckBoxHeader checked={checked}>
        <Flex pl={2} justifyContent='center'>
          <SmallCheckbox checked={checked} disabled={disabled} onClick={onToggle} />
        </Flex>
      </CheckBoxHeader>
    ) : (
      <CheckBoxCell checked={checked}>
        <Flex pl={2} justifyContent='center'>
          <SmallCheckbox checked={checked} disabled={disabled} onClick={onToggle} />
        </Flex>
      </CheckBoxCell>
    )}
  </>
)

/**
 * @deprecated use @invisible/ui/table instead
 */
const Table = Object.assign(TableContainer, {
  Action,
  Cell,
  HeaderSortable,
  Header,
  Row,
  CheckBox,
  Head,
  Body,
})

export { Table as SortTable }
