import { z } from '@invisible/zod'

import { schema as intervalSchema } from './Interval'

const schema = z.object({
  name: z.string(),
  valueType: z.string(),
  operator: z.string(),
  key: z.string().optional(),
  value: z.union([
    z.null(),
    z.boolean(),
    z.number(),
    z.string(),
    z.array(z.string()),
    intervalSchema,
  ]),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
