import { withErrorBoundary } from '@invisible/common/components/error-boundary'
import { ITile, Tile } from '@invisible/ui/tile'
import { isEmpty } from 'lodash/fp'
import React from 'react'

interface ITileGridProps {
  tiles: ITile[]
  isLoading?: boolean
}

const MAX_COLS = 4

const verticalSeparatorStyles = 'w-px h-33 bg-theme-weak-2 mx-px'

const getTileWidth = (
  tileIndex: number,
  numberOfLastRowTiles: number,
  totalTiles: number
): string => {
  // check if tile in question OR the current tile is one of the last row tiles in the grid
  if (tileIndex + 1 > totalTiles - numberOfLastRowTiles) {
    return numberOfLastRowTiles > 1 ? `${(1 / numberOfLastRowTiles) * 100}%` : '100%'
  } else {
    return `${(1 / MAX_COLS) * 100}%`
  }
}

const shouldRenderSeparator = (index: number, totalTiles: number): boolean =>
  index !== totalTiles - 1 && (index + 1) % MAX_COLS !== 0

export const TileGrid = withErrorBoundary(({ tiles, isLoading }: ITileGridProps) => {
  const numberOfLastRowTiles = tiles?.length % MAX_COLS
  if (!isLoading && isEmpty(tiles)) {
    return (
      <div className='text-paragraphs flex h-20 items-center justify-center text-base'>
        There is no data to show at the moment
      </div>
    )
  } else {
    return (
      <div className='flex flex-row flex-wrap items-center justify-center'>
        {tiles?.map((tile, index) => (
          <div
            key={index}
            className={`flex items-center justify-center`}
            style={{ width: getTileWidth(index, numberOfLastRowTiles, tiles?.length) }}>
            <Tile {...tile} isLoading={isLoading} />
            {shouldRenderSeparator(index, tiles?.length) && (
              <div className={verticalSeparatorStyles} />
            )}
          </div>
        ))}
      </div>
    )
  }
})
