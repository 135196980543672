import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { FC } from 'react'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${gray(4)};
  height: 100%;
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
  background-color: white;
`

type IProps = WizardSchemas.WACConfig.TSchema

// Function to remove only the beginning and ending tag symbols around URLs
const removeEnclosingTagsAroundUrls = (text: string): string =>
  text.replace(/<(https?:\/\/)?www\.[^<>]+>/g, (match) => match.replace(/<|>/g, ''))

// eslint-disable-next-line @typescript-eslint/ban-types
export const RichTextWAC: FC<IProps> = ({ value, showName, name }) => {
  const { value: enableRichTextUrlParsing } = useGate('enable-richtext-url-parsing')
  let processedValue = value as string
  if (enableRichTextUrlParsing && typeof processedValue === 'string') {
    processedValue = removeEnclosingTagsAroundUrls(processedValue)
  }
  const sanitizedHtml = sanitize(processedValue || '')

  return (
    <Container>
      {showName ? (
        <Text mb='10px' fontWeight='bold'>
          {name}
        </Text>
      ) : null}
      <div className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </Container>
  )
}
