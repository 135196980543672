import { UrlSchema, UuidSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

const qaConfigSchema = z.object({
  measurementDescriptions: z
    .object({
      id: UuidSchema,
      belowTheBar: z.string(),
      atTheBar: z.string(),
      aboveTheBar: z.string(),
    })
    .optional(),
  conditionalMeasurementDescriptions: z
    .array(
      z.object({
        id: UuidSchema,
        baseVariableId: UuidSchema,
        belowTheBar: z.string(),
        atTheBar: z.string(),
        aboveTheBar: z.string(),
        value: z.string(),
      })
    )
    .optional(),
})

/**
 * StepWithAutoAssign step metadata contains a url to fetch the assignee from.
 * At execution, process engine will fetch the assignee and assign the step.
 */
const schema = z
  .object({
    getAssigneeAutomation: UrlSchema.optional(),
    redoAssign: z.boolean().default(false).optional(),
    autoAssignStrategy: z
      .object({
        type: z.enum(['same_base_run', 'same_step', 'sla', 'base_variable']).optional(),
        force: z.boolean().optional(),
        baseVariableId: z.string().uuid().optional(), // Only present for base_variable assignment
        preferPreviousBaseVariableId: z.boolean().optional(), // Only present for same_step with preferential base variable assignment
        previousBaseVariableId: z.string().uuid().optional(), // Only present for same_step with preferential base variable assignment
        stickyStepId: z.string().uuid().optional(), // Only present for same_base_run assignment
      })
      .nullable()
      .optional(),
    qaConfig: qaConfigSchema.optional(),
  })
  .strict()

/**
 * StepWithAutoAssign step metadata contains a url to fetch the assignee from.
 * At execution, process engine will fetch the assignee and assign the step.
 */
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates StepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.partial().strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates step meta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({ branchStepMeta, data }: { branchStepMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(branchStepMeta, updateArgs)
}

export { create, createArgsSchema, qaConfigSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
