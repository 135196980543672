import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Modal } from '@invisible/ui/modal'
import { useToasts } from '@invisible/ui/toasts'
import { ReactNode, useState } from 'react'

import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'

type IProps = {
  baseRunId: string
  disableDelete?: boolean
  children: ReactNode
  stepRunId: string
}

export const RowActionCell = ({ baseRunId, children, disableDelete, stepRunId }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { addToast } = useToasts()
  const reactQueryContext = useContext()

  const { mutateAsync: deleteBaseRuns, isLoading: isBaseRunDeleteLoading } =
    useBaseRunDeleteWithStepRunReference({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
      onError: (error) => {
        addToast(`Delete failed: ${error?.message}`, {
          appearance: 'error',
        })
      },
    })

  const handleDeleteBaseRun = async () => {
    if (window.confirm('Are you sure you want to delete this base run?') === false) return
    await deleteBaseRuns({ baseRunIds: [baseRunId], stepRunId })
    setShowModal(false)
  }

  return (
    <div key={baseRunId} className='cursor-pointer'>
      <div onClick={() => setShowModal(true)}>{children}</div>
      <Modal title='Base Run Actions' visible={showModal} onClose={() => setShowModal(false)}>
        <div className='flex flex-col justify-center gap-4 py-2'>
          <div>
            Base Run ID: <span className='font-semibold'>{baseRunId}</span>
          </div>
          {disableDelete ? null : (
            <Button
              onClick={() => handleDeleteBaseRun()}
              disabled={isBaseRunDeleteLoading}
              variant='danger'
              size='md'>
              Delete
            </Button>
          )}
        </div>
      </Modal>
    </div>
  )
}
