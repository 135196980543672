import {
  TChoiceFreeTextHandler,
  TQuestionnaireAnswerChangeHandler,
  TQuestionTree,
} from '@invisible/common/types'
import { Radio } from '@invisible/ui/radio'
import React, { FC } from 'react'

interface IProps {
  onChange?: TQuestionnaireAnswerChangeHandler
  onChoiceFreeTextChange?: TChoiceFreeTextHandler
  question: TQuestionTree
  choice: string
  readOnly?: boolean
  identifier?: string
  freeText?: { [optionId: string]: string }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleChoice: FC<IProps> = ({
  question,
  onChange,
  choice,
  readOnly,
  freeText,
  identifier,
  onChoiceFreeTextChange,
}) => {
  const handleChange = (c: string) => {
    if (readOnly || !onChange) return
    const arg = {
      questionId: question.id,
      value: c,
      type: 'single_choice' as TQuestionTree['type'],
    }
    onChange(arg)
  }

  const handleFreeTextChange = (optionId: string, value: string) => {
    if (readOnly || !onChoiceFreeTextChange) return
    onChoiceFreeTextChange({ questionId: question.id, optionId, value })
  }

  return (
    <Radio.Group
      name={`single-choice-options-${question.id}-${readOnly ? 'readOnly' : 'writable'}-${
        identifier ?? choice
      }`}
      selected={choice}
      onChange={handleChange}
      orientation='vertical'>
      {question.options.map((o) => (
        <div key={o.id} className='flex items-center space-x-2'>
          <Radio label={o.text} value={o.id} disabled={!!readOnly} />
          {o.isCustom && choice === o.id ? (
            <div>
              <input
                type='text'
                value={freeText?.[o.id] ?? ''}
                onChange={(e) => handleFreeTextChange(o.id, e.target.value)}
                disabled={Boolean(readOnly)}
                placeholder='Free text entry'
                className='w-72 border-b border-transparent border-gray-300 focus:border-blue-500'
              />
            </div>
          ) : null}
        </div>
      ))}
    </Radio.Group>
  )
}

export { SingleChoice }
