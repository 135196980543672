import { useAbilityContext } from '@invisible/authorization/client'
import { GenericRolesSelector } from '@invisible/common/components/generic-role-selector'
import { useStore } from '@invisible/common/stores/process-store'
import { useQuery } from '@invisible/trpc/client'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { FC, useCallback, useEffect } from 'react'
import shallow from 'zustand/shallow'

interface IPermissionsProps {
  permissions: string[]
  baseViewId: string
  baseViewUserId: string | null
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Permissions: FC<IPermissionsProps> = ({ permissions, baseViewId, baseViewUserId }) => {
  const [Can, ability] = useAbilityContext()
  const { editedPermissions, setEditedPermissions, isPrivate, setIsPrivate, setIsFreshNew } =
    useStore(
      useCallback(
        (state) => ({
          editedPermissions: state.editedPermissions,
          setEditedPermissions: state.setEditedPermissions,
          isPrivate: state.isPrivate,
          setIsPrivate: state.setIsPrivate,
          setIsFreshNew: state.setIsFreshNew,
        }),
        []
      ),
      shallow
    )

  useEffect(() => {
    // We set the store in sync with backend
    setEditedPermissions(permissions)
  }, [permissions, setEditedPermissions])

  const canChangePermissions = ability?.can('view', 'BaseViewEditor') // operators can create private baseView, but cannot change permissions.

  return (
    <div>
      <div className='pt-8 font-semibold'>View Permissions</div>
      <GenericRolesSelector
        editedPermissions={editedPermissions}
        setEditedPermissions={setEditedPermissions}
        canChangePermissions={Boolean(canChangePermissions)}
        setIsFreshNew={setIsFreshNew}
        setIsPrivate={setIsPrivate}
        isPrivate={isPrivate}
      />
      <div className='h-4 py-3'>
        {editedPermissions.length === 0 && !isPrivate
          ? '⚠️ Please apply some permissions to be able to save this Base View.'
          : null}
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const PermissionWithQueryWrapper: FC = () => {
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)

  const { data: baseView } = useQuery(['baseView.findById', { id: baseViewId }])
  const { data: permissions } = useQuery(['baseView.getPermissions', { id: baseViewId }])
  if (!permissions || !baseView)
    return (
      <div className='pt-10'>
        <LogoSpinner height={40} width={40} />
      </div>
    )

  return (
    <Permissions
      permissions={permissions}
      baseViewId={baseViewId}
      baseViewUserId={baseView.userId}
    />
  )
}
