import { classNames } from '@invisible/common/helpers'
import { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

interface ICurrencyCell {
  id: number | string
  value?: string
  disabled?: boolean
  isEditable?: boolean
  placeholder?: string
  label: string
  onBlur?: (value: string | undefined) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const CurrencyCell: React.FC<ICurrencyCell> = ({ id, value, disabled, onBlur, label }) => {
  const [internalValue, setInternalValue] = useState<string | undefined>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const addZeroes = (num: string | undefined) => {
    if (!num) return undefined
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  useEffect(() => {
    setInternalValue(addZeroes(String(Number(value ?? 0))))
  }, [value])

  const handleKeyPress = (e: { key: string }): void => {
    if (e?.key === 'Enter') inputRef.current?.blur()
  }

  const handleBlur = () => {
    const formattedValue = addZeroes(internalValue)
    setInternalValue(formattedValue ?? '')
    onBlur?.(formattedValue)
  }

  const handleChange = (value: string | undefined): void => {
    if (!value) {
      setInternalValue('0')
      return
    }
    setInternalValue(value)
  }

  return (
    <div
      className={classNames(
        'box-border flex h-8 grow py-1.5 px-2',
        disabled ? 'bg-grayScale-300' : '',
        'focus-within:bg-grayScale-100 border-grayScale-400 items-center rounded border border-solid focus-within:border focus-within:border-purple-600'
      )}>
      <CurrencyInput
        id={String(id ?? '')}
        ref={inputRef}
        value={internalValue}
        decimalsLimit={2}
        onValueChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        disabled={disabled}
        aria-label={label}
        prefix='$'
        className='text-gray-1300 w-full border-0 bg-transparent p-0 text-left text-sm font-normal leading-5 tracking-normal outline-0'
      />
    </div>
  )
}
