import { z } from '@invisible/zod'

/**
 * PendingBaseRunCheckStepMeta step metadata contains a sourceBaseVariableId and a targetnBaseVariableId.
 * These meta fields are used to check if the any of the pending base run exists in this process by which has the same source base run variable value. If it does, then the target base run variable value is set to true otherwise false.
 *
 */
const schema = z.object({
  targetBaseVariableId: z.string().uuid().optional(),
  sourceBaseVariableId: z.string().uuid().optional(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
