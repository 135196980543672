import { SVGProps } from 'react'
function UserSwitch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.064 6.648a6.088 6.088 0 00-6.089-6.09 6.088 6.088 0 00-3.56 11.03l-.022.01a9.147 9.147 0 00-2.93 1.979A9.17 9.17 0 002.49 16.51a9.173 9.173 0 00-.722 3.39.196.196 0 00.196.202h1.47a.198.198 0 00.197-.191 7.326 7.326 0 012.151-5.017 7.278 7.278 0 015.193-2.158 6.088 6.088 0 006.09-6.09zm-6.089 4.223a4.223 4.223 0 110-8.446 4.223 4.223 0 010 8.446zm2.578 5.426h6.482a.197.197 0 00.197-.196v-1.375a.197.197 0 00-.197-.196h-4.333l1.159-1.476a.2.2 0 00.041-.12.197.197 0 00-.196-.197h-1.783a.4.4 0 00-.309.15l-1.682 2.139a.788.788 0 00.621 1.271zm5.893 1.572h-6.482a.197.197 0 00-.196.196v1.375c0 .108.088.197.196.197h4.334l-1.16 1.475a.197.197 0 00.155.317h1.783a.4.4 0 00.31-.15l1.681-2.138a.788.788 0 00-.62-1.272z'
        fill='currentColor'
      />
    </svg>
  )
}

export default UserSwitch
