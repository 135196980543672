import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { formatTime } from './helpers'
import { MenuList } from './MenuList'

type Annotation = {
  id: string
  startFrame: number
  endFrame: number
  duration: number
  text: string
}
type AnnotationEditProps = {
  preview?: boolean
  editMode?: boolean
  annotation: Annotation
  setEditMode?: (editMode: boolean) => void
  saveAnnotationText?: (id: string, text: string) => void
  deleteAnnotation?: (id: string) => void
}

export const AnnotationEdit = ({
  annotation,
  preview,
  editMode: isEditing,
  setEditMode,
  saveAnnotationText,
  deleteAnnotation,
}: AnnotationEditProps) => {
  const [text, setText] = useState(annotation.text ?? '')

  return (
    <div>
      <div className='mb-2 flex items-center justify-between'>
        <div className='flex flex-col'>
          <span className='text-base font-bold'>{formatTime(annotation.duration)}</span>
          <div className='flex items-center gap-1 text-gray-400'>
            <span className='text-[10px]'>{formatTime(annotation.startFrame)}</span>
            <span>-</span>
            <span className='text-[10px]'>{formatTime(annotation.endFrame)}</span>
          </div>
        </div>
        {preview ? null : (
          <div className='flex items-center'>
            <EditIcon
              className='cursor-pointer text-gray-500'
              onClick={() => setEditMode?.(true)}
            />

            <MenuList
              id='edit-annotation-menu'
              menuItems={[
                {
                  label: 'Delete Annotation',
                  value: 'delete',
                  handleClick: () => deleteAnnotation?.(annotation.id),
                },
              ]}>
              <MoreVertIcon className=' text-gray-500' />
            </MenuList>
          </div>
        )}
      </div>

      {isEditing ? (
        <div className='mb-2 p-1'>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={text}
            sx={{
              fontSize: '12px',
            }}
            variant='outlined'
            onChange={(e) => setText(e.target.value)}
          />
          <div className='mt-2 flex justify-end gap-2'>
            <Button variant='outlined' onClick={() => setEditMode?.(false)}>
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                saveAnnotationText?.(annotation.id, text)
                setEditMode?.(false)
              }}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <Typography className='max-h-[400px] overflow-scroll' variant='body2'>
          {text}
        </Typography>
      )}
    </div>
  )
}
