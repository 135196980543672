import { SVGProps } from 'react'

function TrashFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5323_77969)'>
        <path
          d='M30.3511 5.63646H24.1011V4.40569C24.1011 3.42107 23.7261 2.43646 22.9761 1.82107C22.3511 1.08261 21.3511 0.713379 20.3511 0.713379H12.8511C11.8511 0.713379 10.8511 1.08261 10.2261 1.82107C9.47607 2.43646 9.10107 3.42107 9.10107 4.40569V5.63646H2.85107C2.47607 5.63646 2.22607 5.75953 1.97607 6.00569C1.72607 6.25184 1.60107 6.49799 1.60107 6.86723C1.60107 7.23646 1.72607 7.48261 1.97607 7.72876C2.22607 7.97492 2.47607 8.09799 2.85107 8.09799H4.10107V30.2518C4.10107 30.8672 4.35107 31.4826 4.85107 31.9749C5.35107 32.4672 5.97607 32.7134 6.60107 32.7134H26.6011C27.2261 32.7134 27.8511 32.4672 28.3511 31.9749C28.8511 31.4826 29.1011 30.8672 29.1011 30.2518V8.09799H30.3511C30.7261 8.09799 30.9761 7.97492 31.2261 7.72876C31.4761 7.48261 31.6011 7.23646 31.6011 6.86723C31.6011 6.49799 31.4761 6.25184 31.2261 6.00569C30.9761 5.75953 30.7261 5.63646 30.3511 5.63646ZM14.1011 24.098C14.1011 24.4672 13.9761 24.7134 13.7261 24.9595C13.4761 25.2057 13.2261 25.3288 12.8511 25.3288C12.4761 25.3288 12.2261 25.2057 11.9761 24.9595C11.7261 24.7134 11.6011 24.4672 11.6011 24.098V14.2518C11.6011 13.8826 11.7261 13.6365 11.9761 13.3903C12.2261 13.1441 12.4761 13.0211 12.8511 13.0211C13.2261 13.0211 13.4761 13.1441 13.7261 13.3903C13.9761 13.6365 14.1011 13.8826 14.1011 14.2518V24.098ZM21.6011 24.098C21.6011 24.4672 21.4761 24.7134 21.2261 24.9595C20.9761 25.2057 20.7261 25.3288 20.3511 25.3288C19.9761 25.3288 19.7261 25.2057 19.4761 24.9595C19.2261 24.7134 19.1011 24.4672 19.1011 24.098V14.2518C19.1011 13.8826 19.2261 13.6365 19.4761 13.3903C19.7261 13.1441 19.9761 13.0211 20.3511 13.0211C20.7261 13.0211 20.9761 13.1441 21.2261 13.3903C21.4761 13.6365 21.6011 13.8826 21.6011 14.2518V24.098ZM21.6011 5.63646H11.6011V4.40569C11.6011 4.03646 11.7261 3.7903 11.9761 3.54415C12.2261 3.29799 12.4761 3.17492 12.8511 3.17492H20.3511C20.7261 3.17492 20.9761 3.29799 21.2261 3.54415C21.4761 3.7903 21.6011 4.03646 21.6011 4.40569V5.63646Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_77969'>
          <rect width='32' height='32' fill='white' transform='translate(0.601074 0.713379)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrashFilled
