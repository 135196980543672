import { SVGProps } from 'react'
function Video(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#video_svg__clip0)' fill='currentColor'>
        <path d='M4.75 10.318H2.937V8.506a.125.125 0 00-.124-.125h-.75a.125.125 0 00-.126.125v1.812H.125a.125.125 0 00-.125.125v.75c0 .069.056.125.125.125h1.813v1.813c0 .069.056.125.125.125h.75a.125.125 0 00.124-.125v-1.813H4.75a.125.125 0 00.125-.125v-.75a.125.125 0 00-.125-.125z' />
        <path d='M13.25 3.727l-2 1.152V2.504a1 1 0 00-1-1H1a1 1 0 00-1 1v5.5h1.125V2.629h9v8.75H6v1.125h4.25a1 1 0 001-1V9.129l2 1.151a.5.5 0 00.75-.43V4.16a.5.5 0 00-.75-.433zm-.375 5.042l-1.625-.934v-1.66l1.625-.937V8.77z' />
        <path d='M4 4.623a.125.125 0 00.125-.125v-.75A.125.125 0 004 3.623H2.25a.125.125 0 00-.125.125v.75c0 .069.056.125.125.125H4z' />
      </g>
      <defs>
        <clipPath id='video_svg__clip0'>
          <path fill='#fff' d='M0 0h14v14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Video
