import { classNames } from '@invisible/common/helpers'
import { useStore } from '@invisible/common/stores/process-store'
import { useClickAway } from '@invisible/hooks/use-click-away'
import { DateRangePicker, IRange } from '@invisible/ui/date-range-picker'
import { FilledFilterIcon } from '@invisible/ui/icons'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import shallow from 'zustand/shallow'

type TPositionXProps = 'left' | 'right' | 'center'

// eslint-disable-next-line @typescript-eslint/ban-types
const DatePickerContainer: FC<{ positionX: TPositionXProps }> = ({ positionX, children }) => (
  <div
    className={`absolute left-[${
      positionX === 'center' ? '-50%' : positionX === 'left' ? '-100%' : '0%'
    }]`}>
    {children}
  </div>
)
interface IProps {
  columnId: string
  positionX?: TPositionXProps
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DateFilter: React.FC<IProps> = ({ columnId, positionX = 'center' }) => {
  const { filterOption, setFilterOption } = useStore(
    useCallback(
      (state) => ({
        filterOption: state.filterOption,
        setFilterOption: state.setFilterOption,
      }),
      []
    ),
    shallow
  )
  const defaultFilter = filterOption?.find((f) => f.id === columnId)?.value as {
    startDate: Date
    endDate: Date
  }
  const [filterOpen, setFilterOpen] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<
    {
      startDate: Date | null
      endDate: Date | null
      key: string
    }[]
  >([{ startDate: null, endDate: new Date(''), key: 'selection' }])

  useEffect(() => {
    if (
      selectedDateRange[0].startDate &&
      selectedDateRange[0].endDate instanceof Date &&
      !isNaN(selectedDateRange[0].endDate as any)
    ) {
      setFilterOption([
        ...filterOption.filter((f) => f.id !== columnId),
        {
          id: columnId as string,
          value: {
            startDate: selectedDateRange[0].startDate as Date,
            endDate: selectedDateRange[0].endDate as Date,
          },
        },
      ])
    }
  }, [selectedDateRange])

  useClickAway(ref, () => {
    setFilterOpen(false)
  })

  return (
    <div ref={ref}>
      <div className='ml-1 h-[14px] cursor-pointer items-center  pb-[1px]'>
        <FilledFilterIcon
          width={14}
          height={14}
          className={classNames(
            'text-theme-main',
            !defaultFilter?.startDate ? 'opacity-25' : 'opacity-100'
          )}
          onClick={() => setFilterOpen((prev) => !prev)}
        />
      </div>

      <DatePickerContainer positionX={positionX}>
        <DateRangePicker
          ranges={selectedDateRange as IRange[]}
          onChange={(range) => {
            setSelectedDateRange(range)
          }}
          standalone
          moveRangeOnFirstSelection={true}
          isOpen={filterOpen}
          onOpenChange={(open) => {
            if (open) setFilterOpen(true)
          }}
          onClear={() => {
            setSelectedDateRange([{ startDate: null, endDate: new Date(''), key: 'selection' }])
            setFilterOption(filterOption.filter((f) => f.id !== columnId))
            setFilterOpen(false)
          }}
        />
      </DatePickerContainer>
    </div>
  )
}

export { DateFilter }
