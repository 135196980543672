import { SVGProps } from 'react'
function AddBadgeOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M18.6 11.3667C18.6 7.37862 15.3547 4.13333 11.3667 4.13333C7.37862 4.13333 4.13333 7.37862 4.13333 11.3667C4.13333 15.3547 7.37862 18.6 11.3667 18.6C15.3547 18.6 18.6 15.3547 18.6 11.3667ZM6.2 11.3667C6.2 8.51797 8.51797 6.2 11.3667 6.2C14.2154 6.2 16.5333 8.51797 16.5333 11.3667C16.5333 14.2154 14.2154 16.5333 11.3667 16.5333C8.51797 16.5333 6.2 14.2154 6.2 11.3667Z'
        fill='currentColor'
      />
      <path
        d='M22.7333 14.4667C22.5887 14.4667 22.4233 14.4667 22.2787 14.4873C22.5887 13.4953 22.7333 12.4413 22.7333 11.3667C22.7333 5.10467 17.6287 0 11.3667 0C5.10467 0 0 5.10467 0 11.3667C0 14.88 1.612 18.042 4.13333 20.1293V29.9667C4.13333 30.318 4.31933 30.6487 4.62933 30.8553C4.91867 31.0207 5.31133 31.0413 5.62133 30.8967L11.3667 28.024L17.112 30.8967C17.2567 30.9587 17.4013 31 17.5667 31C17.7527 31 17.9387 30.938 18.104 30.8553C18.414 30.6487 18.6 30.318 18.6 29.9667V29.884C19.8193 30.5867 21.2247 31 22.7333 31C27.3007 31 31 27.3007 31 22.7333C31 18.166 27.3007 14.4667 22.7333 14.4667ZM5.74533 18.7653C3.51333 17.0707 2.06667 14.384 2.06667 11.3667C2.06667 6.24133 6.24133 2.06667 11.3667 2.06667C16.492 2.06667 20.6667 6.24133 20.6667 11.3667C20.6667 12.648 20.4187 13.8467 19.9433 14.9627C17.6287 15.7893 15.7893 17.6287 14.9627 19.9433C13.8467 20.4187 12.648 20.6667 11.3667 20.6667C9.32067 20.6667 7.44 20.0053 5.91067 18.8893C5.84867 18.848 5.78667 18.8067 5.74533 18.7653ZM16.5333 28.2927L11.8213 25.9367C11.532 25.792 11.2013 25.792 10.9122 25.9367L6.2 28.2927V21.4727C7.75 22.2787 9.50667 22.7333 11.3667 22.7333C12.4413 22.7333 13.4953 22.5887 14.4873 22.2787C14.4667 22.4233 14.4667 22.5887 14.4667 22.7333C14.4667 24.8207 15.252 26.722 16.5333 28.1893V28.2927ZM22.7333 28.9333C21.1627 28.9333 19.6953 28.334 18.6 27.342C17.3393 26.2053 16.5333 24.552 16.5333 22.7333C16.5333 22.2787 16.5953 21.824 16.678 21.39C16.802 20.8529 16.988 20.3567 17.236 19.8813C17.4633 19.4473 17.7113 19.0547 18.0213 18.7033C18.538 18.104 19.1789 17.608 19.8813 17.236C20.3567 16.9878 20.8527 16.802 21.39 16.678C21.824 16.5953 22.2787 16.5333 22.7333 16.5333C26.1433 16.5333 28.9333 19.3233 28.9333 22.7333C28.9333 26.1433 26.1433 28.9333 22.7333 28.9333Z'
        fill='currentColor'
      />
      <path
        d='M25.8333 21.7H23.7667V19.6333C23.7667 19.0547 23.312 18.6 22.7333 18.6C22.1547 18.6 21.7 19.0547 21.7 19.6333V21.7H19.6333C19.0547 21.7 18.6 22.1547 18.6 22.7333C18.6 23.312 19.0547 23.7667 19.6333 23.7667H21.7V25.8333C21.7 26.412 22.1547 26.8667 22.7333 26.8667C23.312 26.8667 23.7667 26.412 23.7667 25.8333V23.7667H25.8333C26.412 23.7667 26.8667 23.312 26.8667 22.7333C26.8667 22.1547 26.412 21.7 25.8333 21.7Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AddBadgeOutline
