import { SVGProps } from 'react'

function GlobalFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.0612 21.415L31.1074 21.2612C32.2975 17.8545 32.2975 14.145 31.1074 10.7383L31.0612 10.5844C29.9422 7.48659 27.8962 4.80863 25.2013 2.91481C22.5065 1.02099 19.2937 0.00323897 15.9999 0C12.7062 0.00323897 9.4934 1.02099 6.79856 2.91481C4.10372 4.80863 2.05765 7.48659 0.938667 10.5844L0.892514 10.7383C-0.297586 14.145 -0.297586 17.8545 0.892514 21.2612L0.938667 21.415C2.06146 24.5104 4.10848 27.1858 6.80247 29.0791C9.49646 30.9723 12.7072 31.9918 15.9999 31.9994C19.2937 31.9962 22.5065 30.9784 25.2013 29.0846C27.8962 27.1908 29.9422 24.5128 31.0612 21.415ZM15.9999 3.32302C17.7921 5.16652 19.1346 7.39877 19.9229 9.84598H12.0769C12.8653 7.39877 14.2078 5.16652 15.9999 3.32302ZM12.0769 22.1535H19.9229C19.1346 24.6007 17.7921 26.8329 15.9999 28.6764C14.2078 26.8329 12.8653 24.6007 12.0769 22.1535ZM11.4308 19.692C10.9591 17.2531 10.9591 14.7464 11.4308 12.3075H20.5691C21.0408 14.7464 21.0408 17.2531 20.5691 19.692H11.4308ZM23.0767 12.3075H29.0305C29.7076 14.7224 29.7076 17.277 29.0305 19.692H23.0767C23.487 17.2476 23.487 14.7519 23.0767 12.3075ZM28.0613 9.84598H22.5229C21.7827 7.25419 20.521 4.84085 18.8153 2.7538C20.7848 3.17906 22.6356 4.0357 24.2344 5.26204C25.8331 6.48838 27.1401 8.05392 28.0613 9.84598ZM18.8153 29.2456C20.521 27.1586 21.7827 24.7452 22.5229 22.1535H28.0613C27.1401 23.9455 25.8331 25.5111 24.2344 26.7374C22.6356 27.9637 20.7848 28.8204 18.8153 29.2456Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GlobalFilled
