import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { classNames } from '@invisible/common/helpers'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { TextArea } from '@invisible/ui/form'
import { RobotHeadIcon, UserOutlineIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { TJson } from '@invisible/zod'
import { compact, isPlainObject } from 'lodash/fp'
import pMap from 'p-map'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunVariableFindManyByBaseRunId } from '../../hooks/useBaseRunVariableFindManyByBaseRunId'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { SingleTurn } from './SingleTurn'
import { ToolSelector } from './ToolSelector'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>
type TMessageType = 'user_prompt' | 'bot_to_tool_request' | 'tool_to_bot_response' | 'bot_response'
type Json = string | number | boolean | null | JsonArray | { [key: string]: Json }
type JsonArray = Array<Json>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  isReview?: boolean
  stepRun: TStepRun
  isReadOnly: boolean
}

const FAKE_MESSAGE_ID = 'afb8d433-9e37-4ce0-aaab-e05bba558e03'

const Orchestration2WAC = ({ baseRun, isReview, orchestration2, stepRun, isReadOnly }: IProps) => {
  const [inputText, setInputText] = useState('')
  const [currentTurn, setCurrentTurn] = useState('user_prompt')
  const [insertAtIndex, setInsertAtIndex] = useState<number | boolean>(false)
  const [messageToBeInserted, setMessageToBeInserted] = useState<
    Record<string, unknown> | string | null
  >()
  const [turnTypeToBeInserted, setTurnTypeToBeInserted] = useState<TMessageType>('bot_response')
  const [metadataValidationFailures, setMetadataValidationFailures] = useState([] as string[])
  const reactQueryContext = useContext()
  const { dispatch } = useWizardState()
  const { addToast } = useToasts()

  const config = orchestration2 as NonNullable<WizardSchemas.Orchestration2.TSchema>

  const { data: messages, isLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config.messagesBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])

  const { mutateAsync: createBaseRun, isLoading: isCreatingNewMessage } = useBaseRunCreate({
    onMutate: async (data) => {
      await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.messagesBaseId,
            parentBaseRunId: baseRun.id,
          },
        ],
        (prevData) => {
          if (!prevData) return
          setInputText('')

          // When we push both Tool-Bot messages at same time, this causes some conflicts due to duplicate keys.
          if (prevData.find((item) => item.id === FAKE_MESSAGE_ID)) return prevData

          return [
            ...prevData,
            {
              id: FAKE_MESSAGE_ID,
              baseId: config.messagesBaseId as string,
              createdAt: new Date(),
              totalCount: prevData.length + 1,
              baseRunVariables: [
                {
                  id: uuid(),
                  baseVariable: { id: config.typeBaseVariableId as string, name: 'Type' },
                  value: data.initialValues[0].value,
                },
                {
                  id: uuid(),
                  baseVariable: { id: config.indexBaseVariableId as string, name: 'Index' },
                  value: data.initialValues[1].value,
                },
                {
                  id: uuid(),
                  baseVariable: { id: config.textBaseVariableId as string, name: 'Text' },
                  value: data.initialValues[2].value,
                },
              ],
            },
          ]
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      dispatch({
        type: 'setBaseRun',
        baseRun: {
          ...baseRun,
          baseRunVariables: baseRun.baseRunVariables.map((variable) => {
            if (variable.baseVariableId === data[0].baseVariableId) {
              return {
                ...variable,
                value: data[0].value,
              }
            }
            return variable
          }),
        },
      })
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateManyVariables, isLoading: isUpdatingManyVariables } =
    useBaseRunVariablesWizardUpdate({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
    })

  const getIsBotTurn = (message: TFindChildBaseRunsData[number]) => {
    for (const variable of message.baseRunVariables) {
      if (
        variable.baseVariable.id === (config.typeBaseVariableId as string) &&
        ['bot_response', 'bot_to_tool_request'].includes(variable.value as string)
      ) {
        return true
      }
    }

    return false
  }

  const validateMetadata = (message: TFindChildBaseRunsData[number]) => {
    for (const variable of message.baseRunVariables) {
      for (const metadata of config.metadata ?? []) {
        if (metadata.required) {
          if (
            metadata.baseVariableId === variable.baseVariable.id &&
            (variable.value === null || variable.value === '')
          ) {
            return false
          }
        }
      }
    }

    return true
  }

  useEffect(() => {
    setMetadataValidationFailures([])
    for (const message of messages ?? []) {
      if (!getIsBotTurn(message)) continue

      // We now know the message is bot_response or bot_to_tool_request. We now check the required metadata variables to be filled.
      if (!validateMetadata(message)) {
        setMetadataValidationFailures((prev) => [...prev, message.id])
      }
    }
  }, [messages])

  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'Orchestration2-Metadata',
      value: metadataValidationFailures.length === 0,
    })
  }, [metadataValidationFailures])

  useEffect(() => {
    dispatch({
      type: 'setIsWacSubmitting',
      key: 'Orchestration2',
      value: isCreatingNewMessage,
    })
  }, [dispatch, isCreatingNewMessage])

  // Parses messages base runs into a typed object with its base run variables
  const normalizedMessages = useMemo(
    () =>
      (messages ?? [])
        .map((message) => ({
          id: message.id,
          type: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.typeBaseVariableId
          )?.value as string,
          index: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.indexBaseVariableId
          )?.value as number,
          text: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.textBaseVariableId
          )?.value as string,
        }))
        .sort((a, b) => a.index - b.index),
    [config.textBaseVariableId, config.indexBaseVariableId, config.typeBaseVariableId, messages]
  )
  const lastStagedAction = baseRun.baseRunVariables.find(
    (b) => b.baseVariableId === config.stagedActionBaseVariableId
  )?.value as null | { tool: string; action: string }

  useEffect(() => {
    const mostRecentMessage = normalizedMessages?.[normalizedMessages.length - 1]

    if (mostRecentMessage?.type === 'bot_response') {
      setCurrentTurn('user_prompt')
      return
    }
    if (mostRecentMessage?.type === 'tool_to_bot_response') {
      // If the User has already selected this option, we should not default to the other option when components refresh
      if (currentTurn === 'bot_to_tool_request') return

      setCurrentTurn('bot_response')
      return
    }
    if (mostRecentMessage?.type === 'bot_to_tool_request') {
      setCurrentTurn('tool_to_bot_response')
      return
    }
    if (mostRecentMessage?.type === 'user_prompt') {
      // If the User has already selected this option, we should not default to the other option when components refresh
      if (currentTurn === 'bot_to_tool_request') return

      setCurrentTurn('bot_response')
      return
    }
  }, [normalizedMessages])

  const messagesBaseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: compact(normalizedMessages?.map((message) => message.id) ?? []),
  })

  const mostRecentMessage = normalizedMessages?.[normalizedMessages.length - 1]

  const handleBaseRunCreation = async ({
    type,
    text,
    increment = 1,
    index,
    lastUpdated,
    schemaVersion,
  }: {
    type: TMessageType
    text: string | Record<string, unknown>
    increment?: number
    index?: number
    lastUpdated?: string
    schemaVersion?: string
  }) => {
    await createBaseRun({
      baseId: config.messagesBaseId as string,
      parentBaseRunId: baseRun.id,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: config.typeBaseVariableId as string,
          value: type,
        },
        {
          baseVariableId: config.indexBaseVariableId as string,
          value: index
            ? index
            : (mostRecentMessage?.index && Number(mostRecentMessage?.index) + increment) ??
              normalizedMessages.length + increment,
        },
        {
          baseVariableId: config.textBaseVariableId as string,
          value: text as TJson,
        },
        ...(lastUpdated
          ? [
              {
                baseVariableId: config.lastUpdatedBaseVariableId as string,
                value: lastUpdated,
              },
            ]
          : []),
        ...(schemaVersion
          ? [
              {
                baseVariableId: config.schemaVersionBaseVariableId as string,
                value: schemaVersion,
              },
            ]
          : []),
      ],
    })
  }

  const insertNewMessage = async ({
    type,
    message,
    index,
  }: {
    type: TMessageType
    message: string | Record<string, unknown>
    index: number
  }) => {
    // Filters the list of messages AFTER the index to be inserted at. These messages' indices need to be incremented by 1.
    const messagesToShift = normalizedMessages.filter((message) => message.index >= index)

    const updateData = messagesToShift.map((message) => ({
      baseRunId: message.id,
      baseVariableId: config.indexBaseVariableId as string,
      value: message.index + 1,
    }))

    //We update the indices first and then insert the new message.
    await updateManyVariables({ stepRunId: stepRun.id, data: updateData })
    await handleBaseRunCreation({ type, text: message, index })
  }

  const handleInsert = async () => {
    // For now we do not have the form interface for individual messages.
    // We will allow the QA Agents to type the JSON by hand, but we will validating it to be a valid JSON it before attempting to insert.
    if (!['user_prompt', 'bot_response'].includes(turnTypeToBeInserted)) {
      try {
        const parsedJSON = JSON.parse(messageToBeInserted as string)
        if (!isPlainObject(parsedJSON)) throw new Error(`Not a valid JSON object`)
      } catch (e) {
        addToast(`Please enter a valid JSON object.`, {
          appearance: 'error',
        })
        return
      }
    }
    await insertNewMessage({
      type: turnTypeToBeInserted,
      message: ['user_prompt', 'bot_response'].includes(turnTypeToBeInserted)
        ? (messageToBeInserted as string)
        : (JSON.parse(messageToBeInserted as string) as Record<string, unknown>),
      index: insertAtIndex as number,
    })

    setMessageToBeInserted(null)
    setInsertAtIndex(false)
  }

  const handleMessageSubmission = async () => {
    if (!inputText) return

    switch (currentTurn) {
      case 'user_prompt': {
        await handleBaseRunCreation({ type: 'user_prompt', text: inputText, increment: 1 })

        dispatch({
          type: 'setReadyForSubmit',
          key: 'Orchestration2',
          value: false,
        })

        break
      }

      case 'bot_response': {
        if (mostRecentMessage.id === FAKE_MESSAGE_ID) {
          alertMessageNotYetSaved()
          return
        }

        await handleBaseRunCreation({ type: 'bot_response', text: inputText, increment: 1 })

        dispatch({
          type: 'setReadyForSubmit',
          key: 'Orchestration2',
          value: true,
        })

        break
      }
    }
  }

  const handleToolSelectorSubmit = async ({
    botToToolRequest,
    toolToBotResponse,
    stagedAction,
    lastUpdated,
    schemaVersion,
  }: {
    botToToolRequest: Record<string, unknown>
    toolToBotResponse: Record<string, unknown> | string
    stagedAction?: { tool: string; action: string } | null
    lastUpdated?: string
    schemaVersion?: string
  }) => {
    if (mostRecentMessage.id === FAKE_MESSAGE_ID) {
      alertMessageNotYetSaved()
      return
    }

    await Promise.all([
      handleBaseRunCreation({
        type: 'bot_to_tool_request',
        text: botToToolRequest,
        increment: 1,
        ...(lastUpdated ? { lastUpdated } : {}),
        ...(schemaVersion ? { schemaVersion } : {}),
      }),
      handleBaseRunCreation({
        type: 'tool_to_bot_response',
        text: toolToBotResponse as unknown as string,
        increment: 2,
        ...(lastUpdated ? { lastUpdated } : {}),
        ...(schemaVersion ? { schemaVersion } : {}),
      }),
      ...(stagedAction
        ? [
            updateVariable({
              stepRunId: stepRun.id,
              data: [
                {
                  baseRunId: baseRun.id,
                  baseVariableId: config.stagedActionBaseVariableId as string,
                  value: stagedAction,
                },
              ],
            }),
          ]
        : [
            updateVariable({
              stepRunId: stepRun.id,
              data: [
                {
                  baseRunId: baseRun.id,
                  baseVariableId: config.stagedActionBaseVariableId as string,
                  value: null,
                },
              ],
            }),
          ]),
    ])

    setCurrentTurn('bot_response')
  }

  const handleMultiToolSubmit = async ({
    botToToolRequests,
    toolToBotResponses,
    stagedActions,
  }: {
    botToToolRequests?: {
      value: Record<string, unknown>
      lastUpdated?: string
      schemaVersion?: string
    }[]
    toolToBotResponses?: {
      value: Record<string, unknown>
      lastUpdated?: string
      schemaVersion?: string
    }[]
    stagedActions?:
      | {
          action: string
          tool: string
          requestValues: Record<string, unknown> | null
          responseValues: Record<string, unknown> | null
          schemaVersion: string
          lastUpdated: string
          requiresConfirmation: boolean
        }[]
      | null
  }) => {
    if (mostRecentMessage.id === FAKE_MESSAGE_ID) {
      alertMessageNotYetSaved()
      return
    }
    const mostRecentMessageIndex = mostRecentMessage.index

    if (botToToolRequests) {
      await pMap(botToToolRequests, async ({ value, lastUpdated, schemaVersion }, index) => {
        await handleBaseRunCreation({
          type: 'bot_to_tool_request',
          text: value,
          index: mostRecentMessageIndex + index + 1,
          ...(lastUpdated ? { lastUpdated } : {}),
          ...(schemaVersion ? { schemaVersion } : {}),
        })
      })
    }

    await updateVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: baseRun.id,
          baseVariableId: config.stagedActionBaseVariableId as string,
          value: (stagedActions ?? null) as Json,
        },
      ],
    })

    if (toolToBotResponses) {
      await pMap(toolToBotResponses, async ({ value, lastUpdated, schemaVersion }, index) => {
        await handleBaseRunCreation({
          type: 'tool_to_bot_response',
          text: value,
          index: (botToToolRequests ?? []).length + mostRecentMessageIndex + index + 1,
          ...(lastUpdated ? { lastUpdated } : {}),
          ...(schemaVersion ? { schemaVersion } : {}),
        })
      })
    }

    setCurrentTurn('bot_response')
  }

  const alertMessageNotYetSaved = () => {
    addToast(
      `Last message is not yet saved. Please try submitting your Response again in a few seconds.`,
      {
        appearance: 'error',
      }
    )
  }

  const getPlaceholderText = () => {
    switch (currentTurn) {
      case 'user_prompt':
        return 'Type the User Prompt here...'

      case 'bot_response':
        return 'Type the Bot Response here...'

      case 'bot_to_tool_request':
        return 'Type the Bot to Tool Request here...'

      case 'tool_to_bot_response':
        return 'Type the Tool to Bot Response here...'

      default:
        return 'Type the message here...'
    }
  }

  return (
    <div className='relative box-border flex h-full w-full flex-col justify-between gap-4 rounded-md border border-solid border-gray-300 bg-white p-3'>
      <div>{isLoading ? <span>Loading...</span> : null}</div>
      <div className='overflow-auto py-10'>
        {normalizedMessages?.map((message) => (
          <SingleTurn
            key={message.id}
            index={message.index}
            messageId={message.id}
            type={message.type}
            text={message.text}
            baseRunVariables={(messagesBaseRunVariables ?? []).filter(
              (b) => b.baseRunId === message.id
            )}
            config={config}
            orchestrationId={baseRun.id}
            mostRecentMessageId={normalizedMessages?.[normalizedMessages.length - 1]?.id}
            onInsertAfter={() => setInsertAtIndex(message.index + 1)}
            metadataValidationFailures={metadataValidationFailures}
            stepRunId={stepRun.id}
            wizardIsReadOnly={isReadOnly}
          />
        ))}
      </div>
      <div className='flex items-center justify-between gap-6'>
        {!isReview ? (
          <div className='flex w-full items-center gap-2'>
            <div
              className={classNames(
                'flex h-7 w-7 items-center justify-center rounded-full p-1',
                currentTurn === 'bot_response' ? 'bg-pink-300' : 'bg-primary'
              )}>
              {currentTurn === 'bot_response' ? (
                <RobotHeadIcon className='h-6 w-6 text-white' />
              ) : (
                <UserOutlineIcon className='h-5 w-5 text-white' />
              )}
            </div>
            <TextArea
              rows={4}
              placeholder={getPlaceholderText()}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className='!w-[700px] resize-none !rounded'
              readOnly={isReadOnly}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.ctrlKey && !isCreatingNewMessage) {
                  handleMessageSubmission()
                }
              }}
            />
            {currentTurn === 'bot_response' || currentTurn === 'bot_to_tool_request' ? (
              <Dropdown
                options={[
                  { key: 'bot_response', value: 'Bot Response' },
                  { key: 'bot_to_tool_request', value: 'Bot To Tool Request' },
                ]}
                maxHeight='200px'
                selectedKey={currentTurn}
                alignment='center'
                width='100%'
                disabled={isReadOnly}
                onChange={({ key }) => setCurrentTurn(key as string)}
              />
            ) : null}

            <Button
              icon='RocketFilledIcon'
              size='md'
              variant='primary'
              shape='square'
              onClick={handleMessageSubmission}
              disabled={isCreatingNewMessage || isUpdatingManyVariables || isReadOnly}
            />
            {isCreatingNewMessage || isUpdatingManyVariables ? 'Saving...' : null}

            {!isReadOnly && currentTurn === 'bot_to_tool_request' ? (
              <ToolSelector
                lastStagedAction={lastStagedAction}
                onClose={() => setCurrentTurn('bot_response')}
                onSave={async (arg) => {
                  handleToolSelectorSubmit(arg)
                }}
                toolsList={config.toolsList ?? []}
                messages={normalizedMessages}
                handleMultiToolSubmit={handleMultiToolSubmit}
                allowMultiActionSelection={config.isMultiAction ?? false}
              />
            ) : null}

            {insertAtIndex !== false ? (
              <Modal
                title='Insert Message Modal'
                width={700}
                onClose={() => setInsertAtIndex(false)}>
                <Dropdown
                  options={[
                    { key: 'user_prompt', value: 'User Prompt' },
                    { key: 'bot_response', value: 'Bot Response' },
                    { key: 'bot_to_tool_request', value: 'Bot To Tool Request' },
                    { key: 'tool_to_bot_response', value: 'Tool To Bot Response' },
                  ]}
                  maxHeight='200px'
                  selectedKey={turnTypeToBeInserted}
                  alignment='center'
                  width='100%'
                  disabled={isReadOnly}
                  onChange={({ key }) => setTurnTypeToBeInserted(key as TMessageType)}
                />
                <TextArea
                  rows={4}
                  placeholder='Enter Message to be inserted here...'
                  value={messageToBeInserted as string}
                  onChange={(e) => setMessageToBeInserted(e.target.value)}
                  className='!w-[700px] resize-none !rounded'
                  readOnly={isReadOnly}
                />
                <div className='mt-4 flex items-center gap-4'>
                  <Button
                    variant='primary'
                    onClick={async () => handleInsert()}
                    disabled={isCreatingNewMessage || isUpdatingManyVariables || isReadOnly}>
                    Insert
                  </Button>
                </div>
              </Modal>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export { Orchestration2WAC }
