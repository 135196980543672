import LanguageIcon from '@mui/icons-material/Language'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarExportProps,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'
import { ReactNode, useRef, useState } from 'react'

const BUTTON_SLOT_PROPS = { button: { sx: { fontWeight: 'normal' } } }

const TimezoneToolbar = ({
  leading,
  trailing,
  leadingSearchInput,
  timezone,
  setTimezone,
  isExportable,
  isTimeZoneEnabled = true,
  autoFocus = true,
  quickFilterPlaceholder,
  exportProps,
}: {
  leading?: ReactNode
  trailing?: ReactNode
  leadingSearchInput?: ReactNode
  timezone?: string | null
  setTimezone?: (arg: string | null) => void
  isExportable?: boolean
  isTimeZoneEnabled?: boolean
  autoFocus?: boolean
  quickFilterPlaceholder?: string
  exportProps?: GridToolbarExportProps
}) => {
  const timezones =
    typeof (Intl as any).supportedValuesOf !== 'undefined'
      ? (Intl as any).supportedValuesOf('timeZone')
      : []
  const dropdownRef = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box display='flex'>
        {leading}
        <GridToolbarColumnsButton slotProps={BUTTON_SLOT_PROPS} />
        <GridToolbarFilterButton slotProps={BUTTON_SLOT_PROPS} />
        <GridToolbarDensitySelector slotProps={BUTTON_SLOT_PROPS} />
        {isExportable && <GridToolbarExport sx={{ fontWeight: 'normal' }} {...exportProps} />}
        {isTimeZoneEnabled && setTimezone ? (
          <Box>
            <Button
              ref={dropdownRef}
              startIcon={<LanguageIcon />}
              onClick={() => setOpen(true)}
              sx={{ fontWeight: 'normal' }}>
              {timezone ? timezone.replaceAll('_', ' ') : 'Universal Coordinated Time'}
            </Button>
            <Popper
              disablePortal
              open={open}
              anchorEl={dropdownRef?.current ?? null}
              placement='bottom-start'
              style={{
                background: 'white',
                zIndex: 1000,
              }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Autocomplete
                  freeSolo
                  sx={{ width: 200, pb: 0.5 }}
                  options={timezones}
                  value={timezone}
                  onChange={(event: any, newValue: string | null) => {
                    setTimezone(newValue)
                  }}
                  open
                  groupBy={(option: string) => option.split('/')[0]}
                  getOptionLabel={(option: string) =>
                    option.split('/')[1]?.replaceAll('_', ' ') || ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} size='small' placeholder='Timezone' />
                  )}
                />
              </ClickAwayListener>
            </Popper>
          </Box>
        ) : null}
        {trailing}
      </Box>
      <Stack direction='row' alignItems='center' gap='8px'>
        {leadingSearchInput}
        <GridToolbarQuickFilter
          autoFocus={autoFocus}
          placeholder={quickFilterPlaceholder}
          variant='outlined'
          size='small'
        />
      </Stack>
    </GridToolbarContainer>
  )
}

export { TimezoneToolbar }
