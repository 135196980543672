import { SVGProps } from 'react'
function Scissors(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={49}
      height={49}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g filter='url(#scissors_svg__filter0_d)'>
        <rect x={8.726} y={4.225} width={31.445} height={31.014} rx={15.507} fill='#fff' />
        <path
          d='M27.615 18.651l1.62-.935a1.87 1.87 0 10-2.555-.685l.935 1.62zm0 0l-8.889 5.132M27.615 20.813l1.62.935a1.87 1.87 0 11-2.555.685l.935-1.62zm0 0l-8.889-5.132'
          stroke='currentColor'
        />
        <rect
          x={9.226}
          y={4.725}
          width={30.445}
          height={30.014}
          rx={15.007}
          stroke='currentColor'
        />
      </g>
      <defs>
        <filter
          id='scissors_svg__filter0_d'
          x={0.726}
          y={0.225}
          width={47.444}
          height={47.014}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default Scissors
