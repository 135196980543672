import { z } from 'zod'

const frCasesSchema = z.object({
  goLiveID: z.string().optional(),

  custID: z.string().optional(),

  accountName: z.string().optional(),

  goLiveName: z.string().optional(),

  caseUrl: z.string().optional(),

  finalReviewType: z.string().optional(),

  packageStatus: z.string().optional(),

  virtualRestaurant: z.boolean().optional(),

  containsAlcohal: z.boolean().optional(),

  externalNotes: z.string().optional(),

  goLiveOwner: z.string().optional(),

  addressCopy: z.string().optional(),

  ghMenuMate: z.string().optional(),

  ghEntryMethod: z.string().optional(),

  menuItemCount: z.string().optional(),

  queue: z.string().optional(),

  finalReviewRepName: z.string().optional(),

  finalReviewComplete: z.string().optional(),

  processType: z.string().optional(),
})

const pagesDone = z.object({
  page1: z.boolean().optional(),

  page2: z.boolean().optional(),

  page3: z.boolean().optional(),

  page4: z.boolean().optional(),

  page5: z.boolean().optional(),

  page6: z.boolean().optional(),

  page7: z.boolean().optional(),

  page8: z.boolean().optional(),
})

export { frCasesSchema, pagesDone }
