import { grey } from '@mui/material/colors'
import LinearProgress from '@mui/material/LinearProgress'
import { alpha } from '@mui/material/styles'
import { GridToolbar } from '@mui/x-data-grid-pro'

import Pagination from './Pagination'

interface PaletteOptions {
  primary: {
    main: string
    [key: number]: string
  }
}

const PINNED_COLUMN_CLASSES = '& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-cell--pinnedRight'

const MuiDataGrid = (palette: PaletteOptions) => ({
  styleOverrides: {
    root: {
      borderRadius: 8,
      '& .row--even': {
        backgroundColor: grey[50],
        [PINNED_COLUMN_CLASSES]: {
          backgroundColor: grey[50],
        },
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(grey[200], 0.6),
          [PINNED_COLUMN_CLASSES]: {
            backgroundColor: `${grey[100]} !important`,
          },
        },
      },

      '& .row--odd': {
        backgroundColor: grey[200],
        [PINNED_COLUMN_CLASSES]: {
          backgroundColor: grey[200],
        },
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(grey[300], 0.9),
          [PINNED_COLUMN_CLASSES]: {
            backgroundColor: `${grey[300]} !important`,
          },
        },
      },
      '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    columnHeaders: {
      backgroundColor: palette.primary[50],
      color: palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    pinnedColumnHeaders: {
      backgroundColor: palette.primary[50],
    },
  },
  defaultProps: {
    pagination: true,
    pageSizeOptions: [25, 50, 100],
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    slots: {
      toolbar: GridToolbar,
      loadingOverlay: LinearProgress,
      pagination: Pagination,
    },
    slotProps: {
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
        printOptions: { disableToolbarButton: true },
        sx: { button: { fontWeight: 'normal' } },
      },
      pagination: {
        color: 'primary',
      },
    },
    getRowClassName: (params: { indexRelativeToCurrentPage: number }) =>
      `row--${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}`,
  },
})

export default MuiDataGrid
