import { withErrorBoundary } from '@invisible/common/components/error-boundary'
import { IPieChartDatum } from '@invisible/common/types'
import { Skeleton } from '@invisible/ui/skeleton'
import { OrdinalColorScaleConfig } from '@nivo/colors'
import { ColorSchemeId } from '@nivo/colors'
import { Margin } from '@nivo/core'
import { ComputedDatum, MouseEventHandler, PieTooltipProps, ResponsivePie } from '@nivo/pie'
import { FC, useEffect, useState } from 'react'

import { BRIGHT_THEME, CHILL_THEME } from './helpers'

export interface PieChartProps {
  chartDataProp: IPieChartDatum[]
  defs?: {
    id: string
    type: string
    background: string
    color: string
    size: number
    stagger: boolean
  }[]
  padAngle?: number
  height: string
  width?: string
  isInteractive?: boolean
  radius?: number
  isLoading?: boolean
  innerRadius?: number
  sortByValue?: boolean
  enableArcLabels?: boolean
  enableArcLinkLabels?: boolean
  valueFormat?: (value: number) => string
  fill?: {
    match: {
      id: string
    }
    id: string
  }[]
  colors:
    | OrdinalColorScaleConfig<Omit<ComputedDatum<IPieChartDatum>, 'color' | 'fill' | 'arc'>>
    | ColorSchemeId
    | undefined
  showLegends?: boolean
  customArcLabelsTextColor?: string
  direction?: 'column' | 'row'
  cornerRadius?: number
  translateX?: number

  // eslint-disable-next-line @typescript-eslint/ban-types
  tooltip?: React.FC<PieTooltipProps<IPieChartDatum>>
  margin?: Partial<Margin>
  handleNodeClick?: (node: ComputedDatum<IPieChartDatum>) => void
  onMouseEnter?: (node: unknown, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void
  onMouseLeave?: (node: unknown, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void
  onClick?: (node: unknown, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const PieChart: FC<PieChartProps> = withErrorBoundary(
  ({
    chartDataProp,
    defs,
    height = '600px',
    width = 'auto',
    radius,
    isLoading,
    customArcLabelsTextColor,
    innerRadius,
    sortByValue,
    enableArcLabels,
    enableArcLinkLabels,
    cornerRadius,
    padAngle,
    isInteractive,
    fill,
    colors,
    showLegends,
    tooltip,
    margin,
    onMouseEnter,
    onMouseLeave,
    handleNodeClick,
    valueFormat,
  }) => {
    const [chartData, setChartData] = useState<IPieChartDatum[]>([])
    useEffect(() => {
      const animation = setTimeout(
        () => setChartData(chartDataProp.sort((a, b) => b.value - a.value)),
        6
      )

      return () => {
        clearTimeout(animation)
      }
    }, [chartDataProp])

    const handleMouseEnter: MouseEventHandler<IPieChartDatum, SVGPathElement> = (node, event) => {
      const target = event.target as SVGPathElement
      target.style.cursor = 'pointer'
      onMouseEnter?.(node, event)
    }

    return (
      <div
        data-test='pie-chart'
        className='flex items-center justify-center'
        style={{
          height,
          width,
        }}>
        {isLoading ? (
          <div className='flex justify-center'>
            <Skeleton.Circle radius={radius ? radius : 140} />
          </div>
        ) : (
          <ResponsivePie
            transitionMode={'startAngle'}
            data={chartData}
            colors={
              colors === 'bright'
                ? BRIGHT_THEME
                : colors && colors === 'chill'
                ? CHILL_THEME
                : colors
            }
            tooltip={tooltip}
            valueFormat={valueFormat}
            onMouseEnter={(node, event) => handleMouseEnter(node, event)}
            onMouseLeave={(node, event) => onMouseLeave?.(node, event)}
            onClick={(node: ComputedDatum<IPieChartDatum>) =>
              handleNodeClick && handleNodeClick(node)
            }
            sortByValue={sortByValue}
            innerRadius={innerRadius}
            isInteractive={isInteractive}
            enableArcLinkLabels={enableArcLinkLabels}
            enableArcLabels={enableArcLabels}
            margin={{
              top: 10,
              right: 10,
              bottom: 10,
              left: 10,
              ...(margin ?? {}),
            }}
            padAngle={padAngle}
            cornerRadius={cornerRadius}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#333333'
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={
              customArcLabelsTextColor
                ? customArcLabelsTextColor
                : { from: 'color', modifiers: [['darker', 2]] }
            }
            defs={defs}
            fill={fill}
            legends={
              showLegends
                ? [
                    {
                      anchor: 'left',
                      direction: 'column',
                      justify: false,
                      translateY: -20,
                      translateX: 0,
                      itemsSpacing: 20,
                      itemWidth: 100,
                      itemHeight: 0,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]
                : undefined
            }
          />
        )}
      </div>
    )
  }
)

export default PieChart
