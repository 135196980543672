import { useDownloadReportCSV } from '@invisible/common/components/reports'
import { IDataFilter, IResponseReportCSV, TFilterValue } from '@invisible/common/types'
import { trackClickEvent, trackHoverEvent } from '@invisible/segment'
import { useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { FILTER_TYPE } from '@invisible/ui/constants'
import { DownloadOutlineIcon } from '@invisible/ui/icons'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { useEffect, useRef, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const DownloadCSVIcon: React.FC<{
  dataFilters: IDataFilter<TFilterValue>[]
  reportName: string
  fileName: string
  withButton?: boolean
  color?: string
  width?: number
  height?: number
  className?: string
}> = ({
  dataFilters,
  reportName,
  fileName,
  color,
  withButton,
  width = 16,
  height = 16,
  className,
}) => {
  const [dataForCSV, setDataForCSV] = useState<IResponseReportCSV | null>(null)
  const downloadCSVEncodedUri = useDownloadReportCSV(dataForCSV)

  const downloadLinkRef = useRef<any>(null)
  const { isLoading, refetch: getReportCSVData } = useQuery(
    [
      'report.getReportCSVData',
      {
        dataFilter: dataFilters?.filter(
          (filter: IDataFilter<TFilterValue>) => filter.name !== FILTER_TYPE.Drill
        ),
        reportName,
      },
    ],
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSettled: (data) => {
        if (!data) return
        setDataForCSV(data)
      },
    }
  )

  useEffect(() => {
    if (!downloadCSVEncodedUri) return
    downloadLinkRef.current.click()
  }, [downloadCSVEncodedUri])

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    trackClickEvent('reports_DownloadCSV')
    if (!downloadCSVEncodedUri) {
      e.preventDefault()
      getReportCSVData()
    }
  }
  return (
    <a
      className='cursor-pointer'
      onClick={handleClick}
      onMouseOver={() => trackHoverEvent('reports_DownloadCSV')}
      download={fileName}
      href={downloadCSVEncodedUri || '#'}
      ref={downloadLinkRef}>
      {!isLoading ? (
        withButton ? (
          <Button variant='primary' size='md' iconLeft='DownloadOutlineIcon'>
            Download CSV
          </Button>
        ) : (
          <DownloadOutlineIcon
            className={`text-theme-main ${className ?? ''}`}
            color={color}
            height={width}
            width={height}
          />
        )
      ) : (
        <LogoSpinner height={height} width={width} />
      )}
    </a>
  )
}

export { DownloadCSVIcon }
