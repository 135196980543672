import { Avatar } from '@invisible/ui/avatar'
import { Button } from '@invisible/ui/button'
import {
  CalendarOutlineIcon,
  CloseIcon,
  DollarIcon,
  EnvelopeOutlineIcon,
  SlackIcon,
  SuitcaseIcon,
  UserOutlineIcon,
} from '@invisible/ui/icons'
import Link from 'next/link'
import React from 'react'
import { useEffect, useRef } from 'react'

const BaseballCard = ({
  image,
  name,
  email,
  slack,
  userId,
  isVisible = false,
  setIsVisible,
}: {
  image?: string | null
  name?: string | null
  email: string | null
  slack?: string | null
  isVisible: boolean
  setIsVisible: (v: boolean) => void
  userId: string
}) => {
  const divRef = useRef<HTMLDivElement>(null)

  const handleClickEvent = (event: MouseEvent) => {
    if (divRef.current && !divRef?.current?.contains(event.target as Node)) {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEvent)

    return () => {
      document.removeEventListener('mousedown', handleClickEvent)
    }
  }, [])
  return (
    <div
      className={`${
        isVisible ? 'absolute' : 'hidden'
      } border-main h-max-content w-max-content bg-void z-50 -mt-16 -ml-4 whitespace-nowrap rounded-md border border-solid text-left shadow-lg`}
      ref={divRef}>
      <div className='divide-main flex flex-col divide-y divide-x-0 divide-solid'>
        <div className='flex justify-between p-2 pr-0'>
          <div className='flex gap-2 pt-2'>
            <div>
              <Avatar size={40} src={image ?? undefined} objectFit='cover' />
            </div>
            <div className='flex flex-col gap-1'>
              <div className='text-theme-main text-sm'>{name}</div>
              <div className='flex gap-2'>
                <EnvelopeOutlineIcon
                  width={14}
                  height={14}
                  className='text-paragraphs pt-[0.8px]'
                />
                <div className='text-paragraphs'>{`${email?.split('@')[0]}@` ?? ''}</div>
              </div>
            </div>
          </div>
          <Button variant='subtle' size='md' onClick={() => setIsVisible(false)}>
            <CloseIcon width={12} height={12} className='text-theme-main hover:cursor-pointer' />
          </Button>
        </div>
        <div className='flex gap-12 p-2 pt-4'>
          <div className='flex gap-1'>
            <Button
              variant='subtle'
              size='sm'
              onClick={() => window.open(`/profile/${userId}?tab=personal`)}>
              <UserOutlineIcon
                width={18}
                height={18}
                className='text-theme-main hover:cursor-pointer'
              />
            </Button>
            <Button
              variant='subtle'
              size='sm'
              onClick={() => window.open(`/profile/${userId}?tab=earnings`)}>
              <DollarIcon width={18} height={18} className='text-theme-main hover:cursor-pointer' />
            </Button>
            <Button
              variant='subtle'
              size='sm'
              onClick={() => window.open(`/profile/${userId}?tab=availability`)}>
              <CalendarOutlineIcon
                width={18}
                height={18}
                className='text-theme-main hover:cursor-pointer'
              />
            </Button>
            <Button
              variant='subtle'
              size='sm'
              onClick={() => window.open(`/profile/${userId}?tab=work`)}>
              <SuitcaseIcon
                width={20}
                height={20}
                className='text-theme-main hover:cursor-pointer'
              />
            </Button>
          </div>
          <div className='flex gap-1'>
            <Button variant='subtle' size='sm'>
              <Link href={email ? `mailto:${email}` : ''} passHref>
                <a className='no-underline' target='_blank'>
                  <EnvelopeOutlineIcon
                    width={18}
                    height={18}
                    className='text-theme-main hover:cursor-pointer'
                  />
                </a>
              </Link>
            </Button>
            {slack ? (
              <Button
                variant='subtle'
                size='sm'
                onClick={() => window.open(`https://slack.com/app_redirect?channel=${slack}`)}>
                <SlackIcon
                  width={18}
                  height={18}
                  className='text-theme-main hover:cursor-pointer'
                  color='rgb(83, 81, 87)'
                />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export { BaseballCard }
