import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useTosSowState } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  IStepRunType,
  toGlobalId,
  useGetOrCreateStatementOfWorkMutation,
  useStepRunStartV2Mutation,
} from '@invisible/concorde/gql-client'
import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { useContext, useQuery } from '@invisible/trpc/client'
import { resetIdleCheckStateStorage } from '@invisible/ui/hooks/use-user-activity'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { WizardCell } from '@invisible/ui/react-table'
import { useToasts } from '@invisible/ui/toasts'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isWithinInterval } from 'date-fns'
import { addDays, endOfDay, startOfDay } from 'date-fns/fp'
import { useSession } from 'next-auth/react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { AddAvailabilityModal } from './AddAvailabilityModal'
import {
  handleSetUserLifecycleStage,
  handleStepRunUpdatesOnLooopResourceStatus,
} from './common/helpers'
import { TBaseRunQueryData } from './hooks/useGetBaseRuns'
import { useStepRunUnsnooze } from './hooks/useStepRunUnsnooze'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TBaseRunVariable = NonNullable<
  inferQueryOutput<'baseRunVariable.findWizardDataForStepRun'>
>[number]

interface IWizardProps {
  stepMeta: WizardSchemas.WizardStepTemplateMeta.TSchema
  baseRun: TBaseRun
  stepRun: TStepRun
  stepName: string
  processId: string
  wizardInitialBRVs: TBaseRunVariable[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
const WizardButton: FC<IWizardProps> = ({
  stepMeta,
  baseRun,
  stepRun,
  stepName,
  processId,
  wizardInitialBRVs,
}) => {
  const { data: session } = useSession()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { state, dispatch: dispatchWizardState } = useWizardState()
  const { addToast } = useToasts()
  const [loggedInUser] = useLoggedInUser()
  const { dispatch: dispatchSowState } = useTosSowState()

  const { data: schedules, isLoading: isAvailabilityLoading } = useQuery([
    'agentSchedule.findMany',
    {
      userEmail: loggedInUser?.email ?? '',
      startDate: startOfDay(addDays(-1, new Date())),
      endDate: endOfDay(addDays(1, new Date())),
    },
  ])

  const [openAddAvailabilityModal, setOpenAddAvailabilityModal] = useState(false)

  const isInternalUser = useMemo(
    () => (loggedInUser?.email ?? '').includes('invisible.'),
    [loggedInUser?.email]
  )
  const { mutateAsync: getOrCreateSow } = useGetOrCreateStatementOfWorkMutation({
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Get or create SoW failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })
  const { mutateAsync: startStepRun } = useStepRunStartV2Mutation({
    onSuccess: (response) => {
      if (response.stepRunStartV2.__typename !== 'GraphQLErrorType') {
        const stepRunStartData = response.stepRunStartV2 as IStepRunType
        const transformedData = {
          id: fromGlobalId(stepRunStartData.id),
          stepId: fromGlobalId(stepRunStartData.step.id),
          assigneeId: fromGlobalId(stepRunStartData.assignee?.id),
          baseRunId: fromGlobalId(stepRunStartData.baseRun.id),
        }

        reactQueryContext.queryClient.setQueryData<
          inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
        >(['stepRun.findAssignedToMe'], (prevData) => {
          if (!prevData) return

          return prevData.map((p) => ({
            ...p,
            stepRuns: p.stepRuns.map((s) =>
              s.id === fromGlobalId(stepRunStartData.id) ? { ...s, status: 'running' } : s
            ),
          }))
        })
        handleSetUserLifecycleStage({
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
        })
        handleStepRunUpdatesOnLooopResourceStatus({
          id: transformedData.id,
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
          baseRunId: transformedData.baseRunId,
        })
      }
    },
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      resetIdleCheckStateStorage()
    },
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Start failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })

  const { mutateAsync: unSnoozeStepRun, isLoading: isUnsnoozing } = useStepRunUnsnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
    onError: (error) => {
      addToast(`Unsnooze failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
    onSuccess: () => {
      reactQueryContext.queryClient.setQueryData<
        inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
      >(['stepRun.findAssignedToMe'], (prevData) => {
        if (!prevData) return

        return prevData.map((p) => ({
          ...p,
          stepRuns: p.stepRuns.map((s) => (s.id === stepRun.id ? { ...s, status: 'running' } : s)),
        }))
      })
    },
  })

  const handleWizardButtonClick = async () => {
    const isUserAvailableNow = schedules?.some((schedule) =>
      isWithinInterval(new Date(), {
        start: new Date(schedule.startTime),
        end: new Date(schedule.endTime),
      })
    )
    if (isInternalUser && !isUserAvailableNow) {
      setOpenAddAvailabilityModal(true)
      return
    }

    await actToWizardButton()
  }

  const actToWizardButton = async () => {
    /**
     * This is a flag to check if the error is sow error or not
     * Initially set to true for testing purpose
     */
    try {
      if (stepRun.status === 'snoozed') {
        await unSnoozeStepRun({ stepRunId: stepRun.id })
        return
      }

      const openWizard = () => {
        dispatchWizardState({
          type: 'openWizard',
          stepRun,
          baseRun,
          wizardInitialBRVs,
          wizardData: stepMeta.wizardConfig ?? [],
          stepName,
          trainingLink: stepMeta.trainingLink,
          processId,
        })
      }

      if (stepRun.status === 'running') {
        openWizard()
      }

      if (stepRun.status === 'pending') {
        const stepRunStartData = await startStepRun({ id: toGlobalId('StepRunType', stepRun.id) })
        if (stepRunStartData.stepRunStartV2.__typename === 'GraphQLErrorType') {
          const { message, code } = stepRunStartData.stepRunStartV2

          if (message === 'User has not acknowledged the latest statement of work') {
            try {
              const request = await getOrCreateSow({
                stepRunId: toGlobalId('StepRunType', stepRun.id),
              })

              if (request?.getOrCreateStatementOfWork?.__typename !== 'GraphQLErrorType') {
                dispatchSowState({
                  type: 'setSowToAcknowledge',
                  showSowModal: true,
                  sowToAcknowledge: request.getOrCreateStatementOfWork,
                  openWizard,
                  stepRunId: stepRun.id,
                })
              }
            } catch (err: unknown) {
              addToast(`Fetch Statement of Work failed: ${(err as Error | undefined)?.message}`, {
                appearance: 'error',
              })
            }
          } else {
            addToast(`${code}: ${message}`, {
              appearance: 'error',
            })
          }
        } else {
          // Start didn't fail, open the wizard
          openWizard()
        }
      }
    } catch (error) {
      // if we fail to start, we return to not open the wizard
      return
    }
  }

  useEffect(() => {
    if (state?.stepRun?.id === stepRun?.id) dispatchWizardState({ type: 'setStepRun', stepRun })
  }, [stepRun])

  return (
    <>
      <WizardCell
        status={stepRun?.status ?? 'disabled'}
        loading={isUnsnoozing}
        disabled={
          isAvailabilityLoading ||
          !(session?.user && stepRun?.assignee?.email === session?.user?.email)
        }
        onClick={handleWizardButtonClick}
      />
      <AddAvailabilityModal
        schedules={schedules ?? []}
        onAdded={() => actToWizardButton()}
        isOpen={openAddAvailabilityModal}
        onClose={() => setOpenAddAvailabilityModal(false)}
      />
    </>
  )
}

export { WizardButton }
