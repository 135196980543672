/* eslint-disable @typescript-eslint/ban-types */

import { StoryProvider } from '../providers'

type WithStoryProps = {
  storyId: string
}

const withStory =
  (WrappedComponent: React.FC): React.FC<WithStoryProps> =>
  ({ storyId, children }) =>
    (
      <StoryProvider storyId={storyId}>
        <WrappedComponent>{children}</WrappedComponent>
      </StoryProvider>
    )

export { withStory }
