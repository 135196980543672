/* eslint-disable @typescript-eslint/ban-types */

import { StoryDynamicFiltersProvider } from '../providers'

const withStoryDynamicFilters =
  (WrappedComponent: React.FC): React.FC =>
  ({ children }) =>
    (
      <StoryDynamicFiltersProvider>
        <WrappedComponent>{children}</WrappedComponent>
      </StoryDynamicFiltersProvider>
    )

export { withStoryDynamicFilters }
