import { SVGProps } from 'react'
function Document(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M42.355 12.032L30.827.504A1.711 1.711 0 0029.616 0H6.859C5.91 0 5.145.766 5.145 1.714v44.572c0 .948.766 1.714 1.714 1.714h34.285c.949 0 1.715-.766 1.715-1.714V13.248c0-.455-.182-.894-.504-1.216zm-3.45 2.004H28.823V3.954l10.082 10.082zm.097 30.107h-30V3.857H25.18V15.43a2.25 2.25 0 002.25 2.25h11.572v26.464z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Document
