import { signIn, signOut } from 'next-auth/react'

import {
  EXTERNAL_API_TOKENS,
  NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXTAUTH_URL,
} from './config/env'

const LOGOUT_URL = `https://${NEXT_PUBLIC_AUTH0_DOMAIN}/v2/logout?client_id=${NEXT_PUBLIC_AUTH0_CLIENT_ID}&returnTo=${NEXTAUTH_URL}/api/auth/signin`

export const logout = () => {
  signOut({ callbackUrl: LOGOUT_URL })
}

export const login = (redirectTo = '/') => {
  signIn('auth0', { callbackUrl: `${NEXTAUTH_URL}${redirectTo}` })
}

export const getExternalUser = (authToken: string) => {
  const externalAccessKey = EXTERNAL_API_TOKENS.split(',').find(
    (token: string) => token.split('@')[0] === authToken.split(' ')[1]
  )
  if (!externalAccessKey) throw new Error('Invalid Auth Token')

  return externalAccessKey.split('@')[1].concat('@invisible.email')
}
