import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { NullSwitch } from '@invisible/ui/null-switch'
import { Text } from '@invisible/ui/text'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { TJson } from '@invisible/zod'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Tooltip from '@mui/material/Tooltip'
import { find } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { ReactElement } from 'react'
import Markdown from 'react-markdown'
import { Flex } from 'rebass'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { useGetBasesByProcessId } from '../hooks/useGetBasesByProcessId'

type TBaseRun = TBaseRunQueryData['items'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]
interface IProps {
  information: WizardSchemas.WACConfig.TSchema['information']
  processId: string
}

const JsonEditor = dynamic(() => import('react-json-view'), { ssr: false })

const getHtmlValue = (value: string) => {
  const sanitizedHtml = sanitize(value as string)
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
}

const jsonRenderer = ({
  valueInput,
  enableTypeMismatch,
}: {
  valueInput: TJson
  enableTypeMismatch: boolean
}) =>
  enableTypeMismatch && (
    <div className='flex flex-col gap-y-4'>
      <JsonEditor
        src={valueInput && valueInput !== '' ? (valueInput as object) : {}}
        name={null}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        quotesOnKeys={false}
        validationMessage='Invalid JSON'
        indentWidth={2}
        sortKeys
      />
    </div>
  )

const fieldComponents: Record<
  WizardSchemas.FormFieldType.TSchema,
  (
    props: WizardSchemas.FormField.TSchema & { valueInput: string; enableTypeMismatch: boolean }
  ) => ReactElement | null
> = {
  url: ({ valueInput }) => {
    const protocol = valueInput.startsWith('http') ? '' : 'http://'
    return (
      <a href={`${protocol}${valueInput}`} target='_blank' rel='noreferrer'>
        {valueInput}
      </a>
    )
  },
  input: ({ valueInput }) => <div>{valueInput}</div>,
  number: ({ valueInput }) => <div>{valueInput}</div>,
  string: ({ valueInput }) => (
    <Markdown
      className='overflow-auto'
      components={{
        a: ({ children, href }) => (
          <a href={href} target='_blank' rel='noreferrer'>
            {children}
          </a>
        ),
        p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
      }}>
      {valueInput}
    </Markdown>
  ),
  html: ({ valueInput }) => getHtmlValue(valueInput),
  dropdown: ({ valueInput }) => <div>{valueInput}</div>,
  date: ({ valueInput }) => <div>{valueInput}</div>,
  boolean: ({ valueInput }) => <NullSwitch isOn={Boolean(valueInput)} onToggle={() => undefined} />,
  // @ts-expect-error - There is no a_url type defined in the FormFieldType. For now this doesn't affect anything as a_url is not really being used in the info wac by any project
  a_url: ({ valueInput }: { valueInput: string }) => {
    let parsedValue: string | string[] | null = []
    try {
      parsedValue = JSON.parse(valueInput as string)
    } catch (e) {
      parsedValue = valueInput
    }

    return (
      <div className='flex flex-col gap-y-4'>
        {(
          (typeof parsedValue === 'string' ? parsedValue.split(',') : parsedValue) as string[]
        )?.map((url: string) => (
          <a
            href={`${url.startsWith('http') ? '' : 'http://'}${url}`}
            target='_blank'
            rel='noreferrer'>
            {url}
          </a>
        ))}
      </div>
    )
  },
  object: jsonRenderer,
  a_any: jsonRenderer,
  a_string: jsonRenderer,
  a_boolean: jsonRenderer,
  a_date: jsonRenderer,
  a_datetime: jsonRenderer,
  a_duration: jsonRenderer,
  a_email: jsonRenderer,
  a_enum: jsonRenderer,
  a_number: jsonRenderer,
  a_object: jsonRenderer,
  a_a_any: jsonRenderer,
  a_a_object: jsonRenderer,
  a_a_string: jsonRenderer,
}

const InformationWAC = ({ information, processId }: IProps) => {
  const { state: wizardState } = useWizardState()
  const { data: bases } = useGetBasesByProcessId(processId)
  const { value: enableTypeMismatch } = useGate('enable-type-mismatch')

  if (!information) {
    return null
  }

  const canConvertToString = (value: unknown) => {
    try {
      String(value)
      return true
    } catch (e) {
      return false
    }
  }

  return (
    <>
      {information.fields.map((field) => {
        const baseRunVariable = find({ baseVariableId: field.baseVariableId })(
          wizardState.wizardInitialBRVs
        ) as TBaseRunVariable | undefined
        const baseName: string | null = bases?.find((b) => b.id === field.baseId)?.name ?? null
        const typeMismatch = enableTypeMismatch
          ? wizardState.typeMismatches.find(
              (typeMismatch) => typeMismatch.baseRunVariableId === baseRunVariable?.id
            )
          : false

        return (
          <Flex key={field.baseVariableId} alignItems='center' justifyContent='center' mb={3}>
            <Text textAlign='end' width={field.dimensions?.labelWidth ?? '40%'} mr={2}>
              {information?.hideBaseNames
                ? field.label
                : baseName
                ? `${baseName} > ${field.label}`
                : field.label}
            </Text>
            <Flex width={field.dimensions?.contentWidth ?? '60%'} justifyContent='flex-start'>
              {/* If there is a type mismatch, display an error message. Otherwise, if no renderer is defined for the type, default to using a string. */}
              {typeMismatch ? (
                canConvertToString(baseRunVariable?.value) ? (
                  <div className='flex flex-row items-center space-x-2'>
                    {fieldComponents['string']?.({
                      ...field,
                      valueInput: String(baseRunVariable?.value),
                      enableTypeMismatch,
                    })}
                    <Tooltip
                      title={`Type mismatch for key ${field.label} expected: ${
                        typeMismatch.expected
                      },${' '}
                    found: ${typeMismatch.actual}`}
                      placement='bottom'>
                      <WarningAmberIcon fontSize='small' color='warning' />
                    </Tooltip>
                  </div>
                ) : (
                  <Text color='red'>
                    Error: Type mismatch for key {field.label} expected: ${typeMismatch.expected},{' '}
                    found: ${typeMismatch.actual}
                  </Text>
                )
              ) : Object.keys(fieldComponents).includes(
                  baseRunVariable?.baseVariable.type ?? ''
                ) ? (
                fieldComponents[
                  baseRunVariable?.baseVariable.type as keyof typeof fieldComponents
                ]?.({
                  ...field,
                  valueInput: (baseRunVariable?.value as string) ?? '',
                  enableTypeMismatch,
                })
              ) : (
                fieldComponents['string']?.({
                  ...field,
                  valueInput: (baseRunVariable?.value as string) ?? '',
                  enableTypeMismatch,
                })
              )}
            </Flex>
          </Flex>
        )
      })}
    </>
  )
}

export { InformationWAC }
