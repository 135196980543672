import { NullSwitch } from '@invisible/ui/null-switch'
import { theme, ThemeProvider } from '@invisible/ui/themes'
import type { TVariableType } from '@invisible/ultron/zod'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { isObjectLike } from 'lodash/fp'
import { FC } from 'react'
import sanitize from 'sanitize-html'

const getHtmlValue = (value: string) => {
  const sanitizedHtml = sanitize(value)
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseRunVariableValue: FC<{
  type: TVariableType | 'childBaseRuns' | 'ID'
  value: any
}> = ({ type, value }) => {
  const isArrayType = type.startsWith('a_')
  if (isArrayType) {
    return <div>{`${JSON.stringify(value).slice(0, 60)}...`}</div>
  }
  switch (type) {
    case 'boolean':
      return (
        <ThemeProvider theme={theme}>
          <NullSwitch isOn={Boolean(value)} onToggle={() => undefined} disabled={true} />
        </ThemeProvider>
      )
    case 'url':
      return (
        <a href={value} target='_blank' rel='noreferrer'>
          {value}
        </a>
      )
    case 'html':
      return getHtmlValue(value)

    case 'ID':
      return (
        <Stack direction='row' spacing={1}>
          <div className='self-center'>{value}</div>
          <IconButton size='small' onClick={() => navigator.clipboard.writeText(value)}>
            <ContentCopyIcon sx={{ color: 'grey.500', fontSize: '18px' }} />
          </IconButton>
        </Stack>
      )
    default:
      return <div>{isObjectLike(value) ? JSON.stringify(value) : value}</div>
  }
}
