import { SVGProps } from 'react'
function CheckList(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='m21.76,6l4.24,4.24-13.24,13.24L4,14.73l4.24-4.24,4.52,4.52,9-9Zm-9,25l-4.52-4.52-4.24,4.24,8.76,8.76,13.24-13.24-4.24-4.24-9,9Zm0,16l-4.52-4.52-4.24,4.24,8.76,8.76,13.24-13.24-4.24-4.24-9,9ZM28,13v6h28v-6h-28Zm0,22h28v-6h-28v6Zm0,16h28v-6h-28v6Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CheckList
