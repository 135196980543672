import { GQLValidationError } from '@invisible/common/types'

export function toGlobalId(type: string, id?: string | null) {
  if (!id) return ''
  return Buffer.from(`${type}:${id}`).toString('base64')
}

export function fromGlobalId(globalId?: string | null) {
  if (!globalId) return ''
  return Buffer.from(globalId, 'base64').toString('ascii').split(':')?.[1]
}

export const isValidationError = (error: unknown): error is GQLValidationError => {
  if (typeof error === 'object' && error !== null) {
    const errorObj = error as { meta?: unknown }
    if ('meta' in errorObj) {
      const meta = errorObj.meta as { response?: unknown }
      if (meta && 'response' in meta) {
        const response = meta.response as { errors?: unknown }
        return Array.isArray(response?.errors)
      }
    }
  }
  return false
}

export const getErrorMessage = (error: unknown) => {
  if (isValidationError(error)) {
    return error.meta.response.errors.map((error) => error.message).join('\n')
  }

  if (error instanceof Error) {
    return error.message
  }
  return ''
}
