import { useStore } from '@invisible/common/stores/process-store'
import { useMutation } from '@invisible/trpc/client'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { DateTimeCell } from '@invisible/ui/react-table'
import { FC, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'

interface IDeadlineCellProps {
  baseRunId: string
  value: Date | null
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DeadlineCell: FC<IDeadlineCellProps> = ({ baseRunId, value }) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage } = useStore(
    useCallback(
      (state) => ({
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        currentPage: state.currentPage,
      }),
      []
    ),
    shallow
  )

  const reactQueryClient = useQueryClient()

  const { mutateAsync: updateBaseRun } = useMutation('baseRun.update', {
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onMutate: (variables) => {
      reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
        [
          'get-base-runs',
          {
            baseId,
            baseViewId,
            take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
            filters: filterOption ?? [],
            sort: sortOption ?? {},
            search: searchTerm ?? '',
            page: currentPage ?? 1,
          },
        ],
        (prevData) => {
          if (!prevData) return prevData

          const updatedBaseRunData = prevData.items.map((baseRun) => {
            if (baseRun.id === variables.id) {
              return {
                ...baseRun,
                deadline: variables.deadline ?? baseRun.deadline,
              }
            }
            return baseRun
          })
          return {
            ...prevData,
            baseRuns: updatedBaseRunData,
          }
        }
      )
    },
  })

  const updateDeadline = useCallback(
    ({ baseRunId, value }: { baseRunId: string; value: any }) => {
      const deadline = new Date(value)
      updateBaseRun({ id: baseRunId, deadline })
    },
    [updateBaseRun]
  )

  return (
    <DateTimeCell
      onChange={(value) => {
        updateDeadline({
          baseRunId,
          value,
        })
      }}
      value={value}
    />
  )
}
