import { SVGProps } from 'react'
function InfoFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.1848 0.5C13.0203 0.5 9.92688 1.43838 7.2957 3.19649C4.66451 4.95459 2.61375 7.45344 1.40275 10.3771C0.191747 13.3007 -0.125106 16.5177 0.492257 19.6214C1.10962 22.7251 2.63347 25.5761 4.87111 27.8137C7.10875 30.0513 9.95968 31.5752 13.0634 32.1926C16.1671 32.8099 19.3841 32.4931 22.3078 31.2821C25.2314 30.0711 27.7302 28.0203 29.4883 25.3891C31.2464 22.7579 32.1848 19.6645 32.1848 16.5C32.1767 12.259 30.4884 8.19409 27.4895 5.19527C24.4907 2.19645 20.4258 0.508132 16.1848 0.5ZM15.8771 7.88461C16.2423 7.88461 16.5992 7.99289 16.9028 8.19575C17.2064 8.3986 17.443 8.68693 17.5827 9.02427C17.7225 9.36162 17.759 9.73282 17.6878 10.0909C17.6166 10.4491 17.4407 10.778 17.1826 11.0362C16.9244 11.2944 16.5954 11.4702 16.2373 11.5414C15.8792 11.6127 15.508 11.5761 15.1706 11.4364C14.8333 11.2967 14.545 11.06 14.3421 10.7564C14.1392 10.4528 14.031 10.0959 14.031 9.73077C14.031 9.24114 14.2255 8.77156 14.5717 8.42534C14.9179 8.07912 15.3875 7.88461 15.8771 7.88461ZM17.4156 25.1154H16.1848C15.8584 25.1154 15.5453 24.9857 15.3145 24.7549C15.0837 24.5241 14.954 24.211 14.954 23.8846V16.5C14.6276 16.5 14.3146 16.3703 14.0838 16.1395C13.853 15.9087 13.7233 15.5956 13.7233 15.2692C13.7233 14.9428 13.853 14.6298 14.0838 14.3989C14.3146 14.1681 14.6276 14.0385 14.954 14.0385H16.1848C16.5112 14.0385 16.8243 14.1681 17.0551 14.3989C17.2859 14.6298 17.4156 14.9428 17.4156 15.2692V22.6538C17.742 22.6538 18.0551 22.7835 18.2859 23.0143C18.5167 23.2451 18.6464 23.5582 18.6464 23.8846C18.6464 24.211 18.5167 24.5241 18.2859 24.7549C18.0551 24.9857 17.742 25.1154 17.4156 25.1154Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InfoFilled
