import { SVGProps } from 'react'

function QuestionMark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z'
        fill='currentColor'
      />
      <path
        d='M12.1794 6.18494C11.5935 5.67126 10.8201 5.39001 9.99976 5.39001C9.17944 5.39001 8.40601 5.67322 7.82007 6.18494C7.21069 6.71814 6.87476 7.43494 6.87476 8.20251V8.35095C6.87476 8.43689 6.94507 8.5072 7.03101 8.5072H7.96851C8.05444 8.5072 8.12476 8.43689 8.12476 8.35095V8.20251C8.12476 7.34119 8.96655 6.64001 9.99976 6.64001C11.033 6.64001 11.8748 7.34119 11.8748 8.20251C11.8748 8.80994 11.4451 9.36658 10.7791 9.62244C10.365 9.78064 10.0134 10.058 9.76147 10.4213C9.50562 10.7924 9.3728 11.2377 9.3728 11.6888V12.1088C9.3728 12.1947 9.44312 12.265 9.52905 12.265H10.4666C10.5525 12.265 10.6228 12.1947 10.6228 12.1088V11.6654C10.6238 11.4758 10.6819 11.2909 10.7896 11.1348C10.8972 10.9787 11.0494 10.8587 11.2263 10.7904C12.3787 10.347 13.1228 9.33142 13.1228 8.20251C13.1248 7.43494 12.7888 6.71814 12.1794 6.18494ZM9.21851 14.2963C9.21851 14.5035 9.30082 14.7022 9.44733 14.8487C9.59384 14.9952 9.79256 15.0775 9.99976 15.0775C10.207 15.0775 10.4057 14.9952 10.5522 14.8487C10.6987 14.7022 10.781 14.5035 10.781 14.2963C10.781 14.0891 10.6987 13.8904 10.5522 13.7438C10.4057 13.5973 10.207 13.515 9.99976 13.515C9.79256 13.515 9.59384 13.5973 9.44733 13.7438C9.30082 13.8904 9.21851 14.0891 9.21851 14.2963Z'
        fill={props.fill ?? 'currentColor'}
      />
    </svg>
  )
}

export default QuestionMark
