import { parseAsLocale } from '@invisible/common/date'
import { Button } from '@invisible/ui/button'
import { Icons } from '@invisible/ui/icons'
import type { StepRunStatus } from '@invisible/ultron/prisma'
import type { TUuid } from '@invisible/zod'
import { intervalToDuration, subHours, subMinutes, subSeconds } from 'date-fns/fp'
import { flow, isEmpty, reduce } from 'lodash/fp'
import { FC, useEffect, useState } from 'react'

import { WIZARD_STATUS } from './WizardCell'

type StepRunSnoozeActivityWithUnsnoozedAt = {
  id: TUuid
  snoozedAt: Date
  unsnoozedAt: Date | null
  active: boolean
}

interface StepRunControlsCellProps {
  status?: StepRunStatus
  startedAt: Date | null
  disabled: boolean
  stepRunSnoozeActivities?: StepRunSnoozeActivityWithUnsnoozedAt[]
  onClick: () => void
  doneClick: () => void
  snoozeClick: () => void
}

type Duration = { hours?: string; minutes?: string; seconds?: string }

/**
 * Returns current duration of a step run.
 * Calculates duration based on startedAt, stepRunSnoozeActivities & current timestamp
 */
const getStepRunCurrentDuration = ({
  startedAt,
  stepRunSnoozeActivities,
}: {
  startedAt: Date
  stepRunSnoozeActivities?: StepRunSnoozeActivityWithUnsnoozedAt[]
}) => {
  let end = parseAsLocale(new Date())

  if (stepRunSnoozeActivities && !isEmpty(stepRunSnoozeActivities)) {
    const parsedSnoozeActivities = stepRunSnoozeActivities.map(
      ({ snoozedAt, unsnoozedAt, ...rest }) => ({
        ...rest,
        snoozedAt: parseAsLocale(snoozedAt),
        unsnoozedAt: unsnoozedAt ? parseAsLocale(unsnoozedAt) : null,
      })
    )

    end = reduce((prev, snoozeActivity: StepRunSnoozeActivityWithUnsnoozedAt) => {
      if (
        snoozeActivity.snoozedAt.getTime() < new Date(startedAt).getTime() ||
        (snoozeActivity.unsnoozedAt &&
          snoozeActivity.unsnoozedAt.getTime() < new Date(startedAt).getTime())
      )
        return prev

      const snoozeInterval = intervalToDuration({
        start: snoozeActivity.snoozedAt,
        end: snoozeActivity.unsnoozedAt,
      })

      return flow(
        subSeconds(snoozeInterval.seconds),
        subMinutes(snoozeInterval.minutes),
        subHours(snoozeInterval.hours)
      )(prev)
    }, end)(parsedSnoozeActivities)
  }

  startedAt = parseAsLocale(startedAt)

  const { hours, minutes, seconds } = intervalToDuration({
    start: startedAt,
    end,
  })

  return {
    hours: hours?.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    }),
    minutes: minutes?.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    }),
    seconds: seconds?.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    }),
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StepRunControlsCell: FC<StepRunControlsCellProps> = ({
  status = 'pending',
  startedAt,
  disabled,
  stepRunSnoozeActivities,
  onClick,
  doneClick,
  snoozeClick,
}) => {
  const [duration, setDuration] = useState<Duration | undefined>(() =>
    getStepRunCurrentDuration({ startedAt: startedAt ?? new Date(), stepRunSnoozeActivities })
  )

  useEffect(() => {
    if (status !== 'running') return

    const durationTimerId = setInterval(() => {
      setDuration(() =>
        getStepRunCurrentDuration({ startedAt: startedAt ?? new Date(), stepRunSnoozeActivities })
      )
    }, 1000)

    return () => clearInterval(durationTimerId)
  }, [startedAt, status, stepRunSnoozeActivities])

  if (['disabled', 'pending'].includes(status))
    return (
      <Button
        wFull
        size='md'
        variant='primary'
        iconLeft={WIZARD_STATUS[status].icon}
        disabled={disabled}
        title={status}
        onClick={onClick}>
        {WIZARD_STATUS[status].text}
      </Button>
    )

  if (status === 'snoozed') {
    return (
      <Button
        wFull
        size='md'
        variant={WIZARD_STATUS[status].variant}
        iconLeft={WIZARD_STATUS[status].icon}
        color={WIZARD_STATUS[status]?.color}
        disabled={disabled}
        title={status}
        onClick={onClick}>
        {WIZARD_STATUS[status].text}
      </Button>
    )
  }

  if (status === 'running')
    return (
      <div className='flex w-full items-center gap-2'>
        <Button
          size='md'
          variant='primary'
          iconLeft='ClockFilledIcon'
          title='Duration'
          disabled={disabled}>
          {`${duration?.hours}:${duration?.minutes}:${duration?.seconds}`}
        </Button>
        <Button
          shape='square'
          size='md'
          variant='secondary'
          icon='SnoozeOutlineIcon'
          color='theme'
          title='Snooze'
          disabled={disabled}
          onClick={() => snoozeClick()}
        />
        <Button
          shape='square'
          size='md'
          variant='secondary'
          icon='CheckCircleOutlineIcon'
          color='success'
          disabled={disabled}
          title='Done'
          onClick={doneClick}
        />
      </div>
    )

  return (
    <div className='border-weak flex h-8 w-full items-center justify-center space-x-2 rounded-md border border-solid'>
      {Icons[WIZARD_STATUS[status].icon]({ className: 'w-4 h-4' })}
      <span>{WIZARD_STATUS[status].text}</span>
    </div>
  )
}

export { StepRunControlsCell }
