import { SVGProps } from 'react'
function FolderOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='24'
      viewBox='0 0 33 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.00845 0.66947C1.44312 0.240816 2.03266 0 2.64737 0H10.3733C10.8713 0.00201995 11.3557 0.16124 11.7553 0.45433L11.7581 0.456349L15.7825 3.42576C15.7848 3.42737 15.7878 3.42847 15.7906 3.42857H25.8252C26.4399 3.42857 27.0295 3.66939 27.4641 4.09804C27.8988 4.5267 28.143 5.10808 28.143 5.71429V8H30.0116C30.3781 7.99998 30.7394 8.08567 31.0658 8.25001C31.3922 8.41438 31.6745 8.65273 31.8894 8.94552C32.1043 9.23831 32.2457 9.57718 32.302 9.93431C32.3584 10.2914 32.328 10.6567 32.2135 11L32.2121 11.0043L28.0835 23.2185C27.9258 23.6852 27.4829 24 26.9841 24H1.48848C0.848443 24 0.32959 23.4883 0.32959 22.8571V2.28571C0.32959 1.6795 0.573785 1.09812 1.00845 0.66947ZM25.8252 8V5.71429H15.7815C15.2835 5.71227 14.7991 5.55305 14.3995 5.25996L14.3967 5.25793L10.3723 2.28853C10.37 2.28693 10.367 2.28582 10.3642 2.28571L2.64737 2.28571L2.64737 15.8219L4.76245 9.57143L4.76334 9.56876C4.91611 9.11269 5.2107 8.71564 5.60531 8.43402C6.00066 8.15187 6.47623 7.99998 6.96425 8M3.09692 21.7143L6.9626 10.2903L6.96415 10.2857H30.0117L26.1488 21.7143H3.09692ZM6.96425 8H25.8252Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FolderOutline
