import { SVGProps } from 'react'

function TagOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='29'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1452 0.663491C13.4674 0.600418 13.8001 0.617599 14.114 0.713518C14.4277 0.80938 14.713 0.980942 14.9448 1.21308V1.21308L27.9911 14.2594C28.1783 14.4435 28.3271 14.663 28.429 14.905C28.5318 15.1491 28.5847 15.4113 28.5847 15.6761C28.5847 15.941 28.5318 16.2031 28.429 16.4472C28.3271 16.6893 28.1783 16.9087 27.9911 17.0928L16.6854 28.3986C16.5012 28.5858 16.2818 28.7346 16.0398 28.8365C15.7957 28.9392 15.5335 28.9922 15.2686 28.9922C15.0038 28.9922 14.7416 28.9392 14.4975 28.8365C14.2555 28.7346 14.0361 28.5858 13.8519 28.3986L0.80602 15.3527V15.3527C0.573884 15.1209 0.401909 14.8352 0.306047 14.5214C0.210128 14.2075 0.192947 13.8748 0.25602 13.5527L0.256711 13.5492L2.26921 3.46171C2.34826 3.06547 2.658 2.75573 3.05424 2.67668L13.1417 0.664182L13.1452 0.663491ZM13.5295 2.62622L4.09999 4.50746L2.21875 13.937L2.21949 13.9377L15.2686 26.9869L26.5794 15.6761L13.5295 2.62622Z'
        fill='currentColor'
      />
      <path
        d='M8.5 10.4073C9.32843 10.4073 10 9.73578 10 8.90735C10 8.07892 9.32843 7.40735 8.5 7.40735C7.67157 7.40735 7 8.07892 7 8.90735C7 9.73578 7.67157 10.4073 8.5 10.4073Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TagOutline
