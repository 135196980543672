import { z } from '@invisible/zod'

import { schema as reportVisualizationMappingSchema } from './ReportVisualizationMapping'

const schema = z.object({
  companyId: z.number().nullable(),
  filters: z.array(reportVisualizationMappingSchema),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
