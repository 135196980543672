import { NotFoundImg } from '@invisible/ui/images'
import { Text } from '@invisible/ui/text'
import { styled } from '@invisible/ui/themes'
import Link from 'next/link'

const Container = styled.div`
  text-align: center;
  margin: 1em 9em;
  @media (max-width: 600px) {
    margin: 0 1em;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin: 0 2em;
  }
`

const Copyright = styled(Text)`
  margin-top: 2rem;
  margin-bottom: 50px;
`

const Anchor = styled.a`
  text-decoration: none;
  border-radius: 37px;
  background-color: #542cea;
  border: 0;
  color: #efefef;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
`

const Description = styled.p`
  color: black;
  font-family: Roboto;
  font-size: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 10px;
  }
`

const NotFoundImage = styled(NotFoundImg)`
  margin-top: 2rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const Error = ({ message }: { message?: string }) => (
  <Container>
    <NotFoundImage width={650} height={430} />
    <Description>
      {message
        ? message
        : 'Seems like something went wrong. Ollie is working his magic as we speak tofix this problem.'}
    </Description>
    <Link href='/'>
      <Anchor>Go Home</Anchor>
    </Link>
    <Copyright>All rights reserved. &copy; Invisible Technologies Inc.</Copyright>
  </Container>
)
