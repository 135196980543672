import { SVGProps } from 'react'
function HeartOn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.62 3.877a3.555 3.555 0 00-.779-1.132A3.628 3.628 0 009.293 1.71 3.651 3.651 0 007 2.517a3.651 3.651 0 00-2.293-.808A3.628 3.628 0 002.16 2.745a3.534 3.534 0 00-1.065 2.526c0 .455.093.93.277 1.412.155.403.376.822.66 1.244.448.669 1.064 1.366 1.83 2.073a20.463 20.463 0 002.578 2.014l.324.207a.44.44 0 00.472 0l.324-.207A20.731 20.731 0 0010.138 10c.765-.707 1.382-1.404 1.83-2.073.283-.422.506-.84.66-1.244.184-.483.277-.957.277-1.412a3.481 3.481 0 00-.286-1.394z'
        fill='currentColor'
      />
    </svg>
  )
}

export default HeartOn
