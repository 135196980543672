import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BaseViewMeta } from '@invisible/ultron/zod'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { Dispatch, SetStateAction } from 'react'

type TSteps = NonNullable<inferQueryOutput<'process.findByIdWithBases'>>['bases'][number]['steps']
type TStepRunFilters = NonNullable<BaseViewMeta.TSchema['stepRunFilters']>

interface IProps {
  steps: TSteps
  baseViewId: string
  stepRunFilters: TStepRunFilters
  setStepRunFilters: Dispatch<SetStateAction<TStepRunFilters>>
}

const FILTER_OPTIONS = [
  { label: 'Started At', value: 'started_at' },
  { label: 'Stopped At', value: 'stopped_at' },
  { label: 'Created At', value: 'created_at' },
  { label: 'Updated At', value: 'updated_at' },
]

const ExtraFiltersModal = ({ steps, stepRunFilters, setStepRunFilters }: IProps) => {
  const unfilteredSteps = steps.filter((s) => !stepRunFilters.find((f) => f.step_id === s.id))

  return (
    <MUIThemeProvider>
      <Box sx={{ py: '8px', height: '500px', overflow: 'auto' }}>
        <Stack gap='12px' mb='12px'>
          {/* Steps with filters applied */}
          {stepRunFilters.map((filter) => {
            const filterKey = Object.keys(filter).find(
              (key) => key !== 'step_id'
            ) as keyof typeof filter
            const filterValue = filter[filterKey]

            return (
              <Box key={filter.step_id}>
                <Typography>{steps.find((s) => s.id === filter.step_id)?.name}</Typography>
                <Stack direction='row' alignItems='center' key={filter.step_id} gap='8px' mt='4px'>
                  <Select
                    size='small'
                    sx={{ width: '200px' }}
                    value={filterKey}
                    onChange={(e) =>
                      setStepRunFilters((prev) =>
                        prev.map((f) =>
                          f.step_id === filter.step_id
                            ? { step_id: f.step_id, [e.target.value]: filterValue }
                            : f
                        )
                      )
                    }>
                    {FILTER_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <DateRangePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                    sx={{ width: '200px' }}
                    slots={{ field: SingleInputDateRangeField }}
                    timezone='UTC'
                    defaultValue={
                      typeof filterValue === 'object' &&
                      filterValue.start_date &&
                      filterValue.end_date
                        ? [new Date(filterValue.start_date), new Date(filterValue.end_date)]
                        : undefined
                    }
                    onChange={(range) => {
                      if (range.length === 2)
                        setStepRunFilters((prev) =>
                          prev.map((f) =>
                            f.step_id === filter.step_id
                              ? {
                                  step_id: f.step_id,
                                  [filterKey]: {
                                    start_date: (range[0] as Date).toISOString(),
                                    end_date: (range[1] as Date)?.toISOString(),
                                  },
                                }
                              : f
                          )
                        )
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={() =>
                      setStepRunFilters((prev) => prev.filter((f) => f.step_id !== filter.step_id))
                    }>
                    <ClearIcon sx={{ fontSize: '18px' }} />
                  </IconButton>
                </Stack>
              </Box>
            )
          })}
        </Stack>

        <Stack gap='12px'>
          {/* Unfiltered steps */}
          {unfilteredSteps.map((step) => (
            <Stack direction='row' alignItems='center' gap='4px' key={step.id}>
              <Typography key={step.id}>{step.name}</Typography>
              <IconButton
                size='small'
                onClick={() =>
                  setStepRunFilters((prev) => [
                    ...prev,
                    {
                      step_id: step.id,
                      started_at: {
                        start_date: undefined,
                        end_date: undefined,
                      },
                    },
                  ])
                }>
                <AddIcon sx={{ fontSize: '18px' }} />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </Box>
    </MUIThemeProvider>
  )
}

export { ExtraFiltersModal }
