import { SnackbarContext } from '@invisible/common/providers'
import {
  getErrorMessage,
  parseResponse,
  toGlobalId,
  useExportConfigurationCreateMutation,
  useExportConfigurationUpdateMutation,
} from '@invisible/concorde/gql-client'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'

const useExportCreateOrUpdate = () => {
  const { showSnackbar } = useContext(SnackbarContext)
  const reactQueryClient = useQueryClient()
  const { query } = useRouter()

  const { mutateAsync: createExport, isLoading: isExportCreateLoading } =
    useExportConfigurationCreateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })

  const { mutateAsync: updateExport, isLoading: isExportUpdateLoading } =
    useExportConfigurationUpdateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries([
          'ExportConfigurations',
          { filters: { processId: toGlobalId('Process', query.id as string) } },
        ])
      },
      onSuccess: (data) => {
        parseResponse(data, (_, message) =>
          showSnackbar({
            message,
            variant: 'error',
          })
        )
      },
    })

  return {
    createExport,
    isExportCreateLoading,
    updateExport,
    isExportUpdateLoading,
  }
}

export { useExportCreateOrUpdate }
