import { z } from '@invisible/zod'

import { schema as dataFilterSchema } from '../../DataFilter'

const schema = z.object({
  reportName: z.string(),
  dataFilter: z.array(dataFilterSchema),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
