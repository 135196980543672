import { SVGProps } from 'react'

function TrashOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.851 13.0212C13.5414 13.0212 14.101 13.5722 14.101 14.252V24.0981C14.101 24.7778 13.5414 25.3288 12.851 25.3288C12.1607 25.3288 11.601 24.7778 11.601 24.0981V14.252C11.601 13.5722 12.1607 13.0212 12.851 13.0212Z'
        fill='currentColor'
      />
      <path
        d='M21.601 14.252C21.601 13.5722 21.0414 13.0212 20.351 13.0212C19.6607 13.0212 19.101 13.5722 19.101 14.252V24.0981C19.101 24.7778 19.6607 25.3288 20.351 25.3288C21.0414 25.3288 21.601 24.7778 21.601 24.0981V14.252Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8511 0.713379C11.8565 0.713379 10.9027 1.10239 10.1994 1.79483C9.49616 2.48726 9.10107 3.42641 9.10107 4.40567V5.63662H2.85107C2.16072 5.63662 1.60107 6.18765 1.60107 6.86738C1.60107 7.54712 2.16072 8.09815 2.85107 8.09815H4.10103V30.2519C4.10103 30.9047 4.36442 31.5308 4.83326 31.9925C5.3021 32.4541 5.93799 32.7134 6.60103 32.7134H26.601C27.2641 32.7134 27.9 32.4541 28.3688 31.9925C28.8376 31.5308 29.101 30.9047 29.101 30.2519V8.09815H30.3511C31.0414 8.09815 31.6011 7.54712 31.6011 6.86738C31.6011 6.18765 31.0414 5.63662 30.3511 5.63662H24.1011V4.40567C24.1011 3.42641 23.706 2.48726 23.0027 1.79483C22.2995 1.10239 21.3456 0.713379 20.3511 0.713379H12.8511ZM21.6011 5.63662V4.40567C21.6011 4.07925 21.4694 3.7662 21.235 3.53539C21.0005 3.30458 20.6826 3.17491 20.3511 3.17491H12.8511C12.5196 3.17491 12.2016 3.30458 11.9672 3.53539C11.7328 3.7662 11.6011 4.07925 11.6011 4.40567V5.63662H21.6011ZM6.60103 30.2519V8.09815H26.601V30.2519H6.60103Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TrashOutline
