import { Wizard } from '@invisible/ultron/zod'

import { StoreSlice } from '../index'

type TWAC = Wizard.WizardConfig.TSchema[number]

export interface IWizardBuilderSlice {
  wacs: TWAC[]
  activeWacForConfiguration: string | null
  activeWacForDeletion: string | null

  setWacs: (wacs: TWAC[]) => void
  addWac: (wac: TWAC) => void
  updateWac: (wac: TWAC) => void
  removeWac: (id: string) => void
  updateLayouts: (layouts: Record<string, TWAC['layout']>) => void
  setActiveWacForConfiguration: (wacId: string | null) => void
  setActiveWacForDeletion: (wacId: string | null) => void
}

export const createWizardBuilderSlice: StoreSlice<IWizardBuilderSlice> = (set, get) => ({
  wacs: [],
  activeWacForDeletion: null,
  activeWacForConfiguration: null,

  setWacs(wacs) {
    set((prev) => ({ ...prev, wacs }))
  },
  addWac(wac) {
    set((prev) => ({ ...prev, wacs: [wac, ...prev.wacs] }))
  },
  updateWac(wac) {
    set((prev) => ({
      ...prev,
      wacs: prev.wacs.map((w) => (w.layout.i === wac.layout.i ? wac : w)),
    }))
  },
  removeWac(id) {
    set((prev) => ({ ...prev, wacs: prev.wacs.filter((w) => w.layout.i !== id) }))
  },
  updateLayouts(layouts) {
    set((prev) => ({
      ...prev,
      wacs: prev.wacs.map((wac) => ({ ...wac, layout: layouts[wac.layout.i] })),
    }))
  },
  setActiveWacForConfiguration(wacId) {
    set((prev) => ({ ...prev, activeWacForConfiguration: wacId }))
  },
  setActiveWacForDeletion(wacId) {
    set((prev) => ({ ...prev, activeWacForDeletion: wacId }))
  },
})
