import { fromGlobalId, toGlobalId, useBaseRunFindByIdQuery } from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { useGate } from 'statsig-react'

const useBaseRunFindById = ({ baseRunId }: { baseRunId: string }) => {
  const { value: isGraphQLEnabled } = useGate('enable-concorde-base-run-find-by-id')
  const { data: trpcResult, isLoading: isTrpcLoading } = useTrpcQuery(
    ['baseRun.findById', { id: baseRunId }],
    {
      enabled: !isGraphQLEnabled && Boolean(baseRunId),
    }
  )
  const { data: graphqlResult, isLoading: isGraphqlLoading } = useBaseRunFindByIdQuery(
    { id: toGlobalId('BaseRunType', baseRunId) },
    {
      enabled: isGraphQLEnabled && Boolean(baseRunId),
    }
  )
  const data = isGraphQLEnabled
    ? graphqlResult?.baseRuns.edges.map((edge) => {
        const baseRun = edge.node
        return {
          id: fromGlobalId(baseRun?.id),
          process: {
            id: fromGlobalId(baseRun?.process.id),
            name: baseRun?.process.name,
          },
        }
      })[0]
    : trpcResult
  return {
    data,
    isLoading: isGraphQLEnabled ? isGraphqlLoading : isTrpcLoading,
  }
}

export { useBaseRunFindById }
