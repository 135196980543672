import { SVGProps } from 'react'
function EditDataOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='29'
      viewBox='0 0 26 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3296 9.21509V2.5293L2.32959 2.5293L2.32959 24.5293H5.51917C6.07145 24.5293 6.51917 24.977 6.51917 25.5293C6.51917 26.0816 6.07145 26.5293 5.51917 26.5293H2.32959C1.79916 26.5293 1.29045 26.3186 0.915375 25.9435C0.5403 25.5684 0.32959 25.0597 0.32959 24.5293L0.329591 2.5293C0.329591 1.99887 0.540303 1.49016 0.915376 1.11508C1.29045 0.740011 1.79916 0.529297 2.32959 0.529297L19.3296 0.529298C19.8819 0.529298 20.3296 0.977013 20.3296 1.5293V9.21509H18.3296Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5192 7.21509C11.0714 7.21509 11.5192 7.6628 11.5192 8.21509L11.5192 17.2151C11.5192 17.7674 11.0715 18.2151 10.5192 18.2151C9.96688 18.2151 9.51917 17.7674 9.51917 17.2151L9.51917 8.21509C9.51917 7.6628 9.96688 7.21509 10.5192 7.21509Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5192 8.21509C19.5192 8.76737 19.0715 9.21509 18.5192 9.21509H1.51917C0.966882 9.21509 0.519166 8.76737 0.519166 8.21509C0.519166 7.6628 0.966882 7.21509 1.51917 7.21509H18.5192C19.0715 7.21509 19.5192 7.6628 19.5192 8.21509Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5192 14.2151C14.5192 14.7674 14.0714 15.2151 13.5192 15.2151H1.51916C0.966879 15.2151 0.519164 14.7674 0.519164 14.2151C0.519164 13.6628 0.966879 13.2151 1.51916 13.2151H13.5192C14.0714 13.2151 14.5192 13.6628 14.5192 14.2151Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.51917 20.2151C8.51917 20.7674 8.07145 21.2151 7.51917 21.2151H1.51917C0.96688 21.2151 0.519165 20.7674 0.519165 20.2151C0.519165 19.6628 0.96688 19.2151 1.51917 19.2151H7.51917C8.07145 19.2151 8.51917 19.6628 8.51917 20.2151Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.8121 10.508C20.2026 10.1175 20.8357 10.1175 21.2263 10.508L25.2263 14.508C25.6168 14.8985 25.6168 15.5317 25.2263 15.9222L13.2263 27.9222C13.0387 28.1097 12.7844 28.2151 12.5192 28.2151H8.51917C7.96688 28.2151 7.51917 27.7674 7.51917 27.2151V23.2151C7.51917 22.9499 7.62452 22.6955 7.81206 22.508L19.8121 10.508ZM9.51917 23.6293V26.2151H12.105L23.105 15.2151L20.5192 12.6293L9.51917 23.6293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8121 13.508C17.2026 13.1175 17.8357 13.1175 18.2263 13.508L22.2263 17.508C22.6168 17.8985 22.6168 18.5317 22.2263 18.9222C21.8357 19.3127 21.2026 19.3127 20.8121 18.9222L16.8121 14.9222C16.4215 14.5317 16.4215 13.8985 16.8121 13.508Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EditDataOutline
