import { Wizard } from '@invisible/ultron/zod'
import { FC } from 'react'

import { CohereOperateWAC } from './cohereOperate'
import { FinalReviewOperateWAC, FinalReviewQaOperateWAC, MenuUpdateReviewQA } from './grubhub'
import { KlarnaCompareWAC } from './KlarnaCompareWAC'
import { OpenAIInstructGPTOperateWAC } from './openAI'
import { RagRLHFWAC } from './RagRHLFWAC/'
import { RlhfOperateWAC } from './rlhfOperate'
import { RLHFSingleUser } from './rlhfSingleUser'
import { RLHFReview } from './rlhfSingleUser/Review'

// eslint-disable-next-line @typescript-eslint/ban-types
export const CustomWizardAtomicComponents: { [key in Wizard.WACType.TSchema]?: FC<any> } = {
  klarnaCompare: KlarnaCompareWAC,
  grubhubFinalReviewOperate: FinalReviewOperateWAC,
  grubhubFinalReviewQAOperate: FinalReviewQaOperateWAC,
  grubhubMenuUpdateReviewQA: MenuUpdateReviewQA,
  openAIInstructGPTOperate: OpenAIInstructGPTOperateWAC,
  rlhfOperate: RlhfOperateWAC,
  rlhfSingleUser: RLHFSingleUser,
  rlhfSingleUserReview: RLHFReview,
  cohereOperate: CohereOperateWAC,
  ragRLHF: RagRLHFWAC,
}
