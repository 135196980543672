import { classNames } from '@invisible/common/helpers'
import { useResizeObserver } from '@invisible/hooks/use-resize-observer'
import { Button } from '@invisible/ui/button'
import { DateRangePicker } from '@invisible/ui/date-range-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { SmallCheckbox } from '@invisible/ui/form'
import { IconDropdown } from '@invisible/ui/icon-dropdown'
import { FilledFilterIcon, Icons } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Pagination } from '@invisible/ui/pagination'
import { Skeleton } from '@invisible/ui/skeleton'
import * as Popover from '@radix-ui/react-popover'
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table as TTable,
  TableState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { isWithinInterval } from 'date-fns'
import { first } from 'lodash/fp'
import type {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from 'react'
import { createContext, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
  BulkActionContainer,
  CloseBulkAction,
  FloatingToolbar,
  SelectCounter,
} from './components/floatingToolbar'
import SortIcon from './SortIcon'
import {
  Cell,
  CellCopy,
  CellDate,
  CellIcon,
  CellLink,
  CellMoney,
  CellNameId,
  CellOnClick,
  CellTags,
  CellTime,
  CellUser,
} from './ui-table-cells'

type TableVariant = 'rounded' | 'square' | 'hasTabs'

const TableStyle = 'relative w-full'
const TableVariantStyle: Record<TableVariant, string> = {
  rounded: 'rounded-[5px]',
  square: 'rounded-none',
  hasTabs: 'rounded-tr-[5px] rounded-bl-[5px] rounded-br-[5px]',
}

const HeaderRowStyle = 'bg-theme-weak-3 sticky'
const HeaderCellStyle = 'border-t-theme-weak-2 border-x-theme-weak-2 bg-theme-weak-3 z-20'

const BodyRowStyle = 'even:bg-weak-2 odd:bg-void h-[45px] border-0'
const BodyCellStyle = ' bg-inherit '

type CssStyleProps = {
  style?: CSSProperties
  className?: string
  children: JSX.Element | JSX.Element[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TableComponent: FC<CssStyleProps> = ({ children, className, ...props }) => (
  <table className={`${TableStyle} ${className}`} {...props}>
    {children}
  </table>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderRow: FC<{ style?: CSSProperties; className?: string }> = ({
  children,
  className,
  ...props
}) => (
  <tr className={`${HeaderRowStyle} ${className}`} {...props}>
    {children}
  </tr>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderCell: FC<CssStyleProps> = ({ children, className, ...props }) => (
  <tr className={`${HeaderCellStyle} ${className}`} {...props}>
    {children}
  </tr>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const BodyRow: FC<{ style?: CSSProperties; className?: string }> = ({
  children,
  className,
  ...props
}) => (
  <tr className={`${BodyRowStyle} ${className}`} {...props}>
    {children}
  </tr>
)

const TableStyles = Object.assign(
  {},
  {
    TableStyle,
    HeaderRowStyle,
    HeaderCellStyle,
    BodyRowStyle,
    BulkActionContainer,
    CloseBulkAction,
    SelectCounter,
    BodyCellStyle,
    Table: TableComponent,
    HeaderRow,
    HeaderCell,
    BodyRow,
    SortIcon,
  }
)

declare module '@tanstack/table-core' {
  interface FilterFns {
    includes: FilterFn<unknown>
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    timezone?: string
    width?: string
    minWidth?: string
    maxWidth?: string
    left?: string
    right?: string
    position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
    Filter?: (arg: { column: Column<TData, TValue>; table: TTable<TData> }) => JSX.Element
    Selector?: () => JSX.Element
  }

  interface TableMeta<TData extends RowData> {
    updateBaseRunVariable?: ({
      baseRunVariableId,
      value,
    }: {
      baseRunVariableId: string
      value: any
    }) => void
    pageSize?: number
    currentPage?: number
  }
}

interface IBulkSelectActionProps {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}

type TablePageSize = 10 | 25 | 50 | 75 | 100

interface IProps<DataObject> {
  data: DataObject[]
  columns: ColumnDef<DataObject, any>[]
  isDataLoading?: boolean
  manualSorting?: boolean
  manualPagination?: boolean
  manualFiltering?: boolean
  itemCount?: number
  fixed?: boolean // table-layout: fixed
  variant?: 'rounded' | 'square' | 'hasTabs'
  showRowIndex?: boolean
  hiddenTopBorder?: boolean
  defaultPageSize?: TablePageSize
  onSortChange?: (arg: SortingState) => void
  onPaginationChange?: (arg: { pageSize: number; pageIndex: number }) => void
  onColumnFilterChange?: (arg: ColumnFiltersState) => void
  onRowClick?: (arg: DataObject, idx?: number) => void
  renderRowSubComponent?: ({ row }: { row: Row<DataObject> }) => JSX.Element
  initialState?: Partial<TableState>

  // eslint-disable-next-line @typescript-eslint/ban-types
  BulkSelectActions?: FC<IBulkSelectActionProps>
  tableMeta?: Record<string, any>
  stickyHeader?: boolean
  absoluteInset0?: boolean
  maxHeight?: string
}

type TTablePaginationContextState = {
  tableInstance: TTable<any> | null
  itemCount: number
}

interface TTablePaginationContext {
  state: TTablePaginationContextState
  setState: Dispatch<SetStateAction<TTablePaginationContextState>>
}

// Needed to share state between a table and its linked pagination
const TablePaginationContext = createContext<TTablePaginationContext>({
  state: {
    tableInstance: null,
    itemCount: -1,
  },
  setState: () => undefined,
})

const TablePaginationProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<TTablePaginationContextState>({
    tableInstance: null,
    itemCount: -1,
  })

  return (
    <TablePaginationContext.Provider value={{ state, setState }}>
      {children}
    </TablePaginationContext.Provider>
  )
}

type TTableTabState = string | null

type TTableTabContext = {
  selectedTab: TTableTabState
  setSelectedTab: Dispatch<SetStateAction<TTableTabState>>
}

const TableTabContext = createContext<TTableTabContext>({
  selectedTab: null,
  setSelectedTab: () => undefined,
})

const TableTabProvider = ({
  children,
  defaultTabValue = null,
}: {
  children: ReactNode
  defaultTabValue?: string | null
}) => {
  const [state, setState] = useState(defaultTabValue)

  useEffect(() => {
    setState(defaultTabValue)
  }, [defaultTabValue])

  return (
    <TableTabContext.Provider
      value={{
        selectedTab: state,
        setSelectedTab: setState,
      }}>
      {children}
    </TableTabContext.Provider>
  )
}

// Default array includes filter. Can be overwritten in each column definition
const includesFilter: FilterFn<any> = (row, columnId, value: string[]) => {
  if (!value.length) return true
  return value.includes(row.original?.[columnId])
}

// Filter function to be paired with a date picker
const inDateRangeFilter: FilterFn<any> = (row, columnId, value) => {
  if (!value) return true

  return isWithinInterval(new Date(row.original?.[columnId]), {
    start: value[0],
    end: value[1],
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BulkActionButton: FC<{
  title: string
  text?: string
  onClick: () => void
  icon: keyof typeof Icons
}> = ({ title, text, icon, onClick }) => (
  <div className='border-theme-main  flex h-10  items-center justify-center border-0  border-r-2 border-solid px-3'>
    <div
      className='hover:bg-weak-3 flex h-8 min-w-[32px] max-w-fit cursor-pointer items-center justify-center gap-2 rounded'
      title={title}
      onClick={onClick}>
      {Icons[icon]({ width: 14, height: 14 })}
      {title}
      {text ? <span className='inline-flex'>{text}</span> : null}
    </div>
  </div>
)

const Table = <DataObject,>({
  defaultPageSize,
  onSortChange,
  onPaginationChange,
  onColumnFilterChange,
  manualSorting,
  manualPagination,
  manualFiltering,
  itemCount,
  showRowIndex,
  fixed,
  columns,
  data,
  isDataLoading,
  variant = 'rounded',
  hiddenTopBorder = false,
  onRowClick,
  renderRowSubComponent,
  initialState,
  BulkSelectActions,
  tableMeta = {},
  stickyHeader,
  absoluteInset0,
  maxHeight,
}: IProps<DataObject>) => {
  const [sorting, setSorting] = useState<SortingState>(initialState?.sorting ?? [])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    initialState?.columnFilters || []
  )
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(
    initialState?.pagination ?? {
      pageIndex: 0,
      pageSize: defaultPageSize ?? 25,
    }
  )

  useEffect(() => {
    if (initialState?.pagination) {
      setPagination(initialState.pagination)
    }
  }, [initialState?.pagination])

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const { setState } = useContext(TablePaginationContext)

  const columnsWithHooks = useMemo(() => {
    const columnHelper = createColumnHelper<Record<string, any>>()

    const maybeAddRowIndexColumn = () => {
      if (!showRowIndex) {
        return []
      }
      return [
        columnHelper.display({
          id: 'index',
          header: '#',
          cell: (props) => <span className='text-paragraphs px-4'>{props.row.index + 1}</span>,
        }),
      ]
    }

    const maybeAddBulkSelectColumns = () => {
      if (!BulkSelectActions) {
        return []
      }

      return [
        columnHelper.accessor('selector', {
          header: ({ table }) => (
            <FloatingToolbar
              table={table}
              BulkSelectActionsComponent={BulkSelectActions}></FloatingToolbar>
          ),
          cell: ({ row }) => (
            <div className='px-4' title='Select Row'>
              <SmallCheckbox
                data-testid='select-row'
                checked={row.getIsSelected()}
                onClick={() => row.toggleSelected()}
              />
            </div>
          ),
          enableColumnFilter: false,
          enableSorting: false,
        }),
      ]
    }

    return [...maybeAddRowIndexColumn(), ...maybeAddBulkSelectColumns(), ...columns] as ColumnDef<
      DataObject,
      any
    >[]
  }, [BulkSelectActions, columns])

  const table = useReactTable<DataObject>({
    data,
    columns: columnsWithHooks,
    state: {
      columnFilters,
      sorting,
      pagination: useMemo(
        () => ({
          pageIndex,
          pageSize,
        }),
        [pageIndex, pageSize]
      ),
      rowSelection,
    },
    initialState,
    filterFns: {
      includes: includesFilter,
    },
    defaultColumn: {
      cell: (info) => (
        <Cell title={String(info.getValue())}>
          <Cell.Span>{String(info.getValue())}</Cell.Span>
        </Cell>
      ),
      filterFn: 'includes',
    },
    enableMultiRowSelection: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),

    manualSorting: manualSorting ?? false,
    manualPagination: manualPagination ?? false,
    manualFiltering: manualFiltering ?? false,
    ...(manualPagination ? { pageCount: itemCount ? Math.ceil(itemCount / pageSize) : -1 } : {}),

    // disable pagination state reset when data changes
    autoResetPageIndex: false,
    meta: {
      ...tableMeta,
    },
  })

  // Pushes state changes up to the provider
  useEffect(() => {
    setState({
      tableInstance: table,
      itemCount: itemCount ?? table.getFilteredRowModel().rows.length ?? data.length,
    })
  }, [data.length, itemCount, setState, JSON.stringify(table.getState())])

  // Calls provided function for manual sorting
  useEffect(() => {
    if (onSortChange && manualSorting) onSortChange(sorting)
  }, [sorting])

  // Calls provided function for manual filtering
  useEffect(() => {
    if (onColumnFilterChange && manualFiltering) onColumnFilterChange(columnFilters)
  }, [columnFilters])

  // Calls provided function for manual pagination
  useEffect(() => {
    if (onPaginationChange) onPaginationChange({ pageSize, pageIndex })
  }, [pageIndex, pageSize])

  return (
    <div
      className={`${TableVariantStyle[variant]} ${
        absoluteInset0 ? 'absolute inset-0' : ''
      } border-weak w-full ${fixed ? '' : 'overflow-auto'} border border-solid ${
        maxHeight ? 'scrollbar-hide' : ''
      }`}
      style={maxHeight ? { maxHeight } : undefined}>
      <table
        className={classNames(TableStyles.TableStyle, fixed ? 'table-fixed' : 'table-auto')}
        style={{ borderSpacing: 0 }}>
        <thead className={`h-14 ${stickyHeader ? 'sticky top-0 z-10' : ''}`}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={`${TableStyles.HeaderRowStyle} z-10`}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    ...header.column.columnDef.meta,
                  }}
                  className={TableStyles.HeaderCellStyle}>
                  {header.isPlaceholder ? null : (
                    <div className='flex items-center whitespace-nowrap py-3 px-4'>
                      <div className='text-theme-main text-left text-xs font-bold uppercase tracking-wide'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>

                      {header.column.getCanSort() ? (
                        <SortIcon
                          direction={header.column.getIsSorted() as 'asc' | 'desc'}
                          className='ml-2 h-5 shrink-0 cursor-pointer'
                          onClick={header.column.getToggleSortingHandler()}
                        />
                      ) : null}
                      {!header.column.getCanFilter() ? null : header.column.columnDef.meta
                          ?.Filter ? (
                        header.column.columnDef.meta?.Filter({
                          column: header.column,
                          table,
                        })
                      ) : (
                        <DefaultFilter column={header.column} />
                      )}
                      {header.column.columnDef.meta?.Selector &&
                        header.column.columnDef.meta.Selector()}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {isDataLoading ? (
          <tbody className='bg-void'>
            {[...new Array(10)].map((row, i) => (
              <tr key={i} className={`w-full ${TableStyles.BodyRowStyle}`}>
                {table.getHeaderGroups()[0].headers.map((row, index) => (
                  <td key={index}>
                    <Skeleton.Rectangle height={50} width={'100%'} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className='bg-void'>
            {table.getRowModel().rows.map((row, index) => (
              <Fragment key={row.id}>
                <tr
                  className={TableStyles.BodyRowStyle + `${onRowClick ? ' cursor-pointer' : ''}`}
                  onClick={() => onRowClick?.(row.original, index)}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      style={{
                        ...cell.column.columnDef.meta,
                      }}
                      key={cell.id}
                      className={classNames(
                        TableStyles.BodyCellStyle,
                        onRowClick ? 'hover:cursor-pointer' : ''
                      )}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {/* Reference: https://react-table-v7.tanstack.com/docs/examples/sub-components */}
                {row.getIsExpanded() ? (
                  <tr>
                    <td colSpan={row.getVisibleCells().length}>
                      {/*
                        Inside it, call our renderRowSubComponent function. In reality,
                        you could pass whatever you want as props to
                        a component like this, including the entire
                        table instance. But for now, we just
                        need the row.
                      */}
                      {renderRowSubComponent?.({ row }) ?? null}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        )}
      </table>
    </div>
  )
}

const TablePagination = () => {
  const { state } = useContext(TablePaginationContext)

  const { tableInstance: table, itemCount } = state

  if (!table) return null

  return (
    <div className='flex justify-end gap-3'>
      <div className='flex shrink-0 items-center gap-2'>
        <div>Showing</div>
        <Dropdown
          search={false}
          name='Items per page'
          placeholder='#'
          width='70px'
          dropdownWidth='70px'
          options={[10, 25, 30, 40, 50, 75].map((o) => ({
            key: String(o),
            value: o,
          }))}
          selectedKey={String(table.getState().pagination.pageSize)}
          onChange={({ value }) => table.setPageSize(Number(value))}
        />
        <div>items per page</div>
      </div>

      <div className='flex items-center justify-center'>
        <Pagination
          pages={table.getPageCount()}
          selectedPage={table.getState().pagination.pageIndex + 1 ?? 1}
          onClick={(page) => table.setPageIndex(page - 1)}
          alwaysVisible
        />
      </div>

      <div className='flex items-center gap-2'>
        <div>Page</div>
        <Dropdown
          name='Page'
          placeholder='1'
          maxHeight='400px'
          width='70px'
          dropdownWidth='120px'
          alignment='center'
          options={Array(table.getPageCount())
            .fill('_')
            .map((o, i) => ({
              key: String(i + 1),
              value: i + 1,
            }))}
          selectedKey={String(table.getState().pagination.pageIndex + 1 ?? 1)}
          onChange={({ value }) => table.setPageIndex(Number(value) - 1)}
        />
        <div>
          of {table.getPageCount()} ({itemCount} total items)
        </div>
      </div>
    </div>
  )
}

const TimezoneSelector = ({
  timezone,
  updateTimezone,
}: {
  timezone: string
  updateTimezone: (timezone: string) => void
}) => {
  const timezones = (Intl as any)?.supportedValuesOf('timeZone') ?? []
  return (
    <IconDropdown
      className={classNames(
        'text-theme-main !w-5 !justify-start bg-transparent p-1 hover:bg-transparent',
        timezone === 'UTC' ? 'opacity-25' : 'opacity-100'
      )}
      size='sm'
      search
      icon={timezone === 'UTC' ? 'GlobalOutlineIcon' : 'GlobalFilledIcon'}
      name='Timezones'
      dropdownWidth='200px'
      maxHeight='340px'
      options={[
        { key: 'UTC', value: 'UTC' },
        ...timezones.map((tz: { tz: string }) => ({
          key: tz,
          value: (tz as any).replaceAll('_', ' '),
        })),
      ]}
      selectedKey={timezone}
      onChange={({ key }) => updateTimezone(key)}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DefaultFilter: FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const filterValue = (column.getFilterValue() ?? []) as string[]
  const [searchInput, setSearchInput] = useState('')

  const sortedUniqueValues = useMemo(
    () =>
      Array.from(column.getFacetedUniqueValues()?.keys() ?? new Map().keys())
        .map((v) => String(v))
        .sort(),
    [column.getFacetedUniqueValues()]
  ) as string[]

  return (
    <Popover.Root>
      <Popover.Trigger className='ml-1 h-5 w-3 border-0 bg-transparent p-0'>
        <FilledFilterIcon
          className={classNames(
            'text-theme-main h-5 shrink-0 cursor-pointer',
            filterValue.length === 0 ? 'opacity-25' : 'opacity-100'
          )}
        />
      </Popover.Trigger>

      <Popover.Content className='border-weak bg-void z-30 h-60 w-52 overflow-auto rounded-md border border-solid py-2 shadow'>
        <Input
          value={searchInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value)}
          placeholder='Search...'
          className='px-2'
        />

        <div className='divide-weak border-weak mt-2  divide-y border-0 border-y border-solid'>
          {sortedUniqueValues
            .filter((v) => v.toLowerCase().includes(searchInput.toLowerCase()))
            .map((value) => (
              <div
                key={value}
                title={value}
                className='pointer box-border flex h-8 w-full items-center  truncate border-0 border-solid px-2 text-left font-normal leading-8'>
                <SmallCheckbox
                  onClick={(checked) => {
                    checked
                      ? column.setFilterValue((prev: string[]) => [...(prev ?? []), value])
                      : column.setFilterValue([])
                  }}
                  checked={filterValue.includes(value)}
                />
                {value}
              </div>
            ))}
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

interface IRange {
  startDate: Date
  endDate: Date
  key: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DateFilter: FC<{ column: Column<any, any> }> = ({ column }) => {
  const filterValue = column.getFilterValue() as [Date, Date] | null
  const [selectedDateRange, setSelectedDateRange] = useState<
    {
      startDate: Date | null
      endDate: Date
      key: string
    }[]
  >([{ startDate: null, endDate: new Date(''), key: 'selection' }])

  return (
    <Popover.Root>
      <Popover.Trigger className='ml-1 h-5 w-3 border-0 bg-transparent p-0'>
        <FilledFilterIcon
          className={classNames(
            'text-theme-main h-5 shrink-0 cursor-pointer',
            !filterValue ? 'opacity-25' : 'opacity-100'
          )}
        />
      </Popover.Trigger>

      <Popover.Content>
        {/* Hack to make date picker below show as it's absolutely positioned */}
        <div>&nbsp;</div>
        <div className='-ml-40'>
          <DateRangePicker
            closeOnClickOut={false}
            standalone
            isOpen={true}
            onClear={() => {
              column.setFilterValue(null)
              setSelectedDateRange([{ startDate: null, endDate: new Date(''), key: 'selection' }])
            }}
            ranges={selectedDateRange as IRange[]}
            onChange={(range) => {
              setSelectedDateRange(range)
              const { startDate, endDate } = first(range) as IRange
              column.setFilterValue([startDate, endDate])
            }}
          />
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

const TabGroup = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>
  const [scrollValue, setScrollValue] = useState(0)

  const [width] = useResizeObserver(ref)
  const containerWidth = useMemo(() => Math.ceil(width), [width])

  const showLeftIcon = scrollValue > 0
  const showRightIcon = Math.ceil(scrollValue) < ref?.current?.scrollWidth - containerWidth

  const TAB_WIDTH = 96
  const SPACE_BETWEEN_TABS = 4

  const handleLeftClick = () => {
    const tabIndexCutOnTheLeft = Math.floor(
      parseFloat((scrollValue / (TAB_WIDTH + SPACE_BETWEEN_TABS)).toFixed(2))
    )

    const maxNumberOfFullTabInView = Math.floor(containerWidth / (TAB_WIDTH + SPACE_BETWEEN_TABS))
    const scrollToValue =
      (tabIndexCutOnTheLeft - maxNumberOfFullTabInView) * (TAB_WIDTH + SPACE_BETWEEN_TABS)

    ref.current?.scrollTo(scrollToValue, 0)
  }
  const handleRightClick = () => {
    const tabIndexToScroll = Math.floor(
      (containerWidth + scrollValue) / (TAB_WIDTH + SPACE_BETWEEN_TABS)
    )
    const scrollToValue = tabIndexToScroll * (TAB_WIDTH + SPACE_BETWEEN_TABS)
    ref.current?.scrollTo(scrollToValue, 0)
  }
  const handleScroll = () => {
    const { scrollLeft } = ref.current
    setScrollValue(scrollLeft)
  }

  return (
    <div className='relative  flex h-12 overflow-x-auto overflow-y-hidden'>
      {showLeftIcon && (
        <div className='bg-void absolute -top-[1px] z-10 flex h-12 w-12 items-center'>
          <Button
            size='md'
            variant='secondary'
            icon='CaretLeftIcon'
            shape='square'
            onClick={handleLeftClick}
          />
        </div>
      )}

      {showRightIcon && (
        <div
          onClick={handleRightClick}
          className='bg-void absolute -top-[1px] right-0 z-10 box-border flex  h-12 w-12 items-center justify-center'>
          <Button
            size='md'
            variant='secondary'
            icon='CaretRightIcon'
            shape='square'
            onClick={handleRightClick}
          />
        </div>
      )}

      <div
        className='scrollbar-hide flex translate-y-[1px] gap-1 overflow-x-scroll scroll-smooth'
        ref={ref}
        onScroll={handleScroll}>
        {children}
      </div>
    </div>
  )
}

const TableHeader = ({
  children,
  justifyBetween,
}: {
  children: ReactNode
  justifyBetween?: boolean
}) => (
  <div className={`flex gap-2 ${justifyBetween ? 'justify-between' : ''} relative top-[1px] z-[1]`}>
    {children}
  </div>
)

type TableHeaderTabProps = {
  label: string
  value: string
  onClick?: (tab: { label: string; value: string }) => void
}

const TableHeaderTab = ({ label, value, onClick }: TableHeaderTabProps) => {
  const { selectedTab, setSelectedTab } = useContext(TableTabContext)

  const handleClick = () => {
    setSelectedTab(value)
    onClick?.({ label, value })
  }

  const isActive = value === selectedTab

  return (
    <div
      title={label}
      className={`hover:text-theme-main box-border flex h-12 w-24 flex-shrink-0 cursor-pointer items-center justify-center rounded-t-md border border-solid p-2 ${
        isActive ? 'bg-theme-weak-3 border-theme-weak-2 text-theme-main border-b-0' : 'border-weak'
      }`}
      onClick={handleClick}>
      <span className='overflow-hidden text-ellipsis whitespace-nowrap'>{label}</span>
    </div>
  )
}

const TableHeaderContainer = ({ children, tab }: { children: ReactNode; tab?: string }) => {
  const { selectedTab } = useContext(TableTabContext)
  if (selectedTab !== null && selectedTab !== tab) return null
  return <div className='flex items-center gap-2'>{children}</div>
}

const TableContainer = ({ children, tab }: { children: ReactNode; tab?: string }) => {
  const { selectedTab } = useContext(TableTabContext)

  if (selectedTab !== null && selectedTab !== tab) return null

  return <div className='relative flex w-full flex-grow flex-col gap-4'>{children}</div>
}

Table.TabProvider = TableTabProvider
TableHeader.TabGroup = TabGroup
TableHeader.Tab = TableHeaderTab
TableHeader.Container = TableHeaderContainer
Table.Header = TableHeader
Table.Container = TableContainer

Table.Pagination = TablePagination
Table.Filter = DefaultFilter
Table.Timezone = TimezoneSelector
Table.PaginationProvider = TablePaginationProvider
Table.DateFilter = DateFilter
Table.filterFns = {
  inDateRangeFilter,
  includesFilter,
}

Table.Cell = Cell
Table.CellDate = CellDate
Table.CellTime = CellTime
Table.CellMoney = CellMoney
Table.CellUser = CellUser
Table.CellNameId = CellNameId
Table.CellOnClick = CellOnClick
Table.CellIcon = CellIcon
Table.CellTags = CellTags
Table.CellCopy = CellCopy
Table.CellLink = CellLink

export { BulkActionButton, createColumnHelper, Table, TableStyles }
export type { Row, RowSelectionState, TablePageSize, Updater }
