// code sourced from jsonata repo(https://github.com/jsonata-js/jsonata-exerciser)
import jsonata, { Expression } from 'jsonata'

const timeboxExpression = (expr: Expression, timeout: number, maxDepth: number) => {
  let depth = 0
  const time = Date.now()

  const checkRunnaway = function () {
    if (depth > maxDepth) {
      // stack too deep
      // eslint-disable-next-line  no-throw-literal
      throw {
        code: 'U1001',
        message:
          'Stack overflow error: Check for non-terminating recursive function.  Consider rewriting as tail-recursive.',
        stack: new Error().stack,
      }
    }
    if (Date.now() - time > timeout) {
      // expression has run for too long
      // eslint-disable-next-line  no-throw-literal
      throw {
        code: 'U1002',
        message: 'Expression evaluation timeout: Check for infinite loop',
        stack: new Error().stack,
      }
    }
  }

  // register callbacks
  expr.assign('__evaluate_entry', function () {
    depth++
    checkRunnaway()
  })
  expr.assign('__evaluate_exit', function () {
    depth--
    checkRunnaway()
  })
}

const evalJsonata = async (input: Record<string, unknown>, rule: string) => {
  const expr = jsonata(rule)

  timeboxExpression(expr, 1000, 500)

  let expressionResult = await expr.evaluate(input, {})
  if (typeof expressionResult === 'undefined') {
    expressionResult = '** no match **'
  } else {
    expressionResult = JSON.stringify(
      expressionResult,
      function (key, val) {
        return typeof val !== 'undefined' && val !== null && val.toPrecision
          ? Number(val.toPrecision(13))
          : val && (val._jsonata_lambda === true || val._jsonata_function === true)
          ? '{function:' + (val.signature ? val.signature.definition : '') + '}'
          : typeof val === 'function'
          ? '<native function>#' + val.length
          : val
      },
      2
    )
  }
  return expressionResult
}

export { evalJsonata, timeboxExpression }
