import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { FC } from 'react'
import { Box, Flex, FlexProps } from 'rebass'

enum TextAlign {
  CENTER = ' center',
  LEFT = 'left',
  RIGHT = 'right',
}

interface IDivider extends Omit<FlexProps, 'textAlign'> {
  textAlign?: TextAlign
  dashed?: boolean
}

const LeftBox = styled(Box)``
const RightBox = styled(Box)``
const CustomBox = styled(Box)``

const FlexContainer = styled(Flex)<IDivider>`
  ${LeftBox}, ${RightBox}, ${CustomBox} {
    width: 100%;
    position: static;
    height: 0px;
    left: 0px;
    right: 0px;
    top: calc(50% - 0px / 2);
    border-bottom-width: 1px;
    border-bottom-color: ${gray(4)};
    border-bottom-style: ${({ dashed }) => (dashed ? 'dashed' : 'solid')};
  }
  ${LeftBox} {
    width: ${({ textAlign }) => (textAlign === TextAlign.LEFT ? '3%' : '100%')};
  }
  ${RightBox} {
    width: ${({ textAlign }) => (textAlign === TextAlign.RIGHT ? '3%' : '100%')};
  }
`

const CustomText = styled(Text)`
  position: static;
  width: 27px;
  height: 22px;
  left: 16px;
  top: 0px;
  min-width: fit-content;
  padding-left: 1%;
  padding-right: 1%;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const Divider: FC<IDivider> = (props) => {
  if (props.children) {
    return (
      <FlexContainer data-cy='divider' {...props} width='100%' alignItems='center'>
        <LeftBox />
        <CustomText data-cy='dividerText'>{props.children}</CustomText>
        <RightBox />
      </FlexContainer>
    )
  }
  return (
    <FlexContainer data-cy='divider' {...props} width='100%'>
      <CustomBox data-cy='dividerBox' />
    </FlexContainer>
  )
}

export { Divider }
