import { SVGProps } from 'react'

function TeamFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_5323_78056)'>
        <path
          d='M14.356 27.4637C14.356 27.4637 12.356 27.4637 12.356 25.5082C12.356 23.5526 14.356 17.686 22.356 17.686C30.356 17.686 32.356 23.5526 32.356 25.5082C32.356 27.4637 30.356 27.4637 30.356 27.4637H14.356ZM22.356 15.7304C23.9473 15.7304 25.4734 15.1123 26.5986 14.0121C27.7238 12.9119 28.356 11.4197 28.356 9.86374C28.356 8.3078 27.7238 6.81559 26.5986 5.71538C25.4734 4.61516 23.9473 3.99707 22.356 3.99707C20.7647 3.99707 19.2385 4.61516 18.1133 5.71538C16.9881 6.81559 16.356 8.3078 16.356 9.86374C16.356 11.4197 16.9881 12.9119 18.1133 14.0121C19.2385 15.1123 20.7647 15.7304 22.356 15.7304Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.20607 27.4637C9.94061 27.4637 10.4393 26.6947 10.3733 25.9794C10.3589 25.8231 10.3531 25.6658 10.356 25.5082C10.356 23.4083 11.21 21.2594 12.8146 19.5138C13.3565 18.9243 13.1503 17.9331 12.3483 17.8174C11.6898 17.7225 11.0236 17.6784 10.356 17.686C2.35596 17.686 0.355957 23.5526 0.355957 25.5082C0.355957 27.4637 2.35596 27.4637 2.35596 27.4637H9.20607Z'
          fill='currentColor'
        />
        <path
          d='M9.35596 15.7304C10.682 15.7304 11.9538 15.2153 12.8915 14.2985C13.8292 13.3816 14.356 12.1381 14.356 10.8415C14.356 9.5449 13.8292 8.30139 12.8915 7.38455C11.9538 6.4677 10.682 5.95263 9.35596 5.95263C8.02987 5.95263 6.75811 6.4677 5.82042 7.38455C4.88274 8.30139 4.35596 9.5449 4.35596 10.8415C4.35596 12.1381 4.88274 13.3816 5.82042 14.2985C6.75811 15.2153 8.02987 15.7304 9.35596 15.7304Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5323_78056'>
          <rect width='32' height='32' fill='white' transform='translate(0.355957)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TeamFilled
