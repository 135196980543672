import { SVGProps } from 'react'
function DocUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M41.8697 11.6616L30.6197 0.411563C30.489 0.281 30.334 0.177453 30.1633 0.106836C29.9926 0.0362197 29.8097 -8.35397e-05 29.625 1.44348e-07H9C8.12976 1.44348e-07 7.29516 0.345702 6.67981 0.961056C6.06445 1.57641 5.71875 2.41101 5.71875 3.28125V44.7188C5.71875 45.589 6.06445 46.4236 6.67981 47.0389C7.29516 47.6543 8.12976 48 9 48H39C39.8702 48 40.7048 47.6543 41.3202 47.0389C41.9356 46.4236 42.2812 45.589 42.2812 44.7188V12.6562C42.2813 12.4715 42.245 12.2886 42.1744 12.118C42.1038 11.9473 42.0002 11.7922 41.8697 11.6616ZM31.0312 4.80094L37.4803 11.25H31.0312V4.80094ZM39 45.1875H9C8.87568 45.1875 8.75645 45.1381 8.66854 45.0502C8.58064 44.9623 8.53125 44.8431 8.53125 44.7188V3.28125C8.53125 3.15693 8.58064 3.0377 8.66854 2.94979C8.75645 2.86189 8.87568 2.8125 9 2.8125H28.2188V12.6562C28.2188 13.0292 28.3669 13.3869 28.6306 13.6506C28.8944 13.9143 29.252 14.0625 29.625 14.0625H39.4688V44.7188C39.4688 44.8431 39.4194 44.9623 39.3315 45.0502C39.2435 45.1381 39.1243 45.1875 39 45.1875Z'
        fill='currentColor'
      />
      <path
        d='M25.0228 18.3469C24.8914 18.2075 24.7328 18.0966 24.557 18.0207C24.3811 17.9448 24.1916 17.9057 24 17.9057C23.8085 17.9057 23.6189 17.9448 23.4431 18.0207C23.2672 18.0966 23.1086 18.2075 22.9772 18.3469L15.0084 26.7844C14.7523 27.0556 14.6145 27.4175 14.6252 27.7904C14.6359 28.1633 14.7944 28.5167 15.0656 28.7728C15.3369 29.0289 15.6988 29.1668 16.0717 29.156C16.4446 29.1453 16.798 28.9869 17.0541 28.7156L22.5938 22.8497V39.9375C22.5938 40.3104 22.7419 40.6681 23.0056 40.9319C23.2694 41.1956 23.627 41.3437 24 41.3437C24.373 41.3437 24.7307 41.1956 24.9944 40.9319C25.2581 40.6681 25.4063 40.3104 25.4063 39.9375V22.8497L30.9459 28.7156C31.0728 28.8499 31.2248 28.958 31.3933 29.0335C31.5619 29.1091 31.7437 29.1507 31.9283 29.156C32.113 29.1613 32.2968 29.1302 32.4695 29.0645C32.6421 28.9987 32.8001 28.8996 32.9344 28.7728C33.0687 28.646 33.1767 28.494 33.2523 28.3254C33.3279 28.1569 33.3695 27.9751 33.3748 27.7904C33.3801 27.6058 33.349 27.4219 33.2832 27.2493C33.2175 27.0767 33.1184 26.9187 32.9916 26.7844L25.0228 18.3469Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocUpload
