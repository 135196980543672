import { useAbilityContext } from '@invisible/authorization/client'
import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { useQuery } from '@invisible/trpc/client'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Dropdown } from '@invisible/ui/dropdown'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { Pagination } from '@invisible/ui/pagination'
import { Table, TablePageSize } from '@invisible/ui/table'
import { ColumnDef, createColumnHelper, RowData } from '@tanstack/react-table'
import { FC, memo, useCallback, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { BaseRunDetails } from './baseViewComponents/BaseRunDetails'
import { HeaderRowSelector, RowSelector } from './baseViewComponents/BulkActions'
import { DEFAULT_ITEMS_PER_PAGE } from './common/constants'
import { TBaseRunQueryData } from './hooks/useGetBaseRuns'

export interface TModel extends Record<string, unknown> {
  [key: string]: TCellValue | unknown
}

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateBaseRunVariable?: ({
      baseRunVariableId,
      value,
    }: {
      baseRunVariableId: string
      value: any
    }) => void
    pageSize?: number
    currentPage?: number
  }
}

export type TBase = TProcessWithBases['bases'][number]

export interface TCellValue {
  actualValue: any
  baseRunId: string
  id: string
  isEditable: boolean
  type?: string
}

const columnHelper = createColumnHelper<TModel>()

export const rowNumbersHook = (
  columns: ColumnDef<TModel, Record<string, TCellValue> & Record<string, unknown>>[]
): ColumnDef<TModel, Record<string, TCellValue> & Record<string, unknown>>[] => [
  columnHelper.accessor('rowNumbers', {
    header: () => (
      <div
        className='text-theme-main flex px-3 text-left text-xs font-bold uppercase tracking-wide'
        style={{ width: '40px' }}>
        #
      </div>
    ),
    cell: (info) => {
      const { index } = info.row
      return (
        <Table.Cell justifyCenter>
          <BaseRunDetails baseRunId={info.row.original.id as string}>
            {((info.table.options.meta?.currentPage ?? 1) - 1) *
              (info.table.options.meta?.pageSize ?? DEFAULT_ITEMS_PER_PAGE) +
              (index + 1)}
          </BaseRunDetails>
        </Table.Cell>
      )
    },
  }),
  ...columns,
]

interface IProps {
  columns: ColumnDef<TModel>[]
  data: TModel[]
  base: TBase
  baseDataLoading: boolean
  itemCount: number
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BaseViewTable: FC<IProps> = ({ base, columns, data, baseDataLoading, itemCount }) => {
  const [, ability] = useAbilityContext()
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const {
    itemsPerPage,
    filterOption,
    sortOption,
    searchTerm,
    currentPage,
    filterableColumns,
    setItemCount,
    setPage,
  } = useStore(
    useCallback(
      (state) => ({
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        currentPage: state.currentPage,
        filterableColumns: state.filterableColumns,
        setItemCount: state.setItemCount,
        setPage: state.setPage,
      }),
      []
    ),
    shallow
  )

  const { mutateAsync: updateBaseRunVariableMutation } = useMutation('baseRunVariable.update', {
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onMutate: (variables) => {
      reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
        [
          'get-base-runs',
          {
            baseId: base.id,
            baseViewId,
            take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
            filters: filterOption ?? [],
            sort: sortOption ?? {},
            search: searchTerm ?? '',
            page: currentPage ?? 1,
          },
        ],
        (prevData) => {
          if (!prevData) return prevData

          const updatedBaseRunData = prevData.items.map((baseRun) => ({
            ...baseRun,
            baseRunVariables: baseRun.baseRunVariables.map((brv) => {
              if (brv.id === variables.id) {
                return { ...brv, value: variables.value }
              }
              return brv
            }),
          }))
          return {
            ...prevData,
            baseRuns: updatedBaseRunData,
          }
        }
      )
    },
  })

  useQuery(
    // we are just prefetching
    [
      'baseView.getColumnOptions',
      {
        baseId: base.id,
        filterableColumns,
      },
    ]
  )

  const updateBaseRunVariable = useCallback(
    ({ baseRunVariableId, value }: { baseRunVariableId: string; value: any }) => {
      updateBaseRunVariableMutation({ id: baseRunVariableId, value })
    },
    [updateBaseRunVariableMutation]
  )

  const columnsForBaseViewTable = useMemo(
    () => [
      ...(ability?.can('cancel', 'BaseRun')
        ? [
            columnHelper.display({
              id: ' selector',
              header: (info) => (
                <div className='text-theme-main flex text-left text-xs font-bold uppercase tracking-wide'>
                  <HeaderRowSelector
                    allBaseRunIds={info.table.options.data.map(({ id }) => id) as string[]}
                  />
                </div>
              ),
              cell: (info) => (
                <Table.Cell>
                  <RowSelector baseRunId={info.row.original.id as string} />
                </Table.Cell>
              ),
            }),
          ]
        : []),
      columnHelper.display({
        id: 'rowNumbers',
        header: () => (
          <div className='text-theme-main flex text-left text-xs font-bold uppercase tracking-wide'>
            #
          </div>
        ),
        cell: (info) => {
          const { index } = info.row
          return (
            <Table.Cell justifyCenter>
              <BaseRunDetails baseRunId={info.row.original.id as string}>
                {((currentPage ?? 1) - 1) * (itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE) + (index + 1)}
              </BaseRunDetails>
            </Table.Cell>
          )
        },
      }),
      ...columns,
    ],
    [ability, JSON.stringify(columns), currentPage, itemsPerPage]
  )

  const previousPageCount = useRef(1)
  const pageCount = useMemo(() => {
    const currentValue = Math.ceil(itemCount / (itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE))

    if (itemCount === 0 && baseDataLoading) return previousPageCount.current
    previousPageCount.current = currentValue
    return currentValue
  }, [itemCount, baseDataLoading, itemsPerPage])

  return (
    <ErrorBoundary>
      <div className='relative flex flex-grow flex-col'>
        <Table
          manualPagination
          absoluteInset0
          stickyHeader
          defaultPageSize={(itemsPerPage as TablePageSize) ?? DEFAULT_ITEMS_PER_PAGE}
          tableMeta={{
            pageSize: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
            currentPage,
            updateBaseRunVariable,
          }}
          variant='hasTabs'
          data={data}
          columns={columnsForBaseViewTable}
          isDataLoading={baseDataLoading}
        />
      </div>
      <div className='flex items-center gap-3 self-end'>
        <div className='flex items-center gap-2'>
          <div>Showing</div>
          <Dropdown
            name='Base runs'
            placeholder='10'
            width='70px'
            search={false}
            dropdownWidth='70px'
            options={[10, 25, 30, 40, 50, 75].map((o) => ({
              key: String(o),
              value: o,
            }))}
            selectedKey={String(itemsPerPage)}
            onChange={({ value }) => setItemCount(value as number)}
          />
          <div>items per page</div>
        </div>

        <Pagination
          pages={pageCount}
          selectedPage={currentPage ?? 1}
          onClick={(page) => setPage(page)}
        />

        <div className='flex items-center gap-2'>
          <div>Page</div>
          <Dropdown
            name='Base runs'
            placeholder='1'
            maxHeight='400px'
            width='70px'
            dropdownWidth='120px'
            alignment='center'
            options={Array(pageCount)
              .fill('_')
              .map((o, i) => ({
                key: String(i + 1),
                value: i + 1,
              }))}
            selectedKey={String(currentPage ?? 1)}
            onChange={({ value }) => setPage(value as number)}
          />
          <div>
            of {pageCount} ({itemCount} total items)
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

const MemoizedBaseViewTable = memo(BaseViewTable)

export { MemoizedBaseViewTable }
