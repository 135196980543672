import {
  IAttribute,
  IDataFilter,
  IFormattedReport,
  IInterval,
  TDateViewLevel,
  TFilterValue,
  TVisualizationType,
} from '@invisible/common/types'
import { useQuery } from '@invisible/trpc/client'
import { CHART_TYPE, FILTER_TYPE } from '@invisible/ui/constants'
import { ColorSchemeId } from '@nivo/colors'
import { flatten, flow, get, map } from 'lodash/fp'
import { useMemo } from 'react'
import { useDeepCompareEffect } from 'react-use'

import {
  COLOR_SCHEMES,
  findFilterByName,
  formatReportTitle,
  formatVisualizations,
  parseFilterColumnOptions,
  replaceThemeNamesByColorSchemes,
} from './helpers'

export interface IReportDataQuery {
  filters: IDataFilter<TFilterValue>[]
  reportId?: string
  config?: {
    dateViewLevel?: TDateViewLevel
    sharedColorTheme?: ColorSchemeId | 'bright'
    colorThemePerChart?: Partial<Record<TVisualizationType, ColorSchemeId | 'bright'>>
  }
}

export const useReportData = ({ filters, reportId, config }: IReportDataQuery) => {
  const {
    refetch: fetchReportData,
    data: report,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [
      'report.getReportData',
      {
        id: reportId ?? '',
        filters,
      },
    ],
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    }
  )

  const formattedReport = useMemo(() => {
    if (!report || isLoading) return

    const sharedColorScheme = config?.sharedColorTheme
      ? COLOR_SCHEMES[config.sharedColorTheme as never]
      : COLOR_SCHEMES.bright

    const colorSchemePerChart = config?.colorThemePerChart
      ? replaceThemeNamesByColorSchemes(config?.colorThemePerChart)
      : undefined

    const formattedVisualizations = formatVisualizations({
      visualizations: report.visualizations,
      sharedColorScheme,
      dateViewLevel: config?.dateViewLevel ?? 'daily',
      colorSchemePerChart,
    })

    const reportAttributeMappings: IAttribute[] = flow(
      map(get('attributesMapping')),
      flatten
    )(report.visualizations)

    const filterColumnOptions = parseFilterColumnOptions(reportAttributeMappings)

    return {
      ...report,
      reportTitle: formatReportTitle({
        title: String(report.reportTitle),
        data: report.visualizations.find((vis) => vis.type === CHART_TYPE.Table)?.data[0],
        interval: findFilterByName<IInterval>(FILTER_TYPE.Interval, filters)?.value,
      }),
      visualizations: {
        ...formattedVisualizations,
      },
      filterColumnOptions,
    } as IFormattedReport
  }, [
    report,
    isLoading,
    config?.sharedColorTheme,
    config?.colorThemePerChart,
    config?.dateViewLevel,
    filters,
  ])

  useDeepCompareEffect(() => {
    if (!reportId) return
    fetchReportData()
  }, [filters, reportId])

  return {
    isLoading: isFetching || isLoading,
    data: formattedReport,
    error: error,
  }
}
