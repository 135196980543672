import { format as formatDuration } from 'date-fns/fp'
import { round } from 'lodash'
import { isUndefined, map } from 'lodash/fp'

const hasDecimals = (n: number) => n % 1 !== 0

const formatCurrency = (n: any, showDecimals?: boolean) => {
  const rounded = round(n, 2)
  const decimals = showDecimals || hasDecimals(rounded) ? 2 : 0
  const amount = rounded.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  return amount.includes('-') ? amount.replace('-', '-$') : `$${amount}`
}

const CENTS_IN_DOLLAR = 100
const MILLICENTS_IN_DOLLAR = 1000

const formatCents = (n: number, showDecimals?: boolean) =>
  formatCurrency(n / CENTS_IN_DOLLAR, showDecimals)

const formatMillicents = (n: number) => {
  const amount = (n / MILLICENTS_IN_DOLLAR).toLocaleString('en-US', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  })
  return n >= 0
    ? `$${amount.charAt(amount.length - 1) === '0' ? amount.slice(0, -1) : amount}`
    : `-$${
        amount.charAt(amount.length - 1) === '0' ? amount.slice(1, -1) : amount.replace('-', '')
      }`
}

const formatToDollar = (n?: number) => {
  if (isUndefined(n)) return '$-'
  else
    return n >= 0
      ? `$${n.toLocaleString('en', { minimumFractionDigits: 2 })}`
      : `-$${Math.abs(n).toLocaleString('en', { minimumFractionDigits: 2 })}`
}

const formatTimer = (ms: number) => formatDuration('h[h]m[m]')(ms)

const convertCentsToDollars = (amountInCents: number): number => amountInCents / 100
const convertDollarsToCents = (amountInDollars: number): number => amountInDollars * 100
const convertMillicentsToDollars = (amountInMillicents: number): number => amountInMillicents / 1000

const formatHoursToDays = (hours: number, isLong?: boolean): string =>
  hours < 24
    ? `${hours}${isLong ? 'hours' : 'hrs'}`
    : `${Math.floor(hours / 24)}${isLong ? 'days' : 'd'}${' '}${
        hours % 24 > 0 ? (hours % 24).toFixed() + 'hrs' : ''
      }`

// @ts-expect-error lodash types doesn't include convert with cap: false
const mapWithKey = map.convert({ cap: false })

export {
  convertCentsToDollars,
  convertDollarsToCents,
  convertMillicentsToDollars,
  formatCents,
  formatCurrency,
  formatHoursToDays,
  formatMillicents,
  formatTimer,
  formatToDollar,
  mapWithKey,
}
