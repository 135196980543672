import { noop } from 'lodash/fp'
import { NextPage } from 'next'
import { createContext, PropsWithChildren, useCallback, useState } from 'react'

export const INPUT_DEBOUNCE_DELAY = 1000

export type TSaveStatus = 'saved' | 'saving' | 'failed'

const SAVE_STATUSES: Readonly<{ [k: string]: TSaveStatus }> = {
  SAVED: 'saved',
  SAVING: 'saving',
  FAILED: 'failed',
}

type PromisedFn = (options: any) => Promise<any>

export interface ISavesObject {
  [k: number]: TSaveStatus
}

interface MyContextValue {
  saves: ISavesObject
  addSaving: () => ISavesObject | undefined
  setSaved: (id: number | undefined) => void
  withSaveStatusHook: <T extends PromisedFn>(fn: T) => T
  fakeSavingForInputTyping: () => void
}

const TIMEOUT_SERVER = 30000

const uselessHOF = <T extends PromisedFn>(fn: T): T => {
  const innerFn: T = (async (innerOpts: any) => await fn(innerOpts)) as unknown as T
  return innerFn
}
const SaveStatus = createContext<MyContextValue>({
  saves: [],
  addSaving: () => undefined,
  setSaved: noop,
  withSaveStatusHook: uselessHOF,
  fakeSavingForInputTyping: noop,
})

let uniqueId = 0

const SaveStatusProvider = (props: PropsWithChildren<any>) => {
  const [saves, setSaves] = useState<ISavesObject>({})
  const addSaving = useCallback(() => {
    uniqueId += 1
    setSaves((saves) => ({ ...saves, [uniqueId]: SAVE_STATUSES.SAVING }))
    ;((uniqueId) =>
      setTimeout(() => {
        setSaves((saves) => {
          const saved = saves[uniqueId]
          const newSaves = { ...saves }
          if (saved === SAVE_STATUSES.SAVING) {
            newSaves[uniqueId] = SAVE_STATUSES.FAILED
          }
          return newSaves
        })
      }, TIMEOUT_SERVER))(uniqueId)
    return { id: uniqueId, status: SAVE_STATUSES.SAVING }
  }, [])
  const setSaved = useCallback((id: number | undefined) => {
    if (id !== undefined) {
      setSaves((saves) => {
        const newSaves = { ...saves }
        newSaves[id] = SAVE_STATUSES.SAVED
        return newSaves
      })
    }
  }, [])
  const withSaveStatusHook = <T extends PromisedFn>(fn: T): T => {
    const innerFn: T = (async (innerOpts: any) => {
      const saving = addSaving()
      const result = await fn(innerOpts)
      setSaved(saving.id)
      return result
    }) as unknown as T
    return innerFn
  }
  const fakeSavingForInputTyping = () => {
    const fakeSaving = addSaving()
    setTimeout(() => {
      setSaved(fakeSaving.id)
    }, INPUT_DEBOUNCE_DELAY + 200) // just for safety
  }

  const value = {
    saves,
    addSaving,
    setSaved,
    withSaveStatusHook,
    fakeSavingForInputTyping,
  }
  return <SaveStatus.Provider value={value as MyContextValue}>{props.children}</SaveStatus.Provider>
}

const withSaveStatusProvider = (Page: NextPage) => (props: any) =>
  (
    <SaveStatusProvider>
      <Page {...props} />
    </SaveStatusProvider>
  )

export { SAVE_STATUSES, SaveStatus, SaveStatusProvider, withSaveStatusProvider }
