import { SVGProps } from 'react'
function InputVar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={18}
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.726 4.429L18.298 9l-4.572 4.571M.998 9h17.143M8.765 4.43a8.078 8.078 0 11-.005 8.976'
        stroke='currentColor'
      />
    </svg>
  )
}

export default InputVar
