import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { classNames } from '@invisible/common/helpers'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard } from '@invisible/ultron/zod'
import { FC, useState } from 'react'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { Metadata } from './Metadata'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>

interface IProps {
  response: {
    id: string
    text: string
    model: string
    index: number
  }
  activePromptId: string
  editMode?: boolean
  baseRunVariables: TBaseRunVariables
  wacConfig: Wizard.WizardConfig.TSchema[number]['config']['cohereOperate']
  handleClose?: () => void
  stepRunId: string
}

type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

// eslint-disable-next-line @typescript-eslint/ban-types
const PromptResponse: FC<IProps> = ({
  response: { id, text, index, model },
  activePromptId,
  baseRunVariables,
  wacConfig,
  editMode = false,
  handleClose,
  stepRunId,
}) => {
  const reactQueryContext = useContext()
  const [isMetaDetailsOpen, setIsMetaDetailsOpen] = useState(true)
  const [isSelected, setIsSelected] = useState(editMode)
  const [responseText, setResponseText] = useState(text)
  const { dispatch } = useWizardState()

  const { mutateAsync: createBaseRun } = useBaseRunCreate()

  const { mutateAsync: updateVariable, isLoading } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: wacConfig?.responsesBaseId as string,
            parentBaseRunId: activePromptId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return prevData.map((b) =>
            b.id !== data[0].baseRunId
              ? b
              : {
                  ...b,
                  baseRunVariables: b.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
          )
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
  })

  const handleResponseSelection = async () => {
    const responseBaseRunId = await getOrCreateResponseBaseRunId(id)
    await updateVariable({
      stepRunId,
      data: [
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.promptResponseBaseVariableId as string,
          value: responseText,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.promptAcceptedModelBaseVariableId as string,
          value: model,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.responseIdBaseVariableId as string,
          value: responseBaseRunId,
        },
      ],
    })

    dispatch({
      type: 'setReadyForSubmit',
      key: 'cohereOperate',
      value: true,
    })
    handleClose?.()
  }

  const getOrCreateResponseBaseRunId = async (baseRunId?: string) => {
    if (baseRunId) return baseRunId

    const baseRun = await createBaseRun({
      baseId: wacConfig?.responsesBaseId as string,
      parentBaseRunId: activePromptId,
      stepRunId,
      initialValues: [
        {
          baseVariableId: wacConfig?.responseTextBaseVariableId as string,
          value: responseText,
        },
        {
          baseVariableId: wacConfig?.responseModelBaseVariableId as string,
          value: model,
        },
      ],
    })

    await updateVariable({
      stepRunId,
      data: [
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.responseIdBaseVariableId as string,
          value: baseRun.id,
        },
      ],
    })
    return baseRun.id
  }

  const handleUpdateBaseVariable = async (data: {
    baseRunId: string
    baseVariableId: string
    value: any
  }) => {
    const baseRunId = await getOrCreateResponseBaseRunId(id)
    await updateVariable({
      stepRunId,
      data: [
        {
          ...data,
          baseRunId,
        },
      ],
    })
  }
  return (
    <div className='bg-weak-3 border-main rounded-md border border-solid p-3'>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>Response {index}</div>
        {!isSelected ? (
          <Button size='sm' variant='secondary' onClick={() => setIsSelected(true)}>
            Select
          </Button>
        ) : (
          <Button size='sm' variant='primary' onClick={handleResponseSelection} loading={isLoading}>
            Save
          </Button>
        )}
      </div>
      <div
        className={classNames(
          'my-3 inline-block whitespace-pre-line outline-none',
          isSelected ? 'rounded border border-solid border-gray-300 bg-white p-2' : 'bg-inherit'
        )}
        contentEditable={isSelected}
        onInput={(e) => setResponseText(e?.currentTarget?.textContent ?? '')}>
        {text?.trim()}
      </div>

      <div>
        <h4 className='flex justify-between'>
          {' '}
          <span>Metadata info</span>{' '}
          <span
            className='cursor-pointer font-thin'
            onClick={() => setIsMetaDetailsOpen(!isMetaDetailsOpen)}>
            {isMetaDetailsOpen ? 'Hide' : 'Show'}
          </span>{' '}
        </h4>

        {isMetaDetailsOpen ? (
          <Metadata
            data={wacConfig?.responseMetadata}
            baseRunId={id}
            baseRunVariables={baseRunVariables}
            updateVariable={handleUpdateBaseVariable}
          />
        ) : null}
      </div>
    </div>
  )
}

export { PromptResponse }
