import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { EXPORT_STATUS_OPTIONS, exportStatusValues } from '../FilterConfiguration'

interface IProps {
  optionValues: ('generated' | 'approved' | 'rejected')[]
  updateBaseRunFilter: (key: string, value: unknown, isBaseRunStatus?: boolean) => void
}

const ExcludeBaseRunsByExportStatus = ({ optionValues, updateBaseRunFilter }: IProps) => (
  <Stack direction='row' alignItems='center' gap='16px'>
    <Stack direction='row' alignItems='center' gap={1} width='300px'>
      <Typography>Filter the data by export status </Typography>
      <Tooltip
        arrow
        title={
          <Typography fontSize='12px'>
            All previously exported base runs are included by default. To exclude any, uncheck the
            relevant statuses in the dropdown list.
          </Typography>
        }>
        <InfoOutlinedIcon sx={{ color: 'grey.500' }} />
      </Tooltip>
    </Stack>
    <Select
      multiple
      value={optionValues}
      displayEmpty
      onChange={(e) => {
        const value = e.target.value
        const selectedValues = typeof value === 'string' ? value.split(',') : value
        // create a new array by filtering out the selected values
        // as the api expects the excluded values
        const excludedValues = exportStatusValues.filter((v) => !selectedValues.includes(v))
        updateBaseRunFilter(
          'exported_base_run_status',
          excludedValues.length ? excludedValues : null
        )
      }}
      input={<OutlinedInput size='small' sx={{ width: '270px' }} />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <Typography color='text.disabled'>Select an export status (Optional)</Typography>
        }
        return selected
          .map((s) => EXPORT_STATUS_OPTIONS.find((o) => s === o.value)?.label)
          .join(', ')
      }}>
      {EXPORT_STATUS_OPTIONS.map((exportStatusOption) => (
        <MenuItem
          key={exportStatusOption.value}
          value={exportStatusOption.value}
          sx={{ p: 0, px: '4px' }}>
          <Checkbox checked={optionValues.indexOf(exportStatusOption.value) > -1} size='small' />
          <Tooltip
            title={<Typography fontSize='12px'>{exportStatusOption.toolTipText}</Typography>}
            arrow
            placement='right-end'>
            <ListItemText primary={exportStatusOption.label} />
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  </Stack>
)

export { ExcludeBaseRunsByExportStatus }
