import { isEmpty } from 'lodash/fp'
import { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

/* eslint-disable-next-line */
export interface UiHooksUseResizeObserverProps {}

const useResizeObserver = (ref: MutableRefObject<HTMLElement>, callback?: (entry: any) => void) => {
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (isEmpty(entries)) return

      const [{ contentRect }] = entries
      setWidth(contentRect.width)
      setHeight(contentRect.height)

      if (callback) {
        callback(contentRect)
      }
    },
    [callback]
  )

  useLayoutEffect(() => {
    if (!ref.current) return

    const RO = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        handleResize(entries)
      })
    })
    RO.observe(ref.current)

    return () => {
      RO.disconnect()
    }
  }, [ref])

  return [width, height]
}

export { useResizeObserver }
