const parseToNumber = (value: string) => parseInt(value.replace(/\D/g, ''), 10)

const parseToLocaleString = (value: number) =>
  value.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })

const getDurationInSeconds = (hour: string, minute: string) =>
  Number(hour) * 3600 + Number(minute) * 60

const getDurationInHours = (hour: string, minute: string) =>
  getDurationInSeconds(hour, minute) / 3600

const formatHourInputValue = (value: number) => Math.max(value, 0).toString()

const formatMinuteInputValue = (value: number) => Math.min(Math.max(value, 0), 59).toString() // Maximum minute input is 59 and minimum is 0

export {
  formatHourInputValue,
  formatMinuteInputValue,
  getDurationInHours,
  getDurationInSeconds,
  parseToLocaleString,
  parseToNumber,
}
