import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { classNames } from '@invisible/common/helpers'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { RobotHeadIcon, UserOutlineIcon } from '@invisible/ui/icons'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { compact } from 'lodash/fp'
import { FC, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunVariableFindManyByBaseRunId } from '../../hooks/useBaseRunVariableFindManyByBaseRunId'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { SingleTurn } from './SingleTurn'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  isReview?: boolean
  isReadOnly: boolean
}

const FAKE_PROMPT_ID = 'afb8d433-9e37-4ce0-aaab-e05bba558e03'

// eslint-disable-next-line @typescript-eslint/ban-types
const RLHFSingleUser: FC<IProps> = ({ baseRun, isReview, rlhfSingleUser, stepRun, isReadOnly }) => {
  const [inputText, setInputText] = useState('')
  const reactQueryContext = useContext()
  const { dispatch } = useWizardState()
  const config = rlhfSingleUser as NonNullable<WizardSchemas.RLHFSingleUser.TSchema>

  const { data: prompts } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config.promptsBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])

  const { mutateAsync: createBaseRun } = useBaseRunCreate({
    onMutate: async (data) => {
      await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.promptsBaseId,
            parentBaseRunId: baseRun.id,
          },
        ],
        (prevData) => {
          if (!prevData) return
          setInputText('')

          return [
            ...prevData,
            {
              id: FAKE_PROMPT_ID,
              baseId: config.promptsBaseId as string,
              createdAt: new Date(),
              totalCount: prevData.length + 1,
              baseRunVariables: [
                {
                  id: uuid(),
                  baseVariable: { id: config.promptTextBaseVariableId as string, name: 'Text' },
                  value: data.initialValues[0].value,
                },
                {
                  id: uuid(),

                  baseVariable: { id: config.promptIndexBaseVariableId as string, name: 'Index' },
                  value: data.initialValues[1].value,
                },
              ],
            },
          ]
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: createResponseBaseRun } = useBaseRunCreate({
    onMutate: async (data) => {
      await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.promptsBaseId,
            parentBaseRunId: baseRun.id,
          },
        ],
        (prevData) => {
          if (!prevData) return
          setInputText('')

          return prevData.map((b) =>
            b.id === data.parentBaseRunId
              ? {
                  ...b,
                  baseRunVariables: b.baseRunVariables.map((v) =>
                    v.baseVariable.id === config.promptResponseBaseVariableId
                      ? { ...v, value: data.initialValues[0].value }
                      : v
                  ),
                }
              : b
          )
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.promptsBaseId,
            parentBaseRunId: baseRun.id,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return prevData.map((baseRun) =>
            baseRun.id === data[0].baseRunId
              ? {
                  ...baseRun,
                  baseRunVariables: baseRun.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
              : baseRun
          )
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  // Parses prompts base runs into a typed object with its base run variables
  const normalizedPrompts = useMemo(
    () =>
      (prompts ?? [])
        .map((prompt) => ({
          id: prompt.id,
          text: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.promptTextBaseVariableId
          )?.value as string,
          index: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.promptIndexBaseVariableId
          )?.value as number,
          response: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.promptResponseBaseVariableId
          )?.value as string,

          responseId: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.responseIdBaseVariableId
          )?.value as string,
        }))
        .sort((a, b) => a.index - b.index),
    [
      config.promptIndexBaseVariableId,
      config.promptResponseBaseVariableId,
      config.promptTextBaseVariableId,
      config.responseIdBaseVariableId,
      prompts,
    ]
  )

  const responsesBaseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: compact(normalizedPrompts?.map((prompt) => prompt.responseId) ?? []),
  })

  const handlePromptSubmission = async () => {
    if (!inputText) return
    dispatch({
      type: 'setReadyForSubmit',
      key: 'rlhfSingleUser',
      value: false,
    })
    const mostRecentPrompt = normalizedPrompts?.[normalizedPrompts.length - 1]
    if (!mostRecentPrompt || mostRecentPrompt.response) {
      await createBaseRun({
        baseId: config.promptsBaseId as string,
        parentBaseRunId: baseRun.id,
        stepRunId: stepRun.id,
        initialValues: [
          {
            baseVariableId: config.promptTextBaseVariableId as string,
            value: inputText,
          },
          {
            baseVariableId: config.promptIndexBaseVariableId as string,
            value: mostRecentPrompt?.index + 1 ?? 1,
          },
        ],
      })
      return
    }
    if (mostRecentPrompt.id === FAKE_PROMPT_ID) return

    const response = await createResponseBaseRun({
      stepRunId: stepRun.id,
      baseId: config.responsesBaseId as string,
      parentBaseRunId: mostRecentPrompt.id,
      initialValues: [
        {
          baseVariableId: config.responseTextBaseVariableId as string,
          value: inputText,
        },
      ],
    })

    await updateVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: mostRecentPrompt.id,
          baseVariableId: config.promptResponseBaseVariableId as string,
          value: inputText,
        },
        {
          baseRunId: mostRecentPrompt.id,
          baseVariableId: config.responseIdBaseVariableId as string,
          value: response.id,
        },
      ],
    })
    dispatch({
      type: 'setReadyForSubmit',
      key: 'rlhfSingleUser',
      value: true,
    })
  }

  const isResponse =
    normalizedPrompts.length && !normalizedPrompts?.[normalizedPrompts.length - 1]?.response

  return (
    <div className='relative box-border flex h-full w-full flex-col justify-between gap-4 rounded-md border border-solid border-gray-300 bg-white p-3'>
      <div className='overflow-auto py-10'>
        {normalizedPrompts?.map((prompt, index) => (
          <SingleTurn
            promptId={prompt.id}
            text={prompt.text}
            response={prompt.response}
            key={prompt.id}
            index={index + 1}
            responseId={prompt.responseId}
            baseRunVariables={(responsesBaseRunVariables ?? []).filter(
              (b) => b.baseRunId === prompt.responseId
            )}
            config={config}
            conversationId={baseRun.id}
            stepRunId={stepRun.id}
            wizardIsReadOnly={isReadOnly}
          />
        ))}
      </div>
      <div className='flex items-center justify-between gap-6'>
        {!isReview ? (
          <div className='flex w-full items-center gap-2'>
            <div
              className={classNames(
                'flex h-7 w-7 items-center justify-center rounded-full p-1',
                isResponse ? 'bg-pink-300' : 'bg-primary'
              )}>
              {isResponse ? (
                <RobotHeadIcon className='h-6 w-6 text-white' />
              ) : (
                <UserOutlineIcon className='h-5 w-5 text-white' />
              )}
            </div>
            <TextArea
              rows={4}
              placeholder={isResponse ? 'Enter the response here...' : 'Enter your prompt here...'}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className='!w-[900px] resize-none !rounded'
              readOnly={isReadOnly}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.ctrlKey) {
                  handlePromptSubmission()
                }
              }}
            />
            <Button
              icon='RocketFilledIcon'
              size='md'
              variant='primary'
              shape='square'
              disabled={isReadOnly}
              onClick={handlePromptSubmission}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export { RLHFSingleUser }
