import { useSession } from '@invisible/authentication/client'
import { fromGlobalId, useMyCompaniesQuery } from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

type SegmentString = `/${string}`

export const useRedirect = (regex: RegExp, str: SegmentString, deps: boolean[]) => {
  const { push, asPath } = useRouter()

  if (deps.every((dep) => dep === true)) {
    const newPath = asPath.replace(regex, str)
    push(newPath)
  }
}

export const useRedirectFirstSegment = (str: SegmentString, deps: boolean[]) => {
  const regex = /^\/\w+/
  useRedirect(regex, str, deps)
}

export const useCompanyMenu = () => {
  const { data: session } = useSession()
  const isInternalUser = useMemo(
    () => {
      if (!session?.user?.email) return false
      return (session?.user?.email).endsWith('invisible.email')
    },
    [session?.user]
  )

  const { data: allCompaniesData, error: allCompaniesError } = useQuery(['company.findActiveCompanies'], { enabled: isInternalUser })
  const { data: myCompanyData, error: myCompaniesError } = useMyCompaniesQuery(undefined, { enabled: !isInternalUser })

  const standardizeCompanyDataForUser = () => {
    if (isInternalUser) {
      return {
        myCompanies: allCompaniesData?.map(company => ({
          id: company.id,
          name: company.name ?? '',
        }))
      }
    } else {
      return {
        myCompanies: [{
          id: fromGlobalId(myCompanyData?.me?.company?.id),
          name: myCompanyData?.me?.company?.name ?? '',
        }]
      }
    }
  }

  const data = standardizeCompanyDataForUser()
  
  const error = isInternalUser
    ? allCompaniesError
    : myCompaniesError
  
  return {
    data,
    error,
  }
}
