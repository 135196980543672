import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { useEffect } from 'react'

import { DisplayIssueCount } from './DisplayIssueCount'
import { useHighlightInputErrorsOnEvent } from './hooks/useHighlightInputErrorsOnEvent'
import { TIssueType, TMatch } from './types'
import { countMatchesPerIssueType, getUniqueMatches } from './utils/common'
import { highlightInputFieldErrors } from './utils/highlightInputFieldErrors'
import { highlightTextNodeErrors } from './utils/highlightTextNodeErrors'
type IProps = Omit<WizardSchemas.WACConfig.TSchema, 'value'> & {
  value: TMatch[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SpellcheckWAC = ({ value, showName, name }: IProps) => {
  const matches = value
  const uniqueMatches = getUniqueMatches(matches)

  const issueCountPerType = countMatchesPerIssueType({ matches })

  // Highlight the errors on the text nodes and input fields at an interval of 3 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      highlightTextNodeErrors({ matches: uniqueMatches })
      highlightInputFieldErrors({ matches: uniqueMatches })
    }, 3000)
    highlightTextNodeErrors({ matches: uniqueMatches })
    highlightInputFieldErrors({ matches: uniqueMatches })
    // Cleanup function to clear the interval on unmount
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  useHighlightInputErrorsOnEvent({ matches: uniqueMatches }) // Attach the event listeners

  return (
    <div className='box-border h-full overflow-auto rounded-lg border border-gray-400 bg-white p-2 shadow-sm'>
      {showName ? <div className='mb-2.5 font-bold'>{name}</div> : null}
      <div className='flex items-center gap-3'>
        {Object.entries(issueCountPerType).map(([issueType, count]) => (
          <DisplayIssueCount key={issueType} issueType={issueType as TIssueType} count={count} />
        ))}
      </div>
    </div>
  )
}

export * from './types'
export { DisplayIssueCount }
