import { z } from '@invisible/zod'

import { attributeNameEnum } from '../../../../enums'
import * as PrismaSchemas from '../../../PrismaModelsAsZodSchema'
// attributePropertiesSchema is zod implementation of IAttribute interface
import { schema as attributePropertiesSchema } from '../../Attribute'

const strictReportSchema = PrismaSchemas.ReportModel.omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
}).strict()

const strictReportVisualizationSchema = PrismaSchemas.ReportVisualizationModel.omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
}).strict()

const strictAttributePropertiesSchema = attributePropertiesSchema
  .omit({
    groupId: true,
    attributeId: true,
  })
  .strict()

const strictVisualizationAttributeSchema = z
  .object({
    name: attributeNameEnum,
    properties: strictAttributePropertiesSchema,
  })
  .strict()

const schema = strictReportSchema
  .extend({
    visualizations: z.array(
      strictReportVisualizationSchema.extend({
        attributes: z.array(strictVisualizationAttributeSchema),
      })
    ),
  })
  .strict()

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
