import { JsonSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

import { schema as BaseVariableMappingSchema } from './BaseVariableMapping'

/**
 * Trigger step metadata when expecting a payload.
 * payloadSchema is a JSON Schema object that can be used to validate the payload.
 */
const triggerWithPayloadSchema = z
  .object({
    baseVariableMappings: z.array(BaseVariableMappingSchema).default([]).optional(),
    payloadSchema: z.record(JsonSchema),
  })
  .strict()

/**
 * Trigger step metadata when not expecting a payload.
 */
const triggerWithoutPayloadSchema = z
  .object({
    baseVariableMappings: z.null(),
    payloadSchema: z.null(),
  })
  .strict()

/**
 * Trigger step metadata. Can either have both baseVariableMappings and payloadSchema set, or neither.
 */
const schema = z.union([triggerWithPayloadSchema, triggerWithoutPayloadSchema])

/**
 * Trigger step metadata
 *
 * payloadSchema is a JSON Schema object that can be used to validate the payload.
 * Trigger steps can optionally create a baseRun and/or a stepRun.
 * The stepRun and baseRun it creates would be determined by the step the trigger step points to via a stepGoTo.
 */
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates TriggerStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = z.object({
  baseVariableMappings: BaseVariableMappingSchema.optional(),
  payloadSchema: JsonSchema.optional(),
})
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates Trigger step meta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({
  triggerStepMeta,
  data,
}: {
  triggerStepMeta: TSchema
  data: TUpdateArgs
}): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(triggerStepMeta, updateArgs)
}

const RAW_PAYLOAD_KEY = 'RAW_PAYLOAD'

export {
  create,
  createArgsSchema,
  RAW_PAYLOAD_KEY,
  schema,
  triggerWithoutPayloadSchema,
  triggerWithPayloadSchema,
  update,
  updateArgsSchema,
}

export type { TCreateArgs, TSchema, TUpdateArgs }
