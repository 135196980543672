import { IVisualizationLegend } from '@invisible/common/types'
import { map } from 'lodash/fp'

interface ILegendsList {
  legends: IVisualizationLegend[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const LegendsList: React.FC<ILegendsList> = ({ legends }) => (
  <ul className='scrollbar-hide my-2 flex list-none flex-row justify-start gap-4 overflow-x-scroll p-0'>
    {map(
      (legend: IVisualizationLegend) => (
        <li
          key={legend.label}
          className='flex flex-row items-center justify-start gap-2 whitespace-nowrap'>
          <span style={{ backgroundColor: legend.color }} className='mb-px h-2 w-2 rounded-full' />
          <span className='text-sm text-gray-500'>{legend.label}</span>
        </li>
      ),
      legends
    )}
  </ul>
)
