import {
  fromGlobalId,
  toGlobalId,
  useBaseRunFindManyByParentsQuery,
} from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { PrismaAll } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Prisma } from '@prisma/client'
import { useQueryClient } from 'react-query'
import { useGate } from 'statsig-react'

// eslint-disable-next-line no-warning-comments
/**
 * TODO: to be removed when completely migrated to graphql
 * as of now baseRun.findManyByParents does not query stepRuns
 * but the return type contains step runs.
 */
type TManyBaseRunDataTRPC = NonNullable<inferQueryOutput<'baseRun.findManyByParents'>>
type TStepRun = TManyBaseRunDataTRPC[number]['stepRuns'][number]

type TManyBaseRunData = {
  status: PrismaAll.BaseRunStatus
  id: string
  createdAt: Date
  parentId: string | null
  baseRunVariables: {
    id: string
    baseVariableId: string
    value: Prisma.JsonValue
    baseRunId: string
    baseId: string
    createdAt: Date
    updatedAt: Date
    valueStr: string | null
  }[]
  stepRuns: TStepRun[]
  deliveredAt: Date | null
}[]

const useBaseRunFindManyByParents = ({
  parentIds,
  includeBaseVariableIds,
}: {
  parentIds: string[]
  includeBaseVariableIds: string[]
}): TManyBaseRunData => {
  const { value: isGraphQLEnabled } = useGate('enable-graphql-base-run-find-many-by-parents')
  const reactQueryClient = useQueryClient()

  const { data: trpcResult } = useTrpcQuery(
    ['baseRun.findManyByParents', { parentIds, includeBaseVariableIds }],
    { enabled: !isGraphQLEnabled }
  )

  const { data: graphqlResult } = useBaseRunFindManyByParentsQuery(
    {
      parentIds: parentIds.map((id) => toGlobalId('BaseRunType', id)),
      baseVariableIds: includeBaseVariableIds.map((id) => toGlobalId('BaseVariableType', id)),
    },
    {
      enabled: isGraphQLEnabled,
      onSettled: () => {
        reactQueryClient.invalidateQueries('BaseRunFindManyByParents')
        reactQueryClient.invalidateQueries('baseRun.findManyByParents')
      },
    }
  )

  const data = isGraphQLEnabled
    ? graphqlResult?.baseRuns.edges.map((edge) => ({
        id: fromGlobalId(edge.node.id),
        parentId: fromGlobalId(edge.node.parent?.id),
        status: fromGlobalId(edge.node.status) as PrismaAll.BaseRunStatus,
        createdAt: new Date(edge.node.createdAt),
        deliveredAt: new Date(edge.node.deliveredAt),
        baseRunVariables: edge.node.baseRunVariables.map((brv) => ({
          id: fromGlobalId(brv.id),
          baseRunId: fromGlobalId(brv.baseRun.id),
          baseId: fromGlobalId(brv.base.id),
          baseVariableId: fromGlobalId(brv.baseVariable.id),
          createdAt: new Date(brv.createdAt),
          updatedAt: new Date(brv.updatedAt),
          value: brv.value as Prisma.JsonValue,
          valueStr: brv.valueStr as string | null,
        })),
        stepRuns: [],
      }))
    : trpcResult
  return data ?? []
}

export { useBaseRunFindManyByParents }
