import { SVGProps } from 'react'

function SkillsRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.75 13.375H23.375C23.5408 13.375 23.6997 13.4408 23.8169 13.5581C23.9342 13.6753 24 13.8342 24 14C24 14.1658 23.9342 14.3247 23.8169 14.4419C23.6997 14.5592 23.5408 14.625 23.375 14.625H22.75V17.125C22.75 17.4565 22.6183 17.7745 22.3839 18.0089C22.1495 18.2433 21.8315 18.375 21.5 18.375H20.25V19C20.25 19.3315 20.1183 19.6495 19.8839 19.8839C19.6495 20.1183 19.3315 20.25 19 20.25H17.125C16.7935 20.25 16.4755 20.1183 16.2411 19.8839C16.0067 19.6495 15.875 19.3315 15.875 19V14.625H12.125V19C12.125 19.3315 11.9933 19.6495 11.7589 19.8839C11.5245 20.1183 11.2065 20.25 10.875 20.25H9C8.66848 20.25 8.35054 20.1183 8.11612 19.8839C7.8817 19.6495 7.75 19.3315 7.75 19V18.375H6.5C6.16848 18.375 5.85054 18.2433 5.61612 18.0089C5.3817 17.7745 5.25 17.4565 5.25 17.125V14.625H4.625C4.45924 14.625 4.30027 14.5592 4.18306 14.4419C4.06585 14.3247 4 14.1658 4 14C4 13.8342 4.06585 13.6753 4.18306 13.5581C4.30027 13.4408 4.45924 13.375 4.625 13.375H5.25V10.875C5.25 10.5435 5.3817 10.2255 5.61612 9.99112C5.85054 9.7567 6.16848 9.625 6.5 9.625H7.75V9C7.75 8.66848 7.8817 8.35054 8.11612 8.11612C8.35054 7.8817 8.66848 7.75 9 7.75H10.875C11.2065 7.75 11.5245 7.8817 11.7589 8.11612C11.9933 8.35054 12.125 8.66848 12.125 9V13.375H15.875V9C15.875 8.66848 16.0067 8.35054 16.2411 8.11612C16.4755 7.8817 16.7935 7.75 17.125 7.75H19C19.3315 7.75 19.6495 7.8817 19.8839 8.11612C20.1183 8.35054 20.25 8.66848 20.25 9V9.625H21.5C21.8315 9.625 22.1495 9.7567 22.3839 9.99112C22.6183 10.2255 22.75 10.5435 22.75 10.875V13.375ZM7.75 10.875H6.5V17.125H7.75V10.875ZM20.25 17.125H21.5V10.875H20.25V17.125Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40609 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50335 20.9221 7.21717 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SkillsRibbon
