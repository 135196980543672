import { UuidSchema, z } from '@invisible/zod'

import {
  FORMULA_ARGUMENT_TYPES,
  FormulaNamesSchema,
  FormulaResultTypesSchema,
  FormulaValueTypesSchema,
} from '../../constants/formula'

/**
 * A constant argument in a formula.
 */
const constantArgSchema = z
  .object({
    type: z.literal(FORMULA_ARGUMENT_TYPES.CONSTANT),
    resultType: FormulaResultTypesSchema,
    value: z.string(),
    valueType: FormulaValueTypesSchema,
  })
  .strict()

/**
 * A constant argument in a formula.
 */
type TConstantArg = z.infer<typeof constantArgSchema>

/**
 * A variable argument in a formula, referencing a base variable.
 */
const variableArgSchema = z
  .object({
    type: z.literal(FORMULA_ARGUMENT_TYPES.VARIABLE),
    baseVariableId: UuidSchema,
    resultType: FormulaResultTypesSchema,
    valueType: FormulaValueTypesSchema,
  })
  .strict()
/**
 * A variable argument in a formula, referencing a base variable.
 */
type TVariableArg = z.infer<typeof variableArgSchema>

/**
 * An argument in a formula.
 */
const formulaArgSchema = z.union([constantArgSchema, variableArgSchema])

/**
 * An argument in a formula.
 */
type TFormulaArg = z.infer<typeof formulaArgSchema>

/**
 * A formula. Implementation of the execution is tied to the formula name.
 */
const formulaSchema = z.object({
  name: FormulaNamesSchema,
  args: formulaArgSchema.array(),
  resultType: FormulaResultTypesSchema,
})

/**
 * A formula. Implementation of the execution is tied to the formula name.
 */
type TFormula = z.infer<typeof formulaSchema>

const isConstantArg = (v: TFormulaArg): v is TConstantArg =>
  v.type === FORMULA_ARGUMENT_TYPES.CONSTANT
const isVariableArg = (v: TFormulaArg): v is TVariableArg =>
  v.type === FORMULA_ARGUMENT_TYPES.VARIABLE

export {
  constantArgSchema,
  formulaArgSchema,
  formulaSchema,
  isConstantArg,
  isVariableArg,
  variableArgSchema,
}

export type { TConstantArg, TFormula, TFormulaArg, TVariableArg }
