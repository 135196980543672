import { SVGProps } from 'react'

function Archive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 2.42857C0 1.63959 0.639593 1 1.42857 1H14.5714C15.3604 1 16 1.63959 16 2.42857V3.57143C16 4.27357 15.4934 4.85741 14.8258 4.97741L14.3796 12.5628C14.3086 13.7709 13.3081 14.7143 12.0979 14.7143H3.90212C2.69189 14.7143 1.69142 13.7709 1.62035 12.5628L1.17415 4.97741C0.506556 4.85741 0 4.27357 0 3.57143V2.42857ZM1.71929 3.85714C1.71631 3.85712 1.71332 3.85712 1.71033 3.85714H1.42857C1.27078 3.85714 1.14286 3.72922 1.14286 3.57143V2.42857C1.14286 2.27078 1.27078 2.14286 1.42857 2.14286H14.5714C14.7292 2.14286 14.8571 2.27078 14.8571 2.42857V3.57143C14.8571 3.72922 14.7292 3.85714 14.5714 3.85714H14.2897C14.2867 3.85712 14.2837 3.85712 14.2807 3.85714H1.71929ZM2.32032 5L2.76124 12.4957C2.79677 13.0998 3.29701 13.5714 3.90212 13.5714H12.0979C12.703 13.5714 13.2032 13.0998 13.2388 12.4957L13.6797 5H2.32032ZM5.90458 7.28571C5.90458 6.97012 6.16041 6.71429 6.476 6.71429H9.52362C9.83922 6.71429 10.0951 6.97012 10.0951 7.28571C10.0951 7.60131 9.83922 7.85714 9.52362 7.85714H6.476C6.16041 7.85714 5.90458 7.60131 5.90458 7.28571Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Archive
