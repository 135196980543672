import { SVGProps } from 'react'
function PenAndPaper(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8475 0.9875C13.0974 0.737565 13.5026 0.737565 13.7526 0.9875L16.3126 3.5475C16.5625 3.79743 16.5625 4.20266 16.3126 4.45259L8.63258 12.1326C8.51255 12.2526 8.34977 12.32 8.18003 12.32H5.62003C5.26657 12.32 4.98003 12.0335 4.98003 11.68V9.12004C4.98003 8.9503 5.04746 8.78751 5.16749 8.66749L10.9205 2.91451L10.9272 2.90764L10.9341 2.90087L12.8475 0.9875ZM6.26003 9.38513L11.3798 4.26533L13.0347 5.92023L7.91493 11.04H6.26003V9.38513ZM14.9549 4.00004L13.9398 5.01514L12.2849 3.36024L13.3 2.34514L14.9549 4.00004ZM1.78 1.4401C1.44052 1.4401 1.11495 1.57495 0.874903 1.815C0.634857 2.05504 0.5 2.38062 0.5 2.72009V15.5201C0.5 15.8595 0.634857 16.1851 0.874903 16.4252C1.11495 16.6652 1.44052 16.8001 1.78 16.8001H14.58C14.9195 16.8001 15.245 16.6652 15.4851 16.4252C15.7251 16.1851 15.86 15.8595 15.86 15.5201V8.48008C15.86 8.12662 15.5734 7.84009 15.22 7.84009C14.8665 7.84009 14.58 8.12662 14.58 8.48008V15.5201H1.78L1.78 2.72009L8.81999 2.72009C9.17345 2.72009 9.45999 2.43356 9.45999 2.08009C9.45999 1.72663 9.17345 1.4401 8.81999 1.4401H1.78Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PenAndPaper
