import { SVGProps } from 'react'
function DataSource(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={46}
      height={48}
      viewBox='0 0 46 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M3.238 30H29.67l.745-.75 1.323-1.332 1.915-1.929V2.562A2.558 2.558 0 0031.109 0H2.544A2.558 2.558 0 000 2.562v38.33a2.558 2.558 0 002.544 2.561h17.62l.351-3.26H3.238V30zm0-3.26v-9.746h27.177v9.745H3.238zM30.415 3.26v10.473H3.238V3.26h27.177z'
        fill='currentColor'
      />
      <path
        d='M8.724 10.458a1.871 1.871 0 001.864-1.877 1.868 1.868 0 00-1.864-1.878A1.868 1.868 0 006.86 8.581c0 1.039.837 1.877 1.864 1.877zM8.724 19.966a1.871 1.871 0 00-1.864 1.877c0 1.039.833 1.878 1.864 1.878a1.868 1.868 0 001.864-1.878 1.871 1.871 0 00-1.864-1.877zM8.724 37.262a1.87 1.87 0 001.864-1.877 1.87 1.87 0 00-1.864-1.877 1.87 1.87 0 00-1.864 1.877 1.87 1.87 0 001.864 1.877zM38.917 25.3L23.726 40.598l-.546 5.091c-.065.61.134 1.216.55 1.659.394.42.935.652 1.504.652h.106l5.394-.27 15.228-15.336-7.045-7.094zm-9.594 19.23l-2.78.14.277-2.581 12.092-12.177 2.461 2.478-12.05 12.14z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DataSource
