import { SVGProps } from 'react'

function CopyFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.8515 0H6.85148C6.49786 0 6.15872 0.140476 5.90867 0.390525C5.65862 0.640573 5.51815 0.979711 5.51815 1.33333C5.51815 1.68696 5.65862 2.02609 5.90867 2.27614C6.15872 2.52619 6.49786 2.66667 6.85148 2.66667H29.5181V25.3333C29.5181 25.687 29.6586 26.0261 29.9087 26.2761C30.1587 26.5262 30.4979 26.6667 30.8515 26.6667C31.2051 26.6667 31.5442 26.5262 31.7943 26.2761C32.0443 26.0261 32.1848 25.687 32.1848 25.3333V1.33333C32.1848 0.979711 32.0443 0.640573 31.7943 0.390525C31.5442 0.140476 31.2051 0 30.8515 0Z'
        fill='currentColor'
      />
      <path
        d='M25.5181 5.33333H1.51815C0.781768 5.33333 0.184814 5.93029 0.184814 6.66667V30.6667C0.184814 31.403 0.781768 32 1.51815 32H25.5181C26.2545 32 26.8515 31.403 26.8515 30.6667V6.66667C26.8515 5.93029 26.2545 5.33333 25.5181 5.33333Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CopyFilled
