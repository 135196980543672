import { z } from '@invisible/zod'

import { predicateSchema } from '../predicate'
import { stringArgSchema } from './args'

/**
 * Formula that takes 1 or more string arguments and returns a boolean.
 */
const stringPredicateSchema = predicateSchema
  .omit({ args: true })
  .extend({ args: stringArgSchema.array().min(1) })
  .strict()

/**
 * Formula that takes 1 or more string arguments and returns a boolean.
 */
type TStringPredicate = z.infer<typeof stringPredicateSchema>

/**
 * Formula that takes exactly two string arguments and returns a boolean.
 */
const stringComparisonSchema = stringPredicateSchema
  .omit({ args: true })
  .extend({ args: stringArgSchema.array().length(2) })
  .strict()

/**
 * Formula that takes exactly two string arguments and returns a boolean.
 */
type TStringComparison = z.infer<typeof stringComparisonSchema>

/**
 * Formula that takes exactly one string argument and returns a boolean.
 */
const stringUnaryPredicateSchema = stringPredicateSchema
  .omit({ args: true })
  .extend({ args: stringArgSchema.array().length(1) })
  .strict()

/**
 * Formula that takes exactly one string argument and returns a boolean.
 */
type TStringUnaryPredicate = z.infer<typeof stringUnaryPredicateSchema>

export { stringComparisonSchema, stringPredicateSchema, stringUnaryPredicateSchema }
export type { TStringComparison, TStringPredicate, TStringUnaryPredicate }
