import { classNames } from '@invisible/common/helpers'
import { CaretBackIcon, RotateRightIcon } from '@invisible/ui/icons'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Electron {
  interface DidStartNavigationEvent {
    isMainFrame: boolean
    url: string
  }

  interface WebviewTag {
    canGoBack(): boolean
    canGoForward(): boolean
    goBack(): void
    goForward(): void
    getWebContentsId(): number
    reload(): void
    addEventListener(
      event: 'did-start-navigation' | 'dom-ready',
      listener: (event: DidStartNavigationEvent) => void
    ): void
    removeEventListener(
      event: 'did-start-navigation' | 'dom-ready',
      listener: (event: DidStartNavigationEvent) => void
    ): void
  }
}

interface IProps {
  tabId: string
  protocol: string
  initialURL: string
  height: number
}

const EmbedWindow = ({ tabId, protocol, initialURL, height }: IProps) => {
  const ref = useRef<Electron.WebviewTag>(null)
  const [url, setUrl] = useState('')
  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoForward, setCanGoForward] = useState(false)

  const handleDidStartNavigation = useCallback((event: Electron.DidStartNavigationEvent) => {
    if (!event.isMainFrame) return

    setUrl(event.url)
    setCanGoBack(ref.current?.canGoBack() ?? false)
    setCanGoForward(ref.current?.canGoForward() ?? false)
  }, [])

  const handleDomReady = useCallback(() => {
    const webview = ref.current
    if (!webview) return

    const id = webview.getWebContentsId()
    const electronAPI = (window as any).electronAPI as Record<string, unknown>
    if (typeof electronAPI?.openWindow === 'function') electronAPI.openWindow(id)
  }, [])

  useEffect(() => {
    const node = ref.current
    if (!node) return
    node.addEventListener('did-start-navigation', handleDidStartNavigation)
    node.addEventListener('dom-ready', handleDomReady)

    return () => {
      node.removeEventListener('did-start-navigation', handleDidStartNavigation)
      node.removeEventListener('dom-ready', handleDomReady)
    }
  }, [handleDidStartNavigation, handleDomReady])

  return (
    <>
      <div className='my-1 flex items-center gap-2'>
        <div className='flex items-center gap-2'>
          <CaretBackIcon
            onClick={() => ref?.current?.goBack()}
            height={16}
            width={16}
            className={classNames(canGoBack ? 'cursor-pointer text-gray-500' : 'text-gray-200')}
          />
          <RotateRightIcon
            onClick={() => ref?.current?.reload()}
            height={16}
            width={16}
            className='cursor-pointer text-gray-500'
          />
          <CaretBackIcon
            onClick={() => ref?.current?.goForward()}
            height={16}
            width={16}
            className={classNames(
              'rotate-180',
              canGoForward ? 'cursor-pointer text-gray-500' : 'text-gray-200'
            )}
          />
        </div>
        <div className='rounded border border-solid border-gray-300 p-2 text-gray-500' title={url}>
          {url}
        </div>
      </div>

      <div className='h-full'>
        {/*  Electron webview to provide a more reliable embed experience */}
        <webview
          src={`${protocol}${initialURL}`}
          //@ts-expect-error it doesn't work when set to true as a boolean
          allowpopups='true'
          ref={ref as unknown as RefObject<HTMLWebViewElement>}
          id={tabId}
          style={{ height }}
        />
      </div>
    </>
  )
}

export { EmbedWindow }
