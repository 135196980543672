import { SVGProps } from 'react'
function DumbellFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='20'
      viewBox='0 0 32 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31 9H30V5C30 4.46957 29.7893 3.96086 29.4142 3.58579C29.0391 3.21071 28.5304 3 28 3H26V2C26 1.46957 25.7893 0.960859 25.4142 0.585786C25.0391 0.210714 24.5304 0 24 0H21C20.4696 0 19.9609 0.210714 19.5858 0.585786C19.2107 0.960859 19 1.46957 19 2V9H13V2C13 1.46957 12.7893 0.960859 12.4142 0.585786C12.0391 0.210714 11.5304 0 11 0H8C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V9H1C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0 10.2652 0.105357 10.5196 0.292893 10.7071C0.48043 10.8946 0.734784 11 1 11H2V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H6V18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H11C11.5304 20 12.0391 19.7893 12.4142 19.4142C12.7893 19.0391 13 18.5304 13 18V11H19V18C19 18.5304 19.2107 19.0391 19.5858 19.4142C19.9609 19.7893 20.4696 20 21 20H24C24.5304 20 25.0391 19.7893 25.4142 19.4142C25.7893 19.0391 26 18.5304 26 18V17H28C28.5304 17 29.0391 16.7893 29.4142 16.4142C29.7893 16.0391 30 15.5304 30 15V11H31C31.2652 11 31.5196 10.8946 31.7071 10.7071C31.8946 10.5196 32 10.2652 32 10C32 9.73478 31.8946 9.48043 31.7071 9.29289C31.5196 9.10536 31.2652 9 31 9ZM4 15V5H6V15H4ZM28 15H26V5H28V15Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DumbellFilled
