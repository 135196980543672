import { SVGProps } from 'react'
function PlusCamera(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.2578 2.22265C4.35054 2.08355 4.50665 2 4.67383 2H6.67383C6.94997 2 7.17383 2.22386 7.17383 2.5C7.17383 2.77614 6.94997 3 6.67383 3H4.94142L4.08985 4.27735C3.99712 4.41645 3.841 4.5 3.67383 4.5H1.67383C1.54122 4.5 1.41404 4.55268 1.32027 4.64645C1.22651 4.74022 1.17383 4.86739 1.17383 5V12C1.17383 12.1326 1.22651 12.2598 1.32027 12.3536C1.41404 12.4473 1.54122 12.5 1.67383 12.5H11.6738C11.8064 12.5 11.9336 12.4473 12.0274 12.3536C12.1212 12.2598 12.1738 12.1326 12.1738 12V5.5C12.1738 5.22386 12.3977 5 12.6738 5C12.95 5 13.1738 5.22386 13.1738 5.5V12C13.1738 12.3978 13.0158 12.7794 12.7345 13.0607C12.4532 13.342 12.0717 13.5 11.6738 13.5H1.67383C1.276 13.5 0.894472 13.342 0.613168 13.0607C0.331863 12.7794 0.173828 12.3978 0.173828 12V5C0.173828 4.60218 0.331863 4.22064 0.613168 3.93934C0.894472 3.65804 1.276 3.5 1.67383 3.5H3.40624L4.2578 2.22265Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.67383 6.5C5.70733 6.5 4.92383 7.2835 4.92383 8.25C4.92383 9.2165 5.70733 10 6.67383 10C7.64033 10 8.42383 9.2165 8.42383 8.25C8.42383 7.2835 7.64033 6.5 6.67383 6.5ZM3.92383 8.25C3.92383 6.73122 5.15505 5.5 6.67383 5.5C8.19261 5.5 9.42383 6.73122 9.42383 8.25C9.42383 9.76878 8.19261 11 6.67383 11C5.15505 11 3.92383 9.76878 3.92383 8.25Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.17383 3C8.17383 2.72386 8.39769 2.5 8.67383 2.5H12.6738C12.95 2.5 13.1738 2.72386 13.1738 3C13.1738 3.27614 12.95 3.5 12.6738 3.5H8.67383C8.39769 3.5 8.17383 3.27614 8.17383 3Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6738 0.5C10.95 0.5 11.1738 0.723858 11.1738 1V5C11.1738 5.27614 10.95 5.5 10.6738 5.5C10.3977 5.5 10.1738 5.27614 10.1738 5V1C10.1738 0.723858 10.3977 0.5 10.6738 0.5Z'
        fill='black'
      />
    </svg>
  )
}

export default PlusCamera
