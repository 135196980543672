import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { formatToShortDate } from '@invisible/common/date'
import { useStore } from '@invisible/common/stores/process-store'
import { Tag } from '@invisible/ui/tag'
import { FC, useCallback } from 'react'
import { validate as isValidUUID } from 'uuid'
import shallow from 'zustand/shallow'

type TBase = TProcessWithBases['bases'][number]
type TBaseVariable = TBase['baseVariables'][number]

interface IFilterAndSortProps {
  baseVariables: TBaseVariable[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterAndSort: FC<IFilterAndSortProps> = ({ baseVariables }) => {
  const { filterOption, sortOption } = useStore(
    useCallback(
      (state) => ({
        filterOption: state.filterOption,
        sortOption: state.sortOption,
      }),
      []
    ),
    shallow
  )

  const formattedFilters = filterOption.map((f) => {
    const value =
      typeof f.value === 'object' &&
      !Array.isArray(f.value) &&
      f.value !== null &&
      f.value?.startDate
        ? `${formatToShortDate(new Date(f.value?.startDate))}-${formatToShortDate(
            new Date(f.value?.endDate)
          )}`
        : f.value
    if (isValidUUID(f.id)) {
      return {
        filterName: baseVariables?.find((bv) => bv.id === f.id)?.name,
        filterValue: f.value,
      }
    }
    return {
      filterName: f.id,
      filterValue: value,
    }
  })

  const formattedSort = Object.keys(sortOption).map((column) => {
    if (isValidUUID(column)) {
      return {
        sortName: baseVariables?.find((bv) => bv.id === column)?.name,
        sortValue: sortOption[column],
      }
    }
    return {
      sortName: column,
      sortValue: sortOption[column],
    }
  })

  return (
    <div>
      <div className='pb-2 pt-3 font-semibold'>Filters</div>
      <div className='flex'>
        {!formattedFilters.length ? <div>No filter applied</div> : null}
        {formattedFilters.map((f) => (
          <div className='mr-2'>
            <Tag>{`${f.filterName}: ${f.filterValue}`}</Tag>
          </div>
        ))}
      </div>
      <div className='pb-2 pt-3 font-semibold'>Sort</div>
      <div className='flex'>
        {!formattedSort.length ? <div>No sort applied</div> : null}
        {formattedSort.map((s) => (
          <div className='mr-2'>
            <Tag>{`${s.sortName}: ${s.sortValue}`}</Tag>
          </div>
        ))}
      </div>
    </div>
  )
}
