import pTimes from 'p-times'

export const DEFAULT_MODEL = 'cohere-command'

type ChatHistory = { text: string; acceptedResponse: string }[]

export const queryModel = async ({
  prompt,
  chatHistory,
  model,
  callCount = 1,
}: {
  chatHistory: ChatHistory
  prompt: string
  model: string
  callCount?: number
}): Promise<{ text: string }[] | []> => {
  try {
    const request = fetch('/api/wacs/rlhf/query-model', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatHistory,
        model: model,
        query: prompt,
        meta: { stream: false },
      }),
    }).then((res) => res.json())

    const result = await pTimes(callCount < 1 ? 1 : callCount, async () => await request)
    return result.reduce((acc, curr) => [...acc, ...curr], [])
  } catch (e) {
    return []
  }
}
