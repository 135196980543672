import { z } from '@invisible/zod'

import { schema as formFieldSchema } from './FormField'

const schema = z.object({
  baseId: z.string().optional(),
  fields: z.array(formFieldSchema),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
