import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TIconName } from '@invisible/ui/icons'
import { Icons } from '@invisible/ui/icons'
import { css, fontSizes, gray, purple, styled } from '@invisible/ui/themes'
import { useState } from 'react'
import { Card as ReCard, CardProps, Flex, Link } from 'rebass'

const hoverStyles = css`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px #e8e6ed;
  }
`

const selectedStyles = css`
    border: 1px solid ${purple(6)};
`

const unselectedStyles = css`
   border:  1px solid ${gray(4)};
`

const Content = styled(ReCard)<{
  hoverable?: boolean
  selectable?: boolean
  selected?: boolean
  className?: string
}>`
  min-height: 130px;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ hoverable }) => hoverable && hoverStyles}
  border-radius: 6px;
${({ selectable, selected }) => (selectable && selected ? selectedStyles : unselectedStyles)};
`

const Title = styled(Flex)<{ titleCenter?: boolean; divider?: boolean }>`
  color: #0A0810;
  font-weight: 500;
  font-size: ${fontSizes(2)};
  padding: ${({ divider }) => (divider ? '0 1rem 10px' : '1rem 1rem 6px')};
  border-bottom: ${({ divider }) => (divider ? '1px solid #f2f2f2' : 'none')};
  align-items: center;
  justify-content: ${({ titleCenter }) => (titleCenter ? 'center' : 'space-between')};
  margin-bottom: ${({ divider }) => (divider ? '16px' : '0px')};
`

const CloseLink = styled(Link)`
  cursor: pointer;
  color: #565656;
  padding: 3px 10px;
  &:hover {
    color: #fff;
    background-color: #565656;
    border-radius: 50px;
  }
`

const Body = styled(Flex)<{
  alignCenter?: boolean
}>`
  margin: 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'start')};
  color: #4a4a4a;
  font-size: ${fontSizes(2)};
  flex: 1 1 auto;
`

const Footer = styled(Flex)`
  padding: 1em 40px 0;
  align-items: center;
  justify-content: space-between;
`

const IconHolder = styled.span`
  color: #4520cf;
  background-color: #d1c6f9;
  border-radius: 100%;
  margin-right: 0.8rem;
  height: 1.6em;
  width: 1.6em;
  font-size: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface ICard extends CardProps {
  title?: string
  titleCenter?: boolean
  icon?: TIconName
  footer?: JSX.Element | Array<JSX.Element>
  closeable?: boolean
  hoverable?: boolean
  selectable?: boolean
  onSelect?: () => void
  onClose?: () => void
  alignCenter?: boolean
  divider?: boolean
}

const Card = (props: ICard) => {
  const {
    icon,
    children,
    title,
    titleCenter,
    footer,
    alignCenter,
    divider,
    onSelect = null,
    ...cardProps
  } = props
  const ParsedIcon = icon ? Icons?.[icon] : undefined

  const [isSelected, setSelected] = useState(false)
  return (
    <Content
      data-cy='card'
      {...(cardProps as any)}
      onClick={() => {
        onSelect?.()
        setSelected(!isSelected)
      }}
      selected={isSelected}>
      {props.title && (
        <Title data-cy='cardTitle' titleCenter={titleCenter} divider={divider}>
          {icon && (
            <IconHolder data-cy='cardIcon'>
              {ParsedIcon ? <ParsedIcon height={20} width={20} /> : null}
            </IconHolder>
          )}
          {title}
          {props.closeable ? (
            <CloseLink onClick={props.onClose} data-cy='cardClose'>
              <FontAwesomeIcon icon={faTimes} />
            </CloseLink>
          ) : (
            ''
          )}
        </Title>
      )}
      <Body data-cy='cardBody' alignCenter={alignCenter}>
        {children}
      </Body>
      {footer && <Footer data-cy='cardFooter'>{footer}</Footer>}
    </Content>
  )
}

export { Card }
