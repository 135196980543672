import Badge, { BadgeProps } from '@mui/material/Badge'
import React from 'react'

import { palette } from '../palette'

const MuiBadge = {
  variants: [
    {
      props: { variant: 'dot', isOnline: true },
      style: {
        '& .MuiBadge-badge': {
          color: palette.success.main,
          backgroundColor: palette.success.main,
          boxShadow: `0 0 0 2px white`,
        },
      },
    },
    {
      props: { variant: 'dot', isOnline: false },
      style: {
        '& .MuiBadge-badge': {
          color: palette.success.main,
          backgroundColor: 'white',
          boxShadow: `0 0 0 2px ${palette.grey.paragraph}`,
        },
      },
    },
  ],
}

const IsOnlineBadge = <C extends React.ElementType>(
  props: BadgeProps<C, { component?: C }> & {
    isOnline?: boolean
  }
) => <Badge {...props}>{props.children}</Badge>

export default MuiBadge

export { IsOnlineBadge }
