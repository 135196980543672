import { DownloadCSVIcon, FilterIcon, FullScreenLinkIcon } from '@invisible/ui/reports/icons'
import type { ReactNode } from 'react'
import React, { useState } from 'react'
import { useGate } from 'statsig-react'

import { useFormattedReport, useReportContext } from '../ReportProvider'
import { ReportFilters } from './ReportFilters'

const sum = (arr: number[] = []) => arr.reduce((total, val) => total + val)

const GRID_COLS_MAP = new Map([
  [1, 'lg:grid-cols-1'],
  [2, 'lg:grid-cols-2'],
  [3, 'lg:grid-cols-3'],
  [4, 'lg:grid-cols-4'],
  [5, 'lg:grid-cols-5'],
  [6, 'lg:grid-cols-6'],
  [7, 'lg:grid-cols-7'],
  [8, 'lg:grid-cols-8'],
  [9, 'lg:grid-cols-9'],
  [10, 'lg:grid-cols-10'],
  [11, 'lg:grid-cols-11'],
  [12, 'lg:grid-cols-12'],
])

const COL_SPANS_MAP = new Map([
  [1, 'lg:col-span-1'],
  [2, 'lg:col-span-2'],
  [3, 'lg:col-span-3'],
  [4, 'lg:col-span-4'],
  [5, 'lg:col-span-5'],
  [6, 'lg:col-span-6'],
  [7, 'lg:col-span-7'],
  [8, 'lg:col-span-8'],
  [9, 'lg:col-span-9'],
  [10, 'lg:col-span-10'],
  [11, 'lg:col-span-11'],
  [12, 'lg:col-span-12'],
])

const REPORT_DETAIL_VIEW_PATH = 'insights/detail'

type ReportLayoutProps = {
  children: ReactNode
  colSpans?: number[]
}

const ReportLayout = ({ children, colSpans = [1] }: ReportLayoutProps) => {
  if (sum(colSpans) > 12) {
    throw new Error('Sum of colSpans must be less than or equal to 12')
  }

  const { value: enableDynamicFilters } = useGate('enable-dynamic-filters')

  const [showFilters, setShowFilters] = useState(false)

  const {
    state: { reportName, reportQueryFilters },
  } = useReportContext()

  const { data: formattedReport } = useFormattedReport(reportName)

  const handleFilterClick = () => {
    setShowFilters((prev) => !prev)
  }

  return (
    <div className='border-weak flex flex-col gap-1 rounded-lg border border-solid p-4'>
      <div className='flex justify-between'>
        <div className='text-xl font-normal'>{formattedReport?.reportTitle}</div>
        <div className='flex gap-1'>
          {enableDynamicFilters && (
            <FilterIcon selected={showFilters} onClick={handleFilterClick} width={16} height={16} />
          )}
          {!!formattedReport?.reportName && (
            <DownloadCSVIcon
              data-test='csv-download'
              dataFilters={reportQueryFilters}
              reportName={formattedReport?.reportName}
              fileName={formattedReport?.reportTitle}
            />
          )}

          <FullScreenLinkIcon
            reportFullViewURL={`${REPORT_DETAIL_VIEW_PATH}?reportName=${formattedReport?.reportName}`}
          />
        </div>
      </div>
      <div className='text-muted text-sm font-normal'>{formattedReport?.subtitle}</div>
      {showFilters && <ReportFilters />}
      <div className={`grid grid-cols-1 ${GRID_COLS_MAP.get(sum(colSpans))}`}>
        {React.Children.map(children, (child, i) => (
          <div className={`col-span-1 h-[400px] ${COL_SPANS_MAP.get(colSpans[i])}`}>{child}</div>
        ))}
      </div>
    </div>
  )
}

export { ReportLayout }
