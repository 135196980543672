import { sanitizeUrl } from '@braintree/sanitize-url'

const EmbedLoomVideo = ({ url }: { url: string }) => {
  if (url?.includes('loom.com')) {
    const loomVideoId = sanitizeUrl(url).split('/').pop()
    return (
      <iframe
        className='border-rounded h-[30rem] w-[50rem] border-none bg-black pt-2'
        allowFullScreen
        src={`https://www.loom.com/embed/${loomVideoId}`}></iframe>
    )
  }

  return (
    <iframe
      className='border-rounded h-[30rem] w-[50rem] border-none bg-black pt-2'
      allowFullScreen={true}
      src={sanitizeUrl(url)}></iframe>
  )
}

export { EmbedLoomVideo }
