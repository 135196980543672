import { SnackbarContext } from '@invisible/common/providers'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { LinearProgress, Popover, Typography } from '@mui/material'
import { compact } from 'lodash'
import { useContext as useReactContext, useMemo, useReducer, useState } from 'react'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { TEXT_ANNOTATION_STATE_INITIAL_VALUES } from './constants'
import {
  removeEnclosingTagsAroundUrls,
  selectionIsBackwards,
  selectionIsEmpty,
  splitWithOffsets,
} from './helpers'
import Split from './Split'
import { ITextAnnotationWACProps, TAnnotationState } from './types'

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${gray(4)};
  height: 100%;
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
  background-color: white;
`

export const TextAnnotationWAC = ({
  value,
  showName,
  name,
  stepRun,
  baseRun,
  textAnnotation: config,
}: ITextAnnotationWACProps) => {
  const reactQueryContext = useContext()
  const annotationBaseId = config?.annotationBaseId as string
  const { showSnackbar } = useReactContext(SnackbarContext)
  const { value: enableRichTextUrlParsing } = useGate('enable-richtext-url-parsing')
  const [annotationStack, setAnnotationStack] = useState<string[]>([])

  const [{ anchorEl, annotation, isHighlighting }, dispatch] = useReducer(
    (prev: TAnnotationState, next: TAnnotationState) => ({ ...prev, ...next }),
    TEXT_ANNOTATION_STATE_INITIAL_VALUES
  )

  const sanitizedHtml = useMemo(() => {
    const sanitizedContent = sanitize(value as string, {
      allowedTags: [],
      allowedAttributes: {},
    })

    if (enableRichTextUrlParsing && typeof value === 'string') {
      return removeEnclosingTagsAroundUrls(sanitizedContent)
    }

    return sanitizedContent
  }, [enableRichTextUrlParsing, value])

  const { mutateAsync: createBaseRun, isLoading: createBaseRunLoading } = useBaseRunCreate({
    onSuccess: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
      showSnackbar({
        message: 'Annotation Saved',
        variant: 'success',
      })
    },
    onError: (error) => {
      dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
      logger.error('Error creating Text Annotation', {
        error,
        baseRunId: baseRun.id,
        stepRunId: stepRun.id,
      })
      showSnackbar({ message: `Failed to create annotation`, variant: 'error' })
    },
  })

  const { mutateAsync: deleteBaseRuns, isLoading: deleteBaseRunLoading } =
    useBaseRunDeleteWithStepRunReference({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        if (annotation) {
          setAnnotationStack(annotationStack.filter((id) => id !== annotation.id))
        } else {
          setAnnotationStack(annotationStack.slice(0, annotationStack.length - 1))
        }
        dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
        showSnackbar({
          message: 'Annotation Deleted',
          variant: 'success',
        })
      },
      onError: (error) => {
        dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
        logger.error('Error deleting Text annotation', {
          error,
          baseRunId: baseRun.id,
          stepRunId: stepRun.id,
        })
        showSnackbar({ message: `Failed to Deleted annotation`, variant: 'error' })
      },
    })

  const { data: annotations, isLoading: queryAnnotationsLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: annotationBaseId,
      parentBaseRunId: baseRun.id,
    },
  ])

  const normalizedAnnotations = useMemo(
    () =>
      (annotations ?? [])
        .map((annotation) => ({
          id: annotation.id,
          text: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.textBaseVariableId
          )?.value as string,
          start: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.startBaseVariableId
          )?.value as number,
          end: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.endBaseVariableId
          )?.value as number,
          createdAt: annotation.createdAt,
        }))
        .sort((a, b) => a.start - b.end),
    [annotations, config]
  )

  const validateSelection = (start: number, end: number) => {
    if (start >= end) return false

    if (
      normalizedAnnotations.some(
        (annotation) =>
          (annotation.start < start && start < annotation.end) ||
          (annotation.start < end && end < annotation.end) ||
          (start < annotation.start && annotation.end < end)
      )
    ) {
      showSnackbar({
        message: 'Overlapping Selection',
        variant: 'warning',
      })
      return false
    }

    return true
  }

  const handleMouseUp = () => {
    if (createBaseRunLoading) return

    const selection: Selection | null = window.getSelection()

    if (!selection || selectionIsEmpty(selection)) return

    let start: number =
      parseInt(selection.anchorNode?.parentElement?.getAttribute('data-start') || '0', 10) +
      selection.anchorOffset
    let end: number =
      parseInt(selection.focusNode?.parentElement?.getAttribute('data-start') || '0', 10) +
      selection.focusOffset

    if (selectionIsBackwards(selection)) {
      ;[start, end] = [end, start]
    }

    if (!validateSelection(start, end)) return

    dispatch({ annotation: { start, end, text: selection.toString() }, isHighlighting: true })
  }

  const saveAnnotationToBaseRun = async () => {
    if (!annotation || createBaseRunLoading) return

    const { id } = await createBaseRun({
      baseId: annotationBaseId as string,
      parentBaseRunId: baseRun.id,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: config?.textBaseVariableId as string,
          value: annotation.text,
        },
        {
          baseVariableId: config?.startBaseVariableId as string,
          value: annotation.start,
        },
        {
          baseVariableId: config?.endBaseVariableId as string,
          value: annotation.end,
        },
      ],
    })

    setAnnotationStack([...annotationStack, id])
  }

  const handleDeleteBaseRun = async () => {
    if (!annotation?.id) return
    if (!window.confirm('Are you sure you want to delete this annotation?')) return

    await deleteBaseRuns({ baseRunIds: [annotation.id], stepRunId: stepRun.id })
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault()

    const { key, ctrlKey } = event
    if (key === 'Enter') {
      if (isHighlighting) {
        saveAnnotationToBaseRun()
      }
    }
    if (key === 'z' && ctrlKey) {
      if (annotationStack.length > 0) {
        const id = annotationStack[annotationStack.length - 1]

        deleteBaseRuns({ baseRunIds: [id], stepRunId: stepRun.id })
      } else {
        showSnackbar({ message: 'Nothing to undo', variant: 'warning' })
      }
    }
  }

  const splits = splitWithOffsets(
    sanitizedHtml,
    compact([isHighlighting ? annotation : null, ...normalizedAnnotations])
  )

  return (
    <>
      {annotation ? (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <Typography sx={{ p: 1 }}>
            {isHighlighting ? (
              <Button onClick={saveAnnotationToBaseRun}>Highlight</Button>
            ) : (
              <>
                <Typography>
                  <strong>Start:</strong> {annotation.start}
                </Typography>
                <Typography>
                  <strong>End:</strong> {annotation.end}
                </Typography>
                <Button variant='danger' onClick={handleDeleteBaseRun}>
                  Delete
                </Button>
              </>
            )}
          </Typography>
        </Popover>
      ) : null}
      <Container>
        {createBaseRunLoading || queryAnnotationsLoading || deleteBaseRunLoading ? (
          <LinearProgress color='secondary' />
        ) : null}

        {showName ? (
          <Text mb='10px' fontWeight='bold'>
            {name}
          </Text>
        ) : null}
        <div
          className='whitespace-pre-wrap'
          tabIndex={0}
          onKeyUp={handleKeyPress}
          onMouseUp={handleMouseUp}>
          {splits.map((split) => (
            <Split
              key={`${split.start}-${split.end}`}
              {...split}
              isHighlighted={Boolean(annotation && annotation.start === split.start)}
              dispatcher={dispatch}
            />
          ))}
        </div>
      </Container>
    </>
  )
}
