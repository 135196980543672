import { SVGProps } from 'react'

function SymbolInvisible(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 16C0 7.17714 7.17714 0 16 0C24.8229 0 32 7.17714 32 16C32 24.8229 24.8229 32 16 32C7.17714 32 0 24.8229 0 16ZM26.3841 4.84875C23.6626 2.31327 20.0122 0.761905 16 0.761905C11.9878 0.761905 8.33738 2.31327 5.61586 4.84875H26.3841ZM27.1482 5.61256V26.3874C29.6855 23.6656 31.2381 20.0138 31.2381 16C31.2381 11.9862 29.6855 8.33443 27.1482 5.61256ZM26.3842 27.1512H5.61585C8.33737 29.6867 11.9878 31.2381 16 31.2381C20.0122 31.2381 23.6626 29.6867 26.3842 27.1512ZM4.84875 26.3841V5.61586C2.31327 8.33738 0.761905 11.9878 0.761905 16C0.761905 20.0122 2.31327 23.6626 4.84875 26.3841ZM26.3863 26.3893H5.61066V5.61066H26.3863V26.3893Z'
      />
    </svg>
  )
}

export default SymbolInvisible
