import create, { GetState, SetState } from 'zustand'
import { devtools } from 'zustand/middleware'
import { persist } from 'zustand/middleware'

import { createAutoSnoozeSlice, IAutoSnoozeSlice, TIdleStatus } from './autoSnooze/state'
import {
  createBaseViewSlice,
  IBaseViewSlice,
  TBaseViewDownloadFormat,
  TFilterOption,
} from './baseViewEditor/state'
import { createProcessBuilderSlice, IProcessBuilderSlice } from './processBuilder/state'
import { createWizardBuilderSlice, IWizardBuilderSlice } from './wizardBuilder/state'

export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>,
  get: GetState<E extends T ? E : E & T>
) => T

type State = IProcessBuilderSlice & IWizardBuilderSlice & IBaseViewSlice & IAutoSnoozeSlice

const useStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        ...createBaseViewSlice(set, get),
        ...createProcessBuilderSlice(set, get),
        ...createWizardBuilderSlice(set, get),
        ...createAutoSnoozeSlice(set, get),
      }),
      {
        name: 'inv-operate',
        // Persist layoutOrientation and columnOrder to local storage
        partialize: (state) => ({ layoutOrientation: state.layoutOrientation }),
      }
    )
  )
)

export type { TBaseViewDownloadFormat, TFilterOption, TIdleStatus }
export { useStore }
export * from './constants'
