import { SVGProps } from 'react'
function EditDocOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='29'
      viewBox='0 0 27 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.34995 1.11508C1.72502 0.740011 2.23373 0.529297 2.76416 0.529297H14.7642C15.0294 0.529297 15.2837 0.634654 15.4713 0.82219L22.4713 7.82219C22.8618 8.21271 22.8618 8.84588 22.4713 9.2364C22.0807 9.62693 21.4476 9.62693 21.0571 9.2364L14.3499 2.5293L2.76416 2.5293L2.76416 24.5293H5.26416C5.81644 24.5293 6.26416 24.977 6.26416 25.5293C6.26416 26.0816 5.81644 26.5293 5.26416 26.5293H2.76416C2.23373 26.5293 1.72502 26.3186 1.34995 25.9435C0.974874 25.5684 0.76416 25.0597 0.76416 24.5293V2.5293C0.76416 1.99886 0.974875 1.49016 1.34995 1.11508Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7642 0.529297C15.3164 0.529297 15.7642 0.977012 15.7642 1.5293V7.5293H21.7642C22.3164 7.5293 22.7642 7.97701 22.7642 8.5293C22.7642 9.08158 22.3164 9.5293 21.7642 9.5293H14.7642C14.2119 9.5293 13.7642 9.08158 13.7642 8.5293V1.5293C13.7642 0.977012 14.2119 0.529297 14.7642 0.529297Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0571 10.8222C21.4476 10.4317 22.0807 10.4317 22.4713 10.8222L26.4713 14.8222C26.8618 15.2127 26.8618 15.8459 26.4713 16.2364L14.4713 28.2364C14.2837 28.4239 14.0294 28.5293 13.7642 28.5293H9.76416C9.21188 28.5293 8.76416 28.0816 8.76416 27.5293V23.5293C8.76416 23.2641 8.86952 23.0097 9.05705 22.8222L21.0571 10.8222ZM10.7642 23.9435V26.5293H13.3499L24.3499 15.5293L21.7642 12.9435L10.7642 23.9435Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0571 13.8222C18.4476 13.4317 19.0807 13.4317 19.4713 13.8222L23.4713 17.8222C23.8618 18.2127 23.8618 18.8459 23.4713 19.2364C23.0807 19.6269 22.4476 19.6269 22.0571 19.2364L18.0571 15.2364C17.6665 14.8459 17.6665 14.2127 18.0571 13.8222Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EditDocOutline
