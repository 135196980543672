import { SVGProps } from 'react'

function BuildEditFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.1697 14.2422C22.1673 14.2446 22.1648 14.247 22.1624 14.2494C22.16 14.2519 22.1575 14.2543 22.1551 14.2568L13.5087 22.9032C13.1832 23.2291 13.0002 23.6713 13 24.1319V28.2609C13 28.7221 13.1832 29.1645 13.5094 29.4906C13.8355 29.8168 14.2779 30 14.7391 30H18.8685C19.3292 29.9998 19.7714 29.8164 20.0973 29.4908L28.7113 20.8746C28.7246 20.8627 28.7377 20.8503 28.7505 20.8375C28.7633 20.8247 28.7758 20.8116 28.7878 20.7982L31.491 18.0943C31.8169 17.7682 32 17.326 32 16.8649C32 16.4039 31.8165 15.9613 31.4906 15.6351L27.3616 11.509C27.0355 11.1831 26.5933 11 26.1323 11C25.6712 11 25.2291 11.1831 24.9029 11.509L22.1697 14.2422ZM24.2838 14.9566L26.1323 13.108L29.8919 16.865L28.0423 18.7151L24.2838 14.9566ZM26.6282 20.1295L18.7596 28H15V24.2403L22.8695 16.3708L26.6282 20.1295Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5484 11.0323H15.2258C16.2947 11.0323 17.1613 10.1657 17.1613 9.09678V3.93548C17.1613 2.86655 16.2947 2 15.2258 2H10.0645C8.99558 2 8.12903 2.86655 8.12903 3.93548V9.09677C8.12903 10.1657 8.99558 11.0323 10.0645 11.0323H11.7419V14.6452H8.51801C7.986 14.6431 7.45997 14.7516 6.97061 14.9632C6.48072 15.175 6.0386 15.4853 5.66854 15.8739C5.29856 16.2623 5.00773 16.7215 4.81075 17.2238C4.61402 17.7255 4.51423 18.2616 4.51616 18.8018V20.0645H1.91936C0.859325 20.0645 0 20.9238 0 21.9839V28.0806C0 29.1407 0.859325 30 1.91935 30H8.01613C9.07616 30 9.93548 29.1407 9.93548 28.0806V21.9839C9.93548 20.9238 9.07616 20.0645 8.01613 20.0645H6.32261L6.32261 18.8L6.3226 18.7964C6.32134 18.4822 6.37942 18.1717 6.49251 17.8833C6.60558 17.595 6.77083 17.3358 6.97666 17.1197C7.18239 16.9037 7.4244 16.7351 7.68749 16.6213C7.95045 16.5076 8.23059 16.4504 8.51217 16.4516L15.0968 16.4516C15.5956 16.4516 16 16.0473 16 15.5484C16 15.0496 15.5956 14.6452 15.0968 14.6452H13.5484V11.0323ZM15.2258 3.80645C15.2971 3.80645 15.3548 3.86422 15.3548 3.93548V9.09678C15.3548 9.16804 15.2971 9.22581 15.2258 9.22581H10.0645C9.99325 9.22581 9.93548 9.16804 9.93548 9.09677L9.93548 3.93548C9.93548 3.86422 9.99325 3.80645 10.0645 3.80645L15.2258 3.80645ZM1.80645 21.9839C1.80645 21.9215 1.857 21.871 1.91936 21.871H8.01613C8.07848 21.871 8.12903 21.9215 8.12903 21.9839V28.0806C8.12903 28.143 8.07848 28.1935 8.01613 28.1935H1.91935C1.857 28.1935 1.80645 28.143 1.80645 28.0806L1.80645 21.9839Z'
        fill='currentColor'
      />
      <path
        d='M1.91936 21.871C1.857 21.871 1.80645 21.9215 1.80645 21.9839L1.80645 28.0806C1.80645 28.143 1.857 28.1935 1.91935 28.1935H8.01613C8.07848 28.1935 8.12903 28.143 8.12903 28.0806V21.9839C8.12903 21.9215 8.07848 21.871 8.01613 21.871H1.91936Z'
        fill='currentColor'
      />
      <path
        d='M18.7596 28L26.6282 20.1295L22.8695 16.3708L15 24.2403V28H18.7596Z'
        fill='currentColor'
      />
      <path
        d='M15.3548 3.93548C15.3548 3.86422 15.2971 3.80645 15.2258 3.80645L10.0645 3.80645C9.99325 3.80645 9.93548 3.86422 9.93548 3.93548L9.93548 9.09677C9.93548 9.16804 9.99325 9.22581 10.0645 9.22581H15.2258C15.2971 9.22581 15.3548 9.16804 15.3548 9.09678V3.93548Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BuildEditFilled
