import type { TProcessWithBases } from '@invisible/common/components/process-base'
import {
  Backdrop,
  RGLContainer,
  Topbar,
  WizardContainer,
} from '@invisible/common/components/wizard/wizard-container'
import { sendErrorToSentry } from '@invisible/errors'
import { Button } from '@invisible/ui/button'
import { CloseIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Text } from '@invisible/ui/text'
import { ThemeProvider } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { MANUAL_TRIGGER_STEP_TEMPLATE_ID } from '@invisible/ultron/shared'
import { ManualTriggerStepMeta, TriggerStepMeta } from '@invisible/ultron/zod'
import { keys, map } from 'lodash/fp'
import { isEmpty } from 'radash'
import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Flex } from 'rebass'

import { useExecuteManualTrigger } from '../hooks/useExecuteManualTrigger'
import { ManualTriggerAtomicComponents } from './ManualTriggerAtomicComponents'
import { TParsedProperties, TPayloadSchema, TProperties, TProperty } from './types'

type TBase = TProcessWithBases['bases'][number]
type TStep = TBase['steps'][number]

const LAYOUT = { h: 16, i: 'd', static: true, w: 6, x: 3, y: 0 }

export const ManualTriggerWizard = ({
  processData,
  openInModal,
  onTriggerExecute,
  onClose,
  showOnlyTrigger,
}: {
  processData: TProcessWithBases
  openInModal?: boolean
  onTriggerExecute?: () => void
  onClose?: () => void
  showOnlyTrigger?: string
}) => {
  const { addToast } = useToasts()
  const reactQueryClient = useQueryClient()

  const [currentTriggerStep, setCurrentTriggerStep] = useState<TStep | null>(null)

  const {
    isLoading: isExecuteConcordeManualTriggerLoading,
    mutateAsync: executeConcordeManualTrigger,
  } = useExecuteManualTrigger({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const triggerSteps = useMemo(
    () =>
      processData.bases
        .flatMap((base) => base.steps)
        .filter(
          (step) =>
            step.stepTemplateId === MANUAL_TRIGGER_STEP_TEMPLATE_ID &&
            ((showOnlyTrigger && step.id === showOnlyTrigger) || !showOnlyTrigger)
        ),
    [processData.bases]
  )

  const parsedProperties: TParsedProperties = useMemo(() => {
    const properties = (currentTriggerStep?.meta as ManualTriggerStepMeta.TSchema)?.payloadSchema
      ?.properties as TProperties
    return map((propertyName: string) => ({
      ...(properties[propertyName] as TProperty),
      label: propertyName,
      fieldType: properties[propertyName]?.enum
        ? ('dropdown' as const)
        : properties[propertyName]?.type,
    }))(keys(properties).filter((key) => key !== TriggerStepMeta.RAW_PAYLOAD_KEY))
  }, [currentTriggerStep])

  const requiredFields = useMemo(
    () => (currentTriggerStep?.meta as { payloadSchema: TPayloadSchema })?.payloadSchema?.required,
    [currentTriggerStep]
  )

  const handleTriggerClick = async (step: TStep) => {
    const properties = (step.meta as ManualTriggerStepMeta.TSchema)?.payloadSchema?.properties
    if (isEmpty(properties)) {
      try {
        await executeConcordeManualTrigger({ payload: {}, triggerStepId: step.id })
        onTriggerExecute?.()
      } catch (error) {
        sendErrorToSentry(error)
        addToast(`Something went wrong: ${(error as Error).message}`, {
          appearance: 'error',
        })
      }
      return
    } else {
      setCurrentTriggerStep(step)
    }
  }

  const getStepDescription = (currentTriggerStep: TStep) =>
    (currentTriggerStep.meta as ManualTriggerStepMeta.TSchema)?.description ?? ''

  const TriggerFormWAC = ManualTriggerAtomicComponents['triggerForm'] // for now we have only this, later it may be selected from the step meta

  return (
    <ThemeProvider>
      <div className='flex'>
        {currentTriggerStep ? (
          !openInModal ? (
            <Backdrop>
              <WizardContainer>
                <Topbar>
                  <Text
                    color='white'
                    fontSize={4}
                    fontWeight={400}
                    lineHeight='150%'
                    letterSpacing='0.3rem'>
                    {currentTriggerStep.name.toUpperCase()}
                  </Text>
                  <Flex alignItems='center'>
                    <CloseIcon
                      color='white'
                      width={16}
                      height={18}
                      onClick={() => setCurrentTriggerStep(null)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Flex>
                </Topbar>

                <RGLContainer margin={[10, 10]} rowHeight={30} containerPadding={[20, 10]}>
                  <div key='triggerFormWac' data-grid={LAYOUT}>
                    <TriggerFormWAC
                      parsedProperties={parsedProperties}
                      triggerStepId={currentTriggerStep.id}
                      requiredFields={requiredFields}
                      closeWizard={() => setCurrentTriggerStep(null)}
                      description={getStepDescription(currentTriggerStep)}
                    />
                  </div>
                </RGLContainer>
              </WizardContainer>
            </Backdrop>
          ) : (
            <Modal title='' onClose={() => onClose?.()}>
              <Backdrop>
                <WizardContainer>
                  <Topbar>
                    <Text
                      color='white'
                      fontSize={4}
                      fontWeight={400}
                      lineHeight='150%'
                      letterSpacing='0.3rem'>
                      {currentTriggerStep.name.toUpperCase()}
                    </Text>
                    <Flex alignItems='center'>
                      <CloseIcon
                        color='white'
                        width={16}
                        height={18}
                        onClick={() => setCurrentTriggerStep(null)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Flex>
                  </Topbar>

                  <RGLContainer margin={[10, 10]} rowHeight={30} containerPadding={[20, 10]}>
                    <div key='triggerFormWac' data-grid={LAYOUT}>
                      <TriggerFormWAC
                        parsedProperties={parsedProperties}
                        triggerStepId={currentTriggerStep.id}
                        requiredFields={requiredFields}
                        closeWizard={() => setCurrentTriggerStep(null)}
                        description={getStepDescription(currentTriggerStep)}
                        onTriggerExecute={onTriggerExecute}
                      />
                    </div>
                  </RGLContainer>
                </WizardContainer>
              </Backdrop>
            </Modal>
          )
        ) : null}
        <div className='flex items-center gap-x-3 '>
          {triggerSteps.map((triggerStep) => (
            <Button
              variant='primary'
              loading={isExecuteConcordeManualTriggerLoading}
              size='md'
              iconLeft='RocketFilledIcon'
              key={triggerStep.name}
              onClick={() => handleTriggerClick(triggerStep)}>
              {triggerStep.name}
            </Button>
          ))}
        </div>
      </div>
    </ThemeProvider>
  )
}
