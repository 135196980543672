import { SVGProps } from 'react'
function DoubleCaratDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.366135 11.2929C0.75666 10.9024 1.38982 10.9024 1.78035 11.2929L11.0732 20.5858L20.3661 11.2929C20.7567 10.9024 21.3898 10.9024 21.7803 11.2929C22.1709 11.6834 22.1709 12.3166 21.7803 12.7071L11.7803 22.7071C11.3898 23.0976 10.7567 23.0976 10.3661 22.7071L0.366135 12.7071C-0.0243889 12.3166 -0.0243889 11.6834 0.366135 11.2929Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.366135 0.292893C0.75666 -0.0976311 1.38982 -0.0976311 1.78035 0.292893L11.0732 9.58579L20.3661 0.292893C20.7567 -0.0976311 21.3898 -0.0976311 21.7803 0.292893C22.1709 0.683417 22.1709 1.31658 21.7803 1.70711L11.7803 11.7071C11.3898 12.0976 10.7567 12.0976 10.3661 11.7071L0.366135 1.70711C-0.0243889 1.31658 -0.0243889 0.683417 0.366135 0.292893Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DoubleCaratDown
