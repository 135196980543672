import { SVGProps } from 'react'

function SalesFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M32 28.4545C32 28.776 31.8703 29.0843 31.6395 29.3116C31.4087 29.539 31.0957 29.6667 30.7692 29.6667H1.23077C0.904349 29.6667 0.591298 29.539 0.360484 29.3116C0.12967 29.0843 0 28.776 0 28.4545V4.21212C0 3.89065 0.12967 3.58234 0.360484 3.35502C0.591298 3.12771 0.904349 3 1.23077 3C1.55719 3 1.87024 3.12771 2.10105 3.35502C2.33187 3.58234 2.46154 3.89065 2.46154 4.21212V20.6818L10.2 13.0455C10.4333 12.8178 10.7485 12.6902 11.0769 12.6902C11.4054 12.6902 11.7205 12.8178 11.9538 13.0455L16 17.0455L23.4923 9.66667L21.2769 7.5C21.11 7.32474 20.9968 7.10651 20.9505 6.87056C20.9042 6.6346 20.9267 6.39056 21.0154 6.16667C21.1112 5.94723 21.27 5.7601 21.4722 5.62822C21.6744 5.49635 21.9113 5.42546 22.1538 5.42424H28.3077C28.6341 5.42424 28.9472 5.55195 29.178 5.77926C29.4088 6.00658 29.5385 6.31489 29.5385 6.63636V12.697C29.5392 12.9363 29.468 13.1704 29.3339 13.37C29.1997 13.5695 29.0086 13.7255 28.7846 13.8182C28.6328 13.8776 28.471 13.9084 28.3077 13.9091C27.9809 13.9077 27.667 13.783 27.4308 13.5606L25.2308 11.3788L16.8769 19.6212C16.6436 19.8488 16.3284 19.9765 16 19.9765C15.6716 19.9765 15.3564 19.8488 15.1231 19.6212L11.0769 15.6212L2.46154 24.1061V27.2424H30.7692C31.0957 27.2424 31.4087 27.3701 31.6395 27.5974C31.8703 27.8248 32 28.1331 32 28.4545Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SalesFilled
