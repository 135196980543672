import {
  fromGlobalId,
  IFirstManualStepForBaseRunQuery,
  toGlobalId,
  useFirstManualStepForBaseRunQuery,
} from '@invisible/concorde/gql-client'

const useFirstManualStepForBaseRun = ({ baseRunId }: { baseRunId: string }) => {
  const { data: graphqlResult, isLoading } = useFirstManualStepForBaseRunQuery({
    baseRunId: toGlobalId('BaseRunType', baseRunId),
  })
  const data = extractStepRunFromResult(graphqlResult)
  return {
    data,
    isLoading,
  }
}

const extractStepRunFromResult = (graphqlResult: IFirstManualStepForBaseRunQuery | undefined) => {
  const baseRuns = graphqlResult?.baseRuns.edges
  if (baseRuns) {
    const stepRun = baseRuns[0].node.firstAssignedManualStepRun
    return {
      id: fromGlobalId(stepRun?.id),
      createdAt: new Date(stepRun?.createdAt),
      step: {
        name: stepRun?.step.name,
      },
      assignee: {
        id: fromGlobalId(stepRun?.assignee?.id),
        preferredName: stepRun?.assignee?.preferredName,
        name: stepRun?.assignee?.name,
        email: stepRun?.assignee?.email,
        image: stepRun?.assignee?.image,
      },
    }
  }
  return null
}

export { useFirstManualStepForBaseRun }
