import { isQuestionSkipped } from '@invisible/common/helpers'
import {
  TComment,
  TQuestionAndAnswer,
  TQuestionAndAnswerTree,
  TQuestionnaireAnswerEntries,
  TQuestionTree,
} from '@invisible/common/types'
import { QuestionMeta } from '@invisible/ultron/zod'
import { TUuid } from '@invisible/zod'
import { isEmpty, isNil } from 'lodash/fp'

export const findDeepestCommentNode = (comments: TComment[]): TComment => {
  const commentMap = new Map(comments.map((comment) => [comment.id, comment]))
  let deepestNode = comments[0]
  let maxDepth = 0

  for (const comment of comments) {
    let depth = 0
    let currentNode = comment

    while (currentNode.replyToId) {
      depth++
      const parent = commentMap.get(currentNode.replyToId)
      if (!parent) break
      currentNode = parent
    }

    if (depth > maxDepth) {
      deepestNode = comment
      maxDepth = depth
    }
  }

  return deepestNode
}

export const checkHasNoUnresolvedComments = (comments: TComment[]): boolean => {
  const innerMostComment = findDeepestCommentNode(comments)
  if (innerMostComment.type === 'issue') return false
  return innerMostComment.status === 'assigner_resolved'
}

export const isSkippableQuestion = (question: TQuestionTree) => {
  const parseResult = QuestionMeta.schema.safeParse(question.meta)
  return parseResult.success && Boolean(parseResult.data.skipCondition)
}

export const allRequiredQuestionsAnswered = (
  questions: TQuestionAndAnswerTree[],
  entries: TQuestionnaireAnswerEntries
): boolean =>
  questions.every((question) => {
    if (isQuestionSkipped(question, questions, entries)) return true
    if (!isEmpty(question.children)) return allRequiredQuestionsAnswered(question.children, entries)
    if (question.required && isNil(entries[question.id])) return false
    return true
  })

export const updateAnswerInPlace = (
  questions: TQuestionAndAnswer[],
  questionId: TUuid,
  newValue: TQuestionnaireAnswerEntries[string]
): TQuestionAndAnswer[] =>
  questions.map((question) => {
    if (question.id !== questionId) return question
    if (!question.answer) return question
    const answerId = question.answer.id

    if (question.type === 'open') {
      const textAnswer = newValue?.text as string
      return {
        ...question,
        answer: {
          ...question.answer,
          text: textAnswer,
        },
      }
    }

    if (question.type === 'single_choice') {
      const choice = newValue.choice as string
      const freeText = newValue.freeText?.[choice]
      return {
        ...question,
        answer: {
          ...question.answer,
          answersToOptions: [
            {
              answerId,
              optionId: choice,
              ...(freeText ? { freeText } : {}),
            },
          ],
        },
      }
    }

    if (question.type === 'multi_choice') {
      const choices = newValue.choices as string[]
      const freeText = newValue.freeText || {}
      const newAnswersToOptions = choices.map((optionId) => ({
        answerId,
        optionId,
        ...(freeText[optionId] ? { freeText: freeText[optionId] } : {}),
      }))

      return {
        ...question,
        answer: {
          ...question.answer,
          answersToOptions: newAnswersToOptions,
        },
      }
    }

    return question
  })
