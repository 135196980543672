import { Icons } from '@invisible/ui/icons'
import { FC, useState } from 'react'

const ColorStyles = {
  theme: 'text-theme-main',
  danger: 'text-red-main',
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuItem: FC<{
  label: string
  icon: keyof typeof Icons
  hoveredIcon: keyof typeof Icons
  color?: 'theme' | 'danger'
}> = ({ label, icon, hoveredIcon, color = 'theme' }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={`text-header flex w-40 items-center gap-2 font-normal hover:font-medium hover:${ColorStyles[color]}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {hovered
        ? Icons[hoveredIcon]({ className: `h-5 w-5 ${ColorStyles[color]}` })
        : Icons[icon]({ className: `h-5 w-5 ${ColorStyles[color]}` })}
      <div>{label}</div>
    </div>
  )
}

export { MenuItem }
