import {
  formatBoolean,
  formatInTimeZone,
  formatNumber,
  formatString,
  formatUnit,
  TIMEZONE,
} from '@invisible/common/helpers'
import { ICSVColumn, IResponseReportCSV } from '@invisible/common/types'
import { flow, isEmpty, join, map } from 'lodash/fp'
import { useMemo } from 'react'

const HASH_PERCENT_ENCODE = '%23'

const addRowTitles = (columns: ICSVColumn[]) => (rows: string[][]) => {
  const rowTitles = columns?.map((col: unknown) => (col as { header: string }).header)
  return [rowTitles, ...rows]
}
const formatRow = (columns: ICSVColumn[]) => (instance: Record<string, unknown>) => {
  const format: string[] = []
  columns?.forEach((col) => {
    let formattedValue = instance[col.accessor] as string
    switch (col.valueType) {
      case 'Date':
      case 'DateTime':
        formattedValue = formatInTimeZone({
          date: String(instance[col.accessor] || ''),
          format: `yyyy-MM-dd' ${TIMEZONE.UTC}'`,
        })
        break
      case 'String':
        formattedValue = formatString(String(instance[col.accessor]))
        break
      case 'Boolean':
        formattedValue = formatBoolean(Boolean(instance[col.accessor]))
        break
      default:
        formattedValue = formatNumber(Number(instance[col.accessor]))
        break
    }
    format.push(
      formatUnit({
        valueType: col.valueType,
        value: formattedValue,
        isCSVData: true,
      })
    )
  })
  return format
}

const mapToRows = (columns: ICSVColumn[]) => (instance: Record<string, unknown>[]) =>
  map(formatRow(columns))(instance)

const toCSVRows = (reportData: Record<string, unknown>[], columns: ICSVColumn[]): string =>
  flow(mapToRows(columns), addRowTitles(columns), map(join(',')), join('\n'), (uri: string) =>
    uri.replaceAll('#', HASH_PERCENT_ENCODE)
  )(reportData)

export const getURIEncodedCSVString = ({ columns, data }: IResponseReportCSV) => {
  const formattedCSVData: Record<string, unknown>[] = []
  data.forEach((datum) => {
    const obj: Record<string, unknown> = {}
    columns?.forEach((col) => {
      obj[col.accessor] = datum[col.accessor]
    })
    formattedCSVData.push(obj)
  })
  return `data:text/csv;charset=utf-8,${toCSVRows(formattedCSVData, columns)}`
}

export const useDownloadReportCSV = (dataForCsv: IResponseReportCSV | null) => {
  const downloadCsvEncodedUri = useMemo(() => {
    if (isEmpty(dataForCsv) || isEmpty(dataForCsv.columns) || isEmpty(dataForCsv.data)) return ''
    return getURIEncodedCSVString(dataForCsv)
  }, [dataForCsv])

  return downloadCsvEncodedUri
}
