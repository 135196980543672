import { SVGProps } from 'react'
function EyeClosed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={7}
      viewBox='0 0 17 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.217 1.15S11.78 4.145 8.772 4.145 3.327 1.15 3.327 1.15M8.772 4.28v2.57M5.849 3.487L4.612 5.77M3.537 1.598L1.326 3.222M11.705 3.487l1.238 2.282M14.017 1.598l2.212 1.624'
        stroke='currentColor'
      />
    </svg>
  )
}

export default EyeClosed
