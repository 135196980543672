import { SVGProps } from 'react'

function TaskRibbon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='38'
      viewBox='0 0 28 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8889 10.1254H19.4444C20.3052 10.1254 21 10.8254 21 11.6809V19.4432C21 20.3039 20.3052 20.9987 19.4444 20.9987H11.6822C10.8267 20.9987 10.1267 20.3039 10.1267 19.4432V17.8876H8.55556C7.69481 17.8876 7 17.1928 7 16.3321V8.55427C7 7.69353 7.69481 6.99872 8.55556 6.99872H16.3333C17.1941 6.99872 17.8889 7.69353 17.8889 8.55427V10.1254ZM9.98148 12.1476C9.84667 12.1476 9.70667 12.2046 9.60815 12.3083C9.41111 12.5158 9.4163 12.8424 9.6237 13.0446L10.1267 13.5269L11.1637 14.5172L11.2622 14.6106C11.3659 14.7039 11.4904 14.7558 11.62 14.7558C11.7496 14.7558 11.8793 14.7039 11.9778 14.6106L15.2652 11.4735C15.3585 11.3906 15.4104 11.2765 15.4207 11.1624C15.4363 11.0121 15.3896 10.8617 15.2807 10.7424C15.1822 10.6335 15.037 10.5817 14.9074 10.5817C14.7778 10.5817 14.6481 10.6283 14.5496 10.7269L14.0933 11.1624L11.62 13.5165L10.3393 12.2928C10.277 12.2306 10.2044 12.1891 10.1267 12.1735C10.08 12.1528 10.0281 12.1476 9.98148 12.1476ZM19.4444 19.9617C19.7296 19.9617 19.963 19.7283 19.963 19.4432V11.6809C19.963 11.3958 19.7296 11.1624 19.4444 11.1624H17.8889V16.3321C17.8889 17.1928 17.1941 17.8876 16.3333 17.8876H11.1637V19.4432C11.1637 19.7283 11.397 19.9617 11.6822 19.9617H19.4444Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.25 14C0.25 18.2698 2.19624 22.0851 5.25 24.6071V36.5C5.25 36.9332 5.47432 37.3356 5.84284 37.5633C6.21136 37.7911 6.67153 37.8118 7.05902 37.618L14 34.1475L20.941 37.618C21.3285 37.8118 21.7886 37.7911 22.1572 37.5633C22.5257 37.3356 22.75 36.9332 22.75 36.5V24.6071C25.8038 22.0851 27.75 18.2698 27.75 14C27.75 6.40608 21.5939 0.25 14 0.25C6.40608 0.25 0.25 6.40608 0.25 14ZM14 2.75C7.7868 2.75 2.75 7.7868 2.75 14C2.75 17.6658 4.50334 20.9221 7.21716 22.9761C7.23938 22.9917 7.26105 23.0079 7.28217 23.0249C9.15686 24.4226 11.4818 25.25 14 25.25C16.5182 25.25 18.8431 24.4226 20.7178 23.0249C20.7389 23.0079 20.7606 22.9917 20.7829 22.9761C23.4967 20.9221 25.25 17.6658 25.25 14C25.25 7.7868 20.2132 2.75 14 2.75Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TaskRibbon
