import { z } from '@invisible/zod'

const schema = z.object({
  allowZoom: z.boolean().optional(),
  imageDescriptionBaseVariableId: z.string().optional(),
  imageDescriptionStepTemplateVariableId: z.string().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
