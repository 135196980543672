import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { MouseEvent, useState } from 'react'

type TProps = {
  children: React.ReactNode
  title: string
}

export function MouseOverPopover({ children, title }: TProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        sx={{ cursor: 'pointer' }}
        className='hover:text-blue-700'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {title}
      </Typography>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          width: '70vw',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography sx={{ p: 1 }}>{children}</Typography>
      </Popover>
    </div>
  )
}
