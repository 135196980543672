import { z } from '@invisible/zod'

const datePresetEnum = z.enum([
  'today',
  'this_week',
  'this_month',
  'this_quarter',
  'this_year',
  'yesterday',
  'last_week',
  'last_month',
  'last_quarter',
  'last_year',
])

const processLayoutOrientationEnum = z.enum(['horizontal', 'vertical'])

const attributeNameEnum = z.enum([
  'X_AXIS',
  'Y_AXIS',
  'LABEL',
  'VALUE',
  'COLUMN',
  'LINEAR',
  'COUNTRY_CODE',
  'FILTER',
  'CSV_REPORT',
  'INTERVAL',
  'DRILL',
  'GROUP_BY',
  'COMPANY',
  'PROCESS',
  'TIMEZONE',
  'DYNAMIC',
  'BASE',
  'DATE_VIEW',
  'TILE',
  'CUSTOM_SELECT',
  'BASE_VARIABLE',
])

type TDatePresetEnum = z.infer<typeof datePresetEnum>
type TProcessLayoutOrientationEnum = z.infer<typeof processLayoutOrientationEnum>
type TAttributeNameEnum = z.infer<typeof attributeNameEnum>

export { attributeNameEnum, datePresetEnum, processLayoutOrientationEnum }
export type { TAttributeNameEnum, TDatePresetEnum, TProcessLayoutOrientationEnum }
