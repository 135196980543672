import { Button } from '@invisible/ui/button'
import { CircleArrowLeftIcon } from '@invisible/ui/icons'
import { Progress } from '@invisible/ui/progress'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { TNormalizedBaseRun } from './types'

interface IProps {
  allBaseRuns: TNormalizedBaseRun[]
  onSave: (dataToBeRanked: TNormalizedBaseRun[]) => void
  setCurrentView: Dispatch<SetStateAction<'view' | 'rank'>>
  isUpdatingManyVariables: boolean
  config: WizardSchemas.Ranking.TSchema
  readOnly: boolean
}

const QuickRank = ({
  allBaseRuns,
  onSave,
  setCurrentView,
  isUpdatingManyVariables,
  config,
  readOnly,
}: IProps) => {
  const [dataToBeRanked, setDataToBeRanked] = useState<TNormalizedBaseRun[]>(allBaseRuns)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [leftArr, setLeftArr] = useState<TNormalizedBaseRun[]>([])
  const [rightArr, setRightArr] = useState<TNormalizedBaseRun[]>([])

  const [pivot, setPivot] = useState<TNormalizedBaseRun | null>(allBaseRuns[allBaseRuns.length - 1])

  const [allPartitions, setAllPartitions] = useState<TNormalizedBaseRun[][]>([allBaseRuns])

  const [selectedOption, setSelectedOption] = useState<TNormalizedBaseRun | null>(null)

  const [currentProgress, setCurrentProgress] = useState(0)

  const refMain = useRef<HTMLDivElement>(null)
  const refA = useRef<HTMLDivElement>(null)
  const refB = useRef<HTMLDivElement>(null)

  const handleSave = () => {
    onSave(
      allPartitions
        .reduce((accumulator, value) => accumulator.concat(value), [])
        .map((baseRun, index) => ({ ...baseRun, rank: index + 1 }))
    )

    setDataToBeRanked([])
    setLeftArr([])
    setRightArr([])
    setPivot(null)
    setCurrentIndex(0)
    setSelectedOption(null)
  }

  useEffect(() => {
    // If the current index is the last index, we have finished ranking the current partition, and so we can update the allPartitions state.
    if (currentIndex >= dataToBeRanked.length - 1) {
      setAllPartitions((prev) => {
        const currArr = [...leftArr, pivot, ...rightArr]
        const newAllParts: TNormalizedBaseRun[][] = []

        // Below we find the partition that the new partitions should replace, and perform the replacement
        for (const p of prev) {
          if (p.length === currArr.length && p.every((element) => currArr.includes(element))) {
            if (leftArr.length > 0) newAllParts.push(leftArr)
            newAllParts.push([pivot as TNormalizedBaseRun])
            if (rightArr.length > 0) newAllParts.push(rightArr)
          } else {
            newAllParts.push(p)
          }
        }
        return newAllParts
      })
    }
  }, [currentIndex])

  useEffect(() => {
    for (const partition of allPartitions) {
      // As long as we have any partition that is not an individual element, we continue, as QuickSort is inherently recursive.
      if (partition.length > 1) {
        setDataToBeRanked(partition)
        setLeftArr([])
        setRightArr([])
        setPivot(partition[partition.length - 1])
        setCurrentIndex(0)
        setSelectedOption(null)
        break
      }
    }

    // If all partitions are individual elements, we are done and can save the rankings.
    if (allPartitions.every((partition) => partition.length === 1)) {
      handleSave()
    }
  }, [allPartitions])

  const handleUserDecision = (chosenItem: TNormalizedBaseRun) => {
    if (!pivot || readOnly) return

    const otherItem =
      chosenItem === pivot ? dataToBeRanked[currentIndex] : (pivot as TNormalizedBaseRun)

    // If pivot is picked, it means the pivot is "better", and so we put the item on the right. And vice versa.
    if (chosenItem === pivot) {
      setRightArr((prev) => [...prev, otherItem])
    } else {
      setLeftArr((prev) => [...prev, chosenItem])
    }

    setCurrentIndex((prev) => prev + 1)
    setSelectedOption(null)
  }

  const reset = () => {
    setDataToBeRanked(allBaseRuns)
    setCurrentIndex(0)
    setSelectedOption(null)
    setLeftArr([])
    setRightArr([])
    setPivot(allBaseRuns[allBaseRuns.length - 1])
    setAllPartitions([allBaseRuns])
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.code) {
      case 'KeyA': // A
        refA.current?.focus()
        setSelectedOption(dataToBeRanked[currentIndex])
        break

      case 'KeyB': // B
        refB.current?.focus()
        setSelectedOption(pivot)
        break

      case 'Enter': // Enter
        if (selectedOption) handleUserDecision(selectedOption)
        break
    }
  }

  // The below useEffect is used to calculate the real-time progress. Not accurate yet, but good enough for now.
  useEffect(() => {
    const numberOfFinishedPartitions = allPartitions.filter(
      (partition) => partition.length === 1
    ).length
    const progressAtStartOfRound = numberOfFinishedPartitions / allBaseRuns.length
    const estimatedProgressAtEndOfCurrentRound =
      (numberOfFinishedPartitions + 1) / allBaseRuns.length

    setCurrentProgress(
      progressAtStartOfRound +
        (estimatedProgressAtEndOfCurrentRound - progressAtStartOfRound) *
          (currentIndex / (dataToBeRanked.length + 1))
    )
  }, [allPartitions, currentIndex])

  useEffect(() => {
    // When a selection is saved, the focus goes to the main div
    if (selectedOption === null) {
      refA.current?.blur()
      refB.current?.blur()
      refMain.current?.focus()
    }
  }, [currentProgress])

  return (
    <div
      className='relative flex h-full w-full flex-col justify-between p-6 focus:outline-none'
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={refMain}>
      <Progress percentage={true} progress={currentProgress} width={1400} color={'black'} />
      {pivot ? (
        <div className='overflow-auto px-16'>
          <p className='text-lg'>
            Which of these responses is better in terms of{' '}
            <span className='font-bold'>{config.rankingCriteria}</span>?
          </p>
          <div className='grid grid-cols-12 gap-0'>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={0}
              ref={refA}
              onClick={() => handleUserDecision(dataToBeRanked[currentIndex])}
              tabIndex={1}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>A</div>
              {dataToBeRanked[currentIndex].message}
            </div>
            <div className='col-span-1 my-12 text-2xl'>Or...</div>
            <div
              className='relative col-span-5 m-2 my-10 mr-0 max-w-md rounded-lg bg-gray-100 py-8 px-12 shadow-lg hover:bg-gray-200 focus:bg-gray-400 focus:outline-none'
              key={1}
              ref={refB}
              onClick={() => handleUserDecision(pivot)}
              tabIndex={2}>
              <div className='absolute left-0 top-7 mx-3 text-3xl font-bold text-black'>B</div>
              {pivot.message}
            </div>
            {selectedOption !== null ? (
              <div className='col-span-1 m-2 my-10 mr-0 flex items-center'>
                <CircleArrowLeftIcon
                  color='black'
                  height={24}
                  width={24}
                  onClick={() => handleUserDecision(selectedOption)}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <h2 className='flex items-center justify-center'>
          Finished! The new rankings will be auto-saved now...
        </h2>
      )}
      <div className='flex items-center justify-center gap-6 p-3'>
        <Button
          disabled={
            isUpdatingManyVariables ||
            !allPartitions.every((partition) => partition.length === 1) ||
            readOnly
          }
          onClick={() => {
            handleSave()
            onSave(dataToBeRanked)
          }}>
          {isUpdatingManyVariables ? 'Saving...' : 'Save'}
        </Button>

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            reset()
          }}>
          Reset
        </Button>

        <Button
          disabled={isUpdatingManyVariables || readOnly}
          onClick={() => {
            setCurrentView('view')
          }}>
          View Current Order
        </Button>
      </div>
    </div>
  )
}

export { QuickRank }
