import { Button, ButtonLabelColor, ButtonVariant } from '@invisible/ui/button'
import { Icons } from '@invisible/ui/icons'
import type { StepRunStatus } from '@invisible/ultron/prisma'
import { FC } from 'react'

type IWizardStatus = {
  [status in StepRunStatus]: {
    text: string
    icon: keyof typeof Icons
    variant: ButtonVariant
    color?: ButtonLabelColor
  }
}

const WIZARD_STATUS: IWizardStatus = {
  pending: {
    text: 'Start',
    icon: 'RocketFilledIcon',
    variant: 'primary',
  },
  disabled: {
    text: 'Start',
    icon: 'RocketFilledIcon',
    variant: 'secondary',
  },
  done: {
    text: 'Done',
    icon: 'CheckCircleOutlineIcon',
    variant: 'secondary',
    color: 'success',
  },
  snoozed: {
    text: 'Unsnooze',
    icon: 'SnoozeOutlineIcon',
    variant: 'primary',
  },
  queued: {
    text: 'Queued',
    icon: 'DashCircleFilledIcon',
    color: 'danger',
    variant: 'secondary',
  },
  failed: {
    text: 'Redo',
    icon: 'RotateRightIcon',
    variant: 'primary',
  },
  running: {
    text: 'In Progress',
    icon: 'HourglassFilledIcon',
    variant: 'primary',
  },
  expired: {
    text: 'Expired',
    icon: 'CircledExclamationIcon',
    variant: 'danger',
    color: 'danger',
  },
}

interface IWizardCellProps {
  status?: StepRunStatus
  disabled: boolean
  loading?: boolean
  onClick?: () => void
}

const WizardCell = ({ onClick, status = 'pending', disabled, loading }: IWizardCellProps) => {
  if (['pending', 'disabled', 'running', 'failed'].includes(status))
    return (
      <Button
        wFull
        onClick={onClick}
        variant={WIZARD_STATUS[status].variant}
        color={WIZARD_STATUS[status]?.color}
        size='md'
        disabled={disabled || status === 'disabled'}
        iconLeft={WIZARD_STATUS[status].icon}>
        {WIZARD_STATUS[status].text}
      </Button>
    )

  if (status === 'snoozed') {
    return (
      <Button
        wFull
        onClick={onClick}
        variant={WIZARD_STATUS[status].variant}
        color={WIZARD_STATUS[status]?.color}
        size='md'
        loading={loading}
        disabled={disabled}
        iconLeft={WIZARD_STATUS[status].icon}>
        {WIZARD_STATUS[status].text}
      </Button>
    )
  }

  return (
    <div className='border-weak flex h-8 w-full items-center justify-center space-x-2 rounded-md border border-solid'>
      {Icons[WIZARD_STATUS[status].icon]({ className: 'w-4 h-4' })}
      <div>{WIZARD_STATUS[status].text}</div>
    </div>
  )
}

export { WIZARD_STATUS, WizardCell }
