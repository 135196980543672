import { formatAsUTC, secondsToHMS } from '@invisible/common/date'
import { classNames } from '@invisible/common/helpers'
import { useContext, useMutation } from '@invisible/trpc/client'
import { formatMillicents, formatToDollar } from '@invisible/ui/helpers'
import { Modal } from '@invisible/ui/modal'
import {
  BulkActionButton,
  createColumnHelper,
  RowSelectionState,
  Table,
  Updater,
} from '@invisible/ui/table'
import { useToasts } from '@invisible/ui/toasts'
import type { TBillEventType } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import axios from 'axios'
import { compact, isEmpty, map } from 'lodash/fp'
import { FC, useMemo } from 'react'

type TBillEvent = NonNullable<
  inferQueryOutput<'billEvent.getCycleBreakdownForProcess'>
>[number]['billEvents'][number]

type TAction = {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}
interface DetailedBillEventsProps {
  showModal: boolean
  closeModal: () => void
  billEvents: TBillEvent[]
  canUpdateBillEvent?: boolean
  canDeleteBillEvent?: boolean
}

const ITEMS_PER_PAGE = 10

const columnHelper = createColumnHelper<TBillEvent>()

const columns = [
  columnHelper.accessor('userName', {
    header: () => <div>Agent</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('stepName', {
    header: () => <div>Step</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('baseRunId', {
    header: () => <div>Base Run</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor(
    (row) => {
      const multiplier = row.timeTrackingEnabled ? secondsToHMS(row.multiplier) : row.multiplier
      if (row.timeTrackingEnabled) return multiplier

      return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
    },
    {
      id: 'multiplier',
      header: () => <div>Multiplier</div>,
      cell: (props) => (
        <Table.Cell itemsCenter>
          <MultiplierCell row={props.row.original} />
        </Table.Cell>
      ),
      filterFn: (row, column, value: string[]) => {
        if (isEmpty(value)) return true

        const multiplier = row.original.timeTrackingEnabled
          ? secondsToHMS(row.original.multiplier)
          : row.original.multiplier

        if (row.original.timeTrackingEnabled) return value.includes(multiplier as string)

        return value.includes(`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`)
      },
    }
  ),

  columnHelper.accessor((row) => formatToDollar(row.rate), {
    id: 'rate',
    header: () => <div>Rate</div>,
    cell: (props) => {
      const row = props.row.original

      return (
        <Table.Cell itemsCenter>
          <div className='px-3'>{`${props.getValue()}${
            row.timeTrackingEnabled ? ' / Hour' : ' / Unit'
          }`}</div>
        </Table.Cell>
      )
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(formatToDollar(row.original.rate))
    },
  }),

  columnHelper.accessor((row) => row.status, {
    id: 'amount',
    header: () => <div>Amount</div>,
    cell: (props) => {
      const row = props.row.original
      const status = row.status

      return (
        <Table.Cell itemsCenter>
          <div className='space-y-2'>
            <div>{formatMillicents(row.amount)}</div>
            <div className='flex items-center gap-2'>
              <div
                className={classNames(
                  'flex h-2 w-2 rounded-full',
                  status === 'Drafted'
                    ? 'bg-theme-main'
                    : status === 'Pre-Approved'
                    ? 'bg-sky-main'
                    : 'bg-red-main'
                )}
              />
              {status}
            </div>
          </div>
        </Table.Cell>
      )
    },
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(row.original.status)
    },
  }),

  columnHelper.accessor('createdAt', {
    header: () => <div>Created At</div>,
    cell: (props) => (
      <Table.Cell itemsCenter>
        <div className='px-3'>{formatAsUTC(props.getValue(), 'yyyy-MM-dd')}</div>
      </Table.Cell>
    ),
  }),
]

// eslint-disable-next-line @typescript-eslint/ban-types
const DetailedBillEvents: FC<DetailedBillEventsProps> = ({
  showModal,
  closeModal,
  billEvents,
  canUpdateBillEvent,
  canDeleteBillEvent,
}) => {
  const { addToast } = useToasts()

  const reactQueryClient = useContext()

  const { mutateAsync: approveBillEvents } = useMutation('billEvent.approveMany', {
    onMutate: () => {
      addToast('Approving Bill events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: async (data) => {
      addToast(
        `Approved bill event(s): ${compact(data)
          .map(({ id }) => id)
          .join(', ')}`,
        {
          appearance: 'success',
        }
      )
      compact(data).forEach((billEvent) => {
        billEvents.forEach(({ id }, i) => {
          if (id === billEvent.id)
            billEvents[i].status =
              billEvent.status === 'pre_approved'
                ? 'Pre-Approved'
                : billEvent.status === 'drafted'
                ? 'Drafted'
                : 'Not Approved'
        })
      })
    },

    onError: (error) => {
      addToast(`Approval failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const { mutateAsync: unapproveBillEvents } = useMutation('billEvent.unapproveMany', {
    onMutate: () => {
      addToast('Unapproving Bill events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: async (data) => {
      addToast(
        `Unapproved bill event(s): ${compact(data)
          .map(({ id }) => id)
          .join(', ')}`,
        {
          appearance: 'success',
        }
      )
      compact(data).forEach((billEvent) => {
        billEvents.forEach(({ id }, i) => {
          if (id === billEvent.id)
            billEvents[i].status =
              billEvent.status === 'pre_approved'
                ? 'Pre-Approved'
                : billEvent.status === 'drafted'
                ? 'Drafted'
                : 'Not Approved'
        })
      })
    },

    onError: (error) => {
      addToast(`Unapproval failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const { mutateAsync: deleteManyManualBillEvents } = useMutation('billEvent.deleteManyManual', {
    onMutate: () => {
      addToast('Deleting Manual Bill Events', {
        appearance: 'info',
        loading: true,
        loadingTime: 1200,
        lifeSpan: 1200,
      })
    },

    onSuccess: () => {
      addToast('Successfully deleted bill event(s)', {
        appearance: 'success',
      })
    },

    onError: (error) => {
      addToast(`Deletion failed: ${error?.message}`, {
        appearance: 'error',
      })
    },

    onSettled: () => {
      reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
    },
  })

  const UnapproveBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Unapprove'
            icon='DashCircleOutlineIcon'
            onClick={async () => {
              const selectedBillEvents = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = map((row: TBillEvent) => row.id)(selectedBillEvents)
              await unapproveBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const ApproveBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Approve'
            icon='CheckCircleOutlineIcon'
            onClick={async () => {
              const selectedBillEvents = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = map((row: TBillEvent) => row.id)(selectedBillEvents)
              await approveBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const MoveToPreviousCycleBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Request to Move to Previous Cycle'
            icon='HistoryIcon'
            onClick={async () => {
              const selectedBillEvents = billEvents?.filter((_, key) => rowSelection[key])
              const selectedBillEventIds = map((row: TBillEvent) => row.id)(selectedBillEvents)
              await axios
                .post('/api/redis/change-bill-request', {
                  selectedBillEventIds,
                })
                .then((res) => {
                  if (res.status === 200) {
                    addToast('Bill change request submitted', {
                      appearance: 'success',
                    })
                  } else {
                    addToast('Failed to submit bill change request', {
                      appearance: 'error',
                    })
                  }
                })
                .catch(() => {
                  addToast('Failed to submit bill change request', {
                    appearance: 'error',
                  })
                })
              setRowSelection({})
            }}
          />
        ),
    [billEvents]
  )

  const DeleteManualBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) => {
        const selectedBillEvents = billEvents?.filter((_, key) => rowSelection[key])

        // Don't show delete bulk action if all selected rows are not manual events
        if (
          !isEmpty(selectedBillEvents) &&
          !selectedBillEvents.every(({ type }) => (type as TBillEventType) === 'manual')
        )
          return null

        return (
          <BulkActionButton
            title='Delete'
            text='Delete'
            icon='DeleteOutlineIcon'
            onClick={async () => {
              const selectedManualBillEvents = billEvents
                ?.filter((_, key) => rowSelection[key])
                ?.filter((event) => (event.type as TBillEventType) === 'manual')
              const selectedBillEventIds = map((row: TBillEvent) => row.id)(
                selectedManualBillEvents
              )
              await deleteManyManualBillEvents({ billEventIds: selectedBillEventIds })
              setRowSelection({})
            }}
          />
        )
      },
    [billEvents]
  )

  const BulkActions = useMemo(
    () => (props: TAction) =>
      (
        <>
          <ApproveBulkAction {...props} />
          <UnapproveBulkAction {...props} />
          <MoveToPreviousCycleBulkAction {...props} />
          {canDeleteBillEvent ? <DeleteManualBulkAction {...props} /> : null}
        </>
      ),
    [
      ApproveBulkAction,
      DeleteManualBulkAction,
      UnapproveBulkAction,
      canDeleteBillEvent,
      MoveToPreviousCycleBulkAction,
    ]
  )

  return (
    <Modal
      onClose={closeModal}
      visible={showModal}
      title='Detailed Bill Events'
      icon='InfoFilledIcon'
      className='my-1 max-h-[100vh] overflow-y-auto'>
      <div className='w-full'>
        <Table.PaginationProvider>
          <div className='flex w-full'>
            <Table
              data={billEvents ?? []}
              columns={columns}
              defaultPageSize={ITEMS_PER_PAGE}
              {...(canUpdateBillEvent ? { BulkSelectActions: BulkActions } : {})}
              key={JSON.stringify(billEvents)}
            />
          </div>

          <div className='my-4 flex items-center justify-end'>
            <Table.Pagination />
          </div>
        </Table.PaginationProvider>
      </div>
    </Modal>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MultiplierCell: FC<{ row: TBillEvent }> = ({ row }) => {
  const multiplier = row.timeTrackingEnabled ? secondsToHMS(row.multiplier) : row.multiplier

  return (
    <div className='flex items-center gap-1 px-3'>
      <div
        className={classNames(
          'flex min-w-0 max-w-fit rounded-md border border-solid py-1 px-2',
          row.timeTrackingEnabled
            ? 'border-orange-400 bg-orange-100'
            : 'border-theme-strong bg-theme-weak-3'
        )}>
        {row.timeTrackingEnabled ? (
          <div className='flex items-center gap-1 text-orange-400'>
            <span>{multiplier}</span>
          </div>
        ) : (
          <div className='text-theme-strong flex items-center gap-1'>
            <span>{multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export { DetailedBillEvents }
