import { SVGProps } from 'react'
function LogicFlow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={48}
      viewBox='0 0 50 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.958 14.883l6.872 7.465H.302v3.672H15.83l-6.872 7.465 2.702 2.486 10.853-11.787L11.66 12.39l-2.702 2.492zM40.89 10.77l2.701 2.486 6.107-6.625L43.59 0 40.89 2.487l2.303 2.502h-8.126V8.66h7.764L40.89 10.77zM40.89 20.04l2.124 2.308h-7.947v3.672h7.947l-2.124 2.303 2.701 2.491 6.107-6.63-6.107-6.631-2.701 2.486zM40.89 37.23l2.303 2.503h-8.126v3.672h7.764l-1.941 2.108L43.59 48l6.107-6.626-6.107-6.63-2.701 2.486zM29.611 3.163H25.94v42.04h3.672V3.164z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LogicFlow
