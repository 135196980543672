import { z } from '@invisible/zod'

const schema = z.object({
  to: z.array(z.string()).optional(),
  threadReply: z.boolean().optional(),
  cc: z.array(z.string()).optional(),
  bcc: z.array(z.string()).optional(),
  subject: z.string().optional(),
  body: z.string().optional(),

  /**
   * Legacy: DAL process id, not manticore id
   */
  dalProcessId: z.number().optional(),
  completeStepRunOnSubmit: z.boolean().optional(),
  dalAssistant: z
    .object({
      name: z.string(),
      email: z.string(),
    })
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
