import { SVGProps } from 'react'

function DocumentationFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.356 25C13.1506 25.0033 13.9117 25.3204 14.4736 25.8823C15.0355 26.4442 15.3527 27.2054 15.356 28C15.356 28.2652 15.4613 28.5196 15.6489 28.7071C15.8364 28.8946 16.0907 29 16.356 29C16.6212 29 16.8755 28.8946 17.0631 28.7071C17.2506 28.5196 17.356 28.2652 17.356 28C17.3592 27.2054 17.6764 26.4442 18.2383 25.8823C18.8002 25.3204 19.5613 25.0033 20.356 25H28.356C28.8864 25 29.3951 24.7893 29.7702 24.4142C30.1452 24.0391 30.356 23.5304 30.356 23V7C30.356 6.46957 30.1452 5.96086 29.7702 5.58579C29.3951 5.21071 28.8864 5 28.356 5H22.356C21.0299 5 19.7581 5.52678 18.8204 6.46447C17.8827 7.40215 17.356 8.67392 17.356 10V20C17.356 20.2652 17.2506 20.5196 17.0631 20.7071C16.8755 20.8946 16.6212 21 16.356 21C16.0907 21 15.8364 20.8946 15.6489 20.7071C15.4613 20.5196 15.356 20.2652 15.356 20V10C15.356 8.67392 14.8292 7.40215 13.8915 6.46447C12.9538 5.52678 11.682 5 10.356 5H4.35596C3.82552 5 3.31682 5.21071 2.94174 5.58579C2.56667 5.96086 2.35596 6.46957 2.35596 7V23C2.35596 23.5304 2.56667 24.0391 2.94174 24.4142C3.31682 24.7893 3.82552 25 4.35596 25H12.356Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocumentationFilled
