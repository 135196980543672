import { palette } from '../palette'

const MuiAvatar = {
  styleOverrides: {
    root: {
      backgroundColor: palette.primary.main,
    },
  },
}
export default MuiAvatar
