import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAbilityContext } from '@invisible/authorization/client'
import { formatAsUTC, secondsToHMS } from '@invisible/common/date'
import { classNames, downloadCsvData } from '@invisible/common/helpers'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@invisible/ui/dropdown'
import { formatMillicents, formatToDollar } from '@invisible/ui/helpers'
import { Skeleton } from '@invisible/ui/skeleton'
import {
  BulkActionButton,
  createColumnHelper,
  RowSelectionState,
  Table,
  Updater,
} from '@invisible/ui/table'
import { Tag } from '@invisible/ui/tag'
import { useToasts } from '@invisible/ui/toasts'
import type { PrismaAll, TInvoiceEventType } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { InvoiceEventMeta } from '@invisible/ultron/zod'
import { TUuid } from '@invisible/zod'
import axios from 'axios'
import { compact, first, flatten, flow, isEmpty, map, truncate, uniqBy } from 'lodash/fp'
import { unparse } from 'papaparse'
import { useEffect, useMemo, useState } from 'react'

import { MenuItem } from './baseViewComponents/MenuItem'
import { DetailedInvoiceEvents } from './financeViewComponents/DetailedInvoiceEvents'
import { ManualInvoiceEventModal } from './financeViewComponents/ManualInvoiceEventModal'
import { PreviousPeriodAdjustmentInvoiceEventModal } from './financeViewComponents/PreviousPeriodAdjustmentInvoiceEventModal'

type TAction = {
  rowSelection: RowSelectionState
  setRowSelection: (selectedRows: Updater<RowSelectionState>) => void
}

type InvoiceEventsByCommodity =
  inferQueryOutput<'invoiceEvent.getProcessInvoiceOverview'>['summaryByCommodity'][number]

type InvoiceEvent = InvoiceEventsByCommodity['invoiceEvents'][number]

const ITEMS_PER_PAGE = 10

const columnHelper = createColumnHelper<
  InvoiceEventsByCommodity & {
    onRowClick: (row: InvoiceEventsByCommodity) => void
    handleBillingRowCSVDownload: (row: InvoiceEventsByCommodity) => void
  }
>()

const columns = [
  columnHelper.accessor('commodityName', {
    header: () => <div>Commodity</div>,
    cell: (props) => {
      const row = props.row.original
      return (
        <Table.Cell itemsCenter>
          <div className='space-y-1'>
            <span
              className='text-theme-main cursor-pointer hover:underline'
              onClick={() => row.onRowClick(row)}>
              {props.getValue()}
            </span>
            {(row.type as TInvoiceEventType) === 'manual' ? <Tag color='pink'>Manual</Tag> : null}
          </div>
        </Table.Cell>
      )
    },
  }),

  columnHelper.accessor('stepName', {
    header: () => <div>Step</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor('baseName', {
    header: () => <div>Base</div>,
    cell: (props) => <Table.Cell itemsCenter>{props.getValue()}</Table.Cell>,
  }),

  columnHelper.accessor((row) => row.invoiceEvents.length, {
    id: 'stepRuns',
    header: () => <div>Runs</div>,
    cell: (props) => (
      <Table.Cell itemsCenter>
        <div className='px-3'>{props.row.original.invoiceEvents.length}</div>
      </Table.Cell>
    ),
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(String(row.original.invoiceEvents.length))
    },
  }),

  columnHelper.accessor(
    (row) => {
      const multiplier = row.timeTrackingEnabled
        ? secondsToHMS(row.totalMultiplier / 1000)
        : row.totalMultiplier

      if (row.timeTrackingEnabled) return multiplier

      return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
    },
    {
      id: 'quantity',
      header: () => <div>Quantity</div>,
      cell: (props) => {
        const row = props.row.original
        const multiplier = row.timeTrackingEnabled
          ? secondsToHMS(row.totalMultiplier / 1000)
          : row.totalMultiplier

        return (
          <Table.Cell itemsCenter>
            <div className='px-3'>
              <div
                className={classNames(
                  'flex min-w-0 max-w-fit rounded-md border border-solid py-1 px-2',
                  row.timeTrackingEnabled
                    ? 'border-orange-400 bg-orange-100'
                    : 'border-theme-strong bg-theme-weak-3'
                )}>
                {row.timeTrackingEnabled ? (
                  <div className='flex items-center gap-1 text-orange-400'>
                    <span>{multiplier}</span>
                  </div>
                ) : (
                  <div className='text-theme-strong flex items-center gap-1'>
                    <span>{multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}</span>
                  </div>
                )}
              </div>
            </div>
          </Table.Cell>
        )
      },
      filterFn: (row, column, value: string[]) => {
        if (isEmpty(value)) return true

        const multiplier = row.original.timeTrackingEnabled
          ? secondsToHMS(row.original.totalMultiplier / 1000)
          : row.original.totalMultiplier

        if (row.original.timeTrackingEnabled) return value.includes(multiplier as string)

        return value.includes(`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`)
      },
    }
  ),

  columnHelper.accessor(
    (row) => formatToDollar((row.invoiceEvents[0].meta as InvoiceEventMeta.TSchema).rate),
    {
      id: 'rate',
      header: () => <div>Rate</div>,
      cell: (props) => {
        const row = props.row.original

        return (
          <Table.Cell itemsCenter>
            <div className='px-3'>{`${props.getValue()}${
              row.timeTrackingEnabled ? ' / Hour' : ' / Unit'
            }`}</div>
          </Table.Cell>
        )
      },
      filterFn: (row, column, value: string[]) => {
        if (isEmpty(value)) return true

        return value.includes(
          formatToDollar((row.original.invoiceEvents[0].meta as InvoiceEventMeta.TSchema).rate)
        )
      },
    }
  ),

  columnHelper.accessor((row) => formatMillicents(row.totalAmount), {
    id: 'total',
    header: () => <div>Total</div>,
    cell: (props) => (
      <Table.Cell itemsCenter>
        <div className='px-3'>{props.getValue()}</div>
      </Table.Cell>
    ),
    filterFn: (row, column, value: string[]) => {
      if (isEmpty(value)) return true

      return value.includes(formatMillicents(row.original.totalAmount))
    },
  }),

  columnHelper.display({
    id: 'download',
    cell: (props) => {
      const row = props.row.original
      return (
        <Table.Cell itemsCenter>
          <Button
            variant='subtle'
            size='md'
            shape='square'
            icon='DownloadFilledIcon'
            color='theme'
            title='Download CSV'
            onClick={() => row.handleBillingRowCSVDownload(row)}
          />
        </Table.Cell>
      )
    },
    meta: {
      width: '5%',
    },
  }),
]

export const UsagePage = ({ companyId, processId }: { companyId: TUuid; processId: TUuid }) => {
  const reactQueryClient = useContext()

  const [Can, ability] = useAbilityContext()

  const { addToast } = useToasts()

  const canDeleteInvoiceEvent = ability?.can('delete', 'InvoiceEvent')

  const [invoiceId, setInvoiceId] = useState('')

  const [invoiceStatus, setInvoiceStatus] = useState('')

  const [selectedKey, setSelectedKey] = useState<string>()

  const [showDetailedInvoiceEventsModal, setShowDetailedInvoiceEventsModal] = useState(false)

  const [detailedInvoiceEvents, setDetailedInvoiceEvents] = useState<InvoiceEvent[]>()

  const [showManualInvoiceEventModal, setShowManualInvoiceEventModal] = useState(false)
  const [
    showPreviousPeriodAdjustmentInvoiceEventModal,
    setShowPreviousPeriodAdjustmentInvoiceEventModal,
  ] = useState(false)

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery(
    ['invoice.findForCompany', { companyId }],
    { refetchOnMount: false, refetchOnWindowFocus: false }
  )

  const {
    data: invoiceEvents,
    isLoading,
    refetch: refetchInvoiceOverview,
  } = useQuery(['invoiceEvent.getProcessInvoiceOverview', { invoiceId, processId }])

  const { data: processDLUR, isLoading: isFetchingDLUR } = useQuery(
    [
      'process.getProcessDLUR',
      {
        processId,
        startDate: compact(invoices).find(({ id }) => id === invoiceId)?.startDate as Date,
        endDate: compact(invoices).find(({ id }) => id === invoiceId)?.endDate as Date,
      },
    ],
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { mutateAsync: deleteManyManualInvoiceEvents } = useMutation(
    'invoiceEvent.deleteManyManual',
    {
      onMutate: () => {
        addToast('Deleting Manual Invoice Events', {
          appearance: 'info',
          loading: true,
          loadingTime: 1200,
          lifeSpan: 1200,
        })
      },

      onSuccess: () => {
        addToast('Successfully deleted invoice event(s)', {
          appearance: 'success',
        })
      },

      onError: (error) => {
        addToast(`Deletion failed: ${error?.message}`, {
          appearance: 'error',
        })
      },

      onSettled: () => {
        reactQueryClient.invalidateQueries('invoiceEvent.getProcessInvoiceOverview')
      },
    }
  )

  const uniqueInvoices = useMemo(
    () =>
      uniqBy((inv: PrismaAll.Invoice) => inv.startDate.getTime())(
        invoices?.sort(
          (a, b) => b.startDate.getTime() - a.startDate.getTime()
        ) as PrismaAll.Invoice[]
      ),
    [invoices]
  )

  useEffect(() => {
    if (uniqueInvoices.length > 0) {
      const recentInvoice = uniqueInvoices[0]

      setInvoiceId(recentInvoice.id)
      setInvoiceStatus(recentInvoice.status)
      setSelectedKey(recentInvoice.id)
    }

    // Discard subscriptions to state changes to avoid memory leaks
    return () => {
      setInvoiceId('')
      setInvoiceStatus('')
      setSelectedKey(undefined)
    }
  }, [uniqueInvoices])

  const onRowClick = (row: InvoiceEventsByCommodity) => {
    if (!row.invoiceEvents) return
    setDetailedInvoiceEvents(row.invoiceEvents)
    setShowDetailedInvoiceEventsModal(true)
  }

  const closeModal = () => {
    setDetailedInvoiceEvents(undefined)
    setShowDetailedInvoiceEventsModal(false)
  }

  const handleBillingSummaryCSVDownload = () => {
    const headers = ['Commodity', 'Step', 'Base', 'Runs', 'Quantity', 'Rate', 'Total']
    const invoiceEventsData = compact(
      invoiceEvents?.summaryByCommodity.map((invoiceEventsByCommodity) => ({
        commodityName: invoiceEventsByCommodity.commodityName,
        stepName: invoiceEventsByCommodity.stepName,
        baseName: invoiceEventsByCommodity.baseName,
        runs: `${invoiceEventsByCommodity.invoiceEvents.length}`,
        quantity: invoiceEventsByCommodity.timeTrackingEnabled
          ? secondsToHMS(invoiceEventsByCommodity.totalMultiplier / 1000)
          : `${invoiceEventsByCommodity.totalMultiplier}`,
        rate: formatToDollar(
          (first(invoiceEventsByCommodity.invoiceEvents)?.meta as InvoiceEventMeta.TSchema).rate ??
            0
        ),
        total: formatMillicents(invoiceEventsByCommodity.totalAmount),
      }))
    )

    const csvData = unparse({
      fields: headers,
      data: invoiceEventsData.map((row) => Object.values(row)),
    })
    const startDate = compact(invoices).find(({ id }) => id === invoiceId)?.startDate as Date
    const endDate = compact(invoices).find(({ id }) => id === invoiceId)?.endDate as Date

    downloadCsvData(csvData, `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`)
  }

  const handleBillingRowCSVDownload = (row: InvoiceEventsByCommodity) => {
    const headers = [
      'Step',
      'Step Assignee',
      'Base Run',
      'Multiplier',
      'Rate',
      'Amount',
      'Created At',
    ]
    const invoiceEventsData = compact(row.invoiceEvents).map((invoiceEvent) => ({
      stepName: invoiceEvent.stepName,
      stepAssignee: invoiceEvent.stepAssignee,
      baseRun: invoiceEvent.baseRunId,
      multiplier: invoiceEvent.timeTrackingEnabled
        ? secondsToHMS(invoiceEvent.multiplier / 1000)
        : `${invoiceEvent.multiplier}`,
      rate: formatToDollar(invoiceEvent.rate),
      amount: formatMillicents(invoiceEvent.amount),
      createdAt: invoiceEvent.createdAt.toISOString(),
    }))
    const csvData = unparse({
      fields: headers,
      data: invoiceEventsData.map((row) => Object.values(row)),
    })
    const startDate = compact(invoices).find(({ id }) => id === invoiceId)?.startDate as Date
    const endDate = compact(invoices).find(({ id }) => id === invoiceId)?.endDate as Date

    downloadCsvData(
      csvData,
      `${row.commodityName} (${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()})`
    )
  }

  const DeleteManualBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) => {
        const selectedInvoiceEventOverviews = invoiceEvents?.summaryByCommodity?.filter(
          (_, key) => rowSelection[key]
        )

        // Don't show delete bulk action if all selected rows are not manual events
        if (
          !isEmpty(selectedInvoiceEventOverviews) &&
          !selectedInvoiceEventOverviews?.every(
            ({ type }) => (type as TInvoiceEventType) === 'manual'
          )
        )
          return null

        return (
          <BulkActionButton
            title='Delete'
            icon='DeleteOutlineIcon'
            onClick={async () => {
              const selectedManualInvoiceEventOverviews = invoiceEvents?.summaryByCommodity
                ?.filter((_, key) => rowSelection[key])
                ?.filter((summary) => (summary.type as TInvoiceEventType) === 'manual')
              const selectedInvoiceEventIds = flow(
                map((row: InvoiceEventsByCommodity) => row.invoiceEvents),
                flatten,
                map((row) => row.id)
              )(selectedManualInvoiceEventOverviews)
              await deleteManyManualInvoiceEvents({ invoiceEventIds: selectedInvoiceEventIds })
              setRowSelection({})
            }}
          />
        )
      },
    [invoiceEvents?.summaryByCommodity]
  )

  const MoveToPreviousCycleBulkAction = useMemo(
    () =>
      ({ rowSelection, setRowSelection }: TAction) =>
        (
          <BulkActionButton
            title='Request to Move to Previous Cycle'
            icon='HistoryIcon'
            onClick={async () => {
              const selectedInvoiceEventOverviews = invoiceEvents?.summaryByCommodity?.filter(
                (_, key) => rowSelection[key]
              )
              const selectedInvoiceEventIds = flow(
                map((row: InvoiceEventsByCommodity) => row.invoiceEvents),
                flatten,
                map((row) => row.id)
              )(selectedInvoiceEventOverviews)
              await axios
                .post('/api/redis/change-invoice-request', {
                  selectedInvoiceEventIds,
                })
                .then((res) => {
                  if (res.status === 200) {
                    addToast('Invoice change request submitted', {
                      appearance: 'success',
                    })
                  } else {
                    addToast('Failed to submit invoice change request', {
                      appearance: 'error',
                    })
                  }
                })
                .catch(() => {
                  addToast('Failed to submit invoice change request', {
                    appearance: 'error',
                  })
                })
              setRowSelection({})
            }}
          />
        ),
    [invoiceEvents?.summaryByCommodity]
  )

  const BulkActions = useMemo(
    () => (props: TAction) =>
      (
        <>
          <MoveToPreviousCycleBulkAction {...props} />
          <DeleteManualBulkAction {...props} />
        </>
      ),
    [DeleteManualBulkAction, MoveToPreviousCycleBulkAction]
  )

  return (
    <div className='space-y-5 p-4'>
      {!isLoadingInvoices && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-3'>
            <div className='text-lg font-bold'>Usage</div>
            {invoiceId && invoiceEvents && (
              <span className='text-theme-main'>
                {`total: ${formatMillicents(
                  invoiceEvents.invoiceTotal
                )}  /  status: ${invoiceStatus}`}
              </span>
            )}
          </div>

          <div className='flex items-center gap-2'>
            {isFetchingDLUR ? (
              <Skeleton.Rectangle height={10} width={100} />
            ) : (
              <>
                <div className='flex items-center gap-1'>
                  <div
                    className={classNames(
                      'flex items-center gap-0.5',
                      processDLUR && processDLUR.DLUR < 30
                        ? 'text-green-main'
                        : processDLUR && processDLUR.DLUR > 30
                        ? 'text-red-main'
                        : 'text-header'
                    )}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size='sm'
                      transform={{ rotate: processDLUR && processDLUR.DLUR > 30 ? -45 : 45 }}
                    />
                    <span className='inline-flex'>
                      {typeof processDLUR?.DLUR === 'number' && !Number.isNaN(processDLUR?.DLUR)
                        ? processDLUR?.DLUR === Infinity
                          ? `No UR`
                          : `${processDLUR?.DLUR.toFixed(2)}%`
                        : `${(0).toFixed(2)}%`}
                    </span>
                  </div>
                  <span className='inline-flex uppercase text-gray-400'>DL/UR</span>
                </div>
                <div className='flex items-center gap-1'>
                  <span className='inline-flex'>{processDLUR?.activeUsers ?? 0}</span>
                  <span className='inline-flex capitalize text-gray-400'>Active Agents</span>
                </div>
              </>
            )}
          </div>

          <div className='flex items-center gap-1'>
            <Dropdown
              width='350px'
              name='Invoices'
              options={uniqueInvoices.map((inv) => ({
                key: inv.id,
                value: truncate(
                  { length: 45 },
                  `${formatAsUTC(inv.startDate, 'PPP')} - ${formatAsUTC(inv.endDate, 'PPP')}`
                ),
              }))}
              onChange={({ key }) => {
                const invoice = uniqueInvoices.filter((inv) => inv.id === key)[0]
                setInvoiceId(invoice.id)
                setInvoiceStatus(invoice.status)
                setSelectedKey(key)
                refetchInvoiceOverview()
              }}
              selectedKey={selectedKey}
            />

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div>
                  <Button variant='subtle' size='md' icon='HamburgerIcon' shape='square' />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent alignment='end'>
                <DropdownMenuItem
                  key='Add Usage'
                  internalKey='Add Usage'
                  onSelect={() => setShowManualInvoiceEventModal(true)}>
                  <MenuItem label='Add Usage' icon='BillingIcon' hoveredIcon='BillingIcon' />
                </DropdownMenuItem>
                <Can I='create' a='InvoiceEventPreviousPeriodAdjustment'>
                  <DropdownMenuItem
                    key='Adjust Previous Usage'
                    internalKey='Adjust Previous Usage'
                    onSelect={() => setShowPreviousPeriodAdjustmentInvoiceEventModal(true)}>
                    <MenuItem
                      label='Adjust Previous Usage'
                      icon='BillingIcon'
                      hoveredIcon='BillingIcon'
                    />
                  </DropdownMenuItem>
                </Can>
                <DropdownMenuItem
                  key='Download CSV'
                  internalKey={'Download CSV'}
                  onSelect={handleBillingSummaryCSVDownload}>
                  <MenuItem
                    label='Download CSV'
                    icon='DownloadOutlineIcon'
                    hoveredIcon='DownloadFilledIcon'
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
      {!invoiceId ? null : isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Table.PaginationProvider>
            <div className='w-full'>
              <Table
                defaultPageSize={ITEMS_PER_PAGE}
                data={
                  invoiceEvents?.summaryByCommodity
                    ? invoiceEvents.summaryByCommodity.map((summary) => ({
                        ...summary,
                        onRowClick,
                        handleBillingRowCSVDownload,
                      }))
                    : []
                }
                columns={columns}
                {...(canDeleteInvoiceEvent ? { BulkSelectActions: BulkActions } : {})}
              />
            </div>

            <div className='my-4 flex items-center justify-end'>
              <Table.Pagination />
            </div>
          </Table.PaginationProvider>
          {detailedInvoiceEvents ? (
            <DetailedInvoiceEvents
              showModal={showDetailedInvoiceEventsModal}
              closeModal={closeModal}
              invoiceEvents={detailedInvoiceEvents}
              canDeleteInvoiceEvent={canDeleteInvoiceEvent}
            />
          ) : null}
          {showManualInvoiceEventModal ? (
            <ManualInvoiceEventModal
              invoice={invoices?.find(({ id }) => id === invoiceId)}
              processId={processId}
              companyId={companyId}
              showModal={showManualInvoiceEventModal}
              closeModal={() => setShowManualInvoiceEventModal(false)}
            />
          ) : null}
          {showPreviousPeriodAdjustmentInvoiceEventModal ? (
            <PreviousPeriodAdjustmentInvoiceEventModal
              invoice={invoices?.find(({ id }) => id === invoiceId)}
              processId={processId}
              companyId={companyId}
              showModal={showPreviousPeriodAdjustmentInvoiceEventModal}
              closeModal={() => setShowPreviousPeriodAdjustmentInvoiceEventModal(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}
