const typography = {
  h1: {
    fontSize: 60,
    lineHeight: '60px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  h2: {
    fontSize: 48,
    lineHeight: '48px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  h3: {
    fontSize: 36,
    lineHeight: '40px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  h4: {
    fontSize: 30,
    lineHeight: '36px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  h5: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    letterSpacing: 0,
  },
  h6: { fontSize: 20, lineHeight: '28px', fontWeight: 700, letterSpacing: 0 },
  subtitle1: {},
  subtitle2: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  body1: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
  },
  body2: {
    textOverflow: 'ellipsis',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  button: {
    lineHeight: '20px',
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0,
    textTransform: 'none',
  },
  caption: {},
  overline: {},
}

export default typography
