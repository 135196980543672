import { classNames } from '@invisible/common/helpers'
import { FC } from 'react'

interface IProps {
  data: {
    width: number
    height: number
    label: string
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Stage: FC<IProps> = ({ data: { width, height, label } }) => (
  <div
    className={classNames(
      '-mt-5 box-border flex rounded border-2 border-solid border-blue-600 bg-blue-100 text-white opacity-40'
    )}
    style={{
      width: `${width - 290}px`,
      height: `${height + 40}px`,
    }}>
    <div
      className='w-full truncate pl-3 text-xl font-bold uppercase tracking-wide text-blue-900'
      title={label}>
      {label}
    </div>
  </div>
)
export { Stage }
