import { SVGProps } from 'react'

function Home(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7112 1.63362C15.1559 1.22413 15.7364 0.99707 16.3387 0.99707C16.941 0.99707 17.5215 1.22413 17.9662 1.63362L17.968 1.63525L30.034 12.6925L30.051 12.7084C30.2895 12.9355 30.4805 13.2085 30.6128 13.5112C30.7451 13.8139 30.816 14.1403 30.8213 14.4711L30.8215 14.4908V28.563C30.8215 29.2085 30.5672 29.8277 30.1145 30.2841C29.6618 30.7406 29.0479 30.9971 28.4077 30.9971H21.1663C20.5261 30.9971 19.9122 30.7406 19.4595 30.2841C19.0068 29.8277 18.7525 29.2085 18.7525 28.563V21.2606H13.9249V28.563C13.9249 29.2085 13.6706 29.8277 13.2179 30.2841C12.7653 30.7406 12.1513 30.9971 11.5111 30.9971H4.26975C3.62957 30.9971 3.01562 30.7406 2.56294 30.2841C2.11027 29.8277 1.85596 29.2085 1.85596 28.563V14.4908L1.85611 14.4711C1.86142 14.1403 1.93231 13.8139 2.0646 13.5112C2.19689 13.2085 2.38791 12.9355 2.62642 12.7084L2.64341 12.6925L14.7094 1.63525L14.7112 1.63362ZM4.28119 14.4807L16.3357 3.43391L16.3387 3.43118L16.3417 3.43391L28.3962 14.4807C28.3994 14.4843 28.4021 14.4884 28.404 14.4928C28.406 14.4974 28.4073 14.5023 28.4077 14.5073V28.563H21.1663V21.2606C21.1663 20.6151 20.912 19.9959 20.4593 19.5395C20.0066 19.083 19.3927 18.8265 18.7525 18.8265H13.9249C13.2847 18.8265 12.6708 19.083 12.2181 19.5395C11.7654 19.9959 11.5111 20.6151 11.5111 21.2606V28.563H4.26975V14.5073C4.27017 14.5023 4.27141 14.4974 4.27343 14.4928C4.27537 14.4884 4.27799 14.4843 4.28119 14.4807Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Home
