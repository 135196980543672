import { z } from '@invisible/zod'
import { merge } from 'lodash'

import { schema as jsonBuilderStepMetaSchema } from './JSONBuilderStepMeta'
import { schema as baseVariableMappingSchema } from './MapJSONToVariableStepMeta'

const automationParamsSchema = z.record(z.any())
const schema = z.object({
  automationParams: automationParamsSchema.optional(),
  baseVariableMapping: baseVariableMappingSchema.optional(),
  jsonBuilderConfig: jsonBuilderStepMetaSchema.optional(),
})

type TSchema = z.infer<typeof schema>

const createArgsSchema = schema.strip()
type TCreateArgs = z.infer<typeof createArgsSchema>

/**
 * Creates AutomatedStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates AutomatedStepMeta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({ stepMeta, data }: { stepMeta: TSchema; data: TUpdateArgs }): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(stepMeta, updateArgs)
}

export { automationParamsSchema, create, createArgsSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
