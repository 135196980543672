import { SVGProps } from 'react'
function ThumbsUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.9644 8.3125L12.9644 3.3125C12.9203 3.20237 12.8386 3.11147 12.7337 3.05606C12.6288 3.00065 12.5077 2.98432 12.3919 3.01L8.50001 3.875V2.5C8.50001 2.36739 8.44733 2.24021 8.35356 2.14645C8.2598 2.05268 8.13262 2 8.00001 2C7.8674 2 7.74022 2.05268 7.64646 2.14645C7.55269 2.24021 7.50001 2.36739 7.50001 2.5V4.09875L3.39126 5.01188C3.31204 5.02937 3.23827 5.06589 3.17631 5.11826C3.11436 5.17064 3.06607 5.2373 3.03563 5.3125V5.31625L1.03563 10.3125C1.01171 10.3721 0.999609 10.4358 1.00001 10.5C1.00001 11.9569 2.53376 12.5 3.50001 12.5C4.46626 12.5 6.00001 11.9569 6.00001 10.5C6.00041 10.4358 5.98831 10.3721 5.96438 10.3125L4.18251 5.86062L7.50001 5.125V13H6.50001C6.3674 13 6.24022 13.0527 6.14646 13.1464C6.05269 13.2402 6.00001 13.3674 6.00001 13.5C6.00001 13.6326 6.05269 13.7598 6.14646 13.8536C6.24022 13.9473 6.3674 14 6.50001 14H9.50001C9.63262 14 9.7598 13.9473 9.85356 13.8536C9.94733 13.7598 10 13.6326 10 13.5C10 13.3674 9.94733 13.2402 9.85356 13.1464C9.7598 13.0527 9.63262 13 9.50001 13H8.50001V4.90125L11.6875 4.19375L10.0356 8.3125C10.0117 8.3721 9.99961 8.43578 10 8.5C10 9.95687 11.5338 10.5 12.5 10.5C13.4663 10.5 15 9.95687 15 8.5C15.0004 8.43578 14.9883 8.3721 14.9644 8.3125ZM3.50001 11.5C3.02938 11.5 2.07751 11.2744 2.00438 10.585L3.50001 6.84625L4.99563 10.585C4.92251 11.2744 3.97063 11.5 3.50001 11.5ZM12.5 9.5C12.0294 9.5 11.0775 9.27437 11.0044 8.585L12.5 4.84625L13.9956 8.585C13.9225 9.27437 12.9706 9.5 12.5 9.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ThumbsUp
