import { SVGProps } from 'react'
function StepTemplate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={14}
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#step-template_svg__clip0)'>
        <path
          d='M9.054 3.052a3.947 3.947 0 00-3.912 3.424H2.684A1.392 1.392 0 000 6.998a1.393 1.393 0 002.684.523h2.457a3.947 3.947 0 103.913-4.469zm1.857 4.323a.116.116 0 01-.116.116H9.547V8.74a.116.116 0 01-.116.116h-.755a.116.116 0 01-.116-.116V7.491H7.312a.116.116 0 01-.116-.116v-.754c0-.064.053-.116.117-.116H8.56V5.257c0-.064.053-.116.116-.116h.755c.064 0 .116.052.116.116v1.248h1.248c.063 0 .116.052.116.116v.754z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='step-template_svg__clip0'>
          <path fill='#fff' transform='translate(0 .5)' d='M0 0h13v13H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StepTemplate
