const parseResponse = <T extends { __typename?: string; message?: string; code?: number }>(
  response: T,
  onError?: (code: number, message: string) => void
) => {
  if (response.__typename === 'GraphQLErrorType' && onError) {
    onError(response.code || 500, response.message || 'GraphQL error occurred')
    return
  }

  return response as Exclude<T, { __typename?: 'GraphQLErrorType' }>
}
export { parseResponse }
