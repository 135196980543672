import { IDataFilter, IReportMeta, TDateViewLevel, TFilterValue } from '@invisible/common/types'
import { useState } from 'react'

export interface IReportComponent {
  reportMetaData: IReportMeta
  availableVisualizations: string[]
  dateViewLevel?: Record<string, TDateViewLevel | undefined>
  onDateViewChange: (dateViewLevel?: Record<string, TDateViewLevel | undefined>) => void
  dataFilters: IDataFilter<TFilterValue>[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const withReportLogic = (Component: React.FC<IReportComponent>) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const Report: React.FC<{
    reportMetaData: IReportMeta
    dataFilters: IDataFilter<TFilterValue>[]
  }> = ({ dataFilters, reportMetaData }) => {
    const [dateViewLevel, setDateViewLevel] = useState<Record<string, TDateViewLevel | undefined>>()

    const availableVisualizations = reportMetaData.visualizations.map((vis) => vis.type)

    return (
      <Component
        dataFilters={dataFilters}
        reportMetaData={reportMetaData}
        availableVisualizations={availableVisualizations}
        dateViewLevel={dateViewLevel}
        onDateViewChange={setDateViewLevel}
      />
    )
  }

  return Report
}
