import axios from 'axios'
import { useMutation as rqUseMutation, UseMutationOptions } from 'react-query'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type TVariables = { id: string; skipAutoAssign?: boolean }

const useCompleteStepRun = (options?: UseMutationOptions<unknown, unknown, TVariables>) => {
  const { isLoading, mutateAsync } = rqUseMutation(
    ['complete-step-run'],
    async ({ id, skipAutoAssign }: TVariables) =>
      axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/complete`,
        { step_run_id: id, skip_auto_assign: skipAutoAssign ?? false },
        {
          withCredentials: true,
        }
      ),
    options
  )

  return { isLoading, mutateAsync }
}

export { useCompleteStepRun }
