import { classNames } from '@invisible/common/helpers'
import { TFilterOption, useStore } from '@invisible/common/stores/process-store'
import { useClickAway } from '@invisible/hooks/use-click-away'
import { useQuery } from '@invisible/trpc/client'
import { SmallCheckbox } from '@invisible/ui/form'
import { FilledFilterIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { HeaderContext } from '@tanstack/react-table'
import { isPlainObject } from 'lodash/fp'
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import shallow from 'zustand/shallow'

interface IProps {
  headerContext: HeaderContext<Record<string, any>, any>
  formatOption?: (value: any) => JSX.Element | string
  filterDropdownOptionGetter?: (option: any) => any
  customOptions?: Record<string, (string | boolean | null)[]>
  baseId: string
  baseViewId: string
  isStepStatus?: boolean
  isStepAssignee?: boolean
  isBaseRunStatus?: boolean
}

const FilterDropdown = ({
  headerContext: {
    column: { id },
  },
  formatOption = (v) => String(v),
  customOptions,
  baseId,
  isStepStatus,
  isStepAssignee,
  isBaseRunStatus,
}: IProps) => {
  const { filterOption, setFilterOption, filterableColumns } = useStore(
    useCallback(
      (state) => ({
        filterOption: state.filterOption,
        setFilterOption: state.setFilterOption,
        filterableColumns: state.filterableColumns,
      }),
      []
    ),
    shallow
  )

  const { data: columnOptions } = useQuery([
    'baseView.getColumnOptions',
    {
      baseId,
      filterableColumns,
    },
  ])

  const rawfilterValue = filterOption?.find((f) => f.id === id)?.value as Exclude<
    TFilterOption['value'],
    { startDate: Date; endDate: Date }
  > // date time filterOptions are handled by DateFilter component

  const filterValue =
    typeof rawfilterValue === 'object' || rawfilterValue === undefined
      ? ((rawfilterValue ?? []) as string[] | (boolean | null)[])
      : [rawfilterValue]

  const [filterOpen, setFilterOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const ref = useRef<null | HTMLDivElement>(null)

  const preFilteredOptions = useMemo(() => {
    const options = new Set()
    if (customOptions) {
      customOptions[id as string]?.forEach((option) => {
        options.add(String(option))
      })
    }
    if (columnOptions) {
      columnOptions[id as string]?.forEach((option) => {
        options.add(option)
      })
    }
    return [...options.values()]
  }, [id, columnOptions, customOptions]) as (
    | boolean
    | string
    | {
        id: string
        email: string
        name: string | null
        preferredName: string | null
      }
  )[]

  const options = useMemo(
    () =>
      preFilteredOptions.filter((e) => {
        const option =
          isStepAssignee && typeof e === 'object'
            ? `${e?.email} ${e?.name?.toLowerCase()} ${e?.preferredName?.toLowerCase()}` ?? ''
            : String(e).toLowerCase()
        return option.includes(searchText.toLowerCase())
      }),
    [isStepAssignee, preFilteredOptions, searchText]
  )

  const onClickHandler = ({
    option,
    checked,
  }: {
    option:
      | string
      | boolean
      | null
      | {
          id: string
          email: string
          name: string | null
          preferredName: string | null
        }
    checked: boolean
  }) => {
    // The assignee option is an object with id, email, name, preferredName. But the filterOption value is just the id
    const savedOption = (
      isStepAssignee && typeof option === 'object' ? option?.id ?? '' : option
    ) as string | boolean | null
    const updatedValue = checked
      ? ([...filterValue, savedOption] as string[] | (boolean | null)[])
      : ((filterValue as (string | boolean | null)[]).filter((v) => v !== savedOption) as
          | string[]
          | (boolean | null)[])
    setFilterOption([
      ...filterOption.filter(
        (f) => f.id !== id && (f.value as string[] | (boolean | null)[]).length !== 0
      ),
      ...(updatedValue.length
        ? [
            {
              id: id as string,
              value: updatedValue,
              ...(isStepStatus ? { isStepStatus: true } : {}),
              ...(isStepAssignee ? { isStepAssignee: true } : {}),
              ...(isBaseRunStatus ? { isBaseRunStatus: true } : {}),
            },
          ]
        : []),
    ])
  }

  useClickAway(ref, () => {
    setFilterOpen(false)
  })

  return (
    <div ref={ref}>
      <div className='ml-1 h-[14px] cursor-pointer items-center pb-[1px]'>
        <FilledFilterIcon
          width={14}
          height={14}
          className={classNames(
            'text-theme-main',
            filterValue.length === 0 ? 'opacity-25' : 'opacity-100'
          )}
          onClick={() => setFilterOpen((prev) => !prev)}
        />
      </div>
      {filterOpen ? (
        <div className='absolute left-0 top-[50px] z-50 h-60 w-52 overflow-auto rounded-lg border border-solid border-gray-200 bg-white py-2 text-black shadow'>
          <div className='px-3 pb-3'>
            <Input
              prefix='SearchOutlineIcon'
              placeholder='Search...'
              size='medium'
              value={searchText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchText(e?.target?.value ?? '')
              }}
            />
          </div>
          {options
            .sort((a, b) => {
              const optionA = typeof a === 'object' ? a?.email ?? '' : String(a)
              const optionB = typeof b === 'object' ? b?.email ?? '' : String(b)
              return optionA.localeCompare(optionB)
            })
            .map((option) => (
              <div
                key={isPlainObject(option) ? JSON.stringify(option) : String(option)}
                className='flex items-center border-0 border-b border-solid border-b-gray-200 py-1 px-3'>
                <SmallCheckbox
                  checked={(filterValue as (string | boolean | null | { id: string })[]).includes(
                    typeof option === 'object' && option !== null ? option.id : option // typeof null is 'object' too
                  )}
                  onClick={(checked) => {
                    onClickHandler({ option, checked })
                  }}
                />
                <div className='pointer box-border flex h-8 w-full items-center truncate border-0 border-solid text-left font-normal leading-8'>
                  {formatOption ? formatOption(option) : option}
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  )
}

export { FilterDropdown }
