import {
  formatInTimeZone,
  PERIODS_WITH_CUSTOM as PERIODS,
  TIMEZONE,
  TPeriodOption,
} from '@invisible/common/helpers'
import { IRange } from '@invisible/common/types'
import { DateRangePicker } from '@invisible/ui/date-range-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { differenceInCalendarDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useState } from 'react'

import { IIntervalDropDownProps } from '../types'

const DROPDOWN_PERIOD_OPTIONS = Object.values(PERIODS).map((period) => ({
  key: period,
  value: period,
}))

const DATE_TIME_FOMRAT = 'd MMM yyyy'

const formatCustomDateRangeText = ({ from, to }: { from: string; to: string }) => {
  const fromDate = new Date(from.split('T')[0])
  const toDate = new Date(to.split('T')[0])

  if (differenceInCalendarDays(toDate, fromDate) === 0)
    return formatInTimeZone({
      date: from,
      format: DATE_TIME_FOMRAT,
    })

  return `${formatInTimeZone({
    date: from,
    format: DATE_TIME_FOMRAT,
  })} - ${formatInTimeZone({
    date: to,
    format: DATE_TIME_FOMRAT,
  })}`
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DateFilterDropdown: React.FC<IIntervalDropDownProps> = ({
  onChange,
  width,
  selectedInterval,
}) => {
  const [showDateRange, setShowDateRange] = useState(false)

  const handlePeriodDropdownSelect = (value: TPeriodOption) => {
    if (value !== PERIODS.CUSTOM) {
      onChange({
        periodOption: value,
      })
    } else {
      setTimeout(() => setShowDateRange(true), 200)
    }
  }

  const handleDateRangeChange = (value: [IRange]) => {
    const { startDate, endDate } = value[0]
    const reverseZonedStartDate = zonedTimeToUtc(startDate, TIMEZONE.UTC)
    const reverseZonedEndDate = zonedTimeToUtc(endDate, TIMEZONE.UTC)

    onChange({
      from: formatInTimeZone({ date: reverseZonedStartDate }),
      to: formatInTimeZone({ date: reverseZonedEndDate }),
    })
  }

  const customDateRangeText =
    !!selectedInterval?.from && !!selectedInterval?.to
      ? formatCustomDateRangeText({
          from: selectedInterval.from,
          to: selectedInterval.to,
        })
      : undefined

  return (
    <>
      <Dropdown
        data-test='select-period'
        name='Select a period'
        placeholder='Select a period'
        width={width}
        activeText={customDateRangeText}
        selectedKey={String(
          // If there is preset then preset it shall be.
          // Else If there is date range then custom otherwise unselected
          selectedInterval?.periodOption
            ? selectedInterval.periodOption
            : selectedInterval?.from || selectedInterval?.to
            ? PERIODS.CUSTOM
            : undefined
        )}
        options={DROPDOWN_PERIOD_OPTIONS}
        onChange={({ value }) => handlePeriodDropdownSelect(value as TPeriodOption)}
      />
      {showDateRange && (
        <DateRangePicker
          standalone={true}
          isOpen={true}
          onOpenChange={() => setShowDateRange((prev) => !prev)}
          ranges={[
            {
              startDate: new Date(
                formatInTimeZone({
                  date: selectedInterval?.from ?? new Date(),
                  format: "yyyy-MM-dd'T'HH:mm:ss.SSS",
                })
              ),
              endDate: new Date(
                formatInTimeZone({
                  date: selectedInterval?.to ?? new Date(),
                  format: "yyyy-MM-dd'T'HH:mm:ss.SSS",
                })
              ),
              key: 'selection',
            },
          ]}
          onChange={handleDateRangeChange as (value: IRange[]) => void}
        />
      )}
    </>
  )
}
