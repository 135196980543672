import { TDateViewLevel } from '@invisible/common/types'
import { useReportData } from '@invisible/hooks/use-report'
import { CHART_TYPE } from '@invisible/ui/constants'
import { ReportCard } from '@invisible/ui/reports/cards'
import { DownloadCSVIcon } from '@invisible/ui/reports/icons'
import { VisualizationSelector } from '@invisible/ui/reports/visualization-selector'
import { useEffect, useState } from 'react'

import { DateViewSelector } from './DateViewSelector'
import { TileGrid } from './TileGrid'
import { ToggleableCharts } from './ToggleableChart'
import { IReportComponent, withReportLogic } from './withReportLogic'

const _Report = (props: IReportComponent) => {
  const { reportMetaData, availableVisualizations, dateViewLevel, onDateViewChange, dataFilters } =
    props

  const [selectedVisualization, setSelectedVisualization] = useState<string>(
    availableVisualizations.find(
      (visualization: string) => visualization === CHART_TYPE.BarChart
    ) ?? availableVisualizations[0]
  )

  const {
    data: formattedReport,
    isLoading,
    error,
  } = useReportData({
    reportId: reportMetaData?.id,
    filters: dataFilters,
    config: {
      dateViewLevel: dateViewLevel?.[selectedVisualization],
      sharedColorTheme: 'set3',
      colorThemePerChart: {
        [CHART_TYPE.LineChart]: 'category10',
      },
    },
  })

  useEffect(() => {
    if (isLoading) return
    onDateViewChange({
      [CHART_TYPE.BarChart]: formattedReport?.visualizations?.barChart?.baseDateViewLevel,
      [CHART_TYPE.LineChart]: formattedReport?.visualizations?.lineChart?.baseDateViewLevel,
    })
  }, [isLoading])

  const showReportCardIcons =
    !isLoading && !formattedReport?.visualizations?.grid && availableVisualizations?.length > 1

  const showDateViewSelector =
    (selectedVisualization === CHART_TYPE.BarChart &&
      formattedReport?.visualizations?.barChart?.baseDateViewLevel) ||
    (selectedVisualization === CHART_TYPE.LineChart &&
      formattedReport?.visualizations?.lineChart?.baseDateViewLevel)

  const getBaseDateViewLevel = (visualization: string) => {
    if (visualization === CHART_TYPE.BarChart)
      return formattedReport?.visualizations?.barChart?.baseDateViewLevel
    else return formattedReport?.visualizations?.lineChart?.baseDateViewLevel
  }
  return (
    <ReportCard
      title={reportMetaData?.reportTitle ?? ''}
      subtitle={reportMetaData?.subtitle ?? ''}
      description={reportMetaData?.description}
      isLoading={isLoading}
      cardStyles='border-theme-weak-2 relative rounded-xl border border-solid bg-white p-4 shadow-md'
      disableDetailView>
      <div className='absolute right-4 top-2 my-2 flex flex-row items-center justify-end gap-2'>
        {showReportCardIcons && (
          <>
            <DownloadCSVIcon
              reportName={reportMetaData?.reportName ?? ''}
              dataFilters={dataFilters}
              fileName={reportMetaData?.reportTitle ?? ''}
              className='pt-1'
              width={18}
              height={18}
            />
            <VisualizationSelector
              selected={selectedVisualization}
              justifyContent={'flex-end'}
              visualizations={availableVisualizations}
              onClick={(visualization) =>
                setSelectedVisualization(visualization as keyof typeof CHART_TYPE)
              }
            />
          </>
        )}
        {showDateViewSelector && (
          <DateViewSelector
            baseLevel={getBaseDateViewLevel(selectedVisualization) as TDateViewLevel}
            selectedLevel={dateViewLevel?.[selectedVisualization]}
            onChange={(newDateViewLevel) =>
              onDateViewChange({
                ...dateViewLevel,
                [selectedVisualization]: newDateViewLevel,
              })
            }
          />
        )}
      </div>

      <div id='report-toggleable-chart' data-testid={!isLoading ? 'chart-container' : ''}>
        {formattedReport?.visualizations?.grid ? (
          <TileGrid tiles={formattedReport.visualizations.grid} isLoading={isLoading} />
        ) : (
          <ToggleableCharts
            chartsConfig={{
              pieChart: {
                innerRadius: 0.5,
                enableArcLabels: true,
                enableArcLinkLabels: true,
                margin: {
                  top: 50,
                  bottom: 35,
                },
              },
              barChart: {
                enableGridY: true,
                enableAxisLegends: true,
                showTotal: false,
                enableLabel: true,
                labelSkipHeight: 10,
                labelSkipWidth: 15,
                maxValuePercentile: 95,
                axisLabelFontWeight: 'bolder',
              },
              lineChart: {
                enableGridY: true,
                enableAxisLegends: true,
                axisLabelFontWeight: 'bolder',
              },
              table: {
                style: 'classic',
              },
            }}
            enableLegends={true}
            selectedVisualization={selectedVisualization}
            isLoading={isLoading}
            pieChart={formattedReport?.visualizations?.pieChart}
            table={formattedReport?.visualizations?.table}
            barChart={formattedReport?.visualizations?.barChart}
            choroplethChart={formattedReport?.visualizations?.choroplethChart}
            lineChart={formattedReport?.visualizations?.lineChart}
            errorMessage={error?.message}
          />
        )}
      </div>
    </ReportCard>
  )
}

export const Report = withReportLogic(_Report)
