import {
  fromGlobalId,
  toGlobalId,
  useLifecycleStageFindManyByProcessIdQuery,
} from '@invisible/concorde/gql-client'

const useLifecycleStageFindManyByProcessId = ({ processId }: { processId: string | undefined }) => {
  const { data: graphqlResult, isLoading } = useLifecycleStageFindManyByProcessIdQuery(
    {
      processId: toGlobalId('ProcessType', processId),
    },
    {
      enabled: Boolean(processId),
    }
  )
  const data = graphqlResult?.lifecycleStages.edges.map((lifecycleStage) => ({
    id: fromGlobalId(lifecycleStage.node.id),
    name: lifecycleStage.node.name,
    position: lifecycleStage.node.position,
    updatedAt: new Date(lifecycleStage.node.updatedAt),
    description: lifecycleStage.node.description ?? null,
    createdAt: new Date(lifecycleStage.node.createdAt),
  }))

  return {
    data,
    isLoading,
  }
}

export { useLifecycleStageFindManyByProcessId }
