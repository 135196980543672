import { SVGProps } from 'react'
function Logout(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={16}
      viewBox='0 0 32 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#logout_svg__clip0)'>
        <path
          d='M22.357 11.93h-1.255a.281.281 0 00-.22.103 6.319 6.319 0 01-4.875 2.29 6.3 6.3 0 01-4.475-1.853 6.308 6.308 0 01-1.357-2.009 6.258 6.258 0 01-.497-2.46 6.29 6.29 0 011.854-4.47 6.319 6.319 0 014.475-1.852 6.318 6.318 0 014.875 2.29.284.284 0 00.22.103h1.255a.142.142 0 00.12-.22 7.682 7.682 0 00-6.49-3.53A7.67 7.67 0 008.321 8.08a7.683 7.683 0 007.686 7.6 7.684 7.684 0 006.47-3.53.143.143 0 00-.12-.22zm1.587-4.042l-2.534-2a.143.143 0 00-.232.113v1.357h-5.607a.143.143 0 00-.143.143v1c0 .078.065.143.143.143h5.607V10c0 .12.14.187.232.112l2.534-2a.143.143 0 000-.225z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='logout_svg__clip0'>
          <path fill='currentColor' transform='translate(8)' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logout
