import { SVGProps } from 'react'

function EnterOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10 4C10.2761 4 10.5 4.22386 10.5 4.5V6.5C10.5 6.77614 10.2761 7 10 7H4C3.72386 7 3.5 6.77614 3.5 6.5C3.5 6.22386 3.72386 6 4 6H9.5V4.5C9.5 4.22386 9.72386 4 10 4Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.85355 4.64645C6.04882 4.84171 6.04882 5.15829 5.85355 5.35355L4.70711 6.5L5.85355 7.64645C6.04882 7.84171 6.04882 8.15829 5.85355 8.35355C5.65829 8.54882 5.34171 8.54882 5.14645 8.35355L3.64645 6.85355C3.45118 6.65829 3.45118 6.34171 3.64645 6.14645L5.14645 4.64645C5.34171 4.45118 5.65829 4.45118 5.85355 4.64645Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H12.5C13.0523 0.5 13.5 0.947715 13.5 1.5V10.5C13.5 11.0523 13.0523 11.5 12.5 11.5H1.5C0.947715 11.5 0.5 11.0523 0.5 10.5V1.5ZM12.5 1.5H1.5V10.5H12.5V1.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EnterOutline
