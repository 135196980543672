/*
 * Quill 1.* cannot next block elements inside <li> including nested <ul>,<ol>.
 * To achieve nested lists it uses flat linear lists with CSS class `ql-indent-\d+` on <li>.
 * Nesting <ul> inside <ol> or vice-versa cause topmost list to break in two adjacent lists.
 *
 * There is the only solution: fix bad HTML after getting it from Quill and break it back before
 * passing to Quill again for editing.
 */

import { remove } from 'lodash/fp'

const mkNode = (tagName = 'div') => document.createElement(tagName)

const IS_LI = {
  LI: true,
  li: true,
}

function wrapContent(node: HTMLElement, tagName = 'div') {
  const container = mkNode(tagName)
  while (node.firstChild) {
    container.appendChild(node.firstChild)
  }
  node.appendChild(container)
}

function nextNode(node: any) {
  let current = node
  do {
    current = current.nextSibling
  } while (current && 1 !== current.nodeType)
  return current
}

function firstNode(node: Element) {
  let current = node.firstChild
  while (current && 1 !== current.nodeType) {
    current = current.nextSibling
  }
  return current
}

function lastNode(node: Element) {
  let current = node.lastChild
  while (current && 1 !== current.nodeType) {
    current = current.previousSibling
  }
  return current
}

function parentNodes(node: Element, filter: any = null) {
  const ret: ParentNode[] = []
  let cur = node.parentNode
  while (cur) {
    if (1 === cur.nodeType) {
      if (!filter || filter(cur)) {
        ret.push(cur)
      }
    }
    cur = cur.parentNode
  }
  return ret
}

function insertBefore(
  newNode: any,
  refNode: { parentNode: { insertBefore: (arg0: any, arg1: any) => void } }
) {
  refNode.parentNode.insertBefore(newNode, refNode)
}

function insertAfter(newNode: ChildNode, refNode: Node) {
  const next = nextNode(refNode)
  if (next) {
    insertBefore(newNode, next)
  } else {
    refNode.parentNode!.append(newNode)
  }
}

const reIndent = /^ql-indent-(\d+)$/
const mkLevelClass = (level: any) => `ql-indent-${level}`

const getItemLevel = (li: HTMLLIElement | null) => {
  let level = 0
  if (!li) return level
  Array.from(li.classList).some((name) => {
    const match = name.match(reIndent)
    if (match) {
      level = Number(match[1])
      return true
    }
    return false
  })
  return level
}
const setItemLevel = (li: HTMLLIElement, level: number) => {
  let changed = false
  Array.from(li.classList).forEach((name) => {
    if (reIndent.test(name)) {
      li.classList.remove(name)
      changed = true
    }
  })

  if (!level) {
    return changed
  }

  li.classList.add(mkLevelClass(level))
  return true
}

const removeItemLevel = (li: HTMLLIElement) => {
  let level = 0
  Array.from(li.classList).some((name) => {
    const match = name.match(reIndent)
    if (match) {
      remove(name, li.classList)
      if (!li.classList.length) {
        li.removeAttribute('class')
      }
      level = Number(match[1])
      return true
    }
    return false
  })
  return level
}

const getListLevel = (list: Element) => {
  const li = firstNode(list) as HTMLLIElement | null
  return li && IS_LI[li.tagName as 'li' | 'LI'] ? getItemLevel(li) : 0
}

export function fixUp(content: string) {
  const swap = mkNode()

  swap.innerHTML = content

  // find all `ul,ol`
  const lists = Array.from(swap.querySelectorAll('ul,ol'))
  // if none then return content;
  if (!lists.length) {
    return content
  }

  let changed = false

  // check its indent level
  // nest lists and remember if anything was changed
  {
    let prevList: Element | null = null
    let prevListLevel: number | null = null
    let prevLastLi: HTMLLIElement | null = null
    let prevLastLiLevel: number | null = null
    const stack: any[][] = []
    lists.forEach((list, listIndex) => {
      const curLevel = getListLevel(list)
      const curLastLi = lastNode(list) as HTMLLIElement | null
      const curLastLiLevel = getItemLevel(curLastLi)
      if (null === prevList) {
        prevList = list
        prevListLevel = curLevel
        prevLastLi = curLastLi
        prevLastLiLevel = curLastLiLevel
        return
      }

      while (prevLastLiLevel && curLevel < prevLastLiLevel && stack.length) {
        ;[prevList, prevListLevel, prevLastLi, prevLastLiLevel] = stack.pop() as any[]
      }

      if (prevLastLiLevel && curLevel > prevLastLiLevel) {
        stack.push([prevList, prevListLevel, prevLastLi, prevLastLiLevel])
        if (prevLastLi && !firstNode(prevLastLi)) {
          wrapContent(prevLastLi)
        }
        prevLastLi!.appendChild(list)
        changed = true

        prevList = list
        prevListLevel = curLevel
        prevLastLi = curLastLi
        prevLastLiLevel = curLastLiLevel
        return
      }

      if (list.tagName === prevList!.tagName && nextNode(prevList) === list) {
        // merge lists
        while (list.firstChild) {
          prevList!.appendChild(list.firstChild)
          changed = true
        }
        // remove empty list from DOM
        list.remove()
        // remove it from array
        delete lists[listIndex]

        prevLastLi = lastNode(prevList!) as HTMLLIElement
        prevLastLiLevel = getItemLevel(prevLastLi)
        return
      }

      prevList = list
      prevListLevel = curLevel
      prevLastLi = curLastLi
      prevLastLiLevel = curLastLiLevel
    })
  }

  // check all its `li`
  lists.forEach((list) => {
    const li = firstNode(list) as HTMLLIElement
    const baseLevel = removeItemLevel(li)

    let prevLi = li
    let prevList = list
    let prevLevel = baseLevel
    const stack: [HTMLElement & { type: string; value: number }, Element, number][] = []
    let nextLi = nextNode(prevLi)
    while (nextLi) {
      const curLi = nextLi
      nextLi = nextNode(nextLi)
      const curLevel = removeItemLevel(curLi)

      while (curLevel < prevLevel && curLevel >= baseLevel && stack.length) {
        ;[prevLi, prevList, prevLevel] = stack.pop()!
      }

      if (curLevel > prevLevel) {
        if (!firstNode(prevLi)) {
          wrapContent(prevLi)
        }
        const curList = mkNode(prevList.tagName)
        curList.appendChild(curLi)
        prevLi.appendChild(curList)
        changed = true
        stack.push([prevLi, prevList, prevLevel])
        prevLi = curLi
        prevList = curList
        prevLevel = curLevel
      } else {
        if (prevList !== list) {
          changed = true
          prevList.appendChild(curLi)
        }
        prevLi = curLi
      }
    }
  })

  // if nothing was changed then return content;
  if (!changed) {
    return content
  }

  return swap.innerHTML
}

export function breakDown(content: string) {
  const swap = mkNode()
  swap.innerHTML = content

  let changed = false

  // find all `ul,ol`
  const lists = Array.from(swap.querySelectorAll('ul,ol')).map((list) => {
    const level = parentNodes(list, (n: HTMLElement) => IS_LI[n.tagName as 'LI' | 'li']).length

    if (level > 0) {
      for (let li = firstNode(list) as HTMLLIElement; li; li = nextNode(li)) {
        if (setItemLevel(li, level)) {
          changed = true
        }
      }
    }

    return { list, level }
  })

  // if nothing changed then no nesting then return origin
  if (!changed) {
    return content
  }

  //const
  lists.forEach(({ list, level }) => {
    if (!level) {
      return
    }

    const topLi = parentNodes(
      list,
      (n: { tagName: string | number }) => IS_LI[n.tagName as 'LI' | 'li']
    ).slice(-1)[0]
    const topList = topLi.parentNode
    if (list.tagName === (topList as HTMLElement).tagName) {
      // move current <li>s after topLi in order
      while (list.lastChild) {
        insertAfter(list.lastChild, topLi as HTMLElement)
      }
      list.remove()
    } else {
      // move next top <li>s into separate list
      let nextTopLi = nextNode(topLi)
      if (nextTopLi) {
        const nextTopList = mkNode((topList as HTMLElement).tagName)
        insertAfter(nextTopList, topList as Node)
        while (nextTopLi) {
          const next = nextNode(nextTopLi)
          nextTopList.appendChild(nextTopLi)
          nextTopLi = next
        }
      }
      insertAfter(list, topList as Node)
    }
  })

  return swap.innerHTML
}
