import auth0 from 'auth0-js'

import {
  NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DB_CONNECTION,
  NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_AUTH0_REDIRECT_URL,
} from './config/env'

// handle webAuth object as singleton
export const webAuth = new auth0.WebAuth({
  domain: NEXT_PUBLIC_AUTH0_DOMAIN ?? '',
  clientID: NEXT_PUBLIC_AUTH0_CLIENT_ID ?? '',
  redirectUri: NEXT_PUBLIC_AUTH0_REDIRECT_URL ?? '',
  scope: 'openid profile email',
  responseType: 'code token id_token',
})

/**
 * Perform signup directly with Auth0
 * @param email
 * @param password
 * @returns Promise<object> - Auth0 user object
 */
const ERROR_DESCRIPTIONS = {
  invalid_password: 'Password too weak',
  invalid_signup: 'Invalid signup. Did you already sign up?',
  unhandled_error: `Unhandled error: `,
}

// For Tests
export const getErrorDescription = (code: string) => {
  if (!(code in ERROR_DESCRIPTIONS)) {
    return ERROR_DESCRIPTIONS['unhandled_error']
  }
  return (ERROR_DESCRIPTIONS as any)[code]
}

export const doSignup = (email: string, password: string): Promise<any> =>
  new Promise((resolve, reject) =>
    webAuth.redirect.signupAndLogin(
      {
        connection: NEXT_PUBLIC_AUTH0_DB_CONNECTION ?? '',
        email,
        password,
      },
      (err, result) => {
        if (err) {
          const code = err.code ?? 'unhandled_error'
          const errObj: { source: string; type: string; message: string } = {
            source: 'auth0',
            type: code,
            message: `${code}: ${getErrorDescription(code)}`,
          }
          return reject(errObj)
        }
        return resolve(result)
      }
    )
  )

/**
 * Perform login with Google
 * @param email
 * @param password
 * @returns Promise<object> - Auth0 user object
 */
export const doLoginWithGoogle = (): void =>
  webAuth.authorize({
    connection: 'google-oauth2',
    scope: 'openid profile email',
  })

/**
 * Perform login directly with Auth0
 * @param email
 * @param password
 * @returns Promise<object> - Auth0 user object
 */
export const doLogin = (email: string, password: string): Promise<any> =>
  new Promise<void>((resolve, reject) =>
    webAuth.login(
      {
        realm: NEXT_PUBLIC_AUTH0_DB_CONNECTION,
        email,
        password,
      },
      (err) => {
        if (err) {
          return reject(err)
        }
        return resolve()
      }
    )
  )

export const resetPassword = (email: string): Promise<void> =>
  new Promise<void>((resolve, reject) =>
    webAuth.changePassword(
      {
        connection: NEXT_PUBLIC_AUTH0_DB_CONNECTION ?? '',
        email,
      },
      (err) => {
        if (err) {
          return reject(err)
        }
        return resolve()
      }
    )
  )
