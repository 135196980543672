import { SVGProps } from 'react'

function PopOutFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.07692 27C0.92987 27 0 26.0701 0 24.9231V4.15385C0 3.00679 0.92987 2.07692 2.07692 2.07692H11.4231C11.9966 2.07692 12.4615 2.54186 12.4615 3.11538C12.4615 3.68891 11.9966 4.15385 11.4231 4.15385H2.07692V24.9231H22.8462V15.5769C22.8462 15.0034 23.3111 14.5385 23.8846 14.5385C24.4581 14.5385 24.9231 15.0034 24.9231 15.5769V24.9231C24.9231 26.0701 23.9932 27 22.8462 27H2.07692Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1958 13.8042C13.6014 14.2097 13.6014 14.8672 13.1958 15.2728L5.92661 22.542C5.52107 22.9475 4.86355 22.9475 4.458 22.542C4.05246 22.1365 4.05246 21.4789 4.458 21.0734L11.7272 13.8042C12.1328 13.3986 12.7903 13.3986 13.1958 13.8042Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.19231 14.5385C5.19231 13.9649 5.65724 13.5 6.23077 13.5H12.4615C13.0351 13.5 13.5 13.9649 13.5 14.5385V20.7692C13.5 21.3428 13.0351 21.8077 12.4615 21.8077C11.888 21.8077 11.4231 21.3428 11.4231 20.7692V15.5769H6.23077C5.65724 15.5769 5.19231 15.112 5.19231 14.5385Z'
        fill='currentColor'
      />
      <path
        d='M14.5385 2.07692C14.5385 0.92987 15.4683 0 16.6154 0H24.9231C26.0701 0 27 0.92987 27 2.07692V10.3846C27 11.5317 26.0701 12.4615 24.9231 12.4615H16.6154C15.4683 12.4615 14.5385 11.5317 14.5385 10.3846V2.07692Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PopOutFilled
