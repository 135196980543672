import type { TBaseRun, TBaseRunVariable, TChildBaseRun } from './types'

const formatObject = ({
  baseRun,
  wizardInitialBRVs,
}: {
  baseRun: TBaseRun | TChildBaseRun
  wizardInitialBRVs?: TBaseRunVariable[]
}) => {
  const result: Record<string, any> = {}
  baseRun?.baseRunVariables?.forEach((brv) => {
    result[brv.baseVariable.name] = brv.value
    result[`${brv.baseVariable.name} baseRunVariableId`] = brv.id
    result['baseRunId'] = baseRun.id
  })
  wizardInitialBRVs?.forEach((brv) => {
    result[brv.baseVariable.name] = brv.value
    result[`${brv.baseVariable.name} baseRunVariableId`] = brv.id
    result['baseRunId'] = brv.baseRunId
  })
  return result
}

export { formatObject }
