import { SVGProps } from 'react'

function CaretDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.47 5.47a.75.75 0 0 1 1.06 0L8 9.94l4.47-4.47a.75.75 0 1 1 1.06 1.06l-5 5a.75.75 0 0 1-1.06 0l-5-5a.75.75 0 0 1 0-1.06Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CaretDown
