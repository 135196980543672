import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { RLHFSingleUser } from '.'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  isReadOnly: boolean
}

const RLHFReview = (props: IProps) => <RLHFSingleUser {...props} isReview />

export { RLHFReview }
