import type { PrismaAll } from '@invisible/ultron/prisma'
import { ManualStepMeta, Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isPlainObject } from 'lodash/fp'

type TStepWithWizardMeta = PrismaAll.Step & {
  stepTemplate: PrismaAll.StepTemplate
  meta: WizardSchemas.WizardStepTemplateMeta.TSchema
}

type TStepWithoutWizardMeta = PrismaAll.Step & {
  stepTemplate: PrismaAll.StepTemplate & {
    type: typeof PrismaAll.StepTemplateType.manual
    subtype: typeof PrismaAll.StepTemplateSubtype.wizardless
  }
  meta: ManualStepMeta.TSchema
}

const isWizard = (
  s: PrismaAll.Step & { stepTemplate: PrismaAll.StepTemplate }
): s is TStepWithWizardMeta =>
  s.stepTemplate.type === 'manual' &&
  s.stepTemplate.subtype !== 'wizardless' &&
  isPlainObject(s.meta) &&
  Boolean(WizardSchemas.WizardStepTemplateMeta.schema.safeParse(s.meta).success)

const isWizardless = (
  s: PrismaAll.Step & { stepTemplate: PrismaAll.StepTemplate }
): s is TStepWithoutWizardMeta =>
  s.stepTemplate.type === 'manual' && s.stepTemplate.subtype === 'wizardless'

export { isWizard, isWizardless }
