import { z } from '@invisible/zod'

const schema = z.object({
  reportName: z.string(),
  visualizationType: z.string(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
