import { LabelType, ReportType, TDateViewLevel } from '@invisible/common/types'

export const INVISIBLE_SUPPORT_EMAIL = 'mailto:support@invisible.email'

export const CHART_TYPE = {
  Choropleth: 'Choropleth',
  BarChart: 'BarChart',
  PieChart: 'PieChart',
  LineChart: 'LineChart',
  Table: 'Table',
  Linear: 'Linear',
  Tile: 'Tile',
  Grid: 'Grid',
} as const

export const PIE_CHART_NO_DATA = [
  {
    id: 'noData',
    label: 'no data',
    value: 60,
  },
  {
    id: 'no-data',
    label: 'no data',
    value: 280,
  },
]

export const NO_DATA_THEME = ['#A19FA5', '#EAE9EB']

export const REPORT_TYPE = {
  Custom: 'Custom',
  Core: 'Core',
} as Record<string, ReportType>

export type ReportName = keyof typeof REPORT_NAME

export const REPORT_NAME = {
  worksharing_credit_spent: 'worksharing_credit_spent',
  received_deliverables: 'received_deliverables',
  days_or_hours_waited: 'days_or_hours_waited',
  clients_to_process_tags: 'clients_to_process_tags',
  process_tags_to_instances: 'process_tags_to_instances',
  process_tag_instances_to_outputs: 'process_tag_instances_to_outputs',
  clients_to_process_delegators: 'clients_to_process_delegators',
  process_delegators_to_instances: 'process_delegators_to_instances',
  delegator_instances_to_outputs: 'delegator_instances_to_outputs',
  process_tags_to_budget: 'process_tags_to_budget',
  instances_to_budget: 'instances_to_budget',
  outputs_to_budget: 'outputs_to_budget',
  billing_report: 'billing_report',
} as const

export const SERIES_TYPE = {
  Series: 'Series',
  String: 'String',
} as Record<string, LabelType>

export const TABLE_SKELETON_DATA = [
  {
    Header: '',
    accessor: 'firstName',
  },
  {
    Header: '',
    accessor: 'lastName',
  },
  {
    Header: '',
    accessor: 'three',
  },
  {
    Header: '',
    accessor: 'four',
  },
  {
    Header: '',
    accessor: 'five',
  },
  {
    Header: '',
    accessor: 'six',
  },
  {
    Header: '',
    accessor: 'seven',
  },
  {
    Header: '',
    accessor: 'eight',
  },
] as const

export const FILTERS_CRITERIA_TYPE = {
  contains: 'contains',
  excludes: 'excludes',
  starts_with: 'startsWith',
  ends_with: 'endsWith',
  between: 'btween',
  in: 'in',
  not_in: 'notIn',
  generic_comparison: 'genericComparison',
  null: 'null',
  not_null: 'notNull',
}

export const FILTER_OPERATOR = {
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  contains: 'contains',
  excludes: 'excludes',
  starts_with: 'startsWith',
  ends_with: 'endsWith',
  equals: '=',
  not_equals: '!=',
  between: 'between',
  null: 'null',
  not_null: 'notNull',
  in: 'in',
  not_in: 'notIn',
}

export const REPORT_FAC = {
  generic_input: 'genericInput',
  generic_range: 'genericRange',
  radio_list: 'redioList',
  checkbox_list: 'checkboxList',
  boolean_select: 'booleanSelect',
  null_select: 'nullSelect',
  date_single: 'dateSingle',
  date_range: 'dateRange',
}

export const REPORT_FILTER_MAPPING = {
  text: [
    {
      label: 'contains',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.contains,
    },
    {
      label: 'does not contain',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.excludes,
    },
    {
      label: 'exact match',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.equals,
    },
    {
      label: 'starts with',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.starts_with,
    },
    {
      label: 'ends with',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.ends_with,
    },
    {
      label: 'contains one of',
      FAC: REPORT_FAC.checkbox_list,
      operator: FILTER_OPERATOR.in,
    },
    {
      label: 'does not contain',
      FAC: REPORT_FAC.checkbox_list,
      operator: FILTER_OPERATOR.not_in,
    },
  ],
  number: [
    {
      label: '=',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.equals,
    },
    {
      label: '!=',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.not_equals,
    },
    {
      label: '<',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.lt,
    },
    {
      label: '>',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.gt,
    },
    {
      label: '<=',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.lte,
    },
    {
      label: '>=',
      FAC: REPORT_FAC.generic_input,
      operator: FILTER_OPERATOR.gte,
    },
    {
      label: 'within',
      FAC: REPORT_FAC.generic_range,
      operator: FILTER_OPERATOR.between,
    },
  ],
  boolean: [
    {
      label: '=',
      FAC: REPORT_FAC.boolean_select,
      operator: FILTER_OPERATOR.equals,
    },
  ],
  date: [
    {
      label: 'is',
      FAC: REPORT_FAC.date_single,
      operator: FILTER_OPERATOR.equals,
    },
    {
      label: 'is before',
      FAC: REPORT_FAC.date_single,
      operator: FILTER_OPERATOR.lt,
    },
    {
      label: 'is on before',
      FAC: REPORT_FAC.date_single,
      operator: FILTER_OPERATOR.lte,
    },
    {
      label: 'is after',
      FAC: REPORT_FAC.date_single,
      operator: FILTER_OPERATOR.gt,
    },
    {
      label: 'is on after',
      FAC: REPORT_FAC.date_single,
      operator: FILTER_OPERATOR.gte,
    },
    {
      label: 'between',
      FAC: REPORT_FAC.date_range,
      operator: FILTER_OPERATOR.between,
    },
  ],
  common: [
    {
      label: 'Null',
      FAC: REPORT_FAC.null_select,
      operator: FILTER_OPERATOR.null,
    },

    {
      label: 'Not null',
      FAC: REPORT_FAC.null_select,
      operator: FILTER_OPERATOR.not_null,
    },
  ],
}

export const FAC_OPERATOR_CRITERIA_MAPPING = {
  [FILTER_OPERATOR.gt]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.gte]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.lt]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.lte]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.contains]: FILTERS_CRITERIA_TYPE.contains,
  [FILTER_OPERATOR.excludes]: FILTERS_CRITERIA_TYPE.excludes,
  [FILTER_OPERATOR.starts_with]: FILTERS_CRITERIA_TYPE.starts_with,
  [FILTER_OPERATOR.ends_with]: FILTERS_CRITERIA_TYPE.ends_with,
  [FILTER_OPERATOR.equals]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.not_equals]: FILTERS_CRITERIA_TYPE.generic_comparison,
  [FILTER_OPERATOR.between]: FILTERS_CRITERIA_TYPE.between,
  [FILTER_OPERATOR.null]: FILTERS_CRITERIA_TYPE.null,
  [FILTER_OPERATOR.not_null]: FILTERS_CRITERIA_TYPE.not_null,
  [FILTER_OPERATOR.in]: FILTERS_CRITERIA_TYPE.in,
  [FILTER_OPERATOR.not_in]: FILTERS_CRITERIA_TYPE.not_in,
} as const

export const FILTER_TYPE = {
  Interval: 'INTERVAL',
  Drill: 'DRILL',
  GroupBy: 'GROUP_BY',
  Company: 'COMPANY',
  Process: 'PROCESS',
  CsvReport: 'CSV_REPORT',
  Timezone: 'TIMEZONE',
  Dynamic: 'DYNAMIC',
  Filter: 'FILTER',
  BASE: 'BASE',
  DateView: 'DATE_VIEW',
  CustomSelect: 'CUSTOM_SELECT',
  BaseVariable: 'BASE_VARIABLE',
} as const

export const VIEW_TYPES = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
} as Record<string, TDateViewLevel>

export const DATE_TIME_FORMATS = {
  [VIEW_TYPES.HOURLY]: 'MMM d, yyyy HH:00',
  [VIEW_TYPES.DAILY]: 'MMM d, yyyy',
  [VIEW_TYPES.WEEKLY]: 'MMM d, yyyy',
  [VIEW_TYPES.MONTHLY]: 'MMM yyyy',
  [VIEW_TYPES.QUARTERLY]: 'QQQ yyyy',
  [VIEW_TYPES.YEARLY]: 'yyyy',
} as Record<TDateViewLevel, string>

export const REPORT_VISUALIZATION_ORDER = {
  [CHART_TYPE.BarChart]: 1,
  [CHART_TYPE.LineChart]: 2,
  [CHART_TYPE.Table]: 3,
  [CHART_TYPE.PieChart]: 4,
  [CHART_TYPE.Grid]: 5,
  [CHART_TYPE.Tile]: 6,
  [CHART_TYPE.Choropleth]: 7,
  [CHART_TYPE.Linear]: 8,
}
