import { FC } from 'react'
import ContentLoader from 'react-content-loader'

interface ICircleProps {
  radius: number
  speed?: number
  backgroundColor?: string
  foregroundColor?: string
}

interface IRectangleProps {
  speed?: number
  width?: number | string
  height: number
  backgroundColor?: string
  foregroundColor?: string
  rx?: number
  ry?: number
  className?: string
}

// eslint-disable-next-line react/jsx-no-useless-fragment
// eslint-disable-next-line @typescript-eslint/ban-types
const SkeletonComponent: FC = ({ children }) => <> {children}</>

const Circle = ({
  radius = 5,
  speed = 2,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  ...props
}: ICircleProps) => (
  <ContentLoader
    data-cy='skeletonCircle'
    speed={speed}
    width={radius * 2}
    height={radius * 2}
    viewBox={`0 0 ${radius * 2} ${radius * 2}`}
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    {...props}>
    <circle cx='50%' cy='50%' r='50%' />
  </ContentLoader>
)

const Rectangle = ({
  speed = 2,
  width,
  height,
  backgroundColor = '#f3f3f3',
  foregroundColor = '#ecebeb',
  rx = 3,
  ry = 3,
  ...props
}: IRectangleProps) => (
  <ContentLoader
    data-cy='skeletonRectangle'
    speed={speed}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    {...props}>
    <rect x={0} y={0} rx={rx} ry={ry} width='100%' height='100%' />
  </ContentLoader>
)

const Skeleton = Object.assign(SkeletonComponent, { Circle, Rectangle })

export { Skeleton }
