import { TIdleStatus, useStore } from '@invisible/common/stores/process-store'
import {
  fromGlobalId,
  IUserActivityEnum,
  toGlobalId,
  useCheckIdleTimeoutOnReloadMutation,
  useIdleCheckMutation,
  useStepRunSnoozeOnReconnectMutation,
  useStepRunSnoozeV2Mutation,
  useUserActivityCreateMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from '@invisible/trpc/client'
import { differenceInMilliseconds } from 'date-fns/fp'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useGate } from 'statsig-react'
import { validate as isValidUUID } from 'uuid'
import shallow from 'zustand/shallow'

import { AUTO_SNOOZE_STOP_REASON, LOCAL_STORAGE_KEYS } from './constants'

const IDLE_CHECK_INTERVAL = 350
const IDLE_CHECK_TRIGGER_TIMER = 2 * 60 * 1000 // 2 minutes
const FORCE_AUTO_SNOOZE_TIMER = 2 * 60 * 1000 // 2 minutes
const OFFLINE_TIMER = 1 * 45 * 1000 // 45 seconds
const FIFTEEN_MINS = 15 * 60 * 1000

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

const resetIdleCheckStateStorage = () => {
  if (!window || typeof window === 'undefined') return
  logger.info('Auto-snooze: Resetting idle check state storage')
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.IDLE_CHECK,
    JSON.stringify({
      isModalOpen: false,
      isAutoSnoozed: false,
      stepRunId: null,
      stepName: null,
      timeoutTimestamp: null,
      isNotWorkingOnConfiguredStep: false,
    })
  )
}

const useUserActivity = () => {
  const reactQueryContext = useContext()
  const { value: enableAutoSnooze } = useGate('enable-auto-snooze-v2')
  const [isActive, setIsActive] = useState(true)

  const { mutateAsync: concordeCreateUserActivity } = useUserActivityCreateMutation({
    onError: (error: { message: string }) => {
      logger.error(error.message)
    },
  })

  const { mutateAsync: concordeStepRunSnoozeOnReconnect } = useStepRunSnoozeOnReconnectMutation({
    onSuccess: (data) => {
      if (data.stepRunSnoozeOnReconnect?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to snooze step run on reconnect')
        return
      }

      if (data.stepRunSnoozeOnReconnect?.id) {
        const body = {
          isModalOpen: true,
          isAutoSnoozed: true,
          stepRunId: fromGlobalId(data.stepRunSnoozeOnReconnect.id),
          stepName: data.stepRunSnoozeOnReconnect.step.name,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: true,
        }
        localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
        window.dispatchEvent(new Event('storage'))
      }
    },
  })

  const { mutateAsync: concordeStepRunSnoozeV2 } = useStepRunSnoozeV2Mutation({
    onSuccess: (data) => {
      if (data.stepRunSnoozeV2?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to snooze step run')
        return
      }
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
  })

  const { mutateAsync: concordeIdleCheckOnReload } = useCheckIdleTimeoutOnReloadMutation({
    onSuccess: (data) => {
      if (data.checkIdleTimeoutOnReload?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to check user idle status on reload')
        return
      }

      if (data.checkIdleTimeoutOnReload?.id) {
        const body = {
          isModalOpen: true,
          isAutoSnoozed: true,
          stepRunId: fromGlobalId(data.checkIdleTimeoutOnReload.id),
          stepName: data.checkIdleTimeoutOnReload
            ? data.checkIdleTimeoutOnReload?.step?.name
            : null,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: true,
        }
        localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
        window.dispatchEvent(new Event('storage'))
      }
    },
  })

  const { mutateAsync: concordeIdleCheck } = useIdleCheckMutation({
    onSuccess: (data) => {
      if (data.checkIdleTimeout?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to check user idle status')
        return
      }
      const body = {
        isModalOpen: false,
        isAutoSnoozed: false,
        stepRunId: data?.checkIdleTimeout ? fromGlobalId(data.checkIdleTimeout.stepRun?.id) : null,
        stepName: data.checkIdleTimeout ? data.checkIdleTimeout.stepRun?.step.name : null,
        timeoutTimestamp: data.checkIdleTimeout ? data?.checkIdleTimeout.timeoutTimestamp : null,
        isNotWorkingOnConfiguredStep: true,
      }
      // idleCheck.timeoutTimestamp defines when the idle warning modal should be displayed
      localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
      window.dispatchEvent(new Event('storage'))
    },
  })

  const [lastActivityDate, setLastActivityDate] = useState(new Date())
  const [isNewTab, setIsNewTab] = useState(true)
  const { setIdleStatus } = useStore(
    useCallback(
      (state) => ({
        setIdleStatus: state.setIdleStatus,
      }),
      []
    ),
    shallow
  )

  const getStorageIdleCheck = () => {
    const storedIdleCheck = localStorage.getItem(LOCAL_STORAGE_KEYS.IDLE_CHECK)
    return storedIdleCheck ? (JSON.parse(storedIdleCheck) as TIdleStatus) : null
  }

  const updateStorageIdleCheck = (body: TIdleStatus) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
    window.dispatchEvent(new Event('storage'))
    setIdleStatus(body)
  }

  const getStorageOfflineStatus = () => {
    const storedOfflineModal = localStorage.getItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL)
    const storedLastOnline = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ONLINE)
    return {
      offlineModal: storedOfflineModal ? (JSON.parse(storedOfflineModal) as boolean) : null,
      lastOnline: storedLastOnline ? new Date(storedLastOnline) : null,
    }
  }

  const isInApp =
    window.location.href.includes('app.inv.tech') || window.location.href.includes('app-sta.invsta')

  useInterval(async () => {
    if (!enableAutoSnooze || !isInApp) return

    const idleCheck = getStorageIdleCheck()
    if (!idleCheck) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.IDLE_CHECK,
        JSON.stringify({
          isModalOpen: false,
          isAutoSnoozed: false,
          stepRunId: null,
          stepName: null,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: false,
        })
      )
      return
    }

    // Reset idle check local storage state if the current combination of auto-snooze, modal state,
    // and stepRunId validity requires reinitialization.
    if (
      (idleCheck.isAutoSnoozed &&
        idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string)) ||
      (idleCheck.isAutoSnoozed &&
        !idleCheck.isModalOpen &&
        isValidUUID(idleCheck.stepRunId as string)) ||
      (idleCheck.isAutoSnoozed &&
        !idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string)) ||
      (!idleCheck.isAutoSnoozed &&
        idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string))
    ) {
      resetIdleCheckStateStorage()
      return
    }

    if (idleCheck?.isAutoSnoozed) return

    const storedBrowserActivity = localStorage.getItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY)
    if (!storedBrowserActivity) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY, new Date().toISOString())
      return
    }
    const browserActivity = new Date(storedBrowserActivity)

    // Run once per tab on load
    // If user is coming back to a running task, they'll be auto snoozed
    if (isNewTab && navigator.onLine) {
      setIsNewTab(false)
      concordeIdleCheckOnReload({ lastActivityTime: browserActivity })
    }

    // If the leader heartbeat is not updated, the leader is dead

    const storedLeaderHeartbeat = localStorage.getItem(LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT)
    if (storedLeaderHeartbeat) {
      const leaderHeartbeat = new Date(JSON.parse(storedLeaderHeartbeat))

      if (leaderHeartbeat.getTime() + IDLE_CHECK_INTERVAL * 10 < new Date().getTime()) {
        const storedBrowserActivity = localStorage.getItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY)
        if (!storedBrowserActivity) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY, new Date().toISOString())
          return
        }
        const browserActivity = new Date(storedBrowserActivity)
        // If the leader is dead, the user is now the leader
        // Set the current tab activity time as the leader heartbeat
        setLastActivityDate(browserActivity)
      }
    }

    if (lastActivityDate.getTime() < browserActivity.getTime()) return

    // Leader sets a heartbeat on local storage
    // If the heartbeat is not updated, the leader is dead
    localStorage.setItem(LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT, JSON.stringify(new Date().getTime()))

    if (!navigator.onLine) {
      // Offline handler
      const { offlineModal, lastOnline } = getStorageOfflineStatus()

      if (offlineModal) return

      // If last online has not been set yet
      // This is the first interval the user has been offline
      if (!lastOnline) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ONLINE, new Date().toISOString())
        window.dispatchEvent(new Event('storage'))
        return
      }

      // Show the offline modal if user has been offline for more than OFFLINE_TIMER
      if (new Date().getTime() > lastOnline.getTime() + OFFLINE_TIMER) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL, JSON.stringify(true))
        window.dispatchEvent(new Event('storage'))
        return
      }
    } else {
      // Online handler
      localStorage.setItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY, lastActivityDate.toISOString())

      const { offlineModal, lastOnline } = getStorageOfflineStatus()

      if (lastOnline) {
        // Remove lastOnline so it resets the offline time
        localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_ONLINE)

        // If offline modal was being displayed, need to run snooze on reconnect
        // This will ensure any active configured step run gets auto-snooze at the time of disconnect
        if (offlineModal) {
          concordeStepRunSnoozeOnReconnect({ lastOnlineDate: new Date(lastOnline) })

          localStorage.setItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL, JSON.stringify(false))
          window.dispatchEvent(new Event('storage'))
        }
      }
    }

    // Force-snooze and idle warning modals
    if (idleCheck?.timeoutTimestamp && idleCheck?.stepRunId) {
      const warningTimeout = new Date(idleCheck.timeoutTimestamp * 1000)
      // Force-snooze
      // 2 minutes (FORCE_AUTO_SNOOZE_TIMER) after showing the first modal, should force-snooze tasks
      if (
        idleCheck?.timeoutTimestamp &&
        warningTimeout.getTime() + FORCE_AUTO_SNOOZE_TIMER < new Date().getTime()
      ) {
        if (!isValidUUID(idleCheck.stepRunId) || idleCheck?.isSnoozeRequestInProgress) return
        updateStorageIdleCheck({
          ...idleCheck,
          isSnoozeRequestInProgress: true,
        })
        concordeStepRunSnoozeV2({
          id: toGlobalId('StepRun', idleCheck.stepRunId),
          stopReason: AUTO_SNOOZE_STOP_REASON,
        })
          .then((data) => {
            if (data.stepRunSnoozeV2?.__typename === 'GraphQLErrorType') {
              logger.warn('Auto-snooze: Failed to force-snooze step run')
              return
            }
            updateStorageIdleCheck({
              isModalOpen: true,
              isAutoSnoozed: true,
              stepRunId: idleCheck.stepRunId,
              stepName: idleCheck?.stepName,
              timeoutTimestamp: idleCheck?.timeoutTimestamp,
              isNotWorkingOnConfiguredStep: idleCheck?.isNotWorkingOnConfiguredStep ?? false,
              isSnoozeRequestInProgress: false,
            })
          })
          .catch(() => {
            updateStorageIdleCheck({
              ...idleCheck,
              isSnoozeRequestInProgress: false,
            })
          })

        return
      }

      // Idle warning modal
      if (idleCheck?.timeoutTimestamp && warningTimeout.getTime() < new Date().getTime()) {
        updateStorageIdleCheck({
          isModalOpen: true,
          isAutoSnoozed: false,
          stepRunId: idleCheck?.stepRunId ?? null,
          stepName: idleCheck?.stepName ?? null,
          timeoutTimestamp: idleCheck?.timeoutTimestamp ?? null,
          isNotWorkingOnConfiguredStep: idleCheck?.isNotWorkingOnConfiguredStep ?? false,
        })
      }
    }

    // If modal is open, idle timeout is already set
    if (idleCheck?.isModalOpen) return

    // Activity happened before the idle check trigger timer
    // Reset the idle status and return
    if (lastActivityDate.getTime() + IDLE_CHECK_TRIGGER_TIMER > new Date().getTime()) {
      updateStorageIdleCheck({
        isModalOpen: false,
        isAutoSnoozed: false,
        stepRunId: null,
        stepName: null,
        timeoutTimestamp: null,
        isNotWorkingOnConfiguredStep: false,
      })
      return
    }

    // Getting here means this is the lead tab and the user is afk
    if (idleCheck?.isNotWorkingOnConfiguredStep) return

    // It will now request the server to check if the user is working on a step with idle check configured

    // Concorde returns the timestamp for when the idle warning modal should be displayed
    concordeIdleCheck({ lastActivityTime: lastActivityDate }).catch((err) => {
      logger.error('Auto-snooze: Failed to check timeout', { err })
    })
  }, IDLE_CHECK_INTERVAL)

  // Runs once on component mount so idle modal state is consistent
  useEffect(() => {
    const storedIdleCheck = localStorage.getItem('idle-check')
    const idleCheck = storedIdleCheck ? JSON.parse(storedIdleCheck) : {}

    if (!idleCheck) return

    setIdleStatus({
      isModalOpen: idleCheck?.isModalOpen ?? false,
      isAutoSnoozed: idleCheck?.isAutoSnoozed ?? false,
      stepRunId: idleCheck?.stepRunId ?? null,
      stepName: idleCheck?.stepName ?? null,
      timeoutTimestamp: idleCheck?.timeoutTimestamp ?? null,
      isNotWorkingOnConfiguredStep: idleCheck?.timeoutTimestamp ? true : false,
    })
  }, [])

  // Interval request to determine if user should display Active status in platform
  useInterval(() => {
    const isStillActive = differenceInMilliseconds(lastActivityDate, new Date()) < FIFTEEN_MINS
    setIsActive(() => isStillActive)
  }, FIFTEEN_MINS)

  // Check-in to the server every 5 minutes if the user is online
  useInterval(() => {
    if (isActive) {
      concordeCreateUserActivity({
        activityType: IUserActivityEnum.Online,
      })
    }
  }, FIFTEEN_MINS / 3)

  const handleUserActivity = () => {
    setIsActive(() => true)
    setLastActivityDate(() => new Date())
  }

  useEffect(() => {
    concordeCreateUserActivity({
      activityType: isActive ? IUserActivityEnum.Online : IUserActivityEnum.Offline,
    })
  }, [isActive])

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('scroll', handleUserActivity)
    document.addEventListener('click', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)

    return () => {
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('scroll', handleUserActivity)
      document.removeEventListener('click', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
    }
  }, [])

  return isActive
}

export { resetIdleCheckStateStorage, useUserActivity }
