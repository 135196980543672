import { z } from '@invisible/zod'

const schema = z.object({
  conversationsBaseId: z.string().optional(),
  promptsBaseId: z.string().optional(),
  responsesBaseId: z.string().optional(),
  promptTextBaseVariableId: z.string().optional(),
  promptIndexBaseVariableId: z.string().optional(),
  promptResponseBaseVariableId: z.string().optional(),

  responseTextBaseVariableId: z.string().optional(),
  responseIdBaseVariableId: z.string().optional(),
  metadata: z
    .array(
      z.object({
        name: z.string(),
        type: z.string(),
        baseVariableId: z.string(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
