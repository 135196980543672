import { SVGProps } from 'react'

function WizardHat(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.1685 10.6812C12.9926 10.6275 12.8065 10.7264 12.7526 10.9021C12.6987 11.0779 12.7972 11.2642 12.9729 11.3185C14.4289 11.7654 15.3336 12.4096 15.3336 13.0001C15.3336 14.1036 12.3219 15.3334 8.00016 15.3334C3.67844 15.3334 0.666729 14.1036 0.666729 13.0001C0.666729 12.3988 1.59741 11.7465 3.09564 11.298C3.18114 11.2732 3.25312 11.2154 3.29564 11.1374C3.33828 11.0593 3.34788 10.9674 3.32243 10.8823C3.29687 10.797 3.23839 10.7256 3.15993 10.6836C3.08147 10.6418 2.98951 10.633 2.90457 10.6593C1.03146 11.2202 0 12.0515 0 13.0001C0 14.4545 2.80391 16 8 16C13.1961 16 16 14.4545 16 13.0001C16 12.0652 14.9945 11.2416 13.1685 10.6812Z'
        fill='currentColor'
      />
      <path
        d='M3.09803 12.2357C3.15652 12.2943 4.56846 13.6666 8.00032 13.6666C11.4322 13.6666 12.8443 12.2942 12.9026 12.2356C12.9947 12.1434 13.0247 12.0062 12.9796 11.8841L9.64619 2.88435C9.58235 2.7117 9.39049 2.62353 9.21784 2.68737C9.04519 2.75132 8.95702 2.94317 9.02086 3.11582L12.2717 11.8927C11.8397 12.2104 10.5128 12.9998 8.00029 12.9998C5.49237 12.9998 4.16571 12.2133 3.73171 11.8953L6.83286 3.90323C7.21154 3.00482 7.88666 2.26341 8.7458 1.80251C9.92649 1.20721 11.2081 0.837588 12.5247 0.712905C12.0653 1.76791 11.3541 2.69423 10.4535 3.41061C10.3853 3.46708 10.3424 3.54833 10.3343 3.63661C10.3261 3.72478 10.3533 3.81261 10.41 3.88058C10.4667 3.94866 10.5482 3.99129 10.6365 3.99921C10.7247 4.00703 10.8124 3.97946 10.8802 3.92265C12.9469 2.20049 13.3126 0.471594 13.327 0.398739C13.3475 0.296398 13.319 0.190372 13.2498 0.11226C13.1805 0.034136 13.0787 -0.0070459 12.9747 0.000991052C11.395 0.091726 9.85038 0.503893 8.43553 1.21233C7.4381 1.74703 6.6545 2.60796 6.21578 3.65122L3.02292 11.8793C2.97504 12.0024 3.00462 12.1423 3.09803 12.2357Z'
        fill='currentColor'
      />
      <path
        d='M10.3165 8.89408C10.271 8.75837 10.1438 8.66686 10.0006 8.66686H8.73529L8.32077 7.24044C8.27926 7.09814 8.14879 7.00026 8.00057 7.00026C7.85236 7.00026 7.72189 7.09814 7.68037 7.24044L7.26586 8.66686H6.00057C5.85738 8.66697 5.73026 8.75837 5.6846 8.89397C5.63906 9.02968 5.68516 9.17934 5.79922 9.26585L6.81485 10.035L6.35648 11.2128C6.30257 11.3515 6.3471 11.5092 6.46574 11.5992C6.58438 11.6891 6.74822 11.6895 6.86731 11.6001L8.00057 10.7502L9.13383 11.6001C9.25246 11.691 9.41731 11.6906 9.5354 11.5992C9.65393 11.5091 9.69857 11.3515 9.64466 11.2128L9.18629 10.035L10.2019 9.26585C10.3161 9.17947 10.3622 9.0298 10.3165 8.89408ZM8.47357 10.0422L8.59969 10.366L8.20058 10.0669C8.08205 9.97799 7.9191 9.97799 7.80058 10.0669L7.40146 10.366L7.52758 10.0422C7.5816 9.90344 7.53695 9.74575 7.41821 9.65581L6.99275 9.33362H7.51618C7.66439 9.3335 7.79486 9.23563 7.83638 9.09333L8.00055 8.52794L8.16473 9.09333C8.20624 9.23563 8.33671 9.3335 8.48493 9.33362H9.00835L8.5829 9.65592C8.46415 9.74587 8.41955 9.90346 8.47357 10.0422Z'
        fill='currentColor'
      />
      <path
        d='M2.66659 3.33358C2.75499 3.33358 2.83981 3.29842 2.90231 3.23592C2.96481 3.17343 2.99996 3.08861 2.99996 3.00022V2.3336C2.99996 2.14946 2.85074 2.00024 2.66659 2.00024C2.48255 2.00024 2.33333 2.14946 2.33333 2.3336V3.00022C2.33333 3.08861 2.36838 3.17343 2.43088 3.23592C2.49349 3.29842 2.5782 3.33358 2.66659 3.33358Z'
        fill='currentColor'
      />
      <path
        d='M2.66659 4.00019C2.48255 4.00019 2.33333 4.14941 2.33333 4.33356V5.00017C2.33333 5.1842 2.48255 5.33353 2.66659 5.33353C2.85074 5.33353 2.99996 5.1842 2.99996 5.00017V4.33356C2.99996 4.24516 2.96481 4.16035 2.90231 4.09785C2.83981 4.03535 2.75499 4.00019 2.66659 4.00019Z'
        fill='currentColor'
      />
      <path
        d='M3.33366 4.00044H4.00029C4.18433 4.00044 4.33366 3.85123 4.33366 3.66708C4.33366 3.48304 4.18433 3.33383 4.00029 3.33383H3.33366C3.14951 3.33383 3.00029 3.48305 3.00029 3.66708C3.00029 3.85123 3.14951 4.00044 3.33366 4.00044Z'
        fill='currentColor'
      />
      <path
        d='M1.33366 4.00044H2.00029C2.18433 4.00044 2.33366 3.85123 2.33366 3.66708C2.33366 3.48304 2.18433 3.33383 2.00029 3.33383H1.33366C1.14951 3.33383 1.00029 3.48305 1.00029 3.66708C1.00029 3.85123 1.14951 4.00044 1.33366 4.00044Z'
        fill='currentColor'
      />
      <path
        d='M13.0006 6.66675C12.8166 6.66675 12.6672 6.81608 12.6672 7.00012C12.6672 7.18415 12.8166 7.33348 13.0006 7.33348H13.334V7.66684C13.334 7.85099 13.4832 8.0001 13.6672 8.0001C13.8514 8.0001 14.0006 7.85088 14.0006 7.66673V7.33348H14.334C14.518 7.33348 14.6672 7.18415 14.6672 7.00012C14.6672 6.81608 14.518 6.66676 14.334 6.66676H14.0006V6.33351C14.0006 6.14936 13.8514 6.00014 13.6672 6.00014C13.4832 6.00014 13.334 6.14936 13.334 6.33351V6.66687L13.0006 6.66675Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default WizardHat
