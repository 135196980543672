import { SVGProps } from 'react'
function SearchOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5447 0.99707C6.70849 0.99707 0.355957 7.34961 0.355957 15.1859C0.355957 23.0221 6.70849 29.3746 14.5447 29.3746C17.9191 29.3746 21.0184 28.1967 23.4538 26.2296L29.7792 32.555C30.3686 33.1444 31.3244 33.1444 31.9139 32.555C32.5033 31.9655 32.5033 31.0098 31.9139 30.4203L25.5885 24.0949C27.5556 21.6595 28.7335 18.5602 28.7335 15.1859C28.7335 7.34961 22.381 0.99707 14.5447 0.99707ZM3.37485 15.1859C3.37485 9.01689 8.37578 4.01596 14.5447 4.01596C20.7137 4.01596 25.7146 9.01689 25.7146 15.1859C25.7146 21.3548 20.7137 26.3557 14.5447 26.3557C8.37578 26.3557 3.37485 21.3548 3.37485 15.1859Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SearchOutline
