import { z } from '@invisible/zod'

import { schema as formFieldSchema } from './FormField'

const schema = z.object({
  fields: z.array(formFieldSchema),
  hideBaseNames: z.boolean().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
