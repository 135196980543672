import { SVGProps } from 'react'
function Undo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='25'
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M18.3605 1.44181C16.1673 0.533848 13.754 0.296779 11.4259 0.760596C9.09819 1.22435 6.96028 2.36797 5.28254 4.04681L2.73926 6.58261V2.99182C2.73926 2.43954 2.29154 1.99182 1.73926 1.99182C1.18697 1.99182 0.739258 2.43954 0.739258 2.99182L0.739258 8.99166C0.739258 8.99171 0.739258 8.99161 0.739258 8.99166C0.739258 9.13034 0.76749 9.2626 0.818518 9.38266C0.861029 9.48289 0.920643 9.57761 0.997367 9.66235C1.01889 9.68616 1.04155 9.70892 1.06526 9.73056C1.14742 9.80564 1.2391 9.86455 1.33618 9.90726C1.45949 9.96163 1.59585 9.99182 1.73926 9.99182H7.73926C8.29154 9.99182 8.73926 9.54411 8.73926 8.99182C8.73926 8.43954 8.29154 7.99182 7.73926 7.99182H4.15849L6.69532 5.46245L6.69665 5.46112C8.09485 4.06178 9.87667 3.10856 11.8167 2.72205C13.7567 2.33553 15.7678 2.53309 17.5956 3.28973C19.4233 4.04636 20.9856 5.32808 22.0847 6.97275C23.1839 8.61742 23.7706 10.5511 23.7706 12.5293C23.7706 14.5075 23.1839 16.4412 22.0847 18.0859C20.9856 19.7305 19.4233 21.0122 17.5956 21.7689C15.7678 22.5255 13.7567 22.7231 11.8167 22.3366C9.87667 21.95 8.09485 20.9968 6.69665 19.5975C6.30629 19.2068 5.67312 19.2065 5.28244 19.5969C4.89176 19.9873 4.8915 20.6204 5.28186 21.0111C6.9597 22.6903 9.09788 23.8342 11.4259 24.298C13.754 24.7618 16.1673 24.5248 18.3605 23.6168C20.5538 22.7088 22.4286 21.1708 23.7476 19.1972C25.0666 17.2236 25.7706 14.9031 25.7706 12.5293C25.7706 10.1555 25.0666 7.83504 23.7476 5.86144C22.4286 3.88784 20.5538 2.34978 18.3605 1.44181Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Undo
