import { SVGProps } from 'react'
function ColoredPalette(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M19.3125 13.672C19.4421 13.1031 19.505 12.521 19.5 11.9376C19.4687 7.80478 16.0468 4.46103 11.9218 4.50009C10.1596 4.51868 8.4602 5.15723 7.12178 6.30373C5.78335 7.45022 4.89141 9.03142 4.60244 10.7699C4.31347 12.5084 4.64593 14.2931 5.54149 15.8109C6.43705 17.3288 7.83851 18.4827 9.49997 19.0704C9.7826 19.1722 10.0856 19.2043 10.3833 19.164C10.681 19.1237 10.9646 19.0122 11.21 18.8389C11.4553 18.6656 11.6553 18.4357 11.7928 18.1686C11.9304 17.9015 12.0014 17.6052 12 17.3048V17.0001C11.9989 16.7536 12.0467 16.5093 12.1406 16.2813C12.2345 16.0534 12.3725 15.8463 12.5468 15.672C12.7212 15.4976 12.9283 15.3596 13.1562 15.2657C13.3842 15.1719 13.6285 15.1241 13.875 15.1251H17.4843C17.9085 15.1254 18.3202 14.9818 18.6523 14.7179C18.9843 14.454 19.217 14.0852 19.3125 13.672Z'
        stroke='#BBA081'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 8.875C12.5178 8.875 12.9375 8.45527 12.9375 7.9375C12.9375 7.41973 12.5178 7 12 7C11.4822 7 11.0625 7.41973 11.0625 7.9375C11.0625 8.45527 11.4822 8.875 12 8.875Z'
        fill='#42BAFE'
      />
      <path
        d='M8.48438 10.9062C9.00214 10.9062 9.42188 10.4865 9.42188 9.96875C9.42188 9.45098 9.00214 9.03125 8.48438 9.03125C7.96661 9.03125 7.54688 9.45098 7.54688 9.96875C7.54688 10.4865 7.96661 10.9062 8.48438 10.9062Z'
        fill='#4DBC25'
      />
      <path
        d='M8.48438 14.9688C9.00214 14.9688 9.42188 14.549 9.42188 14.0312C9.42188 13.5135 9.00214 13.0938 8.48438 13.0938C7.96661 13.0938 7.54688 13.5135 7.54688 14.0312C7.54688 14.549 7.96661 14.9688 8.48438 14.9688Z'
        fill='#F9D466'
      />
      <path
        d='M15.5156 10.9062C16.0334 10.9062 16.4531 10.4865 16.4531 9.96875C16.4531 9.45098 16.0334 9.03125 15.5156 9.03125C14.9979 9.03125 14.5781 9.45098 14.5781 9.96875C14.5781 10.4865 14.9979 10.9062 15.5156 10.9062Z'
        fill='#E21B1B'
      />
    </svg>
  )
}

export default ColoredPalette
