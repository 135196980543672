import { SVGProps } from 'react'
function CheckCircleOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M23.8642 13.6983C24.356 13.2291 24.3743 12.45 23.9051 11.9582C23.4359 11.4664 22.6569 11.448 22.165 11.9172L13.9845 19.7214L10.3265 16.2256C9.83503 15.756 9.05595 15.7737 8.58633 16.2651C8.11671 16.7565 8.13439 17.5356 8.62581 18.0052L13.1335 22.3129C13.6089 22.7673 14.3576 22.7676 14.8334 22.3136L23.8642 13.6983Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2454 0.5C7.40881 0.5 0.245361 7.66344 0.245361 16.5C0.245361 25.3366 7.40881 32.5 16.2454 32.5C25.0819 32.5 32.2454 25.3366 32.2454 16.5C32.2454 7.66344 25.0819 0.5 16.2454 0.5ZM2.7069 16.5C2.7069 9.02291 8.76828 2.96154 16.2454 2.96154C23.7224 2.96154 29.7838 9.02291 29.7838 16.5C29.7838 23.9771 23.7224 30.0385 16.2454 30.0385C8.76828 30.0385 2.7069 23.9771 2.7069 16.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CheckCircleOutline
