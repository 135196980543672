import { SVGProps } from 'react'

function FieldNumber(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.8929 5.78246H10.1973C10.1089 5.78246 10.0366 5.85478 10.0366 5.94317V15.0557H9.88393L3.57321 5.8521C3.54375 5.80924 3.49286 5.78246 3.44196 5.78246H1.66071C1.57232 5.78246 1.5 5.85478 1.5 5.94317V18.3717C1.5 18.4601 1.57232 18.5325 1.66071 18.5325H3.34018C3.42857 18.5325 3.50089 18.4601 3.50089 18.3717V9.13335H3.65357L10.0339 18.4628C10.0634 18.5057 10.1143 18.5325 10.1679 18.5325H11.8929C11.9813 18.5325 12.0536 18.4601 12.0536 18.3717V5.94317C12.0536 5.85478 11.9813 5.78246 11.8929 5.78246ZM22.0179 16.845H13.875C13.7571 16.845 13.6607 16.9414 13.6607 17.0592V18.345C13.6607 18.4628 13.7571 18.5592 13.875 18.5592H22.0179C22.1357 18.5592 22.2321 18.4628 22.2321 18.345V17.0592C22.2321 16.9414 22.1357 16.845 22.0179 16.845ZM17.9411 15.1575C19.358 15.1575 20.4911 14.6967 21.3214 13.7726C22.1089 12.8914 22.5 11.7423 22.5 10.3226C22.5 8.90032 22.1089 7.73782 21.3214 6.85924C20.4938 5.9271 19.3607 5.46103 17.9411 5.46103C16.5027 5.46103 15.3723 5.92978 14.558 6.87531C13.7759 7.76192 13.3955 8.90835 13.3955 10.3226C13.3955 11.7262 13.7786 12.8726 14.5607 13.7592C15.3804 14.6887 16.5161 15.1575 17.9411 15.1575V15.1575ZM16.0259 8.09406C16.4786 7.54228 17.1054 7.26639 17.9384 7.26639C18.7821 7.26639 19.4063 7.52353 19.8402 8.04585C20.2795 8.5896 20.5071 9.34764 20.5071 10.32C20.5071 11.2923 20.2821 12.0369 19.8429 12.5673C19.4009 13.0869 18.7714 13.3494 17.9384 13.3494C17.1027 13.3494 16.4652 13.0735 16.0259 12.5351C15.5893 11.9967 15.3696 11.2682 15.3696 10.3226C15.3723 9.36371 15.5893 8.63514 16.0259 8.09406V8.09406Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FieldNumber
