import { SVGProps } from 'react'
function RLHFWACIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clip-path='url(#clip0_1450_150304)'>
        <path
          d='M8.652 13.1364H2.958C2.2322 13.1364 1.6408 13.7202 1.6408 14.4366V20.057C1.6408 20.7736 2.2322 21.3572 2.958 21.3572H8.652C9.378 21.3572 9.9692 20.7734 9.9692 20.057V14.4366C9.9692 13.72 9.3778 13.1364 8.652 13.1364ZM8.3474 19.7566H3.2626V14.7374H8.3474V19.7566Z'
          fill='#604CA5'
        />
        <path
          d='M31.189 14.7528H13.464C13.017 14.7528 12.653 15.112 12.653 15.5532C12.653 15.9944 13.0168 16.3536 13.464 16.3536H31.189C31.6358 16.3536 31.9998 15.9944 31.9998 15.5532C31.9998 15.112 31.636 14.7528 31.189 14.7528Z'
          fill='#604CA5'
        />
        <path
          d='M13.464 19.7408H25.4336C25.8804 19.7408 26.2444 19.3816 26.2444 18.9404C26.2444 18.4992 25.8806 18.14 25.4336 18.14H13.464C13.017 18.14 12.653 18.4992 12.653 18.9404C12.653 19.3816 13.0168 19.7408 13.464 19.7408Z'
          fill='#604CA5'
        />
        <path
          d='M10.1828 3.37719L7.6888 3.01999L6.431 0.504594C6.2708 0.180594 5.9444 -0.0224064 5.5766 -0.0244064H5.5726C5.264 -0.0244064 4.8468 0.229394 4.7102 0.503594L3.4522 3.01999L0.958402 3.37719C0.535002 3.43779 0.189002 3.72579 0.0564015 4.12779C-0.0779984 4.52899 0.0308015 4.96239 0.337202 5.25919L2.143 6.99459L1.7158 9.45239C1.6466 9.86919 1.8186 10.2822 2.1648 10.529C2.5128 10.777 2.9638 10.8092 3.3376 10.611L5.5706 9.45419L7.8016 10.611C7.9678 10.697 8.1458 10.7388 8.3236 10.7388C8.553 10.7388 8.7824 10.6676 8.9782 10.527C9.3262 10.278 9.4964 9.86419 9.4232 9.44839L8.996 6.99639L10.7998 5.26099C11.1082 4.96619 11.217 4.53279 11.0866 4.12959C10.9542 3.72659 10.608 3.43779 10.1828 3.37719ZM7.677 6.03219C7.4178 6.28199 7.2952 6.64419 7.3546 7.00819L7.6532 8.72819L6.0948 7.91899C5.7686 7.74619 5.375 7.74619 5.0526 7.91699L3.4862 8.72919L3.7828 7.01499C3.8482 6.66079 3.7314 6.29559 3.4644 6.03199L2.1986 4.81579L3.949 4.56399C4.317 4.51119 4.6334 4.28179 4.7876 3.95979L5.5708 2.39599L6.348 3.95099C6.5082 4.28199 6.8246 4.51139 7.1946 4.56499L8.941 4.81579L7.677 6.03219Z'
          fill='#604CA5'
        />
        <path
          d='M13.464 4.46259H31.189C31.6358 4.46259 31.9998 4.10439 31.9998 3.66319C31.9998 3.22199 31.636 2.86379 31.189 2.86379H13.464C13.017 2.86379 12.653 3.22199 12.653 3.66319C12.653 4.10439 13.0168 4.46259 13.464 4.46259Z'
          fill='#604CA5'
        />
        <path
          d='M13.464 7.85079H25.4336C25.8804 7.85079 26.2444 7.49259 26.2444 7.05139C26.2444 6.61019 25.8806 6.25199 25.4336 6.25199H13.464C13.017 6.25199 12.653 6.61019 12.653 7.05139C12.653 7.49259 13.0168 7.85079 13.464 7.85079Z'
          fill='#604CA5'
        />
        <path
          d='M31.189 25.371H13.464C13.017 25.371 12.653 25.7302 12.653 26.1714C12.653 26.6126 13.0168 26.9718 13.464 26.9718H31.189C31.6358 26.9718 31.9998 26.6126 31.9998 26.1714C31.9998 25.7302 31.636 25.371 31.189 25.371Z'
          fill='#604CA5'
        />
        <path
          d='M25.4336 28.7582H13.464C13.017 28.7582 12.653 29.1174 12.653 29.5586C12.653 29.9998 13.0168 30.359 13.464 30.359H25.4336C25.8804 30.359 26.2444 29.9998 26.2444 29.5586C26.2444 29.1174 25.8806 28.7582 25.4336 28.7582Z'
          fill='#604CA5'
        />
        <path
          d='M8.652 23.7546H2.958C2.2322 23.7546 1.6408 24.3382 1.6408 25.0548V30.6752C1.6408 31.3916 2.2322 31.9754 2.958 31.9754H8.652C9.378 31.9754 9.9692 31.3916 9.9692 30.6752V25.0548C9.9692 24.3382 9.3778 23.7546 8.652 23.7546ZM8.3474 30.3746H3.2626V25.3554H8.3474V30.3746Z'
          fill='#604CA5'
        />
      </g>
      <defs>
        <clipPath id='clip0_1450_150304'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RLHFWACIcon
