import { SVGProps } from 'react'
function Asterisk(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.1 0c1.008 0 1.824.816 1.824 1.823v11.02l9.543-5.51a1.823 1.823 0 1 1 1.823 3.157L19.747 16l9.543 5.51a1.823 1.823 0 1 1-1.823 3.157l-9.543-5.51v11.02a1.823 1.823 0 0 1-3.646 0v-11.02l-9.543 5.51a1.823 1.823 0 0 1-1.823-3.157L12.455 16l-9.543-5.51a1.823 1.823 0 1 1 1.823-3.157l9.543 5.51V1.823C14.278.816 15.094 0 16.101 0Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Asterisk
