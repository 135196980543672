import { z } from '@invisible/zod'

const schema = z.object({
  annotationBaseId: z.string().uuid().optional(),
  textBaseVariableId: z.string().uuid().optional(),
  startBaseVariableId: z.string().uuid().optional(),
  endBaseVariableId: z.string().uuid().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
