import { Button } from '@invisible/ui/button'
import { Input } from '@invisible/ui/input'
import { Switch } from '@invisible/ui/switch'
import { TagInput } from '@invisible/ui/tag-input'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { ChangeEvent, FC, useState } from 'react'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>

interface IProps {
  baseRunId: string
  baseRunVariables: TBaseRunVariables
  data?: { type: string; name: string; baseVariableId: string }[]
  updateVariable: (data: { baseRunId: string; baseVariableId: string; value: any }) => any
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Metadata: FC<IProps> = ({ baseRunId, baseRunVariables, data: metadata, updateVariable }) => {
  const [brvs, setBrvs] = useState<Record<string, any>>(() =>
    baseRunVariables.reduce((acc, brv) => ({ ...acc, [brv.baseVariableId]: brv.value }), {})
  )
  const [isSaving, setIsSaving] = useState<Record<string, boolean>>({})

  return (
    <div className='flex w-96 flex-col gap-4'>
      {(metadata ?? []).map((item) => (
        <div className='flex items-center justify-between'>
          <div className='mb-1'>{item.name}</div>
          {item.type === 'a_string' ? (
            <TagInput
              tags={
                (baseRunVariables.find((v) => v.baseVariableId === item.baseVariableId)?.value ??
                  []) as string[]
              }
              onChange={(value) =>
                updateVariable({
                  baseRunId,
                  baseVariableId: item.baseVariableId,
                  value,
                })
              }
            />
          ) : item.type === 'boolean' ? (
            <Switch
              size='medium'
              isOn={
                (baseRunVariables.find((v) => v.baseVariableId === item.baseVariableId)?.value ??
                  false) as boolean
              }
              onToggle={(value) =>
                updateVariable({
                  baseRunId,
                  baseVariableId: item.baseVariableId,
                  value,
                })
              }
            />
          ) : (
            <div className='flex'>
              <Input
                value={(brvs[item.baseVariableId] ?? '') as string}
                type={item.type === 'number' ? 'number' : 'text'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setBrvs({ ...brvs, [item.baseVariableId]: e.target.value })
                }
              />
              <div className='ml-2 flex items-center'>
                <Button
                  size='sm'
                  variant='secondary'
                  onClick={async () => {
                    setIsSaving((prev) => ({ ...prev, [item.baseVariableId]: true }))
                    await updateVariable({
                      baseRunId,
                      baseVariableId: item.baseVariableId,
                      value: brvs[item.baseVariableId],
                    })
                    setIsSaving((prev) => ({ ...prev, [item.baseVariableId]: false }))
                  }}>
                  {isSaving[item.baseVariableId] ? 'Updating...' : 'Update'}
                </Button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export { Metadata }
