import type { TJson } from '@invisible/zod'
import { constObjectToZod, z } from '@invisible/zod'
import { includes, values } from 'lodash/fp'

const VARIABLE_STAGES = {
  BUILD: 'build',
  SCOPE: 'scope',
  OPERATE: 'operate',
} as const
const _VARIABLE_STAGES = values(VARIABLE_STAGES)
const VariableStagesSchema = constObjectToZod(VARIABLE_STAGES)
type TVariableStage = z.infer<typeof VariableStagesSchema>

const VARIABLE_STAGE_DEFAULT = VARIABLE_STAGES.OPERATE

const VARIABLE_DIRECTIONS = {
  INPUT: 'input',
  OUTPUT: 'output',
} as const
const _VARIABLE_DIRECTIONS = values(VARIABLE_DIRECTIONS)
const VariableDirectionsSchema = constObjectToZod(VARIABLE_DIRECTIONS)
type TVariableDirection = z.infer<typeof VariableDirectionsSchema>

const VARIABLE_BASIC_TYPES = {
  ANY: 'any',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATETIME: 'datetime',
  DURATION: 'duration',
  EMAIL: 'email',
  ENUM: 'enum',
  HTML: 'html',
  NUMBER: 'number',
  STRING: 'string',
  URL: 'url',
  MONEY: 'money',
  DATASET: 'dataset',
} as const
const _VARIABLE_BASIC_TYPES = values(VARIABLE_BASIC_TYPES)
const VariableBasicTypesSchema = constObjectToZod(VARIABLE_BASIC_TYPES)
type TVariableBasicType = z.infer<typeof VariableBasicTypesSchema>

/**
 * These are used when a variable contains a reference to another manticore entity.
 * For example, a QA step run might want to refer to a specific Operate step run.
 */
const VARIABLE_ID_TYPES = {
  BASE_ID: 'base_id',
  BASE_RUN_ID: 'base_run_id',
  PROCESS_ID: 'process_id',
  STEP_ID: 'step_id',
  STEP_RUN_ID: 'step_run_id',
  BASE_VARIABLE_ID: 'base_variable_id',
  BASE_RUN_VARIABLE_ID: 'base_run_variable_id',
} as const
const _VARIABLE_ID_TYPES = values(VARIABLE_ID_TYPES)
const VariableIdTypesSchema = constObjectToZod(VARIABLE_ID_TYPES)
type TVariableIdType = z.infer<typeof VariableIdTypesSchema>

const VARIABLE_GENERIC_TYPES = {
  OBJECT: 'object',
} as const
const _VARIABLE_GENERIC_TYPES = values(VARIABLE_GENERIC_TYPES)
const VariableGenericTypesSchema = constObjectToZod(VARIABLE_GENERIC_TYPES)
type TVariableGenericType = z.infer<typeof VariableGenericTypesSchema>

const VARIABLE_ARRAY_TYPES = {
  ARR_ANY: 'a_any',
  ARR_BOOLEAN: 'a_boolean',
  ARR_DATE: 'a_date',
  ARR_DATETIME: 'a_datetime',
  ARR_DURATION: 'a_duration',
  ARR_EMAIL: 'a_email',
  ARR_ENUM: 'a_enum',
  ARR_HTML: 'a_html',
  ARR_NUMBER: 'a_number',
  ARR_OBJECT: 'a_object',
  ARR_STRING: 'a_string',
  ARR_URL: 'a_url',
} as const
const _VARIABLE_ARRAY_TYPES = values(VARIABLE_ARRAY_TYPES)
const VariableArrayTypesSchema = constObjectToZod(VARIABLE_ARRAY_TYPES)
type TVariableArrayType = z.infer<typeof VariableArrayTypesSchema>

const VARIABLE_ARRAY_OF_ARRAY_TYPES = {
  ARR_ARR_ANY: 'a_a_any',
  ARR_ARR_OBJECT: 'a_a_object',
  ARR_ARR_STRING: 'a_a_string',
} as const

const _VARIABLE_ARRAY_OF_ARRAY_TYPES = values(VARIABLE_ARRAY_OF_ARRAY_TYPES)
const VariableArrayOfArrayTypesSchema = constObjectToZod(VARIABLE_ARRAY_OF_ARRAY_TYPES)
type TVariableArrayOfArrayType = z.infer<typeof VariableArrayOfArrayTypesSchema>

const VARIABLE_NESTED_TYPES = {
  ...VARIABLE_GENERIC_TYPES,
  ...VARIABLE_ARRAY_TYPES,
  ...VARIABLE_ARRAY_OF_ARRAY_TYPES,
} as const
const _VARIABLE_NESTED_TYPES = values(VARIABLE_NESTED_TYPES)
const VariableNestedTypesSchema = constObjectToZod(VARIABLE_NESTED_TYPES)
type TVariableNestedType = z.infer<typeof VariableNestedTypesSchema>

const VARIABLE_TYPES = {
  ...VARIABLE_BASIC_TYPES,
  ...VARIABLE_ID_TYPES,
  ...VARIABLE_NESTED_TYPES,
} as const
const _VARIABLE_TYPES = values(VARIABLE_TYPES)
const VariableTypesSchema = constObjectToZod(VARIABLE_TYPES)
type TVariableType = z.infer<typeof VariableTypesSchema>

const VARIABLE_TYPE_DEFAULT = VARIABLE_TYPES.STRING

// Given a type, return the array type
const VARIABLE_ARRAY_TYPE_MAP = {
  [VARIABLE_TYPES.ANY]: VARIABLE_TYPES.ARR_ANY,
  [VARIABLE_TYPES.BOOLEAN]: VARIABLE_TYPES.ARR_BOOLEAN,
  [VARIABLE_TYPES.DATE]: VARIABLE_TYPES.ARR_DATE,
  [VARIABLE_TYPES.DATETIME]: VARIABLE_TYPES.ARR_DATETIME,
  [VARIABLE_TYPES.DURATION]: VARIABLE_TYPES.ARR_DURATION,
  [VARIABLE_TYPES.EMAIL]: VARIABLE_TYPES.ARR_EMAIL,
  [VARIABLE_TYPES.ENUM]: VARIABLE_TYPES.ARR_ENUM,
  [VARIABLE_TYPES.HTML]: VARIABLE_TYPES.ARR_HTML,
  [VARIABLE_TYPES.NUMBER]: VARIABLE_TYPES.ARR_NUMBER,
  [VARIABLE_TYPES.OBJECT]: VARIABLE_TYPES.ARR_OBJECT,
  [VARIABLE_TYPES.STRING]: VARIABLE_TYPES.ARR_STRING,
  [VARIABLE_TYPES.URL]: VARIABLE_TYPES.ARR_URL,
  [VARIABLE_TYPES.ARR_ANY]: VARIABLE_TYPES.ARR_ARR_ANY,
  [VARIABLE_TYPES.ARR_OBJECT]: VARIABLE_TYPES.ARR_ARR_OBJECT,
  [VARIABLE_TYPES.ARR_STRING]: VARIABLE_TYPES.ARR_ARR_STRING,
} as const

// Given an array type, return the element type
// Simply a reverse of VARIABLE_ARRAY_TYPE_MAP
const VARIABLE_ARRAY_ELEMENT_TYPE_MAP = {
  [VARIABLE_TYPES.ARR_ANY]: [VARIABLE_TYPES.ANY],
  [VARIABLE_TYPES.ARR_BOOLEAN]: [VARIABLE_TYPES.BOOLEAN],
  [VARIABLE_TYPES.ARR_DATE]: [VARIABLE_TYPES.DATE],
  [VARIABLE_TYPES.ARR_DATETIME]: [VARIABLE_TYPES.DATETIME],
  [VARIABLE_TYPES.ARR_DURATION]: [VARIABLE_TYPES.DURATION],
  [VARIABLE_TYPES.ARR_EMAIL]: [VARIABLE_TYPES.EMAIL],
  [VARIABLE_TYPES.ARR_ENUM]: [VARIABLE_TYPES.ENUM],
  [VARIABLE_TYPES.ARR_HTML]: [VARIABLE_TYPES.HTML],
  [VARIABLE_TYPES.ARR_NUMBER]: [VARIABLE_TYPES.NUMBER],
  [VARIABLE_TYPES.ARR_OBJECT]: [VARIABLE_TYPES.OBJECT],
  [VARIABLE_TYPES.ARR_STRING]: [VARIABLE_TYPES.STRING],
  [VARIABLE_TYPES.ARR_URL]: [VARIABLE_TYPES.URL],
  [VARIABLE_TYPES.ARR_ARR_ANY]: [VARIABLE_TYPES.ARR_ANY],
  [VARIABLE_TYPES.ARR_ARR_OBJECT]: [VARIABLE_TYPES.ARR_OBJECT],
  [VARIABLE_TYPES.ARR_ARR_STRING]: [VARIABLE_TYPES.ARR_STRING],
} as const

// We store values as json strings, so when parsed, they can be any JSON type
// Later on, we'll add a parsing function to match up Manticore types with Typescript types
type TParsedVariableValue = TJson

const isBasicType = (t: string): t is TVariableBasicType => includes(t, _VARIABLE_BASIC_TYPES)
const isArrayType = (t: string): t is TVariableArrayType => includes(t, _VARIABLE_ARRAY_TYPES)
const isNestedType = (t: string): t is TVariableNestedType => includes(t, _VARIABLE_NESTED_TYPES)
const isIdType = (t: string): t is TVariableIdType => includes(t, _VARIABLE_ID_TYPES)

export {
  _VARIABLE_ARRAY_OF_ARRAY_TYPES,
  _VARIABLE_ARRAY_TYPES,
  _VARIABLE_BASIC_TYPES,
  _VARIABLE_DIRECTIONS,
  _VARIABLE_GENERIC_TYPES,
  _VARIABLE_ID_TYPES,
  _VARIABLE_NESTED_TYPES,
  _VARIABLE_STAGES,
  _VARIABLE_TYPES,
  isArrayType,
  isBasicType,
  isIdType,
  isNestedType,
  VARIABLE_ARRAY_ELEMENT_TYPE_MAP,
  VARIABLE_ARRAY_TYPE_MAP,
  VARIABLE_ARRAY_TYPES,
  VARIABLE_DIRECTIONS,
  VARIABLE_GENERIC_TYPES,
  VARIABLE_ID_TYPES,
  VARIABLE_NESTED_TYPES,
  VARIABLE_STAGE_DEFAULT,
  VARIABLE_STAGES,
  VARIABLE_TYPE_DEFAULT,
  VARIABLE_TYPES,
  VariableArrayOfArrayTypesSchema,
  VariableArrayTypesSchema,
  VariableBasicTypesSchema,
  VariableDirectionsSchema,
  VariableGenericTypesSchema,
  VariableNestedTypesSchema,
  VariableStagesSchema,
  VariableTypesSchema,
}

export type {
  TParsedVariableValue,
  TVariableArrayOfArrayType,
  TVariableArrayType,
  TVariableBasicType,
  TVariableDirection,
  TVariableGenericType,
  TVariableIdType,
  TVariableNestedType,
  TVariableStage,
  TVariableType,
}
