import { SVGProps } from 'react'

function FilledFilter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.08977 9.82031C4.08977 10.0277 4.25617 10.1953 4.46242 10.1953H7.53743C7.74368 10.1953 7.91008 10.0277 7.91008 9.82031V7.52344H4.08977V9.82031ZM10.3136 1.80469H1.68625C1.39914 1.80469 1.21985 2.11758 1.36399 2.36719L3.95735 6.77344H8.04485L10.6382 2.36719C10.78 2.11758 10.6007 1.80469 10.3136 1.80469V1.80469Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FilledFilter
