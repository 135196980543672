import { SVGProps } from 'react'
function SlackOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.195 0C9.50878 0 8.142 1.36984 8.142 3.05915C8.1416 3.46048 8.22025 3.85796 8.37346 4.22889C8.52667 4.59983 8.75144 4.93695 9.03494 5.22102C9.31843 5.50509 9.65511 5.73054 10.0257 5.88449C10.3964 6.03844 10.7937 6.11789 11.195 6.11829H14.2485V3.05915C14.2492 2.24858 13.9279 1.47093 13.3553 0.897242C12.7826 0.323556 12.0056 0.000812998 11.195 0V0ZM11.195 8.15772H3.05313C1.3669 8.15772 0.00012207 9.52757 0.00012207 11.2174C0.00012207 12.9067 1.3669 14.2765 3.05313 14.2765H11.1955C12.8812 14.2765 14.2485 12.9067 14.2485 11.2174C14.2485 9.52757 12.8813 8.15772 11.195 8.15772'
        fill='#36C5F0'
      />
      <path
        d='M30.5322 11.2174C30.5322 9.52757 29.1649 8.15772 27.4787 8.15772C25.7925 8.15772 24.4257 9.52757 24.4257 11.2174V14.2765H27.4787C28.2893 14.2757 29.0663 13.953 29.639 13.3793C30.2116 12.8056 30.5329 12.028 30.5322 11.2174V11.2174ZM22.3904 11.2174V3.05915C22.391 2.24858 22.0697 1.47093 21.4971 0.897242C20.9245 0.323556 20.1474 0.000812998 19.3368 0C17.6506 0 16.2838 1.36984 16.2838 3.05915V11.2169C16.2838 12.9072 17.6506 14.277 19.3368 14.277C20.1474 14.2762 20.9245 13.9535 21.4971 13.3798C22.0697 12.8061 22.391 12.0285 22.3904 11.2179'
        fill='#2EB67D'
      />
      <path
        d='M19.3368 30.5924C20.1474 30.5916 20.9245 30.2689 21.4971 29.6952C22.0697 29.1215 22.391 28.3439 22.3904 27.5333C22.391 26.7227 22.0697 25.9451 21.4971 25.3714C20.9245 24.7977 20.1474 24.475 19.3368 24.4742H16.2838V27.5333C16.2838 29.2226 17.6506 30.5924 19.3368 30.5924ZM19.3368 22.4347H27.4792C29.1649 22.4347 30.5322 21.0649 30.5322 19.3751C30.5329 18.5645 30.2116 17.7868 29.639 17.2132C29.0663 16.6395 28.2893 16.3167 27.4787 16.3159H19.3368C17.6506 16.3159 16.2838 17.6858 16.2838 19.3751C16.2834 19.7764 16.3621 20.1739 16.5153 20.5448C16.6685 20.9157 16.8933 21.2529 17.1768 21.5369C17.4602 21.821 17.7969 22.0465 18.1676 22.2004C18.5382 22.3544 18.9355 22.4338 19.3368 22.4342'
        fill='#ECB22E'
      />
      <path
        d='M1.54048e-06 19.3752C-0.000401401 19.7765 0.0782475 20.174 0.231458 20.545C0.384668 20.9159 0.609439 21.253 0.892938 21.5371C1.17644 21.8212 1.51311 22.0466 1.88374 22.2006C2.25436 22.3545 2.65168 22.434 3.05301 22.4344C3.86358 22.4336 4.64064 22.1108 5.21327 21.5371C5.7859 20.9634 6.10722 20.1858 6.10654 19.3752V16.3161H3.05301C1.36678 16.3161 1.54048e-06 17.6859 1.54048e-06 19.3752V19.3752ZM8.14188 19.3752V27.5329C8.14188 29.2228 9.50866 30.5926 11.1949 30.5926C12.0055 30.5918 12.7825 30.269 13.3552 29.6954C13.9278 29.1217 14.2491 28.344 14.2484 27.5335V19.3752C14.2488 18.9738 14.1702 18.5763 14.0169 18.2054C13.8637 17.8344 13.6389 17.4972 13.3553 17.2132C13.0717 16.9291 12.735 16.7037 12.3643 16.5497C11.9936 16.3958 11.5963 16.3164 11.1949 16.3161C9.50866 16.3161 8.14188 17.6859 8.14188 19.3752Z'
        fill='#E01E5A'
      />
    </svg>
  )
}

export default SlackOriginal
