import { useAbilityContext } from '@invisible/authorization/client'
import type { TProcessWithBases, TUserPermission } from '@invisible/common/components/process-base'
import { SnackbarContext } from '@invisible/common/providers'
import { TBaseViewDownloadFormat, useStore } from '@invisible/common/stores/process-store'
import { isWizard, isWizardless } from '@invisible/common/types'
import { logger } from '@invisible/logger/client'
import { useContext, useMutation, useQuery, useSubscription } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@invisible/ui/dropdown'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { Icons } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Radio } from '@invisible/ui/radio'
import { Table } from '@invisible/ui/table'
import { useToasts } from '@invisible/ui/toasts'
import {
  AGENT_ADMIN_ROLE_ID,
  AGENT_OPERATOR_ROLE_ID,
  AGENT_SQUAD_LEAD_ROLE_ID,
  AGENT_TEAM_LEAD_ROLE_ID,
} from '@invisible/ultron/shared'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BaseViewMeta } from '@invisible/ultron/zod'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Prisma } from '@prisma/client'
import { debounce, find, flatten, flow, get, orderBy, reduce } from 'lodash/fp'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import type { ChangeEvent } from 'react'
import { useCallback, useContext as reactUseContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useGate } from 'statsig-react'
import shallow from 'zustand/shallow'

import { BaseView } from './BaseView'
import { BaseViewEditor } from './BaseViewEditor'
import {
  parseFiltersDate,
  removeUndefinedFields,
  stringifyDateFilters,
} from './BaseViewEditor/helpers'
import { DEFAULT_ITEMS_PER_PAGE } from './common/constants'
import { FineTuneDialog } from './FineTuneDialog'
import { useExportBaseView } from './hooks/useExportBaseView'
import { TBaseRunQueryData, useGetBaseRuns } from './hooks/useGetBaseRuns'
import { useMyRoles } from './hooks/useMyRoles'
import { useSelectedEmbedLink } from './providers/SelectedEmbedLinkProvider'

const DOWNLOAD_FORMATS = [
  { label: 'CSV', value: 'csv' },
  { label: 'JSONL', value: 'jsonl' },
]

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>
type TStages = NonNullable<inferQueryOutput<'lifecycleStage.findManyByProcessId'>>
type TBase = TProcessWithBases['bases'][number]
type TStep = TBase['steps'][number]

interface IBaseProps {
  base: TBase
  height?: string
  stages: TStages
  openLinkInEmbed?: {
    embedId: string
    baseVariableId: string
  }
  onBooleanSwitchFail?: {
    baseVariableId: string
    handleFail: (baseRunVariableId: string, value: boolean) => void
  }
  baseViewPermissions: TUserPermission[]
}

const doneOrCancelledStatuses = ['done', 'cancelled']

const ColorStyles = {
  theme: 'text-theme-main',
  danger: 'text-red-main',
}

const MenuItem = ({
  label,
  icon,
  hoveredIcon,
  color = 'theme',
}: {
  label: string
  icon: keyof typeof Icons
  hoveredIcon: keyof typeof Icons
  color?: 'theme' | 'danger'
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={`text-header flex w-40 items-center gap-2 font-normal hover:font-medium hover:${ColorStyles[color]}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {hovered
        ? Icons[hoveredIcon]({ className: `h-5 w-5 ${ColorStyles[color]}` })
        : Icons[icon]({ className: `h-5 w-5 ${ColorStyles[color]}` })}
      <div>{label}</div>
    </div>
  )
}

export const Base = ({
  base,
  height,
  openLinkInEmbed,
  onBooleanSwitchFail,
  stages,
  baseViewPermissions,
}: IBaseProps) => {
  const { showSnackbar } = reactUseContext(SnackbarContext)
  const { addToast } = useToasts()
  const [_, ability] = useAbilityContext()
  const [isBaseViewDeniedModalOpen, setIsBaseViewDeniedModalOpen] = useState(false)
  const [baseViewId, setBaseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const router = useRouter()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [isFineTuneLLMOpen, setIsFineTuneLLMOpen] = useState(false)
  const [isDownloadInitiated, setIsDownloadInitiated] = useState(false)
  const [useAssigneeIdsAtExport, setUseAssigneeIdsAtExport] = useState(false)

  const { value: showFineTuneDialog } = useGate('enable-fine-tune-interface')

  const {
    setBaseViewSettingsModalOpen,
    isPrivate,
    itemsPerPage,
    filterOption,
    sortOption,
    searchTerm,
    currentPage,
    hiddenColumns,
    columnOrder,
    filterableColumns,
    editedPermissions,
    stageId,
    downloadFormat,
    setSortOption,
    setHiddenColumns,
    setColumnOrder,
    setFilterableColumns,
    setFilterOption,
    setIsFreshNew,
    setStageId,
    setSearchTerm,
    setDownloadFormat,
  } = useStore(
    useCallback(
      (state) => ({
        createdAtTZ: state.createdAtTZ,
        deliveredAtTZ: state.deliveredAtTZ,
        setBaseViewSettingsModalOpen: state.setBaseViewSettingsModalOpen,
        isPrivate: state.isPrivate,
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        downloadFormat: state.downloadFormat,
        currentPage: state.currentPage,
        hiddenColumns: state.hiddenColumns,
        columnOrder: state.columnOrder,
        filterableColumns: state.filterableColumns,
        editedPermissions: state.editedPermissions,
        stageId: state.stageId,
        setSortOption: state.setSortOption,
        setHiddenColumns: state.setHiddenColumns,
        setColumnOrder: state.setColumnOrder,
        setFilterableColumns: state.setFilterableColumns,
        setFilterOption: state.setFilterOption,
        setIsFreshNew: state.setIsFreshNew,
        setStageId: state.setStageId,
        setSearchTerm: state.setSearchTerm,
        setDownloadFormat: state.setDownloadFormat,
      }),
      []
    ),
    shallow
  )
  const { dispatch } = useSelectedEmbedLink()

  const currentBaseView = useMemo(
    () => base.baseViews.find((bv) => bv.id === baseViewId),
    [base.baseViews, baseViewId]
  )

  const parseBaseViewConfig = (config: Prisma.JsonValue | undefined) => {
    try {
      return config ? BaseViewMeta.schema.parse(config) : null
    } catch (error) {
      logger.error(`Error parsing base view config`, { config, error })

      showSnackbar({
        message: 'Base view config is invalid!',
        variant: 'error',
      })
      return null
    }
  }

  const currentBaseViewConfig = useMemo(
    () => parseBaseViewConfig(currentBaseView?.config),
    [currentBaseView?.config]
  )

  const { mutateAsync: exportBaseView, isLoading: isCreatingDownloadRequest } = useExportBaseView({
    filters: filterOption ?? [],
    sort: sortOption ?? {},
    search: searchTerm ?? '',
    baseId: base.id,
    baseViewId: baseViewId || base.baseViews[0]?.id,
    downloadFormat: downloadFormat,
    useAssigneeIdsAtExport: useAssigneeIdsAtExport,
    stepRunFilters: currentBaseViewConfig?.stepRunFilters ?? [],
  })

  const { data: baseRunData, isLoading } = useGetBaseRuns(
    {
      baseId: base.id,
      baseViewId: baseViewId || base.baseViews[0]?.id,
      take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      filters: filterOption ?? [],
      sort: sortOption ?? {},
      search: searchTerm ?? '',
      page: currentPage ?? 1,
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  )

  const allBaseRunVariablesIds = flatten(
    baseRunData?.items?.map((baseRun) => baseRun.baseRunVariables.map((brv) => brv.id))
  )

  // subscribe to baseRunVariables changes
  useSubscription(['baseRunVariable.onChange', { baseRunVariableIds: allBaseRunVariablesIds }], {
    onNext(brvEvents) {
      reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
        [
          'get-base-runs',
          {
            baseId: base.id,
            baseViewId: baseViewId || base.baseViews[0]?.id,
            take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
            filters: filterOption ?? [],
            sort: sortOption ?? {},
            search: searchTerm ?? '',
            page: currentPage ?? 1,
          },
        ],
        (prevData) => {
          if (!prevData) return prevData

          const updatedBaseRunData = prevData.items.map((baseRun) => ({
            ...baseRun,
            baseRunVariables: baseRun.baseRunVariables.map((brv) => {
              const match = brvEvents.find((brvEvent) => brv.id === brvEvent.baseRunVariableId)
              if (match) {
                return { ...brv, value: match.value }
              }
              return brv
            }),
          }))
          return {
            ...prevData,
            baseRuns: updatedBaseRunData,
          }
        }
      )
    },
    onError(err) {
      logger.error('Subscription error:', { err })
    },
  })

  const { data: session } = useSession()

  const { data: loggedInUser, isLoading: isLoggedInUserLoading } = useMyRoles({
    email: session?.user?.email ?? '',
  })

  const stepRunIdsAssignedToUser = useMemo(
    () =>
      flatten(
        (baseRunData as TBaseRunQueryData)?.items?.map((baseRun) =>
          baseRun.stepRuns
            .filter(
              (stepRun) => (stepRun.step.meta as Record<string, any> | undefined)?.wizardConfig
            )
            .filter(
              (stepRun) => stepRun.assignee?.id === loggedInUser?.id && stepRun.status !== 'done'
            )
            .map((stepRun) => stepRun.id)
        )
      ),
    [baseRunData, loggedInUser]
  )

  const { data: wizardInitialBaseRunVariables } = useQuery(
    ['baseRunVariable.findWizardDataForStepRun', { stepRunIds: stepRunIdsAssignedToUser }],
    { enabled: !isLoading && !isLoggedInUserLoading }
  )

  const { mutateAsync: deleteBaseView, isLoading: isDeleteBaseViewLoading } = useMutation(
    'baseView.delete',
    {
      onSettled: () => {
        reactQueryContext.invalidateQueries('process.findByIdWithBases')
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    }
  )

  const { mutateAsync: createBaseView } = useMutation('baseView.create', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('process.findByIdWithBases')
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onSuccess: (data) => {
      reactQueryContext.queryClient.setQueryData(
        [
          'process.findByIdWithBases',
          {
            id: router.query['id'],
          },
        ],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (prevData: TProcessWithBases) => ({
          ...prevData,
          bases: prevData.bases.map((b) =>
            b.id === base.id ? { ...b, baseViews: [...b.baseViews, data] } : b
          ),
        })
      )
    },
  })

  useEffect(() => {
    if (baseViewId) {
      const baseView = base.baseViews.find((bv) => bv.id === baseViewId)
      if (!baseView) return // this may happen temporary on base change

      if (
        !ability?.can('read', 'BaseView', baseView ?? {}) &&
        !baseViewPermissions?.some(
          (permission) =>
            permission.action === 'read' &&
            permission.subject === 'BaseView' &&
            typeof permission.conditions === 'object' &&
            permission.conditions !== null &&
            'id' in permission.conditions &&
            permission.conditions?.id === baseView.id
        ) &&
        baseView?.userId !== loggedInUser?.id
      ) {
        setIsBaseViewDeniedModalOpen(true)
      }
    }
  }, [ability, addToast, base.baseViews, baseViewId, baseViewPermissions])

  const visibleBaseViews = useMemo(
    () =>
      base?.baseViews?.filter(
        (baseView) =>
          ability?.can('read', 'BaseView', baseView ?? {}) ||
          baseView.userId === loggedInUser?.id ||
          baseViewPermissions.some(
            (permission) =>
              permission.action === 'read' &&
              permission.subject === 'BaseView' &&
              typeof permission.conditions === 'object' &&
              permission.conditions !== null &&
              'id' in permission.conditions &&
              permission.conditions?.id === baseView.id
          )
      ),
    [ability, base?.baseViews, loggedInUser?.id, baseViewPermissions]
  )

  useEffect(() => {
    if (!baseViewId || !base?.baseViews?.map(({ id }) => id).includes(baseViewId)) {
      setBaseViewId(visibleBaseViews[0]?.id as string)
    }

    const unparseConfig = base.baseViews.find((bv) => bv.id === baseViewId)?.config
    const baseViewConfig = parseBaseViewConfig(unparseConfig)
    if (!baseViewConfig) return

    const parsedFilters = parseFiltersDate(baseViewConfig.filters ?? [])

    setSortOption(baseViewConfig.sortBy ?? {})

    setHiddenColumns(baseViewConfig.hiddenColumns ?? [])

    setColumnOrder(baseViewConfig.columnOrder ?? [])

    setFilterableColumns(baseViewConfig.filterableColumns ?? [])

    setFilterOption(parsedFilters)

    setStageId(currentBaseView?.lifecycleStageId ?? null)
  }, [base.id, baseViewId])

  const changeBaseView = ({ label, value }: { label: string; value: string }) => {
    setBaseViewId(value)
    const config = base.baseViews.find((bv) => bv.id === value)?.config
    const baseViewConfig = parseBaseViewConfig(config)
    if (!baseViewConfig) return

    const parsedFilters = parseFiltersDate(baseViewConfig.filters ?? [])

    setSortOption(baseViewConfig.sortBy ?? {})

    setHiddenColumns(baseViewConfig.hiddenColumns ?? [])

    setColumnOrder(baseViewConfig.columnOrder ?? [])

    setFilterableColumns(baseViewConfig.filterableColumns ?? [])

    setFilterOption(parsedFilters)
  }

  const data = useMemo(
    () =>
      flatten(
        baseRunData?.items?.map((baseRun) => ({
          id: baseRun.id,
          timeleft: !doneOrCancelledStatuses.includes(baseRun.status) ? baseRun.deadline : null,
          parentBaseRunId: baseRun.parentId,
          baseRunStatus: baseRun.status,
          createdAt: baseRun.createdAt,
          deadline: baseRun.deadline, // we duplicate because we can't use twice the same accessor
          deliveredAt: baseRun.deliveredAt,
          ...baseRun.childCounts,
          ...reduce((acc, currentStep: TStep) => {
            const findStepRun = find(
              (sr: TStepRun) => sr.baseRunId === baseRun.id && sr.stepId === currentStep.id
            )

            if (isWizard(currentStep)) {
              return {
                ...acc,
                [currentStep.name]: {
                  // Manual step Wizard data
                  id: currentStep.id,
                  baseRunId: baseRun.id,
                  baseRun,
                  actualValue: currentStep.meta,
                  // the baseRun data required by the wizard
                  wizardInitialBRVs:
                    wizardInitialBaseRunVariables?.filter((brv) => brv.baseRunId === baseRun.id) ??
                    [],
                  isEditable: false,
                  processId: currentStep.processId,
                  stepRun: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                },
                [currentStep.id]: {
                  // Manual step assignee data
                  id: currentStep.id,
                  baseRunId: baseRun.id,
                  actualValue: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun,
                    get('assignee')
                  )(baseRun.stepRuns),
                  isEditable: false,
                  stepRun: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                },
              }
            }

            if (isWizardless(currentStep)) {
              return {
                ...acc,
                [currentStep.name]: {
                  // Wizardless Manual Step Wizard data
                  id: currentStep.id,
                  baseRunId: baseRun.id,
                  baseRun,
                  actualValue: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                  isEditable: false,
                  processId: currentStep.processId,
                  stepRun: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                },
                [currentStep.id]: {
                  // Wizardless Manual Step assignee data
                  id: currentStep.id,
                  baseRunId: baseRun.id,
                  actualValue: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun,
                    get('assignee')
                  )(baseRun.stepRuns),
                  isEditable: false,
                  stepRun: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                },
              }
            }

            if (
              currentStep.stepTemplate.type === 'full_auto' ||
              currentStep.stepTemplate.type === 'control_flow'
            ) {
              return {
                ...acc,
                [currentStep.name]: {
                  id: currentStep.id,
                  baseRunId: baseRun.id,
                  baseRun,
                  actualValue: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun,
                    get('status')
                  )(baseRun.stepRuns),
                  isEditable: false,
                  stepRun: flow(
                    orderBy((sr: TStepRun) => new Date(sr.createdAt), 'desc'),
                    findStepRun
                  )(baseRun.stepRuns),
                },
              }
            }
            return acc
          }, {})(base.steps),
          ...reduce(
            (acc, current) => ({
              ...acc,
              [current.baseVariable.id]: {
                // the key to get the baseVariable on the data object is the baseVariableId
                id: current.id,
                baseRunId: baseRun.id,
                actualValue: current.value,
                isEditable: ability?.can('update', 'BaseRunVariable'),
                options: current.baseVariable.options,
                onFail:
                  onBooleanSwitchFail?.baseVariableId === current.baseVariableId
                    ? onBooleanSwitchFail?.handleFail
                    : null,
                onUrlLinkClick:
                  openLinkInEmbed?.baseVariableId === current.baseVariable.id
                    ? (link: string) =>
                        dispatch({
                          type: 'changeEmbedControlLink',
                          payload: {
                            link,
                            embedId: openLinkInEmbed?.embedId,
                          },
                        })
                    : null,
              },
            }),
            {},
            baseRun.baseRunVariables || []
          ),
        }))
      ),
    [
      baseRunData,
      base.steps,
      dispatch,
      openLinkInEmbed,
      onBooleanSwitchFail?.baseVariableId,
      onBooleanSwitchFail?.handleFail,
      wizardInitialBaseRunVariables,
    ]
  )

  const deleteBaseViewHandler = async () => {
    await deleteBaseView({ id: baseViewId })
    setIsDeleteModalOpen(false)
  }

  const createBaseViewHandler = async () => {
    if (!baseViewId || !router.query['id']) return
    await createBaseView({
      processId: router.query['id'] as string,
      baseId: base.id,
      privateBaseView: isPrivate,
      name: `${currentBaseView?.name ?? ''}: copy`,
      roleIds: editedPermissions,
      stageId: stageId || undefined,
      config: {
        ...currentBaseViewConfig,
        filters: stringifyDateFilters(filterOption),
        sortBy: removeUndefinedFields(sortOption),
        hiddenColumns,
        columnOrder,
        filterableColumns,
      },
    })
  }

  const createNewBaseViewHandler = async () => {
    if (!router.query['id']) return
    const newBaseView = await createBaseView({
      processId: router.query['id'] as string,
      baseId: base.id,
      roleIds: ability?.can('view', 'BaseViewEditor')
        ? [
            AGENT_ADMIN_ROLE_ID,
            AGENT_OPERATOR_ROLE_ID,
            AGENT_SQUAD_LEAD_ROLE_ID,
            AGENT_TEAM_LEAD_ROLE_ID,
          ]
        : [],
      privateBaseView: !ability?.can('view', 'BaseViewEditor'),
      name: 'New Base View',
      config: {},
    })
    await reactQueryContext.invalidateQueries('permission.findByLoggedInUser')
    await reactQueryClient.invalidateQueries('UserPermissions')

    setBaseViewId(newBaseView.id)
    setBaseViewSettingsModalOpen(true)
    setIsFreshNew(true)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    addToast('Successfully copied link to your clipboard', {
      appearance: 'success',
    })
  }

  const updateSearchTerm = useCallback(
    debounce(500, (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)),
    [setSearchTerm]
  )

  const handleDownloadModalClose = () => {
    setIsDownloadModalOpen(false)
    setIsDownloadInitiated(false)
    setUseAssigneeIdsAtExport(false)
  }

  const handleDownloadModalButtonClick = async () => {
    if (isDownloadInitiated) {
      handleDownloadModalClose()
      return
    }

    try {
      await exportBaseView()
      setIsDownloadInitiated(true)
    } catch (err) {
      addToast(`Failed to submit download request: ${(err as Error).message}`, {
        appearance: 'error',
      })
      return
    }
  }

  const computeDownloadModalButtonText = () => {
    if (isDownloadInitiated) return 'Close'
    if (isCreatingDownloadRequest) return 'Submitting...'
    return 'Submit Request'
  }

  const visibleBaseViewsSorted = visibleBaseViews.sort(
    (a, b) => a?.name?.localeCompare(b.name ?? '') ?? -1
  )

  return (
    <>
      <div className='relative flex-grow'>
        <div className='absolute inset-0 flex flex-col'>
          <Table.TabProvider defaultTabValue={baseViewId ?? base.baseViews[0]?.id}>
            <Table.Header justifyBetween>
              <Table.Header.TabGroup>
                {visibleBaseViewsSorted.map((baseView) => (
                  <Table.Header.Tab
                    label={baseView.name ?? ''}
                    value={baseView.id}
                    onClick={changeBaseView}
                  />
                ))}
              </Table.Header.TabGroup>
              <div className='flex flex-grow items-center justify-between gap-2'>
                <Button
                  variant='secondary'
                  size='md'
                  icon='PlusIcon'
                  shape='square'
                  onClick={createNewBaseViewHandler}
                />
                <div className='flex items-center gap-2'>
                  <Input
                    placeholder={searchTerm || 'Search...'}
                    // Using placeholder for initial value for now, we might want to add an initialValue prop to the component
                    prefix='SearchOutlineIcon'
                    onChange={updateSearchTerm}
                  />
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <div>
                        <Button variant='subtle' size='md' icon='HamburgerIcon' shape='square' />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent alignment='end'>
                      {currentBaseViewConfig ? (
                        // a base view with bad config will not render these options
                        <>
                          <DropdownMenuItem
                            key='Edit Base View'
                            internalKey={'Edit Base View'}
                            onSelect={() => {
                              setBaseViewSettingsModalOpen(true)
                            }}>
                            <MenuItem
                              label='Edit Base View'
                              icon='EditOutlineIcon'
                              hoveredIcon='EditFilledIcon'
                            />
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            key='Duplicate Base View'
                            internalKey={'Duplicate Base View'}
                            onSelect={createBaseViewHandler}>
                            <MenuItem
                              label='Duplicate Base View'
                              icon='CopyOutlineIcon'
                              hoveredIcon='CopyFilledIcon'
                            />
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            key='Download Base View'
                            internalKey={'Download Base View'}
                            onSelect={() => {
                              setIsDownloadModalOpen(true)
                            }}>
                            <MenuItem
                              label='Download Base View'
                              icon='DownloadOutlineIcon'
                              hoveredIcon='DownloadFilledIcon'
                            />
                          </DropdownMenuItem>
                        </>
                      ) : null}
                      {showFineTuneDialog ? (
                        <DropdownMenuItem
                          key='Base Fine-Tuning'
                          internalKey={'Base Fine-Tuning'}
                          onSelect={() => setIsFineTuneLLMOpen(true)}>
                          <MenuItem
                            label='Fine-Tune'
                            icon='OrchestrationIcon'
                            hoveredIcon='OrchestrationFilledIcon'
                          />
                        </DropdownMenuItem>
                      ) : null}
                      <DropdownMenuItem
                        key='Copy Base View Link'
                        internalKey={'Copy Base View Link'}
                        onSelect={() => {
                          copyToClipboard()
                        }}>
                        <MenuItem
                          label='Copy Base View Link'
                          icon='LinkIcon'
                          hoveredIcon='LinkIcon'
                        />
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        key='Delete Base View'
                        internalKey={'Delete Base View'}
                        onSelect={() => {
                          setIsDeleteModalOpen(true)
                        }}>
                        <MenuItem
                          label='Delete Base View'
                          icon='TrashOutlineIcon'
                          hoveredIcon='TrashFilledIcon'
                          color='danger'
                        />
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {(ability?.can('view', 'BaseViewEditor') || isPrivate) && currentBaseViewConfig ? ( // For private baseView, operators have limited permissions in the baseview editor
                <BaseViewEditor
                  base={base}
                  baseViews={base.baseViews}
                  steps={base.steps}
                  stages={stages}
                />
              ) : null}
            </Table.Header>

            {visibleBaseViewsSorted.map((baseView) => (
              <Table.Container tab={baseView.id}>
                <BaseView
                  base={base}
                  baseView={baseView}
                  data={data ?? []}
                  itemCount={baseRunData?.itemCount ?? 0}
                  height={height}
                  baseDataLoading={isLoading}
                />
              </Table.Container>
            ))}
          </Table.TabProvider>
        </div>
      </div>

      <Modal
        icon='CircledExclamationIcon'
        title='Cannot Access Base View'
        visible={isBaseViewDeniedModalOpen}
        blurred
        onClose={() => setIsBaseViewDeniedModalOpen(false)}
        primaryButton={
          <Button variant='secondary' size='md' onClick={() => setIsBaseViewDeniedModalOpen(false)}>
            Close
          </Button>
        }>
        <div className='w-[400px] p-5'>
          {`You do not have permissions to access the Base View "${currentBaseView?.name}",
          please ask your team lead for access.`}
        </div>
      </Modal>

      <Modal
        icon='CircledExclamationIcon'
        title='Delete Base View'
        visible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        secondaryButton={
          <Button variant='secondary' onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>
        }
        primaryButton={
          <Button
            variant='danger'
            size='md'
            onClick={deleteBaseViewHandler}
            loading={isDeleteBaseViewLoading}>
            Delete
          </Button>
        }>
        <div className='w-[400px] p-5'>
          {`Are you sure you want to delete [${currentBaseView?.name}] Base View? This may impact other users.`}
        </div>
      </Modal>

      <Modal
        icon='DownloadOutlineIcon'
        title={isDownloadInitiated ? 'Downloading...' : 'Download Base View'}
        visible={isDownloadModalOpen}
        onClose={() => {
          handleDownloadModalClose()
        }}
        width='400px'
        minHeight='400px'
        secondaryButton={
          <Button
            variant='secondary'
            disabled={isCreatingDownloadRequest}
            onClick={handleDownloadModalButtonClick}>
            {computeDownloadModalButtonText()}
          </Button>
        }>
        {isDownloadInitiated ? (
          <div className='text-paragraphs p-2'>
            {`Your ${downloadFormat.toUpperCase()} file is being processed, and a download link will be sent to your email when it is ready. Thank you!`}
          </div>
        ) : (
          <>
            <Radio.Group
              name='base-view-downloads'
              onChange={(value) => {
                setDownloadFormat(value as TBaseViewDownloadFormat)
              }}
              orientation='vertical'
              selected={downloadFormat}>
              {DOWNLOAD_FORMATS.map((format) => (
                <Radio label={format.label} value={format.value} key={format.label} />
              ))}
            </Radio.Group>
            <ThemeProvider theme={theme}>
              <Stack marginTop={'16px'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useAssigneeIdsAtExport}
                      onChange={(event) => setUseAssigneeIdsAtExport(event.target.checked)}
                    />
                  }
                  label='Use assignee IDs instead of assignee emails'
                  labelPlacement='end'
                />
              </Stack>
            </ThemeProvider>
          </>
        )}
      </Modal>

      <FineTuneDialog
        open={isFineTuneLLMOpen}
        handleClose={() => setIsFineTuneLLMOpen(false)}
        baseId={base.id}
      />
    </>
  )
}
