import { z } from '@invisible/zod'

import { schema as intervalSchema } from './Interval'
const schema = z.union([
  z.string(),
  z.null(),
  z.number(),
  z.boolean(),
  z.array(z.string()),
  intervalSchema,
])

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
