import { classNames } from '@invisible/common/helpers'
import { FC } from 'react'
import { getBezierPath, Position } from 'reactflow'

interface IEdgeProps {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
}

// eslint-disable-next-line @typescript-eslint/ban-types
const InitialTriggerEdge: FC<IEdgeProps> = ({ id, sourceX, sourceY, targetX, targetY }) => {
  const [customEdgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  return (
    <path
        id={id}
        className={classNames('react-flow__edge-path !stroke-primary w-10 cursor-pointer')}
        d={customEdgePath}
        markerEnd='url(#inactive-marker)'
      />
  )
}

export { InitialTriggerEdge }
