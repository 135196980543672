import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import type {
  TChoiceFreeText,
  TQuestionAndAnswer,
  TQuestionAndAnswerTree,
  TQuestionnaireAnswerEntries,
} from '@invisible/common/types'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  DATA_PROTECTION_IMPACT_ASSESSMENT,
  DPIA_RUN_ID_BASE_VARIABLE,
  DPO_DPIA_AUDIT_PASS_BASE_VARIABLE,
  DPO_DPIA_AUDIT_STEP,
  DPO_PSQ_AUDIT_PASS_BASE_VARIABLE,
  DPO_PSQ_AUDIT_STEP,
  PO_COMPLETES_DPIA_STEP,
  PROCESS_SECURITY_MANAGEMENT,
  QUESTIONNAIRE_RUN_ID_BASE_VARIABLE,
} from '@invisible/ultron/shared'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isEmpty } from 'lodash/fp'
import Link from 'next/link'
import { FC, useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { NEXT_PUBLIC_MANTICORE_URL } from '../../../config/env'
import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import QuestionsQA from '../questionnaireComponents/QA/QuestionsQA'
import {
  allRequiredQuestionsAnswered,
  checkHasNoUnresolvedComments,
} from '../questionnaireComponents/utils'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRunQueryData['items'][number]['stepRuns'][number]
type IProps = WizardSchemas.WACConfig.TSchema & { stepRun: TStepRun } & { baseRun: TBaseRun }

// eslint-disable-next-line @typescript-eslint/ban-types
const ProcessSecurityQuestionnaireWAC: FC<IProps> = ({ stepRun, baseRun }) => {
  const reactQueryClient = useQueryClient()
  const user = stepRun.assignee
  const dpoStepIDs = [DPO_PSQ_AUDIT_STEP, DPO_DPIA_AUDIT_STEP]
  const dpiaStepIDs = [PO_COMPLETES_DPIA_STEP, DPO_DPIA_AUDIT_STEP]

  const isDPOView = dpoStepIDs.includes(stepRun.stepId)

  const isDPIAView = dpiaStepIDs.includes(stepRun.stepId)

  const { data: baseRunVariables } = useQuery([
    'baseRunVariable.findByBaseRunIdAndBaseVariableId',
    [
      {
        baseRunId: baseRun.id,
        baseVariableIds: [
          QUESTIONNAIRE_RUN_ID_BASE_VARIABLE,
          DPIA_RUN_ID_BASE_VARIABLE,
          DPO_PSQ_AUDIT_PASS_BASE_VARIABLE,
          DPO_DPIA_AUDIT_PASS_BASE_VARIABLE,
        ],
      },
    ],
  ])

  const { mutateAsync: updateQuestionnaireRun } = useMutation('questionnaireRun.update', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('questionnaireRun.findById')
    },
  })

  const questionnaireRunIdBRV = useMemo(
    () =>
      baseRunVariables?.find((brv) => brv.baseVariable.id === QUESTIONNAIRE_RUN_ID_BASE_VARIABLE),
    [baseRunVariables]
  )

  const dpiaRunIdBRV = useMemo(
    () => baseRunVariables?.find((brv) => brv.baseVariable.id === DPIA_RUN_ID_BASE_VARIABLE),
    [baseRunVariables]
  )

  const psqPassDataProtectionOfficerBRV = useMemo(
    () => baseRunVariables?.find((brv) => brv.baseVariable.id === DPO_PSQ_AUDIT_PASS_BASE_VARIABLE),
    [baseRunVariables]
  )

  const dpiaPassDataProtectionOfficerBRV = useMemo(
    () =>
      baseRunVariables?.find((brv) => brv.baseVariable.id === DPO_DPIA_AUDIT_PASS_BASE_VARIABLE),
    [baseRunVariables]
  )

  const questionnaireRunId = questionnaireRunIdBRV?.value as string
  const dpiaRunId = dpiaRunIdBRV?.value as string

  const { data: questions } = useQuery([
    'questionnaireQuestion.findQuestionsAndAnswers',
    {
      questionnaireRunId: isDPIAView ? dpiaRunId : questionnaireRunId,
      questionnaireId: isDPIAView ? DATA_PROTECTION_IMPACT_ASSESSMENT : PROCESS_SECURITY_MANAGEMENT,
    },
  ])

  const { data: questionnaireRun } = useQuery([
    'questionnaireRun.findById',
    { id: questionnaireRunId },
  ])

  const reactQueryContext = useContext()
  const { dispatch } = useWizardState()

  const { mutateAsync: markStepRunDone } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
  })

  const { mutateAsync: updateBaseRunVariableMutation } = useMutation('baseRunVariable.update', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const updateBaseRunVariable = useCallback(
    async ({ baseRunVariableId, value }: { baseRunVariableId: string; value: any }) => {
      await updateBaseRunVariableMutation({ id: baseRunVariableId, value })
    },
    [updateBaseRunVariableMutation]
  )

  const { mutateAsync: createQuestionnaireRun } = useMutation(
    'questionnaireRun.createQuestionnaireRun',
    {
      onSettled: () => {
        reactQueryContext.invalidateQueries('questionnaireRun.findById')
      },
    }
  )

  const { data: dpiaRunVariable } = useQuery([
    'baseRunVariable.findOneByBaseVariableIdAndBaseRunId',
    {
      baseRunId: baseRun.id,
      baseVariableId: DPIA_RUN_ID_BASE_VARIABLE,
    },
  ])

  const sendPsqToPO = useCallback(
    async (baseRunVariableId: string) => {
      await updateBaseRunVariable({
        baseRunVariableId,
        value: 'PO_Edits_Needed',
      })

      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    },
    [dispatch, markStepRunDone, stepRun.id, updateBaseRunVariable]
  )

  const generateDPIA = useCallback(
    async (baseRunVariableId: string) => {
      if (!questionnaireRun || !dpiaRunVariable) return
      await updateBaseRunVariable({
        baseRunVariableId,
        value: 'DPIA Needed',
      })
      const dpiaRun = await createQuestionnaireRun({
        questionnaireId: DATA_PROTECTION_IMPACT_ASSESSMENT,
        processId: questionnaireRun.processId,
        issuerId: user?.id as string,
      })

      if (!dpiaRun) return
      await updateBaseRunVariable({
        baseRunVariableId: dpiaRunVariable.id,
        value: dpiaRun.id as string,
      })
      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    },
    [
      createQuestionnaireRun,
      dispatch,
      dpiaRunVariable,
      markStepRunDone,
      questionnaireRun,
      stepRun,
      updateBaseRunVariable,
      user,
    ]
  )

  const passQaDPO = useCallback(
    async (baseRunVariableId: string) => {
      await updateBaseRunVariable({
        baseRunVariableId,
        value: 'DPIA Not Needed',
      })
      await updateQuestionnaireRun({ id: questionnaireRunId, status: 'completed' })
      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    },
    [
      dispatch,
      markStepRunDone,
      questionnaireRunId,
      stepRun.id,
      updateBaseRunVariable,
      updateQuestionnaireRun,
    ]
  )

  const passDpiaDPO = useCallback(
    async (baseRunVariableId: string) => {
      await updateBaseRunVariable({
        baseRunVariableId,
        value: 'DPIA Complete',
      })
      await updateQuestionnaireRun({ id: dpiaRunId, status: 'completed' })
      await updateQuestionnaireRun({ id: questionnaireRunId, status: 'completed' })
      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    },
    [
      dispatch,
      dpiaRunId,
      markStepRunDone,
      questionnaireRunId,
      stepRun.id,
      updateBaseRunVariable,
      updateQuestionnaireRun,
    ]
  )

  const sendDpiaToPO = useCallback(
    async (baseRunVariableId: string) => {
      await updateBaseRunVariable({
        baseRunVariableId,
        value: 'DPIA Needs Edits',
      })
      await markStepRunDone({ id: stepRun.id })
      dispatch({ type: 'closeWizard' })
    },
    [dispatch, markStepRunDone, stepRun.id, updateBaseRunVariable]
  )

  const sendPSQToDPO = useCallback(async () => {
    await markStepRunDone({ id: stepRun.id })
    dispatch({ type: 'closeWizard' })
  }, [dispatch, markStepRunDone, stepRun])

  const sendDPIAToPO = useCallback(async () => {
    await markStepRunDone({ id: stepRun.id })
    dispatch({ type: 'closeWizard' })
  }, [dispatch, markStepRunDone, stepRun.id])

  const questionHierarchy = useMemo(() => {
    const createQuestionHierarchy = (
      questions: TQuestionAndAnswer[],
      parentId: string | null = null,
      level = 0
    ): TQuestionAndAnswerTree[] => {
      const currentLevel = level + 1
      return questions
        ?.filter((question) => question.parentId === parentId)
        .map((child) => ({
          ...child,
          level: currentLevel,
          children: createQuestionHierarchy(questions, child.id, currentLevel),
        }))
    }

    return createQuestionHierarchy(questions as TQuestionAndAnswer[])
  }, [questions])

  const entries = useMemo(() => {
    const computeEntries = (questions: TQuestionAndAnswer[]): TQuestionnaireAnswerEntries =>
      questions?.reduce((acc, curr) => {
        if (!curr.answer) return acc

        if (curr.type === 'multi_choice') {
          const options = curr.answer.answerToOptions
          const optionsFreeText = options.reduce((acc, { optionId, freeText }) => {
            if (freeText) return { ...acc, [optionId]: freeText }
            return acc
          }, {} as TChoiceFreeText)

          return {
            ...acc,
            [curr.id]: {
              choices: curr.answer.answerToOptions.map((a) => a.optionId),
              ...(!isEmpty(optionsFreeText) ? { freeText: optionsFreeText } : {}),
            },
          }
        }

        if (curr.type === 'single_choice') {
          const option = curr.answer.answerToOptions[0]
          return {
            ...acc,
            [curr.id]: {
              choice: option.optionId as string,
              ...(option.freeText ? { freeText: option.freeText } : {}),
            },
          }
        }

        return { ...acc, [curr.id]: { text: curr.answer.text ?? '' } }
      }, {}) as TQuestionnaireAnswerEntries
    return computeEntries(questions as TQuestionAndAnswer[])
  }, [questions])

  const resolutions = useMemo(() => {
    if (!questions) return {}
    const store = questions.reduce((acc, curr) => {
      if (!curr.comments.length) return { ...acc, [curr.id]: true }
      return { ...acc, [curr.id]: checkHasNoUnresolvedComments(curr.comments) }
    }, {})

    return store
  }, [questions])

  const computeButtons = () => {
    if (questions) {
      const allRequiredAnswered = allRequiredQuestionsAnswered(questionHierarchy, entries)
      const hasUnresolvedComments = Object.values(resolutions).some((r) => !r)
      const isPassDisabled = !allRequiredAnswered || hasUnresolvedComments

      if (isDPOView) {
        return (
          <>
            <div className='mr-2 py-4'>
              <Button
                size='md'
                variant='primary'
                disabled={isPassDisabled}
                onClick={() =>
                  isDPIAView
                    ? passDpiaDPO(dpiaPassDataProtectionOfficerBRV?.id as string)
                    : passQaDPO(psqPassDataProtectionOfficerBRV?.id as string)
                }>
                {isDPIAView ? 'Pass DPIA' : 'Pass PSQ'}
              </Button>
            </div>
            <div className='mr-2 py-4'>
              <Button
                size='md'
                disabled={!isPassDisabled}
                variant='secondary'
                onClick={() =>
                  isDPIAView
                    ? sendDpiaToPO(dpiaPassDataProtectionOfficerBRV?.id as string)
                    : sendPsqToPO(psqPassDataProtectionOfficerBRV?.id as string)
                }>
                Send to Process Owner to Redo
              </Button>
            </div>
            {!isDPIAView ? (
              <div className='mr-2 py-4'>
                <Button
                  size='md'
                  variant='secondary'
                  disabled={isPassDisabled}
                  onClick={() => generateDPIA(psqPassDataProtectionOfficerBRV?.id as string)}>
                  Generate DPIA for Process
                </Button>
              </div>
            ) : null}
          </>
        )
      }

      return (
        <div className='ml-2 mt-4'>
          <Button
            size='md'
            disabled={!allRequiredAnswered}
            variant='secondary'
            onClick={isDPIAView ? sendDPIAToPO : sendPSQToDPO}>
            Submit to Data Protection Officer
          </Button>
        </div>
      )
    }
  }

  const computeTitle = () => {
    if (isDPIAView) return `Data Protection Impact Assessment ${isDPOView ? 'Audit' : 'QA'}`
    return `Process Security Questionnaire ${isDPOView ? 'Audit' : 'QA'}`
  }

  if (!user || !baseRunVariables || !questions?.length) return null

  return (
    <div className='w-full'>
      <div className='mx-auto flex w-6/12 flex-col'>
        <h2 className='flex justify-center'>
          {computeTitle()} -&nbsp;
          <span>
            <Link
              href={`${NEXT_PUBLIC_MANTICORE_URL}/build/canvas/process/${
                questionnaireRun?.processId ?? ''
              }`}
              passHref>
              <a
                className='text-theme-main no-underline hover:text-purple-500'
                target='_blank'
                rel='noopener noreferrer'>
                {questionnaireRun?.process.name}
              </a>
            </Link>
          </span>
        </h2>

        <div className='flex flex-col'>
          <QuestionsQA
            questions={questionHierarchy}
            questionnaireRunId={isDPIAView ? dpiaRunId : questionnaireRunId}
            isDPOView={isDPOView}
            entries={entries}
            user={user}
            resolutions={resolutions}
            isDPIAView={isDPIAView}
          />
        </div>
        <div className='flex justify-center p-3'>{computeButtons()}</div>
      </div>
    </div>
  )
}
export { ProcessSecurityQuestionnaireWAC }
