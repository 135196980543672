import { DeviationDirection, TDeviationDirection } from '@invisible/common/types'
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from '@invisible/ui/icons'
import { Skeleton } from '@invisible/ui/skeleton'

export type TDeviationInfo = {
  direction?: TDeviationDirection
  color?: string
  percentage?: string
  label: string
}

export interface ITile {
  value: string
  label: string
  order: number
  deviationInfo: TDeviationInfo
  isLoading?: boolean
}

const containerStyles = 'flex flex-col gap-10 p-5 items-center justify-center w-full'
const contentWrapperStyles = 'flex flex-col items-center justify-center gap-2'
const valueStyles = 'm-0 text-4xl font-bold text-black'
const deviationContainerStyles = 'whitespace-nowrap text-sm font-medium text-black'
const labelStyles =
  'h-6 flex items-center justify-center gap-1 text-sm font-medium leading-none whitespace-nowrap'
const midDotStyles = 'text-xl pt-0.5 leading-none text-gray-400'

const renderDeviationInfo = (deviationInfo: TDeviationInfo) => {
  const { color, percentage, direction } = deviationInfo
  const commonStyles = { color }

  if (percentage === null || typeof percentage === 'undefined') return null

  return (
    <div className='flex items-center justify-center'>
      {direction && (
        <i>
          {direction.toLowerCase() === DeviationDirection.Up ? (
            <ArrowUpIcon className='h-4 w-4' style={commonStyles} />
          ) : direction.toLowerCase() === DeviationDirection.Down ? (
            <ArrowDownIcon className='h-4 w-4' style={commonStyles} />
          ) : (
            <MinusIcon className='mr-1 h-4 w-3' style={commonStyles} />
          )}
        </i>
      )}
      <span style={commonStyles}>{percentage}%</span>
      <span className={midDotStyles}>&nbsp;&middot;</span>
    </div>
  )
}

export const TileSkeleton = () => (
  <div className={containerStyles}>
    <div className={contentWrapperStyles}>
      <Skeleton.Rectangle width={115} height={35} className='mb-1' />
      <div className='mb-2 flex items-center justify-center '>
        <Skeleton.Rectangle width={245} height={15} />
      </div>
      <Skeleton.Rectangle width={145} height={15} />
    </div>
  </div>
)

export const Tile = (props: ITile) => {
  const { value, label, deviationInfo, isLoading } = props
  if (isLoading) return <TileSkeleton />
  return (
    <div className={containerStyles}>
      <div className={contentWrapperStyles}>
        <h1 className={valueStyles}>{value}</h1>
        <div className={labelStyles}>
          {renderDeviationInfo(deviationInfo)}
          <span className='text-gray-400'>{label}</span>
        </div>
        <div>
          <span className={deviationContainerStyles}>{deviationInfo.label}</span>
        </div>
      </div>
    </div>
  )
}
