import ContentLoader from 'react-content-loader'

interface IRectProps {
  width: number | string
  height: number | string
  rx?: number
  ry?: number
  className?: string
}

export const RectSkeleton = ({ width, height, rx, ry }: IRectProps) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'>
    <rect x='8' y='8' rx={rx} ry={ry} width={width} height={height} />
  </ContentLoader>
)
