import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { PlusIcon, TrashOutlineIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { Switch } from '@invisible/ui/switch'
import { TagInput } from '@invisible/ui/tag-input'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { debounce } from 'lodash/fp'
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import sanitize from 'sanitize-html'

import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps {
  text: string
  response: string
  promptId: string
  index: number
  responseId: string
  baseRunVariables: TBaseRunVariables
  config: WizardSchemas.RLHFSingleUser.TSchema
  conversationId: string
  stepRunId: string
  wizardIsReadOnly: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleTurn: FC<IProps> = ({
  text,
  response,
  promptId,
  index,
  responseId,
  baseRunVariables,
  config,
  conversationId,
  stepRunId,
  wizardIsReadOnly,
}) => {
  const [isEditing, setIsEditing] = useState<false | 'prompt' | 'response'>(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [promptText, setPromptText] = useState(text)
  const [responseText, setResponseText] = useState(response)

  const promptRef = useRef<HTMLElement | null>(null)
  const responseRef = useRef<HTMLElement | null>(null)
  const reactQueryContext = useContext()

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
  })

  const { mutateAsync: deleteBaseRuns, isLoading } = useBaseRunDeleteWithStepRunReference({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
    onSuccess: () => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.promptsBaseId,
            parentBaseRunId: conversationId,
          },
        ],
        (prevData) => {
          if (!prevData) return
          return prevData.filter((baseRun) => baseRun.id !== promptId)
        }
      )
    },
  })

  useEffect(() => {
    if (isEditing === 'prompt') promptRef?.current?.focus()
    if (isEditing === 'response') responseRef?.current?.focus?.()
  }, [promptRef, isEditing, responseRef])

  useEffect(() => {
    setResponseText(response)
  }, [response])

  const handlePromptBlur = useCallback(
    async (value: string) => {
      setIsEditing(false)
      updateVariable({
        stepRunId,
        data: [
          {
            baseRunId: promptId,
            baseVariableId: config.promptTextBaseVariableId as string,
            value,
          },
        ],
      })
    },
    [config.promptTextBaseVariableId, promptId, updateVariable]
  )

  const handleResponseBlur = useCallback(
    async (value: string) => {
      setIsEditing(false)
      await updateVariable({
        stepRunId,
        data: [
          {
            baseRunId: promptId,
            baseVariableId: config.promptResponseBaseVariableId as string,
            value,
          },
          {
            baseRunId: responseId,
            baseVariableId: config.responseTextBaseVariableId as string,
            value,
          },
        ],
      })
    },
    [
      config.promptResponseBaseVariableId,
      config.responseTextBaseVariableId,
      promptId,
      responseId,
      updateVariable,
    ]
  )

  return (
    <div className='mb-3'>
      <div className='group flex items-center gap-1'>
        <div className='max-w-1/2 relative  w-fit max-w-[50%] rounded-xl bg-indigo-100  shadow'>
          <ContentEditable
            className='whitespace-pre-line rounded-xl py-2 px-4 text-sm'
            html={sanitize(promptText ?? '', { disallowedTagsMode: 'escape' })}
            innerRef={promptRef}
            disabled={isEditing !== 'prompt' || wizardIsReadOnly}
            onChange={(e) => setPromptText(e.target.value)}
            onDoubleClick={() => setIsEditing('prompt')}
            onBlur={(e) => handlePromptBlur(e.target.innerText)}
          />
          {isEditing !== 'prompt' ? (
            <div className='absolute top-0 right-1 select-none p-1 text-[8px]'>{index}</div>
          ) : null}
        </div>
        {!wizardIsReadOnly ? (
          <TrashOutlineIcon
            className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
            onClick={() => setIsDeleteModalOpen(true)}
          />
        ) : null}
      </div>

      <div className='group flex shrink items-center gap-1'>
        <div className='max-w-1/2 relative ml-auto mt-2 w-fit max-w-[50%] rounded-xl bg-pink-100  shadow '>
          <ContentEditable
            className='whitespace-pre-line rounded-xl py-2 px-4 text-sm'
            html={sanitize(responseText ?? '', { disallowedTagsMode: 'escape' })}
            innerRef={responseRef}
            disabled={isEditing !== 'response' || wizardIsReadOnly}
            onChange={(e) => setResponseText(e.target.value)}
            onDoubleClick={() => {
              if (response) setIsEditing('response')
            }}
            // collapse new lines
            onBlur={(e) => handleResponseBlur(e.target.innerText.replace(/\n\n+/g, '\n\n'))}
          />
          {isEditing !== 'response' ? (
            <div className='absolute top-0 right-1 select-none p-1 text-[8px]'>{index}</div>
          ) : null}
          {!response ? (
            <div className='flex gap-2 py-1.5 px-3 pb-2'>
              <div className='h-2 w-2 animate-bounce rounded-full bg-pink-300 [animation-delay:0.1s]'></div>
              <div className='h-2 w-2 animate-bounce rounded-full bg-pink-300 [animation-delay:0.3s]'></div>
              <div className='h-2 w-2 animate-bounce rounded-full bg-pink-300 [animation-delay:0.5s]'></div>
            </div>
          ) : null}
        </div>
        {response ? (
          <PlusIcon
            className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
            onClick={() => setIsDetailsOpen(true)}
          />
        ) : null}
      </div>

      <Modal visible={isDetailsOpen} title='Add Metadata' onClose={() => setIsDetailsOpen(false)}>
        <div className='flex w-96 flex-col gap-4'>
          {(config.metadata ?? []).map((item) => (
            <div>
              <div className='mb-1'>{item.name}</div>
              {item.type === 'a_string' ? (
                <TagInput
                  tags={
                    (baseRunVariables.find((v) => v.baseVariableId === item.baseVariableId)
                      ?.value ?? []) as string[]
                  }
                  disabled={wizardIsReadOnly}
                  onChange={(value) =>
                    updateVariable({
                      stepRunId,
                      data: [
                        {
                          baseRunId: responseId,
                          baseVariableId: item.baseVariableId,
                          value,
                        },
                      ],
                    })
                  }
                />
              ) : item.type === 'boolean' ? (
                <Switch
                  size='medium'
                  disabled={wizardIsReadOnly}
                  isOn={
                    (baseRunVariables.find((v) => v.baseVariableId === item.baseVariableId)
                      ?.value ?? false) as boolean
                  }
                  onToggle={(value) =>
                    updateVariable({
                      stepRunId,
                      data: [
                        {
                          baseRunId: responseId,
                          baseVariableId: item.baseVariableId,
                          value,
                        },
                      ],
                    })
                  }
                />
              ) : (
                <Input
                  value={
                    (baseRunVariables.find((v) => v.baseVariableId === item.baseVariableId)
                      ?.value ?? '') as string
                  }
                  disabled={wizardIsReadOnly}
                  onChange={debounce(2000, (e: ChangeEvent<HTMLInputElement>) =>
                    updateVariable({
                      stepRunId,
                      data: [
                        {
                          baseRunId: responseId,
                          baseVariableId: item.baseVariableId,
                          value: e.target.value,
                        },
                      ],
                    })
                  )}
                />
              )}
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        visible={isDeleteModalOpen}
        title='Delete Prompt'
        onClose={() => setIsDeleteModalOpen(false)}
        primaryButton={
          <Button
            variant='danger'
            loading={isLoading}
            disabled={wizardIsReadOnly}
            onClick={() => deleteBaseRuns({ baseRunIds: [promptId], stepRunId: stepRunId })}>
            Yes
          </Button>
        }
        secondaryButton={
          <Button variant='secondary' onClick={() => setIsDeleteModalOpen(false)}>
            No
          </Button>
        }>
        Are you sure you want to delete this prompt and response?
      </Modal>
    </div>
  )
}

export { SingleTurn }
