import { SVGProps } from 'react'

function SaveAs(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M27.0087 23.6642C26.73 24.5506 26.191 25.3118 25.4365 25.8673C24.6994 26.4046 23.7831 26.709 22.8668 26.709H22.8576C21.8607 26.709 20.8903 26.3598 20.0996 25.7148C19.902 25.5539 19.7132 25.3748 19.5518 25.1775C18.8867 24.3984 18.5274 23.4134 18.5186 22.3926C18.5186 21.3715 18.878 20.3864 19.5335 19.6074C19.9378 19.124 20.4502 18.721 21.0251 18.461C21.5999 18.1924 22.2288 18.0493 22.8668 18.0493C23.7474 18.0493 24.6009 18.318 25.3283 18.8105C26.092 19.3387 26.6581 20.0821 26.9638 20.9598C27.2691 21.8462 27.2869 22.7778 27.0087 23.6642ZM16.9551 20.7446C16.6407 21.8822 16.6586 23.0821 17.0092 24.2015H10.2889V17.9328H18.6534C17.8448 18.7032 17.2609 19.6613 16.9551 20.7446ZM8.4921 17.9328V24.2015H5.79684V6.29104H18.9046L23.7652 11.136V16.33C22.6871 16.1687 21.5911 16.2939 20.5758 16.7061C20.4232 16.5448 20.2344 16.4104 20.0368 16.3118C19.7943 16.2044 19.5427 16.1418 19.2731 16.1418H10.2889C9.8127 16.1418 9.36349 16.33 9.02202 16.67C8.68056 17.0104 8.4921 17.4581 8.4921 17.9328ZM28.8683 21.0493C28.6616 20.1447 28.2482 19.2853 27.6642 18.5506C27.0986 17.8524 26.3707 17.279 25.5621 16.8852V11.1447C25.5621 10.903 25.5172 10.6791 25.4273 10.4552C25.3375 10.2405 25.2119 10.0522 25.0409 9.88227L20.1624 5.01949C19.8301 4.68788 19.3809 4.50913 18.9046 4.5H5.79684C5.32061 4.5 4.8714 4.68788 4.52993 5.02818C4.18881 5.36857 4 5.81633 4 6.29104V24.2015C4 24.6762 4.19758 25.1327 4.52993 25.4731C4.8714 25.8043 5.32061 25.9925 5.79684 25.9925H17.9255C18.4554 26.7268 19.1654 27.3358 19.9648 27.7657C20.7826 28.2044 21.708 28.4643 22.6422 28.5H22.8668C23.7295 28.5 24.5917 28.3209 25.3732 27.9718C26.2267 27.5954 26.9904 27.0133 27.5927 26.2969C28.1854 25.5896 28.6259 24.7297 28.8413 23.8254C29.0567 22.9208 29.0659 21.9627 28.8683 21.0493Z'
        fill='currentColor'
      />
      <path
        d='M18.4104 8.97765C18.4104 9.21935 18.3119 9.44323 18.1409 9.61329C17.9796 9.78362 17.755 9.87317 17.512 9.87317H11.2231C10.9806 9.87317 10.756 9.78362 10.5855 9.61329C10.4145 9.44323 10.3247 9.21935 10.3247 8.97765C10.3247 8.74463 10.4145 8.52075 10.5855 8.35078C10.756 8.18081 10.9898 8.08212 11.2231 8.08212H17.512C17.7458 8.08212 17.9796 8.18081 18.1409 8.35078C18.3119 8.52075 18.4104 8.74463 18.4104 8.97765Z'
        fill='currentColor'
      />
      <path
        d='M25.2657 22.3793C25.2661 22.4881 25.2369 22.5955 25.1812 22.6893C25.1257 22.7834 25.0457 22.8607 24.9499 22.9135C24.8579 22.9639 24.7551 22.9901 24.6506 22.9898H23.4794V24.1585C23.4794 24.3201 23.4149 24.4754 23.3 24.5902C23.1853 24.7046 23.0295 24.7689 22.867 24.7689C22.7045 24.7689 22.5487 24.7046 22.434 24.5902C22.3192 24.4754 22.2546 24.3201 22.2546 24.1585V22.9898H21.0835C21.0007 22.9936 20.9179 22.981 20.8403 22.9523C20.7624 22.9233 20.6911 22.8792 20.6311 22.8222C20.5711 22.7652 20.523 22.6966 20.4904 22.6204C20.4578 22.5445 20.4409 22.4626 20.4409 22.3801C20.4409 22.2971 20.4578 22.2153 20.4904 22.1394C20.523 22.0635 20.5711 21.9949 20.6311 21.9379C20.6911 21.8809 20.7624 21.8364 20.8403 21.8078C20.9179 21.7787 21.0007 21.7661 21.0835 21.7703H22.2546V20.6016C22.2546 20.4396 22.3192 20.2843 22.434 20.1699C22.5487 20.0555 22.7045 19.9912 22.867 19.9912C23.0295 19.9912 23.1853 20.0555 23.3 20.1699C23.4149 20.2843 23.4794 20.4396 23.4794 20.6016V21.7703H24.6506C24.7309 21.7696 24.8106 21.785 24.8853 21.8155C24.9597 21.8459 25.0274 21.8907 25.0843 21.9473C25.1412 22.004 25.1864 22.0712 25.2169 22.1453C25.2475 22.2195 25.2633 22.2989 25.2629 22.3793H25.2657Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SaveAs
