import { User } from '@datadog/browser-core/cjs/domain/user'
import { datadogRum } from '@datadog/browser-rum'
import { compact } from 'lodash'

import {
  ENV,
  GIT_REF,
  GIT_SHA,
  NEXT_PUBLIC_DATADOG_APP_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
  NEXT_PUBLIC_DATADOG_SERVICE_NAME,
} from '../config/env'

if (typeof window !== 'undefined' && NEXT_PUBLIC_DATADOG_APP_ID && NEXT_PUBLIC_DATADOG_CLIENT_TOKEN)
  datadogRum.init({
    applicationId: NEXT_PUBLIC_DATADOG_APP_ID,
    clientToken: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: NEXT_PUBLIC_DATADOG_SERVICE_NAME,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: compact([GIT_REF, GIT_SHA]).join('@'),
    env: ENV,
    sampleRate: NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
    defaultPrivacyLevel: 'mask-user-input',
    trackFrustrations: true,
    trackInteractions: true,
    trackUserInteractions: true,
    trackViewsManually: true,
  })

export const setUpRumUser = (user: User) => {
  datadogRum.setUser(user)
}

datadogRum.startSessionReplayRecording()
