import { SVGProps } from 'react'
function DateSeparator(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={11}
      height={4}
      viewBox='0 0 11 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.01 3.151L7.77.307a.437.437 0 00-.343-.166H6.54a.11.11 0 00-.087.177L8.426 2.82H.152a.11.11 0 00-.11.11v.82c0 .06.05.11.11.11h9.514c.367 0 .57-.422.345-.709z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DateSeparator
