import 'reactflow/dist/style.css'

import { useProcessById } from '@invisible/common/components/process-base'
import { EDGE_TYPES, NODE_TYPES, useStore } from '@invisible/common/stores/process-store'
import { useMutation } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { ManualStepMeta } from '@invisible/ultron/zod'
import AddIcon from '@mui/icons-material/Add'
import FitScreenOutlinedIcon from '@mui/icons-material/FitScreenOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { useRouter } from 'next/router'
import { isEmpty } from 'radash'
import { MouseEventHandler, ReactNode, useCallback, useEffect, useRef } from 'react'
import ReactFlow, { Background, BackgroundVariant, Controls, useReactFlow } from 'reactflow'
import shallow from 'zustand/shallow'

import { DefaultEdge } from './edges/DefaultEdge'
import { InitialTriggerEdge } from './edges/InitialTriggerEdge'
import { MarkerDefinition } from './markers/DefaultMarker'
import { AttendedMapStep } from './nodes/AttendedMapStep'
import { BlankCanvasTrigger } from './nodes/BlankCanvasTrigger'
import { DefaultStep } from './nodes/DefaultStep'
import { EndStep } from './nodes/EndStep'
import { Stage } from './nodes/Stage'
import { TriggerStep } from './nodes/TriggerStep'
import { getStepsBefore } from './nodes/utils'

const nodeTypes = {
  [NODE_TYPES.TRIGGER_PLACEHOLDER]: BlankCanvasTrigger,
  [NODE_TYPES.REGULAR]: DefaultStep,
  [NODE_TYPES.END]: EndStep,
  [NODE_TYPES.TRIGGER]: TriggerStep,
  [NODE_TYPES.STAGE]: Stage,
  [NODE_TYPES.ATTENDED_MAP]: AttendedMapStep,
  [NODE_TYPES.IGNORE]: () => null,
}

const edgeTypes = {
  [EDGE_TYPES.DEFAULT]: (props: any) => (
    <DefaultEdge
      {...props}
      // We disable creating steps on edges for published processes and if the goFromStep of the edge is a branch step
      withPlusButton={
        !props.data?.isNonEditableProcess &&
        props.data?.stepGoTo?.goToStep?.stepTemplate?.subtype !== 'audit' &&
        props.data?.stepGoTo?.goFromStep?.stepTemplate?.subtype !== 'audit' &&
        props.data?.stepGoTo?.goFromStep?.stepTemplate?.subtype !== 'branch'
      }
      dashed={props.data?.stepGoTo?.goToStep?.stepTemplate?.subtype === 'audit'}
    />
  ),
  [EDGE_TYPES.INITIAL_TRIGGER]: InitialTriggerEdge,
}

const grid = [20, 20] as [number, number]

interface IProps {
  rootBaseId: string
}

interface CustomControlsButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  title: string
  children: ReactNode
}

const Canvas = () => {
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null)
  const {
    applyEdgeChanges,
    applyNodeChanges,
    disableAutoLayout,
    addStepPosition,
    nodes,
    edges,
    currentLayoutId,
  } = useStore(
    useCallback(
      (state) => ({
        applyEdgeChanges: state.onEdgeChanges,
        applyNodeChanges: state.onNodeChanges,
        disableAutoLayout: state.disableAutoLayout,
        addStepPosition: state.addStepPosition,
        autoLayout: state.autoLayout,
        nodes: state.positionedNodes,
        edges: state.positionedEdges,
        graphHorizontalWidth: state.graphHorizontalWidth,
        setGraphHorizontalWidth: state.setGraphHorizontalWidth,
        currentLayoutId: state.currentLayoutId,
      }),
      []
    ),
    shallow
  )

  const { query } = useRouter()

  const { data: process } = useProcessById({ id: query.id as string })

  const { mutateAsync: updateStep } = useMutation('step.update')

  /**
   * React to any changes in the process and update the QA step config accordingly.
   * A qa step will qa all the steps before it that have the same baseId.
   */
  useEffect(() => {
    const qaSteps = process?.steps?.filter((step) => step.stepTemplate?.subtype === 'qa')
    if (!isEmpty(qaSteps) && process) {
      for (const qaStep of qaSteps ?? []) {
        const stepsBeingQaEd = getStepsBefore({
          stepGoTos: process.stepGoTos,
          targetStepId: qaStep.id,
        })
          .map((stepId) => process?.steps.find((step) => step.id === stepId))
          .filter((s) => s?.baseId === qaStep?.baseId && s?.stepTemplate?.type === 'manual')
          .map((step) => step?.id)
          .filter((id) => id) as string[]

        if (!isEmpty(stepsBeingQaEd)) {
          updateStep({
            stepId: qaStep?.id ?? '',
            meta: {
              ...((qaStep?.meta as ManualStepMeta.TSchema) ?? {}),
              config: { stepsToQa: stepsBeingQaEd },
            },
          })
        }
      }
    }
  }, [process?.stepGoTos])

  if (!process) return null

  const CustomControlsButton = ({ onClick, title, children }: CustomControlsButtonProps) => (
    <Tooltip title={title} placement='top'>
      <IconButton
        onClick={onClick}
        sx={{
          width: 30,
          height: 30,
          borderRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          background: '#fff',
        }}>
        {children}
      </IconButton>
    </Tooltip>
  )

  const CustomControls = () => {
    const { zoomIn, zoomOut, fitView } = useReactFlow()
    const buttons = [
      { title: 'Zoom In', onClick: () => zoomIn(), icon: <AddIcon fontSize='small' /> },
      { title: 'Zoom Out', onClick: () => zoomOut(), icon: <RemoveIcon fontSize='small' /> },
      {
        title: 'Fit to Screen',
        onClick: () => fitView(),
        icon: <FitScreenOutlinedIcon fontSize='small' />,
      },
    ]
    return (
      <Controls
        showZoom={false}
        showFitView={false}
        showInteractive={false}
        position='bottom-right'
        style={{ boxShadow: 'none' }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            {buttons.map((button, index) => (
              <CustomControlsButton key={index} title={button.title} onClick={button.onClick}>
                {button.icon}
              </CustomControlsButton>
            ))}
          </Box>
        </ThemeProvider>
      </Controls>
    )
  }

  return (
    <ReactFlow
      minZoom={0.1}
      fitView
      snapToGrid={true}
      panOnScroll={true}
      nodesConnectable={true}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      snapGrid={grid}>
      <MarkerDefinition id='active-marker' color='black' />
      <MarkerDefinition id='inactive-marker' color='#604CA5' />

      <Background
        variant={BackgroundVariant.Dots}
        gap={22}
        size={0.5}
        style={{ color: '#D1D6EF', backgroundColor: '#F1F2F7' }}
      />
      <CustomControls />
    </ReactFlow>
  )
}
export { Canvas }
