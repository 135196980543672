import { SVGProps } from 'react'

function ControlOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='25'
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9993 8.65698C13.5516 8.65698 13.9993 9.1047 13.9993 9.65698V23.157C13.9993 23.7093 13.5516 24.157 12.9993 24.157C12.447 24.157 11.9993 23.7093 11.9993 23.157V9.65698C11.9993 9.1047 12.447 8.65698 12.9993 8.65698Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9993 0.156982C13.5516 0.156982 13.9993 0.604698 13.9993 1.15698V4.65698C13.9993 5.20927 13.5516 5.65698 12.9993 5.65698C12.447 5.65698 11.9993 5.20927 11.9993 4.65698V1.15698C11.9993 0.604698 12.447 0.156982 12.9993 0.156982Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9993 5.65698C12.1708 5.65698 11.4993 6.32856 11.4993 7.15698C11.4993 7.98541 12.1708 8.65698 12.9993 8.65698C13.8277 8.65698 14.4993 7.98541 14.4993 7.15698C14.4993 6.32856 13.8277 5.65698 12.9993 5.65698ZM9.49927 7.15698C9.49927 5.22399 11.0663 3.65698 12.9993 3.65698C14.9323 3.65698 16.4993 5.22399 16.4993 7.15698C16.4993 9.08998 14.9323 10.657 12.9993 10.657C11.0663 10.657 9.49927 9.08998 9.49927 7.15698Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9993 18.657C22.5516 18.657 22.9993 19.1047 22.9993 19.657V23.157C22.9993 23.7093 22.5516 24.157 21.9993 24.157C21.447 24.157 20.9993 23.7093 20.9993 23.157V19.657C20.9993 19.1047 21.447 18.657 21.9993 18.657Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9993 0.156982C22.5516 0.156982 22.9993 0.604698 22.9993 1.15698V14.657C22.9993 15.2093 22.5516 15.657 21.9993 15.657C21.447 15.657 20.9993 15.2093 20.9993 14.657V1.15698C20.9993 0.604698 21.447 0.156982 21.9993 0.156982Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9993 15.657C21.1708 15.657 20.4993 16.3286 20.4993 17.157C20.4993 17.9854 21.1708 18.657 21.9993 18.657C22.8277 18.657 23.4993 17.9854 23.4993 17.157C23.4993 16.3286 22.8277 15.657 21.9993 15.657ZM18.4993 17.157C18.4993 15.224 20.0663 13.657 21.9993 13.657C23.9323 13.657 25.4993 15.224 25.4993 17.157C25.4993 19.09 23.9323 20.657 21.9993 20.657C20.0663 20.657 18.4993 19.09 18.4993 17.157Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.99927 14.657C4.55155 14.657 4.99927 15.1047 4.99927 15.657V23.157C4.99927 23.7093 4.55155 24.157 3.99927 24.157C3.44698 24.157 2.99927 23.7093 2.99927 23.157V15.657C2.99927 15.1047 3.44698 14.657 3.99927 14.657Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.99927 0.156982C4.55155 0.156982 4.99927 0.604698 4.99927 1.15698V10.657C4.99927 11.2093 4.55155 11.657 3.99927 11.657C3.44698 11.657 2.99927 11.2093 2.99927 10.657V1.15698C2.99927 0.604698 3.44698 0.156982 3.99927 0.156982Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.99927 11.657C3.17084 11.657 2.49927 12.3286 2.49927 13.157C2.49927 13.9854 3.17084 14.657 3.99927 14.657C4.82769 14.657 5.49927 13.9854 5.49927 13.157C5.49927 12.3286 4.82769 11.657 3.99927 11.657ZM0.499268 13.157C0.499268 11.224 2.06627 9.65698 3.99927 9.65698C5.93226 9.65698 7.49927 11.224 7.49927 13.157C7.49927 15.09 5.93226 16.657 3.99927 16.657C2.06627 16.657 0.499268 15.09 0.499268 13.157Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ControlOutline
