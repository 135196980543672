import { RefObject, useCallback, useEffect, useRef } from 'react'

export const useClickAway = (ref: RefObject<HTMLElement | null>, callback: (e: Event) => void) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const handleClickOut = useCallback(
    (e: Event) => {
      if (!e.composedPath().includes(ref.current as EventTarget)) {
        savedCallback.current(e)
      }
    },
    [ref]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOut)

    return () => {
      document.removeEventListener('click', handleClickOut)
    }
  }, [handleClickOut])
}
