import { UuidSchema, z } from '@invisible/zod'

const schema = z.object({
  name: z.string().optional(),
  baseVariableId: UuidSchema.optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
