import { SVGProps } from 'react'

function BellOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.1943 10C11.8443 9.35 11.3943 8.15 11.3943 6V5.55C11.3443 2.8 9.14434 0.5 6.39434 0.5C6.39434 0.5 6.39434 0.5 6.34434 0.5C5.69434 0.5 5.04434 0.65 4.44434 0.85C3.89434 1.1 3.34434 1.5 2.84434 1.95C2.39434 2.4 1.99434 2.95 1.74434 3.6C1.49434 4.2 1.34434 4.85 1.34434 5.5V6C1.34434 8.15 0.894336 9.35 0.544336 10C0.494336 10.15 0.444336 10.35 0.444336 10.5C0.444336 10.65 0.494336 10.85 0.594336 11C0.694336 11.15 0.794336 11.3 0.944336 11.35C1.09434 11.45 1.24434 11.5 1.44434 11.5H3.94434C3.94434 12.15 4.19434 12.8 4.69434 13.25C5.14434 13.7 5.79434 14 6.44434 14C7.09434 14 7.74434 13.75 8.19434 13.25C8.64434 12.75 8.94434 12.15 8.94434 11.5H11.3443C11.5443 11.5 11.6943 11.45 11.8443 11.35C11.9943 11.25 12.1443 11.15 12.1943 11C12.2943 10.85 12.3443 10.7 12.3443 10.5C12.2943 10.35 12.2943 10.15 12.1943 10ZM7.49434 12.55C6.94434 13.1 5.94434 13.1 5.39434 12.55C5.09434 12.3 4.94434 11.9 4.94434 11.5H7.94434C7.94434 11.9 7.79434 12.3 7.49434 12.55ZM8.44434 10.5H4.44434H1.44434C1.89434 9.75 2.39434 8.35 2.39434 6V5.5C2.39434 4.95 2.49434 4.45 2.69434 3.95C2.89434 3.45 3.19434 3 3.54434 2.65C3.94434 2.3 4.39434 2 4.89434 1.8C5.39434 1.6 5.89434 1.5 6.39434 1.5C8.59434 1.5 10.3443 3.35 10.3443 5.55V6C10.3443 8.35 10.8443 9.75 11.2943 10.5H8.44434Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BellOutlined
