import { formatInTimeZone, formatUnit, isAlphaNumeric } from '@invisible/common/helpers'
import {
  IAttribute,
  IBarchartData,
  IChoroplethData,
  ILineChartData,
  IPiechartData,
  IPieChartDatum,
  ITableColumn,
  ITableData,
  ITile,
  IVisualization,
  IVisualizationLegend,
  Line,
  TDeviationDirection,
} from '@invisible/common/types'
import { IFormattedPeriod } from '@invisible/common/types'
import { TDateViewLevel } from '@invisible/common/types'
import { DATE_TIME_FORMATS, FILTER_TYPE } from '@invisible/ui/constants'
import { colorSchemes } from '@nivo/colors'
import { format } from 'date-fns'
import { filter, find, findIndex, flow, forEach, isEmpty, map, reduce, uniq } from 'lodash/fp'

import { featureData } from '../../../../constants/world-feature-data'
import { DEFAULT_VISUALIZATION_DATA } from './constants'
import {
  formatLines,
  formatXValueByAttributeValueType,
  sortVisualizationDataByXAxisAttribute,
} from './helpers'

// This scheme is used for assigning colors to target lines returned by
// Bar Chart and Line Chart data formatters
const COLOR_SCHEME_CATEGORY10 = colorSchemes['category10']

interface IBarchartFormatterParams {
  visualization: IVisualization
  colorScheme: string[]
  dateViewLevel: TDateViewLevel
}

interface IPieChartFormatterParams {
  visualization: IVisualization
  formattedPeriod?: IFormattedPeriod
  colorScheme: string[]
  dateFormatString?: string
}

interface IFeatureData {
  type: string
  properties: {
    name: string
    density: number
  }
  id: string
}

// Add keys or properties in each object of formatted data to enable DRILL down
// behavior
const getDrillProperties = ({
  dateViewLevel,
  datum,
  drillAttribute,
  xAttribute,
  yKey,
}: {
  yKey: string
  datum: Record<string, unknown>
  drillAttribute: IAttribute
  xAttribute: IAttribute
  dateViewLevel: TDateViewLevel
}) => {
  const isXAttributeDateType =
    xAttribute.valueType === 'DateTime' || xAttribute.valueType === 'Date'

  const dateProperties = isXAttributeDateType
    ? {
        date: datum[xAttribute.value],
        format: DATE_TIME_FORMATS[dateViewLevel],
      }
    : []

  return {
    ['drillId_' + yKey]: datum[drillAttribute.primaryKey as string] as string,
    valueType: xAttribute.valueType,
    nextReport: drillAttribute.nextReport as string,
    isFinalLevel: !!drillAttribute.isFinalLevel,
    ...dateProperties,
  }
}

const getNewFormattedDatum = ({
  barIndex,
  barIndexKey,
  dateViewLevel,
  datum,
  xAttribute,
  yKey,
  yValue,
  drillAttribute,
}: {
  drillAttribute?: IAttribute
  dateViewLevel: TDateViewLevel
  datum: Record<string, unknown>
  xAttribute: IAttribute
  yKey: string
  barIndexKey: string
  barIndex: string
  yValue: number
}) => {
  const customProperties = drillAttribute
    ? getDrillProperties({
        dateViewLevel,
        drillAttribute,
        datum,
        xAttribute,
        yKey,
      })
    : {}

  return {
    [barIndexKey]: barIndex,
    [yKey]: yValue,
    ...customProperties,
  }
}

const getUpdatedFormattedData = ({
  formattedData,
  index,
  yKey,
  yValue,
}: {
  index: number
  formattedData: IBarchartData['data']
  yKey: string
  yValue: number
}) => {
  const oldYValue = Number(formattedData[index][yKey] ?? 0)
  const updatedYValue = oldYValue + yValue
  formattedData[index] = {
    ...formattedData[index],
    [yKey]: updatedYValue,
  }
  return formattedData
}

const getFormattedData = ({
  data,
  xAttribute,
  yAttribute,
  dateViewLevel,
  drillAttribute,
}: {
  data: Record<string, unknown>[]
  xAttribute: IAttribute
  yAttribute: IAttribute
  dateViewLevel: TDateViewLevel
  drillAttribute?: IAttribute
}) =>
  data.reduce((formattedData: IBarchartData['data'], datum: Record<string, unknown>) => {
    if (!datum[xAttribute.value]) return formattedData

    const barIndexKey = xAttribute.value
    const barIndex = formatXValueByAttributeValueType({
      value: datum[barIndexKey],
      valueType: xAttribute.valueType,
      dateViewLevel,
    })

    const yKey =
      yAttribute.labelType?.toLowerCase() === 'series'
        ? String(datum[yAttribute.labelValue])
        : String(datum[xAttribute.value])

    const yValue = datum[yAttribute.value] ? Number(datum[yAttribute.value]) : 0

    const formattedDatumIndex = formattedData.findIndex(
      (formattedDatum) => formattedDatum[barIndexKey] === barIndex
    )

    if (formattedDatumIndex >= 0) {
      return getUpdatedFormattedData({
        formattedData,
        index: formattedDatumIndex,
        yKey,
        yValue,
      })
    } else {
      const formattedDatum = getNewFormattedDatum({
        barIndex,
        barIndexKey,
        dateViewLevel,
        datum,
        xAttribute,
        yKey,
        yValue,
        drillAttribute,
      })

      return [...formattedData, formattedDatum]
    }
  }, [])

// Returns list of unique keys that are present in each object of formatted data
// excluding the barIndexKey and custom keys (drill related keys)
const getKeysFromFormattedData = ({
  barIndexKey,
  customKeys,
  formattedData,
}: {
  formattedData: IBarchartData['data']
  barIndexKey: string
  customKeys: string[]
}) => {
  const isCustomKey = (key: string) =>
    customKeys.some((customKey: string) => key.startsWith(customKey))
  const allPossibleKeys = formattedData.flatMap((formattedDatum) => Object.keys(formattedDatum))
  const keysWithoutBarIndex = allPossibleKeys.filter(
    (key) => key !== barIndexKey && !isCustomKey(key)
  )
  return uniq(keysWithoutBarIndex)
}

export const formatBarChartData = ({
  visualization,
  colorScheme,
  dateViewLevel,
}: IBarchartFormatterParams): IBarchartData => {
  const X_AXIS = find((attr: IAttribute) => attr.name === 'X_AXIS')(visualization.attributesMapping)
  const Y_AXIS = find((attr: IAttribute) => attr.name === 'Y_AXIS')(visualization.attributesMapping)
  const DRILL = find((attr: IAttribute) => attr.name === 'DRILL')(visualization.attributesMapping)

  if (!X_AXIS || !Y_AXIS) return DEFAULT_VISUALIZATION_DATA.barChart!

  const drillKeys = ['nextReport', 'isFinalLevel', 'date', 'format', 'drillId']

  const visualizationSortedData = sortVisualizationDataByXAxisAttribute(visualization.data, X_AXIS)

  const formattedData = getFormattedData({
    data: visualizationSortedData,
    dateViewLevel,
    xAttribute: X_AXIS,
    yAttribute: Y_AXIS,
    drillAttribute: DRILL,
  })

  const keys = getKeysFromFormattedData({
    barIndexKey: X_AXIS.value,
    customKeys: drillKeys,
    formattedData,
  })

  const legends = keys.map((key, index) => ({
    color: colorScheme[index % colorScheme.length],
    label: key,
  }))

  const [targetLine] = Y_AXIS.target
    ? formatLines({
        colorScheme: COLOR_SCHEME_CATEGORY10,
        data: visualizationSortedData,
        dateViewLevel,
        xAttribute: X_AXIS,
        yAttribute: Y_AXIS,
        isTargetLine: true,
      })
    : []

  const colors = reduce(
    (acc: object, curr: IVisualizationLegend) => ({ ...acc, [curr.label]: curr.color }),
    {}
  )(legends)

  const labelAttr = Y_AXIS.labelType === 'Series' ? Y_AXIS : X_AXIS

  return {
    KEYS: keys,
    legends,
    data: formattedData,
    xAxisLegend: X_AXIS.label,
    yAxisLegend: Y_AXIS.label,
    xAxisLabelRotationAngle: X_AXIS.labelRotationAngle,
    colors,
    indexedBy: X_AXIS.value,
    baseDateViewLevel: visualization.attributesMapping.find(
      (attribute: IAttribute) => attribute.name === 'DATE_VIEW'
    )?.value as TDateViewLevel | undefined,
    tooltipConfig: {
      labelConfig: {
        label: labelAttr.label,
        labelType: labelAttr.labelType,
        labelValue: labelAttr.labelValue,
      },
      valueConfig: {
        label: Y_AXIS.label,
        valueType: Y_AXIS.valueType,
        postfixUnit: Y_AXIS.postfixUnit,
        prefixUnit: Y_AXIS.prefixUnit,
      },
    },
    targetLine,
  }
}

const formatStringByType = ({
  type,
  text,
  dateFormatString,
}: {
  type: string
  text: string
  dateFormatString: string
}) => (type === 'Date' || type === 'DateTime' ? format(new Date(text), dateFormatString) : text)

export const formatPieChartData = ({
  visualization,
  dateFormatString = 'dd MMM, yyyy',
  colorScheme,
}: IPieChartFormatterParams): IPiechartData => {
  const initailizedAttribute: IAttribute = {
    name: 'X_AXIS',
    value: '',
    label: '',
    labelType: 'Series',
    labelValue: '',
    valueType: '',
    isNullable: false,
    columns: [],
    isPrimary: false,
    groupId: '',
    attributeId: '',
    attributeName: '',
  }

  let data: IPieChartDatum[] = []
  let labelAttr = { ...initailizedAttribute }
  let valueAttr = { ...initailizedAttribute }
  let drillAttr = { ...initailizedAttribute }
  forEach((attr: IAttribute) => {
    if (attr.name === 'LABEL') labelAttr = attr
    if (attr.name === 'VALUE') valueAttr = attr
    if (attr.name === FILTER_TYPE.Drill) drillAttr = attr
  })(visualization.attributesMapping)
  forEach((datum: Record<string, string>) => {
    let index = findIndex((series: IPieChartDatum) => series.label === datum[labelAttr.value])(data)
    if (index >= 0) {
      data[index]['value'] = Number(datum[valueAttr.value]) + Number(data[index]['value'])
      data[index]['value'] = Number(data[index]['value'].toFixed(2))
    } else {
      index = data.length
      data[index] = {
        ...(data[index] ?? {}),
        value: Number(datum[valueAttr.value]),
        id: datum[labelAttr.value] ?? '',
        label: datum[labelAttr.value]
          ? formatStringByType({
              type: labelAttr.valueType,
              dateFormatString,
              text: datum[labelAttr.value],
            })
          : '',
        ...(drillAttr
          ? {
              nextReport: drillAttr.nextReport,
              drillId: drillAttr?.primaryKey ? datum[drillAttr.primaryKey] : undefined,
            }
          : {}),
      }
    }
  })(visualization.data)
  data = data.map((datum, index) => ({
    ...datum,
    color: colorScheme[index % colorScheme.length],
  }))
  const legends = map(
    (datum: IPieChartDatum) => ({ color: datum.color || 'black', label: datum.id }),
    data
  )

  return {
    data,
    legends,
    tooltipConfig: {
      labelConfig: {
        label: labelAttr.label,
        labelType: labelAttr.labelType,
        labelValue: labelAttr.labelValue,
      },
      valueConfig: {
        label: valueAttr.label,
        valueType: valueAttr.valueType,
        postfixUnit: valueAttr.postfixUnit,
        prefixUnit: valueAttr.prefixUnit,
      },
    },
  }
}

const formatDatumValue = (
  value: string | number | null,
  column: ITableColumn,
  dateFormatString: string
) => {
  if (column.valueType === 'DateTime' || column.valueType === 'Date') {
    return value ? formatInTimeZone({ date: value, format: dateFormatString }) : null
  }
  return formatUnit({
    postfixUnit: column.postfixUnit,
    prefixUnit: column.prefixUnit,
    value,
    valueType: column.valueType,
  })
}

const formatDatum = (
  datum: Record<string, string | number | null>,
  columns: ITableData['columns'],
  dateFormatString: string
) => {
  const formattedDatum: Record<string, string | number | null> = {}
  columns.forEach((column) => {
    const value = datum[column.accessor]
    formattedDatum[column.accessor] = formatDatumValue(value, column, dateFormatString)
  })
  return formattedDatum
}

export const formatTableData = (
  visualization: IVisualization,
  dateFormatString = 'MMM dd, yyyy'
): ITableData => {
  const data = visualization?.data ?? []
  const columns: ITableData['columns'] = visualization.attributesMapping
    .filter((attr: IAttribute) => attr.name === 'COLUMN')
    .map(({ label, value, sourceLink, valueType, order, postfixUnit, prefixUnit }: IAttribute) => ({
      Header: label,
      accessor: value,
      url: sourceLink,
      valueType,
      order,
      postfixUnit,
      prefixUnit,
    }))

  // @ts-expect-error Bad Typings
  const formattedData = data.map((datum: Record<string, string | number | null>) =>
    formatDatum(datum, columns, dateFormatString)
  )

  return { columns, data: formattedData }
}

export const formatLinearData = (visualization: IVisualization) => {
  const formattedData: Record<string, string>[] = []
  forEach((series: any) => {
    let dataSeries = {}
    forEach((attr: IAttribute) => {
      if (attr.name === 'LABEL') {
        dataSeries = { ...dataSeries, label: series[attr.value] }
      } else if (attr.name === 'VALUE') {
        dataSeries = { ...dataSeries, value: series[attr.value] }
      }
    })(visualization.attributesMapping)
    formattedData.push(dataSeries)
  })(visualization.data)

  return formattedData
}

export const formatChoroplethData = (visualization: IVisualization): IChoroplethData => {
  const VALUE = find((attr: IAttribute) => attr.name === 'VALUE')(visualization.attributesMapping)
  const COUNTRY_CODE = find((attr: IAttribute) => attr.name === 'COUNTRY_CODE')(
    visualization.attributesMapping
  )
  if (isEmpty(visualization.data) || !COUNTRY_CODE || !VALUE)
    return {
      features: [],
      type: '',
    }
  const formattedData = JSON.parse(JSON.stringify(featureData))
  return {
    ...formattedData,
    features: map((feature: IFeatureData) => {
      const sum = flow(
        filter((series: Record<string, unknown>) => series[COUNTRY_CODE.value] === feature.id),
        reduce((acc, series) => acc + Number(series[VALUE.value]), 0)
      )(visualization.data)
      feature.properties.density = sum
      return {
        ...feature,
        properties: {
          ...feature.properties,
          density: sum,
        },
      }
    })(formattedData.features),
  }
}

export const formatTileData = (visualization: IVisualization): Record<string, string> => {
  const data: Record<string, string> = {}
  forEach((series: Record<string, string | number>) => {
    forEach((attr: IAttribute) => {
      if (attr.name === 'VALUE') {
        data[attr.value] = formatUnit({
          prefixUnit: attr.prefixUnit,
          postfixUnit: attr.postfixUnit,
          valueType: attr.valueType,
          value: series[attr.value],
        })
      } else if (attr.name === 'LABEL') {
        data['title'] = attr.label
      }
    })(visualization.attributesMapping)
  })(visualization.data)

  return data
}

export const formatGridData = (visualization: IVisualization): ITile[] => {
  const mapDatumToTile = (datum: Record<string, string>): ITile => {
    const color = datum[tileAttribute?.deviationColor ?? ''] ?? ''
    return {
      value: datum[tileAttribute?.value ?? ''] ?? '',
      label: datum[tileAttribute?.labelValue ?? ''] ?? '',
      order: Number(datum[tileAttribute?.order ?? '']) ?? 0,
      deviationInfo: {
        label: datum[tileAttribute?.deviationLabel ?? ''] ?? '',
        percentage: datum[tileAttribute?.deviationPercent ?? ''],
        direction: datum[tileAttribute?.deviationDirection ?? ''] as TDeviationDirection,
        color: color.charAt(0) === '#' ? color : isAlphaNumeric(color) ? '#' + color : color,
      },
    }
  }

  const tileAttribute = find((attr: IAttribute) => attr.attributeName === 'TILE')(
    visualization.attributesMapping
  )

  if (!tileAttribute) return []
  const data = visualization.data as Record<string, string>[]

  return data?.map(mapDatumToTile).sort((tileA, tileB) => tileA.order - tileB.order)
}

export const formatLineChartData = ({
  visualization,
  colorScheme,
  dateViewLevel,
}: {
  visualization: IVisualization
  colorScheme: string[]
  dateViewLevel: TDateViewLevel
}): ILineChartData => {
  const xAttribute: IAttribute | undefined = visualization.attributesMapping.find(
    (attr) => attr.name === 'X_AXIS'
  )
  const yAttributes: IAttribute[] = visualization.attributesMapping.filter((attr) =>
    attr.name.includes('Y_AXIS')
  )
  const dateViewAttribute = visualization.attributesMapping.find(
    (attr) => attr.name === 'DATE_VIEW'
  )

  const legends: IVisualizationLegend[] = []

  if (
    !xAttribute ||
    yAttributes.length === 0 ||
    !visualization.data ||
    visualization.data.length === 0
  ) {
    return { data: [] as Line[], legends }
  }

  const visualizationSortedData = sortVisualizationDataByXAxisAttribute(
    visualization.data,
    xAttribute
  )

  const formattedData: Line[] = yAttributes.flatMap((yAttribute) =>
    formatLines({
      colorScheme,
      data: visualizationSortedData,
      dateViewLevel,
      xAttribute,
      yAttribute,
    })
  )

  const targetLines: Line[] = yAttributes
    .flatMap((yAttribute) =>
      yAttribute.target
        ? formatLines({
            colorScheme,
            data: visualizationSortedData,
            dateViewLevel,
            xAttribute,
            yAttribute,
            isTargetLine: true,
          })
        : undefined
    )
    .filter((targetLine) => typeof targetLine !== 'undefined') as Line[]

  return {
    data: formattedData,
    legends,
    xAxisLegend: xAttribute.label,
    yAxisLegend: yAttributes.map((attribute) => attribute.label).join('-'),
    xAxisLabelRotationAngle: xAttribute.labelRotationAngle,
    baseDateViewLevel:
      xAttribute.valueType === 'DateTime' || xAttribute.valueType === 'Date'
        ? (dateViewAttribute?.value as TDateViewLevel | undefined)
        : undefined,
    targetLines,
  }
}
