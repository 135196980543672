import { SVGProps } from 'react'

function HourglassFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M27.9544 8.93553V3.45861C27.9544 2.80577 27.6951 2.17967 27.2336 1.71804C26.7721 1.25641 26.1461 0.99707 25.4933 0.99707H8.26555C7.61283 0.99707 6.98683 1.25641 6.52529 1.71804C6.06374 2.17967 5.80444 2.80577 5.80444 3.45861V8.99707C5.80563 9.379 5.89511 9.75549 6.06588 10.0971C6.23666 10.4387 6.48411 10.7362 6.78889 10.9663L14.8336 16.9971L6.78889 23.0278C6.48411 23.2579 6.23666 23.5554 6.06588 23.897C5.89511 24.2387 5.80563 24.6151 5.80444 24.9971V30.5355C5.80444 31.1884 6.06374 31.8145 6.52529 32.2761C6.98683 32.7377 7.61283 32.9971 8.26555 32.9971H25.4933C26.1461 32.9971 26.7721 32.7377 27.2336 32.2761C27.6951 31.8145 27.9544 31.1884 27.9544 30.5355V25.0586C27.9533 24.6767 27.8638 24.3002 27.693 23.9586C27.5222 23.617 27.2748 23.3195 26.97 23.0894L18.9252 16.9971L26.97 10.9048C27.2748 10.6747 27.5222 10.3772 27.693 10.0356C27.8638 9.69395 27.9533 9.31746 27.9544 8.93553ZM25.4933 3.45861V7.15092H8.26555V3.45861H25.4933ZM25.4933 30.5355H8.26555V24.9971L16.8794 18.5355L25.4933 25.0586V30.5355Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default HourglassFilled
