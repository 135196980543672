import { classNames } from '@invisible/common/helpers'
import { sendErrorToSentry } from '@invisible/errors'
import { logger } from '@invisible/logger/client'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { Input } from '@invisible/ui/input'
import { NullSwitch } from '@invisible/ui/null-switch'
import { SingleDatePicker } from '@invisible/ui/single-date-picker'
import { TagInput } from '@invisible/ui/tag-input'
import { gray, styled } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { startCase } from 'lodash/fp'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { convertCsvToJson } from '../common/helpers'
import { useExecuteManualTrigger } from '../hooks/useExecuteManualTrigger'
import { TFieldType, TParsedProperties, TParsedProperty } from './types'

export interface ITriggerFormProps {
  parsedProperties: TParsedProperties
  triggerStepId: string
  requiredFields: string[]
  closeWizard: () => void
  description: string
  onTriggerExecute?: () => void
}

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

const TriggerFormWAC = ({
  parsedProperties,
  triggerStepId,
  requiredFields,
  closeWizard,
  description,
  onTriggerExecute,
}: ITriggerFormProps) => {
  const { value: enableCsvStreaming } = useGate('enable-csv-streaming')
  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const ref = useRef<HTMLDivElement>(null)
  const reactQueryClient = useQueryClient()

  const csvUploadInputRefs = useRef<HTMLInputElement[]>([] as HTMLInputElement[])
  const csvUploadInputRefMap = parsedProperties
    .filter((p) => p.type === 'array' && p.items?.type === 'object')
    .reduce(
      (acc, p, idx) => ({
        ...acc,
        [p.label]: idx,
      }),
      {} as Record<string, number>
    )

  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const {
    isLoading: isExecuteConcordeManualTriggerLoading,
    mutateAsync: executeConcordeManualTrigger,
  } = useExecuteManualTrigger({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, label: string) => {
    event.stopPropagation()
    event.preventDefault()
    csvUploadInputRefs.current[csvUploadInputRefMap[label]]?.click()
  }

  const handleCsvUpload = (label: string) => (e: ChangeEvent<HTMLInputElement>) => {
    convertCsvToJson({ e, setFormValues, label, enableStreaming: enableCsvStreaming })
  }

  useEffect(() => {
    // Adding default values on load
    const defaultFormValues = parsedProperties.reduce((acc, property) => {
      if (property.default !== undefined) {
        return { ...acc, [property.label]: property.default }
      }
      return acc
    }, {})
    setFormValues(defaultFormValues)
  }, [parsedProperties])

  const fieldComponents: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [key in TFieldType]?: FC<TParsedProperty & { formValues: Record<string, any> }>
  } = useMemo(
    () => ({
      string: ({ label, formValues, format }) => {
        if (format === 'date-time') {
          return (
            <DateTimePicker
              views={['year', 'month']}
              value={formValues[label]}
              hideTime={true}
              onChange={(value: Date | null) =>
                setFormValues((prev) => ({
                  ...prev,
                  [label]: value?.toISOString(),
                }))
              }
            />
          )
        }
        return (
          <Input
            width='100%'
            value={formValues[label]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormValues((prev) => ({
                ...prev,
                [label]: e.target.value,
              }))
            }
          />
        )
      },
      number: ({ label, formValues }) => (
        <Input
          width='100%'
          type='number'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: Number(e.target.value),
            }))
          }
        />
      ),
      date: ({ label }) => (
        <SingleDatePicker
          wFull
          onChange={(date) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: date,
            }))
          }
        />
      ),
      datetime: ({ label }) => (
        <DateTimePicker
          value={formValues[label]}
          fullWidth
          hideTime={false}
          inputReadonly
          onChange={(value: Date | null) => {
            if (!value) return

            setFormValues((prev) => ({
              ...prev,
              [label]: value?.toISOString(),
            }))
          }}
        />
      ),
      url: ({ label, formValues }) => (
        <Input
          width='100%'
          type='url'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: e.target.value,
            }))
          }
        />
      ),
      email: ({ label, formValues }) => (
        <Input
          width='100%'
          type='email'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: e.target.value,
            }))
          }
        />
      ),
      dropdown: ({ label, ...property }) => (
        <Dropdown
          width='100%'
          selectedKey={(formValues[label] as string) ?? null}
          maxHeight='300px'
          options={property.enum?.map((option) => ({ key: option, value: option })) ?? []}
          onChange={({ key }) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: key,
            }))
          }
          name={label}
        />
      ),
      boolean: ({ label, formValues }) => (
        <NullSwitch
          isOn={formValues[label]}
          onToggle={(value) => setFormValues((prev) => ({ ...prev, [label]: value }))}
        />
      ),
      array: ({
        label,
        items,
        formValues,
      }: {
        label: string
        items?: { type: TFieldType }
        formValues: Record<string, any>
      }) => {
        if (items?.type === 'object') {
          return (
            <>
              <Button variant='primary' size='md' onClick={(e) => handleClick(e, label)}>
                {formValues[label] ? 'Uploaded' : 'Upload CSV'}
              </Button>
              <input
                ref={(el) => {
                  if (csvUploadInputRefs && el) {
                    csvUploadInputRefs.current[csvUploadInputRefMap[label]] = el
                  }
                }}
                type='file'
                accept='.csv'
                onChange={handleCsvUpload(label)}
                hidden
              />
            </>
          )
        } else if (items?.type === 'string') {
          return (
            <TagInput
              onChange={(value: string[]) =>
                setFormValues((prev) => ({
                  ...prev,
                  [label]: value,
                }))
              }
            />
          )
        } else {
          logger.warn(`array type not supported by TriggerFormWAC: ${items?.type}`)
          return null
        }
      },
    }),
    [formValues, handleClick]
  )

  const isDisabled = requiredFields?.reduce(
    (acc, requiredField) => (formValues[requiredField] === undefined ? true : acc),
    false
  )

  const handleSubmit = async () => {
    try {
      await executeConcordeManualTrigger({ payload: formValues, triggerStepId })
      closeWizard()
      onTriggerExecute?.()
    } catch (error) {
      sendErrorToSentry(error)
      addToast(`Something went wrong: ${(error as Error).message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <Container ref={ref}>
      {description ? (
        <div className='mb-5' dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      ) : null}
      {parsedProperties?.map((field) => (
        <div
          key={field.label}
          className={classNames(
            'mb-5',
            field.type === 'boolean' ? 'flex flex-row-reverse items-center justify-end	gap-3' : ''
          )}>
          <div className={field.type === 'boolean' ? '' : 'mb-2'}>{startCase(field.label)}</div>
          <div className='shrink-0'>
            {fieldComponents[field.fieldType]?.({ ...field, formValues })}
          </div>
        </div>
      ))}
      <div className='mt-2 flex justify-center'>
        <Button
          variant='primary'
          size='md'
          disabled={isDisabled}
          onClick={handleSubmit}
          loading={isExecuteConcordeManualTriggerLoading}>
          Submit
        </Button>
      </div>
    </Container>
  )
}

export { TriggerFormWAC }
