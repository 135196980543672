import { baseTheme } from '@invisible/ui/themes'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FC } from 'react'

const theme = createTheme({
  palette: {
    primary: {
      main: baseTheme.colors.primary,
    },
    secondary: { main: baseTheme.colors.primary },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '28px',
          padding: '3px 0 3px 14px !important',
          fontSize: baseTheme.fontSizes[1],
          width: '120px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: '4px !important',
          color: `${baseTheme.grayScale[6]} !important`,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '16px',
        },
      },
    },
  },
})

export interface IDateTimePickerProps {
  value: Date | null
  onChange: DateTimePickerProps<Date>['onChange']
  ampm?: boolean
  fullWidth?: boolean
  hideTime?: boolean
  views?: ('day' | 'month' | 'year')[]
  disabled?: boolean
  inputReadonly?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DateTimePicker: FC<IDateTimePickerProps> = ({
  value,
  onChange,
  ampm = false,
  fullWidth = false,
  hideTime = false,
  views,
  disabled = false,
  inputReadonly,
}) => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {hideTime ? (
        <MUIDatePicker
          disabled={disabled}
          views={views}
          value={value || null}
          onChange={onChange}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth,
              inputProps: { ...(inputReadonly ? { readOnly: true } : {}) },
            },
          }}
        />
      ) : (
        <MUIDateTimePicker
          disabled={disabled}
          value={value || null}
          ampm={ampm}
          onChange={onChange}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth,
              inputProps: { ...(inputReadonly ? { readOnly: true } : {}) },
            },
          }}
        />
      )}
    </LocalizationProvider>
  </ThemeProvider>
)
