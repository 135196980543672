import { SVGProps } from 'react'
function ToolOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.0438 3.10668C18.1358 2.94045 17.1987 2.95483 16.287 3.15498C14.8634 3.46754 13.5731 4.21748 12.5968 5.2998C11.6206 6.38213 11.0072 7.74265 10.8426 9.1909C10.678 10.6391 10.9704 12.1026 11.6789 13.3764C11.9093 13.7907 11.8172 14.3098 11.4584 14.6195L3.36397 21.6056C3.00241 21.9793 2.7998 22.4792 2.7998 23C2.7998 23.5318 3.01107 24.0418 3.38712 24.4179C3.76316 24.7939 4.27319 25.0052 4.80501 25.0052C5.32581 25.0052 5.82572 24.8026 6.19937 24.441L13.1855 16.3466C13.4952 15.9877 14.0143 15.8956 14.4286 16.1261C15.7024 16.8346 17.1658 17.127 18.6141 16.9624C20.0623 16.7978 21.4229 16.1844 22.5052 15.2082C23.5875 14.2319 24.3374 12.9416 24.65 11.518C24.8502 10.6063 24.8645 9.66917 24.6983 8.7612L20.638 12.8312C20.4012 13.0686 20.0612 13.1715 19.7326 13.1053L16.1951 12.3928C15.8003 12.3133 15.4917 12.0047 15.4122 11.6099L14.6997 8.07243C14.6335 7.74377 14.7364 7.40381 14.9737 7.16703L19.0438 3.10668ZM15.8581 1.20151C17.6893 0.799487 19.6003 0.980161 21.3237 1.71824C21.629 1.84897 21.8494 2.12272 21.9121 2.44884C21.9747 2.77495 21.8714 3.11089 21.6363 3.34543L16.7663 8.20379L17.2416 10.5634L19.6012 11.0387L24.4596 6.16872C24.6941 5.93362 25.03 5.83029 25.3562 5.89294C25.6823 5.95558 25.956 6.17603 26.0868 6.48129C26.8248 8.20465 27.0055 10.1157 26.6035 11.9468C26.2015 13.778 25.2369 15.4376 23.8448 16.6933C22.4526 17.949 20.7027 18.7379 18.8399 18.9496C17.2506 19.1302 15.647 18.8829 14.1929 18.2404L7.68704 25.7784C7.67106 25.7969 7.65441 25.8148 7.63712 25.8321C6.88599 26.5832 5.86725 27.0052 4.80501 27.0052C3.74276 27.0052 2.72402 26.5832 1.9729 25.8321C1.22178 25.081 0.799805 24.0622 0.799805 23C0.799805 21.9377 1.22178 20.919 1.9729 20.1679C1.99019 20.1506 2.00812 20.1339 2.02663 20.1179L9.56456 13.6121C8.92206 12.158 8.67477 10.5544 8.8554 8.96505C9.0671 7.10228 9.85602 5.35234 11.1117 3.96023C12.3674 2.56812 14.027 1.60353 15.8581 1.20151Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ToolOutline
