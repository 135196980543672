import { classNames } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { CaretDownIcon, CheckCircleFilledIcon, CloseCircleIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isNil } from 'lodash/fp'
import React, { ReactNode } from 'react'
type TCheckListValue = Record<string, boolean>

const Section = ({
  section,
  isSkipped,
  isOnlySection,
  fields,
  formValues,
  isCompleted,
  hasNextSection,
  checkLists,
  checkListValue,
  onNextSection,
  goToSection,
  onReview,
  formSubmittedFromSection,

  children,
}: {
  section: WizardSchemas.FormSection.TSchema
  isSkipped?: boolean
  isOnlySection?: boolean
  fields?: WizardSchemas.FormField.TSchema[]
  checkLists?: WizardSchemas.CheckList.TSchema[]
  checkListValue?: TCheckListValue
  formValues?: Record<string, any>
  isCompleted: boolean
  hasNextSection?: boolean
  formSubmittedFromSection?: string
  onNextSection?: (nextSection: string | undefined, shouldSubmit: boolean | undefined) => void
  goToSection?: (nextSection: string | undefined) => void
  onReview?: () => void
  children?: ReactNode
}) => {
  const hasMissingRequiredField = (fields ?? [])
    .filter((f) => f.required)
    .reduce((result, requiredField) => {
      if (
        formValues &&
        requiredField.baseVariableId &&
        (isNil(formValues[requiredField.baseVariableId]) ||
          formValues[requiredField.baseVariableId] === '')
      )
        result = true
      return result
    }, false)

  const hasMissingChecks = (checkLists ?? [])
    .map((checkList) => checkList.checkItems ?? [])
    .reduce((acc, val) => acc.concat(val), [])
    .filter((checkItem) => checkItem.isRequired)
    .reduce((result, checkItem) => {
      if (!checkListValue?.[checkItem.id]) result = true
      return result
    }, false)

  const handleNextSection = () => {
    let nextSection
    let shouldSubmit

    const conditionalField = (fields ?? []).find((field) => field.isConditionalInput)
    const conditionalFieldWithFormula = (fields ?? []).find(
      (field) => field.isConditionalWithFormula
    )

    if (conditionalField) {
      if (conditionalField.type === 'boolean') {
        if (formValues && formValues[conditionalField?.baseVariableId ?? ''] === true) {
          nextSection = conditionalField.goToSectionWhenTrue
          shouldSubmit = conditionalField.goToSectionWhenTrueAndSubmit
        } else if (formValues && formValues[conditionalField?.baseVariableId ?? ''] === false) {
          nextSection = conditionalField.goToSectionWhenFalse
          shouldSubmit = conditionalField.goToSectionWhenFalseAndSubmit
        } else {
          nextSection = conditionalField.goToSectionWhenUnset
          shouldSubmit = conditionalField.goToSectionWhenUnsetAndSubmit
        }
      } else {
        const option = (conditionalField.options ?? []).find(
          (option) => option.value === formValues![conditionalField?.baseVariableId ?? '']
        )
        nextSection = option?.goToSection ?? conditionalField.goToSectionWhenUnset
        shouldSubmit = option?.goToSectionAndSubmit
      }
    }

    if (conditionalFieldWithFormula) {
      ;(conditionalFieldWithFormula.conditionGoTos ?? []).forEach((conditionGoTo) => {
        const { condition } = conditionGoTo
        switch (condition.operator) {
          case 'eq':
            if (
              condition.leftOperand.type === 'constant' &&
              condition?.rightOperand?.type === 'constant'
            ) {
              if (condition.leftOperand.value === condition?.rightOperand?.value) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'constant' &&
              condition?.rightOperand?.type === 'variable'
            ) {
              if (
                condition.leftOperand.value ===
                formValues?.[condition?.rightOperand?.baseVariableId ?? '']
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'variable' &&
              condition.rightOperand?.type === 'constant'
            ) {
              if (
                formValues?.[condition.leftOperand.baseVariableId ?? ''] ===
                condition.rightOperand?.value
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'variable' &&
              condition.rightOperand?.type === 'variable'
            ) {
              if (
                formValues?.[condition.leftOperand.baseVariableId ?? ''] ===
                formValues?.[condition.rightOperand?.baseVariableId ?? '']
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            }

            break
          case 'neq':
            if (
              condition.leftOperand.type === 'constant' &&
              condition.rightOperand?.type === 'constant'
            ) {
              if (condition.leftOperand.value !== condition.rightOperand?.value) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'constant' &&
              condition.rightOperand?.type === 'variable'
            ) {
              if (
                condition.leftOperand.value !==
                formValues?.[condition.rightOperand?.baseVariableId ?? '']
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'variable' &&
              condition.rightOperand?.type === 'constant'
            ) {
              if (
                formValues?.[condition.leftOperand.baseVariableId ?? ''] !==
                condition.rightOperand?.value
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (
              condition.leftOperand.type === 'variable' &&
              condition.rightOperand?.type === 'variable'
            ) {
              if (
                formValues?.[condition.leftOperand.baseVariableId ?? ''] !==
                formValues?.[condition.rightOperand?.baseVariableId ?? '']
              ) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            }
            break
          case 'isNull':
            if (condition.leftOperand.type === 'constant') {
              if (!condition.leftOperand.value) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (condition.leftOperand.type === 'variable') {
              if (!formValues?.[condition.leftOperand.baseVariableId ?? '']) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            }
            break
          case 'isNotNull':
            if (condition.leftOperand.type === 'constant') {
              if (condition.leftOperand.value) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            } else if (condition.leftOperand.type === 'variable') {
              if (formValues?.[condition.leftOperand.baseVariableId ?? '']) {
                nextSection = conditionGoTo.goToSection
                shouldSubmit = conditionGoTo?.goToSectionAndSubmit
              }
            }
            break
          case 'gt':
            if (
              condition.leftOperand.valueType === 'number' &&
              condition?.rightOperand?.valueType === 'number'
            )
              if (
                condition.leftOperand.type === 'constant' &&
                condition?.rightOperand?.type === 'constant'
              ) {
                if (Number(condition.leftOperand.value) > Number(condition?.rightOperand?.value)) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'constant' &&
                condition?.rightOperand?.type === 'variable'
              ) {
                if (
                  Number(condition.leftOperand.value) >
                  Number(formValues?.[condition?.rightOperand?.baseVariableId ?? ''])
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'variable' &&
                condition.rightOperand?.type === 'constant'
              ) {
                if (
                  Number(formValues?.[condition.leftOperand.baseVariableId ?? '']) >
                  Number(condition.rightOperand?.value)
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'variable' &&
                condition.rightOperand?.type === 'variable'
              ) {
                if (
                  Number(formValues?.[condition.leftOperand.baseVariableId ?? '']) >
                  Number(formValues?.[condition.rightOperand?.baseVariableId ?? ''])
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              }
            break
          case 'lt':
            if (
              condition.leftOperand.valueType === 'number' &&
              condition?.rightOperand?.valueType === 'number'
            )
              if (
                condition.leftOperand.type === 'constant' &&
                condition?.rightOperand?.type === 'constant'
              ) {
                if (Number(condition.leftOperand.value) < Number(condition?.rightOperand?.value)) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'constant' &&
                condition?.rightOperand?.type === 'variable'
              ) {
                if (
                  Number(condition.leftOperand.value) <
                  Number(formValues?.[condition?.rightOperand?.baseVariableId ?? ''])
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'variable' &&
                condition.rightOperand?.type === 'constant'
              ) {
                if (
                  Number(formValues?.[condition.leftOperand.baseVariableId ?? '']) <
                  Number(condition.rightOperand?.value)
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              } else if (
                condition.leftOperand.type === 'variable' &&
                condition.rightOperand?.type === 'variable'
              ) {
                if (
                  Number(formValues?.[condition.leftOperand.baseVariableId ?? '']) <
                  Number(formValues?.[condition.rightOperand?.baseVariableId ?? ''])
                ) {
                  nextSection = conditionGoTo.goToSection
                  shouldSubmit = conditionGoTo?.goToSectionAndSubmit
                }
              }
            break
        }
      })
    }
    if (onNextSection) onNextSection(nextSection, shouldSubmit)
  }

  return (
    <div
      className={classNames(
        `border-r-6 mt-2 mb-2 rounded-md border border-solid border-gray-300 ${
          isSkipped ? 'bg-gray-100' : ''
        } pl-4 pt-2 pb-2 pr-4 shadow-sm`
      )}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {isCompleted && !isSkipped ? (
            <div className='text-green-500'>
              <CheckCircleFilledIcon width={20} />
            </div>
          ) : null}
          {isSkipped ? (
            <div className='text-gray-400'>
              <CloseCircleIcon width={20} />
            </div>
          ) : null}
          <h3 className={classNames(`whitespace-pre-line ${isSkipped ? 'text-gray-400' : ''}`)}>
            {section.label}
          </h3>
        </div>

        {isCompleted && !isSkipped ? (
          <div
            className='cursor-pointer text-gray-400'
            onClick={() => goToSection && goToSection(section.id)}>
            <CaretDownIcon />
          </div>
        ) : null}
      </div>
      {!isCompleted && !isSkipped ? children : null}
      {!isCompleted && !isOnlySection ? (
        <div className='flex justify-end'>
          {hasNextSection && section.id !== (formSubmittedFromSection ?? '') ? (
            <Button
              disabled={hasMissingRequiredField || hasMissingChecks}
              onClick={handleNextSection}>
              Next
            </Button>
          ) : (
            <Button
              disabled={hasMissingRequiredField || hasMissingChecks}
              onClick={() => onReview && onReview()}>
              Review
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

export { Section }
