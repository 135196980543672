import { SVGProps } from 'react'
function ToggleAnnotations(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={14}
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.046 7.176L9.508 1.72a.123.123 0 00-.175 0l-3.73 3.675a.125.125 0 00-.001.177l.001.001.625.616-1.83 1.801a.125.125 0 00-.002.177l.001.002.617.607-2.954 2.922h-.85a.127.127 0 00-.127.125v.863c0 .069.056.125.125.125h5.39a.123.123 0 00.087-.036l1.189-1.181.631.621a.122.122 0 00.087.037.122.122 0 00.088-.037l1.83-1.806.626.617a.124.124 0 00.175 0l3.73-3.675a.12.12 0 00.005-.175zM6.174 11.69H3.67l2.1-2.078 1.251 1.233-.848.845zm2.418-.97l-2.678-2.64 1.072-1.056 2.678 2.64-1.072 1.056zm2.633-1.19L7.12 5.484l2.302-2.267 4.106 4.047-2.302 2.265z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ToggleAnnotations
