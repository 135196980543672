import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { classNames } from '@invisible/common/helpers'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { TextArea } from '@invisible/ui/form'
import { RobotHeadIcon, UserOutlineIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Switch } from '@invisible/ui/switch'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { TJson } from '@invisible/zod'
import { differenceInSeconds } from 'date-fns/fp'
import { compact } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import { useGate } from 'statsig-react'
import { v4 as uuid } from 'uuid'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunVariableFindManyByBaseRunId } from '../../hooks/useBaseRunVariableFindManyByBaseRunId'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { CodeWAC } from '../CodeWAC'
import { SingleTurn } from './SingleTurn'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  isReadOnly: boolean
}

const FAKE_MESSAGE_ID = 'afb8d433-9e37-4ce0-aaab-e05bba558e03'

const SFT2WAC = ({ showName, name, baseRun, stepRun, sft2, isReadOnly }: IProps) => {
  const { value: enableTextBoxToggling } = useGate('enable-text-to-code-toggling')

  const [toggleState, setToggleState] = useState(false)

  const config = sft2 as NonNullable<WizardSchemas.SFT2.TSchema>

  const [inputText, setInputText] = useState('')
  const [currentTurn, setCurrentTurn] = useState(config.messageTypes[0]) // We start with the first Message Type

  // States responsible for message insertions
  const [insertAtIndex, setInsertAtIndex] = useState<number | boolean>(false)
  const [messageToBeInserted, setMessageToBeInserted] = useState<
    Record<string, unknown> | string | null
  >()
  const [turnTypeToBeInserted, setTurnTypeToBeInserted] = useState<string>(config.messageTypes[0])

  // States responsible for metadata validation
  const [metadataValidationFailures, setMetadataValidationFailures] = useState([] as string[])

  const reactQueryContext = useContext()
  const { dispatch } = useWizardState()
  const { addToast } = useToasts()

  // We fetch all Messages for the current Conversation
  const { data: messages, isLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config.messagesBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])

  // Mutation to create a new Base Run. To be used to create new Messages.
  const { mutateAsync: createBaseRun, isLoading: isCreatingNewMessage } = useBaseRunCreate({
    onMutate: async (data) => {
      await reactQueryContext.queryClient.cancelQueries('baseRun.findChildBaseRuns')

      // We optimistically update the query data to include the new message.
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.messagesBaseId,
            parentBaseRunId: baseRun.id,
          },
        ],
        (prevData) => {
          if (!prevData) return
          setInputText('')

          return [
            ...prevData,
            {
              id: FAKE_MESSAGE_ID, // We use a fake ID to indicate that the message is not yet saved.
              baseId: config.messagesBaseId as string,
              createdAt: new Date(),
              totalCount: prevData.length + 1,
              baseRunVariables: [
                {
                  id: uuid(),
                  baseVariable: { id: config.typeBaseVariableId as string, name: 'Type' },
                  value: data.initialValues[0].value,
                },
                {
                  id: uuid(),
                  baseVariable: { id: config.indexBaseVariableId as string, name: 'Index' },
                  value: data.initialValues[1].value,
                },
                {
                  id: uuid(),
                  baseVariable: { id: config.textBaseVariableId as string, name: 'Text' },
                  value: data.initialValues[2].value,
                },
              ],
            },
          ]
        }
      )
    },
    onSettled: () => {
      // We invalidate the query upon completion so that the new message is fetched fresh from the database.
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateManyVariables, isLoading: isUpdatingManyVariables } =
    useBaseRunVariablesWizardUpdate({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
    })

  // Initially we set the readyForSubmit state variable to false
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'SFT2',
      value: false,
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: 'setIsWacSubmitting',
      key: 'SFT2',
      value: isCreatingNewMessage,
    })
  }, [dispatch, isCreatingNewMessage])

  const validateMetadata = (message: TFindChildBaseRunsData[number]) => {
    // We find the Message Type for the message
    const messageType = message.baseRunVariables.find(
      (variable) => variable.baseVariable.id === config.typeBaseVariableId
    )?.value as string

    for (const variable of message.baseRunVariables) {
      for (const metadata of config.metadata ?? []) {
        // If the Metadata is required for the Message Type, we check if the variable is empty
        if ((metadata.requiredFor ?? []).includes(messageType)) {
          if (
            metadata.baseVariableId === variable.baseVariable.id &&
            (variable.value === null || variable.value === '')
          ) {
            return false
          }
        }
      }
    }

    // At this point we have checked all the Variables and all the Metadata configurations for the Message Type
    return true
  }

  // Every type the Messages array changes, we check if the metadata variables are filled.
  useEffect(() => {
    setMetadataValidationFailures([]) // We initialize the list of failed metadata variables to be empty

    // We loop through all Messages and check if their required metadata variables are filled
    for (const message of messages ?? []) {
      if (!validateMetadata(message)) {
        setMetadataValidationFailures((prev) => [...prev, message.id])
      }
    }
  }, [messages])

  // Every time the metadataValidationFailures state variable changes, we update the readyForSubmit state variable
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'SFT2-Metadata',
      value: metadataValidationFailures.length === 0, // If there are no metadataValidationFailures, we're ready to submit from Metadata perspective
    })
  }, [metadataValidationFailures])

  // Parses messages base runs into a typed object with its base run variables
  const normalizedMessages = useMemo(
    () =>
      (messages ?? [])
        .map((message) => ({
          id: message.id,
          type: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.typeBaseVariableId
          )?.value as string,
          index: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.indexBaseVariableId
          )?.value as number,
          text: message.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config.textBaseVariableId
          )?.value as string,
          createdAt: message.createdAt,
        }))
        .sort((a, b) => a.index - b.index),
    [config.textBaseVariableId, config.indexBaseVariableId, config.typeBaseVariableId, messages]
  )

  // When the normalizedMessages array changes, we check if we have reached the last Message Type of this round. We also set the currentTurn.
  useEffect(() => {
    // We get the Message Type Index of the last Message
    const mostRecentMessageTypeIndex = config.messageTypes?.indexOf(
      normalizedMessages?.[normalizedMessages.length - 1]?.type
    )

    if (mostRecentMessageTypeIndex < config.messageTypes?.length - 1) {
      // We set the currentTurn to the next Message Type
      return setCurrentTurn(config.messageTypes[mostRecentMessageTypeIndex + 1])
    }

    // If we reach this point, it means that the last Message was of the last Message Type. We set the readyForSubmit state variable to true.
    dispatch({
      type: 'setReadyForSubmit',
      key: 'SFT2',
      value: true,
    })

    // We set the currentTurn to the first Message Type
    return setCurrentTurn(config.messageTypes[0])
  }, [normalizedMessages])

  const messagesBaseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: compact(normalizedMessages?.map((message) => message.id) ?? []),
  })

  const mostRecentMessage = normalizedMessages?.[normalizedMessages.length - 1]

  // When a new Message is submitted, we create a new Base Run for it in the Messages Base.
  const handleBaseRunCreation = async ({
    type,
    text,
    increment = 1,
    index,
  }: {
    type: string
    text: string | Record<string, unknown>
    increment?: number
    index?: number
  }) => {
    const indexValue = index
      ? index
      : (mostRecentMessage?.index && Number(mostRecentMessage?.index) + increment) ??
        normalizedMessages.length + increment

    const messageDurationInSeconds =
      indexValue === 1
        ? differenceInSeconds(new Date(stepRun.createdAt), new Date())
        : differenceInSeconds(new Date(mostRecentMessage.createdAt), new Date())

    const extraInitialValues = config?.timePerMessageBaseVariableId
      ? [
          {
            baseVariableId: config.timePerMessageBaseVariableId as string,
            value: messageDurationInSeconds as number,
          },
        ]
      : []
    await createBaseRun({
      baseId: config.messagesBaseId as string,
      stepRunId: stepRun.id,
      parentBaseRunId: baseRun.id,
      initialValues: [
        {
          baseVariableId: config.typeBaseVariableId as string,
          value: type,
        },
        {
          baseVariableId: config.indexBaseVariableId as string,
          value: indexValue,
        },
        {
          baseVariableId: config.textBaseVariableId as string,
          value: text as TJson,
        },
        ...extraInitialValues,
      ],
    })
  }

  const insertNewMessage = async ({
    type,
    message,
    index,
  }: {
    type: string
    message: string | Record<string, unknown>
    index: number
  }) => {
    // Filters the list of messages AFTER the index to be inserted at. These messages' indices need to be incremented by 1.
    await handleShiftIndices(index, 'forward')
    await handleBaseRunCreation({ type, text: message, index })
  }

  const handleShiftIndices = async (index: number, direction: 'forward' | 'backward') => {
    const messagesToShift = normalizedMessages.filter((message) =>
      direction === 'forward' ? message.index >= index : message.index > index
    )

    const updateData = messagesToShift.map((message) => ({
      baseRunId: message.id,
      baseVariableId: config.indexBaseVariableId as string,
      value: direction === 'forward' ? message.index + 1 : message.index - 1,
    }))

    //We update the indices first and then insert the new message.
    await updateManyVariables({ stepRunId: stepRun.id, data: updateData })
  }

  const handleInsert = async () => {
    await insertNewMessage({
      type: turnTypeToBeInserted,
      message: messageToBeInserted as string,
      index: insertAtIndex as number,
    })

    setMessageToBeInserted(null)
    setInsertAtIndex(false)
  }

  const handleMessageSubmission = async () => {
    if (!inputText) return

    // If the last message is not yet saved, we don't allow the user to submit a new message.
    if (mostRecentMessage && mostRecentMessage.id === FAKE_MESSAGE_ID) {
      alertMessageNotYetSaved()
      return
    }

    let formattedMessageText = inputText

    if (enableTextBoxToggling && toggleState) {
      // Add valid markdown annotation.
      formattedMessageText = '```\n' + inputText + '\n```'
    }

    await handleBaseRunCreation({ type: currentTurn, text: formattedMessageText, increment: 1 })

    // We only enable submission after the last message of a turn is saved.
    dispatch({
      type: 'setReadyForSubmit',
      key: 'SFT2',
      value: config.messageTypes?.indexOf(currentTurn) === config.messageTypes?.length - 1,
    })
  }

  const alertMessageNotYetSaved = () => {
    addToast(
      `Last message is not yet saved. Please try submitting your Response again in a few seconds.`,
      {
        appearance: 'error',
      }
    )
  }

  return (
    <div className='relative box-border flex h-full w-full flex-col justify-between gap-4 rounded-md border border-solid border-gray-300 bg-white p-3'>
      {showName ? <div className='mb-2.5 font-bold'>{name}</div> : null}
      <div>{isLoading ? <span>Loading...</span> : null}</div>
      <div className='overflow-auto py-10'>
        {normalizedMessages?.map((message) => (
          <SingleTurn
            key={message.id}
            index={message.index}
            messageId={message.id}
            type={message.type}
            text={message.text}
            baseRunVariables={(messagesBaseRunVariables ?? []).filter(
              (b) => b.baseRunId === message.id
            )}
            config={config}
            conversationId={baseRun.id}
            mostRecentMessageId={normalizedMessages?.[normalizedMessages.length - 1]?.id}
            onInsertAfter={() => setInsertAtIndex(message.index + 1)}
            metadataValidationFailures={metadataValidationFailures}
            stepRunId={stepRun.id}
            wizardIsReadOnly={isReadOnly}
            shiftIndicesBackward={(index) => handleShiftIndices(index, 'backward')}
          />
        ))}
      </div>

      {!config.readOnly ? (
        <div className='flex flex-col gap-6'>
          {enableTextBoxToggling ? (
            <div className='flex items-center'>
              <p className='mr-2'>Plaintext</p>
              <Switch size='small' isOn={toggleState} onToggle={(value) => setToggleState(value)} />
              <p className='ml-2'>Code</p>
            </div>
          ) : null}
          <div className='flex items-center justify-between gap-6'>
            <div className='flex w-full items-center gap-2'>
              <div
                className={classNames(
                  'flex h-7 w-7 items-center justify-center rounded-full p-1',
                  config.messageTypes.indexOf(currentTurn) > config.messageTypes.length / 2
                    ? 'bg-pink-300'
                    : 'bg-primary'
                )}>
                {config.messageTypes.indexOf(currentTurn) > config.messageTypes.length / 2 ? (
                  <RobotHeadIcon className='h-6 w-6 text-white' />
                ) : (
                  <UserOutlineIcon className='h-5 w-5 text-white' />
                )}
              </div>
              {!toggleState ? (
                <TextArea
                  rows={4}
                  placeholder={`Type the ${currentTurn} here...`}
                  value={inputText ?? ''}
                  readOnly={isReadOnly}
                  onChange={(e) => setInputText(e.target.value)}
                  className='resize-none !rounded'
                  onPaste={(e) => {
                    if (config.disablePaste) {
                      e.preventDefault()
                      return false
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.ctrlKey && !isCreatingNewMessage) {
                      handleMessageSubmission()
                    }
                  }}
                />
              ) : (
                <div className='!w-[700px] resize-none !rounded border-2 border-solid border-indigo-200'>
                  <CodeWAC
                    readOnly={isReadOnly}
                    code={inputText}
                    onChangeMethod={(inputText) => setInputText(inputText)}
                  />
                </div>
              )}
              <Dropdown
                options={config.messageTypes?.map((type) => ({ key: type, value: type })) ?? []}
                maxHeight='200px'
                selectedKey={currentTurn}
                alignment='center'
                width='100%'
                disabled={isReadOnly}
                onChange={({ key }) => setCurrentTurn(key as string)}
              />

              <Button
                icon='RocketFilledIcon'
                size='md'
                variant='primary'
                shape='square'
                onClick={handleMessageSubmission}
                disabled={isCreatingNewMessage || isUpdatingManyVariables || isReadOnly}
              />
              {isCreatingNewMessage || isUpdatingManyVariables ? 'Saving...' : null}
            </div>
          </div>
        </div>
      ) : null}

      {insertAtIndex !== false ? (
        <Modal title='Insert Message Modal' width={700} onClose={() => setInsertAtIndex(false)}>
          <Dropdown
            options={config.messageTypes?.map((type) => ({ key: type, value: type })) ?? []}
            maxHeight='200px'
            selectedKey={turnTypeToBeInserted}
            alignment='center'
            width='100%'
            disabled={isReadOnly}
            onChange={({ key }) => setTurnTypeToBeInserted(key as string)}
          />
          <TextArea
            rows={4}
            placeholder='Enter Message to be inserted here...'
            value={messageToBeInserted as string}
            readOnly={isReadOnly}
            onChange={(e) => setMessageToBeInserted(e.target.value)}
            className='!w-[700px] resize-none !rounded'
            onPaste={(e) => {
              if (config.disablePaste) {
                e.preventDefault()
                return false
              }
            }}
          />
          <div className='mt-4 flex items-center gap-4'>
            <Button
              variant='primary'
              onClick={async () => handleInsert()}
              disabled={isCreatingNewMessage || isUpdatingManyVariables || isReadOnly}>
              Insert
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export { SFT2WAC }
