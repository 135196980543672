import { SVGProps } from 'react'
function StepTemplate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M29.5 2H6.5V5.51127L18.1164 18L6.5 30.4887V34H29.5V31.0909H9.766L21.9425 18L9.766 4.90909H29.5V2Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default StepTemplate
