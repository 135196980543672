import baseStyled, { ThemedStyledInterface } from 'styled-components'

import type { TTheme } from './lib/base-theme'

export * from './lib/base-theme'
export { theme as baseTheme } from './lib/base-theme'
export { default as dark } from './lib/dal3-dark-theme'
export { default as light } from './lib/dal3-light-theme'
export { useTheme } from './lib/hooks-use-theme'
export { SetThemeContext, default as ThemeProvider } from './lib/theme-provider'
export type { ThemedStyledInterface } from 'styled-components'
export { createGlobalStyle, css, keyframes, ServerStyleSheet, withTheme } from 'styled-components'
const styled = baseStyled as any as ThemedStyledInterface<TTheme>

export { styled }
