import { SVGProps } from 'react'
function FolderFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='23'
      viewBox='0 0 33 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.894 8.57025C31.6766 8.29104 31.3931 8.06386 31.066 7.90695C30.739 7.75003 30.3776 7.66774 30.0108 7.66669H28.1422V5.47623C28.1422 4.89528 27.898 4.33813 27.4633 3.92734C27.0287 3.51655 26.4392 3.28577 25.8245 3.28577H15.7859L11.7589 0.43817C11.3597 0.15016 10.8704 -0.00398408 10.3683 7.82852e-05H2.64739C2.0327 7.82852e-05 1.44318 0.230858 1.00852 0.641649C0.573869 1.05244 0.329683 1.60959 0.329683 2.19054V21.9047C0.327737 22.049 0.356383 22.1923 0.413932 22.326C0.471481 22.4597 0.556768 22.5812 0.664767 22.6832C0.772767 22.7853 0.901293 22.8659 1.04277 22.9203C1.18425 22.9747 1.33581 23.0018 1.48854 22.9999H26.9833C27.2273 22.9999 27.4651 22.9272 27.6628 22.7919C27.8605 22.6567 28.008 22.466 28.0842 22.2469L32.2126 10.5554C32.3273 10.2239 32.3582 9.87175 32.3029 9.52702C32.2475 9.18228 32.1075 8.8546 31.894 8.57025ZM10.3683 2.19054L14.3953 5.03814C14.7944 5.32615 15.2837 5.48029 15.7859 5.47623H25.8245V7.66669H6.96412C6.47787 7.6657 6.00361 7.80926 5.60846 8.07707C5.21331 8.34488 4.91729 8.72336 4.7623 9.15894L2.64739 15.1553V2.19054H10.3683Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FolderFilled
