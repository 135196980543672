import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { InfoFilledIcon, PlusIcon, TrashOutlineIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { Switch } from '@invisible/ui/switch'
import { TagInput } from '@invisible/ui/tag-input'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { debounce } from 'lodash/fp'
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import sanitize from 'sanitize-html'

import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps {
  index: number
  messageId: string
  type: string
  text: string
  baseRunVariables: TBaseRunVariables
  config: WizardSchemas.Orchestration2.TSchema
  orchestrationId: string
  mostRecentMessageId: string
  metadataValidationFailures: string[]
  stepRunId: string
  wizardIsReadOnly: boolean
  onInsertAfter: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleTurn: FC<IProps> = ({
  index,
  messageId,
  type,
  text,
  baseRunVariables,
  config,
  orchestrationId,
  mostRecentMessageId,
  metadataValidationFailures,
  stepRunId,
  wizardIsReadOnly,
  onInsertAfter,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [messageText, setMessageText] = useState(text)

  const messageRef = useRef<HTMLElement | null>(null)

  const [metadataFormData, setMetadataFormData] = useState<Record<string, unknown>>({})

  const reactQueryContext = useContext()

  // We remove "address" from the list of allowed HTML Tags so it can be escaped and rendered as is
  const defaultAllowedTags = sanitize.defaults.allowedTags
  const defaultTagindex = defaultAllowedTags.indexOf('address')

  if (defaultTagindex !== -1) {
    defaultAllowedTags.splice(defaultTagindex, 1)
  }

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
    onSuccess: (data) => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.messagesBaseId,
            parentBaseRunId: orchestrationId,
          },
        ],
        (prevData) => {
          if (!prevData) return
          return prevData.map((baseRun) =>
            baseRun.id === data[0].baseRunId
              ? {
                  ...baseRun,
                  baseRunVariables: baseRun.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
              : baseRun
          )
        }
      )
    },
  })

  const { mutateAsync: updateManyVariables, isLoading: isUpdatingManyVariables } =
    useBaseRunVariablesWizardUpdate({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
    })

  const { mutateAsync: deleteBaseRuns, isLoading: isDeleting } =
    useBaseRunDeleteWithStepRunReference({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
      },
      onSuccess: () => {
        reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config.messagesBaseId,
              parentBaseRunId: orchestrationId,
            },
          ],
          (prevData) => {
            if (!prevData) return
            return prevData.filter((baseRun) => baseRun.id !== messageId)
          }
        )
      },
    })

  useEffect(() => {
    if (isEditing) messageRef?.current?.focus()
  }, [messageRef])

  useEffect(() => {
    setMessageText(messageText)
  }, [messageText])

  useEffect(() => {
    // If the state is already set, then we don't reset it.
    if (Object.keys(metadataFormData).length > 0) return

    const initialMetadataFormValues: Record<string, unknown> = {}

    baseRunVariables.forEach((variable) => {
      initialMetadataFormValues[variable.baseVariableId as string] = variable.value
    })

    setMetadataFormData(initialMetadataFormValues)
  }, [baseRunVariables])

  const handleBlur = useCallback(
    async (value: string) => {
      setIsEditing(false)
      updateVariable({
        stepRunId,
        data: [
          {
            baseRunId: messageId,
            baseVariableId: config.textBaseVariableId as string,
            value,
          },
        ],
      })
    },
    [messageId, updateVariable]
  )

  const handleDelete = async () => {
    if (isDeleteModalOpen) {
      await deleteBaseRuns({ baseRunIds: [messageId], stepRunId: stepRunId })
    }
    setIsDeleteModalOpen(false)
  }

  const updateMetadata = async () => {
    const updateData = Object.keys(metadataFormData).map((key) => ({
      baseRunId: messageId,
      baseVariableId: key,
      value: metadataFormData[key] as string,
    }))

    await updateManyVariables({ stepRunId, data: updateData })
    setIsDetailsOpen(false)
  }

  return (
    <div className='mb-3'>
      <div className='group mb-1 flex items-center gap-1'>
        <div
          className={`max-w-1/2 relative  w-fit max-w-[50%] rounded-xl shadow ${
            type !== 'user_prompt' ? 'ml-auto mt-2' : ''
          } ${
            metadataValidationFailures.includes(messageId)
              ? 'bg-red-600'
              : type === 'user_prompt'
              ? 'bg-indigo-400'
              : type === 'bot_to_tool_request'
              ? 'bg-indigo-100'
              : type === 'tool_to_bot_response'
              ? 'bg-pink-100'
              : 'bg-pink-400'
          }`}>
          <ContentEditable
            className='whitespace-pre-line break-words rounded-xl px-4 py-2 text-sm'
            html={sanitize(
              (type === 'tool_to_bot_response' || type === 'bot_to_tool_request'
                ? JSON.stringify(messageText)
                : messageText) ?? '',
              { disallowedTagsMode: 'escape' }
            )}
            innerRef={messageRef}
            disabled={!isEditing || type === 'tool_to_bot_response' || wizardIsReadOnly}
            onChange={(e) => setMessageText(e.target.value)}
            onDoubleClick={() => setIsEditing(true)}
            onBlur={(e) => handleBlur(e.target.innerText)}
          />
          {!isEditing ? (
            <div className='absolute right-1 top-0 select-none p-1 text-[8px]'>{index}</div>
          ) : null}
        </div>
        <InfoFilledIcon
          className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
          onClick={() => setIsDetailsOpen(true)}
        />
        {!wizardIsReadOnly && mostRecentMessageId !== messageId && config.isReview ? (
          <PlusIcon
            className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
            onClick={() => onInsertAfter()}
          />
        ) : null}
        {!wizardIsReadOnly && (mostRecentMessageId === messageId || config.isReview) ? (
          <TrashOutlineIcon
            className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
            onClick={() => setIsDeleteModalOpen(true)}
          />
        ) : null}
      </div>

      <Modal
        visible={isDetailsOpen}
        title='Add Metadata'
        onClose={() => setIsDetailsOpen(false)}
        primaryButton={
          <Button
            variant='primary'
            size='md'
            disabled={wizardIsReadOnly}
            onClick={async () => {
              await updateMetadata()
            }}
            loading={isUpdatingManyVariables}>
            Update Metadata
          </Button>
        }>
        <div className='flex w-96 flex-col gap-4'>
          {(config.metadata ?? []).map((item) => {
            const value = metadataFormData ? metadataFormData[item.baseVariableId] : ''
            return (
              <div>
                <div className='mb-1'>{item.name}</div>
                {item.type === 'a_string' ? (
                  <TagInput
                    key={item.baseVariableId}
                    tags={(value ?? []) as string[]}
                    disabled={wizardIsReadOnly}
                    onChange={(value) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: value,
                      }))
                    }
                  />
                ) : item.type === 'boolean' ? (
                  <Switch
                    size='medium'
                    isOn={(value ?? false) as boolean}
                    disabled={wizardIsReadOnly}
                    onToggle={(value) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: value,
                      }))
                    }
                  />
                ) : item.type === 'string' ? (
                  <Input
                    key={item.baseVariableId}
                    defaultValue={(value ?? '') as string}
                    disabled={wizardIsReadOnly}
                    onChange={debounce(2000, (e: ChangeEvent<HTMLInputElement>) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: e.target.value,
                      }))
                    )}
                  />
                ) : (
                  <TextArea
                    width='100%'
                    key={item.baseVariableId}
                    value={(value ?? '') as string}
                    readOnly={wizardIsReadOnly}
                    onChange={(e) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: e.target.value,
                      }))
                    }
                  />
                )}
              </div>
            )
          })}
        </div>
      </Modal>
      <Modal
        visible={isDeleteModalOpen !== false}
        title={'Delete Last Message'}
        onClose={() => setIsDeleteModalOpen(false)}
        primaryButton={
          <Button
            variant='danger'
            loading={isDeleting}
            disabled={wizardIsReadOnly}
            onClick={() => handleDelete()}>
            Yes
          </Button>
        }
        secondaryButton={
          <Button variant='secondary' onClick={() => setIsDeleteModalOpen(false)}>
            No
          </Button>
        }>
        Are you sure you want to delete this last message?
      </Modal>
    </div>
  )
}

export { SingleTurn }
