import { SVGProps } from 'react'

function DiscoverFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M28.5378 0.99707H7.34297C6.0216 1.00113 4.75552 1.52111 3.82116 2.44349C2.8868 3.36587 2.36007 4.61571 2.35596 5.92015V31.7663C2.35596 32.0927 2.48731 32.4058 2.72112 32.6366C2.95493 32.8674 3.27205 32.9971 3.60271 32.9971H26.0443C26.3749 32.9971 26.692 32.8674 26.9259 32.6366C27.1597 32.4058 27.291 32.0927 27.291 31.7663C27.291 31.4399 27.1597 31.1268 26.9259 30.896C26.692 30.6652 26.3749 30.5355 26.0443 30.5355H4.84946C4.84946 29.8827 5.11217 29.2566 5.57979 28.795C6.04742 28.3333 6.68165 28.074 7.34297 28.074H28.5378C28.8684 28.074 29.1856 27.9443 29.4194 27.7135C29.6532 27.4827 29.7845 27.1696 29.7845 26.8432V2.22784C29.7845 1.90142 29.6532 1.58837 29.4194 1.35755C29.1856 1.12674 28.8684 0.99707 28.5378 0.99707V0.99707ZM24.7975 15.7663L20.8079 12.8125C20.7011 12.7301 20.5694 12.6854 20.4339 12.6854C20.2983 12.6854 20.1667 12.7301 20.0599 12.8125L16.0702 15.7663V3.45861H24.7975V15.7663Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DiscoverFilled
