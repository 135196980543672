import { UuidSchema, z } from '@invisible/zod'
import { assign } from 'lodash'
import { isUndefined, omitBy } from 'lodash/fp'

import { schema as BaseVariableMappingSchema } from './BaseVariableMapping'

const schema = z
  .object({
    inputArrayBaseVariableId: UuidSchema.nullish(),
    childBaseId: UuidSchema,
    baseVariableMappings: z.array(BaseVariableMappingSchema).default([]).optional(),
    createStepRun: z.boolean().default(false).optional(),
    createBaseRun: z.boolean().default(true).optional(),
    createEndMapStep: z.boolean().default(true).optional(),
  })
  .strict()
type TSchema = z.infer<typeof schema>

const createArgsSchema = schema.strip()
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates MapStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = schema.partial().strip()
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates MapStepMeta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({ mapStepMeta, data }: { mapStepMeta: TSchema; data: TUpdateArgs }): void => {
  const filteredData = omitBy(isUndefined, data)
  const updateArgs = updateArgsSchema.parse(filteredData)
  assign(mapStepMeta, updateArgs)
}

export { create, createArgsSchema, schema, update, updateArgsSchema }
export type { TCreateArgs, TSchema, TUpdateArgs }
