import { Skeleton } from '@invisible/ui/skeleton'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { Box } from 'rebass'

const rowsArray = [...Array(30).keys()]

export const BasePageSkeleton = () => (
  <div>
    <Box p={3} pb={0}>
      <Tabs onChange={() => undefined} orientation='horizontal' size='medium' value={'loading...'}>
        {[<Tab name={'Loading'} value={'loading...'} key={'loading...'}></Tab>]}
      </Tabs>
      <Box mt={52}>
        {rowsArray.map((row) => (
          <Box height={52} key={row}>
            <Skeleton.Rectangle width={1500} height={50} />
          </Box>
        ))}
      </Box>
    </Box>
  </div>
)
