import { convertSecondsToHoursMinutesSeconds } from '@invisible/common/date'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { TextArea } from '@invisible/ui/form'
import { SmallCheckbox } from '@invisible/ui/form'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { useToasts } from '@invisible/ui/toasts'
import type { PrismaAll } from '@invisible/ultron/prisma'
import { ChangeEvent, FC, useState } from 'react'

import { TimeInput } from './CommonComponents'
import { getDurationInHours, getDurationInSeconds } from './helpers'

export const categories = [
  { id: 'management_cost', name: 'Management Cost' },
  { id: 'incentive', name: 'Incentive' },
  { id: 'off_platform_work', name: 'Off Platform Work' },
  { id: 'on_platform_work', name: 'On Platform Work' },
  { id: 'adjustment', name: 'Adjustment' },
] as const

interface ManualBillEventModalProps {
  bills?: PrismaAll.Bill[]
  processId: string
  startDate: Date
  endDate: Date
  showModal: boolean
  closeModal: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ManualBillEventModal: FC<ManualBillEventModalProps> = ({
  bills,
  processId,
  startDate,
  endDate,
  showModal,
  closeModal,
}) => {
  const [activeTab, setActiveTab] = useState('Results')
  const handleTabChange = (value: string | number) => {
    setActiveTab(value as string)
  }
  const { data: users, isLoading: isFetchingUsers } = useQuery(['user.getAllInvisibleUsers'], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const { data: commodities, isLoading: isFetchingCommodities } = useQuery(
    ['commodity.findManyPaymentCommoditiesForProcessWithManual', { processId }],
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Modal
      title='Create Cost'
      icon='BillingIcon'
      visible={showModal}
      onClose={closeModal}
      minWidth='150px'
      className='my-1 max-h-[100vh] overflow-y-auto'>
      <div className='border-weak flex w-full border-0 border-b border-solid'>
        <div className='w-full translate-y-[1px]'>
          <Tabs orientation='horizontal' size='small' value={activeTab} onChange={handleTabChange}>
            <Tab name='Results Based' value='Results' />
            <Tab name='Time Based' value='Time' />
          </Tabs>
        </div>
      </div>

      <div className='mt-4 w-full'>
        {isFetchingUsers || isFetchingCommodities ? (
          <div>Loading...</div>
        ) : activeTab === 'Results' ? (
          <ResultsBasedTabComponent
            bills={bills}
            processId={processId}
            commodities={commodities}
            users={users}
            startDate={startDate}
            endDate={endDate}
            closeModal={closeModal}
          />
        ) : activeTab === 'Time' ? (
          <TimeBasedTabComponent
            bills={bills}
            processId={processId}
            commodities={commodities}
            users={users}
            startDate={startDate}
            endDate={endDate}
            closeModal={closeModal}
          />
        ) : null}
      </div>
    </Modal>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BasicConfigurations: FC<{
  commodities?: PrismaAll.Commodity[]
  users?: PrismaAll.User[]
  userId?: string
  setUserId?: (userId: string) => void
  setManualCommodityName?: (commodityName: string) => void
  setCategory?: (categoryName: string) => void
}> = ({ commodities, users, userId, setUserId, setManualCommodityName, setCategory }) => (
  <div className='space-y-4'>
    <div className='space-y-1'>
      <label className='font-bold'>User</label>
      <Dropdown
        name='Select a user'
        width='350px'
        options={users ? users.map((user) => ({ key: user.id, value: user.name ?? '' })) : []}
        onChange={({ key }) => setUserId?.(key)}
        selectedKey={userId}
        isVirtualized
      />
    </div>
    <div className='space-y-1'>
      <label className='font-bold'>Name/Title of Commodity</label>
      <Dropdown
        name='Select a commodity'
        width='350px'
        options={
          commodities
            ? commodities.map((commodity) => ({ key: commodity.id, value: commodity.name }))
            : []
        }
        custom
        onChange={({ value }) => setManualCommodityName?.(value as string)}
      />
    </div>
    <div className='space-y-1'>
      <label className='font-bold'>Set a Category</label>
      <Dropdown
        name='Select a category'
        width='350px'
        options={categories.map((category) => ({ key: category.id, value: category.name }))}
        custom
        onChange={({ key }) => setCategory?.(key as string)}
      />
    </div>
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const ResultsBasedTabComponent: FC<{
  bills?: PrismaAll.Bill[]
  processId: string
  commodities?: PrismaAll.Commodity[]
  users?: PrismaAll.User[]
  startDate: Date
  endDate: Date
  closeModal: () => void
}> = ({ bills, processId, commodities, users, startDate, endDate, closeModal }) => {
  const reactQueryClient = useContext()
  const { addToast } = useToasts()
  const [userId, setUserId] = useState<string>()
  const [rate, setRate] = useState<number>(0)
  const [quantity, setQuantity] = useState<number>()
  const [manualCommodityName, setManualCommodityName] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [deliveredAt, setDeliveredAt] = useState<Date | null>(null)
  const [showError, setShowError] = useState(false)

  const { mutateAsync: createManualBillEvent, isLoading: isCreatingManualBillEvent } = useMutation(
    'billEvent.createManual',
    {
      onSuccess: () => {
        addToast('Bill Event Created', { appearance: 'success' })
        closeModal()
      },
      onError: (error) => {
        addToast(error?.message, { appearance: 'error' })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
      },
    }
  )

  return (
    <div className='space-y-4'>
      <BasicConfigurations
        commodities={commodities}
        users={users}
        userId={userId}
        setUserId={setUserId}
        setManualCommodityName={setManualCommodityName}
        setCategory={setCategory}
      />

      <div className='space-y-1'>
        <div className='flex w-full items-center gap-1'>
          <Input
            placeholder='Rate'
            addOnLeft='$'
            width='160px'
            type='number'
            step='any'
            value={String(rate)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRate(Number(e.target.value))}
          />
          <div>x</div>
          <Input
            placeholder='Quantity'
            addOnRight='Unit(s)'
            width='160px'
            type='number'
            step='any'
            value={String(quantity)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='block font-bold'>Total</label>
        <span className='text-sm text-gray-400'>${((rate ?? 0) * (quantity ?? 0)).toFixed(3)}</span>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>This work was originally delivered on (optional)</label>
        <div>
          <DateTimePicker
            fullWidth
            hideTime
            value={deliveredAt}
            onChange={(date) => setDeliveredAt(date)}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Notes</label>
        <TextArea
          placeholder='Please add context'
          rows={5}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {showError ? (
          <span className='text-xs text-red-400'>
            *Description must be min 7 & max 140 characters long.
          </span>
        ) : null}
      </div>

      <div className='flex items-center justify-end gap-2'>
        <Button size='md' variant='secondary' onClick={closeModal}>
          Cancel
        </Button>

        <Button
          size='md'
          variant='primary'
          loading={isCreatingManualBillEvent}
          onClick={async () => {
            if (description.trim().length < 7 || description.trim().length > 140) {
              setShowError(true)
              return
            }
            await createManualBillEvent({
              userId: userId ?? '',
              billId: bills?.find(
                (bill) => bill.userId === userId && bill.paymentStatus !== 'drafted'
              )?.id,
              processId,
              description,
              category,
              customRate: rate,
              customMultiplier: quantity,
              manualCommodityName,
              isTimeBased: false,
              deliveredAt: deliveredAt ? deliveredAt : undefined,
              startDate,
              endDate,
            })
          }}>
          Create
        </Button>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TimeBasedTabComponent: FC<{
  bills?: PrismaAll.Bill[]
  processId: string
  commodities?: PrismaAll.Commodity[]
  users?: PrismaAll.User[]
  startDate: Date
  endDate: Date
  closeModal: () => void
}> = ({ bills, processId, commodities, users, startDate, endDate, closeModal }) => {
  const reactQueryClient = useContext()
  const { addToast } = useToasts()
  const [userId, setUserId] = useState<string>()
  const [rate, setRate] = useState(0)
  const [manualCommodityName, setManualCommodityName] = useState<string>()
  const [useAgentDefaultRate, setUseAgentDefaultRate] = useState(false)
  const [description, setDescription] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [deliveredAt, setDeliveredAt] = useState<Date | null>(null)
  const [customMultiplier, setCustomMultiplier] = useState<number>()
  const [showError, setShowError] = useState(false)

  const { isLoading: isFetchingAgentDefaultRate } = useQuery(
    ['agent.getAgentDefaultRate', { userId: userId ?? '' }],
    {
      enabled: useAgentDefaultRate && !!userId,
      onSuccess: (data) => setRate((data?.rate ?? 0) / 1000),
      onError: (error) => {
        setRate(0)
        addToast(error?.message, { appearance: 'error' })
      },
    }
  )

  const { mutateAsync: createManualBillEvent, isLoading: isCreatingManualBillEvent } = useMutation(
    'billEvent.createManual',
    {
      onSuccess: () => {
        addToast('Bill Event Created', { appearance: 'success' })
        closeModal()
      },
      onError: (error) => {
        addToast(error?.message, { appearance: 'error' })
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('billEvent.getCycleBreakdownForProcess')
      },
    }
  )

  return (
    <div className='space-y-4'>
      <BasicConfigurations
        commodities={commodities}
        users={users}
        userId={userId}
        setUserId={setUserId}
        setManualCommodityName={setManualCommodityName}
        setCategory={setCategory}
      />

      <div className='space-y-1'>
        <label className='font-bold'>Rate</label>
        <Input
          width='130px'
          placeholder='Rate'
          addOnLeft='$'
          type='number'
          step='any'
          value={String(rate)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setRate(Number(e.target.value))}
          disabled={useAgentDefaultRate}
        />
        <div>
          <SmallCheckbox checked={useAgentDefaultRate} onClick={setUseAgentDefaultRate}>
            <span className='text-sm capitalize'>Use agent default rate</span>
          </SmallCheckbox>
        </div>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Duration</label>
        <TimeInput
          hour={convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).hours}
          minute={convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).minutes}
          onChange={(hour, minute) => setCustomMultiplier(getDurationInSeconds(hour, minute))}
        />
      </div>

      <div className='space-y-1'>
        <label className='block font-bold'>Total</label>
        {isFetchingAgentDefaultRate ? (
          <span className='text-sm text-gray-400'>Fetching agent's default rate...</span>
        ) : (
          <span className='text-sm text-gray-400'>
            $
            {(
              (rate ?? 0) *
              getDurationInHours(
                String(convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).hours),
                String(convertSecondsToHoursMinutesSeconds(customMultiplier ?? 0).minutes)
              )
            ).toFixed(3)}
          </span>
        )}
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>This work was originally delivered on (optional)</label>
        <div>
          <DateTimePicker
            fullWidth
            hideTime
            value={deliveredAt}
            onChange={(date) => setDeliveredAt(date)}
          />
        </div>
      </div>

      <div className='space-y-1'>
        <label className='font-bold'>Notes</label>
        <TextArea
          placeholder='Please add context'
          rows={5}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {showError ? (
          <span className='text-xs text-red-400'>
            *Description must be min 7 & max 140 characters long.
          </span>
        ) : null}
      </div>

      <div className='flex items-center justify-end gap-2'>
        <Button size='md' variant='secondary' onClick={closeModal}>
          Cancel
        </Button>

        <Button
          size='md'
          variant='primary'
          loading={isCreatingManualBillEvent}
          onClick={async () => {
            if (description.trim().length < 7 || description.trim().length > 140) {
              setShowError(true)
              return
            }

            if (category === '') {
              addToast('Please select a category', { appearance: 'error' })
              return
            }

            await createManualBillEvent({
              userId: userId ?? '',
              billId: bills?.find((bill) => bill.userId === userId)?.id,
              processId,
              description,
              category,
              customRate: rate * 1000, // Pass rate as multiple of a thousand as it is converted to unit for time based
              customMultiplier,
              manualCommodityName,
              isTimeBased: true,
              useAgentDefaultRate,
              deliveredAt: deliveredAt ? deliveredAt : undefined,
              startDate,
              endDate,
            })
          }}>
          Create
        </Button>
      </div>
    </div>
  )
}

export { ManualBillEventModal }
