import { z } from '@invisible/zod'

import { FORMULA_NAMES } from '../../../constants/formula'
import { booleanComparisonSchema, booleanUnaryPredicateSchema } from '../boolean'
import { numericComparisonSchema, numericUnaryPredicateSchema } from '../numeric'
import { stringComparisonSchema, stringUnaryPredicateSchema } from '../string'

/**
 * An `eq` formula. Takes two arguments, returns a boolean if they are equal.
 */
const eqFormulaSchema = z.union([
  numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.EQ) }),
  stringComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.EQ) }),
  booleanComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.EQ) }),
])

/**
 * An `eq` formula. Takes two arguments, returns a boolean if they are equal.
 */
type TEqFormula = z.infer<typeof eqFormulaSchema>

/**
 * A `neq` formula. Takes two arguments, returns a boolean if they are not equal.
 */
const neqFormulaSchema = z.union([
  numericComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.NEQ) }),
  stringComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.NEQ) }),
  booleanComparisonSchema.extend({ name: z.literal(FORMULA_NAMES.NEQ) }),
])

/**
 * A `neq` formula. Takes two arguments, returns a boolean if they are not equal.
 */
type TNeqFormula = z.infer<typeof neqFormulaSchema>

/**
 * An `isNull` formula. Takes one argument, returns boolean if it is null.
 */
const isNullFormulaSchema = z.union([
  numericUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NULL) }),
  stringUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NULL) }),
  booleanUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NULL) }),
])

/**
 * An `isNull` formula. Takes one argument, returns boolean if it is null.
 */
type TIsNullFormula = z.infer<typeof isNullFormulaSchema>

/**
 * An `isNotNull` formula. Takes one argument, returns boolean if it is not null.
 */
const isNotNullFormulaSchema = z.union([
  numericUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NOT_NULL) }),
  stringUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NOT_NULL) }),
  booleanUnaryPredicateSchema.extend({ name: z.literal(FORMULA_NAMES.IS_NOT_NULL) }),
])

/**
 * An `isNotNull` formula. Takes one argument, returns boolean if it is not null.
 */
type TIsNotNullFormula = z.infer<typeof isNotNullFormulaSchema>

/**
 * All defined generic predicates (comparing two string, number, boolean, or checking if null).
 * This schema differs from the generic predicateSchema.
 */
const definedGenericPredicateSchema = z.union([
  eqFormulaSchema,
  neqFormulaSchema,
  isNullFormulaSchema,
  isNotNullFormulaSchema,
])

/**
 * All defined generic predicates (comparing two string, number, boolean, or checking if null).
 * This schema differs from the generic predicateSchema.
 */
type TDefinedGenericPredicate = z.infer<typeof definedGenericPredicateSchema>

export {
  definedGenericPredicateSchema,
  eqFormulaSchema,
  isNotNullFormulaSchema,
  isNullFormulaSchema,
  neqFormulaSchema,
}
export type { TDefinedGenericPredicate, TEqFormula, TIsNotNullFormula, TIsNullFormula, TNeqFormula }
