import { SVGProps } from 'react'
function GoogleSheetEdit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M3.24343 3.37756H17.3292V14.0314C17.3292 15.4741 18.4551 16.6466 19.8405 16.6466H30.0712V31.6043L33.3146 28.497V12.0048L20.7626 0H2.54841C1.14447 0 0 1.1918 0 2.6538V42.4415C0 43.9035 1.14447 45.0953 2.54841 45.0953H22.2684L22.6113 41.7177H3.24343V3.37756ZM29.8349 13.269H20.5726V4.40531L29.8349 13.269Z'
        fill='currentColor'
      />
      <path
        d='M6.3154 37.0133H26.7861V22.3064H6.3154V37.0133ZM9.55883 31.3486H14.929V33.6357H9.55883V31.3486ZM18.1724 33.6357V31.3486H23.5426V33.6357H18.1724ZM23.5426 27.9711H18.1724V25.684H23.5426V27.9711ZM14.929 25.684V27.9711H9.55883V25.684H14.929Z'
        fill='currentColor'
      />
      <path
        d='M39.9219 24.4873L24.7056 40.3329L24.1589 45.6067C24.094 46.2388 24.2932 46.8661 24.7102 47.3245C25.1041 47.7587 25.6462 48 26.2208 48C26.2532 48 26.2903 48 26.3227 47.9952L31.7253 47.7153L46.9787 31.8311L39.9219 24.4873ZM30.3121 44.4053L27.5274 44.5501L27.8054 41.877L39.9173 29.2642L42.3823 31.8311L30.3121 44.4053Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GoogleSheetEdit
