import { SVGProps } from 'react'
function DirectionIconUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.8498 6.60469L12.232 1.54576C12.2214 1.51191 12.2003 1.48235 12.1717 1.46144C12.1431 1.44054 12.1085 1.4294 12.073 1.42969H10.6909C10.6177 1.42969 10.5552 1.47612 10.532 1.54576L8.9016 6.60469C8.89624 6.62076 8.89267 6.63862 8.89267 6.65648C8.89267 6.74755 8.96767 6.82255 9.05874 6.82255H10.0659C10.1409 6.82255 10.2052 6.77255 10.2266 6.70112L10.5391 5.60112H12.1284L12.4373 6.69934C12.457 6.77077 12.523 6.82077 12.598 6.82077H13.6909C13.7087 6.82077 13.7248 6.81898 13.7409 6.81362C13.7837 6.79934 13.8177 6.77077 13.8391 6.73148C13.8587 6.69219 13.8623 6.64755 13.8498 6.60469V6.60469ZM10.7016 4.67076L11.2873 2.58326H11.3998L11.973 4.67076H10.7016V4.67076ZM13.2641 13.4726H10.9498V13.4654L13.3177 10.0922C13.3373 10.0636 13.348 10.0315 13.348 9.99577V9.34577C13.348 9.25469 13.273 9.17969 13.182 9.17969H9.5391C9.44803 9.17969 9.37303 9.25469 9.37303 9.34577V10.1136C9.37303 10.2047 9.44803 10.2797 9.5391 10.2797H11.7284V10.2868L9.3516 13.6601C9.33176 13.6883 9.32116 13.722 9.32124 13.7565V14.4065C9.32124 14.4976 9.39624 14.5726 9.48732 14.5726H13.2623C13.3534 14.5726 13.4284 14.4976 13.4284 14.4065V13.6386C13.4286 13.6169 13.4245 13.5953 13.4164 13.5752C13.4082 13.5551 13.3962 13.5367 13.3809 13.5213C13.3656 13.5059 13.3474 13.4936 13.3274 13.4852C13.3073 13.4769 13.2858 13.4726 13.2641 13.4726V13.4726ZM4.39803 1.84219C4.38467 1.82512 4.36759 1.81131 4.3481 1.80181C4.32861 1.79232 4.30721 1.78738 4.28553 1.78738C4.26385 1.78738 4.24245 1.79232 4.22296 1.80181C4.20347 1.81131 4.18639 1.82512 4.17303 1.84219L2.17303 4.37612C2.09803 4.47076 2.16589 4.60826 2.28553 4.60826H3.64267V14.0726C3.64267 14.1511 3.70696 14.2154 3.78553 14.2154H4.78553C4.8641 14.2154 4.92839 14.1511 4.92839 14.0726V4.60826H6.28553C6.40517 4.60826 6.47303 4.46898 6.39803 4.37612L4.39803 1.84219Z'
        fill={props.fill ?? '#604CA5'}
      />
    </svg>
  )
}

export default DirectionIconUp
