import { styled } from '@invisible/ui/themes'
import { FC, useEffect, useState } from 'react'
import { Flex, FlexProps } from 'rebass'

const checkboxPath = `
  M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17L3.5,17 C2,17 1,16 1,14.5 L1,9 Z
`
interface IToggleProps {
  isDisabled: boolean | undefined
  color?: string
  checkColor?: string
}
// eslint-disable-next-line @typescript-eslint/ban-types
const Toggle = styled(Flex as FC<FlexProps>)<IToggleProps>`
  align-items: center;

  > label {
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    position: relative;
    margin-right: 10px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  > label:before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(34, 50, 84, 0.03);
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  > label svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${(props) => (props.isDisabled ? props.theme.grayScale[8] : '#c8ccd4')};
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    width: 25px;
    height: 25px;
  }
  > label svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  > label svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  > label:hover:before {
    opacity: 1;
  }
  > label:hover svg {
    stroke: ${(props) =>
      props.isDisabled ? props.theme.grayScale[8] : props.color ? props.color : '#542cea'};
  }
  > label.checked svg {
    background: ${(props) => (props.color ? props.color : '')};
    stroke: ${(props) => (props.checkColor ? props.checkColor : '#542cea')};
  }
  > label.checked svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  > label.checked svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
`

interface ICheckbox extends FlexProps {
  onToggle: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
  color?: string
  checkColor?: string
}

const Checkbox = ({ onToggle, checked, disabled, children, color, checkColor }: ICheckbox) => {
  const [isChecked, toggleChecked] = useState(checked || false)
  const isControlled = checked !== undefined
  const isDisabled = disabled
  const handleChange = async () => {
    if (isDisabled) return
    const newState = !isChecked
    if (isControlled) {
      onToggle(newState)
    } else {
      toggleChecked(newState)
    }
  }

  useEffect(() => {
    if (!isControlled) onToggle(isChecked)
  }, [isChecked, isControlled])

  useEffect(() => {
    if (checked !== undefined) toggleChecked(checked)
  }, [checked])

  return (
    <Toggle isDisabled={isDisabled} color={color} checkColor={checkColor}>
      <label className={isChecked ? 'checked' : ''}>
        <input
          type='checkbox'
          aria-disabled={isDisabled}
          style={{ display: 'none' }}
          onChange={handleChange}
          checked={isChecked}
        />
        <svg width='38px' height='38px' viewBox='0 0 18 18'>
          <path d={checkboxPath} />
          <polyline points='1 9 7 14 15 4' />
        </svg>
      </label>
      <span>{children}</span>
    </Toggle>
  )
}

export { Checkbox, checkboxPath, Toggle }
