import { SVGProps } from 'react'
function AttachFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.96855 9.3957C7.94506 9.37244 7.91334 9.35939 7.88027 9.35939C7.84721 9.35939 7.81549 9.37244 7.79199 9.3957L5.97637 11.2113C5.13574 12.052 3.71699 12.141 2.78887 11.2113C1.85918 10.2816 1.94824 8.86445 2.78887 8.02383L4.60449 6.2082C4.65293 6.15977 4.65293 6.08008 4.60449 6.03164L3.98262 5.40977C3.95912 5.3865 3.9274 5.37345 3.89434 5.37345C3.86127 5.37345 3.82955 5.3865 3.80605 5.40977L1.99043 7.22539C0.668555 8.54727 0.668555 10.6863 1.99043 12.0066C3.3123 13.327 5.45137 13.3285 6.77168 12.0066L8.5873 10.191C8.63574 10.1426 8.63574 10.0629 8.5873 10.0145L7.96855 9.3957ZM12.0092 1.98945C10.6873 0.667578 8.54824 0.667578 7.22793 1.98945L5.41074 3.80508C5.38748 3.82857 5.37443 3.8603 5.37443 3.89336C5.37443 3.92642 5.38748 3.95815 5.41074 3.98164L6.03105 4.60195C6.07949 4.65039 6.15918 4.65039 6.20762 4.60195L8.02324 2.78633C8.86387 1.9457 10.2826 1.85664 11.2107 2.78633C12.1404 3.71602 12.0514 5.1332 11.2107 5.97383L9.39512 7.78945C9.37185 7.81295 9.3588 7.84467 9.3588 7.87774C9.3588 7.9108 9.37185 7.94252 9.39512 7.96602L10.017 8.58789C10.0654 8.63633 10.1451 8.63633 10.1936 8.58789L12.0092 6.77227C13.3295 5.45039 13.3295 3.31133 12.0092 1.98945ZM8.53262 4.81602C8.50912 4.79275 8.4774 4.7797 8.44434 4.7797C8.41127 4.7797 8.37955 4.79275 8.35605 4.81602L4.81699 8.35352C4.79373 8.37701 4.78068 8.40874 4.78068 8.4418C4.78068 8.47486 4.79373 8.50659 4.81699 8.53008L5.43574 9.14883C5.48418 9.19727 5.56387 9.19727 5.61231 9.14883L9.1498 5.61133C9.19824 5.56289 9.19824 5.4832 9.1498 5.43477L8.53262 4.81602Z'
        fill='#423F4C'
      />
    </svg>
  )
}

export default AttachFile
