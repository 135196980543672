import { SVGProps } from 'react'
function Bicycle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='22'
      height='20'
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.25 6.25C17.25 5.91848 17.1183 5.60054 16.8839 5.36612C16.6495 5.1317 16.3315 5 16 5H12.875L17.25 12.5'
        stroke='#42BAFE'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.25 15.625C18.9759 15.625 20.375 14.2259 20.375 12.5C20.375 10.7741 18.9759 9.375 17.25 9.375C15.5241 9.375 14.125 10.7741 14.125 12.5C14.125 14.2259 15.5241 15.625 17.25 15.625Z'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.75 15.625C6.47589 15.625 7.875 14.2259 7.875 12.5C7.875 10.7741 6.47589 9.375 4.75 9.375C3.02411 9.375 1.625 10.7741 1.625 12.5C1.625 14.2259 3.02411 15.625 4.75 15.625Z'
        stroke='black'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.75 5H6.9375L11.3125 12.5'
        stroke='#42BAFE'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.2891 7.5H8.39844L4.75 12.5'
        stroke='#42BAFE'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default Bicycle
