import { RRule } from 'rrule';
import { z } from 'zod';

import {
    FREQUENCIES,
    FREQUENCIES_PLURAL,
    FREQUENCY_SCHEMA,
    SCHEDULE_TIMEZONE,
    WEEKDAY_SCHEMA,
    WEEKDAYS,
} from './SchedulerConstants';

// Define Zod schema
const schedulerSchema = z.object({
    freq: FREQUENCY_SCHEMA,
    interval: z.number().min(1).default(1).nullable(),
    dtstart: z.date().default(new Date()),
    byweekday: WEEKDAY_SCHEMA,
    byhour: z.number().min(0).max(23).default(12),
    byminute: z.number().min(0).max(59).default(0),
    until: z.date().nullish(),
    count: z.number().nullish(),
    tzid: z.string().default(SCHEDULE_TIMEZONE),
    taskSchedule: z.string().default('default'),
    isCustom: z.boolean().default(false),
    description: z.string().nullish(),
    rruleString: z.string().nullish(),
    endCondition: z.string().optional(),
    monthOption: z.string().nullish(),
    bymonthday: z.array(z.number()).nullish(),
    bysetpos: z.array(z.number()).nullish(),
});

// Capitalize the recursion rule description
const capitalizeString = (input: string | undefined) =>
    input ? input.charAt(0).toUpperCase() + input.slice(1) : input;

// Get the frequencies based on the interval
const getFrequencies = (interval: number | null) => {
    if (interval === null) return FREQUENCIES;
    return interval === 1 ? FREQUENCIES : FREQUENCIES_PLURAL
}

const getRRuleWeekday = (index: number) =>
    // Map JavaScript's getDay() index to RRule's weekday index
    index === 0 ? 6 : index - 1;

/**
     * Returns the ordinal suffix for a given number.
     * @param n - The number for which to get the ordinal suffix.
     * @returns The ordinal suffix as a string.
     */
const getOrdinalSuffix = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
};

/**
 * Returns the text representation of a given RRule.
 * @param rule - The RRule object.
 * @returns The text representation of the RRule.
 */
const getText = (rule: RRule) => {
    const dtstart = rule.options.dtstart.toLocaleString('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    if ( rule.options.count === 1 ) {
        return `Runs once on ${dtstart} UTC`;
    };

    let ruleText = rule.toText().replace(/ at \d{1,2}/, '');

    if ( rule.options.bysetpos ) {
        const offset = rule.options.bysetpos[0] % 100;
        const suffix = getOrdinalSuffix(offset);
        Object.keys(WEEKDAYS).forEach((day) => {
            if ( ruleText.includes(day) ) {
                ruleText = ruleText.replace(new RegExp(day, 'i'), `the ${offset}${suffix} ${day}`);
                return `${ruleText} starting on ${dtstart} UTC`;
            };
        });
    };

    return `${ruleText} starting on ${dtstart} UTC`;
};

export { capitalizeString, getFrequencies, getOrdinalSuffix, getRRuleWeekday, getText, schedulerSchema }
