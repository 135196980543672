import { SVGProps } from 'react'
function BoxIncOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='73'
      height='40'
      viewBox='0 0 73 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M72.3564 35.5224C73.2677 36.8175 73.0854 38.4826 71.9919 39.4077C70.7161 40.3328 68.8935 40.1478 67.9822 39.0377L61.6032 30.7121L55.4064 38.8527C54.4951 40.1478 52.6725 40.1478 51.3967 39.2227C50.1209 38.2976 49.9387 36.6325 50.8499 35.3374L58.1403 25.7168L50.8499 16.0961C49.9387 14.801 50.3032 12.9509 51.3967 12.0258C52.6725 11.1008 54.4951 11.4708 55.4064 12.5809L61.6032 20.9064L67.9822 12.9509C68.8935 11.6558 70.5338 11.4708 71.9919 12.3959C73.2677 13.3209 73.2677 15.171 72.3564 16.4661L65.2483 25.9018L72.3564 35.5224ZM39.1854 34.4124C34.4467 34.4124 30.6193 30.7121 30.6193 25.9018C30.6193 21.2765 34.4467 17.3912 39.1854 17.3912C43.9241 17.3912 47.7516 21.2765 47.7516 25.9018C47.5693 30.7121 43.7419 34.4124 39.1854 34.4124ZM14.0339 34.4124C9.29515 34.4124 5.46774 30.7121 5.46774 25.9018C5.46774 21.2765 9.29515 17.3912 14.0339 17.3912C18.7726 17.3912 22.6 21.2765 22.6 25.9018C22.6 30.7121 18.7726 34.4124 14.0339 34.4124ZM39.1854 11.8408C33.9 11.8408 29.1613 14.801 26.7919 19.2413C24.4226 14.801 19.6839 11.8408 14.2161 11.8408C10.9355 11.8408 8.01935 12.9509 5.64999 14.616V2.77519C5.64999 1.29509 4.37419 0 2.91613 0C1.27581 0 0 1.29509 0 2.77519V26.0868C0.182258 33.8573 6.37903 39.9627 14.0339 39.9627C19.5016 39.9627 24.2403 36.8175 26.6097 32.3772C28.979 36.8175 33.7177 39.9627 39.0032 39.9627C46.8403 39.9627 53.2193 33.6723 53.2193 25.7168C53.4016 18.1312 47.0225 11.8408 39.1854 11.8408Z'
        fill='#0061D5'
      />
    </svg>
  )
}

export default BoxIncOriginal
