import { SVGProps } from 'react'
function ExportFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='27'
      height='21'
      viewBox='0 0 27 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M25.2566 0.529053H1.25659C0.991375 0.529053 0.737021 0.63441 0.549485 0.821946C0.361949 1.00948 0.256592 1.26384 0.256592 1.52905V18.5291C0.256592 19.0595 0.467305 19.5682 0.842378 19.9433C1.21745 20.3183 1.72616 20.5291 2.25659 20.5291H24.2566C24.787 20.5291 25.2957 20.3183 25.6708 19.9433C26.0459 19.5682 26.2566 19.0595 26.2566 18.5291V1.52905C26.2566 1.26384 26.1512 1.00948 25.9637 0.821946C25.7762 0.63441 25.5218 0.529053 25.2566 0.529053ZM9.59409 10.5291L2.25659 17.2541V3.80405L9.59409 10.5291ZM11.0691 11.8916L12.5816 13.2666C12.7659 13.4352 13.0067 13.5287 13.2566 13.5287C13.5064 13.5287 13.7472 13.4352 13.9316 13.2666L15.4441 11.8916L22.6816 18.5291H3.83159L11.0691 11.8916ZM16.9191 10.5291L24.2566 3.80405V17.2541L16.9191 10.5291Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ExportFilled
