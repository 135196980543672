import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { Pagination } from '@invisible/ui/pagination'
import { ReactTable } from '@invisible/ui/react-table'
import { TableStyles } from '@invisible/ui/table'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { flow, map, reduce } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from 'rebass'

import { HeaderCellCustomSort } from '../../baseViewComponents/HeaderCell'
import { DEFAULT_ITEMS_PER_PAGE } from '../../common/constants'
import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { baseRunIdHook, getChildBaseViewWACCustomCell, rowNumbersHook } from './columnHooks'

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

const calculateTextWidth = (text: string, font = 14) => (font * text.length) / 2
const columnHelper = createColumnHelper<Record<string, any>>()

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun
  isReadOnly: boolean
}

export const ChildBaseViewWAC = ({
  showName,
  name,
  childBaseView,
  stepRun,
  isReadOnly,
}: IProps) => {
  const reactQueryContext = useContext()
  const [currentPage, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    childBaseView?.rowsLimit && childBaseView?.rowsLimit > 0
      ? childBaseView?.rowsLimit
      : DEFAULT_ITEMS_PER_PAGE
  )
  const { dispatch } = useWizardState()

  const { mutateAsync: createBaseRun, isLoading: isCreatingNewBaseRun } = useBaseRunCreate({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateBaseRunVariableMutation } = useMutation('baseRunVariable.update', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const updateBaseRunVariable = useCallback(
    ({ baseRunVariableId, value }: { baseRunVariableId: string; value: any }) => {
      updateBaseRunVariableMutation({ id: baseRunVariableId, value, isBaseViewTable: false })
    },
    [updateBaseRunVariableMutation]
  )

  const fields: Record<string, any> = useMemo(
    () =>
      reduce((acc, f) => ({ ...acc, [f.baseVariableId as string]: f }), {}, childBaseView?.fields),
    [childBaseView?.fields]
  )
  const childBaseId = childBaseView?.fields[0].baseId as string

  const { data: validationData } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      parentBaseRunId: stepRun.baseRunId,
      baseId: childBaseId,
    },
  ])

  const { data, isLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      parentBaseRunId: stepRun.baseRunId,
      baseId: childBaseId,
      page: currentPage,
      limit: itemsPerPage,
      filters: (childBaseView?.filters ?? [])
        .filter((f) => f.baseVariableId)
        .map((f) => ({
          id: f.baseVariableId,
          value: f.value.map((v) => (!v ? null : v)),
        })),
      sort: {
        ...(childBaseView?.sortField
          ? { [childBaseView?.sortField]: childBaseView?.sortOrder as 'asc' | 'desc' }
          : { createdAt: 'desc' }),
      },
    },
  ])

  const previousPageCount = useRef(1)
  const itemCount = useMemo(() => data?.[0]?.totalCount ?? 0, [data])
  const pageCount = useMemo(() => {
    const currentValue = Math.ceil(itemCount / (itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE))

    if (itemCount === 0 && isLoading) return previousPageCount.current
    previousPageCount.current = currentValue
    return currentValue
  }, [itemCount, isLoading, itemsPerPage])

  const columns = useMemo(
    () =>
      map((field) => {
        const width = calculateTextWidth(field.label as string) + 120
        return columnHelper.accessor(field.baseVariableId as string, {
          header: (props) => (
            <div className='sub flex items-center'>
              {field.required ? <span className='text-red-500'>*</span> : null}
              <HeaderCellCustomSort
                name={field.label as string}
                headerContext={props}
                sortId={field.baseVariableId}
                filter='normal'
                style={{ width: `${width}px` }} // we use inline style to override default width fit-content
              />
            </div>
          ),
          cell: getChildBaseViewWACCustomCell(field.type, width).Cell,
        })
      }, childBaseView?.fields),
    [childBaseView?.fields]
  )

  const parsedData = useMemo(
    () =>
      (data ?? [])?.map((br) => ({
        id: {
          id: '',
          baseRunId: br.id,
          actualValue: br.id,
          isEditable: false,
          baseVariableId: '',
        },
        ...br.baseRunVariables.reduce(
          (acc, brv) => ({
            ...acc,
            [brv.baseVariable.id]: {
              id: brv.id,
              baseVariableId: brv.baseVariable.id,
              actualValue: brv.value,
              baseRunId: br.id,
              isEditable: fields[brv.baseVariable.id]?.editable && !isReadOnly,
              required: fields[brv.baseVariable.id]?.required,
              options: fields[brv.baseVariable.id]?.options,
              expandable: fields[brv.baseVariable.id]?.expandable,
            },
          }),
          {}
        ),
      })),
    [data]
  )

  // Check if all required fields are filled
  const isDataValid = useMemo(
    () =>
      (validationData ?? [])
        ?.map((br) =>
          [
            ...br.baseRunVariables.map((brv) => ({
              actualValue: brv.value,
              required: fields[brv.baseVariable.id]?.required,
            })),
          ].filter(
            (row) => row.required && (row.actualValue === null || row.actualValue === undefined)
          )
        )
        .filter((row) => row.length > 0).length === 0,
    [fields, validationData]
  )

  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: `ChildBaseViewWAC-${childBaseId}`,
      value: isDataValid,
    })
  }, [parsedData])

  const hooks = [
    ...(childBaseView?.showBaseRunId ? [baseRunIdHook] : []),
    rowNumbersHook({
      disableDelete: childBaseView?.allowAddRemoveRows !== true,
      stepRunId: stepRun.id,
    }),
  ]
  const columnsWithHooks = useMemo(
    () => flow(...hooks)(columns),
    [JSON.stringify(columns), hooks.length]
  )

  const { getRowModel, getHeaderGroups } = useReactTable({
    data: parsedData,
    columns: columnsWithHooks,
    getCoreRowModel: getCoreRowModel(),
    filterFns: { includes: () => true }, // required by the lib but we are not using
    meta: {
      pageSize: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      currentPage,
      updateBaseRunVariable,
    },
  })

  const createNewRow = async () => {
    await createBaseRun({
      baseId: childBaseId,
      parentBaseRunId: stepRun.baseRunId,
      initialValues: [],
      stepRunId: stepRun.id,
    })
  }

  return (
    <Container>
      <div className='mb-2 flex items-center gap-1'>
        {showName ? <Text fontWeight='bold'>{name}</Text> : null}

        <Button
          variant='subtle'
          shape='circle'
          size='md'
          icon='RedoIcon'
          title='Refresh'
          onClick={() => reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')}
        />
        {childBaseView?.allowAddRemoveRows ? (
          <Button
            size='md'
            variant='subtle'
            shape='circle'
            title='Add New Row'
            disabled={isCreatingNewBaseRun || isReadOnly}
            icon='PlusIcon'
            onClick={() => createNewRow()}
          />
        ) : null}
      </div>
      <ErrorBoundary>
        <TableStyles.Table
          style={{ overflow: 'auto', minHeight: '400px', borderSpacing: 0 }}
          className='inline-block w-full border-collapse rounded-tl-none'>
          <thead>
            {getHeaderGroups()?.map((headerGroup) => (
              <TableStyles.HeaderRow
                key={headerGroup.id}
                className='h-[48px]'
                style={{ position: 'sticky', top: 0, zIndex: 2 }}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className='pl-4'>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </TableStyles.HeaderRow>
            ))}
          </thead>
          <tbody className='w-full overflow-y-visible bg-white'>
            {isLoading ? (
              <Flex
                alignItems='center'
                justifyContent='center'
                height='300px'
                width='40px'
                style={{ position: 'fixed', left: '58%' }}>
                <LogoSpinner height={40} width={40} />
              </Flex>
            ) : (
              getRowModel().rows?.map((row) => (
                <TableStyles.BodyRow
                  key={`${row.id}-${Object.values(row.original)?.[0]?.baseRunId}`}
                  className='h-[48px]'>
                  {row?.getVisibleCells().map((cell) => (
                    <td key={cell.id} className={'truncate'}>
                      <ReactTable.CellContainer>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </ReactTable.CellContainer>
                    </td>
                  ))}
                </TableStyles.BodyRow>
              ))
            )}
          </tbody>
        </TableStyles.Table>
      </ErrorBoundary>
      {childBaseView?.rowsLimit && childBaseView.rowsLimit > 0 ? null : (
        <Flex my='10px' alignItems='center'>
          <Flex alignItems='center' mr='20px'>
            <Text mr='8px'>Showing</Text>
            <Dropdown
              name='Base runs'
              placeholder='10'
              width='70px'
              search={false}
              dropdownWidth='70px'
              options={[10, 25, 30, 40, 50, 75].map((o) => ({
                key: String(o),
                value: o,
              }))}
              selectedKey={String(itemsPerPage)}
              onChange={({ value }) => setItemsPerPage(value as number)}
            />
            <Text ml='8px'>items per page</Text>
          </Flex>

          <Pagination
            pages={pageCount}
            selectedPage={currentPage ?? 1}
            onClick={(page) => setPage(page)}
          />

          <Flex alignItems='center' ml='20px'>
            <Text mr='8px'>Page</Text>
            <Dropdown
              name='Base runs'
              placeholder='1'
              maxHeight='400px'
              width='70px'
              dropdownWidth='120px'
              alignment='center'
              options={Array(pageCount)
                .fill('_')
                .map((o, i) => ({
                  key: String(i + 1),
                  value: i + 1,
                }))}
              selectedKey={String(currentPage ?? 1)}
              onChange={({ value }) => setPage(value as number)}
            />
            <Text ml='8px'>
              of {pageCount} ({itemCount} total items)
            </Text>
          </Flex>
        </Flex>
      )}
    </Container>
  )
}
