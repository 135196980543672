import { SVGProps } from 'react'
function DoubleCaratLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.7803 21.7071C22.3898 22.0976 21.7567 22.0976 21.3661 21.7071L11.3661 11.7071C10.9756 11.3166 10.9756 10.6834 11.3661 10.2929L21.3661 0.292894C21.7567 -0.0976301 22.3898 -0.09763 22.7803 0.292894C23.1709 0.68342 23.1709 1.31658 22.7803 1.70711L13.4875 11L22.7803 20.2929C23.1709 20.6834 23.1709 21.3166 22.7803 21.7071Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7803 21.7071C11.3898 22.0976 10.7567 22.0976 10.3661 21.7071L0.366136 11.7071C-0.0243891 11.3166 -0.024389 10.6834 0.366136 10.2929L10.3661 0.292893C10.7567 -0.097631 11.3898 -0.0976309 11.7803 0.292893C12.1709 0.683419 12.1709 1.31658 11.7803 1.70711L2.48746 11L11.7803 20.2929C12.1709 20.6834 12.1709 21.3166 11.7803 21.7071Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DoubleCaratLeft
