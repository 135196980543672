import { formatTimeLeft } from '@invisible/common/date'
import { ClockOutlineIcon } from '@invisible/ui/icons'
import { useTheme } from '@invisible/ui/themes'
import { FC } from 'react'
import { Box, Flex } from 'rebass'

type TClockType = 'safe' | 'warning' | 'danger'
interface IDurationCellProps {
  duration: number
  type: TClockType
  className?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DurationCell: FC<IDurationCellProps> = ({ duration, type, className }) => {
  const { colors } = useTheme()
  const getColor: Record<TClockType, string> = {
    safe: colors.textStatusGreen,
    warning: colors.textStatusOrange,
    danger: colors.textStatusRed,
  }

  return (
    <Flex alignItems='center' className={className}>
      <ClockOutlineIcon width={16} height={16} color={getColor[type]} />
      <Box ml={2} color={type === 'danger' ? colors.textStatusRed : undefined}>
        {formatTimeLeft(duration, 'y[y] d[d] h[h] m[m]')}
      </Box>
    </Flex>
  )
}
