import { z } from 'zod'

const frCasesSchema = z.object({
  goLiveID: z.string().optional(),

  custID: z.string().optional(),

  accountName: z.string().optional(),

  goLiveName: z.string().optional(),

  caseUrl: z.string().optional(),

  finalReviewType: z.string().optional(),

  packageStatus: z.string().optional(),

  virtualRestaurant: z.boolean().optional(),

  containsAlcohal: z.boolean().optional(),

  externalNotes: z.string().optional(),

  menuItemCount: z.string().optional(),

  source: z.string().optional(),

  finalReviewRepName: z.string().optional(),

  queue: z.string().optional(),

  menuCopy: z.string().optional(),
})

export { frCasesSchema }
