import { get } from 'lodash/fp'

const interpolate = (template: string, vars: Record<string, unknown>) =>
  JSON.parse(template, (_, rawValue) => {
    if (rawValue?.[0] !== '$') {
      return rawValue
    }

    const name = rawValue.slice(1)
    const value = get(name)(vars)
    if (typeof value === 'undefined') {
      throw new ReferenceError(`Variable ${name} is not defined`)
    }

    return value
  })

export default interpolate
