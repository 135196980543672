import { UuidSchema, z } from '@invisible/zod'

import { definedGenericPredicateSchema } from './Formula'

const conditionSchema = definedGenericPredicateSchema
type TCondition = z.infer<typeof conditionSchema>

/**
 * A branch (within a branch step's metadata) is simply a condition paired with a stepId.
 */
const branchSchema = z.object({
  stepId: UuidSchema,
  condition: conditionSchema,
  position: z.number().optional(), // should be phased out
})

/**
 * A branch (within a branch step's metadata) is simply a condition paired with a stepId.
 */
type TBranch = z.infer<typeof branchSchema>

const operandTypeSchema = z.union([
  z.literal('string'),
  z.literal('number'),
  z.literal('boolean'),
  z.literal('variable'),
])
type TOperandType = z.infer<typeof operandTypeSchema>

const operatorSchema = z.union([
  z.literal('equals'),
  z.literal('not_equals'),
  z.literal('greater_than'),
  z.literal('greater_than_or_equal'),
  z.literal('less_than'),
  z.literal('less_than_or_equal'),
  z.literal('matches'),
  z.literal('not_matches'),
  z.literal('contains'),
  z.literal('not_contains'),
  z.literal('is_null'),
  z.literal('is_not_null'),
])
type TOperator = z.infer<typeof operatorSchema>

const ruleSchema = z.object({
  operandA: z.unknown(),
  operandAType: operandTypeSchema,
  operator: operatorSchema,
  operandB: z.unknown().optional(),
  operandBType: operandTypeSchema.optional(),
})
type TRule = z.infer<typeof ruleSchema>

type TRuleGroup = {
  any?: (TRuleGroup | TRule)[]
  all?: (TRuleGroup | TRule)[]
}
const ruleGroupSchema: z.ZodType<TRuleGroup> = z.object({
  any: z.array(ruleSchema.or(z.lazy(() => ruleGroupSchema))).optional(),
  all: z.array(ruleSchema.or(z.lazy(() => ruleGroupSchema))).optional(),
})

const nestedBranchSchema = z.object({
  stepId: UuidSchema,
  branchName: z.string(),
  condition: ruleGroupSchema,
})
type TNestedBranch = z.infer<typeof nestedBranchSchema>

/**
 * Branch step metadata contains a default step, and an array of branches (conditional steps).
 * At execution, process engine will go to any steps where condition is true, or the default if no conditions are true.
 */

const nestedBranchStepSchema = z.object({
  defaultStepId: UuidSchema,
  nestedBranches: z.array(nestedBranchSchema),
})

const schema = z.object({
  defaultStepId: UuidSchema,
  branches: z.array(branchSchema),
})

/**
 * Branch step metadata contains a default step, and an array of branches (conditional steps).
 * At execution, process engine will go to any steps where condition is true, or the default if no conditions are true.
 */
type TSchema = z.infer<typeof schema>
type TNestedBranchStepSchema = z.infer<typeof nestedBranchStepSchema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates StepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

export {
  branchSchema,
  conditionSchema,
  create,
  createArgsSchema,
  nestedBranchSchema,
  nestedBranchStepSchema,
  operatorSchema,
  ruleGroupSchema,
  ruleSchema,
  schema,
}
export type {
  TBranch,
  TCondition,
  TCreateArgs,
  TNestedBranch,
  TNestedBranchStepSchema,
  TOperandType,
  TOperator,
  TRule,
  TRuleGroup,
  TSchema,
}
