import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const ReviewInstructions = () => (
  <Paper
    sx={{
      width: '200px',
      padding: '12px',
    }}>
    <Typography variant='body2' fontWeight='bold'>
      How to review an export
    </Typography>
    <br />
    <Typography variant='body2' color='grey.600'>
      An overview is available on this page. Download the complete report to check for completeness
      and correctness, and verify parameters like title, variable count, null values etc.
    </Typography>
    <br />
    <Typography variant='body2' color='grey.600'>
      After verification, return to this modal and click "Approve" to make the file available in
      Workplace for clients. Click "Reject" to prevent client access, verify your export
      configuration, and retrigger.
    </Typography>
    <br />
    <Typography variant='body2' color='grey.600'>
      Once you have approved or rejected, you cannot undo this action.
    </Typography>
  </Paper>
)

export { ReviewInstructions }
