import { upperFirst } from 'lodash/fp'

type TObject = Record<string, unknown>

export const identifyUser = (userId?: string | number, metadata?: TObject) =>
  window.analytics.identify(userId === undefined ? undefined : `${userId}`, metadata)

export const trackEvent = (eventName: string, metadata?: TObject) =>
  window.analytics.track(eventName, metadata)

export const trackClickEvent = (elementName: string, metadata?: TObject) =>
  window.analytics.track(`use${upperFirst(elementName)}.clicked`, metadata)

export const trackHoverEvent = (elementName: string, metadata?: TObject) =>
  window.analytics.track(`use${upperFirst(elementName)}.hovered`, metadata)

export const recordPageView = (page: string, metadata?: TObject) =>
  window.analytics.page(window.location.hostname, page, metadata)
