import { SVGProps } from 'react'
function CaretBack(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='28'
      viewBox='0 0 33 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.3296 13.5293C32.3296 14.2749 31.7326 14.8793 30.9963 14.8793L1.66293 14.8793C0.926547 14.8793 0.329593 14.2749 0.329593 13.5293C0.329593 12.7837 0.926547 12.1793 1.66293 12.1793L30.9963 12.1793C31.7326 12.1793 32.3296 12.7837 32.3296 13.5293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6057 26.6339C14.085 27.1611 13.2408 27.1611 12.7201 26.6339L0.720117 14.4839C0.199418 13.9567 0.199418 13.1019 0.720117 12.5747L12.7201 0.4247C13.2408 -0.102508 14.085 -0.102508 14.6057 0.4247C15.1264 0.95191 15.1264 1.80668 14.6057 2.33389L3.54854 13.5293L14.6057 24.7247C15.1264 25.2519 15.1264 26.1067 14.6057 26.6339Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CaretBack
