import { SVGProps } from 'react'
function SkillFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='20'
      viewBox='0 0 33 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.8049 9H30.8049V5C30.8049 4.46957 30.5942 3.96086 30.2191 3.58579C29.8441 3.21071 29.3354 3 28.8049 3H26.8049V2C26.8049 1.46957 26.5942 0.960859 26.2191 0.585786C25.8441 0.210714 25.3354 0 24.8049 0H21.8049C21.2745 0 20.7658 0.210714 20.3907 0.585786C20.0156 0.960859 19.8049 1.46957 19.8049 2V9H13.8049V2C13.8049 1.46957 13.5942 0.960859 13.2191 0.585786C12.8441 0.210714 12.3354 0 11.8049 0H8.80493C8.2745 0 7.76579 0.210714 7.39072 0.585786C7.01565 0.960859 6.80493 1.46957 6.80493 2V3H4.80493C4.2745 3 3.76579 3.21071 3.39072 3.58579C3.01565 3.96086 2.80493 4.46957 2.80493 5V9H1.80493C1.53972 9 1.28536 9.10536 1.09782 9.29289C0.910288 9.48043 0.804932 9.73478 0.804932 10C0.804932 10.2652 0.910288 10.5196 1.09782 10.7071C1.28536 10.8946 1.53972 11 1.80493 11H2.80493V15C2.80493 15.5304 3.01565 16.0391 3.39072 16.4142C3.76579 16.7893 4.2745 17 4.80493 17H6.80493V18C6.80493 18.5304 7.01565 19.0391 7.39072 19.4142C7.76579 19.7893 8.2745 20 8.80493 20H11.8049C12.3354 20 12.8441 19.7893 13.2191 19.4142C13.5942 19.0391 13.8049 18.5304 13.8049 18V11H19.8049V18C19.8049 18.5304 20.0156 19.0391 20.3907 19.4142C20.7658 19.7893 21.2745 20 21.8049 20H24.8049C25.3354 20 25.8441 19.7893 26.2191 19.4142C26.5942 19.0391 26.8049 18.5304 26.8049 18V17H28.8049C29.3354 17 29.8441 16.7893 30.2191 16.4142C30.5942 16.0391 30.8049 15.5304 30.8049 15V11H31.8049C32.0701 11 32.3245 10.8946 32.512 10.7071C32.6996 10.5196 32.8049 10.2652 32.8049 10C32.8049 9.73478 32.6996 9.48043 32.512 9.29289C32.3245 9.10536 32.0701 9 31.8049 9ZM4.80493 15V5H6.80493V15H4.80493ZM28.8049 15H26.8049V5H28.8049V15Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SkillFilled
