import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { Modal } from '@invisible/ui/modal'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { addMinutes, differenceInMilliseconds, format, isWithinInterval } from 'date-fns'
import enIN from 'date-fns/locale/en-IN'
import { format as tzFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty } from 'radash'
import { useMemo, useState } from 'react'

type TSchedules = inferQueryOutput<'agentSchedule.findMany'>

export const AddAvailabilityModal = ({
  isOpen,
  onClose,
  onAdded,
  schedules,
}: {
  isOpen: boolean
  schedules: TSchedules
  onClose: () => void
  onAdded: () => void
}) => {
  const [formValues, setFormValues] = useState({
    startTime: '',
    endTime: '',
  })

  const [loggedInUser] = useLoggedInUser()
  const reactQueryContext = useContext()
  const { addToast } = useToasts()

  const getEndDateOptions = (startDate: Date) => {
    const endDateOptions = []
    for (let i = 1; i < 96; i++) {
      endDateOptions.push(addMinutes(startDate, i * 15))
    }

    return endDateOptions
  }

  const formatMilliseconds = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    minutes = minutes % 60
    return `${hours !== 0 ? hours + 'h' : ''}${minutes !== 0 ? minutes + 'm' : ''}`
  }

  const { mutateAsync: createSchedule } = useMutation('agentSchedule.create', {
    onSuccess: () => onAdded(),
    onSettled: () => {
      reactQueryContext.invalidateQueries('agentSchedule.findMany')
    },
    onError: (error) => {
      addToast(`Error while creating schedule. ${error.message}`, {
        appearance: 'error',
      })
    },
  })

  const currentTimeZone = Intl.DateTimeFormat('en-us', { timeZoneName: 'short' }).resolvedOptions()
    .timeZone
  const zoneString = tzFormat(utcToZonedTime(new Date(), currentTimeZone), 'zzz', {
    timeZone: currentTimeZone,
    locale: enIN,
  })

  const startDateOptions = useMemo(() => {
    const now = new Date()
    const minutes = now.getMinutes()
    const startDateOption = addMinutes(now, -(minutes % 15))
    setFormValues((prev) => ({ ...prev, startTime: startDateOption.toString() }))
    return [
      {
        key: startDateOption.toString(),
        value: format(startDateOption, 'HH:mm'),
      },
    ]
  }, [])

  const endDateOptions = useMemo(
    () =>
      formValues.startTime
        ? getEndDateOptions(new Date(formValues.startTime ?? '')).map((time) => ({
            key: time.toString(),
            value: `${format(time, 'HH:mm')} (${formatMilliseconds(
              differenceInMilliseconds(time, new Date(formValues.startTime as string))
            )})`,
          }))
        : [],
    [formValues.startTime]
  )

  const isAvailabilityValid = useMemo(
    () =>
      formValues.startTime &&
      formValues.endTime &&
      isWithinInterval(new Date(), {
        start: new Date(formValues.startTime),
        end: new Date(formValues.endTime),
      }),
    [formValues.startTime, formValues.endTime]
  )

  return (
    <Modal
      icon='CalendarFilledIcon'
      title='Add Availability to Get Task'
      width='500px'
      visible={isOpen}
      primaryButton={
        <div className='flex items-center gap-x-2'>
          <Button
            disabled={!isAvailabilityValid}
            onClick={() => {
              createSchedule({
                startTime: zonedTimeToUtc(
                  new Date(formValues.startTime as string),
                  loggedInUser?.timeZone ?? currentTimeZone
                ),
                endTime: zonedTimeToUtc(
                  new Date(formValues.endTime as string),
                  loggedInUser?.timeZone ?? currentTimeZone
                ),
              })
              onClose()
            }}>
            Add
          </Button>
        </div>
      }
      secondaryButton={
        <Button
          onClick={() => {
            onClose()
            window.open(`/profile/${loggedInUser?.id}`, '_blank')
          }}
          variant='secondary'>
          Go to my Availability
        </Button>
      }
      onClose={onClose}>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='text-bold text-lg'>You are not currently available.</div>
          <div className='text-paragraphs'>
            You must be within an availability block to assign yourself a task. Please add an
            availability block so you can get this task.
          </div>
        </div>
        {!isEmpty(schedules) ? (
          <div className='flex flex-col gap-1'>
            <div className='text-bold text-lg'>
              Upcoming availability blocks over the next 24 hours.
            </div>
            {schedules.map((schedule) => (
              <div key={schedule.id} className='flex items-center gap-x-4'>
                <div className='flex flex-col'>
                  <div className='text-paragraphs text-sm'>
                    {format(schedule.startTime, 'MMM do, yyyy')}
                  </div>
                  <div className='text-sm'>{format(schedule.startTime, 'h:mma')}</div>
                </div>
                <div className='text-paragraphs mt-6 flex text-sm'>to</div>
                <div className='flex flex-col'>
                  <div className='text-paragraphs text-sm'>
                    {format(schedule.endTime, 'MMM do, yyyy')}
                  </div>
                  <div className='text-sm'>{format(schedule.endTime, 'h:mma')}</div>
                </div>
                <div className='text-paragraphs mt-6 flex items-center text-sm'>{zoneString}</div>
              </div>
            ))}
          </div>
        ) : null}
        <div className='flex flex-col gap-1'>
          <div className='text-bold text-lg'>Add an availability block over the next 24 hours.</div>
          <div className='flex items-center gap-x-2'>
            <div className='flex flex-col gap-y-1'>
              <div className='text-paragraphs text-sm'>{format(new Date(), 'MMM do, yyyy')}</div>
              <Dropdown
                search={false}
                modal={false}
                width='100px'
                selectedKey={formValues.startTime ?? ''}
                placeholder='--:--'
                onChange={({ key }) => {
                  setFormValues((prev) => ({
                    ...prev,
                    startTime: key,
                    endTime: '',
                  }))
                }}
                options={startDateOptions}
              />
            </div>
            <div className='text-paragraphs mt-6 flex items-center text-sm'>To</div>
            <div className='flex flex-col gap-y-1'>
              <div className='text-paragraphs text-sm'>
                {format(
                  formValues.endTime ? new Date(formValues.endTime) : new Date(),
                  'MMM do, yyyy'
                )}
              </div>
              <Dropdown
                disabled={!formValues.startTime}
                modal={false}
                search={false}
                selectedKey={formValues.endTime ?? ''}
                width='140px'
                placeholder='--:--'
                onChange={({ key }) => {
                  setFormValues((prev) => ({
                    ...prev,
                    endTime: key,
                  }))
                }}
                options={endDateOptions}
              />
            </div>
            <div className='text-paragraphs mt-6 flex items-center text-sm'>{zoneString}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
