import { baseTheme } from '@invisible/ui/themes'
import { FC } from 'react'
import CookieConsent from 'react-cookie-consent'

// This component uses the react-cookie-consent npm
// package by Mastermindzh. See the git repo here:
// https://github.com/Mastermindzh/react-cookie-consent

interface IConsentProps {
  barText?: string
  cookieName?: string
  onAccept?: () => void
  onDecline?: () => void
}

const DEFAULT_CONSENT_BAR_TEXT = 'This website uses cookies to enhance the user experience.'
const DEFAULT_COOKIE_NAME = 'Invisible_Cookie_Consent'

const primaryBtnStyles = {
  color: baseTheme.colors.white,
  border: `1px solid ${baseTheme.purpleScale[6]}`,
  background: baseTheme.purpleScale[7],
  boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.016)',
  borderRadius: '6px',
}

const secondaryBtnStyles = {
  color: baseTheme.grayScale[13],
  border: `1px solid ${baseTheme.grayScale[10]}`,
  background: baseTheme.colors.gray,
  boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.016)',
  borderRadius: '6px',
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CookieConsentBar: FC<IConsentProps> = ({ barText, cookieName, onAccept, onDecline }) => (
  <CookieConsent
    location='bottom'
    hideOnAccept
    hideOnDecline
    enableDeclineButton
    flipButtons
    buttonText='I Accept'
    declineButtonText='Allow only essential cookies'
    buttonStyle={primaryBtnStyles}
    declineButtonStyle={secondaryBtnStyles}
    cookieName={cookieName ?? DEFAULT_COOKIE_NAME}
    visible='byCookieValue'
    onAccept={onAccept}
    onDecline={onDecline}
    data-testid='cookie-consent-bar'>
    {barText ?? DEFAULT_CONSENT_BAR_TEXT}
  </CookieConsent>
)

export { CookieConsentBar }
