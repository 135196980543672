import type { UltronRouter } from '@invisible/ultron/trpc/server'
import { httpLink } from '@trpc/client/links/httpLink'
import { loggerLink } from '@trpc/client/links/loggerLink'
import { splitLink } from '@trpc/client/links/splitLink'
import { createWSClient, wsLink } from '@trpc/client/links/wsLink'
import { withTRPC as _withTRPC } from '@trpc/next'
import { NextComponentType } from 'next'
import superjson from 'superjson'

import { APP_URL, NEXT_PUBLIC_WS_URL, NODE_ENV } from './env'

const ONE_DAY_IN_SECONDS = 60 * 60 * 24

const getWSLink = () => {
  if (typeof window === 'undefined') {
    return httpLink({
      url: `${APP_URL}/api/trpc`,
    })
  }
  const client = createWSClient({
    url: NEXT_PUBLIC_WS_URL,
  })
  return wsLink({
    client,
  })
}

export const withTRPC = (
  AppOrPage: NextComponentType<any, any, any>,
  { ssr }: { ssr: boolean } | undefined = { ssr: true }
) =>
  _withTRPC<UltronRouter>({
    config({ ctx }) {
      ctx?.res?.setHeader(
        'Cache-Control',
        `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`
      )

      return {
        links: [
          splitLink({
            condition(op) {
              // if (op.context.websocket === true) return 'websocket'
              return op.type === 'subscription'
            },
            // when condition is true, use websocket
            true: getWSLink(),
            // when condition is false, use http link
            false: httpLink({
              url: `${typeof window !== 'undefined' ? '' : APP_URL}/api/trpc`,
            }),
          }),
          loggerLink({
            enabled: (opts) =>
              NODE_ENV === 'development' ||
              (opts.direction === 'down' && opts.result instanceof Error),
          }),
        ],
        transformer: superjson,
        queryClientConfig: { defaultOptions: { queries: { staleTime: 60_000 } } },
        headers: () => {
          if (ctx?.req) {
            // on ssr, forward client's headers to the server
            return {
              ...ctx.req.headers,
              'x-ssr': '1',
            }
          }
          return {}
        },
      }
    },
    ssr,
  })(AppOrPage)
