import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { DateMathOrDateRangeSwitcher } from './DateMathOrDateRangeSwitcher'
import { STEP_RUN_PROPERTY_OPTIONS } from './SingleStepRunFilterDialog'

type TValue = {
  start_date: string
  end_date: string
}

const TimeRangeDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean
  onClose: () => void
  onSubmit: (key: string, value: TValue) => void
}) => {
  const [selectedTimeRangeFilterKey, setSelectedTimeRangeFilterKey] = useState<string | null>(null)
  const [selectedTimeRangeFilterValue, setSelectedTimeRangeFilterValue] = useState<{
    start_date?: string
    end_date?: string
  } | null>(null)

  const handleDialogClose = () => {
    setSelectedTimeRangeFilterKey(null)
    setSelectedTimeRangeFilterValue(null)

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleDialogClose}>
      <DialogTitle sx={{ fontWeight: '500' }}>Choose a custom time-range</DialogTitle>
      <IconButton
        onClick={handleDialogClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '400px' }}>
        <Typography color='grey.600'>
          You can choose, below, a more precise range for this step run within your current export.
        </Typography>
        <Box mt='24px' mb='32px'>
          <Typography mb='8px'> Select a property to filter by</Typography>
          <Autocomplete
            options={STEP_RUN_PROPERTY_OPTIONS}
            renderInput={(params) => (
              <TextField {...params} placeholder='Choose a time range format' />
            )}
            size='small'
            disableClearable
            onChange={(_, newValue) => setSelectedTimeRangeFilterKey(newValue?.value)}
          />
        </Box>

        {selectedTimeRangeFilterKey ? (
          <DateMathOrDateRangeSwitcher
            value={selectedTimeRangeFilterValue}
            handleChange={(v) => setSelectedTimeRangeFilterValue(v)}
          />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button sx={{ fontWeight: 'normal' }} onClick={handleDialogClose}>
          CANCEL
        </Button>
        <Button
          sx={{ fontWeight: 'normal' }}
          disabled={!selectedTimeRangeFilterKey || !selectedTimeRangeFilterValue}
          onClick={() => {
            onSubmit(selectedTimeRangeFilterKey as string, selectedTimeRangeFilterValue as TValue)
            handleDialogClose()
          }}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { TimeRangeDialog }
