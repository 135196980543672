import { z } from '@invisible/zod'

const timingSchema = z
  .object({
    hours: z.number().lt(24).array().optional(),
    minutes: z.number().lt(60).array().optional(),
    days: z.number().lt(7).array().optional(),
    dates: z.number().lt(32).array().optional(),
    months: z.number().lt(13).array().optional(),
  })
  .strict()

type TTiming = z.infer<typeof timingSchema>

export type { TTiming }
export { timingSchema }
