import { UuidSchema, z } from '@invisible/zod'

const schema = z.object({
  schema: z.record(z.any()).nullish(),
  asViewer: z.boolean().optional(),
  viewOptions: z
    .object({
      indentWidth: z.number().optional(),
      allowCopy: z.boolean().optional(),
      showDataTypes: z.boolean().optional(),
    })
    .optional(),
  schemaBaseVariableId: UuidSchema.nullish(),
  schemaStepTemplateVariableId: UuidSchema.nullish(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
