import { mapGraphqlToUnifiedProcess, UnifiedProcessById } from '@invisible/common/components/process-base'
import { toGlobalId, useProcessByIdQuery } from '@invisible/concorde/gql-client'
import { useQuery as useTrpcQuery } from '@invisible/trpc/client'
import { useGate } from 'statsig-react'

interface UseProcessByIdQueryProps {
  id: string
  enabled?: boolean
}

/**
 * Custom hook to fetch process data by ID.
 *
 * @param {UseProcessByIdQueryProps} options - The options for fetching process data.
 * @returns {Object} - An object containing the fetched process data and loading state.
 */
const useProcessById = ({ id, enabled = true }: UseProcessByIdQueryProps) => {
  const { value: isGraphqlEnabled } = useGate('enable-graphql-process-by-id-query')

  // tRPC query
  const { data: trpcResult, isLoading: trpcLoading } = useTrpcQuery(
    ['process.findByIdWithStepsAndStepGoTos', { id }],
    {
      enabled: !isGraphqlEnabled && enabled,
    }
  )

  // GraphQL query
  const { data: graphqlResult, isLoading: graphqlLoading } = useProcessByIdQuery(
    { id: toGlobalId('ProcessType', id) },
    {
      enabled: isGraphqlEnabled && enabled,
    }
  )

  return {
    data: isGraphqlEnabled
      ? mapGraphqlToUnifiedProcess(graphqlResult)
      : trpcResult as UnifiedProcessById | null,
    isLoading: isGraphqlEnabled ? graphqlLoading : trpcLoading,
  }
}

export { useProcessById }
