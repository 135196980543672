import { JsonSchema, UuidSchema, z } from '@invisible/zod'

import { schema as checkListSchema } from './CheckList'
import { schema as formFieldSchema } from './FormField'
import { schema as formSectionSchema } from './FormSection'

const schema = z.object({
  fields: z.array(formFieldSchema).optional(),
  sections: z.array(formSectionSchema).optional(),
  checkLists: z.array(checkListSchema).optional(),
  submitButtonText: z.string().optional(),
  type: z.enum(['create', 'update']).optional(),
  baseId: UuidSchema.optional(),
  completeStepRunOnSubmit: z.boolean().optional(),
  embedId: UuidSchema.optional(),
  baseVariableId: UuidSchema.optional(),
  assignParentBaseRunId: z.boolean().optional(),
  createStepRun: z.boolean().optional(),
  stepIdToCreateStepRunFor: z.string().optional(),
  embedUrlField: z
    .object({
      baseVariableId: UuidSchema,
    })
    .optional(),
  canHaveAutoAssignOnNextStep: z.boolean().optional(),
  defaults: z
    .array(
      z.object({
        baseVariableId: UuidSchema.optional(),
        stepTemplateVariableId: UuidSchema.optional(),
        value: JsonSchema,
      })
    )
    .optional(),
  minNumberOfBaseRuns: z.number().optional(),
  maxNumberOfBaseRuns: z.number().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
