import {
  CheckCircleOutlineIcon,
  CircledExclamationIcon,
  CloseCircleIcon,
  DashCircleFilledIcon,
  ProgressIcon,
  RocketFilledIcon,
  SnoozeOutlineIcon,
} from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import type { BaseRunStatus } from '@invisible/ultron/prisma'
import { FC } from 'react'

interface IProps {
  status: BaseRunStatus
}

const BASE_RUN_STATUS = {
  pending: { text: 'Pending', icon: <RocketFilledIcon /> },
  done: {
    text: 'Done',
    icon: <CheckCircleOutlineIcon width={18} height={18} className='text-green-main' />,
  },
  snoozed: {
    text: 'Snoozed',
    icon: <SnoozeOutlineIcon width={18} height={18} className='text-amber-main' />,
  },
  disabled: {
    text: 'Cancelled',
    icon: <CloseCircleIcon width={18} height={18} className='text-muted' />,
  },
  failed: {
    text: 'Failed',
    icon: <DashCircleFilledIcon width={18} height={18} className='text-red-main' />,
  },
  running: {
    text: 'In Progress',
    icon: <ProgressIcon width={18} height={18} className='text-theme-main' />,
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseRunStatusCell: FC<IProps> = ({ status }) => (
  <div className='flex items-center'>
    <div className='mr-2 flex w-5 items-center justify-center'>{BASE_RUN_STATUS[status].icon}</div>
    <Text>{BASE_RUN_STATUS[status].text}</Text>
  </div>
)
