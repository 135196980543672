import { SVGProps } from 'react'
function EditOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.0245 0.9042C25.5244 0.404329 26.3349 0.404329 26.8347 0.9042L31.9547 6.02419C32.4546 6.52406 32.4546 7.33451 31.9547 7.83438L16.5947 23.1944C16.3547 23.4344 16.0291 23.5693 15.6896 23.5693H10.5697C9.86273 23.5693 9.28966 22.9962 9.28966 22.2893V17.1693C9.28966 16.8298 9.42452 16.5042 9.66456 16.2642L21.1705 4.75821L21.1841 4.74448L21.1978 4.73094L25.0245 0.9042ZM11.8497 17.6995L22.0893 7.45986L25.3991 10.7697L15.1595 21.0093H11.8497V17.6995ZM29.2394 6.92929L27.2092 8.95947L23.8994 5.64967L25.9296 3.61948L29.2394 6.92929ZM2.88959 1.80939C2.21063 1.80939 1.55949 2.0791 1.0794 2.5592C0.599304 3.03929 0.32959 3.69043 0.32959 4.36939V29.9693C0.32959 30.6483 0.599303 31.2994 1.0794 31.7795C1.55949 32.2596 2.21063 32.5293 2.88959 32.5293H28.4895C29.1685 32.5293 29.8196 32.2596 30.2997 31.7795C30.7798 31.2994 31.0495 30.6483 31.0495 29.9693V15.8894C31.0495 15.1824 30.4765 14.6094 29.7695 14.6094C29.0626 14.6094 28.4895 15.1824 28.4895 15.8894V29.9693H2.88959L2.88959 4.36939L16.9696 4.36939C17.6765 4.36939 18.2496 3.79631 18.2496 3.08939C18.2496 2.38246 17.6765 1.80939 16.9696 1.80939H2.88959Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EditOutline
