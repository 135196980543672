import {
  formatUnit,
  getBarChartColor,
  getPieChartColor,
  hasBarsLimitExceeded,
  isBrowserCompatible,
} from '@invisible/common/helpers'
import {
  IBarchartData,
  IChoroplethData,
  ILineChartData,
  IPiechartData,
  IPieChartDatum,
  ITableData,
  IVisualizationLegend,
} from '@invisible/common/types'
import { BarChart } from '@invisible/ui/bar-chart'
import { CustomChartToolTip } from '@invisible/ui/chart-tooltip'
import { ChoroplethChart } from '@invisible/ui/choropleth-chart'
import { CHART_TYPE, NO_DATA_THEME, PIE_CHART_NO_DATA } from '@invisible/ui/constants'
import { LineChart } from '@invisible/ui/line-chart'
import { PieChart } from '@invisible/ui/pie-chart'
import { ReportClassicTable, Table } from '@invisible/ui/reports/table'
import { BarDatum, BarTooltipProps, ComputedDatum as ComputedBarDatum } from '@nivo/bar'
import { CompleteTheme, Margin } from '@nivo/core'
import { ComputedDatum as ComputedPieDatum } from '@nivo/pie'
import { flow } from 'lodash'
import { isEmpty, pick, sum, values } from 'lodash/fp'
import percentile from 'percentile'
import { Link } from 'rebass'

import { getAxisMarginByRotationAngle } from './helpers'
import { LegendsList } from './LegendsList'

const MAX_TICK_VALUE_LENGTH = 12

type ChartsConfig = {
  pieChart: {
    innerRadius: number
    enableArcLabels?: boolean
    enableArcLinkLabels?: boolean
    margin?: Partial<Margin>
  }
  barChart?: {
    enableGridY?: boolean
    enableAxisLegends?: boolean
    showTotal?: boolean
    groupMode?: 'stacked' | 'grouped'
    enableLabel?: boolean
    labelSkipHeight?: number
    labelSkipWidth?: number
    maxValuePercentile?: number
    axisLabelFontWeight?: CompleteTheme['axis']['ticks']['text']['fontWeight']
  }
  lineChart?: {
    enableGridY?: boolean
    enableAxisLegends?: boolean
    axisLabelFontWeight?: CompleteTheme['axis']['ticks']['text']['fontWeight']
  }
  table?: {
    style?: 'gray matter' | 'classic'
  }
}

type ToggleableChartsProps = {
  chartsConfig: ChartsConfig
  enableLegends?: boolean
  selectedVisualization: string
  isLoading: boolean
  pieChart?: IPiechartData
  table?: ITableData
  barChart?: IBarchartData
  choroplethChart?: IChoroplethData
  lineChart?: ILineChartData
  errorMessage?: string
  onPieNodeClick?: (node: ComputedPieDatum<IPieChartDatum>) => void
  onBarNodeClick?: (
    node: ComputedBarDatum<BarDatum> & {
      color: string
    }
  ) => void
  onPreviousReportClick?: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ClassicTableSkeleton: React.FC<{ errorMessage?: string; isLoading: boolean }> = ({
  errorMessage,
  isLoading,
}) => <Table isLoading={isLoading} columns={[]} data={[]} errorMessage={errorMessage} />

const sumBarChartDatumKeys = (keys: string[], datum: BarDatum) =>
  flow(pick(keys), values, sum)(datum)

/**
 * @param data Formatted BarChart's data i.e array of BarDatum
 * @param indexedBy The the name of property/key in each BardDatum object whose value is used
 * as label along x-axis points in barchart
 * @param keys List of identifiers of each stack in bar chart.
 * @returns An object whose keys are equivalent of labels on a barchart's x-axis and the sum of
 * the respective bar's against them
 */
const getBarChartTotalsPerIndex = ({
  data,
  indexedBy,
  keys,
}: {
  data: BarDatum[]
  indexedBy: string
  keys: string[]
}) => {
  if (isEmpty(data) || !indexedBy || isEmpty(keys)) return

  return data.reduce((totalsPerIndex, datum) => {
    const totalsPerIndexKey = datum[indexedBy]
    const currentValue = Number(totalsPerIndex[totalsPerIndexKey])
    const datumKeysSum = sumBarChartDatumKeys(keys, datum)

    if (!currentValue)
      return {
        ...totalsPerIndex,
        [totalsPerIndexKey]: datumKeysSum,
      }

    return {
      ...totalsPerIndex,
      [totalsPerIndexKey]: currentValue + datumKeysSum,
    }
  }, {})
}

const truncateTickValue = (value: number) => {
  const castedValue = String(value)
  if (castedValue.length <= MAX_TICK_VALUE_LENGTH) return castedValue
  const trimmedCastedValue = castedValue.slice(0, MAX_TICK_VALUE_LENGTH).trim()
  return trimmedCastedValue + '...'
}

const ChartError = ({
  errorMessage,
  onPreviousReportClick,
}: {
  errorMessage?: JSX.Element | string
  onPreviousReportClick?: () => void
}) => (
  <div
    id={errorMessage ? 'report-error' : 'no-data'}
    className='text-muted text-center text-base font-normal'>
    {errorMessage ?? 'There is no data to show at the moment. '}
    {onPreviousReportClick && (
      <>
        <Link onClick={onPreviousReportClick} style={{ cursor: 'pointer' }}>
          Click
        </Link>{' '}
        to go back
      </>
    )}
  </div>
)

const withLegends =
  (Component: React.FunctionComponent<ToggleableChartsProps>) => (props: ToggleableChartsProps) => {
    const getLegends = (): IVisualizationLegend[] => {
      switch (props.selectedVisualization) {
        case CHART_TYPE.PieChart:
          return props.pieChart?.legends ?? []
        case CHART_TYPE.BarChart:
          return props.barChart?.legends ?? []
        case CHART_TYPE.LineChart:
          return props.lineChart?.legends ?? []
        default:
          return []
      }
    }

    const legends = getLegends()

    if (!props.enableLegends) return <Component {...props} />
    return (
      <>
        <LegendsList legends={legends} />
        <Component {...props} />
      </>
    )
  }
// render pie chart
const renderPieChart = ({
  config,
  pieChart,
  onPreviousReportClick,
  isLoading,
  onPieNodeClick,
  errorMessage,
}: {
  pieChart?: IPiechartData
  config?: ChartsConfig['pieChart']
  onPreviousReportClick?: () => void
  isLoading: boolean
  onPieNodeClick?: (node: ComputedPieDatum<IPieChartDatum>) => void
  errorMessage?: string
}) => (
  <>
    <PieChart
      chartDataProp={
        pieChart?.data && !isEmpty(pieChart?.data) ? pieChart?.data : PIE_CHART_NO_DATA
      }
      height={'333px'}
      colors={pieChart?.data && !isEmpty(pieChart?.data) ? getPieChartColor : NO_DATA_THEME}
      radius={150}
      innerRadius={config?.innerRadius}
      handleNodeClick={onPieNodeClick}
      isLoading={isLoading}
      isInteractive={!!pieChart?.data && !isEmpty(pieChart?.data)}
      showLegends={false}
      padAngle={0}
      margin={config?.margin}
      enableArcLabels={!!pieChart?.data && !isEmpty(pieChart?.data) && config?.enableArcLabels}
      enableArcLinkLabels={
        !!pieChart?.data && !isEmpty(pieChart?.data) && config?.enableArcLinkLabels
      }
      valueFormat={
        pieChart?.tooltipConfig
          ? (value) =>
              formatUnit({
                postfixUnit: pieChart.tooltipConfig.valueConfig.postfixUnit,
                prefixUnit: pieChart.tooltipConfig.valueConfig.prefixUnit,
                valueType: pieChart.tooltipConfig.valueConfig.valueType,
                value: value,
              })
          : undefined
      }
      tooltip={({ datum }) => (
        <CustomChartToolTip
          color={datum.color}
          label={datum.id}
          value={datum.value}
          tooltipConfig={pieChart?.tooltipConfig}
        />
      )}
    />
    {!isLoading && isEmpty(pieChart?.data) && (
      <ChartError errorMessage={errorMessage} onPreviousReportClick={onPreviousReportClick} />
    )}
  </>
)

// render bar chart
const renderBarChart = ({
  barChart,
  config,
  isLoading,
  errorMessage,
  onBarNodeClick,
  onPreviousReportClick,
}: {
  barChart?: IBarchartData
  config: ChartsConfig['barChart']
  onPreviousReportClick?: () => void
  isLoading: boolean
  onBarNodeClick?: (
    node: ComputedBarDatum<BarDatum> & {
      color: string
    }
  ) => void
  errorMessage?: string
}) => {
  const barChartTotalsPerIndex = getBarChartTotalsPerIndex({
    data: barChart?.data ?? [],
    indexedBy: barChart?.indexedBy ?? '',
    keys: barChart?.KEYS ?? [],
  })

  const getYAxisMaxValue = () => {
    if (!config?.maxValuePercentile || !barChartTotalsPerIndex) return
    return percentile(config.maxValuePercentile, Object.values(barChartTotalsPerIndex))
  }

  const numOfXAxisPoints = barChart?.data?.length ?? 0
  const numOfLegends = barChart?.legends?.length ?? 0
  const showBarsLimitExceeded = hasBarsLimitExceeded({
    isGrouped: config?.groupMode === 'grouped',
    numOfLegends: numOfLegends,
    numOfXAxisPoints,
  })

  return (
    <>
      <BarChart
        chartDataProp={showBarsLimitExceeded ? [] : barChart?.data}
        targetLine={barChart?.targetLine}
        height={'400px'}
        enableLabel={config?.enableLabel ?? false}
        isLoading={isLoading}
        handleNodeClick={onBarNodeClick}
        factor={1.7}
        enableLegends={true}
        tooltip={(node: BarTooltipProps<BarDatum>) => (
          <CustomChartToolTip
            color={node.color}
            label={node.id}
            value={node.value}
            tooltipConfig={barChart?.tooltipConfig}
          />
        )}
        enableGridY={config?.enableGridY}
        showTotal={config?.showTotal ?? true}
        keys={barChart?.KEYS}
        layout='vertical'
        padding={0.6}
        groupMode={config?.groupMode ?? 'stacked'}
        minValue='auto'
        maxValue={getYAxisMaxValue() as number | undefined}
        labelSkipHeight={config?.labelSkipHeight ?? 10}
        labelSkipWidth={config?.labelSkipWidth ?? 15}
        indexBy={barChart?.indexedBy}
        colors={
          !!barChart?.data && !isEmpty(barChart.data)
            ? (node) => getBarChartColor(node, barChart?.colors || {})
            : NO_DATA_THEME
        }
        axisLeft={{
          legendPosition: 'middle',
          legend: config?.enableAxisLegends ? barChart?.yAxisLegend : undefined,
          legendOffset: config?.enableAxisLegends ? -55 : undefined,
          ...(barChart?.tooltipConfig
            ? {
                format: (value) =>
                  formatUnit({
                    postfixUnit: barChart?.tooltipConfig.valueConfig.postfixUnit,
                    prefixUnit: barChart?.tooltipConfig.valueConfig.prefixUnit,
                    valueType: barChart?.tooltipConfig.valueConfig.valueType,
                    value: value,
                  }),
              }
            : {}),
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 8,
          legend: config?.enableAxisLegends ? barChart?.xAxisLegend : undefined,
          legendPosition: 'middle',
          legendOffset: config?.enableAxisLegends ? 85 : undefined,
          tickRotation: barChart?.xAxisLabelRotationAngle ?? 0,
          format: truncateTickValue,
        }}
        valueFormat={
          barChart?.tooltipConfig
            ? (value) =>
                formatUnit({
                  postfixUnit: barChart.tooltipConfig.valueConfig.postfixUnit,
                  prefixUnit: barChart.tooltipConfig.valueConfig.prefixUnit,
                  valueType: barChart.tooltipConfig.valueConfig.valueType,
                  value: value,
                })
            : undefined
        }
        margin={{
          bottom: getAxisMarginByRotationAngle(barChart?.xAxisLabelRotationAngle),
          left: config?.enableAxisLegends && !!barChart?.xAxisLegend ? 60 : 0,
          top: 50,
        }}
        skeletonPadding={'30px'}
        theme={{
          axis: {
            legend: {
              text: {
                fontWeight: config?.axisLabelFontWeight,
              },
            },
          },
        }}
      />
      {((!isLoading && isEmpty(barChart?.data)) || showBarsLimitExceeded) && (
        <ChartError
          errorMessage={
            showBarsLimitExceeded ? (
              <p>
                Unable to display the chart due to too many data points. <br />
                Please export or use other charts.
              </p>
            ) : (
              errorMessage
            )
          }
          onPreviousReportClick={onPreviousReportClick}
        />
      )}
    </>
  )
}

const renderTable = ({
  table,
  isLoading,
  config,
  errorMessage,
}: {
  table?: ITableData
  errorMessage?: string
  isLoading: boolean
  config?: ChartsConfig['table']
}) => {
  if (config?.style === 'classic' && !isLoading && !isEmpty(table?.data)) {
    return (
      <ReportClassicTable
        columns={table?.columns ?? []}
        data={table?.data ?? []}
        isLoading={false}
      />
    )
  } else if ((config?.style === 'classic' && isLoading) || isEmpty(table?.data)) {
    return <ClassicTableSkeleton isLoading={isLoading} errorMessage={errorMessage} />
  } else {
    return (
      <Table
        columns={table?.columns ?? []}
        data={table?.data ?? []}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    )
  }
}

const renderChoroplethChart = ({
  choroplethChart,
  isLoading,
  errorMessage,
  onPreviousReportClick,
}: {
  choroplethChart?: IChoroplethData
  isLoading: boolean
  errorMessage?: string
  onPreviousReportClick?: () => void
}) => {
  if (!isBrowserCompatible()) {
    return (
      <ChartError
        errorMessage={
          <p>
            Your browser does not support this report.<br></br>
            For the best experience, please use Google Chrome (version 80 or above).
          </p>
        }
      />
    )
  }

  return (
    <>
      <ChoroplethChart
        height={400}
        center={{ lat: 0, lng: 0 }}
        zoom={0}
        isLoading={isLoading}
        geoJsonDataProp={choroplethChart}
      />
      <ChartError errorMessage={errorMessage} onPreviousReportClick={onPreviousReportClick} />
    </>
  )
}

const renderLineChart = ({
  lineChart,
  isLoading,
  config,
  errorMessage,
  onPreviousReportClick,
}: {
  lineChart?: ILineChartData
  isLoading: boolean
  errorMessage?: string
  onPreviousReportClick?: () => void
  config?: ChartsConfig['lineChart']
}) => (
  <>
    <LineChart
      height={'400px'}
      animate
      isLoading={isLoading}
      data={lineChart?.data ?? []}
      targetLines={lineChart?.targetLines}
      enableGridY={config?.enableGridY}
      axisLeft={{
        legendPosition: 'middle',
        legend: config?.enableAxisLegends ? lineChart?.yAxisLegend : undefined,
        legendOffset: config?.enableAxisLegends ? -55 : undefined,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 20,
        legend: config?.enableAxisLegends ? lineChart?.xAxisLegend : undefined,
        legendPosition: 'middle',
        legendOffset: config?.enableAxisLegends ? 85 : undefined,
        tickRotation: lineChart?.xAxisLabelRotationAngle ?? 0,
        format: truncateTickValue,
      }}
      margin={{
        bottom: getAxisMarginByRotationAngle(lineChart?.xAxisLabelRotationAngle),
        left: config?.enableAxisLegends && !!lineChart?.xAxisLegend ? 60 : 0,
        top: 50,
      }}
      theme={{
        axis: {
          legend: {
            text: {
              fontWeight: config?.axisLabelFontWeight,
            },
          },
        },
      }}
    />
    {!isLoading && isEmpty(lineChart?.data) && (
      <ChartError errorMessage={errorMessage} onPreviousReportClick={onPreviousReportClick} />
    )}
  </>
)

export const ToggleableCharts = withLegends(
  ({
    pieChart,
    table,
    barChart,
    choroplethChart,
    lineChart,
    selectedVisualization,
    isLoading,
    errorMessage,
    onPreviousReportClick,
    onBarNodeClick,
    onPieNodeClick,
    chartsConfig,
  }: ToggleableChartsProps) => {
    switch (selectedVisualization) {
      case CHART_TYPE.BarChart:
        return renderBarChart({
          barChart: barChart as IBarchartData,
          config: chartsConfig.barChart,
          isLoading,
          errorMessage,
          onBarNodeClick,
          onPreviousReportClick,
        })
      case CHART_TYPE.PieChart:
        return renderPieChart({
          pieChart,
          config: chartsConfig.pieChart,
          isLoading,
          errorMessage,
          onPieNodeClick,
          onPreviousReportClick,
        })
      case CHART_TYPE.Choropleth:
        return renderChoroplethChart({
          choroplethChart,
          isLoading,
          errorMessage,
          onPreviousReportClick,
        })
      case CHART_TYPE.Table:
        return renderTable({
          table,
          isLoading,
          config: chartsConfig.table,
          errorMessage,
        })
      case CHART_TYPE.LineChart:
        return renderLineChart({
          lineChart,
          isLoading,
          config: chartsConfig.lineChart,
          errorMessage,
          onPreviousReportClick,
        })
      default:
        return null
    }
  }
)
