import { SnackbarContext } from '@invisible/common/providers'
import { getErrorMessage } from '@invisible/concorde/gql-client'
import axios from 'axios'
import { NEXT_PUBLIC_CONCORDE_URL } from 'libs/common/components/process-base/config/env'
import { useContext } from 'react'
import { useMutation } from 'react-query'

type TError = {
  response: {
    status: number
  }
}

type TBadConfigError = TError & {
  response: {
    data: {
      exports_trigger_failed: { export_configuration_id: string }[]
      exports_trigger_success: { export_configuration_id: string }[]
    }
  }
}
const useTriggerExport = ({
  selectedExports,
}: {
  selectedExports: { id: string; name: string }[]
}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { isLoading: isExportTriggerLoading, mutateAsync: triggerExport } = useMutation(
    ['trigger-export'],
    async ({ exportConfigIds }: { exportConfigIds: string[] }) =>
      axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/data-delivery/export/`,
        { export_configuration_ids: exportConfigIds },
        {
          withCredentials: true,
        }
      ),
    {
      onError: (err: TError) => {
        if (err.response.status === 422) {
          const error = err as TBadConfigError

          const failedExports = error.response?.data?.exports_trigger_failed ?? []
          const errorMessage = `The following exports failed to trigger: ${selectedExports
            .filter((e) => failedExports.some((fe) => fe.export_configuration_id === e.id))
            .map((e) => e.name)
            .join(', ')}`

          showSnackbar({
            message: errorMessage,
            variant: 'error',
          })
          return
        }

        showSnackbar({
          message: getErrorMessage(err),
          variant: 'error',
        })
      },
      onSuccess: () =>
        showSnackbar({
          message: 'Exports triggered successfully',
          variant: 'success',
        }),
    }
  )

  return { isExportTriggerLoading, triggerExport }
}

export { useTriggerExport }
