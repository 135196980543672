import { SVGProps } from 'react'
function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx={12} cy={12} r={11.5} stroke='currentColor' />
      <path stroke='currentColor' d='M4.5 4.5h15v15h-15z' />
    </svg>
  )
}

export default Logo
