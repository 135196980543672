import { SVGProps } from 'react'
function Star(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={17}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M9.065 0v9.395l-2.11-2.903L9.065 0z' fill='#FAF398' />
      <path d='M9.065 9.395L.13 6.492h6.824l2.11 2.903z' fill='#FAF17D' />
      <path d='M9.065 9.395l-3.41 1.108L.131 6.492l8.934 2.903z' fill='#E2B347' />
      <path d='M9.066 9.395L3.546 17l2.11-6.498 3.41-1.108z' fill='#FAF17D' />
      <path
        d='M9.066 9.395v3.589l-5.52 4.017 5.52-7.606zM9.06 0v9.395l2.11-2.903L9.06 0z'
        fill='#E2B347'
      />
      <path d='M9.06 9.395L18 6.492h-6.83L9.06 9.395z' fill='#FAF17D' />
      <path d='M9.06 9.395l3.415 1.108L18 6.492 9.06 9.395z' fill='#E2B347' />
      <path d='M9.06 9.395L14.586 17l-2.11-6.498-3.414-1.108z' fill='#FAF17D' />
      <path d='M9.06 9.395v3.589l5.525 4.017-5.524-7.606z' fill='#D8A744' />
    </svg>
  )
}

export default Star
