import { SVGProps } from 'react'

function ArrowInSquareFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.75 16.5001C1.05964 16.5001 0.5 15.9404 0.5 15.2501V2.75008C0.5 2.05972 1.05964 1.50008 1.75 1.50008H7.375C7.72018 1.50008 8 1.7799 8 2.12508C8 2.47025 7.72018 2.75008 7.375 2.75008H1.75V15.2501H14.25V9.62508C14.25 9.2799 14.5298 9.00008 14.875 9.00008C15.2202 9.00008 15.5 9.2799 15.5 9.62508V15.2501C15.5 15.9404 14.9404 16.5001 14.25 16.5001H1.75Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.44194 8.55814C8.68602 8.80221 8.68602 9.19794 8.44194 9.44202L4.06694 13.817C3.82286 14.0611 3.42714 14.0611 3.18306 13.817C2.93898 13.5729 2.93898 13.1772 3.18306 12.9331L7.55806 8.55814C7.80214 8.31406 8.19786 8.31406 8.44194 8.55814Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.625 9.00008C3.625 8.6549 3.90482 8.37508 4.25 8.37508H8C8.34518 8.37508 8.625 8.6549 8.625 9.00008V12.7501C8.625 13.0953 8.34518 13.3751 8 13.3751C7.65482 13.3751 7.375 13.0953 7.375 12.7501V9.62508H4.25C3.90482 9.62508 3.625 9.34525 3.625 9.00008Z'
        fill='currentColor'
      />
      <path
        d='M9.25 1.50008C9.25 0.809721 9.80964 0.250076 10.5 0.250076H15.5C16.1904 0.250076 16.75 0.80972 16.75 1.50008V6.50008C16.75 7.19043 16.1904 7.75008 15.5 7.75008H10.5C9.80964 7.75008 9.25 7.19043 9.25 6.50008V1.50008Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowInSquareFilled
