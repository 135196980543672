import { logout } from '@invisible/authentication/client'
import { CompanySummary, useSelectedCompany } from '@invisible/hooks/use-selected-company'
import { Dropdown } from '@invisible/ui/dropdown'
import { find, get, sortBy } from 'lodash/fp'
import { FC, useEffect, useMemo } from 'react'

import { useCompanyMenu } from '../../common/hooks'
import { NODE_ENV } from '../../config/env'

// eslint-disable-next-line @typescript-eslint/ban-types
const CompanyMenu: FC = () => {
  const { data, error } = useCompanyMenu()
  const [selectedCompany, setSelectedCompany] = useSelectedCompany()

  useEffect(() => {
    if (data?.myCompanies?.[0] && !selectedCompany)
      // no company previously in localStorage
      setSelectedCompany(data.myCompanies[0] as CompanySummary)
  }, [selectedCompany, data, setSelectedCompany])

  const options = useMemo(() => {
    if (!data?.myCompanies) return []
    const companiesOptions = sortBy((company: CompanySummary) => company.name.toLowerCase())(
      data?.myCompanies
    ) as typeof data.myCompanies

    return companiesOptions
  }, [data])

  useEffect(() => {
    if (data?.myCompanies?.length && selectedCompany) {
      const ids = data.myCompanies.map(get('id'))
      if (!ids?.includes(selectedCompany.id)) {
        // user no longer has access to company in his localStorage
        setSelectedCompany(data.myCompanies[0] as CompanySummary)
      }
    }
  }, [selectedCompany?.id, data])

  useEffect(() => {
    // Rehydrate selectedCompany if it's missing some data
    if (selectedCompany?.id && (!selectedCompany.name || !selectedCompany.id)) {
      const company = find((c: CompanySummary) => selectedCompany.id === c.id)(data?.myCompanies)
      if (company) {
        setSelectedCompany({ ...company })
      }
    }
  }, [selectedCompany?.id, data])

  useEffect(() => {
    const isProductionEnv = NODE_ENV === 'production'
    // access was most likely revoked
    if (error || (isProductionEnv && data?.myCompanies?.length === 0)) {
      logout()
    }
  }, [error, data])

  return (
    <div className='mb-5 flex justify-center'>
      <Dropdown
        data-test='select-company'
        options={options.map((e: any) => ({ key: String(e.id), value: e.name }))}
        selectedKey={String(selectedCompany?.id)}
        width='200px'
        name='Companies'
        placeholder='Companies'
        maxHeight='300px'
        onChange={({ key }) => {
          const company = data?.myCompanies?.find((e: any) => e.id === key)
          if (company) setSelectedCompany(company as CompanySummary)
        }}
      />
    </div>
  )
}

export { CompanyMenu }
