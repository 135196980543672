import { SVGProps } from 'react'

function SymbolInvisibleAdmin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='237'
      height='56'
      viewBox='0 0 237 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='237' height='56' fill='#1E8C93' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 28C24 19.1771 31.1771 12 40 12C48.8229 12 56 19.1771 56 28C56 36.8229 48.8229 44 40 44C31.1771 44 24 36.8229 24 28ZM50.3841 16.8488C47.6626 14.3133 44.0122 12.7619 40 12.7619C35.9878 12.7619 32.3374 14.3133 29.6159 16.8488H50.3841ZM51.1482 17.6126V38.3874C53.6855 35.6656 55.2381 32.0138 55.2381 28C55.2381 23.9862 53.6855 20.3344 51.1482 17.6126ZM50.3842 39.1512H29.6158C32.3374 41.6867 35.9878 43.2381 40 43.2381C44.0122 43.2381 47.6626 41.6867 50.3842 39.1512ZM28.8488 38.3841V17.6159C26.3133 20.3374 24.7619 23.9878 24.7619 28C24.7619 32.0122 26.3133 35.6626 28.8488 38.3841ZM50.3863 38.3893H29.6107V17.6107H50.3863V38.3893Z'
        fill='white'
      />
      <path d='M69.53 26.65H70.4V16.15H69.53V26.65Z' fill='white' />
      <path
        d='M76.1408 26.65H77.0108V17.515L83.7758 26.65H84.7208V16.15H83.8508V25.285L77.0858 16.15H76.1408V26.65Z'
        fill='white'
      />
      <path
        d='M89.0509 16.15L93.6409 26.65H94.3909L98.9809 16.15H98.0659L94.0309 25.435L90.0109 16.15H89.0509Z'
        fill='white'
      />
      <path d='M103.402 26.65H104.272V16.15H103.402V26.65Z' fill='white' />
      <path
        d='M113.733 26.8C116.163 26.8 117.693 25.555 117.693 23.86C117.693 21.955 116.178 21.145 114.138 20.89C112.188 20.65 110.733 20.125 110.733 18.73C110.733 17.635 111.768 16.78 113.658 16.78C115.323 16.78 116.523 17.53 116.763 18.865H117.648C117.378 17.05 115.848 16 113.658 16C111.243 16 109.863 17.155 109.863 18.745C109.863 20.68 111.573 21.385 113.658 21.655C115.548 21.91 116.823 22.495 116.823 23.875C116.823 25.09 115.638 26.02 113.748 26.02C111.993 26.02 110.583 25.27 110.343 23.935H109.458C109.728 25.75 111.453 26.8 113.733 26.8Z'
        fill='white'
      />
      <path d='M122.883 26.65H123.753V16.15H122.883V26.65Z' fill='white' />
      <path
        d='M135.868 21.265C136.873 20.89 137.428 20.065 137.428 18.835C137.428 17.215 136.318 16.15 134.353 16.15H129.493V26.65H134.713C136.738 26.65 137.938 25.57 137.938 23.89C137.938 22.45 137.188 21.565 135.868 21.265ZM130.363 16.93H134.278C135.793 16.93 136.558 17.71 136.558 18.94C136.558 20.17 135.793 20.95 134.278 20.95H130.363V16.93ZM134.653 25.87H130.363V21.7H134.653C136.213 21.7 137.068 22.495 137.068 23.785C137.068 25.075 136.213 25.87 134.653 25.87Z'
        fill='white'
      />
      <path d='M143.02 16.15V26.65H149.77V25.84H143.89V16.15H143.02Z' fill='white' />
      <path
        d='M154.555 26.65H161.905V25.84H155.425V21.595H161.005V20.815H155.425V16.96H161.755V16.15H154.555V26.65Z'
        fill='white'
      />
      <path
        d='M74.996 41.5H75.7L72.202 33.8H71.586L68.088 41.5H68.759L69.826 39.146H73.929L74.996 41.5ZM70.09 38.574L71.883 34.625L73.676 38.574H70.09Z'
        fill='#D2E8E9'
      />
      <path
        d='M78.8791 41.5H81.7831C84.0601 41.5 85.7761 39.894 85.7761 37.65C85.7761 35.406 84.0601 33.8 81.7831 33.8H78.8791V41.5ZM79.5171 40.895V34.405H81.7831C83.6751 34.405 85.1381 35.769 85.1381 37.65C85.1381 39.531 83.6751 40.895 81.7831 40.895H79.5171Z'
        fill='#D2E8E9'
      />
      <path
        d='M89.4004 41.5H90.0274V34.922L93.1184 41.5H93.6244L96.7154 34.922V41.5H97.3424V33.8H96.6274L93.3824 40.664L90.1484 33.8H89.4004V41.5Z'
        fill='#D2E8E9'
      />
      <path d='M101.513 41.5H102.151V33.8H101.513V41.5Z' fill='#D2E8E9' />
      <path
        d='M106.361 41.5H106.999V34.801L111.96 41.5H112.653V33.8H112.015V40.499L107.054 33.8H106.361V41.5Z'
        fill='#D2E8E9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.7381 41.9L73.6711 39.546H70.0838L69.0168 41.9H67.4669L71.3283 33.4H72.4596L76.321 41.9H74.7381ZM69.826 39.146H73.929L74.996 41.5H75.7L72.202 33.8H71.586L68.088 41.5H68.759L69.826 39.146ZM71.883 34.625L70.09 38.574H73.676L71.883 34.625ZM73.0551 38.174L71.883 35.5926L70.7109 38.174H73.0551ZM78.4791 41.9V33.4H81.7831C84.2637 33.4 86.1761 35.1682 86.1761 37.65C86.1761 40.1318 84.2637 41.9 81.7831 41.9H78.4791ZM78.8791 41.5V33.8H81.7831C84.0601 33.8 85.7761 35.406 85.7761 37.65C85.7761 39.894 84.0601 41.5 81.7831 41.5H78.8791ZM79.9171 34.805V40.495H81.7831C83.471 40.495 84.7381 39.2936 84.7381 37.65C84.7381 36.0065 83.471 34.805 81.7831 34.805H79.9171ZM89.0004 41.9V33.4H90.4021L93.383 39.7268L96.374 33.4H97.7424V41.9H96.3154V36.7138L93.8784 41.9H92.8644L90.4274 36.7138V41.9H89.0004ZM90.0274 34.922L93.1184 41.5H93.6244L96.7154 34.922V41.5H97.3424V33.8H96.6274L93.3824 40.664L90.1484 33.8H89.4004V41.5H90.0274V34.922ZM101.113 41.9V33.4H102.551V41.9H101.113ZM102.151 41.5H101.513V33.8H102.151V41.5ZM105.961 41.9V33.4H107.256L111.615 39.2868V33.4H113.053V41.9H111.758L107.399 36.0133V41.9H105.961ZM106.999 34.801L111.96 41.5H112.653V33.8H112.015V40.499L107.054 33.8H106.361V41.5H106.999V34.801ZM79.5171 40.895H81.7831C83.6751 40.895 85.1381 39.531 85.1381 37.65C85.1381 35.769 83.6751 34.405 81.7831 34.405H79.5171V40.895Z'
        fill='#D2E8E9'
      />
    </svg>
  )
}

export default SymbolInvisibleAdmin
