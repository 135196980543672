import { SVGProps } from 'react'
function Menu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='25'
      viewBox='0 0 33 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 12.0293C0.953613 11.2929 1.55057 10.696 2.28695 10.696H31.6203C32.3567 10.696 32.9536 11.2929 32.9536 12.0293C32.9536 12.7657 32.3567 13.3626 31.6203 13.3626H2.28695C1.55057 13.3626 0.953613 12.7657 0.953613 12.0293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 1.36263C0.953613 0.626251 1.55057 0.0292969 2.28695 0.0292969H31.6203C32.3567 0.0292969 32.9536 0.626251 32.9536 1.36263C32.9536 2.09901 32.3567 2.69596 31.6203 2.69596H2.28695C1.55057 2.69596 0.953613 2.09901 0.953613 1.36263Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 22.696C0.953613 21.9596 1.55057 21.3626 2.28695 21.3626H31.6203C32.3567 21.3626 32.9536 21.9596 32.9536 22.696C32.9536 23.4323 32.3567 24.0293 31.6203 24.0293H2.28695C1.55057 24.0293 0.953613 23.4323 0.953613 22.696Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Menu
