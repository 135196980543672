import { SVGProps } from 'react'
function List(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='32'
      height='26'
      viewBox='0 0 32 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99927 2.05263C7.99927 1.29687 8.59622 0.684211 9.3326 0.684211H30.6659C31.4023 0.684211 31.9993 1.29687 31.9993 2.05263C31.9993 2.80839 31.4023 3.42105 30.6659 3.42105H9.3326C8.59622 3.42105 7.99927 2.80839 7.99927 2.05263Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99927 13C7.99927 12.2442 8.59622 11.6316 9.3326 11.6316H30.6659C31.4023 11.6316 31.9993 12.2442 31.9993 13C31.9993 13.7558 31.4023 14.3684 30.6659 14.3684H9.3326C8.59622 14.3684 7.99927 13.7558 7.99927 13Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99927 23.9474C7.99927 23.1916 8.59622 22.5789 9.3326 22.5789H30.6659C31.4023 22.5789 31.9993 23.1916 31.9993 23.9474C31.9993 24.7031 31.4023 25.3158 30.6659 25.3158H9.3326C8.59622 25.3158 7.99927 24.7031 7.99927 23.9474Z'
        fill='currentColor'
      />
      <path
        d='M1.99927 4.10526C3.10384 4.10526 3.99927 3.18627 3.99927 2.05263C3.99927 0.918994 3.10384 0 1.99927 0C0.894698 0 -0.000732422 0.918994 -0.000732422 2.05263C-0.000732422 3.18627 0.894698 4.10526 1.99927 4.10526Z'
        fill='currentColor'
      />
      <path
        d='M1.99927 15.0526C3.10384 15.0526 3.99927 14.1336 3.99927 13C3.99927 11.8664 3.10384 10.9474 1.99927 10.9474C0.894698 10.9474 -0.000732422 11.8664 -0.000732422 13C-0.000732422 14.1336 0.894698 15.0526 1.99927 15.0526Z'
        fill='currentColor'
      />
      <path
        d='M1.99927 26C3.10384 26 3.99927 25.081 3.99927 23.9474C3.99927 22.8137 3.10384 21.8947 1.99927 21.8947C0.894698 21.8947 -0.000732422 22.8137 -0.000732422 23.9474C-0.000732422 25.081 0.894698 26 1.99927 26Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default List
