import { SVGProps } from 'react'

function Deadline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.30125 3.85515C8.72805 3.43009 8.72805 2.74093 8.30125 2.31587C7.87445 1.89081 7.18247 1.89081 6.75567 2.31587L2.12454 6.92811C1.69774 7.35318 1.69774 8.04234 2.12454 8.4674C2.55134 8.89246 3.24332 8.89246 3.67012 8.4674L8.30125 3.85515ZM25.3076 2.31587C25.7344 1.89081 26.4264 1.89081 26.8532 2.31587L31.4843 6.92811C31.9111 7.35318 31.9111 8.04234 31.4843 8.4674C31.0575 8.89246 30.3656 8.89246 29.9388 8.4674L25.3076 3.85515C24.8808 3.43009 24.8808 2.74093 25.3076 2.31587ZM5.87557 16.9358C5.87557 10.9246 10.7686 6.05147 16.8045 6.05147C22.8403 6.05147 27.7334 10.9246 27.7334 16.9358C27.7334 22.9471 22.8403 27.8202 16.8045 27.8202C10.7686 27.8202 5.87557 22.9471 5.87557 16.9358ZM16.8045 3.8746C9.56143 3.8746 3.68979 9.72231 3.68979 16.9358C3.68979 24.1494 9.56143 29.9971 16.8045 29.9971C24.0475 29.9971 29.9192 24.1494 29.9192 16.9358C29.9192 9.72231 24.0475 3.8746 16.8045 3.8746ZM17.8974 9.31678C17.8974 8.71565 17.4081 8.22834 16.8045 8.22834C16.2009 8.22834 15.7116 8.71565 15.7116 9.31678V16.9358C15.7116 17.537 16.2009 18.0243 16.8045 18.0243H24.4547C25.0583 18.0243 25.5476 17.537 25.5476 16.9358C25.5476 16.3347 25.0583 15.8474 24.4547 15.8474H17.8974V9.31678Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Deadline
