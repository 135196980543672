import { BaseViewMeta } from '@invisible/ultron/zod'
import { pickBy } from 'lodash/fp'

export type TFilterOption = {
  id: string
  value: string[] | (boolean | null)[] | { startDate: Date; endDate: Date }
  isStepAssignee?: boolean
  isStepStatus?: boolean
}

export const removeUndefinedFields = (
  obj: Record<string, string | undefined>
): Record<string, string> =>
  pickBy((v: string | undefined) => v !== undefined)(obj) as Record<string, string>

export const stringifyDateFilters = (filters: TFilterOption[]) =>
  filters.map((filter) => {
    if (
      typeof filter.value === 'object' &&
      !Array.isArray(filter.value) &&
      filter.value.startDate
    ) {
      return {
        ...filter,
        value: {
          startDate: filter.value?.startDate.toUTCString(),
          endDate: filter.value?.endDate.toUTCString(),
        },
      } as NonNullable<BaseViewMeta.TSchema['filters']>[number]
    }
    return filter as unknown as NonNullable<BaseViewMeta.TSchema['filters']>[number]
  })

export const parseFiltersDate = (filters: NonNullable<BaseViewMeta.TSchema['filters']>) =>
  filters.map((filter) => {
    if (
      typeof filter.value === 'object' &&
      !Array.isArray(filter.value) &&
      filter.value.startDate
    ) {
      return {
        ...filter,
        value: {
          startDate: new Date(filter.value?.startDate),
          endDate: new Date(filter.value?.endDate),
        },
      }
    }
    return filter as {
      id: string
      value: string[] | (boolean | null)[]
      isStepAssignee?: boolean
      isStepStatus?: boolean
    }
  })
