import { SVGProps } from 'react'

function WordmarkWizardBuilder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='196'
      height='18'
      viewBox='0 0 196 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M2 3L6.36158 15.0676H7.20631L10.3784 4.82738L13.5504 15.0676H14.3952L18.7567 3H17.7224L14.0159 13.4643L10.8094 3H9.9991L6.79256 13.4643L3.08608 3H2Z' />
      <path d='M23.8636 15.0676H24.8635V3H23.8636V15.0676Z' />
      <path d='M30.8063 15.0676H40.7707V14.1367H32.2716L40.7707 3.72406V3H31.1511V3.93093H39.3053L30.8063 14.3435V15.0676Z' />
      <path d='M55.9299 15.0676H57.0333L51.5511 3H50.5857L45.1036 15.0676H46.1552L47.8274 11.3784H54.2577L55.9299 15.0676ZM48.2411 10.4819L51.0512 4.29296L53.8612 10.4819H48.2411Z' />
      <path d='M72.1007 15.0676L68.5321 10.2923C70.5146 9.96473 71.7386 8.56834 71.7386 6.68924C71.7386 4.56879 70.1181 3 67.5839 3H62.0156V15.0676H63.0155V10.413H67.446L70.8594 15.0676H72.1007ZM63.0155 3.91369H67.5495C69.5492 3.91369 70.7388 5.10321 70.7388 6.72372C70.7388 8.34423 69.5492 9.51651 67.5495 9.51651H63.0155V3.91369Z' />
      <path d='M77.259 15.0676H81.8102C85.3788 15.0676 88.0682 12.5506 88.0682 9.0338C88.0682 5.51696 85.3788 3 81.8102 3H77.259V15.0676ZM78.2589 14.1194V3.94817H81.8102C84.7754 3.94817 87.0683 6.08586 87.0683 9.0338C87.0683 11.9817 84.7754 14.1194 81.8102 14.1194H78.2589Z' />
      <path d='M108.355 8.87865C109.51 8.44766 110.148 7.49949 110.148 6.08586C110.148 4.224 108.873 3 106.614 3H101.029V15.0676H107.028C109.355 15.0676 110.734 13.8264 110.734 11.8955C110.734 10.2406 109.872 9.22344 108.355 8.87865ZM102.028 3.89645H106.528C108.269 3.89645 109.148 4.7929 109.148 6.20654C109.148 7.62017 108.269 8.51662 106.528 8.51662H102.028V3.89645ZM106.959 14.1712H102.028V9.37859H106.959C108.752 9.37859 109.734 10.2923 109.734 11.7749C109.734 13.2575 108.752 14.1712 106.959 14.1712Z' />
      <path d='M121.402 15.24C124.264 15.24 126.402 13.1368 126.402 10.0682V3H125.402V10.0682C125.402 12.5851 123.695 14.2401 121.402 14.2401C119.092 14.2401 117.386 12.5851 117.386 10.0682V3H116.386V10.0682C116.386 13.1368 118.524 15.24 121.402 15.24Z' />
      <path d='M132.831 15.0676H133.831V3H132.831V15.0676Z' />
      <path d='M140.429 3V15.0676H148.187V14.1367H141.429V3H140.429Z' />
      <path d='M153.686 15.0676H158.237C161.805 15.0676 164.495 12.5506 164.495 9.0338C164.495 5.51696 161.805 3 158.237 3H153.686V15.0676ZM154.686 14.1194V3.94817H158.237C161.202 3.94817 163.495 6.08586 163.495 9.0338C163.495 11.9817 161.202 14.1194 158.237 14.1194H154.686Z' />
      <path d='M170.175 15.0676H178.622V14.1367H171.175V9.25792H177.588V8.36146H171.175V3.93093H178.45V3H170.175V15.0676Z' />
      <path d='M194.375 15.0676L190.807 10.2923C192.789 9.96473 194.013 8.56834 194.013 6.68924C194.013 4.56879 192.393 3 189.859 3H184.29V15.0676H185.29V10.413H189.721L193.134 15.0676H194.375ZM185.29 3.91369H189.824C191.824 3.91369 193.013 5.10321 193.013 6.72372C193.013 8.34423 191.824 9.51651 189.824 9.51651H185.29V3.91369Z' />
    </svg>
  )
}

export default WordmarkWizardBuilder
