import { SVGProps } from 'react'

function CaretLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.53 2.47a.75.75 0 0 1 0 1.06L6.06 8l4.47 4.47a.75.75 0 1 1-1.06 1.06l-5-5a.75.75 0 0 1 0-1.06l5-5a.75.75 0 0 1 1.06 0Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CaretLeft
