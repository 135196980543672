import { ReactNode } from 'react'

// Serializes a react element
const serialize = <T extends ReactNode | JSX.Element | string>(componentOrString: T) => {
  /* eslint-disable @typescript-eslint/ban-types */
  const getName = (value: string | Function) => {
    if (typeof value === 'string') {
      return value
    } else if (typeof value === 'function') {
      return value.name
    }
    return value
  }
  const replacer = (key: string, value: string) => {
    switch (key) {
      case 'type':
        return getName(value)
      case '_owner':
      case '_store':
      case 'ref':
      case 'key':
        return
      default:
        return value
    }
  }

  if (typeof componentOrString === 'string') return componentOrString

  return JSON.stringify(componentOrString, replacer)
}

export { serialize }
