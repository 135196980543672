import type { TIssueType } from './types'
import { getColorHighlight } from './utils/common'

export const DisplayIssueCount = ({
  issueType,
  count,
}: {
  issueType: TIssueType
  count: number
}) => {
  const color = getColorHighlight(issueType)
  return (
    <div className='flex items-center justify-center gap-3'>
      <div
        className='flex items-center justify-center gap-1 border-0 border-solid pb-1'
        style={{ borderColor: color, borderBottomWidth: '3px' }}>
        <div
          style={{ backgroundColor: color, color: 'white' }}
          className='flex h-5 w-5 items-center justify-center rounded'>
          {count}
        </div>
        <div>{issueType}</div>
      </div>
    </div>
  )
}
