import { SVGProps } from 'react'
function Dollar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='14'
      height='20'
      viewBox='0 0 14 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.232 10.514C11.414 9.745 10.325 9.321 9.167 9.321H7.723V3.893H8.445C8.825 3.893 9.198 3.962 9.551 4.099C9.904 4.237 10.219 4.435 10.488 4.688C10.759 4.942 10.969 5.238 11.114 5.569C11.26 5.9 11.334 6.249 11.334 6.607C11.334 6.982 11.658 7.286 12.056 7.286C12.454 7.286 12.778 6.982 12.778 6.607C12.778 6.069 12.666 5.545 12.448 5.051C12.23 4.555 11.914 4.11 11.509 3.729C11.105 3.349 10.632 3.052 10.102 2.845C9.573 2.639 9.014 2.535 8.444 2.535H7.722V1.179C7.722 0.804 7.399 0.5 7 0.5C6.601 0.5 6.278 0.804 6.278 1.179V2.536H5.194C4.036 2.536 2.948 2.96 2.13 3.729C1.311 4.497 0.86 5.52 0.86 6.608C0.86 7.696 1.311 8.718 2.13 9.487C2.948 10.256 4.036 10.68 5.194 10.68H6.277V16.109H4.833C4.061 16.109 3.336 15.827 2.79 15.314C2.244 14.802 1.944 14.12 1.944 13.395C1.944 13.021 1.621 12.716 1.222 12.716C0.823 12.716 0.5 13.019 0.5 13.393C0.5 14.481 0.951 15.504 1.769 16.273C2.587 17.042 3.676 17.465 4.833 17.465H6.277V18.822C6.277 19.196 6.6 19.501 6.999 19.501C7.398 19.501 7.721 19.197 7.721 18.822V17.465H9.165C10.323 17.465 11.411 17.042 12.23 16.273C13.048 15.503 13.498 14.481 13.498 13.393C13.5 12.305 13.05 11.282 12.232 10.514ZM5.194 9.321C4.422 9.321 3.697 9.038 3.151 8.526C2.606 8.014 2.306 7.332 2.306 6.607C2.306 5.882 2.607 5.201 3.152 4.688C3.698 4.175 4.423 3.893 5.195 3.893H6.278V9.322L5.194 9.321ZM11.209 15.312C10.664 15.825 9.938 16.107 9.166 16.107H7.722V10.678H9.166C9.938 10.678 10.663 10.961 11.21 11.473C11.755 11.985 12.055 12.667 12.055 13.392C12.055 14.117 11.755 14.8 11.209 15.312Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Dollar
