import { logger } from '@invisible/logger/client'
import { FC, forwardRef, useCallback, useEffect } from 'react'

import { useBaseRunCreate } from '../../../hooks/useBaseRunCreate'
import type { TCustomWACProps } from '../../types'
import { BASE_VARIABLES, PROMPTS_BASE_ID } from './lib/constants'

// eslint-disable-next-line @typescript-eslint/ban-types
const OpenAIInstructGPTOperateWAC: FC<TCustomWACProps> = forwardRef(
  ({ stepRun }: TCustomWACProps, wizardcontainerRef) => {
    const { mutateAsync: createBaseRun } = useBaseRunCreate()

    const onBeforeRequestHandler = useCallback(
      async (e) => {
        if (e.detail.openAIIGPTData.url === 'https://labelserver.azurewebsites.net/submit_answer') {
          logger.info('RECEIVED OPENAI IGPT PROMPT SUBMISSION EVENT', {
            baseId: PROMPTS_BASE_ID,
            parentBaseRunId: stepRun.baseRunId,
            stepRunId: stepRun.id,
            loggedInUserEmail: stepRun?.assignee?.email,
            project: e.detail.body.project,
            assnId: e.detail.body.assn_id,
          })

          const result = e.detail.body.answer.skip
            ? 'Skipped'
            : typeof e.detail.body.answer.prompt !== 'undefined' &&
              e.detail.body.answer.prompt.skip_completions !== 'no skip'
            ? 'Partially Submitted'
            : 'Submitted'
          const skipReason =
            result === 'Skipped'
              ? e.detail.body.answer.skip_reason
              : result === 'Partially Submitted'
              ? e.detail.body.answer.prompt?.skip_completions
              : ''
          await createBaseRun({
            baseId: PROMPTS_BASE_ID,
            stepRunId: stepRun.id,
            parentBaseRunId: stepRun.baseRunId,
            initialValues: [
              { baseVariableId: BASE_VARIABLES.PROMPTS.RESULT, value: result },
              { baseVariableId: BASE_VARIABLES.PROMPTS.ASSN_ID, value: e.detail.body.assn_id },
              { baseVariableId: BASE_VARIABLES.PROMPTS.PROJECT_ID, value: e.detail.body.project },
              {
                baseVariableId: BASE_VARIABLES.PROMPTS.TOTAL_TIME,
                value: e.detail.body.answer.total_time,
              },
              { baseVariableId: BASE_VARIABLES.PROMPTS.SKIP_REASON, value: skipReason },
            ],
          })
        }
      },
      [createBaseRun, stepRun]
    )

    useEffect(() => {
      document.addEventListener('openAIIGPTOnBeforeRequestEvent', onBeforeRequestHandler)

      return () => {
        document.removeEventListener('openAIIGPTOnBeforeRequestEvent', onBeforeRequestHandler)
      }
    }, [onBeforeRequestHandler])

    return (
      <div>
        <p>Operate WAC for Open AI Instruct GPT!</p>
      </div>
    )
  }
)

OpenAIInstructGPTOperateWAC.displayName = 'OperateWAC'

export { OpenAIInstructGPTOperateWAC }
