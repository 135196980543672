import { SVGProps } from 'react'
function ParagraphIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='18'
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 1.0293C0.953613 0.477012 1.40133 0.0292969 1.95361 0.0292969H23.9536C24.5059 0.0292969 24.9536 0.477012 24.9536 1.0293C24.9536 1.58158 24.5059 2.0293 23.9536 2.0293H1.95361C1.40133 2.0293 0.953613 1.58158 0.953613 1.0293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 6.0293C0.953613 5.47701 1.40133 5.0293 1.95361 5.0293H17.9536C18.5059 5.0293 18.9536 5.47701 18.9536 6.0293C18.9536 6.58158 18.5059 7.0293 17.9536 7.0293H1.95361C1.40133 7.0293 0.953613 6.58158 0.953613 6.0293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 11.0293C0.953613 10.477 1.40133 10.0293 1.95361 10.0293H23.9536C24.5059 10.0293 24.9536 10.477 24.9536 11.0293C24.9536 11.5816 24.5059 12.0293 23.9536 12.0293H1.95361C1.40133 12.0293 0.953613 11.5816 0.953613 11.0293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.953613 16.0293C0.953613 15.477 1.40133 15.0293 1.95361 15.0293H17.9536C18.5059 15.0293 18.9536 15.477 18.9536 16.0293C18.9536 16.5816 18.5059 17.0293 17.9536 17.0293H1.95361C1.40133 17.0293 0.953613 16.5816 0.953613 16.0293Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ParagraphIcon
