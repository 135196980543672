import { SVGProps } from 'react'

function Documentation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.35596 5C3.82552 5 3.31682 5.21071 2.94174 5.58579C2.56667 5.96086 2.35596 6.46957 2.35596 7V23C2.35596 23.5304 2.56667 24.0391 2.94174 24.4142C3.31682 24.7893 3.82553 25 4.35596 25H12.356C13.1516 25 13.9147 25.3161 14.4773 25.8787C15.0399 26.4413 15.356 27.2044 15.356 28C15.356 28.5523 15.8037 29 16.356 29C16.9082 29 17.356 28.5523 17.356 28C17.356 27.2044 17.672 26.4413 18.2346 25.8787C18.7972 25.3161 19.5603 25 20.356 25H28.356C28.8864 25 29.3951 24.7893 29.7702 24.4142C30.1452 24.0391 30.356 23.5304 30.356 23V7C30.356 6.46957 30.1452 5.96086 29.7702 5.58579C29.3951 5.21071 28.8864 5 28.356 5H20.356C19.0299 5 17.7581 5.52678 16.8204 6.46447C16.6521 6.63275 16.4971 6.81178 16.356 6.99997C16.2148 6.81178 16.0598 6.63275 15.8915 6.46447C14.9538 5.52678 13.682 5 12.356 5H4.35596ZM17.356 10V24C18.2164 23.3547 19.2679 23 20.356 23H28.356V7L20.356 7C19.5603 7 18.7972 7.31607 18.2346 7.87868C17.672 8.44129 17.356 9.20435 17.356 10ZM15.356 10C15.356 9.20435 15.0399 8.44129 14.4773 7.87868C13.9147 7.31607 13.1516 7 12.356 7L4.35596 7V23H12.356C13.444 23 14.4956 23.3547 15.356 24V10Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Documentation
