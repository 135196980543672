import { z } from '@invisible/zod'

import { schema as dynamicFilterValueSchema } from './DynamicFilterValue'

const schema = z.object({
  columnName: z.string(),
  value: dynamicFilterValueSchema,
  operator: z.string(),
  valueType: z.string(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
