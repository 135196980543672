import { SVGProps } from 'react'
function TelephoneOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.9023 15.8001L17.9107 15.8038L17.9191 15.8074L23.7741 18.3042C23.9734 18.3938 24.1393 18.544 24.2482 18.7334C24.3567 18.9221 24.403 19.14 24.3808 19.3563C24.189 20.7997 23.4809 22.1249 22.3873 23.0867C21.2912 24.0508 19.8823 24.5841 18.4225 24.5875C9.05076 24.5868 1.42444 16.9604 1.42383 7.58866C1.42731 6.12895 1.9606 4.72014 2.92463 3.62402C3.88643 2.53044 5.2117 1.82234 6.65511 1.63053C6.87141 1.60836 7.08929 1.65472 7.27793 1.76315C7.46761 1.87219 7.61793 2.03838 7.70745 2.23792L10.2171 8.09373C10.2171 8.09379 10.2171 8.09384 10.2172 8.0939C10.2172 8.09392 10.2172 8.09394 10.2172 8.09395C10.2819 8.24509 10.3078 8.40998 10.2926 8.57367C10.2774 8.73745 10.2216 8.89483 10.1301 9.03155L10.1301 9.03151L10.1242 9.04046L8.36127 11.738C7.98154 12.319 7.90778 13.0993 8.28446 13.761C9.23308 15.4276 10.6179 16.8048 12.2897 17.7442C12.9552 18.1182 13.7372 18.0387 14.3165 17.6532L16.9778 15.8826L16.9778 15.8826L16.9828 15.8792C17.1167 15.789 17.2714 15.7343 17.4323 15.7205C17.5932 15.7066 17.755 15.734 17.9023 15.8001Z'
        stroke='currentColor'
        stroke-width='2'
      />
    </svg>
  )
}

export default TelephoneOutline
