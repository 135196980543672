import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  toGlobalId,
  useBaseRunDeleteWithStepRunReferenceMutation,
} from '@invisible/concorde/gql-client'
import { parseResponse } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { map } from 'lodash'
import { useContext } from 'react'

interface BaseRunsDeleteWithStepRunReferenceInput {
  stepRunId: string
  baseRunIds: string[]
}

type OnMutateHandler = (variables: BaseRunsDeleteWithStepRunReferenceInput) => void

const useBaseRunDeleteWithStepRunReference = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: () => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: baseRunsDelete, isLoading } = useBaseRunDeleteWithStepRunReferenceMutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const {
        input: { baseRunIds },
      } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useBaseRunDeleteWithStepRunReference via GraphQL resulted in an error: ${errorMessage}`,
        {
          baseRunIds: map(baseRunIds, fromGlobalId),
        }
      )
    },
    onSuccess: (_, variables) => {
      const {
        input: { baseRunIds },
      } = variables
      handleOnSuccess?.()
      logger.info('Mutating from useBaseRunDeleteWithStepRunReference via GraphQL successful', {
        baseRunIds: map(baseRunIds, fromGlobalId),
      })
    },
    onMutate: () =>
      handleOnMutate
        ? (variables: BaseRunsDeleteWithStepRunReferenceInput) => {
            handleOnMutate({
              baseRunIds: map(variables.baseRunIds, fromGlobalId),
              stepRunId: fromGlobalId(variables.stepRunId),
            })
          }
        : undefined,
    onSettled,
  })

  const mutateAsync = async (data: BaseRunsDeleteWithStepRunReferenceInput) => {
    logger.info('Mutating from useBaseRunDeleteWithStepRunReference', data.baseRunIds)

    logger.info('Mutating from useBaseRunDeleteWithStepRunReference via GraphQL', {
      baseRunIds: data.baseRunIds,
    })
    const graphQLData = {
      input: {
        stepRunId: toGlobalId('StepRunType', data.stepRunId),
        baseRunIds: map(data.baseRunIds, (baseRunId) => toGlobalId('BaseRunType', baseRunId)),
      },
    }
    const response = await baseRunsDelete(graphQLData)
    const gqlResponse = parseResponse(
      response.baseRunDeleteWithStepRunReference,
      (code: number, message: string) => {
        const _error = new Error(`${code}: ${message}`)
        if (handleError) {
          handleError(_error)
        } else {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        }
      }
    )
    return {
      ...response,
      baseRunsIds: map(gqlResponse ? gqlResponse.baseRuns : [], (baseRun) =>
        fromGlobalId(baseRun.id)
      ),
    }
  }

  return { mutateAsync, isLoading }
}

export { useBaseRunDeleteWithStepRunReference }
