import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import * as React from 'react'

type TProps = GridRenderEditCellParams<any, string>

export function EditTextarea(props: TProps) {
  const { id, field, value, colDef, hasFocus } = props
  const [valueState, setValueState] = React.useState(value)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>()
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)
  const apiRef = useGridApiContext()

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus()
    }
  }, [hasFocus, inputRef])

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el)
  }, [])

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      event.preventDefault()
      const newValue = event.target.value
      setValueState(newValue)
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
    },
    [apiRef, field, id]
  )

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper
          id='test-poper-id'
          className='z-[500]'
          open={true}
          anchorEl={anchorEl}
          placement='bottom-start'>
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              id='standard-multiline-flexible'
              className='MuiDataGrid-editInputCell '
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  )
}
