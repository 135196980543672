import { SVGProps } from 'react'
function CircledPause(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11 1.375c-5.315 0-9.625 4.31-9.625 9.625s4.31 9.625 9.625 9.625 9.625-4.31 9.625-9.625S16.315 1.375 11 1.375zm0 17.617A7.994 7.994 0 013.008 11 7.994 7.994 0 0111 3.008 7.994 7.994 0 0118.992 11 7.994 7.994 0 0111 18.992zM9.11 7.562H8.077a.172.172 0 00-.172.172v6.532c0 .094.078.171.172.171H9.11a.172.172 0 00.172-.171V7.734a.172.172 0 00-.172-.171zm4.812 0H12.89a.172.172 0 00-.172.172v6.532c0 .094.077.171.172.171h1.03a.172.172 0 00.173-.171V7.734a.172.172 0 00-.172-.171z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CircledPause
