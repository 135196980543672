import { SVGProps } from 'react'
function DataTransform(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M36 26.036H12a.86.86 0 00-.857.857v9.428a.86.86 0 00.857.858h24a.86.86 0 00.857-.858v-9.428a.86.86 0 00-.857-.857zm-3 7.285H15v-3.428h18v3.428zm11.143 6.965a3.855 3.855 0 00-3.857 3.857A3.855 3.855 0 0044.143 48 3.855 3.855 0 0048 44.143a3.855 3.855 0 00-3.857-3.857zm0 5.143a1.283 1.283 0 01-1.286-1.286c0-.713.573-1.286 1.286-1.286.712 0 1.286.573 1.286 1.286 0 .712-.574 1.286-1.286 1.286zM36 10.82H12a.86.86 0 00-.857.858v9.428a.86.86 0 00.857.857h24a.86.86 0 00.857-.857V11.68A.86.86 0 0036 10.82zm-3 7.286H15V14.68h18v3.428zM44.143 7.714A3.855 3.855 0 0048 3.857 3.855 3.855 0 0044.143 0a3.855 3.855 0 00-3.857 3.857 3.855 3.855 0 003.857 3.857zm0-5.143c.712 0 1.286.574 1.286 1.286 0 .713-.574 1.286-1.286 1.286a1.283 1.283 0 01-1.286-1.286c0-.712.573-1.286 1.286-1.286zM3.857 0A3.855 3.855 0 000 3.857a3.855 3.855 0 003.857 3.857 3.855 3.855 0 003.857-3.857A3.855 3.855 0 003.857 0zm0 5.143a1.283 1.283 0 01-1.286-1.286c0-.712.574-1.286 1.286-1.286.713 0 1.286.574 1.286 1.286 0 .713-.573 1.286-1.286 1.286zm0 35.143A3.855 3.855 0 000 44.143 3.855 3.855 0 003.857 48a3.855 3.855 0 003.857-3.857 3.855 3.855 0 00-3.857-3.857zm0 5.143a1.283 1.283 0 01-1.286-1.286c0-.713.574-1.286 1.286-1.286.713 0 1.286.573 1.286 1.286 0 .712-.573 1.286-1.286 1.286z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DataTransform
