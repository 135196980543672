import { SVGProps } from 'react'
function DeleteOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='31'
      height='33'
      viewBox='0 0 31 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.5795 13.0725C12.2699 13.0725 12.8295 13.6235 12.8295 14.3032V24.1494C12.8295 24.8291 12.2699 25.3801 11.5795 25.3801C10.8892 25.3801 10.3295 24.8291 10.3295 24.1494V14.3032C10.3295 13.6235 10.8892 13.0725 11.5795 13.0725Z'
        fill='currentColor'
      />
      <path
        d='M20.3295 14.3032C20.3295 13.6235 19.7699 13.0725 19.0795 13.0725C18.3892 13.0725 17.8295 13.6235 17.8295 14.3032V24.1494C17.8295 24.8291 18.3892 25.3801 19.0795 25.3801C19.7699 25.3801 20.3295 24.8291 20.3295 24.1494V14.3032Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5796 0.764648C10.585 0.764648 9.6312 1.15366 8.92794 1.8461C8.22468 2.53853 7.82959 3.47768 7.82959 4.45694V5.68789H1.57959C0.889234 5.68789 0.32959 6.23892 0.32959 6.91865C0.32959 7.59839 0.889234 8.14942 1.57959 8.14942H2.82954V30.3032C2.82954 30.956 3.09294 31.5821 3.56178 32.0437C4.03062 32.5054 4.6665 32.7647 5.32954 32.7647H25.3295C25.9926 32.7647 26.6285 32.5054 27.0973 32.0437C27.5662 31.5821 27.8295 30.956 27.8295 30.3032V8.14942H29.0796C29.7699 8.14942 30.3296 7.59839 30.3296 6.91865C30.3296 6.23892 29.7699 5.68789 29.0796 5.68789H22.8296V4.45694C22.8296 3.47768 22.4345 2.53853 21.7312 1.8461C21.028 1.15366 20.0742 0.764648 19.0796 0.764648H11.5796ZM20.3296 5.68789V4.45694C20.3296 4.13052 20.1979 3.81747 19.9635 3.58666C19.7291 3.35585 19.4111 3.22618 19.0796 3.22618H11.5796C11.2481 3.22618 10.9301 3.35585 10.6957 3.58666C10.4613 3.81747 10.3296 4.13052 10.3296 4.45694V5.68789H20.3296ZM5.32954 30.3032V8.14942H25.3295V30.3032H5.32954Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DeleteOutline
