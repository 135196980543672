import { SVGProps } from 'react'
function RichText(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_3527:78048)'>
        <path
          d='M22.5149 2.12653C22.2842 1.89883 21.9729 1.76933 21.6472 1.76562H5.25801C4.93086 1.76676 4.6175 1.89627 4.38658 2.12578C4.15566 2.35529 4.02601 2.66609 4.02601 2.9901V34.7839C4.02544 34.9445 4.05686 35.1036 4.11852 35.2521C4.18018 35.4007 4.27085 35.5357 4.38531 35.6495C4.49977 35.7632 4.63578 35.8535 4.78554 35.9151C4.9353 35.9767 5.09585 36.0084 5.25801 36.0084H29.946C30.1085 36.0089 30.2695 35.9777 30.4198 35.9163C30.57 35.855 30.7066 35.7648 30.8215 35.651C30.9364 35.5372 31.0275 35.402 31.0894 35.2531C31.1513 35.1043 31.1829 34.9448 31.1823 34.7839V11.2178C31.1816 11.0579 31.1491 10.8997 31.0866 10.7522C31.024 10.6048 30.9327 10.471 30.8179 10.3585L22.5149 2.12653ZM21.0703 4.58839L28.3322 11.7764H21.0703V4.58839ZM28.4016 33.25H6.79804V4.51964H18.4458V12.756C18.4458 13.1821 18.6167 13.5908 18.921 13.8922C19.2252 14.1935 19.6379 14.3628 20.0682 14.3628H28.4016V33.25Z'
          fill='currentColor'
        />
        <path
          d='M32.6747 -0.000493429L26.8096 0.613897C26.7548 0.619141 26.7026 0.639708 26.6591 0.673188C26.6156 0.706668 26.5827 0.751684 26.564 0.803007C26.5454 0.85433 26.5418 0.909841 26.5538 0.963075C26.5658 1.01631 26.5928 1.06508 26.6317 1.1037L28.2151 2.71487L30.1542 4.67835L31.7333 6.28092C31.7723 6.32089 31.8219 6.34918 31.8764 6.36256C31.9289 6.37748 31.9845 6.37748 32.0369 6.36256C32.089 6.34475 32.1354 6.31363 32.1715 6.27233C32.206 6.23034 32.2285 6.17987 32.2365 6.12626L32.9957 0.33463C33.0025 0.290559 32.9991 0.245529 32.9856 0.202975C32.9722 0.16042 32.9491 0.121473 32.9181 0.0891253C32.8871 0.0567779 32.8491 0.0318888 32.8069 0.0163552C32.7647 0.000821518 32.7195 -0.00494621 32.6747 -0.000493429Z'
          fill='currentColor'
        />
        <path
          d='M25.1351 18.1572H20.1636C19.9336 18.1572 19.7472 18.3419 19.7472 18.5697V20.1809C19.7472 20.4087 19.9336 20.5933 20.1636 20.5933H25.1351C25.3651 20.5933 25.5515 20.4087 25.5515 20.1809V18.5697C25.5515 18.3419 25.3651 18.1572 25.1351 18.1572Z'
          fill='currentColor'
        />
        <path
          d='M25.1351 22.9775H20.1636C19.9336 22.9775 19.7472 23.1622 19.7472 23.39V25.0012C19.7472 25.229 19.9336 25.4136 20.1636 25.4136H25.1351C25.3651 25.4136 25.5515 25.229 25.5515 25.0012V23.39C25.5515 23.1622 25.3651 22.9775 25.1351 22.9775Z'
          fill='currentColor'
        />
        <path
          d='M25.1394 27.7979H10.4333C10.2033 27.7979 10.0168 27.9825 10.0168 28.2103V29.8215C10.0168 30.0493 10.2033 30.2339 10.4333 30.2339H25.1394C25.3694 30.2339 25.5558 30.0493 25.5558 29.8215V28.2103C25.5558 27.9825 25.3694 27.7979 25.1394 27.7979Z'
          fill='currentColor'
        />
        <path
          d='M16.4719 18.1611H10.8845C10.4053 18.1611 10.0168 18.5459 10.0168 19.0204V24.5543C10.0168 25.0288 10.4053 25.4136 10.8845 25.4136H16.4719C16.9511 25.4136 17.3395 25.0288 17.3395 24.5543V19.0204C17.3395 18.5459 16.9511 18.1611 16.4719 18.1611Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_3527:78048'>
          <rect width='29' height='36' fill='transparent' transform='translate(4)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RichText
