import { SVGProps } from 'react'
function ToastInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm-1.234-6h2.31V8.604h-2.31V16zm.218-10.11c-.237.21-.355.478-.355.806 0 .328.118.597.355.807.237.21.545.314.923.314.379 0 .686-.104.923-.314.237-.21.355-.479.355-.807 0-.328-.118-.597-.355-.806-.237-.21-.544-.315-.923-.315-.378 0-.686.105-.923.315z'
        fill='#0075FF'
      />
    </svg>
  )
}

export default ToastInfo
