import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import DefaultFallback from './DefaultFallback'
import { ErrorBoundaryProps } from './types'

/**
 * ErrorBoundary component.
 *
 * Wraps `ErrorBoundary` from 'react-error-boundary' to catch unhandled errors in child components.
 * Uses a default full-screen fallback, which can be overridden with a custom one.
 *
 * @param props The props for the ErrorBoundary component.
 *   - fallback? {ComponentType<FallbackProps> | ReactNode} - Displayed on error (default is the DefaultFallback component).
 *   - onError? {(error: Error, info: { componentStack: string })} - Callback on error (default does nothing).
 *
 * @example
 * ```tsx
 * const CouldBreak = () => {
 *   return <div>Invisible</div>
 * };
 *
 * const ErrorFallback = ({ error }: FallbackProps) => <div>{error.message}</div>
 *
 * // Usage with custom fallback
 * <ErrorBoundary fallback={ErrorFallback}>
 *   <CouldBreak />
 * </ErrorBoundary>
 *
 * // Simpler usage without custom props
 * <ErrorBoundary>
 *   <CouldBreak />
 * </ErrorBoundary>
 * ```
 */

export const ErrorBoundary = ({
  children,
  fallback: FallbackComponent = DefaultFallback,
  onError,
}: ErrorBoundaryProps) => (
  <ReactErrorBoundary
    fallbackRender={(fallbackProps) => <FallbackComponent {...fallbackProps} />}
    onError={onError}>
    {children}
  </ReactErrorBoundary>
)
