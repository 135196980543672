import { fromGlobalId } from '@invisible/concorde/gql-client'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LaunchIcon from '@mui/icons-material/Launch'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

export const InterfaceReadOnlyLink = ({ id, step }: { id: string; step: { name: string } }) => {
  const interfaceHref = `/interface/${fromGlobalId(id)}/read-only`
  const [isHovered, setIsHovered] = useState(false)

  return (
    <li
      key={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ fontWeight: 'bold' }}>
      <Stack
        direction='row'
        className='flex w-screen items-center'
        aria-label='interface-read-only'>
        <div className='flex justify-start'>
          <Tooltip title={step.name} enterDelay={500}>
            <Typography
              noWrap
              variant='body2'
              sx={{
                width: '180px',
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              {step.name}
            </Typography>
          </Tooltip>
        </div>

        <IconButton sx={{ color: 'grey.400' }} href={interfaceHref} target='_blank'>
          <LaunchIcon fontSize='small' />
        </IconButton>

        {isHovered && (
          <Tooltip
            title='Copy URL'
            enterDelay={500}
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}>
            <ContentCopyIcon
              fontSize='small'
              onClick={() =>
                navigator.clipboard.writeText(`${window.location.host}${interfaceHref}`)
              }
              sx={{
                cursor: 'pointer',
                color: 'grey.400',
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </li>
  )
}
