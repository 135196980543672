import {
  fromGlobalId,
  IStepRunWithSnoozeActivitiesQuery,
  toGlobalId,
  useStepRunWithSnoozeActivitiesQuery,
} from '@invisible/concorde/gql-client'

type TSnoozeActivity = {
  id: string
  active: boolean
  snoozedAt: Date
  unsnoozedAt: Date | null
}

const useSnoozeActivitiesFindAllInactiveForStepRun = ({
  stepRunId,
  enabled,
}: {
  stepRunId: string
  enabled?: boolean
}) => {
  const {
    data: graphqlResult,
    isLoading,
    refetch,
  } = useStepRunWithSnoozeActivitiesQuery(
    { stepRunId: toGlobalId('StepRunType', stepRunId) },
    {
      enabled: Boolean(stepRunId) && (enabled ?? true),
    }
  )
  if (graphqlResult && graphqlResult.stepRunsV2.edges.length === 1) {
    return {
      data: parseGraphqlInactiveSnoozeActivities(graphqlResult),
      isLoading,
      refetch,
    }
  }
  return {
    data: [] as TSnoozeActivity[],
    isLoading,
    refetch,
  }
}
const parseGraphqlInactiveSnoozeActivities = (
  stepRunData: IStepRunWithSnoozeActivitiesQuery
): TSnoozeActivity[] => {
  const snoozeActivities = stepRunData.stepRunsV2.edges[0].node.snoozeActivities
  return snoozeActivities
    .map((snoozeActivity) => ({
      id: fromGlobalId(snoozeActivity.id),
      active: snoozeActivity.active,
      snoozedAt: new Date(snoozeActivity.snoozedAt),
      unsnoozedAt: snoozeActivity.unsnoozedAt ? new Date(snoozeActivity.unsnoozedAt) : null,
    }))
    .filter(
      (snoozeActivity) =>
        !snoozeActivity.active &&
        snoozeActivity.unsnoozedAt &&
        snoozeActivity.snoozedAt.getTime() < new Date().getTime()
    )
}
export { useSnoozeActivitiesFindAllInactiveForStepRun }
