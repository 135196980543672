import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useQuery } from '@invisible/trpc/client'
import { CheckCircleOutlineIcon, CloseCircleIcon } from '@invisible/ui/icons'
import { SortTable } from '@invisible/ui/sort-table'
import { theme } from '@invisible/ui/themes'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { FC, useEffect, useMemo, useState } from 'react'

import { getBaseRunVariableFromQueryData } from '../common/helpers'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'

const GREEN = 'rgba(82, 196, 26, 1)'
const RED = 'rgba(255, 77, 79, 1)'
const SOFT_RED = 'rgba(255, 77, 79, 0.2)'

const MAX_PDP = 3
const MAX_CHECKOUT = 8
const MAX_PDP_FREE_TEXT = 2
const MAX_CHECKOUT_FREE_TEXT = 2
const HEADER_NAMES = ['Name', 'Agent 1', 'Agent 2', 'Match']

type TBaseRun = TBaseRunQueryData['items'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

interface CompareBaseRunVariable extends TBaseRunVariable {
  name: string
  baseVariableId: string
}

interface IRow {
  rowName: string
  agentOne: string
  agentTwo: string
  match: boolean
}

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  values: CompareBaseRunVariable[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const KlarnaCompareWAC: FC<IProps> = ({ values, baseRun }) => {
  const { state: wizardState } = useWizardState()
  const [rows, setRows] = useState<IRow[]>([])
  const getValue = (variable: TBaseRunVariable) =>
    (wizardState.wizardInitialBRVs as TBaseRunVariable[]).find(
      (v: TBaseRunVariable) => v.baseVariableId === variable?.baseVariableId
    )?.value

  const typeBaseVariableId = values?.find((v) => v.name === 'type')?.baseVariableId ?? 'simple'
  const pdp1BaseVariableId = values?.find((v) => v.name === 'pdp_1')?.baseVariableId ?? ''
  const pdp2BaseVariableId = values?.find((v) => v.name === 'pdp_2')?.baseVariableId ?? ''
  const checkout1BaseVariableId = values?.find((v) => v.name === 'checkout_1')?.baseVariableId ?? ''
  const checkout2BaseVariableId = values?.find((v) => v.name === 'checkout_2')?.baseVariableId ?? ''
  const notes1BaseVariableId = values?.find((v) => v.name === 'notes_1')?.baseVariableId ?? ''
  const notes2BaseVariableId = values?.find((v) => v.name === 'notes_2')?.baseVariableId ?? ''
  const pdpFreeText1BaseVariableId =
    values?.find((v) => v.name === 'pdp_free_text_1')?.baseVariableId ?? ''
  const pdpFreeText2BaseVariableId =
    values?.find((v) => v.name === 'pdp_free_text_2')?.baseVariableId ?? ''
  const checkoutFreeText1BaseVariableId =
    values?.find((v) => v.name === 'checkout_free_text_1')?.baseVariableId ?? ''
  const checkoutFreeText2BaseVariableId =
    values?.find((v) => v.name === 'checkout_free_text_2')?.baseVariableId ?? ''
  const pdpMatchesBaseVariableId =
    values?.find((v) => v.name === 'pdp_matches')?.baseVariableId ?? ''
  const checkoutMatchesBaseVariableId =
    values?.find((v) => v.name === 'checkout_matches')?.baseVariableId ?? ''

  const { data } = useQuery([
    'baseRunVariable.findByBaseRunIdAndBaseVariableId',
    [
      {
        baseRunId: baseRun.id,
        baseVariableIds: [
          typeBaseVariableId,
          pdp1BaseVariableId,
          pdp2BaseVariableId,
          checkout1BaseVariableId,
          checkout2BaseVariableId,
          notes1BaseVariableId,
          notes2BaseVariableId,
          pdpMatchesBaseVariableId,
          checkoutMatchesBaseVariableId,
          pdpFreeText1BaseVariableId,
          pdpFreeText2BaseVariableId,
          checkoutFreeText1BaseVariableId,
          checkoutFreeText2BaseVariableId,
        ],
      },
    ],
  ])

  const type = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, typeBaseVariableId) ?? [],
    [data, typeBaseVariableId]
  )

  const pdp1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdp1BaseVariableId) ?? [],
    [data, pdp1BaseVariableId]
  )

  const pdp2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdp2BaseVariableId) ?? [],
    [data, pdp2BaseVariableId]
  )

  const checkout1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkout1BaseVariableId) ?? [],
    [data, checkout1BaseVariableId]
  )

  const checkout2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkout2BaseVariableId) ?? [],
    [data, checkout2BaseVariableId]
  )

  const pdpFreeText1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpFreeText1BaseVariableId) ?? [],
    [data, pdpFreeText1BaseVariableId]
  )

  const pdpFreeText2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, pdpFreeText2BaseVariableId) ?? [],
    [data, pdpFreeText2BaseVariableId]
  )

  const checkoutFreeText1 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeText1BaseVariableId) ?? [],
    [data, checkoutFreeText1BaseVariableId]
  )

  const checkoutFreeText2 = useMemo(
    () => getBaseRunVariableFromQueryData<string[]>(data, checkoutFreeText2BaseVariableId) ?? [],
    [data, checkoutFreeText2BaseVariableId]
  )

  const notes1 = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, notes1BaseVariableId) ?? '',
    [data, notes1BaseVariableId]
  )

  const notes2 = useMemo(
    () => getBaseRunVariableFromQueryData<string>(data, notes2BaseVariableId) ?? '',
    [data, notes2BaseVariableId]
  )

  const pdpMatches = useMemo(
    () => getBaseRunVariableFromQueryData<boolean[]>(data, pdpMatchesBaseVariableId) ?? '',
    [data, pdpMatchesBaseVariableId]
  )

  const checkoutMatches = useMemo(
    () => getBaseRunVariableFromQueryData<boolean[]>(data, checkoutMatchesBaseVariableId) ?? '',
    [data, checkoutMatchesBaseVariableId]
  )

  useEffect(() => {
    const rowNames: string[] = Array(type === 'complex' ? MAX_PDP + MAX_CHECKOUT : MAX_PDP)
      .fill('')
      .map((_, i) => (i < MAX_PDP ? `PDP ${i + 1}` : `Checkout ${i + 1 - MAX_PDP}`))

    const newRows = rowNames
      .map(function (
        rowName: string,
        index: number
      ): { rowName: string; agentOne: string; agentTwo: string; match?: boolean } {
        return {
          rowName,
          agentOne: index < MAX_PDP ? pdp1[index] : checkout1[index - MAX_PDP],
          agentTwo: index < MAX_PDP ? pdp2[index] : checkout2[index - MAX_PDP],
          match: index < MAX_PDP ? pdpMatches[index] : checkoutMatches[index - MAX_PDP],
        }
      })
      .concat(
        Array(MAX_PDP_FREE_TEXT)
          .fill({})
          .map((_, i) => ({
            rowName: `PDP Free Text ${i + 1}`,
            agentOne: pdpFreeText1[i],
            agentTwo: pdpFreeText2[i],
          }))
      )
      .concat(
        Array(MAX_CHECKOUT_FREE_TEXT)
          .fill({})
          .map((_, i) => ({
            rowName: `Checkout Free Text ${i + 1}`,
            agentOne: checkoutFreeText1[i],
            agentTwo: checkoutFreeText2[i],
          }))
      )
      .concat([
        {
          rowName: 'Additional Notes',
          agentOne: notes1,
          agentTwo: notes2,
        },
      ]) as IRow[]
    setRows(newRows)
  }, [
    baseRun,
    pdp1,
    pdp2,
    checkout1,
    checkout2,
    notes1,
    notes2,
    pdpFreeText1,
    pdpFreeText2,
    checkoutFreeText1,
    checkoutFreeText2,
    pdpMatches,
    checkoutMatches,
    type,
  ])

  const getRowColor = (match?: boolean): string => (match === false ? SOFT_RED : '')

  return (
    <SortTable>
      <SortTable.Head>
        <SortTable.Row>
          {HEADER_NAMES.map((rowName, index) => (
            <SortTable.Header
              key={index}
              headerBackground={theme.purpleScale[2]}
              textColor={theme.purpleScale[7]}
              textBold={true}>
              {rowName}
            </SortTable.Header>
          ))}
        </SortTable.Row>
      </SortTable.Head>

      <SortTable.Body>
        {rows.map((row: IRow, index: number) => (
          <SortTable.Row key={index} style={{ height: '50px' }}>
            <SortTable.Cell color={getRowColor(row?.match)} textColor={theme.grayScale[9]}>
              {row.rowName}
            </SortTable.Cell>
            <SortTable.Cell color={getRowColor(row?.match)} textColor={theme.grayScale[9]}>
              {row.agentOne}
            </SortTable.Cell>
            <SortTable.Cell color={getRowColor(row?.match)} textColor={theme.grayScale[9]}>
              {row.agentTwo}
            </SortTable.Cell>
            <SortTable.Cell color={getRowColor(row?.match)} textColor={theme.grayScale[9]}>
              {row.match === true && (
                <CheckCircleOutlineIcon color={GREEN} width={30} height={30} />
              )}
              {row.match === false && <CloseCircleIcon color={RED} width={30} height={30} />}
            </SortTable.Cell>
          </SortTable.Row>
        ))}
      </SortTable.Body>
    </SortTable>
  )
}
