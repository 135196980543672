import { SVGProps } from 'react'
function Cycle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='28'
      height='25'
      viewBox='0 0 28 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.60537 1.88259C2.1719 1.88259 2.63116 2.34185 2.63116 2.90838V8.0373H7.76009C8.32661 8.0373 8.78587 8.49656 8.78587 9.06308C8.78587 9.62961 8.32661 10.0889 7.76009 10.0889H1.60537C1.03885 10.0889 0.57959 9.62961 0.57959 9.06308V2.90838C0.57959 2.34185 1.03885 1.88259 1.60537 1.88259Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.23059 1.32128C10.7237 0.702201 12.3241 0.383545 13.9404 0.383545C15.5568 0.383545 17.1572 0.702201 18.6503 1.32128C20.1433 1.94037 21.4997 2.84773 22.6418 3.99148C23.0421 4.39236 23.0416 5.04186 22.6407 5.44216C22.2398 5.84246 21.5903 5.84198 21.19 5.4411C20.2385 4.48818 19.1084 3.7322 17.8645 3.2164C16.6205 2.70061 15.2871 2.43511 13.9404 2.43511C12.5938 2.43511 11.2603 2.70061 10.0164 3.2164C8.77243 3.7322 7.64236 4.48818 6.69083 5.4411L6.68923 5.4427L2.32965 9.78946C1.92846 10.1895 1.27897 10.1885 0.878968 9.78732C0.478964 9.38614 0.479921 8.73665 0.881104 8.33664L5.23909 3.99148L5.2399 3.99067C6.38181 2.84731 7.73788 1.94022 9.23059 1.32128Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.095 16.3205C19.095 15.7539 19.5543 15.2947 20.1208 15.2947H26.2755C26.842 15.2947 27.3013 15.7539 27.3013 16.3205V22.4752C27.3013 23.0417 26.842 23.501 26.2755 23.501C25.709 23.501 25.2497 23.0417 25.2497 22.4752V17.3462H20.1208C19.5543 17.3462 19.095 16.887 19.095 16.3205Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.0019 15.5962C27.4019 15.9974 27.401 16.6469 26.9998 17.0469L22.6418 21.392L22.641 21.3928C21.4991 22.5362 20.143 23.4433 18.6503 24.0622C17.1572 24.6813 15.5568 25 13.9404 25C12.3241 25 10.7237 24.6813 9.23059 24.0622C7.73753 23.4431 6.38117 22.5358 5.23909 21.392C4.83879 20.9911 4.83926 20.3417 5.24015 19.9414C5.64104 19.5411 6.29053 19.5415 6.69083 19.9424C7.64236 20.8953 8.77243 21.6513 10.0164 22.1671C11.2603 22.6829 12.5938 22.9484 13.9404 22.9484C15.2871 22.9484 16.6205 22.6829 17.8645 22.1671C19.1084 21.6513 20.2385 20.8953 21.19 19.9424L25.5512 15.5941C25.9524 15.1941 26.6019 15.195 27.0019 15.5962Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Cycle
