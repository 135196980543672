import { z } from '@invisible/zod'

/**
 * MapJSONToVariableStepMeta step metadata contains a sourceBaseVariable and jmespathMappings
 * These mappings are used to extracting a specific value from sourceBaseVariable
 * and then saving it into another base run variable i.e. baseVariableId
 * The value is extracted from JSON object using [jmespath](https://github.com/jmespath/jmespath.py)
 */
const schema = z.object({
  sourceBaseVariable: z.string().uuid(),
  sampleJsonObject: z.string(),
  jmespathMappings: z.array(
    z.object({
      jmespath: z.string(),
      required: z.boolean(),
      baseVariableId: z.string().uuid(),
      isSelectedFromUI: z.boolean(),
    })
  ),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
