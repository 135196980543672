import Editor from '@monaco-editor/react'
import LaunchIcon from '@mui/icons-material/Launch'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

import { useFetchBaseRunSample } from '../hooks/useFetchBaseRunSample'
import { evalJsonata } from '../jsonataHelpers'
import { options } from '../Preview'
import styles from '../Preview.module.css'

interface IProps {
  ruleName: string
  expression: string
  filterConfigId: string
}

const ReadOnlyPreview = ({ ruleName, expression, filterConfigId }: IProps) => {
  const [result, setResult] = useState('')

  const { baseRunSample } = useFetchBaseRunSample({
    filterConfigId,
    enabled: !!filterConfigId,
  })

  useEffect(() => {
    const evaluateResult = async () => {
      let jsonataResult = ''
      try {
        jsonataResult = await evalJsonata(baseRunSample, expression)
      } catch (err: unknown) {
        jsonataResult = (err as Error).message || String(err)
      }
      setResult(jsonataResult)
    }

    if (Object.keys(baseRunSample).length === 0) return
    evaluateResult()
  }, [baseRunSample, expression])

  return (
    <Stack direction='column' gap={2} className='flex items-start justify-between'>
      <Typography variant='body1' component='span' sx={{ fontWeight: 'bold' }}>
        Preview your export
      </Typography>

      <Stack direction='row' gap={2}>
        <TextField
          disabled
          label='Selected schema *'
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          value={ruleName}
        />
        <Button
          disabled
          sx={{
            fontWeight: 'normal',
            height: '40px',
          }}
          size='small'
          variant='contained'
          endIcon={<LaunchIcon />}>
          {'Edit this schema'}
        </Button>
        <Button
          sx={{
            fontWeight: 'normal',
            height: '40px',
          }}
          size='small'
          variant='text'
          endIcon={<LaunchIcon />}
          href='https://docs.jsonata.org/simple'
          target='_blank'>
          View documentation
        </Button>
      </Stack>

      <br />

      <Stack direction='row' gap={2} width={'100%'} minHeight={'315px'} height={'400px'}>
        <Stack direction='column' gap={1} width={'50%'}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Data Selected
          </Typography>
          <Editor
            language='json'
            theme='light'
            value={JSON.stringify(baseRunSample, null, 2)}
            options={{ ...options, extraEditorClassName: styles['jsonata-input-pane'] }}
          />
        </Stack>

        <Stack direction='column' gap={1} width={'50%'}>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Data transformed by "{ruleName}"
          </Typography>

          <Editor
            language='json'
            theme='light'
            value={result}
            options={{ ...options, extraEditorClassName: styles['jsonata-result-pane'] }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export { ReadOnlyPreview }
