export const UUIDs: Record<string, any> = {
  CasesBase: {
    'Go Live ID': '5c33a426-0a52-433e-a4c1-0a0ebbe3d670',
    'Cust ID': '8a28e85d-adeb-46e8-b058-fa4c4e6dceec',
    'Go Live Name': '9c073b0c-869d-40c6-af7a-42467c20e7e2',
    'Case URL': '97686c0f-1b2f-41a0-8f9e-37102924a5ac',
    'Final Review Type': '031d857a-cb82-4a6b-aafa-dba9cb717a49',
    'Account Name': '759d3751-bc05-4cf2-8408-7ed230575221',
    'Package Status': 'f4c72072-b0c0-43a6-a6e8-5238e0706cad',
    'Virtual Restaurant': 'f16b98f8-0307-4b57-ab86-f41753ebe018',
    'Contains Alcohal': '3f41b4de-4841-4695-8cff-a0f50d2a66c2',
    'External Notes': 'f1fb4c97-9a32-45fc-8f37-29c4f347042a',

    'Go Live Owner': '86d0c8b9-9311-4fb0-a561-8a6655713d4b',
    'Address Copy': '1aca66d0-6c63-4f0c-89ee-febd6ae5e1b2',
    'GH Menu Mate': 'b17b9884-7cc7-4cc4-a86c-9bddd68475ec',
    'GH Entry Method': 'ec10fe2c-886b-4c89-8584-7d09e31e13e9',
    'Menu Item Count': 'bc08018f-499a-44f2-9515-fa7e3933886d',
    Queue: 'b9f31315-9856-45d2-a4ae-d7d9e62288e3',
    'Final Review Rep Name': 'ac677044-7248-4839-8490-eb3080caa699',
    'Final Review Complete': '9fc77b30-d155-48f4-9e17-dd9c7b642670',
    'Process Type': '03d3243b-95d2-482a-bf6c-f960e577988c',
  },
}

export const ITEM_KEYS: Record<string, any> = {
  goLiveID: 'Go Live ID',
  custID: 'Cust ID',
  goLiveName: 'Go Live Name',
  caseUrl: 'Case URL',
  finalReviewType: 'Final Review Type',
  accountName: 'Account Name',
  packageStatus: 'Package Status',
  virtualRestaurant: 'Virtual Restaurant',
  containsAlcohal: 'Contains Alcohal',
  externalNotes: 'External Notes',
  goLiveOwner: 'Go Live Owner',
  addressCopy: 'Address Copy',
  ghMenuMate: 'GH Menu Mate',
  ghEntryMethod: 'GH Entry Method',
  menuItemCount: 'Menu Item Count',
  queue: 'Queue',
  finalReviewRepName: 'Final Review Rep Name',
  finalReviewComplete: 'Final Review Complete',
  processType: 'Process Type',
}

export const FR_TYPE = ['Level 1', 'Level 2', 'Level 3'] as const
