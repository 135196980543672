import { SVGProps } from 'react'
function Saving(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M15.76 8.098a7.048 7.048 0 10-10.084 6.364' stroke='#2CE28B' />
    </svg>
  )
}

export default Saving
