import { SVGProps } from 'react'

function Message(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M18.2192 16.9812C18.2192 18.0018 17.3919 18.8291 16.3713 18.8291C15.3507 18.8291 14.5234 18.0018 14.5234 16.9812C14.5234 15.9606 15.3507 15.1332 16.3713 15.1332C17.3919 15.1332 18.2192 15.9606 18.2192 16.9812Z'
        fill='currentColor'
      />
      <path
        d='M8.97844 18.8291C9.99902 18.8291 10.8264 18.0018 10.8264 16.9812C10.8264 15.9606 9.99902 15.1332 8.97844 15.1332C7.95785 15.1332 7.13051 15.9606 7.13051 16.9812C7.13051 18.0018 7.95785 18.8291 8.97844 18.8291Z'
        fill='currentColor'
      />
      <path
        d='M25.6098 16.9812C25.6098 18.0018 24.7825 18.8291 23.7619 18.8291C22.7413 18.8291 21.914 18.0018 21.914 16.9812C21.914 15.9606 22.7413 15.1332 23.7619 15.1332C24.7825 15.1332 25.6098 15.9606 25.6098 16.9812Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3567 1.02842C13.4669 0.784639 9.62177 1.96908 6.54331 4.35939C3.46485 6.74969 1.36473 10.1815 0.637211 14.0105C-0.0568123 17.6632 0.543819 21.4377 2.32581 24.6882L1.02014 29.2998C0.898514 29.7196 0.891477 30.1644 0.999814 30.5879C1.10867 31.0134 1.32999 31.4018 1.64059 31.7124C1.95118 32.023 2.33961 32.2444 2.76515 32.3532C3.18869 32.4616 3.63349 32.4545 4.05333 32.3329L8.6648 31.0272C11.9153 32.8092 15.6899 33.4098 19.3426 32.7158C23.1716 31.9883 26.6033 29.8882 28.9936 26.8097C31.3839 23.7313 32.5684 19.8862 32.3246 15.9963C32.0808 12.1064 30.4256 8.43932 27.6697 5.68337C24.9137 2.92743 21.2466 1.27219 17.3567 1.02842ZM8.0544 6.30551C10.6588 4.2833 13.9118 3.28126 17.2026 3.4875C20.4935 3.69373 23.5959 5.09407 25.9274 7.42561C28.259 9.75716 29.6593 12.8596 29.8655 16.1504C30.0718 19.4413 29.0697 22.6942 27.0475 25.2986C25.0253 27.903 22.122 29.6797 18.8827 30.2952C15.6433 30.9107 12.2907 30.3226 9.45419 28.6414C9.16372 28.4692 8.81532 28.4238 8.49043 28.5158L3.39433 29.9587L4.8372 24.8626C4.92919 24.5377 4.88379 24.1893 4.71163 23.8988C3.0304 21.0623 2.44232 17.7097 3.05781 14.4704C3.67329 11.231 5.45001 8.32772 8.0544 6.30551Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Message
