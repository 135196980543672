import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { TBaseRunVariable } from '@invisible/common/components/providers/active-wizard-provider'
import { secondsToHMS } from '@invisible/common/date'
import { SnackbarContext } from '@invisible/common/providers'
import {
  IAgentScoresQuery,
  IAgentScoreType,
  parseResponse,
  toGlobalId,
  useAgentScoreCreateMutation,
  useAgentScoreUpdateMutation,
} from '@invisible/concorde/gql-client'
import { Avatar } from '@invisible/ui/avatar'
import { IsOnlineBadge } from '@invisible/ui/mui-theme-v2'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { ManualStepMeta } from '@invisible/ultron/zod'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { isNil } from 'lodash'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { QaMeasurements } from './QaMeasurements'

type TStepRun = inferQueryOutput<'stepRun.findManyStepRunsByQaStepRunIdToScore'>[number]

const StepRunScore = ({
  stepRun,
  submittedQaResult,
  qaStepRunId,
  expandAccordion,
  wizardIsReadOnly,
}: {
  stepRun: TStepRun
  qaStepRunId: string
  expandAccordion: boolean
  submittedQaResult?: Pick<IAgentScoreType, 'id' | 'notes' | 'score'>
  wizardIsReadOnly: boolean
}) => {
  const [qaResult, setQaResult] = useState<{ score: number; notes: string }>({
    score: 1,
    notes: '',
  })
  const { showSnackbar } = useContext(SnackbarContext)

  const [isExpanded, setIsExpanded] = useState(false)
  const { state } = useWizardState()
  const [qaStepMeasurement, setQaStepMeasurement] =
    useState<{ belowTheBar: string; atTheBar: string; aboveTheBar: string }>()

  const reactQueryClient = useQueryClient()

  useEffect(() => {
    if (submittedQaResult)
      setQaResult({
        score: submittedQaResult.score,
        notes: submittedQaResult.notes as string,
      })
  }, [submittedQaResult])

  useEffect(() => {
    if (state && stepRun) {
      const qaConfig = (stepRun?.step.meta as ManualStepMeta.TSchema)?.qaConfig

      if (qaConfig) {
        const matchingCondition = (qaConfig.conditionalMeasurementDescriptions ?? []).find(
          (conditionalMeasurementDescription) => {
            const matchingBaseVariable = (
              (state.wizardInitialBRVs as TBaseRunVariable[]) ?? []
            ).find(
              (brv) =>
                brv?.baseVariable.id === conditionalMeasurementDescription.baseVariableId &&
                brv.value === conditionalMeasurementDescription.value
            )

            return !!matchingBaseVariable
          }
        )
        if (matchingCondition)
          setQaStepMeasurement({
            belowTheBar: matchingCondition.belowTheBar ?? '',
            atTheBar: matchingCondition.atTheBar ?? '',
            aboveTheBar: matchingCondition.aboveTheBar ?? '',
          })
        else {
          const generalDescriptions = qaConfig.measurementDescriptions
          setQaStepMeasurement({
            belowTheBar: generalDescriptions?.belowTheBar ?? '',
            atTheBar: generalDescriptions?.atTheBar ?? '',
            aboveTheBar: generalDescriptions?.aboveTheBar ?? '',
          })
        }
      } else {
        setQaStepMeasurement(undefined)
      }
    }
  }, [state, stepRun])

  const { mutateAsync: agentScoreUpdate, isLoading: isUpdatingAgentScore } =
    useAgentScoreUpdateMutation({
      onSuccess: (data) => {
        const updatedAgentScore = parseResponse(data.agentScoreUpdate, (_, message) => {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        })
        if (!updatedAgentScore) return
        reactQueryClient.setQueryData(
          ['AgentScores', { qaStepRunId: toGlobalId('StepRun', qaStepRunId) }],
          (prevData) => {
            if (!prevData) return prevData
            const prevDataClone = { ...prevData } as IAgentScoresQuery
            if (prevDataClone.agentScores && prevDataClone.agentScores.edges) {
              return {
                ...prevDataClone,
                agentScores: {
                  ...prevDataClone.agentScores,
                  edges: [
                    ...(prevDataClone.agentScores.edges ?? []).map((edge) => ({
                      ...(edge?.node?.id === updatedAgentScore?.id
                        ? {
                            ...(edge ?? {}),
                            node: {
                              ...edge?.node,
                              score: updatedAgentScore?.score,
                              notes: updatedAgentScore?.notes,
                            },
                          }
                        : edge),
                    })),
                  ],
                },
              }
            }
            return prevData
          }
        )
        setIsExpanded(false)
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries([
          'AgentScores',
          { qaStepRunId: toGlobalId('StepRun', qaStepRunId) },
        ])
      },
      onError: () => {
        showSnackbar({
          message: 'Error updating agent score, please try again.',
          variant: 'error',
        })
      },
    })

  const { mutateAsync: agentScoreCreate, isLoading: isCreatingAgentScore } =
    useAgentScoreCreateMutation({
      onSuccess: (data) => {
        const createdAgentScore = parseResponse(data.agentScoreCreate, (_, message) => {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        })
        if (!createdAgentScore) return

        reactQueryClient.setQueryData(
          ['AgentScores', { qaStepRunId: toGlobalId('StepRun', qaStepRunId) }],
          (prevData) => {
            if (!prevData) return prevData
            const prevDataClone = { ...prevData } as IAgentScoresQuery
            if (prevDataClone.agentScores && prevDataClone.agentScores.edges) {
              return {
                ...prevDataClone,
                agentScores: {
                  ...prevDataClone.agentScores,
                  edges: [
                    ...(prevDataClone.agentScores.edges ?? []).map((edge) => ({
                      ...(edge?.node?.id === createdAgentScore?.id
                        ? {
                            ...(edge ?? {}),
                            node: {
                              ...edge?.node,
                              score: createdAgentScore?.score,
                              notes: createdAgentScore?.notes,
                            },
                          }
                        : edge),
                    })),
                  ],
                },
              }
            }
            return prevData
          }
        )
        setIsExpanded(false)
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries([
          'AgentScores',
          { qaStepRunId: toGlobalId('StepRun', qaStepRunId) },
        ])
      },
      onError: () => {
        showSnackbar({
          message: 'Error creating agent score, please try again.',
          variant: 'error',
        })
      },
    })

  const handleSaveQaResult = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!submittedQaResult) {
      await agentScoreCreate({
        score: qaResult.score,
        ...(qaResult.notes ? { notes: qaResult.notes } : {}),
        stepRunId: stepRun.id,
        qaStepRunId,
      })
    } else {
      await agentScoreUpdate({
        id: submittedQaResult.id,
        score: qaResult.score,
        ...(qaResult.notes ? { notes: qaResult.notes } : {}),
      })
    }
  }

  return (
    <Accordion key={stepRun.id} elevation={0} expanded={isExpanded || expandAccordion}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        onClick={() => setIsExpanded((prev) => !prev)}>
        <Grid container sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
          <Grid item md={4}>
            <Stack direction='row' gap='4px' alignItems='center'>
              <CheckCircleOutlineIcon color={submittedQaResult ? 'success' : 'disabled'} />
              <Typography fontWeight='bold' fontSize='14px'>
                {stepRun.step.name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack direction='row' gap={1} alignItems='center'>
              <IsOnlineBadge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                isOnline={stepRun?.assignee?.lastActivity === 'online'}
                variant='dot'>
                <Avatar size={24} src={stepRun?.assignee?.image ?? undefined} />
              </IsOnlineBadge>
              <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 0, marginTop: '2px' }}>
                <Typography noWrap fontSize='12px' lineHeight='12px'>
                  {stepRun?.assignee?.preferredName ?? stepRun?.assignee?.name}
                </Typography>
                <Typography
                  noWrap
                  variant='body2'
                  fontSize='12px'
                  lineHeight='12px'
                  color='text.secondary'>
                  {`${stepRun?.assignee?.email?.split('@')[0]}@`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack sx={{ display: 'flex', alignItems: 'center' }}>
              {!isNil(submittedQaResult?.score) ? (
                <Chip
                  sx={{ alignSelf: 'end', fontSize: '10px' }}
                  color={
                    submittedQaResult?.score === 0
                      ? 'error'
                      : submittedQaResult?.score === 1
                      ? 'default'
                      : submittedQaResult?.score === 2
                      ? 'success'
                      : 'default'
                  }
                  label={
                    submittedQaResult?.score === 0
                      ? 'Below the bar'
                      : submittedQaResult?.score === 1
                      ? 'At the bar'
                      : submittedQaResult?.score === 2
                      ? 'Exceeds expectations'
                      : ''
                  }
                />
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSaveQaResult}>
          <Stack gap='16px'>
            <Stack>
              <Stack direction='row' gap='4px' alignItems='center'>
                <Typography fontWeight='bold' fontSize='14px'>
                  Quality Score
                </Typography>
                <Tooltip title={<QaMeasurements qaStepMeasurement={qaStepMeasurement} />}>
                  <ErrorOutlineIcon color='disabled' sx={{ height: '16px', width: '16px' }} />
                </Tooltip>
              </Stack>
              <Stack direction='row' gap='4px'>
                <Typography fontSize='12px'>Handling time:</Typography>
                <Typography fontWeight='bold' fontSize='12px'>
                  {secondsToHMS(stepRun.handlingTime?.handlingTime)}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <ButtonGroup variant='outlined' fullWidth aria-label='outlined primary button group'>
                <Button
                  variant={qaResult.score === 0 ? 'contained' : 'outlined'}
                  onClick={() =>
                    setQaResult((prev) => ({
                      ...prev,
                      score: 0,
                    }))
                  }
                  disabled={wizardIsReadOnly}
                  size='small'
                  color='error'>
                  Below the bar (Fail)
                </Button>
                <Button
                  variant={qaResult.score === 1 ? 'contained' : 'outlined'}
                  size='small'
                  color='inherit'
                  disabled={wizardIsReadOnly}
                  onClick={() =>
                    setQaResult((prev) => ({
                      ...prev,
                      score: 1,
                    }))
                  }>
                  At the bar (Pass)
                </Button>
                <Button
                  size='small'
                  variant={qaResult?.score === 2 ? 'contained' : 'outlined'}
                  disabled={wizardIsReadOnly}
                  onClick={() =>
                    setQaResult((prev) => ({
                      ...prev,
                      score: 2,
                    }))
                  }
                  color='success'>
                  Exceeds expectations
                </Button>
              </ButtonGroup>
              <TextField
                label='Provide personalized feedback to this agent'
                multiline
                rows={4}
                sx={{ fontSize: '12px' }}
                disabled={wizardIsReadOnly}
                error={qaResult?.score === 0 && !qaResult?.notes}
                value={qaResult.notes}
                onChange={(event) =>
                  setQaResult((prev) => ({ ...prev, notes: event.target.value }))
                }
              />
            </Stack>
            <LoadingButton
              variant='contained'
              sx={{ alignSelf: 'end' }}
              type='submit'
              loading={isCreatingAgentScore || isUpdatingAgentScore}
              disabled={
                (qaResult?.score === 0 && !qaResult?.notes) ||
                isCreatingAgentScore ||
                isUpdatingAgentScore ||
                wizardIsReadOnly ||
                (qaResult.score === submittedQaResult?.score &&
                  qaResult.notes === submittedQaResult?.notes)
              }>
              Save Quality Scoring
            </LoadingButton>
          </Stack>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export { StepRunScore }
