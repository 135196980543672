import { SVGProps } from 'react'
function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.384766 12C0.384766 11.4477 0.832481 11 1.38477 11H23.3848C23.937 11 24.3848 11.4477 24.3848 12C24.3848 12.5523 23.937 13 23.3848 13H1.38477C0.832481 13 0.384766 12.5523 0.384766 12Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3848 0C12.9371 0 13.3848 0.447715 13.3848 1V23C13.3848 23.5523 12.9371 24 12.3848 24C11.8325 24 11.3848 23.5523 11.3848 23V1C11.3848 0.447715 11.8325 0 12.3848 0Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Plus
