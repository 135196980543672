import { SVGProps } from 'react'
function CalendarFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M22.356 2H19.356V1C19.356 0.734784 19.2506 0.48043 19.0631 0.292893C18.8755 0.105357 18.6212 0 18.356 0C18.0907 0 17.8364 0.105357 17.6488 0.292893C17.4613 0.48043 17.356 0.734784 17.356 1V2H7.35596V1C7.35596 0.734784 7.2506 0.48043 7.06306 0.292893C6.87553 0.105357 6.62117 0 6.35596 0C6.09074 0 5.83639 0.105357 5.64885 0.292893C5.46131 0.48043 5.35596 0.734784 5.35596 1V2H2.35596C1.82552 2 1.31682 2.21071 0.941743 2.58579C0.566671 2.96086 0.355957 3.46957 0.355957 4V24C0.355957 24.5304 0.566671 25.0391 0.941743 25.4142C1.31682 25.7893 1.82552 26 2.35596 26H22.356C22.8864 26 23.3951 25.7893 23.7702 25.4142C24.1452 25.0391 24.356 24.5304 24.356 24V4C24.356 3.46957 24.1452 2.96086 23.7702 2.58579C23.3951 2.21071 22.8864 2 22.356 2ZM22.356 8H2.35596V4H5.35596V5C5.35596 5.26522 5.46131 5.51957 5.64885 5.70711C5.83639 5.89464 6.09074 6 6.35596 6C6.62117 6 6.87553 5.89464 7.06306 5.70711C7.2506 5.51957 7.35596 5.26522 7.35596 5V4H17.356V5C17.356 5.26522 17.4613 5.51957 17.6488 5.70711C17.8364 5.89464 18.0907 6 18.356 6C18.6212 6 18.8755 5.89464 19.0631 5.70711C19.2506 5.51957 19.356 5.26522 19.356 5V4H22.356V8Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CalendarFilled
