import { useContext } from 'react'

import { StoryDynamicFiltersContext } from '../providers'

const useStoryDynamicFilters = () => {
  const context = useContext(StoryDynamicFiltersContext)
  if (!context)
    throw new Error(
      'useStoryDynamicFilters is only usable as a child to StoryDynamicFiltersProvider'
    )
  return context
}

export { useStoryDynamicFilters }
