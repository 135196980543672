import {
  TProcessWithBases,
  TUserPermission,
  useGetLoggedInUser,
  useLoggedInUserPermissions,
  useStepRunAssignAvailableToUser,
} from '@invisible/common/components/process-base'
import { WizardStateProvider } from '@invisible/common/components/providers/active-wizard-provider'
import { useStore } from '@invisible/common/stores/process-store'
import { fromGlobalId } from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { useToasts } from '@invisible/ui/toasts'
import { BaseViewMeta } from '@invisible/ultron/zod'
import { isUndefined } from 'lodash/fp'
import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { Base } from './Base'
import { BasePageSkeleton } from './BasePageSkeleton'
import { useLifecycleStageFindManyByProcessId } from './hooks/useLifecycleStageFindManyByProcessId'
import { ManualTriggerWizard } from './ManualTriggerWizard'
import { SelectedEmbedLinkProvider } from './providers/SelectedEmbedLinkProvider'
import {
  StepRunAssignmentProvider,
  useStepRunAssignment,
} from './providers/StepRunAssignmentProvider'
import { Wizard } from './Wizard'

interface IProps {
  processData: TProcessWithBases
  showImportWizard?: boolean
  showGetNextTask?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ProcessBaseComponent: React.FC<IProps> = ({
  processData,
  showImportWizard,
  showGetNextTask,
}) => {
  const [baseId, setBaseId] = useQueryParam(...BASE_ID_ARGS)
  const [, setBaseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)

  const { setFilterOption } = useStore(
    useCallback(
      (state) => ({
        setFilterOption: state.setFilterOption,
      }),
      []
    ),
    shallow
  )

  const { addToast } = useToasts()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { dispatch: dispatchStepRunAssignment } = useStepRunAssignment()

  const { mutateAsync: autoAssignMutation, isLoading: isAutoAssignStepRunLoading } =
    useStepRunAssignAvailableToUser({
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    })

  const { data: stages } = useLifecycleStageFindManyByProcessId({
    processId: processData?.id,
  })

  const { data: loggedInUser } = useGetLoggedInUser()

  const { data: baseViewPermissions, isLoading: isPermissionsLoading } = useLoggedInUserPermissions(
    {
      onlyBaseView: true,
    }
  )

  const getNextTaskHandler = async () => {
    if (processData && loggedInUser?.id) {
      const assigneeId = loggedInUser.id
      try {
        const stepRun = await autoAssignMutation({
          processId: processData.id,
          assigneeId,
        })
        if (stepRun) {
          const stepRunBase = processData?.bases?.find((b) => b.id === stepRun.baseRun.baseId)
          // Tries to find a base view tagged to the same stage as the stepRun with no filters,
          // If that doesn't exist, it tries to find the "All" base view which has no stageId and no filters.
          const stepRunBaseView =
            stepRunBase?.baseViews?.find(
              (b) =>
                b.lifecycleStageId === stepRun.lifecycleStageId &&
                !(b.config as BaseViewMeta.TSchema)?.filters?.length &&
                ((baseViewPermissions ?? []) as TUserPermission[]).some(
                  (permission) =>
                    permission.action === 'read' &&
                    permission.subject === 'BaseView' &&
                    typeof permission.conditions === 'object' &&
                    permission.conditions !== null &&
                    'id' in permission.conditions &&
                    permission.conditions?.id === b.id
                )
            ) ??
            stepRunBase?.baseViews?.find(
              (b) =>
                !b.lifecycleStageId &&
                !(b.config as BaseViewMeta.TSchema)?.filters?.length &&
                ((baseViewPermissions ?? []) as TUserPermission[]).some(
                  (permission) =>
                    permission.action === 'read' &&
                    permission.subject === 'BaseView' &&
                    typeof permission.conditions === 'object' &&
                    permission.conditions !== null &&
                    'id' in permission.conditions &&
                    permission.conditions?.id === b.id
                )
            )

          if (stepRunBase) {
            setBaseId(stepRunBase.id)
          }
          if (stepRunBaseView) {
            setBaseViewId(stepRunBaseView.id)
          }

          reactQueryContext.invalidateQueries('baseView.getColumnOptions')
          setFilterOption([
            {
              id: fromGlobalId(stepRun.stepId),
              value: [loggedInUser.id || ''],
              isStepAssignee: true,
            },
          ])

          // below might be used to auto open the wizard in the future
          dispatchStepRunAssignment({
            type: 'setAutoAssignedStepRunId',
            value: stepRun?.id ?? null,
          })
          return
        }
      } catch (error: any) {
        sendErrorToSentry(error)
        addToast(`Could not assign a new task: ${error?.message}`, { appearance: 'error' })
      }
    }
  }

  useEffect(() => {
    if (processData?.bases.length && !baseId) setBaseId(processData?.bases[0]?.id)
  }, [baseId, processData?.bases, setBaseId])

  const handleBaseChange = (baseId: string | number) => {
    setBaseId(baseId as string)
  }

  if (isUndefined(processData) || isPermissionsLoading) {
    return <BasePageSkeleton />
  }

  if (processData === null) {
    return <h1>No process found</h1>
  }

  return (
    <WizardStateProvider>
      <SelectedEmbedLinkProvider>
        <div className='border-weak flex justify-between border-0 border-b border-solid'>
          <div className='translate-y-[1px]'>
            {processData.bases.length ? (
              <Tabs
                onChange={handleBaseChange}
                orientation='horizontal'
                size='medium'
                value={baseId || ''}>
                {processData.bases.map((base: any) => (
                  <Tab name={base.name} value={base.id} key={base.id} />
                ))}
              </Tabs>
            ) : null}
          </div>
          <div className='flex items-center gap-2 px-2'>
            {showImportWizard && <ManualTriggerWizard processData={processData} />}
            {showGetNextTask && (
              <Button
                data-dd-action-name='work-get-next-task'
                variant='secondary'
                size='md'
                onClick={getNextTaskHandler}
                loading={isAutoAssignStepRunLoading}>
                Get Next Task
              </Button>
            )}
          </div>
        </div>
        {processData.bases.length
          ? processData.bases.map((base: any) =>
              base.id === baseId ? (
                <div className='flex h-full flex-col p-2'>
                  <Base
                    baseViewPermissions={(baseViewPermissions ?? []) as TUserPermission[]}
                    base={base}
                    stages={stages ?? []}
                  />
                </div>
              ) : null
            )
          : null}

        <Wizard />
      </SelectedEmbedLinkProvider>
    </WizardStateProvider>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ProcessBase: React.FC<IProps> = (props) => (
  <StepRunAssignmentProvider>
    <ProcessBaseComponent {...props} />
  </StepRunAssignmentProvider>
)
