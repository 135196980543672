import { theme } from '@invisible/ui/mui-theme-v2'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { ChangeEvent } from 'react'

import {
  formatHourInputValue,
  formatMinuteInputValue,
  parseToLocaleString,
  parseToNumber,
} from './helpers'

const TimeInput = ({
  hour,
  minute,
  onChange,
}: {
  hour?: number
  minute?: number
  onChange: (hour: string, minute: string) => void
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    const inputValue = event.target.value
    let numericValue = parseToNumber(inputValue)

    if (isNaN(numericValue) || numericValue < 0) {
      numericValue = 0 // Default to 0 if parsing fails
    }

    if (type === 'hour') onChange(formatHourInputValue(numericValue), String(minute ?? '0'))
    if (type === 'minute') onChange(String(hour ?? '0'), formatMinuteInputValue(numericValue))
  }

  const handleIncrementClick = (value: string, type: 'hour' | 'minute') => {
    const numericValue = parseToNumber(value)

    if (isNaN(numericValue) || numericValue < 0) return

    if (type === 'hour')
      return onChange(formatHourInputValue(numericValue + 1), String(minute ?? '0'))

    return onChange(String(hour ?? '0'), formatMinuteInputValue(numericValue + 1))
  }

  const handleDecrementClick = (value: string, type: 'hour' | 'minute') => {
    const numericValue = parseToNumber(value)

    if (isNaN(numericValue) || numericValue < 0) return

    if (type === 'hour')
      return onChange(formatHourInputValue(numericValue - 1), String(minute ?? '0'))

    return onChange(String(hour ?? '0'), formatMinuteInputValue(numericValue - 1))
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='flex w-[350px] items-center gap-2'>
        <TextField
          fullWidth
          label='Hours'
          type='text'
          size='small'
          value={parseToLocaleString(Number(hour ?? '0'))}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'hour')}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  size='small'
                  aria-label='decrement-hour'
                  onClick={() => handleDecrementClick(String(hour ?? '0'), 'hour')}>
                  <RemoveIcon fontSize='inherit' />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  aria-label='increment-hour'
                  onClick={() => handleIncrementClick(String(hour ?? '0'), 'hour')}>
                  <AddIcon fontSize='inherit' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          label='Minutes'
          type='text'
          size='small'
          value={parseToLocaleString(Number(minute ?? '0'))}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange(event, 'minute')}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  size='small'
                  aria-label='decrement-minute'
                  onClick={() => handleDecrementClick(String(minute ?? '0'), 'minute')}>
                  <RemoveIcon fontSize='inherit' />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  aria-label='increment-minute'
                  onClick={() => handleIncrementClick(String(minute ?? '0'), 'minute')}>
                  <AddIcon fontSize='inherit' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </ThemeProvider>
  )
}

export { TimeInput }
