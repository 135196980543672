import { SVGProps } from 'react'
function Filters(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={22}
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M16.895 2.896v18.566M9.896 2.896v18.566M2.896 2.896v18.566' stroke='currentColor' />
      <circle cx={9.88} cy={14.69} r={2.396} fill='currentColor' stroke='#666' />
      <circle cx={16.895} cy={2.896} r={2.396} fill='currentColor' stroke='#666' />
      <circle cx={2.896} cy={9.283} r={2.396} fill='currentColor' stroke='#666' />
    </svg>
  )
}

export default Filters
