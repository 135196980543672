import { useClickAway } from '@invisible/hooks/use-click-away'
import { SmallCheckbox } from '@invisible/ui/form'
import { FilledFilterIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Text } from '@invisible/ui/text'
import { gray, styled, useTheme } from '@invisible/ui/themes'
import { isEqual } from 'lodash/fp'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { UseFiltersColumnProps } from 'react-table'
import { Box, Flex } from 'rebass'

import { IconContainer } from '../ui-react-table'

const Container = styled.div`
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 170px;
  height: 220px;
  border-radius: 2px;
  padding: 10px 0;
  overflow: auto;
  left: 0;
  top: 50px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`

const DropdownItem = styled(Flex)`
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid ${gray(4)};
`
interface IProps {
  column: UseFiltersColumnProps<any> & { id: number | string }
}

const FilterDropdown = ({
  column: { filterValue: defaultFilter, setFilter, preFilteredRows, id },
  formatOption,
  filterDropdownOptionGetter,
  customOptions,
}: IProps & {
  formatOption?: (value: string) => string
  filterDropdownOptionGetter?: (option: any) => void
  customOptions?: Record<string, string[]>
}) => {
  const filterValue = typeof defaultFilter !== 'string' ? defaultFilter : [defaultFilter]
  const { fontSizes, grayScale, textColor } = useTheme()
  const [filterOpen, setFilterOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const ref = useRef<null | HTMLDivElement>(null)

  const preFilteredOptions = useMemo(() => {
    const options = new Set()
    if (customOptions) {
      customOptions[id as string]?.forEach((option) => {
        options.add(String(option))
      })
    } else {
      preFilteredRows.forEach((row) => {
        options.add(
          String(
            filterDropdownOptionGetter
              ? filterDropdownOptionGetter(row.values[id as number])
              : row.values[id as number]
          )
        )
      })
    }
    return Array.from(options)
  }, [id, preFilteredRows]) as string[]
  const options = useMemo(
    () => preFilteredOptions.filter((e) => e.toLowerCase().includes(searchText.toLowerCase())),
    [preFilteredOptions, searchText]
  )
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    if (!isEqual(filterValue, selected)) setSelected((filterValue as string[]) ?? [])
  }, [filterValue])

  useEffect(() => {
    setFilter(selected)
  }, [selected.length])

  useClickAway(ref, () => {
    setFilterOpen(false)
  })

  return (
    <Box ref={ref}>
      <IconContainer ml='5px' alignItems='center' visible={filterValue?.length > 0}>
        <FilledFilterIcon
          width={14}
          height={14}
          color={filterValue?.length > 0 ? 'black' : grayScale[5]}
          onClick={() => setFilterOpen((prev) => !prev)}
        />
      </IconContainer>

      {filterOpen ? (
        <Container>
          <Box px='10px' pb='10px'>
            <Input
              prefix='SearchOutlineIcon'
              placeholder='Search...'
              size='medium'
              value={searchText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchText(e?.target?.value ?? '')
              }}
            />
          </Box>
          {options
            .sort((a, b) => a.localeCompare(b))
            .map((option) => (
              <DropdownItem key={option}>
                <SmallCheckbox
                  checked={selected.includes(option)}
                  onClick={(checked) => {
                    if (checked) {
                      setSelected((prev) => [...prev, option])
                    } else {
                      setSelected((prev) => prev.filter((item) => item !== option))
                    }
                  }}
                />
                {formatOption ? (
                  formatOption(option)
                ) : (
                  <Text color={textColor.primary} fontSize={fontSizes[0]}>
                    {option}
                  </Text>
                )}
              </DropdownItem>
            ))}
        </Container>
      ) : null}
    </Box>
  )
}

export { FilterDropdown }
