import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { createContext, useContext } from 'react'

type TLoaders = {
  isFetchingResponses: boolean
  isCreatingManyBaseRuns: boolean
  isCreatingBaseRun: boolean
  isDeletingBaseRuns: boolean
  isUpdatingBaseVariables: boolean
}

export type RLHFContextDataType = {
  config: WizardSchemas.RLHFPlus.TSchema
  numOfPrompts: number
  stepRunId?: string
  baseRunId?: string
  firstManualStepRunCreatedAt: Date | string
  promptMetadataValidationFailures?: string[]
  responseMetadataValidationFailures?: string[]
  visibleResponseIndices?: number[]
  isReadOnly?: boolean
  loaders?: TLoaders
  setIsUpdated?: (isUpdated: boolean) => void
  updateBaseRunVariables: (
    data: {
      baseRunId: string
      baseVariableId: string
      value: any
    }[]
  ) => Promise<any>
  resubmitPrompt: (prompt: { id: string; text: string }) => Promise<any>
  submitPrompt: (text: string) => Promise<any>
  refetchPromptResponses: (prompt: { id: string; text: string }) => Promise<any>
  deletePrompt: (prompt: { id: string; responseId?: string }) => Promise<any>
}

export const RLHFContext = createContext<RLHFContextDataType | Record<PropertyKey, never>>({})

export const useRLHFContext = () => useContext(RLHFContext)
