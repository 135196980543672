import { useStore } from '@invisible/common/stores/process-store'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { Table, TableStyles } from '@invisible/ui/table'
import type { BaseRunStatus } from '@invisible/ultron/prisma'
import { HeaderContext } from '@tanstack/react-table'
import { CSSProperties, FC, ReactNode, useCallback } from 'react'
import { Box, Flex } from 'rebass'
import shallow from 'zustand/shallow'

import { DateFilter } from './DateFilter'
import { FilterDropdown } from './FilterDropdown'

const baseRunStatuses: BaseRunStatus[] = [
  'pending',
  'running',
  'failed',
  'done',
  'disabled',
  'snoozed',
]

interface IHeaderCellCustomSortProps {
  name: string
  headerContext: HeaderContext<Record<string, any>, any> & { icon?: ReactNode }
  sortId?: string
  filter?: 'normal' | 'datetime'
  timezone?: string
  updateTimezone?: (value: string) => void
  isStepStatus?: boolean
  isStepAssignee?: boolean
  stepRunIds?: string[]
  isParentBaseRunId?: boolean
  isLeftSide?: boolean
  isBaseRunStatus?: boolean
  icon?: ReactNode
  className?: string
  style?: CSSProperties // We need style to override default width fit-content when needed
}

// This component is used for sorting and filtering manually (like on backend for example)

// eslint-disable-next-line @typescript-eslint/ban-types
const HeaderCellCustomSort: FC<IHeaderCellCustomSortProps> = ({
  name,
  headerContext,
  sortId,
  filter = 'normal',
  isStepStatus,
  isStepAssignee,
  isParentBaseRunId,
  isLeftSide,
  isBaseRunStatus,
  timezone,
  updateTimezone,
  icon,
  className,
  stepRunIds,
  ...props
}) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const { sortOption, filterableColumns, setSortOption, stageId } = useStore(
    useCallback(
      (state) => ({
        sortOption: state.sortOption,
        filterableColumns: state.filterableColumns,
        setSortOption: state.setSortOption,
        stageId: state.stageId,
      }),
      []
    ),
    shallow
  )

  const sortDirection = sortId ? (sortOption?.[sortId] as 'desc' | 'asc' | undefined) : undefined

  const setSortDirection = sortId
    ? (value: 'asc' | 'desc' | undefined) => {
        setSortOption({
          [sortId]: value,
        })
      }
    : undefined

  return (
    <div
      className={`relative box-border flex w-fit min-w-[150px] items-center ${className ?? ''}`}
      {...props}>
      <Flex alignItems='center'>
        {icon ? <Box mr={1}>{icon}</Box> : null}

        <div className='text-theme-main text-left text-xs font-bold uppercase tracking-wide'>
          {name}
        </div>
        {setSortDirection && sortId ? (
          <div
            className='h-[22px] cursor-pointer items-center pl-1'
            onClick={() => {
              setSortDirection(
                sortDirection === 'desc' ? 'asc' : sortDirection === 'asc' ? undefined : 'desc'
              )
            }}>
            <TableStyles.SortIcon
              direction={sortDirection ? sortDirection : undefined}
              className='ml-2 h-5 shrink-0 cursor-pointer'
            />
          </div>
        ) : null}

        {filter === 'normal' &&
        (filterableColumns?.find((filter: string) => filter === headerContext.column.id) ||
          isStepAssignee ||
          isStepStatus ||
          isParentBaseRunId ||
          isBaseRunStatus) ? (
          <FilterDropdown
            headerContext={headerContext}
            baseId={baseId}
            baseViewId={baseViewId}
            isStepAssignee={isStepAssignee}
            isStepStatus={isStepStatus}
            isBaseRunStatus={isBaseRunStatus}
            formatOption={
              isStepAssignee
                ? (option) =>
                    option === 'Unassigned' ? (
                      'Unassigned'
                    ) : (
                      <div
                        className='text-theme-main'
                        title={`${option?.preferredName ?? option?.name} ${
                          option?.email?.split('@')[0]
                        }@`}>
                        {`${option?.preferredName ?? option?.name} `}
                        <span className='text-paragraphs'>{`${
                          option?.email?.split('@')[0]
                        }@`}</span>
                      </div>
                    )
                : undefined
            }
            customOptions={
              isBaseRunStatus
                ? {
                    baseRunStatus: stageId
                      ? // If it's a stage baseView, we already filter out in code the cancelled baseRuns
                        baseRunStatuses.filter((s) => s !== 'disabled')
                      : baseRunStatuses,
                  }
                : undefined
            }
          />
        ) : null}
        {filter === 'datetime' ? (
          <DateFilter
            columnId={headerContext.column.id}
            positionX={isLeftSide ? 'right' : 'center'}
          />
        ) : null}
        {timezone && updateTimezone ? (
          <Table.Timezone timezone={timezone} updateTimezone={updateTimezone} />
        ) : null}
      </Flex>
    </div>
  )
}

export { HeaderCellCustomSort }
