import { InvisibleIcon as Circle } from '@invisible/ui/icons'
import { keyframes, styled, textColor } from '@invisible/ui/themes'
import { FC } from 'react'

export interface ICircle {
  width?: number
  height?: number
  color?: string
  className?: string
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const Logo = styled(Circle)<ICircle>`
  animation: ${spin} 1s linear infinite;
  color: ${(color) => (color ? `${color}` : textColor('title'))};
  margin-right: 5px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const LogoSpinner: FC<ICircle> = ({ height = 100, width = 100, ...props }) => (
  <Logo {...props} height={height} width={width} />
)

export { LogoSpinner }
