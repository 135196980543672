/**
 * Returns the steps that are before the target step
 * It does so by traversing the stepGoTos array
 * Finds possible paths to the target step
 * @param stepGoTos
 * @param targetStepId
 */

export const getStepsBefore = ({
  stepGoTos,
  targetStepId,
}: {
  stepGoTos: { goToStepId: string; goFromStepId: string }[]
  targetStepId: string
}) => {
  const stepsBefore: string[] = []
  const visitedSteps = new Set<string>()

  function traverse(currentStep: string) {
    for (const step of stepGoTos) {
      if (step.goToStepId === currentStep && !visitedSteps.has(step.goFromStepId)) {
        visitedSteps.add(step.goFromStepId)
        stepsBefore.unshift(step.goFromStepId)
        traverse(step.goFromStepId)
      }
    }
  }

  traverse(targetStepId)

  return stepsBefore
}
