import { SVGProps } from 'react'

function Link(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.6602 16.107C13.6199 16.0671 13.5655 16.0447 13.5088 16.0447C13.4522 16.0447 13.3978 16.0671 13.3575 16.107L10.245 19.2195C8.80392 20.6606 6.37178 20.8132 4.78071 19.2195C3.18696 17.6257 3.33963 15.1963 4.78071 13.7552L7.89321 10.6427C7.97624 10.5597 7.97624 10.4231 7.89321 10.34L6.82713 9.27395C6.78686 9.23407 6.73247 9.2117 6.6758 9.2117C6.61912 9.2117 6.56473 9.23407 6.52446 9.27395L3.41196 12.3864C1.14588 14.6525 1.14588 18.3195 3.41196 20.5829C5.67803 22.8463 9.34499 22.8489 11.6084 20.5829L14.7209 17.4704C14.8039 17.3873 14.8039 17.2507 14.7209 17.1677L13.6602 16.107ZM20.587 3.41055C18.3209 1.14448 14.6539 1.14448 12.3905 3.41055L9.27535 6.52305C9.23547 6.56333 9.2131 6.61771 9.2131 6.67439C9.2131 6.73107 9.23547 6.78546 9.27535 6.82573L10.3387 7.88913C10.4218 7.97216 10.5584 7.97216 10.6414 7.88913L13.7539 4.77662C15.195 3.33555 17.6271 3.18287 19.2182 4.77662C20.812 6.37037 20.6593 8.79984 19.2182 10.2409L16.1057 13.3534C16.0658 13.3937 16.0435 13.4481 16.0435 13.5048C16.0435 13.5614 16.0658 13.6158 16.1057 13.6561L17.1718 14.7222C17.2548 14.8052 17.3914 14.8052 17.4745 14.7222L20.587 11.6097C22.8503 9.34359 22.8503 5.67662 20.587 3.41055V3.41055ZM14.6271 8.25609C14.5869 8.21621 14.5325 8.19384 14.4758 8.19384C14.4191 8.19384 14.3647 8.21621 14.3245 8.25609L8.25749 14.3204C8.21761 14.3607 8.19524 14.415 8.19524 14.4717C8.19524 14.5284 8.21761 14.5828 8.25749 14.6231L9.31821 15.6838C9.40124 15.7668 9.53785 15.7668 9.62089 15.6838L15.6852 9.61948C15.7682 9.53645 15.7682 9.39984 15.6852 9.3168L14.6271 8.25609Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Link
