import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  toGlobalId,
  useStepRunAssignAvailableToUserMutation,
} from '@invisible/concorde/gql-client'
import { parseResponse } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from 'react'

interface StepRunAssignAvailableToUserInput {
  processId?: string
  prioritizedStepId?: string
  assigneeId?: string
}

interface StepRunAssignAvailableToUserResponse {
  id: string
  status: string
  baseRun: {
    id: string
    base: {
      id: string
    }
  }
  lifecycleStage?: {
    id: string
  } | null
  step: {
    id: string
    name: string
    meta?: string
    process: {
      id: string
    }
  }
}

type OnMutateHandler = (variables: StepRunAssignAvailableToUserInput) => void

const useStepRunAssignAvailableToUser = ({
  onSuccess: handleOnSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: (data: any) => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: userAssignStepRun, isLoading } = useStepRunAssignAvailableToUserMutation({
    onError: (error, variables) => {
      const errorMessage = getErrorMessage(error)
      // unify error handling
      const _error = new Error(errorMessage)
      const { prioritizedStepId, processId } = variables
      if (handleError) {
        handleError(_error)
      } else {
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      }
      logger.error(
        `Mutating from useStepRunAssignAvailableToUserMutation via GraphQL resulted in an error: ${errorMessage}`,
        {
          prioritizedStepId: fromGlobalId(prioritizedStepId),
          processId: fromGlobalId(processId),
        }
      )
    },
    onSuccess: (data, variables) => {
      const { prioritizedStepId, processId } = variables
      if (data.stepRunAssignAvailableToUser) {
        handleOnSuccess?.(
          mapGraphQLData(data.stepRunAssignAvailableToUser as StepRunAssignAvailableToUserResponse)
        )
      }

      logger.info('Mutating from useAssignAvailableStepRunToUserMutation via GraphQL successful', {
        prioritizedStepId: prioritizedStepId,
        processId: processId,
      })
    },
    onMutate: () =>
      handleOnMutate
        ? (variables: StepRunAssignAvailableToUserInput) => {
            handleOnMutate({
              prioritizedStepId: fromGlobalId(variables.prioritizedStepId),
              processId: fromGlobalId(variables.processId),
            })
          }
        : undefined,
    onSettled,
  })

  const mutateAsync = async (data: StepRunAssignAvailableToUserInput) => {
    logger.info('Mutating from useStepRunAssignAvailableToUser', {
      processId: data.processId,
      prioritizedStepId: data.prioritizedStepId,
    })
    const graphQLData = {
      processId: data.processId ? toGlobalId('ProcessType', data.processId) : null,
      prioritizedStepId: data.prioritizedStepId
        ? toGlobalId('StepType', data.prioritizedStepId)
        : null,
    }
    const response = await userAssignStepRun(graphQLData)
    const gqlResponse = parseResponse(
      response.stepRunAssignAvailableToUser,
      (code: number, message: string) => {
        const _error = new Error(`${code}: ${message}`)
        if (handleError) {
          handleError(_error)
        } else {
          showSnackbar({
            message: message,
            variant: 'error',
          })
        }
      }
    )
    if (!gqlResponse) return
    return mapGraphQLData(gqlResponse)
  }

  return { mutateAsync, isLoading }
}

const mapGraphQLData = (stepRun: StepRunAssignAvailableToUserResponse) => ({
  id: stepRun.id,
  baseRunId: stepRun.baseRun.id,
  baseRun: {
    id: stepRun.baseRun.id,
    baseId: stepRun.baseRun.base.id,
    base: {
      id: stepRun.baseRun.base.id,
    },
  },
  lifecycleStageId: stepRun.lifecycleStage ? stepRun.lifecycleStage.id : null,
  lifecycleStage: {
    id: stepRun.lifecycleStage ? stepRun.lifecycleStage.id : null,
  },
  step: {
    id: stepRun.step.id,
    name: stepRun.step.name,
    processId: stepRun.step.process.id,
    process: {
      id: stepRun.step.process.id,
    },
    meta: stepRun.step.meta,
  },
  stepId: stepRun.step.id,
  status: stepRun.status,
})

export { useStepRunAssignAvailableToUser }
