import { SVGProps } from 'react'
function HeartVector(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.5664 2.27909L15.566 2.2787C15.1393 1.86047 14.6363 1.5281 14.0842 1.29967L14.0836 1.29941C13.5109 1.06151 12.8967 0.939747 12.2765 0.941175L12.2754 0.941177C11.404 0.941176 10.5547 1.17975 9.81703 1.62974L9.81702 1.62975C9.64054 1.7374 9.47325 1.85541 9.31497 1.98379L9 2.23927L8.68503 1.98379C8.52675 1.85541 8.35946 1.7374 8.18298 1.62975L8.18296 1.62974C7.44534 1.17975 6.59605 0.941176 5.72461 0.941176C5.09621 0.941176 4.48958 1.06132 3.91641 1.29941L3.91587 1.29963C3.36174 1.52905 2.86283 1.8587 2.43384 2.27885L2.43316 2.27952L2.43316 2.27952C2.00733 2.695 1.66716 3.19001 1.43193 3.73648L15.5664 2.27909ZM15.5664 2.27909C15.9918 2.69521 16.3321 3.19028 16.5681 3.73659C16.8128 4.30516 16.9373 4.90734 16.9355 5.52765V5.52907C16.9355 6.111 16.8167 6.72825 16.572 7.36804L16.5716 7.36917C16.3689 7.90315 16.0699 8.46704 15.6824 9.04546C15.069 9.95995 14.2164 10.9268 13.1433 11.9176C11.3602 13.5624 9.59526 14.6985 9.529 14.7409L9.5289 14.741L9.06698 15.0373C9.06686 15.0373 9.06675 15.0374 9.06663 15.0375C9.02574 15.0634 8.97231 15.0634 8.93142 15.0375C8.9313 15.0374 8.93118 15.0373 8.93106 15.0373L8.46915 14.741L8.4692 14.7409L8.46127 14.7361L8.46119 14.736C8.46036 14.7355 8.45261 14.7306 8.43603 14.7199C8.41965 14.7094 8.39684 14.6946 8.36801 14.6756C8.31037 14.6377 8.22919 14.5837 8.1278 14.5147C7.925 14.3766 7.64191 14.1789 7.30497 13.9309C6.63034 13.4342 5.74378 12.7385 4.85485 11.9176L4.85479 11.9176M15.5664 2.27909L4.85479 11.9176M4.85479 11.9176C3.7817 10.9269 2.92918 9.96012 2.31579 9.04571M4.85479 11.9176L2.31579 9.04571M2.31579 9.04571C1.92889 8.46816 1.63122 7.90374 1.426 7.36804L2.31579 9.04571ZM1.0625 5.52907C1.0625 4.90777 1.18724 4.3051 1.43192 3.73652L1.4259 7.36779C1.18129 6.72809 1.0625 6.11092 1.0625 5.52907Z'
        fill='url(#paint0_linear_1148_202051)'
        stroke='url(#paint1_linear_1148_202051)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1148_202051'
          x1='17.4356'
          y1='8.13162'
          x2='0.5625'
          y2='8.13162'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#DBDBDB' stop-opacity='0.05' />
          <stop offset='0.5' stop-color='#DBDBDB' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1148_202051'
          x1='17.4356'
          y1='8.13162'
          x2='0.5625'
          y2='8.13162'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#DBDBDB' stop-opacity='0.05' />
          <stop offset='0.5' stop-color='#DBDBDB' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HeartVector
