import { getFileExtension } from '@invisible/common/helpers'
import { UploadedFile } from '@invisible/ui/file-uploader'
import { CloseCircleIcon } from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import { defaultStyles, FileIcon } from 'react-file-icon'

interface IEmailWacAttachment {
  removeFile?: (attachment: UploadedFile) => void
  attachments: UploadedFile[]
}

const EmailWacAttachment = ({ attachments, removeFile }: IEmailWacAttachment) => {
  const trimFileName = (filename: string) => {
    const split = filename.indexOf('.')
    const name = filename.substring(0, split)
    const ext = filename.substring(split)
    let trimName = name.substring(0, 7)
    if (name.length > trimName.length) trimName = trimName.padEnd(7 + 3, '.')
    return trimName + ext
  }

  return (
    <div className='my-6 flex justify-center self-center '>
      {attachments.map((attachment) => {
        const name = attachment.fileName
        const type = getFileExtension(name)
        const trimmedName = trimFileName(name)
        return (
          <div className='mx-2.5 flex flex-col' key={name}>
            <div className='w-16 self-center'>
              <FileIcon
                extension={type}
                {...defaultStyles[type as keyof typeof defaultStyles]}
                labelColor='#604CA5'
              />
            </div>
            <Text className='text-sm'>{trimmedName}</Text>
            <CloseCircleIcon
              className='hover:text-red m-auto text-gray-500 hover:text-red-400'
              width='20px'
              onClick={() => removeFile?.(attachment)}
              name={`close-icon-${trimmedName}`}
              type={'button'}
            />
          </div>
        )
      })}
    </div>
  )
}

export { EmailWacAttachment }
