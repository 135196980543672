import { SVGProps } from 'react'
function Flow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='currentColor'>
        <path d='M73.959 79.8c-13.607 0-24.956-11.188-25.432-11.664-.102-.104-10.624-10.449-22.485-10.449-11.902 0-22.378 10.344-22.486 10.449a2.083 2.083 0 01-2.944-2.948c.476-.476 11.825-11.67 25.43-11.67 13.607 0 24.955 11.194 25.432 11.67.102.104 10.624 10.447 22.486 10.447 11.859 0 22.382-10.344 22.488-10.447a2.076 2.076 0 012.942.006 2.074 2.074 0 010 2.942c-.478.476-11.827 11.664-25.431 11.664z' />
        <path d='M73.959 63.147c-13.607 0-24.956-11.194-25.432-11.67-.102-.102-10.624-10.445-22.485-10.445-11.902 0-22.378 10.343-22.486 10.445a2.08 2.08 0 01-2.944 0 2.076 2.076 0 010-2.944c.476-.476 11.825-11.668 25.43-11.668 13.607 0 24.955 11.192 25.432 11.668.102.1 10.624 10.447 22.486 10.447 11.859 0 22.382-10.348 22.488-10.447a2.08 2.08 0 012.942.002 2.074 2.074 0 010 2.942c-.478.476-11.827 11.67-25.431 11.67z' />
        <path d='M73.959 46.482c-13.607 0-24.956-11.194-25.432-11.67-.102-.104-10.624-10.447-22.485-10.447-11.902 0-22.378 10.343-22.486 10.447a2.083 2.083 0 01-2.944-2.948c.476-.476 11.825-11.664 25.43-11.664 13.607 0 24.955 11.188 25.432 11.664.102.104 10.624 10.449 22.486 10.449 11.859 0 22.382-10.346 22.488-10.449a2.077 2.077 0 012.942.006 2.074 2.074 0 010 2.942c-.478.476-11.827 11.67-25.431 11.67z' />
      </g>
    </svg>
  )
}

export default Flow
