import { SVGProps } from 'react'
function EditDataFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='29'
      viewBox='0 0 26 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5192 9.21509V7.21509V2.5293L2.51917 2.5293L2.51917 7.21509L2.51917 24.5293H5.70874C6.26103 24.5293 6.70874 24.977 6.70874 25.5293C6.70874 26.0816 6.26103 26.5293 5.70874 26.5293H2.51916C1.98874 26.5293 1.48003 26.3186 1.10495 25.9435C0.729876 25.5684 0.519165 25.0597 0.519165 24.5293L0.519166 2.5293C0.519166 1.99887 0.729878 1.49016 1.10495 1.11508C1.48003 0.740011 1.98873 0.529297 2.51917 0.529297L19.5192 0.529298C20.0715 0.529298 20.5192 0.977013 20.5192 1.5293V8.21509C20.5192 8.76737 20.0714 9.21509 19.5192 9.21509H18.5192Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7087 7.21509C11.261 7.21509 11.7087 7.6628 11.7087 8.21509L11.7087 17.2151C11.7087 17.7674 11.261 18.2151 10.7087 18.2151C10.1565 18.2151 9.70874 17.7674 9.70874 17.2151L9.70874 8.21509C9.70874 7.6628 10.1565 7.21509 10.7087 7.21509Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7087 8.21509C19.7087 8.76737 19.261 9.21509 18.7087 9.21509H1.70874C1.15646 9.21509 0.708741 8.76737 0.708741 8.21509C0.708741 7.6628 1.15646 7.21509 1.70874 7.21509H2.51917H18.5192H18.7087C19.261 7.21509 19.7087 7.6628 19.7087 8.21509Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7087 14.2151C14.7087 14.7674 14.261 15.2151 13.7087 15.2151H1.70874C1.15645 15.2151 0.708739 14.7674 0.708739 14.2151C0.708739 13.6628 1.15645 13.2151 1.70874 13.2151H13.7087C14.261 13.2151 14.7087 13.6628 14.7087 14.2151Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.70874 20.2151C8.70874 20.7674 8.26103 21.2151 7.70874 21.2151H1.70874C1.15646 21.2151 0.70874 20.7674 0.70874 20.2151C0.70874 19.6628 1.15646 19.2151 1.70874 19.2151H7.70874C8.26103 19.2151 8.70874 19.6628 8.70874 20.2151Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.0016 10.508C20.3922 10.1175 21.0253 10.1175 21.4158 10.508L25.4158 14.508C25.8064 14.8985 25.8064 15.5317 25.4158 15.9222L13.4158 27.9222C13.2283 28.1097 12.974 28.2151 12.7087 28.2151H8.70874C8.15646 28.2151 7.70874 27.7674 7.70874 27.2151V23.2151C7.70874 22.9499 7.8141 22.6955 8.00163 22.508L20.0016 10.508ZM9.70874 23.6293V26.2151H12.2945L20.2945 18.2151L23.2945 15.2151L20.7087 12.6293L17.7087 15.6293L9.70874 23.6293Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0016 13.508C17.3922 13.1175 18.0253 13.1175 18.4158 13.508L22.4158 17.508C22.8064 17.8985 22.8064 18.5317 22.4158 18.9222C22.0253 19.3127 21.3922 19.3127 21.0016 18.9222L20.2945 18.2151L17.7087 15.6293L17.0016 14.9222C16.6111 14.5317 16.6111 13.8985 17.0016 13.508Z'
        fill='currentColor'
      />
      <path
        d='M9.70874 23.6293V26.2151H12.2945L20.2945 18.2151L17.7087 15.6293L9.70874 23.6293Z'
        fill='currentColor'
      />
      <path
        d='M18.5192 2.5293L2.51917 2.5293L2.51917 7.21509H10.7087H18.5192V2.5293Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EditDataFilled
