import { SVGProps } from 'react'
function LinkedIn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M1.78668 4.45796C2.7216 4.45796 3.47949 3.661 3.47949 2.6779C3.47949 1.69479 2.7216 0.897827 1.78668 0.897827C0.851769 0.897827 0.0938721 1.69479 0.0938721 2.6779C0.0938721 3.661 0.851769 4.45796 1.78668 4.45796Z'
        fill='currentColor'
      />
      <path
        d='M6.45335 5.37205V14.8978V5.37205ZM1.78668 5.37205V14.8978V5.37205Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.276879 14.8978V5.37205H3.29649V14.8978H0.276879ZM4.94355 14.8978V5.37205H7.96315V14.8978H4.94355Z'
        fill='currentColor'
      />
      <path
        d='M7.9174 9.60573C7.9174 8.64353 8.51217 7.68133 9.56446 7.68133C10.6625 7.68133 11.0743 8.54731 11.0743 9.84628V14.8978H14.0939V9.4614C14.0939 6.52669 12.6298 5.17961 10.6167 5.17961C9.06119 5.17961 8.28341 6.0937 7.9174 6.71913'
        fill='currentColor'
      />
    </svg>
  )
}

export default LinkedIn
