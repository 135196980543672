import { palette } from '../palette'

const MuiTab = {
  styleOverrides: {
    root: {
      minWidth: 50,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  variants: [
    {
      props: { variant: 'table' },
      style: {
        height: '3rem',
        width: '6rem',
        flexShrink: 0,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.5rem 0.5rem 0 0',
        border: '1px solid',
        padding: '0.5rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        backgroundColor: palette.grey.main,
        borderColor: palette.grey.dark,
        color: 'text.secondary',
        borderBottom: '1 px solid',
        fontWeight: '400',
        '&.Mui-selected': {
          fontWeight: '500',
          color: palette.primary.main,
          backgroundColor: 'white',
          borderBottom: 'none',
        },
        '&:hover': {
          color: palette.primary.main,
        },
        marginRight: '5px',
        zIndex: 1,
      }
    }
  ],
}

export default MuiTab
