import { fromGlobalId, IReadOnlyExportTaskDataQuery } from '@invisible/concorde/gql-client'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { format } from 'date-fns'
import { groupBy, startCase } from 'lodash/fp'

import { STEP_RUN_PROPERTY_OPTIONS_VALUES } from '../SingleStepRunFilterDialog'
import { TREE_VIEW_STYLES } from '../StepRunFilters'
import { IExtraFilters, IStepRunFilters, TDateRange } from './FilterConfiguration'

interface IProps {
  filters: {
    extra_filters: IExtraFilters[]
    step_run_filters: IStepRunFilters[]
  }
  stepsData?: IReadOnlyExportTaskDataQuery['steps']
}

const ReadOnlyStepRunFilters = ({ filters, stepsData }: IProps) => {
  const groupedSteps = groupBy(
    (step) => step.node.lifecycleStage?.name ?? 'No stage',
    stepsData?.edges ?? []
  )
  const groupedStepsKeys = Object.keys(groupedSteps)

  const computeStepRunFilterSummary = (stepId: string) => {
    const statusFilter = filters['extra_filters'].find(
      (filters) => filters.key === fromGlobalId(stepId)
    ) as {
      key: string
      value: string[]
    }
    const timeRangeFilter = filters['step_run_filters'].find(
      (filter) => filter.step_id === fromGlobalId(stepId)
    )

    return (
      <Box>
        <Typography>Step run filters applied</Typography>
        <br />
        {statusFilter ? (
          <>
            <Box>
              <Typography>Filter the data by status ({statusFilter.value.length})</Typography>
              {statusFilter.value.map((status) => (
                <Typography key={status}>- {startCase(status)}</Typography>
              ))}
            </Box>
            <br />
          </>
        ) : null}

        {timeRangeFilter ? (
          <Box>
            <Typography>Filter the data by time range</Typography>
            {STEP_RUN_PROPERTY_OPTIONS_VALUES.map((option) => {
              const dateRange = timeRangeFilter[option] as TDateRange

              return dateRange ? (
                <Typography key={option}>
                  - {startCase(option)}:{' '}
                  {`${format(new Date(dateRange.start_date), 'd MMM yyyy')} - ${format(
                    new Date(dateRange.end_date),
                    'd MMM yyyy'
                  )}`}
                </Typography>
              ) : null
            })}
          </Box>
        ) : null}
      </Box>
    )
  }

  return (
    <SimpleTreeView sx={TREE_VIEW_STYLES} expandedItems={groupedStepsKeys}>
      {groupedStepsKeys.map((stage) => (
        <TreeItem itemId={stage} label={stage} key={stage}>
          <Box ml='8px'>
            {groupedSteps[stage]
              .sort((a, b) => a.node.name.localeCompare(b.node.name))
              .map((step, index) => {
                const statusFilter = filters['extra_filters'].find(
                  (f) => f.key === fromGlobalId(step.node.id)
                )
                const timeRangeFilter = filters['step_run_filters'].find(
                  (f) => f.step_id === fromGlobalId(step.node.id)
                )
                const filterCount =
                  (statusFilter ? 1 : 0) +
                  Object.keys(timeRangeFilter ?? {}).filter((k) => k !== 'step_id').length

                return (
                  <Stack
                    key={index}
                    direction={'row'}
                    sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                    <FormControlLabel
                      control={<Checkbox disabled size='small' checked />}
                      label={<Typography color='grey.800'>{step.node.name}</Typography>}
                    />
                    <Tooltip title={computeStepRunFilterSummary(step.node.id)}>
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        width='16px'
                        height='16px'
                        padding='4px'
                        borderRadius='50%'
                        bgcolor='primary.400'
                        color='white'>
                        {filterCount}
                      </Stack>
                    </Tooltip>
                  </Stack>
                )
              })}
          </Box>
        </TreeItem>
      ))}
    </SimpleTreeView>
  )
}

export { ReadOnlyStepRunFilters }
