import { SVGProps } from 'react'
function SortUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='20'
      viewBox='0 0 25 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6488 0.292893C18.0394 -0.0976311 18.6725 -0.0976311 19.0631 0.292893L24.0631 5.29289C24.4536 5.68342 24.4536 6.31658 24.0631 6.70711C23.6725 7.09763 23.0394 7.09763 22.6488 6.70711L18.356 2.41421L14.0631 6.70711C13.6725 7.09763 13.0394 7.09763 12.6489 6.70711C12.2583 6.31658 12.2583 5.68342 12.6489 5.29289L17.6488 0.292893Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.356 1.49012e-08C18.9082 1.49012e-08 19.356 0.447715 19.356 1V13C19.356 13.5523 18.9082 14 18.356 14C17.8037 14 17.356 13.5523 17.356 13V1C17.356 0.447715 17.8037 1.49012e-08 18.356 1.49012e-08Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 11C0.355957 10.4477 0.803672 10 1.35596 10H10.356C10.9082 10 11.356 10.4477 11.356 11C11.356 11.5523 10.9082 12 10.356 12H1.35596C0.803672 12 0.355957 11.5523 0.355957 11Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 3C0.355957 2.44772 0.803672 2 1.35596 2H8.35596C8.90824 2 9.35596 2.44772 9.35596 3C9.35596 3.55228 8.90824 4 8.35596 4H1.35596C0.803672 4 0.355957 3.55228 0.355957 3Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 19C0.355957 18.4477 0.803672 18 1.35596 18H18.356C18.9082 18 19.356 18.4477 19.356 19C19.356 19.5523 18.9082 20 18.356 20H1.35596C0.803672 20 0.355957 19.5523 0.355957 19Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SortUp
