import { FC, ReactNode } from 'react'
import ReactGrid, { WidthProvider } from 'react-grid-layout'

const RGLWidthProvider = WidthProvider(ReactGrid)

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardTitle: FC<{ children: ReactNode }> = ({ children }) => (
  <h2 className='text-xl font-light tracking-wide text-white'>{children}</h2>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardContainer: FC = ({ children, ...props }) => (
  <div
    {...props}
    className='bg-void flex h-[calc(100vh_-_20px)] w-[calc(100vw_-_20px)] flex-col overflow-auto rounded-lg shadow-md'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const Backdrop: FC = ({ children, ...props }) => (
  <div
    {...props}
    className='fixed top-0 left-0 z-[99] flex h-screen w-screen items-center justify-center bg-[rgba(255,255,255,0.3)]'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const Topbar: FC = ({ children, ...props }) => (
  <div
    {...props}
    className='bg-theme-main box-border flex h-[45px] w-full shrink-0 items-center justify-between px-8 py-10'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const RGLContainer: FC<ReactGrid.ReactGridLayoutProps & ReactGrid.WidthProviderProps> = ({
  children,
  ...props
}) => (
  <RGLWidthProvider {...props} className='bg-weak-2 relative box-border h-full overflow-auto'>
    {children}
  </RGLWidthProvider>
)
