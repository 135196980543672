import Markdown, { Options } from 'react-markdown'

interface TMarkdownProps extends Options {
  dir?: string
}

const CustomMarkdown = ({ children, dir, ...rest }: TMarkdownProps) => (
  <div dir={dir ?? 'auto'}>
    <Markdown {...rest}>{children}</Markdown>
  </div>
)

export default CustomMarkdown
