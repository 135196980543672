import { useAbilityContext } from '@invisible/authorization/client'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { useBaseRunsDelete } from '../hooks/useBaseRunsDelete'

interface IDeleteButtonProps {
  baseRunId: string
  onDeleteDone: () => void
}

export const DeleteButton = ({ baseRunId, onDeleteDone }: IDeleteButtonProps) => {
  const { addToast } = useToasts()
  const [_, ability] = useAbilityContext()
  const canUpdateBaseRun = ability?.can('delete', 'BaseRun')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const reactQueryClient = useQueryClient()

  const { mutateAsync: deleteBaseRuns, isLoading: isBaseRunDeleteLoading } = useBaseRunsDelete({
    onSuccess: () => {
      onDeleteDone()
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onError: (error) => {
      addToast(`Cancel failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
  })

  const handleDeleteButton = async () => {
    if (!canUpdateBaseRun) {
      // Need to update to more specific permission
      return
    }

    await deleteBaseRuns({
      baseRunIds: [baseRunId],
    })
  }

  return (
    <MUIThemeProvider>
      <Button
        variant='contained'
        color='error'
        onClick={() => setIsDeleteDialogOpen(true)}
        sx={{ fontWeight: 'normal' }}>
        Delete Base Run
      </Button>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete Base Run</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will delete the base run and can not be reversed. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            loading={isBaseRunDeleteLoading}
            sx={{ fontWeight: 'normal' }}
            onClick={async () => {
              await handleDeleteButton()
              setIsDeleteDialogOpen(false)
            }}
            autoFocus>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </MUIThemeProvider>
  )
}
