import { SVGProps } from 'react'

function ArrowUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      width='36'
      height='36'
      strokeWidth={1.5}
      stroke='currentColor'
      {...props}>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75'
      />
    </svg>
  )
}

export default ArrowUp
