import { z } from '@invisible/zod'

import { FORMULA_RESULT_TYPES, FORMULA_VALUE_TYPES } from '../../../constants/formula'
import type { TFormulaArg } from '../common'
import { constantArgSchema, variableArgSchema } from '../common'

/**
 * A constant boolean formula argument.
 */
const booleanConstantArgSchema = constantArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.BOOLEAN),
    valueType: z.literal(FORMULA_VALUE_TYPES.BOOLEAN),
  })
  .strip()

/**
 * A constant boolean formula argument.
 */
type TBooleanConstantArg = z.infer<typeof booleanConstantArgSchema>

/**
 * A variable boolean formula argument.
 */
const booleanVariableArgSchema = variableArgSchema
  .omit({
    resultType: true,
    valueType: true,
  })
  .extend({
    resultType: z.literal(FORMULA_RESULT_TYPES.BOOLEAN),
    valueType: z.literal(FORMULA_VALUE_TYPES.BOOLEAN),
  })
  .strip()

/**
 * A constant boolean formula argument.
 */
type TBooleanVariableArg = z.infer<typeof booleanVariableArgSchema>

/**
 * A boolean formula argument.
 */
const booleanArgSchema = z.union([booleanConstantArgSchema, booleanVariableArgSchema])

/**
 * A constant boolean formula argument.
 */
type TBooleanArg = z.infer<typeof booleanArgSchema>

const isBooleanArg = (v: TFormulaArg): v is TBooleanArg =>
  v.resultType === FORMULA_RESULT_TYPES.BOOLEAN && v.valueType === FORMULA_RESULT_TYPES.BOOLEAN

export { booleanArgSchema, booleanConstantArgSchema, booleanVariableArgSchema, isBooleanArg }

export type { TBooleanArg, TBooleanConstantArg, TBooleanVariableArg }
