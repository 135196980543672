import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchema } from '@invisible/ultron/zod'

import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'

const UUIDs = {
  Uber: {
    Steps: {
      Operate: '72bcd542-fe62-4508-bbb3-3b2e7d235a3c',
    },
    BaseVariables: {
      RowCount: '8c8223da-9f79-4e16-bab5-a27c7c443701',
    },
  },
  Bolt: {
    Steps: {
      LeadsQa: '238aa998-face-4eea-b10a-c849358b2643',
      Operate: '96fc5e3e-a808-4f3b-aa3e-b4fc026306c2',
      QA: '44a398ea-5b8a-4e64-a42b-68cc236ce4fe',
      Triage: '1dc17bf1-07f1-46cf-883b-cb045080d812',
    },
    BaseVariables: {
      AirtableApiUrl: 'ba5881a9-bb47-48b5-a65b-359fd3b9f98c',
      AlcoholicItemsCorrect: 'f4739e30-4544-4dce-93e6-3983c955c45e',
      AirtableLink: '9dbc1b38-fbed-4e8d-a105-f8ebb6056fd2',
      AirtableAPILink: 'ba5881a9-bb47-48b5-a65b-359fd3b9f98c',
      CategoriesCorrect: 'f159a530-f75d-4823-b221-6496713c9b1f',
      CloneLink: 'b0ebcfdd-663d-49d4-a8c5-a63613accb48',
      Country: '94d851df-d577-4d72-a69a-107a0bac9f4d',
      DishNamesChecked: '785c9e9f-d480-4f55-9935-aa257b56d81f',
      DishPhotosLinked: 'fbad1ddc-959b-40e1-8770-4bff37556926',
      DuplicatedObjects: 'eb78d123-75aa-4176-9b49-3f98587eb6cb',
      FormattingError: '09b34878-5a28-4bb6-b3a9-69f0ebd451d7',
      GlossaryNotFollowed: 'efac59c1-476c-41d7-a396-974211f8db06',
      IdsChecked: '8bd0f79a-bea3-48b9-8b3c-61542b01a40f',
      IsClone: 'dc36954e-51ef-498e-8d3e-e1b5a88f1673',
      IsEscalated: '7f371bc1-42b7-4fa1-bc28-eeea224275e9',
      IsOperable: 'abb6eec6-212f-4569-8543-017a44a16bf5',
      Language: '4d817068-71db-4bda-9e23-8e6cbc78206a',
      LeadsQaPassed: '0e6c54bb-5a8f-4b66-ab50-3df2342a36dc',
      LinkingError: '562df8d1-4870-41e8-92e9-4d09a82e97a1',
      MenuInterpretation: '4add071a-90e5-4fb1-853a-9e088f9fed47',
      MenuRecordingLink: '22860752-c58b-4001-8b2d-16e73e181394',
      MenuRecordingRequired: '8356cd47-e3a1-4ed9-b21e-ecc5d66cf96b',
      MenuType: 'b6e170f4-d16f-4371-8f96-6e2840d74756',
      MissingObjects: '470e722e-717c-4833-8b8e-b95eef172a7e',
      NumRows: '59dd7852-4fb5-45fb-baec-b16d56a57c78',
      ObjectsNamesFilled: 'beae52a6-4e9f-460f-b373-3794ed40cca1',
      OptionsNamesMakeSense: 'eb66557a-1ee0-473b-b5ce-96b240545a92',
      PhotosRequired: 'cad25427-95c3-4a56-843f-dd90e9a42c43',
      PricingErrors: '855445ac-edad-4697-a87e-7cb226e17a74',
      Priority: '3fc0dccb-2353-494c-a782-538e09cd3708',
      QaPassed: 'acc92cef-bedd-45a8-95bf-a0e4aaa74ca6',
      QaScore: '916eb4aa-7e60-4867-bfc5-db7f4aa57ece',
      RestrictedItems: '9aa338db-3429-4495-ad78-1a7ee25154fb',
      UpdateSize: '53cdae61-ec82-4750-bb06-b709ce2d7ec0',
      WrongDescriptions: '3114266d-8060-47b4-897a-6677862f31ed',
      WrongModifiers: '8abbc20d-ad46-4c4b-81f1-1b6343587a17',
      WrongTags: '72baab82-36c8-4e84-8665-f27a3531d525',
    },
  },
}

type TBaseRunVariable = TBaseRunQueryData['items'][number]['baseRunVariables'][number]

const getFriendlyName = (field: string, formFields: WizardSchema.FormField.TSchema[]) => {
  for (const formField of formFields) {
    if (formField.baseVariableId === field) {
      return formField.label
    }
  }
}

const validateUberOp = (
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[]
) => {
  const requiredFields = [UUIDs.Uber.BaseVariables.RowCount]

  for (const field of requiredFields) {
    if (formValues[field] === null || formValues[field] === '') {
      return {
        result: false,
        message: 'Validation Failed! ' + getFriendlyName(field, formFields) + ' is required.',
      }
    }
  }
  return { result: true }
}

const validateBoltTriage = (
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[]
) => {
  const requiredFields = [
    UUIDs.Bolt.BaseVariables.IsOperable,
    UUIDs.Bolt.BaseVariables.Language,
    UUIDs.Bolt.BaseVariables.Priority,
  ]

  if (formValues[UUIDs.Bolt.BaseVariables.IsClone] === true) {
    requiredFields.push(UUIDs.Bolt.BaseVariables.CloneLink)
  }

  if (
    formValues[UUIDs.Bolt.BaseVariables.IsOperable] === false &&
    formValues[UUIDs.Bolt.BaseVariables.IsClone] === false &&
    formValues[UUIDs.Bolt.BaseVariables.IsEscalated] === false
  ) {
    return {
      result: false,
      message:
        'Validation Failed! ' +
        getFriendlyName(UUIDs.Bolt.BaseVariables.IsEscalated, formFields) +
        ' must be true if case not operable and not a clone',
    }
  }

  if (
    (formValues[UUIDs.Bolt.BaseVariables.IsOperable] === true ||
      formValues[UUIDs.Bolt.BaseVariables.IsClone] === true) &&
    formValues[UUIDs.Bolt.BaseVariables.IsEscalated] === true
  ) {
    return {
      result: false,
      message:
        'Validation Failed! ' +
        getFriendlyName(UUIDs.Bolt.BaseVariables.IsEscalated, formFields) +
        ' must be false if case is operable or is a clone',
    }
  }

  for (const field of requiredFields) {
    if (formValues[field] === null || formValues[field] === '') {
      return {
        result: false,
        message: 'Validation Failed! ' + getFriendlyName(field, formFields) + ' is required.',
      }
    }
  }

  return { result: true }
}

const validateBoltOperate = (
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[],
  baseRunVariables: TBaseRunVariable[]
) => {
  if (formValues[UUIDs.Bolt.BaseVariables.IsEscalated] === true) {
    return { result: true } // No Validations needed if it's being Esca;ated
  }

  const requiredFields = [
    UUIDs.Bolt.BaseVariables.AirtableLink,
    UUIDs.Bolt.BaseVariables.CategoriesCorrect,
    UUIDs.Bolt.BaseVariables.DishNamesChecked,
    UUIDs.Bolt.BaseVariables.DishPhotosLinked,
    UUIDs.Bolt.BaseVariables.ObjectsNamesFilled,
    UUIDs.Bolt.BaseVariables.AlcoholicItemsCorrect,
    UUIDs.Bolt.BaseVariables.IdsChecked,
    UUIDs.Bolt.BaseVariables.OptionsNamesMakeSense,
  ]

  const disabledFields: string[] = []

  const menuType = baseRunVariables.find(
    (x) => x.baseVariableId === UUIDs.Bolt.BaseVariables.MenuType
  )?.value

  const qaPassed = baseRunVariables.find(
    (x) => x.baseVariableId === UUIDs.Bolt.BaseVariables.QaPassed
  )?.value

  if (menuType === 'update') {
    requiredFields.push(
      ...[UUIDs.Bolt.BaseVariables.AirtableApiUrl, UUIDs.Bolt.BaseVariables.UpdateSize]
    )
  } else if (menuType === 'create' && qaPassed === null) {
    disabledFields.push(
      ...[UUIDs.Bolt.BaseVariables.AirtableApiUrl, UUIDs.Bolt.BaseVariables.UpdateSize]
    )
  }

  for (const field of requiredFields) {
    if (formValues[field] === null || formValues[field] === '') {
      return {
        result: false,
        message: 'Validation Failed! ' + getFriendlyName(field, formFields) + ' is required.',
      }
    }
  }

  for (const field of disabledFields) {
    const friendlyName = getFriendlyName(field, formFields)
    if (formValues[field] !== null && formValues[field] !== '' && friendlyName) {
      return {
        result: false,
        message: 'Validation Failed! ' + friendlyName + ' should be left blank.',
      }
    }
  }

  return { result: true }
}

const validateBoltQA = (
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[]
) => {
  const requiredFields = [
    UUIDs.Bolt.BaseVariables.PricingErrors,
    UUIDs.Bolt.BaseVariables.MissingObjects,
    UUIDs.Bolt.BaseVariables.MenuInterpretation,
    UUIDs.Bolt.BaseVariables.WrongDescriptions,
    UUIDs.Bolt.BaseVariables.WrongTags,
    UUIDs.Bolt.BaseVariables.WrongModifiers,
    UUIDs.Bolt.BaseVariables.RestrictedItems,
    UUIDs.Bolt.BaseVariables.GlossaryNotFollowed,
    UUIDs.Bolt.BaseVariables.DuplicatedObjects,
    UUIDs.Bolt.BaseVariables.LinkingError,
    UUIDs.Bolt.BaseVariables.FormattingError,
    UUIDs.Bolt.BaseVariables.QaPassed,
    UUIDs.Bolt.BaseVariables.QaScore,
    UUIDs.Bolt.BaseVariables.NumRows,
  ]

  if (formValues[UUIDs.Bolt.BaseVariables.QaPassed] === true) {
    requiredFields.push(UUIDs.Bolt.BaseVariables.AirtableApiUrl)
  }

  for (const field of requiredFields) {
    if (formValues[field] === null || formValues[field] === '') {
      return {
        result: false,
        message: 'Validation Failed! ' + getFriendlyName(field, formFields) + ' is required.',
      }
    }
  }

  if (
    +formValues[UUIDs.Bolt.BaseVariables.QaScore] < 1 ||
    +formValues[UUIDs.Bolt.BaseVariables.QaScore] > 10
  ) {
    return {
      result: false,
      message: 'Validation Failed! QA Score should be between 1-10.',
    }
  }

  return { result: true }
}

const validateBoltLeadQA = (
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[]
) => {
  const requiredFields = [
    UUIDs.Bolt.BaseVariables.PricingErrors,
    UUIDs.Bolt.BaseVariables.MissingObjects,
    UUIDs.Bolt.BaseVariables.MenuInterpretation,
    UUIDs.Bolt.BaseVariables.WrongDescriptions,
    UUIDs.Bolt.BaseVariables.WrongTags,
    UUIDs.Bolt.BaseVariables.WrongModifiers,
    UUIDs.Bolt.BaseVariables.RestrictedItems,
    UUIDs.Bolt.BaseVariables.GlossaryNotFollowed,
    UUIDs.Bolt.BaseVariables.DuplicatedObjects,
    UUIDs.Bolt.BaseVariables.LinkingError,
    UUIDs.Bolt.BaseVariables.FormattingError,
    UUIDs.Bolt.BaseVariables.LeadsQaPassed,
  ]

  for (const field of requiredFields) {
    if (formValues[field] === null || formValues[field] === '') {
      return {
        result: false,
        message: 'Validation Failed! ' + getFriendlyName(field, formFields) + ' is required.',
      }
    }
  }
  return { result: true }
}

export const validateForm = (
  stepId: string,
  formValues: Record<string, any>,
  formFields: WizardSchema.FormField.TSchema[],
  baseRunVariables: TBaseRunVariable[]
): { result: boolean; message?: string } => {
  const stepIdsToCheck = [
    UUIDs.Uber.Steps.Operate,
    UUIDs.Bolt.Steps.Triage,
    UUIDs.Bolt.Steps.Operate,
    UUIDs.Bolt.Steps.QA,
    UUIDs.Bolt.Steps.LeadsQa,
  ]

  if (!stepIdsToCheck.includes(stepId)) {
    return { result: true }
  }

  if (stepId === UUIDs.Uber.Steps.Operate) {
    return validateUberOp(formValues, formFields)
  }

  if (stepId === UUIDs.Bolt.Steps.Triage) {
    return validateBoltTriage(formValues, formFields)
  }

  if (stepId === UUIDs.Bolt.Steps.Operate) {
    return validateBoltOperate(formValues, formFields, baseRunVariables)
  }

  if (stepId === UUIDs.Bolt.Steps.QA) {
    return validateBoltQA(formValues, formFields)
  }

  if (stepId === UUIDs.Bolt.Steps.LeadsQa) {
    return validateBoltLeadQA(formValues, formFields)
  }

  return { result: true }
}
