import { Line, LineDatum } from '@invisible/common/types'
import { TableTooltip } from '@invisible/ui/chart-tooltip'
import { BarDatum, ComputedBarDatum } from '@nivo/bar'
import { Scale } from '@nivo/scales'
import { useTooltip } from '@nivo/tooltip'
import { line } from 'd3-shape'
import React, { MouseEvent } from 'react'

const DEFAULT_STROKE_WIDTH = 1.7

type BarGroupInfo = {
  xAxisPoint: number
  xAxisLabel: string | number
  width?: number
}

type TargetLayerProps = {
  xScale: Scale<unknown, unknown>
  yScale: Scale<unknown, unknown>
  [key: string]: any
}

type TargetLineProps = {
  label: string
  data: LineDatum[]
  color?: string
  xScale: Scale<unknown, unknown>
  yScale: Scale<unknown, unknown>
  strokeWidth?: string | number
  bars?: ComputedBarDatum<BarDatum>[]
}

// Get width of each bar group along X-Axis of the chart in question
const getBarGroupsInfo = (bars: ComputedBarDatum<BarDatum>[]) => {
  // compare X-Axis label of both bar and bar group check if bar is part of a certain bar group
  const isBarPartOfBarGroup = (bar: ComputedBarDatum<BarDatum>) => (barGroup: BarGroupInfo) =>
    barGroup.xAxisLabel === bar.data.indexValue

  return bars.reduce((barGroupsInfo: BarGroupInfo[], bar: ComputedBarDatum<BarDatum>) => {
    const { x: barXAxisPoint, width: barWidth } = bar
    const { indexValue: barXAxisLabel } = bar.data

    const barGroupIndex = barGroupsInfo.findIndex(isBarPartOfBarGroup(bar))
    const barGroupExists = barGroupIndex >= 0

    // bar is a new bar to a group if its X-Axis point does not match with X-Axis point bar group
    const isNewBarToExistingGroup =
      barGroupExists && barGroupsInfo[barGroupIndex].xAxisPoint !== barXAxisPoint

    if (isNewBarToExistingGroup) {
      const upadtedBarsGroupInfo = [...barGroupsInfo]
      // add width of current bar to the total width of the matched group
      const updatedBarsGroupWidth = (upadtedBarsGroupInfo[barGroupIndex].width ?? 0) + barWidth
      // update width of the bar group
      upadtedBarsGroupInfo[barGroupIndex].width = updatedBarsGroupWidth
      return upadtedBarsGroupInfo
    } else if (!barGroupExists) {
      // bar is first member of its group
      return [
        ...barGroupsInfo,
        {
          xAxisLabel: barXAxisLabel,
          xAxisPoint: barXAxisPoint,
          width: barWidth,
        },
      ]
    }

    // in case the bar exists already in an existing bar group
    return barGroupsInfo
  }, [])
}

// If Target layer is used in bar chart then get the offset of line point so that it renders at
// the center per bar/bar group
const getXAxisPointOffset = (point: LineDatum, barGroupsInfo?: BarGroupInfo[]) => {
  const barGroup = barGroupsInfo?.find((barGroup) => barGroup.xAxisLabel === point.x)
  return (barGroup?.width ?? 0) / 2
}

const TargetLine = ({ data, color, xScale, yScale, strokeWidth, label, bars }: TargetLineProps) => {
  const barGroupsInfo = bars ? getBarGroupsInfo(bars) : undefined

  const tooltip = useTooltip()

  const lineGenerator = line<LineDatum>()
    .x((point) => {
      const offset = getXAxisPointOffset(point, barGroupsInfo)
      return (xScale(point.x as number) as number) + offset
    })
    .y((point) => yScale(point.y as number) as number)

  const renderTooltip = (e: MouseEvent<Element, globalThis.MouseEvent>, point: LineDatum) => {
    const targetValue = String(point.yFormatted) || String(point.y)

    tooltip.showTooltipFromEvent(
      <TableTooltip
        color={color}
        headers={['Target Label', 'Target Value']}
        dataRows={[[label, targetValue]]}
      />,
      e
    )
  }

  return (
    <>
      <path
        d={lineGenerator(data) || ''}
        fill='none'
        strokeWidth={strokeWidth || DEFAULT_STROKE_WIDTH}
        strokeDasharray={5}
        stroke={color}
        style={{ pointerEvents: 'none' }}
      />
      {data.map((point, index) => {
        const barGroup = barGroupsInfo?.find((barGroup) => barGroup.xAxisLabel === point.x)
        const cx = Number(xScale(point.x as number)) + (barGroup?.width ?? 0) / 2
        const cy = Number(yScale(point.y))
        const showTooltip = (e: MouseEvent<Element, globalThis.MouseEvent>) =>
          renderTooltip(e, point)
        const hideTooltip = tooltip.hideTooltip
        return (
          <circle
            key={index}
            cx={cx}
            cy={cy}
            r={5}
            fill='transparent'
            stroke={color}
            strokeWidth={strokeWidth || DEFAULT_STROKE_WIDTH}
            onMouseEnter={showTooltip}
            onMouseMove={showTooltip}
            onMouseLeave={hideTooltip}
          />
        )
      })}
    </>
  )
}

const generateTargetLayer =
  (targets: Line[]): React.FunctionComponent<TargetLayerProps> =>
  ({ xScale, yScale, ...rest }) =>
    (
      <>
        {targets.map((target) => (
          <TargetLine
            key={target.id}
            label={target.id as string}
            data={target.data}
            color={target.color}
            xScale={xScale}
            yScale={yScale}
            bars={rest?.bars}
          />
        ))}
      </>
    )

export { generateTargetLayer }
