import { z } from '@invisible/zod'

/**
 * JSONBuilderStepMeta step metadata contains a jsonObject and outputBaseVariableId
 * The jsonObject is used to read a jinja template in the form of a JSON, with base variable mappings
 * and finally save the mapped json into the given base variable with id outputBaseVariableId
 */
const schema = z.object({
  jsonObject: z.string(),
  outputBaseVariableId: z.string().uuid().nullable(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
