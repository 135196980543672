import { SVGProps } from 'react'
function StarShine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.47327 3.61213C7.70361 3.45193 7.97747 3.36606 8.25805 3.36606C8.53863 3.36606 8.81249 3.45193 9.04283 3.61213C9.27318 3.77233 9.44898 3.9992 9.54662 4.26224L9.54718 4.26375L11.1965 8.73939L11.1985 8.74253L11.2017 8.74451L15.6788 10.3945C15.9419 10.4921 16.1687 10.6679 16.3289 10.8982C16.4891 11.1286 16.575 11.4025 16.575 11.683C16.575 11.9636 16.4891 12.2375 16.3289 12.4678C16.1687 12.6982 15.9419 12.874 15.6788 12.9716L15.6773 12.9722L11.2017 14.6215L11.1985 14.6235L11.1966 14.6266L9.54718 19.1023L9.54662 19.1038C9.44898 19.3669 9.27318 19.5937 9.04283 19.7539C8.81249 19.9141 8.53863 20 8.25805 20C7.97747 20 7.70361 19.9141 7.47327 19.7539C7.24292 19.5937 7.06712 19.3669 6.96948 19.1038L6.96892 19.1023L5.31956 14.6267L5.31756 14.6235L5.31615 14.6224L5.31445 14.6215L0.838788 12.9722L0.837278 12.9716C0.574235 12.874 0.347375 12.6982 0.187172 12.4678C0.0269702 12.2375 -0.0588989 11.9636 -0.0588989 11.683C-0.0588989 11.4025 0.0269702 11.1286 0.187172 10.8982C0.347375 10.6679 0.574235 10.4921 0.837278 10.3945L0.838788 10.3939L5.31442 8.74453L5.31756 8.74253L5.31954 8.73942L6.96892 4.26375L6.96948 4.26224C7.06712 3.9992 7.24292 3.77233 7.47327 3.61213ZM9.90686 14.1514V14.1514C9.97605 13.9636 10.0852 13.7931 10.2266 13.6516C10.3681 13.5101 10.5386 13.401 10.7264 13.3318V13.3318L15.2005 11.683L10.7264 10.0342V10.0342C10.5387 9.96504 10.3681 9.85592 10.2266 9.71443C10.0852 9.57294 9.97605 9.40243 9.90686 9.21467V9.21467L8.25805 4.74054L6.60925 9.21463V9.21463C6.54007 9.40239 6.43095 9.57294 6.28946 9.71443C6.14796 9.85592 5.97745 9.96503 5.78969 10.0342V10.0342L1.31557 11.683L5.78966 13.3318V13.3318C5.97742 13.401 6.14797 13.5101 6.28946 13.6516C6.43095 13.7931 6.54005 13.9636 6.60924 14.1514V14.1514L8.25805 18.6255L9.90686 14.1514Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.756 0C14.1355 0 14.4432 0.307687 14.4432 0.687239V4.81067C14.4432 5.19022 14.1355 5.49791 13.756 5.49791C13.3764 5.49791 13.0687 5.19022 13.0687 4.81067V0.687239C13.0687 0.307687 13.3764 0 13.756 0Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.007 2.74895C11.007 2.3694 11.3147 2.06172 11.6943 2.06172H15.8177C16.1972 2.06172 16.5049 2.3694 16.5049 2.74895C16.5049 3.12851 16.1972 3.43619 15.8177 3.43619H11.6943C11.3147 3.43619 11.007 3.12851 11.007 2.74895Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8794 4.81067C18.2589 4.81067 18.5666 5.11836 18.5666 5.49791V8.24686C18.5666 8.62641 18.2589 8.9341 17.8794 8.9341C17.4998 8.9341 17.1922 8.62641 17.1922 8.24686V5.49791C17.1922 5.11836 17.4998 4.81067 17.8794 4.81067Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8177 6.87239C15.8177 6.49283 16.1254 6.18515 16.5049 6.18515H19.2539C19.6334 6.18515 19.9411 6.49283 19.9411 6.87239C19.9411 7.25194 19.6334 7.55962 19.2539 7.55962H16.5049C16.1254 7.55962 15.8177 7.25194 15.8177 6.87239Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default StarShine
