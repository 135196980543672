import { SVGProps } from 'react'

function BadgeOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5ZM0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 3.5C4.61929 3.5 3.5 4.61929 3.5 6C3.5 7.38071 4.61929 8.5 6 8.5C7.38071 8.5 8.5 7.38071 8.5 6C8.5 4.61929 7.38071 3.5 6 3.5ZM2.5 6C2.5 4.067 4.067 2.5 6 2.5C7.933 2.5 9.5 4.067 9.5 6C9.5 7.933 7.933 9.5 6 9.5C4.067 9.5 2.5 7.933 2.5 6Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 9.5C3.27614 9.5 3.5 9.72386 3.5 10V14.191L5.77639 13.0528C5.91716 12.9824 6.08284 12.9824 6.22361 13.0528L8.5 14.191V10C8.5 9.72386 8.72386 9.5 9 9.5C9.27614 9.5 9.5 9.72386 9.5 10V15C9.5 15.1733 9.41027 15.3342 9.26287 15.4253C9.11546 15.5164 8.93139 15.5247 8.77639 15.4472L6 14.059L3.22361 15.4472C3.06861 15.5247 2.88454 15.5164 2.73713 15.4253C2.58973 15.3342 2.5 15.1733 2.5 15V10C2.5 9.72386 2.72386 9.5 3 9.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default BadgeOutlined
