import { TProcessByIdStep, useProcessById } from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { toGlobalId, useStepRunsUnpauseMutation } from '@invisible/concorde/gql-client'
import { Button } from '@invisible/ui/button'
import { BASE_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { Modal } from '@invisible/ui/modal'
import { theme } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

const UnpauseModal = ({
  visible,
  onCloseModal,
}: {
  visible: boolean
  onCloseModal: () => void
}) => {
  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const { addToast } = useToasts()
  const reactQueryClient = useQueryClient()
  const { query } = useRouter()
  const [pauseSteps, setPauseSteps] = useState<TProcessByIdStep[]>([])
  const [selectedPauseStepId, setSelectedPauseStepId] = useState('')

  const { data: process } = useProcessById({
    id: query.id as string,
    enabled: !!query.id,
  })

  const { selectedBaseRuns, setSelectedBaseRuns } = useStore(
    useCallback(
      (state) => ({
        selectedBaseRuns: state.selectedBaseRuns,
        setSelectedBaseRuns: state.setSelectedBaseRuns,
      }),
      []
    ),
    shallow
  )

  const { mutateAsync: unpauseStepRuns } = useStepRunsUnpauseMutation({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onError: (error: { message: string }) => {
      addToast(`Unpause failed: ${error.message}`, {
        appearance: 'error',
      })
    },
  })
  useEffect(() => {
    if (process) {
      const pauseSteps = process?.steps?.filter(
        (step) =>
          step?.stepTemplate.type === 'control_flow' &&
          step?.stepTemplate.subtype === 'pause' &&
          step?.baseId === baseId
      ) as TProcessByIdStep[]
      setPauseSteps(pauseSteps)
      if (pauseSteps.length === 1) {
        setSelectedPauseStepId(pauseSteps[0].id)
      }
    }
  }, [process])

  const handleUnpause = async () => {
    const unpausedBaseRuns = await unpauseStepRuns({
      baseRunIds: selectedBaseRuns.map((id) => toGlobalId('BaseRun', id)),
      stepId: toGlobalId('Step', selectedPauseStepId),
    })
    setSelectedBaseRuns([])
    onCloseModal()
    addToast(
      `Will unpause ${unpausedBaseRuns?.stepRunsUnpause?.length ?? 0} Base Runs in a minute.`,
      {
        appearance: 'success',
      }
    )
  }

  return (
    <Modal
      icon='CircledExclamationIcon'
      visible={visible}
      title={`Unpause ${selectedBaseRuns.length} Baseruns`}
      onClose={onCloseModal}
      primaryButton={
        <Button variant='danger' size='md' onClick={onCloseModal}>
          Cancel
        </Button>
      }
      secondaryButton={
        <Button
          variant='secondary'
          size='md'
          disabled={pauseSteps.length === 0}
          onClick={handleUnpause}>
          Unpause
        </Button>
      }>
      <ThemeProvider theme={theme}>
        <Stack direction='column' gap='16px' maxWidth='400px'>
          {pauseSteps.length >= 2 ? (
            <>
              <Typography>
                Multiple pause steps found in given base, please select which pause step you want to
                unpause for given base runs.
              </Typography>
              <FormControl>
                <Select
                  size='small'
                  defaultValue=''
                  fullWidth
                  value={selectedPauseStepId}
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedPauseStepId(event.target.value as string)
                  }}>
                  <MenuItem disabled value=''>
                    Select
                  </MenuItem>
                  {(pauseSteps ?? []).map((step) => (
                    <MenuItem key={step.id} value={step.id}>
                      {step.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : null}

          {pauseSteps.length > 0 ? (
            <Typography variant='body2'>
              Are you sure you want to unpause the selected base runs?
            </Typography>
          ) : (
            <Typography>There are no pause steps in this base!</Typography>
          )}
        </Stack>
      </ThemeProvider>
    </Modal>
  )
}

export { UnpauseModal }
