import { SVGProps } from 'react'
function CloseCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24.096 12.44a.282.282 0 00-.282-.28l-2.32.01L18 16.336l-3.492-4.163-2.323-.01a.28.28 0 00-.282.28c0 .068.025.131.067.184l4.574 5.449-4.574 5.446a.282.282 0 00.214.464l2.324-.01L18 19.808l3.49 4.163 2.32.01a.28.28 0 00.282-.281.292.292 0 00-.067-.183l-4.566-5.445 4.573-5.45a.282.282 0 00.064-.183z'
        fill='currentColor'
      />
      <path
        d='M18 2.25C9.302 2.25 2.25 9.302 2.25 18S9.302 33.75 18 33.75 33.75 26.698 33.75 18 26.698 2.25 18 2.25zm0 28.828c-7.221 0-13.078-5.857-13.078-13.078 0-7.221 5.857-13.078 13.078-13.078 7.221 0 13.078 5.857 13.078 13.078 0 7.221-5.857 13.078-13.078 13.078z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CloseCircle
