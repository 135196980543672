import {
  IDataFilter,
  TFilterValue,
  TReportingPlatform,
  TReportingScope,
} from '@invisible/common/types'
import { IStoriesQuery, useStoriesQuery } from '@invisible/concorde/gql-client'
import { createContext } from 'react'

export type TStoriesContext = {
  data?: IStoriesQuery
  isLoading: boolean
  scopedId: string
  staticFilters: IDataFilter<TFilterValue>[]
}

export type TStoriesProvider = {
  platform: TReportingPlatform
  scope: TReportingScope
  scopedId: string
  staticFilters?: IDataFilter<TFilterValue>[]
}

const StoriesContext = createContext<TStoriesContext>({
  data: {} as IStoriesQuery,
  isLoading: false,
  scopedId: '',
  staticFilters: [],
})

// eslint-disable-next-line @typescript-eslint/ban-types
const StoriesProvider: React.FC<TStoriesProvider> = ({
  scope,
  platform,
  scopedId,
  staticFilters,
  children,
}) => {
  const { data, isLoading } = useStoriesQuery(
    {
      // @ts-expect-error Enum value not assignable to enum
      platform,
      // @ts-expect-error Enum value not assignable to enum
      scope,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
  return (
    <StoriesContext.Provider
      value={{
        data,
        isLoading,
        scopedId,
        staticFilters: staticFilters ?? [],
      }}>
      {children}
    </StoriesContext.Provider>
  )
}

export { StoriesContext, StoriesProvider }
