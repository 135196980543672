import { SnackbarContext } from '@invisible/common/providers'
import {
  getErrorMessage,
  parseResponse,
  toGlobalId,
  useBaseFilterConfigUpdateMutation,
  useExportConfigurationCreateMutation,
} from '@invisible/concorde/gql-client'
import LoadingButton from '@mui/lab/LoadingButton'
import { TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useRouter } from 'next/router'
import { Dispatch, useContext } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { z } from 'zod'

import { initialState } from './CreateExportDialog'
import { TReducerAction, TReducerState } from './reducer'
import { TFormSchema } from './schema'

// this will be redundant when all tabs are using hook form with zod
// to be removed
const createDataSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  baseId: z.string(),
  transformationRuleId: z.string(),
  filterConfigId: z.string(),
})

interface IProps {
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
  open: boolean
  handleSubmit: UseFormHandleSubmit<TFormSchema>
  handleClose: () => void
}

const CreatePresetDialog = ({ state, dispatch, open, handleSubmit, handleClose }: IProps) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const reactQueryClient = useQueryClient()
  const { query } = useRouter()

  const { mutateAsync: createExport, isLoading: isExportCreateLoading } =
    useExportConfigurationCreateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })
  const { mutateAsync: updateFilterConfig, isLoading: isUpdateFilterConfigLoading } =
    useBaseFilterConfigUpdateMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })

  const handleCreateExport = async (savePreset?: boolean) => {
    const validationResult = createDataSchema.safeParse(state)
    if (!validationResult.success) {
      showSnackbar({
        message: 'Invalid data provided to create export. Please check the form and try again.',
        variant: 'error',
      })
      return
    }

    if (savePreset) {
      await updateFilterConfig({
        data: {
          baseFilterConfigId: state.filterConfigId,
          isReusable: true,
        },
      })
    }

    const createdExport = await createExport({
      data: {
        baseId: state.baseId,
        name: state.name,
        description: state.description,
        filterConfigId: state.filterConfigId,
        transformationRuleId: state.transformationRuleId,
        processId: toGlobalId('ProcessType', query.id as string),
        recipients: state.recipients,
        rruleString:
          state.schedulerPageConfig.rruleString && state.schedulerPageConfig.description
            ? state.schedulerPageConfig.rruleString
            : null,
        rruleDescription:
          state.schedulerPageConfig.rruleString && state.schedulerPageConfig.description
            ? state.schedulerPageConfig.description
            : null,
      },
    })
    const data = parseResponse(createdExport.exportConfigurationCreate, (_, message) => {
      showSnackbar({
        message,
        variant: 'error',
      })
    })
    if (!data) {
      return
    }

    showSnackbar({
      message: 'Export added.',
      variant: 'success',
    })

    dispatch({
      type: 'SET_STATE',
      payload: initialState,
    })
    handleClose()

    reactQueryClient.invalidateQueries(['ExportConfigurations'])
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Save this export as preset</DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <TextField label='Preset name' size='small' fullWidth required sx={{ mt: '8px' }} />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          sx={{ fontWeight: 'normal' }}
          onClick={async () => {
            await handleSubmit(() => handleCreateExport())()
          }}
          loading={isExportCreateLoading}>
          NO, JUST EXPORT
        </LoadingButton>

        <LoadingButton
          sx={{ fontWeight: 'normal' }}
          onClick={handleSubmit(() => handleCreateExport(true))}
          loading={isExportCreateLoading || isUpdateFilterConfigLoading}>
          SAVE
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export { CreatePresetDialog }
