const EMAIL_DOMAIN = process.env.NEXT_PUBLIC_EMAIL_DOMAIN
const MIDGARD_URL = process.env.NEXT_PUBLIC_MIDGARD_URL
const NODE_ENV = process.env.NODE_ENV
const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY
const STATIC_CDN_URL = process.env.NEXT_PUBLIC_STATIC_CDN_URL
const SERVICE_TOKEN = process.env.SERVICE_TOKEN
const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV
const LAMBDA_URL = process.env.LAMBDA_URL
const PORTAL_URL = process.env.NEXT_PUBLIC_PORTAL_URL
const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APP_ID
const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
const DATADOG_SERVICE_NAME = process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME
const DEFAULT_AD_CALENDLY_URL = process.env.NEXT_PUBLIC_DEFAULT_AD_CALENDLY_URL
const NEXT_PUBLIC_CHAT_URL = process.env.NEXT_PUBLIC_CHAT_URL
const NEXT_PUBLIC_CONCORDE_URL = process.env.NEXT_PUBLIC_CONCORDE_URL
const CHAT_HTTP_URL = process.env.CHAT_HTTP_URL
const GOOGLE_OAUTH_CLIENT_ID = process.env.GOOGLE_OAUTH_CLIENT_ID
const GOOGLE_OAUTH_CLIENT_SECRET = process.env.GOOGLE_OAUTH_CLIENT_SECRET
const GOOGLE_OAUTH_REDIRECT = process.env.GOOGLE_OAUTH_REDIRECT

export {
  CHAT_HTTP_URL,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SERVICE_NAME,
  DEFAULT_AD_CALENDLY_URL,
  EMAIL_DOMAIN,
  GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_OAUTH_CLIENT_SECRET,
  GOOGLE_OAUTH_REDIRECT,
  LAMBDA_URL,
  MIDGARD_URL,
  NEXT_PUBLIC_CHAT_URL,
  NEXT_PUBLIC_CONCORDE_URL,
  NODE_ENV,
  PORTAL_URL,
  SERVICE_TOKEN,
  STATIC_CDN_URL,
  STRIPE_PUBLIC_KEY,
  VERCEL_ENV,
}
