import { SVGProps } from 'react'
function KlarnaOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='135'
      height='30'
      viewBox='0 0 135 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0_6109_50600)'>
        <path
          d='M23.6498 0H17.0326C17.0326 5.43027 14.5401 10.3858 10.2077 13.6499L7.59644 15.6083L17.7448 29.4362H26.0831L16.7359 16.7062C21.1573 12.3145 23.6498 6.37982 23.6498 0Z'
          fill='black'
        />
        <path d='M6.76558 0H0V29.4362H6.76558V0Z' fill='black' />
        <path d='M34.4213 0H28.0415V29.4362H34.4213V0Z' fill='black' />
        <path
          d='M90.3857 8.51633C87.9525 8.51633 85.6379 9.25817 84.0949 11.365V9.08013H78.0415V29.4362H84.1839V18.7537C84.1839 15.6677 86.2611 14.1543 88.7537 14.1543C91.4243 14.1543 92.9673 15.7567 92.9673 18.724V29.4659H99.0504V16.4985C99.0504 11.7507 95.2819 8.51633 90.3857 8.51633Z'
          fill='black'
        />
        <path
          d='M53.7093 9.08012V10.3858C51.9882 9.19882 49.9111 8.51633 47.6559 8.51633C41.7212 8.51633 36.9141 13.3234 36.9141 19.2582C36.9141 25.1929 41.7212 30 47.6559 30C49.9111 30 51.9882 29.3175 53.7093 28.1306V29.4362H59.7924V9.08012H53.7093ZM48.1604 24.4807C45.104 24.4807 42.6411 22.1365 42.6411 19.2582C42.6411 16.3798 45.104 14.0356 48.1604 14.0356C51.2167 14.0356 53.6796 16.3798 53.6796 19.2582C53.6796 22.1365 51.2167 24.4807 48.1604 24.4807Z'
          fill='black'
        />
        <path
          d='M69.2285 11.7211V9.08011H62.9971V29.4362H69.2582V19.9406C69.2582 16.7359 72.73 15.0148 75.1336 15.0148C75.1632 15.0148 75.1929 15.0148 75.1929 15.0148V9.08011C72.73 9.08011 70.4451 10.1484 69.2285 11.7211Z'
          fill='black'
        />
        <path
          d='M117.982 9.08012V10.3858C116.261 9.19882 114.184 8.51633 111.929 8.51633C105.994 8.51633 101.187 13.3234 101.187 19.2582C101.187 25.1929 105.994 30 111.929 30C114.184 30 116.261 29.3175 117.982 28.1306V29.4362H124.065V9.08012H117.982ZM112.433 24.4807C109.377 24.4807 106.914 22.1365 106.914 19.2582C106.914 16.3798 109.377 14.0356 112.433 14.0356C115.49 14.0356 117.953 16.3798 117.953 19.2582C117.982 22.1365 115.49 24.4807 112.433 24.4807Z'
          fill='black'
        />
        <path
          d='M128.783 9.6736C128.783 9.37686 128.576 9.19882 128.249 9.19882H127.686V10.7418H127.953V10.1781H128.249L128.487 10.7418H128.783L128.516 10.1187C128.694 10.0297 128.783 9.88131 128.783 9.6736ZM128.249 9.91099H127.953V9.43621H128.249C128.427 9.43621 128.516 9.52523 128.516 9.6736C128.516 9.82197 128.457 9.91099 128.249 9.91099Z'
          fill='black'
        />
        <path
          d='M128.16 8.54599C127.359 8.54599 126.706 9.19881 126.706 10C126.736 10.8012 127.359 11.454 128.16 11.454C128.961 11.454 129.614 10.8012 129.614 10C129.614 9.19881 128.961 8.54599 128.16 8.54599ZM128.16 11.1869C127.507 11.1869 127.003 10.6528 127.003 10C127.003 9.34718 127.537 8.81305 128.16 8.81305C128.813 8.81305 129.318 9.34718 129.318 10C129.318 10.6528 128.783 11.1869 128.16 11.1869Z'
          fill='black'
        />
        <path
          d='M130.564 22.2255C128.457 22.2255 126.736 23.9466 126.736 26.0534C126.736 28.1602 128.457 29.8813 130.564 29.8813C132.671 29.8813 134.392 28.1602 134.392 26.0534C134.392 23.9169 132.671 22.2255 130.564 22.2255Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_6109_50600'>
          <rect width='134.392' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default KlarnaOriginal
