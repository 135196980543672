import { SVGProps } from 'react'
function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M34.8522 17.4804L1.24284 0.62949C1.10623 0.561186 0.949538 0.545115 0.800877 0.581276C0.635885 0.622064 0.493778 0.726569 0.405672 0.871907C0.317565 1.01724 0.290638 1.19157 0.330787 1.35672L3.79418 15.5076C3.84641 15.7206 4.00311 15.8933 4.21204 15.9616L10.1464 17.9987L4.21606 20.0357C4.00713 20.1081 3.85043 20.2768 3.80222 20.4898L0.330787 34.6607C0.294627 34.8094 0.310698 34.9661 0.379002 35.0987C0.535698 35.4161 0.921413 35.5447 1.24284 35.388L34.8522 18.6335C34.9768 18.5732 35.0772 18.4688 35.1415 18.3482C35.2982 18.0268 35.1696 17.6411 34.8522 17.4804ZM4.29239 30.6348L6.31338 22.3741L18.1741 18.304C18.2665 18.2719 18.3428 18.1996 18.375 18.1032C18.4312 17.9344 18.3428 17.7536 18.1741 17.6933L6.31338 13.6273L4.30043 5.39869L29.5326 18.0509L4.29239 30.6348Z'
        fill={props.fill ?? '#604CA5'}
      />
    </svg>
  )
}

export default SendIcon
