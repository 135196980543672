import ErrorIcon from '@mui/icons-material/Error'
import ReplayIcon from '@mui/icons-material/Replay'
import Button from '@mui/material/Button'
import {
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import * as React from 'react'

type TProps = GridSlotsComponentsProps['toolbar'] & { errorsCount?: number }

export function CustomToolBar(props: TProps) {
  return (
    <GridToolbarContainer
      sx={{
        margin: '5px 0px',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      <div className='flex w-full flex-wrap justify-between'>
        <div className='flex'>
          <div className='text-theme-main flex  items-center font-bold'>
            <Button
              onClick={() => {
                props?.refresh()
              }}
              startIcon={<ReplayIcon />}>
              Reload
            </Button>
          </div>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </div>
        <GridToolbarQuickFilter />
      </div>

      {props?.errorsCount && props.errorsCount > 0 ? (
        <div className='flex justify-start pl-2 font-bold '>
          <ErrorIcon fontSize='small' className='pr-2 text-red-600' /> {props?.errorsCount} errors
          found
        </div>
      ) : null}
    </GridToolbarContainer>
  )
}
