import { SVGProps } from 'react'
function LockFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='27'
      viewBox='0 0 25 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M22.5515 9H18.0515V5.5C18.0515 4.04131 17.4721 2.64236 16.4406 1.61091C15.4092 0.579463 14.0102 0 12.5515 0C11.0928 0 9.69388 0.579463 8.66243 1.61091C7.63098 2.64236 7.05151 4.04131 7.05151 5.5V9H2.55151C2.02108 9 1.51237 9.21071 1.1373 9.58579C0.762227 9.96086 0.551514 10.4696 0.551514 11V25C0.551514 25.5304 0.762227 26.0391 1.1373 26.4142C1.51237 26.7893 2.02108 27 2.55151 27H22.5515C23.0819 27 23.5907 26.7893 23.9657 26.4142C24.3408 26.0391 24.5515 25.5304 24.5515 25V11C24.5515 10.4696 24.3408 9.96086 23.9657 9.58579C23.5907 9.21071 23.0819 9 22.5515 9ZM12.5515 19.5C12.2548 19.5 11.9648 19.412 11.7182 19.2472C11.4715 19.0824 11.2792 18.8481 11.1657 18.574C11.0522 18.2999 11.0225 17.9983 11.0803 17.7074C11.1382 17.4164 11.2811 17.1491 11.4909 16.9393C11.7006 16.7296 11.9679 16.5867 12.2589 16.5288C12.5498 16.4709 12.8514 16.5007 13.1255 16.6142C13.3996 16.7277 13.6339 16.92 13.7987 17.1666C13.9635 17.4133 14.0515 17.7033 14.0515 18C14.0515 18.3978 13.8935 18.7794 13.6122 19.0607C13.3309 19.342 12.9493 19.5 12.5515 19.5ZM16.0515 9H9.05151V5.5C9.05151 4.57174 9.42026 3.6815 10.0766 3.02513C10.733 2.36875 11.6233 2 12.5515 2C13.4798 2 14.37 2.36875 15.0264 3.02513C15.6828 3.6815 16.0515 4.57174 16.0515 5.5V9Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LockFilled
