import { z } from '@invisible/zod'

const schema = z.object({
  orchestrationsBaseId: z.string().optional(),
  messagesBaseId: z.string().optional(),

  typeBaseVariableId: z.string().optional(),
  indexBaseVariableId: z.string().optional(),
  textBaseVariableId: z.string().optional(),
  stagedActionBaseVariableId: z.string().optional(),
  lastUpdatedBaseVariableId: z.string().optional(),
  schemaVersionBaseVariableId: z.string().optional(),
  toolsList: z.array(z.string()).optional(),

  isReview: z.boolean().optional(),
  isMultiAction: z.boolean().optional(),

  metadata: z
    .array(
      z.object({
        name: z.string(),
        type: z.string(),
        baseVariableId: z.string(),
        required: z.boolean().optional(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
