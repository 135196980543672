import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { SnackbarContext } from '@invisible/common/providers'
import { useStore } from '@invisible/common/stores/process-store'
import { logger } from '@invisible/logger/server'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { BASE_VIEW_ID_ARGS, useQueryParam } from '@invisible/ui/hooks/use-query-params'
import { Modal } from '@invisible/ui/modal'
import { Tab, Tabs } from '@invisible/ui/tabs'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { BaseViewMeta } from '@invisible/ultron/zod'
import { isEqual, map, pick, pickBy } from 'lodash/fp'
import { FC, useCallback, useContext as reactUseContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'

import { ExtraFiltersModal } from '../ExtraFiltersModal'
import { ColumnOrder } from './ColumnOrder'
import { FilterAndSort } from './FilterAndSort'
import { BaseViewGeneralSettings } from './GeneralSettings'
import { stringifyDateFilters } from './helpers'

type TStages = NonNullable<inferQueryOutput<'lifecycleStage.findManyByProcessId'>>

export type TBase = TProcessWithBases['bases'][number]
type TBaseView = TBase['baseViews'][number]
export type TStep = TBase['steps'][number]

const removeUndefinedFields = (obj: Record<string, string | undefined>): Record<string, string> =>
  pickBy((v: string | undefined) => v !== undefined)(obj) as Record<string, string>

interface IColumnSelectorProps {
  currentBaseView: TBaseView
  steps: TStep[]
  base: TBase
  stages: TStages
  permissions: string[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseViewSettings: FC<IColumnSelectorProps> = ({
  currentBaseView,
  steps,
  base,
  stages,
  permissions,
}) => {
  const { showSnackbar } = reactUseContext(SnackbarContext)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const [stepRunFilters, setStepRunFilters] = useState(
    (currentBaseView.config as BaseViewMeta.TSchema)?.stepRunFilters ?? []
  )
  const {
    isFreshNew,
    setIsFreshNew,
    baseViewSettingsModalOpen,
    setBaseViewSettingsModalOpen,
    stageId,
    baseViewName,
    filterOption,
    sortOption,
    hiddenColumns,
    filterableColumns,
    columnOrder,
    setHiddenColumns,
    setColumnOrder,
    setFilterableColumns,
    editedPermissions,
    setEditedPermissions,
    isPrivate,
    setIsPrivate,
    setBaseViewName,
    setStageId,
  } = useStore(
    useCallback(
      (state) => ({
        isFreshNew: state.isFreshNew,
        setIsFreshNew: state.setIsFreshNew,
        baseViewSettingsModalOpen: state.baseViewSettingsModalOpen,
        setBaseViewSettingsModalOpen: state.setBaseViewSettingsModalOpen,
        stageId: state.stageId,
        baseViewName: state.baseViewName,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        hiddenColumns: state.hiddenColumns,
        filterableColumns: state.filterableColumns,
        columnOrder: state.columnOrder,
        setHiddenColumns: state.setHiddenColumns,
        setColumnOrder: state.setColumnOrder,
        setFilterableColumns: state.setFilterableColumns,
        editedPermissions: state.editedPermissions,
        setEditedPermissions: state.setEditedPermissions,
        isPrivate: state.isPrivate,
        setIsPrivate: state.setIsPrivate,
        setBaseViewName: state.setBaseViewName,
        setStageId: state.setStageId,
      }),
      []
    ),
    shallow
  )

  const { mutateAsync: updateBaseView, isLoading: isUpdateBaseViewLoading } = useMutation(
    'baseView.update',
    {
      onSettled: () => {
        reactQueryContext.invalidateQueries('process.findByIdWithBases')
        reactQueryClient.invalidateQueries('get-base-runs')
      },
    }
  )

  const { mutateAsync: deleteBaseView } = useMutation('baseView.delete', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('process.findByIdWithBases')
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const { mutateAsync: updatePermissions } = useMutation('baseView.updatePermissions', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseView.getPermissions')
      reactQueryContext.invalidateQueries('baseView.findById')
    },
  })

  const currentBaseViewConfig = useMemo(
    () => BaseViewMeta.schema.parse(currentBaseView?.config ?? {}),
    [currentBaseView]
  )

  const isPermissionsSameAsBackend = useMemo(
    () =>
      permissions.length === editedPermissions.length &&
      isPrivate === Boolean(currentBaseView.userId) &&
      permissions
        .sort()
        .map((permissionId, index) => permissionId === editedPermissions.sort()[index])
        .reduce((acc, curr) => acc && curr, true),
    [currentBaseView.userId, editedPermissions, isPrivate, permissions]
  )

  const isFiltersSameAsBackend = useMemo(
    () =>
      isEqual(
        currentBaseViewConfig?.filters ?? [],
        map(pick(['id', 'value', 'isStepStatus', 'isStepAssignee']))(
          stringifyDateFilters(filterOption)
        )
      ),
    [currentBaseViewConfig?.filters, filterOption]
  )

  const isSortSameAsBackend = useMemo(
    () => isEqual(currentBaseViewConfig.sortBy ?? {}, sortOption),
    [currentBaseViewConfig.sortBy, sortOption]
  )

  const isColumnOrderSameAsBackend = useMemo(
    () => isEqual(currentBaseViewConfig.columnOrder ?? [], columnOrder),
    [columnOrder, currentBaseViewConfig.columnOrder]
  )

  const isHiddenColumnsSameAsBackend = useMemo(
    () => isEqual(currentBaseViewConfig.hiddenColumns ?? [], hiddenColumns),
    [currentBaseViewConfig.hiddenColumns, hiddenColumns]
  )

  const isFilterableSameAsBackend = useMemo(
    () => isEqual(currentBaseViewConfig.filterableColumns ?? [], filterableColumns),
    [currentBaseViewConfig.filterableColumns, filterableColumns]
  )

  const isStageSameAsBackend = useMemo(
    () => isEqual(currentBaseView.lifecycleStageId, stageId),
    [currentBaseView.lifecycleStageId, stageId]
  )

  const isNameSameAsBackend = useMemo(
    () => isEqual(currentBaseView.name, baseViewName),
    [baseViewName, currentBaseView.name]
  )

  const isAllSameAsBackend = useMemo(
    () =>
      isPermissionsSameAsBackend &&
      isFiltersSameAsBackend &&
      isSortSameAsBackend &&
      isColumnOrderSameAsBackend &&
      isHiddenColumnsSameAsBackend &&
      isFilterableSameAsBackend &&
      isStageSameAsBackend &&
      isNameSameAsBackend &&
      isEqual(stepRunFilters, currentBaseViewConfig.stepRunFilters ?? []),
    [
      isColumnOrderSameAsBackend,
      isFilterableSameAsBackend,
      isFiltersSameAsBackend,
      isHiddenColumnsSameAsBackend,
      isNameSameAsBackend,
      isPermissionsSameAsBackend,
      isSortSameAsBackend,
      isStageSameAsBackend,
      currentBaseViewConfig,
      stepRunFilters,
    ]
  )

  const [selectedTab, setSelectedTab] = useState<
    'general' | 'columns' | 'filterAndSort' | 'additionalFilters'
  >('general')

  useEffect(() => {
    // syncing store with backend
    setBaseViewName(currentBaseView.name || '')
    setStageId(currentBaseView.lifecycleStageId)
  }, [currentBaseView.lifecycleStageId, currentBaseView.name, setBaseViewName, setStageId])

  useEffect(() => {
    // If filter and sort have been updated we the Filter and Sort tab is open when opening the modal
    if (!baseViewSettingsModalOpen && (!isFilterableSameAsBackend || !isSortSameAsBackend)) {
      setSelectedTab('filterAndSort')
    }
    // if the user revert the fitlers or sort to what the config is, we select the general tab again
    if (!baseViewSettingsModalOpen && isFilterableSameAsBackend && isSortSameAsBackend) {
      setSelectedTab('general')
    }
  }, [baseViewSettingsModalOpen, isFilterableSameAsBackend, isSortSameAsBackend])

  const handleSaveAndClose = async () => {
    if (!baseViewId || isAllSameAsBackend) return
    const config = {
      ...currentBaseViewConfig,
      filters: stringifyDateFilters(filterOption),
      sortBy: removeUndefinedFields(sortOption),
      hiddenColumns,
      columnOrder,
      filterableColumns,
      stepRunFilters,
    }
    const parsedConfig = BaseViewMeta.schema.safeParse(config ?? {})
    if (!parsedConfig.success) {
      const { errors } = parsedConfig.error
      logger.error(`Error parsing base view config`, { config, errors })

      showSnackbar({
        message:
          'Base view settings was not saved due to invalid configs. Please verify the filter and sort options.',
        variant: 'error',
      })
      return
    }
    // save name,  column order, filtrable, column visibility, filter, sort, stage
    await updateBaseView({
      name: baseViewName,
      lifecycleStageId: stageId,
      config: parsedConfig.data,
      id: baseViewId,
    })

    // save permissions
    if (!isPermissionsSameAsBackend) {
      await updatePermissions({
        baseViewId,
        roleIds: editedPermissions,
        privateBaseView: isPrivate,
      })
    }

    // close modal
    setBaseViewSettingsModalOpen(false)
    setSelectedTab('general')
  }

  const handleCancel = () => {
    if (isFreshNew) {
      deleteBaseView({ id: baseViewId })
      setIsFreshNew(false)
    }

    // reset name
    setBaseViewName(currentBaseView.name || '')
    // reset stage
    setStageId(currentBaseView.lifecycleStageId)
    // reset permissions
    setEditedPermissions(permissions)
    setIsPrivate(Boolean(currentBaseView.userId))
    // reset column visibility
    setHiddenColumns(currentBaseViewConfig?.hiddenColumns ?? [])
    // reset column order
    setColumnOrder(currentBaseViewConfig?.columnOrder ?? [])
    // reset filtrable
    setFilterableColumns(currentBaseViewConfig?.filterableColumns ?? [])

    // Note: We are not resetting filter and sort

    // close modal
    setBaseViewSettingsModalOpen(false)
    setSelectedTab('general')
  }

  const hasNoPermission = useMemo(
    () => editedPermissions.length === 0 && !isPrivate,
    [editedPermissions.length, isPrivate]
  )

  return baseViewSettingsModalOpen ? (
    <Modal
      title='Edit Base View settings'
      onClose={handleCancel}
      disableClickAway={!isAllSameAsBackend}
      icon='SettingsIcon'
      withoutPadding
      secondaryButton={
        <Button onClick={handleCancel} variant='secondary' size='md'>
          Cancel
        </Button>
      }
      primaryButton={
        <Button
          variant='primary'
          size='md'
          onClick={handleSaveAndClose}
          disabled={isAllSameAsBackend || hasNoPermission}
          loading={isUpdateBaseViewLoading}>
          Save & Close
        </Button>
      }>
      <Tabs
        value={selectedTab}
        onChange={(value) => setSelectedTab(value as 'general' | 'columns' | 'filterAndSort')}>
        <Tab name='General' value='general'></Tab>
        <Tab name='Columns' value='columns'></Tab>
        <Tab name='Filter and Sort' value='filterAndSort'></Tab>
        <Tab name='Additional Filters' value='additionalFilters'></Tab>
      </Tabs>
      <div className='min-h-[450px] w-[650px] p-4'>
        {selectedTab === 'general' ? (
          <BaseViewGeneralSettings
            currentBaseView={currentBaseView}
            stages={stages}
            key='general'
          />
        ) : null}
        {selectedTab === 'columns' ? (
          <ColumnOrder steps={steps} base={base} baseViews={base.baseViews} key='columns' />
        ) : null}
        {selectedTab === 'filterAndSort' ? (
          <FilterAndSort baseVariables={base.baseVariables} key='filterAndSort' />
        ) : null}
        {selectedTab === 'additionalFilters' ? (
          <ExtraFiltersModal
            stepRunFilters={stepRunFilters}
            setStepRunFilters={setStepRunFilters}
            baseViewId={currentBaseView.id}
            steps={steps}
          />
        ) : null}
      </div>
    </Modal>
  ) : null
}
