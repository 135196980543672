import { isEqual } from 'lodash'
import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'

export type IBreadcrumbState = (string | { name: string; path: string })[]

const initialState: IBreadcrumbState = []

const BreadcrumbsContext = createContext<
  [IBreadcrumbState, Dispatch<SetStateAction<IBreadcrumbState>>]
>([initialState, () => null])

// eslint-disable-next-line @typescript-eslint/ban-types
export const BreadcrumbsContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<IBreadcrumbState>([''])
  return (
    <BreadcrumbsContext.Provider value={[state, setState]}>{children}</BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbsContext = (breadcrumbsState?: IBreadcrumbState) => {
  const [state, setState] = useContext(BreadcrumbsContext)

  useEffect(() => {
    if (!isEqual(state, breadcrumbsState)) {
      setState(breadcrumbsState ?? [''])
    }
  }, [breadcrumbsState])

  return [state, setState] as const
}
