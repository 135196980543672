import { SVGProps } from 'react'
function CalendarOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 4C0.355957 2.89543 1.25139 2 2.35596 2H22.356C23.4605 2 24.356 2.89543 24.356 4V24C24.356 25.1046 23.4605 26 22.356 26H2.35596C1.25139 26 0.355957 25.1046 0.355957 24V4ZM22.356 4H2.35596V24H22.356V4Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.356 0C18.9082 0 19.356 0.447715 19.356 1V5C19.356 5.55228 18.9082 6 18.356 6C17.8037 6 17.356 5.55228 17.356 5V1C17.356 0.447715 17.8037 0 18.356 0Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.35596 0C6.90824 0 7.35596 0.447715 7.35596 1V5C7.35596 5.55228 6.90824 6 6.35596 6C5.80367 6 5.35596 5.55228 5.35596 5V1C5.35596 0.447715 5.80367 0 6.35596 0Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.355957 9C0.355957 8.44771 0.803672 8 1.35596 8H23.356C23.9082 8 24.356 8.44771 24.356 9C24.356 9.55229 23.9082 10 23.356 10H1.35596C0.803672 10 0.355957 9.55229 0.355957 9Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CalendarOutline
