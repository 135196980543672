import { useSession } from '@invisible/authentication/client'
import { useEffect } from 'react'

declare global {
  interface Window {
    userGuiding: any
  }
}

export const useUserGuidingIdentifyUser = () => {
  const { data: session } = useSession()
  const user = session?.user

  useEffect(() => {
    if (user && window?.userGuiding) {
      window.userGuiding?.identify?.(user.email)
    }
  }, [user])
}

export default useUserGuidingIdentifyUser
