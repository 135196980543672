import { z } from '@invisible/zod'

const schema = z.object({
  models: z
    .array(
      z.object({
        id: z.string().optional(),
        name: z.string(),
        numOfCalls: z.number().optional(),
        params: z.array(
          z.object({
            name: z.string(),
            value: z.any(),
            type: z.enum(['string', 'number', 'boolean', 'object', 'array']),
          })
        ),
        responseMetaParams: z
          .array(
            z.object({
              id: z.string(),
              key: z.string(),
              baseVariableId: z.string(),
            })
          )
          .optional(),
      })
    )
    .optional(),
  conversationsBaseId: z.string().optional(),
  promptsBaseId: z.string().optional(),
  responsesBaseId: z.string().optional(),
  promptTextBaseVariableId: z.string().optional(),
  promptIndexBaseVariableId: z.string().optional(),
  promptResponseBaseVariableId: z.string().optional(),
  promptAcceptedModelBaseVariableId: z.string().optional(),
  promptResponseContextBaseVariableId: z.string().optional(),
  responseIdBaseVariableId: z.string().optional(),

  conversationPreambleBaseVariableId: z.string().optional(),

  responseTextBaseVariableId: z.string().optional(),
  responseOriginalTextBaseVariableId: z.string().optional(),
  responseModelBaseVariableId: z.string().optional(),
  responseIndexBaseVariableId: z.string().optional(),

  allowDeletePrompt: z.boolean().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
