import { useClickAway } from '@invisible/hooks/use-click-away'
import { Checkbox } from '@invisible/ui/checkbox'
import { If } from '@invisible/ui/conditional'
import { Divider } from '@invisible/ui/divider'
import { Input } from '@invisible/ui/input'
import type { TagColor } from '@invisible/ui/tag'
import { Tag } from '@invisible/ui/tag'
import { Text } from '@invisible/ui/text'
import { fontSizes, gray, purple, space, styled, textColor, useTheme } from '@invisible/ui/themes'
import { motion } from 'framer-motion'
import { capitalize, compact, filter, find, flow, map, uniq } from 'lodash/fp'
import { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'rebass'

const Wrapper = styled.div<{
  width?: string
  plain?: boolean
  disabled?: boolean
}>`
  min-height: 28px;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  padding: 0 12px;
  display: inline-block;
  white-space: nowrap;
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  align-content: baseline;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${gray(5)};
  &:focus-within {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    margin-bottom: -1px;
    ${(props) =>
      props.plain
        ? `border-bottom: none;
          transition: all 0.30s ease-in-out;
          box-shadow: 0 0 5px #6300ff;
          `
        : ''}
  }
  ${({ disabled }) =>
    disabled
      ? `pointer-events: none;
          opacity: 0.7;
          cursor: not-allowed;
            `
      : ''}
`

const Label = styled.div<{ nameColor?: string; centerName?: boolean }>`
  color: ${({ nameColor }) => nameColor ?? textColor('disabled')};
  font-size: 14px;
  text-align: ${({ centerName }) => (centerName ? 'center' : 'left')};
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  padding: 8px 0;
`
const DropMenu = styled.div`
  font-size: 14px;
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
`
const DropMenuList = styled(motion.div)<{
  maxHeight?: string | number
  showFullMenuWidth?: boolean
}>`
  display: none;
  opacity: 0;
  padding: 5px 0;
  flex-direction: column;
  position: absolute;
  top: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  width: ${({ showFullMenuWidth }) => (showFullMenuWidth ? null : 'calc(100% + 16px)')};
  min-width: ${({ showFullMenuWidth }) => (showFullMenuWidth ? '250px' : 'calc(100% + 16px)')};
  left: -8px;
  margin-top: 10px;
  min-height: 20px;
  height: fit-content;
  max-height: ${({ maxHeight }) =>
    maxHeight ? (typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight) : 'fit-content'};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'scroll' : 'auto')};
  a,
  a:hover,
  a:active,
  a:visited {
    cursor: pointer;
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: none;
    padding: 9px 10px;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #f0f0f0;

    &:hover {
      background-color: ${purple(2)};
    }
    &:last-child {
      border-bottom: none;
    }
  }
`

const Option = styled.a`
  min-height: 16px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
`

const InvisibleInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  height: 30px;
`

const InMenuSearchInput = styled(Input)``

const IconDiv = styled.div`
  min-width: 11px;
`

const SectionContainer = styled.div`
  padding-top: ${space(2)};
`

const SectionNameText = styled(Text)`
  font-size: ${fontSizes(1)};
  font-weight: bold;
  color: ${textColor('primary')};
  padding-left: ${space(3)};
`

const Down = () => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.1612 0.433105H9.15673C9.08843 0.433105 9.02415 0.466588 8.98397 0.521498L5.17906 5.76614L1.37414 0.521498C1.33397 0.466588 1.26968 0.433105 1.20138 0.433105H0.196912C0.109859 0.433105 0.0589657 0.532213 0.109859 0.603195L4.83218 7.11346C5.00361 7.34918 5.3545 7.34918 5.52459 7.11346L10.2469 0.603195C10.2991 0.532213 10.2483 0.433105 10.1612 0.433105Z'
      fill='black'
      fillOpacity='0.25'
    />
  </svg>
)
const Up = () => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2484 7.12016L5.52611 0.609891C5.35468 0.374177 5.00379 0.374177 4.8337 0.609891L0.11004 7.12016C0.0984157 7.13617 0.091452 7.15509 0.0899209 7.17481C0.0883898 7.19454 0.0923511 7.21431 0.101366 7.23192C0.110381 7.24953 0.124096 7.26431 0.140993 7.2746C0.15789 7.2849 0.177308 7.29031 0.197094 7.29025H1.20156C1.26986 7.29025 1.33415 7.25677 1.37433 7.20186L5.17924 1.95721L8.98415 7.20186C9.02433 7.25677 9.08861 7.29025 9.15692 7.29025H10.1614C10.2484 7.29025 10.2993 7.19114 10.2484 7.12016Z'
      fill='black'
      fillOpacity='0.25'
    />
  </svg>
)

interface IOption {
  key: string
  value: string | number
  color?: TagColor
  isSelected?: boolean
  parentType?: string
}

interface IMultiSelect {
  name: string
  options: IOption[]
  defaultKeys?: string[]
  onSelect?: (selected: IOption[]) => void
  width?: string
  disabled?: boolean
  selectedKeys?: string[]
  selectedText?: string
  nameColor?: string
  centerName?: boolean
  showFullMenuWidth?: boolean
  optionSize?: string
  checkbox?: boolean
  height?: string | number
  maxHeight?: string | number
  showTagsInMenu?: boolean
  hideTags?: boolean
  onAdd?: (selected: IOption) => void
  onRemove?: (removedOption: IOption) => void
  search?: boolean
  inMenuSearch?: boolean
  allowCustomOption?: boolean
  hasSections?: boolean
  limit?: number
}

const MultiSelect: FunctionComponent<IMultiSelect> = ({
  defaultKeys,
  name,
  options,
  onAdd,
  onRemove,
  onSelect,
  width,
  disabled,
  selectedKeys,
  selectedText,
  nameColor,
  centerName,
  showFullMenuWidth,
  optionSize,
  checkbox,
  height,
  maxHeight,
  showTagsInMenu = false,
  hideTags = false,
  search = false,
  inMenuSearch = false,
  allowCustomOption = false,
  hasSections = false,
  limit,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>(selectedKeys ?? defaultKeys ?? [])
  const ref = useRef<null | HTMLDivElement>(null)
  const searchRef = useRef<null | HTMLInputElement>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const theme = useTheme()
  const handleUpdate = (newKeys: string[]) => {
    onSelect?.(
      newKeys.map((item) => ({
        key: item,
        value: options.find((e) => e.key === item)?.value ?? '',
        color: options.find((e) => e.key === item)?.color,
        isSelected: options.find((e) => e.key === item)?.isSelected ?? false,
      }))
    )
  }

  const handleOptionClick = (option: IOption) => {
    const newKeys = selected.includes(option.key)
      ? selected.filter((e) => e !== option.key)
      : [...selected, option.key]

    if (limit && selected.length === limit) return

    if (!selectedKeys) {
      setSelected(newKeys)
    }
    if (selected.includes(option.key)) {
      onRemove?.(option)
    } else {
      onAdd?.({ ...option, isSelected: true })
    }
    handleUpdate(newKeys)
    if (hideTags && !checkbox) setOpen(false)
    if (searchRef) searchRef.current?.focus()
  }

  const generateValues = (optionsArg: IOption[]) =>
    flow(
      filter(({ key }) =>
        searchTerm ? key.toLowerCase().includes(searchTerm.toLowerCase()) : true
      ),
      map((option: IOption) => (
        <Flex flexDirection='row' key={`${option.value}`} alignItems='center'>
          {checkbox && (
            <Box ml={3}>
              <Checkbox
                checked={selected.includes(option.key)}
                onToggle={() => handleOptionClick(option)}
              />
            </Box>
          )}
          <Option
            key={`${option.value}`}
            style={{
              backgroundColor: option.isSelected ? theme.purpleScale[2] : theme.colors['white'],
              fontSize: optionSize,
            }}
            onClick={() => handleOptionClick(option)}>
            {option.key}
          </Option>
        </Flex>
      ))
    )(optionsArg)

  const getParentTypes = (optionsArg: IOption[]) =>
    flow(
      map((option: IOption) => option.parentType),
      uniq,
      compact
    )(optionsArg)

  const parentTypes = hasSections ? getParentTypes(options) : null

  const generateValuesByParent = (optionArgs: IOption[], parentType: string) =>
    flow(
      filter((option: IOption) => option.parentType === parentType),
      generateValues
    )(optionArgs)

  const variants = {
    open: { opacity: 1, display: 'flex' },
    closed: { opacity: 0, transitionEnd: { display: 'none' } },
  }

  const handleTagClose = (item: string) => {
    const newSelection = selected.filter((e) => e !== item)
    onRemove?.({
      key: item,
      value: options.find((e) => e.key === item)?.value ?? '',
      color: options.find((e) => e.key === item)?.color,
      isSelected: options.find((e) => e.key === item)?.isSelected,
    })
    if (!selectedKeys) setSelected(newSelection)
    handleUpdate(newSelection)
  }

  useEffect(() => {
    if (selectedKeys) {
      setSelected(selectedKeys)
    }
  }, [selectedKeys])

  useClickAway(ref, () => {
    if (isOpen) {
      setOpen(false)
      setSearchTerm('')
    }
  })
  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && searchTerm) {
      const searchedOptions: IOption[] = filter(({ key }: IOption) =>
        key.toLowerCase().includes(searchTerm.toLowerCase())
      )(options)
      const selectedOption = searchedOptions?.[0]

      if (allowCustomOption && !selectedOption) {
        const newKeys = selected.includes(searchTerm)
          ? selected.filter((e) => e !== searchTerm)
          : [...selected, searchTerm]
        setSelected(newKeys)
        onAdd?.({
          key: searchTerm,
          value: selected.length,
          isSelected: true,
        })
        handleUpdate(newKeys)
        setSearchTerm('')
      } else if (selectedOption) {
        const newKeys = selected.includes(selectedOption?.key)
          ? selected.filter((e) => e !== selectedOption?.key)
          : [...selected, selectedOption?.key]
        setSelected(newKeys)
        onAdd?.({ ...selectedOption, isSelected: true })
        handleUpdate(newKeys)
        setSearchTerm('')
      }
      if (hideTags) setOpen(false)
    }
  }

  return (
    <Wrapper width={width} disabled={disabled}>
      <DropMenu ref={ref}>
        <Flex
          justifyContent='space-between'
          alignItems='center'
          height={height}
          onClick={() => {
            setOpen((prev) => !prev)
          }}>
          {centerName && <div></div>}
          <>
            {!hideTags && !showTagsInMenu && selected.length !== 0 ? (
              <Flex flexWrap='wrap' py='1px'>
                {selected.map((e, index) => (
                  <Tag
                    key={index}
                    iconRight='CloseIcon'
                    color={find((option: IOption) => option.key === e)(options)?.color}
                    onIconRightClick={() => {
                      handleTagClose(e)
                    }}>
                    {e}
                  </Tag>
                ))}
              </Flex>
            ) : search && isOpen ? null : selected.length !== 0 && selectedText ? (
              <Box py='5px'>{selectedText}</Box>
            ) : (
              <Label nameColor={nameColor} centerName={centerName}>
                {name}
              </Label>
            )}{' '}
          </>
          {!isOpen || !search ? (
            <IconDiv>
              <Down />
            </IconDiv>
          ) : null}
        </Flex>
        <Flex justifyContent='space-between' alignItems='center '>
          {search && isOpen && (
            <InvisibleInput
              ref={searchRef}
              value={searchTerm}
              height={height}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleEnter}
              autoFocus
            />
          )}
          {!isOpen || !search ? null : (
            <IconDiv>
              <Up />
            </IconDiv>
          )}
        </Flex>
        <DropMenuList
          animate={isOpen ? variants.open : variants.closed}
          maxHeight={maxHeight}
          showFullMenuWidth={showFullMenuWidth}>
          {!hideTags && showTagsInMenu ? (
            <Box>
              {selected.map((item) => (
                <Flex key={item} mx='10px' mb='5px'>
                  <Tag iconRight='CloseIcon' onIconRightClick={() => handleTagClose(item)}>
                    {item}
                  </Tag>
                </Flex>
              ))}
            </Box>
          ) : null}
          {inMenuSearch && (
            <Box>
              <Flex flexDirection='row' justifyContent='center' pt='0.5rem' pb='1rem' width='300px'>
                <InMenuSearchInput
                  value={searchTerm}
                  height={height}
                  width='275px'
                  placeholder='Type here to search'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                  onKeyDown={handleEnter}
                  autoFocus
                />
              </Flex>
            </Box>
          )}
          <If conditional={!hasSections}>{generateValues(options)}</If>
          <If conditional={hasSections}>
            {parentTypes?.map((sectionName: string) => (
              <SectionContainer>
                <Box>
                  <SectionNameText>{capitalize(sectionName)}</SectionNameText>
                  <Box pb={'0.5rem'}>
                    <Divider />
                  </Box>
                </Box>
                <Box>{generateValuesByParent(options, sectionName)}</Box>
              </SectionContainer>
            ))}
          </If>
          {allowCustomOption && (
            <Option
              onClick={() => {
                if (searchTerm) {
                  const newKeys = selected.includes(searchTerm)
                    ? selected.filter((e) => e !== searchTerm)
                    : [...selected, searchTerm]
                  setSelected(newKeys)
                  onAdd?.({
                    key: searchTerm,
                    value: selected.length,
                    isSelected: true,
                  })
                  handleUpdate(newKeys)
                  setSearchTerm('')
                }
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div>Create:</div>
                {searchTerm && <Tag>{searchTerm}</Tag>}
              </div>
            </Option>
          )}
        </DropMenuList>
      </DropMenu>
    </Wrapper>
  )
}

export { MultiSelect }
