import { SmallCheckbox } from '@invisible/ui/form'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React from 'react'

type TCheckListValue = Record<string, boolean>

const CheckList = ({
  checkList,
  checkListStatus,
  setCheckListValue,
}: {
  checkList: WizardSchemas.CheckList.TSchema
  checkListStatus: TCheckListValue
  setCheckListValue: React.Dispatch<React.SetStateAction<TCheckListValue>>
}) => (
  <div>
    {checkList.label}
    {checkList.checkItems?.map((checkItem) => (
      <div className='mt-2 flex items-center'>
        <SmallCheckbox
          checked={checkListStatus?.[checkItem.id] ?? false}
          onClick={(value) => {
            setCheckListValue((prev) => ({
              ...prev,
              [checkItem.id]: value,
            }))
          }}
        />
        {checkItem.isRequired ? '* ' : ''}
        {checkItem.value}
      </div>
    ))}
  </div>
)

export { CheckList }
