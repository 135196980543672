import { ISSUE_TYPE_LANGUAGE_TOOL } from '../constants'
import type { TIssueType, TMatch } from '../types'

export const getErrorString = (match: TMatch) =>
  match.context.text.slice(match.context.offset, match.context.offset + match.context.length)

export const getColorHighlight = (issueType: TIssueType) => {
  switch (issueType) {
    case 'Spelling':
      return 'rgba(211, 47, 47, 1)' // red
    case 'Usage':
      return 'rgba(46, 125, 50, 1)' // green
    case 'Grammar':
      return 'rgba(2, 136, 209, 1)' // blue
  }
}

export const getUniqueMatches = (matches: TMatch[]): TMatch[] => {
  const uniqueErrorStrings = new Set<string>()
  const uniqueMatches: TMatch[] = []

  matches.forEach((match) => {
    const errorString = getErrorString(match)

    if (!uniqueErrorStrings.has(errorString)) {
      uniqueErrorStrings.add(errorString)
      uniqueMatches.push(match)
    }
  })

  return uniqueMatches
}

export const countMatchesPerIssueType = ({
  matches,
}: {
  matches: TMatch[]
}): { Spelling: number; Usage: number; Grammar: number } => {
  const matchesPerIssueType = {
    Spelling: 0,
    Usage: 0,
    Grammar: 0,
  }
  matches.forEach((match) => {
    matchesPerIssueType[identifyIssueType(match)]++
  })
  return matchesPerIssueType
}

export const identifyIssueType = (match: TMatch): TIssueType =>
  ISSUE_TYPE_LANGUAGE_TOOL[match?.type?.typeName as keyof typeof ISSUE_TYPE_LANGUAGE_TOOL]

export const escapeRegex = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special characters for regex

export const getTextNodes = () => {
  const walker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null)
  let node
  const textNodes = []
  while ((node = walker.nextNode())) {
    textNodes.push(node)
  }
  return textNodes
}

// Function to get caret coordinates (relative to the input element)
export const getCaretCoordinates = ({
  element,
  startPosition,
  endPosition,
}: {
  element: HTMLInputElement | HTMLTextAreaElement
  startPosition?: number
  endPosition?: number
}) => {
  const position = (startPosition || endPosition) as number
  const div = document.createElement('div')
  const span = document.createElement('span')
  const text = element.value.slice(0, position)

  // Apply styles to the div to match the input element
  const style = window.getComputedStyle(element)

  if (element.tagName === 'TEXTAREA') {
    div.style.whiteSpace = 'pre-wrap'
    div.style.wordWrap = 'break-word' // Ensure wrapping is similar to textarea
  } else {
    div.style.whiteSpace = 'pre'
  }
  div.style.position = 'absolute'
  div.style.visibility = 'hidden'
  div.style.pointerEvents = 'none'
  div.style.font = style.font
  div.style.padding = style.padding
  div.style.border = style.border
  div.style.margin = style.margin
  div.style.lineHeight = style.lineHeight
  div.style.width = style.width // Match width to handle wrapping
  div.style.overflow = 'hidden' // Avoid scrollbar issues in the div

  div.appendChild(document.createTextNode(text))
  span.innerHTML = '&#8203;' // Zero-width space to get the position after the last character
  div.appendChild(span)

  document.body.appendChild(div)

  const rect = element.getBoundingClientRect()
  const lineHeight = parseFloat(style.lineHeight)

  // Calculate the coordinates relative to the element
  const coordinates = {
    left: rect.left + span.offsetLeft - div.scrollLeft,
    top: rect.top + span.offsetTop - div.scrollTop + lineHeight,
  }

  document.body.removeChild(div)
  return coordinates
}

export const getIsWithinVisibleAreaHOF =
  ({
    inputTop,
    inputRight,
    inputBottom,
    inputLeft,
  }: {
    inputTop: number
    inputLeft: number
    inputRight: number
    inputBottom: number
  }) =>
  ({ left, top, right }: { left: number; top: number; right: number }) =>
    top >= inputTop && top <= inputBottom && left >= inputLeft && right <= inputRight
