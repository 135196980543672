import { SVGProps } from 'react'
function LoomOriginal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      {...props}>
      <path
        d='M32.9926 14.6913H22.942L31.6458 9.69209L29.9198 6.71664L21.2148 11.7158L26.239 3.05749L23.2487 1.34005L18.2234 9.9962V0H14.7691V9.9973L9.74385 1.34005L6.75242 3.05749L11.7766 11.7137L3.07388 6.71664L1.34675 9.69209L10.0506 14.6913H0V18.1273H10.0495L1.34675 23.1265L3.07278 26.1019L11.7777 21.1027L6.75242 29.7611L9.74275 31.4785L14.7691 22.8213V32.8175H18.2234V22.6069L23.341 31.4238L26.1456 29.8136L21.0268 20.9944L29.9176 26.1008L31.6447 23.1254L22.942 18.1262H32.9915V14.6913H32.9926ZM16.4963 21.0798C15.8797 21.0798 15.2691 20.9591 14.6994 20.7243C14.1296 20.4896 13.612 20.1455 13.1759 19.7117C12.7398 19.2779 12.3939 18.7629 12.1579 18.1961C11.9218 17.6293 11.8003 17.0217 11.8003 16.4082C11.8002 15.7946 11.9216 15.1871 12.1575 14.6202C12.3934 14.0533 12.7392 13.5382 13.1751 13.1043C13.6111 12.6704 14.1287 12.3262 14.6983 12.0914C15.268 11.8565 15.8786 11.7356 16.4952 11.7355C17.7405 11.7354 18.9349 12.2275 19.8155 13.1036C20.6962 13.9797 21.1911 15.168 21.1912 16.4071C21.1913 17.6462 20.6968 18.8346 19.8163 19.7109C18.9358 20.5872 17.7416 21.0796 16.4963 21.0798Z'
        fill='#625DF5'
      />
    </svg>
  )
}

export default LoomOriginal
