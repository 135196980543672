import { isQuestionSkipped } from '@invisible/common/helpers'
import type {
  TComment,
  TCommentTree,
  TQuestionAndAnswerTree,
  TQuestionnaireAnswerEntries,
  TResolutions,
} from '@invisible/common/types'
import { TUuid } from '@invisible/zod'
import { FC, useCallback, useState } from 'react'

import type { TUser } from '../types'
import QuestionQA from './QuestionQA'

interface IProps {
  questions: TQuestionAndAnswerTree[]
  questionnaireRunId: TUuid
  isDPOView: boolean
  entries: TQuestionnaireAnswerEntries
  user: TUser
  resolutions: TResolutions
  isDPIAView: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const QuestionsQA: FC<IProps> = ({
  questions,
  questionnaireRunId,
  isDPOView,
  entries,
  user,
  resolutions,
  isDPIAView,
}) => {
  const [touched, setTouched] = useState('')
  const [questionInOpenModal, setQuestionInOpenModal] = useState('')

  const handleQuestionTouch = (id: string) => setTouched(id)
  const handleQuestionInOpenModal = (id: string) => setQuestionInOpenModal(id)

  const createCommentHierarchy = useCallback(
    (comments: TComment[], replyToId: string | null = null, level = 0): TCommentTree[] => {
      const currentLevel = level + 1
      return comments
        ?.filter((comment) => comment.replyToId === replyToId)
        .map((child) => ({
          ...child,
          level: currentLevel,
          children: createCommentHierarchy(comments, child.id, currentLevel),
        }))
    },
    []
  )

  const Questions = questions.map((question) => {
    const comments = question.comments
    const commentHierarchy = createCommentHierarchy(comments)
    const answerId = question.answer?.id
    const isSkipped = isQuestionSkipped(question, questions, entries)
    if (isSkipped) return null
    return (
      <QuestionQA
        question={question}
        entries={entries}
        questionnaireRunId={questionnaireRunId}
        comments={commentHierarchy}
        user={user}
        isDPOView={isDPOView}
        handleQuestionTouch={handleQuestionTouch}
        touched={touched}
        handleQuestionInOpenModal={handleQuestionInOpenModal}
        questionInOpenModal={questionInOpenModal}
        answerId={answerId}
        resolutions={resolutions}
        isDPIAView={isDPIAView}
      />
    )
  })

  return (
    <div className='relative w-full'>
      <div className='flex flex-col justify-between space-y-8'>{Questions}</div>
    </div>
  )
}

export default QuestionsQA
