import { SVGProps } from 'react'
function ZoomOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.232 6.769H4.66a.143.143 0 00-.143.142v1.072c0 .078.064.143.143.143h5.57a.143.143 0 00.144-.143V6.91a.143.143 0 00-.143-.142zm5.071 7.571l-2.607-2.607a6.782 6.782 0 00-.464-9.09C9.589 0 5.296 0 2.642 2.644c-2.644 2.654-2.644 6.947 0 9.59a6.782 6.782 0 009.09.464l2.607 2.607c.057.05.148.05.197 0l.767-.768c.05-.048.05-.139 0-.196zm-4.018-3.053a5.431 5.431 0 01-7.678 0 5.43 5.43 0 010-7.68 5.43 5.43 0 017.679 0 5.43 5.43 0 010 7.68z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ZoomOut
