import {
  TBaseRunVariable,
  useWizardState,
} from '@invisible/common/components/providers/active-wizard-provider'
import { safeJSONParse } from '@invisible/common/helpers'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Form from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import dynamic from 'next/dynamic'
import { useQueryClient } from 'react-query'

import { customWidgets } from '../common/rjsfWidgets'

const JsonEditor = dynamic(() => import('react-json-view'), { ssr: false })

type IProps = WizardSchemas.WACConfig.TSchema & {
  baseRunVariableId: string
  wizardInitialBRVs: TBaseRunVariable[]
  isReadOnly: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const JSONWAC = ({
  value,
  showName,
  name,
  json,
  baseRunVariableId,
  wizardInitialBRVs,
  isReadOnly,
}: IProps) => {
  const schema =
    (json?.schemaBaseVariableId
      ? wizardInitialBRVs.find((brv) => brv.id === json?.schemaBaseVariableId)?.value
      : json?.schema) ?? {}
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { dispatch } = useWizardState()
  const { mutateAsync: updateBaseVariable, isLoading } = useMutation('baseRunVariable.update', {
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('baseRunVariable.findWizardDataForStepRun')
    },
    onMutate: (data) => {
      dispatch({ type: 'setBaseRunVariableValue', baseRunVariableId: data.id, value: data.value })
    },
  })
  const parsedData = typeof value === 'string' ? safeJSONParse(value) : value

  return (
    <div className='border-grayScale-400 box-border h-full overflow-auto rounded-lg border border-solid bg-white p-4 shadow-md'>
      {showName ? <div className='mb-2 font-bold'>{name}</div> : null}
      {json?.asViewer ? (
        <JsonEditor
          src={parsedData}
          indentWidth={json?.viewOptions?.indentWidth}
          enableClipboard={json?.viewOptions?.allowCopy}
          displayDataTypes={json?.viewOptions?.showDataTypes ?? false}
          name={false}
        />
      ) : (
        <Form
          schema={schema as RJSFSchema}
          formData={parsedData}
          widgets={customWidgets}
          validator={validator}
          disabled={isReadOnly}
          onSubmit={({ formData }) =>
            updateBaseVariable({
              id: baseRunVariableId,
              value: formData,
            })
          }>
          <div className='mx-auto mt-2 w-fit'>
            <Button disabled={isReadOnly} variant='primary' size='md' loading={isLoading}>
              Save
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}
