import { FC, ReactNode } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const BreadcrumbComponent: FC = ({ children }) => (
  <div className='flex space-x-2 pl-4'>{children}</div>
)

interface ItemProps {
  onClick?: (name: string | ReactNode) => void
  separator?: 'slash' | 'arrow'
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Item: FC<ItemProps> = ({ children, onClick, separator = 'slash', ...props }) => (
  <div
    className={`text-paragraphs flex items-center space-x-2 text-sm ${
      onClick ? 'cursor-pointer' : 'cursor-default'
    } last-of-type:text-theme-main`}
    data-cy='breadcrumb'
    onClick={() => onClick?.(Array.isArray(children) ? children.join('') : children)}
    {...props}>
    {children}
    <div className='text-main ml-2'>{separator === 'arrow' ? '>' : '/'}</div>
  </div>
)

BreadcrumbComponent.displayName = 'Breadcrumb'
Item.displayName = 'Breadcrumb.Item'

const Breadcrumb = Object.assign(BreadcrumbComponent, {
  Item,
})

export { Breadcrumb }
