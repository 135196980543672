import { z } from '@invisible/zod'

const schema = z.object({
  options: z
    .array(
      z.object({
        name: z.string(),
      })
    )
    .optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
