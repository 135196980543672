import { CloseIcon } from '@invisible/ui/icons'
import { filter, map } from 'lodash/fp'

interface ITagInput {
  tags: string[]
  onTagChange: (tags: string[]) => void
  onInputChange: (value: string) => void
  inputText: string
  placeholder: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const TagInput: React.FC<ITagInput> = ({
  tags,
  onTagChange,
  onInputChange,
  inputText,
  placeholder,
}) => {
  const tagsList = map((tag: string) => (
    <div
      key={tag}
      className='bg-weak flex items-center gap-1 whitespace-nowrap rounded-md py-1 pl-2 pr-1'>
      <div>{tag}</div>
      <div
        onClick={() => onTagChange(filter((t: string) => t !== tag)(tags))}
        className='hover:bg-main relative top-[0.33px] rounded-md px-1 py-[0.5px] hover:cursor-pointer'>
        <CloseIcon className='relative top-[0.75px]' width={12} height={12} />
      </div>
    </div>
  ))(tags)
  return (
    <div className='border-main min-h-6 rounded-md border border-solid bg-white p-1 text-sm focus:border-black'>
      <div className='flex flex-wrap overflow-x-auto'>
        <div className='flex flex-wrap gap-1'>{tagsList}</div>
        <input
          className='flex h-6 w-full border-none bg-transparent outline-none'
          onChange={(e) => onInputChange(e.target.value)}
          type='text'
          value={inputText}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}
